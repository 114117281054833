import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Logger, { PageActionEnum, resolveUrls } from '@sympli/ui-logger';

import { actionClearCurrentPageFeatureFlag } from 'src/@core/store/actions/currentPageFeatureFlags';
import { usePreviousValue } from '../../hooks';

function NavigationTracker({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const dispatch = useDispatch();

  let currentLocation = window.location.toString();
  const previousLocation = usePreviousValue(currentLocation);

  React.useLayoutEffect(() => {
    if (previousLocation && previousLocation !== currentLocation) {
      const [previousUrl, groupedPreviousUrl, previousPathUrl, previousGroupedPathUrl] = resolveUrls(previousLocation);
      Logger.capturePageAction(PageActionEnum.RouteChange, {
        previousUrl,
        groupedPreviousUrl,
        previousPathUrl,
        previousGroupedPathUrl
      });
    }

    if (previousLocation !== currentLocation) {
      // when URL changed, we need to clear the redux
      dispatch(actionClearCurrentPageFeatureFlag());
    }
  }, [location, currentLocation, previousLocation, dispatch]);

  return <>{children}</>;
}

export default NavigationTracker;
