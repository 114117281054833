import * as React from 'react';

import classNames from 'classnames';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Box from 'src/@core/components/layout/box';
import Line from 'src/@core/components/line-loader';
import styles, { ClassKeys } from './styles';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'grey';
}
type Props = OwnProps & WithStyles<ClassKeys>;

class WorkspaceDetailBoxLoader extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    color: 'white'
  };

  render() {
    const { classes, className, color, ...other } = this.props;

    return (
      <Box className={classNames(classes['root'], className)} {...other}>
        <Line color={color} variant="large" widthPercent={45} />
        <Line color={color} variant="medium" widthPercent={50} style={{ marginBottom: 4 }} />
        <Line color={color} variant="medium" widthPercent={55} style={{ marginBottom: 4 }} />
        <Line color={color} variant="small" widthPercent={35} style={{ marginBottom: 12 }} />

        <Line color={color} variant="small" icon widthPercent={35} />
        <Line color={color} variant="small" widthPercent={60} marginLeft={20} style={{ marginBottom: 8 }} />

        <Line color={color} variant="small" icon widthPercent={35} />
        <Line color={color} variant="small" widthPercent={40} marginLeft={20} style={{ marginBottom: 8 }} />
        <Line color={color} variant="small" icon widthPercent={50} />
      </Box>
    );
  }
}

export default withStyles(styles)(WorkspaceDetailBoxLoader);
