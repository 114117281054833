import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { DirectionsApiRequest } from '../directions/actions';
import { actionFetchAllDirections, actionUpdateAllDistributionParticipant } from './actions';

export interface AllDirectionsState {
  detail?: HttpTypes.WorkspaceDirectionsOverviewApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: DirectionsApiRequest;
}

const initialState: AllDirectionsState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useAllDirections(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.allDirections);
  if (state.args?.workspaceId !== workspaceId || state.args?.participantId !== participantId) {
    return initialState;
  }
  return state;
}

const reducer = createReducer<
  //
  AllDirectionsState,
  Action
>(initialState)
  .handleAction(actionFetchAllDirections.request, (state, action): AllDirectionsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchAllDirections.success, (state, action): AllDirectionsState => {
    const { data } = action.payload;

    //TODO: review this
    if (data == null) {
      return state;
    }

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: data
    };
  })
  .handleAction(actionFetchAllDirections.failure, (state, action): AllDirectionsState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateAllDistributionParticipant.request, (state, action): AllDirectionsState => {
    if (!action.payload || !state.detail) {
      return state;
    }

    const { distributionsParticipant: updatedDistributionsParticipant, participantId } = action.payload;
    const { distributionsParticipants } = state.detail;
    const idx = distributionsParticipants.findIndex(item => item.participantId === participantId);

    if (idx === -1) {
      return state;
    }

    const newDistributionsParticipants = distributionsParticipants.concat();
    newDistributionsParticipants[idx] = { ...newDistributionsParticipants[idx], ...updatedDistributionsParticipant };
    const { detail } = state;

    return {
      ...state,
      detail: {
        ...detail,
        distributionsParticipants: newDistributionsParticipants
      }
    };
  });

export default reducer;
