import { createAsyncAction } from 'typesafe-actions';

import { LinkedWorkspaceApiResponseModel } from './models';

export interface LinkedWorkspacesApiRequest {
  workspaceId: string;
}

export const actionFetchLinkedWorkspaces = createAsyncAction(
  //
  'FETCH_LINKED_WORKSPACES',
  'FETCH_LINKED_WORKSPACES_SUCCESS',
  'FETCH_LINKED_WORKSPACES_ERROR'
)<
  //
  LinkedWorkspacesApiRequest,
  { data: LinkedWorkspaceApiResponseModel[] },
  { error: Error }
>();
