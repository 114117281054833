import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-28/necds';

import { VIC_TRANSFER_PARTY_FORM_CONFIG } from '../../config';
import { TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup } from '../../enums';

const yupTransfereeDetails = resolveMemoizedTenancyValidation({
  //
  partyCapacityLookup: TransfereeDetails$ReceivingProprietorGroups$Parties$PartyCapacityLookup,
  partyCapacityMinor: NecdsPartyCapacityEnum.Minor,
  isAddressRequired: true,
  partyFormConfig: VIC_TRANSFER_PARTY_FORM_CONFIG
});

export default yupTransfereeDetails;
