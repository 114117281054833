import directionsList from './directionsList';
import globalErrors from './globalErrors';
import profile from './profile';
import profileUserReportsPermissions from './profileUserReportsPermissions';
import searchBoxUpdated from './searchBoxUpdated';
import searchBoxUpdatedArchived from './searchBoxUpdatedArchived';
import workspaceAssignableGroups from './workspaceAssignableGroups';

export const coreSagas = [
  ...directionsList,
  ...globalErrors,
  ...profile,
  ...profileUserReportsPermissions,
  ...searchBoxUpdated,
  ...searchBoxUpdatedArchived,
  ...workspaceAssignableGroups
];
