import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache

/**
 path:
 - partyBook[*].partyType

 schema version:
[{"id":1,"value":"Individual","name":"Individual","_meta":null},{"id":2,"value":"Organisation","name":"Organisation","_meta":null}]

 hash:
 WzEsMl0=
*/
// export enum PartyBook$PartyTypeEnum {
//   Individual = 1,
//   Organisation = 2
// }
// export const PARTY_BOOK$PARTY_TYPE_LOOKUP_OPTIONS: LookupEnumModel<PartyBook$PartyTypeEnum>[] = [
//   {
//     id: PartyBook$PartyTypeEnum.Individual,
//     name: 'Individual'
//   },
//   {
//     id: PartyBook$PartyTypeEnum.Organisation,
//     name: 'Organisation'
//   }
// ];

// /**
//  path:
//  - partyBook[*].administrationStatus

//  schema version:
// [{"id":0,"value":"AdministratorAppointed","name":"Administrator appointed","_meta":null},{"id":1,"value":"ControllerAppointed","name":"Controller appointed","_meta":null},{"id":2,"value":"InLiquidation","name":"In liquidation","_meta":null},{"id":3,"value":"ReceiverAndManagerAppointed","name":"Receiver and manager appointed","_meta":null},{"id":4,"value":"ReceiverAppointed","name":"Receiver appointed","_meta":null},{"id":5,"value":"SubjectToADeedOfCompanyArrangement","name":"Subject to a deed of company arrangement","_meta":null}]

//  hash:
//  WzAsMSwyLDMsNCw1XQ==
// */
// export enum PartyBook$AdministrationStatusEnum {
//   AdministratorAppointed = 0,
//   ControllerAppointed = 1,
//   InLiquidation = 2,
//   ReceiverAndManagerAppointed = 3,
//   ReceiverAppointed = 4,
//   SubjectToADeedOfCompanyArrangement = 5
// }
// export const PARTY_BOOK$ADMINISTRATION_STATUS_LOOKUP_OPTIONS: LookupEnumModel<PartyBook$AdministrationStatusEnum>[] = [
//   {
//     id: PartyBook$AdministrationStatusEnum.AdministratorAppointed,
//     name: 'Administrator appointed'
//   },
//   {
//     id: PartyBook$AdministrationStatusEnum.ControllerAppointed,
//     name: 'Controller appointed'
//   },
//   {
//     id: PartyBook$AdministrationStatusEnum.InLiquidation,
//     name: 'In liquidation'
//   },
//   {
//     id: PartyBook$AdministrationStatusEnum.ReceiverAndManagerAppointed,
//     name: 'Receiver and manager appointed'
//   },
//   {
//     id: PartyBook$AdministrationStatusEnum.ReceiverAppointed,
//     name: 'Receiver appointed'
//   },
//   {
//     id: PartyBook$AdministrationStatusEnum.SubjectToADeedOfCompanyArrangement,
//     name: 'Subject to a deed of company arrangement'
//   }
// ];

/**
 path:
 - partyBook[*].nameChange.justificationReason

 schema version:
[{"id":0,"value":"Reason1","name":"Adoption of New Name","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":1,"value":"Reason2","name":"Approved Abbreviation","_meta":{"conditional":{"rules":[{"partyType":2}]}}},{"id":2,"value":"Reason3","name":"Change of Name","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":3,"value":"Reason4","name":"Incorporated Name Change","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":4,"value":"Reason5","name":"Legislative Change","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":5,"value":"Reason6","name":"Marriage","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":6,"value":"Reason7","name":"Organisation Re-structure","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}},{"id":7,"value":"Reason8","name":"Resumption of Maiden Name","_meta":{"conditional":{"rules":[{"partyType":1},{"partyType":2}]}}}]

 hash:
 WzAsMSwyLDMsNCw1LDYsN10=
*/
// export enum PartyBook$NameChange$JustificationReasonEnum {
//   Reason1 = 0,
//   Reason2 = 1,
//   Reason3 = 2,
//   Reason4 = 3,
//   Reason5 = 4,
//   Reason6 = 5,
//   Reason7 = 6,
//   Reason8 = 7
// }
// export const PARTY_BOOK$NAME_CHANGE$JUSTIFICATION_REASON_LOOKUP_OPTIONS: ConditionalLookupEnumModel<PartyBook$NameChange$JustificationReasonEnum>[] = [
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason1,
//     name: 'Adoption of New Name',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason2,
//     name: 'Approved Abbreviation',
//     _isVisible: (context: object): boolean => $eq($get(context, 'partyType'), 2)
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason3,
//     name: 'Change of Name',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason4,
//     name: 'Incorporated Name Change',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason5,
//     name: 'Legislative Change',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason6,
//     name: 'Marriage',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason7,
//     name: 'Organisation Re-structure',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   },
//   {
//     id: PartyBook$NameChange$JustificationReasonEnum.Reason8,
//     name: 'Resumption of Maiden Name',
//     _isVisible: (context: object): boolean => {
//       const partyType = $get(context, 'partyType');
//       return $eq(partyType, 1) || $eq(partyType, 2);
//     }
//   }
// ];

/**
 path:
 - partyBook[*].nameChange.actionRequiredValue

 schema version:
[{"id":0,"value":"NoSupportingDocumentsRequired","name":"No supporting documents required","_meta":{"conditional":{"rules":[{"justificationReason":1}]}}},{"id":1,"value":"SpecifyChangeOfNameCertificateDetails","name":"Specify Change Of Name Certificate details","_meta":{"conditional":{"rules":[{"justificationReason":0},{"justificationReason":2},{"justificationReason":3}]}}},{"id":2,"value":"SpecifyGovernmentGazetteDetails","name":"Specify Government Gazette details","_meta":{"conditional":{"rules":[{"justificationReason":4}]}}},{"id":3,"value":"SpecifyLegislationReference","name":"Specify legislation reference","_meta":{"conditional":{"rules":[{"justificationReason":4}]}}},{"id":4,"value":"SpecifyMarriageCertificateDetails","name":"Specify Marriage Certificate details","_meta":{"conditional":{"rules":[{"justificationReason":5},{"justificationReason":7}]}}},{"id":5,"value":"SpecifyRecordOfAuthorisedOrganizationName","name":"Specify record of authorised organisation name change filed at Land Registry","_meta":{"conditional":{"rules":[{"justificationReason":3},{"justificationReason":6}]}}}]

 hash:
 WzAsMSwyLDMsNCw1XQ==
*/
export enum PartyBook$NameChange$ActionRequiredValueEnum {
  NoSupportingDocumentsRequired = 0,
  SpecifyChangeOfNameCertificateDetails = 1,
  SpecifyGovernmentGazetteDetails = 2,
  SpecifyLegislationReference = 3,
  SpecifyMarriageCertificateDetails = 4,
  SpecifyRecordOfAuthorisedOrganizationName = 5
}
// export const PARTY_BOOK$NAME_CHANGE$ACTION_REQUIRED_VALUE_LOOKUP_OPTIONS: ConditionalLookupEnumModel<PartyBook$NameChange$ActionRequiredValueEnum>[] = [
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.NoSupportingDocumentsRequired,
//     name: 'No supporting documents required',
//     _isVisible: (context: object): boolean => $eq($get(context, 'justificationReason'), PartyBook$NameChange$JustificationReasonEnum.Reason2)
//   },
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.SpecifyChangeOfNameCertificateDetails,
//     name: 'Specify Change Of Name Certificate details',
//     _isVisible: (context: object): boolean => {
//       const justificationReason = $get(context, 'justificationReason');
//       return (
//         $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason1) ||
//         $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason3) ||
//         $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason4)
//       );
//     }
//   },
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.SpecifyGovernmentGazetteDetails,
//     name: 'Specify Government Gazette details',
//     _isVisible: (context: object): boolean => $eq($get(context, 'justificationReason'), PartyBook$NameChange$JustificationReasonEnum.Reason5)
//   },
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.SpecifyLegislationReference,
//     name: 'Specify legislation reference',
//     _isVisible: (context: object): boolean => $eq($get(context, 'justificationReason'), PartyBook$NameChange$JustificationReasonEnum.Reason5)
//   },
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.SpecifyMarriageCertificateDetails,
//     name: 'Specify Marriage Certificate details',
//     _isVisible: (context: object): boolean => {
//       const justificationReason = $get(context, 'justificationReason');
//       return $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason6) || $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason8);
//     }
//   },
//   {
//     id: PartyBook$NameChange$ActionRequiredValueEnum.SpecifyRecordOfAuthorisedOrganizationName,
//     name: 'Specify record of authorised organisation name change filed at Land Registry',
//     _isVisible: (context: object): boolean => {
//       const justificationReason = $get(context, 'justificationReason');
//       return $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason4) || $eq(justificationReason, PartyBook$NameChange$JustificationReasonEnum.Reason7);
//     }
//   }
// ];

/**
 path:
 - partyBook[*].nameChange.supportingEvidences[*].evidenceTypeValue

 schema version:
[{"id":0,"value":"CertificateUnderNSWTrusteeAndGuardianAct","name":"Certificate under NSW Trustee and Guardian Act","_meta":null},{"id":1,"value":"ChangeOfNameCertificateForIndividual","name":"Change Of Name Certificate For Individual","_meta":null},{"id":2,"value":"ChangeOfNameCertificateForOrganisation","name":"Change Of Name Certificate For Organisation","_meta":null},{"id":3,"value":"CoronersReport","name":"Coroners Report","_meta":null},{"id":4,"value":"DeathCertificate","name":"Death Certificate","_meta":null},{"id":5,"value":"DeedOfAppointment","name":"Deed of Appointment","_meta":null},{"id":6,"value":"GovernmentGazetteReference","name":"Government Gazette Reference","_meta":null},{"id":7,"value":"LandRegistryRecordOfAuthorisedOrganisationName","name":"Land Registry Record Of Authorised Organisation Name","_meta":null},{"id":8,"value":"LegislationReference","name":"Legislation Reference","_meta":null},{"id":9,"value":"LettersOfAdministration","name":"Letters of Administration","_meta":null},{"id":10,"value":"MarriageCertificate","name":"Marriage Certificate","_meta":null},{"id":11,"value":"MedicalCertificateCertifyingDeath","name":"Medical Certificate certifying death","_meta":null},{"id":12,"value":"Probate","name":"Probate","_meta":null},{"id":13,"value":"StatutoryDeclaration","name":"Statutory Declaration","_meta":null}]

 hash:
 WzAsMSwyLDMsNCw1LDYsNyw4LDksMTAsMTEsMTIsMTNd
*/
export enum PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum {
  CertificateUnderNSWTrusteeAndGuardianAct = 0,
  ChangeOfNameCertificateForIndividual = 1,
  ChangeOfNameCertificateForOrganisation = 2,
  CoronersReport = 3,
  DeathCertificate = 4,
  DeedOfAppointment = 5,
  GovernmentGazetteReference = 6,
  LandRegistryRecordOfAuthorisedOrganisationName = 7,
  LegislationReference = 8,
  LettersOfAdministration = 9,
  MarriageCertificate = 10,
  MedicalCertificateCertifyingDeath = 11,
  Probate = 12,
  StatutoryDeclaration = 13
}
export const PARTY_BOOK$NAME_CHANGE$SUPPORTING_EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupEnumModel<PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum>[] = [
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct,
    name: 'Certificate under NSW Trustee and Guardian Act'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.ChangeOfNameCertificateForIndividual,
    name: 'Change Of Name Certificate For Individual'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.ChangeOfNameCertificateForOrganisation,
    name: 'Change Of Name Certificate For Organisation'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.CoronersReport,
    name: 'Coroners Report'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.DeathCertificate,
    name: 'Death Certificate'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.DeedOfAppointment,
    name: 'Deed of Appointment'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.GovernmentGazetteReference,
    name: 'Government Gazette Reference'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.LandRegistryRecordOfAuthorisedOrganisationName,
    name: 'Land Registry Record Of Authorised Organisation Name'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.LegislationReference,
    name: 'Legislation Reference'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.LettersOfAdministration,
    name: 'Letters of Administration'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.MarriageCertificate,
    name: 'Marriage Certificate'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.MedicalCertificateCertifyingDeath,
    name: 'Medical Certificate certifying death'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.Probate,
    name: 'Probate'
  },
  {
    id: PartyBook$NameChange$SupportingEvidences$EvidenceTypeValueEnum.StatutoryDeclaration,
    name: 'Statutory Declaration'
  }
];

/**
 path:
 - transactionInstruments[*].documentType

 schema version:
[{"id":0,"value":"DischargeOfMortgage","name":"Discharge of Mortgage","_meta":null},{"id":1,"value":"Transfer","name":"Transfer","_meta":null},{"id":2,"value":"Mortgage","name":"Mortgage","_meta":null},{"id":3,"value":"Lease","name":"Lease","_meta":null},{"id":4,"value":"NoticeOfDeath","name":"Notice of Death","_meta":null},{"id":5,"value":"TransmissionApplicationDeviseeBeneficiaryNextOfKin","name":"Transmission Application (Devisee, Beneficiary, Next Of Kin)","_meta":null},{"id":6,"value":"TransmissionApplicationExecutorAdministratorTrustee","name":"Transmission Application (Executor, Administrator, Trustee)","_meta":null},{"id":7,"value":"ApplicationToRecordNewRegisteredProprietor","name":"Application to record a new registered proprietor","_meta":null},{"id":8,"value":"ChangeOfName","name":"Change of Name","_meta":null},{"id":9,"value":"Charge","name":"Charge","_meta":null},{"id":10,"value":"DischargeOfCharge","name":"Discharge of Charge","_meta":null},{"id":11,"value":"MortgageOfCharge","name":"Mortgage of Charge","_meta":null},{"id":12,"value":"MortgageOfLease","name":"Mortgage of Lease","_meta":null},{"id":13,"value":"Request","name":"Request","_meta":null},{"id":14,"value":"SubLease","name":"Sub-Lease","_meta":null},{"id":15,"value":"TransferByMortgageeUnderPowerOfSale","name":"Transfer by Mortgagee under Power of Sale","_meta":null},{"id":16,"value":"TransferOfCharge","name":"Transfer of Charge","_meta":null},{"id":17,"value":"TransferOfLease","name":"Transfer of Lease","_meta":null},{"id":18,"value":"TransferOfMortgage","name":"Transfer of Mortgage","_meta":null}]

 hash:
 WzAsMSwyLDMsNCw1LDYsNyw4LDksMTAsMTEsMTIsMTMsMTQsMTUsMTYsMTcsMThd
*/
export enum TransactionInstruments$DocumentTypeEnum {
  DischargeOfMortgage = 0,
  Transfer = 1,
  Mortgage = 2,
  Lease = 3,
  NoticeOfDeath = 4,
  TransmissionApplicationDeviseeBeneficiaryNextOfKin = 5,
  TransmissionApplicationExecutorAdministratorTrustee = 6,
  ApplicationToRecordNewRegisteredProprietor = 7,
  ChangeOfName = 8,
  Charge = 9,
  DischargeOfCharge = 10,
  MortgageOfCharge = 11,
  MortgageOfLease = 12,
  Request = 13,
  SubLease = 14,
  TransferByMortgageeUnderPowerOfSale = 15,
  TransferOfCharge = 16,
  TransferOfLease = 17,
  TransferOfMortgage = 18
}

export const TRANSACTION_INSTRUMENTS$DOCUMENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<TransactionInstruments$DocumentTypeEnum>[] = [
  {
    id: TransactionInstruments$DocumentTypeEnum.DischargeOfMortgage,
    name: 'Discharge of Mortgage'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.Transfer,
    name: 'Transfer'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.Mortgage,
    name: 'Mortgage'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.Lease,
    name: 'Lease'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.NoticeOfDeath,
    name: 'Notice of Death'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransmissionApplicationDeviseeBeneficiaryNextOfKin,
    name: 'Transmission Application (Devisee, Beneficiary, Next Of Kin)'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransmissionApplicationExecutorAdministratorTrustee,
    name: 'Transmission Application (Executor, Administrator, Trustee)'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.ApplicationToRecordNewRegisteredProprietor,
    name: 'Application to record a new registered proprietor'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.ChangeOfName,
    name: 'Change of Name'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.Charge,
    name: 'Charge'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.DischargeOfCharge,
    name: 'Discharge of Charge'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.MortgageOfCharge,
    name: 'Mortgage of Charge'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.MortgageOfLease,
    name: 'Mortgage of Lease'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.Request,
    name: 'Request'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.SubLease,
    name: 'Sub-Lease'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransferByMortgageeUnderPowerOfSale,
    name: 'Transfer by Mortgagee under Power of Sale'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransferOfCharge,
    name: 'Transfer of Charge'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransferOfLease,
    name: 'Transfer of Lease'
  },
  {
    id: TransactionInstruments$DocumentTypeEnum.TransferOfMortgage,
    name: 'Transfer of Mortgage'
  }
];

export const RECEIVING_PARTY_TITLE_MAPPING: Record<number, string> = {
  [TransactionInstruments$DocumentTypeEnum.Transfer]: 'Transferee',
  [TransactionInstruments$DocumentTypeEnum.Mortgage]: 'Mortgagee',
  [TransactionInstruments$DocumentTypeEnum.Lease]: 'Lessee',
  [TransactionInstruments$DocumentTypeEnum.NoticeOfDeath]: 'Applicant',
  [TransactionInstruments$DocumentTypeEnum.TransmissionApplicationDeviseeBeneficiaryNextOfKin]: 'Applicant',
  [TransactionInstruments$DocumentTypeEnum.TransmissionApplicationExecutorAdministratorTrustee]: 'Applicant'
};

export enum PartLandExtentEnum {
  LeaseDescription = 0,
  LotInUnregisteredPlan = 1,
  LotInAutoConsol = 2
}

export const TRANSACTION_INSTRUMENTS$PARTLAND_AFFECTED_EXTENT_TYPE_LOOKUP_OPTIONS: LookupEnumModel<PartLandExtentEnum>[] = [
  {
    id: PartLandExtentEnum.LeaseDescription,
    name: 'Part - Lease description'
  },
  {
    id: PartLandExtentEnum.LotInUnregisteredPlan,
    name: 'Part - Lot in unregistered plan'
  },
  {
    id: PartLandExtentEnum.LotInAutoConsol,
    name: 'Part - Lot in auto consol'
  }
];
