import queryString from 'query-string';
import { call, cancelled, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { SearchBoxUpdatedApiRequest } from '../../components/layout/global-search/models';
import { actionFetchSearchBoxUpdatedArchivedFeed } from '../actions/searchBoxUpdatedArchived';
import { SearchBoxesUpdatedApiResponse } from '../reducers/searchBoxUpdated';
import { captureUserAction } from './searchBoxUpdated';

//SearchBox update
const fetchSearchBoxUpdatedFeed = (q: SearchBoxUpdatedApiRequest = {}) => http.get<SearchBoxesUpdatedApiResponse>(`/Search/workspaces?${queryString.stringify(q)}`);

// fetch with debounce input change
function* sagaDebounceFetchSearchBoxUpdatedArchivedFeed(action: ReturnType<typeof actionFetchSearchBoxUpdatedArchivedFeed.request>) {
  try {
    const data = yield* call(fetchSearchBoxUpdatedFeed, action.payload);

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      //TODO improve our logging, we no longer rely on searchCategoryId
      captureUserAction(action.payload.searchCategoryId);
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchSearchBoxUpdatedArchivedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchSearchBoxUpdatedArchivedFeed.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchSearchBoxUpdatedArchivedFeed.request, sagaDebounceFetchSearchBoxUpdatedArchivedFeed)
];
