import { PartyCapacityModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { PartyMetadata } from '@sympli-mfe/document-forms-framework/shared-config/party';

export declare type ReferenceParty = {
  id: string;
  metadata?: PartyMetadata;
  externalId?: string;
  partyCapacity?: PartyCapacityModel;
};

export class Result<TParty extends ReferenceParty, TReference extends TenancyPartyModel> {
  constructor(
    public updatedParties: TParty[],
    public updatedReferences: TReference[]
  ) {}
}
