import * as yup from 'yup';

import yupClaimDetails from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/sections/claim-details/validationSchema';
import yupOtherParties from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/sections/other-party-details/validationSchema';
import yupProhibition from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/sections/prohibition/validationSchema';
import { BaseCaveatModel } from '../../../../shared/2-24/caveat/models';
import yupCaveators from '../../../../shared/2-24/caveat/sections/caveators/validationSchema';
import yupMortgagees from '../../../../shared/2-24/caveat/sections/mortgagees/validationSchema';
import { yupPartyBook } from '../../../../shared/2-24/caveat/sections/party-book/validationSchema';
import yupProprietors from '../../../../shared/2-24/caveat/sections/registered-proprietor/validationSchema';
import { yupTitleReferences } from '../../../../shared/2-24/caveat/sections/title-references/validationSchema';
import { QLD_CAVEAT_PARTY_FORM_CONFIG } from './config';

export default yup
  .object<BaseCaveatModel>({
    partyBook: yupPartyBook(QLD_CAVEAT_PARTY_FORM_CONFIG),
    titleReferences: yupTitleReferences,
    caveators: yupCaveators,
    proprietors: yupProprietors,
    claimDetails: yupClaimDetails,
    prohibition: yupProhibition,
    otherParties: yupOtherParties({ partyFormConfig: QLD_CAVEAT_PARTY_FORM_CONFIG, isAddressRequired: true, partyCapacityDetailMaxLength: 300 }),
    mortgagees: yupMortgagees,
    invalidParties: yup.mixed<boolean>(),
    mergeFailedReason: yup.mixed(),
    addressBook: yup.mixed(),
    partyReferences: yup.mixed(),
    subscriberParties: yup.mixed()
  })
  .defined()
  .log();
