import { defaultMemoize } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const resolveWorkspaceTypeOptions = defaultMemoize((inCludingFinancial?: boolean) => {
  const WORKSPACE_TYPE_OPTIONS: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[] = [];

  if (inCludingFinancial) {
    WORKSPACE_TYPE_OPTIONS.push({ id: HttpTypes.WorkspaceTypeEnum.FinancialSettlement, name: 'Financial settlement' });
  }

  WORKSPACE_TYPE_OPTIONS.push({ id: HttpTypes.WorkspaceTypeEnum.RegistrationOnly, name: 'Standalone' });
  return WORKSPACE_TYPE_OPTIONS;
});
