import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchJurisdictionSummaryFeed } from '../actions/jurisdictions';

export interface JurisdictionsFeedState {
  items: Array<HttpTypes.JurisdictionApiResponse>;
  status: ApiStatus;
  error?: string;
}

const initialState: JurisdictionsFeedState = {
  items: [],
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  JurisdictionsFeedState,
  Action
>(initialState)
  .handleAction(actionFetchJurisdictionSummaryFeed.request, (state): JurisdictionsFeedState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchJurisdictionSummaryFeed.success, (state, action): JurisdictionsFeedState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchJurisdictionSummaryFeed.failure, (state, action): JurisdictionsFeedState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
