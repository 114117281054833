import * as React from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import visuallyHidden from '@mui/utils/visuallyHidden';

import Autocomplete, { RenderSuggestionParams } from '@sympli/ui-framework/components/form/base-components/auto-complete';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconSearch } from '@sympli/ui-framework/icons';

import Popover from 'src/@core/components/popover';
import UserAvatar101 from 'src/@core/components/user-avatar-101';
import ListItemLink from 'src/components/list-item-link';
import { AllocatedUser } from 'src/models';
import { colors } from 'src/theme';
import DocumentItemBase from '../../../components/document-item-base';
import { UserLookup } from './model';
import styles, { ClassKeys } from './styles';

export interface WorkspaceStaffAssignProps {
  onStaffAssignSubmit(selectedStaffOrCurrentUser?: AllocatedUser): Promise<void>;
  onStaffUnassigned(): Promise<void>;
  onClick(): void;
  getSuggestions(search: string): Promise<UserLookup[]>;
  onPopperClose(): void;
  openPopperTip: boolean;
  userName: string;
  avatarUrl?: string;
  avatarOnly?: boolean;
  isAssignedToCurrentUser: boolean;
  assignedGroupName?: string;
  assignedUserId?: string;
}

type Props = WorkspaceStaffAssignProps & WithStyles<ClassKeys>;
interface State {
  isLoading: boolean;
  isUnassignLoading: boolean;
  selectedSuggestion: UserLookup | undefined;
  value: string;
  isOpen: boolean;
}

class WorkspaceStaffAssign extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isLoading: false,
    isUnassignLoading: false,
    selectedSuggestion: undefined,
    value: '',
    isOpen: false
  };

  private readonly searchingStartAtCharacter: number = 3;

  private buttonRef = React.createRef<HTMLButtonElement>();

  render() {
    const { classes, openPopperTip, avatarOnly } = this.props;
    return (
      <>
        {avatarOnly ? this.renderUserAvatar() : this.renderUserAvatarAssign()}
        <Popover
          open={openPopperTip}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          arrowPosition="bottom"
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
          offset={100}
          anchorEl={this.buttonRef.current}
          onClose={this.props.onPopperClose}
          closeIconColor={colors.GREY_400}
        >
          {this.renderPopperTipStaffAssign()}
        </Popover>
      </>
    );
  }

  private renderUserAvatar = () => {
    const { userName, avatarUrl } = this.props;
    return (
      <IconButton ref={this.buttonRef} onClick={this.props.onClick} size="large" data-testid="workspace-staff-assign-icon-button">
        <UserAvatar101 text={userName} src={avatarUrl} size="small" placement="right-start" tooltipType="dashboardFeed" />
      </IconButton>
    );
  };

  private renderUserAvatarAssign = () => {
    const { userName, avatarUrl, assignedGroupName, classes, onClick } = this.props;
    return (
      <FlexLayout justifyContent="flex-start" flexDirection="column" className={classes.root} data-testid="workspace-staff-assign">
        <span style={visuallyHidden}>{'Workspace staff assign'}</span>
        <List component="nav" className={classes.list}>
          <ListItemLink onClick={onClick} classes={{ link: classes.itemLink }}>
            <Box display="flex" flexDirection="row" ref={this.buttonRef}>
              <Box flexDirection="column" justifyContent="flex-start">
                <DocumentItemBase
                  underline={true}
                  title={userName ? userName : 'Unassigned'}
                  classes={{
                    title: classes.title
                  }}
                  description={<Typography variant="body2">{assignedGroupName}</Typography>}
                />
              </Box>
              <Box flexDirection="column" justifyContent="flex-end" sx={{ marginLeft: 'auto', paddingTop: theme => theme.spacing(0.25) }}>
                <UserAvatar101 text={userName} src={avatarUrl} size="small" showToolTip={false} />
              </Box>
            </Box>
          </ListItemLink>
        </List>
      </FlexLayout>
    );
  };

  private renderAssignmentActions = () => {
    const { assignedUserId, isAssignedToCurrentUser, classes } = this.props;
    const { isUnassignLoading } = this.state;

    // if no one assigned, display Assign to me
    if (!assignedUserId) {
      return (
        <SympliButton variant="text" onClick={this.onAssignToMe} className={classes.assignToMe} color="inherit">
          Assign to me
        </SympliButton>
      );
    }

    // if user has been assigned but not logged in user, display both assign to me & un-assign
    if (assignedUserId && !isAssignedToCurrentUser) {
      return (
        <>
          <SympliButton variant="text" onClick={this.onAssignToMe} className={classNames(classes.assignToMe, 'pr-[2px]')} color="inherit">
            Assign to me
          </SympliButton>
          <div className={classNames('h-[15px] w-[1px] bg-[var(--neutral-300)] ')} />
          <SympliButton variant="text" className={classNames(classes.unAssign, 'ml-[0px] pl-[0px]')} onClick={this.onUnAssigned}>
            {isUnassignLoading ? 'Unassigning...' : 'Set as unassigned'}
          </SympliButton>
        </>
      );
    }

    // if user has been assigned and is logged in user, display un-assign
    if (isAssignedToCurrentUser) {
      return (
        <SympliButton variant="text" className={classes.unAssign} onClick={this.onUnAssigned}>
          {isUnassignLoading ? 'Unassigning...' : 'Set as unassigned'}
        </SympliButton>
      );
    }

    return null;
  };

  private renderPopperTipStaffAssign = () => {
    const { classes } = this.props;
    const { value, isLoading } = this.state;
    const { getSuggestions } = this.props;

    return (
      <FlexLayout flexDirection="column">
        <Typography variant="h2" className={classes.assignTitle}>
          <strong>Assign Workspace</strong>
        </Typography>
        <Typography variant="body2" className={classes.searchUser}>
          <Autocomplete
            type="combobox" // prevent default onBlur to trigger handleOnSuggestionSelected
            fullWidth
            classes={{ suggestionsContainer: classes.suggestionsContainer }}
            label="Search and select user:"
            placeholder="Please type at least 3 characters"
            inputProps={{
              value
            }}
            startAdornment={<IconSearch className={classes.searchIcon} />}
            searchingStartsAt={this.searchingStartAtCharacter}
            onChange={this.handleOnSearchUserChange}
            getSuggestions={getSuggestions}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.handleOnSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
          />
        </Typography>
        <FlexLayout alignItems="center" justifyContent="space-between">
          {this.renderAssignmentActions()}

          <SympliButton
            disabled={isLoading || !value || value.length < 3}
            isLoading={isLoading}
            className={classes.saveButton}
            color="primary"
            variant="contained"
            onClick={this.onClickAssign}
          >
            Assign
          </SympliButton>
        </FlexLayout>
      </FlexLayout>
    );
  };

  private getSuggestionValue = (suggestion: UserLookup, value: string) => {
    return suggestion.name;
  };

  private renderSuggestion = (suggestion: UserLookup, props: RenderSuggestionParams) => {
    const { classes } = this.props;
    const { fullName, email } = suggestion;
    return (
      <MenuItem selected={props.isHighlighted} className={classes.suggestionItemContainer} component="div">
        <div className={classes.suggestionName}>{fullName}</div>
        <div className={classes.suggestionEmail}>{email}</div>
      </MenuItem>
    );
  };

  private onAssignToMe = () => {
    this.handleOnStaffAssign();
  };

  private onUnAssigned = () => {
    this.handleOnStaffUnassign();
  };

  private handleOnStaffUnassign = async () => {
    this.setState({ isUnassignLoading: true });
    try {
      await this.props.onStaffUnassigned();
    } catch (e) {
      // parent is handling errors
    } finally {
      this.setState({ isUnassignLoading: false });
    }
  };

  private onClickAssign = () => {
    const { selectedSuggestion } = this.state;
    if (selectedSuggestion) {
      const { id: userId, firstName, lastName, middleName, avatarUrl } = selectedSuggestion;
      this.handleOnStaffAssign({ userId, firstName, lastName, middleName, avatarUrl });
    }
  };

  private handleOnStaffAssign = async (selectedSuggestionOrCurrentUser?: AllocatedUser) => {
    this.setState({ isLoading: true });
    try {
      await this.props.onStaffAssignSubmit(selectedSuggestionOrCurrentUser);
    } catch (e) {
      // parent is handling errors
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private handleOnSuggestionSelected = (event: React.SyntheticEvent<HTMLDivElement>, selectedSuggestion: UserLookup, suggestionValue?: any) => {
    this.setState({ selectedSuggestion });
  };

  private handleOnSearchUserChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setState({ value });
  };
}

export default withStyles(styles)(WorkspaceStaffAssign);
