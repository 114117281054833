import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { DisableTenancyFieldConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';

const isSourceTitleDisabled = (party: PartyModel, justificationEnabled: boolean) => {
  return !justificationEnabled && party.partyType === PartyTypeEnum.Individual && !!party.readonlyMap?.partyType && party.metadata?.source === DataSource.Title;
};

export const disablePartyFieldEditConfig = (isJustificationEnabled: boolean = false): DisableTenancyFieldConfig<PartyModel> => {
  return {
    disabled: (party: PartyModel) => isSourceTitleDisabled(party, isJustificationEnabled) || party.metadata?.source === DataSource.PrecedingDocument,
    disabledTooltip: (party: PartyModel) => {
      if (isSourceTitleDisabled(party, isJustificationEnabled)) {
        return <span>This party cannot be edited as it has been returned from the title. Please add a new party.</span>;
      } else if (party.metadata?.source === DataSource.PrecedingDocument) {
        return <span>This party cannot be edited as it has been returned from the other document. Please add a new party.</span>;
      }
      return <span></span>;
    }
  };
};
