import { actionFetchProfileUserReportsPermissions } from 'src/@core/store/actions/profileUserReportsPermissions';
import { useProfile } from 'src/@core/store/reducers/profile';
import { ProfileUserReportsPermissionsState } from 'src/@core/store/reducers/profileUserReportsPermissions';
import { getReportPermissionOptions } from 'src/@core/utils/reports';
import useRequestReduxDataOnMount from './useRequestReduxDataOnMount';

export default function useAccessibleReports() {
  const userProfile = useProfile().data;

  const [{ detail: reportOptionsDetail, status }] = useRequestReduxDataOnMount<ProfileUserReportsPermissionsState>(
    userProfile?.userId ? actionFetchProfileUserReportsPermissions.request(userProfile!.userId) : null,
    state => state.profileUserReportsPermissions
  );

  const reports = getReportPermissionOptions(reportOptionsDetail);

  return {
    status,
    reports
  };
}
