import * as React from 'react';

import { FormikProps, FormikValues } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import DeleteButton, { DeleteButtonProps } from '@sympli/ui-framework/components/formik/buttons/delete-button';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDownloadPdf, IconEditNew } from '@sympli/ui-framework/icons';
import { currency } from '@sympli/ui-framework/utils/formatters';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { UserAvatar } from 'src/components/avatars';
import { IconLinked } from 'src/components/icons';
import DistributionRecordError from 'src/containers/workspace/financial/directions/components/distribution-record-error';
import { accountDescriptionMapping } from 'src/containers/workspace/financial/directions/helpers';
import { SourceFundFormikModel, TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import LinkedSettlementToParticipantDetail from 'src/containers/workspace/shared/components/linked-settlement-participant-detail';
import { colors } from 'src/theme';
import { createArrayItemModelKeyAppender } from 'src/utils/formUtils';
import { CategoryEnum } from '../../forms/discharge-mortgage-directions/models';
import { isLoanAdvance } from '../../forms/income-mortgage-directions/helper';
import { getConfirmationDialogProps } from '../../helper';
import { UnlinkLineItemTypeEnum } from '../../models';
import ConditionalEditTooltip from '../conditional-edit-tooltip';
import ViewLinkedSettlement from '../view-linked-settlement/ViewLinkedSettlement';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

interface OwnProps<T extends FormikValues> {
  isEditable?: boolean;
  isMultipleEditorsOpen?: boolean;
  formikProps: FormikProps<T>;
  arrayFieldName: string;
  index: number;
  currentParticipant: HttpTypes.WorkspaceParticipant;
  trustAccountMap: TrustAccountMap;
  canSetAutoBalanceForIM?: boolean;
  workspaceId?: string;
  participantId?: string;
  dispatch?: SafeDispatch<Action>;
  hasManageWorkspacePermission: boolean;
}
type Props<T extends FormikValues> = OwnProps<T> & WithStyles<ClassKeys>;

interface State {
  isLoading: boolean;
}

class SourceFund<T extends FormikValues> extends React.PureComponent<Props<T>, State> {
  public readonly state: Readonly<State> = {
    isLoading: false
  };

  private arrayItemModelKeyAppender = createArrayItemModelKeyAppender<SourceFundFormikModel>(this.props.arrayFieldName);

  render() {
    const { arrayFieldName, index, classes, formikProps, isEditable, currentParticipant, workspaceId, participantId, dispatch, hasManageWorkspacePermission } = this.props;
    const itemBinding = `${arrayFieldName}.[${index}]`;
    const details: SourceFundFormikModel = _get(formikProps.values, itemBinding);
    if (details == null) {
      const scope = Logger.scopeWithCustomAttributes({
        participantId: currentParticipant.id
      });
      Logger.captureException(new InvalidDataError(`Invalid source fund details of ${itemBinding}`, details), scope);
      return null;
    }

    const { isEditorOpen, subscriberName, id, paymentMethod } = details;

    let confirmationDialogProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> = {};
    let isUnlinkSourceFund = paymentMethod === HttpTypes.PaymentMethodEnum.Linked;

    if (id && workspaceId && participantId && dispatch) {
      // for im role, we created the default loan advance item, if later the user chooses
      // to change loan advance to linked source fund then the id still exist
      // to avoid this case we will use the LinkedToSympli Id to double check
      // it is the linked item since once linked item created they cannot change it
      const { linkedSettlementItem } = details;
      isUnlinkSourceFund = linkedSettlementItem?.linkedToSympliId !== undefined;

      confirmationDialogProps = getConfirmationDialogProps(
        id,
        classes.optionIcon,
        this.state.isLoading,
        workspaceId,
        participantId,
        () => {
          this.setState({ isLoading: true });
        },
        () => {
          this.setState({ isLoading: false });
        },
        dispatch,
        isUnlinkSourceFund ? (
          <span>
            <b>A Link</b> has been successfully removed.
          </span>
        ) : (
          'Financial line item has been deleted.'
        ),
        isUnlinkSourceFund ? UnlinkLineItemTypeEnum.UnlinkSourceFund : UnlinkLineItemTypeEnum.Other,
        isUnlinkSourceFund ? classes.deleteLinkedItemTitle : undefined
      );
    }

    // the user must first have the save permission and then if linked item it must has the manage workspace permission
    let showDeleteButton = false;
    if (isEditable) {
      showDeleteButton = true;
      if (isUnlinkSourceFund && !hasManageWorkspacePermission) {
        showDeleteButton = false;
      }
    }

    return (
      <FlexLayout className={classes.recordRow}>
        <FlexLayout flexDirection="column" justifyContent="center" className={classes.paymentTypeCol}>
          {showDeleteButton && (
            <DeleteButton
              //
              className={classes.deleteButton}
              formikProps={formikProps}
              fieldName={arrayFieldName}
              removeIndex={index}
              {...confirmationDialogProps}
            />
          )}
          <UserAvatar src={currentParticipant.avatarUrl} text={subscriberName} className={classes.avatar} />
        </FlexLayout>
        <FlexLayout justifyContent="space-between" alignItems="center" className={classes.accountDetailCol}>
          <FlexLayout flexDirection="column" justifyContent="center" className={classes.flexGrow}>
            {isLoanAdvance(details.category) ? this.renderLoanAdvanceDetail(details, showDeleteButton) : this.renderSourceFundDetail(details, showDeleteButton)}
          </FlexLayout>
          {isEditorOpen || <DistributionRecordError formikProps={formikProps} name={itemBinding} />}
        </FlexLayout>
        <FlexLayout flexDirection="column" justifyContent="center" className={classes.amountCol}>
          <Field //
            aria-label="Amount ($)"
            readOnly={true} // always readonly since user need to click on the editor to modify it
            component={CurrencyInputField}
            fullWidth
            name={isLoanAdvance(details.category) ? this.arrayItemModelKeyAppender(index, 'displayLoanAdvanceAmount') : this.arrayItemModelKeyAppender(index, 'amount')}
            margin="none"
            data-testid={`display-sourceFund-amount-${index}`}
          />
        </FlexLayout>
      </FlexLayout>
    );
  }

  private renderLoanAdvanceDetail(fund: SourceFundFormikModel, showEdit: boolean) {
    const { classes, trustAccountMap, canSetAutoBalanceForIM } = this.props;
    const { trustAccountId, isEditorOpen, amount, reference } = fund;
    const trustAccount = trustAccountMap[trustAccountId];
    const isAutoBalancingForIMElected = !!canSetAutoBalanceForIM && fund.isAutoBalancingSurplusForIMAllowed;

    const accountDescription = trustAccount && trustAccount.bankDetails.accountDescription;
    const accountName = trustAccount && trustAccount.bankDetails.accountName;
    const bankName = trustAccount && trustAccount.bankDetails.bankName;
    const bsb = trustAccount && trustAccount.bankDetails.bsb;
    const accountNumber = trustAccount && trustAccount.bankDetails.accountNumber;

    const descriptionLabel = isAutoBalancingForIMElected ? 'Loan advance available' : 'Loan advance';
    const referenceLabel = reference ? ` (${reference})` : '';
    return (
      <FlexLayout flexDirection="column" justifyContent="center" className={classes.flexGrow}>
        <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
        <Typography>{`${descriptionLabel}${referenceLabel}`}</Typography>
        <Typography>
          {trustAccount && `${bankName} | BSB ${bsb} | Account number ${accountNumber}`}
          {!isAutoBalancingForIMElected && this.renderEditLink(isEditorOpen, showEdit)}
        </Typography>
        {isAutoBalancingForIMElected && (
          <Typography className={classes.explanationDesc}>
            Auto-balancing surplus enabled. Loan advance can be up to {currency(amount)}
            <Typography className={classes.autoSurplusEditLink}>{this.renderEditLink(isEditorOpen, showEdit)}</Typography>
          </Typography>
        )}
      </FlexLayout>
    );
  }

  private renderSourceFundDetail(fund: SourceFundFormikModel, showEdit: boolean) {
    const { classes, trustAccountMap } = this.props;
    const { trustAccountId, isEditorOpen, reference, category, categoryOther, linkedSettlementItem } = fund;

    if (category === CategoryEnum.LinkedSourceFund) {
      return (
        <React.Fragment>
          <Typography className={classes.bold}>
            <IconLinked style={{ verticalAlign: 'bottom', margin: '2px 6px 2px 0px' }} />
            Linked Source Fund
          </Typography>
          <Typography>{reference}</Typography>
          {linkedSettlementItem && linkedSettlementItem.linkedToSympliId && (
            <FlexLayout flexWrap="wrap">
              <LinkedSettlementToParticipantDetail
                matterReference={linkedSettlementItem.linkedToParticipantMatter ?? ''}
                sympliId={linkedSettlementItem.linkedToSympliId ?? ''}
                addresses={linkedSettlementItem.linkedToWorkspaceAddress ?? []}
              >
                {this.renderEditLink(isEditorOpen, showEdit)}
                <ViewLinkedSettlement
                  //
                  linkedToGroupId={linkedSettlementItem.linkedToParticipantGroupId}
                  linkedToParticipantId={linkedSettlementItem.linkedToParticipantId}
                  linkedToWorkspaceId={linkedSettlementItem.linkedToWorkspaceId}
                />
              </LinkedSettlementToParticipantDetail>
            </FlexLayout>
          )}
        </React.Fragment>
      );
    }

    const trustAccount = trustAccountMap[trustAccountId];

    if (!trustAccount) {
      if (!category || (category === 'Other' && !categoryOther)) {
        return <Typography className={classes.newRecordText}>To add a source fund, please enter details below.{this.renderEditLink(isEditorOpen, showEdit)}</Typography>;
      } else {
        return (
          <React.Fragment>
            <Typography className={classes.bold}></Typography>
            <Typography>{category === 'Other' ? categoryOther : category}</Typography>
            <Typography>{this.renderEditLink(isEditorOpen, showEdit)}</Typography>
          </React.Fragment>
        );
      }
    }
    const { accountName, accountDescription } = trustAccount.bankDetails;

    return (
      <React.Fragment>
        <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
        <Typography>
          {category === 'Other' ? categoryOther : category}
          {reference && ` (${reference})`}
        </Typography>
        <Typography>
          {accountDescriptionMapping(trustAccount.bankDetails)}
          {this.renderEditLink(isEditorOpen, showEdit)}
          {this.renderDepositFormLink(fund.depositFormPdfUrl)}
        </Typography>
      </React.Fragment>
    );
  }

  private renderEditLink = (isEditorOpen: boolean, showEdit: boolean) => {
    const { isEditable, isMultipleEditorsOpen } = this.props;
    if (isEditable && !isEditorOpen) {
      const { classes } = this.props;

      return (
        <>
          {showEdit && (
            <ConditionalEditTooltip showTooltip={Boolean(isMultipleEditorsOpen)}>
              <ButtonLink
                data-testid="editButton"
                disabled={isMultipleEditorsOpen}
                icon={<IconEditNew fill={isMultipleEditorsOpen ? colors.NEUTRAL_200 : colors.SYMPLI_GREEN} height={20} width={20} />}
                onClick={this.handleOnEditClick}
                className={classes.editButton}
                color="inherit"
              ></ButtonLink>
            </ConditionalEditTooltip>
          )}
        </>
      );
    }
    return null;
  };

  private renderDepositFormLink = (url: string | undefined) => {
    return url ? (
      <ButtonLink icon={<IconDownloadPdf />} onClick={() => window.open(url)}>
        Deposit form
      </ButtonLink>
    ) : null;
  };

  private handleOnEditClick = () => {
    const { index, formikProps, arrayFieldName } = this.props;
    const { setFieldValue } = formikProps;
    const itemBinding = `${arrayFieldName}.[${index}]`;
    const details: SourceFundFormikModel = _get(formikProps.values, itemBinding);

    setFieldValue(`${this.arrayItemModelKeyAppender(index, 'isEditorOpen')}`, true);
    if (isLoanAdvance(details.category)) {
      setFieldValue(`${this.arrayItemModelKeyAppender(index, 'displayLoanAdvanceAmount')}`, details.amount);
    }
  };
}

export default withStyles(styles)(SourceFund);
