import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import { colors } from 'src/theme';
import { dateTimeLine } from 'src/utils/formatters';

interface Props {
  lodgementDetail: HttpTypes.LodgementDetail;
}

function LodgementCaseStatusTimestamp({ lodgementDetail }: Props) {
  const { dateTime } = lodgementDetail;
  if (!dateTime) {
    return null;
  }

  const { userLocalTime, userTimezoneAbbreviation } = dateTime;

  return (
    <Typography variant="body2" sx={{ color: colors.GREY_40, fontSize: '13px' }}>
      {`${dateTimeLine(userLocalTime, 'd mmm yyyy "at" h:MMTT')} (${userTimezoneAbbreviation})`}
    </Typography>
  );
}

export default React.memo(LodgementCaseStatusTimestamp);
