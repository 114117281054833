import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { useProfile } from 'src/@core/store/reducers/profile';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import { AllDirectionsState, useAllDirections } from 'src/containers/workspace/financial/all-directions/reducer';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { actionFetchLinkedLodgementDetail, actionFetchLinkedSettlementDetail } from 'src/store/actions/workspace';
import { useWorkspaceBasicInfo, WorkspaceBasicInfoState } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail, WorkspaceDetailState } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceDocuments, WorkspaceDocumentsState } from 'src/store/reducers/workspace/workspaceDocuments';
import { useWorkspaceParticipants, WorkspaceParticipantsState } from 'src/store/reducers/workspace/workspaceParticipants';
import { useWorkspaceTasks, WorkspaceTasksState } from 'src/store/reducers/workspace/workspaceTasks';
import { actionFetchFinancialWorkspacesFeedDetailV2 } from './actions';
import { useFinancialWorkspacesFeedV2 } from './reducers/financialWorkspacesFeedV2';
import FinancialTableRowDetail from './table/FinancialTableRowDetail';
import { LastSystemActivityModel } from './table/models';

function FinancialWorkspaceRowContainer({
  //
  workspaceId,
  participantId,
  linkedWorkspaceClusterId,
  linkedWorkspaceType
}: {
  workspaceId: string;
  participantId: string;
  linkedWorkspaceClusterId?: string;
  linkedWorkspaceType?: HttpTypes.LinkedWorkspaceTypeEnum;
}) {
  const dispatch = useSafeDispatch(useDispatch());
  const { items, rowDetailIndex } = useFinancialWorkspacesFeedV2();
  const { userId } = useProfile().data!;
  const workspaceBasicInfoState: WorkspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipantsState: WorkspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const workspaceDetailState: WorkspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState: WorkspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const workspaceTasksState: WorkspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const allDirectionsState: AllDirectionsState = useAllDirections(workspaceId, participantId);

  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const isCriticalRolesEnabled = useFeatureFlag(FeatureToggleEnum.criticalRoles);

  const isFinancialBalanced: boolean | undefined = Number.isInteger(rowDetailIndex) ? items[rowDetailIndex!]?.isFinancialBalanced : undefined;
  const lastSystemActivity: LastSystemActivityModel | undefined = Number.isInteger(rowDetailIndex) ? items[rowDetailIndex!]?.lastSystemActivity : undefined;

  useEffect(() => {
    dispatch(
      actionFetchFinancialWorkspacesFeedDetailV2.request({
        workspaceId,
        participantId
      })
    );
  }, [workspaceId, participantId, dispatch]);

  // if it is linked workspace we will need to fetch the linked details
  React.useEffect(() => {
    if (linkedWorkspaceClusterId === undefined || linkedWorkspaceType === undefined) {
      return;
    }

    if (linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement) {
      dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId: linkedWorkspaceClusterId }));
    }

    if (linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement) {
      dispatch(actionFetchLinkedLodgementDetail.request({ workspaceId, clusterId: linkedWorkspaceClusterId }));
    }
  }, [dispatch, workspaceId, linkedWorkspaceClusterId, linkedWorkspaceType]);

  const linkTo: string = resolveWorkspaceDetailLink({
    workspaceId,
    participantId
  });

  return (
    <FinancialTableRowDetail //
      linkTo={linkTo}
      matterReference={currentWorkspaceParticipant?.reference}
      workspaceBasicInfoState={workspaceBasicInfoState}
      workspaceParticipantsState={workspaceParticipantsState}
      workspaceDetailState={workspaceDetailState}
      workspaceDocumentsState={workspaceDocumentsState}
      workspaceTasksState={workspaceTasksState}
      allDirectionsState={allDirectionsState}
      isFinancialBalanced={isFinancialBalanced}
      lastSystemActivity={lastSystemActivity}
      workspaceId={workspaceId}
      participantId={participantId}
      userId={userId}
      isCriticalRolesEnabled={isCriticalRolesEnabled}
      linkedWorkspaceClusterId={linkedWorkspaceClusterId}
      linkedWorkspaceType={linkedWorkspaceType}
    />
  );
}

export default React.memo(FinancialWorkspaceRowContainer);
