import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { useProfile } from 'src/@core/store/reducers/profile';
import { usePermissionDialog } from 'src/containers/dashboard/shared/hooks/usePermissionDialog';
import { useSafeDispatch } from 'src/hooks';
import { AllocatedUser } from 'src/models';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { getSearchResults, saveAssignee } from './api';
import { UserLookup } from './model';
import WorkspaceStaffAssign from './WorkspaceStaffAssign';

function WorkspaceStaffAssignContainer(props: {
  // route params
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  // info about currently allocated user
  userName: string;
  assignedUserId?: string;
  avatarUrl?: string;
  avatarOnly?: boolean;
  groupId: string;
  assignedGroupName?: string;
  isWorkspaceFinalized?: boolean;
  onAssigneeUpdated(workspaceId: string, participantId: string, selectedSuggestion?: AllocatedUser): void;
}) {
  const dispatch = useSafeDispatch(useDispatch());
  const profile = useProfile().data!;
  const { workspaceId, participantId, workspaceTypeId, assignedUserId, groupId, onAssigneeUpdated, assignedGroupName, isWorkspaceFinalized } = props;
  const isAssignedToCurrentUser = assignedUserId === profile.userId;

  const hasManageInvitationPermission = profile.userPermissions.includes(HttpTypes.UserLevelPermissionEnum.ManageInvitations);
  const dispatchErrorDialog = usePermissionDialog('workspaces');

  const [openPopperTip, setOpenPopper] = React.useState(false);

  const handleOnAvatarClick = React.useCallback(() => {
    if (!hasManageInvitationPermission) {
      dispatchErrorDialog();
      return;
    }

    if (!isWorkspaceFinalized) {
      setOpenPopper(true);
    }
  }, [isWorkspaceFinalized, hasManageInvitationPermission, dispatchErrorDialog]);

  const handleOnPopperClose = React.useCallback(() => {
    setOpenPopper(false);
  }, []);

  const handleOnStaffAssignSubmit = React.useCallback(
    async (selectedSuggestion?: AllocatedUser) => {
      selectedSuggestion = selectedSuggestion
        ? selectedSuggestion
        : {
            //
            userId: profile.userId,
            firstName: profile.firstName,
            lastName: profile.lastName,
            middleName: profile.middleName,
            avatarUrl: profile.avatarUrl
          };

      if (participantId && workspaceId && selectedSuggestion.userId) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: selectedSuggestion.userId !== profile.userId ? 'assign-staff' : 'assign-to-me',
          logGroupId: 'workspace',
          workspaceId,
          participantId,
          workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
        });

        try {
          await saveAssignee([{ userId: selectedSuggestion.userId, participantId, workspaceId }]);
          onAssigneeUpdated(workspaceId, participantId, selectedSuggestion!);
          handleOnPopperClose();
        } catch (error) {
          dispatch(actionCreateGlobalErrorMessage(error));
        }
      }
    },
    [onAssigneeUpdated, handleOnPopperClose, participantId, workspaceId, workspaceTypeId, profile, dispatch]
  );

  const getSuggestions = React.useCallback(
    (inputValue: string): Promise<UserLookup[]> => {
      return getSearchResults(groupId, inputValue);
    },
    [groupId]
  );

  const handleOnStaffUnassigned = React.useCallback(async () => {
    if (participantId && workspaceId) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'unassigned',
        logGroupId: 'workspace',
        workspaceId,
        participantId,
        workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
      });

      try {
        await saveAssignee([{ participantId, workspaceId }]);
        onAssigneeUpdated(workspaceId, participantId, undefined);
        handleOnPopperClose();
      } catch (error) {
        dispatch(actionCreateGlobalErrorMessage(error));
      }
    }
  }, [handleOnPopperClose, participantId, workspaceId, workspaceTypeId, dispatch, onAssigneeUpdated]);

  return (
    <WorkspaceStaffAssign //
      isAssignedToCurrentUser={isAssignedToCurrentUser}
      onStaffAssignSubmit={handleOnStaffAssignSubmit}
      onClick={handleOnAvatarClick}
      getSuggestions={getSuggestions}
      userName={props.userName}
      avatarUrl={props.avatarUrl}
      avatarOnly={props.avatarOnly}
      onPopperClose={handleOnPopperClose}
      openPopperTip={openPopperTip}
      assignedGroupName={assignedGroupName}
      onStaffUnassigned={handleOnStaffUnassigned}
      assignedUserId={props.assignedUserId}
    />
  );
}

export default React.memo(WorkspaceStaffAssignContainer);
