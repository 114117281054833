import { HttpTypes } from '@sympli/api-gateway/types';

export const jurisdictionNameMapping: Record<HttpTypes.JurisdictionsEnum, keyof typeof HttpTypes.JurisdictionsEnum> = {
  [HttpTypes.JurisdictionsEnum.NSW]: 'NSW',
  [HttpTypes.JurisdictionsEnum.VIC]: 'VIC',
  [HttpTypes.JurisdictionsEnum.QLD]: 'QLD',
  [HttpTypes.JurisdictionsEnum.TAS]: 'TAS',
  [HttpTypes.JurisdictionsEnum.WA]: 'WA',
  [HttpTypes.JurisdictionsEnum.ACT]: 'ACT',
  [HttpTypes.JurisdictionsEnum.NT]: 'NT',
  [HttpTypes.JurisdictionsEnum.SA]: 'SA'
};

// const allJurisdictionList = [
//   HttpTypes.JurisdictionsEnum.NSW,
//   HttpTypes.JurisdictionsEnum.VIC,
//   HttpTypes.JurisdictionsEnum.QLD,
//   HttpTypes.JurisdictionsEnum.TAS,
//   HttpTypes.JurisdictionsEnum.WA,
//   HttpTypes.JurisdictionsEnum.ACT,
//   HttpTypes.JurisdictionsEnum.NT,
//   HttpTypes.JurisdictionsEnum.SA
// ];
// const supportedJurisdictionList = [HttpTypes.JurisdictionsEnum.NSW, HttpTypes.JurisdictionsEnum.VIC, HttpTypes.JurisdictionsEnum.QLD];
// export const jurisdictionOptions: Array<LookupEnumModel<number>> = supportedJurisdictionList.map(jurisdiction => ({
//   id: jurisdiction,
//   name: jurisdictionNameMapping[jurisdiction]
// }));

// export const supportedJurisdictionStateOptions: Array<LookupEnumModel<string>> = supportedJurisdictionList.map(jurisdiction => {
//   const name = jurisdictionNameMapping[jurisdiction];
//   return {
//     id: name,
//     name
//   };
// });

// export const allJurisdictionStateOptions: Array<LookupEnumModel<string>> = allJurisdictionList.map(jurisdiction => {
//   const name = jurisdictionNameMapping[jurisdiction];
//   return {
//     id: name,
//     name
//   };
// });

// export interface JurisdictionOptionModel {
//   id: number;
//   isActive: boolean;
//   isSelected: boolean;
//   name: string;
// }
