import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { testValueReuseInArray } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { CaveatModel_2_21_2 } from '../../models';

type FormValues = CaveatModel_2_21_2;
// run party data validation only if party field edit is not disabled
const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

const yupContactItem = yup
  .object<TenancyPartyModel>()
  .partial({
    partyBookId: yup // reference to party store
      .string()
      .default('')
      .nullable(true)
      .required(msg.REQUIRED)
      .test(
        'PARTY ALREADY SELECTED CHECK',
        'Cannot have multiple contacts with the same name',
        testValueReuseInArray(
          (model: FormValues) => model.contactDetails,
          (contact: TenancyPartyModel) => contact.partyBookId
        )
      )
      .test(
        createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG, {
          shouldValidatePartyData
        })
      ),
    addressBookId: yup // reference to address store
      .string()
      .default('')
      .required(msg.REQUIRED)
      .defined()
  })
  .defined();

// path: detailsForServiceOfNotice
const yupSchema = yup
  .array<TenancyPartyModel>() //
  .of<TenancyPartyModel>(yupContactItem)
  .min(1, msg.MIN_ITEMS(1))
  .max(2, msg.MAX_ITEMS(2))
  .defined();

export default yupSchema;
