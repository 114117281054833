import React from 'react';

import { FormikProps, setIn } from 'formik';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { BsbLookupApiResponse } from '@sympli/ui-framework/components/formik/bank-account-field';
import { DeleteButtonProps } from '@sympli/ui-framework/components/formik/buttons/delete-button';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { actionFetchDirections, actionUpdateDirectionsWorkForm } from 'src/containers/workspace/financial/directions/actions';
import { ParticipantSettingApiResponse } from 'src/containers/workspace/shared/detail/models';
import { actionFetchWorkspaceById } from 'src/store/actions/workspace';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { deleteWorkspaceDirectionLineItem, updateWorkspaceDirectionLineItem } from '../../api';
import { DeleteWorkspaceDirectionLineItemRequestModel, DistributionFormikModel, TrustAccountMap, UpdateWorkspaceDirectionLineItemRequestModel } from '../../models';
import DeleteLinkedSettlementDetailContainer from './components/delete-linked-settlement-detail';
import { bpayCheck, bsbCheck } from './components/direction-payee-detail/api';
import { BpayCheckApiResponse } from './components/direction-payee-detail/models';
import { HoldingAccountDistribution, TrustAccountDistribution } from './components/direction-record/models';
import { UnlinkLineItemTypeEnum } from './models';

import type { SafeDispatch } from 'src/hooks';

export class CheckHelper {
  static bsbCheckTable: { [key: string]: Promise<BsbLookupApiResponse> } = {};
  static bpayNumberCheckTable: { [key: string]: Promise<BpayCheckApiResponse> } = {};

  static checkBsb(bsb: string) {
    if (!CheckHelper.bsbCheckTable[bsb]) {
      CheckHelper.bsbCheckTable[bsb] = bsbCheck(bsb)
        .then(response => {
          return response;
        })
        .catch(error => {
          // TODO improvement check error type, if it is not 400 or 404, add a flag to this promise to trigger refetch for this bsb
          // TODO only cache fetch error 404/400
          throw error;
        });
    }
    return CheckHelper.bsbCheckTable[bsb];
  }

  static checkBillerCode(workspaceId: string, billerCode: string) {
    const key = workspaceId + billerCode;
    if (!CheckHelper.bpayNumberCheckTable[key]) {
      CheckHelper.bpayNumberCheckTable[key] = bpayCheck(workspaceId, billerCode)
        .then(response => {
          return response;
        })
        .catch(error => {
          // TODO improvement check error type, if it is not 400 or 404, add a flag to this promise to trigger refetch for this bsb
          // TODO only cache fetch error 404/400
          throw error;
        });
    }
    return CheckHelper.bpayNumberCheckTable[key];
  }
}

export async function saveFSSLineItem(
  payload: UpdateWorkspaceDirectionLineItemRequestModel,
  dispatch: SafeDispatch<Action>,
  isOnlyOpenEditor: boolean,
  linkedToParticipantMatterReference?: string
) {
  try {
    const { workspaceId, participantId } = payload;
    const res = await updateWorkspaceDirectionLineItem(payload);
    // linked settlement has a different toast message and action
    if (res?.linkedSettlementWorkspacesDetail) {
      handleLinkedSuccessful(dispatch, res.linkedSettlementWorkspacesDetail, workspaceId, participantId);
    }

    if (isOnlyOpenEditor) {
      /* 
        fetch the whole directions when saving is successful. And it's the only open editor
        This solves two problems:
        1. reset forms so when people leave the page, they don't see unsaved change alert
        2. newly saved item will now have an id, so it can be deleted
      */
      dispatch(actionFetchDirections.request({ workspaceId: payload.workspaceId, participantId: payload.participantId }));
    } else {
      // if there are other open editors, it's always dirty and has unsaved data.
      // we need to update redux store with latest data from backend.
      dispatch(actionUpdateDirectionsWorkForm(res));
      // TODO setup the saved line item's Id, this will be done in next stage when backend supports single line item saving.
    }
    return true; // success returns true
  } catch (error) {
    const scope = Logger.scopeWithCustomAttributes(payload);
    Logger.captureException(new Error('Saving FSS inline item failed'), scope);

    // if linked settlement we have a different display error message, otherwise the generic one should be displayed
    const { requestPayload } = payload;
    const isLinkedItemAction =
      requestPayload.direction?.paymentMethod === HttpTypes.PaymentMethodEnum.Linked || requestPayload.sourceFund?.paymentMethod === HttpTypes.PaymentMethodEnum.Linked;

    if (isLinkedItemAction) {
      displayLinkedErrorToastMessage(dispatch, error?.response.status, error?.response.data.message, linkedToParticipantMatterReference);
    }
    return false; // failure returns false
  }
}

export function handleLinkedSuccessful(
  //
  dispatch: SafeDispatch<Action>,
  linkedSettlementWorkspacesDetail: HttpTypes.LinkedSettlementWorkspacesDetail,
  workspaceId: string,
  participantId: string
) {
  const { numberOfLinkedWorkspaces, linkedToParticipantMatterReference } = linkedSettlementWorkspacesDetail;
  dispatch(
    actionOpenGlobalSimpleNotification({
      message: (
        <>
          <div className="font-[700] text-[14px]">Linked workspace</div>
          <div className="font-[400] text-[14px] pt-[8px]">
            This Workspace has been linked to "<b>{linkedToParticipantMatterReference}</b>". This Workspace <b>has been linked</b> with {numberOfLinkedWorkspaces} Workspaces, and
            will require the same <b>Settlement Date & Time</b>.
          </div>
          <div className="font-[400] text-[14px] pt-[8px]  pb-[12px]">
            Note that you need to <b>make your own arrangements</b> with other participants in order to <b>align Settlement Date & Time</b> between Linked Workspaces.
          </div>
        </>
      ),
      variant: 'new-success',
      autoHideDuration: 5000
    })
  );
  // Refetch the workspace detail data after creating linked line-item
  dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
}

export function displayLinkedErrorToastMessage(
  //
  dispatch: SafeDispatch<Action>,
  status?: number,
  errorMessage?: string,
  linkedToParticipantMatterReference?: string
) {
  // if backend does not return message or generic internal server error replace with right message
  // and tickleApi policy will block if user does not has the save financial permission so explicitly set the message
  const defaultMessage = 'we have encountered technical issues, please refresh the page and try again';
  if (errorMessage === undefined) {
    if (status === 403) {
      errorMessage = 'the user does not have Edit or Save Financials permission';
    } else {
      errorMessage = defaultMessage;
    }
  } else if (errorMessage.toLowerCase() === 'internal server error') {
    errorMessage = defaultMessage;
  }

  dispatch(
    actionOpenGlobalSimpleNotification({
      message: (
        <>
          <div className="font-[700] text-[14px]">Could not Link workspace</div>
          <div className="font-[400] text-[14px] pt-[8px]  pb-[12px]">
            We were unable to link to workspace "<b>{linkedToParticipantMatterReference}</b>" because {errorMessage}
          </div>
        </>
      ),
      variant: 'new-error',
      autoHideDuration: 5000
    })
  );
}

/**
 * This function will loop through a complex object or array, to see
 * for the give key, return how many times the value will match.
 */
export function getKeyValueMatchingNumberInObject(obj: object | Array<any>, key: string, value: string | boolean | number): number {
  let number = 0;

  const callback = (acc: number, currentValue: any) => {
    if (Array.isArray(currentValue) || (typeof currentValue === 'object' && currentValue != null)) {
      return acc + getKeyValueMatchingNumberInObject(currentValue, key, value);
    }
    return acc;
  };

  if (Array.isArray(obj)) {
    number = number + obj.reduce(callback, number);
  } else if (typeof obj === 'object') {
    for (const [k, v] of Object.entries(obj)) {
      if (Array.isArray(v) || (typeof v === 'object' && v != null)) {
        number = number + getKeyValueMatchingNumberInObject(v, key, value);
      } else if (k === key && v === value) {
        number++;
      }
    }
  }

  return number;
}

export function displayToastMessage(isSuccess: boolean, dispatch: SafeDispatch<Action>, successMessage?: React.ReactNode) {
  dispatch(
    actionOpenGlobalSimpleNotification({
      message: isSuccess ? (
        successMessage || 'Financial line item has been saved'
      ) : (
        <>
          <b>Something went wrong.</b> We have encountered Technical Issues, please try again
        </>
      ),
      variant: isSuccess ? 'new-success' : 'new-warning',
      autoHideDuration: 5000
    })
  );
}

export function isToggleBillingMethodEnabled(detail: ParticipantSettingApiResponse | undefined) {
  if (detail == null || detail.billingSetting == null) {
    return false;
  }
  const isBillingMethodChangeableByUser = detail.billingSetting.isBillingMethodChangeableByUser;
  return isBillingMethodChangeableByUser;
}

// ToDo: [Tech Debt] WEB-24105 Use category id in future
// Once we have the Id in the SelectField then remove this mapping
export const categoryUsageMappings = new Map<string, string>([
  ['Buyer’s Agent Commission', 'Buyers agent commission'],
  ['Professional Fees', 'Professional fees'],
  ['Customer Loan Account', 'Customer loan account'],
  ['Third Party Beneficiary', 'Third Party Beneficiary']
]);

export const usageRoleGroupMappings = new Map<HttpTypes.WorkspaceRoleEnum, string>([
  [HttpTypes.WorkspaceRoleEnum.Purchaser, 'Acting as purchaser'],
  [HttpTypes.WorkspaceRoleEnum.Vendor, 'Acting as vendor'],
  [HttpTypes.WorkspaceRoleEnum.DischargingMortgagee, 'Acting as discharging mortgagee']
]);

export function getGeneralAccountsLookup(financialAccounts: HttpTypes.FinancialAccountsApiResponse | undefined, category: string): LookupEnumModel<string>[] {
  if (!financialAccounts) return [];
  return financialAccounts
    .filter(item => item.accountType.value === HttpTypes.SubscriberBankAccountTypeEnum.General && categoryUsageMappings.has(category))
    .map(item => ({
      id: item.id,
      name: `${item.accountName} | BSB ${item.bsbNumber} | Acc ${item.accountNumber}`
    }));
}

export function getDefaultAccountId(category: string, workspaceRole?: HttpTypes.WorkspaceRoleEnum, usage?: HttpTypes.GeneralAccountsUsagesApiResponse): string | undefined {
  if (!usage) return undefined;
  const map = categoryUsageMappings.get(category);
  let usages = usage.filter(item => item.description === map && item.existingDefault);

  if (workspaceRole) {
    const group = usageRoleGroupMappings.get(workspaceRole);
    usages = usages.filter(item => item.group === group);
  }

  if (!usages?.length) return undefined;

  return usages[0].existingDefault!.accountId;
}

export function getBankDetails(accountId: string, financialAccounts: HttpTypes.FinancialAccountsApiResponse): HttpTypes.FinancialAccountsApiResponse[number] | undefined {
  return financialAccounts.find(account => account.id === accountId);
}

// we need to do this mapping as if it is loan account/trust account
// the data will be returned from backend as a separate mapping
export async function updateTrustAccountDistributionMapping(
  //
  formikProps: FormikProps<{ distributions: Array<DistributionFormikModel> }>,
  trustAccountMap: TrustAccountMap,
  accountId: string,
  itemFieldName: string,
  workspaceId: string,
  participantId: string,
  dispatch: SafeDispatch<Action>,
  openEditor?: boolean,
  savingFssLineItem?: boolean,
  distribution?: TrustAccountDistribution | HoldingAccountDistribution,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  let res: boolean = true;
  if (!openEditor && savingFssLineItem && distribution) {
    const { paymentMethod, amount, directionCategory, category, categoryOther, id } = distribution;
    if (setIsLoading) {
      setIsLoading(true);
    }
    res = await saveFSSLineItem(
      {
        workspaceId,
        participantId,
        requestPayload: {
          type: HttpTypes.DirectionTypeEnum.Direction,
          direction: {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id,
            bankAccountId: paymentMethod === HttpTypes.PaymentMethodEnum.TrustAccount ? distribution.bankAccountId : undefined,
            bankDetails: paymentMethod === HttpTypes.PaymentMethodEnum.TrustAccount ? distribution.bankDetails : undefined,
            holdingAccountDetails: paymentMethod === HttpTypes.PaymentMethodEnum.HoldingAccount ? distribution.holdingAccountDetails : undefined
          }
        }
      },
      dispatch,
      getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) === 1
    );
    if (setIsLoading) {
      setIsLoading(false);
    }
    displayToastMessage(res, dispatch);
  }

  let { setValues, values: newValues } = formikProps;
  const trustAccount = trustAccountMap[accountId];
  const fieldName = createModelKeyAppender<DistributionFormikModel>(itemFieldName);
  const bankDetailsFieldName = createModelKeyAppender<HttpTypes.BankDetailsModel>(fieldName('bankDetails'));

  newValues = setIn(newValues, bankDetailsFieldName('bankName'), trustAccount.bankDetails.bankName);
  newValues = setIn(newValues, bankDetailsFieldName('accountName'), trustAccount.bankDetails.accountName);
  newValues = setIn(newValues, bankDetailsFieldName('accountDescription'), trustAccount.bankDetails.accountDescription);
  newValues = setIn(newValues, bankDetailsFieldName('bsb'), trustAccount.bankDetails.bsb);
  newValues = setIn(newValues, bankDetailsFieldName('accountNumber'), trustAccount.bankDetails.accountNumber);

  if (!openEditor && res) {
    // when api call is failure, we don't want to close the editor
    newValues = setIn(newValues, fieldName('isEditorOpen'), false);
  }

  setValues(newValues);
}

export const getConfirmationDialogProps = (
  id: string,
  className: string,
  isLoading: boolean,
  workspaceId: string,
  participantId: string,
  setLoading: () => void,
  stopLoading: () => void,
  dispatch: SafeDispatch<Action>,
  successMessage: React.ReactNode,
  unlinkLineItemType: UnlinkLineItemTypeEnum,
  titleClassName?: string,
  where?: 'bin icon' | 'unlink button' //only for NewRelics analytics
) => {
  const confirmationAsyncClose = async (value?: boolean) => {
    if (value && workspaceId && participantId && id) {
      setLoading();

      const isLinkedSettlementItem = unlinkLineItemType !== UnlinkLineItemTypeEnum.Other;

      if (isLinkedSettlementItem) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'linked-settlement',
          logGroupId: 'workspace',
          action: 'unlink-linked-settlement',
          workspaceId,
          participantId,
          where: where ?? 'bin icon'
        });
      }

      const payload: DeleteWorkspaceDirectionLineItemRequestModel = {
        workspaceId,
        participantId,
        directionItemId: id!,
        isLinkedSettlementItem
      };

      try {
        const res = await deleteWorkspaceDirectionLineItem(payload);
        /* only update redux store when delete is successful, this will make left side calculator gets update:
          we cannot fetch data again because this will cause unsaved editors gone.
        */
        dispatch(actionUpdateDirectionsWorkForm(res));

        stopLoading();

        displayToastMessage(true, dispatch, successMessage);

        if (isLinkedSettlementItem) {
          // Refetch the workspace detail data after creating linked line-item
          dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
        }

        return true;
      } catch (error) {
        const scope = Logger.scopeWithCustomAttributes(payload);
        Logger.captureException(new Error('deleting a FSS inline item failed'), scope);

        // 500 or not message show the default one, otherwise show the one from the server
        const defaultErrorMsg = 'we have encountered technical issues, please refresh the page and try again';

        if (Number(error?.response?.status) !== 500) {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: error?.response?.data?.message ?? defaultErrorMsg,
              variant: 'new-warning',
              autoHideDuration: 5000
            })
          );
        } else {
          dispatch(
            actionOpenGlobalSimpleNotification({
              message: defaultErrorMsg,
              variant: 'new-warning',
              autoHideDuration: 5000
            })
          );
        }
      }
      return false;
    }
  };

  if (unlinkLineItemType === UnlinkLineItemTypeEnum.Other) {
    const confirmProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> = {
      confirmBeforeDelete: true,
      confirmationContent: <Typography>This action will delete the financial line item.</Typography>,
      confirmationAsyncClose,
      confirmationProps: {
        title: (
          <FlexLayout flexDirection="row" alignItems="center">
            <IconDelete className={className} />
            <span>Delete line item</span>
          </FlexLayout>
        ),
        color: 'error',
        buttonArrow: true,
        okButtonText: 'Delete',
        isLoading
      }
    };

    return confirmProps;
  }

  const confirmProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> = {
    confirmBeforeDelete: true,
    confirmationContent: (
      <DeleteLinkedSettlementDetailContainer //
        workspaceId={workspaceId}
        participantId={participantId}
        unlinkLineItemType={unlinkLineItemType}
      />
    ),
    confirmationAsyncClose,
    confirmationProps: {
      title: (
        <FlexLayout flexDirection="row" alignItems="center">
          <div>Unlink workspace</div>
        </FlexLayout>
      ),
      color: 'error',
      okButtonText: 'Unlink',
      isLoading,
      maxWidth: 'md',
      className: titleClassName
    }
  };

  return confirmProps;
};
