import * as React from 'react';

import { Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft } from '@sympli/ui-framework/icons';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import Header from 'src/@core/components/typography/header';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { actionFetchDirectionsList } from 'src/@core/store/actions/directionsList';
import Formik from 'src/components/formik';
import { resolveDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import WorkflowPanel from 'src/containers/workspace/shared/components/workflow-panel';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { modelKey } from 'src/utils/formUtils';
import withRedirectRouter, { RouteRedirectProps } from 'src/utils/withRedirectRouter';
import { actionUpdateStampDutyDetails } from '../../actions';
import ActionButtons from '../../components/action-buttons';
import StampDutyDocumentList from '../../components/stamp-duty-document-list';
import TransactionStatus from '../../components/transaction-status';
import TransferDetails from '../../components/transfer-details';
import { OsrCaseStatusDisplayMapping, resolveVerificationTimestampDescription, TransactionCreatedOsrCaseStatus } from '../../helpers';
import { OsrCaseStatusEnum, StampDutyApiResponse, StampDutyProgressStatusEnum, VerifyStampDutyApiResponse } from '../../models';
import ProgressMessageNotification from '../components/progress-message-notification';
import { cancelTransaction } from './api';
import { VicStampDutyFormDataModel } from './models';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

interface OwnProps {
  queryParams: WorkspaceDetailRouteParams;
  detail: StampDutyApiResponse;
  dispatch: Dispatch<Action>;
  isLocked: boolean;
}

interface State {
  isWorkflowLoading: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys> & RouteRedirectProps;

const fieldName = modelKey<VicStampDutyFormDataModel>();

class VicStampDuty extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false // make it consistent with other states, the function for this is controlling the cancel loading and top bar button click since it is not part of the form
  };
  private formikProps: FormikProps<VicStampDutyFormDataModel>;

  private getInitialValue = (): VicStampDutyFormDataModel => {
    const { detail } = this.props;
    return { referenceNumber: detail.referenceNumber };
  };

  /*
   if workspace is locked or osr case statuses are cancelled, finalise or completed
   or there is no reference number we should hide the cancel button
  */
  private get showCancelOperationButton() {
    const { detail, isLocked } = this.props;
    const { osrCaseStatus, referenceNumber } = detail;

    if (isLocked || !referenceNumber || osrCaseStatus == null) {
      return false;
    }

    if (osrCaseStatus !== OsrCaseStatusEnum.Cancelled && osrCaseStatus !== OsrCaseStatusEnum.Finalised && osrCaseStatus !== OsrCaseStatusEnum.Completed) {
      return true;
    }

    return false;
  }

  private get isLoading() {
    return this.formikProps?.isSubmitting || this.state.isWorkflowLoading;
  }

  // reference number (transaction id) is mandatory for vic sro,
  // first step is to create the transaction id and then can do multiple times
  // verification
  render() {
    const {
      classes,
      detail,
      queryParams: { workspaceId, participantId }
    } = this.props;
    const action = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/stampduties`;

    return (
      <FlexLayout flexDirection="column" fullWidth className={classes.rootV2}>
        <ProgressMessageNotification jurisdictionId={HttpTypes.JurisdictionsEnum.VIC} detail={detail} />
        <WorkflowPanel mode="light">
          {this.renderVerificationTimestamp()}
          {this.renderSubmitButton()}
        </WorkflowPanel>
        <WorkspacePageContentWrapper className={undefined}>
          <Formik //
            method="post"
            action={action}
            getInitialValues={this.getInitialValue}
            validationSchema={getValidationSchema}
            onPreSubmit={this.handleOnPreSubmit}
            onPostSubmit={this.handleOnPostSubmit}
          >
            {(formikProps: FormikProps<VicStampDutyFormDataModel>) => this.renderForm(formikProps)}
          </Formik>
        </WorkspacePageContentWrapper>
      </FlexLayout>
    );
  }

  private renderVerificationTimestamp() {
    const { classes, detail } = this.props;
    const description = resolveVerificationTimestampDescription(detail);
    if (!description) {
      return null;
    }
    return <Typography className={classes.timestamp}>{description}</Typography>;
  }

  private renderForm(formikProps: FormikProps<VicStampDutyFormDataModel>) {
    this.formikProps = formikProps;

    return (
      <Form>
        <Header>Stamp Duty</Header>
        {this.renderStampDutyCaseReference()}
        {this.renderTransferDetail()}
        {this.renderActionButton()}
      </Form>
    );
  }

  private referenceNumberLabelledBy: string = _uniqueId();

  /**
   * if transaction id is not created, show create button and disabled it once clicked
   * disabled the button if the creating is in progress since it is async progress
   */
  private renderStampDutyCaseReference() {
    const { classes, detail } = this.props;
    const { status, canVerify } = detail;
    const isCancelButtonDisabled = status === StampDutyProgressStatusEnum.CancellingTransaction || status === StampDutyProgressStatusEnum.Verifying;
    const isCreateEnabled = status === StampDutyProgressStatusEnum.ReadyForTransactionCreation || status === StampDutyProgressStatusEnum.TransactionCreationError;
    return (
      <FlexLayout alignItems="center" className={classes.transactionIdContainer}>
        <Typography id={this.referenceNumberLabelledBy} className={classes.labelMarginRight}>
          Stamp Duty case reference:{' '}
        </Typography>
        <Field //
          aria-labelledby={this.referenceNumberLabelledBy}
          name={fieldName('referenceNumber')}
          component={InputField}
          className={classes.transactionIdField}
          disabled
        />
        {isCreateEnabled && (
          <SympliButton
            onClick={this.handleFormSubmit}
            arrowRight
            disabled={this.isLoading || !canVerify} // we should re-visit the backend logic, if cannot verify we should not setup status to creatingTransaction
            isLoading={this.isLoading}
            color="primary"
            variant="contained"
          >
            Create case reference
          </SympliButton>
        )}
        {this.showCancelOperationButton && (
          <SympliButton onClick={this.cancelTransaction} disabled={isCancelButtonDisabled || this.isLoading} isLoading={this.isLoading} color="primary" variant="outlined">
            Cancel
          </SympliButton>
        )}
      </FlexLayout>
    );
  }

  private renderTransferDetail() {
    const { classes, queryParams, detail } = this.props;
    return (
      <FormGroup title="Transfer details" classes={{ title: classes.formGroupTitle }}>
        {this.renderTransactionId()}
        {this.renderTransferDetails()}
        <StampDutyDocumentList items={detail.stampDutyDocuments} queryParams={queryParams} />
      </FormGroup>
    );
  }

  private renderActionButton() {
    const { classes } = this.props;
    return (
      <FlexLayout justifyContent="space-between" className={classes.formSubmitButtonContainer}>
        <ButtonLink onClick={this.handleOnBackClick} icon={<IconCaretLeft width="18" height="18" />} color="inherit">
          Cancel
        </ButtonLink>
        {this.renderSubmitButton()}
      </FlexLayout>
    );
  }

  private renderSubmitButton = () => {
    const { detail, queryParams, isLocked } = this.props;
    const disableVerify = !detail.canVerify || isLocked || this.isLoading;
    const { referenceNumber } = detail;

    if (!referenceNumber) {
      return null;
    }

    const link = resolveDirectionsLink(queryParams);

    return <ActionButtons stampDutyStatus={detail.status} onClick={this.handleFormSubmit} href={link} disabled={disableVerify} loading={this.isLoading} />;
  };

  private renderTransactionId() {
    const { detail } = this.props;
    const { status, referenceNumber, assessmentNumber, osrCaseStatus, elnoLodgementCaseId, earliestSroSettlementDate } = detail;
    const osrCaseStatusDescription = osrCaseStatus != null ? OsrCaseStatusDisplayMapping[osrCaseStatus] : undefined;

    switch (status) {
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.TransactionCancellationError:
        return osrCaseStatus != null && TransactionCreatedOsrCaseStatus.includes(osrCaseStatus) ? (
          <TransactionStatus
            referenceNumber={referenceNumber}
            subStatus={osrCaseStatusDescription}
            elnoLodgementCaseId={elnoLodgementCaseId}
            earliestSroSettlementDate={earliestSroSettlementDate}
          />
        ) : (
          <TransactionStatus referenceNumber={referenceNumber} elnoLodgementCaseId={elnoLodgementCaseId} />
        );
      case StampDutyProgressStatusEnum.Verifying:
        return (
          <TransactionStatus
            referenceNumber={referenceNumber}
            subStatus={osrCaseStatusDescription}
            elnoLodgementCaseId={elnoLodgementCaseId}
            earliestSroSettlementDate={earliestSroSettlementDate}
          />
        );
      case StampDutyProgressStatusEnum.Verified:
        return (
          <TransactionStatus
            assessmentNumber={assessmentNumber}
            referenceNumber={referenceNumber}
            subStatus={osrCaseStatusDescription}
            elnoLodgementCaseId={elnoLodgementCaseId}
            verified
          />
        );
      case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
        return <TransactionStatus subStatus={osrCaseStatusDescription} />;
      case StampDutyProgressStatusEnum.VerificationError:
        return <TransactionStatus referenceNumber={referenceNumber} />;
      default:
        return null;
    }
  }

  private renderTransferDetails() {
    const { detail } = this.props;
    const { consideration, dutyAmount, payableDuty, referenceNumber, assessmentNumber, elnoLodgementCaseId } = detail;
    switch (detail.status) {
      case StampDutyProgressStatusEnum.ReadyForTransactionCreation:
      case StampDutyProgressStatusEnum.PreparingDocument:
      case StampDutyProgressStatusEnum.CreatingTransaction:
      case StampDutyProgressStatusEnum.TransactionCreationError:
      case StampDutyProgressStatusEnum.CancellingTransaction:
        return <TransferDetails items={[{ label: 'Consideration ($)', value: consideration }]} />;
      case StampDutyProgressStatusEnum.TransactionCreated:
      case StampDutyProgressStatusEnum.Verifying:
      case StampDutyProgressStatusEnum.Verified:
      case StampDutyProgressStatusEnum.VerificationError:
      case StampDutyProgressStatusEnum.TransactionCancellationError:
      case StampDutyProgressStatusEnum.Paid:
        return (
          <TransferDetails
            items={[
              { label: 'Consideration ($)', value: consideration },
              { label: 'Stamp Duty ($)', value: dutyAmount },
              { label: 'Payable duty ($)', value: payableDuty }
            ]}
          />
        );
      default:
        const scope = Logger.scopeWithCustomAttributes({
          referenceNumber,
          assessmentNumber,
          elnoLodgementCaseId
        });
        Logger.captureException(new InvalidDataError('Unsupported status', detail.status), scope);
        return null;
    }
  }

  private handleFormSubmit = () => {
    this.formikProps && this.formikProps.submitForm();
  };

  private handleOnPreSubmit = (values: VicStampDutyFormDataModel): VicStampDutyFormDataModel => {
    this.setState({ isWorkflowLoading: true });
    return values;
  };

  private cancelTransaction = () => {
    const { detail, queryParams, dispatch } = this.props;
    const { workspaceId, participantId } = queryParams;
    this.setState({ isWorkflowLoading: true });
    cancelTransaction(workspaceId, participantId, detail.referenceNumber).then(res => {
      this.setState({ isWorkflowLoading: false });
      batch(() => {
        dispatch(actionUpdateStampDutyDetails({ ...res }));
        dispatch(actionFetchDirectionsList.request(queryParams));
      });
    });
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<VicStampDutyFormDataModel, VerifyStampDutyApiResponse>) => {
    this.setState({ isWorkflowLoading: false });
    if (!args.error) {
      batch(() => {
        this.props.dispatch(actionUpdateStampDutyDetails({ ...args.response }));
        this.props.dispatch(actionFetchDirectionsList.request(this.props.queryParams));
      });
    }
  };

  private handleOnBackClick = () => {
    const { queryParams } = this.props;
    this.props.navigate(resolveWorkspaceDetailLink(queryParams));
  };
}

const styledComponent = withStyles(styles)(withRedirectRouter(VicStampDuty));
export default styledComponent;
