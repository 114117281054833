import * as React from 'react';

import classNames from 'classnames';

import { HttpTypes } from '@sympli/api-gateway/types';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import ChipInputField from '@sympli/ui-framework/components/formik/chip-input-field';
import Field from '@sympli/ui-framework/components/formik/field';

import { resolveTitlePlaceholder } from 'src/containers/dashboard/shared/helpers';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import ActiveWorkspaceAccordion from './components/active-workspace-accordion';
import { FinancialProps, StandaloneProps } from './components/active-workspace-accordion/ActiveWorkspaceAccordionContainer';
import DuplicateWorkspaces, { DuplicateWorkspaceItem } from './components/duplicate-workspaces';
import { ActiveWorkspaceItem } from './components/search-join-table/models';
import TitleList, { TitleListItem } from './components/title-list';
import { VerifyPropertyContextEnum, VerifyTitleFormModel } from './models';
import { useStyles } from './styles';

interface Props {
  name?: string;
  variant?: VerifyPropertyContextEnum;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  disableVerifyButton: boolean;
  showLoader: boolean;
  isFormValid: boolean;
  titles: TitleListItem[];
  duplicateWorkspaces?: DuplicateWorkspaceItem[] | ActiveWorkspaceItem[];
  inputRef?: React.Ref<HTMLInputElement>;
  onVerifyClick(event: React.MouseEvent<HTMLElement>): void;
  onDeleteClick(e: React.MouseEvent<HTMLButtonElement>, referenceNumber: string | null): void;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  // only used for Financial workspace search and join feature
  groupId?: string;
  role?: HttpTypes.WorkspaceRoleEnum;
  userPermissions?: HttpTypes.UserProfileModel['userPermissions']; //
  subscriberId?: string;
  matter?: string;
  onClose: () => void;
}

function VerifyPropertySection({
  //
  name,
  variant = VerifyPropertyContextEnum.WorkspaceCreation,
  jurisdictionId,
  disableVerifyButton,
  showLoader,
  titles,
  duplicateWorkspaces,
  inputRef,
  isFormValid,
  onVerifyClick,
  onDeleteClick,
  workspaceTypeId,
  groupId,
  role,
  userPermissions,
  subscriberId,
  matter,
  onClose
}: Props) {
  const classes = useStyles();
  const placeholder = resolveTitlePlaceholder(jurisdictionId);
  const fieldName = name === undefined ? modelKey<VerifyTitleFormModel>() : createModelKeyAppender<VerifyTitleFormModel>(name);
  const description =
    variant === VerifyPropertyContextEnum.WorkspaceCreation
      ? 'You can verify up to 20 titles separated by commas or spaces'
      : 'You can add a maximum of 20 titles to a workspace. Separate multiple titles with commas or spaces.';

  return (
    <FormGroup //
      title="Verify title references"
      description={description}
      direction="column"
      classes={{
        columnTitleContainer: classes.columnTitleContainer,
        description: variant === VerifyPropertyContextEnum.WorkspaceCreation ? classes.createWorkspaceDescription : classes.addTitlesDescription
      }}
    >
      {({ titleId }) => (
        <>
          <Field //
            component={ChipInputField}
            className={classNames(classes.chipInputFullWidth, groupId && 'w-[1000px]')}
            name={fieldName('titleChips')}
            resolvedValueName={fieldName('titleInputValue')}
            placeholder={placeholder}
            disableSubmit={disableVerifyButton}
            showLoader={showLoader}
            onButtonClick={onVerifyClick}
            aria-labelledby={titleId}
            inputRef={inputRef}
            multilineOfChips={true}
          />
          <TitleList //
            titles={titles}
            onDeleteTitleClick={onDeleteClick}
            isFormValid={isFormValid}
            deleteDisabled={showLoader}
          />
          {groupId ? (
            <ActiveWorkspaceAccordion
              activeWorkspaces={duplicateWorkspaces as ActiveWorkspaceItem[]}
              isLoading={showLoader}
              groupId={groupId}
              {...(workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly
                ? ({
                    workspaceType: HttpTypes.WorkspaceTypeEnum.RegistrationOnly
                  } satisfies StandaloneProps)
                : ({
                    workspaceType: workspaceTypeId as HttpTypes.WorkspaceTypeEnum.FinancialSettlement, //TODO remove this once api-gateway is updated.
                    role: role!,
                    userPermissions: userPermissions!,
                    subscriberId: subscriberId!,
                    matter: matter!
                  } satisfies FinancialProps))}
              onClose={onClose}
            />
          ) : (
            <DuplicateWorkspaces duplicateWorkspaces={duplicateWorkspaces as DuplicateWorkspaceItem[]} />
          )}
        </>
      )}
    </FormGroup>
  );
}

export default React.memo(VerifyPropertySection);
