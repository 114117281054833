// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2081751114/linked+workspace+list+-+data+dependency+insights

import FormHelperText from '@mui/material/FormHelperText';

import { useProfile } from 'src/@core/store/reducers/profile';
import LinkedWorkspaceListLoader from './components/linked-workspace-list-loader';
import LinkedWorkspaceList from './LinkedWorkspaceList';
import { LinkedWorkspaceListItem } from './models';
import { useLinkedWorkspaces } from './reducer';
import { resolveComponentItemsSelector } from './selectors';

interface Props {
  workspaceId: string;
  // other
  collapsedByDefault?: boolean;
}

function LinkedWorkspaceListContainer({ workspaceId, collapsedByDefault }: Props) {
  const { isLoading, error, items } = useLinkedWorkspaces(workspaceId);
  const { subscriberId } = useProfile().data!;

  if (isLoading) {
    return <LinkedWorkspaceListLoader />;
  }

  if (error) {
    return (
      <FormHelperText //
        error={true}
        role="alert"
      >
        {error}
      </FormHelperText>
    );
  }

  if (!items.length) {
    return null;
  }

  const currentWorkspaceIndex = items.findIndex(item => item.workspaceId === workspaceId);
  const mappedItems: LinkedWorkspaceListItem[] = resolveComponentItemsSelector({
    //
    items,
    currentWorkspaceId: workspaceId
  });

  return (
    <LinkedWorkspaceList //
      currentWorkspaceIndex={currentWorkspaceIndex}
      currentSubscriberId={subscriberId}
      items={mappedItems}
      collapsedByDefault={collapsedByDefault}
    />
  );
}

export default LinkedWorkspaceListContainer;
