import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    halfWidth: {
      width: '50%'
    },
    fixedWidthDropdown: {
      width: 480
    }
  }),
  {
    name: 'ActionProhibited'
  }
);
