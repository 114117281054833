import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { DocumentFormIdentifierDisplayMapping } from 'src/@core/models/document';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { DocumentRoleMappingLookupModel } from 'src/containers/dashboard/components/create-new-workspace/models';
import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import {
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceTasks
} from 'src/store/actions/workspace';
import { DocumentListModel } from '../../models';
import AddDocumentDialog from '../add-document-dialog';
import { DocumentListBase } from '../document-list-base';
import { addAdditionalDocuments } from './api';
import { getDocumentTypeOptionsStores } from './helpers';
import { AddDocumentFormikModel, AddWorkspaceDocumentApiRequest, AddWorkspaceDocumentApiResponse, DOCUMENT_ADDED_MSG } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  items: DocumentListModel[];
  onCancel(): void;
  documentId?: string;
  isPrimary: boolean;
}

function DocumentListAddContainer({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  items,
  onCancel,
  documentId,
  isPrimary
}: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const documentTypeStore = getDocumentTypeOptionsStores(workspaceId, participantId, dispatch);
  const [documentTypeOptions, setDocumentTypeOptions] = React.useState<DocumentRoleMappingLookupModel[] | null>(null);
  const [defaultDocumentType, setDefaultDocumentType] = React.useState<HttpTypes.DocumentTypeIdentifierEnum | null>(null);

  React.useEffect(() => {
    getDocumentTypeOptions();
    // call this api only onComponentMount
  }, []);

  const initialValues: AddDocumentFormikModel = React.useMemo(() => {
    return { documentType: defaultDocumentType };
  }, [defaultDocumentType]);

  const getDocumentTypeOptions = async () => {
    const result = await documentTypeStore.query();
    if (result.length === 1) setDefaultDocumentType(result[0].id);
    setDocumentTypeOptions(result);
  };

  const onSave = async (values: AddDocumentFormikModel): Promise<AddWorkspaceDocumentApiResponse | undefined> => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'add-document',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      documentType: DocumentFormIdentifierDisplayMapping[values.documentType!],
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
    });

    const roles: HttpTypes.WorkspaceRoleEnum[] = documentTypeOptions!.find(o => o.id === values.documentType)!.roleIds;

    const workspaceDocument: AddWorkspaceDocumentApiRequest = {
      documentIdentifier: values.documentType!,
      roles
    };

    try {
      const result = await addAdditionalDocuments(workspaceId, participantId, workspaceDocument);
      if (result.isSuccess) {
        batch(() => {
          // non-SLC doc will use the generic message, otherwise we will use
          // the endpoint is returning a list, but it is always one document ATM

          const slcDocument = result.createdDocumentDetails.find(d => d.isSLCDocument);

          if (!slcDocument) {
            dispatch(
              actionOpenGlobalSimpleNotification({
                //
                message: DOCUMENT_ADDED_MSG,
                autoHideDuration: 5000,
                variant: 'new-success'
              })
            );
          } else {
            dispatch(
              actionOpenGlobalSimpleNotification({
                message: (
                  <>
                    <Typography className="text-[14px] text-[var(--neutral-000)]">
                      <b>Note</b> The '<b>{slcDocument.documentName}</b>' document requires a separate lodgement case that has been created automatically.
                    </Typography>
                    <Typography className="text-[14px] text-[var(--neutral-000)]">
                      <b>You will need to lodge this document manually before settlement.</b> When you are ready, simply press the 'Lodge' button at the top right of your document
                      to initiate the process.
                    </Typography>
                    <Typography className="text-[14px] text-[var(--neutral-000)]">
                      All of the above <b>will not affect</b> the automated settlement attempt of the <b>Primary Lodgement Case</b>.
                    </Typography>
                  </>
                ),
                variant: 'new-success',
                autoHideDuration: 500000
              })
            );
          }

          dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
          // we need to inform DocumentListContainer about ability to re-arranging of the documents
          // this is driven by 'canRearrangeLodgementInstructions' flag currently returned from workspaceDetail so we need to refetch it
          // TODO backend should return this flag so we don't necessary need to refetch whole workspace detail
          // TODO explain why we need to refetch workspace and decide which one to use (full or basic)
          dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
          dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
          dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));

          // since we have refactor our whole loading to partial loading, now calling workspace detail cannot close dialog anymore.
          onCancel();

          // when in workspace participant page, we also need to fetch new tasks
          if (!documentId) {
            dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
          }

          // redirect to newly created document
          if (result.createdDocumentDetails.length === 1) {
            const newlyCreatedDocumentLink = resolveDocumentDetailLink({ participantId, workspaceId, documentId: result.createdDocumentDetails[0].documentId });
            navigate(newlyCreatedDocumentLink);
          }
        });

        return result;
      }

      if (!result.titleReferenceErrors?.length) {
        dispatch(actionCreateGlobalErrorMessage({ message: 'Unable to add the document' }));
      }

      await getDocumentTypeOptions();
      return result;
    } catch (error) {
      dispatch(actionCreateGlobalErrorMessage(error));
      return undefined;
    }
  };

  return (
    <>
      <DocumentListBase items={items} dataTestId="document-list-add" isCriticalRolesEnabled={false} />
      {isPrimary && ( // WEB-37858: In MLC scenario, it would render AddDocumentDialog many times, so only render it in PLC
        <AddDocumentDialog //
          isOpen={true}
          documentTypes={documentTypeOptions}
          onSave={onSave}
          onClose={onCancel}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

export default React.memo(DocumentListAddContainer);
