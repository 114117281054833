import React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import Formik from '@sympli/ui-framework/components/formik';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { useStyles } from './styles';

export type FeatureFlagsControllerFormikModel = {
  featureGroup: Array<number>;
};

export type Props = {
  options: LookupEnumModel[];
  initialValues: FeatureFlagsControllerFormikModel;
  onReset(): void;
  onSubmit(values: FeatureFlagsControllerFormikModel): void;
};

const fieldName = modelKey<FeatureFlagsControllerFormikModel>();

function FeatureFlagsControllerFormik({ options, initialValues, onReset, onSubmit }: Props) {
  const classes = useStyles();

  const renderForm = React.useCallback(
    ({ isSubmitting, dirty, isValid, submitForm }: FormikProps<FeatureFlagsControllerFormikModel>) => {
      return (
        <FlexLayout className={classes.inputContainer} justifyContent="space-evenly" flexDirection="column">
          <FormGroup
            title="Feature flags used for this page"
            description="You can enable/disable feature flags via Update button. Reset button is to recover all flags to status in LaunchDarkly. 🔥 indicates the feature is now controlled by yourself. Note: all updates only happen in UI, not in LaunchDarkly."
          >
            <FlexLayout flexDirection="column" fullWidth>
              <Field
                className={classNames(classes.checkboxGroup)}
                vertical={true}
                name={fieldName('featureGroup')}
                component={CheckboxGroupField}
                options={options}
                format="string"
                aria-label="feature flags"
              />
            </FlexLayout>
          </FormGroup>
          <WizardStepper //
            backLabel="Reset"
            nextLabel="Update"
            isLoading={isSubmitting}
            disabled={!(dirty && isValid && !isSubmitting)}
            onBack={onReset}
            onNext={submitForm}
          />
        </FlexLayout>
      );
    },
    [classes.checkboxGroup, classes.inputContainer, onReset, options]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formikProps: FormikProps<FeatureFlagsControllerFormikModel>) => renderForm(formikProps)}
    </Formik>
  );
}

export default FeatureFlagsControllerFormik;
