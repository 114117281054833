import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { WeekdayEnum } from 'src/containers/dashboard/reports/components/weekdays/weekday/models';
import { AsyncDialogAction } from 'src/hooks';
import { initializeTimeOptions } from 'src/utils/timeHelper';

import type { SafeDispatch } from 'src/hooks';

export interface FetchScheduledReportHistoryApiRequest {
  id: string;
}

export type FetchScheduledReportHistoryApiResponse = ScheduledReportHistoryModel;

export interface FetchReportApiRequest {
  reportType: HttpTypes.ReportTypeEnum;
  subType?: string;
}

export interface FetchReportsApiResponse {
  endpoint: string;
  reportName?: string;
  filterData?: string;
}

export interface FetchReportOptionsApiResponse {
  reportTypeOptions: Array<LookupEnumModel>;
}

export enum ReportFormatEnum {
  Csv = 0,
  Pdf = 1
}
export interface SavedReportModel {
  id: number;
  name: string;
  recipients?: string;
  scheduledFrequency: ScheduledFrequencyEnum;
  reportSendTime: string;
  interval?: number;
  daysOfWeek?: WeekdayEnum[];
  subscriberId?: string;
  reportType: HttpTypes.ReportTypeEnum;
  reportFormat: ReportFormatEnum;
  filterData: string;
  feature: HttpTypes.ReportFeatureTypeEnum;
  dayOfMonth?: DayOfMonthEnum;
}

export interface SavedScheduleReportModel extends SavedScheduleReportApiResponse {
  scheduledFrequency: ScheduledFrequencyEnum;
  reportType: HttpTypes.ReportTypeEnum;
  isLoading: boolean;
}

export interface SavedScheduleReportApiResponse {
  id: number;
  generatedAtUtc: string;
  reportName: string;
}

export interface ScheduledReportHistoryModel extends SchedulerFormikModel {
  reports: Array<SavedScheduleReportApiResponse>;
}

export interface DeleteReportApiRequest<T> {
  id: number;
  reportFeatureType: HttpTypes.ReportFeatureTypeEnum;
  asyncDialogDispatch: SafeDispatch<AsyncDialogAction<T>>;
}

export interface FetchReportsDashboardApiResponse {
  savedReports: SavedReportModel[];
}

export enum ScheduledFrequencyEnum {
  Daily = 0,
  Weekly = 1,
  Monthly = 2
}

export const scheduledFrequencyMapping: Record<ScheduledFrequencyEnum, string> = {
  [ScheduledFrequencyEnum.Daily]: 'Daily',
  [ScheduledFrequencyEnum.Weekly]: 'Weekly',
  [ScheduledFrequencyEnum.Monthly]: 'Monthly'
};

export enum TeamWorkloadReportSubTypesEnum {
  standalone = 'Standalone',
  financial = 'Financial'
}

export const ReportSubTypesMap: Record<HttpTypes.ReportTypeEnum, string[] | undefined> = {
  [HttpTypes.ReportTypeEnum.UserPermissionReport]: undefined,
  [HttpTypes.ReportTypeEnum.UserActivityReport]: undefined,
  [HttpTypes.ReportTypeEnum.TeamWorkloadReport]: Object.values(TeamWorkloadReportSubTypesEnum)
};

//inverse of ReportDetailEnumRoutingMap
export const ReportRoutingToTypeMap: Record<string, HttpTypes.ReportTypeEnum> = {
  'user-permissions': HttpTypes.ReportTypeEnum.UserPermissionReport,
  'user-activity': HttpTypes.ReportTypeEnum.UserActivityReport,
  'team-workload': HttpTypes.ReportTypeEnum.TeamWorkloadReport
};

export interface SchedulerFormikModel {
  id?: number;
  name: string;
  scheduledFrequency: ScheduledFrequencyEnum | null;
  filterData: string;
  reportSendTime: string;
  interval?: number;
  daysOfWeek?: WeekdayEnum[];
  dayOfMonth?: DayOfMonthEnum;
  reportType: HttpTypes.ReportTypeEnum;
  subscriberId?: string;
}

export interface EditDeleteButton {
  onDeleteClick: (reportName: string, reportId: number) => void;
  onEditClick: () => void;
}

export const WeekdayFullNameMap: Record<WeekdayEnum, string> = {
  [WeekdayEnum.Su]: 'Sunday',
  [WeekdayEnum.Mo]: 'Monday',
  [WeekdayEnum.Tu]: 'Tuesday',
  [WeekdayEnum.We]: 'Wednesday',
  [WeekdayEnum.Th]: 'Thursday',
  [WeekdayEnum.Fr]: 'Friday',
  [WeekdayEnum.Sa]: 'Saturday'
};

export const FREQUENCY_OPTIONS: LookupEnumModel<ScheduledFrequencyEnum>[] = [
  {
    id: ScheduledFrequencyEnum.Daily,
    name: 'Daily'
  },
  {
    id: ScheduledFrequencyEnum.Weekly,
    name: 'Weekly'
  },
  {
    id: ScheduledFrequencyEnum.Monthly,
    name: 'Monthly'
  }
];

export const DAILY_INTERVAL_OPTIONS: LookupEnumModel<number>[] = generateIdNameLookup(1, 15);

export const MONTHLY_INTERVAL_OPTIONS: LookupEnumModel<number>[] = generateIdNameLookup(1, 12);

function generateIdNameLookup(start: number, end: number): LookupEnumModel<number>[] {
  const GENERATED_OPTIONS: LookupEnumModel<number>[] = [];
  if (start === 0 || end === 0 || start === end) {
    return GENERATED_OPTIONS;
  }

  for (let i = start; i <= end; i++) {
    GENERATED_OPTIONS.push({ id: i, name: i.toString() });
  }

  return GENERATED_OPTIONS;
}

export const GENERATION_TIME_OPTIONS: LookupEnumModel<string>[] = initializeTimeOptions(0, 23 * 60 + 30);

export const getTextForInterval = (selectedFrequency: ScheduledFrequencyEnum | null) => {
  switch (selectedFrequency) {
    case ScheduledFrequencyEnum.Daily:
      return 'day(s)';
    case ScheduledFrequencyEnum.Weekly:
      return 'week(s)';
    case ScheduledFrequencyEnum.Monthly:
      return 'month(s)';
    default:
      return '';
  }
};

export enum DayOfMonthEnum {
  FirstDayOfMonth = 0,
  FifteenthDayOfMonth = 1
}

export const DayOfMonthEnumMapping: Record<DayOfMonthEnum, string> = {
  [DayOfMonthEnum.FirstDayOfMonth]: '1st day of the month',
  [DayOfMonthEnum.FifteenthDayOfMonth]: '15th day of the month'
};

export const DAY_OF_MONTH_OPTIONS: LookupEnumModel<DayOfMonthEnum>[] = [
  {
    id: DayOfMonthEnum.FirstDayOfMonth,
    name: 'First day of month'
  },
  {
    id: DayOfMonthEnum.FifteenthDayOfMonth,
    name: '15th day of month'
  }
];

export interface FetchScheduleReportHistoryApiRequest {
  reportId: number;
}

export interface FetchScheduleReportHistoryApiResponse {
  pdfUrl: string;
}

export interface FavouriteFormikModel {
  id?: number;
  name: string;
  filterData: string;
  reportType: HttpTypes.ReportTypeEnum;
  parameterData?: string;
}

export interface FetchFavouriteReportApiRequest {
  reportId: string;
}

export interface FetchFavouriteReportApiResponse {
  id: number;
  name: string;
  reportType: HttpTypes.ReportTypeEnum;
  filterData: string;
  parameterData?: string;
}

export interface ReportDetailsState {
  endpoint: string;
  reportName?: string;
  filterData?: Record<string, string[]>;
  parameterData?: TableauParameter[];
}

export interface TableauParameter {
  name: string;
  value: any;
  type: tableau.ParameterDataType;
}

export interface TableauInitialiseData {
  reportLink: string;
  filters?: Record<string, string[]>;
}
