import { isAfter, isSameDay } from 'date-fns';

import { HttpTypes } from '@sympli/api-gateway/types';
import { MessageNotificationContainer, MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';

import { isWorkspaceFinalized } from 'src/containers/shared/helpers';
import { WorkspaceActivityLogsState } from '../../../../../../store/reducers/workspace/workspaceActivityLogs';
import { WorkspaceActivityLogModel } from '../tab-logs/models';
import { mapLogToNotification } from './workspaceLogNotificationMapper';

interface Props {
  workspaceId: string;
  participantId: string;
  workspaceLogs: WorkspaceActivityLogsState['items'];
  workspaceParticipants: HttpTypes.WorkspaceParticipant[];
  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
  externalNotifications?: JSX.Element[];
}

function WorkspaceLogNotifications({ workspaceId, participantId, workspaceLogs, workspaceParticipants, workspaceStatus, externalNotifications }: Props) {
  const participant = workspaceParticipants.find(wp => wp.id === participantId);
  if (participant === undefined || workspaceParticipants.findIndex(p => p.archivedStatus && p.id === participantId) !== -1 || isWorkspaceFinalized(workspaceStatus)) {
    return null;
  }

  const acceptedDateTime = new Date(participant.lastInvitationStatusUpdatedDate!);
  const workspaceLogsForNotification: WorkspaceActivityLogModel[] = Object.keys(workspaceLogs)
    .filter(dateOnlyValue => isAfter(new Date(dateOnlyValue), acceptedDateTime) || isSameDay(new Date(dateOnlyValue), acceptedDateTime))
    .flatMap(dateOnlyValue => {
      const logs = workspaceLogs[dateOnlyValue] || [];
      return logs.filter(l => isAfter(new Date(l.dateTimeUtc), acceptedDateTime));
    });

  if (workspaceLogsForNotification.length === 0) {
    return resolveExternalNotifications(externalNotifications);
  }

  const notifications = workspaceLogsForNotification
    .flatMap(log => {
      const key = `${workspaceId}/${participantId}/${log.participantId}/${log.workspaceLogType}/${log.dateTimeUtc}`;
      const notification = mapLogToNotification(log);
      if (notification === undefined) return [];

      const contents = notification({ log, participantId, workspaceParticipants });

      if (!contents) return [];

      return contents
        .filter(e => Boolean(e))
        .map(content => (
          <MessageNotificationWithLocalStorage //
            primary={content}
            key={key}
            v2
            storageNamespace={key}
            messageId={key}
            open={undefined}
            variant="warning"
          />
        ));
    })
    .concat(externalNotifications ?? []);

  return notifications.length > 0 ? <MessageNotificationContainer>{notifications}</MessageNotificationContainer> : null;
}

function resolveExternalNotifications(externalNotifications: JSX.Element[] | undefined) {
  return externalNotifications?.length ? <MessageNotificationContainer>{externalNotifications}</MessageNotificationContainer> : null;
}

export default WorkspaceLogNotifications;
