import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import yupVerifyProperty from 'src/containers/shared/verify-property-section/validationSchema';
import { WorkspaceCreationErrorsMap } from '../../../../models';
import { CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel, MatterDetailDocumentItemModel } from '../../models';

const validationSchema = yup.object<CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel, CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel>({
  createdByReference: yup.string().trim().required(msg.REQUIRED).max(200, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(200)),
  groupId: yup.string().required(msg.REQUIRED),
  jurisdictionId: yup.number().nullable().required(msg.REQUIRED),
  documents: yup
    .array<MatterDetailDocumentItemModel, CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel>()
    .of(
      yup
        .object<MatterDetailDocumentItemModel, CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel>({
          documentIdentifier: yup.number<HttpTypes.DocumentTypeIdentifierEnum>().nullable().required(msg.REQUIRED),
          roleOptions: yup.mixed<LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[]>() // we are not validating this one
        })
        .defined()
    )
    .defined(),
  titleInformation: yupVerifyProperty,
  workspaceCreationErrorsMap: yup
    .mixed<WorkspaceCreationErrorsMap>()
    .nullable(false)
    .test(
      //
      'workspaceCreationErrorsMap test',
      'The selected document cannot be lodged.',
      function test(this: yup.TestContext<CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel>, workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap) {
        return !workspaceCreationErrorsMap || !Object.keys(workspaceCreationErrorsMap as WorkspaceCreationErrorsMap).length;
      }
    ),
  isControllingParty: yup.mixed(), // we are not validating this one
  instructingOrganisationSettlementAgentId: yup.mixed() // we are not validating this one
  // verifiedTitles: yup.array().required(msg.REQUIRED)
});

export default validationSchema;
