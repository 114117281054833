import * as React from 'react';

import { isPast } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useProfile } from 'src/@core/store/reducers/profile';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from '../../../../store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceParticipants } from '../../../../store/reducers/workspace/workspaceParticipants';
import { orderedAvailableParticipantsSelector, settlementAcceptedByParticipantsSelector } from '../../shared/detail/selectors';
import { getRescindApprovalData } from './helper';
import { useSettlementDateDetails } from './reducers/settlementDetail';
import SettlementDate from './SettlementDate';

export interface SettlementDateRouteParams {
  workspaceId: string;
  participantId: string;
  declined: string;
}

function SettlementDateContainer() {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, declined } = useRouterParams<SettlementDateRouteParams>();
  const { subscriberId } = useProfile().data!;
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const settlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);

  const availableParticipants: HttpTypes.WorkspaceParticipant[] = orderedAvailableParticipantsSelector({
    workspaceParticipants: workspaceParticipantsState
  });
  const settlementAcceptedByParticipants: string[] | undefined = settlementAcceptedByParticipantsSelector({
    settlementDateDetails: settlementDateDetailsState
  });
  const proposedParticipant: HttpTypes.WorkspaceParticipant | undefined = workspaceParticipantsState.items.find(
    p => p.id === settlementDateDetailsState.detail.proposedByParticipant?.id
  );
  const proposedSettlementDate = settlementDateDetailsState.detail.proposedSettlementDate;
  const isProposedSettlementDateInPast = Boolean(proposedSettlementDate) && isPast(new Date(proposedSettlementDate!));

  return (
    <SettlementDate //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      navigate={navigate}
      //redux
      jurisdictionId={workspaceBasicInfoState.detail?.jurisdictionId}
      workspaceStatusId={workspaceBasicInfoState.detail?.workspaceStatusId}
      workspaceSettlementDate={workspaceBasicInfoState.detail?.settlementDate}
      expectedSettlementDate={workspaceBasicInfoState.detail?.expectedSettlementDate}
      workspaceType={workspaceBasicInfoState.detail?.workspaceTypeId}
      isRollover={workspaceBasicInfoState.detail?.isRollover}
      // settlement date details
      proposedParticipant={proposedParticipant}
      availableParticipants={availableParticipants}
      settlementAcceptedByParticipants={settlementAcceptedByParticipants}
      settlementDetailsState={settlementDateDetailsState}
      detail={settlementDateDetailsState.detail}
      // profile
      subscriberId={subscriberId}
      // other
      isLoadingWorkspace={workspaceBasicInfoState.isLoading}
      isLoadingWorkspaceParticipants={workspaceParticipantsState.isLoading}
      isLoadingSettlementDateDetails={settlementDateDetailsState.isLoading}
      declined={Boolean(declined)}
      dispatch={dispatch}
      rescindApprovalData={getRescindApprovalData(participantId, isProposedSettlementDateInPast, proposedParticipant?.id, settlementAcceptedByParticipants)}
    />
  );
}

export default React.memo(SettlementDateContainer);
