import { vicNameChangeConversion } from '@sympli-mfe/document-components/party-form/vic/2-28/components/party-justification';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { VIC_WOC_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { getCaveators, getSelectedCaveat, filterTitleReferences as getTitleReferences, getCaveatsBeingWithdrawn as getWithdrawCaveatsSelection } from './helper';
import { ApiWithdrawalOfCaveat2_28_0Model, WithdrawalOfCaveat2_28_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: VIC_WOC_PARTY_FORM_CONFIG,
  nameChangeConversion: vicNameChangeConversion
});

class Converter implements IConverter<WithdrawalOfCaveat2_28_0Model, ApiWithdrawalOfCaveat2_28_0Model> {
  fromApiToFormModel(apiValues: ApiWithdrawalOfCaveat2_28_0Model, context: IConverterContext): WithdrawalOfCaveat2_28_0Model {
    // Implement any necessary conversion from API to form values
    const { caveats } = apiValues;
    const relinquishingIds = caveats.map(caveat => caveat.caveators.map(caveator => caveator.partyBookId)).flat();

    const titleReferences = getTitleReferences(caveats);
    const withdrawCaveats = getWithdrawCaveatsSelection(caveats, titleReferences);

    let formModel: WithdrawalOfCaveat2_28_0Model = {
      ...apiValues,
      partyBook: convertPartyBookFromApiToFormModel(apiValues.partyBook || [], relinquishingIds),
      titleReferences: titleReferences,
      withdrawCaveats: withdrawCaveats,
      caveators: getCaveators(withdrawCaveats)
    };
    formModel = applyDefaultMap(formModel, fallbackMap as any);
    return formModel;
  }
  fromFormToApiModel(formValues: WithdrawalOfCaveat2_28_0Model, originalApiModel: ApiWithdrawalOfCaveat2_28_0Model) {
    formValues = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: partyBookFromForm,
      titleReferences,
      withdrawCaveats,
      ...rest
    } = formValues;

    const selectedTitles = titleReferences.filter(x => x.isSelected).map(x => x.reference);
    const selectedCaveat = getSelectedCaveat(withdrawCaveats);
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
      caveats: formValues.caveats.map(x => {
        const caveatIsSelected = x.dealingNumber === selectedCaveat!.dealingNumber;
        return {
          ...x,
          isSelected: caveatIsSelected,
          titleReferences: x.titleReferences.map(t => {
            return {
              ...t,
              isSelected: caveatIsSelected ? selectedTitles.includes(t.reference) : false
            };
          })
        };
      })
    };
  }
}

export default new Converter();
