import { useEffect, useState } from 'react';

import { screens, ScreenSizeVariant } from '../@core/theme/screens';

const useScreenSize = (): ScreenSizeVariant => {
  const [screenSize, setScreenSize] = useState<ScreenSizeVariant>('large-screen');

  useEffect(() => {
    const getScreenSize = () => {
      const breakpoints = screens; // Get breakpoints from config
      const screenWidth = window.innerWidth;

      for (const [label, size] of Object.entries(breakpoints)) {
        if (screenWidth >= parseInt(size)) {
          setScreenSize(label as ScreenSizeVariant);
          break; // Break loop once the correct label is found
        }
      }
    };

    getScreenSize();

    window.addEventListener('resize', getScreenSize);

    return () => {
      window.removeEventListener('resize', getScreenSize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
