import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    halfWidth: {
      width: '50%'
    },
    quarterWidth: {
      width: '25%'
    },
    multiline: {
      boxSizing: 'border-box',
      minHeight: 51 // two lines
    },
    marginTop: {
      marginTop: theme.spacing(1.5)
    },
    dutyAssessmentNumberTooltipList: {
      margin: `8px 0`,
      paddingLeft: 16
    },
    dutyAssessmentNumberTooltipListItem: {
      lineHeight: '12px',
      '& + &': {
        paddingBottom: 4
      }
    },
    fixedWidthDropdown: {
      width: 480
    }
  });

export type ClassKeys = typeof styles;

export default styles;
