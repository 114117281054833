import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchSettlementDetails, actionSetEditStatus, FetchSettlementDetailsApiRequest } from '../actions';
import { SettlementDateReviewerItemModel } from '../components/settlement-date-reviewer-list/models';

export interface SettlementDateDetailsApiResponse {
  workspaceSettlementDate: string | null;
  proposedSettlementDate: string | null;
  workspaceId: string;
  proposedByParticipantId: string | null;
  settlementDateProposalId: string | null;
  participants: SettlementDateReviewerItemModel[];
  jurisdiction: HttpTypes.JurisdictionsEnum;
  minReschedulingInHours: number;
  reasonText?: string;
  isUnsupported?: boolean; // IOP, settlementDate can be unsupported by other RLENO
  unsupportedReason?: string;
}

export interface SettlementDateDetailsModel {
  isEditing: boolean;
  participantId?: string; // * To identify the subscriber and its workspace role // TODO this can be removed and use the participantId from query
  participants: Array<SettlementDateReviewerItemModel>;
  workspaceSettlementDate?: string; // * current/previous workspace settlement date
  localWorkspaceSettlementDate?: string;
  isAcceptedByUser: boolean; // * calculate from participantId
  isAcceptedByAll: boolean; // * this is computed value depending on whether every participant has accepted proposed settlement date
  settlementDateProposalId?: string; // * The identifier for each proposal
  proposedByParticipant?: SettlementDateReviewerItemModel; // * The participant proposed the newDate
  proposedSettlementDate?: string;
  /**
   * localized proposedSettlementDate value based on user profile timezone
   */
  localProposedSettlementDate?: string;
  reasonText?: string;
  minReschedulingInHours: number;
  proposedOn?: string;
  isUnsupported?: boolean;
  unsupportedReason?: string;
}

export interface SettlementDateDetailsState {
  isLoading: boolean;
  isRefetching: boolean;
  status: ApiStatus;
  error?: string;
  detail: SettlementDateDetailsModel;
  args?: FetchSettlementDetailsApiRequest;
}

const initialState: SettlementDateDetailsState = {
  status: 'idle',
  isLoading: false,
  isRefetching: false,
  detail: {
    isEditing: false,
    participantId: undefined,
    participants: [],
    workspaceSettlementDate: undefined,
    localWorkspaceSettlementDate: undefined,

    isAcceptedByUser: false,
    isAcceptedByAll: true, // by default we assume everyone has accepted it

    settlementDateProposalId: undefined,
    proposedByParticipant: undefined,
    proposedSettlementDate: undefined,
    localProposedSettlementDate: undefined,
    minReschedulingInHours: 2,
    reasonText: ''
  },
  error: undefined
};

export function useSettlementDateDetails(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.settlementDateDetails);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  SettlementDateDetailsState,
  Action
>(initialState)
  .handleAction(actionFetchSettlementDetails.request, (state, action): SettlementDateDetailsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchSettlementDetails.success, (state, action): SettlementDateDetailsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      error: undefined
    };
  })
  .handleAction(actionFetchSettlementDetails.failure, (state, action): SettlementDateDetailsState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionSetEditStatus, (state, action): SettlementDateDetailsState => {
    return {
      ...state,
      detail: {
        ...state.detail,
        isEditing: action.payload
      },
      error: undefined
    };
  });

export default reducer;
