import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { InvitationItemModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  name: string;
  jurisdiction: HttpTypes.JurisdictionsEnum;
  disabled: boolean;
  groups: GroupOptionModel[];
}

function GroupAndMatterNumber({ name, jurisdiction, disabled, groups }: Props) {
  const fieldName = createModelKeyAppender<InvitationItemModel>(name);
  const groupOptions = groups.filter(group => group.jurisdictions.some(j => j.id === jurisdiction));
  const classes = useStyles();
  return (
    <FlexLayout flexDirection="row" fullWidth>
      <Field //
        name={fieldName('groupId')}
        label="Select a group"
        options={groupOptions}
        component={SelectField}
        className={classes.groupSelectionField}
        disabled={disabled}
      />
      <Field //
        name={fieldName('matterNumber')}
        label="Matter/loan number"
        component={InputField}
        disabled={disabled}
      />
    </FlexLayout>
  );
}

export default React.memo(GroupAndMatterNumber);
