import _get from 'lodash-es/get';
import store from 'store2';

import { HttpTypes } from '@sympli/api-gateway/types';
import DocumentSigning from '@sympli/digital-signing';
import { ICertIdentifier, IGetVersionResponse, ISignRequestPayload, ISignResponse, IUserInfo, SigningToolEnum } from '@sympli/digital-signing/interfaces';
import Logger, { PageActionEnum, SeverityEnum } from '@sympli/ui-logger';

import environments from 'src/@core/environments';
import http, { NoResponseData } from 'src/utils/http';
import { NO_CERTIFICATE_FOUND, NO_HARD_TOKEN_CERTIFICATE_FOUND, NO_SOFT_TOKEN_CERTIFICATE_FOUND } from './constants';
import { GetUserCertsArgs, LogUserSigningRequest, SigningApplicationUpdateNotification } from './model';

const buildSigningLogger =
  (logGroupId: string) =>
  (...args: any[]) => {
    // WEB-17729 - log everything to NR for better visibility
    Logger.capturePageAction(PageActionEnum.DocumentSigning, {
      step: 'library',
      log: args,
      logGroupId // WEB-27575
    });
  };

// * Get all signing certificates by calling document signing toolkit
export async function getUserCertificatesFromToolkit(
  //
  userCertsArgs: GetUserCertsArgs,
  logGroupId: string // WEB-27575
) {
  const { userInfo } = userCertsArgs;

  // always create new instance otherwise there are issues in scenarios when challenge code approval failed
  const ds = new DocumentSigning({
    logger: buildSigningLogger(logGroupId)
  });

  const { certs, errors } = await ds.listCertificates(userInfo);

  for (const c of certs) {
    Logger.console(SeverityEnum.Debug, c.id.certSerialNumber, c);
  }

  return { certs, errors };
}

// resolve error code when no certificate exists based on soft/hard token types
export function resolveNoCertificateFoundErrorMsg(isUserSoftToken: boolean, isUserHardToken: boolean) {
  if (isUserHardToken && isUserSoftToken) {
    return NO_CERTIFICATE_FOUND;
  }

  if (isUserHardToken) {
    return NO_HARD_TOKEN_CERTIFICATE_FOUND;
  }

  if (isUserSoftToken) {
    return NO_SOFT_TOKEN_CERTIFICATE_FOUND;
  }

  // fallback to generic message
  return NO_CERTIFICATE_FOUND;
}

// * This is where we actually sign documents using document signing toolkit
// * The response is then used to call our signing APIs
export async function getSignedData({
  payloads,
  certificateIdentifier,
  userInfo,
  logGroupId // WEB-27575
}: {
  payloads: ISignRequestPayload[];
  certificateIdentifier: ICertIdentifier;
  userInfo: IUserInfo;
  logGroupId: string; // WEB-27575
}): Promise<ISignResponse> {
  // always create new instance otherwise there are issues in scenarios when challenge code approval failed
  const ds = new DocumentSigning({
    logger: buildSigningLogger(logGroupId)
  });

  Logger.capturePageAction(PageActionEnum.DocumentSigning, {
    step: 'before-batch-sign',
    logGroupId
  });

  const response: ISignResponse = await ds.sign({
    payloads,
    userInfo,
    certificateIdentifier,
    includeChain: false // * default as false, varies per land registry
  });

  Logger.capturePageAction(PageActionEnum.DocumentSigning, {
    step: 'after-batch-sign',
    logGroupId
  });

  return response;
}

// * Get latest signing app version from document signing toolkit
// export async function getNewSigningAppVersion(userId: string): Promise<SigningAppVersion | undefined> {
//   // * We just want to silently get the latest app version and internally document signing toolkit doesn't need challenge code to get app version
//   // * Therefore not passing challenge code callbacks
//   const ds = new DocumentSigning({
//     logger: signingLogger
//   });

//   // * If we are unable to get the version, null will be returned
//   const currentSigningAppVersion = await ds.getSigningAppVersion();

//   if (!currentSigningAppVersion) {
//     return;
//   }

//   const latestVersion: SigningAppVersion = {
//     ...currentSigningAppVersion,
//     forceUpdate: false
//   };

//   const currentDate = new Date();
//   let notification = getUserSigningApplicationUpdateNotification(userId);
//   // * If latest app version is equal to the version we saved with notification and next reminder is in the future
//   // * We don't want to show app update dialog during signing
//   // * Therefore return undefined as SigningProvider uses the latest app version to control open/close app update dialog
//   notification = notification?.version === latestVersion.signingAppVersion ? notification : undefined;
//   if (notification && new Date(notification.nextReminder) > currentDate) {
//     return;
//   }

//   // * Default to 1 day for the signing app update notification reminder
//   const remindersLeft = notification?.remindersLeft ?? 1;

//   return {
//     ...latestVersion,
//     forceUpdate: remindersLeft <= 0
//   };
// }

// * Update signing app update notification with user chosen value from update dialog during signing
// export function postponeSigningAppUpdateReminder(daysUntilNextReminder: number, userProfile: AuthProfileApiResponse) {
//   const date = new Date();
//   date.setDate(date.getDate() + daysUntilNextReminder);
//   const currentNotification = getUserSigningApplicationUpdateNotification(userProfile.userId);
//   const currentReminderLeft =
//     currentNotification && currentNotification.version === userProfile.latestAppVersion?.signingAppVersion ? currentNotification.remindersLeft : MAX_SIGNING_APP_UPDATE_REMINDERS;
//   const notification: SigningApplicationUpdateNotification = {
//     version: userProfile.latestAppVersion?.signingAppVersion,
//     nextReminder: date,
//     remindersLeft: currentReminderLeft - (!daysUntilNextReminder ? 0 : 1)
//   };
//   setUserSigningApplicationUpdateNotification(userProfile.userId, notification);
// }

export function getUserInfo(userProfile: HttpTypes.UserProfileModel): IUserInfo {
  return {
    givenName: userProfile.middleName ? `${userProfile.firstName} ${userProfile.middleName}` : userProfile.firstName,
    surname: userProfile.lastName,
    abn: userProfile.subscriberAbn,
    email: userProfile.email,
    allowSoftToken: userProfile.isSoftTokenEnabled,
    allowHardToken: true,
    supportedGatekeepers: userProfile.certificateAccreditedGatekeepers
  };
}

function getSigningAppInfo(logGroupId: string): Promise<IGetVersionResponse | null> {
  try {
    // * We just want to silently get the latest app version
    const ds = new DocumentSigning({
      logger: buildSigningLogger(logGroupId)
    });

    return ds.getVersion();
  } catch (e) {
    // * If we are unable to get the version, null will be returned
    return Promise.resolve(null);
  }
}

// * API logging for signing result
export async function logUserSigning(
  args:
    | {
        //
        isSuccessful: true;
        logGroupId: string; // WEB-27575
        signingInfo: ISignResponse['signingInfo'];
      }
    | {
        //
        isSuccessful: false;
        logGroupId: string; // WEB-27575
      }
) {
  // if browser extension is installed, additional details will be returned
  const currentSigningAppVersion: IGetVersionResponse | null = await getSigningAppInfo(args.logGroupId);
  try {
    return http.post<NoResponseData, LogUserSigningRequest>('/workspaces/signing-log', {
      isSuccessful: args.isSuccessful,
      signingTool: SigningToolEnum.BrowserExtension,
      ...currentSigningAppVersion
    });
  } catch (error) {
    const scope = Logger.scopeWithCustomAttributes({ ...currentSigningAppVersion, logGroupId: args.logGroupId });
    Logger.captureException(error, scope);
  }
}

export function resolveSigningErrorMessage(err: Error) {
  // // ! Fortify special case
  // if (err['type'] === 'pkcs11') {
  //   return 'Something is wrong about fortify. Please restart fortify.';
  // }
  return errorMessageMapping[err.message] || 'There was an error while signing the document. Please try again.';
}

export const errorMessageMapping: Record<string, string> = {
  // fortify related errors
  // [ERR_WS_NO_SUITABLE_CRYPTO_PROVIDER]: 'Make sure you are using a supported hard token device and device is plugged in.',
  // User has not approved the challenge code (challenge code dialog)
  // [ERR_SOCKET_SESSION_DENIED]: 'You need to approve the connection between your hard token and Simply app.',
  // User has been denied access to the crypto session on the device  (pin dialog)
  // for example when user entered incorrect code or clicked cancel
  // [ERR_WS_DEVICE_ACCESS_DENIED]: 'Incorrect password provided',
  // [ERR_WS_CONNECTION_TIMEOUT]: 'Connection has timed out. Please unplug and plug in your hard token and try again.',
  // [ERR_WS_SIGNING_FAILED]: 'Failed to sign the document. Please unplug and plug in your hard token and try again.',
  // [ERR_PAYLOAD_NOT_STRING]: 'The document is not valid. Please check the document and try again.',
  // [ERR_WS_NO_CERTIFICATES]: 'No valid certificate found. Please check the certificate or plug in the hard token.',
  // [WS_PROVIDER_NOT_FOUND]: 'Failed to find the certificate from this computer.',
  // [ERR_WS_NO_SIGNING_CERT]: 'Failed to find the certificate from this computer.',

  CKR_SESSION_HANDLE_INVALID: `The specified session handle was invalid at the time that the function was invoked.
  Note that this can happen if the session's token is removed before the function invocation,
  since removing a token closes all sessions with it. Please unplug and plug in your hard token and try again.`,
  // from customized certificate checking when the tool kit return the
  [NO_HARD_TOKEN_CERTIFICATE_FOUND]: 'Please make sure you are using a supported hard token device and device is plugged in.',
  [NO_SOFT_TOKEN_CERTIFICATE_FOUND]: 'Please make sure the certificate has been installed on you computer.',
  [NO_CERTIFICATE_FOUND]: 'No valid signing certificate found. Please ensure you have installed your signing certificate, or your hard token is plugged in.'
};

/* ******************* Default signing certificate setting helper *******************  */
const namespace = environments.STORE_NAMESPACE;
export function getDefaultSigningCertificateId(userId: string) {
  const defaultSigningCertificate = store.get(`${namespace}/defaultSigningCertificate`);
  return _get<ICertIdentifier | undefined>(defaultSigningCertificate, userId, undefined);
}

export function setDefaultSigningCertificateId(userId: string, defaultCertId?: ICertIdentifier) {
  const defaultSigningCertificate = store.get(`${namespace}/defaultSigningCertificate`);
  // if defaultCertId is undefined, it will remove his/her default signing certificate
  store.set(`${namespace}/defaultSigningCertificate`, { ...defaultSigningCertificate, [userId]: defaultCertId });
}

export function getUserSigningApplicationUpdateNotification(userId: string) {
  const signingApplicationUpdateNotification = store.get(`${namespace}/signingApplicationUpdateNotification`);
  return _get<SigningApplicationUpdateNotification | undefined>(signingApplicationUpdateNotification, userId, undefined);
}

export function setUserSigningApplicationUpdateNotification(userId: string, notification: SigningApplicationUpdateNotification) {
  const signingApplicationUpdateNotification = store.get(`${namespace}/signingApplicationUpdateNotification`);
  store.set(`${namespace}/signingApplicationUpdateNotification`, {
    ...signingApplicationUpdateNotification,
    [userId]: notification
  });
}
