import * as yup from 'yup';

import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { resolveRelinquishingTenancyValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/validationSchema';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { MergeParty } from 'src/containers/documents/party-merge/model';
import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { BaseTransfer2_24_1Model } from '../../models';

interface TransferorContext<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}> {
  partyBook: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBook'];
  selectedTitleReferences: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['titleReferences'];
  transferors: RelinquishingTenancyDetailModel;
}

const PARTIES_DO_NOT_MATCH = (allowNameChange: boolean) =>
  `The parties on the selected titles do not match; please ${allowNameChange ? 'provide name justification or ' : ''}remove the mismatched titles.`;
const NAME_JUSTIFICATION_ONLY_FOR_WHOLE_TRANSFER_ERROR_MESSAGE = 'The name justification can only be provided for the proprietors selling their whole share.';
const JOINT_TENANCY_SEVER_ERROR_MESSAGE = 'Severing the Joint Tenancy requires the transfer of the whole share.';

export function resolveTransferorSchema<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  getLegalEntityName,
  yupPartyBookId,
  yupAddressBookId,
  yupPartyCapacity,
  yupShareFractionTransferring,
  yupShareTransferred,
  allowNameChange = true,
  allowSeverJointTenancy = false
}: {
  getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined;
  yupPartyBookId?: yup.Schema<any>;
  yupAddressBookId?: yup.Schema<any>;
  yupPartyCapacity?: yup.Schema<any>;
  yupShareFractionTransferring?: yup.Schema<any>;
  yupShareTransferred?: yup.Schema<any>;
  allowNameChange?: boolean;
  allowSeverJointTenancy?: boolean;
}): yup.Schema<RelinquishingTenancyDetailModel> {
  return memoizeSchemaWithContext<RelinquishingTenancyDetailModel, BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>, object>(
    resolveRelinquishingTenancyValidationSchema({
      yupPartyBookId,
      yupAddressBookId,
      yupPartyCapacity,
      yupShareFractionTransferring,
      yupShareTransferred
    }).test(
      //
      'Transferor requires name change test',
      PARTIES_DO_NOT_MATCH(allowNameChange),
      function test(this: yup.TestContext<TransferorContext<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { selectedTitleReferences, partyBook } = this.options.context!;
        if (selectedTitleReferences.length <= 1) return true;
        return allowNameChange ? PartyValidations.verifyJustification(partyBook, getLegalEntityName) : partyBook.every(p => !p.mergeMetadata?.requiresJustification);
      }
    ),
    ({
      partyBook,
      titleReferences,
      transferors
    }: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>): TransferorContext<
      TPartLandAffectedModel,
      TDocumentPartyJustification,
      TNameChange
    > => ({
      //
      partyBook,
      transferors,
      selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
    })
  )
    .test(
      'Transferor partial share validation',
      NAME_JUSTIFICATION_ONLY_FOR_WHOLE_TRANSFER_ERROR_MESSAGE,
      function test(this: yup.TestContext<TransferorContext<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { transferors, partyBook } = this.options.context!;
        const justifyingParties = partyBook.filter(pb => pb.receivingOrRelinquishingDetails.isRelinquishing && pb.receivingOrRelinquishingDetails.isChangingName);

        return (
          allowNameChange ||
          transferors.proprietorGroups.filter(
            t => t.isSelected && t.shareTransferred === ShareTransferredTypeEnum.Partial && t.parties.some(tp => justifyingParties.find(jp => jp.id === tp.partyBookId))
          ).length === 0
        );
      }
    )
    .test(
      'Joint tenancy severance check',
      JOINT_TENANCY_SEVER_ERROR_MESSAGE,
      function test(this: yup.TestContext<TransferorContext<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { transferors } = this.options.context!;

        return (
          !allowSeverJointTenancy ||
          transferors.proprietorGroups
            .filter(pg => pg.parties.length > 1)
            .every(pg => pg.shareTransferred !== ShareTransferredTypeEnum.Partial || pg.parties.every(p => p.isSelected))
        );
      }
    );
}
