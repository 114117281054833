import * as React from 'react';

import { Action } from 'redux';
import { MenuItemProps } from '@mui/material/MenuItem';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import DocumentWorkflowPanel from 'src/containers/documents/components/document-workflow-panel';
import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import ErrorMessagePanel from 'src/containers/workspace/financial/detail/components/error-message-panel';
import { ErrorVariantEnum } from 'src/containers/workspace/financial/detail/components/error-message-panel/models';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import SwitchFormView from 'src/containers/workspace/shared/components/menu-items/switch-form-view';
import UnsignPayments from 'src/containers/workspace/shared/components/menu-items/unsign-payments';
import UnsignSourceFunds from 'src/containers/workspace/shared/components/menu-items/unsign-source-funds';
import { resolveSingleDocumentActionInfo } from 'src/containers/workspace/shared/detail/helpers';
import { actionToggleCurrentParticipantDocDisplay } from 'src/store/actions/workspace';
import DirectionsPreviewContainer from '../../components/directions-preview';
import DirectionsSummary from '../../components/directions-summary';
import SourceFundMessage from '../../components/source-fund-message';
import { directionUpdateHelper, havePurchaserParticipantInWorkspace } from '../../helpers';
import { DirectionCommonProps, DirectionDocumentWorkflowMapping } from '../../models';
import { SourceFundsStatusEnum } from '../edit-directions/components/source-funds-dialog/models';
import { trustAccountOptionsSelector } from '../edit-directions/selectors';
import ReadonlyDirections from '../readonly-directions';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

interface OwnProps extends DirectionCommonProps {
  isLoadingDirectionsList: boolean;
  sympliSourceFundCalculatorMessage: {
    warningMessage?: string;
    buttonLinkText?: string;
    sourceFundStatus: SourceFundsStatusEnum;
    variant: ErrorVariantEnum;
  };
  requiresStampDuty: boolean;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isWorkflowLoading: boolean;
}

class ReviewDirections extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false
  };
  private currentStep = DocumentWorkflowStepsEnum.Review;

  render() {
    return (
      <>
        {this.renderDocumentStepper()}
        {this.renderWarningMessageBox()}
        {this.renderDocumentContent()}
        {this.renderDirectionSummary()}
      </>
    );
  }

  private renderDocumentContent() {
    const { detail, currentParticipant, workspaceParticipants, directionsCategoriesDetail, queryParams, hasManageWorkspacePermission } = this.props;
    if (detail == null || currentParticipant == null) {
      return null;
    }
    if (currentParticipant.displayDocumentAsPdf) {
      return <DirectionsPreviewContainer queryParams={queryParams} />;
    }
    return (
      <ReadonlyDirections
        workspaceParticipants={workspaceParticipants}
        detail={detail}
        currentParticipant={currentParticipant}
        directionsCategoriesDetail={directionsCategoriesDetail}
        hasManageWorkspacePermission={hasManageWorkspacePermission}
      />
    );
  }

  private renderDirectionSummary() {
    const { workspaceType, detail, currentParticipant, portalIdForDirectionSummary, requiresStampDuty, workspaceParticipants } = this.props;
    const havePurchaserParticipant = havePurchaserParticipantInWorkspace(workspaceParticipants);
    const workspaceRole = currentParticipant.workspaceRole.id;
    const { directionsSummary } = detail;
    const { trustAccountBankDetailMap } = trustAccountOptionsSelector(detail);

    return (
      <DirectionsSummary
        workspaceTypeId={workspaceType}
        workspaceRoleId={workspaceRole}
        directionsSummary={directionsSummary}
        trustAccounts={detail.trustAccounts}
        trustAccountBankDetailMap={trustAccountBankDetailMap}
        sourceFunds={detail.sourceFunds}
        portalIdForDirectionSummary={portalIdForDirectionSummary}
        requiresStampDuty={requiresStampDuty}
        havePurchaserParticipant={havePurchaserParticipant}
      />
    );
  }

  private renderDocumentStepper() {
    const { classes, detail, steps, paymentsPermissions, sourceFundPermissions, currentParticipant } = this.props;
    const { isWorkflowLoading } = this.state;

    const { financialActions, status } = detail.documentSummary;
    const reviewActionInfo = resolveSingleDocumentActionInfo(financialActions, HttpTypes.DocumentActionTypeEnum.Review);

    const disableApproveButton = !paymentsPermissions[this.currentStep] || !sourceFundPermissions[this.currentStep];
    const mode = currentParticipant.displayDocumentAsPdf === true ? 'dark' : 'light';

    const nextButtonStyling = mode === 'dark' ? { root: classes.disabledNextDark } : { root: classes.disabledNextLight };

    return (
      <DocumentWorkflowPanel
        mode={mode}
        steps={steps}
        disableStepper={isWorkflowLoading}
        disableMenu={isWorkflowLoading}
        currentStep={this.currentStep}
        lastEnabledStep={DirectionDocumentWorkflowMapping[status]}
        onStepClick={this.handleOnStepClick}
        menuItems={this.renderMenuList()}
      >
        <ActionLabel mode={mode} documentActionInfo={reviewActionInfo} currentParticipantId={currentParticipant.id} />
        <SympliButton
          classes={nextButtonStyling}
          color="primary"
          variant="contained"
          onClick={this.handleOnPrimaryActionClick}
          arrowRight
          isLoading={isWorkflowLoading}
          disabled={isWorkflowLoading || disableApproveButton}
        >
          Approve
        </SympliButton>
      </DocumentWorkflowPanel>
    );
  }

  private renderWarningMessageBox = () => {
    const { isLoadingDirectionsList: isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    const {
      sympliSourceFundCalculatorMessage: {
        warningMessage, //
        buttonLinkText,
        sourceFundStatus,
        variant
      }
    } = this.props;

    const errors: MessageModel[] = [];
    const warnings: MessageModel[] = [];
    if (warningMessage) {
      const sympliSourceFundErrorOrWarning = (
        <SourceFundMessage //
          title={warningMessage}
          actionText={buttonLinkText}
          onClick={() => this.handleButtonClick(sourceFundStatus)}
        />
      );
      if (variant === ErrorVariantEnum.Error) {
        errors.push({
          message: sympliSourceFundErrorOrWarning
        });
      } else {
        warnings.push({
          message: sympliSourceFundErrorOrWarning
        });
      }
    }

    const errorHeader = "We've found issues in your Financial Settlement Schedule.";
    const warningHeader = "We've found warnings in your Financial Settlement Schedule.";
    return (
      <ErrorMessagePanel //
        errorHeader={errorHeader}
        warningHeader={warningHeader}
        errors={errors}
        warnings={warnings}
      />
    );
  };

  private handleButtonClick = (sourceFundStatus: SourceFundsStatusEnum) => {
    switch (sourceFundStatus) {
      case SourceFundsStatusEnum.Surplus:
        this.props.onStepChange && this.props.onStepChange(DocumentWorkflowStepsEnum.Write);
        break;
      case SourceFundsStatusEnum.ShortFall:
        // TODO: Hook to the document download once it's ready
        this.props.onStepChange && this.props.onStepChange(DocumentWorkflowStepsEnum.Write);
        break;
      default:
        this.props.onStepChange && this.props.onStepChange(DocumentWorkflowStepsEnum.Write);
    }
  };

  private handleOnStepClick = (e: React.MouseEvent<HTMLButtonElement>, stepValue: DocumentWorkflowStepsEnum) => {
    this.props.onStepChange && this.props.onStepChange(stepValue);
  };

  private renderMenuList(): Array<React.ReactElement<MenuItemProps>> | undefined {
    const { paymentsPermissions, sourceFundPermissions, queryParams, dispatch, currentParticipant } = this.props;
    const menuItems = Array<React.ReactElement<MenuItemProps>>();

    if (paymentsPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignPayments queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    if (sourceFundPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignSourceFunds queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    const { displayDocumentAsPdf } = currentParticipant;
    menuItems.push(<SwitchFormView displayDocumentAsPdf={displayDocumentAsPdf} onClick={this.handleOnViewChange} />);
    return menuItems;
  }

  private handleOnPrimaryActionClick = () => {
    const { workspaceId, participantId } = this.props.queryParams;
    this.setState({ isWorkflowLoading: true });

    endpoints
      .reviewDirections({ workspaceId, participantId })
      .then((response: HttpTypes.ReviewWorkspaceDirectionsApiResponse) => {
        // update the status of the current step
        const { dispatch } = this.props;
        directionUpdateHelper({
          dispatch,
          newDirectionForm: { documentSummary: response },
          newDistributionsParticipantData: { distributionStatus: response.status, isPartiallySigned: response.isPartiallySigned },
          participantId,
          workspaceId
        });
      })
      .catch(err => {
        this.setState({ isWorkflowLoading: false }, () => {
          this.props.dispatch(actionCreateGlobalErrorMessage(err));
        });
      });
  };

  private handleOnViewChange = () => {
    const { participantId } = this.props.queryParams;
    this.props.dispatch(actionToggleCurrentParticipantDocDisplay(participantId));
  };

  private setIsWorkflowLoading = (isWorkflowLoading: boolean) => {
    this.setState({ isWorkflowLoading });
  };
}

const styledComponent = withStyles(styles)(ReviewDirections);
export default styledComponent;
