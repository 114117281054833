import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupItemModel } from '@sympli/ui-framework/models';

import Secured from 'src/@core/auth/profile-security/Secured';
import environments from 'src/@core/environments';
import { resolveReportLink } from 'src/@core/pages/links';
import { IconHelp, IconSettings, IconSympliLogo } from '../../icons';
import EnvironmentLabel from '../environment-label';
import FeatureFlagsControllerContainer from '../feature-flags-controller';
import GlobalSearchContainer from '../global-search';
import TopPanelIconLink from '../top-panel-icon-link';
import TopPanelLink from '../TopPanelLink';
import UserProfileNav from '../user-profile-nav';
import { isActiveNav } from './helpers';

// 48;
// 32;
// 24;

/* max width of content

1920 - 116 * 2 = 1688
1536 - 80 * 2  = 1376
1280 - 48 * 2 = 1184
*/

const useStyles = makeStyles(
  () => ({
    menu: {
      margin: 0,
      padding: 0,
      marginBlock: 0,
      listStyleType: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& li': {
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      },
      '& a': {
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }),
  { name: 'TopPanelMenu' }
);

function TopPanelMenu({ secondary, children, className }: React.PropsWithChildren<{ secondary?: boolean; className?: string }>) {
  const classes = useStyles();

  if (!secondary) {
    return <ul className={classNames('z-[5] h-[64px] medium-screen:h-[90px]', classes.menu, className)}>{children}</ul>;
  }
  return (
    <ul className={classNames('absolute left-0 top-[54px] z-[5] h-[48px] min-w-max pt-[10px] group-hover:flex medium-screen:top-[80px]', classes.menu, className)}>{children}</ul>
  );
}

interface TopPanelProps {
  // profile info
  userEmail: string;
  userName: string;
  firstName: string;
  lastName: string;
  userAvatarUrl?: string;
  userSubscriberName?: string;
  userTimezone?: string;
  // menu
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  showFinancialWsLink?: boolean;
  showReportsLink?: boolean;
  pathname: string;
  reports: LookupItemModel<HttpTypes.ReportTypeEnum>[];
  // search
  hideSearchBar?: boolean;
}

export default function TopPanel({
  //
  hideSearchBar,
  workspaceTypeId,
  userName,
  firstName,
  lastName,
  userEmail,
  userAvatarUrl,
  userSubscriberName,
  userTimezone,
  showFinancialWsLink,
  showReportsLink,
  pathname,
  reports
}: TopPanelProps) {
  const secondaryPanelBackgroundRef = React.useRef<HTMLDivElement>(null);

  const handleOnMouseEnterOrOver = React.useCallback(() => {
    secondaryPanelBackgroundRef.current?.classList.remove('hidden');
  }, [secondaryPanelBackgroundRef]);

  const handleOnMouseLeave = React.useCallback(() => {
    secondaryPanelBackgroundRef.current?.classList.add('hidden');
  }, [secondaryPanelBackgroundRef]);

  return (
    <div className="flex-0 flex flex-col justify-center">
      <nav
        className="relative z-[5] mx-auto box-border w-screen shrink-0 px-[24px] medium-screen:px-[32px] large-screen:px-[48px]"
        style={{
          boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)'
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="mr-[16px] large-screen:mr-[32px]" data-testid="sympli-logo">
              <IconSympliLogo />
            </Link>
            <TopPanelMenu className="flex">
              <li //
                className="group"
                {...(showFinancialWsLink && {
                  onMouseEnter: handleOnMouseEnterOrOver,
                  onMouseOver: handleOnMouseEnterOrOver,
                  onMouseLeave: handleOnMouseLeave
                })}
              >
                <TopPanelLink to="/workspaces" isActive={isActiveNav('new-ws', { pathname, workspaceTypeId })}>
                  Workspaces
                </TopPanelLink>
                {showFinancialWsLink && (
                  <TopPanelMenu className="hidden group-hover:flex" secondary>
                    <li>
                      <TopPanelLink to="/standalone" secondary isActive={isActiveNav('standalone', { pathname, workspaceTypeId })}>
                        Standalone
                      </TopPanelLink>
                    </li>
                    <li>
                      <TopPanelLink to="/financial-workspaces" secondary isActive={isActiveNav('financial-workspaces', { pathname, workspaceTypeId })}>
                        Financial
                      </TopPanelLink>
                    </li>
                  </TopPanelMenu>
                )}
              </li>
              <li>
                <TopPanelLink to="/invitations" isActive={isActiveNav('invitations', { pathname, workspaceTypeId })}>
                  Invitations
                </TopPanelLink>
              </li>
              {showReportsLink && (
                <li //
                  className="group relative"
                  {...(!!reports.length && {
                    onMouseEnter: handleOnMouseEnterOrOver,
                    onMouseOver: handleOnMouseEnterOrOver,
                    onMouseLeave: handleOnMouseLeave
                  })}
                >
                  <TopPanelLink to="/reports" isActive={isActiveNav('reports', { pathname })}>
                    Reports
                  </TopPanelLink>
                  {!!reports.length && (
                    <TopPanelMenu secondary className="hidden group-hover:flex">
                      <li>
                        <TopPanelLink secondary to="/reports" isActive={isActiveNav('reports-home', { pathname })}>
                          Home
                        </TopPanelLink>
                      </li>
                      {reports.map(r => (
                        <li key={r.id}>
                          <TopPanelLink
                            //
                            secondary
                            to={resolveReportLink(HttpTypes.ReportFeatureTypeEnum.Favourite, r.id)}
                            isActive={isActiveNav('reports-page', { pathname, reportTypeId: r.id })}
                          >
                            {r.name}
                          </TopPanelLink>
                        </li>
                      ))}
                    </TopPanelMenu>
                  )}
                </li>
              )}

              <EnvironmentLabel environmentName={environments.APP_ENV} />
              <FeatureFlagsControllerContainer environmentName={environments.APP_ENV} />
            </TopPanelMenu>
          </div>

          <div className="flex h-[28px] content-center items-center gap-[14px] medium-screen:h-[36px] medium-screen:gap-[18px] ">
            {!hideSearchBar ? <GlobalSearchContainer /> : null}

            <TopPanelIconLink icon={<IconHelp />} to="/help" pattern="/help" />

            <Secured requiredRolePermissions={HttpTypes.RolePermissionsEnum.AccessSettings} hiddenForDisabled>
              <TopPanelIconLink icon={<IconSettings />} to="/settings/groups" pattern="/settings/*" dataTestId="settings-icon" />
            </Secured>

            <UserProfileNav //
              name={userName}
              firstName={firstName}
              lastName={lastName}
              email={userEmail}
              avatarUrl={userAvatarUrl}
              subscriberName={userSubscriberName}
              timezone={userTimezone}
              avatarClassName="h-[28px] w-[28px] medium-screen:h-[36px] medium-screen:w-[36px]"
              className="w-[28px] medium-screen:w-[36px]"
            />
          </div>
        </div>
      </nav>
      <div
        ref={secondaryPanelBackgroundRef}
        className="absolute top-[64px] hidden h-[48px] w-screen bg-mui-palette-common-white medium-screen:top-[90px]"
        style={{
          boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
          zIndex: 4
        }}
      />
    </div>
  );
}
