import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { prepareTitleForSubmission } from 'src/containers/shared/verify-property-section/helpers';
import {
  CreateLodgementOnlyWorkspaceInvitationsStepRequest,
  CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel,
  CreateLodgementOnlyWorkspaceMatterDetailsStepRequest,
  MatterDetailDocumentItemModel
} from '../../models';

// payload calculation for multistep process:
// POST /gateway​/api​/{version}​/Workspaces​/registration-only​/creation-details
export function prepareRegCreationDetailRequestData(
  matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel
): CreateLodgementOnlyWorkspaceMatterDetailsStepRequest {
  const {
    jurisdictionId,
    titleInformation: { titleVerificationResult },
    documents,
    groupId
  } = matterDetailsStepData;
  const workspaceType = HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  // this should never happen, titleVerificationResult should always be present here. It's guaranteed by validation
  const titleReferences = titleVerificationResult ? titleVerificationResult.titles.map(title => title.titleReference) : [];
  const docIdentifiers: HttpTypes.DocumentTypeIdentifierEnum[] = documents.map(doc => doc.documentIdentifier as HttpTypes.DocumentTypeIdentifierEnum);

  return {
    //
    jurisdictionId: jurisdictionId as HttpTypes.JurisdictionsEnum,
    titleReferences,
    workspaceType,
    groupId,
    documents: docIdentifiers
  };
}

export function prepareSingleStepRegSubmitData(
  matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel,
  currentSubscriberId: string
): CreateLodgementOnlyWorkspaceInvitationsStepRequest {
  //
  // POST /gateway/api/{version}/Workspaces/registration-only
  const {
    jurisdictionId,
    createdByReference,
    titleInformation: { titleVerificationResult },
    groupId,
    documents: formDocuments
  } = matterDetailsStepData;
  // this should never happen, titleVerificationResult should always be present here. It's guaranteed by validation
  const titleReferences = titleVerificationResult ? titleVerificationResult.titles.map(title => prepareTitleForSubmission(jurisdictionId!, title.titleReference)) : [];
  const documents: CreateLodgementOnlyWorkspaceInvitationsStepRequest['documents'] = formDocuments.map(({ documentIdentifier, roleOptions }) => {
    return {
      documentIdentifier: documentIdentifier as HttpTypes.DocumentTypeIdentifierEnum,
      roleIds: roleOptions.map(({ id }) => id),
      invitations: [] // empty by default;
    };
  });

  return {
    //
    jurisdictionId: jurisdictionId as HttpTypes.JurisdictionsEnum,
    createdByReference,
    titleReferences,
    groupId,
    documents
  };
}

export function getParentPath(fieldName: string = '') {
  const names = fieldName.split('.');
  return names.slice(0, names.length - 1).join('.');
}

export function createDocumentItem(
  documentIdentifier: HttpTypes.DocumentTypeIdentifierEnum | null = null, //
  roleOptions: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[] = []
): MatterDetailDocumentItemModel {
  //
  return {
    documentIdentifier,
    roleOptions
  };
}

export function resolveDocumentsForLinkedWorkspace(hasPaperTitle: boolean): MatterDetailDocumentItemModel[] {
  // Mor || Transaction | Dm &&
  // values.titleVerificationResult?.status == ' Success' &&
  // values.titleVerificationResult?.titles.some(t => !t.controller?.isControlledByCurrentSub)

  // ec : NominateEctToTheLodgementCase

  // pt: ConvertPctAndNominateToTheLodgementCase

  const ROLE_OPTIONS: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[] = [
    {
      //
      id: HttpTypes.WorkspaceRoleEnum.ControllingParty,
      name: 'Controlling Party'
    }
  ];
  if (hasPaperTitle) {
    return [
      //
      createDocumentItem(HttpTypes.DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase, ROLE_OPTIONS)
    ];
  }

  return [
    //
    createDocumentItem(HttpTypes.DocumentTypeIdentifierEnum.NominateEctToTheLodgementCase, ROLE_OPTIONS)
  ];
}

// explicitly use CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel typing so it indicates that we are not dealing with formValues
export function requiresLinkedWorkspace(matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel) {
  const {
    documents,
    jurisdictionId,
    titleInformation: { titleVerificationResult }
  } = matterDetailsStepData;

  if (jurisdictionId === HttpTypes.JurisdictionsEnum.VIC) {
    // https://tickleme.atlassian.net/browse/WEB-13383
    // https://tickleme.atlassian.net/browse/WEB-14454

    if (titleVerificationResult?.hasPaperTitle) {
      return true;
    }

    const docsRequiringLinkedWorkspaces: HttpTypes.DocumentTypeIdentifierEnum[] = [
      //
      HttpTypes.DocumentTypeIdentifierEnum.Mortgage,
      HttpTypes.DocumentTypeIdentifierEnum.Transfer,
      HttpTypes.DocumentTypeIdentifierEnum.DischargeOfMortgage
    ];

    if (documents.some(({ documentIdentifier }) => docsRequiringLinkedWorkspaces.includes(documentIdentifier as HttpTypes.DocumentTypeIdentifierEnum))) {
      return titleVerificationResult?.titles.some(t => !t.controller?.isControlledByCurrentSubscriber);
    }
  }
  return false;
}

export function requiresInvitationStep(matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel) {
  const { documents, isControllingParty } = matterDetailsStepData; // explicitly use global values as they contain updated documents with roles

  // we can directly scan documents for roles, since they have been already updated with relevant roles;
  return documents.some(({ roleOptions }, index) => {
    if (roleOptions.length > 1) {
      return true;
    }
    // Check isControllingParty checkbox value for additional document
    if (
      index !== 0 &&
      roleOptions.some(({ id }) => {
        return id === HttpTypes.WorkspaceRoleEnum.ControllingParty;
      })
    ) {
      return !isControllingParty;
    }
    return false;
  });
}

export function requiresMultipleTitleDealingCheck(matterDetailsStepData: CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel): boolean {
  //We will only ever have 1 document for this step (DM) for (SA), however if we ever do cater for more docs, this needs to improve.
  //https://tickleme.atlassian.net/browse/WEB-17510
  return (
    matterDetailsStepData.jurisdictionId === HttpTypes.JurisdictionsEnum.SA &&
    matterDetailsStepData.documents.length === 1 &&
    matterDetailsStepData.documents[0].documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.DischargeOfMortgage
  );
}
