import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';
import { ICertIdentifier, ICertInfo, IUserInfo } from '@sympli/digital-signing/interfaces';

export interface GetCertificatesRequest {
  userProfile: HttpTypes.UserProfileModel;
  logGroupId: string; // WEB-27575
  // leaving this one here for compatibility with old signing library
  challengeCallback?: (code: string) => void;
  challengeCompleteCallback?: () => void;
}

export interface GetCertificatesResponse {
  userInfo: IUserInfo;
  certificatesToSelect: ICertInfo[];
  previouslySavedCertIdentifier?: ICertIdentifier;
}

export const actionGetCertificates = createAsyncAction(
  //
  'GET_CERTIFICATES',
  'GET_CERTIFICATES_SUCCESS',
  // * We don't want to trigger globalErrorMiddleware as we will handle failing to get certificate manually in SigningProvider
  'GET_CERTIFICATES_FAILURE'
)<
  //
  GetCertificatesRequest,
  GetCertificatesResponse,
  { error: Error }
>();

export const actionUpdateSelectedCertificate = createAction('UPDATE_SELECTED_CERTIFICATE')<ICertIdentifier | undefined>();

export const actionResetCertificates = createAction('RESET_CERTIFICATES')();
