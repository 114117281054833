import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { useWorkspaceDetail } from '../../../../../store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from '../../../../../store/reducers/workspace/workspaceParticipants';
import { getLinkedSettlementCategoryOptions } from './helpers';
import LinkLodgementDialog from './LinkLodgementDialog';
import LinkSettlementDialog from './LinkSettlementDialog';
import { LINKED_WORKSPACE_LOOKUP_OPTIONS } from './models';

export interface LinkSettlementDialogProps {
  workspaceId: string;
  participantId: string;
  open: boolean;
  onClose(): void;
}

function LinkWorkspaceDialogContainer({ open, workspaceId, participantId, onClose }: LinkSettlementDialogProps): JSX.Element {
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId);
  const isLinkedSettlementEnabled = useFeatureFlag(FeatureToggleEnum.linkedSettlementsSupportedJurisdictions);
  const isLinkedLodgementEnabled = useFeatureFlag(FeatureToggleEnum.isLinkedLodgementsEnabled);

  const dispatch = useSafeDispatch(useDispatch());
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const currentParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const clusterId = workspaceDetail.detail?.linkedWorkspaceCluster?.clusterId;
  const workspaceRoleId = currentParticipant?.workspaceRole.id;
  const categoryOptions = getLinkedSettlementCategoryOptions(workspaceDetail.detail?.linkedWorkspaceCluster);

  // there are two feature flags controls the two radio buttons and
  // if only one feature flag is enabled we should hide the radio buttons
  // if both feature flags are enabled then if one enabled then the rest will be disabled
  const linkedWorkspaceType = workspaceDetail.detail?.linkedWorkspaceCluster?.linkedWorkspaceType;
  const shouldShowRadioButtons = isLinkedLodgementEnabled && isLinkedSettlementEnabled;
  const resolveItemDisabled = (option: LookupEnumModel<HttpTypes.LinkedWorkspaceTypeEnum>) => {
    if (workspaceDetail.detail?.isLinkedLodgementEnabled === false && option.id === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement) {
      return true;
    }

    if (workspaceDetail.detail?.isLinkedSettlementEnabled === false && option.id === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement) {
      return true;
    }

    return false;
  };

  // default to linked settlement
  const [selectedLinkedType, setSelectedType] = useState<HttpTypes.LinkedWorkspaceTypeEnum | null>();

  useEffect(() => {
    let selectedLinkedWorkspaceType: HttpTypes.LinkedWorkspaceTypeEnum = HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement;
    if (linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement) {
      selectedLinkedWorkspaceType = HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement;
    }

    if (linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement) {
      selectedLinkedWorkspaceType = HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement;
    }

    if (linkedWorkspaceType === undefined && workspaceDetail.detail?.isLinkedSettlementEnabled && !workspaceDetail.detail?.isLinkedLodgementEnabled) {
      selectedLinkedWorkspaceType = HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement;
    }

    if (linkedWorkspaceType === undefined && workspaceDetail.detail?.isLinkedLodgementEnabled && !workspaceDetail.detail?.isLinkedSettlementEnabled) {
      selectedLinkedWorkspaceType = HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement;
    }

    setSelectedType(selectedLinkedWorkspaceType);
  }, [linkedWorkspaceType, workspaceDetail.detail?.isLinkedSettlementEnabled, workspaceDetail.detail?.isLinkedLodgementEnabled, setSelectedType]);

  const handleOnLinkTypeChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: HttpTypes.LinkedWorkspaceTypeEnum) => {
      setSelectedType(resolvedValue);
    },
    [setSelectedType]
  );

  return (
    <ConfirmationDialog //
      open={open}
      onClose={onClose}
      title={
        <FormGroup className="border-none pb-0" title={<Typography variant="subtitle1">Link workspace</Typography>}>
          {shouldShowRadioButtons && (
            <Radio //
              options={LINKED_WORKSPACE_LOOKUP_OPTIONS}
              format="number"
              onChange={handleOnLinkTypeChange}
              resolveItemDisabled={resolveItemDisabled}
              value={selectedLinkedType}
              aria-label="link Workspace type"
              classes={{ formControlLabelRoot: 'ml-[35px]' }}
            />
          )}
        </FormGroup>
      }
      showActionButtons={false}
      classes={{ dialogPaper: 'w-[1080px] max-w-[1080px] pb-[5px]', dialogTitle: 'bg-[var(--neutral-025)]' }}
    >
      {selectedLinkedType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement && (
        <LinkSettlementDialog
          participantId={participantId}
          workspaceId={workspaceId}
          onClose={onClose}
          clusterId={clusterId}
          workspaceRoleId={workspaceRoleId}
          dispatch={dispatch}
          categoryOptions={categoryOptions}
        />
      )}

      {selectedLinkedType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement && (
        <LinkLodgementDialog participantId={participantId} workspaceId={workspaceId} onClose={onClose} workspaceRoleId={workspaceRoleId} dispatch={dispatch} />
      )}
    </ConfirmationDialog>
  );
}

export default React.memo(LinkWorkspaceDialogContainer);
