import { createAsyncAction } from 'typesafe-actions';

import { SubscriberAssignableGroupsApiResponse, WorkspaceGroupRequestModel, WorkspaceGroupsApiResponse } from 'src/containers/dashboard/shared/models';
import { SingleGroupMembersRequestModel } from './models';
import { GroupMembersApiResponse } from './reducers/groupMembers';
import { SupportedJurisdictionApiResponse } from './reducers/supportedJurisdictions';
import { UserJurisdictionsAPIResponse } from './reducers/userJurisdictions';

export const actionFetchSupportedJurisdictions = createAsyncAction(
  //
  'FETCH_SUPPORTED_JURISDICTIONS',
  'FETCH_SUPPORTED_JURISDICTIONS_SUCCESS',
  'FETCH_SUPPORTED_JURISDICTIONS_ERROR'
)<
  //
  undefined,
  { data: SupportedJurisdictionApiResponse },
  { error: Error }
>();

export const actionFetchWorkspaceGroups = createAsyncAction(
  //
  'FETCH_WORKSPACE_GROUPS',
  'FETCH_WORKSPACE_GROUPS_SUCCESS',
  'FETCH_WORKSPACE_GROUPS_ERROR'
)<
  //
  WorkspaceGroupRequestModel,
  { data: WorkspaceGroupsApiResponse },
  { error: Error }
>();

export const actionFetchGroupMembers = createAsyncAction(
  //
  'FETCH_All_GROUP_MEMBERS',
  'FETCH_All_GROUP_MEMBERS_SUCCESS',
  'FETCH_All_GROUP_MEMBERS_ERROR'
)<
  //
  undefined,
  { data: GroupMembersApiResponse },
  { error: Error }
>();

export const actionFetchSingleGroupMembers = createAsyncAction(
  //
  'FETCH_SINGLE_GROUP_MEMBERS',
  'FETCH_SINGLE_GROUP_MEMBERS_SUCCESS',
  'FETCH_SINGLE_GROUP_MEMBERS_ERROR'
)<
  //
  SingleGroupMembersRequestModel,
  { data: GroupMembersApiResponse },
  { error: Error }
>();

export const actionFetchSubscriberAssignableGroups = createAsyncAction(
  //
  'FETCH_SUBSCRIBER_ASSIGNABLE_GROUPS',
  'FETCH_SUBSCRIBER_ASSIGNABLE_GROUPS_SUCCESS',
  'FETCH_SUBSCRIBER_ASSIGNABLE_GROUPS_ERROR'
)<
  //
  undefined,
  { data: SubscriberAssignableGroupsApiResponse },
  { error: Error }
>();

export const actionFetchUserJurisdictions = createAsyncAction(
  //
  'FETCH_USER_JURISDICTIONS',
  'FETCH_USER_JURISDICTIONS_SUCCESS',
  'FETCH_USER_JURISDICTIONS_ERROR'
)<
  //
  undefined,
  { data: UserJurisdictionsAPIResponse },
  { error: Error }
>();
