import { HttpTypes } from '@sympli/api-gateway/types';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { StampDutyPaymentMethodEnum } from '../../qld-stamp-duty/models';

const PAID_STATEMENT = 'No outstanding payable duty.';

const payableDutyStatement = (payableDuty: number) => `${currency(payableDuty)} payment has been automatically added to purchaser's directions.`;

export function resolveVerifiedDescription(jurisdictionId: HttpTypes.JurisdictionsEnum, payableDuty: number, paymentOption?: StampDutyPaymentMethodEnum) {
  switch (jurisdictionId) {
    case HttpTypes.JurisdictionsEnum.NSW:
    case HttpTypes.JurisdictionsEnum.VIC:
      return payableDuty === 0 ? PAID_STATEMENT : payableDutyStatement(payableDuty);
    case HttpTypes.JurisdictionsEnum.QLD:
      switch (paymentOption) {
        case StampDutyPaymentMethodEnum.PaidToCommissioner:
          return PAID_STATEMENT;
        case StampDutyPaymentMethodEnum.ReceivedBySelfAssessor:
          return 'Duty payments must be received by you prior to settlement.';
        case StampDutyPaymentMethodEnum.CommittedAsPartOfElno:
        default:
          return payableDutyStatement(payableDuty);
      }
    case HttpTypes.JurisdictionsEnum.SA:
      return payableDuty === 0 ? PAID_STATEMENT : '';
    default:
      return '';
  }
}
