import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import MuiSkeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import { getVariant } from 'src/containers/dashboard/shared/formatters/helpers';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import MultipleLodgementCaseHeader from 'src/containers/shared/document-list/components/document-list-base/components';
import {
  consolidateDocumentStatus,
  groupDocumentsByLodgementCaseId,
  resolveColorVariant,
  resolveDocumentParticipantMode,
  resolveDocumentStatusText,
  sortingByLodgementCase
} from 'src/containers/shared/document-list/helpers';
import { DocumentListItemParticipantMode, DocumentListItemStatusEnum } from 'src/containers/shared/document-list/models';
import { resolveLodgementCaseStepAndVariant } from 'src/containers/shared/lodgement-case-status/conversion';
import { StatusProgressModel } from 'src/containers/shared/lodgement-case-status/models';
import { useScreenSize } from 'src/hooks';
import { WorkspaceDetailState } from 'src/store/reducers/workspace/workspaceDetail';
import { WorkspaceDocumentsState } from 'src/store/reducers/workspace/workspaceDocuments';
import { ScreenSizeVariant } from 'src/theme';
import MultipleLodgementCaseMessage from './components/multiple-lodgement-case-message';
import { LodgementCaseDetail } from './models';

type Row = {
  documentName: string;
  workspaceRoles: string[]; // roles of individual document participants (e.g. NOA has multiple parties)
  subscriberNames: string[]; // names of subscribers participating on document
  documentStatusText: string;
  uiStatus: DocumentListItemStatusEnum;
};

type NestedFormatterProps = Pick<FormatterInputModel<Row>, 'row' | 'tooltipHandlers'> & {
  className?: string;
  isReady: boolean;
};

function workspaceRolesFormatter({
  //
  className,
  row,
  isReady,
  tooltipHandlers
}: NestedFormatterProps) {
  return (
    <div data-binding="workspaceRoles" className={classNames('truncated font-[400] text-[var(--neutral-400)]', className)} {...(isReady && tooltipHandlers)}>
      {isReady ? row.workspaceRoles.join('/') : <MuiSkeleton variant="text" />}
    </div>
  );
}

function subscriberNamesFormatter({
  //
  className,
  row,
  isReady,
  tooltipHandlers
}: NestedFormatterProps) {
  return (
    <div className={classNames('font-[400] text-[var(--neutral-400)]', className)}>
      {isReady ? (
        row.subscriberNames.map((sn, i) => {
          return (
            <div key={i} data-binding={`subscriberNames[${i}]`} className="truncated" {...tooltipHandlers}>
              {sn}
            </div>
          );
        })
      ) : (
        <MuiSkeleton variant="text" />
      )}
    </div>
  );
}

function getRows(items: HttpTypes.WorkspaceDocumentSummary[], participantId: string, isCriticalRolesEnabled: boolean): Row[] {
  return items.map(document => {
    const {
      documentForm,
      documentStatus: { id: documentStatusId },
      documentParticipants,
      requiredSigners: numberOfRequiredSigners,
      jurisdiction: { id: jurisdictionId },
      lodgementDetail,
      documentActions
    } = document;
    // identify primary participants
    const primaryDocumentParticipants = documentParticipants.filter(item => item.isPrimaryDocumentParticipant);
    // check whether currentParticipant is listed as a document participant
    const currentDocumentParticipant = documentParticipants.find(item => item.id === participantId);
    const data = {
      documentType: documentForm.documentType,
      documentStatusId,
      documentParticipants,
      primaryDocumentParticipants,
      currentDocumentParticipant,
      numberOfRequiredSigners,
      jurisdictionId,
      lrDocumentId: lodgementDetail?.lrDocumentId,
      documentLodgementStatus: lodgementDetail?.status,
      documentActions,
      isCriticalRolesEnabled
    };
    const participantMode: DocumentListItemParticipantMode = resolveDocumentParticipantMode(document, participantId);
    const uiStatus: DocumentListItemStatusEnum = consolidateDocumentStatus(participantMode, data);
    const documentStatusText: string = resolveDocumentStatusText(uiStatus, data).replace(/partially /i, '');

    return {
      documentName: documentForm.name,
      workspaceRoles: documentParticipants.map(p => p.workspaceRole.name),
      subscriberNames: documentParticipants.map(p => p.name),
      documentStatusText,
      uiStatus
    };
  });
}

function DocumentsCardDetail({
  //
  participantId,
  userId,
  workspaceDocumentsState,
  workspaceDetailState,
  isCriticalRolesEnabled
}: {
  participantId: string;
  userId: string;
  workspaceDocumentsState: WorkspaceDocumentsState;
  workspaceDetailState: WorkspaceDetailState;
  isCriticalRolesEnabled: boolean;
}) {
  const multipleLodgementCaseReadMeMessage = `${userId}_financial_dashboard_mlc_readme`;
  const [showMessage, setShowMessage] = useState(false);
  const isReady: boolean =
    (workspaceDocumentsState.status === 'resolved' || workspaceDocumentsState.status === 'refetching') &&
    (workspaceDetailState.status === 'resolved' || workspaceDetailState.status === 'refetching');
  const isLoading = workspaceDocumentsState.isLoading || workspaceDetailState.isLoading;
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const dismissMessageHandler = () => {
    setShowMessage(false);
    localStorage.setItem(multipleLodgementCaseReadMeMessage, 'true');
  };

  useEffect(() => {
    if (localStorage.getItem(multipleLodgementCaseReadMeMessage) !== 'true') setShowMessage(true);
  }, [multipleLodgementCaseReadMeMessage, setShowMessage]);

  const lodgementCaseDetails: LodgementCaseDetail[] = React.useMemo(() => {
    const groupedDocumentsByLodgementCaseId = groupDocumentsByLodgementCaseId(workspaceDocumentsState.items);

    const lodgementCaseDetailModels: LodgementCaseDetail[] = [];
    for (let [key, value] of groupedDocumentsByLodgementCaseId) {
      let lodgementCaseDetail = workspaceDetailState.detail?.lodgementCases.find(x => x.id === key);
      lodgementCaseDetailModels.push({
        lodgementCase: lodgementCaseDetail,
        documents: value
      });
    }

    // sort by the lodgement case created date
    sortingByLodgementCase(lodgementCaseDetailModels);

    return lodgementCaseDetailModels;
  }, [workspaceDetailState.detail?.lodgementCases, workspaceDocumentsState.items]);

  const columns: ColumnsModel<Row> = {
    documentNameAndRole: {
      verticalAlign: 'top',
      label: 'DOCUMENTS',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        return (
          <div className="text-[12px] leading-[14px]">
            <div data-binding="documentName" className="truncated font-[700] text-[var(--neutral-1000)]" {...(isReady && tooltipHandlers)}>
              {isReady ? row.documentName : <MuiSkeleton variant="text" />}
            </div>
            {(screenVariant === 'large-screen' || screenVariant === 'medium-screen') &&
              workspaceRolesFormatter({
                //
                row,
                tooltipHandlers,
                isReady
              })}
            {screenVariant === 'medium-screen' &&
              subscriberNamesFormatter({
                //
                row,
                tooltipHandlers,
                isReady
              })}
          </div>
        );
      }
    },
    ...((screenVariant === 'large-screen' || screenVariant === 'small-laptop') && {
      subscriberName: {
        thClassName: screenVariant === 'small-laptop' ? 'w-[170px]' : 'w-[75px]',
        width: screenVariant === 'small-laptop' ? 170 : 75,
        verticalAlign: 'top',
        label: '',
        formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
          return (
            <div className="text-[12px] leading-[14px]">
              {screenVariant === 'small-laptop' &&
                workspaceRolesFormatter({
                  //
                  row,
                  tooltipHandlers,
                  isReady
                })}
              {subscriberNamesFormatter({
                //
                row,
                tooltipHandlers,
                isReady
              })}
            </div>
          );
        }
      }
    }),

    documentStatus: {
      verticalAlign: 'top',
      thClassName: 'w-[95px]',
      width: 95,
      label: 'STATUS',
      formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
        const variant = resolveColorVariant(row.uiStatus) || 'warning';

        return (
          <div data-binding="documentStatus">
            {isReady ? (
              <VerticalStatusLine //
                text={row.documentStatusText}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    }
  };

  if (isLoading) {
    return (
      <CardDetail //
        columns={columns}
        rows={[]}
        {...(isLoading && {
          isLoading: true,
          pageSize: 4
        })}
      />
    );
  }

  // for non MLC workspace, there would be only one Table
  if (lodgementCaseDetails.length === 1) {
    const rows: Row[] = getRows(workspaceDocumentsState.items, participantId, isCriticalRolesEnabled);

    return (
      <CardDetail //
        columns={columns}
        rows={rows}
        {...(isReady && {
          pageSize: rows.length
        })}
      />
    );
  }

  return (
    <>
      <FlexLayout justifyContent="space-between" alignItems="center" className="mb-[16px] border-x-0 border-b border-t-0 border-solid border-[var(--greek-waters)]">
        <Typography className="pb-[8px] font-volkswagen-serial-medium text-[14px] font-[500] uppercase text-[var(--sympli-green)]">Documents</Typography>
        <Typography className="w-[95px] pb-[8px] font-volkswagen-serial-medium text-[10px] font-[500] uppercase text-[var(--sympli-green)]">Status</Typography>
      </FlexLayout>
      {lodgementCaseDetails.map(x => {
        if (!x.lodgementCase) {
          return null;
        }
        const { lodgementCaseStatusId, id, isPrimary, name } = x.lodgementCase;
        const allDocumentsFullySigned: boolean = x.documents.every(d => d.isFullySignedByAllParticipants);

        const info: Pick<StatusProgressModel, 'step' | 'variant'> | null = resolveLodgementCaseStepAndVariant({
          lodgementCaseStatusId: lodgementCaseStatusId,
          allDocumentsSigned: allDocumentsFullySigned,
          workspaceStatusId: workspaceDetailState.detail?.workspaceStatus.id
        });

        if (!info) {
          return null;
        }
        const text = info.step.description;
        const variant = getVariant(info);
        const rows: Row[] = getRows(x.documents, participantId, isCriticalRolesEnabled);

        return (
          <Box key={id} className="mb-[16px]">
            <MultipleLodgementCaseHeader
              headerTypographyClassName="text-[14px]"
              name={name}
              text={text}
              variant={variant}
              defaultOpenExpansion={isPrimary}
              disableExpansion={isPrimary}
            >
              <CardDetail //
                columns={columns}
                rows={rows}
                {...(isReady && {
                  pageSize: rows.length
                })}
                noHeader
              />
            </MultipleLodgementCaseHeader>
            {isPrimary && showMessage && <MultipleLodgementCaseMessage dismissMessageHandler={dismissMessageHandler} />}
          </Box>
        );
      })}
    </>
  );
}

export default DocumentsCardDetail;
