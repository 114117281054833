import { HttpTypes } from '@sympli/api-gateway/types';

export interface LogModel extends Omit<HttpTypes.SubscriberActivityLog, 'created'> {
  template: string;
  created: Date;
}
export interface GroupedLogItem {
  formattedDate: string;
  logs: LogModel[];
}

export enum SubscriberActivityLogLinkEnum {
  GroupDetailLink = 1,
  UserDetailLink = 2,
  DefaultFieldPopulationJurisdictionLink = 3,
  LinkedSubscriberDetailLink = 4,
  LoanAccountSignerDetailLink = 5,
  LoanAccountSignerUserLink = 6,
  LinkedSubscriberGroupDetailLink = 7,
  AutoJustificationDetailLink = 8,
  FinancialAccountsLink = 9
}
