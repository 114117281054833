import { HttpTypes } from '@sympli/api-gateway/types';

export const WorkspaceTypeDisplayMapping: Partial<Record<HttpTypes.WorkspaceTypeEnum, string>> = {
  [HttpTypes.WorkspaceTypeEnum.FinancialSettlement]: 'Financial',
  [HttpTypes.WorkspaceTypeEnum.RegistrationOnly]: 'Standalone'
};

export interface WorkspaceDetailRouteParams {
  workspaceId: string;
  participantId: string;
}
