import { useContext, useState } from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import DirectionsContext from 'src/containers/workspace/financial/directions/Directions.context';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { bsbCheckFormikHelper } from '../../../../components/direction-payee-detail/helper';
import { BankTransferDistribution, ConditionalDistributionModel } from '../../../../components/direction-record/models';
import { getBankDetails, getDefaultAccountId } from '../../../../helper';
import { FinancialLineItemLabelEnum } from '../../../../models';
import { CategoryEnum, DischargeDirectionsFormModel } from '../../models';

import type { SafeDispatch } from 'src/hooks';

const useMortgageEditor = (itemFieldName: string, dispatch: SafeDispatch<Action>) => {
  const { setFocusLabel } = useContext(DirectionsContext);
  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveBankTransferDistribution = (
    itemFieldName: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    workspaceId: string,
    participantId: string,
    savingFssLineItem: boolean,
    keepDialogOpen?: boolean,
    distribution?: BankTransferDistribution
  ) => {
    setIsLoading(true);

    const { values } = formikProps;

    const bankDetails = _get(values, fieldName('bankDetails'));

    return bsbCheckFormikHelper(itemFieldName, bankDetails, formikProps, workspaceId, participantId, dispatch, savingFssLineItem, keepDialogOpen, distribution) //
      .finally(() => {
        setIsLoading(false);
        setFocusLabel(FinancialLineItemLabelEnum.Payment);
      });
  };

  const prePopulateBankDetails = (accountId: string, formikProps: FormikProps<DischargeDirectionsFormModel>, financialAccounts?: HttpTypes.FinancialAccountsApiResponse) => {
    const { values, setFieldValue, setFieldTouched } = formikProps;

    const bankDetailsField = fieldName('bankDetails');

    const defaultBankDetails = getBankDetails(accountId, financialAccounts ?? []);

    const bankDetails = _get(values, bankDetailsField);

    const updatedBankDetails: HttpTypes.BankDetailsModel = {
      ...bankDetails,
      accountName: defaultBankDetails?.accountName,
      accountNumber: defaultBankDetails?.accountNumber,
      bsb: defaultBankDetails?.bsbNumber,
      bankName: defaultBankDetails?.bankName
    };

    setFieldValue(bankDetailsField, updatedBankDetails);

    setFieldTouched(bankDetailsField);
  };

  const autoSelectAccount = (
    selectedCategory: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    usage?: HttpTypes.GeneralAccountsUsagesApiResponse,
    currentParticipant?: HttpTypes.WorkspaceParticipant,
    financialAccounts?: HttpTypes.FinancialAccountsApiResponse
  ) => {
    const { setFieldValue } = formikProps;

    if (selectedCategory !== CategoryEnum.LinkedPayment) {
      clearLinkedDetails(formikProps);
    }

    const selectAccountsField = fieldName('prepopulateAccountId');
    const workspaceRole = currentParticipant?.workspaceRole.id;
    const defaultAccountId = getDefaultAccountId(selectedCategory, workspaceRole, usage);

    if (defaultAccountId) {
      setFieldValue(selectAccountsField, defaultAccountId);
      prePopulateBankDetails(defaultAccountId, formikProps, financialAccounts);
      return;
    }

    setFieldValue(selectAccountsField, '');

    clearBankDetails(formikProps);
  };

  const clearLinkedDetails = (formikProps: FormikProps<DischargeDirectionsFormModel>) => {
    const { values, setFieldValue } = formikProps;

    const paymentMethod = _get(values, fieldName('paymentMethod'));
    if (paymentMethod === HttpTypes.PaymentMethodEnum.Linked) {
      setFieldValue(fieldName('paymentMethod'), null);
      setFieldValue(fieldName('amount'), undefined);
      setFieldValue(fieldName('reference'), undefined);
      setFieldValue(fieldName('linkedSettlementItem'), null);
      setFieldValue(fieldName('linkedSettlementFundsNotRequired'), false);
    }
  };

  const clearBankDetails = (formikProps: FormikProps<DischargeDirectionsFormModel>) => {
    const { values, setFieldValue, setFieldTouched } = formikProps;

    const bankDetails = _get(values, fieldName('bankDetails'));

    const updatedBankDetails: HttpTypes.BankDetailsModel = {
      ...bankDetails,
      accountName: '',
      reference: '',
      bsb: '',
      accountNumber: ''
    };

    setFieldValue(fieldName('bankDetails'), updatedBankDetails);
    setFieldTouched(fieldName('bankDetails'));
  };

  return {
    isLoading,
    setIsLoading,
    actions: {
      saveBankTransferDistribution,
      prePopulateBankDetails,
      autoSelectAccount,
      clearLinkedDetails,
      clearBankDetails
    }
  };
};

export default useMortgageEditor;
