import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export interface DirectionsListApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionResetDirectionsList = createAction('RESET_DIRECTIONS_LIST')();

export const actionUpdateDirectionsListPaymentsStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_PAYMENTS_STATUS'
)<
  //
  Partial<HttpTypes.WorkspaceDirectionsStatusApiResponse['paymentsStatus']>
>();

export const actionUpdateDirectionsListSourceFundsStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_SOURCE_FUNDS_STATUS'
)<
  //
  Partial<HttpTypes.WorkspaceDirectionsStatusApiResponse['sourceFundsStatus']>
>();

export const actionUpdateDirectionsListTaaStatus = createAction(
  //
  'UPDATE_DIRECTIONS_LIST_TAA_STATUS'
)<
  //
  Partial<Exclude<HttpTypes.WorkspaceDirectionsStatusApiResponse['trustAccountAuthorisationRecordStatuses'], undefined>[number]>
>();

export const actionUpdateDirectionsWorkspaceCurrentParticipantDetail = createAction(
  //
  'UPDATE_DIRECTIONS_WORKSPACE_CURRENT_PARTICIPANT_DETAIL'
)<{
  workspaceParticipant: Partial<HttpTypes.WorkspaceParticipant>;
}>();

export const actionFetchDirectionsList = createAsyncAction(
  //
  'FETCH_DIRECTIONS_LIST',
  'FETCH_DIRECTIONS_LIST_SUCCESS',
  'FETCH_DIRECTIONS_LIST_ERROR'
)<
  //
  DirectionsListApiRequest,
  { data: HttpTypes.WorkspaceDirectionsStatusApiResponse },
  { error: Error }
>();
