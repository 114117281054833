import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useConfirmationDialogStyles = makeStyles(
  (theme: Theme) => ({
    dialogPaper: {
      width: 360,
      borderRadius: 4,
      background: 'var(--neutral-000)',
      boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.15)'
    },
    withdrawInviteButton: {
      padding: '8px 32px',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      color: colors.NEUTRAL_000,
      borderRadius: 34,
      backgroundColor: colors.WATERMELON,

      '&.MuiButton-containedPrimary:hover': {
        boxShadow: 'none',
        backgroundColor: colors.WATERMELON_DARK
      }
    },
    description: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    }
  }),
  {
    name: 'ConfirmationDialogStylesOverrides'
  }
);
