import _isEqual from 'lodash-es/isEqual';
import { matchPath } from 'react-router-dom';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import environments from 'src/@core/environments';
import { useStoreSelector } from 'src/hooks';
import {
  actionFetchWorkspaceBasicInfo,
  actionPreloadWorkspaceBasicInfo,
  actionUpdateWorkspaceIsLockedStatus,
  actionUpdateWorkspaceStatusToArchived,
  actionUpdateWorkspaceStatusToLodgeInProgress,
  WorkspaceRelatedApiRequest
} from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';

export interface WorkspaceBasicInfoState {
  detail?: HttpTypes.WorkspaceBasicInfoApiResponse;
  cacheFallback?: {
    matter?: string;
  };
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
}

const initialState: WorkspaceBasicInfoState = {
  detail: undefined,
  cacheFallback: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

const compareArgs = (a?: WorkspaceRelatedApiRequest, b?: WorkspaceRelatedApiRequest) => _isEqual(a, b);

export function useWorkspaceBasicInfo(workspaceId: string) {
  // workspace basic info is only related to workspaceId
  const state = useStoreSelector(store => store.workspaceBasicInfo);
  if (state.args?.workspaceId !== workspaceId) {
    return initialState;
  }
  return state;
}

const workspaceBasicInfoReducer = createReducer<
  //
  WorkspaceBasicInfoState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceBasicInfo.request, (state, action): WorkspaceBasicInfoState => {
    const isMatchingWorkspace = compareArgs(state.args, action.payload);
    const status = isMatchingWorkspace ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      cacheFallback: isMatchingWorkspace ? state.cacheFallback : initialState.cacheFallback,
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchWorkspaceBasicInfo.success,
    (state, action): WorkspaceBasicInfoState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload.detail
    })
  )
  .handleAction(
    actionFetchWorkspaceBasicInfo.failure,
    (state, action): WorkspaceBasicInfoState => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    })
  )
  // preload done by dashboards to improve UX when loading workspace page
  .handleAction(actionPreloadWorkspaceBasicInfo, (state, action): WorkspaceBasicInfoState => {
    const { data, participantId, matter: reference } = action.payload;
    const args: WorkspaceRelatedApiRequest = {
      workspaceId: data.id,
      participantId
    };
    if (
      !matchPath(
        {
          path: `${environments.BASENAME}/workspace/:workspaceId/*` // only dashboard should over-write workspaceBasicInfo, need to exclude workspace detail pages, check https://tickleme.atlassian.net/browse/WEB-27376
        },
        window.location.pathname
      ) &&
      !compareArgs(state.args, args)
    ) {
      return {
        ...state,
        detail: action.payload.data,
        cacheFallback: {
          matter: reference
        },
        status: 'resolved',
        isLoading: false,
        isRefetching: false,
        args,
        error: undefined
      };
    }
    return state;
  })
  // other
  .handleAction(actionUpdateWorkspaceStatusToLodgeInProgress, (state, action): WorkspaceBasicInfoState => {
    // make sure that update is related to currently displayed workspace
    if (state.detail?.id !== action.payload) {
      return state;
    }

    if (state.detail == null) {
      return state;
    }

    /**
     * !important
     * since this is only used for standalone workspaces, so we can safely assume lodgementCases has only one item
     */
    const newLodgementCase: HttpTypes.LodgementCase = { ...state.detail.lodgementCases[0] };
    newLodgementCase.lodgementCaseStatusId = HttpTypes.LodgementCaseStatusEnum.LodgementRequested;

    return {
      ...state,
      detail: {
        ...state.detail,
        lodgementCases: [newLodgementCase],
        isLocked: true
      }
    };
  })
  .handleAction(actionUpdateWorkspaceStatusToArchived, (state, action): WorkspaceBasicInfoState => {
    // make sure that update is related to currently displayed workspace
    if (state.detail?.id !== action.payload) {
      return state;
    }

    if (state.detail == null) {
      return state;
    }

    return {
      ...state,
      detail: {
        ...state.detail,
        workspaceStatusId: HttpTypes.WorkspaceStatusEnum.Archived
      }
    };
  })
  .handleAction(actionUpdateWorkspaceIsLockedStatus, (state, action): WorkspaceBasicInfoState => {
    // prevent update of an unloaded workspace, also need this check to pass TS verification for ...state.detail since detail is optional
    if (state.detail == null) {
      return state;
    }

    return {
      ...state,
      detail: {
        ...state.detail,
        isLocked: action.payload
      }
    };
  });

export default workspaceBasicInfoReducer;
