import * as React from 'react';

import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';

interface Row {
  isFinancialBalanced: boolean;
}

function FundsCardDetail({
  //
  isFinancialBalanced = false
}: {
  isFinancialBalanced?: boolean;
}) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const rows: Row[] = [
    {
      isFinancialBalanced
    }
  ];

  const columns: ColumnsModel<Row> = {
    funds: {
      label: 'Funds',

      formatter: ({ row }: FormatterInputModel<Row>) => {
        return (
          <div className="text-[12px] leading-[14px]">
            <div className="truncated font-[700] text-[var(--neutral-1000)]">Settlement Balance</div>
          </div>
        );
      }
    },
    status: {
      thClassName: screenVariant !== 'small-laptop' ? 'w-[100px]' : 'w-[130px]',
      label: 'status',
      formatter: ({ row: { isFinancialBalanced }, tooltipHandlers }: FormatterInputModel<Row>) => {
        const text = isFinancialBalanced ? 'Balanced' : 'Unbalanced';
        const variant = isFinancialBalanced ? 'ok' : 'error';

        return (
          <div data-binding="status">
            <VerticalStatusLine //
              text={text}
              variant={variant}
              tooltipHandlers={tooltipHandlers}
              className="h-[14px]"
              textClassName="truncated"
            />
          </div>
        );
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
    />
  );
}

export default React.memo(FundsCardDetail);
