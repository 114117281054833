import { createAction } from 'typesafe-actions';

import { FeatureToggleEnum } from '../../auth/feature-toggle/models';

export interface FlagStatusByUI {
  name: FeatureToggleEnum;
  status: boolean;
}

export const actionUpdateFeatureFlagsControlledByUI = createAction(
  //
  'UPDATE_FEATURE_FLAGS_ENABLED_BY_UI'
)<Array<FlagStatusByUI>>();

export const actionClearFeatureFlagsControlledByUI = createAction(
  //
  'CLEAR_FEATURE_FLAGS_ENABLED_BY_UI'
)();
