import { ComparisonGroup, ComparisonsMap, Container, MergeParty, PartyMap, PartyMatch, PartyMergeResult, ProprietorGroup, ReferenceGroup } from './model';

/**
 * https://tickleme.atlassian.net/wiki/spaces/~5de440aa7095a40d125388c9/pages/2415887591/Party+Merge+NSW
 */

export default class PartyMerger {
  /**
   * Will merge the parties in one container when structure matched otherwise will return all without merge
   * @param containers Parties to compare
   * @returns Return the merged containers, single when structure same
   */
  static merge<T extends MergeParty>(containersToMerge: Container<T>[]): PartyMergeResult<T> {
    let containers = containersToMerge.map(c => c.clone().resetMetadata(containersToMerge.length > 1));
    if (containers.length < 2) return new PartyMergeResult(containers);
    const comparisonsMap = this.createComparisonsMap(containers);
    return mergeContainers();

    function mergeContainers(): PartyMergeResult<T> {
      if (comparisonsMap.comparisons.length === 0) return new PartyMergeResult(containers, comparisonsMap.failedReason);

      const reference = containers[0];
      reference.tenancyDetail.proprietorGroups.forEach(referenceProprietorGroup => {
        const matchingProprietorGroups = comparisonsMap.comparisons.flatMap(list => list.filter(rg => rg.proprietorGroup === referenceProprietorGroup));

        const matchingGroupHash: string = `${[
          referenceProprietorGroup.parties[0].mergeMetadata!.identity.groupHash,
          ...matchingProprietorGroups.flatMap(e => e.comparisonGroups.flatMap(cg => cg.proprietorGroup.parties[0].mergeMetadata!.identity.groupHash))
        ].join('|')}`;

        referenceProprietorGroup.parties.forEach(party => {
          party.mergeMetadata!.matchingGroupHash = matchingGroupHash;
          const matchedPartiesForAll = matchingProprietorGroups.flatMap(rg => rg.comparisonGroups.flatMap(cg => cg.matchingPartyMaps.filter(mp => mp.referenceParty === party)));
          const hasMatching: boolean = matchedPartiesForAll.length === matchingProprietorGroups.length;

          if (hasMatching) {
            party.mergeMetadata!.requiresJustification = false;
            assignMatchingItemHash<T>(
              matchedPartiesForAll.map(e => e.matchedParty),
              party
            );
          }
        });

        addRemainingUnmatchedItems();

        function addRemainingUnmatchedItems() {
          matchingProprietorGroups.forEach(e =>
            e.comparisonGroups.forEach(cg =>
              cg.proprietorGroup.parties.forEach(p => {
                p.mergeMetadata!.matchingGroupHash = matchingGroupHash;
                const noMatchFound = p.mergeMetadata!.matchingItemHash === p.mergeMetadata!.identity.itemHash;
                if (noMatchFound) referenceProprietorGroup.mergedParties.push(p);
              })
            )
          );
        }
      });

      const failedReason = comparisonsMap.validate();
      return new PartyMergeResult([reference], failedReason);
    }
  }

  /**
   * Create the mapping for best matches across containers groups
   * @param containers Containers to merge
   * @returns returns the result of matching between containers
   */
  private static createComparisonsMap<T extends MergeParty>(containers: Container<T>[]): ComparisonsMap<T> {
    const reference = containers[0]; // Reference container used for comparison with all other containers
    const containersToCompare = containers.slice(1);

    const comparisons = new Array<Array<ReferenceGroup<T>>>();
    for (const containerToCompare of containersToCompare) {
      const proprietorGroups = containerToCompare.tenancyDetail.proprietorGroups;
      if (reference.tenancyDetail.proprietorGroups.length !== proprietorGroups.length) {
        return ComparisonsMap.structureNotSame();
      }

      const referenceGroups: ReferenceGroup<T>[] = [];
      comparisons.push(referenceGroups);
      for (const referenceProprietorGroup of reference.tenancyDetail.proprietorGroups) {
        const referenceGroup = new ReferenceGroup(referenceProprietorGroup);
        referenceGroups.push(referenceGroup);
        const proprietorGroupsToCompare = proprietorGroups.filter(e => e.parties.length === referenceProprietorGroup.parties.length);
        if (!proprietorGroupsToCompare.length) return ComparisonsMap.structureNotSame();

        proprietorGroupsToCompare.forEach(proprietorGroup => {
          const matchingPartyMaps = this.getMatchingParties(referenceProprietorGroup.parties, proprietorGroup.parties);
          referenceGroup.comparisonGroups.push(new ComparisonGroup<T>(proprietorGroup, matchingPartyMaps));
        });
      }

      const groupTaken: ProprietorGroup<T>[] = [];
      const orderedReferenceGroups = referenceGroups.sort((a, b) => b.maxMatched - a.maxMatched || b.maxNameMatched - a.maxNameMatched);

      for (const orderedReferenceGroup of orderedReferenceGroups) {
        const comparisonGroups = orderedReferenceGroup.comparisonGroups.filter(cg => !groupTaken.includes(cg.proprietorGroup));
        if (!comparisonGroups.length) return ComparisonsMap.structureNotSame();

        // Take the best match
        orderedReferenceGroup.comparisonGroups = [
          comparisonGroups.sort((a, b) => {
            return (
              b.matchingPartyMaps.filter(e => e.partyMatch.matched).length - a.matchingPartyMaps.filter(e => e.partyMatch.matched).length ||
              b.matchingPartyMaps.filter(e => e.partyMatch.nameMatched).length - a.matchingPartyMaps.filter(e => e.partyMatch.nameMatched).length
            );
          })[0]
        ];

        groupTaken.push(...orderedReferenceGroup.comparisonGroups.map(x => x.proprietorGroup));
      }
    }

    return new ComparisonsMap(comparisons);
  }

  private static getMatchingParties<T extends MergeParty>(referenceParties: T[], partiesToCompare: T[]): PartyMap<T>[] {
    const matchParties: PartyMap<T>[] = [];
    const remainingParties: T[] = [...partiesToCompare];

    findMatch(false);

    if (remainingParties.length) findMatch(true);

    return matchParties;

    function findMatch(allowPartial: boolean) {
      referenceParties
        .filter(rp => matchParties.some(mp => mp.referenceParty === rp) === false)
        .forEach(referenceParty => {
          for (const [index, remainingParty] of remainingParties.entries()) {
            const result = compareParties(referenceParty, remainingParty, party => party.legalEntityName);
            if (result.matched || (allowPartial && result.nameMatched)) {
              matchParties.push(new PartyMap<T>(referenceParty, remainingParty, result));
              remainingParties.splice(index, 1);
              break;
            }
          }
        });
    }
  }
}

/**
 * Create and assign the matching item hash for all matched parties
 * @param matchedPartiesForAll Matching parties from all containers except first
 * @param party First container party as reference
 */
export function assignMatchingItemHash<T extends MergeParty>(matchedPartiesForAll: T[], party: T): string {
  const itemHashes = matchedPartiesForAll.map(e => e.mergeMetadata!.identity.itemHash);
  const matchingItemHash = `${[party.mergeMetadata!.identity.itemHash].concat(itemHashes).join('|')}`;
  party.mergeMetadata!.matchingItemHash = matchingItemHash;
  matchedPartiesForAll.forEach(e => (e.mergeMetadata!.matchingItemHash = party.mergeMetadata!.matchingItemHash));
  return matchingItemHash;
}

/**
 * Compare two parties using legal entity name (case insensitive), party type and party capacity
 * @param party1 party to compare
 * @param party2 party to compare
 * @returns returns true when match both condition otherwise false
 */
export function compareParties<T extends MergeParty>( //
  party1: T,
  party2: T,
  getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined
): PartyMatch {
  const nameMatched = getLegalEntityName(party1)?.toLowerCase() === getLegalEntityName(party2)?.toLowerCase();
  const matched = nameMatched && party1.partyType === party2.partyType && party1.partyCapacity?.capacity === party2.partyCapacity?.capacity;
  return new PartyMatch(matched, nameMatched);
}
