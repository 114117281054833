import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    dialogPaper: {
      marginTop: 40,
      minWidth: 1080,
      // this is temp fix as material ui-update the paper style
      display: 'flex',
      flexDirection: 'column',
      margin: '48px auto',
      position: 'relative',
      overflowY: 'auto',
      // Fix IE11 issue, to remove at some point.
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 'none'
    },
    root: {
      '& .MuiDialog-container:after': {
        height: 0
      }
    },
    createWorkspaceDialogContent: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 20
    }
  }),
  { name: 'CreateNewWorkspaceDialog' }
);
