import { Me } from './me';
import { Mfa } from './mfa';

import type { Client } from '../client';

export class Profile {
  me: Me;
  mfa: Mfa;

  constructor(client: Client) {
    this.me = new Me(client);
    this.mfa = new Mfa(client);
  }
}
