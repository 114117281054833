import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { LodgementCaseLogMap } from 'src/containers/workspace/shared/detail/models';
import { AuthorityDocumentModel } from '../../models';
import Caption from '../caption';
import DocumentVerificationResults from '../document-verification-results';
import { LodgementCaseComplianceMessages } from '../lodgement-case-compliance-messages/LodgementCaseComplianceMessages';
import { useStyles } from './styles';

export interface Props {
  // route params
  currentDocumentId?: string;
  // redux data
  lodgementCompliances?: HttpTypes.LodgementCompliance[]; // source: authority compliance report || workspace detail
  lodgementCaseLogSortedMap: LodgementCaseLogMap; // source: workspace detail
  lodgementCaseStatus?: HttpTypes.LodgementCaseStatusEnum; // source: workspace detail
  documents: AuthorityDocumentModel[]; // source:  authority compliance report || workspace documents
  pastAuthorityComplianceReport?: HttpTypes.AuthorityComplianceReportApiResponse; // source authority compliance report
  // other
  isLodgementCaseCompliant?: boolean; // success of the entire lodgement case verification
  onResolveIssueClick?: (event: React.SyntheticEvent, document: AuthorityDocumentModel) => void;
}

function LodgementVerificationResult({
  // route params
  currentDocumentId,
  // redux data
  lodgementCompliances,
  lodgementCaseLogSortedMap,
  lodgementCaseStatus,
  documents,
  pastAuthorityComplianceReport,
  // other
  isLodgementCaseCompliant,
  onResolveIssueClick
}: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {lodgementCompliances?.length ? (
          <LodgementCaseComplianceMessages //
            lodgementCompliances={lodgementCompliances}
          />
        ) : null}
        {documents.map(d => (
          <DocumentVerificationResults //
            key={d.documentId}
            // current document data
            document={d}
            defaultExpanded={currentDocumentId === d.documentId}
            // workspace lodgement case data
            isLodgementCaseCompliant={isLodgementCaseCompliant}
            onResolveIssueClick={onResolveIssueClick}
          />
        ))}
      </div>
      <Caption //
        lodgementCaseStatus={lodgementCaseStatus}
        pastAuthorityComplianceReport={pastAuthorityComplianceReport}
        lodgementCaseLogSortedMap={lodgementCaseLogSortedMap}
      />
    </>
  );
}

export default React.memo(LodgementVerificationResult);
