import Typography from '@mui/material/Typography';

import Tooltip from 'src/@core/components/tooltip/Tooltip';
import { titleAddress } from 'src/utils/formatters';

interface Props {
  matterReference: string;
  sympliId: string;
  addresses: Array<string>;
}

function LinkedSettlementToParticipantDetail({ matterReference, sympliId, addresses, children }: React.PropsWithChildren<Props>) {
  const plusNValue = Math.max(addresses.length - 1, 0);
  return (
    <span className="text-[14px] leading-[20px] text-[var(--neutral-1000)]">
      Matter Reference {matterReference} <span className="text-[var(--neutral-200)]">|</span> ID {sympliId} <span className="text-[var(--neutral-200)]">|</span> Address{' '}
      {addresses[0]}{' '}
      {plusNValue > 0 && (
        <Tooltip
          title={
            <div>
              <div className="mb-[16px]">
                <b>Multiple addresses found</b>
              </div>
              {addresses.map((item, i) => {
                return (
                  <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                    {titleAddress(item)}
                  </Typography>
                );
              })}
            </div>
          }
          placement="bottom-end"
        >
          <b>+{plusNValue}</b>
        </Tooltip>
      )}
      {children}
    </span>
  );
}

export default LinkedSettlementToParticipantDetail;
