import * as React from 'react';

import { Form } from 'formik';

import { HttpTypes } from '@sympli/api-gateway/types';
import { PortalSource } from '@sympli/ui-framework/components/portal';

import PromptConfirmDialog from 'src/@core/components/prompt-confirm-dialog';
import { PromptCallBack } from 'src/@core/components/prompt-confirm-dialog/model';
import SaveFormStepper from '../save-form-stepper';

export interface Props {
  workflowType: HttpTypes.DocumentWorkflowTypeEnum;
  canEdit: boolean;
  stepperCanEdit: boolean;
  isSubmitting: boolean;
  isSaveAsDraft: boolean;
  dirty: boolean;
  onFormCancel: () => void;
  handleOnSaveAndContinueClick: () => void;
  children: React.ReactNode;
  portalIdForFormSubmit?: string;
}

function DirectionForm({
  canEdit,
  stepperCanEdit,
  isSaveAsDraft,
  dirty,
  onFormCancel,
  isSubmitting,
  handleOnSaveAndContinueClick,
  workflowType,
  portalIdForFormSubmit,
  children
}: Props) {
  const renderStepper = (haveCancelButton: boolean) => (
    <SaveFormStepper
      workflowType={workflowType}
      isSubmitting={isSubmitting}
      canEdit={stepperCanEdit}
      isSaveAsDraft={isSaveAsDraft}
      haveCancelButton={haveCancelButton}
      formDirty={dirty}
      onFormCancel={onFormCancel}
      handleOnSaveAndContinueClick={handleOnSaveAndContinueClick}
    />
  );

  const handleOnLeavePage = (promptCallback: PromptCallBack) => {
    promptCallback(true); // redirect to new url
  };

  return (
    <>
      <PromptConfirmDialog
        when={dirty}
        title="You have unsaved changes!"
        message="Your unsaved changes will be lost. Are you sure you want to leave?"
        primaryButtonText="Cancel"
        customButtonText="Leave"
        onCustomButtonClick={handleOnLeavePage}
      />
      <Form>
        {children}
        {canEdit && renderStepper(true)}
        {portalIdForFormSubmit && <PortalSource id={portalIdForFormSubmit}>{renderStepper(false)}</PortalSource>}
      </Form>
    </>
  );
}

export default React.memo(DirectionForm);
