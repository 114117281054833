import { default as validationRules } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { DischargeOfMortgage2_28_0Model } from '../../models';
import { VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES } from '../../visibilityChecks';

const yupTitleReferences = validateWhenVisible2<DischargeOfMortgage2_28_0Model['titleReferences']>({
  visibilityCheck: (parentValue: DischargeOfMortgage2_28_0Model) => VISIBILITY_CHECK_TITLE_REFERENCES_AND_MORTGAGEES(parentValue),
  validationWhenVisible: validationRules
});

export default yupTitleReferences;
