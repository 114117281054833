import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';

import { isTitleVerificationSuccessful } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/matter-detail/helpers';
import { TitleVerificationResponse, VerifyTitleFormModel } from './models';

const MAX_TITLES = 20;
const regexWA = /^((CT|LG|GC|GT|LR|SR|MB|)(\d{1,6})(-|\/)(\d{1,6})(A?)|(EC|ET|RG|SP)([0-9]{1,6})((-|\/)[0]{0,6})?(A?)|(CL|PE)(\d{1,6})(-|\/)(\d{1,6})(A?))$/;
const regexQLD = /^\d*$/;

export default yup
  .object<VerifyTitleFormModel>({
    jurisdictionId: yup.mixed(),
    titleInputValue: yup.mixed(), // we are not validating this one
    titleChips: yup
      .array<string>()
      .of(yup.string().defined())
      .test(
        'max 20 titles test',
        `You have exceeded the ${MAX_TITLES} titles verification limit. Please remove the excess titles and try again`,
        function test(this: yup.TestContext<VerifyTitleFormModel>) {
          const { existingTitles = [], titleChips, titleVerificationResult }: VerifyTitleFormModel = this.parent;
          const maxTitleAmount = MAX_TITLES - existingTitles.length;
          const newTitles = titleChips
            .filter(tr => !existingTitles.some(t => t === tr))
            .concat(titleVerificationResult?.titles?.filter(tr => !existingTitles.some(t => t === tr.titleReference)).map(result => result.titleReference) ?? []);
          return newTitles.length <= maxTitleAmount;
        }
      )
      .test(
        'titleChips',
        'Must provide at least one title', //
        function test(this: yup.TestContext<VerifyTitleFormModel>) {
          const { titleChips, titleVerificationResult, titleInputValue } = this.parent;
          if (!titleChips.length && !titleVerificationResult?.titles.length) {
            return !!titleInputValue;
          }
          return true;
        }
      )
      .test(
        'titleChips',
        'Invalid format', //
        function test(this: yup.TestContext<VerifyTitleFormModel>, titleChips: string[]) {
          const { jurisdictionId, titleInputValue } = this.parent;
          if (titleInputValue) {
            titleChips = titleChips.concat(titleInputValue);
          }
          switch (jurisdictionId) {
            case HttpTypes.JurisdictionsEnum.SA:
            case HttpTypes.JurisdictionsEnum.VIC:
              return titleChips.every(title => title.split('/').length === 2);
            case HttpTypes.JurisdictionsEnum.QLD:
              return titleChips.every(t => t.length === 8 && regexQLD.test(t));
            case HttpTypes.JurisdictionsEnum.WA: {
              return titleChips.every(t => regexWA.test(t));
            }
            default:
              return true;
          }
        }
      )
      .defined(),
    titleVerificationResult: yup
      .mixed<TitleVerificationResponse>() //
      .nullable(false)
      .test(
        'titleVerificationResult test',
        'Title verification failed',
        function test(this: yup.TestContext<VerifyTitleFormModel>, titleVerificationResult?: TitleVerificationResponse) {
          return isTitleVerificationSuccessful(titleVerificationResult?.titles);
        }
      ) // we are not validating this one
  })
  .defined();
