import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconEye } from '@sympli/ui-framework/icons';

import Header from 'src/@core/components/typography/header';
import { SignDocumentsViewMode } from '../../models';
import { useStyles } from './styles';

interface Props {
  onClick: () => void;
  mode: SignDocumentsViewMode;
  disabled?: boolean;
  className?: string;
}

function HeadingToolbar({ mode, onClick, disabled, className }: Props) {
  const classes = useStyles();
  return (
    <FlexLayout justifyContent="space-between" className={className}>
      <Header>Sign</Header>
      <SympliButton
        variant="text"
        onClick={onClick}
        aria-label="Toggle document view"
        disabled={disabled}
        startIcon={mode === 'mergedPdf' ? <IconEye className={classes.icon} /> : <IconEye className={classes.icon} />}
        className={classes.button}
        color="inherit"
      >
        {mode === 'mergedPdf' ? 'View as PDF' : 'Back to document selection'}
      </SympliButton>
    </FlexLayout>
  );
}

export default HeadingToolbar;
