import React from 'react';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';

import converter from './conversion';
import { ApiRequestToTransferEctControl_2_28_0_Model, RequestToTransferEctControl_2_28_0_Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<RequestToTransferEctControl_2_28_0_Model, ApiRequestToTransferEctControl_2_28_0_Model>;

function DocumentForm(props: Props): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<RequestToTransferEctControl_2_28_0_Model>(validationSchema, validateDebounce);

  return (
    <DocumentFormWrapper //
      {...props}
      converter={converter}
      validate={validate}
      RootForm={RootForm}
    />
  );
}

export default React.memo(DocumentForm);
