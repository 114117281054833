import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentActionModel } from 'src/containers/documents/models';

export interface WorkspaceDetailModel extends HttpTypes.WorkspaceDetailApiResponse {
  settlementDateInUserTimezone?: string;
  tillSettlement?: number;
  currentParticipantId?: string;
  tacKey?: string;
}

export enum ReadyForSettlementFailureCodeEnum {
  SroRetrievalError = 1,
  NoSroFound = 2,
  SroCheckFailed = 3,
  LodgementVerificationRetrievalError = 4,
  NoLodgementVerificationFound = 5,
  LodgementVerificationCheckFailed = 6,
  UnfinishedTasks = 7,
  UnfinishedTasksRetrievalError = 8,
  FinancialBalanceCheckRetrievalError = 9,
  FinancialBalanceCheckFailed = 10
}

export enum SettlementStepEnum {
  PreSettlement = 'PreSettlement',
  Bsb = 'Bsb',
  Sro = 'Sro',
  LodgementVerification = 'LodgementVerification',
  FsmReservation = 'FsmReservation',
  Lodgement = 'Lodgement',
  FinancialDisbursement = 'FinancialDisbursement',
  ReservationCancellation = 'ReservationCancellation',
  StampDutyReservation = 'StampDutyReservation'
}

export const WorkspaceSettlementStatusEnumMapping: Record<HttpTypes.SettleSmartStatusEnum, string> = {
  [HttpTypes.SettleSmartStatusEnum.InProgress]: 'Settlement in progress',
  [HttpTypes.SettleSmartStatusEnum.Completed]: 'Settlement completed',
  [HttpTypes.SettleSmartStatusEnum.PartiallyCompleted]: 'Settlement partially completed',
  [HttpTypes.SettleSmartStatusEnum.Fail]: 'Settlement failed',
  [HttpTypes.SettleSmartStatusEnum.Postponed]: 'Settlement in progress',
  [HttpTypes.SettleSmartStatusEnum.FailedWithPostponed]: 'Settlement failed',
  [HttpTypes.SettleSmartStatusEnum.Delayed]: 'Settlement rolled'
};

export interface DocumentActionInfo {
  numberOfActions?: number; // this used as number of signers/approvers/savers
  documentActions: Array<DocumentActionModel>;
  actionType: HttpTypes.DocumentActionTypeEnum;
}

export interface ParticipantSettingApiResponse {
  billingSetting?: BillingSetting;
}

export interface BillingSetting {
  billingMethodType: HttpTypes.BillingMethodEnum;
  isBillingMethodChangeableByUser?: boolean;
}

export type LodgementCaseLogMap = { [key in HttpTypes.LodgementCaseStatusEnum]: Array<HttpTypes.LodgementCaseLogDetail> };

export interface WorkspaceLatestTransactionApiResponse {
  workspaceId: string;
  settlementDateUtc: string;
  isRollover: boolean;
  settlementStep?: SettlementStepEnum;
}

export enum SettlementNotificationStatusEnum {
  InProgress = 0,
  Success = 1,
  Fail = 2,
  PendingDecision = 3
}

export enum SettlementNotificationTypeEnum {
  Undefined = -1,
  Settlement = 0,
  Bsb = 1,
  Sro = 2,
  LodgementVerification = 3,
  FsmReservation = 4,
  Lodgement = 5,
  FinancialDisbursement = 6,
  ReservationCancellation = 7,
  SettleFunds = 8,
  DisburseFunds = 9
}

//todo: move all these to api gateway httpTypes
export interface LinkedSettlementDetailApiRequest {
  workspaceId: string;
  clusterId: string;
}

export type LinkedSettlementDetailApiResponse = HttpTypes.LinkedSettlementDetail;

export interface LinkedLodgementDetailApiRequest {
  workspaceId: string;
  clusterId: string;
}

export type LinkedLodgementDetailApiResponse = HttpTypes.LinkedLodgementDetail;
