export enum DateFormatEnum {
  DATE = 'yyyy-mm-dd', // isoDate
  DATETIME = "yyyy-mm-dd'T'HH:MM:ss", // isoDateTime
  SHORTTIME = 'h:MM TT',
  TIME = 'HH:MM',
  AUSSHORTDATE = 'dd/mm/yy',
  AU_SHORT_DAY_MONTH = 'dd mmm',
  HOUR_MIN_TIMEMARKER = 'h:MMtt',
  AUSDATE = 'dd/mm/yyyy',
  AUSDATETEXT = 'ddd dd mmm yyyy',
  AUSDATETIME = 'dd mmm yyyy h:MM TT',
  AUSDATETIMETEXT = 'dd mmm yyyy, h:MM TT',
  DATEMONTH = 'd mmm',
  DATEMONTHYEAR = 'd mmm yyyy',
  DATEMONTH_SHORTYEAR = 'd mmm yy',
  DAY_MONTH_HOUR_MIN_MARKER = 'd mmm h:MMTT', //15 Jan 9:31AM
  //TODO review all these date format
  DAY_MONTH_HOUR_MIN_MARKER_2 = 'd mmm, h:MMtt', // 22 Apr, 9:18pm
  DAY_MONTH_YEAR_MIN_MARKER = 'dd/mm/yy - h:MM TT' // 10/04/23 - 10:30 AM
}
