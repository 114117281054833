import { useMemo } from 'react';
import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { IconLinked } from 'src/components/icons';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { FinancialLinkedWorkspaceStatus, resolveFinancialLinkedWorkspaceStatus, SETTLEMENT_WORKSPACE_STATUSES } from 'src/utils/status-mapping/workspaceStatusHelper';

interface Props {
  linkedWorkspaceOverallStatus: HttpTypes.LinkedWorkspaceOverallStatusEnum;
  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
  extraContent?: React.ReactNode;
  extraContentClassName?: string;
  dataTestId?: string;
}

function FinancialLinkedWorkspaceStatusBadge({ linkedWorkspaceOverallStatus, extraContent, extraContentClassName, dataTestId, workspaceStatus }: Props) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const wsStatusInfo: FinancialLinkedWorkspaceStatus | null = useMemo(() => {
    const wsStatus = resolveFinancialLinkedWorkspaceStatus(linkedWorkspaceOverallStatus);

    if (!wsStatus) {
      return null;
    }

    return { ...wsStatus };
  }, [linkedWorkspaceOverallStatus]);

  if (!wsStatusInfo) {
    return null;
  }

  const { variant, title } = wsStatusInfo;

  // hide the linked status title if settlement has started
  const displayTitle = workspaceStatus !== undefined && SETTLEMENT_WORKSPACE_STATUSES.includes(workspaceStatus) ? undefined : title;

  if (extraContent) {
    return (
      <div className="flex items-center">
        <VerticalStatusLine //
          text={
            <div className="flex items-center gap-[4px]">
              {displayTitle && <div className="uppercase text-[var(--neutral-800)]">{displayTitle}</div>}
              <IconLinked />
            </div>
          }
          dataTestId={dataTestId}
          variant={variant}
          textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
          extraContent={extraContent}
          extraContentClassName={extraContentClassName}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[4px]">
      <VerticalStatusLine //
        text={
          <div className="flex items-center gap-[4px]">
            {displayTitle && <div className="uppercase text-[var(--neutral-800)]">{displayTitle}</div>}
            <IconLinked />
          </div>
        }
        variant={variant}
        dataTestId={dataTestId}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      />
    </div>
  );
}

export default React.memo(FinancialLinkedWorkspaceStatusBadge);
