import * as React from 'react';

import classNames from 'classnames';
import visuallyHidden from '@mui/utils/visuallyHidden';

import { HttpTypes } from '@sympli/api-gateway/types';

import { participantStatusNameMapping } from 'src/containers/shared/participant-list/components/participant-item/models';
import { useStyles } from './styles';

interface Props {
  participantStatusId: HttpTypes.ParticipantStatusEnum;
  margin?: 'left';
}

function ParticipantStatusBadge({ participantStatusId, margin = 'left' }: Props) {
  const classes = useStyles();

  switch (participantStatusId) {
    case HttpTypes.ParticipantStatusEnum.Pending:
      return (
        <div className={classNames(classes.root, margin === 'left' && classes.marginLeft)}>
          <span style={visuallyHidden}>Invitation status</span>
          {participantStatusNameMapping[participantStatusId]}
        </div>
      );

    default:
      return null;
  }
}

export default React.memo(ParticipantStatusBadge);
