import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { resolveReviewDocumentActionInfo } from 'src/containers/workspace/shared/detail/selectors';
import { useSafeDispatch } from 'src/hooks';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from '../../models';
import { reviewDocument } from '../shared/helpers';
import ViewDocument from './ViewDocument';

interface Props {
  queryParams: DocumentPageRouteParams;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
  documentIds: string[];
  displayDocumentAsPdf: boolean;
  currentParticipant?: HttpTypes.WorkspaceParticipant;
}

function ViewDocumentContainer({
  //
  queryParams,
  documentDetail,
  jurisdictionId,
  workflowPanelProps,
  documentIds,
  displayDocumentAsPdf,
  currentParticipant
}: Props) {
  const navigate = useNavigate();
  const [isWorkflowLoading, setIsWorkflowLoading] = React.useState(false);
  const hash = React.useRef(documentDetail.hash); // contains the initial document detail hash and used in submission, must be prevented from getting updated

  const dispatch = useSafeDispatch(useDispatch());
  const { documentWorkflowType } = documentDetail;
  const isWorkflowStandard = documentWorkflowType === HttpTypes.DocumentWorkflowTypeEnum.Standard;
  const nextActionLabel = isWorkflowStandard ? 'Save and approve' : 'Save and review';
  const hasPermissionToReview = documentDetail.documentPermissions.includes(HttpTypes.DocumentPermissionEnum.Review);
  const nextActionDisabled = !isWorkflowStandard || isWorkflowLoading || !hasPermissionToReview;
  const { participantId } = queryParams;
  const reviewActionInfo = resolveReviewDocumentActionInfo({ detail: documentDetail, participantId });

  const handleReviewClick = React.useCallback(() => {
    reviewDocument(queryParams, documentDetail, documentIds, dispatch, navigate, setIsWorkflowLoading, hash.current);
  }, [dispatch, documentDetail, documentIds, navigate, queryParams]);

  return (
    <ViewDocument
      queryParams={queryParams}
      documentDetail={documentDetail}
      jurisdictionId={jurisdictionId}
      workflowPanelProps={workflowPanelProps}
      reviewActionInfo={reviewActionInfo}
      nextActionLabel={nextActionLabel}
      nextActionDisabled={nextActionDisabled}
      isWorkflowLoading={isWorkflowLoading}
      isWorkflowStandard={isWorkflowStandard}
      displayDocumentAsPdf={displayDocumentAsPdf}
      handleOnClick={handleReviewClick}
      currentParticipant={currentParticipant}
    />
  );
}
export default React.memo(ViewDocumentContainer);
