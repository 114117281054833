import { Action, createReducer } from 'typesafe-actions';

import { filterAcceptedParticipants, filterInvitations } from 'src/containers/dashboard/shared/helpers';
import { actionUpdateParticipantList } from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';
import { actionResendInvite } from '../actions';
import { SentInviteDetailModel } from '../models';

export interface SentInvitesFeedDetailState {
  data?: SentInviteDetailModel | null;
  status: ApiStatus;
  isResendingInvite: boolean;
  error?: string;
}

const initialState: SentInvitesFeedDetailState = {
  data: undefined,
  status: 'idle',
  isResendingInvite: false,
  error: undefined
};

const detailReducer = createReducer<
  //
  SentInvitesFeedDetailState,
  Action
>(initialState)
  .handleAction(actionResendInvite.request, (state): SentInvitesFeedDetailState => {
    return {
      ...state,
      isResendingInvite: true,
      error: undefined
    };
  })
  .handleAction(actionResendInvite.success, (state): SentInvitesFeedDetailState => {
    return {
      ...state,
      isResendingInvite: false,
      error: undefined
    };
  })
  .handleAction(actionResendInvite.failure, (state, action): SentInvitesFeedDetailState => {
    return {
      ...state,
      isResendingInvite: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateParticipantList, (state, action): SentInvitesFeedDetailState => {
    const { data } = state;
    const { participants, workspaceId } = action.payload;

    if (data == null) {
      return state;
    }

    if (data.workspaceId !== workspaceId) {
      return state;
    }

    return {
      ...state,
      data: {
        ...data,
        participants,
        invitations: participants.filter(filterInvitations),
        acceptedParticipants: participants.filter(filterAcceptedParticipants)
      }
    };
  });

export default detailReducer;
