import { MergeParty } from './model';
import { assignMatchingItemHash, compareParties } from './PartyMerger';

/**
 * https://tickleme.atlassian.net/wiki/spaces/~5de440aa7095a40d125388c9/pages/2415887591/Party+Merge+NSW
 */

export default class PartyJustification {
  /**
   * ! This method will mutate the parties parameter
   * Will adjust the matching id hash once successful otherwise will return reason of unsuccessful
   * @param parties Parties to check the justification
   * @param getLegalEntityName Will use this function to get the legal entity name
   * @returns Return the reason of unsuccessful otherwise undefined
   */
  public static adjustJustification<T extends MergeParty>(parties: T[], getLegalEntityName: <T extends MergeParty>(party: T) => string | undefined): string | undefined {
    const mergedGroups = parties
      .filter(p => p.mergeMetadata?.requiresJustification)
      .map(p => {
        p.mergeMetadata!.matchingItemHash = p.mergeMetadata!.identity.itemHash;
        return p;
      })
      .reduce((map, p) => map.set(p.mergeMetadata!.matchingGroupHash, [...(map.get(p.mergeMetadata!.matchingGroupHash) ?? []), p]), new Map());

    for (const value of mergedGroups.values()) {
      const groupsMap: Map<string, T[]> = value.reduce(
        (map: Map<string, T[]>, e: T) => map.set(e.mergeMetadata!.identity.groupHash, [...(map.get(e.mergeMetadata!.identity.groupHash) ?? []), e]),
        new Map<string, T[]>()
      );

      if (groupsMap.size === 1) continue;

      const [referenceGroupParties, ...otherGroupsParties] = groupsMap.values();

      for (const referenceParty of referenceGroupParties) {
        const justifiedParties: T[] = [];
        for (const parties of otherGroupsParties) {
          const party = parties.find(
            p =>
              p.mergeMetadata!.matchingItemHash === p.mergeMetadata!.identity.itemHash && //
              compareParties(referenceParty, p, getLegalEntityName).matched
          );

          if (party) justifiedParties.push(party);
          else return `Justified has not been completed ${referenceParty.legalEntityName}`;
        }

        assignMatchingItemHash(justifiedParties, referenceParty);
      }
    }

    return undefined;
  }
}
