import * as React from 'react';

import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import BreakdownsCollapseList from '../../../directions/views/edit-directions/components/breakdowns-collapse-list';
import Currency from '../currency';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  amount: string;
  description?: string;
  withDivider: boolean;
  showBreakdownsCol: boolean;
  lineItemBreakdowns?: HttpTypes.WorkspaceDirectionsApiResponse['directions'][number]['lineItemBreakdowns'];
  breakdownCategory?: string;
  breakdownAccountDisplayName?: string;
}

interface State {
  breakdownOpen: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class LineItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
  public readonly state: Readonly<State> = {
    breakdownOpen: false
  };

  render() {
    const { classes, children, description, amount, showBreakdownsCol, lineItemBreakdowns, withDivider, breakdownCategory, breakdownAccountDisplayName } = this.props;
    const hasBreakdowns = lineItemBreakdowns && lineItemBreakdowns.length > 0;
    return (
      <>
        <FlexLayout>
          <div className={classNames(classes.columnOne, classes.description)}>{description}</div>
          <FlexLayout flexDirection="column" className={classNames(showBreakdownsCol ? classes.columnTwoWithBreakdowns : classes.columnTwo, classes.bottomPadding)}>
            {children}
          </FlexLayout>
          {showBreakdownsCol && (
            <FlexLayout className={classes.breakdownsCol}>
              {hasBreakdowns && (
                <ButtonLink
                  onClick={() => {
                    this.setState(({ breakdownOpen }) => ({
                      breakdownOpen: !breakdownOpen
                    }));
                  }}
                  color="inherit"
                  className={classes.showDetailsButtonLink}
                >
                  {this.state.breakdownOpen ? 'Hide details' : 'Show details'}
                </ButtonLink>
              )}
            </FlexLayout>
          )}
          {(!showBreakdownsCol || !this.state.breakdownOpen) && <Currency className={classNames(classes.columnThree, classes.bottomPadding)}>{amount}</Currency>}
        </FlexLayout>
        <FlexLayout></FlexLayout>
        {showBreakdownsCol && (
          <BreakdownsCollapseList
            open={this.state.breakdownOpen}
            lineItems={lineItemBreakdowns ?? []}
            category={breakdownCategory}
            accountDisplayName={breakdownAccountDisplayName}
            columnClasses={{ columnOne: classes.breakdownLineItemsColOne, columnTwo: classes.breakdownsCol, columnThree: classes.columnThree }}
          />
        )}
        <FlexLayout>
          <FlexLayout flexDirection="column" className={classes.columnOne} />
          <FlexLayout flexDirection="column" className={classes.dividerColumn}>
            {withDivider && <Divider className={classes.divider}></Divider>}
          </FlexLayout>
        </FlexLayout>
      </>
    );
  }
}

export default withStyles(styles)(LineItem);
