import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { TrustAccountEnhancedDetails, TrustAccountSetupStepEnum } from '../trust-accounts/detail/models';

export const actionStartTrustAccountDetails = createAction('START_TRUST_ACCOUNT_DETAILS')();

export const actionFetchTrustAccountDetail = createAsyncAction(
  //
  'FETCH_TRUST_ACCOUNT_DETAIL',
  'FETCH_TRUST_ACCOUNT_DETAIL_SUCCESS',
  'FETCH_TRUST_ACCOUNT_DETAIL_ERROR'
)<
  //
  { id: string; isRequest: boolean; changeType?: string },
  {
    data: {
      details: TrustAccountEnhancedDetails;
      step: TrustAccountSetupStepEnum;
      progress: TrustAccountSetupStepEnum;
    };
  },
  { error: Error }
>();

export const actionUpdateTrustAccountRequestProgress = createAction('TRUST_ACCOUNT_UPDATE_REQUEST_PROGRESS')<{
  //
  status?: HttpTypes.TrustAccountRequestStatusEnum;
}>();

export const actionUpdateStep = createAction('TRUST_ACCOUNT_UPDATE_STEP')<TrustAccountSetupStepEnum>();

export const actionUpdateTrustAccountDetails = createAction('TRUST_ACCOUNT_DETAILS_UPDATE')<TrustAccountEnhancedDetails>();

export const actionTrustAccountRequestPostSave = createAction('TRUST_ACCOUNT_REQUEST_POST_SAVE')<HttpTypes.TrustAccountRequestDetailsApiResponse>();
