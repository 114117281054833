import { NotificationContentProps } from '../models';

export function LodgementInstructionsOrderUpdatedNotification(props: NotificationContentProps) {
  return PrefillDataSourceChangedNotification(props, 'The lodgement order has changed and has made the party details on your documents inaccurate; please review the document.');
}

export function DocumentUpdatedNotification(props: NotificationContentProps) {
  return PrefillDataSourceChangedNotification(
    props,
    'The document in the lodgement order is updated and has made the party details on your documents inaccurate; please review the document.'
  );
}

function PrefillDataSourceChangedNotification({ log, participantId }: NotificationContentProps, message: string) {
  const data = log.dataObject as { PrefillImpacts: PrefillImpact[] | undefined };

  if (!data.PrefillImpacts) return undefined;

  const notifications: JSX.Element[] = [];

  if (data.PrefillImpacts.findIndex(e => e.ParticipantId === participantId && e.DataChanged) !== -1) {
    notifications.push(<span>The party details, such as party name, tenancy structure, or share fraction, have been updated; please review.</span>);
  } else if (data.PrefillImpacts.findIndex(e => e.ParticipantId === participantId && e.SourceChanged) !== -1) {
    notifications.push(<span>{message}</span>);
  }

  return notifications;
}

interface PrefillImpact {
  DocumentId: string;
  ParticipantId: string;
  DataChanged: boolean;
  SourceChanged: boolean;
}
