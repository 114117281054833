import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardTypeEnum } from 'src/models';
import { WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import { AggregatorComponentBaseProps, AggregatorNameEnum, AggregatorQuery, StandaloneDashboardAggregatorApiRequest } from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): StandaloneDashboardAggregatorApiRequest => {
  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.totalOpenWorkspaces,
      workspaceStatus:
        assignmentType === AssignmentTypeEnum.ReAssigned
          ? [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors, WorkspaceStatusEnum.Ready]
          : [
              //
              WorkspaceStatusEnum.InPreparation,
              WorkspaceStatusEnum.InPreparationWithErrors,
              WorkspaceStatusEnum.Ready,
              WorkspaceStatusEnum.Queued,
              WorkspaceStatusEnum.Lodging,
              WorkspaceStatusEnum.Lodged
            ]
    }
  ];

  const payload: StandaloneDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function OpenWorkspacesCard(props: AggregatorComponentBaseProps) {
  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title=""
          cardType={DashboardTypeEnum.Standalone}
          isLoading={isLoading}
          onActionClick={onActionClick}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.totalOpenWorkspaces,
              content,
              status: 'Open Workspaces',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(OpenWorkspacesCard);
