import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionDeleteUserFromStore, actionFetchUsersFeed, UsersApiRequest } from '../actions/users';
import { UserApiResponse } from '../group-settings/models';

export interface UsersFeedState {
  items: Array<UserApiResponse>;
  totalCount: number;
  query: UsersApiRequest;
  status: ApiStatus;
  error?: string;
  args?: {
    groupIds?: string[];
  };
}

const initialState: UsersFeedState = {
  items: [],
  error: undefined,
  query: {
    isArchived: false,
    sortBy: 'fullName',
    sortOrder: SortDirectionEnum.asc,
    pageSize: 10,
    pageNumber: 1, // service page number is index from 1
    status: HttpTypes.UserStatusEnum.Current
  },
  status: 'idle',
  totalCount: 0,
  args: undefined
};

export const useUserFeeds = (groupIds?: string[]) => {
  const state = useStoreSelector(store => store.usersFeed);
  //Have to check if we have stale userfeed data for filtered groupIds
  if (_isEqual(groupIds, state.args?.groupIds)) {
    return state;
  }

  return initialState;
};

const reducer = createReducer<
  //
  UsersFeedState,
  Action
>(initialState)
  .handleAction(actionFetchUsersFeed.request, (state, action): UsersFeedState => {
    return {
      ...state,
      query: {
        ...state.query,
        ...action.payload
      },
      error: undefined,
      status: 'pending',
      args: {
        groupIds: action.payload?.groupIds
      }
    };
  })
  .handleAction(actionFetchUsersFeed.success, (state, action): UsersFeedState => {
    const { items, pageNumber, pageSize, totalCount } = action.payload.data;

    return {
      ...state,
      items,
      query: {
        ...state.query,
        pageNumber,
        pageSize
      },
      totalCount,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchUsersFeed.failure, (state, action): UsersFeedState => {
    return {
      ...state,
      error: action.payload.error.message,
      status: 'rejected'
    };
  })
  .handleAction(actionDeleteUserFromStore, (state, action): UsersFeedState => {
    const deletedUserId: string = action.payload;
    return {
      ...state,
      items: state.items.filter(item => item.id !== deletedUserId)
    };
  });

export default reducer;
