import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { RegOnlyPaymentSummaryApiResponse } from 'src/containers/shared/payment-summary-box/models';
import { AuthorisationDenyPermissionReasonCodeEnum } from '../workspace/financial/directions/models';
import { JsonSchemaRootModel } from './dynamic-form/models';

export interface DocumentPageRouteParams {
  workspaceId: string;
  participantId: string;
  documentId: string;
}

export type DocumentSigningMessageApiRequest = {
  workspaceId: string;
  participantId: string;
  documents: Array<{ documentState: string; documentType: string; documentVersion: string }>;
};

export type DocumentSigningMessageApiResponse = {
  documentState?: string;
  documentType?: string;
  documentVersion?: string;
  documentId: string;
};

export interface DocumentDetailApiRequest {
  workspaceId: string;
  documentId: string;
  participantId: string;
  // taskId?: string;
}

export type SaveDocumentFormRequest = DocumentDetailApiRequest;
export type DocumentsPageRouteAndQueryModel = DocumentDetailApiRequest;

export interface PaymentSummaryApiRequest {
  workspaceId: string;
  participantId: string;
}

// export interface DocumentFormModel {
//   id: string;
//   name: string;
//   documentType: DocumentTypeIdentifierEnum;
//   schema: JsonSchemaRootModel; // needs to be converted to JSON
//   documentViewerType: DocumentViewerTypeEnum;

//   template?: string;

//   // // TODO: Probably not useful, remove later
//   // permission: Array<{
//   //   workspaceRoleId: WorkspaceRoleEnum;
//   //   accessOptions: Array<DocumentPermissionEnum>;
//   // }>;
// }

export interface DocumentNotificationModel {
  heading: string;
  message: string;
}

export type DocumentDetailDataModel<D = { [key: string]: any }> = D & { [key: string]: any }; // dynamic object

export interface DocumentDetailUpdateModel extends DocumentSummary {
  pdfUrl?: string;
  expiry?: string;
  data: DocumentDetailDataModel;
  hash: string;
  version: number;
  documentWorkflowType: HttpTypes.DocumentWorkflowTypeEnum;
}
export interface DocumentFormAndDetailModel extends HttpTypes.WorkspaceDocumentDetailApiResponse {
  parsedData: DocumentDetailDataModel /* data (originally string) parsed to an object format */;
  parsedSchema?: JsonSchemaRootModel /* documentForm.schema (originally string) parsed to an object format */;
  pdfS3Key?: string;
}

export interface AddAdditionalDocumentsRequestModel {
  workspaceId: string;
  participantId: string;
  workspaceDocument: WorkspaceDocumentModel;
}

export interface WorkspaceDocumentModel {
  documentIdentifier: HttpTypes.DocumentTypeIdentifierEnum;
  roles: HttpTypes.WorkspaceRoleEnum[];
}
// MR - check (AccessOptionEnum)
export enum DocumentWorkflowStepsEnum {
  Read = HttpTypes.AccessOptionEnum.Read,
  Write = HttpTypes.AccessOptionEnum.Write,
  Sign = HttpTypes.AccessOptionEnum.Sign,
  Review = HttpTypes.AccessOptionEnum.Review,
  // UI models only
  Unsign = 4,
  Resolve = 5, // * UI use only for edit document with issues
  Lodged = 6 // * UI use only for display lodged document
}

// Workflow step should be mapped to their respective next action label
export const DocumentWorkflowStepsEnumMap = new Map<DocumentWorkflowStepsEnum, string>([
  [DocumentWorkflowStepsEnum.Write, 'Edit'],
  [DocumentWorkflowStepsEnum.Sign, 'Sign'],
  [DocumentWorkflowStepsEnum.Review, 'Approve']
]);

export const extendedDocumentWorkflowMapping: Partial<Record<HttpTypes.DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  [HttpTypes.DocumentStatusEnum.InPreparation]: DocumentWorkflowStepsEnum.Write,
  [HttpTypes.DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Review,
  [HttpTypes.DocumentStatusEnum.Approved]: DocumentWorkflowStepsEnum.Review,
  [HttpTypes.DocumentStatusEnum.Signing]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Signed]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Review,
  [HttpTypes.DocumentStatusEnum.LodgementInProgress]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Lodged]: DocumentWorkflowStepsEnum.Lodged,
  [HttpTypes.DocumentStatusEnum.ResolveIssues]: DocumentWorkflowStepsEnum.Resolve
};

export const standardDocumentWorkflowMapping: Partial<Record<HttpTypes.DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  ...extendedDocumentWorkflowMapping,
  [HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Approved]: DocumentWorkflowStepsEnum.Sign
};

export interface DocumentActionModel {
  type: HttpTypes.DocumentActionTypeEnum;
  userId: string;
  participantId: string;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  participantSubscriberName: string;
  createdDateTime: string;
}

export interface DocumentDenyPermissionModel<T = HttpTypes.DocumentDenyPermissionReasonCodeEnum | AuthorisationDenyPermissionReasonCodeEnum> {
  documentPermissionType: HttpTypes.DocumentPermissionEnum;
  reasonCode: LookupEnumModel<T>;
}

export interface DocumentWorkflowModel {
  isWorkspaceLocked: boolean;
  steps: Array<DocumentWorkflowStepsEnum>;
  requiredSigners: number;
}

export interface SaveDebitAccountApiRequest {
  workspaceId: string;
  participantId: string;
  debitAccountId: string;
  paymentSummaryModel: RegOnlyPaymentSummaryApiResponse;
}

export interface UpdateWorkspaceDocumentRequestModel {
  data: string;
  hash: string;
}

export interface DocumentSummary {
  documentActions: Array<DocumentActionModel>;
  documentId?: string;
  documentPermissions: Array<HttpTypes.DocumentPermissionEnum>;
  documentWorkflow: DocumentWorkflowModel;
  status: HttpTypes.DocumentStatusEnum;
  isFullySignedByAllParticipants: boolean;
  documentDenyPermissions: Array<DocumentDenyPermissionModel>;
}

export interface PanelDetails {
  primaryMessage?: string;
  secondaryMessage?: string;
  panelVariant?: PanelVariantEnum;
  priority: number;
}

export enum PanelVariantEnum {
  Warning = 0,
  Success = 1
}

export interface DocumentPrerequisite {
  isPrerequisiteNotReady?: boolean;
  prerequisiteError?: string;
}
