import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentContextProvider } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { HttpTypes } from '@sympli/api-gateway/types';

import Header from 'src/@core/components/typography/header';
import DocumentDataNotification from 'src/containers/documents/components/document-data-notification';
import JsonSchemaForm from 'src/containers/documents/dynamic-form';
import { DocumentDetailDataModel, DocumentFormAndDetailModel, DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import ScaffoldedForm from 'src/containers/documents/scaffolded-form';
import styles, { ClassKeys } from './styles';

function foo() {}

const DEFAULT_FORM_DATA: DocumentDetailDataModel = {};
interface OwnProps {
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  documentModel: DocumentFormAndDetailModel;
  workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[];
  queryParams: DocumentsPageRouteAndQueryModel;
  currentParticipant?: HttpTypes.WorkspaceParticipant;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class ReadonlyDocument extends React.PureComponent<Props> {
  private dialogPortalId = _uniqueId();

  render() {
    const { classes, workspaceDocuments, currentParticipant } = this.props;
    return (
      <div className={classes.root}>
        {this.renderDocumentHeader()}
        <DocumentContextProvider // need to wrap with documentContextProvide because the disable props are getting from it.
          disabled={true} // disable all fields
          dialogPortalId={this.dialogPortalId}
          isLoading={false}
          nextActionLabel=""
          // does not matter here since we are hiding the panel anyway
          nextActionDisabled={false}
          setNextActionDisabled={foo}
          filesApi={{
            upload(files: File[]) {
              return Promise.resolve([]);
            },
            download(attachmentId: string) {
              return Promise.resolve({
                pdfUrl: '',
                expiry: ''
              });
            }
          }}
          workspaceDocuments={workspaceDocuments}
          currentParticipant={currentParticipant}
        >
          {this.renderForm()}
        </DocumentContextProvider>
      </div>
    );
  }

  private renderDocumentHeader() {
    const {
      documentModel: {
        parsedData: { documentNotification },
        documentForm: { name }
      }
    } = this.props;

    return (
      <React.Fragment>
        <Header>{name}</Header>
        {documentNotification ? <DocumentDataNotification notification={documentNotification} /> : null}
      </React.Fragment>
    );
  }

  private renderForm() {
    const {
      parsedData = DEFAULT_FORM_DATA,
      documentForm: { documentViewerType }
    } = this.props.documentModel;

    if (documentViewerType === HttpTypes.DocumentViewerTypeEnum.CustomReactForm) {
      return this.renderScaffoldedForm(parsedData);
    }

    return this.renderJsonSchemaForm(parsedData);
  }

  private renderScaffoldedForm(data: object) {
    const {
      documentModel: {
        parsedSchema,
        documentForm: { documentType, version: documentFormVersion }
      },
      jurisdictionId,
      queryParams
    } = this.props;
    // parsedSchema?.version is populated when documentViewerType is FormGenerator,
    // and it will be undefined for CustomReactForm.
    // Using fallback to documentFormVersion for better compatibility
    const documentVersion = parsedSchema?.version ?? documentFormVersion;
    return (
      <ScaffoldedForm
        documentTypeId={documentType}
        version={documentVersion}
        jurisdictionId={jurisdictionId!}
        //
        data={data}
        // TODO we should remove these two props since it's a duplicate of document context after documents that are using them are converted to use documentContext
        isLoading={false}
        disabled={true}
        nextActionLabel=""
        //
        onSave={this.handleOnFormSave}
        onCancel={this.handleOnFormCancel}
        onRenderForm={this.handleOnRenderForm}
        onPostSubmit={this.handleOnPostSubmit}
        hideActionsPanel={true}
        // routing
        queryParams={queryParams}
      />
    );
  }

  private renderJsonSchemaForm(data: object) {
    const {
      documentModel: { parsedSchema }
    } = this.props;

    return (
      <JsonSchemaForm
        schema={parsedSchema!} // parsedSchema is always populated for DocumentViewerType.FormGenerator
        //
        data={data}
        isLoading={false}
        disabled={true}
        nextActionLabel=""
        //
        onSave={this.handleOnFormSave}
        onCancel={this.handleOnFormCancel}
        onRenderForm={this.handleOnRenderForm}
        onPostSubmit={this.handleOnPostSubmit}
      />
    );
  }

  private handleOnRenderForm = () => {};

  private handleOnFormSave = () => Promise.resolve();

  private handleOnFormCancel = () => {};

  private handleOnPostSubmit = () => {};
}

const styledComponent = withStyles(styles)(ReadonlyDocument);

export default styledComponent;
