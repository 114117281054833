import { memo } from 'react';

import ButtonBase from '@mui/material/ButtonBase';

import { IconRefresh } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';

export interface Props {
  isRefetching?: boolean;
  refreshHandler: () => void;
}

const RefreshIcon = ({ isRefetching, refreshHandler }: Props) => {
  return (
    <ButtonBase
      sx={{
        borderRadius: '44px',
        position: 'absolute',
        top: 0,
        right: 0,
        width: 44,
        height: 44,
        backgroundColor: colors.GREEK_WATERS_TRANSLUCENT,
        '&:hover': {
          backgroundColor: colors.GREEK_WATERS_LUMINOUS,
          '& path': {
            fill: colors.NEUTRAL_1000
          }
        }
      }}
      disabled={isRefetching}
      onClick={refreshHandler}
      data-testid="dashboard-refresh-icon"
    >
      <IconRefresh />
    </ButtonBase>
  );
};

export default memo(RefreshIcon);
