import format from 'date-fns/format';

import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger from '@sympli/ui-logger';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2 } from '../models';
import {
  DocumentsStatusEnum,
  DocumentsStatusOptions,
  DocumentTypeOptions,
  DocumentTypesNotInStandalone,
  FilterEnum,
  FilterOptions,
  WorkspaceArchivedStatusOptions,
  WorkspaceStatusEnum,
  WorkspaceStatusOptions
} from './models';

// if saved value has a value then use it, if we do not have saved value then based on selected tab show the default value
const defaultDateTimeRangeModel = (
  selectedTab: StandaloneDashboardRouteTabsEnumV2,
  savedFilter?: StandaloneDashboardFilterModel
): { lastAccessPeriod?: DashboardDateRangeModel; completedDatePeriod?: DashboardDateRangeModel; archivedDatePeriod?: DashboardDateRangeModel } => {
  const lastAccessPeriod = savedFilter?.lastAccessPeriod
    ? savedFilter.lastAccessPeriod
    : selectedTab === StandaloneDashboardRouteTabsEnumV2.recent
      ? { start: format(new Date(), 'dd/MM/yyyy'), end: format(new Date(), 'dd/MM/yyyy') }
      : undefined;

  const completedDatePeriod = savedFilter?.completedDatePeriod ? savedFilter.completedDatePeriod : undefined;
  const archivedDatePeriod = savedFilter?.archivedDatePeriod ? savedFilter.archivedDatePeriod : undefined;

  return { lastAccessPeriod, completedDatePeriod, archivedDatePeriod };
};

const resolveSelectedFiltersAndDefaultWorkspaceStatus = (
  selectedTab: StandaloneDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
): Pick<StandaloneDashboardFilterModel, 'selectedFilters' | 'workspaceStatus'> => {
  const selectedFilters: FilterEnum[] = [];
  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    selectedFilters.push(FilterEnum.Groups);
  }
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.recent:
      selectedFilters.unshift(FilterEnum.AccessedDate);
      return { selectedFilters: selectedFilters, workspaceStatus: [] };

    default: // all, active, complete, to be reworked, archived
      return { selectedFilters: selectedFilters, workspaceStatus: [] };
  }
};

export const defaultDashboardFilterModel = (selectedTab: StandaloneDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): StandaloneDashboardFilterModel => {
  const resolveSelectedFilters = resolveSelectedFiltersAndDefaultWorkspaceStatus(selectedTab, assignmentType);

  const defaultOptions: StandaloneDashboardFilterModel = {
    assignmentType: assignmentType,
    selectedTab: selectedTab,
    selectedFilters: resolveSelectedFilters.selectedFilters,

    ...defaultDateTimeRangeModel(selectedTab, undefined),
    jurisdictions: [],
    myTasks: [],
    documentTypes: [],
    documentStatus: [],
    workspaceStatus: resolveSelectedFilters.workspaceStatus,
    groupIds: []
  };

  return defaultOptions;
};

export const getFiltersWithSelectedTab = (
  //
  key: string,
  selectedTab: StandaloneDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
): [StandaloneDashboardFilterModel, boolean] => {
  const value = localStorage.getItem(key);

  const defaultOptions = defaultDashboardFilterModel(selectedTab, assignmentType);

  if (value) {
    const savedFilters = JSON.parse(value) as StandaloneDashboardFilterModel[];
    const savedFilter = savedFilters.find(d => d.selectedTab === selectedTab && d.assignmentType === assignmentType);

    if (!savedFilter) {
      return [defaultOptions, false];
    }

    return [
      {
        selectedTab: selectedTab,
        assignmentType: assignmentType,
        selectedFilters: savedFilter.selectedFilters,

        ...defaultDateTimeRangeModel(selectedTab, savedFilter),
        jurisdictions: savedFilter.jurisdictions,
        myTasks: savedFilter.myTasks,
        documentTypes: savedFilter.documentTypes,
        documentStatus: savedFilter.documentStatus,
        workspaceStatus: savedFilter.workspaceStatus,

        groupIds: savedFilter.groupIds,
        userIds: savedFilter.userIds
      },
      true
    ];
  }

  return [defaultOptions, false];
};

export const convertDateTimeObjectToArrayString = (datePeriod: DashboardDateRangeModel | undefined) => {
  const result: string[] = [];
  if (!datePeriod) {
    return result;
  }

  if (datePeriod.start && typeof datePeriod.start === 'string') {
    result.push(datePeriod.start);

    if (
      datePeriod.end &&
      typeof datePeriod.end === 'string' && //
      datePeriod.start === datePeriod.end
    ) {
      return result;
    }
  }

  if (datePeriod.end && typeof datePeriod.end === 'string') {
    result.push(datePeriod.end);
  }

  return result;
};

export const getDefaultDocumentsStatusOptions = (selectedTab: StandaloneDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return DocumentsStatusOptions;

    case StandaloneDashboardRouteTabsEnumV2.active:
      return DocumentsStatusOptions.filter(d => d.id !== DocumentsStatusEnum.Completed);

    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return DocumentsStatusOptions.filter(d => d.id !== DocumentsStatusEnum.Lodged && d.id !== DocumentsStatusEnum.Completed);

    case StandaloneDashboardRouteTabsEnumV2.archived:
    case StandaloneDashboardRouteTabsEnumV2.complete:
      return [];
    default:
      Logger.captureException(new Error(`${selectedTab} is not supported for documents status`));
      return DocumentsStatusOptions;
  }
};

export const getDefaultDocumentTypeOptions = (selectedTab: StandaloneDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  return DocumentTypeOptions.filter(d => !DocumentTypesNotInStandalone.includes(d.id)).sort((a, b) => a.name.localeCompare(b.name));
};

export const getDefaultWorkspaceOptions = (selectedTab: StandaloneDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): LookupItemModel<number>[] => {
  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    return getDefaultReassignWorkspaceOptions(selectedTab);
  }

  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return WorkspaceStatusOptions;

    case StandaloneDashboardRouteTabsEnumV2.active:
      return WorkspaceStatusOptions.filter(d => d.id !== WorkspaceStatusEnum.Completed);

    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.InPreparationWithErrors);

    case StandaloneDashboardRouteTabsEnumV2.complete:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.Completed);

    case StandaloneDashboardRouteTabsEnumV2.archived:
      return WorkspaceArchivedStatusOptions;

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return WorkspaceStatusOptions;
  }
};

const getDefaultReassignWorkspaceOptions = (selectedTab: StandaloneDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.active:
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return WorkspaceStatusOptions.filter(
        d => d.id !== WorkspaceStatusEnum.Queued && d.id !== WorkspaceStatusEnum.Lodged && d.id !== WorkspaceStatusEnum.Lodging && d.id !== WorkspaceStatusEnum.Completed
      );

    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.InPreparationWithErrors);

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return WorkspaceStatusOptions;
  }
};

export const getDefaultSelectOptions = (selectedTab: StandaloneDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): LookupItemModel<number>[] => {
  // each one of them have diff default select list and assign to group allow has the members
  const defaultFilterOptions =
    assignmentType !== AssignmentTypeEnum.AssignedToMe
      ? FilterOptions.concat([
          {
            id: FilterEnum.Groups,
            name: 'Group'
          },
          {
            id: FilterEnum.Members,
            name: 'Member'
          }
        ])
      : FilterOptions;

  switch (selectedTab) {
    case StandaloneDashboardRouteTabsEnumV2.active:
    case StandaloneDashboardRouteTabsEnumV2.all:
    case StandaloneDashboardRouteTabsEnumV2.recent:
      return defaultFilterOptions;

    case StandaloneDashboardRouteTabsEnumV2.toBeReworked:
      return defaultFilterOptions.filter(d => d.id !== FilterEnum.WorkspaceStatus);

    case StandaloneDashboardRouteTabsEnumV2.complete:
      return [
        {
          id: FilterEnum.CompletedDate,
          name: 'Completed Date'
        }
      ]
        .concat(defaultFilterOptions)
        .filter(d => d.id !== FilterEnum.AccessedDate && d.id !== FilterEnum.MyTasks && d.id !== FilterEnum.WorkspaceStatus && d.id !== FilterEnum.DocumentStatus);

    case StandaloneDashboardRouteTabsEnumV2.archived:
      const archivedFilters = [
        {
          id: FilterEnum.ArchivedDate,
          name: 'Archived Date'
        },
        {
          id: FilterEnum.Jurisdiction,
          name: 'Jurisdiction (JDX)'
        },
        {
          id: FilterEnum.DocumentType,
          name: 'Document Type'
        },
        {
          id: FilterEnum.WorkspaceStatus,
          name: 'Status' // this is different to other tab which is workspace status
        }
      ];
      if (assignmentType === AssignmentTypeEnum.AssignedToGroup) {
        archivedFilters.push({
          id: FilterEnum.Groups,
          name: 'Group'
        });
      }

      return archivedFilters;

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return defaultFilterOptions;
  }
};
