import * as yup from 'yup';

import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { DeceasedTenancyDetailValidationConfiguration } from '../../config';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorModel, EvidenceModel } from '../../models';
import { yupDateOfDeath } from '../date-of-death/validationSchema';
import { yupEvidence } from '../evidence/validationSchema';
import { VISIBILITY_CHECK_DATE_OF_DEATH } from './visibilityChecks';

export const resolveDeceasedProprietorSchema = (proprietorGroupType: ProprietorGroupTypeEnum, config?: DeceasedTenancyDetailValidationConfiguration) =>
  yup
    .object<DeceasedProprietorModel>({
      isSelected: yup.bool(),
      partyBookId: yup.mixed(),
      dateOfDeath: validateWhenVisible2<DeceasedProprietorModel['dateOfDeath']>({
        visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH,
        validationWhenVisible: yupDateOfDeath(config),
        isObjectOrArray: true,
        disableEarlyAbort: true
      }),
      evidences: validateWhenVisible2({
        visibilityCheck: (
          { isSelected, partyBookId }: DeceasedProprietorModel,
          { deceasedTenancyDetail: { tenancyType, proprietorGroups }, partyBook }: BaseDeceasedTenancyDetailComponentModel
        ) => {
          if (isSelected) {
            return true;
          }

          if (
            (tenancyType === TenancyTypeEnum.JointTenants && proprietorGroups.every(pg => pg.parties.some(p => p.isSelected))) ||
            (proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse && proprietorGroups.some(pg => pg.parties.some(p => p.isSelected)))
          ) {
            const party = partyBook.find(pb => pb.id === partyBookId)!;
            return Boolean(!isSelected && !party.mergeMetadata?.requiresJustification);
          }

          return false;
        },

        validationWhenVisible: yup.array<EvidenceModel>(yupEvidence(config)).required(msg.REQUIRED).min(1, msg.MIN_ITEMS(1)).max(20, msg.MAX_ITEMS(20)),
        isObjectOrArray: true,
        disableEarlyAbort: true
      })
    })
    .defined();
