import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardTypeEnum } from 'src/models';
import { WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import { AggregatorComponentBaseProps, AggregatorNameEnum, AggregatorQuery, FinancialDashboardAggregatorApiRequest } from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): FinancialDashboardAggregatorApiRequest => {
  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.previous,
      workspaceStatus: [WorkspaceStatusEnum.InPreparationWithErrors],
      withProposals: false
    }
  ];

  const payload: FinancialDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function PreviousCard(props: AggregatorComponentBaseProps) {
  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title="Previous"
          cardType={DashboardTypeEnum.Financial}
          onActionClick={onActionClick}
          isLoading={isLoading}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.previous,
              content,
              status: 'To be Rebooked',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(PreviousCard);
