import { startOfTomorrow, startOfYesterday, sub } from 'date-fns';

import { QldNameChange, QldPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { CommonTenancyLabelsEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/enums';
import { SHARE_TRANSFERRED_TYPE_OPTIONS, ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { TenancyConfigContextData } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/context/tenancyConfigContext';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { NecdsPartyCapacityEnum, PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-24/qld';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { formatNewName } from '../../helpers';

export const QLD_TRANSFER_PARTY_FORM_CONFIG: PartyFormConfig<QldNameChange> = {
  ...QLD_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...QldPartyNameChangeConfig, formatNewName }
};

export const QLD_TRANSFEROR_PARTY_CAPACITY_TYPE: LookupEnumModel<NecdsPartyCapacityEnum, string>[] = restrictedLookupOptions(PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [NecdsPartyCapacityEnum.PersonalRepresentative, NecdsPartyCapacityEnum.Trustee]
});

export const QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = { ...TENANCY_COMPONENT_LABEL_CONFIG, partyRole: CommonTenancyLabelsEnum.Transferor };
export const QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG: Partial<TenancyConfigContextData> = {
  partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG,
  componentLabels: QLD_TRANSFER_RELINQUISHING_TENANCY_LABEL_CONFIG,
  allowNewGroup: true,
  partyCapacityLookup: QLD_TRANSFEROR_PARTY_CAPACITY_TYPE,
  enablePartyCapacityDetail: true,
  partySelectionMode: 'select-when-joint',
  groupSelectionMode: 'select-all',
  resolveDisabledPartyCapacityFields: (tenancyParty: TenancyPartyModel, partyBook: PartyModel[]): boolean => {
    const party = partyBook.find(pb => pb.id === tenancyParty.partyBookId);
    return party?.metadata!.source === DataSource.Title;
  },
  disablePartyEditConfig: disablePartyFieldEditConfig(true)
};

export const QLD_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANSFERRED_TYPE: LookupEnumModel<ShareTransferredTypeEnum, string>[] = restrictedLookupOptions(
  SHARE_TRANSFERRED_TYPE_OPTIONS,
  { whitelist: [ShareTransferredTypeEnum.Whole, ShareTransferredTypeEnum.Partial] }
);
export const QLD_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = { ...TENANCY_COMPONENT_LABEL_CONFIG, partyRole: CommonTenancyLabelsEnum.Transferee };

export const QLD_TRANSFEREE_PARTY_CAPACITY_TYPE: LookupEnumModel<NecdsPartyCapacityEnum, string>[] = restrictedLookupOptions(PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [NecdsPartyCapacityEnum.Trustee]
});

export const QLD_TRANSFER_RECEIVING_TENANCY_CONFIG: Partial<TenancyConfigContextData> = {
  partyFormConfig: {
    ...QLD_TRANSFER_PARTY_FORM_CONFIG,
    individualPartyFormConfig: { ...QLD_TRANSFER_PARTY_FORM_CONFIG.individualPartyFormConfig, allowsNameSuffix: true }
  },
  componentLabels: QLD_TRANSFER_RECEIVING_TENANCY_LABEL_CONFIG,
  allowNewGroup: true,
  partyCapacityMode: 'group',
  partyCapacityLookup: QLD_TRANSFEREE_PARTY_CAPACITY_TYPE,
  forceDobOnIndividual: true,
  disablePartyEditConfig: disablePartyFieldEditConfig(),
  partyDobMinDate: sub(startOfTomorrow(), { years: 150 }),
  partyDobMaxDate: sub(startOfYesterday(), { years: 18 })
};

export const QLD_TRANSFER_DOCUMENT_ATTACHMENT_TYPE: LookupEnumModel<string, string>[] = [
  {
    id: 'Evidence of Charitable Trust',
    name: 'Evidence of Charitable Trust'
  },
  {
    id: 'Evidence of Ministerial Consent',
    name: 'Evidence of Ministerial Consent'
  }
];
