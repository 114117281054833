import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupGenericPhoneNumber } from '@sympli/ui-framework/components/formik/phone-number-field';
import msg from '@sympli/ui-framework/utils/messages';

import { yupEmailAddress } from 'src/containers/documents/scaffolded-form/vic/2-28/helpers/yupEmailAddress';
import { RepresentativeDetailModel } from '../../models';
import { VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL } from '../../visibilityChecks';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: transferorRepresentativeDetail
const yupTransferorRepresentativeDetail = yup //
  .mixed<RepresentativeDetailModel>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL,
      validationWhenVisible: yup
        .object<RepresentativeDetailModel>()
        .required(msg.REQUIRED)
        .shape({
          name: yup.string().default('').trim().required(msg.REQUIRED),
          contactPhoneNumber: yupGenericPhoneNumber.required(msg.REQUIRED).max(20, msg.MAX_LENGTH(20)),
          emailAddress: yupEmailAddress.required(msg.REQUIRED)
        }),
      isObjectOrArray: true
    })
  );

export default yupTransferorRepresentativeDetail;
