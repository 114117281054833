import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class Search {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves a NON paginated list of users matching search criteria. It sends a request to the
   * `/search/users`
   * API route.
   *
   * @param queryParams - Filters.
   * @param config - Additional config to pass with the request.
   * @returns The NON paginated list of users.
   *
   * @example
   * To retrieve the list of users matching search criteria:
   *
   * ```ts
   * sdk.search.listUsersBySearchTerm({
   *   searchTerm: 'John'
   * })
   * .then((users) => {
   *   console.log(users)
   * })
   * ```
   */
  async listUsersBySearchTerm(
    queryParams: {
      searchTerm: string;
    },
    config?: ClientRequestConfig
  ) {
    return this.#client.fetch<HttpTypes.SearchUserListApiResponse>( //
      `/search/users`,
      {
        query: queryParams,
        ...config
      }
    );
  }
}
