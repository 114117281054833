import { CONFIG_PATH } from './resolveRunTimeEnv';

type OidcClient = {
  domain: string;
  clientId: string;
  authorityUrl?: string; // make it optional for now so we do not break the existing SSO
  env?: string; // this is only used for local development
};

interface OktaConfig {
  clientId: string;
  authorityUrl: string;
}

interface NewRelicConfig {
  appId: string;
}

interface EnvironmentConfig {
  okta: OktaConfig;
  newrelic: NewRelicConfig;
}

export type LocalConfigurations = {
  environment: Record<string, EnvironmentConfig>; // Dynamically typed keys (e.g., dev1, sit1, iwt, pp)
  version: number;
};

export type ProductionConfigurations = {
  oidcClients: OidcClient[];
};

const fetchConfigurations = async (): Promise<LocalConfigurations | ProductionConfigurations> => {
  return fetch(CONFIG_PATH).then(response => response.json());
};

export default fetchConfigurations;
