import React from 'react';

import Collapse from '@mui/material/Collapse';
import { ClassNameMap } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import { currency } from '@sympli/ui-framework/utils/formatters';

import BreakdownLineItem from '../breakdown-line-item';
import { useStyles } from './styles';

interface Props {
  lineItems: HttpTypes.WorkspaceDirectionsApiResponse['directions'][number]['lineItemBreakdowns'];
  accountDisplayName?: string;
  category?: string;
  open: boolean;
  columnClasses: ClassNameMap<'columnOne' | 'columnTwo' | 'columnThree'>;
}

function BreakdownsCollapseList({ lineItems, accountDisplayName, category, open, columnClasses }: Props) {
  const classes = useStyles();
  return (
    <Collapse in={open}>
      <div className={classes.breakdownList}>
        {lineItems.map((lineItem, idx) => {
          const isLast = idx === lineItems.length - 1;
          return (
            <BreakdownLineItem
              key={idx}
              amount={currency(lineItem.amount)}
              noBottomBorder={isLast}
              accountDisplayName={accountDisplayName}
              description={`${category ? category + ' ' : ''}(${lineItem.description})`}
              columnClasses={columnClasses}
            />
          );
        })}
      </div>
    </Collapse>
  );
}

export default React.memo(BreakdownsCollapseList);
