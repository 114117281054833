import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { WorkspaceBasicInfoState } from '../../../store/reducers/workspace/workspaceBasicInfo';
import { WorkspaceParticipantsState } from '../../../store/reducers/workspace/workspaceParticipants';
import { MessagingDisabledReasonEnum, MessagingDisabledStatus } from './models';

const getParticipant = (participantId: string, workspaceParticipantsState: WorkspaceParticipantsState): HttpTypes.WorkspaceParticipant | undefined =>
  workspaceParticipantsState.items.find(participant => participant.id === participantId);

const getWithdrawnParticipantIds = (workspaceParticipantsState: WorkspaceParticipantsState): Array<string> =>
  workspaceParticipantsState.items.filter(participant => participant.archivedStatus).map(participant => participant.id);

export const getIsFinalisedOrArchived = function (workspaceBasicInfoState: WorkspaceBasicInfoState): boolean {
  const workspaceStatusId = workspaceBasicInfoState?.detail?.workspaceStatusId;
  return (
    workspaceStatusId !== undefined &&
    [
      //
      HttpTypes.WorkspaceStatusEnum.Finalised,
      HttpTypes.WorkspaceStatusEnum.Success,
      HttpTypes.WorkspaceStatusEnum.Archived,
      HttpTypes.WorkspaceStatusEnum.Abandoned,
      HttpTypes.WorkspaceStatusEnum.Withdrawn
    ].includes(workspaceStatusId)
  );
};

export const messagingDisabledSelector = createSelector<
  //
  {
    participantId: string;
    workspaceBasicInfoState: WorkspaceBasicInfoState;
    workspaceParticipantsState: WorkspaceParticipantsState;
    recipientParticipantIds?: Array<string>;
  },
  boolean,
  HttpTypes.WorkspaceParticipant | undefined,
  Array<string>,
  Array<string> | undefined,
  MessagingDisabledStatus
>(
  args => getIsFinalisedOrArchived(args.workspaceBasicInfoState),
  args => getParticipant(args.participantId, args.workspaceParticipantsState),
  args => getWithdrawnParticipantIds(args.workspaceParticipantsState),
  args => args.recipientParticipantIds,
  (
    //
    isFinalisedOrArchived: boolean,
    currentParticipant: HttpTypes.WorkspaceParticipant | undefined,
    withdrawnParticipantIds: Array<string>,
    recipientParticipantIds?: Array<string>
  ) => {
    if (currentParticipant?.archivedStatus) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.OwnWithdrawn
      };
    }

    if (isFinalisedOrArchived) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.WorkspaceInActive
      };
    }

    const isAnyParticipantWithdrawn = recipientParticipantIds?.some(participantId => withdrawnParticipantIds?.includes(participantId));

    if (isAnyParticipantWithdrawn) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.OtherPartyWithdrawn
      };
    }

    return {
      isDisabled: false
    };
  }
);
