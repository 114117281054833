import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { IconCaretLeft } from '@sympli/ui-framework/icons';

import { PromptCallBack } from './model';
import Prompt from './prompt';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  when: boolean;

  title?: string;
  message?: string;

  primaryButtonText?: string;
  secondaryButtonText?: string;

  customButtonText?: string;
  onCustomButtonClick?: (promptCallback: PromptCallBack) => void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  open: boolean;
}

class PromptConfirmDialog extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    title: 'Leave without saving?',
    message: 'You have not saved your changes. Your changes will be lost if you leave this page.',
    primaryButtonText: 'Stay on this page'
  };
  public readonly state: Readonly<State> = {
    open: false
  };
  private globalPromptTriggerAccessKey;
  private promptCallback: PromptCallBack;

  get hasCustomButton() {
    return !(this.props.customButtonText == null);
  }

  constructor(props: Props) {
    super(props);
    this.globalPromptTriggerAccessKey = _uniqueId('showPromptConfirmDialog');
  }

  componentDidMount() {
    // We need to assign the handleOnOpen function to window level
    // Then we can access on react-router history level to trigger on the custom confirm dialog
    window[this.globalPromptTriggerAccessKey] = this.handleOnOpen as any;
  }

  componentWillUnmount() {
    delete window[this.globalPromptTriggerAccessKey];
  }

  render() {
    const { when, title, message } = this.props;
    const { open } = this.state;
    return (
      <Prompt when={when} message={this.getReactRouterUniqueAccessKeyMessage}>
        <ConfirmationDialog open={open} onClose={this.handleOnClose} title={title} showActionButtons={false}>
          {message}
          {this.renderActionButtons()}
        </ConfirmationDialog>
      </Prompt>
    );
  }

  private renderActionButtons() {
    const { classes } = this.props;

    return (
      <DialogActions classes={{ spacing: classes.dialogActionButton }} className={classes.dialogActions}>
        {this.renderSecondaryButton()}
        {this.renderPrimaryButton()}
        {this.renderCustomButton()}
      </DialogActions>
    );
  }

  private renderSecondaryButton() {
    const { secondaryButtonText } = this.props;
    if (secondaryButtonText == null) {
      return null;
    }
    return (
      <ButtonLink onClick={this.handleOnSecondaryButtonClick} icon={<IconCaretLeft width="18" height="18" />} color="inherit">
        {secondaryButtonText}
      </ButtonLink>
    );
  }

  private renderPrimaryButton() {
    const { classes, primaryButtonText } = this.props;
    const variant = this.hasCustomButton ? 'outlined' : 'contained';
    return (
      <Button onClick={this.handleOnPrimaryButtonClick} color="primary" variant={variant} className={classes.primaryButton}>
        {primaryButtonText}
      </Button>
    );
  }

  private renderCustomButton() {
    const { classes, customButtonText } = this.props;
    if (!this.hasCustomButton) {
      return null;
    }
    return (
      <Button onClick={this.handleOnCustomButtonClick} color="primary" variant="contained" className={classes.customButton}>
        {customButtonText}
      </Button>
    );
  }

  private getReactRouterUniqueAccessKeyMessage = () => {
    // Pass the unique access key as message, which will be used in
    // getCustomUserConfirmation to access and invoke handleOnOpen function
    return this.globalPromptTriggerAccessKey;
  };

  private handleOnOpen = (callback: PromptCallBack) => {
    this.setState({ open: true });
    this.promptCallback = callback;
  };

  private handleOnClose = () => {
    this.setState({ open: false });
    this.promptCallback(false); // react-router ignore redirect
  };

  private handleOnPrimaryButtonClick = () => {
    this.setState({ open: false });
    this.promptCallback(false); // react-router ignore redirect
  };

  private handleOnSecondaryButtonClick = () => {
    this.setState({ open: false });
    this.promptCallback(true); // redirect to new url
  };

  private handleOnCustomButtonClick = () => {
    this.setState({ open: false });
    this.props.onCustomButtonClick && this.props.onCustomButtonClick(this.promptCallback); // redirect controlled by custom handler
  };
}

export default withStyles(styles)(PromptConfirmDialog);
