import queryString from 'query-string';

import { HttpTypes } from '@sympli/api-gateway/types';

export const ReportDetailEnumRoutingMap: Record<HttpTypes.ReportTypeEnum, string> = {
  [HttpTypes.ReportTypeEnum.UserPermissionReport]: 'user-permissions',
  [HttpTypes.ReportTypeEnum.UserActivityReport]: 'user-activity',
  [HttpTypes.ReportTypeEnum.TeamWorkloadReport]: 'team-workload'
};

export const resolveReportLink = (feature: HttpTypes.ReportFeatureTypeEnum, reportType: HttpTypes.ReportTypeEnum, reportId?: number): string => {
  const link =
    feature === HttpTypes.ReportFeatureTypeEnum.Favourite //
      ? `/reports/${ReportDetailEnumRoutingMap[reportType]}/${reportId ?? 'new'}`
      : `/reports/scheduled/history/${reportId}`;

  return link;
};

export function resolveWorkspaceDetailLink(params: {
  //
  workspaceId: string;
  participantId?: string;
}) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);

  return `/workspace/${encodeURIComponent(workspaceId)}/detail?${query}`;
}
