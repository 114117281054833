import * as React from 'react';

import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import { ErrorMessageQueueItemModel } from '../../store/actions/globalErrors';
import { DialogTypeEnum } from './message-dialog/models';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props: MuiAlertProps, ref: React.ForwardedRef<any>) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GlobalSnackbar({ items, onClose }: { items: ErrorMessageQueueItemModel[]; onClose(id: string): void }) {
  const open = !!items.length;

  React.useEffect(() => {
    const timers = items
      .filter(item => item.autohideSec)
      .map(item => {
        return setTimeout(onClose, item.autohideSec! * 1000, item.id);
      });

    return () => {
      timers.forEach(t => clearTimeout(t));
    };
  }, [items, onClose]);

  return (
    <Snackbar open={open} TransitionComponent={SlideTransition}>
      <Stack flexDirection="column" gap={1}>
        {items.map(({ id = '', type, message }) => {
          const severity = type === DialogTypeEnum.Error ? 'error' : 'success';
          return (
            <Grow key={id} in={open} timeout={1000}>
              <Alert onClose={() => onClose(id)} severity={severity}>
                {message}
              </Alert>
            </Grow>
          );
        })}
      </Stack>
    </Snackbar>
  );
}

export default React.memo(GlobalSnackbar);
