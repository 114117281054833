import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export interface TrustAccountsRegisteredRequestedLists {
  registeredItems: HttpTypes.TrustAccountDetails[];
  requestedItems: HttpTypes.TrustAccountRequestDetailsApiResponse[];
}

export const actionFetchTrustAccountLists = createAsyncAction(
  //
  'FETCH_TRUST_ACCOUNT_LISTS',
  'FETCH_TRUST_ACCOUNT_LISTS_SUCCESS',
  'FETCH_TRUST_ACCOUNT_LISTS_ERROR'
)<void, { data: TrustAccountsRegisteredRequestedLists }, { error: Error }>();

export const actionToggleNewAccountAddedFlag = createAction(
  //
  'TOGGLE_NEW_TRUST_ACCOUNT_ADDED_FLAG'
)<boolean>();
