import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

const ANIMATION_DURATION = '0.2s';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    input: {
      // toggle-bg
      '& ~ .toggle-bg': {
        borderRadius: 80,
        background: colors.NEUTRAL_050,
        boxShadow: '0px 2px 2px 0px #D4D5D7 inset'
      },
      '&:checked ~ .toggle-bg': {
        background: colors.SUNNY_DAY,
        boxShadow: '0px 2px 2px 0px #D6AB00 inset'
      },
      '&[disabled] ~ .toggle-bg': {
        opacity: 0.4
      },

      // toggle-dot
      '& ~ .toggle-dot': {
        width: 40,
        left: 2,
        backgroundColor: theme.palette.common.white,
        transition: `left ${ANIMATION_DURATION} linear`
      },
      '&:checked ~ .toggle-dot': {
        width: 40,
        left: 87,
        transition: 'left 0.15s linear'
      },

      // toggle-text
      '& ~ .toggle-text': {
        userSelect: 'none',
        color: colors.NEUTRAL_600,
        fontWeight: 400,
        left: 48,
        transition: `color ${ANIMATION_DURATION} linear, left ${ANIMATION_DURATION} linear`
      },
      '&:checked ~ .toggle-text': {
        color: colors.NEUTRAL_700,
        transition: `color ${ANIMATION_DURATION} linear, left ${ANIMATION_DURATION} linear`,
        fontWeight: 700,
        left: 10
      },

      // toggle-cross
      '& ~ .toggle-cross': {
        zIndex: 1,
        fill: colors.NEUTRAL_600,
        stroke: colors.NEUTRAL_600,
        transition: `fill ${ANIMATION_DURATION} linear`
      },

      // toggle-tick
      '& ~ .toggle-tick': {
        zIndex: 1,
        fill: colors.NEUTRAL_1000,
        transition: `fill ${ANIMATION_DURATION} linear`
      }
    }
  }),
  {
    name: 'ToggleCheckbox'
  }
);
