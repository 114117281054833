import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { formatPartyName } from '@sympli-mfe/document-forms-framework/components/party-form';
import { filterObjectByValue } from '@sympli-mfe/document-forms-framework/utils/object';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { CaveatModel } from '../../models';

export function resolvePartyLookupOptions(caveatModel: CaveatModel): LookupItemModel[] {
  const FORMATED_PARTY_OPTIONS: LookupItemModel[] = caveatModel.partyBook.map(party => ({ id: party.id, name: formatPartyName(party, NSW_PARTY_FORM_CONFIG) }));
  const uniqueNameCounts: Record<string, number> = {};
  for (const formattedName of FORMATED_PARTY_OPTIONS.map(({ name }) => name)) {
    const previousCount = uniqueNameCounts[formattedName] ?? 0;
    uniqueNameCounts[formattedName] = previousCount + 1;
  }
  if (Object.keys(uniqueNameCounts).length === FORMATED_PARTY_OPTIONS.length) {
    // All names are unique so we do not need to append any additional suffix for identification
    return FORMATED_PARTY_OPTIONS;
  }

  const repeatedNames = Object.keys(filterObjectByValue(uniqueNameCounts, count => count > 1));

  const proprietors = caveatModel.proprietors || [];
  for (const repeatedName of repeatedNames) {
    let nonProprietorPartyIndex = 1;
    for (const option of FORMATED_PARTY_OPTIONS.filter(({ name }) => name === repeatedName)) {
      const proprietorIndex = proprietors.findIndex(({ partyBookId }) => partyBookId === option.id);
      // If the party corresponds to a proprietor, label the party as a proprietor. Otherwise, use an ascending index.
      const identifyingSuffix = proprietorIndex !== -1 ? (proprietors.length === 1 ? 'Proprietor' : `Proprietor ${proprietorIndex + 1}`) : nonProprietorPartyIndex++;
      // Using mutation here, but it is permissible as it is the same method in which the object was created.
      option.name = `${option.name} (${identifyingSuffix})`;
    }
  }
  return FORMATED_PARTY_OPTIONS;
}
