import _uniq from 'lodash-es/uniq';
import { defaultMemoize } from 'reselect';

import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveMatchingPartyHashesOnTAB } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-beneficiary-devisee-next-of-kin/2-21-2/utils/resolveMatchingPartyHashesOnTAB';
import { resolveMatchingPartyHashesOnTAE } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-without-duty/2-21-3/utils/resolveMatchingPartyHashesOnTAE';
import { isItemInGroup } from '../../../helpers';
import { NoticeOfDeath_2_21_2_Model } from '../models';
import { resolveDeceasedPartiesOnNOD } from './resolveDeceasedPartiesOnNOD';
import { resolveMatchingPartyHashesOnNOD } from './resolveMatchingPartyHashesOnNOD';

type FormModel = NoticeOfDeath_2_21_2_Model;

// explicitly memoize this call so we can call it multiple times without re-calculating
const resolveDeceasedPartyBookItemsOnCurrentDocument = defaultMemoize(
  (
    //
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'],
    partyBook: FormModel['partyBook']
  ): FormModel['partyBook'] => {
    return resolveDeceasedPartiesOnNOD(deceasedTenancyDetail, partyBook);
  }
);

export const calculateCrossDocumentMatches = ({
  workspaceDocuments,
  documentId,
  context,
  deceasedTenancyDetail,
  partyBook
}: {
  //
  workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[];
  documentId: string;
  context: IConverterContext;
  deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
  partyBook: FormModel['partyBook'];
}): {
  matchingParties: Map<string, Set<string>>;
  matchingGroups: Map<string, Set<string>>;
} => {
  const matchingParties = new Map<
    /**
     * matchingItemHash
     */
    string,
    /**
     * list of documents
     */
    Set<string>
  >();

  const matchingGroups = new Map<
    /**
     * matchingItemHash
     */
    string,
    /**
     * list of documents
     */
    Set<string>
  >();
  // find selected parties from current document
  const selectedPartiesFromCurrentDocument: FormModel['partyBook'] = resolveDeceasedPartyBookItemsOnCurrentDocument(deceasedTenancyDetail, partyBook) //
    .filter(p => p.mergeMetadata); // we will need the mergedMetadata, so we can compare it with other documents

  if (selectedPartiesFromCurrentDocument.length) {
    // selected parties from current document (although for NOD we support only one selected party always, this is generic and can be used across any other documents)
    selectedPartiesFromCurrentDocument.forEach(party => {
      // Ensure that data exists
      if (!party || !party.mergeMetadata) return;

      // initialize
      matchingParties.set(party.mergeMetadata.matchingItemHash, new Set<string>());
      // initialize empty list
      // this key may later be removed if we find a surviving party in the same group
      matchingGroups.set(party.mergeMetadata.matchingGroupHash, new Set<string>());
    });

    const deceasedAcrossNOD = resolveMatchingPartyHashesOnNOD({
      selectedPartiesFromCurrentDocument,
      // exclude the current document from the list
      workspaceDocuments: workspaceDocuments.filter(doc => doc.documentId !== documentId),
      context
    });
    const deceasedAcrossTAE = resolveMatchingPartyHashesOnTAE({
      //
      selectedPartiesFromCurrentDocument,
      workspaceDocuments,
      context
    });
    const deceasedAcrossTAB = resolveMatchingPartyHashesOnTAB({
      //
      selectedPartiesFromCurrentDocument,
      workspaceDocuments,
      context
    });

    // process other documents and add their names to final list
    deceasedAcrossNOD.matchingByPartyItemHash.forEach(matchingItemHash => {
      matchingParties.get(matchingItemHash)?.add('Notice of Death');
    });
    deceasedAcrossTAE.matchingByPartyItemHash.forEach(matchingItemHash => {
      matchingParties.get(matchingItemHash)?.add('Transmission Application');
    });
    deceasedAcrossTAB.matchingByPartyItemHash.forEach(matchingItemHash => {
      matchingParties.get(matchingItemHash)?.add('Transmission Application');
    });

    const selectedPartyItemHashesFromAllDocuments: string[] = _uniq([
      // parties on the current document
      ...matchingParties.keys(),
      // plus all parties selected on other documents that has same matchingGroupHash as items in selectedPartiesFromCurrentDocument
      // These parties may no be the exact same party as in selectedPartiesFromCurrentDocument, but they are from the same group
      ...deceasedAcrossNOD.matchingByGroupHash,
      ...deceasedAcrossTAE.matchingByGroupHash,
      ...deceasedAcrossTAB.matchingByGroupHash
    ]);

    selectedPartiesFromCurrentDocument.forEach(party => {
      // the group this party belongs to:
      if (!party || !party.mergeMetadata) return;

      const { matchingGroupHash } = party.mergeMetadata;
      const allPartiesInTheSameGroup: FormModel['partyBook'] = partyBook.filter(p => p.mergeMetadata?.matchingGroupHash === matchingGroupHash);

      // FYI the format of hashes is as follows
      // matchingGroupHash: "55/1185415+GI-2"
      // matchingItemHash: "55/1185415+GI-2+II-0"
      // this allows us to identify parties in the same group by using the matchingGroupHash followed by a + sign
      const selectedPartiesInTheSameGroup = selectedPartyItemHashesFromAllDocuments.filter(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash));
      // check if there is any surviving party in the same group
      // if there is, we need to remove the group from our original list, since we don't want to show warning for the group that has a surviving party
      if (allPartiesInTheSameGroup.length > selectedPartiesInTheSameGroup.length) {
        matchingGroups.delete(matchingGroupHash);
      }
    });
    // matchingGroups now contains only keys of the group that has no surviving party
    // now we can go through other documents and add their names to final list if their parties are from the identified groups
    for (const matchingGroupHash of matchingGroups.keys()) {
      if (deceasedAcrossNOD.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
        matchingGroups.get(matchingGroupHash)?.add('Notice of Death');
      }
      if (deceasedAcrossTAE.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
        matchingGroups.get(matchingGroupHash)?.add('Transmission Application');
      }
      if (deceasedAcrossTAB.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
        matchingGroups.get(matchingGroupHash)?.add('Transmission Application');
      }
    }
  }

  return {
    matchingParties,
    matchingGroups
  };
};
