import React from 'react';

import { SettlementDateDetailsState, useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { useWorkspaceBasicInfo, WorkspaceBasicInfoState } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceParticipants, WorkspaceParticipantsState } from 'src/store/reducers/workspace/workspaceParticipants';
import InvitationsCardDetail from './InvitationsCardDetail';

interface Props {
  participantId: string;
  workspaceId: string;
  className?: string;
  invitationColumn?: string; // this component has been shared with SDT and in SDT they want to name it participants
  showWorkspaceRoleColumn?: boolean;
  displayActionColumn?: boolean;
}

function InvitationsCardDetailContainer({ participantId, workspaceId, className, invitationColumn, showWorkspaceRoleColumn, displayActionColumn }: Props) {
  const workspaceBasicInfoState: WorkspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipantsState: WorkspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const settlementDateDetailsState: SettlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);

  return (
    <InvitationsCardDetail //
      workspaceParticipantsState={workspaceParticipantsState}
      settlementDateDetailsState={settlementDateDetailsState}
      workspaceBasicInfoState={workspaceBasicInfoState}
      className={className}
      invitationColumnName={invitationColumn}
      showWorkspaceRoleColumn={showWorkspaceRoleColumn}
      displayActionColumn={displayActionColumn}
      currentParticipantId={participantId}
      workspaceId={workspaceId}
    />
  );
}

export default React.memo(InvitationsCardDetailContainer);
