import GenericLayoutWrapper from '../../components/layout/GenericLayoutWrapper';
import { useProfile } from '../../store/reducers/profile';
import HelpPage from './HelpPage';

interface Props {
  topPanel: React.ReactNode;
}

function HelpPageContainer({ topPanel }: Props): JSX.Element {
  const subscriberName = useProfile().data?.subscriberName;
  const supportPageUrl = useProfile().data?.supportPageUrl;

  return (
    <GenericLayoutWrapper topPanel={topPanel}>
      <HelpPage subscriberName={subscriberName} supportPageUrl={supportPageUrl} />
    </GenericLayoutWrapper>
  );
}

export default HelpPageContainer;
