import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { resolveAuthorisationRecordLink } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/helpers';
import { resolveDirectionsLink } from 'src/containers/workspace/financial/directions/helpers';
import { resolveSettlementDateLink } from 'src/containers/workspace/financial/settlement-date/helper';
import { resolveStampDutyLink } from 'src/containers/workspace/financial/stamp-duty/helpers';
import { TaskTypesEnum } from 'src/models/tasks';
import { actionSetDocumentListAction, actionSetTitleListAction } from 'src/store/actions/workspace';
import { WorkspaceTaskDataObject } from './models';

export function resolveTaskUrgency({
  taskType,
  workspaceStatusId,
  isRollover
}: {
  taskType: TaskTypesEnum;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  isRollover?: boolean;
}): 'high' | 'medium' | 'normal' | 'global' {
  if (!isRollover && taskType === TaskTypesEnum.ReviewNewSettlementDate) {
    return 'medium';
  }

  if (taskType === TaskTypesEnum.ProposeSettlementTime || taskType === TaskTypesEnum.ProposeSettlementDateAndTime) {
    return 'global';
  }

  if (
    isRollover || //
    taskType === TaskTypesEnum.ResolveDocumentIssues ||
    taskType === TaskTypesEnum.RebookSettlementDate ||
    workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Failed
  ) {
    return 'high';
  }
  return 'normal';
}

interface TaskRedirectParams {
  workspaceId: string;
  documentId?: string;
  participantId?: string;
  taskId: string;
  taskType: TaskTypesEnum;
  taskGroupType: HttpTypes.TaskGroupTypeEnum;
  role?: HttpTypes.WorkspaceRoleEnum;
  data: WorkspaceTaskDataObject;
}

interface TaskRedirect {
  action?: Action;
  link: string;
}

function resolveOtherPage(data: TaskRedirectParams): TaskRedirect {
  const { workspaceId, participantId = '', taskType } = data;
  switch (taskType) {
    case TaskTypesEnum.RebookSettlementDate:
    case TaskTypesEnum.ReviewNewSettlementDate:
      return { link: resolveSettlementDateLink({ workspaceId, participantId }) };
    case TaskTypesEnum.SendInvitation:
    case TaskTypesEnum.ResendInvitation:
      return { link: resolveWorkspaceDetailLink({ workspaceId, participantId }) };
    case TaskTypesEnum.VerifyStampDuty:
    case TaskTypesEnum.CancelStampDuty:
      return { link: resolveStampDutyLink({ workspaceId, participantId }) };
    case TaskTypesEnum.SignAccountAuthorisationRecord:
      const { id: accountId, type } = data.data || {};
      return { link: resolveAuthorisationRecordLink({ workspaceId, participantId }, accountId, type!) };
    case TaskTypesEnum.ProposeSettlementTime:
    case TaskTypesEnum.ProposeSettlementDateAndTime:
      return { link: resolveSettlementDateLink({ workspaceId, participantId }) };
    case TaskTypesEnum.AddDocument:
      return {
        link: '',
        action: actionSetDocumentListAction({ mode: 'add' })
      };
    case TaskTypesEnum.AddTitle:
      return {
        link: '',
        action: actionSetTitleListAction({ mode: 'add' })
      };
    default:
      Logger.captureException(new InvalidDataError('Task type not supported!', taskType));
      return { link: '' };
  }
}

export function resolveTaskRedirect(data: TaskRedirectParams): TaskRedirect {
  const { workspaceId, participantId = '', taskId, taskGroupType, documentId } = data;

  switch (taskGroupType) {
    case HttpTypes.TaskGroupTypeEnum.Document:
      return { link: resolveDocumentDetailLink({ workspaceId, documentId: documentId!, participantId, taskId }) };
    case HttpTypes.TaskGroupTypeEnum.Directions:
      return { link: resolveDirectionsLink({ workspaceId, participantId }) };
    case HttpTypes.TaskGroupTypeEnum.Lodgement:
      return { link: resolveWorkspaceDetailLink({ participantId, workspaceId }) };
    case HttpTypes.TaskGroupTypeEnum.Other:
      return resolveOtherPage(data);
    default:
      Logger.captureException(new InvalidDataError('Task group not supported!', taskGroupType));
      return { link: '' };
  }
}
