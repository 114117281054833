import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import { AnimationVariant, ProgressNotificationPanel } from '@sympli/components-library/progress-notification-panel';
import { StatusChip, StatusChipVariant } from '@sympli/components-library/status-chip';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from 'src/@core/components/layout/constants';
import { IconStar } from 'src/components/icons';
import { SettlementStepEnum } from 'src/containers/workspace/shared/detail/models';
import { AcknowledgementErrorCodeEnum } from 'src/containers/workspace/shared/models';
import { SettlementDateDetailsModel } from '../../../settlement-date/reducers/settlementDetail';

export enum WorkspaceProgressPanelNotificationTypeEnum {
  /**
   * Preview: ![LodgementInProgress](__preview/lodgement_in_progress.png)
   */
  LodgementInProgress,
  /**
   * Preview: ![LodgementVerificationInProgress](__preview/lodgement_verification_in_progress.png)
   */
  LodgementVerificationInProgress,
  /**
   * Preview: ![](__preview/lodgement_paused.png)
   */
  LodgementPaused,
  /**
   * Preview: ![SettlementInProgress](__preview/settlement_in_progress.png)
   */
  SettlementInProgress,
  /**
   * Preview: ![SettlementPaused](__preview/settlement_paused.png)
   */
  SettlementPaused,
  /**
   * Preview: ![SettlementRollover](__preview/settlement_rollover.png)
   */
  SettlementRollover,
  /**
   * Preview: ![SettlementSuccessful](__preview/settlement_successful_awaiting_registration.png)
   */
  SettlementSuccessfulAwaitingRegistration,
  /**
   * Preview: ![SettlementSuccessful](__preview/settlement_successful_documents_registered.png)
   */
  SettlementSuccessfulDocumentsRegistered,
  /**
   * Preview: ![SettlementUnsuccessful](__preview/settlement_unsuccessful.png)
   */
  SettlementUnsuccessful,
  /**
   * Preview: ![SettlementUnsuccessful](__preview/funds_not_balanced.png)
   */
  FundsNotBalanced,
  /**
   * Preview: ![](__preview/lodgement_verification_error_financial.png)
   */
  LodgementVerificationError,
  /**
   * Preview: ![](__preview/lodgement_verification_error_lrs_unavilable_financial.png)
   */
  LodgementVerificationErrorDueToLrsUnavailable,
  /**
   * Preview: ![](__preview/lodgement_technical_error.png)
   */
  LodgementTechnicalError,
  /**
   * Preview: ![](__preview/lodgement_technical_error_lrs_unavailable.png)
   */
  LodgementTechnicalErrorDueToLrsUnavailable
}

export function resolveWorkspaceProgressNotificationPanelTypeForPLC(props: {
  //
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum;
  settlementStatusId?: HttpTypes.SettleSmartStatusEnum;
  settlementStep?: SettlementStepEnum;
  settlementRollover?: boolean;
  lastSettlementReadyCheckReasonId?: HttpTypes.ReadyForSettlementFailureCodeEnum;
  settlementDateDetail?: {
    proposedSettlementDate: SettlementDateDetailsModel['proposedSettlementDate'];
    isAcceptedByAll: SettlementDateDetailsModel['isAcceptedByAll'];
    isAcceptedByUser: SettlementDateDetailsModel['isAcceptedByUser'];
  };
  acknowledgementErrorCode?: string;
}): WorkspaceProgressPanelNotificationTypeEnum | undefined {
  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.Fail && props.workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Failed) {
    // the panel should disappear when all participants accepted new settlement date (workspace status will change to onSchedule in this case)
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementUnsuccessful;
  }

  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.InProgress && props.workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementIssue) {
    // manual intervention required
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementPaused;
  }

  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.InProgress && props.workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementInProgress) {
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementInProgress;
  }

  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.Completed && props.lodgementCaseStatusId !== HttpTypes.LodgementCaseStatusEnum.Registered) {
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulAwaitingRegistration;
  }

  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.Completed && props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.Registered) {
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulDocumentsRegistered;
  }

  //! for primary lodgement case we should not display lodgement in progress panel
  // if (props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementRequested) {
  //   return WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress;
  // }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested && props.settlementStep !== SettlementStepEnum.LodgementVerification) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress;
  }

  // settlementRollover flag is set by the system in case of settlement failure that requires a retry in another half an hour
  // the moment a participant proposes a different settlement date/time, the flag will be set to false
  // then all participants should not see the rollover panel.
  // if all participants cannot agree on the new settlement date/time, the system will take over once time arrives, and isRollOver flag will once again be true,
  // we display the rollover panel again.
  if (props.settlementStatusId === HttpTypes.SettleSmartStatusEnum.Delayed && props.settlementRollover) {
    return WorkspaceProgressPanelNotificationTypeEnum.SettlementRollover;
  }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError) {
    return props.acknowledgementErrorCode === AcknowledgementErrorCodeEnum.ServiceNotavailableAtThisTime
      ? WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationErrorDueToLrsUnavailable
      : WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationError;
  }

  if (props.lastSettlementReadyCheckReasonId === HttpTypes.ReadyForSettlementFailureCodeEnum.FinancialBalanceCheckFailed) {
    return WorkspaceProgressPanelNotificationTypeEnum.FundsNotBalanced;
  }
}

export function resolveWorkspaceProgressNotificationPanelTypeForSLC(props: {
  //
  lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum;
  acknowledgementErrorCode?: string;
}): WorkspaceProgressPanelNotificationTypeEnum | undefined {
  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementRequested) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress;
  }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress;
  }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused;
  }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError) {
    return props.acknowledgementErrorCode === AcknowledgementErrorCodeEnum.ServiceNotavailableAtThisTime
      ? WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationErrorDueToLrsUnavailable
      : WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationError;
  }

  if (props.lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementTechnicalError) {
    return props.acknowledgementErrorCode === AcknowledgementErrorCodeEnum.ServiceNotavailableAtThisTime
      ? WorkspaceProgressPanelNotificationTypeEnum.LodgementTechnicalErrorDueToLrsUnavailable
      : WorkspaceProgressPanelNotificationTypeEnum.LodgementTechnicalError;
  }
}

function notificationTypeToPanelProps(
  //
  notificationType: WorkspaceProgressPanelNotificationTypeEnum,
  templateArgs: Partial<{
    documentName: string;
    lodgementCaseName: string;
  }> = {}
): {
  animationVariant?: AnimationVariant;
  title: {
    variant?: StatusChipVariant;
    children: React.ReactNode;
  };
  description: React.ReactNode;
} {
  switch (notificationType) {
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress:
      return {
        animationVariant: undefined,
        title: {
          variant: 'yellow',
          children: 'Lodgement In Progress'
        },
        description: (
          <div>
            The lodgement of the <b>'{templateArgs.documentName}'</b> document is with the <b>Land Registry</b>.
            <br />
            This might take a <b>few seconds</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress:
      return {
        animationVariant: undefined,
        title: {
          variant: 'yellow',
          children: 'Lodgement Verification In Progress'
        },
        description: (
          <div>
            The verification of the <b>{templateArgs.lodgementCaseName}</b> is with the <b>Land Registry</b>.<br />
            This might take a <b>few seconds</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationError:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Verification Unsuccessful'
        },
        description: (
          <div>
            <b>Something went wrong.</b>
            <br />
            The verification of the <b>{templateArgs.lodgementCaseName}</b> with the Land Registry was unsuccessful.
            <br />
            Please try again or call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationErrorDueToLrsUnavailable:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Verification Unsuccessful'
        },
        description: (
          <div>
            <b>Something went wrong.</b>
            <br />
            The verification of the <b>{templateArgs.lodgementCaseName}</b> with the Land Registry was unsuccessful as the <b>land registry is unavailable</b>.
            <br />
            Please try again during business hours or call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Paused'
        },
        description: (
          <div>
            The document in <b>{templateArgs.lodgementCaseName}</b> has attempted to lodge, however, it has <b>encountered technical issues.</b>
            <br />
            Please <b>lodge the document again</b>.
            <br />
            Alternatively, please call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementTechnicalError:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Paused'
        },
        description: (
          <div>
            The document in this workspace has attempted to lodge, however, it has <b>encountered technical issues.</b>
            <br />
            Please <b>lodge the workspace again</b>.
            <br />
            Alternatively, please call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementTechnicalErrorDueToLrsUnavailable:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Paused'
        },
        description: (
          <div>
            The document in this workspace has attempted to lodge, however, it has <b>encountered technical issues.</b>
            <br />
            <b>The land registry is unavailable</b>.
            <br />
            Please try again during business hours or call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.FundsNotBalanced:
      return {
        animationVariant: undefined,
        title: {
          children: 'Funds not balanced'
        },
        description: (
          <div>
            This workspace is not ready for settlement as the funds are not balanced.
            <br />
            Please review your source funds and payments.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.SettlementInProgress:
      return {
        animationVariant: 'in-progress',
        title: {
          variant: 'green',
          children: 'Settlement In Progress'
        },
        description: (
          <div>
            The <b>settlement process has now started</b>. Editing the workspace’s content is <b>temporarily unavailable</b>.
            <br />
            This might take a <b>few minutes</b>.
            <br />
            Not long now!
          </div>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulDocumentsRegistered:
      return {
        animationVariant: 'success',
        title: {
          variant: 'light-green',
          children: (
            <>
              <span className="pr-2">Settlement Successful</span>
              <IconStar />
            </>
          )
        },
        description: (
          <>
            <div>
              The workspace has <b>been settled</b> & <b>completed</b>! For further information regarding the <b>registration</b> please refer to <b>individual documents status</b>
              (es).
            </div>
            <div className="text-sm italic text-[color:var(--neutral-500)]">
              Note | <b>90 days</b> from settlement completion the system <b>will archive this workspace</b> (including potential uncompleted Supported Lodgement Cases). The system
              is archiving so you are able to search faster.
            </div>
          </>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulAwaitingRegistration:
      return {
        animationVariant: 'success',
        title: {
          variant: 'light-green',
          children: (
            <>
              <span className="pr-2">Settlement Successful</span>
              <IconStar />
            </>
          )
        },
        description: (
          <div>
            The workspace has now <b>been settled</b>! <b>Registration</b> is still with the <b>Land Registry</b>.
          </div>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.SettlementRollover:
      return {
        animationVariant: 'rollover',
        title: {
          variant: 'red',
          children: 'Settlement Rollover'
        },
        description: (
          <div>
            This workspace <b>attempted to settle</b>. However, it has encountered errors.
            <br />
            The system will automatically try to settle this workspace again in the next <b>30 minutes</b> and will continue to try every 30 minutes until the
            <b> end of the business day</b>. We call this process <b>'Rollover'</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.SettlementPaused:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Settlement Paused'
        },
        description: (
          <div>
            This Workspace has attempted to settle, however, it has <b>encountered issues</b>.<br />
            We are working hard to resolve those issues. When its all done this workspace will continue the settlement. Monitor this workspace for <b>a few minutes</b>.  If the
            state hasn’t changed, please call <b>Sympli Customer Support</b> to <b>help you settle</b>!
          </div>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.SettlementUnsuccessful:
      return {
        animationVariant: 'error',
        title: {
          variant: 'red',
          children: 'Settlement Unsuccessful'
        },
        description: (
          <div>
            Something went wrong.
            <br />
            This workspace has <b>attempted to settle</b> without success. <b>Review and edit the workspace content</b> and then <b>rebook the settlement date and time</b> to
            initiate another settlement.
          </div>
        )
      };
    default:
      throw new Error(`Unknown notification type: ${notificationType}`);
  }
}

interface Props {
  notificationType: WorkspaceProgressPanelNotificationTypeEnum;
  className?: string;
  // template args used for lodgement in progress and lodgement verification in progress
  documentName?: string;
  lodgementCaseName?: string;
}

export function WorkspaceProgressNotificationPanel(props: Props) {
  const { animationVariant, title, description } = notificationTypeToPanelProps(props.notificationType, {
    documentName: props.documentName,
    lodgementCaseName: props.lodgementCaseName
  });
  return (
    <ProgressNotificationPanel //
      className={props.className}
      animationVariant={animationVariant}
    >
      {title.variant ? (
        <StatusChip //
          variant={title.variant}
        >
          {title.children}
        </StatusChip>
      ) : (
        <div className="text-sm font-bold not-italic leading-5 text-[color:var(--neutral-700)]">{title.children}</div>
      )}
      {description}
    </ProgressNotificationPanel>
  );
}

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      left: -32,
      // this needs to ba aligned with MuiTab settings in our ui-framework
      top: -28,
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        top: -8
      }
    }
  }),
  {
    name: 'WorkspaceProgressNotificationPanelBackground'
  }
);

export function WorkspaceProgressNotificationPanelBackground({
  //
  notificationType,
  className
}: {
  //
  notificationType: WorkspaceProgressPanelNotificationTypeEnum;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        //
        'w-[calc(100vw_-_calc(var(--small-content-left-panel)_+_calc(100vw_-_var(--small-content))_/_2)_-_19px)]',
        'medium-screen:w-[calc(100vw_-_calc(var(--medium-content-left-panel)_+_calc(100vw_-_var(--medium-content))_/_2)_-_19px)]',
        'large-screen:w-[calc(100vw_-_calc(var(--large-content-left-panel)_+_calc(100vw_-_var(--large-content))_/_2)_-_19px)]',
        'h-[144px]',
        classes.root,

        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress && 'bg-[var(--sunny-day-translucent)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress && 'bg-[var(--sunny-day-translucent)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.FundsNotBalanced && 'bg-[var(--sunny-day-translucent)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementInProgress && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulAwaitingRegistration && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementSuccessfulDocumentsRegistered && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementPaused && 'bg-[var(--watermelon-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementRollover && 'bg-[var(--watermelon-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.SettlementUnsuccessful && 'bg-[var(--watermelon-lite)]',
        className
      )}
    ></div>
  );
}
