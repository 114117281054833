import * as yup from 'yup';

import { ConsiderationModel } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { AllowedConsiderationsJurisdiction } from '@sympli-mfe/document-forms-framework/components/sections/consideration/models';
import { yupConsiderationAmount, yupConsiderationType } from '@sympli-mfe/document-forms-framework/components/sections/consideration/validation-schema';

export default (jurisdiction: AllowedConsiderationsJurisdiction) =>
  yup
    .object<ConsiderationModel>({
      type: yupConsiderationType(jurisdiction),
      amount: yupConsiderationAmount
    })
    .defined();
