import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchSupportedJurisdictions } from '../actions';

export interface SupportedJurisdictionModel {
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  supportedJurisdictions: Array<HttpTypes.JurisdictionsEnum>;
}

export type SupportedJurisdictionApiResponse = SupportedJurisdictionModel[];

export interface SupportedJurisdictionsState {
  items: SupportedJurisdictionApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: SupportedJurisdictionsState = {
  items: [],
  status: 'idle',
  error: undefined
};

const supportedJurisdictionReducer = createReducer<
  //
  SupportedJurisdictionsState,
  Action
>(initialState)
  .handleAction(actionFetchSupportedJurisdictions.request, (state): SupportedJurisdictionsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchSupportedJurisdictions.success, (state, action): SupportedJurisdictionsState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchSupportedJurisdictions.failure, (state, action): SupportedJurisdictionsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default supportedJurisdictionReducer;
