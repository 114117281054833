import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/containers/workspace/shared/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';

interface Props {
  steps: Array<string>;
}

function ApprovedFinancialSettlementSummary(props: Props) {
  const { steps } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        approved
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant’s financials have now <b>been approved</b> and are now ready to be signed.
      </Typography>
      <Breadcrumbs //
        currentStep={2}
        steps={steps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

export default React.memo(ApprovedFinancialSettlementSummary);
