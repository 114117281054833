import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import TitlesContainer from 'src/containers/workspace/titles';
import { TitleRouteParams } from 'src/containers/workspace/titles/models';
import { useRouterParams } from 'src/hooks';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';

export default function WorkspaceTitlesPageContainer() {
  const { workspaceId, participantId } = useRouterParams<TitleRouteParams>();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  if (workspaceDetailState.error) {
    return <GeneralCrashedContent />;
  }

  return <TitlesContainer />;
}
