import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { BaseTransmissionApplicationModel } from '../../models';

export function yupApplicants<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  partyFormConfig: PartyFormConfig,
  disableMixedPartyTypes?: boolean,
  shouldValidatePartyData?: (party: PartyModel) => boolean
) {
  return resolveMemoizedReceivingTenancyValidation({
    partyFormConfig,
    isAddressRequired: false,
    forceDobVisibilityOnReceivingIndividual: false,
    shouldValidatePartyData
  }).testContextualRule({
    uniqueTestName: 'Mixed party type check',
    message: 'An individual and organisation applicants are present in one group; such combination is not supported for this document.',
    onlyIf: () => disableMixedPartyTypes === true,
    requirement: (parent: BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>, _: any) => {
      const { applicants, partyBook } = parent;
      const applicantParties = applicants.proprietorGroups
        .flatMap(pg => pg.parties.map(p => p.partyBookId))
        .filter(id => Boolean(id))
        .reduce<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBook']>(
          (acc: BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBook'], partyBookId: string) => {
            const exists = acc.find(p => p.id === partyBookId);
            if (!exists) {
              acc.push(partyBook.find(pb => pb.id === partyBookId)!);
            }

            return acc;
          },
          []
        );

      return applicantParties.every(p => p.partyType === PartyTypeEnum.Individual) || applicantParties.every(p => p.partyType === PartyTypeEnum.Organisation);
    }
  });
}
