import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import SectionTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { StateEnum } from '@sympli-mfe/enums-2-21/nsw';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG, PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { Mortgage2_21_3Model } from '../../models';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

function SectionMortgagees({ name, focusRef }: Props): JSX.Element {
  const {
    values: { mortgagors }
  } = useFormikContext<Mortgage2_21_3Model>();

  const partyBookIdsOfAllMortgagors = useMemo(
    () =>
      mortgagors.proprietorGroups
        .flatMap(x => x.parties)
        .map(mortgagor => mortgagor.partyBookId)
        .filter(Boolean) as string[], //
    [mortgagors]
  );
  return (
    <SectionTenancy //
      name={name}
      jurisdiction={StateEnum.NSW}
      componentLabels={NSW_MORTGAGE_TENANCY_COMPONENT_LABEL_CONFIG}
      // party setup
      partyFormConfig={PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
      disableRelinquishingAsReceiving
      relinquishingPartyIds={partyBookIdsOfAllMortgagors}
      // no address
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      focusRef={focusRef}
      disablePartyEditConfig={disablePartyFieldEditConfig(true)}
    />
  );
}

export default React.memo(SectionMortgagees);
