import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel, DashboardTypeEnum } from 'src/models';
import { colors } from 'src/theme';
import { MyTaskEnum, WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import {
  AggregatorComponentBaseProps,
  AggregatorNameEnum,
  AggregatorQuery,
  FinancialDashboardAggregatorApiRequest,
  FinancialDashboardAggregatorApiResponse,
  TodayCountModel
} from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): FinancialDashboardAggregatorApiRequest => {
  const { from, to } = getDateRange(DateRangeTypeEnum.Today);

  const settlementPeriod: DashboardDateRangeModel = {
    start: from,
    end: to
  };

  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.todaySettlements,
      workspaceStatus:
        assignmentType === AssignmentTypeEnum.ReAssigned
          ? [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors, WorkspaceStatusEnum.Ready]
          : [
              //
              WorkspaceStatusEnum.InPreparation,
              WorkspaceStatusEnum.InPreparationWithErrors,
              WorkspaceStatusEnum.Ready,
              WorkspaceStatusEnum.Settling,
              WorkspaceStatusEnum.SettlingWithErrors
            ],
      settlementPeriod: settlementPeriod
    },
    {
      name: AggregatorNameEnum.todayNotReady,
      workspaceStatus: [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors],
      settlementPeriod: settlementPeriod
    },
    {
      name: AggregatorNameEnum.readyToSign,
      workspaceStatus: [
        //
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors
      ],
      myTasks: [
        //
        MyTaskEnum.SignDocuments,
        MyTaskEnum.SignFinancials
      ]
    },
    {
      name: AggregatorNameEnum.todaySettled,
      workspaceStatus: [WorkspaceStatusEnum.Settled],
      settlementPeriod: settlementPeriod
    },
    {
      name: AggregatorNameEnum.todayRebooked,
      workspaceStatus: [WorkspaceStatusEnum.InPreparationWithErrors],
      settlementPeriod: settlementPeriod,
      withProposals: false
    }
  ];

  const payload: FinancialDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

const useStyles = makeStyles(
  () => ({
    cardToday: {
      color: colors.SUNNY_DAY_DARK
    },
    cardTodayTitle: {
      color: colors.NEUTRAL_1000
    },
    cardStatusToday: {
      color: colors.SUNNY_DAY
    },
    todayCardRootStyle: {
      padding: '16px 8px'
    }
  }),
  {
    name: 'today'
  }
);

function TodayCard(props: AggregatorComponentBaseProps) {
  const classes = useStyles();

  const defaultContent = React.useMemo<TodayCountModel>(() => ({ notReady: 0, readyToSign: 0, settlements: 0, settled: 0, rebooked: 0 }), []);
  const contentResolver = React.useCallback(
    (response: FinancialDashboardAggregatorApiResponse): TodayCountModel => ({
      settlements: response.find(d => d.name === AggregatorNameEnum.todaySettlements)?.count ?? 0,
      notReady: response.find(d => d.name === AggregatorNameEnum.todayNotReady)?.count ?? 0,
      readyToSign: response.find(d => d.name === AggregatorNameEnum.readyToSign)?.count ?? 0,
      settled: response.find(d => d.name === AggregatorNameEnum.todaySettled)?.count ?? 0,
      rebooked: response.find(d => d.name === AggregatorNameEnum.todayRebooked)?.count ?? 0
    }),
    []
  );
  return (
    <AggregatorCardWrapper<TodayCountModel> //
      payloadResolver={payloadResolver}
      defaultContent={defaultContent}
      contentResolver={contentResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <>
          <AggregatorCard
            title="Today"
            cardType={DashboardTypeEnum.Financial}
            isLoading={isLoading}
            onActionClick={onActionClick}
            isFetchingGridData={isFetchingGridData}
            items={[
              {
                id: AggregatorNameEnum.todaySettlements,
                content: content.settlements,
                status: 'Settlements',
                actionText: 'Show me',
                displaySeparator: true,
                extraInfo: {
                  settledNumber: content.settled,
                  rebookNumber: content.rebooked
                }
              },
              {
                id: AggregatorNameEnum.todayNotReady,
                content: content.notReady,
                status: 'Not Ready',
                actionText: 'Show me'
              }
            ]}
            titleStyle={classes.cardTodayTitle}
            contentStyle={classes.cardToday}
            statusStyle={classes.cardStatusToday}
            actionStyle={classes.cardToday}
            rootStyle={classes.todayCardRootStyle}
          />

          <AggregatorCard
            isFetchingGridData={isFetchingGridData}
            title=""
            cardType={DashboardTypeEnum.Financial}
            isLoading={isLoading}
            onActionClick={onActionClick}
            items={[
              {
                id: AggregatorNameEnum.readyToSign,
                content: content.readyToSign,
                status: 'Ready to Sign',
                actionText: 'Show me'
              }
            ]}
            titleStyle={classes.cardToday}
            contentStyle={classes.cardToday}
            statusStyle={classes.cardStatusToday}
            actionStyle={classes.cardToday}
          />
        </>
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(TodayCard);
