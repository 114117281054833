import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.GREEK_WATERS_DARK,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8
    }
  });

export type ClassKeys = typeof styles;

export default styles;
