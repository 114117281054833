import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import { actionFetchSettlementDetails } from 'src/containers/workspace/financial/settlement-date/actions';
import { useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import SettlementDateContainer, { SettlementDateRouteParams } from 'src/containers/workspace/financial/settlement-date/SettlementDateContainer';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';

function WorkspaceSettlementDatePageContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = useRouterParams<SettlementDateRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const settlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);

  useEffect(() => {
    dispatch(actionFetchSettlementDetails.request({ workspaceId, participantId }));
  }, [dispatch, workspaceId, participantId]);

  if (workspaceBasicInfoState.error || workspaceDetailState.error || settlementDateDetailsState.error) {
    return <GeneralCrashedContent />;
  }

  return <SettlementDateContainer />;
}

export default React.memo(WorkspaceSettlementDatePageContainer);
