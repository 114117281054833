import { resolveGenericTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import {
  VISIBILITY_CHECK_CLAIM_CATEGORY,
  VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID,
  VISIBILITY_CHECK_CLAIM_PARTIES,
  VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER,
  VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID,
  VISIBILITY_CHECK_QUALIFICATION
} from './checks';
import { CaveatModel_2_21_2 } from './models';
import { VISIBILITY_CHECK_CLAIM_DATE } from './sections/claim-details/checks';

export const caveatFallbackMap: FallbackMap<CaveatModel_2_21_2> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  extentOfProhibitions: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        actionProhibitedOption: [null],
        qualification: [VISIBILITY_CHECK_QUALIFICATION, ''],
        lrDocumentId: [VISIBILITY_CHECK_EXTENT_OF_PROHIBITIONS_LR_DOCUMENT_ID, '']
      }
    }
  },
  claimDetails: {
    $children: {
      estateOrInterestClaimed: [null],
      claimCategory: [VISIBILITY_CHECK_CLAIM_CATEGORY, null],
      detailsSupportingTheClaim: [''],
      claimDate: [VISIBILITY_CHECK_CLAIM_DATE, null],
      lrDocumentID: [VISIBILITY_CHECK_CLAIM_DETAILS_LR_DOCUMENT_ID, ''],
      claimParties: {
        $arrayVisibility: [VISIBILITY_CHECK_CLAIM_PARTIES, []],
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            partyBookId: [''],
            claimPartyCapacity: [null]
          }
        }
      }
    }
  },
  attachments: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: NO_FALLBACK
  },
  dutyAssessment: {
    $children: {
      dutyAssessmentNumber: [VISIBILITY_CHECK_DUTY_ASSESSMENT_NUMBER, '' as any /* TODO fix this any in fallback util functionality */, '']
    }
  },
  caveators: resolveGenericTenancyDetailFallback({ shouldAddDefaultItemToEmptyArray: true }),
  contactDetails: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [true],
        partyBookId: [''],
        addressBookId: [''],
        partyCapacity: NO_FALLBACK
      }
    }
  },
  proprietors: resolveGenericTenancyDetailFallback(),
  caveatStatementsAgreed: [false],
  workspaceTitleReferences: NO_FALLBACK,
  subscriberParties: NO_FALLBACK,
  precedingData: NO_FALLBACK,
  // custom properties
  proprietorsOnTitle: NO_FALLBACK,
  invalidParties: NO_FALLBACK,
  partyBookApi: NO_FALLBACK
};
