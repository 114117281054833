import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchGroupDetails, actionFetchSubscriberTrustAccounts, actionResetGroupDetails } from '../actions/groups';

export interface SubscriberTrustAccountsState {
  trustAccounts?: HttpTypes.TrustAccountDetails[];
  status: ApiStatus;
  error?: string;
}
export interface GroupDetailsState {
  detail?: HttpTypes.SubscriberGroupApiResponse;
  status: ApiStatus;
  error?: string;
  subscriberTrustAccounts: SubscriberTrustAccountsState;
}

const subscriberTrustAccountsInitialState: SubscriberTrustAccountsState = {
  trustAccounts: undefined,
  status: 'idle',
  error: undefined
};

const initialState: GroupDetailsState = {
  detail: undefined,
  status: 'idle',
  error: undefined,
  subscriberTrustAccounts: subscriberTrustAccountsInitialState
};

const reducer = createReducer<
  //
  GroupDetailsState,
  Action
>(initialState)
  .handleAction(actionFetchGroupDetails.request, (): GroupDetailsState => {
    return {
      ...initialState,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchGroupDetails.success, (state, action): GroupDetailsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchGroupDetails.failure, (state, action): GroupDetailsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionResetGroupDetails, (): GroupDetailsState => {
    return {
      ...initialState
    };
  })
  .handleAction(actionFetchSubscriberTrustAccounts.request, (state): GroupDetailsState => {
    return {
      ...state,
      subscriberTrustAccounts: {
        ...subscriberTrustAccountsInitialState,
        status: 'pending'
      }
    };
  })
  .handleAction(actionFetchSubscriberTrustAccounts.success, (state, action): GroupDetailsState => {
    return {
      ...state,
      subscriberTrustAccounts: {
        trustAccounts: action.payload.data,
        status: 'resolved',
        error: undefined
      }
    };
  })
  .handleAction(actionFetchSubscriberTrustAccounts.failure, (state, action): GroupDetailsState => {
    return {
      ...state,
      subscriberTrustAccounts: {
        trustAccounts: undefined,
        status: 'rejected',
        error: action.payload.error.message
      }
    };
  });

export default reducer;
