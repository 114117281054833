import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { INITIAL_FINAL_TENANCY_HOLDINGS_MODEL } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings/models';
import { convertReceivingTenancyDetailFromApiToForm } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { NSW_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import {
  calculateTransferorsOnTitle,
  convertNewTransfereesFromFromToOldApiModel,
  convertNewTransferorsFromFormToOldApiModel,
  convertOldTransfereesToNewTransferees,
  convertOldTransferorsFromApiToFormModel,
  convertOldTransferorsToNewTransferors,
  convertPartiesToFormModel_2_21_1,
  recalculateFormValues,
  resolveTransferors
} from './helpers';
import { ApiTransfer2_21_1Model, Transfer2_21_1Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification, NswNameChange>({
  partyFormConfig: NSW_TRANSFER_PARTY_FORM_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

class Converter implements IConverter<Transfer2_21_1Model, ApiTransfer2_21_1Model> {
  fromApiToFormModel(apiModel: ApiTransfer2_21_1Model, _context: IConverterContext): Transfer2_21_1Model {
    const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
    const transferorsOld: Transfer2_21_1Model['transferors'] = convertOldTransferorsFromApiToFormModel({
      //
      transferors: apiModel.transferors,
      isGroupStructureMeaningful: true
    });

    const transferorsNew: Transfer2_21_1Model['transferorsNew'] = convertOldTransferorsToNewTransferors({
      transferorsOld
    });

    const transfereesOld: Transfer2_21_1Model['transferees'] = convertReceivingTenancyDetailFromApiToForm(apiModel.transferees);
    const transfereesNew: Transfer2_21_1Model['transfereesNew'] = convertOldTransfereesToNewTransferees({
      transfereesOld,
      transfereesApiOld: apiModel.transferees
    });

    let formModel: Omit<Transfer2_21_1Model, 'transferorsOnTitle'> & {
      transferorsOnTitle?: Transfer2_21_1Model['transferorsOnTitle'];
    } = {
      ...apiModel,
      partyBookApi: apiModel.partyBook,
      partyBook: convertPartiesToFormModel_2_21_1(apiModel.partyBook, risInvolvedPartyIds),
      finalTenancyHoldings: INITIAL_FINAL_TENANCY_HOLDINGS_MODEL,
      transferees: transfereesOld,
      transferors: transferorsOld,
      transfereesNew,
      transferorsNew
    };

    if (apiModel.precedingData.dataChanged ?? true) {
      formModel = {
        ...formModel,
        ...recalculateFormValues({
          titleReferences: formModel.titleReferences,
          partyBook: formModel.partyBook,
          transfereesNew: formModel.transfereesNew,
          precedingData: formModel.precedingData
        })
      };
    } else {
      // we need to recalculate transferorsOnTitle when user edits existing transfer document
      const { transferorsNew, partyBookApi } = resolveTransferors({
        titleReferences: apiModel.titleReferences,
        precedingData: formModel.precedingData,
        partyBook: formModel.partyBook
      });

      transferorsNew.proprietorGroups = transferorsNew.proprietorGroups.map(pg => {
        return {
          ...pg,
          parties: pg.parties.map(p => {
            // we need to remap the partyBookId that was created by merger by what the backend reassigned them to
            const pmParty = partyBookApi.find(pba => pba.id === p.partyBookId);
            const partyWithReassignedId = formModel.partyBook.find(p => p.mergeMetadata?.matchingItemHash === pmParty?.mergeMetadata?.matchingItemHash);
            return {
              ...p,
              partyBookId: partyWithReassignedId?.id ?? p.partyBookId
            };
          })
        };
      });
      const transferorsOnTitle = calculateTransferorsOnTitle(
        // pass partybook with backend regenerated ids
        apiModel.partyBook.filter(p => p.metadata?.source === DataSource.Title || p.metadata?.source === DataSource.PrecedingDocument),
        transferorsNew // this now contains transferors with reassigned partyBookIds so it can be used to compare transferors structure
      );

      formModel = {
        ...formModel,
        transferorsOnTitle
      };
    }

    const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
    return formModelWithDefaultMapApplied;
  }

  fromFormToApiModel(formValues: Transfer2_21_1Model, _originalApiModel: ApiTransfer2_21_1Model): ApiTransfer2_21_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      partyBookApi,
      // transferors,
      // transferees,
      transferorsNew,
      transfereesNew,
      finalTenancyHoldings,
      mergeFailedReason: mergeFailed,
      // custom UI only props
      // transferorsTenancyType,
      transferorsOnTitle,
      precedingData,
      ...rest
    } = formModel;

    PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);
    const transferorsOld: ApiTransfer2_21_1Model['transferors'] = convertNewTransferorsFromFormToOldApiModel(transferorsNew, precedingData.manualOverride);
    const transfereesOld: ApiTransfer2_21_1Model['transferees'] = convertNewTransfereesFromFromToOldApiModel(transfereesNew);
    const result: ApiTransfer2_21_1Model = {
      ...rest,
      attachments: formModel.documentAttachments,
      needToGeneratePartyBook: false,
      transferors: transferorsOld,
      transferees: transfereesOld,
      partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
      precedingData: {
        ...precedingData,
        dataChanged: false
      }
    };

    return result;
  }
}

export default new Converter();
