import * as React from 'react';

import { useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ProfileContextProvider from 'src/@core/auth/profile-security/Profile.context.Provider';
import ErrorBoundary from 'src/@core/components/error-boundary';
import GlobalNotificationContainer from 'src/@core/components/global-notification';
import GlobalSimpleNotificationContainer from 'src/@core/components/global-simple-notification';
import LoadingProfile from 'src/@core/components/profile-loader';
import AppCrashedPage from 'src/@core/pages/app-crashed-page';
import { actionFetchProfile } from 'src/@core/store/actions/profile';
import { useProfile } from 'src/@core/store/reducers/profile';
import { RealtimeConnectionProvider } from 'src/socket-2.0';
import TopPanelContainer from './components/layout/v2/TopPanelContainer';
import Router from './router';

function AppContent({ performProfileFetch }: { performProfileFetch?: boolean }) {
  const dispatch = useDispatch();
  const { status: userProfileStatus } = useProfile();
  // this logic is here purely for smoke test
  // in normal scenario, the profile fetching is done in AuthContainer
  React.useEffect(() => {
    if (performProfileFetch) {
      dispatch(actionFetchProfile.request());
    }
  }, [dispatch, performProfileFetch]);

  const topPanel = React.useMemo(() => <TopPanelContainer />, []);

  if (performProfileFetch && (userProfileStatus === 'idle' || userProfileStatus === 'pending')) {
    return <LoadingProfile />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ErrorBoundary component={AppCrashedPage} topPanel={topPanel}>
        <ProfileContextProvider>
          <RealtimeConnectionProvider>
            <GlobalNotificationContainer />
            <GlobalSimpleNotificationContainer />
            <Router />
          </RealtimeConnectionProvider>
        </ProfileContextProvider>
      </ErrorBoundary>
    </LocalizationProvider>
  );
}

export default React.memo(AppContent);
