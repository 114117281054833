import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272 } from 'src/@core/components/layout/constants';

const styles = (theme: Theme) =>
  createStyles({
    inputContainer: {
      width: WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272,
      padding: '31px 40px 0px 40px',
      backgroundColor: theme.palette.grey[50],

      '@media (min-width:1536px) and (max-width:1920px)': {
        padding: '31px 270px 0px 40px'
      },
      '@media (min-width:1920px)': {
        padding: '31px 120px 0px 40px'
      }
    },
    dateTime: {
      fontWeight: 'bold',
      fontSize: '18px'
    },
    proposedDateTime: {
      color: '#F58923',
      fontWeight: 'bold'
    },
    proposalDetailLabel: {
      fontWeight: 'bold',
      fontSize: 14,
      marginBottom: 2,
      marginTop: 20
    },
    avatar: {
      marginRight: 5
    }
  });

export type ClassKeys = typeof styles;

export default styles;
