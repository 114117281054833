import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { Transfer2_24_1Model } from '../../models';

interface TitleReferencesContext {
  mergeFailedReason?: MergeFailedReasonsEnum;
}

const yupTitleReferences = resolveTitleReferencesValidationSchema<Transfer2_24_1Model['titleReferences'][number], TitleReferencesContext>().test(
  //
  'Multiple title validation',
  'Party merge failed.',
  function test(this: yup.TestContext<TitleReferencesContext>) {
    const { mergeFailedReason } = this.options.context!;
    if (!mergeFailedReason) return true;

    if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame)
      return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
      return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.PartyCapacityMismatch)
      return this.createError({ message: 'The party capacity of the proprietors do not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
      return this.createError({
        message: 'The share fraction of the proprietors does not match; please remove the mismatched titles. To transact on mismatched titles, please create a new workspace.'
      });

    return false;
  }
);

const memoizedYupTitleReferences = memoizeSchemaWithContext<Transfer2_24_1Model['titleReferences'], Transfer2_24_1Model, TitleReferencesContext>(
  yupTitleReferences,
  function contextResolver({ mergeFailedReason }: Transfer2_24_1Model): TitleReferencesContext {
    return { mergeFailedReason };
  }
);

export default memoizedYupTitleReferences;
