import { Action, createReducer } from 'typesafe-actions';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchInvitationsSubscribers } from '../actions';

export interface InvitationSubscriberSearchState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: LookupItemModel<string>[];
  error?: string;
}

const initialState: InvitationSubscriberSearchState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined
};

export function useInvitationSubscribersSearchState(): InvitationSubscriberSearchState {
  const state = useStoreSelector(store => store.invitationsSubscribersSearch);
  return state;
}

const invitationSubscribersReducer = createReducer<
  //
  InvitationSubscriberSearchState,
  Action
>(initialState)
  .handleAction(actionFetchInvitationsSubscribers.request, (state): InvitationSubscriberSearchState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined
    };
  })
  .handleAction(actionFetchInvitationsSubscribers.success, (state, action): InvitationSubscriberSearchState => {
    const items = action.payload.data.map(x => ({ id: x.id, name: x.displayName }));

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: items
    };
  })
  .handleAction(actionFetchInvitationsSubscribers.failure, (state, action): InvitationSubscriberSearchState => {
    return {
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    };
  });

export default invitationSubscribersReducer;
