import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import environments from 'src/@core/environments';
import { Card } from 'src/containers/dashboard/components/card';
import { AllDirectionsState } from 'src/containers/workspace/financial/all-directions/reducer';
import { WorkspaceBasicInfoState } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { WorkspaceDetailState } from 'src/store/reducers/workspace/workspaceDetail';
import { WorkspaceDocumentsState } from 'src/store/reducers/workspace/workspaceDocuments';
import { WorkspaceParticipantsState } from 'src/store/reducers/workspace/workspaceParticipants';
import { WorkspaceTasksState } from 'src/store/reducers/workspace/workspaceTasks';
import { ApiStatus } from 'src/utils/http';
import { LastSystemActivityModel } from './models';
import DocumentsCardDetail from './row-detail/DocumentsCardDetail';
import FinancialsCardDetail from './row-detail/FinancialsCardDetail';
import FundsCardDetail from './row-detail/FundsCardDetail';
import InvitationsCardDetailContainer from './row-detail/invitations-card-detail';
import LinkedWorkspaceCardDetailContainer from './row-detail/LinkedWorkspaceCardDetailContainer';
import MessagesCardDetailContainer from './row-detail/MessagesCardDetailContainer';
import MyTasksCardDetail from './row-detail/MyTasksCardDetail';
import SystemActivityCardDetail from './row-detail/SystemActivityCardDetail';
import WorkspaceCardDetail from './row-detail/WorkspaceCardDetail';

// TODO share this with other dashboards
function TableRowDetailWrapper({
  //
  children,
  status,
  error,
  linkTo
}: React.PropsWithChildren<{
  //
  status?: ApiStatus;
  error?: string;
  linkTo?: string;
}>) {
  const { BASENAME } = environments;

  const handleOnClick = React.useCallback(() => {
    // open a new tab and set the new opener to null to avoid injection
    const newWindow = window.open(`${BASENAME}${linkTo}`);
    if (newWindow) {
      newWindow.opener = null;
    }
  }, [linkTo, BASENAME]);

  if (status === 'rejected') {
    return <div className="p-[20px]">{error}</div>;
  }

  if (status === 'idle' || status === 'pending') {
    return (
      <div className="p-[20px]">
        <InlineLoader />
      </div>
    );
  }

  return (
    <div className="p-[20px]">
      <div className="grid grid-cols-[repeat(2,540px)] justify-between gap-[20px] medium-screen:grid-cols-[repeat(4,320px)] large-screen:grid-cols-[repeat(4,380px)]">
        {children}
      </div>
      <div className="flex justify-center pb-[28px] pt-[38px]">
        <SympliButton onClick={handleOnClick} variant="contained" color="primary" className="rounded-[34px] px-[45px] py-[8px]">
          View Workspace
        </SympliButton>
      </div>
    </div>
  );
}

interface FinancialTableRowDetailProps {
  linkTo?: string;
  matterReference?: string;
  workspaceBasicInfoState: WorkspaceBasicInfoState;
  workspaceDetailState: WorkspaceDetailState;
  workspaceParticipantsState: WorkspaceParticipantsState;
  workspaceDocumentsState: WorkspaceDocumentsState;
  workspaceTasksState: WorkspaceTasksState;
  allDirectionsState: AllDirectionsState;
  isFinancialBalanced?: boolean;
  lastSystemActivity?: LastSystemActivityModel;
  participantId: string;
  workspaceId: string;
  userId: string;
  isCriticalRolesEnabled: boolean;
  linkedWorkspaceClusterId?: string;
  linkedWorkspaceType?: HttpTypes.LinkedWorkspaceTypeEnum;
}

function FinancialTableRowDetail({
  //
  linkTo,
  matterReference,
  workspaceBasicInfoState,
  workspaceDetailState,
  workspaceDocumentsState,
  workspaceParticipantsState,
  workspaceTasksState,
  allDirectionsState,
  workspaceId,
  isFinancialBalanced,
  lastSystemActivity,
  participantId,
  userId,
  isCriticalRolesEnabled,
  linkedWorkspaceClusterId,
  linkedWorkspaceType
}: FinancialTableRowDetailProps) {
  // const screenSizeVariant: ScreenSizeVariant = useScreenSize();
  // const classes = useFinancialTableRowDetailStyles();

  // TODO reimplement this based on the story requirements
  return (
    <TableRowDetailWrapper
      //
      // status={status}
      // error={error}
      linkTo={linkTo}
    >
      {/* {status !== 'idle' ? (
        <> */}
      <Card>
        <WorkspaceCardDetail
          //
          matterReference={matterReference}
          workspaceBasicInfoState={workspaceBasicInfoState}
          workspaceDetailState={workspaceDetailState}
          workspaceParticipantsState={workspaceParticipantsState}
        />
        <InvitationsCardDetailContainer participantId={participantId} workspaceId={workspaceId} showWorkspaceRoleColumn />
        {linkedWorkspaceClusterId && linkedWorkspaceType !== undefined && (
          <LinkedWorkspaceCardDetailContainer //
            workspaceId={workspaceId}
            linkedWorkspaceClusterId={linkedWorkspaceClusterId}
            linkedWorkspaceType={linkedWorkspaceType}
            workspaceStatus={workspaceDetailState.detail?.workspaceStatus.id}
          />
        )}
      </Card>
      <Card>
        <DocumentsCardDetail //
          workspaceDocumentsState={workspaceDocumentsState}
          participantId={participantId}
          workspaceDetailState={workspaceDetailState}
          userId={userId}
          isCriticalRolesEnabled={isCriticalRolesEnabled}
        />
      </Card>
      <Card>
        <FinancialsCardDetail //
          allDirectionsState={allDirectionsState}
        />
        <FundsCardDetail //
          isFinancialBalanced={isFinancialBalanced}
        />
      </Card>
      <Card>
        <SystemActivityCardDetail //
          lastSystemActivity={lastSystemActivity}
        />
        <MessagesCardDetailContainer //
          workspaceId={workspaceId}
          participantId={participantId}
        />
        <MyTasksCardDetail //
          workspaceBasicInfoState={workspaceBasicInfoState}
          workspaceTasksState={workspaceTasksState}
        />
      </Card>

      {/* </>
      ) : null} */}
    </TableRowDetailWrapper>
  );
}

export default FinancialTableRowDetail;
