import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';

const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig(true).disabled(party);

// path: mortgageeDetails
const yupMortgagee = resolveMemoizedTenancyValidation({
  isAddressRequired: false,
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  shouldValidatePartyData
});

export default yupMortgagee;
