import React, { useCallback } from 'react';

import { useField, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import PartyForm, { formatPartyName, formatPartyType } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { HttpTypes } from '@sympli/api-gateway/types';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { createModelKeyAppender } from 'src/utils/formUtils';
import { NSW_NOD_PARTY_FORM_CONFIG, NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE } from '../../config';
import { NoticeOfDeath2_21_1Model, SurvivingJointTenantModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

export const SURVIVING_TENANT_MAKING_APPLICATION_LABEL: string = 'Surviving tenant making application';

function SectionSurvivingJointTenants({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const { values } = formikProps;
  const [, { value: items }] = useField<SurvivingJointTenantModel[]>(name);

  const renderItem = useCallback(
    //
    ({ item, itemBinding }: DocumentArrayItemRenderProps<SurvivingJointTenantModel>) => {
      const fieldName = createModelKeyAppender<SurvivingJointTenantModel>(itemBinding);
      const party = values.partyBook.find(party => party.id === item.partyBookId)!;
      const isOrg = party.partyType === HttpTypes.PartyTypeEnum.Organisation;
      const bookIndexOfPartyId = values.partyBook.findIndex(party => party.id === item.partyBookId);
      const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

      return (
        <>
          {isOrg && (
            <Subform
              subformBindingPath={partyBindingPath}
              component={PartyForm}
              disabled={disabled}
              config={NSW_NOD_PARTY_FORM_CONFIG_WITHOUT_NAME_CHANGE}
              showFormGroups={false}
            />
          )}
          {!isOrg && (
            <>
              <Typography className={classes.partyTitle}>{formatPartyType(party.partyType)}</Typography>
              <Typography className={classes.partyDisplay}>{formatPartyName(party, NSW_NOD_PARTY_FORM_CONFIG)}</Typography>
            </>
          )}
          {items.length > 1 && (
            <Field //
              name={fieldName('isMakingApplication')}
              label={SURVIVING_TENANT_MAKING_APPLICATION_LABEL}
              component={CheckboxField}
              disabled={disabled}
              format="boolean"
            />
          )}
        </>
      );
    },
    [classes.partyDisplay, classes.partyTitle, disabled, items.length, values.partyBook]
  );

  return (
    <div data-section={name} className={className} data-testid={'survivingJointTenants'}>
      {!items.length ? (
        <Typography className={classes.noSurvivingsMessage}>Please select a deceased joint tenant</Typography>
      ) : (
        <DocumentFieldArray //
          arrayBinding={name}
          itemTitle={({ itemIndex }) => (
            <Typography>{formikProps.values.survivingJointTenants!.length >= 1 ? `Surviving joint tenant ${itemIndex + 1}` : 'Surviving joint tenant'}</Typography>
          )}
          renderItem={renderItem}
          disabled={disabled}
          itemStyle="formGroup"
          mode="fixed"
        />
      )}
    </div>
  );
}

export default React.memo(SectionSurvivingJointTenants);
