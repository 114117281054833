import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { VerifyTitleFormModel } from 'src/containers/shared/verify-property-section/models';
import { AllowedAdditionalParticipantRole, TitleReferenceErrorItem, WorkspaceCreationErrorsMap } from '../../models';
import { CreateFinancialWorkspaceInvitationsStepResponse, InvitationItemModel } from './steps/invite-participants/models';

export enum CreateFinancialWorkspaceStepEnum {
  AddMatterDetails = 0,
  InviteMembers = 1,
  JoinActiveWorkspace = 2
}

export interface CreateFinancialWorkspaceSharedModel {
  matterDetailsStepData: CreateFinancialWorkspaceMatterDetailsStepFormModel;
  matterDetailsStepApiResponse?: CreateFinancialWorkspaceMatterDetailsStepResponse; // response from the server
  inviteParticipantsStepData: CreateFinancialWorkspaceInviteParticipantsStepFormModel;
  inviteParticipantsStepApiResponse?: CreateFinancialWorkspaceInvitationsStepResponse; // response from server
}

export interface CreateFinancialWorkspaceMatterDetailsStepFormModel {
  createdByReference: string;
  jurisdictionId: HttpTypes.JurisdictionsEnum | null;

  instructingOrganisationSettlementAgentId: string;
  groupId: string;
  createdByRoleId: HttpTypes.WorkspaceRoleEnum | null;

  // * material-ui-pickers can NOT deal date with empty string, use null
  settleDate?: string | Date | null;
  // * settleTime is in jurisdictionId's timezone, 06/03/2019
  settleTime?: string;

  isNoSettlementDateTime?: boolean; // flag used in UI for a checkbox field

  isControllingParty: boolean;
  titleInformation: VerifyTitleFormModel;
  // here we store errors that came after attempt to create workspace
  workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap;
  isInteroperable?: boolean;
  transactionTypeId: HttpTypes.WorkspaceTransactionTypeEnum | null;
  transactionTypeOtherDescription?: string;
}

export interface FinancialWorkspaceTitleCreationDetailsStepRequest {
  jurisdiction: HttpTypes.JurisdictionsEnum;
  workspaceRole: HttpTypes.WorkspaceRoleEnum;
  titleReferences: string[];
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  isInteroperable?: boolean;
}

export interface CreateFinancialWorkspaceMatterDetailsStepResponse {
  canCreateWorkspace: boolean;
  titleReferenceErrors: TitleReferenceErrorItem[];
  invitableRoles: HttpTypes.WorkspaceRoleEnum[];
  vendors: LookupItemModel[];
  mortgages: Mortgage[];
  invitationDescription?: string;
  allowedAdditionalParticipantRoles: AllowedAdditionalParticipantRole[];
}

interface Mortgage {
  dealingNumber: string;
  parties: LookupItemModel[];
}

export interface InvitationModel {
  id: number;
  role?: HttpTypes.WorkspaceRoleEnum;
  invitation: InvitationItemModel;
}

export interface WorkspaceInviteParticipantsFormModel {
  invitedRoles: Partial<Record<HttpTypes.WorkspaceRoleEnum, boolean>>;
  invitations: InvitationModel[];
  additionalInvitations: InvitationModel[];
}

export interface CreateFinancialWorkspaceInviteParticipantsStepFormModel extends WorkspaceInviteParticipantsFormModel {
  vendors: string[];
}

export interface AdditionalParticipantRoleConfig {
  compatibleRoles: HttpTypes.WorkspaceRoleEnum[];
  numberOfParticipantsAllowed: number;
}
