import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { DirectionsApiRequest } from '../directions/actions';
import { actionFetchAllDirections } from './actions';

const fetchAllDirections = (args: DirectionsApiRequest) => {
  return endpoints.getDirectionsOverview(args);
  // const { workspaceId, participantId } = payload;
  // return http.get<AllDirectionsApiResponse>(`/workspaces/${workspaceId}/participants/${participantId}/directions/overview`);
};

function* sagaFetchAllDirections(action: ReturnType<typeof actionFetchAllDirections.request>) {
  try {
    const allDirectionsDetail: HttpTypes.WorkspaceDirectionsOverviewApiResponse = yield* call(fetchAllDirections, action.payload);
    yield put(actionFetchAllDirections.success({ data: allDirectionsDetail }));
  } catch (error) {
    yield put(actionFetchAllDirections.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchAllDirections.request, sagaFetchAllDirections)
];
