import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import GenericLayoutWrapper from 'src/@core/components/layout/GenericLayoutWrapper';
import NotFoundAnimation from 'src/@core/components/layout/not-found-animation';
import { useStyles } from './styles';

interface Props {
  topPanel: React.ReactNode;
}

function NotFoundPage(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <GenericLayoutWrapper topPanel={props.topPanel}>
      <div className="p-[32px]">
        <FlexLayout fullWidth fullHeight alignItems="center" flexDirection="column">
          <Typography variant="h1">We can't seem to find the page you're looking for. Please check your URL.</Typography>
          <NotFoundAnimation />
          <SympliButton variant="contained" color="primary" className={classes.backButton} href="/">
            Go to dashboard
          </SympliButton>
        </FlexLayout>
      </div>
    </GenericLayoutWrapper>
  );
}

export default NotFoundPage;
