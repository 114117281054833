import * as React from 'react';

import { FormikProps, useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconExclamationCircle } from '@sympli/ui-framework/icons';

import { IS_PLAN_DOCUMENT_REQUIRED } from '../../../isRequiredChecks';
import { FormDatePeriod as DatePeriodModel, Lease2_21_2Model } from '../../../models';
import { isAfterOrEqual, isBefore } from '../../lease-details/helpers';
import { getDateAfter, getDateBefore } from '../helpers';
import { formatDateLength, getLeasePeriod } from './helpers';
import { useStyles } from './styles';

interface Props {
  name: string;
  minStartDate?: Date;
  displayLeaseLengthWarning?: boolean;
}

function DatePeriod({ name, minStartDate, displayLeaseLengthWarning = false }: Props): JSX.Element {
  const fieldName = createModelKeyAppender<DatePeriodModel>(name);
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const { values } = useFormikContext<Lease2_21_2Model>();
  const { titleReferences } = values;
  const item: DatePeriodModel = _get(values, name);
  const leasePeriod = formatDateLength(item.dateLength);
  const leasePeriodMessage = leasePeriod ?? 'Please enter a start and expiry date';
  const minEndDate = item.startDate ? getDateAfter(item.startDate) : undefined;
  const maxStartDate = item.endDate ? getDateBefore(item.endDate) : undefined;
  const selectedTitles = titleReferences.filter(tr => tr.isSelected);
  const displayPlanDocumentWarning = displayLeaseLengthWarning && IS_PLAN_DOCUMENT_REQUIRED(selectedTitles, item.dateLength);
  const handleStartDateOnChange = React.useCallback(
    (dateValue: Date, { setFieldValue }: FormikProps<Lease2_21_2Model>) => {
      if (!item.endDate || !dateValue) {
        return;
      }
      if (isAfterOrEqual(dateValue, item.endDate)) {
        setFieldValue(fieldName('endDate'), null);
        setFieldValue(fieldName('dateLength'), null);
        return;
      }
      const leasePeriod = getLeasePeriod(dateValue, item.endDate);
      if (leasePeriod) {
        setFieldValue(fieldName('dateLength'), leasePeriod);
      }
    },
    [fieldName, item.endDate]
  );

  const handleEndDateOnChange = React.useCallback(
    (dateValue: Date, { setFieldValue }: FormikProps<Lease2_21_2Model>) => {
      if (!item.startDate || !dateValue) {
        return;
      }

      if (isBefore(dateValue, item.startDate)) {
        setFieldValue(fieldName('dateLength'), null);
        return;
      }
      const leasePeriod = getLeasePeriod(item.startDate, dateValue);
      if (leasePeriod) {
        setFieldValue(fieldName('dateLength'), leasePeriod);
      }
    },
    [fieldName, item.startDate]
  );

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout flexDirection="row" className={classes.datePeriodDates}>
        <Field //
          label="Start date"
          component={DatePickerField}
          name={fieldName('startDate')}
          className={classes.date}
          onChange={handleStartDateOnChange}
          minDate={minStartDate}
          maxDate={maxStartDate}
          disabled={disabled}
        />
        <Field //
          label="Expiry date"
          component={DatePickerField}
          name={fieldName('endDate')}
          className={classes.date}
          onChange={handleEndDateOnChange}
          minDate={minEndDate}
          disabled={disabled}
        />
      </FlexLayout>
      <TextOnly //
        label="Lease length"
        name={fieldName('dateLength')}
        value={leasePeriodMessage}
        classes={{ text: !leasePeriod ? classes.placeholder : undefined }}
      />
      {displayPlanDocumentWarning && (
        <FlexLayout flexDirection="row" className={classes.planDocumentWarning}>
          <IconExclamationCircle className={classes.iconWarning} />
          <TextOnly //
            value="For part-land leases over 25 years in length please upload a Plan Document"
            fullWidth
            className={classes.planDocumentWarningText}
          />
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

export default React.memo(DatePeriod);
