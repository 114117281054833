import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { SharedInitialValues } from '../models';
import FinancialForm from './financial-form';
import RegOnlyForm from './reg-only-form';

interface Props {
  workspaceTypeOptions: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[];
  onClose(link?: string): void;
}

interface State {
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  sharedInitialValues: Partial<SharedInitialValues>;
}

function CreationFormView({ onClose, workspaceTypeOptions }: Props) {
  const [state, setState] = React.useState<State>({
    // preselect workspace type if user is not allowed to choose one
    workspaceTypeId: workspaceTypeOptions.length === 1 ? workspaceTypeOptions[0].id : undefined, // WEB-22557
    sharedInitialValues: {}
  });

  const handleOnWorkspaceTypeChange = React.useCallback(
    (workspaceTypeId: HttpTypes.WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => {
      setState({
        //
        workspaceTypeId,
        sharedInitialValues
      });
    },
    [setState]
  );

  const { workspaceTypeId, sharedInitialValues } = state;
  switch (workspaceTypeId) {
    case HttpTypes.WorkspaceTypeEnum.FinancialSettlement:
      return (
        <FinancialForm //
          workspaceTypeOptions={workspaceTypeOptions}
          workspaceTypeId={workspaceTypeId}
          sharedInitialValues={sharedInitialValues}
          onClose={onClose}
          onWorkspaceTypeChange={handleOnWorkspaceTypeChange}
        />
      );

    case HttpTypes.WorkspaceTypeEnum.RegistrationOnly:
    default:
      return (
        <RegOnlyForm //
          workspaceTypeOptions={workspaceTypeOptions}
          workspaceTypeId={workspaceTypeId}
          sharedInitialValues={sharedInitialValues}
          onClose={onClose}
          onWorkspaceTypeChange={handleOnWorkspaceTypeChange}
        />
      );
  }
}

export default CreationFormView;
