import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { downloadDocumentAttachment } from 'src/containers/documents/views/edit-document/api';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import http from 'src/utils/http';
import { UploadFileTypeEnum } from './enums';
import { DownloadFileApiResponse, WorkspaceFile } from './models';

export const resolveDownloadWorkspaceFileUrl = async (workspaceId: string, participantId: string, fileId: string) => {
  const { downloadUrl } = await http.get<DownloadFileApiResponse>( //
    `/files/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/${encodeURIComponent(fileId)}`,
    {
      withCredentials: true
    }
  );

  return downloadUrl;
};

export async function downloadFile(workspaceId: string, participantId: string, workspaceTypeId: HttpTypes.WorkspaceTypeEnum, item: WorkspaceFile) {
  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
    feature: 'uploaded-files-view',
    logGroupId: 'workspace',
    workspaceId,
    participantId,
    workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId]
  });

  let downloadUrl: string;
  switch (item.uploadFileType) {
    case UploadFileTypeEnum.DocumentAttachment:
      const { pdfUrl } = await downloadDocumentAttachment(item.s3Key!, {
        documentId: item.attachedToDocumentId!,
        workspaceId,
        participantId
      });

      downloadUrl = pdfUrl;
      break;
    case UploadFileTypeEnum.WorkspaceFile:
    default:
      downloadUrl = await resolveDownloadWorkspaceFileUrl(workspaceId, participantId, item.id!);
      break;
  }

  window.open(downloadUrl);
}
