import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { NoticeOfDeath_2_21_2_Model } from '../models';

type FormModel = NoticeOfDeath_2_21_2_Model;

export function resolveDeceasedPartiesOnNOD(
  //
  deceasedTenancyDetail: FormModel['deceasedTenancyDetail'],
  partyBook: FormModel['partyBook']
): FormModel['partyBook'] {
  try {
    if (Array.isArray(deceasedTenancyDetail?.proprietorGroups) && Array.isArray(partyBook)) {
      const selectedPartyBookIds: string[] = deceasedTenancyDetail?.proprietorGroups //
        .flatMap(group =>
          group.parties //
            .filter(p => p.isSelected)
            .map(proprietor => proprietor.partyBookId)
            .filter(Boolean)
        );

      const selectedParties: FormModel['partyBook'] = partyBook.filter(p => selectedPartyBookIds.includes(p.id));
      return selectedParties;
    }
  } catch (e) {}
  Logger.captureException(new BusinessLogicError('Unable to resolve deceased parties'));
  return [];
}
