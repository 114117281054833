import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { PartLandAffectedWithDescriptionsModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupTypeEnum, TenancyPartyModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiPartySourceModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { PartyAdjuster } from 'src/containers/documents/partyAdjuster';
import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { NSW_CAVEAT_ADDRESS_FORM_CONFIG, NSW_CAVEAT_PARTY_FORM_CONFIG } from './config';
import { CaveatModel_2_21_2 } from './models';

type FormModel = CaveatModel_2_21_2;

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: NSW_CAVEAT_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification>({
  partyFormConfig: NSW_CAVEAT_PARTY_FORM_CONFIG
});

export {
  //
  convertAddressBookFromApiToFormModel,
  convertAddressBookFromFormToApiModel,
  convertPartyBookFromApiToFormModel,
  convertPartyBookFromFormToApiModel
};

/**
 * Returns list of title references selected by the user.
 */
export const getSelectedTitleReferences = defaultMemoize(
  (
    //
    titleReferences: FormModel['titleReferences']
  ): FormModel['titleReferences'] => {
    return titleReferences.filter(({ isSelected }) => isSelected);
  }
);

export function calculateProprietorsOnTitle(proprietorIds: string[], partyBook: FormModel['partyBook']): FormModel['proprietorsOnTitle'] {
  const proprietorParties: PartyModel[] = partyBook.filter(pb => proprietorIds.includes(pb.id));
  const partyBookIds: string[] = proprietorParties.map(p => p.id);
  const partyNamesMap: Map<string, string> = new Map<string, string>(proprietorParties.map(p => [p.id, p.legalEntityName!]));
  return {
    partyBook: proprietorParties,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }
      return partyNamesMap.get(party.id) === party.legalEntityName!;
    }
  };
}

export function isProprietorsResetTenancyAvailable({
  //
  partyBook,
  proprietors,
  proprietorsOnTitle,
  precedingData
}: {
  partyBook: FormModel['partyBook'];
  proprietors: FormModel['proprietors'];
  proprietorsOnTitle: FormModel['proprietorsOnTitle'];
  precedingData: FormModel['precedingData'];
}): boolean {
  if (precedingData.sourceChanged) return true;

  const displayedPartyBookIds = proprietors.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedPartyBookIds, proprietorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  const displayedParties: PartyModel[] = displayedPartyBookIds.map(id => partyBook.find(p => p.id === id)).filter(Boolean) as PartyModel[];

  //* 2. has any tenant been updated by the user?
  const isUpdated = displayedParties.some(p => !proprietorsOnTitle.isOriginal(p));

  return isUpdated;
}

export function generateProprietors(formModel: CaveatModel_2_21_2, includeCustomParties: boolean = true): CaveatModel_2_21_2 {
  const selectedTitles = getSelectedTitleReferences(formModel.titleReferences);

  if (!selectedTitles.length) {
    return {
      ...formModel,
      proprietors: {
        proprietorGroups: [],
        tenancyType: TenancyTypeEnum.None
      }
    };
  }

  const otherParties = formModel.partyBook.filter(
    party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber || party.metadata?.addedFrom === DataSource.Custom
  );

  const customProprietors = includeCustomParties
    ? formModel.proprietors.proprietorGroups.flatMap(pg => pg.parties).filter(proprietor => otherParties.some(o => o.id === proprietor.partyBookId!))
    : [];

  const commonProprietors = getCommonProprietors(formModel);
  const commonProprietorPartyReference = commonProprietors.map(p => toPartyReference(p));
  const proprietors = createGenericTenancyDetail([...commonProprietorPartyReference, ...customProprietors]);

  const proprietorPartyIds = commonProprietorPartyReference.map(p => p.partyBookId!);

  const partyBook = convertPartyBookFromApiToFormModel(
    commonProprietors.map(p => p.party),
    proprietorPartyIds
  ).concat(otherParties);

  const defaultCaveators = getDefaultCaveators(formModel.caveators, formModel.subscriberParties);

  const claimDetails: FormModel['claimDetails'] = {
    ...formModel.claimDetails,
    claimParties: PartyAdjuster.adjustArray(formModel.claimDetails.claimParties!, partyBook, formModel.partyBook)
  };

  const proprietorsOnTitle: FormModel['proprietorsOnTitle'] = calculateProprietorsOnTitle(proprietorPartyIds, partyBook);
  return {
    ...formModel, //
    proprietors,
    partyBookApi: commonProprietors.map(p => p.party),
    partyBook,
    caveators: PartyAdjuster.adjustTenancyDetail(defaultCaveators, partyBook, formModel.partyBook),
    contactDetails: PartyAdjuster.adjustArray(formModel.contactDetails, partyBook, formModel.partyBook),
    claimDetails,
    proprietorsOnTitle,
    precedingData: {
      ...formModel.precedingData,
      titlesUsed: selectedTitles
        .map(title => title.reference!)
        .filter(titleReference => Boolean(formModel.precedingData.newProprietors.find(np => np.proprietorsSource.titles.includes(titleReference)))),
      dataChanged: formModel.precedingData?.dataChanged ?? false,
      sourceChanged: false,
      manualOverride: false
    }
  };
}

const getDefaultCaveators = (caveators: CaveatModel_2_21_2['caveators'], subscriberParties: CaveatModel_2_21_2['subscriberParties']): GenericTenancyDetailModel => {
  if (caveators.proprietorGroups.length) return caveators;

  const defaultCaveatorParties = subscriberParties.map(party => toPartyReference(party!));
  const defaultCaveators: GenericTenancyDetailModel = createGenericTenancyDetail(defaultCaveatorParties);

  return defaultCaveators;
};

export function hasCommonProprietors(titleReferences: CaveatModel_2_21_2['titleReferences'], precedingData: CaveatModel_2_21_2['precedingData']): boolean {
  const { hasCommonProprietor } = retrieveProprietorFromTitle(
    titleReferences.filter(tr => tr.isSelected),
    precedingData
  );
  return hasCommonProprietor;
}

export const retrieveProprietorFromTitle = (
  selectedTitles: CaveatModel_2_21_2['titleReferences'],
  precedingData: CaveatModel_2_21_2['precedingData']
): { hasCommonProprietor: boolean; commonProprietors: ApiPartySourceModel<NswDocumentPartyJustification>[] } => {
  const getProprietors = (
    titleReference: TitleReferenceModel<PartLandAffectedWithDescriptionsModel, NswDocumentPartyJustification>
  ): ApiPartySourceModel<NswDocumentPartyJustification>[] => titleReference.proprietor.proprietorGroups.flatMap(item => item.parties);

  if (!selectedTitles.length) return { hasCommonProprietor: false, commonProprietors: [] };

  const allProprietors = selectedTitles.map(
    tr => precedingData.newProprietors.find(p => p.proprietorsSource.titles.some(t => t === tr.reference))?.proprietorGroups.flatMap(pg => pg.parties) ?? getProprietors(tr)
  );
  const proprietorsToCheck = allProprietors.slice(1);
  const commonProprietors = proprietorsToCheck.reduce((prev, current) => {
    if (!prev.length) return prev;
    return getCommonParties(prev, current);
  }, allProprietors[0]);

  return {
    hasCommonProprietor: commonProprietors.length !== 0 && allProprietors.some(p => p.length !== commonProprietors.length),
    commonProprietors
  };
};

export function getCommonProprietors(values: CaveatModel_2_21_2): ApiPartySourceModel<NswDocumentPartyJustification>[] {
  const { commonProprietors: commonApiProprietors } = retrieveProprietorFromTitle(
    values.titleReferences.filter(x => x.isSelected),
    values.precedingData
  );
  if (!commonApiProprietors.length) return [];

  let id = 1;
  const commonProprietors = commonApiProprietors.map(apiProprietor => ({
    ...apiProprietor,
    party: { ...apiProprietor.party, id: apiProprietor.party.externalId ?? `CAVEAT-${id++}` }
  }));

  return commonProprietors;
}

const toPartyReference = (apiPartySource: ApiPartySourceModel<NswDocumentPartyJustification>): TenancyPartyModel => ({
  isSelected: true,
  partyBookId: apiPartySource.party.id,
  partyCapacity: apiPartySource.partyCapacity ?? {},
  addressBookId: apiPartySource.addressBookId
});

function getCommonParties(
  parties1: ApiPartySourceModel<NswDocumentPartyJustification>[],
  parties2: ApiPartySourceModel<NswDocumentPartyJustification>[]
): ApiPartySourceModel<NswDocumentPartyJustification>[] {
  return parties1.reduce((prev: ApiPartySourceModel<NswDocumentPartyJustification>[], party1: ApiPartySourceModel<NswDocumentPartyJustification>) => {
    if (parties2.some(party2 => isSameProprietor(party1, party2))) {
      prev.push(party1);
    }

    return prev;
  }, []);
}

const isSameProprietor = (party1: ApiPartySourceModel, party2: ApiPartySourceModel): boolean =>
  party1.party.legalEntityName === party2.party.legalEntityName && party1.party.partyType === party2.party.partyType;

function createGenericTenancyDetail(defaultCaveatorParties: TenancyPartyModel[] = []): GenericTenancyDetailModel {
  if (defaultCaveatorParties.length === 0) {
    return { tenancyType: TenancyTypeEnum.None, proprietorGroups: [] };
  }

  return {
    tenancyType: TenancyTypeEnum.None,
    proprietorGroups: [
      {
        isSelected: true,
        proprietorGroupType: ProprietorGroupTypeEnum.None,
        parties: [...defaultCaveatorParties],
        shareFraction: { numerator: 1, denominator: 1 }
      }
    ]
  };
}
