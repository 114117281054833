import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { updateProprietorWithCapacity } from '../../../components/deceased-tenancy-detail/helpers';
import { DeceasedProprietorModel, DeceasedTenancyDetailModel, initDeceasedProprietor } from '../../../components/deceased-tenancy-detail/models';
import { convertPartiesToFormModel } from '../../../helpers';
import { NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from '../config';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../models';

type FormModel = TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model;

const getCurrentApplicantsPartyBookIds = defaultMemoize((applicants: ReceivingTenancyDetailModel): string[] => {
  const applicantsPartyBookIds: string[] = applicants.proprietorGroups
    .flatMap(pg =>
      pg.parties //
        .map(({ partyBookId }) => partyBookId)
    )
    .filter(Boolean) as string[];
  return applicantsPartyBookIds;
});

export function generateDeceasedTenancy(formModel: FormModel): FormModel {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Custom);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  const { precedingData } = formModel;

  const containers = selectedTitles.map(title => {
    const groups: ProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] =
      precedingData.newProprietors
        .find(np => np.proprietorsSource.titles.some(tr => tr === title.reference))
        ?.proprietorGroups.map(({ parties, ...pg }) => ({ ...pg, parties: parties.map(p => p.party) })) ?? title.proprietor.proprietorGroups;

    return new Container(
      title.reference!,
      new TenancyDetail(
        groups.map(pg => new ProprietorGroup(pg.parties, pg, pg.shareFraction, pg.proprietorGroupType)),
        title.proprietor.tenancyType
      )
    );
  });

  const mergeResult = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = mergeResult.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties,
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = mergeResult.containers[0]?.tenancyDetail.tenancyType! ?? TenancyTypeEnum.None;
  const deceasedTenancyDetail: DeceasedTenancyDetailModel = {
    tenancyType,
    proprietorGroups: groups.map(pg => ({
      ...pg,
      isSelected: groups.length === 1 && (pg.parties.length === 1 || tenancyType === TenancyTypeEnum.JointTenants),
      parties: pg.parties.map(p => {
        const defaultSelected = groups.length === 1 && pg.parties.length === 1;
        const proprietor: DeceasedProprietorModel = initDeceasedProprietor(defaultSelected, p.id!);
        return defaultSelected ? updateProprietorWithCapacity(proprietor, proprietor.isSelected, formModel.applicantCapacity) : proprietor;
      })
    }))
  };

  const unselectedProprietorParties = formModel.titleReferences
    .filter(tr => !tr.isSelected)
    .flatMap(tr => tr.proprietor.proprietorGroups.flatMap(pg => pg.parties))
    .map((party, index: number) => ({ ...party, id: (party.id = party.externalId ? party.externalId : `TITLE-${++index}`) }));

  const titleProprietors = groups.flatMap(pg => pg.parties).concat(unselectedProprietorParties);
  const partyIds = titleProprietors.map(p => p.id!);
  const customPartyIds = customParties.map(p => p.id!);
  const applicantsPartyBookIds = getCurrentApplicantsPartyBookIds(formModel.applicants).filter(x => !partyIds.includes(x) && !customPartyIds.includes(x));

  const partyBook = convertPartiesToFormModel_2_21_1(
    titleProprietors,
    titleProprietors.map(p => p.id!)
  )
    .concat(customParties)
    .concat(formModel.partyBook.filter(x => applicantsPartyBookIds.includes(x.id)));

  const grantees = formModel.grantees.map(_ => ({ partyBookId: '', consent: false }));

  return {
    ...formModel,
    mergeFailedReason: mergeResult.failedReason,
    deceasedTenancyDetail: deceasedTenancyDetail,
    partyBookApi: titleProprietors,
    partyBook,
    grantees,
    precedingData: {
      ...precedingData,
      titlesUsed: selectedTitles
        .map(title => title.reference!)
        .filter(titleReference => Boolean(precedingData.newProprietors.find(np => np.proprietorsSource.titles.includes(titleReference)))),
      dataChanged: formModel.precedingData?.dataChanged ?? false,
      sourceChanged: false
    }
  };
}

export function convertPartiesToFormModel_2_21_1(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}
