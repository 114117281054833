import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { AllowedAdditionalParticipantRole, TitleReferenceErrorItem } from 'src/containers/dashboard/components/create-new-workspace/models';
import { useStoreSelector } from 'src/hooks';
import { actionFetchAdditionalInvitableRoles, WorkspaceRelatedApiRequest } from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';

interface WorkspaceInvitableRoleBaseDetail {
  invitableRoles: HttpTypes.WorkspaceRoleEnum[];
  vendors: LookupItemModel[];
  allowedAdditionalParticipantRoles: AllowedAdditionalParticipantRole[];
}

type WorkspaceInvitableRolePositiveDetail = WorkspaceInvitableRoleBaseDetail & {
  canCreateWorkspace: true;
  titleReferenceErrors: null; // backend will send us explicitly null in this case
};

type WorkspaceInvitableRoleNegativeDetail = WorkspaceInvitableRoleBaseDetail & {
  canCreateWorkspace: false;
  titleReferenceErrors: TitleReferenceErrorItem[];
};

export type WorkspaceInvitableRoleDetailApiResponse = WorkspaceInvitableRolePositiveDetail | WorkspaceInvitableRoleNegativeDetail;

export interface WorkspaceInvitationState {
  detail?: WorkspaceInvitableRoleDetailApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
}

const initialState: WorkspaceInvitationState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

function compareArgs(stateArgs?: WorkspaceRelatedApiRequest, payloadArgs?: WorkspaceRelatedApiRequest) {
  return _isEqual(stateArgs, payloadArgs);
}

export function useWorkspaceInvitation(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceInvitation);
  if (compareArgs(state.args, { workspaceId, participantId })) {
    return state;
  }
  return initialState;
}

const workspaceInvitationReducer = createReducer<
  //
  WorkspaceInvitationState,
  Action
>(initialState)
  .handleAction(actionFetchAdditionalInvitableRoles.request, (state, action): WorkspaceInvitationState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchAdditionalInvitableRoles.success,
    (state, action): WorkspaceInvitationState => ({
      ...state,
      detail: action.payload.detail,
      status: 'resolved',
      isLoading: false,
      isRefetching: false
    })
  )
  .handleAction(
    actionFetchAdditionalInvitableRoles.failure,
    (state, action): WorkspaceInvitationState => ({
      ...initialState,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  );

export default workspaceInvitationReducer;
