import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DECEASED_TENANCY_FALLBACK_MAP } from '../../components/deceased-tenancy-detail/fallback';
import { NoticeOfDeath_2_21_2_Model } from './models';

export const fallbackMap: FallbackMap<NoticeOfDeath_2_21_2_Model> = {
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  precedingData: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  deceasedTenancyDetail: DECEASED_TENANCY_FALLBACK_MAP,
  survivingJointTenants: NO_FALLBACK,
  context: NO_FALLBACK
};
