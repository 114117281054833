import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export interface SortableParticipant {
  workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
}

export function sortParticipants<T extends SortableParticipant>(participants: Array<T>, priorityOrder: Array<HttpTypes.WorkspaceRoleEnum>) {
  const grouped = priorityOrder.reduce((result, role) => result.set(role, []), new Map<HttpTypes.WorkspaceRoleEnum, Array<T>>());
  const others: Array<T> = [];

  participants.forEach(participant => {
    const roleParticipantList = grouped.get(participant.workspaceRole.id);
    roleParticipantList !== undefined ? roleParticipantList.push(participant) : others.push(participant);
  });

  const sorted = priorityOrder.reduce((result, role) => {
    const roleParticipantList = grouped.get(role);
    if (roleParticipantList) {
      return result.concat(roleParticipantList);
    }
    return result;
  }, new Array<T>());

  return sorted.concat(others);
}
