import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class Me {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves non-paginated list of linked devices of a currently signed in user. It sends a request to the
   * `/profile/linked-devices`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The list of linked devices currently signed in user.
   *
   * @example
   * To retrieve the list of linked devices of a currently signed in user:
   *
   * ```ts
   * sdk.profile.me.listLinkedDevices()
   * .then((devices) => {
   *   console.log(devices)
   * })
   * ```
   */
  async listLinkedDevices(config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.ProfileDeviceListApiResponse>( //
      `/profile/linked-devices`,
      {
        ...config
      }
    );
  }

  /**
   * This method deletes a linked device. This method sends a request to the
   * /profile/device/${deviceId}/unlink
   * API route.
   *
   * @param id - The customer groups ID.
   * @param config - Additional config to pass with the request.
   * @returns
   *
   * @example
   * sdk.profile.me.deleteLinkedDevice("device_123")
   * .then(() => {
   *   console.log('Deleted linked device')
   * })
   */
  async deleteLinkedDevice(deviceId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ResponseNotDefined>(`/profile/device/${deviceId}/unlink`, {
      method: 'PUT',
      ...config,
      body: {}
    });
  }

  /**
   * This method verifies whether password satisfies all security requirements. This method sends a request to the
   * /profile/validate-password
   * API route.
   *
   * @param password - Password.
   * @param config - Additional config to pass with the request.
   * @returns
   *
   * @example
   * sdk.profile.me.validatePassword("superSecretPassword")
   * .then(({ result }) => {
   *   console.log(result); // true or false
   * })
   */
  async validatePassword(password: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ProfileValidatePasswordApiResponse>(`/profile/is-valid-password`, {
      method: 'POST',
      ...config,
      body: { password } satisfies HttpTypes.ProfileValidatePasswordApiRequestBody
    });
  }
}
