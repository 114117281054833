import * as React from 'react';

import { twMerge } from 'tailwind-merge';
import MuiSkeleton from '@mui/material/Skeleton';

import { HttpTypes } from '@sympli/api-gateway/types';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { CardDetail } from 'src/containers/dashboard/components/card';
import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { SettlementDateDetailsState } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { useScreenSize } from 'src/hooks';
import { WorkspaceBasicInfoState } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { WorkspaceParticipantsState } from 'src/store/reducers/workspace/workspaceParticipants';
import { ScreenSizeVariant } from 'src/theme';
import { InvitationsActionsFormatter } from './Formatters';

export interface Row {
  subscriberName: string;
  workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
  participantStatus: LookupEnumModel<HttpTypes.ParticipantStatusEnum>;
  settlementAcceptance: {
    isLoading: boolean;
    isAcceptedByUser?: boolean;
    hasProposedNewDate: boolean;
    isDateOnlyOrNoDateTime: boolean;
  };
  participantId: string;
  reference?: string;
  canSwitch?: boolean; // if true, display switch button to this participant
  currentParticipantId: string;
  workspaceId: string;
  isPendingInvitation: boolean;
  canResend?: boolean;
}

type NestedFormatterProps = Pick<FormatterInputModel<Row>, 'row' | 'tooltipHandlers'> & {
  className?: string;
  isReady: boolean;
};

function workspaceRolesFormatter({
  //
  className,
  row,
  isReady,
  tooltipHandlers
}: NestedFormatterProps) {
  return (
    <div data-binding="workspaceRoles" className={twMerge('truncated font-[400] text-[var(--neutral-400)]', className)} {...(isReady && tooltipHandlers)}>
      {isReady ? row.workspaceRole.name : <MuiSkeleton variant="text" />}
    </div>
  );
}

const mapping: Record<HttpTypes.ParticipantStatusEnum, 'ok' | 'neutral' | 'warning' | 'error'> = {
  [HttpTypes.ParticipantStatusEnum.Pending]: 'warning',
  [HttpTypes.ParticipantStatusEnum.Accepted]: 'ok',
  [HttpTypes.ParticipantStatusEnum.Rejected]: 'error',
  [HttpTypes.ParticipantStatusEnum.InviteForwarded]: 'neutral',
  [HttpTypes.ParticipantStatusEnum.Withdrawn]: 'neutral'
};

export interface InvitationsCardDetailProps {
  workspaceParticipantsState: WorkspaceParticipantsState;
  settlementDateDetailsState: SettlementDateDetailsState;
  workspaceBasicInfoState: WorkspaceBasicInfoState;
  className?: string;
  invitationColumnName?: string;
  showWorkspaceRoleColumn?: boolean;
  displayActionColumn?: boolean;
  currentParticipantId: string;
  workspaceId: string;
}

function InvitationsCardDetail({
  //
  workspaceParticipantsState: {
    //
    status,
    isLoading,
    items
  },
  settlementDateDetailsState,
  workspaceBasicInfoState: { detail, status: workspaceBasicInfoStatus },
  className,
  invitationColumnName,
  showWorkspaceRoleColumn,
  displayActionColumn,
  currentParticipantId,
  workspaceId
}: InvitationsCardDetailProps) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const isSmallScreen = screenVariant === 'small-laptop';

  const isReady: boolean = (status === 'resolved' || status === 'refetching') && (workspaceBasicInfoStatus === 'resolved' || workspaceBasicInfoStatus === 'refetching');
  const isSettlementAcceptanceReady = settlementDateDetailsState.status === 'resolved';

  const rows: Row[] = isReady
    ? items
        .filter(p => !p.archivedStatus || ![HttpTypes.ParticipantArchivedTypeEnum.Withdraw, HttpTypes.ParticipantArchivedTypeEnum.Abandon].includes(p.archivedStatus.type))
        .map(p => {
          const settlementParticipant = settlementDateDetailsState.detail?.participants.find(sp => sp.id === p.id);

          return {
            subscriberName: p.name,
            workspaceRole: p.workspaceRole,
            participantStatus: p.participantStatus,
            settlementAcceptance: {
              isDateOnlyOrNoDateTime: !detail?.settlementDate,
              isLoading: !isSettlementAcceptanceReady,
              isAcceptedByUser: settlementParticipant?.hasAccepted,
              hasProposedNewDate: !!(settlementDateDetailsState.detail?.proposedByParticipant?.id === settlementParticipant?.id)
            },
            participantId: p.id,
            reference: p.reference,
            canSwitch: p.canSwitch,
            currentParticipantId,
            workspaceId,
            isPendingInvitation: p.participantStatus.id === HttpTypes.ParticipantStatusEnum.Pending,
            canResend: p.canResend
          } satisfies Row;
        })
    : [];

  const invitationsFormatter = ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
    return (
      <div className="text-[12px] leading-[14px] my-[2px] py-[2px]">
        <div data-binding="subscriberName" className="truncated font-[700] text-[var(--neutral-1000)]" {...(isReady && tooltipHandlers)}>
          {isReady ? row.subscriberName : <MuiSkeleton variant="text" />}
        </div>
        {(screenVariant !== 'small-laptop' || !showWorkspaceRoleColumn) &&
          workspaceRolesFormatter({
            //
            row,
            isReady,
            tooltipHandlers,
            className: currentParticipantId === row.participantId ? 'text-[var(--sympli-green)]' : undefined
          })}
      </div>
    );
  };

  const invitationsHoverFormatter = (props: FormatterInputModel<Row>) => {
    const { row, tooltipHandlers } = props;
    if (!row.reference) {
      return invitationsFormatter(props);
    }

    return (
      <div className="text-[12px] leading-[14px] my-[2px] py-[2px]">
        <div data-binding="subscriberName" className="truncated font-[700] text-[var(--neutral-1000)]" {...(isReady && tooltipHandlers)}>
          {isReady ? row.subscriberName : <MuiSkeleton variant="text" />}
        </div>
        <div data-binding="reference" className="truncated font-[400] text-[var(--neutral-400)] w-[338px]">
          {row.reference}
        </div>
      </div>
    );
  };

  const columns: ColumnsModel<Row> = {
    invitations: {
      verticalAlign: 'top',
      label: `${invitationColumnName ?? 'Invitations'}`,
      ...(displayActionColumn && { width: isSmallScreen ? 110 : 140 }),
      formatter: invitationsFormatter,
      ...(displayActionColumn && {
        hoveredFormatter: invitationsHoverFormatter
      })
    },
    ...(screenVariant === 'small-laptop' &&
      showWorkspaceRoleColumn && {
        workspaceRole: {
          verticalAlign: 'top',
          width: 150,
          label: '',
          formatter: ({ row, tooltipHandlers }: FormatterInputModel<Row>) => {
            return workspaceRolesFormatter({
              //
              row,
              isReady,
              tooltipHandlers,
              className: 'text-[12px] leading-[14px] my-[2px] py-[2px]'
            });
          }
        }
      }),
    participantStatus: {
      verticalAlign: 'top',
      width: displayActionColumn ? (isSmallScreen ? 66 : 87) : screenVariant === 'medium-screen' ? 70 : 80,
      label: 'INVITE',
      formatter: ({ row: { participantStatus }, tooltipHandlers }: FormatterInputModel<Row>) => {
        const variant = mapping[participantStatus.id];
        return (
          <div data-binding="participantStatus" className="pt-[2px]">
            {isReady ? (
              <VerticalStatusLine //
                text={participantStatus.name}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    },
    settlementAcceptanceStatus: {
      width: displayActionColumn ? (isSmallScreen ? 66 : 73) : screenVariant === 'medium-screen' ? 65 : 80,
      verticalAlign: 'top',
      label: 'DATE & TIME',
      formatter: ({ row: { settlementAcceptance }, tooltipHandlers }: FormatterInputModel<Row>) => {
        const text = settlementAcceptance.isDateOnlyOrNoDateTime ? 'Not set' : settlementAcceptance.isAcceptedByUser ? 'Accepted' : 'Pending';
        const variant = settlementAcceptance.isDateOnlyOrNoDateTime ? 'neutral' : settlementAcceptance.isAcceptedByUser ? 'ok' : 'warning';

        return (
          <div data-binding="settlementAcceptanceStatus" className="pt-[2px]">
            {isReady && !settlementAcceptance.isLoading ? (
              <VerticalStatusLine //
                text={text}
                variant={variant}
                tooltipHandlers={tooltipHandlers}
                className="h-[14px]"
                textClassName="truncated"
              />
            ) : (
              <MuiSkeleton variant="text" />
            )}
          </div>
        );
      }
    },
    ...(displayActionColumn && {
      actions: {
        verticalAlign: 'top',
        width: 14,
        label: '',
        formatter: InvitationsActionsFormatter
      }
    })
  };

  return (
    <CardDetail //
      className={className}
      columns={columns}
      rows={rows}
      {...(isLoading && {
        isLoading: true,
        pageSize: 4
      })}
      {...(isReady && {
        pageSize: rows.length
      })}
    />
  );
}

export default React.memo(InvitationsCardDetail);
