import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchNotificationsSettings = createAsyncAction(
  //
  'FETCH_NOTIFICATIONSSETTINGS',
  'FETCH_NOTIFICATIONSSETTINGS_SUCCESS',
  'FETCH_NOTIFICATIONSSETTINGS_ERROR'
)<
  //
  void,
  { data: HttpTypes.NotificationSettingsApiResponse },
  { error: Error }
>();
