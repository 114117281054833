import { HttpTypes } from '@sympli/api-gateway/types';

import { WorkspaceTaskApiResponse } from 'src/containers/workspace/shared/detail/components/my-tasks/models';

export function isLodgementProgressVisible(
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum,
  lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum,
  workspaceTasks?: WorkspaceTaskApiResponse[]
) {
  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.LodgementInQueue) {
    return true;
  }

  switch (lodgementCaseStatusId) {
    case HttpTypes.LodgementCaseStatusEnum.LodgementRequested:
    case HttpTypes.LodgementCaseStatusEnum.LodgementSuccess:
    case HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError:
    case HttpTypes.LodgementCaseStatusEnum.LodgementTechnicalError:
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested:
    case HttpTypes.LodgementCaseStatusEnum.Registered:
    case HttpTypes.LodgementCaseStatusEnum.Rejected:
    case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
    case HttpTypes.LodgementCaseStatusEnum.Unnecessary:
      return true;
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError:
      return true;
    case HttpTypes.LodgementCaseStatusEnum.LodgementError:
      if (workspaceTasks && workspaceTasks.length === 0) {
        // Lodgement error without any tasks (example, resolve issues for document)
        return true;
      }
  }

  return false;
}
