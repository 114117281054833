import { HttpTypes } from '@sympli/api-gateway/types';

export const DocumentFormIdentifierDisplayMapping: Record<HttpTypes.DocumentTypeIdentifierEnum, string> = {
  [HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions]: 'Lodgement Instructions',
  [HttpTypes.DocumentTypeIdentifierEnum.CoRDHolderConsent]: 'CoRD Holder Consent',
  [HttpTypes.DocumentTypeIdentifierEnum.Mortgage]: 'Mortgage',
  [HttpTypes.DocumentTypeIdentifierEnum.DischargeOfMortgage]: 'Discharge Of Mortgage',
  [HttpTypes.DocumentTypeIdentifierEnum.Caveat]: 'Caveat',
  [HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfCaveat]: 'Withdrawal Of Caveat',
  [HttpTypes.DocumentTypeIdentifierEnum.PriorityNotice]: 'Priority Notice',
  [HttpTypes.DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice]: 'Extension Of Priority Notice',
  [HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice]: 'Withdrawal Of Priority Notice',
  [HttpTypes.DocumentTypeIdentifierEnum.Transfer]: 'Transfer',
  [HttpTypes.DocumentTypeIdentifierEnum.SettlementStatement]: 'Settlement Statement',
  [HttpTypes.DocumentTypeIdentifierEnum.NoticeOfSale]: 'Notice Of Sale',
  [HttpTypes.DocumentTypeIdentifierEnum.TransmissionApplication]: 'Transmission Application',
  [HttpTypes.DocumentTypeIdentifierEnum.WithdrawalCoRDHolderConsent]: 'Withdrawal CoRD Holder Consent',
  [HttpTypes.DocumentTypeIdentifierEnum.PaymentSummary]: 'Payment Summary',
  [HttpTypes.DocumentTypeIdentifierEnum.AdministrativeNotice]: 'Administrative Notice',
  [HttpTypes.DocumentTypeIdentifierEnum.TransferOfControl]: 'Transfer Of Control',
  [HttpTypes.DocumentTypeIdentifierEnum.Nomination]: 'Nomination',
  [HttpTypes.DocumentTypeIdentifierEnum.NoticeOfAcquisition]: 'Notice Of Acquisition',
  [HttpTypes.DocumentTypeIdentifierEnum.Form24PartA]: 'Form24PartA',
  [HttpTypes.DocumentTypeIdentifierEnum.Form24PartB]: 'Form24PartB',
  [HttpTypes.DocumentTypeIdentifierEnum.Form25]: 'Form 25',
  [HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfNomination]: 'Withdrawal of Nomination',
  [HttpTypes.DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase]: 'Convert pCT and Nominate to the Lodgement Case',
  [HttpTypes.DocumentTypeIdentifierEnum.NominateEctToPaperInstrument]: 'Nominate eCT to Paper Instrument',
  [HttpTypes.DocumentTypeIdentifierEnum.NominateEctToTheLodgementCase]: 'Nominate eCT to the Lodgement Case',
  [HttpTypes.DocumentTypeIdentifierEnum.Lease]: 'Lease',
  [HttpTypes.DocumentTypeIdentifierEnum.SubLease]: 'Sub-Lease',
  [HttpTypes.DocumentTypeIdentifierEnum.NoticeOfDeath]: 'Notice Of Death',
  [HttpTypes.DocumentTypeIdentifierEnum.NationalMortgageOverInterestInLand]: 'Mortgage over an interest in land',
  [HttpTypes.DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee]: 'Transmission Application by Executor, Administrator or Trustee',
  [HttpTypes.DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin]: 'Transmission Application by Beneficiary, Devisee or Next of Kin',
  [HttpTypes.DocumentTypeIdentifierEnum.DischargeOfMortgageOverInterestInLand]: 'Discharge of Mortgage over an interest in land',
  [HttpTypes.DocumentTypeIdentifierEnum.TransferOfInterest]: 'Transfer of Interest',
  [HttpTypes.DocumentTypeIdentifierEnum.CaveatOverInterestInLand]: 'Caveat over an interest in land',
  [HttpTypes.DocumentTypeIdentifierEnum.RequestTransferEctControlToRegistrar]: 'Request to transfer eCT control to Registrar of Titles',
  [HttpTypes.DocumentTypeIdentifierEnum.RequestEctControlFromRegistrar]: 'Request eCT control from Registrar of Titles'
};
