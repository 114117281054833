import * as React from 'react';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel, DashboardTypeEnum } from 'src/models';
import { WorkspaceStatusEnum } from '../../filters/models';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import { AggregatorComponentBaseProps, AggregatorNameEnum, AggregatorQuery, FinancialDashboardAggregatorApiRequest } from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): FinancialDashboardAggregatorApiRequest => {
  const { to } = getDateRange(DateRangeTypeEnum.ThisWeekStartsOnMonday);

  const settlementPeriod: DashboardDateRangeModel = {
    start: new Date(), // it is always from today and til end of the week
    end: to
  };

  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.thisWeekSettlements,
      workspaceStatus:
        assignmentType === AssignmentTypeEnum.ReAssigned
          ? [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors, WorkspaceStatusEnum.Ready]
          : [
              WorkspaceStatusEnum.InPreparation,
              WorkspaceStatusEnum.InPreparationWithErrors,
              WorkspaceStatusEnum.Ready,
              WorkspaceStatusEnum.Settling,
              WorkspaceStatusEnum.SettlingWithErrors
            ],
      settlementPeriod
    }
  ];

  const payload: FinancialDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

function ThisWeekCard(props: AggregatorComponentBaseProps) {
  return (
    <AggregatorCardWrapper //
      payloadResolver={payloadResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <AggregatorCard
          title="This Week"
          cardType={DashboardTypeEnum.Financial}
          isLoading={isLoading}
          onActionClick={onActionClick}
          isFetchingGridData={isFetchingGridData}
          items={[
            {
              id: AggregatorNameEnum.thisWeekSettlements,
              content,
              status: 'Total Settlements',
              actionText: 'Show me'
            }
          ]}
        />
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(ThisWeekCard);
