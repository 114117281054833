import queryString from 'query-string';

import { HttpTypes } from '@sympli/api-gateway/types';

export interface TitleQueryParams {
  workspaceId: string;
  participantId?: string;
  titleReference: string;
}

export const readonlyWorkspaceStatuses: HttpTypes.WorkspaceStatusEnum[] = [
  HttpTypes.WorkspaceStatusEnum.Abandoned,
  HttpTypes.WorkspaceStatusEnum.Archived,
  HttpTypes.WorkspaceStatusEnum.Success,
  HttpTypes.WorkspaceStatusEnum.Finalised
];

export function resolveTitleDetailLink(queryParams: TitleQueryParams) {
  const { workspaceId, participantId = '', titleReference } = queryParams;
  const query = queryString.stringify({ participantId, titleReference });
  return `/workspace/${encodeURIComponent(workspaceId)}/titles?${query}`;
}

// this means user cannot do anything to the workspace
export function resolveWorkspaceReadonly(workspaceStatus: HttpTypes.WorkspaceStatusEnum) {
  return readonlyWorkspaceStatuses.includes(workspaceStatus);
}
