import * as yup from 'yup';

import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import { HttpTypes } from '@sympli/api-gateway/types';
import msg from '@sympli/ui-framework/utils/messages';

import {
  AdditionalParticipantRoleConfig,
  CreateFinancialWorkspaceInviteParticipantsStepFormModel,
  InvitationModel
} from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/models';
import { FinancialRoleMapping, financialWorkspaceRoles, resolveWorkspaceRole } from 'src/models/roles';
import { isSupportCompatibleRolesCheck } from './components/additional-invitations/helpers';
import { InvitationItemModel, SubscriberSearchItemModel } from './models';

const INVITATION_DESCRIPTION_MAX_CHARACTER: number = 250;
const MATTER_NUMBER_MAX_CHARACTER: number = 200;
const DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE: string = 'You can not invite the same subscriber with the same role';

function getValidationSchema(
  createdByRoleId: HttpTypes.WorkspaceRoleEnum | string,
  currentSubscriber: HttpTypes.UserProfileModel,
  additionalParticipantRoleConfigs: Partial<Record<HttpTypes.WorkspaceRoleEnum, AdditionalParticipantRoleConfig>>
) {
  const schema = yup
    .object<CreateFinancialWorkspaceInviteParticipantsStepFormModel>({
      invitations: yup
        .array()
        .of<InvitationModel>(getInvitationValidationSchema(createdByRoleId, currentSubscriber, additionalParticipantRoleConfigs))
        .defined(),
      additionalInvitations: yup
        .array()
        .of<InvitationModel>(getAdditionalInviteValidationSchema(createdByRoleId, currentSubscriber, additionalParticipantRoleConfigs))
        .defined(),
      invitedRoles: yup.mixed(),
      vendors:
        createdByRoleId === HttpTypes.WorkspaceRoleEnum.Vendor
          ? yup
              .array<string>()
              .min(1, msg.MUST_SELECT_AT_LEAST_X(1, 'vendor'))
              .test(
                'vendors',
                'The total number of the vendors that you represent and invite are more than number of vendors on title', //
                function test(this: yup.TestContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>, vendors: string[]): boolean {
                  let vendorTotalNumber = 0;
                  const { invitedRoles, invitations } = this.options.context!;
                  const invitedVendors = invitations.filter(x => x.role === HttpTypes.WorkspaceRoleEnum.Vendor);
                  if (invitedRoles && invitedRoles[HttpTypes.WorkspaceRoleEnum.Vendor]) {
                    vendorTotalNumber += invitedVendors.length;
                  }
                  vendorTotalNumber += vendors.length;
                  if (
                    additionalParticipantRoleConfigs[HttpTypes.WorkspaceRoleEnum.Vendor] &&
                    vendorTotalNumber > additionalParticipantRoleConfigs[HttpTypes.WorkspaceRoleEnum.Vendor]!.numberOfParticipantsAllowed
                  ) {
                    return false;
                  }
                  return true;
                }
              )
              .defined()
          : yup.mixed()
    })
    .defined();

  return schema;
}

export function getInvitationValidationSchema(
  createdByRoleId: HttpTypes.WorkspaceRoleEnum | string,
  currentSubscriber: HttpTypes.UserProfileModel,
  additionalParticipantRoleConfigs: Partial<Record<HttpTypes.WorkspaceRoleEnum, AdditionalParticipantRoleConfig>>,
  additionalInvitation: boolean = false,
  workspaceParticipants: HttpTypes.WorkspaceParticipant[] = []
) {
  const getErrorMessage = (roles: HttpTypes.WorkspaceRoleEnum[]) => {
    const roleNames = roles
      .sort()
      .map(role => resolveWorkspaceRole(role))
      .join(' or ');
    return `This participant cannot be invited with ${roleNames} participant.`;
  };
  return yup
    .object<InvitationModel>({
      id: yup.mixed(),
      role: yup
        .mixed<HttpTypes.WorkspaceRoleEnum>()
        .test(
          'compatibleRoles',
          'compatible with selected roles',
          function test(this: yup.TestContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>, value: HttpTypes.WorkspaceRoleEnum): boolean | yup.ValidationError {
            const values = this.options.context!;
            const { invitedRoles } = values;
            if (invitedRoles[value] && isSupportCompatibleRolesCheck(value)) {
              const compatibleRoles = additionalParticipantRoleConfigs[value]?.compatibleRoles || [];
              if (
                Object.entries(invitedRoles).some(([key, selected]) => {
                  const role = Number(key);
                  return selected && role !== value && !compatibleRoles.includes(role);
                })
              ) {
                const incompatibleRoles = financialWorkspaceRoles.filter(item => item !== value && !compatibleRoles.includes(item));
                return this.createError({ message: getErrorMessage(incompatibleRoles) });
              }
            }

            return true;
          }
        ),
      invitation: getInviteValidationSchema(createdByRoleId, currentSubscriber, additionalInvitation, workspaceParticipants)
    })
    .defined();
}

export function getInviteValidationSchema(
  createdByRoleId: HttpTypes.WorkspaceRoleEnum | string,
  currentSubscriber: HttpTypes.UserProfileModel,
  additionalInvitation: boolean = false,
  workspaceParticipants: HttpTypes.WorkspaceParticipant[] = []
) {
  const yupSubscriber = yup
    .object<SubscriberSearchItemModel>({
      // Id will either contain id (SYM) or elnoSubscriberId (IOP)
      id: yup
        .string()
        .default('')
        .test(
          '_subscriberId_check',
          'Must specify subscriber name', //
          function test(this: yup.TestContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>, subscriberId: string): boolean | yup.ValidationError {
            // * if role is NOT ticked, it's not mandatory
            const values = this.options.context!;
            const { additionalInvitations, invitations, invitedRoles } = values;

            // get index from indicator from path
            const idxMatch = this.path.match(/\[\d\]+/g);
            if (!Array.isArray(idxMatch) || idxMatch.length === 0 || !idxMatch) {
              return false;
            }

            const numberOfIdxMatch = Number(idxMatch[0].replace(/\D/g, ''));
            // the add more roles can be exceed the backend returned value
            if (numberOfIdxMatch >= values.invitations.length) {
              return false;
            }

            let roleToProcess = values.invitations[numberOfIdxMatch].role!;
            if (additionalInvitation) {
              if (numberOfIdxMatch >= values.additionalInvitations.length) {
                return false;
              }
              roleToProcess = values.additionalInvitations[numberOfIdxMatch].role!;
            }

            if (!additionalInvitation && (!invitedRoles || !invitedRoles[roleToProcess!])) {
              return true;
            }

            if (!subscriberId.length) {
              return false;
            }

            // 2. if subscriber id is not empty, check duplication
            const additionalInvitationItems = additionalInvitations.filter(s => s.role === roleToProcess).map(additionalInvitation => additionalInvitation.invitation);
            let invitationItems = invitations.filter(s => s.role === roleToProcess).map(x => x.invitation);

            const allInvitations = invitationItems.concat(additionalInvitationItems);

            if (workspaceParticipants && workspaceParticipants.some(s => s.subscriberId === subscriberId && s.workspaceRole.id === roleToProcess)) {
              // invitation list has two/duplicated {subscriberId}
              return this.createError({ message: DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE });
            }

            const invitationsByRole: InvitationItemModel[] = allInvitations.filter(
              (invitation: InvitationItemModel) => invitation.subscriber.id === subscriberId && !invitation.inviteRoleAsSelf
            );

            if (invitationsByRole.length > 1) {
              // invitation list has two/duplicated {subscriberId}
              return this.createError({ message: DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE });
            }

            // 3. if subscriber id is not empty, check self duplication
            if (createdByRoleId === roleToProcess && currentSubscriber.subscriberId === subscriberId) {
              // you have already invite yourself to this role
              return this.createError({ message: DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE });
            }

            return true;
          }
        )
        .defined(),
      name: yup.mixed(),
      elnoId: yup.mixed(),
      abn: yup.string()
    })
    .defined();

  return yup
    .object<InvitationItemModel>({
      subscriber: validateWhenVisible2<SubscriberSearchItemModel>({
        visibilityCheck: (parent: InvitationItemModel) => {
          return parent.inviteRoleAsSelf === false;
        },
        validationWhenVisible: yupSubscriber
      }),
      inviteRoleAsSelf: yup
        .boolean()
        .test('inviteRoleAsSelf', 'check inviteRoleAsSelf', function test(this: yup.TestContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>) {
          const values = this.options.context!;
          const { additionalInvitations } = values;
          const idxMatch = this.path.match(/\[\d\]+/g);
          if (!Array.isArray(idxMatch) || idxMatch.length === 0 || !idxMatch) {
            return false;
          }
          const numberOfIdxMatch = Number(idxMatch[0].replace(/\D/g, ''));

          // the add more roles can be exceed the backend returned value
          if (numberOfIdxMatch >= values.invitations.length) {
            return false;
          }
          let roleToProcess = values.invitations[numberOfIdxMatch].role!;
          if (additionalInvitation) {
            if (numberOfIdxMatch >= values.additionalInvitations.length) {
              return false;
            }
            roleToProcess = values.additionalInvitations[numberOfIdxMatch].role!;
          }
          if (this.originalValue) {
            // if get invited subscribers
            const { invitations } = values;
            const additionalInvitationItems = additionalInvitations.filter(s => s.role === roleToProcess).map(additionalInvitation => additionalInvitation.invitation);
            let invitationItems = invitations.filter(s => s.role === roleToProcess).map(x => x.invitation);
            const allInvitations = invitationItems.concat(additionalInvitationItems);
            const invitationsByRole: InvitationItemModel[] = allInvitations.filter(
              (invitation: InvitationItemModel) => invitation.subscriber.id === currentSubscriber.subscriberId || invitation.inviteRoleAsSelf
            );

            if (
              (workspaceParticipants.length
                ? workspaceParticipants.some(s => s.workspaceRole.id === roleToProcess && s.subscriberId === currentSubscriber.subscriberId)
                : createdByRoleId === roleToProcess) ||
              invitationsByRole.length > 1
            ) {
              return this.createError({ message: DUPLICATE_INVITED_PARTICIPANT_ERROR_MESSAGE });
            }
          }

          return true;
        })
        .defined(),
      invitationDescription: yup
        .string()
        .max(
          INVITATION_DESCRIPTION_MAX_CHARACTER,
          ({ max, value }) => ` ${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${value.length}/${max} characters (including spaces and formatting)`
        ),
      groupId: validateWhenVisible2<string>({
        visibilityCheck: (parent: InvitationItemModel) => {
          return Boolean(parent.inviteRoleAsSelf);
        },
        validationWhenVisible: yup.string().required(msg.REQUIRED)
      }),
      matterNumber: validateWhenVisible2<string>({
        visibilityCheck: (parent: InvitationItemModel) => {
          return Boolean(parent.inviteRoleAsSelf);
        },
        validationWhenVisible: yup
          .string()
          .required(msg.REQUIRED)
          .max(MATTER_NUMBER_MAX_CHARACTER, ({ max }) => msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max))
      })
    })
    .defined();
}

export function getAdditionalInviteValidationSchema(
  createdByRoleId: HttpTypes.WorkspaceRoleEnum | string,
  currentSubscriber: HttpTypes.UserProfileModel,
  additionalParticipantRoleConfigs: Partial<Record<HttpTypes.WorkspaceRoleEnum, AdditionalParticipantRoleConfig>>,
  workspaceParticipants: HttpTypes.WorkspaceParticipant[] = []
): yup.Schema<InvitationModel> {
  const getAllowedMaxParticipant = (role: HttpTypes.WorkspaceRoleEnum) => additionalParticipantRoleConfigs[role]?.numberOfParticipantsAllowed;

  const getDealingName = (role: HttpTypes.WorkspaceRoleEnum) => {
    switch (role) {
      case HttpTypes.WorkspaceRoleEnum.DischargingCaveator:
        return 'caveats';
      case HttpTypes.WorkspaceRoleEnum.DischargingMortgagee:
        return 'mortgages';
    }
  };

  const getErrorMessage = (role: HttpTypes.WorkspaceRoleEnum, isProposedWorkspaceCreatorRole: boolean) => {
    // need to take into account if the role was selected on the previous screen
    return `The number of ${getDealingName(role)} registered on the title(s) is ${
      isProposedWorkspaceCreatorRole ? getAllowedMaxParticipant(role)! + 1 : getAllowedMaxParticipant(role)
    }, additional participants in ${FinancialRoleMapping[role]} role are not required.`;
  };

  return yup
    .object<InvitationModel>({
      invitation: getInviteValidationSchema(createdByRoleId, currentSubscriber, true, workspaceParticipants),
      role: yup
        .mixed<HttpTypes.WorkspaceRoleEnum>()
        .test('role', 'invalid role selected', function test(this: yup.TestContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>, value?: HttpTypes.WorkspaceRoleEnum):
          | boolean
          | yup.ValidationError {
          if (!value || getAllowedMaxParticipant(value) === undefined) return true;
          const additionalInvitation: InvitationModel = this.parent;
          const parentForm: CreateFinancialWorkspaceInviteParticipantsStepFormModel = this.options.context!;
          const additionalInvitationsMatchingRole = parentForm.additionalInvitations.filter(invitation => invitation.role === value);
          const index = additionalInvitationsMatchingRole.findIndex(invitation => invitation.id === additionalInvitation.id);
          const additionalInvitations = additionalInvitationsMatchingRole.slice(0, index + 1);
          const isProposedWorkspaceCreatorRole = createdByRoleId === value;
          const rolesCount = parentForm.invitedRoles[value] ? additionalInvitations.length + 1 : additionalInvitations.length;
          if (rolesCount > getAllowedMaxParticipant(value)!) {
            return this.createError({ message: getErrorMessage(value, isProposedWorkspaceCreatorRole) });
          }
          return true;
        }),
      id: yup.mixed()
    })
    .defined();
}

export default getValidationSchema;
