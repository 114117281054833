import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { actionFetchFinancialAccounts } from '../actions/financialAccounts';

const fetchDebitAccountsFeed = () => http.get<HttpTypes.FinancialAccountsApiResponse>('/settings/financial-accounts');

function* sagaFetchFinancialAccounts() {
  try {
    const data = yield* call(fetchDebitAccountsFeed);
    yield put(actionFetchFinancialAccounts.success({ data }));
  } catch (error) {
    yield put(actionFetchFinancialAccounts.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchFinancialAccounts.request, sagaFetchFinancialAccounts)
];
