import { NswNameChange, nswPartyNameChangeConfig } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TENANCY_COMPONENT_LABEL_CONFIG, TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import { NAME_SUFFIX_LOOKUP_OPTIONS, NameSuffixEnum } from '@sympli-mfe/enums-2-21/nsw';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';

import { DeceasedTenancyContextData, PartyCapacityLookup } from '../../components/deceased-tenancy-detail/context/DeceasedTenancyContext';
import { DeceasedTenancyPartyCapacityEnum } from '../../components/deceased-tenancy-detail/enums';
import { formatNewName } from '../../helpers';
import { BENEFICIARY_DEVISEE_NEXT_OF_KIN_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS } from './enums';

// this file was automatically generated from config.ts.mustache
// Define any form-specific configuration in this file
export const NSW_TAB_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...NSW_PARTY_FORM_CONFIG
};

export const NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE: PartyFormConfig<NswNameChange> = {
  ...NSW_PARTY_FORM_CONFIG,
  nameChangeConfig: { ...nswPartyNameChangeConfig, formatNewName }
};

export const NSW_TAB_GRANTEE_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...NSW_TAB_PARTY_FORM_CONFIG,
  individualPartyFormConfig: {
    ...NSW_TAB_PARTY_FORM_CONFIG.individualPartyFormConfig,
    nameSuffixLookupEnum: restrictedLookupOptions(NAME_SUFFIX_LOOKUP_OPTIONS, { blacklist: [NameSuffixEnum.Minor] })
  }
};

export const NSW_TAB_RECEIVING_TENANCY_LABEL_CONFIG: TenancyComponentLabelConfig = {
  ...TENANCY_COMPONENT_LABEL_CONFIG,
  partyRole: 'Applicant'
};

const TAB_PARTY_CAPACITY_LOOKUPS: PartyCapacityLookup[] = [
  DeceasedTenancyPartyCapacityEnum.Beneficiary,
  DeceasedTenancyPartyCapacityEnum.Devisee,
  DeceasedTenancyPartyCapacityEnum.NextOfKin
].map((capacity: DeceasedTenancyPartyCapacityEnum) => {
  return {
    capacity,
    evidenceTypes: BENEFICIARY_DEVISEE_NEXT_OF_KIN_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS
  };
});

export const NSW_TAB_DECEASED_TENANCY_CONTEXT_CONFIG: DeceasedTenancyContextData = new DeceasedTenancyContextData(TAB_PARTY_CAPACITY_LOOKUPS);
