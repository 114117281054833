import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionLodgementOrderSummary from '@sympli-mfe/document-forms-framework/components/sections/lodgement-order-summary';
import SectionResponsibleSubscriber from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Subform from '@sympli/ui-framework/components/formik/subform';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import {
  VISIBILITY_CHECK_LODGEMENT_ORDER_SUMMARY,
  VISIBILITY_CHECK_RESPONSIBLE_SUBSCRIBER
} from 'src/containers/documents/scaffolded-form/shared/lodgement-instructions/visibilityChecks';
import { useRouterParams } from 'src/hooks';
import { WorkspacePageRouteParams } from 'src/pages/workspace/models';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { LodgementInstructions2_21_1Model } from './models';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<LodgementInstructions2_21_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel, setNextActionDisabled, nextActionDisabled } = useDocumentContext();
  const { isDndMode } = useDndContext();
  const formikProps = useFormikContext<LodgementInstructions2_21_1Model>();
  const { values, dirty } = formikProps;
  const { participants, isResponsibleParticipant, currentParticipantId, responsibleParticipantId } = values;
  const focusSubscriberPartyRef = React.useRef<ButtonBaseActions>(null);
  const participantOptions = participants?.map(({ id, role, subscriber }) => ({ id: id, name: `${subscriber?.legalName} (${role})` })) ?? [];

  const { workspaceId, participantId } = useRouterParams<WorkspacePageRouteParams>();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  useEffect(() => {
    if (!isResponsibleParticipant) {
      setNextActionDisabled(!dirty || currentParticipantId !== responsibleParticipantId);
    }
  }, [currentParticipantId, dirty, isResponsibleParticipant, responsibleParticipantId, setNextActionDisabled]);

  useEffect(() => {
    if (participantOptions.length > 1 && focusSubscriberPartyRef.current) {
      focusSubscriberPartyRef.current.focusVisible();
      return;
    }
  }, [participantOptions.length]);

  return (
    <>
      <Form className={className}>
        {VISIBILITY_CHECK_RESPONSIBLE_SUBSCRIBER(values) && (
          <SectionResponsibleSubscriber //
            name={fieldName('responsibleParticipantId')}
            options={participantOptions}
            focusRef={focusSubscriberPartyRef}
            disabled={isResponsibleParticipant}
          />
        )}

        {VISIBILITY_CHECK_LODGEMENT_ORDER_SUMMARY(values) && (
          <Subform
            subformBindingPath={fieldName('lodgementOrderSummary')}
            component={SectionLodgementOrderSummary}
            title="Lodgement order"
            canRearrangeLodgementInstructions={workspaceDetailState.detail?.canRearrangeLodgementInstructions}
          />
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled || isDndMode || nextActionDisabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
