import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { testValueReuseInArray } from '@sympli-mfe/document-forms-framework/utils';
import msg from '@sympli/ui-framework/utils/messages';

import { CaveatModel, CaveatParty, ServiceOfNoticeDetails } from '../../models';
import { testUnaffectedProvider } from '../party-book/test-unaffected-provider';

type FormValues = CaveatModel;

const yupContactItem = yup
  .object<CaveatParty>()
  .nullable(true)
  .shape({
    // party: yupNSWDocumentParty
    // address: yupNSWDeliveryAddress
    partyBookId: yup // reference to party store
      .string()
      .default('')
      .nullable(true)
      .required(msg.REQUIRED)
      .test(
        'PARTY ALREADY SELECTED CHECK',
        'Cannot have multiple contacts with the same name',
        testValueReuseInArray(
          (model: FormValues) => model.detailsForServiceOfNotice?.contacts!,
          contact => contact.partyBookId
        )
      )
      .test('Referenced party validity check', 'Party is invalid', testUnaffectedProvider)
      .test(createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG)),
    addressBookId: yup // reference to address store
      .string()
      .default('')
      .nullable(true)
      .required(msg.REQUIRED)
  });

// path: detailsForServiceOfNotice
export default yup
  .object<ServiceOfNoticeDetails>()
  .nullable(true)
  .shape({
    contacts: yup
      .array<CaveatParty>() //
      .required(msg.REQUIRED)
      .min(1, msg.MIN_ITEMS(1))
      .max(2, msg.MAX_ITEMS(2))
      .of(yupContactItem)
  });
