import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

// Map Workspace Type
export const workspaceTypeMapping: Partial<Record<HttpTypes.WorkspaceTypeEnum, string>> = {
  [HttpTypes.WorkspaceTypeEnum.FinancialSettlement]: 'Financial settlement',
  [HttpTypes.WorkspaceTypeEnum.RegistrationOnly]: 'Standalone'
};

export interface CreateWSDefaultValueModel {
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  createdByRoleId: HttpTypes.WorkspaceRoleEnum;
}

export interface DocumentRoleMappingLookupModel extends LookupEnumModel<HttpTypes.DocumentTypeIdentifierEnum> {
  roleIds: HttpTypes.WorkspaceRoleEnum[];
  validationMessage?: string;
}

export interface DocumentRoleMappingResponse {
  documentRoles: Array<{
    //
    documentFormIdentifier: HttpTypes.DocumentTypeIdentifierEnum;
    documentDescription: string;
    workspaceRoles: HttpTypes.WorkspaceRoleEnum[];
    validationMessage?: string;
  }>;
}

export interface TitleReferenceErrorItem {
  titleReference: string;
  message: string;
}

export interface CreateWorkspaceInvitationRequestItemModel {
  subscriberId?: string;
  roleId: HttpTypes.WorkspaceRoleEnum;
  reference?: string;
  groupId?: string;
  elnoId?: string;
  elnoSubscriberId?: string;
  displayName: string;
  invitationDescription?: string;
  abn: string;
}

export interface SharedInitialValues {
  createdByReference: string;
  groupId: string;
  instructingOrganisationSettlementAgentId: string;
}

export type WorkspaceCreationErrorsMap = Record<TitleReferenceErrorItem['titleReference'], TitleReferenceErrorItem['message']>;

export interface AllowedAdditionalParticipantRole {
  dealings: Dealing[];
  noParticipantLimit: boolean;
  numberOfParticipantsAllowed: number;
  workspaceRole: HttpTypes.WorkspaceRoleEnum;
  documentType?: HttpTypes.DocumentTypeIdentifierEnum;
  compatibleWorkspaceRoles: HttpTypes.WorkspaceRoleEnum[];
}

interface Dealing {
  dealingNumber?: string;
  parties: LookupItemModel[];
}

export enum RepresentationSubscriberTypeEnum {
  Other = 0,
  Represented = 1,
  SelfRepresented = 2
}
