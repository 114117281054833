import { Middleware } from 'redux';
import { ActionMeta } from 'redux-actions';

import { AxiosError } from 'src/utils/http';
import { actionCreateGlobalErrorMessage } from '../actions/globalErrors';

export class ActionStore {
  private static actions: Array<ActionMeta<any, any>> = [];

  public static popAll() {
    const actions = this.actions;
    this.actions = [];
    return actions;
  }
  public static pop() {
    return this.actions.pop();
  }
  public static push(action: ActionMeta<any, any>) {
    if (action == null) {
      if (!import.meta.env.PROD) {
        throw new Error('Push undefined Action');
      }
      return;
    }
    this.actions.push(action);
  }
}
export interface GeneralSagasErrorModel {
  error: AxiosError;
  skipGlobalErrorHandling?: boolean;
  redirectOnClose?: string;
  title?: string;
}

const errorHandleMiddleware = store => next => (action: ActionMeta<GeneralSagasErrorModel, any>) => {
  // let the action go through always so if there is anything else listening to it, it can be executed
  const result = next(action);

  const { type, payload, error: isErrorAction, meta } = action;
  if (typeof type !== 'string') {
    if (!import.meta.env.PROD) {
      throw new Error('Dispatch action error! Action type is not string.');
    }
    return result;
  }

  // we track all actions that are marked as ERROR (Temp solution, may apply "error: true" to all error action)
  if (isErrorAction || type.endsWith('_ERROR')) {
    // For type safety, in case dispatch error action without payload
    if (payload == null) {
      return result;
    }
    if (payload.skipGlobalErrorHandling) {
      return result;
    }
    switch (typeof payload) {
      case 'object':
        // payload here includes error object from axios promise (dispatch from saga).
        const { error, redirectOnClose, title } = payload;
        store.dispatch(actionCreateGlobalErrorMessage({ ...error, redirectOnClose, title }, meta));
        break;
      default:
        if (!import.meta.env.PROD) {
          throw new Error('Unsupported error type');
        }
        break;
    }
  }
  return result;
};

export default errorHandleMiddleware as Middleware;
