import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { sdk } from 'src/utils/http';
import {
  actionFetchGroupDetails,
  actionFetchGroupDistributionsWorkflow,
  actionFetchGroupDocumentsWorkflow,
  actionFetchGroupLodgement,
  actionFetchGroupsFeed,
  actionFetchSubscriberTrustAccounts,
  GroupDetailsApiRequest
} from '../actions/groups';

const fetchGroupsFeed = (): Promise<HttpTypes.SubscriberGroupListApiResponse> => {
  // return http.get<HttpTypes.SubscriberGroupListApiResponse>('/settings/groups');
  return sdk.settings.group.list();
};

function* sagaFetchGroupsFeed() {
  try {
    const data = yield* call(fetchGroupsFeed);

    yield put(actionFetchGroupsFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupsFeed.failure({ error }));
  }
}

const fetchGroupsDetail = (groupId: GroupDetailsApiRequest): Promise<HttpTypes.SubscriberGroupApiResponse> => {
  // return http.get<HttpTypes.SubscriberGroupApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}`);
  return sdk.settings.group.retrieve(groupId);
};

function* sagaFetchGroupsDetail(action: ReturnType<typeof actionFetchGroupDetails.request>) {
  try {
    const data = yield* call(fetchGroupsDetail, action.payload);

    yield put(actionFetchGroupDetails.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDetails.failure({ error }));
  }
}

const fetchSubscriberTrustAccounts = (): Promise<HttpTypes.SettingsTrustAccountListApiResponse> => {
  // return http.get<HttpTypes.SettingsTrustAccountListApiResponse>(`/settings/trust-accounts`);
  return sdk.settings.trustAccount.list();
};

function* sagaFetchSubscriberTrustAccounts(action: ReturnType<typeof actionFetchSubscriberTrustAccounts.request>) {
  try {
    const data = yield* call(fetchSubscriberTrustAccounts);

    yield put(actionFetchSubscriberTrustAccounts.success({ data }));
  } catch (error) {
    yield put(actionFetchSubscriberTrustAccounts.failure({ error }));
  }
}

const fetchGroupDocumentsWorkflow = (groupId: GroupDetailsApiRequest): Promise<HttpTypes.GroupDocumentsWorkflowApiResponse> => {
  // return http.get<HttpTypes.GroupDocumentsWorkflowApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupDocumentWorkflow`);
  return sdk.settings.group.retrieveDocumentWorkflowSettings(groupId);
};

function* sagaFetchGroupDocumentsWorkflow(action: ReturnType<typeof actionFetchGroupDocumentsWorkflow.request>) {
  try {
    const data = yield* call(fetchGroupDocumentsWorkflow, action.payload);

    yield put(actionFetchGroupDocumentsWorkflow.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDocumentsWorkflow.failure({ error }));
  }
}

const fetchGroupDistributionsWorkflow = (groupId: GroupDetailsApiRequest): Promise<HttpTypes.GroupDistributionsWorkflowApiResponse> => {
  // return http.get<HttpTypes.GroupDistributionsWorkflowApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupDistributionsWorkflow`);
  return sdk.settings.group.retrieveDistributionWorkflowSettings(groupId);
};

function* sagaFetchGroupDistributionsWorkflow(action: ReturnType<typeof actionFetchGroupDistributionsWorkflow.request>) {
  try {
    const data = yield* call(fetchGroupDistributionsWorkflow, action.payload);

    yield put(actionFetchGroupDistributionsWorkflow.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupDistributionsWorkflow.failure({ error }));
  }
}

const fetchGroupLodgement = (groupId: GroupDetailsApiRequest): Promise<HttpTypes.GroupLodgementPreferencesApiResponse> => {
  // return http.get<HttpTypes.GroupLodgementPreferencesApiResponse>(`/settings/groups/${encodeURIComponent(groupId)}/groupLodgementPreferences`);
  return sdk.settings.group.retrieveLodgementSettings(groupId);
};

function* sagaFetchGroupLodgement(action: ReturnType<typeof actionFetchGroupLodgement.request>) {
  try {
    const data = yield* call(fetchGroupLodgement, action.payload);

    yield put(actionFetchGroupLodgement.success({ data }));
  } catch (error) {
    yield put(actionFetchGroupLodgement.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchGroupsFeed.request, sagaFetchGroupsFeed),
  takeLatest(actionFetchGroupDetails.request, sagaFetchGroupsDetail),
  takeLatest(actionFetchSubscriberTrustAccounts.request, sagaFetchSubscriberTrustAccounts),
  takeLatest(actionFetchGroupDocumentsWorkflow.request, sagaFetchGroupDocumentsWorkflow),
  takeLatest(actionFetchGroupDistributionsWorkflow.request, sagaFetchGroupDistributionsWorkflow),
  takeLatest(actionFetchGroupLodgement.request, sagaFetchGroupLodgement)
];
