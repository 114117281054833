import { Action, createReducer } from 'typesafe-actions';

import {
  //
  actionCloseGlobalSimpleNotification,
  actionOpenGlobalSimpleNotification,
  GlobalNotificationActionPayload
} from '../actions/globalSimpleNotification';

type GlobalSimpleNotificationState = GlobalNotificationActionPayload & {
  open: boolean;
};
const initialState: GlobalSimpleNotificationState = {
  open: false,
  autoHideDuration: 3000,
  message: ''
};

const globalSimpleNotification = createReducer<
  //
  GlobalSimpleNotificationState,
  Action
>(initialState)
  .handleAction(
    actionOpenGlobalSimpleNotification,
    (state, action): GlobalSimpleNotificationState => ({
      ...state,
      ...action.payload,
      open: true
    })
  )
  .handleAction(actionCloseGlobalSimpleNotification, (): GlobalSimpleNotificationState => ({ ...initialState }));

export default globalSimpleNotification;
