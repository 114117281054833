import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import Consideration from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { generateTransferors } from './helpers';
import { Transfer2_21_1Model } from './models';
import DocumentAttachments from './sections/document-attachments';
import FinalTenancyHoldings from './sections/final-tenancy-holdings';
import TitleReferences from './sections/title-references';
import Transferees from './sections/transferees';
import Transferors from './sections/transferors';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<Transfer2_21_1Model>();
function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<Transfer2_21_1Model>();
  const {
    setValues,
    values: { transferors }
  } = formikProps;

  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const anyPartialShareTransferred =
    transferors?.relinquishingProprietorGroups.some(group => group.isTransacting && group.shareTransferred === ShareTransferredTypeEnum.Partial) ?? false;
  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues(values => {
        generateTransferors(values);
        return values;
      });
    },
    [setValues]
  );

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
          onChange={handleSelectedTitleReferenceChange}
        />

        <Transferors //
          name={fieldName('transferors')}
        />

        <Consideration //
          name={fieldName('consideration')}
          jurisdiction={HttpTypes.JurisdictionsEnum.NSW}
        />

        <Transferees //
          name={fieldName('transferees')}
        />

        {anyPartialShareTransferred && (
          <FinalTenancyHoldings //
            name={fieldName('finalTenancyHoldings')}
          />
        )}

        <DocumentAttachments //
          name={fieldName('documentAttachments')}
        />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
