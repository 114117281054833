import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { actionCloseLodgementVerificationDialog, actionOpenLodgementVerificationDialog, actionOpenLodgementVerificationDialogWithContext } from './actions';

export interface LodgementVerificationDialogState {
  isOpen: boolean;
  authorityMessageId?: string;
  authorityComplianceReportType?: HttpTypes.AuthorityComplianceReportTypeEnum;
  lodgementCaseId?: string;
}

const initialState: LodgementVerificationDialogState = {
  isOpen: false,
  authorityMessageId: undefined,
  authorityComplianceReportType: undefined
};

const lodgementVerificationDialogReducer = createReducer<
  //
  LodgementVerificationDialogState,
  Action
>(initialState)
  .handleAction(
    actionOpenLodgementVerificationDialogWithContext,
    (_, action): LodgementVerificationDialogState => ({
      ...initialState,
      ...action.payload,
      isOpen: true
    })
  )
  .handleAction(
    actionOpenLodgementVerificationDialog,
    (): LodgementVerificationDialogState => ({
      ...initialState,
      isOpen: true
    })
  )
  .handleAction(actionCloseLodgementVerificationDialog, (): LodgementVerificationDialogState => {
    return {
      ...initialState,
      isOpen: false
    };
  });

export default lodgementVerificationDialogReducer;
