import React from 'react';

import classNames from 'classnames';

import { HttpTypes } from '@sympli/api-gateway/types';
import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';

import { getColumns } from './helper';
import { ActiveWorkspaceItem, SearchJoinTableContext } from './models';
import { useTableStyles } from './styles';

const SearchJoinTable = ({
  //
  rows,
  workspaceType,
  isLoading,
  onRowClick,
  role
}: {
  rows: ActiveWorkspaceItem[];
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  isLoading?: boolean;
  onRowClick?(rowIndex: number, rowData: ActiveWorkspaceItem): void;
  role?: HttpTypes.WorkspaceRoleEnum;
}) => {
  const classes = useTableStyles({ workspaceType });
  const tableContext = React.useMemo<SearchJoinTableContext>(() => ({ workspaceType, role }), [workspaceType, role]);

  const columns: ColumnsModel<ActiveWorkspaceItem> = React.useMemo(
    () =>
      getColumns({
        workspaceType,
        role
      }),
    [workspaceType, role]
  );

  return (
    <Table //
      className={classNames(classes.root)}
      context={tableContext}
      columns={columns}
      rows={rows}
      isLoading={isLoading}
      onRowClick={onRowClick}
      noFooter
    />
  );
};

export default React.memo(SearchJoinTable);
