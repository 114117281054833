import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSafeDispatch } from '../../hooks';
import { actionRemoveGlobalErrorById, ErrorMessageQueueItemModel } from '../../store/actions/globalErrors';
import useStoreSelector from '../../store/useStoreSelector';
import GlobalDialog from './GlobalDialog';
import GlobalSnackbar from './GlobalSnackbar';

function GlobalNotificationContainer() {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const error: ErrorMessageQueueItemModel | undefined = useStoreSelector(store => store.globalErrors.queue[0]);
  const snackQueue: ErrorMessageQueueItemModel[] = useStoreSelector(store => store.globalErrors.snackQueue);

  const handleSnackbarOnClose = React.useCallback(
    (id: string) => {
      dispatch(actionRemoveGlobalErrorById({ id, snackbar: true }));
    },
    [dispatch]
  );

  return (
    <>
      <GlobalDialog //
        dispatch={dispatch}
        error={error}
        navigate={navigate}
      />
      <GlobalSnackbar //
        items={snackQueue}
        onClose={handleSnackbarOnClose}
      />
    </>
  );
}

export default React.memo(GlobalNotificationContainer);
