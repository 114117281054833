import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';
import sharedStyles from '../../../edit-directions/styles';

const styles = (theme: Theme) => ({
  ...sharedStyles(theme),
  ...createStyles({
    deleteButton: {
      position: 'absolute',
      left: -10,
      width: 48, //.MuiIconButton-root has removed the default 48 width
      '&.Mui-disabled': {
        fill: theme.palette.action.disabled,
        color: theme.palette.action.disabled
      }
    },
    flexGrow: {
      flexGrow: 1
    },
    marginLeft: {
      marginLeft: 3
    },
    amountColPlus: {
      width: '16%',
      textAlign: 'center'
    },
    newRecordText: {
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    },
    showDetailsButtonLink: {
      color: colors.NEUTRAL_500,
      paddingLeft: 0
    },
    breakdownLineItemsColOne: {
      width: '55%',
      paddingLeft: 20,
      paddingRight: 9
    }
  })
});

export type ClassKeys = typeof styles;

export default styles;
