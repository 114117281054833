import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { TrustAccountEnhancedDetails } from './models';

export const trustAccountDetailsSelector = createSelector<
  //
  HttpTypes.TrustAccountDetails,
  HttpTypes.TrustAccountDetails,
  TrustAccountEnhancedDetails
>(
  (data: HttpTypes.TrustAccountDetails) => data,
  (data: HttpTypes.TrustAccountDetails) => ({
    trustAccountId: data.id,
    jurisdictionIds: data.jurisdictions,
    contactPerson: data.contactPerson,
    trustAccountDetail: {
      accountDescription: data.accountDescription,
      bsbNumber: data.bsbNumber,
      bankName: data.bankName,
      branchLocation: data.branchLocation,
      accountName: data.accountName,
      accountNumber: data.accountNumber
    },
    signingRule: data.signingRule,
    trustAccountSignatories: data.signatories?.length
      ? {
          trustAccountSignatoriesDetails: data.signatories
        }
      : {
          trustAccountSignatoriesDetails: []
        },
    trustAccountOwners: data.owners?.length
      ? {
          accountOwners: data.owners
        }
      : {
          accountOwners: []
        },
    status: HttpTypes.TrustAccountRequestStatusEnum.Approved
  })
);

export const trustAccountRequestDetailsSelector = createSelector<
  //
  HttpTypes.TrustAccountRequestDetailsApiResponse,
  HttpTypes.TrustAccountRequestDetailsApiResponse,
  TrustAccountEnhancedDetails
>(
  (data: HttpTypes.TrustAccountRequestDetailsApiResponse) => data,
  (data: HttpTypes.TrustAccountRequestDetailsApiResponse) => ({
    trustAccountId: data.trustAccountId,
    trustAccountRequestId: data.id,
    jurisdictionIds: data.jurisdictions,
    contactPerson: data.contactPerson,
    trustAccountDetail: {
      accountDescription: data.accountDescription,
      bsbNumber: data.bsbNumber,
      bankName: data.bankName,
      branchLocation: data.branchLocation,
      accountName: data.accountName,
      accountNumber: data.accountNumber
    },
    signingRule: data.signingRule,
    trustAccountSignatories: data.signatories?.length
      ? {
          trustAccountSignatoriesDetails: data.signatories
        }
      : {
          trustAccountSignatoriesDetails: []
        },
    trustAccountOwners: data.owners?.length
      ? {
          accountOwners: data.owners
        }
      : {
          accountOwners: []
        },
    status: data.status,
    type: data.type || HttpTypes.TrustAccountRequestTypeEnum.NewAccount
  })
);
