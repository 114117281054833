import * as React from 'react';

import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useProfile } from 'src/@core/store/reducers/profile';
import { actionFetchLinkedSettlementDetail } from 'src/store/actions/workspace';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import SettlementDateReviewForm from './SettlementDateReviewForm';

import type { SafeDispatch } from 'src/hooks';

interface OwnProps {
  // for return link
  workspaceId: string;
  participantId: string;
  jurisdictionId: HttpTypes.JurisdictionsEnum;

  isAcceptedByUser: boolean;
  settlementAcceptedByAll: boolean;
  proposedByParticipant?: HttpTypes.WorkspaceParticipant;
  settlementDateProposalId?: string;
  proposedSettlementDate?: string;
  localProposedSettlementDate?: string;
  reasonText?: string;
  workspaceSettlementDate?: string;

  onRedirect?: (message: string) => void;
  dispatch: SafeDispatch<Action>;
}

function SettlementDateReviewFormContainer(props: OwnProps) {
  const { workspaceId, participantId, dispatch } = props;
  const clusterId = useWorkspaceDetail(workspaceId, participantId).detail?.linkedWorkspaceCluster?.clusterId;
  const profileState = useProfile();

  React.useEffect(() => {
    if (clusterId) {
      dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId }));
    }
  }, [dispatch, clusterId, workspaceId]);

  return <SettlementDateReviewForm {...props} clusterId={clusterId} userId={profileState.data!.userId} subscriberId={profileState.data!.subscriberId} />;
}

export default React.memo(SettlementDateReviewFormContainer);
