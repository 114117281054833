import * as React from 'react';

import classNames from 'classnames';
import { differenceInMilliseconds } from 'date-fns';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
/* eslint-disable no-restricted-imports */
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { colors } from 'src/theme';
import { VerticalStatusLineVariant } from 'src/utils/status-mapping/workspaceStatusHelper';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '13px',
      '@media (max-width:1536px)': {
        lineHeight: '12px'
      },
      textTransform: 'uppercase'
    },
    rootExtraContent: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '13px',
      '@media (max-width:1536px)': {
        lineHeight: '12px'
      },
      position: 'relative'
    },
    rightPanel: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 12
    },
    text: {
      color: colors.NEUTRAL_800
    },
    statusLine: {
      width: 3,
      height: 9,
      borderRadius: 2,
      marginRight: 4,
      flexShrink: 0
    },
    statusLineExtraContent: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 6,
      marginRight: 6
    },
    statusLineVariant: {
      '&.ok': {
        background: colors.GREEK_WATERS
      },
      '&.warning': {
        background: colors.SUNNY_DAY
      },
      '&.error': {
        background: colors.WATERMELON
      },
      '&.neutral': {
        background: '#BFC1C3'
      }
    }
  }),
  {
    name: 'VerticalStatusLine'
  }
);

export interface VerticalStatusLineProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  extraContent?: React.ReactNode;
  text: string | React.ReactNode;
  variant: VerticalStatusLineVariant;
  textClassName?: string;
  extraContentClassName?: string;
  tooltipHandlers?: {
    onMouseEnter(event: React.MouseEvent<HTMLElement>): void;
    onMouseLeave(event: React.MouseEvent<HTMLElement>): void;
  };
  tooltipTitle?: NonNullable<React.ReactNode>;
  tooltipOtherProps?: Omit<MuiTooltipProps, 'title' | 'children'>;
  dataTestId?: string;
}

function VerticalStatusLine({
  //
  text,
  variant,
  className,
  textClassName,
  tooltipHandlers,
  tooltipTitle,
  tooltipOtherProps,
  extraContent,
  extraContentClassName,
  dataTestId,
  ...rest
}: VerticalStatusLineProps) {
  const classes = useStyles();

  const openTimeRef = React.useRef<Date | null>(null);

  const handleOnClose = () => {
    const now = new Date();
    const ms: number = differenceInMilliseconds(now, openTimeRef.current || now);
    const seconds = ms / 1000;
    if (seconds >= 1) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'variant-progress-MLC',
        logGroupId: 'workspace',
        text,
        variant: variant,
        seconds: ms / 1000
      });
    }
  };

  const handleOnOpen = () => {
    openTimeRef.current = new Date();
  };

  if (extraContent) {
    return (
      <Box
        //
        {...rest}
        className={classNames(classes.rootExtraContent, className)}
        data-testid={dataTestId}
      >
        <div className={classNames(classes.statusLineExtraContent, classes.statusLineVariant, variant)} />
        <div className={classNames(classes.rightPanel, extraContentClassName)}>
          {extraContent}
          {typeof text === 'string' ? (
            <div className={classNames(classes.text, 'uppercase', textClassName)}>{text}</div> //
          ) : (
            <>{text}</>
          )}
        </div>
      </Box>
    );
  }

  return (
    <Tooltip {...tooltipOtherProps} title={tooltipTitle} tooltipType="document" onClose={handleOnClose} onOpen={handleOnOpen}>
      <Box
        //
        {...rest}
        className={classNames(classes.root, className)}
        data-testid={dataTestId}
      >
        <div className={classNames(classes.statusLine, classes.statusLineVariant, variant)} />
        <div className={classNames(classes.text, textClassName)} {...tooltipHandlers}>
          {text}
        </div>
      </Box>
    </Tooltip>
  );
}

export default VerticalStatusLine;
