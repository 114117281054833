import { HttpTypes } from '@sympli/api-gateway/types';

import { useWorkspaceDetail } from '../../../../../store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from '../../../../../store/reducers/workspace/workspaceParticipants';

type AdditionalOptionType = 'archivedOnly' | 'reassignOnly' | 'withdraw' | 'abandon';

export function useAdditionalOptionType(
  workspaceId: string,
  participantId: string,
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum,
  lodgementCases: HttpTypes.LodgementCase[]
): AdditionalOptionType {
  const workspaceParticipants = useWorkspaceParticipants(workspaceId).items;

  // if there are more than 1 participant that accepts the invite and hasn't withdrawn or abandoned, this should be withdraw
  const isWithdraw =
    workspaceParticipants.filter(participant => !participant.archivedStatus && participant.participantStatus.id === HttpTypes.ParticipantStatusEnum.Accepted).length > 1;

  // for iop there is a chance there is no lc, we will use default one if there is no lc
  // for financial ws we will use PLC, otherwise take the first one
  const anyResponsibleLodgementCaseLocked = lodgementCases.some(d => d.responsibleParticipantId === participantId && d.isLocked);

  if (anyResponsibleLodgementCaseLocked) {
    return 'reassignOnly';
  }

  if (isWithdraw) {
    return 'withdraw';
  }

  return 'abandon';
}

// we are combine the link settlement & link lodgement function together
export function useCanLinkWorkspace({
  workspaceId,
  participantId,
  workspaceTypeId
}: {
  workspaceId: string;
  participantId: string;
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
}): {
  showLink: boolean;
  showUnlink: boolean;
} {
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId).detail;

  const defaultLinkage = {
    showLink: false,
    showUnlink: false
  };

  if (!workspaceDetail) {
    return defaultLinkage;
  }

  const { isLinkedSettlementEnabled, isLinkedLodgementEnabled, isUnlinkLodgementEnabled } = workspaceDetail;

  if (workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.FinancialSettlement) {
    return defaultLinkage;
  }

  return {
    showLink: isLinkedSettlementEnabled || isLinkedLodgementEnabled,
    showUnlink: isUnlinkLodgementEnabled
  };
}
