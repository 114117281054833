import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Form24A_2_24_1Model } from './models';

export const qldForm24AFallbackMap: FallbackMap<Form24A_2_24_1Model> = {
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  trustNames: NO_FALLBACK,
  transfereeDetails: {
    $children: {
      transferees: {
        $shouldAddDefaultItemToEmptyArray: false,
        $arrayItem: {
          $children: {
            transferee: {
              $children: {
                isSelected: NO_FALLBACK,
                partyBookId: NO_FALLBACK,
                addressBookId: [''],
                partyCapacity: NO_FALLBACK
              }
            },
            isForeignPersonOrCorp: [null],
            isResident: [null]
          }
        }
      },
      phone: [''],
      postalAddressId: [''],
      email: [null],
      trustName: [null]
    }
  },
  transactionDetails: {
    $children: {
      settlementDate: NO_FALLBACK,
      isPossessionDateSameAsSettlement: [false],
      possessionDate: [null]
    }
  }
};
