import { HttpTypes } from '@sympli/api-gateway/types';

import { AuthProfileState, useProfile } from 'src/@core/store/reducers/profile';

export default function useUserPermission(requiredPermission: HttpTypes.UserLevelPermissionEnum): boolean {
  const userProfileState: AuthProfileState = useProfile();

  if (userProfileState.status !== 'resolved') {
    return false;
  }

  const isUserPermissionAllowed = userProfileState.data!.userPermissions.includes(requiredPermission);

  return isUserPermissionAllowed;
}
