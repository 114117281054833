import dateFormat from 'dateformat';
import queryString from 'query-string';

import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { OsrCaseStatusEnum, StampDutyApiResponse, StampDutyProgressStatusEnum } from './models';

export function resolveStampDutyLink(params: WorkspaceDetailRouteParams) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);

  return `/workspace/${encodeURIComponent(workspaceId)}/stamp-duty?${query}`;
}

export function resolveVerificationTimestampDescription(detail: StampDutyApiResponse | undefined) {
  if (detail == null) {
    return null;
  }
  const { status, verificationTimestamp } = detail;

  switch (status) {
    case StampDutyProgressStatusEnum.Verified:
    case StampDutyProgressStatusEnum.Paid:
      return `Verified on ${dateFormat(verificationTimestamp, "dd mmm yyyy, 'at' h:MM TT")}`;
    case StampDutyProgressStatusEnum.VerificationError:
      return `Last attempted on ${dateFormat(verificationTimestamp, "dd mmm yyyy, 'at' h:MM TT")}`;
    default:
      return null;
  }
}

// currently it

export const OsrCaseStatusDisplayMapping: Record<OsrCaseStatusEnum, string> = {
  [OsrCaseStatusEnum.WaitingForClaim]: 'Waiting for Claim',
  [OsrCaseStatusEnum.Claimed]: 'Claimed',
  [OsrCaseStatusEnum.ReadyToSubmit]: 'Ready To Submit',
  [OsrCaseStatusEnum.Submitted]: 'Submitted',
  [OsrCaseStatusEnum.Allocated]: 'Allocated',
  [OsrCaseStatusEnum.Returned]: 'Returned',
  [OsrCaseStatusEnum.ReadyToCertify]: 'Ready to Certify',
  [OsrCaseStatusEnum.CertifiedAndReadyToSettle]: 'Certified and Ready to Settle',
  [OsrCaseStatusEnum.Assessed]: 'Assessed',
  [OsrCaseStatusEnum.Cancelled]: 'Cancelled',
  [OsrCaseStatusEnum.Finalised]: 'Finalised',
  [OsrCaseStatusEnum.Completed]: 'Completed',
  [OsrCaseStatusEnum.RequestToCancel]: 'Request to Cancel',
  [OsrCaseStatusEnum.ExtendSettlementDate]: 'Extend Settlement Date',
  [OsrCaseStatusEnum.Review]: 'Review'
};

export const TransactionCreatedOsrCaseStatus: OsrCaseStatusEnum[] = [
  OsrCaseStatusEnum.WaitingForClaim,
  OsrCaseStatusEnum.Claimed,
  OsrCaseStatusEnum.ReadyToSubmit,
  OsrCaseStatusEnum.Submitted,
  OsrCaseStatusEnum.Allocated,
  OsrCaseStatusEnum.Returned,
  OsrCaseStatusEnum.ReadyToCertify,
  OsrCaseStatusEnum.Assessed,
  OsrCaseStatusEnum.RequestToCancel,
  OsrCaseStatusEnum.ExtendSettlementDate,
  OsrCaseStatusEnum.Review
];
