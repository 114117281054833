import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import MessageNotification from 'src/@core/components/message-notification';

export interface Props {
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  className?: string;
  style?: React.CSSProperties;
}

const jurisdictionMessageMapping: Partial<Record<HttpTypes.JurisdictionsEnum, string>> = {
  [HttpTypes.JurisdictionsEnum.NSW]: 'Please lodge your documents between 6:00am - 11:59pm.',
  [HttpTypes.JurisdictionsEnum.QLD]: 'Please lodge your documents on business days between 8:30am - 4:30pm.',
  [HttpTypes.JurisdictionsEnum.SA]: 'Please lodge your documents on business days between 9:00am - 5:00pm.',
  [HttpTypes.JurisdictionsEnum.WA]: 'Please lodge your documents on business days between 8:30am - 4:30pm.'
  // VIC is 24/7
};

function OutsideOperationHoursMessageBox({
  //
  jurisdictionId,
  className,
  style
}: Props) {
  if (![HttpTypes.JurisdictionsEnum.NSW, HttpTypes.JurisdictionsEnum.QLD, HttpTypes.JurisdictionsEnum.SA, HttpTypes.JurisdictionsEnum.WA].includes(jurisdictionId)) {
    return null;
  }

  const primary = `The ${HttpTypes.JurisdictionsEnum[jurisdictionId]} land registry office is currently closed.`;

  return (
    <MessageNotification //
      variant="warning"
      primary={primary}
      secondary={jurisdictionMessageMapping[jurisdictionId]}
      classOverride={className}
      style={style}
    />
  );
}

export default OutsideOperationHoursMessageBox;
