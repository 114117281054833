import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch } from '../../hooks';
import useStoreSelector from '../../store/useStoreSelector';
import AuthenticationDialog from './MfaAuthenticationDialog';

function MfaAuthenticationDialogContainer() {
  const dispatch = useSafeDispatch(useDispatch());

  const { mfaId, required: showDialog } = useStoreSelector(store => store.mfaCheck);

  return (
    <AuthenticationDialog //
      dispatch={dispatch}
      showDialog={showDialog}
      mfaId={mfaId}
    />
  );
}

export default React.memo(MfaAuthenticationDialogContainer);
