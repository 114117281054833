import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceDocuments } from 'src/store/reducers/workspace/workspaceDocuments';
import TitlesAndAddressesBase from '../titles-and-addresses-base';
import { TitlesAndAddressesBaseProps } from '../titles-and-addresses-base/TitlesAndAddressesBase';
import AddTitleDialog from './AddTitleDialog';

interface Props extends TitlesAndAddressesBaseProps {
  onCancel(): void;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
}

export default function TitlesAndAddressesAddContainer(props: Props) {
  const navigate = useNavigate();
  const { workspaceId, participantId, onCancel } = props;
  const { items: workspaceDocuments } = useWorkspaceDocuments(workspaceId, participantId);
  const allDocuments = workspaceDocuments.flatMap(d => d.supportingDocuments ?? []).concat(workspaceDocuments);
  const documentTypes = allDocuments.map(x => x.documentForm.documentType);
  const dispatch = useSafeDispatch(useDispatch());

  return (
    <>
      <TitlesAndAddressesBase {...props} dataTestId="title-list-add" />
      <AddTitleDialog //
        {...props}
        handleCancel={onCancel}
        dispatch={dispatch}
        existingDocumentTypes={documentTypes}
        navigate={navigate}
      />
    </>
  );
}
