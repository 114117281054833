import React from 'react';

import _isEqual from 'lodash-es/isEqual';
import { useDispatch } from 'react-redux';

import { ISignResponse } from '@sympli/digital-signing/interfaces';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { DialogTypeEnum } from 'src/@core/components/global-notification';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { getUserInfo, logUserSigning, resolveSigningErrorMessage, setDefaultSigningCertificateId } from 'src/utils/signing/helpers';
import SignButton from '../sign-button';
import { actionGetCertificates, actionResetCertificates, actionUpdateSelectedCertificate } from './actions';
import CertificateSelectionDialog from './components/certificate-selection-dialog';
import { SelectedCertificateModel } from './components/certificate-selection-dialog/models';
import { OnSignArgs } from './models';
import { SigningProviderState, useSigningProviderReducer } from './useSigningProviderReducer';

/**
 * Certificate listing is now triggered upon clicking on the sign button
- when user lands on signing page we DO NOT automatically fetch
  certificates
- when user clicks on sign button, we start certificate fetching process
  in case when:
    a) we have never fetched certificates
    b) previous certificate listing failed
    c) signing process failed
    d) user profile has been updated
- during the certificate fetching process we also reach out to local
  storage for previously selected default certificate and verify whether
  this certificate is still between those that had been newly returned
  by signing library (see sagas.ts).
- if no certificates had been found, we display an error.
- assuming some certificates had been found, we store these and
  information about previously selected default certificate (if any)
  in redux.
- signing provider will display certificate selection dialog if redux
  does not contain previously selected default certificate.
- user can mark one of the listed certificate as default, but we don't
  store this information anywhere until the whole signing process has
  completed with success.
- on the other hand, if redux contains info about previously selected
  certificate, we will use it without displaying certificate selection
  dialog.
- before final signing is triggered, confirmation dialog is shown.
  User can confirm to continue with signing or exit. If user decided
  to exit, the information about selected certificate won't be
  preserved. Therefore if user decides to click on sign button again,
  we will trigger certificate fetching process from the beginning.
- assuming signing was successful and user wanted to save this
  certificate as their default for the future, we store this
  information in redux and local storage.
- if signing failed for whatever reason, we explicitly remove selected
  certificate from redux and local storage.
  If user tries to sign again, we will trigger certificate fetching
  process again.

 */
interface Props<T extends { signingInfo: ISignResponse['signingInfo'] }> {
  initialState?: SigningProviderState<T>;
  isLoading?: boolean;
  disabled?: boolean;
  // ! onReadyToSign & onPostSign have to be memoized otherwise post sign effect will be triggered multiple times
  /**
   * @description This method has to be memoized
   */
  onReadyToSign: (readyToSignCallback: () => void) => void;

  /**
   * @description This method has to be memoized
   */
  onSign: (args: OnSignArgs) => Promise<T>;

  /**
   * @description This method has to be memoized
   */
  onPostSign: (signedData: T) => Promise<void> | void;

  preStartSigning?: () => Promise<void> | void;

  mode?: 'light' | 'dark';

  label?: React.ReactNode;

  variant?: 'contained' | 'outlined';

  showArrow?: boolean;
}

function SigningProvider<T extends { signingInfo: ISignResponse['signingInfo'] }>({
  //
  initialState,
  isLoading,
  disabled,
  onReadyToSign,
  preStartSigning,
  onSign,
  onPostSign,
  children,
  label = 'Sign',
  mode,
  variant,
  showArrow
}: React.PropsWithChildren<Props<T>>) {
  const userProfile = useProfile().data!;
  const {
    // certificate marked by the user in the passed as the default certificate
    // this will only be present if the certificate is still installed on users machine (see ./sagas.ts - getCertificates)
    previouslySavedCertIdentifier,
    // list of certificates return from signing library
    certificatesToSelect,
    // status of the listing progress
    status: certificateListingStatus,
    error: certificateListingError,
    userInfo: certificatesUserInfo
  } = useStoreSelector(store => store.certificates); /* danger:disable */
  const {
    //
    status,
    isSigning,
    signedData,
    dispatch,
    signError,
    // certificate that was selected by the user from the list or automatically set when certificate listing dialog was not shown
    // because we have received previouslySavedCertIdentifier
    certificateSelectedForSigning,
    shouldRememberCertSelection,
    logGroupId // WEB-27575
  } = useSigningProviderReducer<T>(initialState);
  const reduxDispatch = useSafeDispatch(useDispatch());

  // TODO Currently the signing app version api is not implemented. So we don't show the update dialog
  // TODO Once the api is added, we should turn this back on
  // * Update dialog section
  // const [openUpdateDialog, setOpenUpdateDialog] = React.useState(!!userProfile.latestAppVersion);
  // const handleCloseUpdateDialog = React.useCallback(
  //   (daysUntilNextReminder: number) => {
  //     if (daysUntilNextReminder > 0) {
  //       postponeSigningAppUpdateReminder(daysUntilNextReminder, userProfile);
  //     }

  //     setOpenUpdateDialog(false);
  //   },
  //   [userProfile]
  // );
  // const updateDialog = (
  //   <SigningMiddlewareUpdateDialog //
  //     open={openUpdateDialog}
  //     version={userProfile.latestAppVersion}
  //     onClose={handleCloseUpdateDialog}
  //   />
  // );

  // * Challenge code dialog section for Fortify users
  // const [challengeCode, setChallengeCode] = React.useState<string | undefined>(undefined);
  // const handleOpenChallengeCodeDialog = React.useCallback(
  //   (challengeCode: string) => {
  //     Logger.capturePageAction(PageActionEnum.DocumentSigning, {
  //       step: 'open-challenge-code',
  //       logGroupId
  //     });

  //     setChallengeCode(challengeCode);
  //   },
  //   [logGroupId]
  // );

  // const handleCloseChallengeCodeDialog = React.useCallback(() => {
  //   Logger.capturePageAction(PageActionEnum.DocumentSigning, {
  //     step: 'close-challenge-code',
  //     logGroupId
  //   });

  //   setChallengeCode(undefined);
  // }, [logGroupId]);

  const handleFailure = React.useCallback(
    (error: Error) => {
      // we want to explicitly force the user to select certificate in case of any error
      setDefaultSigningCertificateId(userProfile.userId);
      reduxDispatch(actionResetCertificates());
      // send log to backend
      logUserSigning({
        //
        isSuccessful: false,
        logGroupId
      });
      // capture in NR
      Logger.capturePageAction(PageActionEnum.DocumentSigning, {
        step: 'error',
        message: error.message,
        logGroupId
      });
    },
    [reduxDispatch, userProfile, logGroupId]
  );

  // * Sign button section
  const startSigning = React.useCallback(() => {
    const logGroupId = Date.now().toString(); // WEB-27575 - always generate new group id
    Logger.capturePageAction(PageActionEnum.DocumentSigning, {
      step: 'sign-button-click',
      logGroupId
    });

    Promise.resolve(preStartSigning?.()).then(() => {
      // we need to refetching the certificate list if user info updated eg: name
      // if we have never fetched certificates before or previous certificate listing failed or later the signing itself failed for whatever reason,
      // we will try to re-fetch certificates in case user installs a new certificate
      if (certificateListingStatus === 'idle' || certificateListingStatus === 'rejected' || signError || !_isEqual(getUserInfo(userProfile), certificatesUserInfo)) {
        reduxDispatch(
          actionGetCertificates.request({
            userProfile,
            logGroupId // WEB-27575
          })
        );
      }

      dispatch({ type: 'start', logGroupId });
    });
  }, [
    //
    preStartSigning,
    userProfile,
    certificatesUserInfo,
    dispatch,
    reduxDispatch,
    certificateListingStatus,
    signError
  ]);

  // * Certificate selection dialog section
  const [openTooltip, setOpenTooltip] = React.useState(false);
  // handle for closing certificate selection dialog without choosing anything
  const handleCertificateSelectionClose = React.useCallback(() => {
    Logger.capturePageAction(PageActionEnum.DocumentSigning, {
      step: 'certificate-selection-dialog',
      action: 'cancel',
      logGroupId
    });

    // * When certificate selection dialog is closed without selecting a cert, reset the signing state
    dispatch({ type: 'reset' });
    setOpenTooltip(false);
  }, [dispatch, logGroupId]);

  const handleCertificateSelectionConfirm = React.useCallback(
    ({ setDefault: shouldRememberCertSelection, id: certificateSelectedForSigning }: SelectedCertificateModel) => {
      Logger.capturePageAction(PageActionEnum.DocumentSigning, {
        step: 'certificate-selection-dialog',
        action: 'submit',
        data: {
          //
          setDefault: shouldRememberCertSelection,
          certSerialNumber: certificateSelectedForSigning.certSerialNumber
        },
        logGroupId
      });

      // set current selection
      dispatch({
        //
        type: 'select-certificate',
        certificateSelectedForSigning,
        shouldRememberCertSelection
      });
      // close the dialog
      setOpenTooltip(false);
    },
    [dispatch, logGroupId]
  );
  // we can rely on certificateListingStatus here as it will be resolved only if some certificates had been found
  // we explicitly don't want to display dialog if user has previously selected certificate
  // please note logic in sagas.ts where we make sure that previouslySavedCertIdentifier is one of the currently installed certificates
  const shouldRenderCertificateSelectionDialog = certificateListingStatus === 'resolved' && !previouslySavedCertIdentifier;

  React.useEffect(() => {
    // when user clicked on sign button in workflow panel, status will change to started
    if (status === 'started') {
      // if we failed to fetch user certificates, we stop the signing process
      // saga takes care of displaying global error
      if (certificateListingStatus === 'rejected') {
        dispatch({ type: 'failure' }); // just stop the signing process, we don't need to store error to local state
        // reset the global state (certificateListingStatus will become 'idle') so we don't have any potential racing condition when we click sign again
        reduxDispatch(actionResetCertificates());
        return;
      }

      // if certificate listing was successful
      if (certificateListingStatus === 'resolved' && !certificateSelectedForSigning) {
        // explicitly make sure that user did not make selection yet
        // since we will render tooltip only if there is no certificate selected
        // we will call this conditionally
        if (shouldRenderCertificateSelectionDialog) {
          dispatch({ type: 'list-certificates' });
          Logger.capturePageAction(PageActionEnum.DocumentSigning, {
            step: 'certificate-selection-dialog',
            action: 'open',
            logGroupId
          });
          setOpenTooltip(true);
        } else {
          // otherwise we are not rendering dialog for selecting certificate
          // and we know that user had previously selected and saved some certificate
          // so we will use this certificate as the one that should be used
          dispatch({ type: 'select-certificate', certificateSelectedForSigning: previouslySavedCertIdentifier! });
        }
      }
    }
  }, [
    certificateListingStatus,
    dispatch,
    certificateListingError,
    reduxDispatch,
    status,
    shouldRenderCertificateSelectionDialog,
    previouslySavedCertIdentifier,
    certificateSelectedForSigning,
    logGroupId
  ]);

  React.useEffect(() => {
    // * User has selected the certificate
    if (status === 'certificate-selected') {
      const readyToSignCallback = () => {
        Logger.capturePageAction(PageActionEnum.DocumentSigning, {
          step: 'ready-to-sign',
          action: 'confirm',
          logGroupId
        });

        dispatch({ type: 'confirm' });
      };

      Logger.capturePageAction(PageActionEnum.DocumentSigning, {
        step: 'ready-to-sign',
        action: 'start',
        logGroupId
      });

      // * We need to provide onReadyToSign handler
      // * because it's on fortify to wait for the user to click on their dialog in order to confirm that displayed challenge codes are matching
      onReadyToSign(readyToSignCallback);
    }
  }, [dispatch, onReadyToSign, status, logGroupId]);

  React.useEffect(() => {
    if (status === 'confirmed') {
      if (signedData) {
        // * If user has already triggered signing process and signedData exists, don't sign again
        // this will for example happen when postSign step has failed
        // and since we already have signed data by library, we don't need to go through that part again
        dispatch({ type: 'sign' });
      } else {
        const sign = async () => {
          Logger.capturePageAction(PageActionEnum.DocumentSigning, {
            step: 'signing-start',
            logGroupId
          });

          dispatch({ type: 'signing-start' });

          try {
            const data: T = await onSign({
              userInfo: getUserInfo(userProfile),
              certificateIdentifier: certificateSelectedForSigning!, // at this point user must have selected a certificate
              // openChallengeCodeDialog: handleOpenChallengeCodeDialog,
              // closeChallengeCodeDialog: handleCloseChallengeCodeDialog,
              logGroupId
            });
            dispatch({ type: 'sign', signedData: data });
          } catch (error) {
            dispatch({ type: 'failure', error }); // explicitly store the error so we know that we need to refetch certificate if user tries to sign again
            handleFailure(error);
            reduxDispatch(
              actionCreateGlobalErrorMessage({
                ...error,
                type: DialogTypeEnum.Error,
                title: 'Signing failed.',
                message: resolveSigningErrorMessage(error)
              })
            );
          }
        };

        sign();
      }
    }
  }, [
    dispatch,
    reduxDispatch,
    onSign,
    // handleOpenChallengeCodeDialog,
    // handleCloseChallengeCodeDialog,
    signedData,
    status,
    userProfile,
    previouslySavedCertIdentifier,
    certificateSelectedForSigning,
    handleFailure,
    logGroupId
  ]);

  React.useEffect(() => {
    if (status === 'signed' && signedData) {
      Logger.capturePageAction(PageActionEnum.DocumentSigning, {
        step: 'post-sign-start',
        signingInfo: signedData.signingInfo, // log information about signing tool that was used
        logGroupId
      });

      dispatch({ type: 'post-sign-start' });

      // remember it in redux as well, so when we move to another document we can use
      if (shouldRememberCertSelection) {
        // remember selection in redux and local storage since we know this certificate is working successfully
        // it's important to store it in redux now because the user can decide to unsign the document and try
        // signing again in which case we don't want to ask him for cert selection again.
        setDefaultSigningCertificateId(userProfile.userId, certificateSelectedForSigning);
        reduxDispatch(actionUpdateSelectedCertificate(certificateSelectedForSigning));
      }

      Promise.resolve(onPostSign(signedData as T))
        .then(() => {
          Logger.capturePageAction(PageActionEnum.DocumentSigning, {
            step: 'done',
            logGroupId
          });

          logUserSigning({
            //
            isSuccessful: true,
            signingInfo: signedData.signingInfo,
            logGroupId
          });
          dispatch({ type: 'complete' });
        })
        .catch(error => {
          dispatch({ type: 'post-sign-failure', error }); // explicitly store the error so we know that we need to refetch certificate if user tries to sign again
          handleFailure(error);
          reduxDispatch(actionCreateGlobalErrorMessage(error));
        });
    }
  }, [
    dispatch,
    handleFailure,
    onPostSign,
    reduxDispatch,
    signedData,
    status,
    // userProfile.latestAppVersion,
    shouldRememberCertSelection,
    userProfile.userId,
    certificateSelectedForSigning,
    logGroupId
  ]);

  // * Render section
  return (
    <>
      {/* {updateDialog} */}
      {/* <ChallengeCodeDialog //
        challengeCode={challengeCode}
        open={!!challengeCode}
        onClose={handleCloseChallengeCodeDialog}
      /> */}
      {children}
      {shouldRenderCertificateSelectionDialog && (
        <CertificateSelectionDialog
          certificatesToSelect={certificatesToSelect!}
          onClose={handleCertificateSelectionClose}
          onConfirm={handleCertificateSelectionConfirm}
          open={openTooltip}
        />
      )}
      <SignButton //
        onClick={startSigning}
        isLoading={isSigning || isLoading}
        disabled={isSigning || disabled || isLoading}
        mode={mode}
        variant={variant}
        showArrow={showArrow}
      >
        {label}
      </SignButton>
    </>
  );
}

export default React.memo(SigningProvider);
