import * as React from 'react';

import { Form, FormikProps, FormikState } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { FormikPostSubmitArgs, runValidationSchema, TickleFormikProps } from '@sympli/ui-framework/components/formik';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import Formik from 'src/components/formik';
import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import { JsonSchemaRootModel } from './models';
import { DefinitionToJsx, SchemaParser } from './modules';
import { mergeObjects, resolveDefaultValues } from './modules/data';
import { ParsedFieldDefinition } from './modules/models';
import { getYupDefinition } from './modules/validation';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  schema: JsonSchemaRootModel;
  //
  data: object;
  isLoading: boolean;
  disabled: boolean;
  nextActionLabel: string;
  //
  onSave(data: object, formikProps: FormikProps<any>): Promise<any>;
  onCancel(): void;
  onRenderForm(formikProps: FormikProps<any>): void;
  onPostSubmit: (args: FormikPostSubmitArgs<any, any>) => void | Promise<any>;
  //
  onBlockingActionInProgress?: (inProgress: boolean) => void;
  queryParams?: DocumentsPageRouteAndQueryModel;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  internalActionInProgress: boolean;
  resolvedFieldDefinitions: Array<ParsedFieldDefinition>;
  schema?: JsonSchemaRootModel;
}

class JsonSchemaForm extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    internalActionInProgress: false,
    resolvedFieldDefinitions: JsonSchemaForm.getFieldsDefinition(this.props.schema)
  };

  private d2jsx: DefinitionToJsx;
  // private formikValues: object;

  private formikProps?: FormikProps<any>;

  private get isLoading() {
    return this.props.isLoading || this.formikProps?.isSubmitting || false;
  }

  private get disabled() {
    return this.props.disabled || this.isLoading || this.state.internalActionInProgress;
  }

  private get scrollToErrorProps(): TickleFormikProps<any>['scrollToError'] {
    const { identifier: documentType, state: jurisdiction, version: schemaVersion } = this.props.schema;

    return {
      // additional data that will be logged whenever validation occurs on invisible field
      resolveLogScope: () => ({
        //
        jurisdiction,
        documentType,
        schemaVersion,
        documentViewerType: 'FormGenerator'
      })
    };
  }

  constructor(props: Props) {
    super(props);

    const { classes, schema, queryParams } = props;

    this.d2jsx = new DefinitionToJsx({
      classes,
      schema,
      onBlockingActionInProgress: this.handleOnBlockingActionInProgress,
      disableAllInputFields: this.disabled,
      queryParams
    });
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { schema: nextSchema } = nextProps;
    const { schema: prevSchema } = prevState;

    if (nextSchema !== prevSchema) {
      return {
        schema: nextSchema,
        resolvedFieldDefinitions: JsonSchemaForm.getFieldsDefinition(nextSchema)
      };
    }
    return null;
  }

  static getFieldsDefinition(schema: JsonSchemaRootModel) {
    return new SchemaParser(schema).getFieldDefinitions();
  }

  private getInitialValues() {
    const { data: formikValues } = this.props;

    const schemaResolvedValues = resolveDefaultValues(this.state.resolvedFieldDefinitions, _cloneDeep(formikValues));
    // console.log('schemaResolvedValues', _cloneDeep(schemaResolvedValues));
    //
    // console.log('backendValues', _cloneDeep(formikValues));
    const initialValues = mergeObjects(schemaResolvedValues, formikValues);
    // console.log('merged', initialValues);
    return initialValues;
  }

  private getValidationSchema() {
    const validationSchema = getYupDefinition(this.state.resolvedFieldDefinitions);
    // schema resolved validation
    return validationSchema;
  }

  render() {
    return (
      <Formik //
        initialValues={this.getInitialValues()}
        validationSchema={this.getValidationSchema()}
        onPreSubmit={this.handleOnPreSubmit}
        onSubmit={this.handleSubmit}
        onPostSubmit={this.handlePostSubmit}
        scrollToError={this.scrollToErrorProps}
        // debounceValidation={true} // improve performance ---comment out not available in upgrade version yet
      >
        {(formikProps: FormikProps<any>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private renderFieldDefinitions(formikProps: FormikProps<any>) {
    return this.d2jsx.getJsx({ formikProps, fields: this.state.resolvedFieldDefinitions });
  }

  private renderForm = (formikProps: FormikProps<any>) => {
    const { classes, onRenderForm, nextActionLabel } = this.props;

    this.formikProps = formikProps;
    onRenderForm(formikProps);

    return (
      <Form className={classes.root}>
        {this.renderFieldDefinitions(formikProps)}
        {nextActionLabel && (
          <WizardStepper //
            disabled={this.disabled}
            backLabel="Cancel"
            onBack={this.handleOnBackClick}
            nextLabel={nextActionLabel}
            isLoading={this.isLoading}
          />
        )}
      </Form>
    );
  };

  private handleOnPreSubmit = (values: object, formikProps: FormikProps<object>) => {
    // console.log('before cleanup', values);
    const cleanedValues = this.d2jsx.cleanValues({
      values,
      fields: this.state.resolvedFieldDefinitions
    });
    // console.log('after cleanup', cleanedValues);
    return cleanedValues;
  };

  private handleSubmit = (values: any, formikProps: FormikProps<any>) => {
    // received values are now clean, the invisible fields had been reset to their _meta.invisibleValue
    // we now need to run the final validation that may potentially show new errors

    // we reset the values and run the validation
    formikProps.setFormikState((prevState: FormikState<any>) => ({ ...prevState, values }));
    return runValidationSchema(values, this.getValidationSchema())
      .then(() => this.props.onSave(values, formikProps))
      .catch(errors => {
        formikProps.setFormikState((prevState: FormikState<any>) => ({ ...prevState, errors }));
        // propagate error to Formik so we don't trigger post submit
        throw new Error('Validation failed');
      });

    // comment out the below as the callback method is not fired after formik upgrade, need to re-visit this
    // https://github.com/jaredpalmer/formik/pull/398
    // formikProps.setFormikState(
    //   (prevState: FormikState<any>) => ({ ...prevState, values }),
    //   () => {
    //     runValidationSchema(values, this.getValidationSchema())
    //       .then(() => {
    //         this.props.onSave(values, formikProps);
    //       })
    //       .catch(errors => {
    //         formikProps.setFormikState((prevState: FormikState<any>) => ({ ...prevState, isSubmitting: false, errors }));
    //       });
    //   }
    // );
  };

  private handlePostSubmit = (args: FormikPostSubmitArgs<any, any>) => this.props.onPostSubmit(args);

  private handleOnBlockingActionInProgress = (inProgress: boolean) => {
    const { onBlockingActionInProgress } = this.props;
    if (typeof onBlockingActionInProgress === 'function') {
      this.props.onBlockingActionInProgress!(inProgress);
    }

    this.setState({ internalActionInProgress: inProgress });
  };

  private handleOnBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.onCancel();
  };
}

export default withStyles(styles)(JsonSchemaForm);
