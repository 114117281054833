import { HttpTypes } from '@sympli/api-gateway/types';

import { SourceFundFormikModel } from 'src/containers/workspace/financial/directions/models';

export const NEW_SOURCEFUND: SourceFundFormikModel = {
  isEditorOpen: true,
  trustAccountId: '',
  amount: '' as any,
  reference: '',
  category: '',
  categoryOther: '',
  subscriberName: '',
  participantId: '',
  accountType: HttpTypes.BankAccountTypeEnum.SympliSourceAccount,
  paymentMethod: HttpTypes.PaymentMethodEnum.BankTransfer
};
