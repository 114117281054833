import _uniqueId from 'lodash-es/uniqueId';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export enum TrustAccountSetupStepEnum {
  AccountDetails = 1,
  Signers = 2,
  Owners = 3,
  Review = 4,
  FormUpload = 5
}

export const TrustAccountNewRequestStepsEnum: TrustAccountSetupStepEnum[] = [
  TrustAccountSetupStepEnum.AccountDetails,
  TrustAccountSetupStepEnum.Signers,
  TrustAccountSetupStepEnum.Owners,
  TrustAccountSetupStepEnum.Review,
  TrustAccountSetupStepEnum.FormUpload
];

export const TrustAccountAddSignersSteps: TrustAccountSetupStepEnum[] = [
  //
  TrustAccountSetupStepEnum.Signers,
  TrustAccountSetupStepEnum.Review,
  TrustAccountSetupStepEnum.FormUpload
];

export const TrustAccountRequestTypeMapping: Record<HttpTypes.TrustAccountRequestTypeEnum, string> = {
  [HttpTypes.TrustAccountRequestTypeEnum.NewAccount]: 'New account',
  [HttpTypes.TrustAccountRequestTypeEnum.AddSignatories]: 'Add signers'
};

export interface AccountDetail {
  bsbNumber: string;
  accountNumber: string;
  accountDescription: string;
  accountName: string;
  bankName: string;
  branchLocation?: string;
}

export interface TrustAccountEnhancedDetails {
  trustAccountRequestId?: string;
  jurisdictionIds: HttpTypes.JurisdictionsEnum[];
  contactPerson: HttpTypes.TrustAccountContact;
  trustAccountDetail: AccountDetail;
  trustAccountOwners: HttpTypes.TrustAccountOwners;
  signingRule: HttpTypes.TrustAccountSigningRuleEnum;
  trustAccountSignatories: {
    trustAccountSignatoriesDetails: HttpTypes.TrustAccountRequestSignatoryDetails[];
  };
  trustAccountId?: string;
  status: HttpTypes.TrustAccountRequestStatusEnum;
  type?: HttpTypes.TrustAccountRequestTypeEnum;
}

interface SigningRuleItem extends LookupEnumModel<HttpTypes.TrustAccountSigningRuleEnum> {}

export const SigningRulesOptions: SigningRuleItem[] = [
  {
    id: HttpTypes.TrustAccountSigningRuleEnum.OneSigner,
    name: '1 signer required'
  },
  {
    id: HttpTypes.TrustAccountSigningRuleEnum.TwoSigners,
    name: '2 signers required'
  },
  {
    id: HttpTypes.TrustAccountSigningRuleEnum.ImposedSigningLimit,
    name: 'Imposed signing limits'
  }
];

export const DEFAULT_TRUST_ACCOUNT_DETAILS: TrustAccountEnhancedDetails = {
  jurisdictionIds: [],
  contactPerson: {
    email: '',
    fullName: '',
    work: ''
  },
  trustAccountDetail: {
    accountDescription: '',
    bsbNumber: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
    branchLocation: ''
  },
  trustAccountOwners: {
    accountOwners: []
  },
  signingRule: HttpTypes.TrustAccountSigningRuleEnum.OneSigner,
  trustAccountSignatories: {
    trustAccountSignatoriesDetails: []
  },
  status: HttpTypes.TrustAccountRequestStatusEnum.NotSubmitted,
  type: HttpTypes.TrustAccountRequestTypeEnum.NewAccount
};

export const DEFAULT_ACCOUNT_OWNER: HttpTypes.TrustAccountOwner = {
  fullName: '',
  email: '',
  workPhone: '',
  jobTitle: ''
};

export const PORTAL_ID_FOR_TRUST_ACCOUNT_SETTING = _uniqueId('portal-trust-account-settings');
