import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class TrustAccount {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves a NON paginated list of trust accounts. It sends a request to the
   * `/settings/trust-accounts`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns List of existing trust accounts.
   *
   * @example
   * To retrieve list of existing trust accounts:
   *
   * ```ts
   * sdk.settings.trustAccount.list()
   * .then((trustAccounts) => {
   *   console.log(trustAccounts)
   * })
   * ```
   */
  async list(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsTrustAccountListApiResponse>( //
      `/settings/trust-accounts`,
      {
        ...config
      }
    );
  }
}
