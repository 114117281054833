import * as React from 'react';

import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';

interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
  labelConfig: TenancyComponentLabelConfig;
}

function Lessees({ name, focusRef, labelConfig }: Props) {
  return (
    <SectionTenancyReceiving
      //
      name={name}
      focusRef={focusRef}
      componentLabels={labelConfig}
      partyFormConfig={NSW_LEASE_PARTY_FORM_CONFIG}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      disablePartyEditConfig={disablePartyFieldEditConfig()}
    />
  );
}

export default React.memo(Lessees);
