import * as React from 'react';

import Box from '@mui/material/Box';

import { convertWorkspaceStatusColorIntoVerticalStatusLineColor } from 'src/containers/dashboard/shared/formatters/helpers';
import VerticalStatusLine, { VerticalStatusLineProps } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import Breadcrumbs from 'src/containers/workspace/shared/components/workspace-status-rebuild/breadcrumbs';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { ProgressStep, StatusProgressModel } from './models';
import { useStyles } from './styles';

interface Props {
  currentProgress: StatusProgressModel | null;
  steps: ProgressStep[];
  verticalStatusLineProps?: VerticalStatusLineProps;
  dataTestId?: string;
}

function LodgementCaseStatus({ currentProgress, steps, verticalStatusLineProps, dataTestId }: Props) {
  const classes = useStyles();
  const screenVariant: ScreenSizeVariant = useScreenSize();

  if (!currentProgress) {
    return null;
  }

  const { step, variant, tooltipContent } = currentProgress;
  const stepDescriptions = steps.map(ps => ps.description);

  const tooltipTitle = (
    <Box display="flex" flexDirection="column">
      {tooltipContent}
      <Breadcrumbs //
        currentStep={step.value}
        steps={stepDescriptions}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );

  if (verticalStatusLineProps) {
    return (
      <VerticalStatusLine //
        {...verticalStatusLineProps}
        tooltipTitle={tooltipTitle}
        tooltipOtherProps={{ classes: { popper: classes.popper } }}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <VerticalStatusLine //
      text={steps.find(x => x.value === step.value)?.description}
      variant={convertWorkspaceStatusColorIntoVerticalStatusLineColor(variant)}
      textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipTitle={tooltipTitle}
      dataTestId={dataTestId}
    />
  );
}

export default React.memo(LodgementCaseStatus);
