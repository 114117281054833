import * as React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import UserManagerProvider from 'src/@core/auth/UserManagerProvider';
import environments from 'src/@core/environments';
import logoSympli from '../../assets/logo-sympli.svg';
import { useStyles } from './styles';

const LoggedOut = () => {
  const classes = useStyles();

  const onLogIn = () => {
    // to avoid infinite loop, we need to clean up the staled token in the storage before hard reload
    UserManagerProvider.authClient.clearStorage();

    window.location.href = environments.BASENAME;
  };

  return (
    <FlexLayout justifyContent="space-around" alignItems="center" className={classes.container}>
      <Paper className={classes.paper}>
        <img className={classes.logo} src={logoSympli} alt="logo-sympli" />
        <Typography variant="subtitle1">You are now logged out.</Typography>
        <Typography className={classes.description}>If you wish to log in again, please click on the button below.</Typography>
        <SympliButton onClick={onLogIn} variant="contained" color="primary" arrowRight>
          Login
        </SympliButton>
      </Paper>
    </FlexLayout>
  );
};

export default React.memo(LoggedOut);
