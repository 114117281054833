import { NswDocumentPartyJustification, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './configs';
import { fallbackMap } from './fallback';
import { ApiNoticeOfDeathModel_2_21_2, NoticeOfDeath_2_21_2_Model } from './models';
import { convertFromApiToFormModel } from './utils/convertFromApiToFormModel';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification>({
  partyFormConfig: NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
  nameChangeConversion: nswNameChangeConversion
});

export function convertFromFormToApiModel(formValues: NoticeOfDeath_2_21_2_Model, initialApiValues: ApiNoticeOfDeathModel_2_21_2): ApiNoticeOfDeathModel_2_21_2 {
  const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook,
    partyBookApi,
    mergeFailedReason: mergeFailed,
    deceasedTenancyDetail,
    ...rest
  } = formModel;

  PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);

  const result: ApiNoticeOfDeathModel_2_21_2 = {
    ...rest,
    deceasedJointTenants: deceasedTenancyDetail,
    partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
    precedingData: {
      ...formModel.precedingData,
      dataChanged: false
    }
  };

  return result;
}

class Converter implements IConverter<NoticeOfDeath_2_21_2_Model, ApiNoticeOfDeathModel_2_21_2> {
  fromApiToFormModel(initialApiValues: ApiNoticeOfDeathModel_2_21_2, context: IConverterContext): NoticeOfDeath_2_21_2_Model {
    return convertFromApiToFormModel(initialApiValues, context);
  }
  fromFormToApiModel(values: NoticeOfDeath_2_21_2_Model, initialApiValues: ApiNoticeOfDeathModel_2_21_2): ApiNoticeOfDeathModel_2_21_2 {
    return convertFromFormToApiModel(values, initialApiValues);
  }
}

export default new Converter();
