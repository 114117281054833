import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { resolveUuid } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import IconListItem from 'src/@core/components/icon-list-item';
import { MultiUserAvatar } from 'src/components/avatars';
import { SelectedSettlementDistributionsTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import { resolveStampDutyLink } from '../../helpers';
import { StampDutyStatusTooltipContent } from './helper';
import { StampDutyStatusDisplayMapping } from './models';

type OwnProps = {
  workspaceId: string;
  participantId: string;
  isLocked?: boolean;
  selectedSettlementDistributionsType?: SelectedSettlementDistributionsTypeEnum;
  stampDutyStatus: Exclude<HttpTypes.WorkspaceDirectionsStatusApiResponse['stampDutyStatus'], undefined>;
};

function StampDutyLeftPanelStatus({ workspaceId, participantId, selectedSettlementDistributionsType, isLocked, stampDutyStatus }: OwnProps) {
  const { status, workspaceParticipants } = stampDutyStatus;

  const participants = workspaceParticipants.map(participant => ({
    name: participant.subscriberName,
    id: participant.subscriberId,
    reference: participant.reference,
    avatarUrl: participant.avatarUrl,
    workspaceRoles: participant.workspaceRoles
  }));
  const linkTo = resolveStampDutyLink({ workspaceId, participantId });
  const selected = selectedSettlementDistributionsType === SelectedSettlementDistributionsTypeEnum.StampDuty;
  const disabled = status === HttpTypes.StampDutyStatusEnum.AwaitingStampDutyDocuments;
  const currentStatus = StampDutyStatusDisplayMapping[status];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <IconListItem
        key={resolveUuid('string', 20)}
        selected={selected}
        linkTo={disabled ? undefined : linkTo}
        primary="Stamp Duty"
        secondary={
          <Tooltip title={StampDutyStatusTooltipContent(currentStatus)} tooltipType="document">
            <Typography variant="body4">{currentStatus}</Typography>
          </Tooltip>
        }
        secondaryAction={<MultiUserAvatar items={participants} placement="right-start" />}
      />
    </Box>
  );
}

export default React.memo(StampDutyLeftPanelStatus);
