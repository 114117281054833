import React, { useCallback, useState } from 'react';

import { Form, FormikHelpers, FormikProps } from 'formik';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { NewIconView } from '@sympli/ui-framework/icons';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import Formik from 'src/components/formik';
import LinkedWorkspaceSearchContainer from 'src/containers/workspace/shared/components/linked-workspace-search';
import { SearchTableModel } from 'src/containers/workspace/shared/components/linked-workspace-search/components/linked-workspace-search-table/models';
import { actionFetchLinkedLodgementDetail } from 'src/store/actions/workspace';
import { modelKey } from 'src/utils/formUtils';
import { SaveLinkLodgement } from './api';
import { LinkLodgementDialogFormikModel } from './models';
import { getLinkLodgementValidationSchema } from './validationSchema';

import type { SafeDispatch } from 'src/hooks';

export interface LinkLodgementDialogProps {
  workspaceId: string;
  participantId: string;
  workspaceRoleId?: HttpTypes.WorkspaceRoleEnum;
  onClose(): void;
  dispatch: SafeDispatch<Action>;
}

const fieldName = modelKey<LinkLodgementDialogFormikModel>();

function LinkLodgementDialog({ workspaceId, participantId, dispatch, workspaceRoleId, onClose }: LinkLodgementDialogProps) {
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClearSelectedRow = () => {
    setSelectedRow(undefined);
  };

  const handleOnSubmit = async (formikValues: LinkLodgementDialogFormikModel, _: FormikHelpers<LinkLodgementDialogFormikModel>) => {
    setIsLoading(true);
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'linked-settlement',
      logGroupId: 'workspace',
      route: 'workspace-overview',
      action: 'save-linked-lodgement',
      workspaceId,
      participantId,
      workspaceRoleId
    });

    const { linkedLodgementItem } = formikValues;

    try {
      const response = await SaveLinkLodgement(workspaceId, participantId, {
        toParticipantId: linkedLodgementItem.linkedParticipantId,
        toWorkspaceId: linkedLodgementItem.linkedWorkspaceId,
        toParticipantWorkspaceRole: linkedLodgementItem.linkedToParticipantRole
      });
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <>
              <div className="font-[700] text-[14px]">Workspace linked successfully</div>
              <div className="font-[400] text-[14px] pt-[8px]">
                This workspace <b>has been linked</b> with {response.numberOfLinkedWorkspaces} workspaces.
              </div>
              <div className="font-[400] text-[14px] pt-[8px]  pb-[12px]">
                All linked workspaces must start settling at the same time, please <b>work with participants</b> from other linked workspaces to{' '}
                <b>align the Settlement Date & Time.</b>
              </div>
            </>
          ),
          variant: 'new-success',
          autoHideDuration: 5000
        })
      );

      // fetch the link lodgement detail
      dispatch(actionFetchLinkedLodgementDetail.request({ workspaceId, clusterId: response.clusterId }));
    } catch (error) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <>
              <div className="font-[700] text-[14px]">Could not Link workspace</div>
              <div className="font-[400] text-[14px] pt-[8px]  pb-[12px]">
                <b>Workspace could not be linked at this time.</b> Please try again later.
              </div>
            </>
          ),
          variant: 'new-error',
          autoHideDuration: 5000
        })
      );
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<LinkLodgementDialogFormikModel>) => {
      const { isValid, isSubmitting, submitForm, values } = formikProps;
      const disabled = !isValid || isSubmitting || !values.linkedLodgementItem.linkedWorkspaceId || selectedRow === undefined;

      const handleOnLinkedWorkspaceRowClick = (rowIndex: number, rowData: SearchTableModel) => {
        const linkedSettlementItemField = fieldName('linkedLodgementItem');

        formikProps.setFieldValue(linkedSettlementItemField, {
          linkedWorkspaceId: rowData.workspaceId,
          linkedParticipantId: rowData.participantId,
          linkedToParticipantRole: rowData.participantRoleId
        });

        setSelectedRow(rowIndex);
      };

      return (
        <Form aria-label="link lodgement" className="mt-[32px]">
          <FlexLayout flexDirection="row" className="pt-[10px] w-full bg-[var(--greek-waters-translucent)] mb-[8px] mt-[8px]">
            <NewIconView fill="var(--sympli-green)" />
            <Typography variant="body2" className="text-[var(--neutral-600)] pl-[8px] mb-[8px]">
              <b>Note </b> that you can link up to <b>12 workspaces in total.</b> You are <b>not able to link </b>a workspace already involved in a <b>'Linked Settlement'</b>
            </Typography>
          </FlexLayout>
          <LinkedWorkspaceSearchContainer
            selectedRow={selectedRow}
            clearSelectedRow={handleClearSelectedRow}
            onRowClick={(rowIndex: number, rowData: SearchTableModel) => handleOnLinkedWorkspaceRowClick(rowIndex, rowData)}
            numberOfRowsDisplayed={5}
            type={HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement}
            workspaceRole={workspaceRoleId}
            excludeWorkspaceId={workspaceId}
          />
          <Divider className="bg-[var(--neutral-200)] mb-[8px] mt-[16px]" />
          <WizardStepper //
            disabled={disabled}
            isLoading={isLoading}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Link"
            color="primary"
            showBackIcon={false}
            showNextIcon={false}
            onNext={submitForm}
          />
        </Form>
      );
    },
    [isLoading, onClose, selectedRow, workspaceRoleId, workspaceId]
  );

  const getInitialValues = (): LinkLodgementDialogFormikModel => {
    return {
      linkedLodgementItem: {
        linkedWorkspaceId: '',
        linkedParticipantId: '',
        linkedToParticipantRole: workspaceRoleId ?? HttpTypes.WorkspaceRoleEnum.IncomingMortgagee // default value
      }
    };
  };

  return (
    <Formik //
      initialValues={getInitialValues()}
      onSubmit={handleOnSubmit}
      validationSchema={getLinkLodgementValidationSchema()}
    >
      {(formikProps: FormikProps<LinkLodgementDialogFormikModel>) => renderForm(formikProps)}
    </Formik>
  );
}

export default React.memo(LinkLodgementDialog);
