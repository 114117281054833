import { HttpTypes } from '@sympli/api-gateway/types';

import { TitleDealingsItemModel } from 'src/containers/dashboard/components/create-new-workspace/views/reg-only-form/models';

export const DOCUMENT_ADDED_MSG = 'Document has been added';

export interface AddDocumentFormikModel {
  documentType: HttpTypes.DocumentTypeIdentifierEnum | null;
}

interface AddWorkspaceDocumentBaseResponse {
  createdDocumentDetails: CreatedDocumentDetail[];
}

interface CreatedDocumentDetail {
  documentId: string;
  documentName: string;
  isSLCDocument: boolean;
  lodgementCaseName?: string;
}

type AddWorkspaceDocumentPositiveResponse = AddWorkspaceDocumentBaseResponse & {
  isSuccess: true;
  titleReferenceErrors: null; // backend will send us explicitly null in this case
};

type AddWorkspaceDocumentNegativeResponse = AddWorkspaceDocumentBaseResponse & {
  isSuccess: false;
  titleReferenceErrors: Array<TitleReferenceError>;
};

export type AddWorkspaceDocumentApiResponse = AddWorkspaceDocumentPositiveResponse | AddWorkspaceDocumentNegativeResponse;

export interface AddWorkspaceDocumentApiRequest {
  documentIdentifier: HttpTypes.DocumentTypeIdentifierEnum;
  roles: HttpTypes.WorkspaceRoleEnum[];
}

export interface TitleReferenceError {
  titleReference: string;
  message: string;
}

export interface AvailableTitleDealingsResponse {
  titleDealings: TitleDealingsItemModel[];
}
