import { useCallback, useEffect } from 'react';

import { FormikProps, getIn, setIn } from 'formik';
import _get from 'lodash-es/get';
import _isEqual from 'lodash-es/isEqual';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import PartyCheckboxList from '@sympli-mfe/document-forms-framework/components/party-checkbox-list';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { ConditionalLookupEnumModel, createModelKeyAppender, resolveLabel, resolveSelectPlaceholder, resolveVisibleEnumOptions } from '@sympli-mfe/document-forms-framework/utils';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { ClaimStatementEnum, ENUM_CLAIM_CATEGORY_OPTIONS, ENUM_CLAIM_STATEMENT_OPTIONS, ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS, EstateOrInterestClaimedEnum } from '../../enums';
import { filterSelectablePartyReferences, isSupportClaimDate, isSupportClaimPartyDetails, isSupportDetailsSupportingTheClaim } from '../../helpers';
import { ADDRESS_BOOK_KEY, BaseCaveatModel, ClaimDetailsModel, PARTY_BOOK_KEY } from '../../models';
import { Context } from '../../RootForm';
import { VISIBILITY_CHECK_CLAIM_DATE, VISIBILITY_CHECK_CLAIM_PARTY_DETAILS, VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM } from './visibilityChecks';

type FormValues = BaseCaveatModel;

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  dialogPortalId: string;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const debug = !import.meta.env.PROD;

const SectionClaimDetails = ({ name, formikProps, disabled, dialogPortalId, syncPartyReferences }: Props): JSX.Element => {
  const { values, getValues, setFieldValue } = formikProps;
  const fieldName = createModelKeyAppender<ClaimDetailsModel>(name);
  const claimDetails: ClaimDetailsModel = _get(values, name);
  const { claimParties } = claimDetails;
  const { partyBook, addressBook, titleReferences } = values;
  const setPartyBook = useCallback((value: PartyModel[]) => setFieldValue(PARTY_BOOK_KEY, value), [setFieldValue]);
  const setAddressBook = useCallback((value: AddressBookEntityModel[]) => setFieldValue(ADDRESS_BOOK_KEY, value), [setFieldValue]);
  const context = useRootFormContext<Context>();

  useEffect(() => {
    if (VISIBILITY_CHECK_CLAIM_PARTY_DETAILS(claimDetails)) {
      const updatedParties = filterSelectablePartyReferences(titleReferences, partyBook, claimParties);
      if (!_isEqual(updatedParties, claimParties)) {
        setFieldValue(fieldName('claimParties'), updatedParties);
      }
    }
  }, [partyBook, claimDetails.claimStatement]);

  function recalculateOption<T>(allOptions: ConditionalLookupEnumModel<T>[], currentFormValues: FormValues, bindingPath: string) {
    const options = resolveVisibleEnumOptions<T>(allOptions, currentFormValues, bindingPath);
    const currentValue = getIn(currentFormValues, bindingPath);
    return options.length === 1 ? options[0].id : currentValue ? options.find(item => item.id === currentValue)?.id : null;
  }

  const applyChange = (currentFormValues: FormValues): void => {
    setFieldValue(name, getIn(currentFormValues, name));
  };

  const handleClaimCategoryChange = (): void => {
    applyChange(onClaimCategoryChanged(getValues()));
  };

  const handleEstateOrInterestClaimedChange = (): void => {
    applyChange(onEstateOrInterestClaimedChanged(getValues()));
  };

  const handleClaimStatementChange = (): void => {
    applyChange(onClaimStatementChanged(getValues()));
  };

  const onClaimCategoryChanged = (currentFormValues: FormValues): FormValues => {
    const updatedEstateOrInterestClaimed = recalculateOption(ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS, currentFormValues, fieldName('estateOrInterestClaimed'));

    return onEstateOrInterestClaimedChanged(setIn(currentFormValues, fieldName('estateOrInterestClaimed'), updatedEstateOrInterestClaimed));
  };

  const onEstateOrInterestClaimedChanged = (currentFormValues: FormValues): FormValues => {
    const updatedClaimStatement = recalculateOption(ENUM_CLAIM_STATEMENT_OPTIONS, currentFormValues, fieldName('claimStatement'));

    return onClaimStatementChanged(setIn(currentFormValues, fieldName('claimStatement'), updatedClaimStatement));
  };

  const onClaimStatementChanged = (currentFormValues: FormValues): FormValues => {
    const currentClaimDetails: ClaimDetailsModel = getIn(currentFormValues, name);
    const updatedDetailsSupportingTheClaim = !!currentClaimDetails.claimStatement && isSupportDetailsSupportingTheClaim(currentClaimDetails.claimStatement) ? '' : undefined;
    const updatedClaimDate = !!currentClaimDetails.claimStatement && isSupportClaimDate(currentClaimDetails.claimStatement) ? null : undefined;
    const updatedClaimParties = !!currentClaimDetails.claimStatement && isSupportClaimPartyDetails(currentClaimDetails.claimStatement) ? [] : undefined;

    const updatedClaimDetails: ClaimDetailsModel = {
      ...currentClaimDetails,
      detailsSupportingTheClaim: updatedDetailsSupportingTheClaim,
      estateOrInterestClaimed: currentClaimDetails.estateOrInterestClaimed,
      claimDate: updatedClaimDate,
      claimParties: updatedClaimParties,
      claimStatement: currentClaimDetails.claimStatement
    };

    return setIn(currentFormValues, name, updatedClaimDetails);
  };

  const renderOptions = () => {
    return (
      <>
        <Field
          label={resolveLabel('Claim category', true)}
          name={fieldName('claimCategory')}
          format="string"
          options={ENUM_CLAIM_CATEGORY_OPTIONS}
          onChange={handleClaimCategoryChange}
          placeholder={resolveSelectPlaceholder(true)}
          component={SelectField}
          disabled={disabled}
          debug={debug}
        />
        <Field
          label={resolveLabel('Estate or interest claimed', true)}
          name={fieldName('estateOrInterestClaimed')}
          format="string"
          options={resolveVisibleEnumOptions<EstateOrInterestClaimedEnum>(ENUM_ESTATE_OR_INTEREST_CLAIMED_OPTIONS, values, fieldName('estateOrInterestClaimed'))}
          onChange={handleEstateOrInterestClaimedChange}
          placeholder={resolveSelectPlaceholder(true)}
          component={SelectField}
          disabled={disabled}
          debug={debug}
        />
        <Field
          label={resolveLabel('Grounds of claim', true)}
          name={fieldName('claimStatement')}
          format="string"
          options={resolveVisibleEnumOptions<ClaimStatementEnum>(ENUM_CLAIM_STATEMENT_OPTIONS, values, fieldName('claimStatement'))}
          onChange={handleClaimStatementChange}
          placeholder={resolveSelectPlaceholder(true)}
          component={SelectField}
          disabled={disabled}
          debug={debug}
        />
      </>
    );
  };

  const renderClaimParties = () => {
    return (
      <PartyCheckboxList //
        name={fieldName('claimParties')}
        disabled={disabled}
        dialogPortalId={dialogPortalId}
        title={'Claim Party details'}
        addButtonLabel={'Add new Claim Party details'}
        partyBook={partyBook}
        setPartyBook={setPartyBook}
        addressBook={addressBook}
        setAddressBook={setAddressBook}
        partyFormConfig={context.partyFormConfig}
        partyCapacityLookup={context.partyCapacityLookup}
        addressFormConfig={context.addressFormConfig}
        addressFormInitialValues={context.addressFormInitialValues}
        onDataChange={syncPartyReferences}
      />
    );
  };

  return (
    <FormGroup title="Claim details" fieldAreaDirection="column">
      {renderOptions()}
      {VISIBILITY_CHECK_CLAIM_PARTY_DETAILS(claimDetails) && renderClaimParties()}
      {VISIBILITY_CHECK_CLAIM_DATE(claimDetails) && (
        <Field
          key="date" // Adding key as this will be rendered in a React.ReactNode[]
          label={resolveLabel('Date claimed', true)}
          name={fieldName('claimDate')}
          component={DatePickerField}
          disableFuture
          disabled={disabled}
        />
      )}
      {VISIBILITY_CHECK_DETAILS_SUPPORTING_THE_CLAIM(claimDetails) && (
        <Field
          key="details" // Adding key as this will be rendered in a React.ReactNode[]
          label={resolveLabel('Clause number', true)}
          name={fieldName('detailsSupportingTheClaim')}
          component={InputField}
          fullWidth
          multiline
          disabled={disabled}
        />
      )}
    </FormGroup>
  );
};

export default SectionClaimDetails;
