import * as React from 'react';

import { useFormikContext } from 'formik';
import _isEqual from 'lodash-es/isEqual';

import FinalHoldings, { FinalTenancyHoldingsBuilder } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import { BaseTransfer2_24_1Model, TransferRootFormContext } from '../../models';
import { TenancyDetailExtractor } from './models';

interface Props {
  name: string;
}

function FinalTenancyHoldings<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({ name }: Props) {
  const { values, setFieldValue } = useFormikContext<BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const fieldName = modelKey<BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const { partyFormConfig, shouldMergeRemainingWithReceiving } = useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  const {
    //
    partyBook,
    transferors,
    transferees,
    finalTenancyHoldings: finalTenancyHoldingsFromForm
  } = values;

  const finalTenancyHoldings = React.useMemo(() => {
    const tde = new TenancyDetailExtractor(transferors, transferees, shouldMergeRemainingWithReceiving);
    return FinalTenancyHoldingsBuilder.build(tde, partyBook, partyFormConfig);
  }, [transferors, transferees, shouldMergeRemainingWithReceiving, partyBook, partyFormConfig]);

  const tenancyHoldingsHasChanged = !_isEqual(finalTenancyHoldingsFromForm, finalTenancyHoldings);

  React.useEffect(() => {
    if (tenancyHoldingsHasChanged) {
      setFieldValue(fieldName('finalTenancyHoldings'), finalTenancyHoldings);
    }
  }, [setFieldValue, finalTenancyHoldings, tenancyHoldingsHasChanged, fieldName]);

  return (
    <FinalHoldings //
      name={name}
    />
  );
}
export default React.memo(FinalTenancyHoldings);
