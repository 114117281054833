import React from 'react';

import SectionReceivingTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';

import { TransferRootFormContext } from '../../models';
import { RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from './config';

interface Props {
  name: string;
}
function Transferees<TDocumentPartyJustification, TNameChange extends {}>({ name }: Props) {
  const { receivingTenancyConfig } = useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  return <SectionReceivingTenancy name={name} totalShareConfig={RECEIVING_TENANCY_TOTAL_SHARE_CONFIG} {...receivingTenancyConfig} />;
}

export default React.memo(Transferees);
