import Typography from '@mui/material/Typography';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { IconSupport } from 'src/@core/components/icons';
import Header from 'src/@core/components/typography/header';
import HelpPageCard from './help-page-card';
import { getCustomizedSupportMailtoLink } from './helpers';
import IconTraining from './IconTraining';
import { useStyles } from './styles';

const SUPPORT_EMAIL_ADDRESS: string = 'help@sympli.com.au';

export interface Props {
  subscriberName: string | undefined;
  supportPageUrl: string | undefined;
}

const HelpPage = ({ subscriberName, supportPageUrl }: Props) => {
  const classes = useStyles();
  const mailtoLink = getCustomizedSupportMailtoLink(SUPPORT_EMAIL_ADDRESS, subscriberName);

  return (
    <div className={classes.root}>
      <Header>Sympli Support</Header>
      <div className={classes.grid}>
        <HelpPageCard
          icon={<IconTraining title="Self service support" />}
          headerText="Access self service support"
          message="Learn how to access, use and transact on Sympli with our step-by-step support guides."
        >
          <a href={supportPageUrl} target="_blank" rel="noopener noreferrer">
            <SympliButton color="primary" variant="contained">
              Sympli Support Portal
            </SympliButton>
          </a>
        </HelpPageCard>
        <HelpPageCard
          icon={<IconSupport title="Contact support" />}
          headerText="Need more support?"
          message={
            <>
              <Typography>
                Email us at{' '}
                <a href={mailtoLink} className={classes.emailSupport}>
                  {SUPPORT_EMAIL_ADDRESS}
                </a>
              </Typography>
              <Typography>or call us on 1300 796 754</Typography>
            </>
          }
        />
      </div>
    </div>
  );
};

export default HelpPage;
