import { defaultMemoize } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

export const memoizedAllParticipantSigned = defaultMemoize((documentList: Array<HttpTypes.WorkspaceDocumentSummary>) => {
  if (documentList.length === 0) {
    return false;
  }
  return documentList.every(doc => {
    return doc.documentParticipants.every(participant => participant.documentStatus === HttpTypes.DocumentStatusEnum.Signed);
  });
});
