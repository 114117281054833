import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import { prepareReceivingTenancyDetailForApi, ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { prepareRelinquishingTenancyDetailForApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import PartyReferencer from 'src/containers/documents/party-referencer/PartyReferencer';
import { calculateTransferorsOnTitle, generateTransferors, resolveTransferors } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/helpers';
import { convertPartiesToFormModel, getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { ApiTransfer2_24_1Model, Transfer2_24_1Model } from './models';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<QldDocumentPartyJustification, QldNameChange>({
  partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG,
  nameChangeConversion: QldNameChangeConversion
});

class Converter implements IConverter<Transfer2_24_1Model, ApiTransfer2_24_1Model> {
  fromApiToFormModel(apiModel: ApiTransfer2_24_1Model, _context: IConverterContext): Transfer2_24_1Model {
    const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
    const partyBook = convertPartiesToFormModel(
      { partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG, nameChangeConversion: QldNameChangeConversion },
      apiModel.partyBook,
      risInvolvedPartyIds
    );

    let formModel: Transfer2_24_1Model = {
      ...apiModel,
      partyBookApi: apiModel.partyBook,
      partyBook: partyBook
    };

    if (apiModel.precedingData?.dataChanged ?? true) {
      formModel = generateTransferors(formModel, QLD_TRANSFER_PARTY_FORM_CONFIG, QldNameChangeConversion, convertPartiesToFormModel);
    } else {
      // regenerate transferors on title
      const { transferors, transferorsParties } = resolveTransferors(formModel.titleReferences.filter(tr => tr.isSelected));

      formModel.transferorsOnTitle = calculateTransferorsOnTitle(transferorsParties, transferors, parties =>
        convertPartiesToFormModel({ partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG, nameChangeConversion: QldNameChangeConversion }, parties, risInvolvedPartyIds)
      );
    }

    const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
    return formModelWithDefaultMapApplied;
  }

  fromFormToApiModel(formValues: Transfer2_24_1Model, _originalApiModel: ApiTransfer2_24_1Model): ApiTransfer2_24_1Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook: formPartyBook,
      partyBookApi,
      mergeFailedReason: mergeFailed,
      transferees: formTransferees,
      finalTenancyHoldings,
      transferors,
      precedingData,
      ...rest
    } = formModel;

    let partyBook = [...formPartyBook];
    PartyJustification.adjustJustification(partyBook, getLegalEntityNameQLD);

    const transferees: ReceivingTenancyDetailModel = {
      ...formTransferees,
      proprietorGroups: formTransferees.proprietorGroups.map(pg => {
        const { updatedParties, updatedReferences } = PartyReferencer.adjustReferences(partyBook, pg.parties);
        pg.parties = updatedReferences;
        partyBook = updatedParties;
        return pg;
      })
    };

    const result: ApiTransfer2_24_1Model = {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
      transferors: prepareRelinquishingTenancyDetailForApi(transferors, formModel.precedingData.manualOverride),
      transferees: prepareReceivingTenancyDetailForApi(transferees),
      precedingData: {
        ...precedingData,
        dataChanged: false
      }
    };

    return result;
  }
}

export default new Converter();
