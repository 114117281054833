import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { prepareReceivingTenancyDetailForApi, ShareSplitTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { ReceivingProprietorGroupModel, ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/models';
import { RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { ProprietorGroupTypeResolver, TenancyTypeResolver } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared';
import {
  convertReceivingTenancyDetailFromApiToForm,
  ApiReceivingTenancyDetailModel as OldApiReceivingTenancyDetailModel,
  FormReceivingTenancyDetailModel as OldFormReceivingTenancyDetailModel,
  ReceivingTenancyDetails$ShareSplitTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import {
  convertRelinquishingGroupsToGroupsWithMeta as convertOldRelinquishingGroupsToGroupsWithMeta,
  RelinquishingProprietorGroupModel as OldRelinquishingProprietorGroupModel,
  RelinquishingTenancyDetailModel as OldRelinquishingTenancyDetailModel,
  RelinquishingTenancyDetailModelWithMeta as OldRelinquishingTenancyDetailModelWithMeta,
  ShareTransferredTypeEnum
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { TenancyPartyModel as OldTenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ProprietorGroupModel, ProprietorGroupTypeEnum, TenancyPartyModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartySourceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { lowestCommonMultiple } from '@sympli-mfe/document-forms-framework/utils';

import { Container, MergeFailedReasonsEnum, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { PartyAdjuster } from 'src/containers/documents/partyAdjuster';
import { convertPartiesToFormModel } from '../../helpers';
import { NSW_TRANSFER_PARTY_FORM_CONFIG } from './config';
import { ApiTransfer2_21_1Model, Transfer2_21_1Model } from './models';

export function calculateTransferorsOnTitle(
  apiProprietors: ApiTransfer2_21_1Model['partyBook'],
  transferors: Transfer2_21_1Model['transferorsNew']
): Transfer2_21_1Model['transferorsOnTitle'] {
  const partyBook: PartyModel[] = convertPartiesToFormModel_2_21_1(apiProprietors);
  const partyBookIds: string[] = partyBook.map(p => p.id);
  // explicitly ignore name change in reset tenancy check
  const partyNamesMap: Map<string, string> = new Map<string, string>(partyBook.map(p => [p.id, p.legalEntityName!]));
  return {
    partyBook,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }
      return partyNamesMap.get(party.id) === party.legalEntityName!;
    },
    transferors
  };
}

export function isTransferorsResetTenancyAvailable({
  //
  partyBook,
  transferorsNew,
  transferorsOnTitle,
  precedingData
}: {
  partyBook: Transfer2_21_1Model['partyBook'];
  transferorsNew: Transfer2_21_1Model['transferorsNew'];
  transferorsOnTitle: Transfer2_21_1Model['transferorsOnTitle'];
  precedingData: Transfer2_21_1Model['precedingData'];
}): boolean {
  if (precedingData.sourceChanged) return true;

  const displayedPartyBookIds = transferorsNew.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedPartyBookIds, transferorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  const displayedParties: PartyModel[] = displayedPartyBookIds.map(id => partyBook.find(p => p.id === id)).filter(Boolean) as PartyModel[];

  //* 2. has any tenant been updated by the user?
  if (displayedParties.some(p => !transferorsOnTitle.isOriginal(p))) {
    return true;
  }

  //* 3. has tenancy structure been changed?
  if (transferorsOnTitle.transferors.proprietorGroups.length !== transferorsNew.proprietorGroups.length) {
    return true;
  }

  const tenancyStructureChanged = transferorsNew.proprietorGroups.some((pg, i) => {
    const pgOnTitle = transferorsOnTitle.transferors.proprietorGroups[i];
    if (pg.parties.length !== pgOnTitle.parties.length) {
      return true;
    }
    const partyOrderDoesNotMatch = pg.parties.some((p, j) => {
      return pgOnTitle.parties[j].partyBookId !== p.partyBookId;
    });
    return partyOrderDoesNotMatch;
  });

  return tenancyStructureChanged;
}

export function resolveTransferors(formModel: {
  //
  titleReferences: Transfer2_21_1Model['titleReferences'];
  precedingData: Transfer2_21_1Model['precedingData'];
  partyBook: Transfer2_21_1Model['partyBook'];
}): {
  transferorsNew: Transfer2_21_1Model['transferorsNew'];
  partyBookApi: Transfer2_21_1Model['partyBookApi'];
  mergeFailedReason: Transfer2_21_1Model['mergeFailedReason'];
} {
  const selectedTitles: Transfer2_21_1Model['titleReferences'] = getSelectedTitleReferences(formModel.titleReferences);
  const { precedingData, partyBook } = formModel;

  const containers = selectedTitles.map(title => {
    const precedingDataTenancy = precedingData.newProprietors.find(p => p.proprietorsSource.titles.some(x => x === title.reference!));
    const groups: ProprietorGroupModel<ApiPartySourceModel<NswDocumentPartyJustification, {}>>[] =
      precedingDataTenancy?.proprietorGroups ??
      title.transferors.relinquishingProprietorGroups.map(pg => ({
        ...pg,
        isSelected: pg.isTransacting,
        parties: pg.parties.map(p => ({ party: p.mortgagorParty ? p.mortgagorParty : p.originalRisParty })),
        shareFraction: pg.previouslyHeldShareFraction,
        proprietorGroupType: pg.proprietorGroupType ?? ProprietorGroupTypeEnum.None
      }));

    return new Container(
      title.reference!,
      new TenancyDetail(
        groups.map(
          pg =>
            new ProprietorGroup(
              pg.parties.map(p => ({ ...p.party, partyCapacity: p.partyCapacity })),
              pg,
              pg.shareFraction,
              pg.proprietorGroupType
            )
        ),
        precedingDataTenancy?.tenancyType ?? title.transferors.tenancyType ?? undefined
      )
    );
  });

  const result = PartyMerger.merge(containers);

  const groups: OldRelinquishingProprietorGroupModel<ApiPartySourceModel<NswDocumentPartyJustification>>[] = result.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(
      ({
        // Take out shareFraction as old model cannot hold onto shareFraction otherwise it will be mapped to shareTransferring when converting to new model
        tag: { shareFraction, ...pgTag },
        ...pg
      }) => {
        const proprietorGroup: OldRelinquishingProprietorGroupModel<ApiPartySourceModel<NswDocumentPartyJustification>> = {
          ...pgTag,
          parties: pg.mergedParties.map(({ partyCapacity, ...p }) => {
            const partyFromPartyBook = partyBook.find(x => x.id === p.id);
            return { party: { ...p, birthDate: partyFromPartyBook?.dob ?? p.birthDate }, partyCapacity };
          }),
          previouslyHeldShareFraction: shareFraction,
          proprietorGroupType: pg.proprietorGroupType
        };
        return proprietorGroup;
      }
    )
  );

  const transferorsRelinquishingProprietorGroups: OldRelinquishingTenancyDetailModelWithMeta<OldTenancyPartyModel>['relinquishingProprietorGroups'] = groups.map(pg => {
    return {
      ...pg,
      parties: pg.parties.map(p => ({
        //
        id: p.party.id!,
        partyBookId: p.party.id!
      })),
      isTransacting: groups.length === 1 || pg.isTransacting,
      shareFraction: pg.shareFraction
    };
  });
  const tenancyType = result.failedReason === MergeFailedReasonsEnum.StructureNotSame ? TenancyTypeEnum.None : result.containers[0]?.tenancyDetail.tenancyType!;
  const transferorsOld: Transfer2_21_1Model['transferors'] = convertOldTransferorsFromApiToFormModel({
    transferors: {
      tenancyType,
      relinquishingProprietorGroups: transferorsRelinquishingProprietorGroups
    },
    isGroupStructureMeaningful: result.containers.length === 1
  });
  const transferorsNew: Transfer2_21_1Model['transferorsNew'] = convertOldTransferorsToNewTransferors({
    transferorsOld: transferorsOld
  });

  const partyBookApi: Transfer2_21_1Model['partyBookApi'] = groups.flatMap(pg => pg.parties.map(p => p.party));
  return {
    transferorsNew,
    partyBookApi,
    mergeFailedReason: result.failedReason
  };
}

export function recalculateFormValues(
  formModel: {
    titleReferences: Transfer2_21_1Model['titleReferences'];
    partyBook: Transfer2_21_1Model['partyBook'];
    transfereesNew: Transfer2_21_1Model['transfereesNew'];
    precedingData: Transfer2_21_1Model['precedingData'];
  },
  includeCustomParties = true
): {
  transferorsNew: Transfer2_21_1Model['transferorsNew'];
  transfereesNew?: Transfer2_21_1Model['transfereesNew'];
  partyBook?: Transfer2_21_1Model['partyBook'];
  partyBookApi?: Transfer2_21_1Model['partyBookApi'];
  mergeFailedReason?: Transfer2_21_1Model['mergeFailedReason'];
  transferorsOnTitle?: Transfer2_21_1Model['transferorsOnTitle'];
  precedingData: Transfer2_21_1Model['precedingData'];
} {
  const selectedTitles: Transfer2_21_1Model['titleReferences'] = getSelectedTitleReferences(formModel.titleReferences);
  const { precedingData } = formModel;

  if (!selectedTitles.length) {
    const transferorsOld: Transfer2_21_1Model['transferors'] = {
      relinquishingProprietorGroups: [],
      tenancyType: null,
      isGroupStructureMeaningful: false
    };
    const transferorsNew: Transfer2_21_1Model['transferorsNew'] = convertOldTransferorsToNewTransferors({
      transferorsOld: transferorsOld
    });
    const transferorsOnTitle: Transfer2_21_1Model['transferorsOnTitle'] = calculateTransferorsOnTitle([], transferorsNew);
    return {
      transferorsNew,
      precedingData,
      transferorsOnTitle
    };
  }
  const {
    //
    partyBookApi,
    transferorsNew,
    mergeFailedReason
  } = resolveTransferors({
    //
    titleReferences: formModel.titleReferences,
    precedingData: formModel.precedingData,
    partyBook: formModel.partyBook
  });

  const transferorsOnTitle: Transfer2_21_1Model['transferorsOnTitle'] = calculateTransferorsOnTitle(partyBookApi, transferorsNew);

  const customParties: Transfer2_21_1Model['partyBook'] = includeCustomParties
    ? formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.addedFrom === DataSource.Custom)
    : [];

  const partyBook: Transfer2_21_1Model['partyBook'] = convertPartiesToFormModel_2_21_1(
    partyBookApi.filter((value, index, self) => index === self.findIndex(t => t.id === value.id)),
    partyBookApi.map(p => p.id!)
  ).concat(customParties);

  return {
    partyBook,
    partyBookApi,
    transferorsNew,
    transfereesNew: PartyAdjuster.adjustTenancyDetail(formModel.transfereesNew, partyBook, formModel.partyBook),
    mergeFailedReason,
    transferorsOnTitle,
    precedingData: {
      ...precedingData,
      titlesUsed: selectedTitles
        .map(title => title.reference!)
        .filter(titleReference => Boolean(precedingData.newProprietors.find(np => np.proprietorsSource.titles.includes(titleReference)))),
      dataChanged: formModel.precedingData?.dataChanged ?? false,
      sourceChanged: false,
      manualOverride: false
    }
  };
}

export function convertPartiesToFormModel_2_21_1(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_TRANSFER_PARTY_FORM_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}

export function convertOldTransferorsFromApiToFormModel({
  transferors,
  isGroupStructureMeaningful
}: {
  transferors: OldRelinquishingTenancyDetailModel<OldTenancyPartyModel>;
  isGroupStructureMeaningful: boolean;
}): //
OldRelinquishingTenancyDetailModelWithMeta<OldTenancyPartyModel> {
  const groups: OldRelinquishingProprietorGroupModel<OldTenancyPartyModel>[] = transferors.relinquishingProprietorGroups.map(pg => {
    return convertOldRelinquishingGroupsToGroupsWithMeta(pg, transferors.tenancyType, pg.isTransacting);
  });

  return {
    ...transferors,
    relinquishingProprietorGroups: groups,
    isGroupStructureMeaningful: isGroupStructureMeaningful,
    tenancyType: isGroupStructureMeaningful ? transferors.tenancyType : null
  };
}

export function convertOldTransferorsFromFormToApiModel(transferors: OldRelinquishingTenancyDetailModel<OldTenancyPartyModel>): //
OldRelinquishingTenancyDetailModel<OldTenancyPartyModel> {
  const groups: OldRelinquishingProprietorGroupModel<OldTenancyPartyModel>[] = transferors.relinquishingProprietorGroups;

  return {
    relinquishingProprietorGroups: groups.map(e => {
      const { isGroupFromMulti, ...api } = e;
      return api;
    }),
    tenancyType: transferors.tenancyType
  };
}

export function convertNewTransferorsFromFormToOldApiModel(
  transferors: RelinquishingTenancyDetailModel,
  isTransferorsResetTenancyAvailable: boolean
): OldRelinquishingTenancyDetailModel<OldTenancyPartyModel> {
  const relinquishingProprietorGroups: OldRelinquishingProprietorGroupModel<OldTenancyPartyModel>[] = transferors.proprietorGroups.map((pg: RelinquishingProprietorGroupModel) => {
    const parties: OldTenancyPartyModel[] = pg.parties.map((p: TenancyPartyModel, i: number) => {
      const oldP: OldTenancyPartyModel = {
        // id: string;
        // addressBookId?: string;
        // partyBookId?: string;
        // partyCapacity?: number | string | null;
        // partyCapacityDetail?: string;
        // birthDate?: Date | string | null;
        // isTransactingParty?: boolean;
        id: '' + (i + 1),
        addressBookId: p.addressBookId,
        partyBookId: p.partyBookId,
        partyCapacity: p.partyCapacity.capacity,
        partyCapacityDetail: p.partyCapacity.details,
        isTransactingParty: p.isSelected
      };
      return oldP;
    });

    const oldPg: OldRelinquishingProprietorGroupModel<OldTenancyPartyModel> = {
      // isGroupFromMulti?: boolean;
      // isTransacting: boolean;
      // parties: PartyType[];
      // shareFraction?: FractionModel;
      // previouslyHeldShareFraction: FractionModel;
      // proprietorGroupType?: ProprietorGroupTypeEnum;
      // shareTransferred: ShareTransferredTypeEnum;

      isTransacting: pg.isSelected,
      parties,
      shareFraction: pg.shareFractionTransferring,
      previouslyHeldShareFraction: pg.shareFraction,
      shareTransferred: pg.shareTransferred,
      proprietorGroupType: pg.proprietorGroupType
    };
    return oldPg;
  });
  return {
    relinquishingProprietorGroups,
    tenancyType: isTransferorsResetTenancyAvailable ? TenancyTypeResolver.resolve(relinquishingProprietorGroups) : transferors.tenancyType
  };
}

export function convertOldTransfereesFromApiToFormModel(transferees: OldApiReceivingTenancyDetailModel): OldFormReceivingTenancyDetailModel {
  const transfereesOld = convertReceivingTenancyDetailFromApiToForm(transferees);
  return transfereesOld;
}

export function convertNewTransfereesFromFromToOldApiModel(transfereesNew: ReceivingTenancyDetailModel): OldApiReceivingTenancyDetailModel {
  const data = prepareReceivingTenancyDetailForApi(transfereesNew);
  const receivingProprietorGroups: OldApiReceivingTenancyDetailModel['receivingProprietorGroups'] = data.proprietorGroups.map((pg: ReceivingProprietorGroupModel, i: number) => {
    const parties: OldTenancyPartyModel[] = pg.parties.map((p: TenancyPartyModel, i: number) => {
      // id: string;
      // addressBookId?: string;
      // partyBookId?: string;
      // partyCapacity?: number | string | null;
      // partyCapacityDetail?: string;
      // birthDate?: Date | string | null;
      // isTransactingParty?: boolean;

      return {
        id: '' + (i + 1),
        addressBookId: p.addressBookId,
        partyBookId: p.partyBookId,
        partyCapacity: p.partyCapacity.capacity,
        partyCapacityDetail: p.partyCapacity.details,
        isTransactingParty: p.isSelected
      };
    });

    const oldPg: OldApiReceivingTenancyDetailModel['receivingProprietorGroups'][number] = {
      id: '' + (i + 1),
      parties,
      isNoSurvivorshipJointType: pg.isNoSurvivorshipJointType,
      minimumTrusteeThreshold: pg.minimumTrusteeThreshold,
      shareFraction: pg.shareFraction
      // id: string;
      // parties: TenancyPartyModel[];
      // isNoSurvivorshipJointType?: boolean;
      // minimumTrusteeThreshold?: number | null;
      // shareFraction?: FractionModel;
    };

    return oldPg;
  });

  return {
    receivingProprietorGroups,
    shareSplitType: transfereesNew.shareSplitType
      ? transfereesNew.shareSplitType === ShareSplitTypeEnum.TenantsInCommonEqualShare
        ? ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonEqualShare
        : ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonUnequalShare
      : null
  };
}

export function convertOldTransfereesToNewTransferees({
  transfereesOld,
  transfereesApiOld
}: {
  transfereesOld: OldFormReceivingTenancyDetailModel;
  transfereesApiOld: OldApiReceivingTenancyDetailModel;
}): ReceivingTenancyDetailModel {
  const tenancyType: TenancyTypeEnum = TenancyTypeResolver.resolve(transfereesOld.receivingProprietorGroups);

  const lcm: number | null =
    transfereesApiOld.receivingProprietorGroups.length !== 0 &&
    transfereesApiOld.receivingProprietorGroups.every(group => group.shareFraction?.denominator! > 0 && group.shareFraction?.numerator! > 0)
      ? transfereesApiOld.receivingProprietorGroups.map(group => group.shareFraction!.denominator).reduce(lowestCommonMultiple)
      : NaN;

  const proprietorGroups: ReceivingProprietorGroupModel[] = transfereesApiOld.receivingProprietorGroups.map(
    (oldPg: OldApiReceivingTenancyDetailModel['receivingProprietorGroups'][number]) => {
      const proprietorGroupType: ProprietorGroupTypeEnum = ProprietorGroupTypeResolver.resolve(tenancyType, oldPg.parties.length);
      const pg: ReceivingProprietorGroupModel = {
        isSelected: true,
        proprietorGroupType,
        parties: oldPg.parties.map((pg: OldTenancyPartyModel) => {
          return {
            isSelected: !!pg.isTransactingParty,
            addressBookId: pg.addressBookId,
            partyBookId: pg.partyBookId,
            partyCapacity: {
              capacity: pg.partyCapacity,
              details: pg.partyCapacityDetail
            }
          };
        }),
        shareQuantity: lcm ? oldPg.shareFraction!.numerator! * (lcm / oldPg.shareFraction!.denominator!) : null,
        isNoSurvivorshipJointType: oldPg.isNoSurvivorshipJointType,
        minimumTrusteeThreshold: oldPg.minimumTrusteeThreshold,
        shareFraction: oldPg.shareFraction ?? {
          denominator: null,
          numerator: null
        }
      };
      return pg;
    }
  );

  const transfereesNew: ReceivingTenancyDetailModel = {
    shareSplitType: transfereesOld.shareSplitType
      ? transfereesOld.shareSplitType === ReceivingTenancyDetails$ShareSplitTypeEnum.TenantsInCommonEqualShare
        ? ShareSplitTypeEnum.TenantsInCommonEqualShare
        : ShareSplitTypeEnum.TenantsInCommonUnequalShare
      : null,
    proprietorGroups,
    tenancyType
  };
  return transfereesNew;
}

export function convertOldTransferorsToNewTransferors({
  transferorsOld
}: {
  transferorsOld: OldRelinquishingTenancyDetailModel<OldTenancyPartyModel>;
}): RelinquishingTenancyDetailModel {
  const transferorsNew: RelinquishingTenancyDetailModel = {
    tenancyType: Number.isInteger(transferorsOld.tenancyType) ? (transferorsOld.tenancyType as TenancyTypeEnum) : TenancyTypeEnum.None, // TODO what is the default value here?
    proprietorGroups: transferorsOld.relinquishingProprietorGroups.map(pg => {
      return {
        isSelected: pg.isTransacting,
        parties: pg.parties.map(p => {
          return {
            isSelected: pg.isTransacting || p.isTransactingParty || false,
            addressBookId: p.addressBookId,
            partyBookId: p.partyBookId,
            partyCapacity: {
              capacity: p.partyCapacity,
              details: p.partyCapacityDetail
            }
          };
        }),
        proprietorGroupType: Number.isInteger(pg.proprietorGroupType) ? (pg.proprietorGroupType as ProprietorGroupTypeEnum) : ProprietorGroupTypeEnum.None, // TODO what is the default value here?
        shareTransferred: pg.shareTransferred ?? ShareTransferredTypeEnum.Whole,
        shareFraction: pg.previouslyHeldShareFraction,
        shareFractionTransferring: pg.shareFraction
      };
    })
  };

  return transferorsNew;
}

/**
 * Returns list of title references selected by the user.
 */
export const getSelectedTitleReferences = defaultMemoize(
  (
    //
    titleReferences: Transfer2_21_1Model['titleReferences']
  ): Transfer2_21_1Model['titleReferences'] => {
    return titleReferences.filter(({ isSelected }) => isSelected);
  }
);

export const getCurrentTransfereePartyBookIds = defaultMemoize((transfereesNew: ReceivingTenancyDetailModel): string[] => {
  const transfereesPartyBookIds: string[] = transfereesNew.proprietorGroups
    .flatMap(pg =>
      pg.parties //
        .map(({ partyBookId }) => partyBookId)
    )
    .filter(Boolean) as string[];
  return transfereesPartyBookIds;
});
