import endOfToday from 'date-fns/endOfToday';

import { resolveTenancyFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-28/necds';

import { Mortgage2_28_0Model } from './models';

export const fallbackMap: FallbackMap<Mortgage2_28_0Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  addressBook: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: true,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        isPartLandAffected: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        volume: NO_FALLBACK,
        folio: NO_FALLBACK,
        estateType: NO_FALLBACK,
        mortgagors: NO_FALLBACK,
        hasTransfer: NO_FALLBACK
      }
    }
  },
  mortgageeDetails: resolveTenancyFallback({
    partyCapacityMinor: NecdsPartyCapacityEnum.Minor
  }),
  termsAndConditions: {
    $children: {
      dealingNumber: [''],
      subscriberDefaultDealingNumber: NO_FALLBACK,
      additionalCovenants: [''],
      mortgageDate: [null],
      preRegisteredStandardTerms: NO_FALLBACK
    }
  },
  mortgagors: NO_FALLBACK,
  needToGenerateMortgagors: NO_FALLBACK,
  transferees: NO_FALLBACK,
  proprietors: NO_FALLBACK
};

export const END_OF_TODAY = endOfToday();
