import React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { Exclusive } from '@sympli/ui-framework/utils/type';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceDetail } from '../../../../../store/reducers/workspace/workspaceDetail';
import AbandonLodgementOnlyDialog from '../abandon-lodgement-only-dialog';
import LinkWorkspaceDialogContainer from '../link-workspace-dialog';
import { ReassignWorkspaceGroupContainer } from '../reassign-workspace-group-container/ReassignWorkspaceGroupContainer';
import UnlinkLodgementWorkspaceDialogContainer from '../unlink-lodgement-workspace-dialog';
import WithdrawFinancialParticipantDialogContainer from '../withdraw-financial-participant-dialog';
import { useAdditionalOptionType, useCanLinkWorkspace } from './help';
import { useStyles } from './styles';

type ManageWorkspaceBoxContainerProps = {
  // route params
  workspaceId: string;
  participantId: string;
  subscriberId: string;
  // redux

  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  lodgementCases: HttpTypes.LodgementCase[];
} & Exclusive<
  {
    // LO ws does not need info about role
    workspaceTypeId: HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  },
  {
    workspaceRoleId: HttpTypes.WorkspaceRoleEnum;
    workspaceTypeId: HttpTypes.WorkspaceTypeEnum.FinancialSettlement;
  }
>;

function ManageWorkspaceBoxContainer({
  // route params
  workspaceId,
  participantId,
  subscriberId,
  // redux
  workspaceRoleId,
  workspaceStatusId,
  workspaceTypeId,
  lodgementCases
}: ManageWorkspaceBoxContainerProps) {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId).detail;
  const [dialogType, setDialogType] = React.useState<'reassign' | 'withdraw' | 'abandon' | 'link' | 'unlink' | null>(null);

  const additionalOptionType = useAdditionalOptionType(workspaceId, participantId, workspaceStatusId, lodgementCases);
  const canLinkWorkspace = useCanLinkWorkspace({ workspaceId, participantId, workspaceTypeId });

  const ReassignOnly = React.useMemo(
    () => (
      <>
        <ul className={classes.options}>
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={() => {
                setDialogType('reassign');
              }}
              aria-label="Reassign workspace group"
              color="inherit"
              className={classes.optionItem}
              data-testid="reassign-workspace-group"
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>Reassign workspace group</span>
              </FlexLayout>
            </SympliButton>
          </li>
        </ul>
        {/* related dialogs */}
        <ReassignWorkspaceGroupContainer //
          workspaceId={workspaceId}
          participantId={participantId}
          open={dialogType === 'reassign'}
          onClose={() => setDialogType(null)}
        />
      </>
    ),
    [classes, dialogType, participantId, workspaceId]
  );

  const AllOptions = React.useMemo(() => {
    const isWithdraw = additionalOptionType === 'withdraw';
    const withdrawOnClick = () => {
      const participantHasLinkedDirection =
        workspaceDetail?.linkedWorkspaceCluster?.linkedPaymentParticipantId === participantId ||
        workspaceDetail?.linkedWorkspaceCluster?.linkedSourceFundParticipantId === participantId;

      const participantHasLinkedLodgement =
        workspaceDetail?.linkedWorkspaceCluster?.linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement &&
        workspaceDetail?.linkedWorkspaceCluster?.linkedBySubscriberId === subscriberId &&
        workspaceDetail?.linkedWorkspaceCluster?.linkedByParticipantRoleId === workspaceRoleId;

      if (participantHasLinkedDirection) {
        dispatch(
          actionCreateGlobalErrorMessage({
            title: `User cannot ${isWithdraw ? 'withdraw from' : 'abandon'} workspace`,
            message: `This workspace is a part of a Linked Settlement. To ${isWithdraw ? 'withdraw from' : 'abandon'} this workspace please click Unlink or Delete the linked line item in the Financial Settlement Schedule and try again.`
          })
        );
      } else if (participantHasLinkedLodgement) {
        dispatch(
          actionCreateGlobalErrorMessage({
            title: `User cannot ${isWithdraw ? 'withdraw from' : 'abandon'} workspace`,
            message: `This workspace is a part of a Linked Lodgement. To ${isWithdraw ? 'withdraw from' : 'abandon'} this workspace please Unlink the Linked workspaces and try again.`
          })
        );
      } else {
        setDialogType(isWithdraw ? 'withdraw' : 'abandon');
      }
    };

    return (
      <>
        {/* menu items */}
        <ul className={classes.options}>
          {canLinkWorkspace.showLink && (
            <li className={classes.optionItem}>
              <SympliButton //
                variant="text"
                onClick={() => {
                  setDialogType('link');
                  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
                    feature: 'linked-workspace',
                    logGroupId: 'workspace',
                    route: 'workspace-overview',
                    action: 'open-link-dialog',
                    workspaceId,
                    participantId
                  });
                }}
                aria-label="Link other workspace"
                color="inherit"
                className={classes.optionItem}
                data-testid="link-other-workspace"
              >
                <FlexLayout alignItems="center">
                  <span className={classes.optionText}>Link workspace</span>
                </FlexLayout>
              </SympliButton>
            </li>
          )}
          {canLinkWorkspace.showUnlink && (
            <li className={classes.optionItem}>
              <SympliButton //
                variant="text"
                onClick={() => {
                  setDialogType('unlink');
                  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
                    feature: 'unlinked-workspace',
                    logGroupId: 'workspace',
                    route: 'workspace-overview',
                    action: 'open-unlink-dialog',
                    workspaceId,
                    participantId
                  });
                }}
                aria-label="Unlink other workspace"
                color="inherit"
                className={classes.optionItem}
                data-testid="unlink-other-workspace"
              >
                <FlexLayout alignItems="center">
                  <span className={classes.optionText}>Unlink workspace</span>
                </FlexLayout>
              </SympliButton>
            </li>
          )}
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={() => {
                setDialogType('reassign');
              }}
              aria-label="Reassign workspace group"
              color="inherit"
              className={classes.optionItem}
              data-testid="reassign-workspace-group"
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>Reassign workspace group</span>
              </FlexLayout>
            </SympliButton>
          </li>
          <li className={classes.optionItem}>
            <SympliButton //
              variant="text"
              onClick={withdrawOnClick}
              aria-label={isWithdraw ? 'Withdraw from workspace' : 'Abandon workspace'}
              color="inherit"
              className={classes.optionItem}
              data-testid={isWithdraw ? 'withdraw-from-workspace' : 'abandon-workspace'}
            >
              <FlexLayout alignItems="center">
                <span className={classes.optionText}>{isWithdraw ? 'Withdraw from workspace' : 'Abandon workspace'}</span>
              </FlexLayout>
            </SympliButton>
          </li>
        </ul>
        {/* related dialogs */}
        <ReassignWorkspaceGroupContainer //
          workspaceId={workspaceId}
          participantId={participantId}
          open={dialogType === 'reassign'}
          onClose={() => setDialogType(null)}
        />
        {canLinkWorkspace.showLink && (
          <LinkWorkspaceDialogContainer //
            workspaceId={workspaceId}
            participantId={participantId}
            open={dialogType === 'link'}
            onClose={() => setDialogType(null)}
          />
        )}
        {canLinkWorkspace.showUnlink && (
          <UnlinkLodgementWorkspaceDialogContainer //
            workspaceId={workspaceId}
            participantId={participantId}
            open={dialogType === 'unlink'}
            onClose={() => setDialogType(null)}
          />
        )}
        {workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.RegistrationOnly ? (
          <WithdrawFinancialParticipantDialogContainer // financial ws allows both withdraw and abandoning
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            //redux data
            workspaceRoleId={workspaceRoleId}
            // dialog
            open={dialogType === 'withdraw' || dialogType === 'abandon'}
            onClose={() => setDialogType(null)}
            // other
            isWithdraw={isWithdraw}
          />
        ) : (
          <AbandonLodgementOnlyDialog // LO ws allows only abandon
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // redux
            workspaceTypeId={workspaceTypeId}
            // dialog
            open={dialogType === 'abandon'}
            onClose={() => setDialogType(null)}
          />
        )}
      </>
    );
  }, [
    additionalOptionType,
    classes.options,
    classes.optionItem,
    classes.optionText,
    canLinkWorkspace,
    workspaceId,
    participantId,
    subscriberId,
    dialogType,
    workspaceTypeId,
    workspaceRoleId,
    workspaceDetail?.linkedWorkspaceCluster,
    dispatch
  ]);

  if (additionalOptionType === 'reassignOnly') {
    return ReassignOnly;
  }

  return AllOptions;
}

export default ManageWorkspaceBoxContainer;
