import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_LEASE_LESSOR_TENANCY_COMPONENT_LABEL_CONFIG, NSW_LEASE_PARTY_FORM_CONFIG } from '../../config';
import { generateLessors, getSelectedTitleReferences, isLessorsResetTenancyAvailable } from '../../helpers';
import { Lease2_21_2Model } from '../../models';

interface Props {
  name: string;
  focusRef: React.RefObject<ButtonBaseActions>;
}

const INCLUDE_CUSTOM_PARTIES: boolean = true;

function SectionLessors({
  //
  name,
  focusRef
}: Props): JSX.Element {
  const { values, setValues } = useFormikContext<Lease2_21_2Model>();
  React.useEffect(() => {
    setValues(values => {
      const enableResetTenancyButton = isLessorsResetTenancyAvailable({
        partyBook: values.partyBook,
        lessors: values.lessors,
        lessorsOnTitle: values.lessorsOnTitle,
        precedingData: values.precedingData
      });
      if (values.precedingData.manualOverride !== enableResetTenancyButton) {
        return {
          ...values,
          precedingData: { ...values.precedingData, manualOverride: enableResetTenancyButton }
        };
      }
      return values;
    });
  }, [
    // if any of the following values has changed, we need to recalculate the reset tenancy button availability
    values.partyBook,
    values.lessors,
    values.lessorsOnTitle,
    values.precedingData,
    setValues
  ]);

  // reset tenancy should restore lessors section to the state that
  // ...it only contains lessors from currently selected titles and originally sourced from title
  const handleOnTenancyReset = React.useCallback(() => {
    setValues(values => {
      return {
        ...generateLessors(values, !INCLUDE_CUSTOM_PARTIES)
      };
    });
  }, [
    //
    setValues
  ]);

  const selectedTitles = getSelectedTitleReferences(values.titleReferences);
  if (selectedTitles.length === 0) {
    return (
      <Section //
        title={`${NSW_LEASE_LESSOR_TENANCY_COMPONENT_LABEL_CONFIG.partyRole}s`}
        className="mb-[10px]"
        data-name={name}
      >
        <Typography className="text-sm italic p-5">Please select a title reference to identify the {NSW_LEASE_LESSOR_TENANCY_COMPONENT_LABEL_CONFIG.partyRole}</Typography>
      </Section>
    );
  }

  return (
    <SectionGenericTenancy //
      name={name}
      focusRef={focusRef}
      partyFormConfig={NSW_LEASE_PARTY_FORM_CONFIG}
      disablePartyEditConfig={disablePartyFieldEditConfig()}
      componentLabels={NSW_LEASE_LESSOR_TENANCY_COMPONENT_LABEL_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
      // enable adding new parties
      tenancyPartiesMode="dynamic"
      // enable tenancy reset
      onTenancyReset={values.precedingData.manualOverride ? handleOnTenancyReset : undefined}
      precedingData={values.precedingData}
    />
  );
}

export default React.memo(SectionLessors);
