import React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { TenancyChangeMessageConfigurator } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { PrecedingData } from '@sympli-mfe/document-forms-framework/core/prefill/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedTenancyDetailModel } from '../../../../components/deceased-tenancy-detail/models';
import { NoticeOfDeath_2_21_2_Model } from '../../models';
import { calculateCrossDocumentMatches } from '../../utils/calculateCrossDocumentMatches';
import DeceasedProprietorArray from './components/deceased-proprietor-array';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP } from './visibilityChecks';

type FormModel = NoticeOfDeath_2_21_2_Model;

const fieldName = modelKey<FormModel>();
const deceasedTenancySectionName = fieldName('deceasedTenancyDetail');

interface Props {
  documentId: string;
  precedingData: PrecedingData;
}

function DeceasedTenancyDetail({
  //
  documentId
}) {
  const fieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(deceasedTenancySectionName);
  const formikProps = useFormikContext<FormModel>();
  const { deceasedTenancyDetail } = formikProps.values;
  const classes = useStyles();

  const { disabled, workspaceDocuments } = useDocumentContext();

  const matchingTenancies = calculateCrossDocumentMatches({
    //
    workspaceDocuments,
    documentId,
    context: formikProps.values.context,
    deceasedTenancyDetail: formikProps.values.deceasedTenancyDetail,
    partyBook: formikProps.values.partyBook
  });

  const isDeceasedProprietorGroupVisible = (deceasedTenancyDetail: DeceasedTenancyDetailModel, proprietorGroup: DeceasedProprietorGroupModel): boolean => {
    const { parties, proprietorGroupType } = proprietorGroup;
    const { tenancyType } = deceasedTenancyDetail;

    if (parties.length === 1) {
      return false;
    }

    const isJointTenants = tenancyType === TenancyTypeEnum.JointTenants;
    const isJointTenantsGroup =
      proprietorGroupType === HttpTypes.ProprietorGroupTypeEnum.JointTenantsInterse || proprietorGroupType === HttpTypes.ProprietorGroupTypeEnum.JointTenantsWithNoSurvivorship;

    return isJointTenants || isJointTenantsGroup;
  };

  const renderItem = ({ itemBinding, itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorGroupModel>) => {
    if (!isDeceasedProprietorGroupVisible(deceasedTenancyDetail, item)) {
      return null;
    }

    const numberOfPartiesInPreceedingGroups = deceasedTenancyDetail.proprietorGroups
      .slice(0, itemIndex)
      .filter(i => isDeceasedProprietorGroupVisible(deceasedTenancyDetail, i))
      .flatMap(group => group.parties ?? []).length;
    const isFirstGroup = itemIndex === 0;

    return (
      <SectionLike //
        className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
        key={itemBinding}
        data-testid={itemBinding}
      >
        <div key={itemBinding} className={classes.bottomDivider}>
          <DeceasedProprietorArray //
            matchingTenancies={matchingTenancies}
            itemBinding={itemBinding}
            numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
          />
        </div>
      </SectionLike>
    );
  };

  return (
    <DocumentFieldArray //
      arrayBinding={fieldName('proprietorGroups')}
      renderItem={renderItem}
      disabled={disabled}
      itemStyle="none"
      mode="fixed"
    />
  );
}

function SectionDeceasedTenancyDetail({ documentId, precedingData }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { deceasedTenancyDetail } = formikProps.values;
  const { proprietorGroups } = deceasedTenancyDetail;
  const sectionErrorMessage = getFormikError(formikProps, deceasedTenancySectionName);
  const sectionTitle = `Deceased joint ${pluralize('tenant', proprietorGroups.flatMap(group => group.parties).length)}`;
  const tenancyChangeMessageConfig = TenancyChangeMessageConfigurator.configure(false, { ...precedingData, titlesUsed: [], newProprietors: [] });

  return (
    <Section //
      title={sectionTitle}
      className={classNames(proprietorGroups.length && classes.section)}
      error={sectionErrorMessage}
      info={tenancyChangeMessageConfig.messages.find(m => m.type === 'info')?.message}
      data-name={deceasedTenancySectionName}
      data-testid={deceasedTenancySectionName}
    >
      {!VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP(deceasedTenancyDetail) ? (
        <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
      ) : (
        <DeceasedTenancyDetail //
          documentId={documentId}
        />
      )}
    </Section>
  );
}

export default React.memo(SectionDeceasedTenancyDetail);
