import { ActionMeta } from 'redux-actions';
import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';
import { HandleActionMeta } from '@sympli/ui-framework/actions';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';

import { jurisdictionNameMapping } from 'src/@core/models/jurisdictions';
import { sdk } from 'src/utils/http';
import { fetchJurisdictions } from '../../subscriber-profile/sagas/jurisdictions';
import {
  // actionDeleteUser,
  actionFetchUserDetails,
  actionFetchUserLinkedDevicesFeed,
  actionFetchUserPermissions,
  actionFetchUserReportsPermissions,
  actionFetchUsersFeed,
  actionUpdateSuspendConfirmDialog,
  actionUpdateUserDetails,
  DELETE_USER_LINKED_DEVICE,
  DeleteUserLinkedDeviceRequestModel,
  UPDATE_USER_SUSPENSION,
  UpdateUserSuspensionRequestModel,
  UsersApiRequest
} from '../actions/users';
import { DEFAULT_USER_DETAILS } from '../users-detail/detail/models';

const fetchUsersFeed = (query: UsersApiRequest): Promise<HttpTypes.SettingsUserListApiResponse> => {
  // const uri = '/settings/users';
  const { sortBy, sortOrder, pageNumber, pageSize, search, role, status, groupIds } = query;

  return sdk.settings.user.list({
    orderBy: sortBy,
    sortOrder: sortOrder ? (sortOrder === SortDirectionEnum.asc ? HttpTypes.SortOrderEnum.Asc : HttpTypes.SortOrderEnum.Desc) : undefined,
    pageNumber,
    pageSize,
    groupIds,
    accountStatusId: status,
    roleId: role,
    freeSearchTerm: search
  });

  // return http.get<UsersApiResponse>(uri + '?' + q);
};

function* sagaFetchUsersFeed(action: ReturnType<typeof actionFetchUsersFeed.request>) {
  try {
    const data = yield* call(fetchUsersFeed, action.payload);
    yield put(actionFetchUsersFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchUsersFeed.failure({ error }));
  }
}

const fetchUserDetail = (userId: string): Promise<HttpTypes.SettingsUserApiResponse> => sdk.settings.user.retrieve(userId);

function* sagaFetchUsersDetail(action: ReturnType<typeof actionFetchUserDetails.request>) {
  try {
    let data = DEFAULT_USER_DETAILS;

    if (action.payload === 'new') {
      const jurisdictions = yield* call(fetchJurisdictions);
      if (jurisdictions.length === 1) {
        data = { ...data, timezone: jurisdictionNameMapping[jurisdictions[0]] };
      }
    } else {
      const userDetails = yield* call(fetchUserDetail, action.payload);
      data = { ...data, ...userDetails };
    }

    yield put(actionFetchUserDetails.success({ data }));
  } catch (error) {
    yield put(actionFetchUserDetails.failure({ error }));
  }
}

const fetchUserPermissionsDetail = (userId: string): Promise<HttpTypes.SettingsUserPermissionsApiResponse> => sdk.settings.user.retrievePermissions(userId);

function* sagaFetchUserPermissionsDetail(action: ReturnType<typeof actionFetchUserPermissions.request>) {
  try {
    const data = yield* call(fetchUserPermissionsDetail, action.payload);

    yield put(actionFetchUserPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchUserPermissions.failure({ error }));
  }
}

const updateUserSuspension = (payload: UpdateUserSuspensionRequestModel): Promise<HttpTypes.ResponseNotDefined> => {
  // const { id: userId, isSuspended, httpConfig } = payload;
  // const uri = `/settings/users/${encodeURIComponent(userId)}/suspension`;
  // return http.post<UpdateUserSuspensionRequestModel>(uri, { isSuspended }, httpConfig);
  return sdk.settings.user.updateUserSuspension(
    payload.id,
    {
      isSuspended: payload.isSuspended
    },
    payload.httpConfig
  );
};

function* sagaUpdateUserSuspension(action: ActionMeta<UpdateUserSuspensionRequestModel, HandleActionMeta>) {
  try {
    const {
      payload: { id, isSuspended }
    } = action;
    yield* call(updateUserSuspension, action.payload);
    yield put(action.meta.createSuccess({}));
    yield put(actionUpdateUserDetails.request({ id, isSuspended }));
    yield put(actionUpdateSuspendConfirmDialog({ isOpen: false }));
  } catch (error) {
    yield put(action.meta.createError(error));
  }
}

// const deleteUser = (userId: DeleteUserRequestModel) => {
//   const uri = `/settings/users/${encodeURIComponent(userId)}`;
//   return http.delete(uri);
// };

// function* sagaDeleteUser(action: ReturnType<typeof actionDeleteUser.request>) {
//   try {
//     const data = yield* call(deleteUser, action.payload);

//     yield put(actionDeleteUser.success({ data }));
//   } catch (error) {
//     yield put(actionDeleteUser.failure({ error }));
//   }
// }

const fetchUserLinkedDevicesFeed = (userId: string): Promise<HttpTypes.SettingsUserDeviceListApiResponse> => sdk.settings.user.listLinkedDevices(userId);

function* sagaFetchUserLinkedDevicesFeed(action: ReturnType<typeof actionFetchUserLinkedDevicesFeed.request>) {
  try {
    const data = yield* call(fetchUserLinkedDevicesFeed, action.payload);
    yield put(actionFetchUserLinkedDevicesFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchUserLinkedDevicesFeed.failure({ error }));
  }
}

const deleteUserLinkedDevice = (payload: DeleteUserLinkedDeviceRequestModel): Promise<HttpTypes.SettingsRemoveUserDeviceApiResponse> => {
  const { userId, deviceId, httpConfig } = payload;
  // const uri = `/settings/users/${encodeURIComponent(userId)}/devices/${encodeURIComponent(deviceId)}/unlink`;
  // return http.put<DeleteUserLinkedDeviceRequestModel>(uri, {}, httpConfig);
  return sdk.settings.user.removeLinkedDevice(userId, deviceId, httpConfig);
};

function* sagaDeleteUserLinkedDevice(action: ActionMeta<DeleteUserLinkedDeviceRequestModel, HandleActionMeta>) {
  try {
    yield* call(deleteUserLinkedDevice, action.payload);
    yield put(action.meta.createSuccess({ data: action.payload }));
  } catch (error) {
    yield put(action.meta.createError(error));
  }
}

const fetchUserReportsPermissionsDetail = (userId: string): Promise<HttpTypes.SettingsUserReportPermissionsApiResponse> => {
  return sdk.settings.user.retrieveReportPermissions(userId);
};

function* sagaFetchUserReportsPermissionsDetail(action: ReturnType<typeof actionFetchUserReportsPermissions.request>) {
  try {
    const data = yield* call(fetchUserReportsPermissionsDetail, action.payload);

    yield put(actionFetchUserReportsPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchUserReportsPermissions.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchUsersFeed.request, sagaFetchUsersFeed),
  takeLatest(actionFetchUserDetails.request, sagaFetchUsersDetail),
  takeLatest(actionFetchUserPermissions.request, sagaFetchUserPermissionsDetail),
  // takeLatest(actionDeleteUser.request, sagaDeleteUser),
  takeLatest(actionFetchUserLinkedDevicesFeed.request, sagaFetchUserLinkedDevicesFeed),
  takeLatest(DELETE_USER_LINKED_DEVICE.ACTION, sagaDeleteUserLinkedDevice),
  takeLatest(UPDATE_USER_SUSPENSION.ACTION, sagaUpdateUserSuspension),
  takeLatest(actionFetchUserReportsPermissions.request, sagaFetchUserReportsPermissionsDetail)
];
