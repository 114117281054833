import * as React from 'react';

import { useDispatch } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';

import { HttpTypes } from '@sympli/api-gateway/types';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconError } from '@sympli/ui-framework/icons';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { useSafeDispatch } from 'src/hooks';
import { actionFetchWorkspaceParticipantActivityLogs } from 'src/store/actions/workspace';
import { useWorkspaceBasicInfo } from '../../../../../../store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from '../../../../../../store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipantActivityLogs } from '../../../../../../store/reducers/workspace/workspaceParticipantActivityLogs';
import { useWorkspaceParticipants } from '../../../../../../store/reducers/workspace/workspaceParticipants';
import { actionOpenLodgementVerificationDialogWithContext } from '../../../components/lodgement-verification-dialog/actions';
import { useStyles } from './styles';
import TabLogs from './TabLogs';

interface Props {
  workspaceId: string;
  participantId: string;
}

function TabLogsContainer({ workspaceId, participantId }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const classes = useStyles();

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const workspaceActivityLogsState = useWorkspaceParticipantActivityLogs(workspaceId, participantId);
  const isLinkDisabled =
    workspaceBasicInfoState.detail?.workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Abandoned ||
    workspaceBasicInfoState.detail?.workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Withdrawn;

  const isLoading = workspaceBasicInfoState.isLoading || workspaceParticipantsState.isLoading || workspaceActivityLogsState.isLoading || workspaceDetailState.isLoading;

  const lodgementCases = workspaceDetailState.detail?.lodgementCases ?? [];

  const {
    error,
    loadedCount,
    totalCount,
    items,
    query: { pageNumber }
  } = workspaceActivityLogsState;

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceParticipantActivityLogs.request({ workspaceId, participantId, pageNumber: 1 }));
  }, [dispatch, workspaceId, participantId]);

  const handleOnLoadMoreClick = React.useCallback(() => {
    dispatch(actionFetchWorkspaceParticipantActivityLogs.request({ workspaceId, participantId, pageNumber: pageNumber + 1 }));
  }, [dispatch, workspaceId, participantId, pageNumber]);

  const onShowLodgementVerificationDialog = React.useCallback(
    (authorityMessageId?: string, authorityComplianceReportType?: HttpTypes.AuthorityComplianceReportTypeEnum, lodgementCaseId?: string) => {
      dispatch(actionOpenLodgementVerificationDialogWithContext({ authorityMessageId, authorityComplianceReportType, lodgementCaseId }));
    },
    [dispatch]
  );

  const onAccessDenied = React.useCallback(() => {
    dispatch(
      actionCreateGlobalErrorMessage({
        //
        message: 'You have withdrawn or abandoned this workspace. As you are no longer a participant, you cannot access data entered in this workspace.',
        title: 'Access denied',
        icon: <IconError />
      })
    );
  }, [dispatch]);

  function renderContent() {
    if (loadedCount === 0) {
      return null;
    }

    return (
      <TabLogs
        items={items}
        workspaceId={workspaceId}
        participantId={participantId}
        // the parent guaranties that this component will be rendered only if workspaceBasicInfoState was successfully loaded
        workspaceStatus={workspaceBasicInfoState.detail!.workspaceStatusId}
        onShowLodgementVerificationDialog={onShowLodgementVerificationDialog}
        onAccessDenied={onAccessDenied}
        isLinkDisabled={isLinkDisabled}
        lodgementCases={lodgementCases}
      />
    );
  }

  if (error) {
    return (
      <FormHelperText role="alert" error>
        {error}
      </FormHelperText>
    );
  }

  if (isLoading) {
    return <InlineLoader alignCenter />;
  }

  // user is allowed to see workspace logs even if they have abandoned the workspace

  return (
    <>
      {renderContent()}
      {loadedCount < totalCount && (
        <SympliButton
          className={classes.loadButton}
          type="button"
          color="primary"
          variant="outlined"
          onClick={handleOnLoadMoreClick}
          disabled={isLoading || workspaceActivityLogsState.isRefetching}
          isLoading={workspaceActivityLogsState.isRefetching}
        >
          See older activity
        </SympliButton>
      )}
    </>
  );
}

export default TabLogsContainer;
