import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiWithdrawalOfNomination_Model, WithdrawalOfNomination_Model } from './models';

class Converter implements IConverter<WithdrawalOfNomination_Model, ApiWithdrawalOfNomination_Model> {
  fromApiToFormModel(apiModel: ApiWithdrawalOfNomination_Model, context: IConverterContext): WithdrawalOfNomination_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: WithdrawalOfNomination_Model, _originalApiModel: ApiWithdrawalOfNomination_Model): ApiWithdrawalOfNomination_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
