import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionFetchWorkspaceAssignableGroups } from 'src/@core/store/actions/workspaceAssignableGroups';
import { useWorkspaceAssignableGroups, WorkspaceAssignableGroupsState } from 'src/@core/store/reducers/workspaceAssignableGroups';
import { useRolePermission, useSafeDispatch } from 'src/hooks';
import { actionFetchSubscriberAssignableGroups, actionFetchSupportedJurisdictions } from '../../shared/actions';
import AssignableGroupsIssueDialog from './dialogs/assignable-groups-issue-dialog';
import CreateNewWorkspaceDialog from './dialogs/create-new-workspace-dialog';
import { useStyles } from './styles';
import { useWorkspaceTypeOptions } from './useWorkspaceTypeOptions';

function CreateNewWorkspaceContainer() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());

  const workspaceAssignableGroups: WorkspaceAssignableGroupsState = useWorkspaceAssignableGroups();
  const canManageGroupPermission: boolean = useRolePermission(HttpTypes.RolePermissionsEnum.ManageGroups);
  const workspaceTypeOptions: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[] = useWorkspaceTypeOptions();

  React.useEffect(() => {
    batch(() => {
      dispatch(actionFetchWorkspaceAssignableGroups.request());
      dispatch(actionFetchSupportedJurisdictions.request());
      dispatch(actionFetchSubscriberAssignableGroups.request());
    });
  }, [dispatch]);

  const handleOnClose = React.useCallback(
    (link?: string) => {
      // always close dialog when handleOnClose is called
      setIsOpen(false);
      if (typeof link === 'string') {
        navigate(link);
      }
    },
    [navigate, setIsOpen]
  );
  const disabled = workspaceAssignableGroups.isLoading || workspaceAssignableGroups.status === 'rejected';

  return (
    <>
      <SympliButton //
        disabled={disabled}
        color="primary"
        variant="contained"
        className={classes.createWorkspaceButton}
        onClick={() => setIsOpen(true)}
      >
        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
          <path //
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.8002 0.200195V3.8002H0.200195V6.2002H3.8002V9.8002H6.2002V6.2002H9.8002V3.8002H6.2002V0.200195H3.8002Z"
            fill="white"
          />
        </svg>{' '}
        Create New
      </SympliButton>
      {workspaceAssignableGroups.items.length ? (
        <CreateNewWorkspaceDialog //
          isOpen={isOpen}
          onClose={handleOnClose}
          workspaceTypeOptions={workspaceTypeOptions}
        />
      ) : (
        <AssignableGroupsIssueDialog //
          isOpen={isOpen}
          onClose={handleOnClose}
          canManageGroupPermission={canManageGroupPermission}
        />
      )}
    </>
  );
}

export default React.memo(CreateNewWorkspaceContainer);
