import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { FormikHelpers } from 'formik';
import _debounce from 'lodash-es/debounce';
import _isEqual from 'lodash-es/isEqual';
import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Secured from 'src/@core/auth/profile-security/Secured';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useWorkspaceAssignableGroups, WorkspaceAssignableGroupsState } from 'src/@core/store/reducers/workspaceAssignableGroups';
import DashboardWrapper from 'src/components/layout/v2/DashboardWrapper';
import Dialog from 'src/containers/dashboard/components/dialog/Dialog';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { useSafeDispatch, useScreenSize, useUserPermission } from 'src/hooks';
import { DashboardTypeEnum } from 'src/models';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme';
import { AxiosError } from 'src/utils/http';
import CreateNewWorkspaceContainer from '../components/create-new-workspace';
import { actionFetchGroupMembers, actionFetchUserJurisdictions } from '../shared/actions';
import { DEBOUNCE_LIMIT } from '../shared/helpers';
import { usePermissionDialog } from '../shared/hooks/usePermissionDialog';
import useShowMessage from '../shared/hooks/useShowMessage';
import { GroupMembersState, useGroupMembers } from '../shared/reducers/groupMembers';
import { SubscriberAssignableGroupsState, useSubscriberAssignableGroups } from '../shared/reducers/subscriberAssignableGroups';
import { UserJurisdictionsState, useUserJurisdictions } from '../shared/reducers/userJurisdictions';
import { actionFetchInvitationsFeedV2, actionFetchInvitationsSubscribers, showInvitationsDashboardFeedDetail } from './actions';
import { removeLocalStorageSavedModel, updateLocalStorage } from './aggregators/helper';
import { fetchAggregatorInfo, forwardInvitationToGroup, forwardInvitationToSubscriber, respondToInvitation } from './api';
import { AcceptInviteFormValues, AcceptInviteSubmitType } from './components/modal-dialog-content/AcceptInviteForm';
import { DeclineInviteFormValues } from './components/modal-dialog-content/DeclineInviteForm';
import { ForwardInviteToGroupFormValues } from './components/modal-dialog-content/ForwardInviteToGroupForm';
import { ForwardInviteToSubscriberFormValues } from './components/modal-dialog-content/ForwardInviteToSubscriberForm';
import ModalDialogContent from './components/modal-dialog-content/ModalDialogContent';
import { defaultDashboardFilterModel, getFiltersWithSelectedTab } from './filters/helper';
import { defaultTabQuery, getDefaultAssignmentTypeTab, InvitationSelectedTabMapping, resolveSavedTabFiltersQuery } from './helper';
import InvitationsDashboardV2 from './InvitationsDashboard';
import InvitationsDashboardContext from './InvitationsDashboard.context';
import InvitationsDashboardRowContainer from './InvitationsDashboardRowContainer';
import { getSuccessMessage as getSuccessNotification, getWarningNotification, SuccessMessageEnum, WarningMessageEnum } from './messages';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from './models';
import { useInvitationsFeedV2 } from './reducers/invitationsFeedV2';
import { InvitationSubscriberSearchState, useInvitationSubscribersSearchState } from './reducers/invitationSubscribersSearch';
import { InvitationsTableRowModel } from './table/models';

function InvitationsDashboardContainer() {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const dispatchErrorDialog = usePermissionDialog('invitations');
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([]);
  const [isRefetchingData, setIsRefetchingData] = React.useState<boolean>(false);
  const [currentUpdatedTime, setCurrentUpdatedTime] = React.useState<Date>(new Date());
  const [receivedTabDialogRowItem, setReceivedTabDialogRowItem] = React.useState<InvitationsTableRowModel | undefined>(undefined);

  const [selectedTab, setSelectedTab] = React.useState<InvitationsDashboardRouteTabsEnumV2>(InvitationsDashboardRouteTabsEnumV2.received);
  const { query, totalCount, items, rowDetailIndex, status } = useInvitationsFeedV2();
  // assigned to group is always there for the group value
  const workspaceAssignableGroupsState: WorkspaceAssignableGroupsState = useWorkspaceAssignableGroups();
  const subscriberAssignableGroupsState: SubscriberAssignableGroupsState = useSubscriberAssignableGroups();
  const userJurisdictionsState: UserJurisdictionsState = useUserJurisdictions();
  const groupMembersState: GroupMembersState = useGroupMembers();
  const subscribersSearchState: InvitationSubscriberSearchState = useInvitationSubscribersSearchState();

  const defaultGroup = workspaceAssignableGroupsState.items.length > 0 ? workspaceAssignableGroupsState.items[0].id : undefined;
  const { userId, userPermissions } = useProfile().data!;
  const hasManageInvitationPermission = userPermissions.includes(HttpTypes.UserLevelPermissionEnum.ManageInvitations);

  // localStorageKey is for storing filter config
  const localStorageKey = `${userId}_InvitationDashboard`;
  const uniqueStorageKeyForWelcomeMessage = `INVITATION_DB_${userId}`;
  const uniqueStorageKeyForRefreshMessage = `INVITATION_DB_REFRESH_${userId}`;

  const assignmentTypeLocalStorageKey = `${userId}_invitationDashboard_assignmentType`;
  const [assignmentType, setAssignmentType] = React.useState<AssignmentTypeEnum>(getDefaultAssignmentTypeTab(assignmentTypeLocalStorageKey));

  const [selectedFilters, hasSavedValue] = useMemo(() => getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType), [localStorageKey, selectedTab, assignmentType]);
  // this is filters we need to persistent during all actions
  // as aggregators can impact filters (override), so it is always top down actions
  const [currentTabFilters, setCurrentTabFilters] = React.useState(selectedFilters);

  const isUserAllowedToManageWorkspace = useUserPermission(HttpTypes.UserLevelPermissionEnum.ManageInvitations);
  // reset checkbox values
  const resetSelectedItems = () => {
    setSelectedIndexes([]);
  };

  const sorting: {
    sortBy: string | undefined;
    sortOrder: DashboardSortDirectionEnum | undefined;
  } = useMemo(() => {
    const { sortBy, sortOrder }: InvitationsDashboardV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);

    return {
      sortBy: query.sortBy !== undefined ? query.sortBy : sortBy,
      sortOrder: query.sortOrder !== undefined ? query.sortOrder : sortOrder
    };
  }, [selectedTab, assignmentType, defaultGroup, query.sortBy, query.sortOrder]);

  const fetchInitialData = React.useCallback(
    (assignmentType: AssignmentTypeEnum, defaultGroup?: string) => {
      const { sortBy, sortOrder }: InvitationsDashboardV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);
      const filter = hasSavedValue
        ? {
            ...resolveSavedTabFiltersQuery(selectedFilters),
            sortBy,
            sortOrder
          }
        : defaultTabQuery(selectedTab, assignmentType, defaultGroup);

      if ((status === 'pending' || status === 'refetching') && _isEqual(query, filter)) {
        return;
      }

      dispatch(actionFetchInvitationsFeedV2.request(filter));
    },
    [dispatch, hasSavedValue, selectedFilters, selectedTab]
  );

  useEffect(() => {
    // defaultGroup is only used for Reassign Tab, other tabs don't need to listen to the change of defaultGroup
    if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      fetchInitialData(assignmentType, defaultGroup);
    }
  }, [assignmentType, defaultGroup, fetchInitialData]);

  useEffect(() => {
    if (assignmentType !== AssignmentTypeEnum.ReAssigned) {
      fetchInitialData(assignmentType);
    }
  }, [fetchInitialData, assignmentType]);

  useEffect(() => {
    if (currentTabFilters.groupIds?.length === 0 && assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      setCurrentTabFilters({ ...currentTabFilters, groupIds: [defaultGroup] });
    }
  }, [currentTabFilters, assignmentType, defaultGroup]);

  useEffect(() => {
    const invitationType = InvitationSelectedTabMapping[selectedTab];

    dispatch(
      actionFetchInvitationsSubscribers.request({
        assignmentType,
        invitationType
      })
    );
  }, [dispatch, assignmentType, selectedTab]);

  useEffect(() => {
    batch(() => {
      dispatch(actionFetchUserJurisdictions.request());
      dispatch(actionFetchGroupMembers.request());
    });
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab !== InvitationsDashboardRouteTabsEnumV2.received) {
      setReceivedTabDialogRowItem(undefined);
    }
  }, [selectedTab]);

  const fetchData = useCallback(
    (updatedFilter: Partial<InvitationsDashboardV2ApiRequest>) => {
      const newQuery: InvitationsDashboardV2ApiRequest = {
        ...query,
        ...updatedFilter,
        assignmentType
      };

      dispatch(actionFetchInvitationsFeedV2.request(newQuery));
    },
    [query, assignmentType, dispatch]
  );
  const fetchDataWithDebounce = useMemo(() => _debounce(fetchData, DEBOUNCE_LIMIT), [fetchData]);

  const refreshTableData = useCallback(() => {
    dispatch(actionFetchInvitationsFeedV2.request(query));
  }, [dispatch, query]);

  const handleOnAssignmentChange = useCallback(
    (assignmentType: AssignmentTypeEnum) => {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'assignment-tab',
        logGroupId: `${DashboardTypeEnum.Invitation}-dashboard`,
        tabName: `${assignmentType}`
      });

      setAssignmentType(assignmentType);
      setSelectedTab(InvitationsDashboardRouteTabsEnumV2.received);
      localStorage.setItem(assignmentTypeLocalStorageKey, assignmentType.toString());
      const [selectedFilters] = getFiltersWithSelectedTab(localStorageKey, InvitationsDashboardRouteTabsEnumV2.received, assignmentType);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [assignmentTypeLocalStorageKey, localStorageKey]
  );

  const handleOnInvitationTypeTabChange = useCallback(
    (selectedTab: InvitationsDashboardRouteTabsEnumV2, skipLogging?: boolean) => {
      if (!skipLogging) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'filter-tab',
          logGroupId: `${DashboardTypeEnum.Invitation}-dashboard`,
          tabName: `${selectedTab}`
        });
      }
      setSelectedTab(selectedTab);
      // have to reset this as the current filters been in state
      const [selectedFilters, _] = getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [setSelectedTab, localStorageKey, assignmentType]
  );

  const handleOnAddFilterUpdate = useCallback(
    (currentTabFilters: InvitationsDashboardUiFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...currentTabFilters });
    },
    [localStorageKey]
  );

  const handleOnTableFilterChange = useCallback(
    (updatedFilters: InvitationsDashboardV2ApiRequest, currentTabFilters: InvitationsDashboardUiFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);
      resetSelectedItems();
      if (typeof updatedFilters.settlementPeriod === 'string' && updatedFilters.settlementPeriod === 'AllDates') {
        updatedFilters.settlementPeriod = null;
      }

      if (typeof updatedFilters.lastInvitationSentPeriod === 'string' && updatedFilters.lastInvitationSentPeriod === 'AllDates') {
        updatedFilters.lastInvitationSentPeriod = null;
      }

      if (typeof updatedFilters.archivedDatePeriod === 'string' && updatedFilters.archivedDatePeriod === 'AllDates') {
        updatedFilters.archivedDatePeriod = null;
      }

      // state update so force the filter component update
      setCurrentTabFilters({ ...currentTabFilters });

      fetchDataWithDebounce({
        ...query, // merge current query
        ...updatedFilters,
        pageNumber: 1
      });
    },
    [fetchDataWithDebounce, localStorageKey, query]
  );

  const handleOnAggregatorActionClick = useCallback(
    (
      //
      filters: InvitationsDashboardV2ApiRequest,
      engagedTab: InvitationsDashboardRouteTabsEnumV2,
      currentTabFilters: InvitationsDashboardUiFilterModel
    ) => {
      // for this one we should always fire the call direction instead of getting existing query cached in redux
      if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
        filters.groupIds = [defaultGroup];
        currentTabFilters.groupIds = [defaultGroup];
      }
      updateLocalStorage(localStorageKey, currentTabFilters);
      //setCurrentTabFilters({ ...currentTabFilters });
      handleOnInvitationTypeTabChange(engagedTab, true);

      // must manually fire the call since since tab will not trigger the api call
      if (selectedTab === engagedTab) {
        dispatch(actionFetchInvitationsFeedV2.request(filters));
      }

      resetSelectedItems();
    },
    [localStorageKey, handleOnInvitationTypeTabChange, assignmentType, defaultGroup, selectedTab, dispatch]
  );

  const handleOnPageChange = useCallback(
    (args: { pageNumber: number }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
      if (selectedIndexes.length > 0) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            ...getWarningNotification(WarningMessageEnum.NoteReassign),
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );
      }
    },
    [fetchData, assignmentType, selectedIndexes, dispatch]
  );

  const handleOnSortChange = useCallback(
    (args: { sortBy: string; sortOrder: DashboardSortDirectionEnum }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
    },
    [fetchData, assignmentType]
  );

  const handleOnRowToggle = React.useCallback(
    (newRowDetailIndex: number) => {
      if (rowDetailIndex !== newRowDetailIndex) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'invitation-expansion-panel-open',
          logGroupId: 'dashboard'
        });
      }

      dispatch(
        showInvitationsDashboardFeedDetail({
          rowDetailIndex: rowDetailIndex === newRowDetailIndex ? undefined : newRowDetailIndex
        })
      );
    },
    [dispatch, rowDetailIndex]
  );

  let rowDetailNode: React.ReactNode = null;

  if (selectedTab === InvitationsDashboardRouteTabsEnumV2.archived) {
    rowDetailNode = <InvitationsDashboardRowContainer />;
  }

  const handleOnTableFiltersReset = useCallback(() => {
    removeLocalStorageSavedModel(localStorageKey, selectedTab, assignmentType);
    setCurrentTabFilters(defaultDashboardFilterModel(selectedTab, assignmentType));
    // fire the default api call as we cleanup all the filters
    dispatch(actionFetchInvitationsFeedV2.request(defaultTabQuery(selectedTab, assignmentType, defaultGroup)));
    resetSelectedItems();
  }, [localStorageKey, selectedTab, assignmentType, dispatch, defaultGroup]);

  const [shouldShowWelcomeMessage, dismissWelcomeMessageHandler] = useShowMessage(uniqueStorageKeyForWelcomeMessage);
  const [shouldShowRefreshMessage, dismissRefreshIconMessageHandler] = useShowMessage(uniqueStorageKeyForRefreshMessage);

  // Reassign handlers
  const handleOnDismissReassignBar = (event: React.MouseEvent) => {
    resetSelectedItems();
  };

  const refreshHandler = React.useCallback(() => {
    setIsRefetchingData(true);
    refreshTableData();
  }, [refreshTableData]);

  React.useEffect(() => {
    if (isRefetchingData && (status === 'resolved' || status === 'rejected')) {
      setIsRefetchingData(false);
    }
  }, [status, isRefetchingData]);

  const resetCurrentUpdatedTime = React.useCallback((date: Date) => {
    setCurrentUpdatedTime(date);
  }, []);

  const handleOnRowClick = useCallback(
    (rowIndex: number, rowData: InvitationsTableRowModel) => {
      if (assignmentType === AssignmentTypeEnum.ReAssigned) {
        hasManageInvitationPermission
          ? setSelectedIndexes(selectedIndexes.includes(rowIndex) ? selectedIndexes.filter(x => x !== rowIndex) : selectedIndexes.concat([rowIndex]))
          : dispatchErrorDialog();
      } else {
        const invitationId = items[rowIndex]?.participantId;
        if (!isUserAllowedToManageWorkspace) {
          dispatch(
            actionCreateGlobalErrorMessage({
              title: 'User Permission Required',
              message: 'You do not have permission to send, manage or withdraw invitations. Please contact your admin for more information on managing workspaces.'
            })
          );
          return;
        }

        if (invitationId) {
          setReceivedTabDialogRowItem(items[rowIndex]);
        }
      }
    },
    [assignmentType, dispatch, dispatchErrorDialog, hasManageInvitationPermission, isUserAllowedToManageWorkspace, items, selectedIndexes]
  );

  const handleOnReceivedTabDialogClose = useCallback(() => {
    setReceivedTabDialogRowItem(undefined);
  }, []);

  const handleOnDialogError = useCallback(
    (e: any) => {
      const axiosError = e as AxiosError;
      const defaultMsg = getWarningNotification(axiosError.response?.status === 403 ? WarningMessageEnum.AccessDenied : WarningMessageEnum.SomethingWentWrong);

      if (axiosError.response?.status === 403 || axiosError.response?.status === 400) {
        // if backend returns the msg we use it, otherwise use the generic one
        const msg = (axiosError.response?.data as any)?.message;
        const displayMsg = msg ? { message: msg } : { ...defaultMsg };

        dispatch(
          actionOpenGlobalSimpleNotification({
            ...displayMsg,
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );

        refreshTableData();
      } else {
        dispatch(
          actionOpenGlobalSimpleNotification({
            ...defaultMsg,
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );
      }
    },
    [dispatch, refreshTableData]
  );

  const handleOnAcceptInvite = useCallback(
    //
    async (data: AcceptInviteFormValues, type: AcceptInviteSubmitType) => {
      try {
        await respondToInvitation({
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          reference: data.reference,
          groupId: data.groupId,
          invitationResponseTypeId: HttpTypes.InvitationResponseTypeEnum.Accepted,
          acceptInviteOnly: !data.hasAcceptedSettlementDate
        });

        dispatch(
          actionOpenGlobalSimpleNotification({
            ...getSuccessNotification(SuccessMessageEnum.InvitationAccepted, data.reference),
            variant: 'new-success',
            autoHideDuration: 5000
          })
        );

        if (type === 'accept-and-view') {
          navigate(
            resolveWorkspaceDetailLink({
              workspaceId: data.workspaceId,
              participantId: data.invitationId
            })
          );
        } else {
          refreshHandler();
          handleOnReceivedTabDialogClose();
        }
      } catch (e) {
        handleOnDialogError(e);
      }
    },
    [dispatch, handleOnReceivedTabDialogClose, navigate, refreshHandler, handleOnDialogError]
  );
  const handleOnDeclineInvite = useCallback(
    //
    async (data: DeclineInviteFormValues) => {
      try {
        await respondToInvitation({
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          reason: data.note,
          invitationResponseTypeId: HttpTypes.InvitationResponseTypeEnum.Declined
        });
        refreshHandler();
        dispatch(
          actionOpenGlobalSimpleNotification({
            ...getSuccessNotification(SuccessMessageEnum.InvitationDeclined),
            variant: 'new-success',
            autoHideDuration: 5000
          })
        );
        handleOnReceivedTabDialogClose();
      } catch (e) {
        handleOnDialogError(e);
      }
    },
    [dispatch, handleOnReceivedTabDialogClose, refreshHandler, handleOnDialogError]
  );
  const handleOnForwardInviteToGroup = useCallback(
    //
    async (data: ForwardInviteToGroupFormValues) => {
      try {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'forward-invitation-internally',
          logGroupId: 'dashboard',
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          groupId: data.groupId
        });

        await forwardInvitationToGroup({
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          groupId: data.groupId
        });
        refreshHandler();
        dispatch(
          actionOpenGlobalSimpleNotification({
            ...getSuccessNotification(SuccessMessageEnum.InvitationForwarded, {
              //
              groupName: data.groupName
            }),
            variant: 'new-success',
            autoHideDuration: 5000
          })
        );
        handleOnReceivedTabDialogClose();
      } catch (e) {
        handleOnDialogError(e);
      }
    },
    [dispatch, handleOnReceivedTabDialogClose, refreshHandler, handleOnDialogError]
  );

  const handleOnForwardInviteToSubscriber = useCallback(
    //
    async (data: ForwardInviteToSubscriberFormValues, formikHelpers: FormikHelpers<ForwardInviteToSubscriberFormValues>) => {
      try {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'forward-invitation-externally',
          logGroupId: 'dashboard',
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          forwardedSubscriberDisplayName: data.forwardedSubscriberDisplayName,
          forwardedSubscriberElnoId: data.forwardedSubscriberElnoId,
          forwardedSubscriberId: data.forwardedSubscriberId,
          isLinkedWorkspace: data.isLinkedWorkspace
        });

        await forwardInvitationToSubscriber({
          invitationId: data.invitationId,
          workspaceId: data.workspaceId,
          isLinkedWorkspace: data.isLinkedWorkspace,
          newInvitationDescription: data.newInvitationDescription?.trim(),
          forwardedSubscriberDisplayName: data.forwardedSubscriberDisplayName,
          forwardedSubscriberElnoId: data.forwardedSubscriberElnoId,
          forwardedSubscriberId: data.forwardedSubscriberId
        });

        refreshHandler();
        dispatch(
          actionOpenGlobalSimpleNotification({
            ...getSuccessNotification(SuccessMessageEnum.InvitationForwarded, {
              //
              groupName: data.forwardedSubscriberDisplayName
            }),
            variant: 'new-success',
            autoHideDuration: 5000
          })
        );
        handleOnReceivedTabDialogClose();
      } catch (e) {
        if (e.response?.data?.errors) {
          const { setErrors } = formikHelpers;
          setErrors(e.response.data.errors);
        } else {
          handleOnDialogError(e);
        }
      }
    },
    [dispatch, handleOnReceivedTabDialogClose, refreshHandler, handleOnDialogError]
  );

  return (
    <DashboardWrapper>
      <InvitationsDashboardContext.Provider value={{ currentUpdatedTime, resetCurrentUpdatedTime }}>
        {receivedTabDialogRowItem && (
          <Dialog //
            open={true}
            maxWidth={false}
            onClose={handleOnReceivedTabDialogClose}
          >
            {isUserAllowedToManageWorkspace && (
              <ModalDialogContent
                invitationDetail={receivedTabDialogRowItem}
                invitationAssignableGroups={workspaceAssignableGroupsState.items}
                invitationForwardableGroups={subscriberAssignableGroupsState.items}
                onAccept={handleOnAcceptInvite}
                onDecline={handleOnDeclineInvite}
                onForwardToGroup={handleOnForwardInviteToGroup}
                onForwardToSubscriber={handleOnForwardInviteToSubscriber}
                onCancel={handleOnReceivedTabDialogClose}
              />
            )}
          </Dialog>
        )}

        <InvitationsDashboardV2
          /**
           * header component props
           */
          screenSize={screenSize}
          assignmentType={assignmentType}
          onAssignmentTypeChange={handleOnAssignmentChange}
          createNewButtonNode={
            <Secured requiredUserPermissions={HttpTypes.UserLevelPermissionEnum.ManageInvitations} hiddenForDisabled>
              <CreateNewWorkspaceContainer />
            </Secured>
          }
          /**
           * aggregators component props
           */
          localStorageKey={localStorageKey}
          fetchAggregatorInfo={fetchAggregatorInfo}
          onAggregatorClick={handleOnAggregatorActionClick}
          /**
           * nav-tabs component props
           */
          invitationGroup={selectedTab}
          onInvitationTypeTabChange={handleOnInvitationTypeTabChange}
          /**
           * table filters component props
           */
          groupMembers={groupMembersState.items}
          userJurisdictions={userJurisdictionsState.items}
          subscribers={subscribersSearchState.items}
          currentTableFilters={currentTabFilters}
          onTableAddFilterChange={handleOnAddFilterUpdate}
          onTableFiltersReset={handleOnTableFiltersReset}
          onTableFiltersChange={handleOnTableFilterChange}
          /**
           * table component props
           */
          rows={items}
          apiStatus={status}
          totalCount={totalCount}
          pageSize={query.pageSize}
          pageNumber={query.pageNumber}
          sortBy={sorting.sortBy}
          sortOrder={sorting.sortOrder}
          onPageChange={handleOnPageChange}
          onSortChange={handleOnSortChange}
          onRowClick={handleOnRowClick}
          rowDetailNode={rowDetailNode}
          rowDetailIndex={rowDetailIndex}
          onRowToggle={handleOnRowToggle}
          /**
           * Reassign Welcome
           * */
          shouldShowWelcomeMessage={shouldShowWelcomeMessage && assignmentType === AssignmentTypeEnum.ReAssigned}
          dismissWelcomeMessageHandler={dismissWelcomeMessageHandler}
          /**
           * Reassign Table extra props
           */
          selectedRowIndexes={selectedIndexes}
          onSelectionChange={(selectedRowIndexes: number[]) => {
            if (!hasManageInvitationPermission) {
              dispatchErrorDialog();
            } else {
              setSelectedIndexes(selectedRowIndexes);
            }
          }}
          /**
           * Reassign bar props
           */
          shouldShowReassignBar={selectedIndexes.length > 0}
          handleOnDismissReassignBar={handleOnDismissReassignBar}
          selectedItems={items.filter((x, index) => selectedIndexes.includes(index)).map(x => ({ workspaceId: x.workspaceId, participantId: x.participantId }))}
          groupId={currentTabFilters.groupIds && currentTabFilters.groupIds.length > 0 ? currentTabFilters.groupIds[0] : defaultGroup}
          resetSelectedItems={resetSelectedItems}
          autoRefresh={refreshTableData}
          //
          refetchingAggregators={isRefetchingData}
          isRefetchingData={isRefetchingData}
          refreshHandler={refreshHandler}
          shouldShowRefreshMessage={shouldShowRefreshMessage}
          dismissRefreshIconMessageHandler={dismissRefreshIconMessageHandler}
          hasManageInvitationPermission={hasManageInvitationPermission}
        />
      </InvitationsDashboardContext.Provider>
    </DashboardWrapper>
  );
}

export default React.memo(InvitationsDashboardContainer);
