import { HttpTypes } from '@sympli/api-gateway/types';

import { AccountModel } from '../models';

export enum BankAccountEditTypeEnum {
  None,
  All,
  Partial
}

export interface BankAccountTypeMapping {
  apiAccountType: string;
  description: string;
  editType: BankAccountEditTypeEnum;
  canAdd: boolean;
  canDelete: boolean;
  linkText: string;
}

export const BANK_ACCOUNT_TYPE_MAPPINGS = new Map<HttpTypes.SubscriberBankAccountTypeEnum, BankAccountTypeMapping>([
  [
    HttpTypes.SubscriberBankAccountTypeEnum.Debit,
    {
      apiAccountType: 'debit', //
      description: 'Direct debit',
      linkText: 'Direct debit account details',
      editType: BankAccountEditTypeEnum.None,
      canAdd: false,
      canDelete: false
    }
  ],
  [
    HttpTypes.SubscriberBankAccountTypeEnum.Loan,
    {
      apiAccountType: 'loan', //
      description: 'Loan',
      linkText: 'Loan account details',
      editType: BankAccountEditTypeEnum.Partial,
      canAdd: false,
      canDelete: false
    }
  ],
  [
    HttpTypes.SubscriberBankAccountTypeEnum.General,
    {
      apiAccountType: 'general', //
      description: 'General',
      linkText: 'General account details',
      editType: BankAccountEditTypeEnum.All,
      canAdd: true,
      canDelete: true
    }
  ]
]);

export interface Enumeration<T> {
  value: T;
  description: string;
}

export interface FinancialAccount extends AccountModel {
  id: string;
  accountType: Enumeration<HttpTypes.SubscriberBankAccountTypeEnum>;
  categories: string[];
}
