import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

// Updating TAC status only
export interface LaunchTitleActivityCheckRequestModel {
  titleReference: string;
  participantId: string;
  workspaceId: string;
}

export interface ResupplyTitleInfoCostApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionLaunchTitleActivityCheck = createAsyncAction(
  //
  'FETCH_TITLE_ACTIVITY_CHECK',
  'FETCH_TITLE_ACTIVITY_CHECK_SUCCESS',
  'FETCH_TITLE_ACTIVITY_CHECK_ERROR'
)<
  //
  LaunchTitleActivityCheckRequestModel,
  undefined,
  { error: Error }
>();

export const actionFetchTitleResupplyCost = createAsyncAction(
  //
  'FETCH_TITLE_RESUPPLY_COST',
  'FETCH_TITLE_RESUPPLY_COST_SUCCESS',
  'FETCH_TITLE_RESUPPLY_COST_ERROR'
)<
  //
  ResupplyTitleInfoCostApiRequest,
  { data: HttpTypes.GetLandRegistryInformationResupplyCostApiResponse },
  { error: Error }
>();
