import * as React from 'react';

import { Action } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import search from 'src/@core/assets/search.svg';
import AbandonLodgementOnlyDialog from 'src/containers/workspace/shared/components/abandon-lodgement-only-dialog';
import { AbandonReasonEnum } from 'src/containers/workspace/shared/components/abandon-lodgement-only-dialog/model';
import MyTasks from 'src/containers/workspace/shared/detail/components/my-tasks';
import { WorkspaceDocumentsState } from 'src/store/reducers/workspace/workspaceDocuments';
import { WorkspaceTasksState } from 'src/store/reducers/workspace/workspaceTasks';
import OutsideOperationHoursMessageBox from '../outside-operation-hours';
import LodgementProgressContainer, { isLodgementProgressVisible } from './components/lodgement-progress';
import Summary from './components/summary';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2084897049/Tasks+tab+-+data+dependency+insights
export interface OwnProps {
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  //
  workspaceDetailState: {
    isLoadingWorkspaceBasicInfo: boolean;
    // basic workspace info
    jurisdictionId?: HttpTypes.JurisdictionsEnum;
    workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
    lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum;
    // full workspace info
    isLodgementReady?: boolean;
    isValidOperatingHoursForRegOnly?: boolean;
  };
  // workspace tasks
  // - tasks count
  // - api status (for loader)
  // - error
  // - tasks items (for table of tasks)
  workspaceTasksState: WorkspaceTasksState;

  // workspace documents
  workspaceDocumentsState: {
    isLoading: WorkspaceDocumentsState['isLoading'];
    status: WorkspaceDocumentsState['status'];
    count: number; // documents count (to offer user to abandon workspace if there are no documents)
  };

  backLink: string;
  dispatch: SafeDispatch<Action>;
  onSelectReportsTab?: () => void;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isButtonLoading: boolean;
  openAbandonDialog: boolean;
}

class TabTasks extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isButtonLoading: false,
    openAbandonDialog: false
  };

  render() {
    const { classes, workspaceId, participantId, workspaceTypeId, backLink, onSelectReportsTab, workspaceDetailState, workspaceTasksState, workspaceDocumentsState } = this.props;
    // we might not need to wait for list of tasks at all.
    // see the logic below

    // make sure we have basic info available
    if (workspaceDetailState.isLoadingWorkspaceBasicInfo) {
      return (
        <div className={classes.loader}>
          <BlockLoader />
        </div>
      );
    }

    // this takes priority over displaying list of tasks
    if (isLodgementProgressVisible(workspaceDetailState.workspaceStatusId, workspaceDetailState.lodgementCaseStatusId)) {
      return (
        <LodgementProgressContainer
          workspaceId={workspaceId}
          participantId={participantId}
          // other
          backLink={backLink}
          onSelectReportsTab={onSelectReportsTab}
        />
      );
    }

    // Show loader for task section when refetching to avoid old task list present to  customers
    // https://tickleme.atlassian.net/browse/WEB-18877
    if (workspaceTasksState.isLoading || workspaceTasksState.isRefetching) {
      return (
        <div className={classes.loader}>
          <BlockLoader />
        </div>
      );
    }

    // this is very unlikely to happen since we don't support removing of all documents
    // therefore there is no need to show loader while we wait for list of documents
    if (workspaceDocumentsState.status === 'resolved' && !workspaceDocumentsState.count) {
      const { workspaceId, participantId } = this.props;
      const { openAbandonDialog } = this.state;
      return (
        <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
          <img height={130} width={130} alt="Document deleted from workspace" src={search} />
          <Summary
            title="Document deleted from workspace"
            message="Registration of this document can no longer proceed."
            description="Unfortunately, your document cannot be lodged and has been removed from the workspace."
          />
          <span>
            {this.renderBackToDashboardButton()}
            {this.renderAbandonWorkspaceButton()}
            <AbandonLodgementOnlyDialog //
              workspaceId={workspaceId}
              participantId={participantId}
              workspaceTypeId={workspaceTypeId}
              onClose={() => this.setState({ openAbandonDialog: false })}
              defaultReason={AbandonReasonEnum.DocumentNoLongerNeeded}
              open={openAbandonDialog}
            />
          </span>
        </FlexLayout>
      );
    }
    // user should not be allowed to do lodgement if LRS is not operating
    const disabled = this.props.workspaceDetailState.isLodgementReady && !this.props.workspaceDetailState.isValidOperatingHoursForRegOnly;
    const showOutsideHoursMessage = Number.isInteger(workspaceDetailState.jurisdictionId) && disabled;
    return (
      <>
        {showOutsideHoursMessage && <OutsideOperationHoursMessageBox jurisdictionId={workspaceDetailState.jurisdictionId!} />}
        <MyTasks //
          items={workspaceTasksState.items}
          disabled={disabled}
          {...(workspaceTasksState.status === 'rejected' && {
            error: workspaceTasksState.error
          })}
          onTaskAction={this.props.dispatch}
        />
      </>
    );
  }

  private renderBackToDashboardButton() {
    const { classes, backLink } = this.props;
    return (
      <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
        Back to dashboard
      </SympliButton>
    );
  }

  private renderAbandonWorkspaceButton = () => {
    const { classes } = this.props;
    return (
      <SympliButton //
        arrowRight
        className={classes.actionButton}
        color="primary"
        variant="contained"
        onClick={this.handleOnAbandonWorkspaceClick}
      >
        Abandon workspace
      </SympliButton>
    );
  };

  private handleOnAbandonWorkspaceClick = () => {
    this.setState({ openAbandonDialog: true });
  };
}

export default withStyles(styles)(TabTasks);
