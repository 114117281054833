import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { actionFetchWorkspaceById } from 'src/store/actions/workspace';

type LrsState = Record<HttpTypes.JurisdictionsEnum, LrsInfo>;
interface LrsInfo {
  isValidOperatingHoursForRegOnly: boolean;
}

const defaultInfo: LrsInfo = {
  isValidOperatingHoursForRegOnly: false
};

const initialState = [
  HttpTypes.JurisdictionsEnum.NSW,
  HttpTypes.JurisdictionsEnum.VIC,
  HttpTypes.JurisdictionsEnum.QLD,
  HttpTypes.JurisdictionsEnum.TAS,
  HttpTypes.JurisdictionsEnum.WA,
  HttpTypes.JurisdictionsEnum.ACT,
  HttpTypes.JurisdictionsEnum.NT,
  HttpTypes.JurisdictionsEnum.SA
].reduce((acc, jurisdictionId) => {
  acc[jurisdictionId] = defaultInfo;
  return acc;
}, {}) as LrsState;

export function useLrsInfo(jurisdictionId?: HttpTypes.JurisdictionsEnum): LrsInfo {
  const state: LrsState = useStoreSelector(store => store.lrsInfo);
  return Number.isInteger(jurisdictionId) && jurisdictionId! in state ? state[jurisdictionId!] : defaultInfo;
}

const reducer = createReducer<
  //
  LrsState,
  Action
>(initialState) //
  // regularly update data with every workspace fetch
  .handleAction(actionFetchWorkspaceById.success, (state, action): LrsState => {
    const jurisdictionId: HttpTypes.JurisdictionsEnum = action.payload.detail.jurisdiction.id;
    const isValidOperatingHoursForRegOnly: boolean = !!action.payload.detail.isValidOperatingHoursForRegOnly;

    if (state[jurisdictionId]?.isValidOperatingHoursForRegOnly === isValidOperatingHoursForRegOnly) {
      return state;
    }

    return {
      ...state,
      [jurisdictionId]: {
        ...state[jurisdictionId],
        isValidOperatingHoursForRegOnly
      }
    };
  });

export default reducer;
