import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class AutoJustification {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves an auto justification by its ID. It sends a request to the
   * `/settings/auto-justification/{justificationId}/detail`
   * API route.
   *
   * @param id - The justifications ID.
   * @param config - Additional config to pass with the request.
   * @returns The auto justification details.
   *
   * @example
   * To retrieve an auto justification by its ID:
   *
   * ```ts
   * sdk.settings.autoJustification.retrieve("justification_123")
   * .then((detail) => {
   *   console.log(detail)
   * })
   * ```
   */
  async retrieve(justificationId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SubscriberAutoJustificationDetailApiResponse>( //
      `/settings/auto-justification/${justificationId}/detail`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves a data containing list of auto justifications. It sends a request to the
   * `/settings/auto-justifications`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns Data containing info about existing justifications.
   *
   * @example
   * To retrieve data containing list of auto justifications:
   *
   * ```ts
   * sdk.settings.autoJustification.list()
   * .then(({existingJustifications}) => {
   *   console.log(existingJustifications)
   * })
   * ```
   */
  async list(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SubscriberAutoJustificationsApiResponse>( //
      `/settings/auto-justifications`,
      {
        ...config
      }
    );
  }
}
