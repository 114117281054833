import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ProprietorGroupModel, TenancyDetail, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';

export class PartyAdjuster {
  static adjust(partyBookId: string | undefined, newPartyBook: PartyModel[], oldPartyBook: PartyModel[]): string | undefined {
    if (Boolean(partyBookId) === false || newPartyBook.findIndex(e => e.id === partyBookId) !== -1) return partyBookId;

    const party = oldPartyBook.find(e => e.id === partyBookId);
    const matchedParties = newPartyBook.filter(e => e.legalEntityName === party?.legalEntityName);

    if (matchedParties.length === 1) return matchedParties[0].id;

    return undefined;
  }

  static adjustTenancyDetail<T extends TenancyDetail<ProprietorGroupModel<TenancyPartyModel>>>(tenancyDetail: T, newPartyBook: PartyModel[], oldPartyBook: PartyModel[]): T {
    return {
      ...tenancyDetail,
      proprietorGroups: tenancyDetail.proprietorGroups.map(pg => {
        return {
          ...pg,
          parties: PartyAdjuster.adjustArray(pg.parties, newPartyBook, oldPartyBook)
        };
      })
    };
  }

  static adjustArray<T extends { partyBookId?: string }>(parties: T[], newPartyBook: PartyModel[], oldPartyBook: PartyModel[]): T[] {
    return parties.map(p => {
      const partyBookId = PartyAdjuster.adjust(p.partyBookId, newPartyBook, oldPartyBook);
      return { ...p, partyBookId: partyBookId };
    });
  }
}
