import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import UserAvatar, { UserAvatarProps } from 'src/@core/components/user-avatar';
import styles, { ClassKeys } from './styles';

interface OwnProps extends UserAvatarProps {
  n?: number;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class AvatarN extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    n: 0
  };

  render() {
    const { classes, n, ...rest } = this.props;
    return (
      <FlexLayout className={classes.root}>
        <UserAvatar {...rest} size="small" className={classes.avatar} />
        {this.renderNumberedAvatar()}
      </FlexLayout>
    );
  }

  private renderNumberedAvatar = () => {
    const { classes, n } = this.props;
    if (!n) {
      return null;
    }
    return <Avatar className={classes.avatarWithNumber}>{`+${n}`}</Avatar>;
  };
}

export default withStyles(styles)(AvatarN);
