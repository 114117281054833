import React, { useCallback, useState } from 'react';

import { Form, FormikHelpers, FormikProps } from 'formik';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Box from 'src/@core/components/layout/box';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import Formik from 'src/components/formik';
import { actionFetchLinkedLodgementDetail, actionFetchWorkspaceById } from 'src/store/actions/workspace';
import { modelKey } from 'src/utils/formUtils';
import { ApiStatus } from 'src/utils/http';
import { UnlinkLodgement } from './api';
import { UnlinkLodgementDialogFormikModel, UnlinkLodgementRequest, UnlinkLodgementWorkspaceItem } from './models';
import UnlinkLodgementWorkspacesTable from './unlink-lodgement-table';
import { UnlinkLodgementWorkspaceTableModel } from './unlink-lodgement-table/models';
import { getUnlinkLodgementValidationSchema } from './validationSchema';

import type { SafeDispatch } from 'src/hooks';

const fieldName = modelKey<UnlinkLodgementDialogFormikModel>();

export interface UnlinkLodgementDialogProps {
  workspaceId: string;
  participantId: string;
  clusterId: string;
  onClose(): void;
  dispatch: SafeDispatch<Action>;
  rows: UnlinkLodgementWorkspaceTableModel[];
  apiStatus?: ApiStatus;
}

function UnlinkLodgementDialog({
  //
  workspaceId,
  participantId,
  dispatch,
  onClose,
  rows,
  apiStatus,
  clusterId
}: UnlinkLodgementDialogProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnSubmit = async (formikValues: UnlinkLodgementDialogFormikModel, _: FormikHelpers<UnlinkLodgementDialogFormikModel>) => {
    setIsLoading(true);
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'unlinked-lodgement',
      logGroupId: 'workspace',
      route: 'workspace-overview',
      action: 'unlink-lodgement',
      workspaceId,
      participantId
    });

    const { unlinkLodgementWorkspaceItems } = formikValues;

    //todo: move below to container
    try {
      const response = await UnlinkLodgement(workspaceId, participantId, {
        clusterId: clusterId,
        workspaceIds: formikValues.unlinkLodgementWorkspaceItems.map(d => d.workspaceId)
      } satisfies UnlinkLodgementRequest);

      // WEB-41745: When the response returns empty clusterId, it means that current workspace also does not belong to any cluster,
      //            we need to show specific toast message about it
      const isCurrentWorkspaceInvolved = formikValues.unlinkLodgementWorkspaceItems.find(d => d.workspaceId === workspaceId) || !response.clusterId;
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <div className="font-[400] text-[14px]">
              <b>
                {unlinkLodgementWorkspaceItems.length} {unlinkLodgementWorkspaceItems.length === 1 ? ' link ' : ' links '}
              </b>
              {unlinkLodgementWorkspaceItems.length === 1 ? 'has' : 'have'} been <b>successfully removed</b> from this workspace.
              {isCurrentWorkspaceInvolved ? (
                <span>
                  This Workspace is <b>no longer linked.</b>
                </span>
              ) : (
                ''
              )}
            </div>
          ),
          variant: 'new-success',
          autoHideDuration: 5000
        })
      );

      // fetch the link lodgement detail otherwise realtime
      if (response.clusterId && !isCurrentWorkspaceInvolved) {
        dispatch(actionFetchLinkedLodgementDetail.request({ workspaceId, clusterId: response.clusterId }));
      } else {
        dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
      }
    } catch (error) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <div className="font-[400] text-[14px]">
              <b>Workspace could not be unlinked at this time.</b> Please try again later.
            </div>
          ),
          variant: 'new-error',
          autoHideDuration: 5000
        })
      );
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<UnlinkLodgementDialogFormikModel>) => {
      const { isValid, isSubmitting, submitForm, values } = formikProps;
      const disabled = !isValid || isSubmitting || formikProps.values.unlinkLodgementWorkspaceItems.length === 0;

      const workspaceIdsField = fieldName('unlinkLodgementWorkspaceItems');
      const selectionChange = (selectedRowIndexes: number[]) => {
        const selectedWorkspaceIds: UnlinkLodgementWorkspaceItem[] = [];

        for (let i = 0; i < rows.length; i++) {
          if (selectedRowIndexes.includes(i)) {
            selectedWorkspaceIds.push({ index: i, workspaceId: rows[i].workspaceId } satisfies UnlinkLodgementWorkspaceItem);
          }
        }

        formikProps.setFieldValue(workspaceIdsField, selectedWorkspaceIds);
      };

      return (
        <Form aria-label="link lodgement" className="mt-[32px]">
          <FlexLayout flexDirection="row" className="font-[400] text-[14px] leading-5 mt-[20px] mb-[20px] ">
            <Typography variant="body2">
              This workspace is linked to <b>{rows.length} workspaces</b> in total.
            </Typography>
          </FlexLayout>
          <Box title="Linked Workspaces" className="mt-[20px] pb-[25px]">
            <UnlinkLodgementWorkspacesTable
              //
              rows={rows}
              apiStatus={apiStatus}
              onSelectionChange={selectionChange}
              selectedRowIndexes={values.unlinkLodgementWorkspaceItems.map(d => d.index)}
            />
          </Box>
          <Divider className="bg-[var(--neutral-100)]" />
          <WizardStepper //
            disabled={disabled}
            isLoading={isLoading}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Unlink"
            color="error"
            showBackIcon={false}
            showNextIcon={false}
            onNext={submitForm}
          />
        </Form>
      );
    },
    [isLoading, onClose, rows, apiStatus]
  );

  const getInitialValues = (): UnlinkLodgementDialogFormikModel => {
    return {
      unlinkLodgementWorkspaceItems: []
    };
  };

  return (
    <Formik //
      initialValues={getInitialValues()}
      onSubmit={handleOnSubmit}
      validationSchema={getUnlinkLodgementValidationSchema()}
    >
      {(formikProps: FormikProps<UnlinkLodgementDialogFormikModel>) => renderForm(formikProps)}
    </Formik>
  );
}

export default React.memo(UnlinkLodgementDialog);
