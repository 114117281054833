import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { RelinquishingModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';

import {
  DeceasedProprietorGroupModel,
  DeceasedProprietorModel,
  DeceasedTenancyDetailModel
} from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { resolveDeceasedGroupWarning, resolveDeceasedPartyWarning } from 'src/containers/documents/scaffolded-form/shared/crossDocumentUtils';
import { NoticeOfDeath_2_21_2_Model } from '../../../../models';
import { calculateCrossDocumentMatches } from '../../../../utils/calculateCrossDocumentMatches';
import { extractSurvivingJointTenants } from '../../../../utils/common';
import { DEFAULT_DEATH_OF_DATE, DEFAULT_EVIDENCE, filterOrganisationProprietors } from '../../helpers';
import DeceasedProprietor from '../deceased-proprietor/DeceasedProprietor';
import { useStyles } from './styles';

interface Props {
  itemBinding: string;
  numberOfPartiesInPreceedingGroups: number;
  matchingTenancies: ReturnType<typeof calculateCrossDocumentMatches>;
}

type FormModel = NoticeOfDeath_2_21_2_Model;
const baseFieldName = modelKey<FormModel>();

function DeceasedProprietorArray({
  //
  itemBinding,
  numberOfPartiesInPreceedingGroups,
  matchingTenancies
}: Props): JSX.Element {
  const fieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
  const name = fieldName('parties');
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<FormModel>();
  const { values, setFieldValue, setValues } = formikProps;
  const {
    deceasedTenancyDetail: { tenancyType, proprietorGroups },
    partyBook
  } = values;

  const { proprietorGroupType, parties: proprietorGroupParties } = getIn(values, itemBinding) as DeceasedProprietorGroupModel;

  const isJointTenants = tenancyType === TenancyTypeEnum.JointTenants || proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse;

  const resolveItemWarning = ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>): string[] => {
    const messages: string[] = [];

    if (itemIndex === 0) {
      const groupMessage: string | undefined = resolveDeceasedGroupWarning({
        //
        proprietorGroupParties,
        matchingGroups: matchingTenancies.matchingGroups,
        partyBook: values.partyBook
      });
      groupMessage && messages.push(groupMessage);
    }

    const partyMessage: string | undefined = resolveDeceasedPartyWarning({
      //
      partyBookId: item.partyBookId,
      matchingParties: matchingTenancies.matchingParties,
      partyBook: values.partyBook
    });
    partyMessage && messages.push(partyMessage);

    return messages;
  };

  const renderItem = ({ item, itemBinding, itemIndex }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const onPartyTypeChange = (newPartyType: HttpTypes.PartyTypeEnum) => {
      if (newPartyType === HttpTypes.PartyTypeEnum.Organisation && item.isSelected) {
        // Organisation selected set all isSelected to false
        setFieldValue(fieldName('isSelected'), false);
        const partyFieldName = createModelKeyAppender<DeceasedProprietorModel>(itemBinding);
        setFieldValue(partyFieldName('isSelected'), false);
        return;
      }

      // Only one proprietor group can be selected
      // if any group is already selected, takes priority
      if (proprietorGroups.some(p => p.isSelected)) {
        return;
      }

      if (!isJointTenants) {
        const updatedProprietorGroup: DeceasedProprietorModel[] = proprietorGroupParties.map((p: DeceasedProprietorModel, index: number) => {
          const updateProprietor: DeceasedProprietorModel = {
            ...p,
            isSelected: index === itemIndex,
            dateOfDeath: p.dateOfDeath ? { ...p.dateOfDeath } : { ...DEFAULT_DEATH_OF_DATE },
            evidences: p.evidences.length ? [...p.evidences] : [{ ...DEFAULT_EVIDENCE }]
          };

          return updateProprietor;
        });
        updateProprietorGroup(updatedProprietorGroup);
      } else {
        // proprietors uses stale data so we can assume if we only had 1 organisation proprietor, we have already updated it as part of this onPartyTypeChange
        if (proprietorGroupParties.filter((p: DeceasedProprietorModel) => filterOrganisationProprietors(p, partyBook) !== undefined).length === 1) {
          setFieldValue(fieldName('isSelected'), true);
        }
      }
    };

    return (
      <DeceasedProprietor //
        name={itemBinding}
        proprietor={item}
        onPartyTypeChange={onPartyTypeChange}
      />
    );
  };

  const updateProprietorGroup = useCallback(
    (updatedProprietors: DeceasedProprietorModel[], previouslySelectedProprietor?: DeceasedProprietorModel) => {
      if (previouslySelectedProprietor) {
        const proprietorParty = partyBook.find(pb => pb.id === previouslySelectedProprietor.partyBookId);
        if (proprietorParty!.receivingOrRelinquishingDetails.isRelinquishing) {
          proprietorParty!.receivingOrRelinquishingDetails.isChangingName = false;
          proprietorParty!.receivingOrRelinquishingDetails.nameChange = undefined;
        }
        setFieldValue(baseFieldName('partyBook'), partyBook);
      }

      setFieldValue(fieldName('parties'), updatedProprietors);
      setFieldValue(
        fieldName('isSelected'),
        updatedProprietors.some(p => p.isSelected)
      );
    },
    [fieldName, partyBook, setFieldValue]
  );

  const renderPartyLabel = (itemIndex: number): string => {
    const label = `Joint tenant ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;

    return label;
  };

  const renderItemTitle = ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const label = renderPartyLabel(itemIndex);
    const { values } = formikProps;
    const partyBook = values.partyBook ?? [];
    const disable = disabled || partyBook.find(party => party.id === item.partyBookId)?.partyType === HttpTypes.PartyTypeEnum.Organisation;

    return (
      <FormControlLabel
        key={itemIndex}
        value={item.partyBookId}
        label={isJointTenants && label}
        checked={item.isSelected}
        disabled={disable}
        control={<Radio color="primary" aria-label="Deceased Joint Tenant" />}
        onChange={handleOnRadioButtonChange}
        className={classes.radioButtonRoot}
        classes={{ label: classes.radioButtonLabel }}
      />
    );
  };

  const handleOnRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const convertedDeceasedTenancyDetailModel: DeceasedTenancyDetailModel = {
        tenancyType,
        proprietorGroups: proprietorGroups.map(group => {
          return {
            ...group,
            isSelected: group.parties.some(proprietor => proprietor.partyBookId! === event.target.value),
            parties: group.parties.map(proprietor => {
              const isAffectedProprietor = proprietor.partyBookId! === event.target.value;

              const updatedProprietor: DeceasedProprietorModel = {
                ...proprietor,
                isSelected: isAffectedProprietor,
                dateOfDeath: proprietor.dateOfDeath ? { ...proprietor.dateOfDeath } : { ...DEFAULT_DEATH_OF_DATE },
                evidences: proprietor.evidences.length ? [...proprietor.evidences] : [{ ...DEFAULT_EVIDENCE }]
              };
              return updatedProprietor;
            })
          };
        })
      };

      const survivingJointTenants = extractSurvivingJointTenants(convertedDeceasedTenancyDetailModel);

      setValues(values => {
        return {
          ...values,
          partyBook: values.partyBook.map(p => {
            const survivingTenantParty = survivingJointTenants.proprietorGroups.flatMap(pg => pg.parties).find(party => party.partyBookId === p.id);
            const relinquishingDetails = p.receivingOrRelinquishingDetails as RelinquishingModel<NswNameChange, {}>;

            return {
              ...p,
              receivingOrRelinquishingDetails: {
                ...p.receivingOrRelinquishingDetails,
                isChangingName: survivingTenantParty ? false : relinquishingDetails.isChangingName,
                nameChange: survivingTenantParty ? undefined : relinquishingDetails.nameChange
              }
            };
          }),
          deceasedTenancyDetail: convertedDeceasedTenancyDetailModel,
          survivingJointTenants: survivingJointTenants
        };
      });
    },
    [setValues, proprietorGroups, tenancyType]
  );

  return (
    <div data-section={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemWarning={resolveItemWarning}
        itemTitle={renderItemTitle}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorArray);
