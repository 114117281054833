import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchSubscriberSigningRule } from '../actions/subscriberSigningRule';

export interface SubscriberSigningRuleState {
  detail?: HttpTypes.SettingsSubscriberSigningRuleApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: SubscriberSigningRuleState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

export function useSubscriberSigningRule() {
  const state = useStoreSelector(store => store.subscriberSigningRule);
  if (state) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  SubscriberSigningRuleState,
  Action
>(initialState)
  .handleAction(actionFetchSubscriberSigningRule.request, (state): SubscriberSigningRuleState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchSubscriberSigningRule.success, (state, action): SubscriberSigningRuleState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchSubscriberSigningRule.failure, (state, action): SubscriberSigningRuleState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
