import * as React from 'react';

import classNames from 'classnames';
import { FormikProps, FormikValues } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import DeleteButton, { DeleteButtonProps } from '@sympli/ui-framework/components/formik/buttons/delete-button';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconEditNew } from '@sympli/ui-framework/icons';
import { currency } from '@sympli/ui-framework/utils/formatters';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { UserAvatar } from 'src/components/avatars';
import { IconLinked } from 'src/components/icons';
import DistributionRecordError from 'src/containers/workspace/financial/directions/components/distribution-record-error';
import { accountDescriptionMapping, ASTERISK, isVendorAmountDirection, resolveBankTransferCategoryDescription } from 'src/containers/workspace/financial/directions/helpers';
import { DistributionFormikModel, TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import LinkedSettlementToParticipantDetail from 'src/containers/workspace/shared/components/linked-settlement-participant-detail';
import { colors } from 'src/theme';
import { createArrayItemModelKeyAppender } from 'src/utils/formUtils';
import { getConfirmationDialogProps } from '../../helper';
import { UnlinkLineItemTypeEnum } from '../../models';
import BreakdownsCollapseList from '../breakdowns-collapse-list/BreakdownsCollapseList';
import ConditionalEditTooltip from '../conditional-edit-tooltip';
import ViewLinkedSettlement from '../view-linked-settlement';
import { LINE_ITEM_PERMISSION_TOOLTIP_TITLE } from './constants';
import { resolveCommonIcons } from './helpers';
import { ConditionalDistributionModel } from './models';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

interface OwnProps<T extends FormikValues> {
  isEditable?: boolean;
  isMultipleEditorsOpen?: boolean;
  formikProps: FormikProps<T>;
  arrayFieldName: string;
  index: number;
  workspaceRole?: HttpTypes.WorkspaceRoleEnum;
  trustAccountMap: TrustAccountMap;
  allParticipants: HttpTypes.WorkspaceParticipant[];
  workspaceId?: string;
  participantId?: string;
  dispatch?: SafeDispatch<Action>;
  hasManageWorkspacePermission: boolean;
}
type Props<T extends FormikValues> = OwnProps<T> & WithStyles<ClassKeys>;

interface State {
  isLoading: boolean;
  breakdownOpen: boolean;
}

class DirectionRecord<T extends FormikValues> extends React.PureComponent<Props<T>, State> {
  public readonly state: Readonly<State> = {
    isLoading: false,
    breakdownOpen: false
  };

  // * Use this component base to deal with some more CONSISTENT and STATIC behaviors for direction record
  // * like
  // * - col style
  // * - delete button
  // * - avatar
  // * - amount field
  // * - disable for delete button and amount field
  // * coupled with distribution model
  // * use field 'isEditorOpen' in formikValue
  // private asterisk = ASTERISK;
  private arrayItemModelKeyAppender = createArrayItemModelKeyAppender<DistributionFormikModel>(this.props.arrayFieldName);

  render() {
    const { arrayFieldName, index, classes, formikProps, isEditable, workspaceId, participantId, dispatch, hasManageWorkspacePermission } = this.props;
    const itemBinding = `${arrayFieldName}.[${index}]`;
    const details: ConditionalDistributionModel = _get(formikProps.values, itemBinding);
    if (details == null) {
      Logger.captureException(new InvalidDataError(`Invalid direction details of ${itemBinding}`, details));
      return null;
    }

    const avatarSrc = this.resolveAvatarSrc(details.subscriberName, details.participantId);
    const { id, paymentMethod } = details;
    const hasBreakdowns = details.lineItemBreakdowns.length > 0;

    let confirmationDialogProps: Pick<DeleteButtonProps, 'confirmBeforeDelete' | 'confirmationContent' | 'confirmationProps' | 'confirmationAsyncClose'> = {};
    const isUnlinkPayment = paymentMethod === HttpTypes.PaymentMethodEnum.Linked;

    if (id && workspaceId && participantId && dispatch) {
      confirmationDialogProps = getConfirmationDialogProps(
        id,
        classes.optionIcon,
        this.state.isLoading,
        workspaceId,
        participantId,
        () => {
          this.setState({ isLoading: true });
        },
        () => {
          this.setState({ isLoading: false });
        },
        dispatch,
        isUnlinkPayment ? (
          <span>
            <b>A Link</b> has been successfully removed.
          </span>
        ) : (
          'Financial line item has been deleted.'
        ),
        isUnlinkPayment ? UnlinkLineItemTypeEnum.UnlinkPayment : UnlinkLineItemTypeEnum.Other,
        isUnlinkPayment ? classes.deleteLinkedItemTitle : undefined
      );
    }

    // the user must first have the save permission and then if linked item it must has the manage workspace permission
    let showDeleteButton = false;
    if (isEditable) {
      showDeleteButton = true;
      if (isUnlinkPayment && !hasManageWorkspacePermission) {
        showDeleteButton = false;
      }
    }

    return (
      <>
        <FlexLayout className={classes.recordRow}>
          <FlexLayout flexDirection="column" justifyContent="center" className={classes.paymentTypeCol}>
            {showDeleteButton && (
              <DeleteButton
                //
                className={classNames(classes.deleteButton)}
                formikProps={formikProps}
                fieldName={arrayFieldName}
                removeIndex={index}
                {...confirmationDialogProps}
              />
            )}
            <UserAvatar src={avatarSrc} border={true} text={details.subscriberName} className={classes.avatar} />
          </FlexLayout>
          <FlexLayout justifyContent="space-between" alignItems="center" className={classes.accountDetailWithBreakdownsCol}>
            <FlexLayout flexDirection="column" justifyContent="center" className={classes.flexGrow}>
              {this.renderAccountDetailCol(details, showDeleteButton)}
            </FlexLayout>
            {details.isEditorOpen || <DistributionRecordError formikProps={formikProps} name={itemBinding} />}
          </FlexLayout>
          {
            <FlexLayout className={classes.feeBreakdownCol}>
              {hasBreakdowns && (
                <ButtonLink
                  onClick={() => {
                    this.setState(({ breakdownOpen }) => ({
                      breakdownOpen: !breakdownOpen
                    }));
                  }}
                  color="inherit"
                  className={classes.showDetailsButtonLink}
                >
                  {this.state.breakdownOpen ? 'Hide details' : 'Show details'}
                </ButtonLink>
              )}
            </FlexLayout>
          }
          {this.renderInputAmountCol(details)}
        </FlexLayout>
        {
          <BreakdownsCollapseList
            open={this.state.breakdownOpen}
            lineItems={details.lineItemBreakdowns}
            category={details.category}
            accountDisplayName={details.bankDetails?.accountDisplayName ?? ''}
            columnClasses={{ columnOne: classes.breakdownLineItemsColOne, columnTwo: classes.feeBreakdownCol, columnThree: classes.amountCol }}
          />
        }
      </>
    );
  }

  private renderAccountDetailCol(details: ConditionalDistributionModel, showEdit: boolean) {
    const { classes, trustAccountMap, workspaceRole } = this.props;
    const { isEditorOpen } = details;
    const asterisk: string = isVendorAmountDirection(workspaceRole, details) ? `${ASTERISK} ` : '';

    if (!details.category && !Number.isInteger(details.paymentMethod)) {
      return this.renderDefaultPlaceholderText(asterisk, isEditorOpen);
    }

    switch (details.paymentMethod) {
      case HttpTypes.PaymentMethodEnum.HoldingAccount: {
        const { accountId, reference, shortfallAmount, acceptSurplus } = details.holdingAccountDetails;
        const account = trustAccountMap[accountId];
        const accountName = account && account.bankDetails.accountName;
        const accountDescription = account && account.bankDetails.accountDescription;
        let description = '';
        if (shortfallAmount) {
          description = `Accepted amount: ${currency(Number(details.amount) - Number(shortfallAmount))} to ${currency(Number(details.amount))}`;
        } else {
          description = `Accepted amount: ${currency(Number(details.amount))}`;
        }
        if (acceptSurplus) {
          description = `${description} | All surplus accepted `;
        }

        return (
          <React.Fragment>
            <Typography className={classes.bold}>{accountDescription || accountName}</Typography>
            <Typography>Loan payout{!!reference && ` (${reference})`}</Typography>
            <Typography>
              {description}
              {this.renderEditLink(isEditorOpen, showEdit)}
            </Typography>
          </React.Fragment>
        );
      }
      case HttpTypes.PaymentMethodEnum.BankTransfer: {
        const { accountName, accountDisplayName, accountDescription } = details.bankDetails;
        const { category, categoryOther } = details;
        const bankAccountDescription = accountDescriptionMapping(details.bankDetails);
        const categoryDescription = resolveBankTransferCategoryDescription('Bank transfer', details.bankDetails, category, categoryOther);

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {accountDisplayName || accountDescription || accountName || 'Bank transfer'}
            </Typography>
            <Typography>{categoryDescription}</Typography>
            <Typography>
              {bankAccountDescription}
              {this.renderEditLink(isEditorOpen, showEdit)}
            </Typography>
          </React.Fragment>
        );
      }
      case HttpTypes.PaymentMethodEnum.TrustAccount: {
        const { category, categoryOther, bankDetails } = details;

        const accountName = bankDetails.accountName;
        const accountDescription = bankDetails.accountDescription;
        const bankAccountDescription = accountDescriptionMapping(bankDetails);
        const categoryDescription = resolveBankTransferCategoryDescription('Trust account', bankDetails, category, categoryOther);

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {accountDescription || accountName || 'Trust account'}
            </Typography>
            <Typography>{categoryDescription}</Typography>
            <Typography>
              {bankAccountDescription}
              {this.renderEditLink(isEditorOpen, showEdit)}
            </Typography>
          </React.Fragment>
        );
      }
      case HttpTypes.PaymentMethodEnum.BPAY: {
        const { billerCode, billerName, billerReference, description } = details.bpayDetails;

        const billerCodeText = !!billerCode && `BPAY biller code ${billerCode}`;
        const billerReferenceText = !!billerReference && `Reference number ${billerReference}`;

        const { category, categoryOther } = details;

        const desc = [billerCodeText, billerReferenceText].filter(Boolean).join(' | '); /* danger:disable */

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              {asterisk}
              {billerName || 'BPAY'}
            </Typography>
            <Typography>
              {category === 'Other' ? categoryOther : category}
              {description && ` (${description})`}
            </Typography>
            <Typography>
              {desc}
              {this.renderEditLink(isEditorOpen, showEdit)}
            </Typography>
          </React.Fragment>
        );
      }
      case HttpTypes.PaymentMethodEnum.Linked: {
        const { reference } = details;

        return (
          <React.Fragment>
            <Typography className={classes.bold}>
              <IconLinked style={{ verticalAlign: 'bottom', margin: '2px 6px 2px 0px' }} />
              Linked Payment
            </Typography>
            <Typography>{reference}</Typography>
            {details.linkedSettlementItem && details.linkedSettlementItem.linkedToSympliId && (
              <FlexLayout flexWrap="wrap">
                <LinkedSettlementToParticipantDetail
                  matterReference={details.linkedSettlementItem.linkedToParticipantMatter ?? ''}
                  sympliId={details.linkedSettlementItem.linkedToSympliId ?? ''}
                  addresses={details.linkedSettlementItem.linkedToWorkspaceAddress ?? []}
                >
                  {this.renderEditLink(isEditorOpen, showEdit)}
                  <ViewLinkedSettlement
                    //
                    linkedToGroupId={details.linkedSettlementItem.linkedToParticipantGroupId}
                    linkedToParticipantId={details.linkedSettlementItem.linkedToParticipantId}
                    linkedToWorkspaceId={details.linkedSettlementItem.linkedToWorkspaceId}
                  />
                </LinkedSettlementToParticipantDetail>
              </FlexLayout>
            )}
          </React.Fragment>
        );
      }
      default:
        // Add edit button for safety purpose
        const { category, categoryOther } = details;
        const categoryText = category === 'Other' ? categoryOther : category;
        if (!categoryText) {
          return this.renderDefaultPlaceholderText(asterisk, isEditorOpen);
        }

        return (
          <React.Fragment>
            <Typography className={classes.bold}>{asterisk}</Typography>
            <Typography>{categoryText}</Typography>
            <Typography>{this.renderEditLink(isEditorOpen, showEdit)}</Typography>
          </React.Fragment>
        );
    }
  }

  private renderDefaultPlaceholderText(asterisk: string, isEditorOpen: boolean) {
    const { classes } = this.props;
    return (
      <Typography className={classes.newRecordText}>
        {asterisk}
        To add a destination, please enter details below.{this.renderEditLink(isEditorOpen, true)}
      </Typography>
    );
  }

  private renderInputAmountCol(details: ConditionalDistributionModel) {
    const { workspaceRole, classes, index } = this.props;
    const asterisk = isVendorAmountDirection(workspaceRole, details) && `${ASTERISK} `;
    const amountColStyle = asterisk ? classes.amountColPlus : classes.amountCol;

    return (
      <FlexLayout flexDirection="column" justifyContent="center" className={amountColStyle}>
        <FlexLayout flexDirection="row" alignItems="center" fullWidth justifyContent="flex-end">
          {asterisk && <Typography>{asterisk}</Typography>}
          <Field
            aria-label="Amount ($)"
            readOnly={true} // it will always be readonly since user needs to confirm the data first when add a new item
            component={CurrencyInputField}
            className={classNames(asterisk && classes.marginLeft)}
            name={this.arrayItemModelKeyAppender(index, 'amount')}
            margin="none"
            fullWidth
          />
        </FlexLayout>
      </FlexLayout>
    );
  }

  // todo: why source fund & direction has different behavior ?
  // they should be consistent if no permission hide the edit button
  private renderEditLink = (isEditorOpen: boolean, showEdit: boolean) => {
    /**
     * isEditorOpen: is the state whether current editor is open or not
     * isMultipleEditorsOpen: is when other editors are open, we disable the editor
     */
    const { isEditable, classes, isMultipleEditorsOpen } = this.props;
    if (isEditable && !isEditorOpen) {
      const show = showEdit || Boolean(isMultipleEditorsOpen);
      return (
        <>
          {show && (
            <ConditionalEditTooltip
              //
              showTooltip={isMultipleEditorsOpen ?? false}
              title={isMultipleEditorsOpen ? undefined : LINE_ITEM_PERMISSION_TOOLTIP_TITLE}
            >
              <ButtonLink
                disabled={isMultipleEditorsOpen}
                icon={<IconEditNew fill={isMultipleEditorsOpen ? colors.NEUTRAL_200 : colors.SYMPLI_GREEN} height={20} width={20} />}
                onClick={this.handleOnEditClick}
                className={classes.editButton}
                color="inherit"
              ></ButtonLink>
            </ConditionalEditTooltip>
          )}
        </>
      );
    }
    return null;
  };

  private resolveAvatarSrc(subscriberName: string, participantId?: string) {
    const { allParticipants } = this.props;

    if (!participantId) {
      const participant = allParticipants.find(item => item.name === subscriberName);
      if (participant != null) {
        return participant && participant.avatarUrl;
      } else {
        return resolveCommonIcons('sympli');
      }
    }
    const participant = allParticipants.find(item => item.id === participantId);
    return participant && participant.avatarUrl;
  }

  private handleOnEditClick = () => {
    const {
      index,
      formikProps: { setFieldValue }
    } = this.props;

    setFieldValue(`${this.arrayItemModelKeyAppender(index, 'isEditorOpen')}`, true);
  };
}

export default withStyles(styles)(DirectionRecord);
