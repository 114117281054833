import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { colors } from '@sympli/ui-framework/theme';

import Breadcrumbs from 'src/containers/workspace/shared/components/workspace-status-rebuild/breadcrumbs/Breadcrumbs';
import { VerticalStatusLineVariant, WorkspaceStatusColorVariantEnum } from 'src/utils/status-mapping/workspaceStatusHelper';
import { LodgementOnlyQueuedWorkspaceSteps, LodgementOnlyWorkspaceStatusEnum, LodgementOnlyWorkspaceSteps } from './models';

export const tooltipDescription = (lodgementOnlyWorkspaceStatusEnum: LodgementOnlyWorkspaceStatusEnum, workspaceStatusEnum: HttpTypes.WorkspaceStatusEnum) => {
  switch (lodgementOnlyWorkspaceStatusEnum) {
    case LodgementOnlyWorkspaceStatusEnum.InPreparation: {
      if (workspaceStatusEnum === HttpTypes.WorkspaceStatusEnum.Failed) {
        return (
          <>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              Something went wrong.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
              This workspace has <b> attempted to lodge but was unsuccessful</b>. It has returned to the 'In Preparation' state.
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
              <b>Edit, save and approve</b> the unverified document(s) to initiate another automated Lodgement Verification.
            </Typography>
          </>
        );
      }

      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          The workspace is <b>now active</b> for you to start completing.
        </Typography>
      );
    }

    case LodgementOnlyWorkspaceStatusEnum.Ready: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The documents have <b>been approved, verified and signed</b>.
          </Typography>

          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The workspace is now ready for Lodgement. <b> Click Lodge</b> in the Workspace to submit the lodgement.{' '}
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Queued: {
      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          This Workspace was submitted for lodgement <b>outside of Land Registry business hours</b> or there was an issue lodging this Workspace, this has been queued to be lodged
          at the next available time.
        </Typography>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Lodging: {
      return (
        <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
          The lodgement process has <b>now started</b>. This might take a few minutes.
        </Typography>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Lodged: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The lodgement has <b>been accepted</b> by the Land Registry.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            They are presently <b>processing your documents</b>.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Completed: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            All done! The matter has now <b>been completed</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            For further information regarding the registration please refer to the <b>individual document status(es)</b>.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            This workspace will be archived in 90 days.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Abandoned: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            You have <b>abandoned this workspace</b> or this workspace was inactive for 120 days.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            Note: Abandoned workspaces can not be reactivated.
          </Typography>
        </>
      );
    }
    case LodgementOnlyWorkspaceStatusEnum.Archived: {
      return (
        <>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            The system <b>automatically archives completed workspaces</b> after 90 days.
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
            Note: Archived workspaces can not be reactivated.
          </Typography>
        </>
      );
    }
  }
};

export function getVerticalStatusWithToolTip(
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum,
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum,
  includeToolTip?: boolean
): {
  //
  variant: VerticalStatusLineVariant;
  title: string;
  tooltipContent: NonNullable<React.ReactNode> | undefined;
} {
  const { step, variant: colorVariant } = getLodgementOnlyWorkspaceStatusEnumMapping({ workspaceStatusId, lodgementCaseStatusId });

  const verticalVariant: VerticalStatusLineVariant =
    colorVariant === WorkspaceStatusColorVariantEnum.Inactive
      ? 'neutral'
      : colorVariant === WorkspaceStatusColorVariantEnum.Success
        ? 'ok'
        : colorVariant === WorkspaceStatusColorVariantEnum.Failure
          ? 'error'
          : 'warning';

  const steps = workspaceStatusId === HttpTypes.WorkspaceStatusEnum.LodgementInQueue ? LodgementOnlyQueuedWorkspaceSteps : LodgementOnlyWorkspaceSteps;
  const currentStep = steps.indexOf(step) + 1;

  if (!includeToolTip) {
    return { variant: verticalVariant, title: step, tooltipContent: undefined };
  }
  //if there is no return then give an empty content as safe guard
  const tooltipContent = (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        {step}
      </Typography>
      {tooltipDescription(step, workspaceStatusId)}
      {colorVariant !== WorkspaceStatusColorVariantEnum.Inactive && (
        <Breadcrumbs //
          currentStep={currentStep} // WEB-31850 - if queued we need to display Ready
          steps={LodgementOnlyWorkspaceSteps}
          sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
        />
      )}
    </Box>
  );

  return { variant: verticalVariant, title: step, tooltipContent };
}

export function getLodgementOnlyWorkspaceStatusEnumMapping({
  //
  workspaceStatusId,
  lodgementCaseStatusId
}: {
  //
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
}): {
  //
  step: LodgementOnlyWorkspaceStatusEnum;
  variant: WorkspaceStatusColorVariantEnum;
} {
  const variant =
    workspaceStatusId === HttpTypes.WorkspaceStatusEnum.OnSchedule
      ? WorkspaceStatusColorVariantEnum.Warning
      : workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Archived || workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Abandoned
        ? WorkspaceStatusColorVariantEnum.Inactive
        : workspaceStatusId !== HttpTypes.WorkspaceStatusEnum.Failed
          ? WorkspaceStatusColorVariantEnum.Success
          : WorkspaceStatusColorVariantEnum.Failure;

  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Archived) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Archived, variant };
  }

  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Abandoned) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Abandoned, variant };
  }
  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.LodgementInQueue) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Queued, variant };
  }

  // lodging is always override the workspace status(except Archived, Abandoned, lodgement in queue) as it is
  if (lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementRequested) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Lodging, variant };
  }

  // we don't need to consider lodgement case status of technical/connection error because the WorkspaceStatus will stay as ReadyForLodge
  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.ReadyForLodge) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Ready, variant };
  }

  if (lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementSuccess) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Lodged, variant };
  }

  // completed status is any status after registered. WEB-31851
  if (
    [
      HttpTypes.LodgementCaseStatusEnum.Registered,
      HttpTypes.LodgementCaseStatusEnum.Rejected,
      HttpTypes.LodgementCaseStatusEnum.Withdrawn,
      HttpTypes.LodgementCaseStatusEnum.Unnecessary
    ].includes(lodgementCaseStatusId)
  ) {
    return { step: LodgementOnlyWorkspaceStatusEnum.Completed, variant };
  }

  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Failed) {
    return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
  }

  if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.OnSchedule) {
    return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
  }

  return { step: LodgementOnlyWorkspaceStatusEnum.InPreparation, variant };
}
