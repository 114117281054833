import { NotificationContentProps } from '../models';

export function DocumentParticipationAddedNotification({ log, participantId }: NotificationContentProps) {
  if (log.participantId === participantId) {
    return undefined;
  }
  const { WorkspaceRole, DocumentName } = log.dataObject as DocumentParticipationDataModel;

  return [
    <span>
      '{WorkspaceRole}' has joined the {DocumentName} document. This action may have caused changes to your workspace, please check your tasks.
    </span>
  ];
}

export function DocumentParticipationWithdrawnNotification({ log, participantId, workspaceParticipants }: NotificationContentProps) {
  const withdrawnParticipants = workspaceParticipants.filter(p => p.archivedStatus);

  if (log.participantId === participantId || Boolean(withdrawnParticipants.find(wp => wp.id === log.participantId))) {
    return undefined;
  }

  const { WorkspaceRole, DocumentName } = log.dataObject as DocumentParticipationDataModel;

  return [
    <span>
      '{WorkspaceRole}' has withdrawn from {DocumentName} document. This action may have caused changes to your workspace, please check your tasks.
    </span>
  ];
}

interface DocumentParticipationDataModel {
  ParticipantId: string;
  DocumentId: string;
  WorkspaceRole: string;
  DocumentName: string;
}
