import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchReportsDashboard, actionUpdateReportsInRedux } from '../actions';
import { SavedReportModel } from '../models';

export interface ReportsDashboardState {
  savedReports?: Array<SavedReportModel>;
  status: ApiStatus;
  error?: string;
}

const initialState: ReportsDashboardState = {
  savedReports: undefined,
  status: 'idle',
  error: undefined
};

const reportsDashboardReducer = createReducer<
  //
  ReportsDashboardState,
  Action
>(initialState)
  .handleAction(actionFetchReportsDashboard.request, (state): ReportsDashboardState => {
    return { ...state, error: undefined, status: 'pending' };
  })
  .handleAction(actionFetchReportsDashboard.success, (state, action): ReportsDashboardState => {
    const savedReports = action.payload.data.savedReports;

    return {
      ...state,
      savedReports,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchReportsDashboard.failure, (state, action): ReportsDashboardState => {
    return {
      ...state,
      error: action.payload.error.message,
      status: 'rejected'
    };
  })
  .handleAction(actionUpdateReportsInRedux, (state, action): ReportsDashboardState => {
    const reportsAfterDelete: Array<SavedReportModel> = state.savedReports?.filter((item: SavedReportModel) => item.id !== action.payload.data) || [];

    return {
      ...state,
      savedReports: reportsAfterDelete
    };
  });

export default reportsDashboardReducer;
