import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { sdk } from 'src/utils/http';
import { actionFetchSubscriberSigningRule } from '../actions/subscriberSigningRule';

const fetchSubscriberSigningRule = (): Promise<HttpTypes.SettingsSubscriberSigningRuleApiResponse> => {
  // return http.get<HttpTypes.SettingsSubscriberSigningRuleApiResponse>('/settings/subscriber/signing-rule');
  return sdk.settings.subscriber.retrieveSigningPreferences();
};

function* sagaFetchSubscriberSigningRule() {
  try {
    const data = yield* call(fetchSubscriberSigningRule);
    yield put(actionFetchSubscriberSigningRule.success({ data }));
  } catch (error) {
    yield put(actionFetchSubscriberSigningRule.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchSubscriberSigningRule.request, sagaFetchSubscriberSigningRule)
];
