import * as React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { WorkspaceAssignableGroupsState } from 'src/@core/store/reducers/workspaceAssignableGroups';
import { SupportedJurisdictionsState } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { CreateFinancialWorkspaceMatterDetailsStepFormModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  onGroupFieldChange: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => void;
  assignGroupsState: WorkspaceAssignableGroupsState;
  supportedJurisdictionsState: SupportedJurisdictionsState;
  subscriberProfile: HttpTypes.UserProfileModel;
  isInteroperable: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const fieldName = modelKey<CreateFinancialWorkspaceMatterDetailsStepFormModel>();

class AboutYourMatterSection extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <FormGroup title="About your matter" className={classes.noBorder}>
        <Field //
          label="Your matter number"
          name={fieldName('createdByReference')}
          component={InputField}
          fullWidth
        />
        {this.renderGroupAndInstructingOrganisationDetail()}
      </FormGroup>
    );
  }

  private renderGroupAndInstructingOrganisationDetail() {
    const {
      //
      classes,
      assignGroupsState,
      supportedJurisdictionsState,
      isInteroperable
    } = this.props;
    if (
      //
      assignGroupsState.isLoading ||
      supportedJurisdictionsState.status === 'pending'
    ) {
      return (
        <div className={classes.fullWidth}>
          <LinearProgress />
        </div>
      );
    }
    if (
      //
      assignGroupsState.status === 'rejected' ||
      supportedJurisdictionsState.status === 'rejected'
    ) {
      return <div className={classes.message}>We can not fetch your user profile detail. Please refresh the page</div>;
    }

    const { items: groupOptions = [] } = assignGroupsState;

    return (
      <>
        {groupOptions.length > 1 && (
          <Field //
            label="Assign workspace to group"
            name={fieldName('groupId')}
            component={SelectField}
            options={groupOptions}
            placeholder={resolveSelectPlaceholder(true)}
            fullWidth
            onChange={this.handleOnGroupFieldChange}
          />
        )}

        {isInteroperable ? (
          <Field //
            label="This workspace is interoperable"
            name={fieldName('isInteroperable')}
            component={CheckboxField}
            formTip={
              <div style={{ textAlign: 'center' }}>
                When selected, the user will
                <br />
                be able to invite subscribers
                <br />
                from other ELNOs.
              </div>
            }
          />
        ) : null}
      </>
    );
  }

  private handleOnGroupFieldChange = (event: React.ChangeEvent<HTMLInputElement>, ...args) => {
    const [resolvedValue] = args as [string];
    this.props.onGroupFieldChange(event, resolvedValue);
  };
}

const styledComponent = withStyles(styles)(AboutYourMatterSection);

export default styledComponent;
