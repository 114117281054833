import { HttpTypes } from '@sympli/api-gateway/types';

import { SourceFundFormikModel } from 'src/containers/workspace/financial/directions/models';

export const LOAN_ADVANCE_CATEGORY = 'Loan Advance';
export const LOAN_ADVANCE_RANGE_CATEGORY = 'Loan Advance Available';

export function isLoanAdvance(category: string): boolean {
  return category === LOAN_ADVANCE_CATEGORY || category === LOAN_ADVANCE_RANGE_CATEGORY;
}

export function getDefaultLoanAdvanceSourceFund(
  trustAccountId: string,
  initialLoanAdvanceAmount: number,
  workspaceRole: HttpTypes.WorkspaceRoleEnum,
  isAutoBalancingSurplusForIMAllowed?: boolean,
  id?: string
): SourceFundFormikModel {
  return {
    trustAccountId: trustAccountId,
    amount: 0,
    reference: '',
    category: workspaceRole === HttpTypes.WorkspaceRoleEnum.IncomingMortgagee && isAutoBalancingSurplusForIMAllowed ? LOAN_ADVANCE_RANGE_CATEGORY : LOAN_ADVANCE_CATEGORY,
    categoryOther: '',
    subscriberName: '',
    isEditorOpen: true,
    participantId: '',
    accountType: HttpTypes.BankAccountTypeEnum.BankAccount,
    bankDetails: { bsb: '', accountNumber: '', accountName: '', bankName: '', reference: '' },
    initialLoanAdvanceAmount: initialLoanAdvanceAmount,
    displayLoanAdvanceAmount: initialLoanAdvanceAmount,
    isAutoBalancingSurplusForIMAllowed: isAutoBalancingSurplusForIMAllowed,
    id,
    paymentMethod: HttpTypes.PaymentMethodEnum.BankTransfer
  };
}
