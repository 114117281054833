// this file was automatically generated from enums.ts.mustache
import { LookupEnumModel } from '@sympli/ui-framework/models';

/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].dateOfDeath.dateOfDeathType

 schema version:
[{"id":0,"value":"ActualDate","name":"Actual date","_meta":null},{"id":1,"value":"DateDescription","name":"Date description","_meta":null},{"id":2,"value":"DateRange","name":"Date range","_meta":null}]

 hash:
 WzAsMSwyXQ==
*/
export enum DateOfDeathTypeEnum {
  ActualDate = 0,
  DateDescription = 1,
  DateRange = 2
}
export const TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS: LookupEnumModel<DateOfDeathTypeEnum>[] = [
  {
    id: DateOfDeathTypeEnum.ActualDate,
    name: 'Actual Date'
  },
  {
    id: DateOfDeathTypeEnum.DateDescription,
    name: 'Date Description'
  },
  {
    id: DateOfDeathTypeEnum.DateRange,
    name: 'Date Range'
  }
];

/**
 path:
 - titleReferences[*].deceasedJointTenants[*].deceasedProprietors[*].evidence.evidenceTypeValue

 schema version:
[{"id":0,"value":"CertificateUnderNSWTrusteeAndGuardianAct","name":"Certificate under NSW Trustee and Guardian Act","_meta":null},{"id":3,"value":"CoronersReport","name":"Coroners Report","_meta":null},{"id":4,"value":"DeathCertificate","name":"Death Certificate","_meta":null},{"id":11,"value":"MedicalCertificateCertifyingDeath","name":"Medical Certificate certifying death","_meta":null}]

 hash:
 WzAsMyw0LDExXQ==
*/
export enum EvidenceTypeValueEnum {
  CertificateUnderNSWTrusteeAndGuardianAct = 0,
  CoronersReport = 3,
  DeathCertificate = 4,
  MedicalCertificateCertifyingDeath = 11
}
export const TITLE_REFERENCES$DECEASED_JOINT_TENANTS$DECEASED_PROPRIETORS$EVIDENCE$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupEnumModel<EvidenceTypeValueEnum>[] = [
  {
    id: EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct,
    name: 'Certificate under NSW Trustee and Guardian Act'
  },
  {
    id: EvidenceTypeValueEnum.CoronersReport,
    name: 'Coroners Report'
  },
  {
    id: EvidenceTypeValueEnum.DeathCertificate,
    name: 'Death Certificate'
  },
  {
    id: EvidenceTypeValueEnum.MedicalCertificateCertifyingDeath,
    name: 'Medical Certificate certifying death'
  }
];
