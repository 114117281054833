import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import { colors } from 'src/theme';
import LodgementCaseStatusTimestamp from '../components/lodgement-case-status-timestamp/LodgementCaseStatusTimestamp';

interface Props {
  lodgementDetail: HttpTypes.LodgementDetail;
}

function InPreparationLodgementFailed({ lodgementDetail }: Props) {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Lodgement Unsuccessful
      </Typography>
      <LodgementCaseStatusTimestamp lodgementDetail={lodgementDetail} />
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        <b>Something went wrong</b>.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        <b>Edit, save and approve</b> the unverified document(s) to initiate another automated Lodgement Verification.
      </Typography>
    </>
  );
}

export default React.memo(InPreparationLodgementFailed);
