import _groupBy from 'lodash-es/groupBy';
import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { generalWorkspaceRoleOrderedList } from 'src/containers/shared/document-list/models';

const getDistributionsParticipants = (distributionsParticipants: HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants']) => distributionsParticipants;

export const distributionsParticipantOrderedListSelector = createSelector<
  //
  HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'],
  HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'],
  HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants']
>(getDistributionsParticipants, distributionsParticipants => {
  const participantsByWorkspaceRoleId = _groupBy(distributionsParticipants, 'participant.workspaceRole.id');
  return generalWorkspaceRoleOrderedList.flatMap(roleId => participantsByWorkspaceRoleId[roleId]).filter(Boolean);
});
