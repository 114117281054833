import { QldDocumentPartyJustification, QldNameChange, QldNameChangeConversion } from '@sympli-mfe/document-components/party-form/qld/2-24/components/party-justification';
import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { RootFormContextProvider } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';
import { HttpTypes } from '@sympli/api-gateway/types';

import { TransferRootFormContext } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/models';
import RootForm from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/RootForm';
import { convertPartiesToFormModel } from '../../helpers';
import {
  QLD_TRANSFER_DOCUMENT_ATTACHMENT_TYPE,
  QLD_TRANSFER_PARTY_FORM_CONFIG,
  QLD_TRANSFER_RECEIVING_TENANCY_CONFIG,
  QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG,
  QLD_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANSFERRED_TYPE
} from './config';
import converter from './conversion';
import { ApiTransfer2_24_1Model, Transfer2_24_1Model } from './models';
import Consideration from './sections/consideration/Consideration';
import validationSchema from './validationSchema';

export default function DocumentForm(props: DocumentFormProps<Transfer2_24_1Model, ApiTransfer2_24_1Model>): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<Transfer2_24_1Model>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <RootFormContextProvider<TransferRootFormContext<QldDocumentPartyJustification, QldNameChange>>
        partyFormConfig={QLD_TRANSFER_PARTY_FORM_CONFIG}
        nameChangeConversion={QldNameChangeConversion}
        partLandAffectedType="description"
        shareTransferredTypeOptions={QLD_TRANSFER_RELINQUISHING_TENANCY_SHARE_TRANSFERRED_TYPE}
        relinquishingTenancyConfig={QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG}
        receivingTenancyConfig={QLD_TRANSFER_RECEIVING_TENANCY_CONFIG}
        partyConversion={convertPartiesToFormModel}
        jurisdiction={HttpTypes.JurisdictionsEnum.QLD}
        ConsiderationComponent={Consideration}
        documentAttachmentTypeOptions={QLD_TRANSFER_DOCUMENT_ATTACHMENT_TYPE}
        shouldMergeRemainingWithReceiving={false}
      >
        <DocumentFormWrapper //
          {...props}
          converter={converter}
          validate={validate}
          RootForm={RootForm}
          validateOnMount
        />
      </RootFormContextProvider>
    </DndContextProvider>
  );
}
