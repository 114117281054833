import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { getLinkedWorkspaceTableRow } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/helper';
import { useLinkedLodgementWorkspaces } from 'src/store/reducers/workspace/linkedLodgementDetail';
import { useLinkedSettlementWorkspaces } from 'src/store/reducers/workspace/linkedSettlementDetail';
import LinkedWorkspaceCardDetail from './LinkedWorkspaceCardDetail';
import { LinkedWorkspaceCardModel } from './models';

interface Props {
  workspaceId: string;
  linkedWorkspaceClusterId: string;
  className?: string;
  linkedWorkspaceType: HttpTypes.LinkedWorkspaceTypeEnum;
  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
}

function LinkedWorkspaceCardDetailContainer({ workspaceId, linkedWorkspaceClusterId, linkedWorkspaceType, workspaceStatus }: Props) {
  const linkedSettlementDetail = useLinkedSettlementWorkspaces(workspaceId, linkedWorkspaceClusterId);
  const linkedLodgementDetail = useLinkedLodgementWorkspaces(workspaceId, linkedWorkspaceClusterId);

  const model = React.useMemo(() => {
    const rowData = getLinkedWorkspaceTableRow(workspaceId, linkedWorkspaceType, linkedSettlementDetail, linkedLodgementDetail);

    // linked lodgement
    if (linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement) {
      return {
        isLoading: linkedLodgementDetail.isLoading,
        apiStatus: linkedLodgementDetail.status,
        linkedWorkspaceOverallStatus: linkedLodgementDetail.detail?.linkedWorkspaceOverallStatus,
        data: rowData
      } satisfies LinkedWorkspaceCardModel;
    }

    // linked settlement
    return {
      isLoading: linkedSettlementDetail.isLoading,
      apiStatus: linkedSettlementDetail.status,
      linkedWorkspaceOverallStatus: linkedSettlementDetail.detail?.linkedWorkspaceOverallStatus,
      data: rowData
    } satisfies LinkedWorkspaceCardModel;
  }, [workspaceId, linkedWorkspaceType, linkedSettlementDetail, linkedLodgementDetail]);

  return (
    <LinkedWorkspaceCardDetail //
      apiStatus={model.apiStatus}
      linkedWorkspaceOverallStatus={model.linkedWorkspaceOverallStatus}
      isLoading={model.isLoading}
      rows={model.data}
      linkedWorkspaceType={linkedWorkspaceType}
      workspaceStatus={workspaceStatus}
    />
  );
}

export default React.memo(LinkedWorkspaceCardDetailContainer);
