import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchProfile = createAsyncAction(
  //
  'FETCH_PROFILE',
  'FETCH_PROFILE_SUCCESS',
  'FETCH_PROFILE_ERROR'
)<
  //
  void,
  { data: HttpTypes.UserProfileModel },
  { error: Error; redirectOnClose?: string }
>();

export const actionUpdateProfile = createAction(
  //
  'UPDATE_PROFILE'
)<
  //
  Partial<HttpTypes.UserProfileModel>
>();

export const actionRequireLinkDevice = createAction(
  //
  'REQUIRE_LINK_DEVICE'
)<void>();
