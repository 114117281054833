import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Action } from 'typesafe-actions';

import useSafeDispatch, { SafeDispatch } from 'src/@core/hooks/useSafeDispatch';
import { Store } from 'src/store/reducers';
import useStoreSelector from './useStoreSelector';

/**
 * dispatch redux action and listens for changes in reducer
 */
export default function useRequestReduxDataOnMount<T>(
  //
  action: Action | null,
  selector: (rootReducer: Store) => T
): [T, SafeDispatch<any>] {
  //
  const safeDispatch = useSafeDispatch(useDispatch());

  React.useLayoutEffect(() => {
    action && safeDispatch(action);
  }, []);

  const slice = useStoreSelector<T>(selector);
  return [slice, safeDispatch];
}
