import React from 'react';

import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';

import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import DirectionsContext from '../../../../Directions.context';
import SourceFundEditor, { Props } from './SourceFundEditor';

function SourceFundEditorContainer(props: Omit<Props<FormikValues>, 'dispatch' | 'setFocusLabel'>) {
  const dispatch = useSafeDispatch(useDispatch());
  const { setFocusLabel } = React.useContext(DirectionsContext);
  const workspaceDetail = useWorkspaceDetail(props.workspaceId, props.participantId);

  return (
    <SourceFundEditor //
      {...props}
      dispatch={dispatch}
      setFocusLabel={setFocusLabel}
      linkedWorkspaceCluster={workspaceDetail.detail?.linkedWorkspaceCluster}
    />
  );
}

export default React.memo(SourceFundEditorContainer);
