// tslint:disable:max-line-length
import * as React from 'react';

export default function IconAuthenticationRequired(props: React.SVGProps<SVGSVGElement> & { title?: string }) {
  const { title = 'Authentication Required', ...rest } = props;
  return (
    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title>{title}</title>}
      <g id="01C-//-REGISTRATION-ONLY-(WITH-SIGN-ALL-DOCS)-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Check-your-mobile" transform="translate(-575.000000, -399.000000)">
          <g id="popup" transform="translate(460.000000, 359.000000)">
            <g id="illo-mfa" transform="translate(115.000000, 40.000000)">
              <circle id="Oval" fill="#F2F2F2" cx="65" cy="65" r="65" />
              <g id="Group" transform="translate(37.709924, 17.862595)">
                <g transform="translate(0.000000, 30.582929)" id="Path">
                  <path
                    d="M29.7851145,35.6152104 C29.0513995,29.1927954 27.5561069,16.107009 27.4725191,15.7826446 C27.3610687,15.3470696 12.3524173,5.02301432 12.3524173,5.02301432 C12.3524173,5.02301432 8.81386768,-0.806277206 1.45814249,0.852614977 C1.45814249,0.852614977 -1.12379135,1.45500599 2.55407125,6.34827456 C2.73053435,6.57996341 2.95343511,6.83945492 3.13918575,7.07114378 C3.59427481,7.66426724 4.00292621,8.29446092 4.39300254,8.9246546 C5.00597964,9.91628288 5.56323155,10.9449814 5.98117048,12.0292852 C6.5105598,13.3916157 6.79847328,14.818819 6.8913486,16.2645574 C7.0678117,18.9984859 6.64058524,21.7509495 6.64987277,24.4848779 C6.64987277,25.4023658 6.6870229,26.3291212 6.78918575,27.246609 C6.88206107,28.1084916 7.03066158,28.9703741 7.24427481,29.8137215 C7.45788804,30.6292663 7.72722646,31.4170084 8.07086514,32.1862154 C8.41450382,32.9554224 8.83244275,33.6968267 9.33396947,34.3733581 C9.86335878,35.1054949 10.4949109,35.7634913 11.2100509,36.3195445 C11.8508906,36.8199924 12.5474555,37.2370324 13.2811705,37.5706643 L13.2811705,49.1365718 L39.6670483,49.1365718 L39.6670483,34.7903981 L29.7851145,35.6152104 Z"
                    fill="#EFCA9B"
                  />
                  <path
                    d="M45.1188295,27.9880134 C44.1343511,26.7739638 37.233715,30.073213 32.8592875,32.3530314 L32.5342239,31.6579648 L11.8694656,31.6023595 L30.1844784,47.3201312 L39.713486,47.3201312 L35.7384224,38.553025 C45.2024173,36.1619961 45.1188295,27.9880134 45.1188295,27.9880134 Z"
                    fill="#D8B393"
                  />
                </g>
                <g transform="translate(9.287532, 0.000000)">
                  <path
                    d="M36.8900763,60.6561416 C36.8900763,62.8803546 35.0790076,64.6875276 32.85,64.6875276 L4.58804071,64.6875276 C2.35903308,64.6875276 0.547964377,62.8803546 0.547964377,60.6561416 L0.547964377,4.38355309 C0.547964377,2.1593401 2.35903308,0.352167056 4.58804071,0.352167056 L32.85,0.352167056 C35.0790076,0.352167056 36.8900763,2.1593401 36.8900763,4.38355309 L36.8900763,60.6561416 Z"
                    id="Path"
                    fill="#333333"
                  />
                  <rect id="Rectangle" fill="#2EA392" x="3.04631043" y="4.95814144" width="31.3454198" height="50.96228" />
                  <ellipse id="Oval" fill="#FF884D" cx="18.7143766" cy="2.71539335" rx="1" ry="1" />
                </g>
                <path
                  d="M28.0019084,40.7587029 C18.6122137,37.5613968 17.6927481,25.7081951 17.7484733,20.555435 C22.4108142,20.3700839 25.986514,18.5351082 28.0019084,17.2005804 C30.0080153,18.5351082 33.583715,20.3700839 38.246056,20.555435 C38.3017812,25.6989275 37.3637405,37.5613968 28.0019084,40.7587029 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <g transform="translate(38.078880, 25.022396)" id="Path">
                  <path
                    d="M16.643257,3.79042963 C17.2562341,5.27323828 16.485369,6.98773579 14.9157761,7.62719702 L5.61895674,11.4454293 C4.0586514,12.0848905 2.29402036,11.4083591 1.68104326,9.93481799 L1.01234097,8.31299603 C0.399363868,6.83018737 1.17022901,5.11568986 2.73982188,4.47622863 L12.0366412,0.657996341 C13.5969466,0.0185351082 15.3615776,0.695066557 15.9745547,2.16860766 L16.643257,3.79042963 Z"
                    fill="#EFCA9B"
                  />
                  <path
                    d="M1.33740458,9.07293546 L1.69033079,9.92555044 C2.30330789,11.4083591 4.0586514,12.0848905 5.62824427,11.4361618 L14.9250636,7.61792947 C16.485369,6.97846824 17.2562341,5.25470317 16.6525445,3.78116207 L16.2996183,2.93781465 L1.33740458,9.07293546 Z"
                    fill="#D8B393"
                  />
                </g>
                <g transform="translate(37.150127, 32.436439)" id="Path">
                  <path
                    d="M16.503944,4.26307488 C17.1169211,5.74588354 16.346056,7.46038105 14.7764631,8.09984228 L5.47964377,11.9273421 C3.91933842,12.5668034 2.15470738,11.8902719 1.54173028,10.4167308 L0.87302799,8.79490884 C0.260050891,7.31210018 1.03091603,5.59760267 2.60050891,4.95814144 L11.8973282,1.13990915 C13.4576336,0.500447921 15.2222646,1.17697937 15.8352417,2.65052047 L16.503944,4.26307488 Z"
                    fill="#EFCA9B"
                  />
                  <path
                    d="M1.1980916,9.54558072 L1.55101781,10.3981957 C2.16399491,11.8810044 3.91933842,12.5575358 5.4889313,11.908807 L14.7857506,8.09057473 C16.346056,7.45111349 17.1169211,5.72734843 16.5132316,4.25380733 L16.1603053,3.40119235 L1.1980916,9.54558072 Z"
                    fill="#D8B393"
                  />
                </g>
                <g transform="translate(37.150127, 40.777238)" id="Path">
                  <path
                    d="M15.9652672,4.00358337 C16.5782443,5.48639203 15.8073791,7.20088953 14.2377863,7.84035077 L4.94096692,11.6585831 C3.38066158,12.2980443 1.61603053,11.6215128 1.00305344,10.1479717 L0.334351145,8.52614977 C-0.278625954,7.04334111 0.492239186,5.32884361 2.06183206,4.68938237 L11.3586514,0.871150085 C12.9189567,0.231688852 14.6835878,0.908220302 15.2965649,2.3817614 L15.9652672,4.00358337 Z"
                    fill="#EFCA9B"
                  />
                  <path
                    d="M0.659414758,9.29535676 L1.01234097,10.1479717 C1.62531807,11.6307804 3.38066158,12.3073118 4.95025445,11.6585831 L14.2470738,7.84035077 C15.8073791,7.20088953 16.5782443,5.47712447 15.9745547,4.00358337 L15.6216285,3.15096839 L0.659414758,9.29535676 Z"
                    fill="#D8B393"
                  />
                </g>
                <g transform="translate(38.078880, 49.118037)" id="Path">
                  <path
                    d="M13.726972,3.50313545 C14.2377863,4.75425525 13.587659,6.19999369 12.2688295,6.74677938 L4.43015267,9.97188821 C3.11132316,10.5186739 1.62531807,9.94408555 1.11450382,8.69296574 L0.547964377,7.32136774 C0.0371501272,6.07024793 0.687277354,4.62450949 2.00610687,4.0777238 L9.84478372,0.852614977 C11.1636132,0.315096839 12.6496183,0.880417639 13.1604326,2.13153744 L13.726972,3.50313545 Z"
                    fill="#EFCA9B"
                  />
                  <path
                    d="M0.808015267,7.96082897 L1.10521628,8.67443064 C1.61603053,9.92555044 3.10203562,10.4908712 4.42086514,9.9533531 L12.259542,6.72824427 C13.5783715,6.19072614 14.2284987,4.73572014 13.7176845,3.49386789 L13.429771,2.78026623 L0.808015267,7.96082897 Z"
                    fill="#D8B393"
                  />
                </g>
                <polygon id="Path" fill="#FFFFFF" points="11.3122137 83.8713646 42.9919847 83.6674784 43.3820611 75.984676 11.7022901 76.1885622" />
                <path
                  d="M33.8159033,25.6340546 C33.9645038,25.7823355 34.0295165,25.958419 34.0295165,26.1623052 C34.0295165,26.3661914 33.9552163,26.5422749 33.8159033,26.6905558 L28.187659,32.3066936 L27.1288804,33.3631948 C26.9802799,33.5114756 26.8038168,33.5763485 26.5994911,33.5763485 C26.3951654,33.5763485 26.2187023,33.5022081 26.0701018,33.3631948 L25.0113232,32.3066936 L22.197201,29.4986247 C22.0486005,29.3503438 21.9835878,29.1742603 21.9835878,28.9703741 C21.9835878,28.7664879 22.057888,28.5904044 22.197201,28.4421235 L23.2559796,27.3856224 C23.4045802,27.2373415 23.5810433,27.1724686 23.785369,27.1724686 C23.9896947,27.1724686 24.1661578,27.246609 24.3147583,27.3856224 L26.5994911,29.6747082 L31.7076336,24.5775535 C31.8562341,24.4292726 32.0326972,24.3643997 32.2370229,24.3643997 C32.4413486,24.3643997 32.6178117,24.4385402 32.7664122,24.5775535 L33.8159033,25.6340546 Z"
                  id="Path"
                  fill="#FF811A"
                />
                <path
                  d="M7.69936387,108.940098 C14.0520356,111.006763 20.8226463,112.137405 27.8625954,112.137405 C33.2307888,112.137405 38.4410941,111.479408 43.4284987,110.256091 L43.2148855,82.1846697 L8.62811705,82.3978235 L7.69936387,108.940098 Z"
                  id="Path"
                  fill="#53BBB3"
                />
                <g transform="translate(19.503817, 45.411015)" fill="#EFCA9B" id="Path">
                  <path d="M27.4632316,53.2328307 L48.9452926,53.2328307 L45.685369,44.5027948 C45.685369,44.5027948 46.2240458,40.6752949 44.5244275,35.1796354 C44.5058524,35.0128194 44.4687023,34.8367359 44.4036896,34.6699199 L38.68257,19.0818939 C38.1160305,17.562015 35.9613232,16.8391458 33.8716285,17.4693395 C33.5930025,17.5527475 33.3236641,17.6639581 33.0821883,17.7844363 C32.1534351,17.3395937 31.0575064,17.2191155 29.9987277,17.5342124 C29.5715013,17.6639581 29.1907125,17.8493092 28.8563613,18.0902656 C27.8997455,17.5990852 26.7573791,17.4508044 25.6521628,17.7751688 C24.8255725,18.0439278 24.1475827,18.5351082 23.6832061,19.1653019 C22.6894402,18.5629109 21.4356234,18.3590247 20.2468193,18.7204593 C19.4666667,18.9521481 18.825827,19.3969907 18.3614504,19.9623115 L12.2131043,3.41045991 C11.4793893,1.43647089 9.19465649,0.352167056 7.10496183,0.982360734 C5.01526718,1.61255441 3.91933842,3.72555675 4.65305344,5.69027822 L13.1139949,28.4421235 C13.1697201,28.5811368 13.2254453,28.7201501 13.2997455,28.8498959 L16.2717557,36.4029525 C16.2717557,36.4029525 9.15750636,31.176052 2.80483461,31.9267239 C2.80483461,31.9267239 -4.30012723,33.7802347 5.96259542,39.3500347 C5.96259542,39.3500347 12.6867684,42.6122137 15.1479644,44.6788783 C15.1479644,44.6788783 20.0982188,49.9706517 25.271374,50.8325342 C25.252799,50.8418018 26.4787532,51.1568986 27.4632316,53.2328307 Z" />
                  <polygon opacity="0.3" points="7.94083969 19.2765125 9.69618321 19.2765125 6.43625954 10.5094063 4.72735369 10.5094063" />
                </g>
                <path
                  d="M69.7400763,90.9332408 L43.4935115,100.330541 L45.3974555,105.86327 L69.192112,97.3463882 C69.9165394,96.7532648 70.6223919,96.1416062 71.3096692,95.5114125 L69.7400763,90.9332408 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M42.8340967,104.816037 L44.3201018,110.03367 C54.8243003,107.299741 64.2882952,101.9987 72.0155216,94.8626837 L42.8340967,104.816037 Z"
                  id="Path"
                  fill="#53BBB3"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
