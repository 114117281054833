import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Markdown, { MarkdownToJSX } from '@sympli/markdown-to-jsx';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import { useContentTruncated } from 'src/@core/hooks';
import { ResultItem } from '../../models';
import { ColumnKeyName, Data } from './models';

const REGEX_HIGHLIGHT = /\*\*(.*?)\*\*/g; // regexp to match highlighted parts of the text

export const renderSuggestionContent = (
  what: ColumnKeyName, //keyof ResultItemModel['searchHighlights'],
  {
    //
    reference,
    sympliId,
    customer = [],
    title = [],
    address = [],
    jurisdiction,
    searchHighlights
  }: Data
) => {
  if (!searchHighlights) {
    return null;
  }
  switch (what) {
    case 'reference':
      return 'matter' in searchHighlights ? convertMd(searchHighlights['matter']![0]) : reference;
    case 'sympliId':
      return 'sympliId' in searchHighlights ? convertMd(searchHighlights['sympliId']![0]) : sympliId;
    case 'address':
      return DisplayItems(address, searchHighlights, 'multiple addresses found', 'titleAddresses');
    case 'jurisdiction':
      return (
        <Typography variant={'body2'} className="pl-[4px]">
          {jurisdiction}
        </Typography>
      );
    case 'title':
      return DisplayItems(title, searchHighlights, 'multiple titles found', 'titleReferences');
    case 'customer': {
      if (customer.length === 0) {
        return '-';
      }

      return DisplayItems(customer, searchHighlights, 'multiple customers found', 'clientNames');
    }
    default:
      return null;
  }
};

const mdOptions: MarkdownToJSX.Options = {
  disableParsingRawHTML: true,
  // restrict markdown parser to only recognize bold text
  whitelistRules: ['textBolded']
};

function convertMd(mdText: string) {
  return <Markdown options={mdOptions}>{mdText}</Markdown>;
}

function DisplayItems(items: string[] = [], searchHighlights: ResultItem['searchHighlights'], tooltipLabel: string, searchKey: string) {
  const { refContainerRef, isContentTruncated } = useContentTruncated([items]);

  if (!items.length) {
    return null;
  }

  const hasHighlights = searchKey in searchHighlights;

  const highlightItems: string[] = hasHighlights ? searchHighlights[searchKey]!.map(address => address.replace(REGEX_HIGHLIGHT, '$1')) : [];
  const notHighlightItems: string[] = items?.filter(address => !highlightItems.some(ha => ha === address));
  const unitedItems: string[] = hasHighlights ? searchHighlights[searchKey]!.concat(notHighlightItems) : items;

  const firstItem = convertMd(unitedItems[0]);

  return (
    <div className="flex whitespace-nowrap" ref={refContainerRef}>
      {isContentTruncated && (
        <Tooltip
          title={
            <Typography variant="body2" className="text-[white]">
              {firstItem}
            </Typography>
          }
          placement="top"
        >
          <Typography className="truncate" variant="body2">
            {firstItem}
          </Typography>
        </Tooltip>
      )}
      {!isContentTruncated && <Typography variant="body2">{firstItem}</Typography>}
      {unitedItems.length > 1 && (
        <>
          {','}
          <Tooltip title={renderHintInformation(unitedItems, tooltipLabel)} placement="top">
            <Typography variant={hasHighlights ? 'body2_bold' : 'body2'} className="pl-[4px] underline">
              +{unitedItems.length - 1}
            </Typography>
          </Tooltip>
        </>
      )}
    </div>
  );
}

function renderHintInformation(items: string[], tooltipLabel: string) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" className="mb-[16px] uppercase text-[white]">
        {tooltipLabel}
      </Typography>
      {items.map((item, idx) => {
        return (
          <Typography variant="body2" className="text-[white]" key={idx}>
            {convertMd(item)}
          </Typography>
        );
      })}
    </Box>
  );
}
