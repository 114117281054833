import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';

export interface Props {
  icon: React.ReactElement;
  hoveredIcon: React.ReactElement;
  onClick: () => void;
}

const AnimatedIconButton = ({ icon, hoveredIcon, onClick }: Props) => {
  return (
    <div className="relative min-w-[22px] h-[22px] group medium-screen:mx-[24px] mx-[15px]">
      <ButtonBase style={{ transition: 'opacity 0.2s ease-in-out' }} className="absolute top-[3px] left-[3px] opacity-100 group-hover:opacity-0">
        {icon}
      </ButtonBase>
      <ButtonBase style={{ transition: 'opacity 0.2s ease-in-out' }} onClick={onClick} className="absolute top-[0px] left-[0px] opacity-0 group-hover:opacity-100">
        {hoveredIcon}
      </ButtonBase>
    </div>
  );
};

export default React.memo(AnimatedIconButton);
