import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { refresh } from 'src/containers/shared/document-list/components/delete-document-dialog/DeleteDocumentDialogContainer';
import FinancialDetailContainer from 'src/containers/workspace/financial/detail';
import LodgementDetailContainer from 'src/containers/workspace/registration-only/detail';
import { WorkspaceLogNotificationsContainer } from 'src/containers/workspace/shared/detail/components/workspace-log-notifications/WorkspaceLogNotificationsContainer';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import WorkspaceDetailPageLoader from './workspace-detail-page-loader';

export interface WorkspaceDetailRouteLocationState {
  message?: string;
  secondaryMessage?: string;
  isDocumentDeleted?: boolean;
}

interface TypedLocation extends Location {
  state: WorkspaceDetailRouteLocationState;
}

export default function WorkspaceDetailPageContainer() {
  const { workspaceId, participantId } = useRouterParams<WorkspaceDetailRouteParams>();

  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const { state, pathname, search } = useLocation() as TypedLocation;
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  React.useEffect(() => {
    if (state?.message) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: state.message,
          secondaryMessage: state.secondaryMessage,
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );
      // cleanup location state
      // this will cause componentDidUpdate to be triggered, so it's important to prevent unnecessary re-fetch/re-render
      // history.replace({ ...history.location, state: undefined });
      navigate(pathname + search, { state: undefined, replace: true });
    }
  }, [dispatch, navigate, pathname, search, state]);

  React.useEffect(() => {
    if (state?.isDocumentDeleted) {
      refresh(dispatch, workspaceId, participantId);
    }
  }, [dispatch, participantId, state, workspaceId]);

  if (workspaceBasicInfoState.error || workspaceDetailState.error) {
    return <GeneralCrashedContent />;
  }

  return (
    <>
      {getDetailContent()}
      <WorkspaceLogNotificationsContainer {...{ workspaceId, participantId }} />
    </>
  );

  function getDetailContent() {
    switch (workspaceBasicInfoState.detail?.workspaceTypeId) {
      case HttpTypes.WorkspaceTypeEnum.FinancialSettlement:
        return (
          <FinancialDetailContainer //
            workspaceId={workspaceId}
            participantId={participantId}
          />
        );
      case HttpTypes.WorkspaceTypeEnum.RegistrationOnly:
        // fallback to here even if we don't know workspace type yet
        return (
          <LodgementDetailContainer //
            workspaceId={workspaceId}
            participantId={participantId}
          />
        );
      default:
        return <WorkspaceDetailPageLoader />;
    }
  }
}
