import { HttpTypes } from '@sympli/api-gateway/types';

// todo: after we move all to invitations only legit status will be accepted.
export const participantStatusNameMapping: Record<HttpTypes.ParticipantStatusEnum, string> = {
  [HttpTypes.ParticipantStatusEnum.Pending]: 'Invited',
  [HttpTypes.ParticipantStatusEnum.Accepted]: 'Accepted',
  [HttpTypes.ParticipantStatusEnum.Rejected]: 'Rejected',
  [HttpTypes.ParticipantStatusEnum.InviteForwarded]: 'forwarded',
  [HttpTypes.ParticipantStatusEnum.Withdrawn]: 'Withdrawn'
};
