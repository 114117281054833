import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { columnFormatterCheck, ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';
import { dynamicColumns } from '@sympli/ui-framework/components/table/helpers';

import { IconStar } from 'src/components/icons';
import {
  financialWorkspaceStatusFormatter,
  jurisdictionFormatter,
  settlementTimeFormatter,
  titleFormatter,
  workspaceStatusBarFormatter
} from 'src/containers/dashboard/shared/formatters';
import { modelKey } from 'src/utils/formUtils';
import { UserProfileDetail } from '../models';
import { LinkedWorkspaceTableModel, Variant } from './models';
import ViewButton, { ViewButtonProps } from './row-detail/ViewButton';

const cf = columnFormatterCheck<LinkedWorkspaceTableModel>();

const fieldName = modelKey<LinkedWorkspaceTableModel>();

export const resolveColumns = ({
  variant,
  userProfileDetail,
  viewButtonLabel,
  onViewClick,
  showOrderColumn
}: {
  //
  variant: Variant;
  userProfileDetail: UserProfileDetail;
  viewButtonLabel?: string;
  onViewClick: (participantsHaveAccess: HttpTypes.LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void;
  showOrderColumn?: boolean;
}) => {
  return resolveTableColumns(variant, userProfileDetail, onViewClick, viewButtonLabel, showOrderColumn);
};

function resolveTableColumns(
  variant: Variant,
  userProfileDetail: UserProfileDetail,
  onViewClick: (participantsHaveAccess: HttpTypes.LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void,
  viewButtonLabel?: string,
  showOrderColumn?: boolean
) {
  const orderLabel = showOrderColumn ? 'WS' : '';
  const columns: ColumnsModel<LinkedWorkspaceTableModel> = dynamicColumns<LinkedWorkspaceTableModel, Variant>(
    {
      isCurrent: {
        thClassName: 'w-[5px] pr-[15px]',
        label: '',
        name: fieldName('isCurrentWorkspace'),
        formatter: cf<LinkedWorkspaceTableModel['isCurrentWorkspace']>(isCurrentWorkspaceFormatter)
      },
      order: {
        thClassName: 'w-[20px]',
        label: `${orderLabel}`,
        name: fieldName('order'),
        formatter: cf<LinkedWorkspaceTableModel['order']>(orderFormatter)
      },
      setlDate: {
        thClassName: (args: Variant) => {
          return args === 'small' ? 'w-[88px]' : 'w-[90px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        name: fieldName('settlementDate'),
        get: ({ settlementDate, workspaceStatus, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: LinkedWorkspaceTableModel) => ({
          settlementDate,
          workspaceStatusId: workspaceStatus,
          pendingProposedSettlementDate,
          expectedSettlementDate,
          isUnsupportedSettlementDate
        }),
        formatter: cf<{
          //
          settlementDate: LinkedWorkspaceTableModel['settlementDate'];
          workspaceStatusId: LinkedWorkspaceTableModel['workspaceStatus'];
          pendingProposedSettlementDate: LinkedWorkspaceTableModel['pendingProposedSettlementDate'];
          expectedSettlementDate: LinkedWorkspaceTableModel['expectedSettlementDate'];
          isUnsupportedSettlementDate: LinkedWorkspaceTableModel['isUnsupportedSettlementDate'];
        }>(settlementTimeFormatter)
      },
      title: {
        label: 'Title',
        thClassName: (args: Variant) => {
          return args === 'small' ? 'w-[80px]' : 'w-[100px]';
        },
        name: fieldName('sympliId'),
        get: ({ titles, sympliId }: LinkedWorkspaceTableModel) => ({
          titles,
          sympliId
        }),
        formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple titles found</b>
                  </div>
                  {row.titles.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      jdx: {
        thClassName: (args: Variant) => {
          return args === 'small' ? 'w-[48px]' : 'w-[50px]';
        },
        label: 'JDX',
        name: fieldName('jurisdiction'),
        formatter: cf<LinkedWorkspaceTableModel['jurisdiction']>(jurisdictionFormatter)
      },
      wsStatusText: {
        thClassName: (args: Variant) => {
          return args === 'small' ? 'w-[92px]' : 'w-[96px]';
        },
        label: 'WS STATUS',
        name: fieldName('workspaceStatus'),
        formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(financialWorkspaceStatusFormatter)
      },

      permission: {
        thClassName: (variant: Variant) => {
          if (variant === 'large') {
            return 'w-[160px] px-[20px]';
          } else if (variant === 'small') {
            return 'w-[60px]';
          }
          return 'w-[80px] px-[20px]';
        },
        label: 'ACCESS',
        name: '-',
        get: ({ linkedWorkspaceParticipants, workspaceId, isCurrentWorkspace }: LinkedWorkspaceTableModel) => ({
          linkedWorkspaceParticipants,
          userProfileDetail,
          linkedToWorkspaceId: workspaceId,
          onViewClick,
          viewButtonLabel,
          isCurrentWorkspace,
          className: variant === 'small' ? 'px-[12px]' : undefined
        }),
        formatter: cf<ViewButtonProps>(permissionCheckFormatter)
      },
      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        name: fieldName('workspaceStatus'),
        padding: 'none',
        formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(workspaceStatusBarFormatter)
      }
    },
    variant
  );

  return columns;
}

export function isCurrentWorkspaceFormatter<
  //
  R extends object,
  V extends boolean
>({ value }: FormatterInputModel<R, V>) {
  if (!value) {
    return null;
  }
  return (
    <div>
      <IconStar height={'16px'} width={'16px'} style={{ marginTop: 3 }} />
    </div>
  );
}

export function orderFormatter<
  //
  R extends object,
  V extends number
>({ value }: FormatterInputModel<R, V>) {
  return <div className="font-[700]">{value}</div>;
}

// need to pass in the user Id, user allowed group id / user subscriber to check if the user has
// the permission to access the workspace and what role requires to redirect
function permissionCheckFormatter<
  //
  R extends object,
  V extends ViewButtonProps
>({ value }: FormatterInputModel<R, V>) {
  return (
    <ViewButton //
      {...value}
    />
  );
}
