import * as React from 'react';

import visuallyHidden from '@mui/utils/visuallyHidden';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import NavLink from '@sympli/ui-framework/components/nav-link';
import { IconEye } from '@sympli/ui-framework/icons';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { UserAvatar } from 'src/components/avatars';
import { LinkedWorkspaceParticipantModel } from 'src/containers/shared/linked-workspace-list/models';
import ParticipantStatusBadge from 'src/containers/workspace/shared/components/participant-status-badge';
import { resolveInvitationType } from 'src/models/roles';
import { useSharedStyles } from '../styles';
import { useStyles } from './styles';

interface Props {
  workspaceId: string;
  participants: LinkedWorkspaceParticipantModel[];
  currentWorkspaceId: string;
  currentSubscriberId: string;
}

function ParticipantList({
  //
  workspaceId,
  participants,
  currentWorkspaceId,
  currentSubscriberId
}: Props) {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  return participants.map(
    (
      {
        //
        subscriberId,
        subscriberName,
        workspaceRoleId,
        invitationStatusId,
        participantId,
        avatarUrl
      },
      i //
    ) => (
      <FlexLayout //
        alignItems="center"
        justifyContent="space-between"
        key={subscriberName}
        className={sharedClasses.item}
      >
        <div>
          <FlexLayout alignItems="center">
            <UserAvatar //
              text={subscriberName}
              src={avatarUrl}
              size="extraSmall"
              className={classes.avatar}
              margin="right"
            />
            <b>
              <span style={visuallyHidden}>Subscriber name</span>
              {subscriberName}
            </b>
          </FlexLayout>
          <div>
            <span>
              <span style={visuallyHidden}>Workspace role</span>
              {resolveInvitationType(workspaceRoleId)}
            </span>
            <ParticipantStatusBadge //
              margin="left"
              participantStatusId={invitationStatusId}
            />
          </div>
        </div>
        {currentWorkspaceId !== workspaceId && invitationStatusId !== HttpTypes.ParticipantStatusEnum.Pending && currentSubscriberId === subscriberId && (
          <NavLink icon={<IconEye className={classes.seeWorkspaceIcon} />} to={resolveWorkspaceDetailLink({ workspaceId, participantId })}>
            See workspace
          </NavLink>
        )}
      </FlexLayout>
    )
  );
}

export default React.memo(ParticipantList);
