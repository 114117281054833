import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { NoticeOfDeath2_21_1Model, TitleReferenceModel } from '../../models';
import { titlesHasSameTenancyStructureAndNumberOfGroups } from '../../utils/common';

const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match; please remove the mismatched titles';

type FormModel = NoticeOfDeath2_21_1Model;
interface NoticeOfDeathContext {
  partyBook: FormModel['partyBook'];
}

const contextResolver = ({ partyBook }: FormModel): NoticeOfDeathContext => ({ partyBook });

// path: titleReferences

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel, NoticeOfDeathContext>() //
  .test(
    //
    'Multiple title selected: check for matching relinquishing proprietor details',
    MISMATCHED_TENANCY_STRUCTURE_MESSAGE,
    function test(this: yup.TestContext<NoticeOfDeathContext>, titleReferences: TitleReferenceModel[]) {
      const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected);
      if (selectedTitleReferences.length <= 1) {
        return true;
      }

      // Check tenancy type per title
      if (!titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences)) {
        this.createError({ message: MISMATCHED_TENANCY_STRUCTURE_MESSAGE });
        return false;
      }

      return true;
    }
  );

const yupMemoizedSchema: yup.MixedSchema<TitleReferenceModel[], NoticeOfDeathContext> = memoizeSchemaWithContext<TitleReferenceModel[], FormModel, NoticeOfDeathContext>(
  yupTitleReferences,
  contextResolver
);
export default yupMemoizedSchema;
