import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { LinkedWorkspaceApiResponseModel, LinkedWorkspaceListItem } from './models';

export const resolveComponentItemsSelector = createSelector<
  //
  {
    //
    items: LinkedWorkspaceApiResponseModel[];
    currentWorkspaceId: string;
  },
  LinkedWorkspaceApiResponseModel[],
  string,
  LinkedWorkspaceListItem[]
>(
  ({ items }: { items: LinkedWorkspaceApiResponseModel[] }) => items,
  ({ currentWorkspaceId }: { currentWorkspaceId: string }) => currentWorkspaceId,

  (originalItems: LinkedWorkspaceApiResponseModel[], currentWorkspaceId: string): LinkedWorkspaceListItem[] => {
    const items: LinkedWorkspaceListItem[] = originalItems
      .reduce(
        (
          acc: LinkedWorkspaceListItem[], //
          {
            //
            workspaceId,
            workspaceStatusId,
            lodgementCaseStatusId,
            jurisdiction,
            participants,
            documents: originalDocuments
          }: LinkedWorkspaceApiResponseModel,
          i: number
        ) => {
          // ignore lodgement instructions
          const documents = originalDocuments.filter(item => item.documentTypeId !== HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions);
          let workspaceTypeTitle = '';

          // we receive empty documents in case when invitation has not been accepteds
          if (participants.some(e => e.invitationStatusId !== HttpTypes.ParticipantStatusEnum.Pending)) {
            const [firstDocument, ...restOfDocuments] = documents;
            workspaceTypeTitle = `${jurisdiction} ${firstDocument?.documentName}${restOfDocuments.length ? ` (+${restOfDocuments.length})` : ''}`;
          } else {
            workspaceTypeTitle = 'Awaiting invitee';
            workspaceStatusId = HttpTypes.WorkspaceStatusEnum.AwaitingInvitee;
            lodgementCaseStatusId = HttpTypes.LodgementCaseStatusEnum.AwaitingInvitee;
          }

          acc.push({
            order: i + 1,
            workspaceId,
            workspaceStatusId,
            lodgementCaseStatusId,
            workspaceTypeTitle,
            participants
          });
          return acc;
        },
        []
      )
      // include only non-hero workspace
      // this must be done as a last step to keep the information about order
      .filter(item => item.workspaceId !== currentWorkspaceId);

    return items;
  }
);
