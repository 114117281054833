import { VIC_PARTY_FORM_CONFIG as VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-28/config';
import { FormReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving/validation-schema';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-28/necds';
import { PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-28/vic';

import { Mortgage2_28_0Model } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupMortgageeDetails = resolveMemoizedTenancyValidation<FormReceivingTenancyDetailModel, Mortgage2_28_0Model>({
  isAddressRequired: true,
  partyCapacityLookup: PARTY_CAPACITY_LOOKUP_OPTIONS,
  partyCapacityMinor: NecdsPartyCapacityEnum.Minor,
  partyFormConfig: VIC_M_PARTY_FORM_WITH_NO_NAME_CHANGE_CONFIG
});

export default yupMortgageeDetails;
