import React from 'react';

import Typography from '@mui/material/Typography';

import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCalendar, IconCSV, IconDownload, IconPDF, IconStar } from '@sympli/ui-framework/icons';

import FeatureToggle from 'src/@core/auth/feature-toggle';
import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import ReportDownload from '../report-download';
import { useStyles } from './styles';

interface Props {
  title?: string;
  areButtonsDisabled: boolean;
  isLoading: boolean;
  hasSchedulerButton: boolean;
  hasFavouriteButton: boolean;
  onDownloadCSVClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDownloadPDFClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOnFavouriteClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOnScheduleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const ReportDetailHeader = ({
  //
  title,
  areButtonsDisabled,
  hasSchedulerButton,
  hasFavouriteButton,
  onDownloadCSVClick,
  onDownloadPDFClick,
  handleOnFavouriteClick,
  handleOnScheduleClick
}: Props) => {
  const classes = useStyles();

  const renderButtons = () => {
    const downloadOptions: Array<{ icon?: JSX.Element; text: string; onClick: (event: React.MouseEvent<HTMLElement>) => void }> = [
      { icon: <IconCSV color="primary" />, text: 'Download as CSV', onClick: onDownloadCSVClick },
      { icon: <IconPDF color="primary" />, text: 'Download as PDF', onClick: onDownloadPDFClick }
    ];
    return (
      <div className={classes.buttons}>
        <ReportDownload disabled={areButtonsDisabled} menuItems={downloadOptions} primaryButtonText="Download" iconDownload={<IconDownload />} />
        {hasSchedulerButton && (
          <FeatureToggle featureToggleType={FeatureToggleEnum.biReportingSchedulerFavoriteEnabled}>
            <SympliButton className={classes.button} color="primary" variant="outlined" onClick={handleOnScheduleClick}>
              <IconCalendar />
              Schedule
            </SympliButton>
          </FeatureToggle>
        )}
        {hasFavouriteButton && (
          <FeatureToggle featureToggleType={FeatureToggleEnum.biReportingSchedulerFavoriteEnabled}>
            <SympliButton className={classes.button} variant="contained" color="primary" onClick={handleOnFavouriteClick}>
              <IconStar className={classes.iconStar} /> Add to favourites
            </SympliButton>
          </FeatureToggle>
        )}
      </div>
    );
  };
  return (
    <>
      <div className={classes.selectReports}>
        <Typography variant="h1">{title} report</Typography>
      </div>
      {renderButtons()}
    </>
  );
};

export default ReportDetailHeader;
