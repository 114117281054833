import * as yup from 'yup';

import yupCtController from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/nomination-details/validationSchema';
import yupTitleReferences from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/title-references/validationSchema';
import { WithdrawalOfNomination_2_18_1_Model } from './models';

export default yup //
  .object<WithdrawalOfNomination_2_18_1_Model>({ titleReferences: yupTitleReferences, ctController: yupCtController })
  .defined()
  .log();
