import * as React from 'react';

import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import DirectionsContext from 'src/containers/workspace/financial/directions/Directions.context';
import { TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { useDirectionsCategories } from 'src/containers/workspace/financial/directions/reducers/categories';
import { useFeatureFlag, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { useWorkspaceFinancialAccounts } from 'src/store/reducers/workspace/workspaceDebitAccountsFeed';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import { DischargeDirectionsFormModel } from '../models';
import MortgageEditor from './MortgageEditor';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
  formikProps: FormikProps<DischargeDirectionsFormModel>;
  index: number;
  detail: HttpTypes.WorkspaceDirectionsApiResponse;
  arrayFieldName?: string;
  workspaceId: string;
  participantId: string;
  trustAccountMap: TrustAccountMap;
}

function MortgageEditorContainer({
  //
  queryParams,
  workspaceId,
  participantId,
  ...rest
}: Props) {
  const { setFocusLabel } = React.useContext(DirectionsContext);
  const { isLoading, error, detail: directionsCategoriesDetail } = useDirectionsCategories(queryParams.workspaceId, queryParams.participantId);
  const dispatch = useSafeDispatch(useDispatch());
  const { items: financialAccounts } = useWorkspaceFinancialAccounts();
  const { detail } = useWorkspaceDetail(workspaceId, participantId);
  const restrictManualAccountDetailsEntryEnabled: boolean = useFeatureFlag(FeatureToggleEnum.restrictManualAccountDetailsEntry);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const currentParticipant: HttpTypes.WorkspaceParticipant | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const usageState = useStoreSelector(store => store.generalAccountUsages);

  if (isLoading) {
    return <BlockLoader />;
  }

  if (!directionsCategoriesDetail || error) {
    return null;
  }

  return (
    <MortgageEditor //
      {...rest}
      workspaceId={workspaceId}
      participantId={participantId}
      directionsCategoriesDetail={directionsCategoriesDetail}
      usage={usageState.detail}
      financialAccounts={financialAccounts}
      currentParticipant={currentParticipant}
      restrictManualAccountDetailsEntryEnabled={restrictManualAccountDetailsEntryEnabled}
      dispatch={dispatch}
      setFocusLabel={setFocusLabel}
      workspaceClusterDetail={detail?.linkedWorkspaceCluster}
    />
  );
}

export default MortgageEditorContainer;
