import Typography from '@mui/material/Typography';

import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';
import { dynamicColumns } from '@sympli/ui-framework/components/table/helpers';

import {
  financialWorkspaceStatusFormatter,
  jurisdictionFormatter,
  settlementTimeFormatter,
  titleFormatter,
  workspaceStatusBarFormatter
} from 'src/containers/dashboard/shared/formatters';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme';
import { modelKey } from 'src/utils/formUtils';
import { isCurrentWorkspaceFormatter } from '../../../detail/components/linked-settlement-detail/linked-workspace-table/helpers';
import { UnlinkLodgementWorkspaceTableModel } from './models';

const cf = columnFormatterCheck<UnlinkLodgementWorkspaceTableModel>();

const fieldName = modelKey<UnlinkLodgementWorkspaceTableModel>();

export const resolveColumns = ({
  screenVariant
}: {
  //
  screenVariant: ScreenSizeVariant;
}) => {
  return resolveTableColumns(screenVariant);
};

function resolveTableColumns(screenVariant: ScreenSizeVariant) {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);

  const columns: ColumnsModel<UnlinkLodgementWorkspaceTableModel> = dynamicColumns<UnlinkLodgementWorkspaceTableModel, ScreenSizeEnum>(
    {
      isCurrent: {
        thClassName: 'w-[15px]',
        label: '',
        name: fieldName('isCurrentWorkspace'),
        formatter: cf<UnlinkLodgementWorkspaceTableModel['isCurrentWorkspace']>(isCurrentWorkspaceFormatter)
      },

      setlDate: {
        thClassName: (args: ScreenSizeEnum) => {
          return args === ScreenSizeEnum.SmallLaptop ? 'w-[88px]' : 'w-[90px]';
        },
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        name: fieldName('settlementDate'),
        get: ({ settlementDate, workspaceStatus, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: UnlinkLodgementWorkspaceTableModel) => ({
          settlementDate,
          workspaceStatusId: workspaceStatus,
          pendingProposedSettlementDate,
          expectedSettlementDate,
          isUnsupportedSettlementDate
        }),
        formatter: cf<{
          //
          settlementDate: UnlinkLodgementWorkspaceTableModel['settlementDate'];
          workspaceStatusId: UnlinkLodgementWorkspaceTableModel['workspaceStatus'];
          pendingProposedSettlementDate: UnlinkLodgementWorkspaceTableModel['pendingProposedSettlementDate'];
          expectedSettlementDate: UnlinkLodgementWorkspaceTableModel['expectedSettlementDate'];
          isUnsupportedSettlementDate: UnlinkLodgementWorkspaceTableModel['isUnsupportedSettlementDate'];
        }>(settlementTimeFormatter)
      },
      title: {
        label: 'Title',
        thClassName: (args: ScreenSizeEnum) => {
          return args === ScreenSizeEnum.SmallLaptop ? 'w-[80px]' : 'w-[100px]';
        },
        name: fieldName('sympliId'),
        get: ({ titles, sympliId }: UnlinkLodgementWorkspaceTableModel) => ({
          titles,
          sympliId
        }),
        formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple titles found</b>
                  </div>
                  {row.titles.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },
      jdx: {
        thClassName: (args: ScreenSizeEnum) => {
          return args === ScreenSizeEnum.SmallLaptop ? 'w-[48px]' : 'w-[50px]';
        },
        label: 'JDX',
        name: fieldName('jurisdiction'),
        formatter: cf<UnlinkLodgementWorkspaceTableModel['jurisdiction']>(jurisdictionFormatter)
      },
      wsStatusText: {
        thClassName: (args: ScreenSizeEnum) => {
          return args === ScreenSizeEnum.SmallLaptop ? 'w-[92px]' : 'w-[96px]';
        },
        label: 'WS STATUS',
        name: fieldName('workspaceStatus'),
        formatter: cf<UnlinkLodgementWorkspaceTableModel['workspaceStatus']>(financialWorkspaceStatusFormatter)
      },

      workspaceStatusBar: {
        thClassName: 'w-[6px]',
        label: '',
        name: fieldName('workspaceStatus'),
        padding: 'none',
        formatter: cf<UnlinkLodgementWorkspaceTableModel['workspaceStatus']>(workspaceStatusBarFormatter)
      }
    },
    screenSize
  );

  return columns;
}
