import React from 'react';

import { FormikProps } from 'formik';

import { HttpTypes } from '@sympli/api-gateway/types';

import Formik from 'src/components/formik';
import DirectionsSummary from 'src/containers/workspace/financial/directions/components/directions-summary';
import { isDistributionValid, isSourceFundValid } from 'src/containers/workspace/financial/directions/helpers';
import SourceFundList from 'src/containers/workspace/financial/directions/views/edit-directions/components/source-fund-list';
import { NEW_SOURCEFUND } from 'src/containers/workspace/financial/directions/views/edit-directions/components/source-fund-list/models';
import { DistributionFormikModel, SourceFundFormikModel } from '../../../../models';
import DirectionForm from '../../components/direction-form';
import DirectionRecordList from '../../components/direction-record-list';
import { getKeyValueMatchingNumberInObject } from '../../helper';
import useEditDirections from '../../hooks/useEditDirections';
import { EditDirectionCommonProps } from '../../models';
import { directionsSummarySelector, otherPartiesDirectionsSummarySelector } from '../../selectors';
import { SourceFundModel } from '../discharge-mortgage-directions/models';
import { getDefaultLoanAdvanceSourceFund, isLoanAdvance, LOAN_ADVANCE_CATEGORY, LOAN_ADVANCE_RANGE_CATEGORY } from './helper';
import { IncomingDirectionsFormModel } from './models';
import { getValidationSchema } from './validationSchema';

interface Props extends EditDirectionCommonProps {
  workspaceRole: HttpTypes.WorkspaceRoleEnum;
}

/**
 * Not exclusively used as IncomingMortgagee
 * Used for directions with LoanAdvance - Source Funder
 */
function IncomeMortgageDirections({
  portalIdForDirectionSummary,
  canEdit,
  onFormCancel,
  portalIdForFormSubmit,
  queryParams,
  currentParticipant,
  detail,
  directionsCategoriesDetail,
  workspaceType,
  requiresStampDuty,
  havePurchaserParticipant,
  workspaceRole,
  numberOfLinkedWorkspaces,
  hasManageWorkspacePermission
}: Props) {
  const { workspaceId, participantId, defaultDistributionsValue, onPostSubmit, isSaveAsDraft, setIsSaveAsDraft, trustAccountOptions, trustAccountBankDetailMap } =
    useEditDirections(queryParams, detail, currentParticipant, directionsCategoriesDetail.directionCategories);

  const sourceFundDefaultValue = React.useMemo(() => {
    return { ...NEW_SOURCEFUND, subscriberName: currentParticipant.name };
  }, [currentParticipant.name]);

  // filter loan advance options
  const trustAccountLoanAdvanceOptions = React.useMemo(() => trustAccountOptions.filter(option => option.canUseAsLoanAdvance), [trustAccountOptions]);

  const getInitialValues = React.useCallback((): IncomingDirectionsFormModel => {
    const { directions, loanAdvance, directionsSummary, sourceFunds } = detail;

    const defaultDistributions = directions.map(item => {
      const result: DistributionFormikModel = { ...defaultDistributionsValue, ...item };
      return {
        ...result,
        isEditorOpen: !isDistributionValid(result)
      };
    });

    const defaultSourceFunds = sourceFunds.map(item => {
      const result: SourceFundFormikModel = { ...NEW_SOURCEFUND, ...item };
      return {
        ...result,
        isEditorOpen: !isSourceFundValid(result)
      };
    });

    const initialLoanAdvanceAmount =
      loanAdvance?.category === HttpTypes.LoanAdvanceCategoryEnum.Range && directionsSummary.autoBalancedLoanAdvanceAmount > 0
        ? directionsSummary.autoBalancedLoanAdvanceAmount
        : loanAdvance?.amount || 0;

    if (loanAdvance?.bankDetails) {
      // display the loan advance in the source funds list
      defaultSourceFunds.unshift({
        ...NEW_SOURCEFUND,
        trustAccountId: loanAdvance.accountId,
        amount: loanAdvance.amount,
        reference: loanAdvance.reference,
        category:
          workspaceRole === HttpTypes.WorkspaceRoleEnum.IncomingMortgagee && loanAdvance.isAutoBalancingSurplusForIMAllowed ? LOAN_ADVANCE_RANGE_CATEGORY : LOAN_ADVANCE_CATEGORY,
        id: loanAdvance.id,
        isEditorOpen: false,
        bankDetails: loanAdvance.bankDetails,
        initialLoanAdvanceAmount: initialLoanAdvanceAmount,
        displayLoanAdvanceAmount: initialLoanAdvanceAmount,
        isAutoBalancingSurplusForIMAllowed: loanAdvance.isAutoBalancingSurplusForIMAllowed
      });
      return {
        distributions: defaultDistributions,
        sourceFunds: defaultSourceFunds,
        hasManageWorkspacePermission
      };
    }

    const loanAdvanceAccountId = trustAccountLoanAdvanceOptions.length === 1 ? trustAccountLoanAdvanceOptions[0].id : '';

    if (loanAdvance && loanAdvance.bankDetails == null) {
      defaultSourceFunds.push(
        getDefaultLoanAdvanceSourceFund(loanAdvanceAccountId, initialLoanAdvanceAmount, workspaceRole, loanAdvance?.isAutoBalancingSurplusForIMAllowed, loanAdvance.id)
      );
    }

    return {
      distributions: defaultDistributions,
      sourceFunds: defaultSourceFunds,
      hasManageWorkspacePermission
    };
  }, [defaultDistributionsValue, detail, trustAccountOptions, workspaceRole, hasManageWorkspacePermission]);

  const handleOnPreSubmit = React.useCallback(
    (values: IncomingDirectionsFormModel): HttpTypes.UpdateWorkspaceDirectionsApiRequestBody => {
      const distributions: HttpTypes.UpdateWorkspaceDirectionsApiRequestBody['directions'] = values.distributions
        .filter(item => !item.isLocked)
        .map(item => {
          const { bankDetails, bpayDetails, paymentMethod, holdingAccountDetails, amount, directionCategory, category, categoryOther, bankAccountId, id, linkedSettlementItem } =
            item;
          const commonValues = {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id
          };
          switch (paymentMethod) {
            case HttpTypes.PaymentMethodEnum.BPAY:
              return { ...commonValues, bpayDetails };
            case HttpTypes.PaymentMethodEnum.HoldingAccount:
              return { ...commonValues, holdingAccountDetails };
            case HttpTypes.PaymentMethodEnum.TrustAccount:
              return { ...commonValues, bankAccountId, bankDetails };
            case HttpTypes.PaymentMethodEnum.Linked:
              return {
                ...commonValues,
                reference: item.reference,
                linkedSettlementItem: {
                  linkedParticipantId: linkedSettlementItem?.linkedToParticipantId!,
                  linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId!,
                  linkedToParticipantMatterReference: linkedSettlementItem?.linkedToParticipantMatter
                }
              };
            case HttpTypes.PaymentMethodEnum.BankTransfer:
            default:
              return { ...commonValues, bankDetails };
          }
        });

      // filter out the loan advance, loan advance submitted separately to the source funds list
      const sourceFunds: SourceFundModel[] = values.sourceFunds
        .filter(x => !isLoanAdvance(x.category))
        .map(item => {
          const { trustAccountId, amount, category, reference, categoryOther, id, paymentMethod, linkedSettlementItem } = item;

          const trustAccount = trustAccountBankDetailMap[trustAccountId];
          if (trustAccount && trustAccount.bankAccountType === HttpTypes.BankAccountTypeEnum.SympliSourceAccount) {
            return {
              trustAccountId,
              amount: Number(amount),
              reference: trustAccount.bankDetails.accountNumber,
              category,
              categoryOther,
              id,
              paymentMethod: HttpTypes.PaymentMethodEnum.BankTransfer
            };
          }
          return {
            trustAccountId,
            amount: Number(amount),
            reference,
            category,
            categoryOther,
            id,
            paymentMethod,
            linkedSettlementItem:
              paymentMethod === HttpTypes.PaymentMethodEnum.Linked
                ? {
                    linkedParticipantId: linkedSettlementItem?.linkedToParticipantId,
                    linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId
                  }
                : null
          };
        });

      const loanAdvanceSourceFund = values.sourceFunds.find(x => isLoanAdvance(x.category));
      return {
        directions: distributions,
        sourceFunds: sourceFunds,
        isSaveAsDraft: isSaveAsDraft,
        loanAdvance: loanAdvanceSourceFund
          ? {
              amount: loanAdvanceSourceFund.amount,
              accountId: loanAdvanceSourceFund.trustAccountId,
              reference: loanAdvanceSourceFund.reference,
              isAutoBalancingSurplusForIMAllowed: loanAdvanceSourceFund.isAutoBalancingSurplusForIMAllowed,
              id: loanAdvanceSourceFund.id
            }
          : undefined
      };
    },
    [isSaveAsDraft, trustAccountBankDetailMap]
  );

  return (
    <Formik //
      method="post"
      action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions`}
      httpConfig={{ withCredentials: true }}
      validateOnMount
      getInitialValues={getInitialValues}
      validationSchema={getValidationSchema(trustAccountBankDetailMap, workspaceId, numberOfLinkedWorkspaces)}
      onPreSubmit={handleOnPreSubmit}
      onPostSubmit={onPostSubmit}
    >
      {(formikProps: FormikProps<IncomingDirectionsFormModel>) => {
        const { distributions, sourceFunds } = formikProps.values;
        const isMultipleEditorsOpen = getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) >= 1;

        // * formik will update twice with same value (due to async validation)
        // * these selector prevent recalculation and re-render

        // need to use the source fund data for the loan advance when flag turned on
        const editingLoanAdvanceAmount = detail.loanAdvance?.amount;

        const savedLoanAdvanceAmount =
          detail.loanAdvance?.category === HttpTypes.LoanAdvanceCategoryEnum.Range && detail.directionsSummary.autoBalancedLoanAdvanceAmount > 0
            ? detail.directionsSummary.autoBalancedLoanAdvanceAmount
            : detail.loanAdvance?.amount || 0;

        const otherPartiesSummary = otherPartiesDirectionsSummarySelector({
          workspaceRole: workspaceRole,
          directionsSummary: detail.directionsSummary,
          sourceFunds: detail.sourceFunds,
          directions: detail.directions,
          initialLoanAdvanceAmount: savedLoanAdvanceAmount
        });

        const updatedSummary = directionsSummarySelector({
          workspaceRole: workspaceRole,
          otherPartiesSummary,
          sourceFunds: detail.sourceFunds.filter(x => !isLoanAdvance(x.category)),
          distributions: detail.directions,
          loanAdvanceRequiredAmount: detail.directionsSummary.loanAdvanceRequiredAmount || 0,
          editingLoanAdvanceAmount: Number(editingLoanAdvanceAmount) || 0
        });

        // make sure all editors are closed for add and edit,
        // todo is it ok if it is edit model but no data been modified? do we want to keep it disabled?
        const distributionsInEditModel = distributions.some(item => item.isEditorOpen);
        const sourceFundsInEditMode = sourceFunds.some(item => item.isEditorOpen);

        return (
          <DirectionForm
            workflowType={detail.distributionWorkflowType}
            stepperCanEdit={canEdit && !distributionsInEditModel && !sourceFundsInEditMode}
            canEdit={canEdit}
            isSubmitting={formikProps.isSubmitting}
            isSaveAsDraft={isSaveAsDraft}
            dirty={formikProps.dirty && isMultipleEditorsOpen}
            onFormCancel={onFormCancel}
            handleOnSaveAndContinueClick={() => {
              setIsSaveAsDraft(false);
              formikProps.submitForm();
            }}
            portalIdForFormSubmit={portalIdForFormSubmit}
          >
            <SourceFundList
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              workspaceId={workspaceId}
              participantId={participantId}
              canEdit={canEdit}
              formikProps={formikProps}
              currentParticipant={currentParticipant}
              defaultValue={sourceFundDefaultValue}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              sourceFundCategories={directionsCategoriesDetail.sourceFundsCategories}
              canSetAutoBalanceForIM={detail.canSetAutoBalanceForIM}
              autoBalanceDefaultValue={detail.autoBalanceDefaultValue}
            />
            <DirectionRecordList
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              canEdit={canEdit}
              formikProps={formikProps}
              defaultValue={defaultDistributionsValue}
              currentParticipant={currentParticipant}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              workspaceId={workspaceId}
              participantId={participantId}
              workspaceRole={workspaceRole}
              paymentMethodOptions={detail.directionPaymentMethods}
            />
            <DirectionsSummary
              workspaceTypeId={workspaceType}
              workspaceRoleId={workspaceRole}
              directionsSummary={updatedSummary}
              trustAccounts={detail.trustAccounts}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              sourceFunds={sourceFunds}
              portalIdForDirectionSummary={portalIdForDirectionSummary}
              requiresStampDuty={requiresStampDuty}
              havePurchaserParticipant={havePurchaserParticipant}
            />
          </DirectionForm>
        );
      }}
    </Formik>
  );
}

export default IncomeMortgageDirections;
