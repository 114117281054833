import queryString from 'query-string';
import { call, cancelled, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { SearchLinkedWorkspaceApiRequest, SearchLinkedWorkspaceApiResponse } from 'src/containers/workspace/shared/components/linked-workspace-search/models';
import http from 'src/utils/http';
import { actionFetchLinkedWorkspaceUpdatedFeed } from '../actions/workspace';

//linked workspace search update
const fetchSearchLinkedWorkspaceUpdatedFeed = (q: SearchLinkedWorkspaceApiRequest) =>
  http.get<SearchLinkedWorkspaceApiResponse>(`/Search/linked-workspaces?${queryString.stringify(q)}`);

// fetch with debounce input change
function* sagaDebounceFetchSearchLinkedWorkspaceFeed(action: ReturnType<typeof actionFetchLinkedWorkspaceUpdatedFeed.request>) {
  try {
    const payload = action.payload;
    const data = yield* call(fetchSearchLinkedWorkspaceUpdatedFeed, action.payload);
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: payload.linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement ? 'linked-settlement' : 'linked-lodgement',
      logGroupId: 'workspace',
      action: 'search-linked-workspace',
      totalCount: data.totalCount
    });

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchLinkedWorkspaceUpdatedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchLinkedWorkspaceUpdatedFeed.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchLinkedWorkspaceUpdatedFeed.request, sagaDebounceFetchSearchLinkedWorkspaceFeed)
];
