import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: 15,
      marginBottom: 10
    },
    divider101: {
      marginTop: 16,
      marginBottom: 16
    },
    WorkspaceExpansionBox: {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 16
    },
    icon: {
      fontSize: 16,
      paddingRight: 6,
      alignSelf: 'flex-start',
      color: theme.palette.text.primary
    },
    calculatorRow: {
      marginTop: 10,
      paddingTop: 14
    },
    summaryRow: {
      fontSize: 13,
      paddingTop: 4,
      paddingBottom: 5
    },
    summaryRow101: {
      fontSize: 14,
      paddingTop: 4,
      paddingBottom: 4,
      lineHeight: '20px'
    },
    summaryMinusSymbol: {
      marginLeft: 8,
      marginRight: 8
    },
    summaryPlusSymbol: {
      marginLeft: 8,
      marginRight: 8
    },
    summaryDes: {
      marginLeft: 24
    },
    summaryTitle: {
      marginTop: 10
    },
    summaryTitleDesc: {
      fontWeight: 'bold'
    },
    lastSummaryRow: {
      fontWeight: 'bold',
      borderTop: `1px solid ${theme.palette.divider}`,
      marginLeft: 22
    },
    lastSummaryRow101: {
      fontWeight: 700,
      borderTop: `1px solid ${theme.palette.divider}`,
      marginLeft: 22,
      paddingBottom: 0
    },
    balanceCal: {
      marginTop: 10,
      paddingTop: 14,
      borderTop: `1px solid ${theme.palette.divider}`
    },
    balanceInvisiablePlus: {
      visibility: 'hidden'
    },
    balanceTotal101: {
      color: colors.RED_NEW,
      fontWeight: 'bold'
    },
    balanceTotal: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold'
    },
    balancedTotal: {
      color: colors.GREEN,
      fontWeight: 'bold'
    },
    balancedTotal101: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    balancePurchaseTitle: {
      display: 'inline-flex'
    },
    balancePurchaseDes: {
      marginRight: 4
    },
    clearedStatus: {
      color: colors.GREEN,
      fontWeight: 'bold'
    },
    iconClearedStatus: {
      fill: colors.GREEN,
      paddingRight: 3
    },
    sourceUnbalanced: {
      color: theme.palette.error.main,
      fontWeight: 'bold'
    },
    disabledText: {
      color: theme.palette.text.disabled
    },
    message: {
      fontSize: 13,
      marginLeft: 10,
      marginTop: 15,
      textAlign: 'left',
      color: theme.palette.text.disabled
    },
    marginLeft: {
      marginLeft: 20
    },
    halfMarginLeft: {
      marginLeft: '50%'
    },
    statusBadge: {
      marginTop: 13
    },
    tooltipIcon: {
      paddingLeft: 8,
      paddingBottom: 4
    }
  });

export type ClassKeys = typeof styles;

export default styles;
