import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { NoticeOfAcquisitionDocumentModel } from './models';
import yupCertification from './sections/certification/validationSchema';
import yupDetailsOfTransaction from './sections/details-of-transaction/validationSchema';
import yupDetailsOfProperties from './sections/section-details-of-properties/validationSchema';
import yupTransferees from './sections/transferees/validationSchema';
import yupTransferorRepresentativeDetail from './sections/transferor-representative-detail/validationSchema';
import yupTransferors from './sections/transferors/validationSchema';
import {
  VISIBILITY_CHECK_TRANSFEREE_PRIMARY_CONTRACT,
  VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL
} from './visibilityChecks';

// this file was automatically generated from validationSchema.ts.mustache
export const ERROR_MESSAGE_ASSIGN_ALL_TITLES_TO_PROPERTY = 'Before you can provide property details, please assign all titles to a property in the above section';
const yupAllTitlesAssigned = yup.boolean().test('all separated properties are assigned', ERROR_MESSAGE_ASSIGN_ALL_TITLES_TO_PROPERTY, function test(value: any) {
  return value;
});

const yupTransfereePrimaryContactPartyBookId = yup //
  .mixed<string>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
      validationWhenVisible: yup.string().nullable().required(msg.REQUIRED)
    })
  );

export default yup
  .object<NoticeOfAcquisitionDocumentModel>()
  .shape({
    isPopulatedDataFromTransferDocumentReady: yup.mixed<unknown>().test(
      'transfer-populated-data-test',
      'Transfer document should be updated before updating this document', //
      function test(this: yup.TestContext<NoticeOfAcquisitionDocumentModel>) {
        const document = this.parent;
        if (!document.elnRegistryInstrumentReference || document.elnRegistryInstrumentReference === 'NA') {
          return this.createError({ message: 'Transfer document should be updated before updating this document.' });
        }

        return true;
      }
    ),
    transferors: yupTransferors,
    transferorRepresentativeDetail: yupTransferorRepresentativeDetail,
    transferees: yupTransferees,
    transfereePrimaryContactPartyBookId: yup.mixed().test(
      validateWhenVisible({
        visibilityCheck: VISIBILITY_CHECK_TRANSFEREE_PRIMARY_CONTRACT,
        validationWhenVisible: yupTransfereePrimaryContactPartyBookId
      })
    ),
    allTitlesAssigned: yupAllTitlesAssigned,
    arePropertiesSeparatelyRated: yup //
      .mixed<boolean>()
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_TRANSFEREES_AND_PROPERTY_DETAILS_AND_TRANSACTION_DETAIL_AND_TRANSFEREE_AGENT_SOLICITOR_DETAIL,
          validationWhenVisible: yup.boolean().nullable().required(msg.REQUIRED)
        })
      ),

    // .test('all separated properties are assigned', 'All of the Volume and Folios must been assigned to a Property', function test(
    //   this: yup.TestContext<NoticeOfAcquisitionDocumentModel>,
    //   arePropertiesSeparatelyRated: any
    // ) {
    //   if (arePropertiesSeparatelyRated === true) {
    //     const { titleReferences, propertyDetails } = this.options.context!;
    //     const titlesUsed = propertyDetails.flatMap(pd => pd.titleReferences.filter(Boolean));
    //     return titleReferences.length === titlesUsed.length;
    //   }

    //   return true;
    // }),
    propertyDetails: yupDetailsOfProperties,
    transactionDetail: yupDetailsOfTransaction,
    transfereeAgentSolicitorDetail: yupCertification
  })
  .log();
