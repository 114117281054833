import * as yup from 'yup';

import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-24/qld';
import msg from '@sympli/ui-framework/utils/messages';

import { QLD_TRANSFER_DOCUMENT_ATTACHMENT_TYPE } from '../../config';
import { Transfer2_24_1Model } from '../../models';

const ATTACHMENT_REQUIRED_TRUSTEE_ERROR_MESSAGE = 'An attachment is required where there are more than four trustee transferees in a group';

export const yupAttachments = yup
  .array()
  .min(0, msg.MIN_ITEMS(0))
  .max(20, msg.MAX_ITEMS(20))
  .of<DocumentAttachmentItemModel>(
    yup
      .object<DocumentAttachmentItemModel>({
        documentType: yup.mixed<string>().required(msg.REQUIRED).oneOf(getLookupValuesAllowingEmpty(QLD_TRANSFER_DOCUMENT_ATTACHMENT_TYPE)),
        attachment: yup
          .array()
          .min(1, msg.REQUIRED)
          .max(1, msg.MAX_ITEMS(1))
          .of<DocumentAttachmentModel>(
            //
            yup
              .object<DocumentAttachmentModel>()
              .shape({
                id: yup.string().default(''),
                fileName: yup.string().default(''),
                fileType: yup.string().default('')
              })
              .defined()
          )
          .defined()
      })
      .defined()
  )
  .test('Transferee trustee check', ATTACHMENT_REQUIRED_TRUSTEE_ERROR_MESSAGE, function test(this: yup.TestContext<Transfer2_24_1Model>) {
    const { transferees, attachments } = this.options.context!;
    if (transferees.proprietorGroups.some(pg => pg.parties.length > 4 && pg.parties[0].partyCapacity.capacity === PartyCapacityEnum.Trustee) && !attachments?.length) {
      return false;
    }
    return true;
  });
