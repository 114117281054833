import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentItemLoader } from 'src/components/loaders';
import { useWorkspaceDocuments } from 'src/store/reducers/workspace/workspaceDocuments';
import PaymentItem from './components/payment-item/PaymentItem';
import PaymentLineItems from './components/payment-line-items/PaymentLineItems';
import { RegOnlyPaymentSummaryApiResponse } from './models';
import styles, { ClassKeys } from './styles';

// DOCS https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2082603015/payment+summary+box+-+data+dependency+insights
export interface OwnProps {
  // basic workspace info
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  workspaceId: string;
  participantId: string;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  // payment summary info
  lineItems?: RegOnlyPaymentSummaryApiResponse['lineItems'];
  isLoading?: boolean;
  error?: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

function PaymentSummaryBox({ isLoading, error, workspaceStatusId, lineItems, workspaceId, participantId, jurisdictionId }: Props) {
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const onlyLI = workspaceDocumentsState.items.every(x => x.documentForm.documentType === HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions);

  const renderPaymentDetail = () => {
    const title = 'Payment summary';
    let desc = onlyLI ? undefined : 'In progress';
    const workspaceInLodgementQueue = workspaceStatusId === HttpTypes.WorkspaceStatusEnum.LodgementInQueue;
    if (workspaceInLodgementQueue) {
      desc = 'Locked';
    } else if (lineItems) {
      desc = 'Fees debited at lodgement';
    }

    return (
      <>
        <PaymentItem //
          title={title}
          description={desc}
        />
        {lineItems || onlyLI ? (
          <PaymentLineItems //
            jurisdictionId={jurisdictionId}
            lineItems={lineItems}
          />
        ) : null}
      </>
    );
  };

  if (isLoading) {
    return (
      <List>
        <ListItem disableGutters>
          <DocumentItemLoader color="grey" />
        </ListItem>
        <ListItem disableGutters>
          <DocumentItemLoader color="grey" />
        </ListItem>
      </List>
    );
  }
  if (error) {
    // TODO error state
    return null;
  }
  return renderPaymentDetail();
}

export default withStyles(styles)(PaymentSummaryBox);
