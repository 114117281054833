import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useWorkspaceAssignableGroups } from 'src/@core/store/reducers/workspaceAssignableGroups';
import { useFeatureFlag, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { SharedInitialValues } from '../../../../models';
import { CreateFinancialWorkspaceSharedModel, CreateFinancialWorkspaceStepEnum } from '../../models';
import MatterDetail from './MatterDetail';

interface Props {
  workspaceTypeOptions: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[];
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  onGlobalValuesUpdate: (value?: Partial<CreateFinancialWorkspaceSharedModel>) => CreateFinancialWorkspaceSharedModel;
  onStepChange: (step: CreateFinancialWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: HttpTypes.WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;
}

function MatterDetailContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const profileState = useProfile();
  const assignGroupsState = useWorkspaceAssignableGroups();
  const supportedJurisdictionsState = useStoreSelector(store => store.supportedJurisdictions);
  const isSettlementDateOnlyEnabled = useFeatureFlag(FeatureToggleEnum.settlementDateOnlyEnabled);
  const isSettlementNullDateTimeEnabled = useFeatureFlag(FeatureToggleEnum.settlementNullDateTimeEnabled);

  const areCriticalRolesEnabled: boolean = useFeatureFlag(FeatureToggleEnum.criticalRoles);
  const areCriticalRolesNSWEnabled: boolean = useFeatureFlag(FeatureToggleEnum.criticalRolesNSW);

  return (
    <MatterDetail //
      // other
      {...props}
      dispatch={dispatch}
      // redux
      assignGroupsState={assignGroupsState}
      supportedJurisdictionsState={supportedJurisdictionsState}
      subscriberProfile={profileState.data!}
      //feature toggles
      isSettlementDateOnlyEnabled={isSettlementDateOnlyEnabled}
      isSettlementNullDateTimeEnabled={isSettlementNullDateTimeEnabled}
      areCriticalRolesEnabled={areCriticalRolesEnabled}
      areCriticalRolesNSWEnabled={areCriticalRolesNSWEnabled}
    />
  );
}

export default React.memo(MatterDetailContainer);
