import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';
import sharedStyles from '../../../edit-directions/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...sharedStyles(theme),
    deleteButton: {
      position: 'absolute',
      left: -10
    },
    okButton: {
      width: 50,
      whiteSpace: 'nowrap'
    },
    amountField: {
      width: 180,
      whiteSpace: 'nowrap'
    },
    autoBalanceCheckBox: {
      marginRight: 5
    },
    iconInfo: {
      width: 13,
      height: 13,
      marginBottom: 14
    },
    toolTipContent: {
      color: colors.NEUTRAL_000,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '20px'
    }
  }),
  {
    name: 'SourceFundEditor'
  }
);
