import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export interface DirectionsApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionFetchDirections = createAsyncAction(
  //
  'FETCH_DIRECTIONS',
  'FETCH_DIRECTIONS_SUCCESS',
  'FETCH_DIRECTIONS_ERROR'
)<
  //
  DirectionsApiRequest,
  { data: HttpTypes.WorkspaceDirectionsApiResponse },
  { error: Error }
>();

export const actionFetchDirectionsCategories = createAsyncAction(
  //
  'FETCH_DIRECTIONS_CATEGORIES',
  'FETCH_DIRECTIONS_CATEGORIES_SUCCESS',
  'FETCH_DIRECTIONS_CATEGORIES_ERROR'
)<
  //
  { workspaceId: string; participantId: string },
  { data: HttpTypes.WorkspaceDirectionsCategoriesApiResponse },
  { error: Error }
>();

export const actionUpdateDirectionsWorkForm = createAction(
  //
  'UPDATE_DIRECTIONS_FORM'
)<
  //
  Partial<HttpTypes.UpdateWorkspaceDirectionsApiResponse>
>();

export const actionCombinationAllDirectionActionsApprove = createAsyncAction(
  //
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE',
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE_SUCCESS',
  'COMBINATION_ALL_DIRECTION_ACTION_APPROVE_ERROR'
)<
  //
  {
    newDirectionForm: Partial<HttpTypes.UpdateWorkspaceDirectionsApiResponse>;
    newDistributionsParticipantData: Partial<HttpTypes.DistributionsParticipantModel>;
    participantId?: string;
    workspaceId?: string;
  },
  { data: HttpTypes.WorkspaceDirectionsOverviewApiResponse },
  { error: Error }
>();

export const actionFetchGeneralAccountUsages = createAsyncAction(
  //
  'FETCH_GENERAL_ACCOUNT_USAGES',
  'FETCH_GENERAL_ACCOUNT_USAGES_SUCCESS',
  'FETCH_GENERAL_ACCOUNT_USAGES_ERROR'
)<
  //
  undefined,
  { data: HttpTypes.GeneralAccountsUsagesApiResponse },
  { error: Error }
>();
