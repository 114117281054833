import React from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { CommonApiResponse, DashboardInvitationAllocateInvitationsApiRequest, invitationsAssignment } from 'src/containers/dashboard/invitation/api';
import { actionFetchSingleGroupMembers } from 'src/containers/dashboard/shared/actions';
import { useSingleGroupMembers } from 'src/containers/dashboard/shared/reducers/singleGroupMembers';
import { saveAssignee } from 'src/containers/shared/workspace-staff-assign/api';
import { SaveAssigneeToWorkspace } from 'src/containers/shared/workspace-staff-assign/model';
import { useSafeDispatch } from 'src/hooks';
import { getWarningMessage, WarningMessageEnum } from './messages';
import ReassignBar from './ReassignBar';

export interface Props {
  selectedItems?: Array<{ workspaceId: string; participantId: string }>;
  onDismissClick: React.MouseEventHandler<SVGElement>;
  groupId?: string;
  resetSelectedItems: () => void;
  autoRefresh: () => void;
  isInvitation?: boolean;
}

function ReassignBarContainer({ selectedItems, onDismissClick, groupId, resetSelectedItems, autoRefresh, isInvitation }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const [assignButtonLoading, setAssignButtonLoading] = React.useState<boolean>(false);
  const [unassignButtonLoading, setUnassignButtonLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // This state represents the value displayed in the textbox.
  const [searchValue, setSearchValue] = React.useState<string>('');

  const { items: singleGroupMembers, status } = useSingleGroupMembers(groupId);

  // This state represents the option object selected by the user
  const [value, setValue] = React.useState<LookupItemModel<string> | null>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const handleOnOpen = () => {
    if (searchValue.trim().length >= 3) {
      setOpen(true);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>, newInputValue: string) => {
    setSearchValue(newInputValue);

    if (newInputValue.trim().length >= 3) {
      setOpen(true);
      if (status === 'pending' || status === 'refetching') {
        setIsLoading(true);
      }
    } else {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (status === 'rejected' || status === 'resolved') {
      setIsLoading(false);
    }
  }, [status]);

  // When user searched but no selection happens, we clear the selected option.
  const handleOnBlur = () => {
    if (value?.name !== searchValue) {
      setValue(null);
    }
  };

  const handleOnChange = (event: any, newValue: LookupItemModel<string> | null) => {
    setValue(newValue);
  };

  const handleOnMenuClick = (option: LookupItemModel<string>) => {
    setSearchValue(option.name);
    setValue(option);
    setOpen(false);
  };

  React.useEffect(() => {
    if (groupId) {
      dispatch(actionFetchSingleGroupMembers.request({ groupIds: groupId, excludeInactive: true }));
    }
  }, [dispatch, groupId]);

  const handleOnProcessingNotification = React.useCallback(() => {
    dispatch(
      actionOpenGlobalSimpleNotification({
        message: (
          <Typography className="text-[14px] text-[var(--neutral-000)]">
            <b>Processing… </b>Not long now, we are processing your request
          </Typography>
        ),
        variant: 'new-warning',
        autoHideDuration: 60 * 1000
      })
    ); // make it to be long enough, not closed
  }, [dispatch]);

  const handleOnSuccessNotification = React.useCallback(
    (message: string, secondMessage: string) => {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <Typography className="text-[14px] text-[var(--neutral-000)]">
              <b>{message} </b>
              {secondMessage}
            </Typography>
          ),
          variant: 'new-success',
          autoHideDuration: 5000
        })
      );
    },
    [dispatch]
  );

  const handleOnPartiallySuccessNotification = React.useCallback(
    (unit: string) => {
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: (
            <Typography className="text-[14px] text-[var(--neutral-000)]">
              <b>Something has changed. </b>
              {`We couldn't process one or more of your selected ${unit}s`}
            </Typography>
          ),
          variant: 'new-warning',
          autoHideDuration: 5000
        })
      );
    },
    [dispatch]
  );

  const handleOnErrorNotification = React.useCallback(() => {
    dispatch(
      actionOpenGlobalSimpleNotification({
        message: getWarningMessage(WarningMessageEnum.SomethingWentWrong),
        variant: 'new-warning',
        autoHideDuration: 5000
      })
    );
  }, [dispatch]);

  const handleOnButtonClick = React.useCallback(
    async (
      //
      isAssignAction: boolean,
      buttonClick: React.Dispatch<React.SetStateAction<boolean>>,
      handleOnApi: Promise<CommonApiResponse>
    ) => {
      if (selectedItems && selectedItems.length > 0) {
        try {
          const count = selectedItems.length;

          buttonClick(true);

          handleOnProcessingNotification();

          const unit = isInvitation ? 'Invitation' : 'Workspace';
          const { allSuccess } = await handleOnApi;

          if (allSuccess) {
            const msg = isAssignAction ? 'Assigned!' : 'Unassigned!';
            const secondMsg = isAssignAction
              ? `${count} ${count > 1 ? unit + 's have' : unit + ' has'}  been assigned to ${value!.name}`
              : `${count} ${count > 1 ? unit + 's have' : unit + ' has'}  been updated as 'Unassigned'`;

            handleOnSuccessNotification(msg, secondMsg);
          } else {
            handleOnPartiallySuccessNotification(unit);
          }

          autoRefresh();
          resetSelectedItems();
        } catch (error) {
          // TODO https://tickleme.atlassian.net/browse/WEB-35708 dispatch a new error message.
          // when partially success, show message: Something has changed. We couldn’t process one or more of your selected workspaces
          handleOnErrorNotification();
        } finally {
          buttonClick(false);
        }
      }
    },
    [
      //
      autoRefresh,
      handleOnProcessingNotification,
      handleOnSuccessNotification,
      handleOnPartiallySuccessNotification,
      handleOnErrorNotification,
      isInvitation,
      resetSelectedItems,
      selectedItems,
      value
    ]
  );

  const handleOnBulkUnassign = React.useCallback(async () => {
    if (selectedItems && selectedItems.length > 0) {
      const bulkAssignItems: DashboardInvitationAllocateInvitationsApiRequest[] | SaveAssigneeToWorkspace[] = isInvitation
        ? selectedItems.map(x => ({ participantId: x.participantId }))
        : selectedItems.map(x => ({ participantId: x.participantId, workspaceId: x.workspaceId }));

      const api = isInvitation
        ? invitationsAssignment(false, bulkAssignItems as DashboardInvitationAllocateInvitationsApiRequest[])
        : saveAssignee(bulkAssignItems as SaveAssigneeToWorkspace[]); // todo: replace me with real endpoint

      await handleOnButtonClick(false, setUnassignButtonLoading, api);
    }
  }, [isInvitation, handleOnButtonClick, selectedItems]);

  const handleOnBulkAssign = React.useCallback(async () => {
    if (value && selectedItems && selectedItems.length > 0) {
      const bulkAssignItems: DashboardInvitationAllocateInvitationsApiRequest[] | SaveAssigneeToWorkspace[] = isInvitation
        ? selectedItems.map(x => ({ userId: value.id, participantId: x.participantId }))
        : selectedItems.map(x => ({ userId: value.id, participantId: x.participantId, workspaceId: x.workspaceId }));

      const api = isInvitation
        ? invitationsAssignment(true, bulkAssignItems as DashboardInvitationAllocateInvitationsApiRequest[])
        : saveAssignee(bulkAssignItems as SaveAssigneeToWorkspace[]);

      await handleOnButtonClick(true, setAssignButtonLoading, api);
    }
  }, [isInvitation, handleOnButtonClick, selectedItems, value]);

  return (
    <ReassignBar
      open={open}
      value={value}
      searchValue={searchValue}
      isLoading={isLoading}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onInputChange={handleOnSearchInputChange}
      onMenuClick={handleOnMenuClick}
      onAssignButtonClick={handleOnBulkAssign}
      onUnassignButtonClick={handleOnBulkUnassign}
      onDismissClick={onDismissClick}
      options={singleGroupMembers} // options for input search
      selectedNumber={selectedItems?.length || 0}
      selectedItems={selectedItems}
      assignButtonLoading={assignButtonLoading}
      unassignButtonLoading={unassignButtonLoading}
    />
  );
}

export default React.memo(ReassignBarContainer);
