import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { actionFetchScheduledReportHistory } from 'src/containers/dashboard/reports/actions';
import { ScheduledReportHistoryModel } from 'src/containers/dashboard/reports/models';
import { ApiStatus, AxiosError } from 'src/utils/http';

export interface ScheduledReportHistoryState extends ScheduledReportHistoryModel {
  status: ApiStatus;
  error?: AxiosError<any>;
}

export const initialState: ScheduledReportHistoryState = {
  status: 'idle',
  error: undefined,
  name: '',
  reportSendTime: '',
  scheduledFrequency: null,
  filterData: '',
  reports: [],
  reportType: HttpTypes.ReportTypeEnum.UserPermissionReport
};

const scheduledReportHistoryReducer = createReducer<
  //
  ScheduledReportHistoryState,
  Action
>(initialState)
  .handleAction(actionFetchScheduledReportHistory.request, (state): ScheduledReportHistoryState => {
    return { ...state, status: 'pending', error: undefined };
  })
  .handleAction(actionFetchScheduledReportHistory.success, (state, action): ScheduledReportHistoryState => {
    return {
      ...state,
      ...action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchScheduledReportHistory.failure, (state, action): ScheduledReportHistoryState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error
    };
  });

export default scheduledReportHistoryReducer;
