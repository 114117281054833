import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

export interface Props {
  isLoading: boolean;
  isOpen: boolean;
  onClose: (value?: boolean) => void;
  reportName: string;
  reportFeatureType: HttpTypes.ReportFeatureTypeEnum;
}

function ReportDeleteDialog({ isLoading, isOpen, onClose, reportName, reportFeatureType }: Props) {
  const classes = useStyles();

  return (
    <ConfirmationDialog
      isLoading={isLoading}
      title={
        <FlexLayout flexDirection="row" alignItems="center">
          <IconDelete className={classes.optionIcon} />
          <span>Delete report</span>
        </FlexLayout>
      }
      color="primary"
      showActionButtons={true}
      open={isOpen}
      scroll="body"
      okButtonText="Delete report"
      onClose={onClose}
      classes={{ okButton: classes.okButton, cancelButton: classes.cancelButton, dialogPaper: classes.dialogPaper, dialogContent: classes.dialogContent }}
    >
      You are about to delete the report: <strong>{reportName}</strong>.
      {reportFeatureType === HttpTypes.ReportFeatureTypeEnum.Scheduled ? ' All previously generated files based on this report will also be deleted.' : ''}
    </ConfirmationDialog>
  );
}

export default React.memo(ReportDeleteDialog);
