import { HttpTypes } from '@sympli/api-gateway/types';

import { DistributionFormikModel, SourceFundFormikModel } from '../../../../models';

export enum CategoryEnum {
  LoanPayout = 'Loan Payout',
  CustomerLoanAccount = 'Customer Loan Account',
  AcceptSurplus = 'Accept Surplus',
  ProfessionalFees = 'Professional Fees',
  ThirdPartyBeneficiary = 'Third Party Beneficiary',
  Other = 'Other',
  LinkedPayment = 'Linked Payment',
  LinkedSourceFund = 'Linked Source Fund'
}

export interface DischargeDirectionsFormModel {
  distributions: Array<DistributionFormikModel>;
  sourceFunds: Array<SourceFundFormikModel>;
  hasManageWorkspacePermission: boolean;
}
export interface SourceFundModel {
  trustAccountId: string;
  amount: number;
  reference?: string;
  category: string;
  categoryOther?: string;
  id?: string;
  paymentMethod: HttpTypes.PaymentMethodEnum;
}

export interface LoanAdvanceModel {
  amount?: string | number;
  accountId?: string;
  reference?: string;
  isAutoBalancingSurplusForIMAllowed: boolean;
}
