import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  // linkTo?: string;
  noEllipsis?: boolean;
}

type Props = HeaderProps & WithStyles<ClassKeys>;

class Header extends React.PureComponent<Props> {
  private globalCssName = 'tickle-header';

  render() {
    const { classes, className, ...otherProps } = this.props;
    return (
      <header className={classNames(classes.root, this.globalCssName, className)} {...otherProps}>
        {this.renderTitle()}
      </header>
    );
  }

  // renderHeader() {
  //   const { classes, linkTo } = this.props;

  //   if (!linkTo) {
  //     return this.renderTitle();
  //   }

  //   return (
  //     <Link to={linkTo} className={classes.link}>
  //       <IconCaretLeft width="18" height="18" className={classes.icon} />
  //       {this.renderTitle()}
  //     </Link>
  //   );
  // }

  renderTitle() {
    const { classes, children, noEllipsis } = this.props;
    if (typeof children === 'string') {
      return (
        <Typography variant="h1" className={classNames(!noEllipsis && classes.textEllipsis, classes.title)}>
          {children}
        </Typography>
      );
    }

    return children;
  }
}

export default withStyles(styles)(Header);
