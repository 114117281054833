import * as React from 'react';

import { Action, Dispatch } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import NswStampDuty from './forms/nsw-stamp-duty';
import QldStampDuty from './forms/qld-stamp-duty';
import SaStampDuty from './forms/sa-stamp-duty';
import VicStampDuty from './forms/vic-stamp-duty';
import { StampDutyApiResponse } from './models';
import { StampDutyState } from './reducer';

import type { SafeDispatch } from 'src/hooks';

interface CommonProps {
  queryParams: WorkspaceDetailRouteParams;
  detail: StampDutyApiResponse;
  dispatch: SafeDispatch<Action> | Dispatch<Action>;
  isLocked: boolean;
}

const stampDutyFormComponentByJurisdiction: Partial<Record<HttpTypes.JurisdictionsEnum, React.ComponentType<CommonProps>>> = {
  [HttpTypes.JurisdictionsEnum.QLD]: QldStampDuty,
  [HttpTypes.JurisdictionsEnum.NSW]: NswStampDuty,
  [HttpTypes.JurisdictionsEnum.SA]: SaStampDuty,
  [HttpTypes.JurisdictionsEnum.VIC]: VicStampDuty
};

interface Props {
  workspaceId: string;
  participantId: string;
  // redux
  // workspace basic info
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  isLocked?: boolean;

  stampDutyState: StampDutyState;
  isLoading: boolean;
  // other
  dispatch: SafeDispatch<Action>;
  // new layout
}

function StampDuty(props: Props) {
  const { dispatch, isLoading, jurisdictionId, isLocked, stampDutyState, workspaceId, participantId } = props;
  if (isLoading) {
    // TODO improve this loading experience
    return <BlockLoader />;
  }

  const StampDutyForm = stampDutyFormComponentByJurisdiction[jurisdictionId!];
  if (StampDutyForm == null) {
    return null;
  }

  return (
    <StampDutyForm //
      detail={stampDutyState.detail!}
      queryParams={{ workspaceId, participantId }}
      isLocked={isLocked!}
      dispatch={dispatch}
    />
  );
}

export default React.memo(StampDuty);
