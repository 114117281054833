import { isAfter } from 'date-fns';
import dateformat from 'dateformat';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import environments from 'src/@core/environments';
import { DateFormatEnum } from 'src/@core/models';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { convertRangeDateToIsoDate } from 'src/components/date-range-picker/helpers';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel, InvitationsDashboardV2ApiRequest } from './models';
import { ArchivedInvitationLogsActionEnum, ArchivedInvitationLogsRoleEnum, InvitationsTableRowModel } from './table/models';

export const defaultTabQuery = (
  //
  selectedTab: InvitationsDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum,
  groupId: string | undefined
): InvitationsDashboardV2ApiRequest => {
  let sortBy: string | undefined = 'lastInvitationSentDate';
  let sortOrder: DashboardSortDirectionEnum | undefined = DashboardSortDirectionEnum.asc;
  let settlementPeriod: DashboardDateRangeModel | undefined = undefined;
  let lastInvitationSentPeriod: DashboardDateRangeModel | undefined = undefined;

  let invitationType: HttpTypes.InvitationTypeFilterEnum | undefined = undefined;

  let groupIds: string[] = [];

  // const { from, to } = getDateRange(DateRangeTypeEnum.Today);
  const pageSize = 20;
  const pageNumber = 1;

  if (assignmentType === AssignmentTypeEnum.ReAssigned && groupId) {
    groupIds = [groupId];
  }
  switch (selectedTab) {
    case InvitationsDashboardRouteTabsEnumV2.received: {
      invitationType = HttpTypes.InvitationTypeFilterEnum.Received;

      break;
    }
    case InvitationsDashboardRouteTabsEnumV2.sent: {
      invitationType = HttpTypes.InvitationTypeFilterEnum.Sent;
      break;
    }
    case InvitationsDashboardRouteTabsEnumV2.archived: {
      invitationType = HttpTypes.InvitationTypeFilterEnum.Archived;
      sortOrder = DashboardSortDirectionEnum.desc;
      sortBy = 'archivedDate';
      break;
    }
  }

  return {
    //
    sortBy,
    sortOrder,
    pageSize,
    pageNumber,
    settlementPeriod,
    lastInvitationSentPeriod,
    assignmentType,
    invitationType,
    groupIds
  };
};

export const convertPeriodToDateObject = (period: any, prefix: string): Partial<Record<string, string | null>> => {
  if (period && typeof period !== 'string') {
    const { start, end } = convertRangeDateToIsoDate(period);
    return {
      [`${prefix}.start`]: start,
      [`${prefix}.end`]: end
    };
  }
  return {};
};

export const resolveSavedTabFiltersQuery = (currentTabFilters: InvitationsDashboardUiFilterModel): InvitationsDashboardV2ApiRequest => {
  const { selectedTab, settlementPeriod, lastInvitationSentPeriod, archivedDatePeriod, ...rest } = currentTabFilters;

  const dateObject: Partial<{
    //
    'settlementPeriod.start': string | null;
    'settlementPeriod.end': string | null;
    'lastInvitationSentPeriod.start': string | null;
    'lastInvitationSentPeriod.end': string | null;
    'archivedDatePeriod.start': string | null;
    'archivedDatePeriod.end': string | null;
  }> = {
    ...convertPeriodToDateObject(settlementPeriod, 'settlementPeriod'),
    ...convertPeriodToDateObject(lastInvitationSentPeriod, 'lastInvitationSentPeriod'),
    ...convertPeriodToDateObject(archivedDatePeriod, 'archivedDatePeriod')
  };

  const invitationType = InvitationSelectedTabMapping[selectedTab];

  return {
    pageNumber: 1,
    pageSize: 20,
    ...dateObject,
    ...rest,
    invitationType
  };
};

export const resolveFilterTabName = (tab: string) => {
  return tab;
};

export const shouldUpdateTime = (updatedTime: Date, currentUpdatedTime: Date) => {
  return (
    isAfter(updatedTime, currentUpdatedTime) &&
    dateformat(updatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER) !== dateformat(currentUpdatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER)
  );
};

export const InvitationSelectedTabMapping: Record<InvitationsDashboardRouteTabsEnumV2, HttpTypes.InvitationTypeFilterEnum> = {
  [InvitationsDashboardRouteTabsEnumV2.received]: HttpTypes.InvitationTypeFilterEnum.Received,
  [InvitationsDashboardRouteTabsEnumV2.sent]: HttpTypes.InvitationTypeFilterEnum.Sent,
  [InvitationsDashboardRouteTabsEnumV2.archived]: HttpTypes.InvitationTypeFilterEnum.Archived
};

export const getDefaultAssignmentTypeTab = (key: string): AssignmentTypeEnum => {
  const data = localStorage.getItem(key);
  if (data) {
    return Number(data) as AssignmentTypeEnum;
  }
  return AssignmentTypeEnum.AssignedToMe;
};

export const resolveInvitationDetail = (items: InvitationsTableRowModel[], rowDetailIndex?: number): InvitationsTableRowModel | undefined => {
  if (Number.isInteger(rowDetailIndex)) {
    const row: InvitationsTableRowModel = items[rowDetailIndex!];
    if (row) {
      return row;
    } else {
      // this should never happen since reducer takes care of resetting rowDetailIndex
      Logger.captureException(new BusinessLogicError('No invitation row data found.'));
    }
  }
  return;
};

export const resolveArchivedInvitationLogRole = (
  archivedLogs: HttpTypes.ArchivedInvitationListApiResponse,
  invitationDetails: InvitationsTableRowModel
): ArchivedInvitationLogsRoleEnum => {
  if (archivedLogs.length === 1) {
    const { status, forwardedToSubscriber } = archivedLogs[0];
    if (status === HttpTypes.InvitationStatusEnum.Forwarded && forwardedToSubscriber) {
      return ArchivedInvitationLogsRoleEnum.Forwarder;
    }

    if (invitationDetails.type === HttpTypes.ParticipantArchivedStatusEnum.Sent) {
      return ArchivedInvitationLogsRoleEnum.OriginalSender;
    }

    return ArchivedInvitationLogsRoleEnum.LastReceiver;
  }

  // archivedLogs must have at least 1 record, when it's more than 1 record, it should be the original sender
  return ArchivedInvitationLogsRoleEnum.OriginalSender;
};

export const resolveArchivedInvitationLogsAction = (archivedLogs: HttpTypes.ArchivedInvitationListApiResponse): ArchivedInvitationLogsActionEnum => {
  const { status } = archivedLogs[0];

  switch (status) {
    case HttpTypes.InvitationStatusEnum.Accepted:
      return ArchivedInvitationLogsActionEnum.Accepted;
    case HttpTypes.InvitationStatusEnum.Rejected:
      return ArchivedInvitationLogsActionEnum.Declined;
    case HttpTypes.InvitationStatusEnum.Withdrawn:
      return ArchivedInvitationLogsActionEnum.Withdrawn;
    case HttpTypes.InvitationStatusEnum.Forwarded:
    default: // pending in theory will never happen
      return ArchivedInvitationLogsActionEnum.Forwarded;
  }
};

export const openWorkspaceInNewTab = (workspaceId: string, invitedByParticipantId: string, heroWorkspace?: HttpTypes.HeroWorkspaceModel) => {
  const { BASENAME } = environments;
  const linkTo: string = heroWorkspace
    ? resolveWorkspaceDetailLink({
        workspaceId: heroWorkspace.workspaceId,
        participantId: heroWorkspace.participantId
      })
    : resolveWorkspaceDetailLink({
        workspaceId: workspaceId,
        participantId: invitedByParticipantId
      });
  // open a new tab and set the new opener to null to avoid injection
  const newWindow = window.open(`${BASENAME}${linkTo}`);
  if (newWindow) {
    newWindow.opener = null;
  }
};
