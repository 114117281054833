import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { SocketIoConnectionBase } from 'src/socket/connection';
import { ConnectParams } from 'src/socket/model';
import { SocketClientEventEnum, SocketServerEventEnum } from './events';
import { WorkspaceUpdateMessage } from './models';

const WORKSPACE_SERVER_PATH_URI = '/realtime/workspaceUpdated';
const WORKSPACE_SOCKET_PATH = '/realtime/workspaceUpdated/socket.io';

interface WorkspaceConnectParams extends ConnectParams {
  workspaceId: string;
}

type WorkspaceUpdateMessageCallBack = (message: WorkspaceUpdateMessage) => void;

type UserInfo = {
  subscriberId: string;
  userId: string;
  email: string;
  documentId?: string;
  workspaceId: string;
  participantId: string;
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
};

export class WorkspaceSocket extends SocketIoConnectionBase<WorkspaceConnectParams> {
  constructor() {
    super(WORKSPACE_SERVER_PATH_URI, WORKSPACE_SOCKET_PATH);
  }

  protected onAuthenticated(params: WorkspaceConnectParams) {
    super.onAuthenticated(params);
    const { workspaceId, subscriberId, userId, email, token } = params;
    this.emitWithAuth(SocketServerEventEnum.WorkspaceUpdatedInitiate, { token, workspaceId, subscriberId, userId, email });
  }

  public addListener(onReceiveMessage: WorkspaceUpdateMessageCallBack, userInfo: UserInfo) {
    // To unsubscribe all listeners of the WorkspaceUpdatedReceivedSuccess event first
    this.socket.off(SocketClientEventEnum.WorkspaceUpdatedReceivedSuccess);

    this.emitLog({ ...userInfo, socketIdClientSide: this.socket.id, message: 'The client has registered a new event handler' });

    this.socket.on(SocketClientEventEnum.WorkspaceUpdatedReceivedSuccess, (response: string, callback: (str: string) => {}) => {
      try {
        const message: WorkspaceUpdateMessage = JSON.parse(response);

        Logger.console(SeverityEnum.Info, `message received: ${response}`);

        callback(JSON.stringify({ ...userInfo, socketIdClientSide: this.socket.id, messageId: message.MessageId }));

        onReceiveMessage(message);
      } catch (error) {
        Logger.captureException(error);
      }
    });

    return this;
  }
}
