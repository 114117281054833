import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccessToken, IDToken, TokenManagerInterface, UserClaims } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import http, { sdk } from 'src/utils/http';
import IdleCheck from '../auth/IdleCheck';
import MfaLinkDeviceContainer from '../auth/mfa-link-device';
import { useOktaAuth } from '../auth/okta';
import LoadingProfile from '../components/profile-loader';
import { actionFetchProfile } from '../store/actions/profile';
import { useProfile } from '../store/reducers/profile';
import { BroadcastChannel_UserLogout, BroadcastChannel_UserSignin, USER_LOG_OUT, USER_SIGN_IN, UserSignInModel } from './broadcast';
import LogoutContext from './Logout.context';

// fire and forget endpoint to log user sign in
function logUserSignIn() {
  http
    .put(`/profile/log-user-login`, {})
    //
    .catch(e => {
      Logger.captureException(e);
    });
}

function AuthContainer({ children }: React.PropsWithChildren<{}>) {
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;
  const { status: userProfileStatus, mfaSetupInProgress } = useProfile();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function loadAuth() {
      if (isLoggingOut) {
        return;
      }

      try {
        if (authState?.isAuthenticated) {
          const accessToken = (await tokenManager.get('accessToken')) as AccessToken;
          http.Authorization = accessToken.accessToken; // setup accessToken for axios bearer token
          sdk.client.setToken(accessToken.accessToken); // setup accessToken for sdk
          logUserSignIn();

          // clean up potential previous renew event listener
          oktaAuth.tokenManager.off('renewed');
          // add renew event handler when token gets renewed, we update bearer token for axios
          oktaAuth.tokenManager.on('renewed', function onRenewed(key: string, newToken: AccessToken, oldToken: AccessToken) {
            if (key === 'accessToken') {
              http.Authorization = newToken.accessToken; // setup accessToken for axios bearer token
              sdk.client.setToken(newToken.accessToken); // setup accessToken for sdk
            }
          });

          // When an instance signs in, we tell other tabs/instances in the same browser
          const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
          const userInfo: UserClaims = await oktaAuth.token.getUserInfo(accessToken, idToken);
          if (userInfo.sub) {
            BroadcastChannel_UserSignin.postMessage({ type: USER_SIGN_IN, sub: userInfo.sub } as UserSignInModel);
          }

          // refetching the profile when we get a new token eg: after silent renew
          dispatch(actionFetchProfile.request());
        } else {
          await oktaAuth.signInWithRedirect();
        }
      } catch (error) {
        Logger.captureException(error);
      }
    }
    loadAuth();
  }, [isLoggingOut, dispatch, oktaAuth, tokenManager, authState?.isAuthenticated]);

  React.useEffect(() => {
    async function getUserSub() {
      try {
        if (authState?.isAuthenticated) {
          const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
          const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
          const userInfo: UserClaims = await oktaAuth.token.getUserInfo(accessToken, idToken);

          // listens to sign in message
          BroadcastChannel_UserSignin.onmessage = event => {
            const userSignInModel = event.data as UserSignInModel;
            // when another tab signs in with different account, we update our current tab by cleaning up session and do a hard reload
            if (userSignInModel.type === USER_SIGN_IN && userSignInModel.sub !== userInfo.sub) {
              window.sessionStorage.clear();
              window.location.reload();
            }
          };

          // listens to sign out message
          BroadcastChannel_UserLogout.onmessage = event => {
            // when another tab signs out, current tab signs out too
            if (event.data === USER_LOG_OUT && accessToken) {
              navigate('/logout');
            }
          };
        }
      } catch (error) {
        Logger.captureException(error);
      }
    }

    getUserSub();
  }, [authState?.isAuthenticated, oktaAuth.token, tokenManager]);

  const logOut = async () => {
    try {
      setIsLoggingOut(true);
      // https://github.com/okta/okta-auth-js/tree/master#signout
      await oktaAuth.signOut();
      // stop the oktaAuth service
      await oktaAuth.stop();
      // let other tabs know the logout
      BroadcastChannel_UserLogout.postMessage(USER_LOG_OUT);
      // disconnect the channel
      BroadcastChannel_UserLogout.close();
    } catch (error) {
      // log custom error, since error object might not be populated with message
      Logger.captureException(error.message || 'OKTA Error while logging out');
    }
  };

  // this is to make sure idp end session request finished before redirect
  if (isLoggingOut) {
    return null;
  }

  if (userProfileStatus === 'idle' || userProfileStatus === 'pending') {
    return <LoadingProfile />;
  }

  if (mfaSetupInProgress) {
    return <MfaLinkDeviceContainer />;
  }

  // TODO: Should show an error page instead an empty screen
  if (userProfileStatus === 'rejected') {
    return null;
  }

  return (
    <LogoutContext.Provider //
      value={{ isLoggingOut, logOut }}
    >
      {children}
      <IdleCheck />
    </LogoutContext.Provider>
  );
}

export default React.memo(AuthContainer);
