import { Client } from './client';
import { Profile } from './profile';
import { Search } from './search';
import { Settings } from './settings';
import { Config } from './types';

class ApiGateway {
  public client: Client;

  public settings: Settings;
  public profile: Profile;
  public search: Search;

  constructor(config: Config) {
    this.client = new Client(config);

    this.settings = new Settings(this.client);
    this.profile = new Profile(this.client);
    this.search = new Search(this.client);
  }
}

export default ApiGateway;
