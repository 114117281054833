import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { HttpTypes } from '@sympli/api-gateway/types';
import { IconDelete, IconPencil } from '@sympli/ui-framework/icons';

import Tooltip from 'src/@core/components/tooltip';
import { FavouriteFormikModel, SchedulerFormikModel } from 'src/containers/dashboard/reports/models';
import { useStyles } from './styles';

export interface Props {
  reportId: number;
  reportName: string;
  reportFeatureType: HttpTypes.ReportFeatureTypeEnum;
  isDeleteDisabled?: boolean;
  titleForEdit: string;
  titleForDelete: string;
  onDeleteClick: (reportName: string, reportId: number, reportFeatureType: HttpTypes.ReportFeatureTypeEnum) => void;
  onEditClick: (initialValues: SchedulerFormikModel | FavouriteFormikModel) => void;
  initialValues: SchedulerFormikModel | FavouriteFormikModel;
}

const ReportUpdateButtons = (props: Props) => {
  const classes = useStyles();
  const {
    //
    reportId,
    onDeleteClick,
    reportName,
    reportFeatureType,
    onEditClick,
    initialValues,
    titleForEdit,
    isDeleteDisabled,
    titleForDelete
  } = props;

  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Tooltip classes={{ tooltip: classes.tooltip }} title={titleForEdit} placement="bottom">
        <span>
          <IconButton
            className={classes.button}
            aria-label="Edit this report"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              onEditClick(initialValues);
            }}
            size="large"
          >
            <IconPencil className={classes.buttonIcon} />
          </IconButton>
        </span>
      </Tooltip>
      {!isDeleteDisabled && (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={titleForDelete} placement="bottom">
          <IconButton
            className={classes.button}
            onClick={e => {
              e.stopPropagation();
              onDeleteClick(reportName, reportId, reportFeatureType);
            }}
            aria-label="Delete a report"
            color="primary"
            size="large"
          >
            <IconDelete className={classes.buttonIcon} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
export default React.memo(ReportUpdateButtons);
