import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { RequestToTransferEctControl_2_28_0_Model } from './models';
import SectionNominationDetails from './sections/nomination-details';

const fieldName = modelKey<RequestToTransferEctControl_2_28_0_Model>();

function RootForm({ className, hideActionsPanel, onSaveChanges, onCancel }: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, nextActionLabel } = useDocumentContext();
  const { values } = useFormikContext<RequestToTransferEctControl_2_28_0_Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;

    if (!focusOnFirstItem) {
      Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
      return;
    }

    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <Form className={className}>
      <SectionTitleReference name={fieldName('titleReferences')} disabled={disabled} focusRef={focusOnTitleReferencesRef} />
      <SectionNominationDetails name={fieldName('ctController')} value={values.ctController} />

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={nextActionDisabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default RootForm;
