import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchProfileUserReportsPermissions } from '../actions/profileUserReportsPermissions';
import useStoreSelector from '../useStoreSelector';

export interface ProfileUserReportsPermissionsState {
  detail?: HttpTypes.SettingsUserReportPermissionsApiResponse;
  status: ApiStatus;
  error?: string;
  args?: {
    userId: string;
  };
}

const initialState: ProfileUserReportsPermissionsState = {
  detail: undefined,
  status: 'idle',
  error: undefined,
  args: undefined
};

export function useProfileUserReportsPermissions(userId: string) {
  const state = useStoreSelector(store => store.profileUserReportsPermissions);
  // here we make sure that we will never return stale data that does not belong to us
  if (state.args?.userId === userId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  ProfileUserReportsPermissionsState,
  Action
>(initialState)
  .handleAction(actionFetchProfileUserReportsPermissions.request, (state, action): ProfileUserReportsPermissionsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending',
      args: { userId: action.payload }
    };
  })
  .handleAction(actionFetchProfileUserReportsPermissions.success, (state, action): ProfileUserReportsPermissionsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchProfileUserReportsPermissions.failure, (state, action): ProfileUserReportsPermissionsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
