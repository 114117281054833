import * as yup from 'yup';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { createPartyBookItemTestForPartyBookId } from '@sympli-mfe/document-forms-framework/components/party-form';
import { testValueReuseInArray } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { VISIBILITY_CHECK_CAVEATORS } from '../../checks';
import { ActionProhibitedEnum } from '../../enums';
import { CaveatModel, CaveatParty } from '../../models';
import { atLeastOneProprietorListedAsCaveator } from '../../sharedValidation';
import { testUnaffectedProvider } from '../party-book/test-unaffected-provider';

type FormValues = CaveatModel;

const yupCaveatorItem = yup.object<CaveatParty>().shape({
  // party: yupNSWDocumentParty
  // address: yupNSWDeliveryAddress
  partyBookId: yup // reference to party store
    .string()
    .default('')
    .nullable(true)
    .required(msg.REQUIRED)
    .test(
      'PARTY ALREADY SELECTED CHECK',
      'Cannot have multiple caveators with the same name',
      testValueReuseInArray(
        (model: FormValues) => model.caveators!,
        caveator => caveator.partyBookId
      )
    )
    .test('Referenced party validity check', 'Party is invalid', testUnaffectedProvider)
    .test(createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG)),

  addressBookId: yup.string().default('').nullable(true).required(msg.REQUIRED) // reference to address store
});

// path: caveators
export default yup //
  .mixed<CaveatParty[]>()
  .test(
    validateWhenVisible({
      isObjectOrArray: true,
      visibilityCheck: VISIBILITY_CHECK_CAVEATORS,
      validationWhenVisible: yup
        .array<CaveatParty>()
        .min(1, msg.MIN_ITEMS(1))
        .max(20, msg.MAX_ITEMS(20))
        .test(
          testContextualRule<CaveatModel, CaveatParty[]>({
            message: 'To select Action Prohibited #5 you must have at least one Proprietor listed as a Caveator',
            requirement: (caveators: CaveatParty[], { extentOfProhibitions, proprietors }: CaveatModel) => {
              const isActionProhibited5Selected: boolean = (extentOfProhibitions ?? []).some(item => item.actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5);
              if (!isActionProhibited5Selected) {
                // Rule only applies if AP5 is selected
                return true;
              }
              return atLeastOneProprietorListedAsCaveator(proprietors ?? [], caveators ?? []);
            }
          })
        )
        .of(yupCaveatorItem)
    })
  );
