import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import DidNotLoad from 'src/containers/dashboard/components/svg/DidNotLoad';
import { financialWorkspaceStatusFormatter, settlementTimeFormatter, titleFormatter } from 'src/containers/dashboard/shared/formatters';
import FinancialLinkedWorkspaceStatusBadge from 'src/containers/workspace/shared/components/financial-linked-workspace-status-badge';
import { LinkedWorkspaceTableModel } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/linked-workspace-table/models';
import { LinkedWorkspaceTypeMapping } from 'src/containers/workspace/shared/detail/components/linked-settlement-detail/models';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { modelKey } from 'src/utils/formUtils';

const cf = columnFormatterCheck<LinkedWorkspaceTableModel>();

const fieldName = modelKey<LinkedWorkspaceTableModel>();

function LinkedWorkspaceCardDetail({
  //
  rows,
  linkedWorkspaceOverallStatus,
  className,
  isLoading,
  apiStatus,
  linkedWorkspaceType,
  workspaceStatus
}: {
  rows: LinkedWorkspaceTableModel[];
  linkedWorkspaceOverallStatus?: HttpTypes.LinkedWorkspaceOverallStatusEnum;
  className?: string;
  isLoading: boolean;
  apiStatus: string;
  linkedWorkspaceType: HttpTypes.LinkedWorkspaceTypeEnum;
  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
}) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const columns: ColumnsModel<LinkedWorkspaceTableModel> = {
    setlDate: {
      width: screenVariant === 'medium-screen' || screenVariant === 'large-screen' ? 110 : 185,
      verticalAlign: 'top',
      label: '',
      name: fieldName('settlementDate'),
      get: ({ settlementDate, workspaceStatus, pendingProposedSettlementDate, expectedSettlementDate, isUnsupportedSettlementDate }: LinkedWorkspaceTableModel) => ({
        settlementDate,
        workspaceStatusId: workspaceStatus,
        pendingProposedSettlementDate,
        expectedSettlementDate,
        isUnsupportedSettlementDate
      }),
      formatter: cf<{
        //
        settlementDate: LinkedWorkspaceTableModel['settlementDate'];
        workspaceStatusId: LinkedWorkspaceTableModel['workspaceStatus'];
        pendingProposedSettlementDate: LinkedWorkspaceTableModel['pendingProposedSettlementDate'];
        expectedSettlementDate: LinkedWorkspaceTableModel['expectedSettlementDate'];
        isUnsupportedSettlementDate: LinkedWorkspaceTableModel['isUnsupportedSettlementDate'];
      }>(settlementTimeFormatter)
    },

    title: {
      label: '',
      width: screenVariant === 'medium-screen' || screenVariant === 'large-screen' ? 110 : 185,
      name: fieldName('sympliId'),
      get: ({ titles, sympliId }: LinkedWorkspaceTableModel) => ({
        titles,
        sympliId
      }),
      formatter: cf<{ titles: string[]; sympliId: string }>(titleFormatter),
      tooltipFormatter({ anchorEl, row }) {
        switch (anchorEl.dataset.binding) {
          case 'title':
            return anchorEl.innerText;
          case 'plusN':
            return (
              <div>
                <div className="mb-[16px]">
                  <b>Multiple titles found</b>
                </div>
                {row.titles.map((item, i) => {
                  return (
                    <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                      {item}
                    </Typography>
                  );
                })}
              </div>
            );
          default:
            return null;
        }
      }
    },

    wsStatusText: {
      verticalAlign: 'top',
      width: screenVariant === 'medium-screen' ? 65 : 80,
      label: '',
      name: fieldName('workspaceStatus'),
      formatter: cf<LinkedWorkspaceTableModel['workspaceStatus']>(financialWorkspaceStatusFormatter)
    }
  };

  const noDataMessage = apiStatus === 'rejected' ? <DidNotLoad className={'w-[300px] h-[200px]'} /> : undefined;

  return (
    <>
      <FlexLayout flexDirection="row" justifyContent="space-between" className="mt-[32px]">
        <div className="text-[14px] leading-[14px] font-volkswagen-serial-medium font-medium uppercase text-[var(--sympli-green)]">
          {LinkedWorkspaceTypeMapping[linkedWorkspaceType]}
        </div>
        {linkedWorkspaceOverallStatus !== undefined && (
          <FinancialLinkedWorkspaceStatusBadge linkedWorkspaceOverallStatus={linkedWorkspaceOverallStatus} workspaceStatus={workspaceStatus} />
        )}
      </FlexLayout>
      <CardDetail //
        className={className}
        columns={columns}
        rows={rows}
        noDataDisplay={noDataMessage}
        {...(isLoading && {
          isLoading: true,
          pageSize: 4
        })}
      />
    </>
  );
}

export default React.memo(LinkedWorkspaceCardDetail);
