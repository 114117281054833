import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DisplayDateTime from './components/display-date-time/DisplayDateTime';
import DueInDateTime from './components/due-in-date-time/DueInDateTime';
import NoDateNoTime from './components/NoDateNoTime';
import VerticalDivider from './components/VerticalDivider';
import { SettlementDateTimeVariantEnum } from './models';

export interface SettlementDateTimeCardProps {
  // workspace basic info
  isLocked?: boolean;
  isRollover?: boolean;
  isInteroperable?: boolean;
  jurisdictionId?: HttpTypes.JurisdictionsEnum | null;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  // settlement details info
  isAcceptedByAll?: boolean;

  isProposedByUser?: boolean;
  localProposedSettlementDate?: string;

  settlementAwaitingAcceptanceCount?: number;
  settlementDateTime?: HttpTypes.WorkspaceDateTimeModel;
  expectedSettlementDateTime?: HttpTypes.ExpectedSettlementDate;
  isUnsupported?: boolean;
  // other
  isLoading?: boolean;
  // always passed by parent
  editLink?: string;
  declineLink?: string;
  onAcceptClick?(): void; // TODO to be cleaned up
  //TODO above one can be moved to Container level

  // linked settlements info
  isLinkedSettlementDateTimeMisaligned?: boolean;
  redirectSettlementDatePage(): void;
  variant: SettlementDateTimeVariantEnum;
  weeksTill?: number;
  daysTill?: number;
  formattedSettlementDate?: string;
  formattedSettlementTime?: string;
  isAcceptedByUser?: boolean;
  handleOnToggleChange?: (checked: boolean) => void;
  isToggleButtonDisabled?: boolean;
  proposedSettlementDate?: string;
}

function SettlementDateTimeCard({
  //
  variant,
  redirectSettlementDatePage,
  isLinkedSettlementDateTimeMisaligned,
  weeksTill,
  daysTill,
  formattedSettlementDate,
  formattedSettlementTime,
  isAcceptedByUser,
  isToggleButtonDisabled,
  handleOnToggleChange
}: SettlementDateTimeCardProps) {
  const renderContent = () => {
    if (variant === SettlementDateTimeVariantEnum.NoDateNoTime) {
      return <NoDateNoTime redirectSettlementDatePage={redirectSettlementDatePage} />;
    }

    return (
      <div className="flex justify-center items-center gap-[16px] px-[24px] py-[18px]">
        <DueInDateTime variant={variant} weeksTill={weeksTill} daysTill={daysTill} />
        <VerticalDivider />
        <DisplayDateTime
          isAcceptedByUser={isAcceptedByUser}
          redirectSettlementDatePage={redirectSettlementDatePage}
          variant={variant}
          formattedSettlementDate={formattedSettlementDate}
          formattedSettlementTime={formattedSettlementTime}
          handleOnToggleChange={handleOnToggleChange}
          isToggleButtonDisabled={isToggleButtonDisabled}
        />
      </div>
    );
  };

  const renderMisalignedLinkedSettlementDateLabel = () => {
    return (
      <FlexLayout alignItems="center" justifyContent="center">
        <Typography className="text-[12px] leading-[16px] medium-screen:leading-[18px] tracking-[0px] text-[var(--watermelon)] py-[8px] font-medium">
          Misaligned Date & Time between Linked Workspaces
        </Typography>
      </FlexLayout>
    );
  };

  return (
    <div data-testid="settlement-date-time-card" className="w-[300px] rounded-[12px] medium-screen:w-[360px] bg-[var(--watermelon-lite)] ">
      {isLinkedSettlementDateTimeMisaligned && renderMisalignedLinkedSettlementDateLabel()}
      <div className="rounded-[12px] shadow-custom-light h-[120px] bg-[var(--neutral-000)]">{renderContent()}</div>
    </div>
  );
}

export default React.memo(SettlementDateTimeCard);
