import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentActionModel } from 'src/containers/documents/models';

export function getParticipantActionDisplayName(currentParticipantId: string, documentAction: DocumentActionModel): string {
  if (documentAction.participantId !== currentParticipantId) {
    return documentAction.participantSubscriberName;
  }

  return documentAction.userFullName;
}

export function resolveActionPrefix(type: HttpTypes.DocumentActionTypeEnum) {
  switch (type) {
    case HttpTypes.DocumentActionTypeEnum.Review: {
      return 'Reviewed by';
    }
    case HttpTypes.DocumentActionTypeEnum.Sign: {
      return 'Signed by';
    }
    default: {
      return 'Saved by';
    }
  }
}

export function resolveActionRolePrefix(type: HttpTypes.DocumentActionTypeEnum) {
  switch (type) {
    case HttpTypes.DocumentActionTypeEnum.Review: {
      return 'Approver';
    }
    case HttpTypes.DocumentActionTypeEnum.Sign: {
      return 'Signer';
    }
    default: {
      return '';
    }
  }
}
