import * as yup from 'yup';

import { ConsiderationModel } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { yupConsiderationAmount, yupConsiderationType } from '@sympli-mfe/document-forms-framework/components/sections/consideration/validation-schema';
import { HttpTypes } from '@sympli/api-gateway/types';

export default yup
  .object<ConsiderationModel>({
    type: yupConsiderationType(HttpTypes.JurisdictionsEnum.NSW),
    amount: yupConsiderationAmount
  })
  .defined();
