import * as React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconArrowDown } from '@sympli/ui-framework/icons';

import {
  useButtonsOverridesClasses,
  useFormControlStyleOverrides,
  useInputLabelStyleOverrides,
  useListSubheaderOverridesClasses,
  useMenuOverridesClasses,
  usePaperOverridesClasses,
  useSelectOverridesClasses,
  useTextFieldOverridesClasses
} from './styles';

interface TextboxSearchProps {
  label: string;
  value: (string | number)[];
  handleOnTextboxSearchFilterChange: (newValue: string, name?: string) => void;
  placeholder?: string | JSX.Element;
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  name?: string;
  IconComponent?: React.ElementType;
  defaultOpen?: boolean; // this let parent component to take control
}

function TextboxSearch({
  //
  label,
  value,
  handleOnTextboxSearchFilterChange,
  fullWidth,
  className,
  style,
  disabled,
  name,
  defaultOpen
}: TextboxSearchProps) {
  const [open, setOpen] = React.useState<boolean>(Boolean(defaultOpen));
  const formControlOverridesClasses = useFormControlStyleOverrides();
  const inputLabelOverridesClasses = useInputLabelStyleOverrides();
  const selectOverridesClasses = useSelectOverridesClasses();
  const paperOverridesClasses = usePaperOverridesClasses();
  const menuOverridesClasses = useMenuOverridesClasses();
  const listSubheaderOverridesClasses = useListSubheaderOverridesClasses();
  const textFieldOverridesClasses = useTextFieldOverridesClasses();
  const buttonsOverridesClasses = useButtonsOverridesClasses();

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [hasError, setHasError] = React.useState<boolean>(true);

  const labelId = _uniqueId('tbs-label');

  React.useEffect(() => {
    if (searchValue === '') {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [searchValue]);

  const handleOnSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    const numericValue = newSearchValue.replace(/\D/g, '');
    setSearchValue(numericValue);
  };

  const clearTextField = () => {
    setSearchValue('');
    handleOnTextboxSearchFilterChange('', name);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && searchValue) {
      event.preventDefault();
      handleOnTextboxSearchFilterChange(searchValue, name);
      setOpen(false);
    }
  };

  return (
    <FormControl //
      disabled={disabled}
      data-name={name}
      className={classNames(!import.meta.env.PROD && 'devHelper', className)}
      classes={formControlOverridesClasses}
      style={style}
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        width: 120
      }}
    >
      <InputLabel //
        id={labelId}
        shrink={true}
        classes={inputLabelOverridesClasses}
      >
        {label}
      </InputLabel>
      <Select //
        onClose={() => {
          setOpen(false);
        }}
        onOpen={handleOpen}
        open={open}
        name={name}
        classes={selectOverridesClasses}
        labelId={labelId}
        variant="outlined"
        displayEmpty
        value={value === null ? '' : value}
        IconComponent={props => <IconArrowDown width={24} height={24} {...props} />}
        renderValue={() => 'Search'}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          autoFocus: false,
          classes: menuOverridesClasses,

          PaperProps: {
            classes: paperOverridesClasses
          }
        }}
      >
        {
          <ListSubheader //
            classes={listSubheaderOverridesClasses}
          >
            <TextField //
              fullWidth
              variant="outlined"
              classes={textFieldOverridesClasses}
              inputProps={{
                inputMode: 'numeric',
                maxlength: 10
              }}
              // Autofocus on textfield
              autoFocus
              label={label}
              value={searchValue}
              onChange={handleOnSearchInputChange}
              onKeyDown={handleKeyPress}
            />

            <FlexLayout justifyContent="space-between" className={buttonsOverridesClasses.buttons}>
              <FlexLayout flexDirection="row" justifyContent="flex-end">
                <Button variant="outlined" onClick={clearTextField} className={buttonsOverridesClasses.clearButton}>
                  Clear
                </Button>
                <ButtonBase
                  onClick={() => {
                    handleOnTextboxSearchFilterChange(searchValue, name);
                  }}
                  className={buttonsOverridesClasses.searchButton}
                  disabled={hasError}
                >
                  Search
                </ButtonBase>
              </FlexLayout>
            </FlexLayout>
          </ListSubheader>
        }
      </Select>
    </FormControl>
  );
}

export default React.memo(TextboxSearch);
