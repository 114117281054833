import * as React from 'react';

import { useFormikContext } from 'formik';

import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG } from '../../../../components/tenancy/config';
import { NSW_TAE_PARTY_FORM_CONFIG, NSW_TAE_RECEIVING_TENANCY_LABEL_CONFIG } from '../../config';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from '../../models';

interface Props {
  name: string;
}

function Applicants({ name }: Props) {
  const {
    values: { deceasedTenancyDetail }
  } = useFormikContext<TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model>();
  const relinquishingPartyIds = deceasedTenancyDetail.proprietorGroups.find(pg => pg.isSelected)?.parties.map(p => p.partyBookId) ?? [];

  return (
    <SectionTenancyReceiving
      name={name}
      partyFormConfig={NSW_TAE_PARTY_FORM_CONFIG}
      componentLabels={NSW_TAE_RECEIVING_TENANCY_LABEL_CONFIG}
      totalShareConfig={NSW_RECEIVING_TENANCY_TOTAL_SHARE_CONFIG}
      allowNewGroup={false}
      partyIdsToFilter={relinquishingPartyIds}
      disablePartyEditConfig={disablePartyFieldEditConfig()}
    />
  );
}

export default React.memo(Applicants);
