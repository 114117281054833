import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps, getIn } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { createModelKeyAppender, getFormikError } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Formik from '@sympli/ui-framework/components/formik';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper, { WizardStepperClassKeys } from '@sympli/ui-framework/components/wizard-stepper';
import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import ActionProhibited from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/action-prohibited';
import CaveatStatement from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/caveat-statement';
import Caveator from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/caveator';
import { resolvePartyLookupOptions } from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/party-book/resolve-party-lookup-options';
import RegisteredProprietor from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/registered-proprietor';
import ServiceOfNotice from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/service-of-notice';
import { VISIBILITY_CHECK_CAVEATORS } from './checks';
import { convertFromApiToFormModel, convertFromFormToApiModel } from './conversion';
import { SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS } from './enums';
import { ApiCaveatModel_2_21_1, CaveatModel_2_21_1, ServiceOfNoticeDetails } from './models';
import ClaimDetails from './sections/claim-details';
import styles, { ClassKeys } from './styles';
import validationSchema from './validationSchema';

type FormValues = CaveatModel_2_21_1;

export type OwnProps = DocumentFormProps<FormValues, ApiCaveatModel_2_21_1>;

export type Props = OwnProps & WithStyles<ClassKeys>;

function NswCaveatForm(props: Props): JSX.Element {
  const wizardStepperClasses: Partial<ClassNameMap<keyof ReturnType<WizardStepperClassKeys>>> = {
    container: props.classes.wizardStepperContainer,
    nextButton: props.onSaveChanges ? props.classes.wizardStepperNextButton : undefined
  };

  const portalIdDialogs: string = _uniqueId();

  const focusOnTitleReferenceRef = React.createRef<ButtonBaseActions>();
  const focusOnCaveatorRef = React.createRef<ButtonBaseActions>();
  const focusOnServiceOfNoticeRef = React.createRef<ButtonBaseActions>();

  React.useEffect(() => {
    function focusOnFirst() {
      const focusOnFirstItem = focusOnTitleReferenceRef.current || focusOnCaveatorRef.current || focusOnServiceOfNoticeRef.current;
      if (!focusOnFirstItem) {
        Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
        return;
      }
      focusOnFirstItem.focusVisible();
    }
    focusOnFirst();
  }, [focusOnCaveatorRef, focusOnServiceOfNoticeRef, focusOnTitleReferenceRef]);

  function getInitialValues(): FormValues {
    return convertFromApiToFormModel(props.initialApiValues);
  }

  return (
    <Formik //
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      onPostSubmit={props.onPostSubmit}
    >
      {(formikProps: FormikProps<FormValues>) => renderForm(formikProps)}
    </Formik>
  );

  function renderForm(formikProps: FormikProps<FormValues>): JSX.Element {
    props.onRenderForm(formikProps);

    const { classes, className } = props;

    const isLoading: boolean = props.isLoading || formikProps?.isSubmitting || false;
    const disabled: boolean = props.disabled || isLoading;

    return (
      <>
        <Form className={classNames(classes.root, className)}>
          {renderSections(formikProps, disabled)}
          {renderActionsPanel(disabled, isLoading, formikProps)}
        </Form>
        <PortalTarget id={portalIdDialogs} />
      </>
    );
  }

  function renderSections(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const partyOptions = resolvePartyLookupOptions(formikProps.values);
    return (
      <>
        {renderTitleReferencesSection(formikProps, disabled)}
        {renderCaveatorsSection(formikProps, disabled, partyOptions)}
        {renderServiceOfNoticeSection(formikProps, disabled, partyOptions)}
        {renderRegisteredProprietorsSection(formikProps, disabled)}
        {renderActionProhibitedSection(formikProps, disabled)}
        {renderClaimDetailsSection(formikProps, disabled, partyOptions)}
        {renderSupportingDocumentSection(formikProps)}
        {renderCaveatStatementSection(formikProps, disabled)}
      </>
    );
  }

  function renderTitleReferencesSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const name: keyof FormValues = 'titleReferences';

    return (
      <SectionTitleReference //
        name={name}
        disabled={disabled}
        focusRef={focusOnTitleReferenceRef}
        data-name={name}
        data-testid="title-section"
      />
    );
  }

  function renderCaveatorsSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]): JSX.Element {
    const { classes } = props;
    const caveatModel = formikProps.values;
    const name: keyof FormValues = 'caveators';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Caveator"
        error={error}
        data-name={name}
      >
        {VISIBILITY_CHECK_CAVEATORS(caveatModel) ? (
          <Caveator //
            name={name}
            formikProps={formikProps}
            disabled={disabled}
            portalIdDialogs={portalIdDialogs}
            focusRef={focusOnCaveatorRef}
            partyOptions={partyOptions}
            data-testid="caveator"
          />
        ) : (
          <Typography className={classes.caveatorsUnselectableMessage} data-testid="caveators-unselectable-message">
            Action Prohibited #5 has been selected, which requires the Registered Proprietor(s) to be listed as the Caveator(s) - should you wish to change the Caveator(s), please
            select a different Action Prohibited{(caveatModel.proprietors?.length || 0) > 1 && ' or mark one of the proprietors as unaffected'}.
          </Typography>
        )}
      </Section>
    );
  }

  function renderServiceOfNoticeSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]) {
    const fieldName = createModelKeyAppender<ServiceOfNoticeDetails, FormValues>('detailsForServiceOfNotice');
    const name = fieldName('contacts');

    return (
      <Section //
        title="Service of notice"
        data-name={name}
      >
        <ServiceOfNotice //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={portalIdDialogs}
          focusRef={focusOnServiceOfNoticeRef}
          partyOptions={partyOptions}
        />
      </Section>
    );
  }

  function renderRegisteredProprietorsSection(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const name: keyof FormValues = 'proprietors';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Registered proprietor"
        error={error}
        data-name={name}
      >
        <RegisteredProprietor //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={portalIdDialogs}
        />
      </Section>
    );
  }

  function renderActionProhibitedSection(formikProps: FormikProps<FormValues>, disabled: boolean) {
    const name: keyof FormValues = 'extentOfProhibitions';
    const error = getFormikError(formikProps, name); // track section level error

    return (
      <Section //
        title="Action prohibited"
        error={error}
        data-name={name}
        data-testid="action-prohibited"
      >
        <ActionProhibited //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
        />
      </Section>
    );
  }

  function renderClaimDetailsSection(formikProps: FormikProps<FormValues>, disabled: boolean, partyOptions: LookupItemModel[]) {
    const name: keyof FormValues = 'claimDetails';

    return (
      <Section //
        title="Claim details"
        data-name={name}
        data-testid="claim-details"
      >
        <ClaimDetails //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
          portalIdDialogs={portalIdDialogs}
          queryParams={props.queryParams}
          partyOptions={partyOptions}
        />
      </Section>
    );
  }

  function renderCaveatStatementSection(formikProps: FormikProps<FormValues>, disabled: boolean): JSX.Element {
    const name: keyof FormValues = 'caveatStatementsAgreed';

    return (
      <Section //
        title="Caveat statement"
        data-name={name}
        data-testid="caveat-statement"
      >
        <CaveatStatement //
          name={name}
          formikProps={formikProps}
          disabled={disabled}
        />
      </Section>
    );
  }

  function renderActionsPanel(disabled: boolean, isLoading: boolean, formikProps: FormikProps<CaveatModel_2_21_1>) {
    const { hideActionsPanel, submitActionLabel, onSaveChanges } = props;

    if (hideActionsPanel) {
      return null;
    }

    return (
      <WizardStepper isLoading={isLoading} disabled={disabled} backLabel="Cancel" onBack={handleOnBackClick} nextLabel={submitActionLabel} classes={wizardStepperClasses}>
        {onSaveChanges && renderSaveChangesBtn(disabled, formikProps)}
      </WizardStepper>
    );
  }

  function renderSaveChangesBtn(disabled: boolean, formikProps: FormikProps<CaveatModel_2_21_1>) {
    const handleOnSaveChanges = () => {
      const { values } = formikProps;
      const apiCaveatModel = convertFromFormToApiModel(values, props.initialApiValues);
      props.onSaveChanges!(apiCaveatModel, formikProps);
    };

    return (
      <SympliButton color="primary" variant="outlined" className={props.classes.wizardStepperSaveChangesButton} disabled={disabled} onClick={handleOnSaveChanges}>
        Save changes
      </SympliButton>
    );
  }

  function renderSupportingDocumentSection(formikProps: FormikProps<FormValues>) {
    const name: keyof FormValues = 'supportingDocuments';
    const attachments = getIn(formikProps.values, name) ?? [];
    const isRequired: boolean = attachments.length === 0;
    return (
      <div data-testid={name}>
        <DocumentAttachments //
          name={name}
          minItems={0}
          maxItems={1}
          isRequired={isRequired}
          attachmentDocumentTypes={SUPPORTING_DOCUMENT_TYPE_LOOKUP_OPTIONS}
        />
      </div>
    );
  }

  function handleOnSubmit(values: FormValues, formikProps: FormikProps<FormValues>): Promise<HttpTypes.UpdateWorkspaceDocumentApiResponse> {
    const apiCaveatModel = convertFromFormToApiModel(values, props.initialApiValues);
    return props.onSave(apiCaveatModel, formikProps);
  }

  function handleOnBackClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    props.onCancel();
  }
}

export default withStyles(styles)(NswCaveatForm) as React.ComponentType<DocumentFormProps<FormValues, ApiCaveatModel_2_21_1>>;
