import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { AddressBookEntityModel, AddressTypeEnum, PhysicalAddressModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { getEmptyPartyModel, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { LevelTypeEnum, OrganisationTypeEnum, PartyTypeEnum, StreetTypeEnum, UnitTypeEnum } from '@sympli-mfe/enums-2-18/vic';
import { HttpTypes } from '@sympli/api-gateway/types';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import AutocompleteField from '@sympli/ui-framework/components/formik/autocomplete-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconSearch } from '@sympli/ui-framework/icons';

import http from 'src/utils/http';
import { CorrespondenceTypeEnum, SUBSCRIBER_ELN_LOOKUP_OPTIONS, SubscriberElnEnum, SubscriberNameTypeEnum } from '../../enums';
import { CorrespondencesModel, RecipientPartyModel, SubscriberLookupApiResponse, TransferOfControlModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
  disabled: boolean;
  isSympliSubRecipient: boolean;
}

const INITIAL = 'INITIAL_NEW';

function RecipientSubscriberLookup({ name, className, disabled, isSympliSubRecipient }: Props): JSX.Element {
  const formikProps = useFormikContext<TransferOfControlModel>();
  const { values, setValues } = formikProps;
  const classes = useStyles();
  const initialSubscriberLookupResponse: SubscriberLookupApiResponse = { id: INITIAL, name: INITIAL };
  const [subscriberLookupResponse, setSubscriberLookupResponse] = useState(initialSubscriberLookupResponse);
  const fieldName = createModelKeyAppender<RecipientPartyModel>(name);
  const fetchPerformed = React.useRef(false);

  useEffect(() => {
    const setSympliRecipient = (subscriberLookupResponse: SubscriberLookupApiResponse) => {
      const correspondence: CorrespondencesModel[] = subscriberLookupResponse.subscriberLandRegistry!.correspondences!.filter(c => c.type === CorrespondenceTypeEnum.Lodgement);

      const selectedSubscriberId = `Recipient_${subscriberLookupResponse.subscriberLandRegistry!.lrCustomerCode}`;
      const selectedSubscriberPartyModel: PartyModel = getEmptyPartyModel(PartyTypeEnum.Organisation);
      selectedSubscriberPartyModel.id = selectedSubscriberId;
      selectedSubscriberPartyModel.legalEntityName =
        subscriberLookupResponse.names!.filter(name => name.type === SubscriberNameTypeEnum.Legal)[0].name || subscriberLookupResponse.name;
      selectedSubscriberPartyModel.acn = subscriberLookupResponse.acn || '';
      selectedSubscriberPartyModel.organisationName = subscriberLookupResponse.name || '';
      // TODO: KL Need to know default organisation type (especially if we're disabling editing)
      selectedSubscriberPartyModel.organisationType = OrganisationTypeEnum.Registeredcompany;
      const newPartyBook = !values.partyBook.filter(p => p.id === selectedSubscriberPartyModel.id).length ? [...values.partyBook, selectedSubscriberPartyModel] : values.partyBook;

      // TODO: KL Awaiting response from Lachlan for Address that are not type: Physical?
      const selectedSubscriberPhysicalAddress: PhysicalAddressModel = correspondence[0].address.physicalAddress;
      const newAddressBookEntry: AddressBookEntityModel = {
        id: selectedSubscriberId,
        type: AddressTypeEnum.PhysicalAddress,
        physicalAddress: {
          ...selectedSubscriberPhysicalAddress,
          streetType: StreetTypeEnum[selectedSubscriberPhysicalAddress.streetType || ''],
          unitType: UnitTypeEnum[selectedSubscriberPhysicalAddress.unitType || ''],
          floorType: LevelTypeEnum[selectedSubscriberPhysicalAddress.floorType || '']
        }
      };
      const newAddressBook = !values.addressBook.filter(a => a.id === selectedSubscriberId).length ? [...values.addressBook, newAddressBookEntry] : values.addressBook;

      setValues({
        ...values,
        partyBook: newPartyBook,
        addressBook: newAddressBook,
        recipient: {
          ...values.recipient,
          partyBookId: selectedSubscriberPartyModel.id,
          addressBookId: newAddressBookEntry.id,
          votsId: subscriberLookupResponse.subscriberLandRegistry!.lrCustomerCode
        }
      });
    };

    const reinitialiseForm = () => {
      const newPartyBook = values.partyBook.filter(p => p.id === values.applicant!.partyBookId);

      setValues({
        ...values,
        partyBook: newPartyBook,
        addressBook: [],
        recipient: {
          ...values.recipient,
          partyBookId: undefined,
          addressBookId: undefined,
          votsId: undefined,
          email: undefined
        }
      });
    };

    reinitialiseForm();
    if (isSympliSubRecipient && !!subscriberLookupResponse.subscriberLandRegistry) {
      setSympliRecipient(subscriberLookupResponse);
    }

    return () => {};
    // TODO: KL Refactor (See PR #2536 Comment)
  }, [isSympliSubRecipient, subscriberLookupResponse, setValues, values]);

  const handleOnSuggestedSelected = useCallback((event: React.SyntheticEvent<HTMLInputElement>, selectedSuggestion: SubscriberLookupApiResponse) => {
    if (selectedSuggestion.subscriberLandRegistry) {
      setSubscriberLookupResponse(selectedSuggestion);
    }
  }, []);

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // Only change the enum value of subscriberElno if the internal flag has been set (if the user has attempted to searched)
      if (!values.recipient?.subscriberId && fetchPerformed.current) {
        //
        setValues({
          ...values,
          recipient: {
            ...values.recipient,
            subscriberElno: SubscriberElnEnum.AlternateEln,
            subscriberName: undefined
          }
        });
        fetchPerformed.current = false;
      }
    },
    //! FIXME this is anti-pattern, values changes with each formik change,
    //! use setState callback pattern or setFieldValue instead
    //! values should never be in the list of dependencies otherwise memoization won't work
    [values, setValues]
  );

  const handleGetSuggestions = useCallback(name => {
    return getSubscriberSuggestion(name).then((value: SubscriberLookupApiResponse[]) => {
      // Used as an internal flag to check the user has attempted to search for a subscriber.
      fetchPerformed.current = true;
      return value;
    });
  }, []);

  return (
    <div>
      <Field //
        aria-label="Subscriber ELNO"
        name={fieldName('subscriberElno')}
        component={RadioField}
        disabled={disabled}
        options={SUBSCRIBER_ELN_LOOKUP_OPTIONS}
        format="number"
      />
      {isSympliSubRecipient && (
        <Field
          //
          name={fieldName('subscriberId')}
          resolvedValueName={fieldName('subscriberName')}
          component={AutocompleteField}
          getSuggestions={handleGetSuggestions}
          onSuggestionSelected={handleOnSuggestedSelected}
          placeholder="Please type at least 3 characters"
          endAdornment={<IconSearch style={{ marginRight: 4 }} />}
          searchingStartsAt={3}
          classes={{ suggestionsContainer: classes.suggestionsContainer }}
          onBlur={handleOnBlur}
          fullWidth
          noDataMessage={
            <FlexLayout flexDirection="column" justifyContent="center" alignItems="center">
              This organisation is not a Sympli subscriber.
              <div>Please enter recipient details.</div>
            </FlexLayout>
          }
          label={
            <>
              Search for Sympli subscriber
              <InfoIconWithTooltip
                flow="inline"
                tooltipContent="Please use the Sympli subscriber look up to fill in this form, alternatively if you are transferring control to a subscriber on an alternate ELNO please fill in the form below"
              />
            </>
          }
        />
      )}
    </div>
  );
}

function getSubscriberSuggestion(name: string) {
  const encodedName = encodeURIComponent(name);
  //ToDo: Change me, need to add a new api endpoint that returns SubscriberLookupApiResponse
  return http.get<SubscriberLookupApiResponse[]>(`/workspaces/registrationOnly/invitation-subscribers/${encodedName}?jurisdiction=${HttpTypes.JurisdictionsEnum.VIC}`);
}

export default React.memo(RecipientSubscriberLookup);
