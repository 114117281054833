import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { sdk } from 'src/utils/http';
import { actionFetchProfileUserReportsPermissions } from '../actions/profileUserReportsPermissions';

const fetchUserReportsPermissionsDetail = (userId: string): Promise<HttpTypes.SettingsUserReportPermissionsApiResponse> => {
  return sdk.settings.user.retrieveReportPermissions(userId);
};

function* sagaFetchProfileUserReportsPermissionsDetail(action: ReturnType<typeof actionFetchProfileUserReportsPermissions.request>) {
  try {
    const data = yield* call(fetchUserReportsPermissionsDetail, action.payload);

    yield put(actionFetchProfileUserReportsPermissions.success({ data }));
  } catch (error) {
    yield put(actionFetchProfileUserReportsPermissions.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchProfileUserReportsPermissions.request, sagaFetchProfileUserReportsPermissionsDetail)
];
