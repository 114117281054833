import { columnFormatterCheck, ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { IconLinked } from 'src/components/icons';
import { doubleRowPrimarySecondaryFormatter } from 'src/containers/dashboard/shared/formatters';
import { FinancialRoleMapping } from 'src/models/roles';
import { modelKey } from 'src/utils/formUtils';
import { LinkedWorkspaceTableModel } from '../models';
import { LinkedParticipantRow } from './LinkingParticipantCard';

const fieldName = modelKey<LinkedParticipantRow>();
const cf = columnFormatterCheck<LinkedParticipantRow>();

export const getLinkedParticipantRows = (
  //
  linkedWorkspaceDetails: LinkedWorkspaceTableModel[],
  rowDetailIndex?: number
): LinkedParticipantRow[] => {
  if (rowDetailIndex === undefined) {
    return [];
  }

  const selectedLinkedWorkspace = linkedWorkspaceDetails[rowDetailIndex];
  const rowCardDetails: LinkedParticipantRow[] = [];

  // the sequence is always from 0 as an array but we start from 1
  if (selectedLinkedWorkspace.sourceFundLinkedBy) {
    rowCardDetails.push({
      linkedType: 'Source',
      linkingSubscriberName: selectedLinkedWorkspace.sourceFundLinkedBy.subscriberDisplayName,
      linkingSubscriberRole: selectedLinkedWorkspace.sourceFundLinkedBy.workspaceRole.toString(),
      linkedFromOrder: rowDetailIndex,
      linkedToOrder: rowDetailIndex + 1
    } as LinkedParticipantRow);
  }

  if (selectedLinkedWorkspace.paymentLinkedBy) {
    rowCardDetails.push({
      linkedType: 'Payment',
      linkingSubscriberName: selectedLinkedWorkspace.paymentLinkedBy.subscriberDisplayName,
      linkingSubscriberRole: selectedLinkedWorkspace.paymentLinkedBy.workspaceRole.toString(),
      linkedFromOrder: rowDetailIndex + 1,
      linkedToOrder: rowDetailIndex + 2
    } as LinkedParticipantRow);
  }

  return rowCardDetails;
};

export const getLinkedParticipantColumns = (): ColumnsModel<LinkedParticipantRow> => {
  return {
    link: {
      label: 'LINK',
      name: fieldName('linkedType'),
      verticalAlign: 'top',
      thClassName: 'w-[80px]',
      formatter: linkTypeFormatter
    },
    linkingSubscriber: {
      label: 'LINKING SUBSCRIBER',
      name: fieldName('linkingSubscriberName'),
      verticalAlign: 'top',
      thClassName: 'w-[104px]',
      get: ({ linkingSubscriberName: primary, linkingSubscriberRole }: LinkedParticipantRow) => ({ primary, secondary: FinancialRoleMapping[linkingSubscriberRole] }),
      formatter: cf<{
        //
        primary: LinkedParticipantRow['linkingSubscriberName'];
        secondary?: LinkedParticipantRow['linkingSubscriberRole'];
      }>(doubleRowPrimarySecondaryFormatter)
    },
    workspaces: {
      label: 'WORKSPACES',
      name: 'LINKED WORKSPACES',
      verticalAlign: 'top',
      thClassName: 'w-[90px]',
      formatter: linkedWorkspaceChainFormatter
    }
  };
};

function linkedWorkspaceChainFormatter({ row }: FormatterInputModel<LinkedParticipantRow>) {
  return (
    <div className="text-[12px] leading-[14px]">
      <div className="font-[700] text-[var(--neutral-1000)]">
        {row.linkedFromOrder} <IconLinked style={{ verticalAlign: 'bottom', margin: '0px 16px' }} /> {row.linkedToOrder}
      </div>
    </div>
  );
}

export function linkTypeFormatter({ row }: FormatterInputModel<LinkedParticipantRow>) {
  return (
    <div className="text-[12px] leading-[14px]">
      <div className="truncated font-[700] text-[var(--neutral-1000)]">{row.linkedType}</div>
    </div>
  );
}
