import _xor from 'lodash-es/xor';

import { PartyFormConfig, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/enums';
import { RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartySourceModel, NameChangeConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, MergeFailedReasonsEnum, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { PartyAdjuster } from 'src/containers/documents/partyAdjuster';
import { ResolvePartyConversionArgs } from '../../components/party-book/models';
import { TitleReferenceModel } from '../../components/title-references/models';
import { BaseTransfer2_24_1Model } from './models';

export function generateTransferors<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  formModel: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>,
  partyFormConfig: PartyFormConfig<TNameChange>,
  nameChangeConversion: NameChangeConversion<TDocumentPartyJustification, TNameChange>,
  convertPartiesToFormModel: (
    resolvePartyConversion: ResolvePartyConversionArgs<TDocumentPartyJustification, TNameChange>,
    parties: ApiDocumentPartyModel<TDocumentPartyJustification>[],
    relinquishingIds?: string[],
    dataSourceToNameMap?: Map<DataSource, string>
  ) => PartyModel<TNameChange>[]
): BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange> {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Subscriber || (pb.metadata?.source === DataSource.Custom && !pb.metadata?.partyBookId));
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    return { ...formModel, transferors: { tenancyType: TenancyTypeEnum.None, proprietorGroups: [] } };
  }

  const { transferorsParties, transferors, mergeFailedReason } = resolveTransferors<TPartLandAffectedModel, TDocumentPartyJustification>(selectedTitles);

  const partyBook = convertPartiesToFormModel(
    { partyFormConfig: partyFormConfig, nameChangeConversion: nameChangeConversion },
    transferorsParties,
    transferorsParties.map(p => p.id!)
  ).concat(customParties);

  const transferorsOnTitle = calculateTransferorsOnTitle(transferorsParties, transferors, parties =>
    convertPartiesToFormModel(
      { partyFormConfig: partyFormConfig, nameChangeConversion: nameChangeConversion },
      parties,
      parties.map(p => p.id!)
    )
  );

  return {
    ...formModel,
    partyBook,
    partyBookApi: transferorsParties,
    transferors,
    transferees: PartyAdjuster.adjustTenancyDetail(formModel.transferees, partyBook, formModel.partyBook),
    mergeFailedReason,
    transferorsOnTitle,
    precedingData: {
      ...formModel.precedingData,
      titlesUsed: selectedTitles
        .map(title => title.reference!)
        .filter(titleReference => Boolean(formModel.precedingData.newProprietors.find(np => np.proprietorsSource.titles.includes(titleReference)))),
      dataChanged: formModel.precedingData?.dataChanged ?? false,
      sourceChanged: false,
      manualOverride: false
    }
  };
}

export function resolveTransferors<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification>( //
  selectedTitles: TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>[]
): { transferorsParties: ApiDocumentPartyModel<TDocumentPartyJustification, {}>[]; transferors: RelinquishingTenancyDetailModel; mergeFailedReason?: MergeFailedReasonsEnum } {
  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(
            pg =>
              new ProprietorGroup(
                pg.parties.map(({ party, partyCapacity }) => ({ ...party, partyCapacity })),
                pg,
                pg.shareFraction,
                pg.proprietorGroupType
              )
          ),
          title.proprietor.tenancyType
        )
      )
  );

  const result = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiPartySourceModel<TDocumentPartyJustification>>[] = result.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties.map(p => ({ party: p, partyCapacity: p.partyCapacity })),
      isSelected: true,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = result.failedReason === MergeFailedReasonsEnum.StructureNotSame ? TenancyTypeEnum.None : result.containers[0]?.tenancyDetail.tenancyType!;
  const transferors: RelinquishingTenancyDetailModel = {
    tenancyType: tenancyType,
    proprietorGroups: groups.map((pg: ProprietorGroupModel<ApiPartySourceModel<TDocumentPartyJustification>>): RelinquishingProprietorGroupModel => {
      return {
        ...pg,
        parties: pg.parties.map(p => ({ isSelected: true, partyBookId: p.party.id!, partyCapacity: p.partyCapacity ?? {} })),
        isSelected: groups.length === 1 || pg.isSelected,
        shareFraction: pg.shareFraction,
        shareTransferred: ShareTransferredTypeEnum.Whole
      };
    })
  };

  const transferorsParties = groups.flatMap(pg => pg.parties.map(p => p.party));
  return { transferorsParties, transferors, mergeFailedReason: result.failedReason };
}

export function isTransferorsResetTenancyAvailable<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  //
  partyBook,
  transferors,
  transferorsOnTitle
}: {
  partyBook: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBook'];
  transferors: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['transferors'];
  transferorsOnTitle: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['transferorsOnTitle'];
}): boolean {
  if (transferorsOnTitle === undefined) return false;
  const displayedPartyBookIds = transferors.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedPartyBookIds, transferorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  const displayedParties: PartyModel<TNameChange>[] = displayedPartyBookIds.map(id => partyBook.find(p => p.id === id)).filter(Boolean) as PartyModel<TNameChange>[];

  //* 2. has any tenant been updated by the user?
  if (displayedParties.some(p => !transferorsOnTitle.isOriginal(p))) {
    return true;
  }

  //* 3. has tenancy structure been changed?
  if (transferorsOnTitle.transferors.proprietorGroups.length !== transferors.proprietorGroups.length) {
    return true;
  }

  const tenancyStructureChanged = transferors.proprietorGroups.some((pg, i) => {
    const pgOnTitle = transferorsOnTitle.transferors.proprietorGroups[i];
    if (pg.parties.length !== pgOnTitle.parties.length) {
      return true;
    }
    const partyOrderDoesNotMatch = pg.parties.some((p, j) => {
      return pgOnTitle.parties[j].partyBookId !== p.partyBookId;
    });
    return partyOrderDoesNotMatch;
  });

  return tenancyStructureChanged;
}

export function calculateTransferorsOnTitle<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(
  apiProprietors: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['partyBookApi'],
  transferors: BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['transferors'],
  convertPartiesToFormModel: (parties: ApiDocumentPartyModel<TDocumentPartyJustification>[]) => PartyModel<TNameChange>[]
): BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>['transferorsOnTitle'] {
  const partyBook: PartyModel<TNameChange>[] = convertPartiesToFormModel(apiProprietors);
  const partyBookIds: string[] = partyBook.map(p => p.id);
  // explicitly ignore name change in reset tenancy check
  const partyNamesMap: Map<string, string> = new Map<string, string>(partyBook.map(p => [p.id, p.legalEntityName!]));
  return {
    partyBook,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }
      return partyNamesMap.get(party.id) === party.legalEntityName!;
    },
    transferors
  };
}
