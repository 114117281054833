import { HttpTypes } from '@sympli/api-gateway/types';

export const LinkedWorkspaceOverallStatusEnumMap: Record<HttpTypes.LinkedWorkspaceOverallStatusEnum, string> = {
  [HttpTypes.LinkedWorkspaceOverallStatusEnum.Ready]: 'READY',
  [HttpTypes.LinkedWorkspaceOverallStatusEnum.NotReady]: 'NOT READY'
};

export interface UserProfileDetail {
  userId: string;
  subscriberId: string;
  groupIds: string[];
}
export interface ParticipantsHaveAccessToLinkedWorkspace extends HttpTypes.LinkedWorkspaceParticipant {
  workspaceId: string;
}

export const LinkedWorkspaceTypeMapping: Record<HttpTypes.LinkedWorkspaceTypeEnum, string> = {
  [HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement]: 'Linked Settlement',
  [HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement]: 'Linked Lodgement'
};
