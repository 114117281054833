import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { NoticeOfDeath2_21_1Model } from './models';
import DeceasedJointTenants from './sections/deceased-joint-tenants';
import SurvivingJointTenants from './sections/surviving-joint-tenants';
import TitleReferences from './sections/title-references';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<NoticeOfDeath2_21_1Model>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const formikProps = useFormikContext<NoticeOfDeath2_21_1Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const sectionErrorMessage = getFormikError(formikProps, fieldName('survivingJointTenants'), true);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
        />

        <DeceasedJointTenants //
          name={fieldName('deceasedJointTenants')}
        />

        <Section error={sectionErrorMessage} title="Surviving joint tenants" data-name={fieldName('survivingJointTenants')}>
          <SurvivingJointTenants name={fieldName('survivingJointTenants')} />
        </Section>

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
