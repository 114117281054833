import * as React from 'react';

import classNames from 'classnames';

import { ColumnsModel, TableV2 } from '@sympli/ui-framework/components/table';

import { useCardDetailStyles, useCardStyles } from './styles';

export function CardDetail<T extends object>({
  //
  rows,
  columns,
  pageSize,
  isLoading,
  noHeader,
  className,
  noDataDisplay
}: {
  columns: ColumnsModel<T>;
  rows: T[];
  pageSize?: number;
  isLoading?: boolean;
  noHeader?: boolean;
  className?: string;
  noDataDisplay?: string | JSX.Element | undefined;
}) {
  const classes = useCardDetailStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <TableV2 //
        rows={rows}
        columns={columns}
        noDataMessage={noDataDisplay}
        noFooter
        noHeader={noHeader}
        padding="none"
        pageSize={pageSize}
        isLoading={isLoading}
      />
    </div>
  );
}

function Card(props: React.PropsWithChildren<{}>) {
  const classes = useCardStyles();

  return <div className={classes.root}>{props.children}</div>;
}

export default Card;
