import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { actionFetchWorkspaceDocuments, actionUpdateWorkspaceTitleActivity, UpdateWorkspaceTitleActivityRequestModel } from 'src/store/actions/workspace';
import { actionFetchTitleResupplyCost, actionLaunchTitleActivityCheck, LaunchTitleActivityCheckRequestModel, ResupplyTitleInfoCostApiRequest } from './actions';

function launchTitleActivityCheck(args: LaunchTitleActivityCheckRequestModel) {
  const { participantId, workspaceId, titleReference } = args;
  return endpoints.checkTitleActivity({ participantId, workspaceId }, { titleReference });

  // const svc = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/title-references/check-activity`;
  // return http.post<TitleActivityApiResponse>(svc, { titleReference });
}

function* sagaLaunchTitleActivityCheck(action: ReturnType<typeof actionLaunchTitleActivityCheck.request>) {
  try {
    const data: HttpTypes.CheckTitleActivityApiResponse = yield* call(launchTitleActivityCheck, action.payload);

    const payload: UpdateWorkspaceTitleActivityRequestModel = {
      reference: action.payload.titleReference,
      workspaceId: action.payload.workspaceId,
      titleActivityCheckResult: data
    };

    if (data.hasChanges) {
      // WEB-20889 - refresh documents list
      yield put(actionFetchWorkspaceDocuments.request({ workspaceId: action.payload.workspaceId, participantId: action.payload.participantId }));
    }

    yield put(actionUpdateWorkspaceTitleActivity(payload));
    yield put(actionLaunchTitleActivityCheck.success());
  } catch (error) {
    yield put(actionLaunchTitleActivityCheck.failure({ error }));
  }
}

function fetchResupplyTitleCost(args: ResupplyTitleInfoCostApiRequest) {
  return endpoints.getLandRegistryInformationResupplyCost(args);
}

function* sagaFetchResupplyTitleCost(action: ReturnType<typeof actionFetchTitleResupplyCost.request>) {
  try {
    const data: HttpTypes.GetLandRegistryInformationResupplyCostApiResponse = yield* call(fetchResupplyTitleCost, action.payload);
    yield put(actionFetchTitleResupplyCost.success({ data }));
  } catch (error) {
    yield put(actionFetchTitleResupplyCost.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionLaunchTitleActivityCheck.request, sagaLaunchTitleActivityCheck),
  takeLatest(actionFetchTitleResupplyCost.request, sagaFetchResupplyTitleCost)
];
