import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchBillingInvoicing } from '../actions/billingInvoicing';

export interface BillingInvoicingState {
  detail?: HttpTypes.BillingInvoicingApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: BillingInvoicingState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  BillingInvoicingState,
  Action
>(initialState)
  .handleAction(actionFetchBillingInvoicing.request, (state): BillingInvoicingState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchBillingInvoicing.success, (state, action): BillingInvoicingState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchBillingInvoicing.failure, (state, action): BillingInvoicingState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
