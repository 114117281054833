import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import StatusBadge from '@sympli/ui-framework/components/status-badge';
import { IconCheck, IconError, IconLock } from '@sympli/ui-framework/icons';

import { getLodgementCaseStatusEnumMapping } from 'src/utils/status-mapping/workspaceStatusHelper';

interface Props extends React.AriaAttributes {
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  size?: 'small' | 'medium';
}

function LodgementOnlyWorkspaceStatusDefaultBadge({
  //
  lodgementCaseStatusId,
  size = 'small',
  ...ariaRoles
}: Props) {
  const label = getLodgementCaseStatusEnumMapping(size)[lodgementCaseStatusId];

  switch (lodgementCaseStatusId) {
    case HttpTypes.LodgementCaseStatusEnum.LodgementError:
      return (
        <StatusBadge //
          color="error"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    case HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError:
    case HttpTypes.LodgementCaseStatusEnum.LodgementTechnicalError:
      return (
        <StatusBadge //
          color="primary"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    case HttpTypes.LodgementCaseStatusEnum.LodgementRequested:
      return (
        <StatusBadge //
          color="secondary"
          variant="outlined"
          icon={<IconLock />}
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    case HttpTypes.LodgementCaseStatusEnum.LodgementSuccess:
      return (
        <StatusBadge //
          color="info"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    case HttpTypes.LodgementCaseStatusEnum.Unnecessary:
    case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
    case HttpTypes.LodgementCaseStatusEnum.Rejected:
      return (
        <StatusBadge //
          color="secondary"
          icon={<IconError />}
          iconColor="error"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    case HttpTypes.LodgementCaseStatusEnum.Registered:
      return (
        <StatusBadge //
          color="secondary"
          icon={<IconCheck />}
          iconColor="primary"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
    default:
      return (
        <StatusBadge //
          color="secondary"
          variant="outlined"
          size={size}
          label={label}
          {...ariaRoles}
        />
      );
  }
}

export default React.memo(LodgementOnlyWorkspaceStatusDefaultBadge);
