import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { WithdrawalOfCaveat2_28_0Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<WithdrawalOfCaveat2_28_0Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  caveats: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        dealingNumber: NO_FALLBACK,
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isSelected: [false],
              reference: NO_FALLBACK,
              isPartLandAffected: NO_FALLBACK,
              landDescriptions: NO_FALLBACK
            }
          }
        },
        caveators: NO_FALLBACK
      }
    }
  },
  withdrawCaveats: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        dealingNumber: NO_FALLBACK,
        titleReferences: {
          $shouldAddDefaultItemToEmptyArray: true,
          $arrayItem: {
            $children: {
              isSelected: [false],
              reference: NO_FALLBACK,
              isPartLandAffected: NO_FALLBACK,
              landDescriptions: NO_FALLBACK
            }
          }
        },
        caveators: NO_FALLBACK
      }
    }
  },
  caveators: NO_FALLBACK,
  titleReferences: {
    $shouldAddDefaultItemToEmptyArray: false,
    $arrayItem: {
      $children: {
        isSelected: [false],
        reference: NO_FALLBACK,
        landDescriptions: NO_FALLBACK,
        isPartLandAffected: [false]
      }
    }
  },
  partyBook: NO_FALLBACK
};
