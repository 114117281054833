import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useFeatureFlag } from 'src/hooks';
import { financialWorkspaceRoles } from 'src/models/roles';
import { resolveWorkspaceTypeOptions } from './helper';

export const useWorkspaceTypeOptions = (): LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[] => {
  const roles: HttpTypes.SubscriberWorkspaceRoleModel[] | undefined = useProfile().data?.subscriberWorkspaceRoles;
  const isFinanceEnabled = useFeatureFlag(FeatureToggleEnum.financialSettlementsEnabled);
  let WORKSPACE_TYPE_OPTIONS: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>[] = [];

  if (!roles) return WORKSPACE_TYPE_OPTIONS;

  const enabledRoles: HttpTypes.ClientWorkspaceRoleEnum[] = roles.filter(role => role.isEnabled).map(role => role.workspaceRole);
  const enabledRolesSet = new Set<HttpTypes.ClientWorkspaceRoleEnum>(enabledRoles);

  const financial = isFinanceEnabled && financialWorkspaceRoles.some(role => enabledRolesSet.has(role));

  WORKSPACE_TYPE_OPTIONS = resolveWorkspaceTypeOptions(financial);

  return WORKSPACE_TYPE_OPTIONS;
};
