import React from 'react';

import { FieldArray, getIn, useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import Mortgagee from '../../components/mortgagee';
import { DischargeOfMortgage2_28_0Model, MortgageePartyModel } from '../../models';

interface Props {
  name: string;
  disabled: boolean;
  className?: string;
}

function SectionMortgagees({ name, disabled }: Props): JSX.Element {
  const formikProps = useFormikContext<DischargeOfMortgage2_28_0Model>();
  const { values, errors } = formikProps;
  const items = _get(values, name) ?? [];
  const count = items.length;
  const errorMessage = getIn(errors, name);

  return (
    <Section //
      title="Mortgagee"
      data-name={name}
      error={errorMessage}
    >
      <FieldArray //
        name={name}
        render={() => items.map((item, itemIndex) => renderItem(name, item, itemIndex, count, disabled))}
      />
    </Section>
  );
}

function renderItem(name: string, item: MortgageePartyModel, itemIndex: number, count: number, disabled) {
  const hasMultipleItems = count > 1;
  const itemBindingPath = `${name}[${itemIndex}]`;
  const titlePrefix = 'Mortgagee';
  const title = hasMultipleItems ? `${titlePrefix} ${itemIndex + 1}` : titlePrefix;

  return (
    <FormGroup title={title} fieldAreaDirection="column" key={`item-mortgagees[${itemIndex}]`}>
      <Mortgagee //
        name={itemBindingPath}
        disabled={disabled}
      />
    </FormGroup>
  );
}

export default React.memo(SectionMortgagees);
