import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/containers/workspace/shared/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';

interface Props {
  steps: Array<string>;
}

function InPreparationFinancialSettlementSummary(props: Props) {
  const { steps } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        in preparation
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant is <b>actively working</b> on their financials.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        When everything is ready the status will change to ‘Approved’.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={steps}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

export default React.memo(InPreparationFinancialSettlementSummary);
