import * as yup from 'yup';

import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import { yupDeceasedTenancyDetailSchema } from '../../components/deceased-tenancy-detail/validationSchema';
import { resolvePartyRoleCapacitySchema } from '../../components/party-role-capacity/validationSchema';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS, APPLICANT_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from './models';
import yupApplicants from './sections/applicants/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import { yupTitleReferences } from './sections/title-references/validationSchema';

export default yup
  .object<TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model>()
  .partial({
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema,
    titleReferences: yupTitleReferences,
    applicantCapacity: resolvePartyRoleCapacitySchema(APPLICANT_CAPACITY_LOOKUP_OPTIONS),
    partyBook: yupPartyBook,
    applicants: yupApplicants,
    attachments: memoizeSchema(yupAdditionalAttachments(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS)),
    // UI only properties
    invalidParties: yup.mixed<boolean>(),
    partyBookApi: yup.mixed(),
    context: yup.mixed()
  })
  .defined()
  .log();
