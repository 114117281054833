import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { sdk } from 'src/utils/http';
import { actionFetchNotificationsSettings } from '../actions/notificationsSettings';

const fetchNotificationsSettings = (): Promise<HttpTypes.NotificationSettingsApiResponse> => {
  // return http.get<HttpTypes.NotificationSettingsApiResponse>('/settings/subscriber/preferences/notification-settings');
  return sdk.settings.subscriber.retrieveNotificationPreferences();
};

function* sagaFetchNotificationsSettings() {
  try {
    const data = yield* call(fetchNotificationsSettings);
    yield put(actionFetchNotificationsSettings.success({ data }));
  } catch (error) {
    yield put(actionFetchNotificationsSettings.failure({ error }));
  }
}

export default [takeLatest(actionFetchNotificationsSettings.request, sagaFetchNotificationsSettings)];
