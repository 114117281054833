import makeStyles from '@mui/styles/makeStyles';

export const useSmallScreenStyles = makeStyles(
  () => ({
    root: {
      '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
        paddingRight: 10
      },
      '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
        paddingRight: 10
      }
    }
  }),
  {
    name: 'LinkedWorkspaceTable'
  }
);
