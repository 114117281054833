import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useProfile } from 'src/@core/store/reducers/profile';
import { useWorkspaceAssignableGroups, WorkspaceAssignableGroupsState } from 'src/@core/store/reducers/workspaceAssignableGroups';
import { SupportedJurisdictionsState } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { useStoreSelector } from 'src/hooks';
import useInteroperable from '../../useInteroperable';
import AboutYourMatterSection from './AboutYourMatterSection';

interface Props {
  onGroupFieldChange: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => void;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
}

function AboutYourMatterSectionContainer(props: Props) {
  const { workspaceTypeId } = props;
  const assignGroupsState: WorkspaceAssignableGroupsState = useWorkspaceAssignableGroups();
  const supportedJurisdictionsState: SupportedJurisdictionsState = useStoreSelector(store => store.supportedJurisdictions);
  const subscriberProfile: HttpTypes.UserProfileModel = useProfile().data!;
  const isInteroperable = useInteroperable(workspaceTypeId);

  return (
    <AboutYourMatterSection //
      {...props}
      assignGroupsState={assignGroupsState}
      supportedJurisdictionsState={supportedJurisdictionsState}
      subscriberProfile={subscriberProfile}
      isInteroperable={isInteroperable}
    />
  );
}

export default React.memo(AboutYourMatterSectionContainer);
