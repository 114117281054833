import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { BusinessLogicError, SeverityEnum } from '@sympli/ui-logger';

import { WorkspaceStatusColorVariantEnum } from 'src/utils/status-mapping/workspaceStatusHelper';
import Completed from './components/tooltip-contents/completed/Completed';
import InPreparationLodgementFailed from './components/tooltip-contents/in-preparation-lodgement-failed/InPreparationLodgementFailed';
import InPreparationUnverifiedFinancial from './components/tooltip-contents/in-preparation-unverified-financial/InPreparationUnverifiedFinancial';
import InPreparationUnverifiedRegistrationOnly from './components/tooltip-contents/in-preparation-unverified-registration-only/InPreparationUnverifiedRegistrationOnly';
import InPreparationVerificationFailedBothDocs from './components/tooltip-contents/in-preparation-verification-failed-both-docs/InPreparationVerificationFailedBothDocs';
import InPreparationVerificationFailedMyDocs from './components/tooltip-contents/in-preparation-verification-failed-my-docs/InPreparationVerificationFailedMyDocs';
import InPreparationVerificationFailedOtherDocs from './components/tooltip-contents/in-preparation-verification-failed-other-docs/InPreparationVerificationFailedOtherDocs';
import Lodged from './components/tooltip-contents/lodged/Lodged';
import Lodging from './components/tooltip-contents/lodging/Lodging';
import PreparedFinancial from './components/tooltip-contents/prepared-financial/PreparedFinancial';
import PreparedRegistrationOnly from './components/tooltip-contents/prepared-registration-only/PreparedRegistrationOnly';
import ReadyFinancial from './components/tooltip-contents/ready-financial/ReadyFinancial';
import ReadyRegistrationOnly from './components/tooltip-contents/ready-registration-only/ReadyRegistrationOnly';
import Verifying from './components/tooltip-contents/verifying/Verifying';
import { LodgementCaseStatusSteps, StatusProgressModel } from './models';

export function resolveLodgementCaseStepAndVariant({
  lodgementCaseStatusId,
  allDocumentsSigned,
  workspaceStatusId
}: {
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  allDocumentsSigned: boolean;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
}): Omit<StatusProgressModel, 'tooltipContent'> | null {
  switch (lodgementCaseStatusId) {
    case HttpTypes.LodgementCaseStatusEnum.Unverified: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested: {
      if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementInProgress) {
        return {
          step: LodgementCaseStatusSteps.Ready,
          variant: WorkspaceStatusColorVariantEnum.Success
        };
      }

      if (allDocumentsSigned) {
        return {
          step: LodgementCaseStatusSteps.Prepared,
          variant: WorkspaceStatusColorVariantEnum.Warning
        };
      }

      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Failure
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationSuccess:
    case HttpTypes.LodgementCaseStatusEnum.LodgementTechnicalError:
    case HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError: {
      if (allDocumentsSigned || workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementInProgress) {
        return {
          step: LodgementCaseStatusSteps.Ready,
          variant: WorkspaceStatusColorVariantEnum.Success
        };
      }

      return {
        step: LodgementCaseStatusSteps.Prepared,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementError: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Failure
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementRequested: {
      return {
        step: LodgementCaseStatusSteps.Lodging,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementSuccess: {
      return {
        step: LodgementCaseStatusSteps.Lodged,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.Registered:
    case HttpTypes.LodgementCaseStatusEnum.Rejected:
    case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
    case HttpTypes.LodgementCaseStatusEnum.Unnecessary: {
      return {
        step: LodgementCaseStatusSteps.Completed,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.AwaitingInvitee: {
      // Intentionally returning null as this status should never be shown on workspace.
      // Only used in a linked workspace where invited participant hasn't accepted.
      return null;
    }
    default: {
      const scope = Logger.scopeWithCustomAttributes({
        lodgementCaseStatus: lodgementCaseStatusId
      });
      Logger.console(SeverityEnum.Error, `Unmapped lodgement case ${lodgementCaseStatusId}`);
      Logger.captureException(new BusinessLogicError('Unmapped lodgement case status'), scope);
      return null;
    }
  }
}

export function resolveLodgementCaseStatus({
  lodgementCaseStatusId,
  documents,
  workspaceTypeId,
  workspaceStatusId,
  participantId,
  lodgementDetail,
  isSLC
}: {
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  documents: HttpTypes.WorkspaceDocumentSummary[];
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  participantId: string;
  lodgementDetail?: HttpTypes.LodgementDetail;
  isSLC: boolean;
}): StatusProgressModel | null {
  const allDocumentsSigned: boolean = documents.every(d => d.isFullySignedByAllParticipants);

  const lcInfo: Omit<StatusProgressModel, 'tooltipContent'> | null = resolveLodgementCaseStepAndVariant({
    lodgementCaseStatusId,
    allDocumentsSigned,
    workspaceStatusId
  });
  if (!lcInfo) {
    return null;
  }

  const { step, variant } = lcInfo;

  switch (lodgementCaseStatusId) {
    case HttpTypes.LodgementCaseStatusEnum.Unverified: {
      return {
        step,
        variant,
        tooltipContent:
          workspaceTypeId === HttpTypes.WorkspaceTypeEnum.FinancialSettlement && !isSLC ? <InPreparationUnverifiedFinancial /> : <InPreparationUnverifiedRegistrationOnly />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested: {
      return {
        step,
        variant,
        tooltipContent: <Verifying />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError: {
      let tooltipContent = <InPreparationVerificationFailedMyDocs lodgementDetail={lodgementDetail!} />;
      if (workspaceTypeId === HttpTypes.WorkspaceTypeEnum.FinancialSettlement) {
        const allDocuments = documents.flatMap(({ documentParticipants, lodgementDetail, supportingDocuments = [] }) => [
          {
            documentParticipants,
            lodgementDetail
          },
          ...supportingDocuments.map(({ documentParticipants: sdParticipants, lodgementDetail: sdLodgeDetail }) => ({
            documentParticipants: sdParticipants,
            lodgementDetail: sdLodgeDetail
          }))
        ]);

        const documentsWithComplianceErrors = allDocuments.filter(d =>
          d.lodgementDetail?.documentLodgementCompliances.some(dlc => dlc.complianceLevel === HttpTypes.ComplianceLevelEnum.Error)
        );
        const myDocsWithComplianceErrors = documentsWithComplianceErrors.filter(x => Boolean(x.documentParticipants.filter(dc => dc.id === participantId).length));
        const otherDocsWithComplianceErrors = documentsWithComplianceErrors.filter(x => Boolean(x.documentParticipants.filter(dc => dc.id !== participantId).length));

        if (myDocsWithComplianceErrors.length && otherDocsWithComplianceErrors.length) {
          tooltipContent = <InPreparationVerificationFailedBothDocs lodgementDetail={lodgementDetail!} />;
        } else if (otherDocsWithComplianceErrors.length) {
          tooltipContent = <InPreparationVerificationFailedOtherDocs lodgementDetail={lodgementDetail!} />;
        }
      }
      return {
        step,
        variant,
        tooltipContent
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationSuccess:
    case HttpTypes.LodgementCaseStatusEnum.LodgementTechnicalError:
    case HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError: {
      if (allDocumentsSigned) {
        return {
          step,
          variant,
          tooltipContent:
            workspaceTypeId === HttpTypes.WorkspaceTypeEnum.FinancialSettlement ? (
              <ReadyFinancial lodgementDetail={lodgementDetail!} />
            ) : (
              <ReadyRegistrationOnly lodgementDetail={lodgementDetail!} />
            )
        };
      }

      return {
        step,
        variant,
        tooltipContent:
          workspaceTypeId === HttpTypes.WorkspaceTypeEnum.FinancialSettlement ? (
            <PreparedFinancial lodgementDetail={lodgementDetail!} />
          ) : (
            <PreparedRegistrationOnly lodgementDetail={lodgementDetail!} />
          )
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementRequested: {
      return {
        step,
        variant,
        tooltipContent: <Lodging />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementSuccess: {
      return {
        step,
        variant,
        tooltipContent: <Lodged />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.LodgementError: {
      return {
        step,
        variant,
        tooltipContent: <InPreparationLodgementFailed lodgementDetail={lodgementDetail!} />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.Registered:
    case HttpTypes.LodgementCaseStatusEnum.Rejected:
    case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
    case HttpTypes.LodgementCaseStatusEnum.Unnecessary: {
      return {
        step,
        variant,
        tooltipContent: <Completed />
      };
    }
    case HttpTypes.LodgementCaseStatusEnum.AwaitingInvitee: {
      // Intentionally returning null as this status should never be shown on workspace.
      // Only used in a linked workspace where invited participant hasn't accepted.
      return null;
    }
    default: {
      const scope = Logger.scopeWithCustomAttributes({
        lodgementCaseStatus: lodgementCaseStatusId
      });
      Logger.console(SeverityEnum.Error, `Unmapped lodgement case ${lodgementCaseStatusId}`);
      Logger.captureException(new BusinessLogicError('Unmapped lodgement case status'), scope);
      return null;
    }
  }
}
