import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import TextTruncator from '@sympli/ui-framework/components/layout/text-truncator';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { IconError } from '@sympli/ui-framework/icons';

import { UserAvatar } from 'src/components/avatars';
import WorkspaceFileManageButtons from '../workspace-file-manage-buttons/WorkspaceFileManageButtons';
import { downloadFile } from './api';
import { WorkspaceFileStatusEnum } from './enums';
import { hasError, hasVirusOrScript } from './helpers';
import { WorkspaceFile } from './models';
import { useStyles } from './styles';

export interface Props {
  workspaceId: string;
  participantId: string;
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  participants: HttpTypes.WorkspaceParticipant[];
  disabled?: boolean;
  items: WorkspaceFile[];
  documents: HttpTypes.WorkspaceDocumentSummary[];
  isRejected: boolean;
  isLoading: boolean;
  onUploadFile: () => void;
  onEditFile: (file: WorkspaceFile | null) => void;
  onDeleteFile: (file: WorkspaceFile | null) => void;
}

export default function WorkspaceFiles(props: Props) {
  const classes = useStyles();
  const { onUploadFile, onEditFile, onDeleteFile } = props;
  const {
    //
    items,
    isLoading,
    isRejected,
    participants,
    documents,
    workspaceId,
    participantId,
    workspaceTypeId,
    disabled
  } = props;

  const renderFile = (item: WorkspaceFile) => {
    const { id, description, notes, fileName, dateUploaded, uploadedByParticipantId, status, attachedToDocumentId } = item;
    const uploader = participants.find(p => p.id === uploadedByParticipantId);
    const attachedToDocument = documents.find(d => d.documentId === attachedToDocumentId);
    const [fileNameWithoutExtension, fileExtension] = fileName.includes('.') ? [fileName.split('.').slice(0, -1).join('.'), fileName.split('.').slice(-1)] : [fileName, undefined];

    return (
      <FlexLayout flexDirection="column" className={classes.file} justifyContent="space-between" fullWidth key={`file-${id}`}>
        <FlexLayout flexDirection="row">
          <span className={classes.avatarColumn}>{uploader && <UserAvatar src={uploader.avatarUrl} text={uploader.name} size="extraSmall" />}</span>
          <FlexLayout flexDirection="row" className={classes.column}>
            {status === WorkspaceFileStatusEnum.Clean ? (
              <ButtonLink className={classes.descriptionClickable} onClick={() => downloadFile(workspaceId, participantId, workspaceTypeId, item)}>
                {description}
              </ButtonLink>
            ) : (
              <span>{description}</span>
            )}
          </FlexLayout>
          <FlexLayout flexDirection="row" className={classes.column} justifyContent="space-between">
            <span>{dateUploaded}</span>
            <WorkspaceFileManageButtons
              workspaceId={workspaceId}
              participantId={participantId}
              file={item}
              onEditFile={onEditFile}
              onDeleteFile={onDeleteFile}
              disabled={disabled}
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="column">
          {status === WorkspaceFileStatusEnum.Scanning && (
            <span className={classes.fileStatus}>
              <CircularProgress size={14} className={classes.fileStatusIcon} />
              Scanning file. <ButtonLink onClick={() => window.location.reload()}>Refresh page</ButtonLink>
            </span>
          )}
          {hasVirusOrScript(status) && (
            <FlexLayout alignItems="flex-start" className={classNames(classes.fileStatusContainer, classes.error)}>
              <IconError className={classNames(classes.fileStatusIcon, classes.error)} />
              <Typography className={classNames(classes.fileStatusMessage, classes.error)}>File could not be uploaded as it may contain viruses</Typography>
            </FlexLayout>
          )}
          {hasError(status) && (
            <FlexLayout alignItems="flex-start" className={classNames(classes.fileStatusContainer, classes.error)}>
              <IconError className={classNames(classes.fileStatusIcon, classes.error)} />
              <Typography className={classNames(classes.fileStatusMessage, classes.error)}>File was unable to be successfully uploaded. Please retry</Typography>
            </FlexLayout>
          )}
          {notes && <span className={classes.detail}>{notes}</span>}
          <div className={classes.detail}>
            <TextTruncator //
              text={fileNameWithoutExtension}
              truncateAt={50}
              showFullTextAsTooltip={false}
              className={classes.fileName}
            />
            {fileExtension && <span className={classes.fileName}>{`.${fileExtension}`}</span>}
            {attachedToDocument && <span className={classes.uploadedTo}>{`(Uploaded to ${attachedToDocument.documentForm.name})`}</span>}
          </div>
        </FlexLayout>
      </FlexLayout>
    );
  };

  if (isRejected) {
    return (
      <FormHelperText role="alert" error={true}>
        An error occurred while fetching uploaded file details.
      </FormHelperText>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <BlockLoader />
      </div>
    );
  }

  if (items.length) {
    return (
      <>
        <Grid container={true} direction="row">
          <Typography variant="subtitle2" className={classes.column}>
            File name
          </Typography>
          <Typography variant="subtitle2" className={classes.dateUploaded}>
            Date uploaded
          </Typography>
        </Grid>
        {items.map(renderFile)}
      </>
    );
  }

  return (
    <FlexLayout flexDirection="column" alignItems="center" className={classes.noFiles}>
      <Typography className={classes.message}>There are no files that you are able to view.</Typography>
      <ButtonLink className={classes.uploadFile} onClick={onUploadFile} disabled={disabled}>
        Click here to upload a file
      </ButtonLink>
    </FlexLayout>
  );
}
