import * as React from 'react';

import classNames from 'classnames';
import { titleCase } from 'title-case';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

export interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'grey';
  disableAvatar?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class DocumentItemLoader extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    color: 'white',
    disableAvatar: false
  };

  render() {
    const { classes, className, color, disableAvatar, ...other } = this.props;
    const maskerBackgroundClassName = classes[`backgroundMasker${titleCase(color!)}`];
    const disableAvatarClassName = disableAvatar && classes.disableAvatar;

    return (
      <div className={classNames(classes['root'], classes[`background${titleCase(color!)}`], className)} {...other}>
        <div className={classes['animatedBackground']}>
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundIconLeft'])} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundIconRight'])} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundIconBottom'])} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundTitleTop'])} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundTitleBottom'], disableAvatarClassName)} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundTitleAfter'], disableAvatarClassName)} />
          <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundDescriptionAfter'], disableAvatarClassName)} />
          {!disableAvatar && (
            <div className={classNames(classes['backgroundMasker'], maskerBackgroundClassName, classes['backgroundAvatar'], classes[`backgroundAvatar${titleCase(color!)}`])} />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DocumentItemLoader);
