import { Action, createReducer } from 'typesafe-actions';

import { ApiStatus } from 'src/utils/http';
import { actionFetchSubscriberActivityLogsFeed, SubscriberActivityLogsApiRequest } from '../actions/logs';
import { LogModel } from '../log/models';

export interface LogsFeedState {
  items: Record<string, LogModel[]>;
  status: ApiStatus;
  isLoadingMore: boolean;
  error?: string;

  query: SubscriberActivityLogsApiRequest;

  pageNumber: number;
  totalCount: number;
  loadedCount: number;
}

const initialState: LogsFeedState = {
  items: {},
  status: 'idle',
  isLoadingMore: false,

  query: {
    pageSize: 20,
    pageNumber: 1
  },

  pageNumber: 1,
  loadedCount: 0,
  totalCount: 0
};

const reducer = createReducer<
  //
  LogsFeedState,
  Action
>(initialState)
  .handleAction(actionFetchSubscriberActivityLogsFeed.request, (state, action): LogsFeedState => {
    if (action.payload.pageNumber === 1) {
      return {
        ...initialState,
        status: 'pending'
      };
    }

    return {
      ...state,
      query: {
        ...state.query,
        ...action.payload
      },
      error: undefined,
      status: 'resolved',
      isLoadingMore: true
    };
  })
  .handleAction(actionFetchSubscriberActivityLogsFeed.success, (state, action): LogsFeedState => {
    const { query, loadedCount, items: groupedLogs } = state;
    const { items, totalCount } = action.payload.data;

    const newGroupedLogs = items.reduce(
      (result, currentValue) => {
        const { created } = currentValue;
        const dateValue = created.toDateString();
        result[dateValue] = (result[dateValue] || []).concat(currentValue);
        return result;
      },
      { ...groupedLogs }
    );

    return {
      ...initialState,
      query: state.query,
      items: newGroupedLogs,
      pageNumber: query.pageNumber,
      loadedCount: loadedCount + items.length,
      totalCount,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchSubscriberActivityLogsFeed.failure, (_, action): LogsFeedState => {
    return {
      ...initialState,
      error: action.payload.error.message,
      status: 'rejected'
    };
  });

export default reducer;
