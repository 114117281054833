import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { HttpTypes } from '@sympli/api-gateway/types';

import { DateOfDeathModel, DeceasedProprietorModel, EvidenceModel } from '../../../../components/deceased-tenancy-detail/models';

export const DEFAULT_DEATH_OF_DATE: DateOfDeathModel = { type: null, date: null, from: null, to: null, description: '' };
export const DEFAULT_EVIDENCE: EvidenceModel = { date: null, type: null, documentReference: '', defaultAdded: true };

export const filterOrganisationProprietors = (deceasedProprietor: DeceasedProprietorModel, partyBook: PartyModel[]): DeceasedProprietorModel | undefined => {
  const party = partyBook.find(p => p.id === deceasedProprietor.partyBookId);
  return party?.partyType === HttpTypes.PartyTypeEnum.Organisation ? deceasedProprietor : undefined;
};
