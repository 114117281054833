import { createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';

import { BridgeWorkspaceModel } from './models';

// this file was automatically generated from checks.ts.mustache
/**
 path:
 - issuingParty
 
 schema version:
[
  bridgeWorkspace: {
    "workspaceType": 1
  }
]
*/
export const VISIBILITY_CHECK_ISSUING_PARTY_DIRECTION = createContextCheck((context: BridgeWorkspaceModel | null): boolean => {
  if (!context) {
    return true;
  }
  return context!.workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
});
