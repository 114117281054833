import classNames from 'classnames';

function DidNotLoad({ className }: { className?: string }) {
  return (
    <div className={classNames('flex h-[400px] items-center justify-center', className)}>
      <div className="pr-[40px] text-right font-volkswagen-serial-medium font-normal leading-[30px]">
        <div className="text-[25px] text-[var(--neutral-400)]">Sorry, this page didn't load properly</div>
        <div className="text-[20px] text-[var(--sympli-green)]">Please refresh to try again</div>
      </div>
      <svg width="215" height="265" viewBox="0 0 215 265" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.905273 171.817C13.6366 223.264 66.5149 254.44 119.013 241.449C171.511 228.459 203.748 176.221 191.018 124.773L0.905273 171.817Z"
          fill="#FFDF61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.0088 252.88C90.0088 248.223 93.8607 244.448 98.6123 244.448C103.364 244.448 107.216 248.223 107.216 252.88H90.0088Z"
          fill="#FF929B"
        />
        <circle cx="108.72" cy="99.1777" r="44.1523" fill="#E5FFFD" />
        <line x1="158.106" y1="32.0496" x2="169.011" y2="0.505225" stroke="#FF4858" strokeLinecap="round" />
        <line x1="174.297" y1="56.9512" x2="210.445" y2="52.0621" stroke="#FF4858" strokeLinecap="round" />
        <line x1="169.791" y1="42.3973" x2="207.293" y2="8.54343" stroke="#FF4858" strokeLinecap="round" />
        <circle cx="108.722" cy="99.1776" r="11.9364" fill="#72F2EB" />
        <path
          d="M107.354 105.016C107.354 104.586 107.498 104.231 107.784 103.951C108.071 103.665 108.422 103.521 108.839 103.521C109.236 103.521 109.581 103.668 109.874 103.961C110.167 104.254 110.313 104.605 110.313 105.016C110.313 105.413 110.167 105.755 109.874 106.041C109.581 106.321 109.236 106.461 108.839 106.461C108.429 106.461 108.077 106.321 107.784 106.041C107.498 105.755 107.354 105.413 107.354 105.016ZM110.118 92.7598V100.758C110.118 101.103 109.991 101.399 109.737 101.646C109.483 101.894 109.184 102.018 108.839 102.018C108.481 102.018 108.178 101.894 107.931 101.646C107.683 101.399 107.56 101.103 107.56 100.758V92.7598C107.56 92.4017 107.687 92.0957 107.94 91.8418C108.194 91.5879 108.494 91.4609 108.839 91.4609C109.184 91.4609 109.483 91.5879 109.737 91.8418C109.991 92.0957 110.118 92.4017 110.118 92.7598Z"
          fill="#FFFCF0"
        />
      </svg>
    </div>
  );
}

export default DidNotLoad;
