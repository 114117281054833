import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchProfileUserReportsPermissions = createAsyncAction(
  //
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS',
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS_SUCCESS',
  'FETCH_PROFILE_USER_REPORTS_PERMISSIONS_ERROR'
)<
  //
  string,
  { data: HttpTypes.SettingsUserReportPermissionsApiResponse },
  { error: Error }
>();
