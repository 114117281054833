import * as yup from 'yup';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';

import { yupApplicantAddress } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/applicant-address/validationSchema';
import { yupApplicants } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/applicants/validationSchema';
import yupPartyBook from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/party-book/validationSchema';
import { yupTitleReferences } from 'src/containers/documents/scaffolded-form/shared/2-24/transmission-application/sections/title-references/validationSchema';
import { yupDeceasedTenancyDetailSchema } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/validationSchema';
import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSMISSION_DECEASED_TENANCY_VALIDATION_CONFIGURATION, QLD_TRANSMISSION_PARTY_FORM_CONFIG } from './config';
import { TransmissionApplication2_24_0_Model } from './models';

const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

export default yup
  .object<TransmissionApplication2_24_0_Model>()
  .partial({
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema(getLegalEntityNameQLD, false, QLD_TRANSMISSION_DECEASED_TENANCY_VALIDATION_CONFIGURATION),
    titleReferences: yupTitleReferences(true),
    partyBook: yupPartyBook(QLD_TRANSMISSION_PARTY_FORM_CONFIG),
    applicants: yupApplicants(QLD_TRANSMISSION_PARTY_FORM_CONFIG, true, shouldValidatePartyData),
    applicantAddress: yupApplicantAddress
  })
  .defined()
  .log();
