import * as React from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import Section from '@sympli/ui-framework/components/form/base-components/section';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import {
  NSW_CAVEAT_ADDRESS_FORM_CONFIG as ADDRESS_FORM_CONFIG,
  NSW_CAVEAT_PARTY_FORM_CONFIG as PARTY_FORM_CONFIG,
  NSW_CAVEAT_REGISTERED_PROPRIETOR_TENANCY_COMPONENT_LABEL_CONFIG as TENANCY_COMPONENT_LABEL_CONFIG
} from '../../config';
import { generateProprietors, getSelectedTitleReferences, hasCommonProprietors, isProprietorsResetTenancyAvailable } from '../../helpers';
import { CaveatModel_2_21_2 } from '../../models';

type FormValues = CaveatModel_2_21_2;

interface Props {
  name: string;
}

const INCLUDE_CUSTOM_PARTIES = true;

function SectionRegisteredProprietor({ name }: Props): JSX.Element {
  const { values, setValues } = useFormikContext<FormValues>();

  React.useEffect(() => {
    const enableResetTenancyButton = isProprietorsResetTenancyAvailable({
      partyBook: values.partyBook,
      proprietors: values.proprietors,
      proprietorsOnTitle: values.proprietorsOnTitle,
      precedingData: values.precedingData
    });

    if (values.precedingData.manualOverride !== enableResetTenancyButton) {
      setValues(values => {
        return {
          ...values,
          precedingData: { ...values.precedingData, manualOverride: enableResetTenancyButton }
        };
      });
    }
  }, [values.partyBook, values.proprietors, values.proprietorsOnTitle, values.precedingData, setValues]);

  const handleOnTenancyReset = () => {
    setValues(values => {
      return {
        ...generateProprietors(values, !INCLUDE_CUSTOM_PARTIES)
      };
    });
  };

  if (values.proprietors.proprietorGroups.flatMap(p => p.parties).length === 0) {
    const selectedTitles = getSelectedTitleReferences(values.titleReferences);
    const sectionMessage =
      selectedTitles.length === 0
        ? `Please select a title reference to identify the ${TENANCY_COMPONENT_LABEL_CONFIG.partyRole}`
        : `The proprietors on the selected titles do not match. Please review the selected titles.`;

    return (
      <Section //
        title={`${TENANCY_COMPONENT_LABEL_CONFIG.partyRole}s`}
        className="mb-[10px]"
        data-name={name}
      >
        <Typography className="text-sm italic p-5">{sectionMessage}</Typography>
      </Section>
    );
  }

  const warnings =
    !values.precedingData.manualOverride && hasCommonProprietors(values.titleReferences, values.precedingData)
      ? [
          'The proprietors displayed are the common proprietors from the selected titles. Please add a caveat document for each title if you intend to claim against all registered proprietors on a title.'
        ]
      : [];

  return (
    <SectionGenericTenancy //
      name={name}
      partyFormConfig={PARTY_FORM_CONFIG}
      addressFormConfig={ADDRESS_FORM_CONFIG}
      disablePartyEditConfig={disablePartyFieldEditConfig()}
      componentLabels={TENANCY_COMPONENT_LABEL_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
      partySelectionMode="select-when-joint"
      groupSelectionMode="select-all"
      // enable adding new parties
      tenancyPartiesMode="dynamic"
      // enable tenancy reset
      onTenancyReset={values.precedingData.manualOverride ? handleOnTenancyReset : undefined}
      warnings={warnings}
      precedingData={values.precedingData}
    />
  );
}

export default SectionRegisteredProprietor;
