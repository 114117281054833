import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { RescindApprovalModel } from '../../models';
import SettlementDateEditForm from './SettlementDateEditForm';

interface Props {
  workspaceId: string;
  proposedSettlementDate?: string;
  workspaceSettlementDate?: string; // * '2018-04-04T13:00:00'
  expectedSettlementDate?: HttpTypes.ExpectedSettlementDate;
  participantId: string;
  onSettlementDateChange(newWorkspaceSettlementDate: string): void; // notify parent that there was a change in date
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  onRedirect?: (message: string) => void;
  minReschedulingInHours: number;
  reasonId?: number;
  reasonText?: string;
  settlementAcceptedByAll: boolean;
  numberOfParticipants: number;
  declined?: boolean;
  isRollover?: boolean;
  rescindApprovalData: RescindApprovalModel;
}

function SettlementDateEditFormContainer(props: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const calendarYearAndMonth: string = useStoreSelector(store => store.settlementEditForm.calendarYearAndMonth);

  return (
    <SettlementDateEditForm //
      {...props}
      dispatch={dispatch}
      calendarYearAndMonth={calendarYearAndMonth}
      navigate={navigate}
    />
  );
}

export default React.memo(SettlementDateEditFormContainer);
