import * as React from 'react';

import queryString from 'query-string';

import http from '@sympli/http-agent';

import { DocumentPreviewEnhanced } from 'src/components/document-preview';
import { useDocumentDetail } from 'src/containers/documents/reducers/document';
import { DocumentPageRouteParams } from '../../models';

interface Props {
  queryParams: DocumentPageRouteParams;
}

interface WorkspaceDocumentPdfUrlApiResponse {
  pdfUrl?: string;
}

function DocPreviewContainer({ queryParams }: Props) {
  const { detail, status, error } = useDocumentDetail(queryParams.workspaceId, queryParams.participantId, queryParams.documentId);

  const [pdfUrl, setPdfUrl] = React.useState<string | undefined>();

  const getDocumentPdfUrl = (args: { workspaceId: string; participantId: string; documentId: string; pdfS3Key: string }): Promise<WorkspaceDocumentPdfUrlApiResponse> => {
    const query = queryString.stringify({ pdfS3Key: args.pdfS3Key });

    const uri = `/workspaces/${encodeURIComponent(args.workspaceId)}/documents/${encodeURIComponent(args.documentId)}/participants/${encodeURIComponent(args.participantId)}/pdfUrl?${query}`;
    return http.get<WorkspaceDocumentPdfUrlApiResponse>(uri, {
      withCredentials: true
    });
  };

  React.useEffect(() => {
    async function fetchPdfUrl() {
      if (detail?.pdfS3Key) {
        const result = await getDocumentPdfUrl({ ...queryParams, pdfS3Key: detail.pdfS3Key });
        setPdfUrl(result.pdfUrl);
      } else {
        setPdfUrl(undefined);
      }
    }

    fetchPdfUrl();
  }, [detail?.pdfS3Key, queryParams]);

  return (
    <DocumentPreviewEnhanced //
      pdfUrl={pdfUrl}
      error={error?.message}
      status={status}
    />
  );
}

export default React.memo(DocPreviewContainer);
