import React from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import InfoIconWithTooltip from '@sympli/ui-framework/components/form/base-components/info-icon-with-tooltip';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { ISSUING_INSTRUCTION_TYPE_LOOKUP_OPTIONS, IssuingInstructionTypeEnum } from '../../enums';
import { IssuingInstructionsModel, LodgementInstructions_2_28_0 } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
  className?: string;
}

function SectionIssuingInstructions({ name, className }: Props): JSX.Element {
  const { disabled } = useDocumentContext();
  const formikProps = useFormikContext<LodgementInstructions_2_28_0>();

  const { values, setFieldValue } = formikProps;
  const fieldName = createModelKeyAppender<IssuingInstructionsModel>(name);
  const classes = useStyles();
  const handleOnRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFieldValue(fieldName('issuingInstructionType'), Number(value));
  };

  const renderRadioLabel = (item: LookupEnumModel<IssuingInstructionTypeEnum>) => {
    const toolTipContent =
      item.id === IssuingInstructionTypeEnum.RetainOrAcquireEctControl
        ? 'This selection will make your subscriber the title controller after the registration of the documents in the lodgement case.'
        : 'This selection will make the Registrar of Titles the title controller after the registration of the documents in the lodgement case.';
    return (
      <Typography className={classes.radioLabel}>
        {item.name}
        <InfoIconWithTooltip flow="inline" tooltipContent={toolTipContent} />
      </Typography>
    );
  };
  return (
    <FormGroup //
      title="Issuing instructions for eCT after registration"
      fieldAreaDirection="column"
      data-testid={fieldName('issuingInstructionType')}
    >
      <Radio //
        name={fieldName('issuingInstructionType')}
        options={ISSUING_INSTRUCTION_TYPE_LOOKUP_OPTIONS}
        format="number"
        onChange={handleOnRadioButtonChange}
        value={values.issuingInstructions?.issuingInstructionType}
        aria-label="Issuing instruction type"
        label="Select your preference"
        resolveItemLabel={renderRadioLabel}
        classes={{
          formControl: classes.radioFormControl,
          formControlLabel: classes.radioFormControlLabel,
          fieldLabel: classes.radioFieldLabel
        }}
        vertical
        disabled={disabled || !values.issuingInstructions?.editable}
      />
    </FormGroup>
  );
}

export default React.memo(SectionIssuingInstructions);
