import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { FormTenancyDetailModel, PartyGroup, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { convertPartiesToFormModel } from '../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { EMPTY_MORTGAGOR_PARTY, Mortgage2_21_2Model, TitleReferenceModel } from './models';

export function generateMortgagors(formModel: Mortgage2_21_2Model, partyAdder?: string): Mortgage2_21_2Model {
  const customParties = formModel.partyBook.filter(party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber);

  const customMortgagors = getCustomMortgagors(customParties, formModel);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    const mortgagors = customMortgagors.length ? customMortgagors : [EMPTY_MORTGAGOR_PARTY];
    return { ...formModel, mortgagors: createFormMortgagors(mortgagors) };
  }

  const createContainer = (key: string, mortgagors: PartyGroup<ApiDocumentPartyModel<NswDocumentPartyJustification>>[]) => {
    return new Container(key, new TenancyDetail(mortgagors.map(g => new ProprietorGroup(g.parties))));
  };

  const containers: Container<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = [];
  const titleWithTransfer = selectedTitles.filter(t => t.hasTransfer);

  if (titleWithTransfer.length) {
    const key = titleWithTransfer.map(t => t.reference).join();
    containers.push(createContainer(key, formModel.transferees));
  }

  const containersForNonTransferTitles = selectedTitles
    .filter(t => !t.hasTransfer)
    .map(title => {
      return createContainer(title.reference!, title.mortgagors);
    });

  const result = PartyMerger.merge(containers.concat(containersForNonTransferTitles));

  const apiMortgagors = result.containers.flatMap(c => c.tenancyDetail.proprietorGroups.flatMap(pg => pg.mergedParties));
  const partyBook = convertPartiesToFormModel2212(
    apiMortgagors,
    apiMortgagors.map(p => p.id!),
    partyAdder
  ).concat(customParties);

  const risMortgagors: TenancyPartyModel[] = apiMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));

  return {
    ...formModel, //
    partyBookApi: apiMortgagors,
    partyBook: partyBook,
    mortgagors: createFormMortgagors(risMortgagors.concat(customMortgagors))
  };
}

export function convertPartiesToFormModel2212(
  parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[],
  relinquishingIds?: string[],
  partyAdder?: string
): PartyModel<NswNameChange>[] {
  const uniqueParties = Array.from(
    parties
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.id!)) entryMap.set(e.id!, e);
        return entryMap;
      }, new Map<string, ApiDocumentPartyModel<NswDocumentPartyJustification>>())
      .values()
  );

  const dataSourceToNameMap = partyAdder
    ? new Map<DataSource, string>([
        [DataSource.Transfer, partyAdder],
        [DataSource.TransmissionApplication, partyAdder]
      ])
    : undefined;

  return convertPartiesToFormModel(
    {
      partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    uniqueParties,
    relinquishingIds,
    dataSourceToNameMap
  );
}

/**
 * returns selected title reference items
 */
export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export function filterOutSelectedParties(
  partyBook: PartyModel<{}, {}>[], //
  currentSelectedParty: TenancyPartyModel,
  selectedPartyIds?: string[]
) {
  const partyOptions = partyBook
    .filter(party => {
      const isCurrentlySelected = party.id === currentSelectedParty.partyBookId;
      const hasBeenSelected = (selectedPartyIds ?? []).includes(party.id);

      /*
       * We need to return current selected party too;
       * otherwise, the selected value of the dropdown list will be empty.
       */
      return isCurrentlySelected || !hasBeenSelected;
    })
    .map(party => ({ id: party.id, name: formatPartyName(party) }));

  return partyOptions;
}

function getCustomMortgagors(customParties: PartyModel<NswNameChange, {}>[], formModel: Mortgage2_21_2Model) {
  const customMortgagors = customParties.filter(p => formModel.mortgagors.proprietorGroups.flatMap(pg => pg.parties).some(m => m.partyBookId === p.id));
  return customMortgagors.map(p => ({ id: p.id!, partyBookId: p.id! }));
}

/**
 *
 * @param parties tenants in the group
 * @param groupId defaults to 1 when only one group can exist in mortgagors section
 * @returns converted form model
 */
export function createFormMortgagors(parties: TenancyPartyModel[], groupId: string = '1'): FormTenancyDetailModel {
  return { shareSplitType: null, proprietorGroups: [{ parties: parties, shareQuantity: null, id: groupId }] };
}

export function getPartyAdder(
  participants:
    | {
        id: string;
        name: string;
        workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
      }[]
    | undefined
): string | undefined {
  return participants?.find(p => p.workspaceRole.id === HttpTypes.WorkspaceRoleEnum.Purchaser || p.workspaceRole.id === HttpTypes.WorkspaceRoleEnum.Beneficiary)?.name;
}
