import { HttpTypes } from '@sympli/api-gateway/types';

import { AuthProfileState, useProfile } from 'src/@core/store/reducers/profile';

export default function useRolePermission(requiredPermission: HttpTypes.RolePermissionsEnum): boolean {
  const userProfileState: AuthProfileState = useProfile();

  if (userProfileState.status !== 'resolved') {
    return false;
  }

  const isAllowed = userProfileState.data!.rolePermissions.includes(requiredPermission);

  return isAllowed;
}
