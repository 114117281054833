import { HttpTypes } from '@sympli/api-gateway/types';

export const UNAVAILABLE_DISPLAY_NAME = 'UNAVAILABLE';
export const IN_PREPARATION_DISPLAY_NAME = 'IN PREPARATION';
export const VERIFIED_DISPLAY_NAME = 'VERIFIED';

export const StampDutyStatusDisplayMapping: Record<HttpTypes.StampDutyStatusEnum, string> = {
  [HttpTypes.StampDutyStatusEnum.AwaitingStampDutyDocuments]: UNAVAILABLE_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.WaitingForClaim]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Claimed]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.ReadyToSubmit]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Submitted]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Allocated]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.ReadyToCertify]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Assessed]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Cancelled]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.RequestToCancel]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.ExtendSettlementDate]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Review]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.ReadyToVerify]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.ReadyToCreateTransaction]: IN_PREPARATION_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.CertifiedAndReadyToSettle]: VERIFIED_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Returned]: VERIFIED_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Finalised]: VERIFIED_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Completed]: VERIFIED_DISPLAY_NAME,
  [HttpTypes.StampDutyStatusEnum.Verified]: VERIFIED_DISPLAY_NAME
};

export const StampDutySteps: string[] = [UNAVAILABLE_DISPLAY_NAME, IN_PREPARATION_DISPLAY_NAME, VERIFIED_DISPLAY_NAME];
