import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class Mfa {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves QRCode for MFA. It sends a request to the
   * `/profile/link-device`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The MFA details which includes URL to OKTAs QRCode image.
   *
   * @example
   * To retrieve QRCode for MFA verification:
   *
   * ```ts
   * sdk.profile.mfa.retrieveMfaQrCode()
   * .then(({ qrCode }) => {
   *   // render the QRCode image
   * })
   * ```
   */
  async retrieveMfaQrCode(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.MfaRetrieveMfaQrCodeApiResponse>( //
      `/profile/link-device`,
      {
        ...config
      }
    );
  }
}
