import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { DocumentPageRouteParams, DocumentPrerequisite, DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import DocumentNotificationPanel from './DocumentNotificationPanel';
import { DocumentResponseBase, getPanelDetails } from './helper';

interface Props<T> {
  profileUserId: string;
  currentWorkflowStep?: DocumentWorkflowStepsEnum;
  queryParams: DocumentPageRouteParams;
  detail: T;
  documentType: string;
  workspaceDocuments?: HttpTypes.WorkspaceDocumentSummary[];
  documentPrerequisite?: DocumentPrerequisite;
}

function DocumentNotificationPanelContainer<T extends DocumentResponseBase>(props: Props<T>) {
  const { profileUserId, queryParams, currentWorkflowStep, detail, documentType, workspaceDocuments, documentPrerequisite } = props;

  const { workspaceId, documentId, participantId } = queryParams;

  const { primaryMessage, secondaryMessage, panelVariant } = getPanelDetails(
    detail,
    currentWorkflowStep!,
    documentType,
    documentId,
    profileUserId,
    participantId,
    workspaceDocuments,
    documentPrerequisite
  );

  if (!primaryMessage || panelVariant === undefined) {
    return null;
  }

  return (
    <DocumentNotificationPanel //
      variant={panelVariant}
      primary={primaryMessage}
      secondary={secondaryMessage}
      linkToWorkspace={resolveWorkspaceDetailLink({ workspaceId, participantId })}
    />
  );
}

export default React.memo(DocumentNotificationPanelContainer);
