import React from 'react';

import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';

import { useSafeDispatch } from 'src/hooks';
import { useLinkedLodgementWorkspaces } from 'src/store/reducers/workspace/linkedLodgementDetail';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { UnlinkLodgementWorkspaceTableModel } from './unlink-lodgement-table/models';
import UnlinkLodgementDialog from './UnlinkLodgementDialog';

export interface UnlinkLodgementWorkspaceDialogProps {
  workspaceId: string;
  participantId: string;
  open: boolean;
  onClose(): void;
}

function UnlinkLodgementWorkspaceDialogContainer({ open, workspaceId, participantId, onClose }: UnlinkLodgementWorkspaceDialogProps): JSX.Element {
  const workspaceDetail = useWorkspaceDetail(workspaceId, participantId);
  const dispatch = useSafeDispatch(useDispatch());

  // if we reach here there must already an cluster id
  const clusterId = workspaceDetail.detail!.linkedWorkspaceCluster!.clusterId;

  const linkedLodgementDetail = useLinkedLodgementWorkspaces(workspaceId, clusterId);
  const rowData =
    linkedLodgementDetail?.detail?.linkedLodgementWorkspaceDetails?.map(
      d =>
        ({
          //
          ...d,
          isCurrentWorkspace: d.workspaceId === workspaceId
        }) satisfies UnlinkLodgementWorkspaceTableModel
    ) ?? [];

  return (
    <ConfirmationDialog //
      open={open}
      onClose={onClose}
      title={<FormGroup className="border-none pb-0" title={<Typography variant="subtitle1">Unlink Workspaces</Typography>}></FormGroup>}
      showActionButtons={false}
      classes={{ dialogPaper: 'w-[900px] max-w-[1080px] pb-[5px]', dialogTitle: 'bg-[var(--neutral-025)]' }}
    >
      <UnlinkLodgementDialog //
        participantId={participantId}
        workspaceId={workspaceId}
        clusterId={clusterId}
        onClose={onClose}
        dispatch={dispatch}
        rows={rowData}
        apiStatus={linkedLodgementDetail.status}
      />
    </ConfirmationDialog>
  );
}

export default React.memo(UnlinkLodgementWorkspaceDialogContainer);
