import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import { useContentTruncated } from 'src/@core/hooks';
import { colors } from 'src/@core/theme/colors';
import { renderSuggestionContent } from './helpers';
import { FormattersProps } from './models';

export const FormatReferenceColumn = ({ row }: FormattersProps) => {
  const { refContainerRef, isContentTruncated } = useContentTruncated(row.reference);

  return (
    <div ref={refContainerRef} className="whitespace-nowrap">
      {isContentTruncated && (
        <Tooltip
          title={
            <Typography variant="body2" sx={{ color: colors.WHITE }}>
              {row.reference}
            </Typography>
          }
          placement="top"
        >
          <div className="truncate">{renderSuggestionContent('reference', row)}</div>
        </Tooltip>
      )}
      {!isContentTruncated && renderSuggestionContent('reference', row)}
    </div>
  );
};

export const FormatSympliIdColumn = ({ row }: FormattersProps) => {
  return <div>{renderSuggestionContent('sympliId', row)}</div>;
};

export const FormatPlusNColumn = ({ row, columnName }: FormattersProps) => {
  return <div>{renderSuggestionContent(columnName, row)}</div>;
};

export const FormatWorkspaceTypeColumn = ({ row, columnName }: FormattersProps) => {
  return <div>{row.workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly ? 'Standalone' : 'Financial'}</div>;
};
