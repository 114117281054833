import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { JsonSchemaRootModel } from '../dynamic-form/models';
import { DocumentDetailApiRequest, DocumentFormAndDetailModel } from '../models';

export interface DocumentDetailState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  detail?: DocumentFormAndDetailModel;
  error?: Error;
  args?: DocumentDetailApiRequest;
}

const initialState: DocumentDetailState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

const DEFAULT_SCHEMA: Partial<JsonSchemaRootModel> = { required: [], properties: {} };

export function useDocumentDetail(workspaceId: string, participantId: string, documentId: string) {
  const state = useStoreSelector(store => store.workspaceDocuments);

  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    const documents = state.items.flatMap(d => d.supportingDocuments ?? []).concat(state.items);
    const detail = documents.find(doc => doc.documentId === documentId);

    if (!detail) {
      return initialState;
    }

    const parsedSchema: JsonSchemaRootModel = detail.documentForm.schema ? JSON.parse(detail.documentForm.schema) : DEFAULT_SCHEMA;

    return {
      ...state,
      error: state.error ? { message: state.error } : undefined,
      detail: {
        documentId: detail.documentId,
        documentPermissions: detail.permissions,
        documentDenyPermissions: detail.documentDenyPermissions,
        status: detail.documentStatus.id,
        documentActions: detail.documentActions,
        isFullySignedByAllParticipants: detail.isFullySignedByAllParticipants,
        id: detail.documentId,
        data: detail.data!,
        hash: detail.hash!,
        lodgementCaseId: detail.lodgementCaseId,
        parsedData: JSON.parse(detail.data ?? '{}'),
        documentWorkflow: detail.documentWorkflow,
        version: detail.version,
        documentForm: detail.documentForm,
        pdfS3Key: detail.pdfS3Key,
        documentWorkflowType: detail.documentWorkflowType,
        parsedSchema
      }
    };
  }
  return initialState;
}
