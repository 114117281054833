import Typography from '@mui/material/Typography';

import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import {
  addressFormatter,
  doubleRowPrimarySecondaryFormatter,
  newSettlementDatetimeDisplay,
  participantRoleFormatter,
  titleFormatter
} from 'src/containers/dashboard/shared/formatters';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme';
import { titleAddress } from 'src/utils/formatters';
import { modelKey } from 'src/utils/formUtils';
import { LinkedWorkspaceResultItem } from '../../models';
import { radioSelectFormatter } from './Formatters';
import { SearchTableModel } from './models';

const cf = columnFormatterCheck<SearchTableModel>();

// TODO move this to ui-framework
type WithExtra<T, ExtraProps> = {
  [P in keyof T]: Omit<T[P], keyof ExtraProps> & ExtraProps;
};

function dynamicColumns<R extends object, CH>(
  conditionalColumns: WithExtra<
    //
    ColumnsModel<R>,
    {
      thClassName?: ((args: CH) => string | undefined) | string;
      className?: ((args: CH) => string | undefined) | string;
      label: ((args: CH) => string | JSX.Element) | (string | JSX.Element);
      visibilityCheck?: (args: CH) => boolean;
    }
  >,
  args: CH
): ColumnsModel<R> {
  // recalculate columns based on their visibility
  const columns: ColumnsModel<R> = Object.entries(conditionalColumns) //
    .reduce((acc: ColumnsModel<R>, [key, { visibilityCheck, label, thClassName, className, ...columnDef }]) => {
      if (!visibilityCheck || visibilityCheck?.(args)) {
        if (typeof label === 'function') {
          label = label(args);
        }

        if (typeof thClassName === 'function') {
          thClassName = thClassName(args);
        }

        if (typeof className === 'function') {
          className = className(args);
        }

        acc[key] = {
          ...columnDef,
          label,
          thClassName,
          className
        };
      }
      return acc;
    }, {});

  // return only visible columns
  return columns;
}

type DynamicArgs = {
  screenSize: ScreenSizeEnum;
};

const fieldName = modelKey<SearchTableModel>();

export const resolveColumns = ({
  screenVariant
}: {
  //
  screenVariant: ScreenSizeVariant;
}) => {
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);
  const args: DynamicArgs = {
    screenSize
  };

  return resolveAssignToMeColumns(args);
};

function resolveAssignToMeColumns(args: DynamicArgs) {
  const columns: ColumnsModel<SearchTableModel> = dynamicColumns<SearchTableModel, DynamicArgs>(
    {
      select: {
        thClassName: 'w-[50px]',
        label: 'SELECT',
        name: fieldName('isSelected'),
        sortable: false,
        get: ({ isSelected }: SearchTableModel) => ({
          isSelected
        }),
        formatter: cf<{ isSelected?: boolean }>(radioSelectFormatter)
      },

      settlementDate: {
        thClassName: 'w-[90px]',
        className: 'py-[4px]', // special padding to support 3 lines of text
        label: 'SETL DATE',
        name: fieldName('settlementDateTime'),
        get: ({ settlementDateTime }: SearchTableModel) => ({
          snapshotSettlementDate: settlementDateTime
        }),
        formatter: cf<{
          snapshotSettlementDate: LinkedWorkspaceResultItem['settlementDateTime'];
        }>(newSettlementDatetimeDisplay)
      },

      reference: {
        label: 'REFERENCE',
        // auto width
        sortable: false,
        name: fieldName('sympliId'),
        get: ({ reference: primary, sympliId: secondary }: SearchTableModel) => ({
          primary,
          secondary
        }),
        formatter: cf<{
          //
          primary: SearchTableModel['reference'];
          secondary: string;
        }>(doubleRowPrimarySecondaryFormatter)
      },

      title: {
        thClassName: 'w-[90px]',
        label: 'TITLE',
        name: fieldName('titles'),
        sortable: false,
        get: ({ titles }: SearchTableModel) => ({
          titles
        }),
        formatter: cf<{ titles: string[] }>(titleFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'title':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple titles found</b>
                  </div>
                  {row.titles?.map((item, i) => {
                    return (
                      <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              );
            default:
              return null;
          }
        }
      },

      address: {
        label: 'Address',
        thClassName: 'w-[160px]',
        name: fieldName('address'),
        sortable: false,
        get: ({ address, jurisdiction }: SearchTableModel) => ({ addresses: address, jurisdictionId: jurisdiction }),
        formatter: cf<{ addresses: SearchTableModel['address']; jurisdictionId: number }>(addressFormatter),
        tooltipFormatter({ anchorEl, row }) {
          switch (anchorEl.dataset.binding) {
            case 'address':
              return anchorEl.innerText;
            case 'plusN':
              return (
                <div>
                  <div className="mb-[16px]">
                    <b>Multiple addresses found</b>
                  </div>
                  {row.address &&
                    row.address.map((item, i) => {
                      return (
                        <Typography key={i} className="text-[14px] text-[var(--neutral-000)]">
                          {titleAddress(item, row.jurisdiction)}
                        </Typography>
                      );
                    })}
                </div>
              );
            default:
              return null;
          }
        }
      },

      role: {
        thClassName: 'w-[88px]',
        label: 'ROLE',
        name: fieldName('role'),
        get: ({ role }: SearchTableModel) => [role],
        sortable: false,
        formatter: cf<Array<SearchTableModel['role']>>(participantRoleFormatter)
      }
    },
    args
  );
  return columns;
}
