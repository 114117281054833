import React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import DocumentItemBase from 'src/components/document-item-base';
import ListItemLink from 'src/components/list-item-link';
import { useStyles } from './styles';

export interface MatterAndTasksProps {
  matter: string;
  matterLink: string;
  numberOfTasks?: number;
  selected?: boolean;
}

function MatterAndTasks(props: MatterAndTasksProps) {
  const { matter, matterLink, numberOfTasks, selected } = props;
  const classes = useStyles();
  const description = numberOfTasks === undefined ? '' : numberOfTasks === 0 ? 'tasks completed' : `${numberOfTasks} ${pluralize(' task', numberOfTasks)}`;

  return (
    <FlexLayout justifyContent="flex-start" flexDirection="column" className={classNames(classes.root, selected && classes.selected)}>
      <span style={visuallyHidden}>{'Matter and tasks'}</span>
      <List component="nav" classes={{ root: classes.fullWidth }} disablePadding>
        <ListItemLink linkTo={matterLink} classes={{ link: classes.itemLink }} disabled={selected} selected={selected}>
          <DocumentItemBase
            underline={!!matterLink}
            title={<span className="break-words">{matter}</span>}
            classes={{
              title: classes.title,
              description: classes.description
            }}
            description={<Typography variant="body6">{description}</Typography>}
          />
        </ListItemLink>
      </List>
    </FlexLayout>
  );
}

export default React.memo(MatterAndTasks);
