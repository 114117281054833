import React from 'react';

import classNames from 'classnames';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { HttpTypes } from '@sympli/api-gateway/types';
import {
  GroupLockIconSmall,
  IconCalculator,
  IconCalendar,
  IconCheck,
  IconDelete,
  IconEditDocument,
  IconEnvelope,
  IconError,
  IconGroup,
  IconLock,
  IconMapMaker,
  IconSearchPlus,
  IconTasks,
  IconTimes,
  IconUnlock,
  IconWorkspaceUser
} from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';
import { SettlementNotificationStatusEnum } from '../../../../models';
import { LogWorkspaceSettlementInProgressEntity } from '../../models';
import { useStyles } from './styles';

interface Props {
  type: HttpTypes.LogTypeEnum;
  dataObject?: object;
}

function ActivityLogItemIcon({ type, dataObject }: Props) {
  const classes = useStyles();

  switch (type) {
    case HttpTypes.LogTypeEnum.SettlementLodgementFailed:
    case HttpTypes.LogTypeEnum.SettlementLodgementSucceeded:
    case HttpTypes.LogTypeEnum.SettlementLodgementInitiated:
    case HttpTypes.LogTypeEnum.SettlementLodgementVerificationFailed:
    case HttpTypes.LogTypeEnum.SettlementLodgementVerificationSucceeded:
    case HttpTypes.LogTypeEnum.SettlementLodgementVerificationInitiated:
    case HttpTypes.LogTypeEnum.SettlementInProgress: {
      const { StatusCode: statusCode } = dataObject as LogWorkspaceSettlementInProgressEntity;
      switch (statusCode) {
        case SettlementNotificationStatusEnum.Fail: {
          return <IconLock className={classNames(classes.icon, classes.iconFillRed)} />;
        }
        case SettlementNotificationStatusEnum.Success: {
          return <IconCheck color="primary" className={classes.icon} />;
        }
        default: {
          return <IconLock className={classes.icon} />;
        }
      }
    }

    case HttpTypes.LogTypeEnum.WorkspaceTitleAdded:
    case HttpTypes.LogTypeEnum.DocumentSigned:
    case HttpTypes.LogTypeEnum.SLCDocumentSigned:
    case HttpTypes.LogTypeEnum.DocumentPartiallySigned:
    case HttpTypes.LogTypeEnum.StampDutyVerifySuccess:
    case HttpTypes.LogTypeEnum.LodgementVerificationSuccess:
    case HttpTypes.LogTypeEnum.SLCLodgementVerificationSuccess:
    case HttpTypes.LogTypeEnum.WorkspaceCreated: {
      return <IconCheck color="primary" className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceLinkedPaymentCreated:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedSourceFundCreated:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedLodgementCreated:
      return <GroupLockIconSmall fill={colors.BLACK} height={16} width={16} className={classes.icon} />;

    case HttpTypes.LogTypeEnum.WorkspaceLinkedPaymentUnlinked:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedSourceFundUnlinked:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedLodgementUnlinked:
      return <GroupLockIconSmall fill={colors.RED} height={16} width={16} className={classes.icon} />;

    case HttpTypes.LogTypeEnum.LodgementVerificationFailedTechnicalError:
    case HttpTypes.LogTypeEnum.SLCLodgementVerificationFailedTechnicalError:
    case HttpTypes.LogTypeEnum.LodgementFailedConnectivityError:
    case HttpTypes.LogTypeEnum.LodgementFailedTechnicalError:
    case HttpTypes.LogTypeEnum.SLCLodgementFailedConnectivityError:
    case HttpTypes.LogTypeEnum.SLCLodgementFailedTechnicalError:
    case HttpTypes.LogTypeEnum.PreSettlementCheckFailed:
    case HttpTypes.LogTypeEnum.SettlementFailed:
    case HttpTypes.LogTypeEnum.LodgementFailed:
    case HttpTypes.LogTypeEnum.SLCLodgementVerificationFailed:
    case HttpTypes.LogTypeEnum.SLCLodgementFailed:
    case HttpTypes.LogTypeEnum.LodgementVerificationFailed: {
      return <IconError className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.InvitationsSent:
    case HttpTypes.LogTypeEnum.InvitationResent:
    case HttpTypes.LogTypeEnum.InvitationForwarded:
    case HttpTypes.LogTypeEnum.InvitationAccepted:
    case HttpTypes.LogTypeEnum.WorkspaceJoined:
    case HttpTypes.LogTypeEnum.InvitationRejected:
    case HttpTypes.LogTypeEnum.InvitationWithdrawn:
    case HttpTypes.LogTypeEnum.InvitationStatusUpdated: {
      return <IconEnvelope className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceAutoBalanced:
    case HttpTypes.LogTypeEnum.DirectionsUnsigned:
    case HttpTypes.LogTypeEnum.SourceFundsUnsigned:
    case HttpTypes.LogTypeEnum.PaymentsUnsigned:
    case HttpTypes.LogTypeEnum.DirectionsReviewed:
    case HttpTypes.LogTypeEnum.SourceFundsApproved:
    case HttpTypes.LogTypeEnum.PaymentsApproved:
    case HttpTypes.LogTypeEnum.DirectionsUpdated:
    case HttpTypes.LogTypeEnum.SourceFundsSaved:
    case HttpTypes.LogTypeEnum.PaymentsSaved:
    case HttpTypes.LogTypeEnum.SourceFundsSavedBySystem:
    case HttpTypes.LogTypeEnum.PaymentsSavedBySystem:
    case HttpTypes.LogTypeEnum.AutoBalanceSurplusRemoved:
    case HttpTypes.LogTypeEnum.SourceFundsOptedOut:
    case HttpTypes.LogTypeEnum.PaymentsOptedOut: {
      return <IconCalculator className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceSettlementDatetimeSupported:
    case HttpTypes.LogTypeEnum.WorkspaceSettlementDatetimeUnsupported:
    case HttpTypes.LogTypeEnum.SettlementDateTimeCouldNotBeAccepted:
    case HttpTypes.LogTypeEnum.SettlementDateProposed:
    case HttpTypes.LogTypeEnum.SettlementDateApproved:
    case HttpTypes.LogTypeEnum.SettlementDateUpdated:
    case HttpTypes.LogTypeEnum.SettlementDateApprovalRevoked: {
      return <IconCalendar className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.LodgementInitated:
    case HttpTypes.LogTypeEnum.LodgementVerificationInitated:
    case HttpTypes.LogTypeEnum.SLCLodgementInitated:
    case HttpTypes.LogTypeEnum.SLCLodgementVerificationInitated:
    case HttpTypes.LogTypeEnum.WorkspaceLocked:
    case HttpTypes.LogTypeEnum.WorkspaceLockedForLodgement:
    case HttpTypes.LogTypeEnum.WorkspaceLockedForSettlement: {
      return <IconLock className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceUnlocked: {
      return <IconUnlock className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.MatterUpdated: {
      return <IconTasks className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.UserAssigned:
    case HttpTypes.LogTypeEnum.UserUnassigned: {
      return <IconWorkspaceUser className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.StampDutySettlementVerifyInitated:
    case HttpTypes.LogTypeEnum.StampDutyVerifyInitated: {
      return <IconSearchPlus className={classes.icon} />;
    }

    case HttpTypes.LogTypeEnum.StampDutyVerifyFailed: {
      return <IconSearchPlus className={classNames(classes.icon, classes.iconFillRed)} />;
    }

    case HttpTypes.LogTypeEnum.TitleActivityNoChange: {
      return <IconMapMaker className={classNames(classes.icon, classes.iconMapMaker)} />;
    }

    case HttpTypes.LogTypeEnum.TitleActivityPositiveChange: {
      return <IconMapMaker className={classNames(classes.icon, classes.iconMapMaker, classes.iconFillOrange)} />;
    }

    case HttpTypes.LogTypeEnum.TitleActivityPositiveChangeWarningDismissed: {
      return <CloseRoundedIcon className={classNames(classes.icon, classes.iconCloseWarning)} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceAbandoned:
    case HttpTypes.LogTypeEnum.WorkspaceWithdraw: {
      return <IconTimes className={classNames(classes.icon, classes.iconFillOrange)} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceFileDeleted: {
      return <IconDelete className={classNames(classes.icon, classes.iconFillBlack)} />;
    }

    case HttpTypes.LogTypeEnum.WorkspaceGroupReassigned: {
      return <IconGroup className={classes.icon} />;
    }

    default:
      return <IconEditDocument className={classes.icon} />;
  }
}

export default React.memo(ActivityLogItemIcon);
