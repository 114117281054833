import ExpiredStorage from 'expired-storage';
import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';
import { DEFAULT_LOCAL_STORAGE_EXPIRY } from '@sympli/ui-framework/hooks';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { WorkspaceDetailModel } from 'src/containers/workspace/shared/detail/models';
import { useStoreSelector } from 'src/hooks';
import {
  actionDismissTacWarning,
  actionFetchWorkspaceById,
  actionSetTitleListAction,
  actionUpdateWorkspaceIsLockedStatus,
  actionUpdateWorkspaceStatusToArchived,
  actionUpdateWorkspaceStatusToLodgeInProgress,
  actionUpdateWorkspaceTitleActivity,
  actionUpdateWorkspaceTitleLandRegistryDetail,
  TitleListActionMode,
  WorkspaceRelatedApiRequest
} from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';
import { getWorkspaceStatusEnumMapping } from 'src/utils/status-mapping/workspaceStatusHelper';

export interface WorkspaceDetailState {
  detail?: WorkspaceDetailModel;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
  titleListActionMode?: TitleListActionMode;
}

const initialState: WorkspaceDetailState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined,
  titleListActionMode: 'default'
};

export function useWorkspaceDetail(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceDetail);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const workspaceDetailReducer = createReducer<
  //
  WorkspaceDetailState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceById.request, (state, action): WorkspaceDetailState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchWorkspaceById.success, (state, action): WorkspaceDetailState => {
    const titleReferences: HttpTypes.TitleReference[] = action.payload.detail.titleReferences.map(item => {
      return updateTitleReferenceWithLocalStorage(action.payload.detail.tacKey!, item);
    });

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: {
        ...action.payload.detail,
        titleReferences
      }
    };
  })
  .handleAction(
    actionFetchWorkspaceById.failure,
    (state, action): WorkspaceDetailState => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    })
  )
  .handleAction(actionSetTitleListAction, (state, action): WorkspaceDetailState => {
    return {
      ...state,
      titleListActionMode: action.payload.mode
    };
  })
  .handleAction(actionUpdateWorkspaceTitleActivity, (state, action): WorkspaceDetailState => {
    const { workspaceId, reference, titleActivityCheckResult } = action.payload;

    // we need to make sure that we are updating correct workspace and correct title
    if (state.detail == null || state.detail.id !== workspaceId) {
      return state;
    }

    const titleReferences = state.detail.titleReferences.map(item => {
      if (item.landRegistryDetail.reference === reference) {
        return updateTitleReferenceWithLocalStorage(state.detail!.tacKey!, { ...item, titleActivityCheckResult });
      }
      return item;
    });

    return {
      ...state,
      detail: {
        ...state.detail,
        titleReferences
      }
    };
  })
  .handleAction(actionUpdateWorkspaceTitleLandRegistryDetail, (state, action): WorkspaceDetailState => {
    const { workspaceId, reference, landRegistryDetail } = action.payload;
    // we need to make sure that we are updating correct workspace and correct title

    if (state.detail == null || state.detail.id !== workspaceId || landRegistryDetail == null) {
      return state;
    }

    // find the title which need to update with fresh data
    const titleReferences = state.detail.titleReferences.map(item => {
      if (item.landRegistryDetail.reference === reference) {
        let titleActivityCheckResult: HttpTypes.CheckTitleActivityApiResponse | undefined;

        if (item.titleActivityCheckResult != null) {
          titleActivityCheckResult = {
            ...item.titleActivityCheckResult,
            administrationChanges: [],
            documentChanges: [],
            hasChanges: false,
            numberOfChanges: 0
          };
        }

        return {
          ...item,
          // update land registry part with fresh data
          landRegistryDetail,
          // and make the activity check result empty, since we can expect that there has not been any changes,
          // because we have got fresh data from resupply
          titleActivityCheckResult: titleActivityCheckResult,
          previousLandRegistryDetail: { ...item.landRegistryDetail }
        };
      }

      return item;
    });

    return {
      ...state,
      detail: {
        ...state.detail,
        titleReferences
      }
    };
  })
  .handleAction(actionUpdateWorkspaceStatusToLodgeInProgress, (state, action): WorkspaceDetailState => {
    // make sure that update is related to currently displayed workspace
    if (state.detail?.id !== action.payload) {
      return state;
    }

    if (state.detail == null) {
      return state;
    }

    /**
     * !important
     * since this is only used for standalone workspaces, so we can safely assume lodgementCases has only one item
     */
    const newLodgementCase: HttpTypes.LodgementCase = { ...state.detail.lodgementCases[0] };
    newLodgementCase.lodgementCaseStatusId = HttpTypes.LodgementCaseStatusEnum.LodgementRequested;

    const detail: WorkspaceDetailModel = {
      ...state.detail,
      lodgementCases: [newLodgementCase],
      isLocked: true
    };

    return {
      ...state,
      detail
    };
  })
  .handleAction(actionUpdateWorkspaceStatusToArchived, (state, action): WorkspaceDetailState => {
    // make sure that update is related to currently displayed workspace
    if (state.detail?.id !== action.payload) {
      return state;
    }

    if (state.detail == null) {
      return state;
    }

    const archivedWorkspaceStatus: LookupEnumModel<HttpTypes.WorkspaceStatusEnum> = {
      name: getWorkspaceStatusEnumMapping[HttpTypes.WorkspaceStatusEnum.Archived],
      id: HttpTypes.WorkspaceStatusEnum.Archived
    };

    return {
      ...state,
      detail: {
        ...state.detail,
        workspaceStatus: archivedWorkspaceStatus
      }
    };
  })
  .handleAction(actionUpdateWorkspaceIsLockedStatus, (state, action): WorkspaceDetailState => {
    // prevent update of an unloaded workspace, also need this check to pass TS verification for ...state.detail since detail is optional
    if (state.detail == null) {
      return state;
    }

    return {
      ...state,
      detail: {
        ...state.detail,
        isLocked: action.payload
      }
    };
  })
  .handleAction(actionDismissTacWarning, (state, action): WorkspaceDetailState => {
    if (!state.detail) return state;

    const titleReferences = state.detail.titleReferences.map(item => {
      if (item.titleActivityCheckResult && item.landRegistryDetail.reference === action.payload.titleReference) {
        const key = `${state.detail?.tacKey!}/${item.landRegistryDetail.reference}`;
        new ExpiredStorage().setItem(key, item.titleActivityCheckResult.documentChanges.map(e => e.dealingNumber).join(','), DEFAULT_LOCAL_STORAGE_EXPIRY);
        return {
          ...item,
          titleActivityCheckResult: {
            ...item.titleActivityCheckResult,
            hasChanges: false
          }
        };
      }

      return item;
    });

    return {
      ...state,
      detail: {
        ...state.detail,
        titleReferences
      }
    };
  });

function updateTitleReferenceWithLocalStorage(key: string, item: HttpTypes.TitleReference): HttpTypes.TitleReference {
  if (item.titleActivityCheckResult?.hasChanges) {
    const warning = getTacWarning(key, item.landRegistryDetail.reference, item.titleActivityCheckResult);
    return {
      ...item,
      titleActivityCheckResult: {
        ...item.titleActivityCheckResult,
        hasChanges: warning
      }
    };
  }

  return item;
}

export function getTacWarning(tacKey: string, titleReference: string, newTac: { hasChanges?: boolean; documentChanges?: HttpTypes.DocumentChangeEntityModel[] }): boolean {
  if (!newTac.hasChanges) return false;
  const expiredStorage = new ExpiredStorage();
  const key = `${tacKey}/${titleReference}`;
  const oldTac = expiredStorage.getItem(key);
  if (!oldTac) return newTac.hasChanges ?? false;
  // When user dismiss tac warning we save the tac dealing numbers, checking if there is any new changes or not
  return oldTac !== newTac.documentChanges?.map(e => e.dealingNumber).join(',');
}

export default workspaceDetailReducer;
