import { HttpTypes } from '@sympli/api-gateway/types';

export {
  RoleChatPermissionMapping,
  DEFAULT_WORKSPACE_ROLE_OPTIONS,
  DEFAULT_WORKSPACE_TYPE_OPTIONS,
  resolveFinancialRoleType,
  resolveWorkspaceType,
  resolveFinancialSettlementType,
  getWorkspaceRoleTypeOptions
} from 'src/models/roles';

export { TaskTypesEnum } from 'src/models/tasks';

export interface TitleReferenceEntityModel {
  reference: string;
  address: HttpTypes.ResupplyLandRegistryInformationApiResponse['propertyAddress'];
  proprietors?: Array<{
    legalEntityName: string;
  }>;
  documentChanges?: HttpTypes.DocumentChangeEntityModel[];
  hasChanges?: boolean;
}

export interface AllocatedUser {
  userId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  avatarUrl?: string;
}

// export const settlementStatusMap = wordifyEnum(HttpTypes.SettlementStatusEnum);

export enum DashboardTypeEnum {
  Standalone = 'standalone-dashboard',
  Financial = 'financial-dashboard',
  Invitation = 'invitation-dashboard'
}
export interface DashboardDateRangeModel {
  start: Date | string | null;
  end: Date | string | null;
}
