import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import { validation } from '../../../../../../../shared/invitation-description';
import { CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel, DocumentInvitationItemModel, DocumentWithInvitationModel, SubscriberSearchItemModel } from '../../models';

const validationSchema = yup.object<CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel, CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel>({
  documents: yup
    .array<DocumentWithInvitationModel>()
    .of(
      yup.lazy<DocumentWithInvitationModel>((document: DocumentWithInvitationModel) => {
        const { selectedRoleIds } = document;

        return yup
          .object<DocumentWithInvitationModel, CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel>({
            selectedRoleIds: yup //
              .array<HttpTypes.WorkspaceRoleEnum>()
              .defined()
              .test(
                'groupTest',
                msg.MUST_SELECT_AT_LEAST_X(1, 'role'), //
                function test(this: yup.TestContext<CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel>, selectedRoleIds: HttpTypes.WorkspaceRoleEnum[] = []) {
                  const { roleOptions } = this.parent as DocumentWithInvitationModel;
                  if (roleOptions.length > 1) {
                    return !!selectedRoleIds.length; // at least one role must be selected
                  }
                  return true;
                }
              ),
            documentIdentifier: yup.mixed<HttpTypes.DocumentTypeIdentifierEnum>(),
            invitations: yup //
              .array<DocumentInvitationItemModel>()
              .defined()
              .of(
                yup.lazy<DocumentInvitationItemModel>((invitation: DocumentInvitationItemModel) => {
                  if (selectedRoleIds.includes(invitation.roleId)) {
                    return yup.mixed<DocumentInvitationItemModel>(); // no ned to validate invitation because role is already represented by current user
                  }

                  return yup //
                    .object<DocumentInvitationItemModel>({
                      subscriberIsReadOnly: yup.mixed(), // no ned to validate
                      roleId: yup.mixed<HttpTypes.WorkspaceRoleEnum>(),
                      // WEB-20887
                      subscriber: yup.mixed<SubscriberSearchItemModel>().when('subscriberIsReadOnly', {
                        is: false, // validate only when subscriberIsReadOnly === false
                        then: yup
                          .object<SubscriberSearchItemModel>({
                            id: yup // subscriberId is required if given role is not in selectedRoleIds
                              .string()
                              .required(msg.REQUIRED),
                            name: yup.mixed(), // no ned to validate
                            abn: yup.string()
                          })
                          .defined()
                      })
                    })
                    .defined();
                })
              ),
            roleOptions: yup.mixed<LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[]>() // no ned to validate
          })
          .defined();
      })
    )
    .defined(),
  invitationDescription: validation
});

export default validationSchema;
