import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';
import msg from '@sympli/ui-framework/utils/messages';

import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { ReassignWorkspaceGroupFormikModel } from '../reassign-workspace-group-container/models';

export const getValidationSchema = (assignableGroups: GroupOptionModel[], jurisdiction: HttpTypes.JurisdictionsEnum | undefined) => {
  return yup.object<ReassignWorkspaceGroupFormikModel>({
    groupId: yup
      .string()
      .trim()
      .required(msg.REQUIRED)
      .test('jurisdiction-validation', 'The selected group is not enabled for this jurisdiction. Please contact your Super Admin for more information.', value => {
        const selectedGroup = assignableGroups.find(group => group.id === value);
        return selectedGroup?.jurisdictions.find(x => x.id === jurisdiction) ? true : false;
      })
  });
};
