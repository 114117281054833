import { createAction, createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchOrganisationProfile = createAsyncAction(
  //
  'FETCH_ORGANISATION_PROFILE',
  'FETCH_ORGANISATION_PROFILE_SUCCESS',
  'FETCH_ORGANISATION_PROFILE_ERROR'
)<
  //
  void,
  { data: HttpTypes.SettingsSubscriberDetailsApiResponse },
  { error: Error }
>();

export const actionUpdateOrganisationProfilePicture = createAction(
  //
  'UPDATE_ORGANISATION_PROFILE_PICTURE'
)<//
{ profileImageUrl?: string }>();
