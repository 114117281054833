import * as React from 'react';

import Grid from '@mui/material/Grid';

import { HttpTypes } from '@sympli/api-gateway/types';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { HoldingAccountDetailsModel } from 'src/containers/workspace/financial/directions/models';
import { createModelKeyAppender, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { ConditionalDistributionModel } from '../../../../../components/direction-record/models';
import { EditBankDetailsProps } from './EditBankDetails';
import { getLoanAccountOptions } from './helpers';
import { CommonProps } from './models';
import { useStyles } from './styles';

interface AcceptSurplusEditBankDetailsProps {
  financialAccounts?: EditBankDetailsProps['financialAccounts'];
  itemFieldName: EditBankDetailsProps['itemFieldName'];
  formikProps: EditBankDetailsProps['formikProps'];
  isLoading: EditBankDetailsProps['isLoading'];
  disableSave: boolean;
  onUpdate: CommonProps['onUpdate'];
  onAccountChange: EditBankDetailsProps['prePopulateBankDetails'];
}

const AcceptSurplusSelectBankAccount = (props: AcceptSurplusEditBankDetailsProps) => {
  const { financialAccounts, itemFieldName, formikProps, isLoading, disableSave, onUpdate, onAccountChange } = props;
  const classes = useStyles();

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);
  const bankDetailsFieldName = createModelKeyAppender<HttpTypes.BankDetailsModel>(fieldName('bankDetails'));
  const holdingAccountDetailsFieldName = createModelKeyAppender<HoldingAccountDetailsModel>(fieldName('holdingAccountDetails'));

  const handleAccountChange = (_, resolvedValue: string) => {
    onAccountChange(resolvedValue, formikProps, financialAccounts);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field
            label="Account"
            component={SelectField}
            className={classes.fullWidth}
            placeholder={resolveSelectPlaceholder(true)}
            name={holdingAccountDetailsFieldName('accountId')}
            options={getLoanAccountOptions(financialAccounts)}
            onChange={handleAccountChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Field //
            label="Reference (optional)"
            component={InputField}
            name={bankDetailsFieldName('reference')}
            className={classes.fullWidth}
          />
        </Grid>
      </Grid>
      <FlexLayout fullWidth>
        <FormGroupFooter
          className={classes.formGroupFooter}
          title="If there is a surplus in this workspace it will be paid into this account"
          formTip="The Surplus funds will be calculated after all parties in the workspace have approved their Financial Settlement Statement. This amount will populate automatically based on the difference between the source funds and directions"
        />
        <SympliButton
          className={classes.saveButton}
          color="primary"
          variant="contained"
          onClick={_ => onUpdate(itemFieldName, formikProps)}
          isLoading={isLoading}
          disabled={disableSave || isLoading}
        >
          Update
        </SympliButton>
      </FlexLayout>
    </React.Fragment>
  );
};

export default AcceptSurplusSelectBankAccount;
