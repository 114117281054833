import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { CategoryEnum } from 'src/containers/workspace/financial/directions/views/edit-directions/forms/discharge-mortgage-directions/models';
import { saveFSSLineItem } from 'src/containers/workspace/financial/directions/views/edit-directions/helper';
import { LinkedCategory, LinkSettlementDialogFormikModel } from './models';

import type { SafeDispatch } from 'src/hooks';

export async function saveLinkedSettlementHelper({
  workspaceId,
  participantId,
  formikValues,
  dispatch,
  workspaceRoleId
}: {
  workspaceId: string;
  participantId: string;
  formikValues: LinkSettlementDialogFormikModel;
  dispatch: SafeDispatch<Action>;
  workspaceRoleId?: HttpTypes.WorkspaceRoleEnum;
}) {
  const { category, amount, reference, linkedSettlementItem } = formikValues;

  if (category === CategoryEnum.LinkedPayment) {
    await saveFSSLineItem(
      {
        workspaceId,
        participantId,
        requestPayload: {
          type: HttpTypes.DirectionTypeEnum.Direction,
          direction: {
            paymentMethod: HttpTypes.PaymentMethodEnum.Linked,
            amount: Number(amount),
            directionCategory:
              workspaceRoleId === HttpTypes.WorkspaceRoleEnum.IncomingMortgagee || workspaceRoleId === HttpTypes.WorkspaceRoleEnum.Purchaser
                ? HttpTypes.DirectionCategoryEnum.Purchaser
                : HttpTypes.DirectionCategoryEnum.Settlement,
            category: CategoryEnum.LinkedPayment,
            reference,
            linkedSettlementItem
          }
        }
      },
      dispatch,
      true,
      linkedSettlementItem.linkedToParticipantMatterReference
    );
  } else {
    // linked source fund
    await saveFSSLineItem(
      {
        workspaceId,
        participantId,
        requestPayload: {
          type: HttpTypes.DirectionTypeEnum.SourceFund,
          sourceFund: {
            paymentMethod: HttpTypes.PaymentMethodEnum.Linked,
            amount: Number(amount),
            category: CategoryEnum.LinkedSourceFund,
            reference,
            linkedSettlementItem
          }
        }
      },
      dispatch,
      true,
      linkedSettlementItem.linkedToParticipantMatterReference
    );
  }
}

export const getInitialValues = (categoryOptions: LookupEnumModel<LinkedCategory>[]): LinkSettlementDialogFormikModel => {
  return {
    category: categoryOptions.length > 1 ? undefined : categoryOptions[0].id,
    reference: '',
    amount: '1',
    linkedSettlementFundsNotRequired: false,
    linkedSettlementItem: {
      linkedWorkspaceId: '',
      linkedParticipantId: '',
      linkedToParticipantMatterReference: ''
    }
  };
};

export const LINKED_PAYMENT_ONLY: LookupEnumModel<LinkedCategory>[] = [{ id: CategoryEnum.LinkedPayment, name: CategoryEnum.LinkedPayment }];
export const LINKED_SOURCE_FUND_ONLY: LookupEnumModel<LinkedCategory>[] = [{ id: CategoryEnum.LinkedSourceFund, name: CategoryEnum.LinkedSourceFund }];

export const getLinkedSettlementCategoryOptions = (linkedWorkspaceCluster?: HttpTypes.LinkedWorkspaceCluster): LookupEnumModel<LinkedCategory>[] => {
  if (!linkedWorkspaceCluster) return LINKED_PAYMENT_ONLY.concat(LINKED_SOURCE_FUND_ONLY);

  return linkedWorkspaceCluster.hasLinkedPayment ? LINKED_SOURCE_FUND_ONLY : LINKED_PAYMENT_ONLY;
};
