import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { updateProprietorWithCapacity } from '../../../components/deceased-tenancy-detail/helpers';
import { DeceasedProprietorModel, DeceasedTenancyDetailModel, initDeceasedProprietor } from '../../../components/deceased-tenancy-detail/models';
import { convertPartiesToFormModel } from '../../../helpers';
import { NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from '../configs';
import { NoticeOfDeath_2_21_2_Model } from '../models';

export function generateDeceasedTenancy(formModel: NoticeOfDeath_2_21_2_Model): NoticeOfDeath_2_21_2_Model {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Custom);
  const selectedProprietor = formModel.deceasedTenancyDetail.proprietorGroups.flatMap(e => e.parties).filter(e => e.isSelected)[0];
  const selectedProprietorName = formModel.partyBook.find(e => e.id === selectedProprietor?.partyBookId)?.legalEntityName;

  const containers = formModel.titleReferences
    .filter(tr => tr.isSelected)
    .map(
      title =>
        new Container(
          title.reference!,
          new TenancyDetail(
            title.proprietor.proprietorGroups.map(
              pg =>
                new ProprietorGroup(
                  pg.parties.map(p => p.party),
                  pg,
                  pg.shareFraction,
                  pg.proprietorGroupType
                )
            ),
            title.proprietor.tenancyType
          )
        )
    );

  const mergeResult = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = mergeResult.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties,
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = mergeResult.containers[0]?.tenancyDetail.tenancyType ?? TenancyTypeEnum.None;
  const deceasedTenancyDetail: DeceasedTenancyDetailModel = {
    tenancyType,
    proprietorGroups: groups.map(pg => ({
      ...pg,
      isSelected: groups.length === 1,
      parties: pg.parties.map(p => {
        const defaultSelected = groups.length === 1 && pg.parties.length === 1;
        const proprietor: DeceasedProprietorModel = initDeceasedProprietor(defaultSelected, p.id!);

        return defaultSelected ? updateProprietorWithCapacity(proprietor, proprietor.isSelected) : proprietor;
      })
    }))
  };

  const unselectedProprietorParties = formModel.titleReferences
    .filter(tr => !tr.isSelected)
    .flatMap(tr => tr.proprietor.proprietorGroups.flatMap(pg => pg.parties))
    .map((x, index: number) => ({ ...x.party, id: (x.party.id = x.party.externalId ? x.party.externalId : `TITLE-${++index}`) }));

  const titleProprietors = groups.flatMap(pg => pg.parties).concat(unselectedProprietorParties);

  const partyBook = convertPartiesToFormModel_2_21_2(
    titleProprietors,
    titleProprietors.map(p => p.id!)
  ).concat(customParties);

  setUserData();

  return {
    ...formModel,
    mergeFailedReason: mergeResult.failedReason,
    deceasedTenancyDetail: deceasedTenancyDetail,
    partyBookApi: titleProprietors,
    partyBook,
    precedingData: { dataChanged: formModel.precedingData.dataChanged ?? false, manualOverride: false, sourceChanged: false },
    survivingJointTenants: extractSurvivingJointTenants(deceasedTenancyDetail)
  };

  function setUserData() {
    const parties = groups.flatMap(pg => pg.parties).filter(e => e.legalEntityName === selectedProprietorName);
    if (parties.length === 1) {
      for (const group of deceasedTenancyDetail.proprietorGroups) {
        const partyIndex = group.parties.findIndex(e => e.partyBookId === parties[0].id);
        if (partyIndex !== -1) {
          group.parties[partyIndex] = { ...selectedProprietor, partyBookId: parties[0].id! };
          group.isSelected = true;
          break;
        }
      }
    }
  }
}

export function extractSurvivingJointTenants(deceasedTenancyDetail: DeceasedTenancyDetailModel): ReceivingTenancyDetailModel {
  const selectedGroups = deceasedTenancyDetail.proprietorGroups.filter(p => p.parties.some(x => x.isSelected));
  const deceasedJointTenants = selectedGroups.flatMap(r => r.parties).filter(p => p.isSelected);
  const survivingJointTenantsCount = selectedGroups.flatMap(x => x.parties).filter(x => !x.isSelected).length;

  const survivingJointTenants: ReceivingTenancyDetailModel = {
    tenancyType: TenancyTypeEnum.None,
    shareSplitType: null,
    proprietorGroups:
      deceasedJointTenants.length === 0
        ? []
        : selectedGroups.map(pg => ({
            ...pg,
            shareQuantity: null,
            isSelected: false,
            parties: pg.parties
              .filter(p => !p.isSelected)
              .map(p => {
                return {
                  ...p,
                  isSelected: survivingJointTenantsCount === 1,
                  partyCapacity: {}
                };
              })
          }))
  };

  return survivingJointTenants;
}

export function convertPartiesToFormModel_2_21_2(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}
