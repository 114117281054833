import * as React from 'react';

import { useProfile } from '../../store/reducers/profile';
import ProfileContext from './Profile.context';

interface Props {}

function ProfileContextProvider({ children }: React.PropsWithChildren<Props>) {
  const profile = useProfile().data;
  // console.log('%cProfile.context.Provider - render (OUTSIDE of the context.PROVIDER), %s', logStyles, getCurrentTime());
  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
}

export default React.memo(ProfileContextProvider);
