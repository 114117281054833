import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import FinancialWorkspaceStatusBadgeContainer from 'src/containers/workspace/shared/components/financial-workspace-status-badge/FinancialWorkspaceStatusBadgeContainer';
import LodgementOnlyWorkspaceStatusProgress from 'src/containers/workspace/shared/components/lodgement-only-workspace-status-progress';

export interface WorkspaceStatusProps {
  // basic workspace info
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  className?: string;
}

function WorkspaceStatus(props: WorkspaceStatusProps) {
  const {
    //
    workspaceTypeId,
    lodgementCaseStatusId,
    workspaceStatusId
  } = props;

  const renderWorkspaceStatus = () => {
    if (workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly) {
      return (
        <LodgementOnlyWorkspaceStatusProgress // this is the new component
          workspaceStatusId={workspaceStatusId}
          lodgementCaseStatusId={lodgementCaseStatusId}
        />
      );
    }

    return (
      <FinancialWorkspaceStatusBadgeContainer //
        workspaceStatusId={workspaceStatusId}
      />
    );
  };

  return <div className={props.className}>{renderWorkspaceStatus()}</div>;
}

export default React.memo(WorkspaceStatus);
