import { endOfToday, endOfYesterday, isBefore } from 'date-fns';
import _get from 'lodash-es/get';
import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { memoizeSchemaWithContext, testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { HttpTypes } from '@sympli/api-gateway/types';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import {
  TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS,
  TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS
} from '../../enums';
import { getParentProprietorPath, titlesHasMatchingPartyNameAndPartyType, titlesHasMatchingShareFraction, titlesHasSameTenancyStructureAndNumberOfGroups } from '../../helpers';
import { IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE } from '../../isRequiredChecks';
import { DateOfDeathModel, DeceasedProprietorGroupModel, DeceasedProprietorModel, EvidenceModel, FractionModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';
import {
  VISIBILITY_CHECK_DATE_OF_DEATH,
  VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP,
  VISIBILITY_CHECK_EVIDENCES,
  VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE,
  VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE
} from '../../visibilityChecks';

interface TransmissionApplicationWithoutDutyContext {
  partyBook: TransmissionApplicationWithoutDuty2_21_1Model['partyBook'];
  selectedTitleReferences: TransmissionApplicationWithoutDuty2_21_1Model['titleReferences'];
  deceasedProprietorGroups: TransmissionApplicationWithoutDuty2_21_1Model['deceasedProprietorGroups'];
}

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.';
export const MUST_SELECT_ONE_LAST_DECEASED = 'You must specify the recently deceased party';
export const ONLY_ONE_GROUP_IS_SELECTED = 'Only 1 proprietor group must be selected';
export const MUST_BE_TODAY_OR_PAST_DATE = 'Must be today or a past date';
export const MUST_BE_PAST_DATE = 'Must be a past date';
export const EVIDENCE_DATE_MUST_BE_EARLIER_THAN_DATE_OF_DEATH = 'Evidence date can not be earlier than date of death';
export const PLEASE_SELECT_A_PROPRIETOR_GROUP = 'Please select a proprietor group';

const END_OF_TODAY = endOfToday();
export const END_OF_YESTERDAY = endOfYesterday();

const contextResolver = ({ partyBook, titleReferences, deceasedProprietorGroups }: TransmissionApplicationWithoutDuty2_21_1Model): TransmissionApplicationWithoutDutyContext => ({
  //
  partyBook,
  deceasedProprietorGroups,
  selectedTitleReferences: titleReferences.filter(tr => tr.isSelected)
});

const yupLastDeceasedValidation = yup
  .mixed<unknown>()
  .test('Deceased proprietor groups last deceased validation section', 'Validate last deceased in deceased proprietor groups section', function test(this: yup.TestContext) {
    const { deceasedProprietors } = this.parent;
    if (deceasedProprietors.length > 1 && deceasedProprietors.every(x => x.isAffectedProprietor && !x.isLastDeceased)) {
      return this.createError({ message: MUST_SELECT_ONE_LAST_DECEASED });
    }
    return true;
  });

const resolveDeceasedProprietorGroups = yup
  .array()
  .of<DeceasedProprietorGroupModel>(
    yup
      .object<DeceasedProprietorGroupModel>({
        deceasedProprietors: yup
          .array()
          .of<DeceasedProprietorModel>(
            yup
              .object<DeceasedProprietorModel>({
                deceasedProprietorId: yup.mixed<string>(),
                isEvidenceVisible: yup.mixed<boolean>(),
                isLastDeceasedBefore: yup.mixed<boolean>(),
                isAffectedProprietor: yup.mixed<boolean>(),
                isLastDeceased: yup.mixed<boolean>(),
                partyBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED), // suspicious validation, user can't see this!
                dateOfDeath: yup.mixed<DateOfDeathModel>().test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_DATE_OF_DEATH,
                    validationWhenVisible: yup
                      .object<DateOfDeathModel>()
                      .nullable()
                      .shape({
                        dateOfDeathType: yup
                          .mixed()
                          .required(msg.REQUIRED)
                          .oneOf(
                            getLookupValuesAllowingEmpty(TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS),
                            msg.INVALID_SELECTION
                          ),
                        deathDate: yup
                          .mixed<Date | string | null>()
                          .nullable()
                          .test(
                            validateWhenVisible({
                              visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE,
                              validationWhenVisible: yupDatePicker //
                                .required(msg.REQUIRED)
                                .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                            })
                          ),
                        fromDate: yup
                          .mixed<Date | string | null>()
                          .nullable()
                          .test(
                            validateWhenVisible({
                              visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
                              validationWhenVisible: yupDatePicker //
                                .required(msg.REQUIRED)
                                .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                            })
                          ),
                        toDate: yup
                          .mixed<Date | string | null>()
                          .nullable()
                          .test(
                            validateWhenVisible({
                              visibilityCheck: VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE,
                              validationWhenVisible: yupDatePicker //
                                .required(msg.REQUIRED)
                                .max(END_OF_YESTERDAY, MUST_BE_PAST_DATE)
                                .test(
                                  testContextualRule({
                                    uniqueTestName: '"deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.toDate" contextual validation rule #1',
                                    requirement: (_, context: DateOfDeathModel): boolean => {
                                      return isNotNullOrEmpty(context.fromDate) && isBefore(new Date(context.fromDate!), new Date(context.toDate!));
                                    },
                                    message: 'To date can not be equal or smaller than from date'
                                  })
                                )
                                .defined()
                            })
                          ),
                        dateDescription: yup
                          .mixed()
                          .nullable()
                          .test(
                            validateWhenVisible({
                              visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_DESCRIPTION,
                              validationWhenVisible: yup.string().default('').trim().nullable().required(msg.REQUIRED).max(200, 'Max length: 200 characters')
                            })
                          )
                      }),
                    isObjectOrArray: true
                  })
                ),
                evidences: yup.mixed<EvidenceModel[]>().test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_EVIDENCES,
                    validationWhenVisible: yup
                      .array()
                      .required(msg.REQUIRED)
                      .min(1, msg.MIN_ITEMS(1))
                      .max(20, msg.MAX_ITEMS(20))
                      .of<EvidenceModel>(
                        yup
                          .object<EvidenceModel>({
                            isDeletable: yup.mixed<boolean>(),
                            evidenceTypeValue: yup
                              .mixed<EvidenceModel['evidenceTypeValue']>()
                              .required(msg.REQUIRED)
                              .oneOf(
                                getLookupValuesAllowingEmpty(TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS),
                                msg.INVALID_SELECTION
                              ),
                            evidenceDate: yupDatePicker //
                              .required(msg.REQUIRED)
                              .max(END_OF_TODAY, MUST_BE_TODAY_OR_PAST_DATE)
                              .test('Validate evidence date', EVIDENCE_DATE_MUST_BE_EARLIER_THAN_DATE_OF_DEATH, function test(this: yup.TestContext, evidenceDate: Date) {
                                const rootModel = this.options.context!;
                                const parentProprietorPath = getParentProprietorPath(this.path);

                                const parentProprietor: DeceasedProprietorModel = _get(rootModel, parentProprietorPath);
                                if (!parentProprietor.isLastDeceased) return true;

                                if (!evidenceDate) return true;
                                const lastDeceasedDateDeath = parentProprietor.dateOfDeath;
                                if (VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DEATH_DATE(lastDeceasedDateDeath!)) {
                                  if (isNotNullOrEmpty(lastDeceasedDateDeath!.deathDate!)) {
                                    const deathDate = new Date(lastDeceasedDateDeath!.deathDate!);
                                    deathDate.setHours(0, 0, 0, 0);
                                    return !isBefore(evidenceDate, deathDate);
                                  }
                                }
                                if (VISIBILITY_CHECK_FROM_DATE_AND_TO_DATE(lastDeceasedDateDeath!)) {
                                  if (isNotNullOrEmpty(lastDeceasedDateDeath!.toDate)) {
                                    const toDate = new Date(lastDeceasedDateDeath!.toDate!);
                                    toDate.setHours(0, 0, 0, 0);
                                    return !isBefore(evidenceDate, toDate);
                                  }
                                }
                                return true;
                              }),
                            evidenceDocumentReference: yup.mixed<string>().test(
                              validateWhenVisible({
                                visibilityCheck: VISIBILITY_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE,
                                validationWhenVisible: yup
                                  .string()
                                  .default('')
                                  .trim()
                                  .test(
                                    testContextualRule({
                                      /**
             * what we found in schema:
             [{"required":true,"message":"Required","onlyIf":{"evidenceTypeValue": [12,9,5,4,0]}}]
             *
             */
                                      uniqueTestName: '"deceasedProprietorGroups[*].deceasedProprietors[*].evidences[*].evidenceDocumentReference" contextual validation rule #1',
                                      onlyIf: IS_REQUIRED_CHECK_TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_DOCUMENT_REFERENCE,
                                      requirement: isNotNullOrEmpty,
                                      message: msg.REQUIRED
                                    })
                                  )
                              })
                            )
                          })
                          .defined()
                      )
                      .defined(),
                    isObjectOrArray: true
                  })
                )
              })
              .defined()
          )
          .defined(),
        tenancyType: yup.mixed<HttpTypes.TenancyTypeEnum>(),
        lastDeceasedValidation: yupLastDeceasedValidation,
        previouslyHeldShareFraction: yup.mixed<FractionModel>()
      })
      .defined()
  )
  .defined()
  .log();

export const resolveDeceasedProprietorGroupsValidationSchema = yup.mixed<DeceasedProprietorGroupModel[]>().test(
  validateWhenVisible({
    isObjectOrArray: true,
    visibilityCheck: VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP,
    validationWhenVisible: resolveDeceasedProprietorGroups
  })
);

export const yupDeceasedProprietorGroupsSectionValidation = memoizeSchemaWithContext(
  yup
    .mixed<unknown>()
    .test(
      'Deceased proprietor groups validation section',
      'Validate deceased proprietor groups section',
      function test(this: yup.TestContext<TransmissionApplicationWithoutDutyContext>) {
        const { selectedTitleReferences, partyBook, deceasedProprietorGroups } = this.options.context!;
        if (!titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences)) return true;
        if (selectedTitleReferences.length > 1 && deceasedProprietorGroups.length > 1) {
          if (!titlesHasMatchingPartyNameAndPartyType(selectedTitleReferences, partyBook) || !titlesHasMatchingShareFraction(partyBook, deceasedProprietorGroups)) {
            return this.createError({ message: MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE });
          }
        }

        if (deceasedProprietorGroups.every(group => group.deceasedProprietors.every(x => !x.isAffectedProprietor))) {
          return this.createError({ message: PLEASE_SELECT_A_PROPRIETOR_GROUP });
        }

        return true;
      }
    ),
  contextResolver
);

export default memoizeSchemaWithContext(resolveDeceasedProprietorGroups, contextResolver);
