import '@sympli/ui-framework/extensions';
import 'src/utils/http/overrides';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import NewRelicBrowser from 'new-relic-browser';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

import WithAuthentication from 'src/@core/auth/WithAuthentication';
import environments from 'src/@core/environments';
import ThemeWrapper from 'src/@core/theme/ThemeWrapper';
import NavigationTracker from './@core/components/router/NavigationTracker';
import AppContent from './AppContent';
import TopPanelContainer from './components/layout/v2/TopPanelContainer';
import configureStore from './store/configureStore';

import './index.css';

declare global {
  interface Window {
    simulateError(): void;
    simulateMessage(): void;
    newrelic?: typeof NewRelicBrowser;
    NR_APP_ID?: string;
    NR_RELEASE_ID?: string;
  }
}

const { APP_ENV: releaseName } = environments; // release name must be resolved based on domain
if (typeof window.NR_RELEASE_ID === 'string') {
  // we need to add release info otherwise source map won't be correctly mapped by NewRelic
  window.newrelic?.addRelease(releaseName, window.NR_RELEASE_ID);
}

Logger.capturePageAction(PageActionEnum.RouteInitialPageLoad);

// This must be the first line https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// Temp solution for disable console on production
// if (import.meta.env.PROD) {
//   const noop = () => {};
//   console.log = noop;
//   console.warn = noop;
//   console.error = noop;
// }

/*
  React Router 6 accepts a BASENAME, for example '/app', 
  but it wouldn't allow user to enter '/' and redirect to that '/app'
  so we have to manipulate window object to redirect
*/
const { BASENAME } = environments;
if (window.location.pathname === '/') {
  window.location.replace(BASENAME);
}

const rootElement: HTMLElement = document.getElementById('root') as HTMLElement;
const REDUX_STORE = configureStore({});

// this is necessary for smoke test to work
const WithConditionalAuthentication = ({ children }: { children: React.ReactNode }) => {
  const topPanel = React.useMemo(() => <TopPanelContainer />, []);
  if (import.meta.env.VITE_SMOKE_TEST === 'true') {
    return <>{children}</>;
  }

  return <WithAuthentication topPanel={topPanel}>{children}</WithAuthentication>;
};

ReactDOM.render(
  <ThemeWrapper rootElement={rootElement}>
    <ReduxProvider store={REDUX_STORE}>
      <BrowserRouter basename={environments.BASENAME}>
        <NavigationTracker>
          <WithConditionalAuthentication>
            <AppContent performProfileFetch={import.meta.env.VITE_SMOKE_TEST === 'true'} />
          </WithConditionalAuthentication>
        </NavigationTracker>
      </BrowserRouter>
    </ReduxProvider>
  </ThemeWrapper>,
  rootElement
);

window.simulateError = function simulateError() {
  const scope = Logger.getDefaultScope();
  Logger.captureException(new Error(`Test simulation`), scope.setExtraAttributes({ test: `Test ${new Date()}` }));
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
