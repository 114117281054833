import { HttpTypes } from '@sympli/api-gateway/types';

import { WorkspaceDetailRouteParams } from 'src/models/workspace';

export enum FinancialLineItemLabelEnum {
  Payment = 'destination',
  SourceFund = 'source fund'
}

export enum UnlinkLineItemTypeEnum {
  UnlinkPayment = 0,
  UnlinkSourceFund = 1,
  Other = 2
}

export interface EditDirectionCommonProps {
  canEdit: boolean;
  queryParams: WorkspaceDetailRouteParams;
  detail: HttpTypes.WorkspaceDirectionsApiResponse;
  currentParticipant: HttpTypes.WorkspaceParticipant;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  directionsCategoriesDetail: HttpTypes.WorkspaceDirectionsCategoriesApiResponse;
  portalIdForDirectionSummary: string;
  portalIdForFormSubmit: string;
  requiresStampDuty: boolean;
  onFormCancel(): void;
  havePurchaserParticipant?: boolean;
  numberOfLinkedWorkspaces?: number;
  hasManageWorkspacePermission: boolean;
}
