import { VIC_ADDRESS_FORM_CONFIG, VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/vic/2-28/config';
import { AddressFieldFormConfig } from '@sympli-mfe/document-forms-framework/components/address-field';

// Define any form-specific configuration in this file

export const VIC_NOA_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG
};

// [WEB-12460] All addresses allow interstate address except for property address.
export const VIC_NOA_PROPERTY_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...VIC_ADDRESS_FORM_CONFIG,
  overseasAddressFormConfig: false
};

export const VIC_NOA_CORRESPONDENCE_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...VIC_CORRESPONDENCE_ADDRESS_FORM_CONFIG
};
