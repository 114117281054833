import { createSelector, defaultMemoize } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { Store } from 'src/store/reducers';

const getWorkspaceDocumentList = ({ state }: LodgementDetailSelectorModel) => state.workspaceDocuments.items;

export const getCurrentParticipantDocuments = (currentParticipantId: string, documents: HttpTypes.WorkspaceDocumentSummary[]): HttpTypes.WorkspaceDocumentSummary[] => {
  const docs = documents.filter(document => {
    // The document is available for current user, only if:
    // current user is one of the participant in the document
    return document.documentParticipants.some(participant => {
      return participant.id === currentParticipantId;
    });
  });

  const supportingDocs = documents.flatMap(d => d.supportingDocuments ?? []);

  if (supportingDocs.length) {
    return docs.concat(getCurrentParticipantDocuments(currentParticipantId, supportingDocs));
  }

  return docs;
};

interface LodgementDetailSelectorModel {
  state: Pick<Store, 'workspaceDocuments'>;
  documentId: string;
}
export const documentLodgementDetailSelector = createSelector<
  LodgementDetailSelectorModel,
  HttpTypes.WorkspaceDocumentSummary[],
  string,
  HttpTypes.DocumentLodgementDetail | undefined
>(
  getWorkspaceDocumentList, //
  ({ documentId }: LodgementDetailSelectorModel) => documentId,
  (documents: HttpTypes.WorkspaceDocumentSummary[], documentId: string) => {
    if (!documents.length || documentId == null) {
      return undefined;
    }

    //flatten supporting documents and documents.
    const flattenedDocuments = documents.flatMap(d => d.supportingDocuments ?? []).concat(documents);
    return flattenedDocuments.find(doc => doc.documentId === documentId)?.lodgementDetail;
  }
);

interface AvailableDocumentSelectorDataModel {
  documentList: Array<HttpTypes.WorkspaceDocumentSummary>;
  participantId: string;
}

const getDocumentList = (data: AvailableDocumentSelectorDataModel) => data.documentList;
const getCurrentParticipantId = (data: AvailableDocumentSelectorDataModel) => data.participantId;

export const currentParticipantDocumentListSelector = createSelector<
  //
  AvailableDocumentSelectorDataModel,
  HttpTypes.WorkspaceDocumentSummary[],
  string,
  HttpTypes.WorkspaceDocumentSummary[]
>(
  getDocumentList, //
  getCurrentParticipantId,
  (documentList, currentParticipantId) => {
    const participantDocuments = getCurrentParticipantDocuments(currentParticipantId, documentList);
    return participantDocuments;
  }
);

const DEFAULT_MESSAGES: HttpTypes.DocumentLodgementDetail['documentLodgementCompliances'] = [];
export const readyToSignDocumentLodgementCompliancesSelector = createSelector<
  //
  AvailableDocumentSelectorDataModel,
  HttpTypes.WorkspaceDocumentSummary[],
  string,
  HttpTypes.DocumentLodgementDetail['documentLodgementCompliances']
>(
  getDocumentList, //
  getCurrentParticipantId,
  (documentList, participantId) => {
    const currentParticipantDocumentList = currentParticipantDocumentListSelector({ participantId, documentList });
    const readyToSignDocList = currentParticipantDocumentList.filter(document => document.documentStatus.id === HttpTypes.DocumentStatusEnum.Signing);

    const mergedMessages: HttpTypes.DocumentLodgementDetail['documentLodgementCompliances'] = readyToSignDocList.reduce(
      (messageList: HttpTypes.DocumentLodgementDetail['documentLodgementCompliances'], { lodgementDetail }: HttpTypes.WorkspaceDocumentSummary) => {
        if (lodgementDetail) {
          messageList.push(...lodgementDetail.documentLodgementCompliances);
        }
        return messageList;
      },
      []
    );

    return mergedMessages.length ? mergedMessages : DEFAULT_MESSAGES;
  }
);

// todo: somehow this method is shared with financial, so make lodgementCase optional. We should not combine them all in one method
export const getDocumentsReadyToSign = defaultMemoize((documents: HttpTypes.WorkspaceDocumentSummary[], lodgementCaseId?: string): HttpTypes.WorkspaceDocumentSummary[] => {
  const allDocuments = documents.concat(documents.flatMap(d => d.supportingDocuments ?? []));
  // Sign permissions already calculated from backend response
  // See Document domain model - VerifySignAccess in DocumentService

  const signedDocs = allDocuments.filter(d => d.permissions.some(x => x === HttpTypes.DocumentPermissionEnum.Sign));

  if (lodgementCaseId) {
    return signedDocs.filter(d => d.lodgementCaseId === lodgementCaseId);
  }

  return signedDocs;
});
