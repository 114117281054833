import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { EnvConfig, fetchEnvConfig } from './fetchLocalEnvConfig';
import { AppEnvironmentEnum } from './models';

const hostname = window.location.hostname.toLowerCase();
const isLocalhost = hostname === AppEnvironmentEnum.local;

//! you don't need to change value here anymore

const getEnvFromDomain = (): EnvConfig => {
  return {
    environment: hostname.replace('sympli.com.au', '').split('.')[1] || 'production',
    localApi: false,
    localRealtime: false
  };
};

const getEnvSelection = async (): Promise<EnvConfig> => {
  try {
    if (isLocalhost) {
      // now we read from env file which we choose when yarn start
      return await fetchEnvConfig();
    } else {
      return getEnvFromDomain();
    }
  } catch (error) {
    Logger.console(SeverityEnum.Error, error);
    return getEnvFromDomain();
  }
};

const selected_env = await getEnvSelection();

const SERVICE_ENV: string = selected_env.environment;
const LOCAL_API: boolean = selected_env.localApi;
const LOCAL_REALTIME: boolean = selected_env.localRealtime;
export { SERVICE_ENV, LOCAL_API, LOCAL_REALTIME };

// You don't need to update these values anymore.
const TARGET_MACHINE = selected_env.localApi ? 'http://localhost:4000' : 'https://api.[0].sympli.com.au';
const SOCKET_SERVER_URL = selected_env.localRealtime ? 'http://localhost:3100' : 'https://api.[0].sympli.com.au';

const TARGET_API = '/gateway/api/1';
const TARGET_API_V2 = '/gateway/api/2';

const STORE_NAMESPACE_PREFIX = 'tickle-ui';

const BASENAME = '/app';

const APP_ENV: AppEnvironmentEnum = isLocalhost ? AppEnvironmentEnum.local : (SERVICE_ENV as AppEnvironmentEnum);

export const CONFIG_PATH = isLocalhost ? '/configs/config.local.json' : '/configs/config.json';

export interface RunTimeEnv {
  SOCKET_SERVER_URL: string;
  TARGET_MACHINE: string;
  APP_ENV: AppEnvironmentEnum;
  STORE_NAMESPACE: string;
  TARGET_API: string;
  BASENAME: string;
  TARGET_API_V2: string;
}

export function resolveRunTimeEnv(): RunTimeEnv {
  const STORE_NAMESPACE = `${STORE_NAMESPACE_PREFIX}_${APP_ENV}`;
  // local env
  if (APP_ENV === 'localhost') {
    return {
      SOCKET_SERVER_URL: SOCKET_SERVER_URL.replace('[0].', `${SERVICE_ENV}.`),
      TARGET_MACHINE: TARGET_MACHINE.replace('[0].', `${SERVICE_ENV}.`),
      STORE_NAMESPACE,
      TARGET_API,
      TARGET_API_V2,
      BASENAME,
      APP_ENV
    };
  }

  const isProduction = APP_ENV === 'production';
  const replaceString = isProduction ? '' : `${APP_ENV}.`;

  return {
    SOCKET_SERVER_URL: SOCKET_SERVER_URL.replace('[0].', replaceString),
    TARGET_MACHINE: TARGET_MACHINE.replace('[0].', replaceString),
    STORE_NAMESPACE,
    TARGET_API,
    TARGET_API_V2,
    BASENAME,
    APP_ENV
  };
}
