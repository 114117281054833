import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { actionFetchWorkspaceAssignableGroups } from '../actions/workspaceAssignableGroups';

const fetchWorkspaceAssignableGroups = () => {
  return http.get<HttpTypes.WorkspaceAssignableGroupListApiResponse>('/workspaces/assignable-groups');
};

function* sagaFetchWorkspaceAssignableGroups() {
  try {
    const data = yield* call(fetchWorkspaceAssignableGroups);
    yield put(actionFetchWorkspaceAssignableGroups.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceAssignableGroups.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchWorkspaceAssignableGroups.request, sagaFetchWorkspaceAssignableGroups)
];
