import * as React from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { NswAddressSelectField_2_21 as AddressSelectField } from '@sympli-mfe/document-components/address-field/nsw/2-21';
import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import DocumentFieldArray from '@sympli-mfe/document-forms-framework/components/document-field-array';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { createModelKeyAppender, getPartyOptionsExcludingSiblings } from '@sympli-mfe/document-forms-framework/utils';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { NSW_CAVEAT_ADDRESS_FORM_CONFIG } from '../../config';
import { ADDRESS_BOOK_KEY, CaveatModel, CaveatParty, PARTY_BOOK_KEY } from '../../models';
import { resolveDefaultItem } from './models';

interface Props {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  portalIdDialogs: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
  partyOptions: LookupItemModel[];
}

type FormValues = CaveatModel;

class SectionCaveator extends React.PureComponent<Props> {
  render() {
    const { name, disabled, formikProps, partyOptions, portalIdDialogs, focusRef } = this.props;
    const items: CaveatParty[] = _get(formikProps.values, name) ?? [];

    return (
      <DocumentFieldArray
        arrayBinding={name}
        createNewItem={resolveDefaultItem}
        itemTitle="Caveator"
        addButtonTitle="Add caveator"
        minItems={1}
        maxItems={20}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="formGroup"
        focusRef={focusRef}
        renderItem={({ itemIndex, itemBinding, itemFocusRef, nextItemFocusRef }) => (
          <CaveatorItem //
            name={itemBinding}
            itemIndex={itemIndex}
            items={items}
            focusRef={itemFocusRef}
            nextItemFocusRef={nextItemFocusRef}
            disabled={disabled}
            partyOptions={partyOptions}
            dialogPortalId={portalIdDialogs}
          />
        )}
      />
    );
  }
}

interface CaveatorItemProps {
  name: string;
  disabled: boolean;
  dialogPortalId: string;
  partyOptions: LookupItemModel[];
  items: CaveatParty[];
  itemIndex: number;
  focusRef: React.RefObject<ButtonBaseActions>;
  nextItemFocusRef?: React.RefObject<ButtonBaseActions>;
}

function CaveatorItem({ name, itemIndex, items, dialogPortalId, disabled, focusRef, nextItemFocusRef, partyOptions }: CaveatorItemProps): JSX.Element {
  const filteredPartyOptions = getPartyOptionsExcludingSiblings(partyOptions, items, itemIndex);
  const fieldName = createModelKeyAppender<CaveatParty>(name);
  const addressFieldFocusRef = React.useRef<ButtonBaseActions>(null);

  return (
    <>
      <PartySelectField
        name={fieldName('partyBookId')}
        partyFormConfig={NSW_CAVEAT_PARTY_FORM_CONFIG}
        disabled={disabled}
        bookRef={PARTY_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        optionsOverride={filteredPartyOptions}
        focusRef={focusRef}
        nextFocusRef={addressFieldFocusRef}
      />
      <AddressSelectField
        name={fieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        focusRef={addressFieldFocusRef}
        nextFocusRef={nextItemFocusRef}
        addressFormConfig={NSW_CAVEAT_ADDRESS_FORM_CONFIG}
      />
    </>
  );
}

export default SectionCaveator;
