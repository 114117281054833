import * as React from 'react';

import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Table, { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';
import { IconCheck, IconDelete, IconExclamation, IconMapMaker, IconTimes } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import { TitleListItem, TitleListItemStatusEnum } from './models';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  titles: TitleListItem[];
  isFormValid: boolean;
  onDeleteTitleClick(e: React.MouseEvent<HTMLButtonElement>, referenceNumber: string | null): void;
  deleteDisabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;
class TitleList extends React.PureComponent<Props> {
  private getColumnWithAddressDefinition(): ColumnsModel<TitleListItem> {
    const { classes, deleteDisabled, isFormValid } = this.props;

    return {
      deleteButton: {
        label: 'Title',
        className: classes.tableButtonColumn,
        formatter: ({ row }: FormatterInputModel<TitleListItem>) => {
          const { status } = row;
          switch (status) {
            case TitleListItemStatusEnum.Existing:
              return <IconMapMaker className={classes.iconMapMarker} />;
            default:
              return (
                <IconButton
                  disabled={deleteDisabled}
                  className={classes.deleteButton}
                  color="primary"
                  data-reference-number={row.titleReference}
                  onClick={this.handleOnIconClick}
                  size="large"
                >
                  <IconDelete className={classNames(classes.deleteButtonIcon, deleteDisabled && classes.deleteButtonIconDisabled)} />
                </IconButton>
              );
          }
        }
      },
      actionView: {
        label: '',
        className: classes.tableTitleColumn,
        formatter: ({ row }: FormatterInputModel<TitleListItem>) => {
          const { status } = row;
          return (
            <div className={classNames(classes.tableCell, status === TitleListItemStatusEnum.Existing && classes.existingTitle)}>
              <div>{row.titleReference}</div>
            </div>
          );
        }
      },
      address: {
        label: 'Address',
        formatter: ({ row }: FormatterInputModel<TitleListItem>) => {
          const { status } = row;
          return (
            <div className={classNames(classes.tableCell, status === TitleListItemStatusEnum.Existing && classes.existingTitle)}>
              <div>{row.address}</div>
            </div>
          );
        }
      },
      status: {
        label: 'Status',
        formatter: ({ row }: FormatterInputModel<TitleListItem>) => {
          const { status, message, additionalMessage } = row;
          // we want to make sure that we have not received any reason
          // sometimes we can receive corrupted data that says isElectronicLodgementEligible but with ineligibility reason
          // such as "The title does not have a controller. Please contact Victoria Land Registry Services."
          switch (status) {
            case TitleListItemStatusEnum.Warning:
              return (
                <div className={classes.tableCell}>
                  <IconExclamation className={classes.iconWarning} />
                  {message} {isFormValid ? additionalMessage : null}
                </div>
              );
            case TitleListItemStatusEnum.Error:
              return (
                <div className={classes.tableCell}>
                  <IconTimes className={classes.iconError} />
                  <div>{message}</div>
                </div>
              );
            case TitleListItemStatusEnum.Existing:
              return (
                <div className={classNames(classes.tableCell, classes.existingTitle)}>
                  <div>{message}</div>
                </div>
              );
            default:
              return (
                <div className={classes.tableCell}>
                  <IconCheck className={classes.iconSuccess} />
                  <div>{message}</div>
                </div>
              );
          }
        }
      }
    };
  }

  public render() {
    const { classes, titles } = this.props;
    if (!titles.length) {
      return null;
    }

    return (
      <div className={classes.container}>
        <Table
          //
          pagination={false}
          sortable={false}
          classes={{
            //
            tableCellBody: classes.tableCellBody,
            tableCellHead: classes.tableCellHead,
            tableRowRoot: classes.tableRowRoot
          }}
          rows={titles}
          columns={this.getColumnWithAddressDefinition()}
          noDataMessage="Enter your title reference details to begin"
          bordersWithSpacing={false}
        />
      </div>
    );
  }
  public handleOnIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const referenceNumber = dataAttribute('reference-number', e);
    this.props.onDeleteTitleClick(e, referenceNumber);
  };
}

export default withStyles(styles)(TitleList);
