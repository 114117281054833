import { useState } from 'react';

import classNames from 'classnames';
import { titleCase } from 'title-case';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { LineItemsModel } from '../../models';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2082603015/Payment+summary+box
export interface Props {
  lineItems?: LineItemsModel;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
}

const PaymentLineItems = ({ lineItems, jurisdictionId }: Props) => {
  const { total, totalGst, documentFees } = lineItems || {};
  const classes = useStyles();
  const sympliServiceFeeAmount = lineItems?.serviceFees.reduce((pre, cur) => pre + cur.fee.total, 0);
  const documentFeeAmount = documentFees?.reduce((pre, cur) => pre + cur.fee.total, 0);
  const [showBreakdowns, setShowBreakdowns] = useState(false);

  return (
    <div className={classNames(classes.root101, classes.feeItems101)}>
      <FlexLayout className={classes.row101} justifyContent="space-between">
        <div>Sympli (Service fee):</div>
        <div>{currency(sympliServiceFeeAmount ?? 0)}</div>
      </FlexLayout>

      {documentFeeAmount !== 0 && (
        <FlexLayout className={classes.row101} justifyContent="space-between">
          <div>{getConsolidationFeeItemName(jurisdictionId)}:</div>
          <div>
            <ButtonLink onClick={() => setShowBreakdowns(!showBreakdowns)} color="inherit" className={classes.breakdown}>
              {showBreakdowns ? 'Hide' : 'Show'}
            </ButtonLink>
          </div>
          <div>{currency(documentFeeAmount ?? 0)}</div>
        </FlexLayout>
      )}

      {showBreakdowns &&
        documentFees?.map((item, i) => {
          return (
            <FlexLayout className={classNames(classes.breakdown, classes.breakdownItalic)} justifyContent="space-between" key={i}>
              {showBreakdowns && <div>&nbsp;- {titleCase(item.description)}</div>}
              <div>{currency(item.fee.total)}</div>
            </FlexLayout>
          );
        })}
      <FlexLayout className={classNames(classes.row101, classes.feeTotal101)} justifyContent="space-between">
        <div>Total:</div>
        <div>{currency(total ?? 0)}</div>
      </FlexLayout>
      <FlexLayout className={classNames(classes.gstInclusive101)} justifyContent="space-between">
        <div>Inclusive of {currency(totalGst ?? 0)} GST</div>
      </FlexLayout>
    </div>
  );
};

function getConsolidationFeeItemName(jurisdiction) {
  const lodgementFeeCategory = 'Lodgement fee';
  switch (jurisdiction) {
    case HttpTypes.JurisdictionsEnum.NSW:
      return `NSW LRS (${lodgementFeeCategory})`;
    case HttpTypes.JurisdictionsEnum.QLD:
      return `Titles QLD (${lodgementFeeCategory})`;
    case HttpTypes.JurisdictionsEnum.VIC:
      return `SER VIC (${lodgementFeeCategory})`;
    case HttpTypes.JurisdictionsEnum.SA:
      return `Land Services SA (${lodgementFeeCategory})`;
    case HttpTypes.JurisdictionsEnum.WA:
      return `Landgate (${lodgementFeeCategory})`;
    case HttpTypes.JurisdictionsEnum.ACT:
      return `Access Canberra ACT (${lodgementFeeCategory})`;
    default:
      return lodgementFeeCategory;
  }
}

export default PaymentLineItems;
