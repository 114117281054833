import { Reducer } from 'redux';

import currentPageFeatureFlags from './currentPageFeatureFlags';
import directionsList from './directionsList';
import featureFlagsControlledByUI from './featureFlagsControlledByUI';
import globalErrors from './globalErrors';
import globalSimpleNotification from './globalSimpleNotification';
import mfaCheck from './mfaCheck';
import profile from './profile';
import profileUserReportsPermissions from './profileUserReportsPermissions';
import searchBoxUpdatedFeed from './searchBoxUpdated';
import searchBoxUpdatedArchivedFeed from './searchBoxUpdatedArchived';
import workspaceAssignableGroups from './workspaceAssignableGroups';

export const coreReducers = {
  currentPageFeatureFlags,
  directionsList,
  featureFlagsControlledByUI,
  // global popup error message dialog
  globalErrors,
  // global snack bar simple notification
  globalSimpleNotification,
  mfaCheck,
  profile,
  profileUserReportsPermissions,
  searchBoxUpdatedFeed,
  searchBoxUpdatedArchivedFeed,
  workspaceAssignableGroups
};

type ExtractState<T> = T extends Reducer<infer S, any> ? S : never;
type ConvertReducers<T> = {
  [K in keyof T]: ExtractState<T[K]>;
};

export type CoreStore = ConvertReducers<typeof coreReducers>;
