import { ProgressStep } from 'src/containers/shared/lodgement-case-status/models';

export enum SelectedSettlementDistributionsTypeEnum {
  Distributions = 0,
  StampDuty = 1,
  TrustAccountAuthorisationRecords = 3
}

export class FinancialSettlementStatusSteps {
  static InPreparation: ProgressStep = {
    value: 1,
    description: 'In Preparation'
  };

  static Ready: ProgressStep = {
    value: 2,
    description: 'Ready'
  };

  static Settling: ProgressStep = {
    value: 3,
    description: 'Settling'
  };

  static Settled: ProgressStep = {
    value: 4,
    description: 'Settled'
  };

  static Disbursing: ProgressStep = {
    value: 5,
    description: 'Disbursing'
  };

  static Disbursed: ProgressStep = {
    value: 6,
    description: 'Disbursed'
  };

  static All: ProgressStep[] = [this.InPreparation, this.Ready, this.Settling, this.Settled, this.Disbursing, this.Disbursed];
}

// data returned
export enum FinancialSettlementStatusEnum {
  Initial = 'initial',
  InPreparation = 'inpreparation',
  Signed = 'signed',
  Ready = 'ready',
  Settling = 'settling',
  Settled = 'settled',
  Disbursing = 'disbursing',
  Disbursed = 'disbursed'
}

export enum FinancialSettlementScheduleStatusEnum {
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  Signed = 'SIGNED'
}

export enum AllParticipantFinancialSettlementScheduleStatusEnum {
  Invited = 'INVITED',
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  PartiallySigned = 'PARTIALLY SIGNED',
  Signed = 'SIGNED',
  NotApplicable = 'N/A'
}

export enum TrustAccountAuthorisationRecordStatusEnum {
  Prepared = 'PREPARED',
  Signed = 'SIGNED'
}

export const TrustAccountAuthorisationRecordSteps: string[] = [TrustAccountAuthorisationRecordStatusEnum.Prepared, FinancialSettlementScheduleStatusEnum.Signed];

export enum SectionTypeEnum {
  Payments = 0,
  Sources = 1
}
