import { FeatureToggleEnum } from '../auth/feature-toggle/models';
import { PlatformEnum } from '../store/actions/currentPageFeatureFlags';

interface FeatureFlags {
  name: FeatureToggleEnum;
  isControlledBy: PlatformEnum;
  isEnabled: boolean;
}

/* 
    calculate featureFlags status according two Selectors
    it contains flags enabled by both LaunchDarkely and UI, platform is LD, isEnabled is true
    it also contains flags enabled by LD but disabled by UI, platform is UI, isEnabled is false
    it also contains flags disabled by LD but enabled by UI, platform is UI, isEnabled is true
    it does not contain flags disabled by both LD and UI.
  */
export const getFeatureFlags = (featuresControlledByUI: Map<FeatureToggleEnum, boolean>, enabledFeaturesByLD?: FeatureToggleEnum[]): Array<FeatureFlags> => {
  const flags: Array<FeatureFlags> = [];

  if (enabledFeaturesByLD) {
    enabledFeaturesByLD.map(flag =>
      flags.push({
        //
        name: flag,
        isEnabled: true,
        isControlledBy: PlatformEnum.LaunchDarkley
      })
    );
  }

  if (featuresControlledByUI) {
    featuresControlledByUI.forEach((isEnabled, flagName) => {
      const enabledByLD = enabledFeaturesByLD?.includes(flagName);

      // flag disabled by LD, but enabled by UI
      if (!enabledByLD && isEnabled) {
        flags.push({
          //
          name: flagName,
          isEnabled: true,
          isControlledBy: PlatformEnum.TickleUI
        });
      }

      // flag enabled by LD, but disabled by UI
      if (enabledByLD && !isEnabled) {
        // update platForm to UI and isEnabled to false
        const flag = flags.find(f => f.name === flagName) as FeatureFlags;
        flag.isEnabled = false;
        flag.isControlledBy = PlatformEnum.TickleUI;
      }
    });
  }
  return flags;
};
