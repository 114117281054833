import { call, put, select, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { getTimeInTimeZone } from 'src/@core/utils/timezone';
import { Store } from 'src/store/reducers';
import { sdk } from 'src/utils/http';
import { actionFetchSubscriberActivityLogsFeed, SubscriberActivityLogsApiRequest } from '../actions/logs';
import { getTemplate } from '../log/helper';
import { LogModel } from '../log/models';

const fetchSubscriberActivityLogsFeed = (query: SubscriberActivityLogsApiRequest): Promise<HttpTypes.SubscriberActivityLogListApiResponse> => {
  const { pageNumber, pageSize } = query;
  // const uri = `/settings/reporting/logs?${queryString.stringify(q)}`;
  // return http.get<HttpTypes.SubscriberActivityLogListApiResponse>(uri);
  return sdk.settings.subscriber.listActivityLogs({
    pageSize,
    pageNumber
  });
};

function selectUserProfileTimezone(state: Store) {
  return state.profile.data?.timezone;
}

function* sagaFetchSubscriberActivityLogsFeed(action: ReturnType<typeof actionFetchSubscriberActivityLogsFeed.request>) {
  try {
    const { items, ...rest } = yield* call(fetchSubscriberActivityLogsFeed, action.payload);

    const timezone: string = yield select(selectUserProfileTimezone);
    const jurisdictionId = HttpTypes.UserTimeZoneEnum[timezone];

    const converted: Array<LogModel> = items.map(item => {
      const { category, data, created } = item;
      const dataObject = JSON.parse(data);
      return {
        ...item,
        data: dataObject,
        created: getTimeInTimeZone(new Date(created), jurisdictionId),
        template: getTemplate(category, dataObject)
      };
    });
    yield put(actionFetchSubscriberActivityLogsFeed.success({ data: { ...rest, items: converted } }));
  } catch (error) {
    yield put(actionFetchSubscriberActivityLogsFeed.failure({ error }));
  }
}

export default [takeLatest(actionFetchSubscriberActivityLogsFeed.request, sagaFetchSubscriberActivityLogsFeed)];
