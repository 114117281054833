import * as React from 'react';

import queryString from 'query-string';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger from '@sympli/ui-logger';

import { useProfile } from 'src/@core/store/reducers/profile';
import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import http from 'src/utils/http';
import { CreateLodgementOnlyWorkspaceSharedModel, CreateLodgeOnlyWorkspaceStepEnum, SubscriberSearchApiResponse, SubscriberSearchItemModel } from '../../models';
import InviteParticipants from './InviteParticipants';

interface Props {
  onGlobalValuesUpdate: (value?: Partial<CreateLodgementOnlyWorkspaceSharedModel>) => CreateLodgementOnlyWorkspaceSharedModel;
  onStepChange: (step: CreateLodgeOnlyWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
}

function InviteParticipantsContainer(props: Props) {
  const {
    matterDetailsStepData: { jurisdictionId }
  } = props.onGlobalValuesUpdate();
  const currentSubscriberData: HttpTypes.UserProfileModel = useProfile().data!;
  const { onClose } = props;

  const onWorkspaceCreated = React.useCallback(
    (props: { workspaceId: string; participantId: string; documentId: string }) => {
      const link = resolveDocumentDetailLink(props);
      onClose(link);
    },
    [onClose]
  );

  const onSubscriberSuggestionsRequested = React.useCallback(
    async (name: string): Promise<SubscriberSearchApiResponse> => {
      try {
        let query: string = queryString.stringify({ jurisdiction: jurisdictionId });
        let url: string = '/workspaces/registrationOnly/invitation-subscribers/';
        const response = await http.get<SubscriberSearchApiResponse>(`${url}${encodeURIComponent(name)}?${query}`);
        // External subscribers will not have an Id, Sympli subscribers will not have an ElnoSubscriberId
        return response.map(({ id, elnoSubscriberId, name, elnoId, abn }: SubscriberSearchItemModel) => {
          return { id: id ?? elnoSubscriberId, name, elnoId, elnoSubscriberId, abn };
        });
      } catch (e) {
        Logger.captureException(e);
        throw e;
      }
    },
    [jurisdictionId]
  );

  return (
    <InviteParticipants //
      {...props}
      currentSubscriberData={currentSubscriberData}
      onWorkspaceCreated={onWorkspaceCreated}
      onSubscriberSuggestionsRequested={onSubscriberSuggestionsRequested}
    />
  );
}

export default React.memo(InviteParticipantsContainer);
