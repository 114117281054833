import React from 'react';

import { useDispatch } from 'react-redux';

import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from '../../../auth/feature-toggle/models';
import { AppEnvironmentEnum } from '../../../environments/models';
import { useSafeDispatch } from '../../../hooks';
import { PlatformEnum } from '../../../store/actions/currentPageFeatureFlags';
import { actionClearFeatureFlagsControlledByUI, actionUpdateFeatureFlagsControlledByUI, FlagStatusByUI } from '../../../store/actions/featureFlagsControlledByUI';
import useStoreSelector from '../../../store/useStoreSelector';
import FeatureFlagsController, { OptionalFormikProps, ContainerProps as Props } from './FeatureFlagsController';
import { FeatureFlagsControllerFormikModel } from './FeatureFlagsControllerFormik';

function FeatureFlagsControllerContainer({ environmentName, className }: Props) {
  // no stale data issue, currentPageFeatureFlags will be cleared when routing changes
  const featuresUsedOnCurrentPage = useStoreSelector(store => store.currentPageFeatureFlags.items);
  const dispatch = useSafeDispatch(useDispatch());
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const formikProps: OptionalFormikProps = React.useMemo(() => {
    // if no feature flags are used, we should display nothing
    if (!featuresUsedOnCurrentPage.size) {
      return {};
    }

    const options: LookupEnumModel[] = [];
    const optionsWithIcon: LookupEnumModel[] = [];

    const initialValues: FeatureFlagsControllerFormikModel = {
      featureGroup: []
    };

    [...featuresUsedOnCurrentPage].map(([flagName, flagValue], index) => {
      if (flagValue.isEnabled) {
        // 'featureGroup' only contains enabled flags
        initialValues.featureGroup.push(index);
      }

      // 'options' contains every flags used on this page
      options.push({
        //
        id: index,
        name: flagName
      });

      // 'optionsWithIcon' will add 🔥 if flag is controlled by UI
      return optionsWithIcon.push({
        //
        id: index,
        name: flagValue.isControlledBy === PlatformEnum.LaunchDarkley ? flagName : `${flagName} 🔥`
      });
    });

    const handleOnSubmit = (values: FeatureFlagsControllerFormikModel) => {
      const { featureGroup } = values; // featureGroup only contains
      const flagStatusArray: Array<FlagStatusByUI> = [];
      // loop through options to recover data structure {name: FlagName, status: boolean}
      options.map(option =>
        featureGroup.includes(option.id) //
          ? flagStatusArray.push({ name: option.name as FeatureToggleEnum, status: true })
          : flagStatusArray.push({ name: option.name as FeatureToggleEnum, status: false })
      );
      dispatch(actionUpdateFeatureFlagsControlledByUI(flagStatusArray));
      handleOnClose();
    };

    const handleOnReset = () => {
      dispatch(actionClearFeatureFlagsControlledByUI());
      handleOnClose();
    };

    return { options: optionsWithIcon, initialValues, onSubmit: handleOnSubmit, onReset: handleOnReset };
  }, [dispatch, featuresUsedOnCurrentPage]);

  if (![AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environmentName)) {
    return null;
  }

  return (
    <FeatureFlagsController //
      className={className}
      environmentName={environmentName}
      flagNumber={featuresUsedOnCurrentPage.size}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      onClick={handleOnClick}
      {...formikProps}
    />
  );
}

export default FeatureFlagsControllerContainer;
