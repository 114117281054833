import { createAction, createAsyncAction } from 'typesafe-actions';

import { RegOnlyPaymentSummaryApiResponse } from '../shared/payment-summary-box/models';
import { PaymentSummaryApiRequest, SaveDebitAccountApiRequest } from './models';

export const actionFetchPaymentSummary = createAsyncAction(
  //
  'FETCH_PAYMENT_SUMMARY',
  'FETCH_PAYMENT_SUMMARY_SUCCESS',
  'FETCH_PAYMENT_SUMMARY_ERROR'
)<
  //
  PaymentSummaryApiRequest,
  RegOnlyPaymentSummaryApiResponse,
  { error: Error }
>();

export const actionUpdatePaymentSummary = createAction('UPDATE_PAYMENT_SUMMARY')<RegOnlyPaymentSummaryApiResponse>();

export const actionSaveDebitAccount = createAsyncAction(
  //
  'SAVE_DEBIT_ACCOUNT',
  'SAVE_DEBIT_ACCOUNT_SUCCESS',
  'SAVE_DEBIT_ACCOUNT_ERROR'
)<
  //
  SaveDebitAccountApiRequest,
  undefined,
  { error: Error }
>();

export interface MergedDocumentsPdfApiRequest {
  workspaceId: string;
  participantId: string;
  documentIds: Array<string>;
}
export const actionFetchMergedDocumentsPdf = createAsyncAction(
  //
  'FETCH_MERGED_DOCUMENTS_PDF',
  'FETCH_MERGED_DOCUMENTS_PDF_SUCCESS',
  'FETCH_MERGED_DOCUMENTS_PDF_ERROR'
)<
  //
  MergedDocumentsPdfApiRequest,
  string,
  { error: Error }
>();
