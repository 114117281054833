import * as React from 'react';

import { Form, FormikProps } from 'formik';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import InvitationDescription from 'src/containers/shared/invitation-description';
import { RegistrationRoleMapping } from 'src/models/roles';
import { createArrayItemModelKeyAppender, modelKey } from 'src/utils/formUtils';
import {
  CreateLodgementOnlyWorkspaceInvitationsStepRequest,
  CreateLodgementOnlyWorkspaceInvitationsStepResponse,
  CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel,
  CreateLodgementOnlyWorkspaceSharedModel,
  CreateLodgeOnlyWorkspaceStepEnum,
  DocumentInvitationItemModel,
  DocumentWithInvitationModel,
  SubscriberSearchApiResponse
} from '../../../reg-only-form/models';
import { requiresLinkedWorkspace } from '../matter-detail/helpers';
import ParticipantInviteDetail from './components/participant-invite-detail';
import YourClients from './components/your-clients';
import { getInitialValues, prepareRegSubmitData } from './helpers';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

interface OwnProps {
  onGlobalValuesUpdate: (value?: Partial<CreateLodgementOnlyWorkspaceSharedModel>) => CreateLodgementOnlyWorkspaceSharedModel;
  onStepChange: (step: CreateLodgeOnlyWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  currentSubscriberData: HttpTypes.UserProfileModel;

  onWorkspaceCreated(args: { workspaceId: string; participantId: string; documentId: string });
  onSubscriberSuggestionsRequested(name: string): Promise<SubscriberSearchApiResponse>;
}

type Props = OwnProps & WithStyles<ClassKeys>;
type FormModel = CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel;

const fieldName = modelKey<CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel>();
const documentItemFieldName = createArrayItemModelKeyAppender<DocumentWithInvitationModel>(fieldName('documents'));

class InviteParticipants extends React.PureComponent<Props> {
  private formikProps: FormikProps<FormModel>;
  private globalValues: CreateLodgementOnlyWorkspaceSharedModel = this.props.onGlobalValuesUpdate();

  private get jurisdictionId(): HttpTypes.JurisdictionsEnum {
    // when user reached the invite step, this property won't be null
    return this.globalValues.matterDetailsStepData.jurisdictionId as HttpTypes.JurisdictionsEnum;
  }

  private get showClientRoles(): boolean {
    // client roles applies only for the first document
    // and only if there are more than one document roles to be selected from.

    const { roleOptions } = this.formikProps.values.documents[0];

    return roleOptions.length > 1;
  }

  private get showInvitations(): boolean {
    const result = this.formikProps.values.documents.some(({ selectedRoleIds, invitations }) => {
      return invitations.some(({ roleId }) => {
        return !selectedRoleIds.includes(roleId);
      });
    });

    return result;
  }

  private getInitialValues(): FormModel {
    return getInitialValues(this.props.currentSubscriberData, this.props.onGlobalValuesUpdate());
  }

  render() {
    return (
      <Formik //
        method="post"
        action="/workspaces/registration-only"
        initialValues={this.getInitialValues()}
        validationSchema={getValidationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
        validateOnMount={true}
      >
        {(formikProps: FormikProps<FormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private get disableContinue() {
    const { isValid, isSubmitting } = this.formikProps;
    if (!isValid || isSubmitting) {
      return true;
    }

    return false;
  }

  private renderForm(formikProps: FormikProps<FormModel>) {
    const { classes } = this.props;
    const { isSubmitting } = formikProps;
    this.formikProps = formikProps;

    Logger.console(SeverityEnum.Debug, 'errors', formikProps.errors);

    return (
      <Form>
        <fieldset disabled={isSubmitting} className={classes.fieldset}>
          {this.showClientRoles && this.renderClientRolesSelection()}
          {this.showInvitations && this.renderDocumentInvitations()}
          <InvitationDescription name={fieldName('invitationDescription')} />
        </fieldset>
        <WizardStepper //
          onBack={this.handleOnClickBack}
          nextLabel="Continue"
          onNext={this.handleOnNext}
          isLoading={isSubmitting}
          disabled={this.disableContinue}
        />
      </Form>
    );
  }

  private renderClientRolesSelection() {
    return (
      <YourClients //
        name={`${fieldName('documents')}[0]`} /* danger:disabled */
      />
    );
  }

  private resolveRoleLabel = (roleId: HttpTypes.WorkspaceRoleEnum): string => {
    if (roleId === HttpTypes.WorkspaceRoleEnum.ControllingParty) {
      if (requiresLinkedWorkspace(this.globalValues.matterDetailsStepData)) {
        return 'Controlling party';
      }
    }
    return RegistrationRoleMapping[roleId] || '';
  };

  private renderDocumentInvitations() {
    return (
      <>
        <FlexLayout alignItems="center">
          <Typography variant="subtitle1">Invite participants</Typography>
        </FlexLayout>
        <Divider className={this.props.classes.divider} />
        {this.formikProps.values.documents.map(this.renderDocumentInvitationDetail)}
      </>
    );
  }

  private renderDocumentInvitationDetail = ({ selectedRoleIds, invitations }: DocumentWithInvitationModel, documentIndex: number) => {
    const { isControllingParty } = this.globalValues.matterDetailsStepData;

    return invitations.map(({ roleId, subscriber }: DocumentInvitationItemModel, invitationIndex: number) => {
      // if user decided to represent this role, we don't offer invitation
      if (selectedRoleIds.includes(roleId)) {
        return null;
      }

      const name = `${documentItemFieldName(documentIndex, 'invitations')}[${invitationIndex}]`;
      const label = this.resolveRoleLabel(roleId);
      return (
        <ParticipantInviteDetail //
          key={name}
          name={name}
          label={label}
          jurisdictionId={this.jurisdictionId}
          roleId={roleId}
          workspaceTypeId={HttpTypes.WorkspaceTypeEnum.RegistrationOnly}
          isControllingParty={isControllingParty}
          setFieldValue={this.formikProps.setFieldValue}
          onSubscriberSuggestionsRequested={this.props.onSubscriberSuggestionsRequested}
        />
      );
    });
  };

  private handleOnClickBack = () => {
    Logger.setInteractionName('Create new - invite participants - back', 'click');
    this.props.onStepChange(CreateLodgeOnlyWorkspaceStepEnum.AddMatterDetails);
  };

  private handleOnNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    Logger.setInteractionName('Create new - invite participants - submit', 'click');
    this.formikProps.submitForm();
  };

  private handleOnPreSubmit = (values: CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel): CreateLodgementOnlyWorkspaceInvitationsStepRequest => {
    const regSubmitData = prepareRegSubmitData({ ...this.globalValues, inviteParticipantsStepData: values });
    return regSubmitData;
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel, CreateLodgementOnlyWorkspaceInvitationsStepResponse>) => {
    if (args.error) {
      return;
    }

    const { workspaceId, createdByParticipantId: participantId, createdDocuments } = args.response;
    this.props.onWorkspaceCreated({
      //
      workspaceId,
      participantId,
      documentId: createdDocuments[0].id
    });
  };
}

const StyledComponent = withStyles(styles)(InviteParticipants);
export default StyledComponent;
