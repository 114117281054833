import * as React from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { AccessToken, Token, TokenManagerInterface } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import { useOktaAuth } from 'src/@core/auth/okta';

const LOGOUT_TIMER_ID = 'LOGOUT_TIMER_ID';

// Idle timeout is set to 80% of the total expiration (eg: 24 mins of 30 mins)
// If user is idle for 80% of the total expiration, we stop the silent renew
// If user becomes active again, we resume silent renew
const IdleCheck = () => {
  const navigate = useNavigate();
  const [idlerTimeoutInMilliseconds, setIdlerTimeoutInMilliseconds] = React.useState<number | undefined>();
  const { oktaAuth } = useOktaAuth();
  const tokenManager: TokenManagerInterface = oktaAuth.tokenManager;

  const handler = React.useCallback(
    (key: string, expiredToken: Token) => {
      try {
        tokenManager.renew('idToken');
      } catch (error) {
        Logger.captureException(error);
      }
    },
    [tokenManager]
  );

  const { start } = useIdleTimer({
    timeout: idlerTimeoutInMilliseconds,
    onIdle: () => {
      tokenManager.off('expired');
      tokenManager.on('expired', () => {
        navigate('/logout');
      });

      /**
       * https://tickleme.atlassian.net/wiki/spaces/DEV/pages/3369009165/Solving+fun+facts+of+OKTA+Token+things
       * this is to solve the above issue
       *  */
      if (idlerTimeoutInMilliseconds) {
        const id = window.setTimeout(
          () => {
            navigate('/logout');
          },
          // 20% of okta token expire time
          idlerTimeoutInMilliseconds / 4
        );
        window.sessionStorage.setItem(LOGOUT_TIMER_ID, String(id));
      }
    },
    onActive: () => {
      tokenManager.off('expired');
      tokenManager.on('expired', handler);
      const id = window.sessionStorage.getItem(LOGOUT_TIMER_ID);

      if (id) {
        clearTimeout(Number(id));
        window.sessionStorage.removeItem(LOGOUT_TIMER_ID);
      }
    },
    startManually: true,
    crossTab: true
  });

  React.useEffect(() => {
    if (idlerTimeoutInMilliseconds != null) {
      start();
    }
  }, [idlerTimeoutInMilliseconds, start]);

  // we need to reset idle timer when user get a new access_token
  React.useEffect(() => {
    async function setup() {
      try {
        const accessToken = (await tokenManager.get('accessToken')) as AccessToken | undefined;

        if (!accessToken?.expiresAt) {
          return;
        }

        tokenManager.off('expired');
        tokenManager.on('expired', handler);

        let newIdlerTimeoutInSeconds = 30 * 60 * 0.8; // default 1800s * 80% (24mins), this in theory should never be used

        if (accessToken.claims.exp && accessToken.claims.iat) {
          newIdlerTimeoutInSeconds = (accessToken.claims.exp - accessToken.claims.iat) * 0.8; // we set idle timer as 80% of token expire time
        } else {
          Logger.captureException(new Error('OKTA Access token does not have exp or iat'));
        }

        setIdlerTimeoutInMilliseconds(newIdlerTimeoutInSeconds * 1000);
      } catch (error) {
        Logger.captureException(error);
      }
    }

    setup();
  }, [handler, oktaAuth, tokenManager]);

  return null;
};

export default React.memo(IdleCheck);
