import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { ProfileContextModel } from 'src/@core/auth/profile-security/Profile.context';
import { enum2IdNameObjArray } from 'src/@core/utils/enums';

const ReportTypeEnumDisplayNameMap: Record<HttpTypes.ReportTypeEnum, string> = {
  [HttpTypes.ReportTypeEnum.UserPermissionReport]: 'User permissions report',
  [HttpTypes.ReportTypeEnum.UserActivityReport]: 'User activity report',
  [HttpTypes.ReportTypeEnum.TeamWorkloadReport]: 'Team workload report'
};

export const ReportTypeLookup: LookupEnumModel<number>[] = enum2IdNameObjArray<number>(HttpTypes.ReportTypeEnum, ReportTypeEnumDisplayNameMap).sort((a, b) =>
  a.name.localeCompare(b.name)
);

export interface UpdateUserReportsPermissionsEntityModel {
  id: HttpTypes.ReportTypeEnum;
  hasAccess: boolean;
}

export interface UpdateUserReportsPermissions {
  reportPermissions: Array<UpdateUserReportsPermissionsEntityModel>;
}

export interface UserReportsPermissionsDetailFormModel {
  reportPermissions: Array<HttpTypes.ReportTypeEnum>;
  selected?: boolean;
  teamWorkloadReportAssignedUsers: Array<HttpTypes.TeamWorkloadReportAssignedUserDomainModel>;
  maxTeamWorkloadReportAccessNumber: number;
}

export type UserDetailsApiResponse = HttpTypes.SettingsUserApiResponse;

export interface UserDetailsFormModel extends UserDetailsApiResponse {
  profileImage?: HttpTypes.ProfileImageModel;
  profile: ProfileContextModel;
}

export const DEFAULT_USER_DETAILS: Partial<UserDetailsApiResponse> = {
  firstName: '',
  middleName: '',
  jobTitle: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  work: '',
  timezone: '',
  avatarUrl: ''
};
