import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { RootFormContextProvider } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';
import { PARTY_CAPACITY_LOOKUP_OPTIONS, PartyCapacityEnum } from '@sympli-mfe/enums-2-24/qld';

import { ApiBaseCaveatModel, BaseCaveatModel } from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/models';
import RootForm, { Context } from 'src/containers/documents/scaffolded-form/shared/2-24/caveat/RootForm';
import { QLD_CAVEAT_ADDRESS_FORM_CONFIG, QLD_CAVEAT_ADDRESS_FORM_INITIAL_VALUES, QLD_CAVEAT_PARTY_FORM_CONFIG } from './config';
import converter from './conversion';
import validationSchema from './validationSchema';

export default function DocumentForm(
  //
  props: DocumentFormProps<BaseCaveatModel, ApiBaseCaveatModel>
): JSX.Element {
  const { validateDebounce } = useDocumentContext();
  const validate = useMemoizableValidation<BaseCaveatModel>(validationSchema, validateDebounce);
  return (
    <DndContextProvider>
      <RootFormContextProvider<Context<PartyCapacityEnum>>
        partyFormConfig={QLD_CAVEAT_PARTY_FORM_CONFIG}
        partyCapacityLookup={PARTY_CAPACITY_LOOKUP_OPTIONS}
        addressFormConfig={QLD_CAVEAT_ADDRESS_FORM_CONFIG}
        addressFormInitialValues={QLD_CAVEAT_ADDRESS_FORM_INITIAL_VALUES}
      >
        <DocumentFormWrapper //
          {...props}
          converter={converter}
          validate={validate}
          validationSchema={validationSchema}
          RootForm={RootForm}
        />
      </RootFormContextProvider>
    </DndContextProvider>
  );
}
