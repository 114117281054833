import React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';

import IconListItem from 'src/@core/components/icon-list-item';
import UserAvatar101 from 'src/@core/components/user-avatar-101';
import { MultiUserAvatar } from 'src/components/avatars';
import { AvatarListItem } from 'src/components/list-item';
import JoinDialog from 'src/containers/documents/components/join-dialog';
import { DocumentListModel } from '../../models';

interface Props {
  document: DocumentListModel;
  forceDisable?: boolean;
  className?: string;
  dataTestId?: string;
  isCriticalRolesEnabled: boolean;
}

function DocumentItem({ document, className, forceDisable, dataTestId, isCriticalRolesEnabled }: Props) {
  const { name, icon, linkTo, description, participants, disabled, selected } = document;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<string>('');

  const handleJoin = React.useCallback((role: string) => {
    setRole(role);
    setOpenDialog(true);
  }, []);

  return (
    <IconListItem
      className={className}
      selected={selected}
      icon={icon}
      linkTo={linkTo}
      disabled={forceDisable || disabled}
      primary={name}
      secondary={description}
      secondaryAction={<MultiUserAvatar items={participants} placement="right-start" />}
      dataTestId={dataTestId}
    >
      {participants &&
        participants.length > 1 &&
        participants?.map((participant, index) => {
          const { name, avatarUrl, role, badge, canJoin } = participant;
          const primary = (
            <>
              {role}
              {canJoin && (
                <>
                  {' '}
                  -
                  <ButtonLink style={{ fontSize: '14px' }} onClick={() => handleJoin(role)}>
                    Join
                  </ButtonLink>
                </>
              )}
            </>
          );
          return (
            <AvatarListItem //
              key={`${name}-${index}`}
              avatar={<UserAvatar101 src={avatarUrl} text={name} size="extraSmall" placement="right-start" tooltipType="document" />}
              avatarSize="s"
              primary={primary}
              secondary={badge}
            />
          );
        })}
      {isCriticalRolesEnabled && openDialog && (
        <JoinDialog //
          documentId={document.documentId}
          workspaceRole={role}
          documentType={document.documentIdentifier}
          setOpenDialog={setOpenDialog}
        />
      )}
    </IconListItem>
  );
}

export default DocumentItem;
