import { Action, createReducer } from 'typesafe-actions';

import { LookupItemModel } from '@sympli/ui-framework/models';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchSingleGroupMembers } from '../actions';

export interface GroupMembersApiResponse {
  id: string;
  name: string;
}

export interface SingleGroupMembersState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: LookupItemModel<string>[];
  error?: string;
  args?: string; // GroupId
}

const initialState: SingleGroupMembersState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useSingleGroupMembers(groupId?: string) {
  const state = useStoreSelector(store => store.singleGroupMembers);
  if (state.args !== groupId) {
    return initialState;
  }
  return state;
}

const singleGroupMembersReducer = createReducer<
  //
  SingleGroupMembersState,
  Action
>(initialState)
  .handleAction(actionFetchSingleGroupMembers.request, (state, action): SingleGroupMembersState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload.groupIds
    };
  })
  .handleAction(actionFetchSingleGroupMembers.success, (state, action): SingleGroupMembersState => {
    const items = action.payload.data.map(x => ({ id: x.id, name: x.name }));

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: items
    };
  })
  .handleAction(actionFetchSingleGroupMembers.failure, (state, action): SingleGroupMembersState => {
    return {
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    };
  });

export default singleGroupMembersReducer;
