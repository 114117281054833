import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { prepareTitleForSubmission } from 'src/containers/shared/verify-property-section/helpers';
import { TitleVerificationStatusEnum, VerifiedTitleResponse } from 'src/containers/shared/verify-property-section/models';
import { CreateWorkspaceInvitationRequestItemModel } from '../../../../models';
import {
  CreateLodgementOnlyWorkspaceInvitationsStepRequest,
  CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel,
  CreateLodgementOnlyWorkspaceSharedModel,
  DocumentInvitationItemModel,
  DocumentWithInvitationModel
} from '../../../reg-only-form/models';
import { InvitationItemModel } from './models';

export function getDefaultInvitationItem(subscriberId: string = ''): InvitationItemModel {
  return {
    subscriber: {
      id: subscriberId,
      name: '',
      abn: ''
    }
  };
}

export function getInitialClientRoleIds(roles: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[], isControllingParty: boolean): HttpTypes.WorkspaceRoleEnum[] {
  if (roles.length === 1 && isControllingParty && roles[0].id === HttpTypes.WorkspaceRoleEnum.ControllingParty) {
    return roles.map(({ id }) => id);
  }
  return [];
}

export function prepareRegSubmitData(
  { matterDetailsStepData, inviteParticipantsStepData }: CreateLodgementOnlyWorkspaceSharedModel,
  needsSelfInvite: boolean = false
): CreateLodgementOnlyWorkspaceInvitationsStepRequest {
  const {
    titleInformation: { titleVerificationResult },
    groupId,
    jurisdictionId,
    createdByReference,
    instructingOrganisationSettlementAgentId
  } = matterDetailsStepData;
  const { documents, invitationDescription } = inviteParticipantsStepData;

  const resolvedDocuments = documents.map((doc: DocumentWithInvitationModel) => {
    const { selectedRoleIds } = doc;
    const invitations: CreateWorkspaceInvitationRequestItemModel[] = doc.invitations //
      .filter(({ roleId }) => {
        if (needsSelfInvite && roleId === HttpTypes.WorkspaceRoleEnum.ControllingParty) {
          return true;
        }

        return !selectedRoleIds.includes(roleId); // choose only invitations that user does not represent
      })
      .map(({ subscriber, roleId }) => {
        return {
          roleId,
          subscriberId: subscriber.id,
          displayName: subscriber.name,
          elnoId: subscriber.elnoId,
          elnoSubscriberId: subscriber.elnoSubscriberId,
          abn: subscriber.abn
        };
      });

    return {
      //
      documentIdentifier: doc.documentIdentifier as HttpTypes.DocumentTypeIdentifierEnum, // documentIdentifier will be always present
      roleIds: selectedRoleIds,
      invitations
    };
  });

  return {
    jurisdictionId: jurisdictionId as HttpTypes.JurisdictionsEnum,
    createdByReference,
    documents: resolvedDocuments,
    // this should never happen, titleVerificationResult should always be present here. It's guaranteed by validation
    titleReferences: titleVerificationResult ? titleVerificationResult.titles.map(title => prepareTitleForSubmission(jurisdictionId!, title.titleReference)) : [],
    groupId,
    instructingOrganisationSettlementAgentId: instructingOrganisationSettlementAgentId?.length ? instructingOrganisationSettlementAgentId : undefined,
    // clientMappingId,
    invitationDescription
  };
}

export function getInitialValues(
  currentSubscriberData: HttpTypes.UserProfileModel,
  createLodgementOnlyWorkspaceSharedModel: CreateLodgementOnlyWorkspaceSharedModel,
  needsSelfInvite: boolean = false
): CreateLodgementOnlyWorkspaceInviteParticipantsStepFormModel {
  const { matterDetailsStepData } = createLodgementOnlyWorkspaceSharedModel;
  const { isControllingParty, documents } = matterDetailsStepData;
  const documentsAndInvites: DocumentWithInvitationModel[] = documents.map(({ documentIdentifier, roleOptions }) => {
    let invitations: DocumentInvitationItemModel[] = []; // roles represented by others
    let selectedRoleIds: HttpTypes.WorkspaceRoleEnum[] = []; // roles represented by user

    if (roleOptions.length === 1) {
      // if the only required role for this document is controlling party
      if (roleOptions[0].id === HttpTypes.WorkspaceRoleEnum.ControllingParty) {
        // we need to check whether user represents controlling party
        // if so, we don't need to invite anyone and just preselect that their represent this role
        if (isControllingParty) {
          selectedRoleIds = [HttpTypes.WorkspaceRoleEnum.ControllingParty];
          // in VIC we need to invite current user for paper self controlled title (document ConvertPctAndNominateToTheLodgementCase)
          if (needsSelfInvite) {
            const { subscriberId, subscriberName, subscriberElnoId, subscriberAbn } = currentSubscriberData;
            invitations.push({
              // we assign current subscriber and it's details to be the invited party
              subscriberIsReadOnly: true,
              roleId: HttpTypes.WorkspaceRoleEnum.ControllingParty,
              subscriber: {
                id: subscriberId,
                name: subscriberName,
                elnoId: subscriberElnoId,
                abn: subscriberAbn
              }
            });
          }
        } else {
          // otherwise we need to invite someone
          // check if we have his subscriber id, and if so, pre-populate it

          let subscriberId: string = '';
          let subscriberName: string = '';
          let elnoId: string = '';
          let abn: string = '';
          // for non paper titles only we check whether someone else is controlling current title
          // and we pre-populate subscriber details
          if (matterDetailsStepData.titleInformation.titleVerificationResult!.titles.every(t => t.status === TitleVerificationStatusEnum.Success)) {
            const titlesWithSubscriber: VerifiedTitleResponse[] = matterDetailsStepData.titleInformation
              .titleVerificationResult!.titles //
              .filter((t: VerifiedTitleResponse) => t.controller?.subscriberId) // only those with specified subscriber
              .filter((t: VerifiedTitleResponse) => !t.controller?.isControlledByCurrentSubscriber);

            // only preset if we found a controller, otherwise default to empty strings
            // we don't want to preset name in case when subscriber is not registered with sympli
            subscriberId = titlesWithSubscriber[0]?.controller?.subscriberId || '';
            subscriberName = titlesWithSubscriber[0]?.controller?.name || '';
            elnoId = titlesWithSubscriber[0]?.controller?.elnoId || '';
            abn = titlesWithSubscriber[0]?.controller?.abn || '';
          }

          invitations.push({
            subscriberIsReadOnly: !!subscriberId, // if subscriber is already registered with sympli, we make it readonly
            roleId: HttpTypes.WorkspaceRoleEnum.ControllingParty,
            subscriber: {
              id: subscriberId,
              name: subscriberName,
              elnoId: elnoId,
              abn: abn
            }
          });
        }
      } else {
        // we will automatically preselect this role on users behalf and we don't invite anyone
        selectedRoleIds = [roleOptions[0].id];
      }
    } else {
      // more roles are required, user needs to choose which one their represent and invite
      invitations = roleOptions.map(({ id }) => {
        return {
          subscriberIsReadOnly: false,
          roleId: id,
          subscriber: {
            id: '',
            name: '',
            abn: ''
          }
        };
      });
    }

    return {
      documentIdentifier: documentIdentifier as HttpTypes.DocumentTypeIdentifierEnum,
      roleOptions,
      selectedRoleIds,
      invitations
    };
  });

  return {
    documents: documentsAndInvites,
    invitationDescription: undefined
  };
}
