import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ProfileContextModel } from './Profile.context';

export interface SecurityRequirementProps {
  minRole?: HttpTypes.UserRoleEnum;
  requiredUserPermissions?: Array<HttpTypes.UserLevelPermissionEnum> | HttpTypes.UserLevelPermissionEnum;
  requiredRolePermissions?: Array<HttpTypes.RolePermissionsEnum> | HttpTypes.RolePermissionsEnum;
}

interface IsAuthorizedSelectorModel extends SecurityRequirementProps {
  profile?: ProfileContextModel;
}

export type IsAuthorizedSelectorType = (props: IsAuthorizedSelectorModel) => boolean;

export const makeSelector = (): IsAuthorizedSelectorType => {
  return createSelector<
    //
    IsAuthorizedSelectorModel,
    ProfileContextModel,
    HttpTypes.UserRoleEnum | undefined,
    HttpTypes.UserLevelPermissionEnum | HttpTypes.UserLevelPermissionEnum[] | undefined,
    HttpTypes.RolePermissionsEnum | HttpTypes.RolePermissionsEnum[] | undefined,
    boolean
  >(
    (props: IsAuthorizedSelectorModel) => props.profile,
    (props: IsAuthorizedSelectorModel) => props.minRole,
    (props: IsAuthorizedSelectorModel) => props.requiredUserPermissions,
    (props: IsAuthorizedSelectorModel) => props.requiredRolePermissions,
    // we ignore userId, it's here just to force recalculation
    (profile, minRole = HttpTypes.UserRoleEnum.Standard, _requiredUserPermissions, _requiredRolePermissions) => {
      // console.log('Profile.context.Consumer - INSIDE selector BODY, %s', getCurrentTime());
      if (!profile) {
        // console.log('Profile.context.Consumer - Profile not loaded - returning, %s', getCurrentTime());
        return false;
      }

      const requiredUserPermissions = resolvePermissionArray(_requiredUserPermissions);
      const requiredRolePermissions = resolvePermissionArray(_requiredRolePermissions);

      const { role, userPermissions = [], rolePermissions = [] } = profile;

      switch (minRole) {
        case HttpTypes.UserRoleEnum.SuperAdmin:
          if (role !== HttpTypes.UserRoleEnum.SuperAdmin) {
            return false;
          }
          break;
        case HttpTypes.UserRoleEnum.Admin:
          if (!(role === HttpTypes.UserRoleEnum.SuperAdmin || role === HttpTypes.UserRoleEnum.Admin)) {
            return false;
          }
          break;
        default:
          break;
      }
      // TODO check for certificate manager permission when is available and required
      const isAllowed =
        requiredRolePermissions.every((permission: HttpTypes.RolePermissionsEnum) => rolePermissions.includes(permission)) &&
        requiredUserPermissions.every((permission: HttpTypes.UserLevelPermissionEnum) => userPermissions.includes(permission));

      return isAllowed;
    }
  );
};

function resolvePermissionArray<T extends number>(permissions: T | Array<T> | undefined): Array<T> {
  if (permissions == null) {
    return [];
  }
  if (Array.isArray(permissions)) {
    return permissions;
  }
  return [permissions];
}
