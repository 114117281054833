import * as React from 'react';

import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import MessageNotificationV2 from 'src/@core/components/message-notification';
import ErrorMessagePanel from 'src/containers/workspace/financial/detail/components/error-message-panel';

interface OwnProps {
  errors?: MessageModel[];
  warnings?: MessageModel[];
}

type Props = OwnProps;

const StampDutyVerificationError = ({ errors, warnings }: Props) => {
  return errors?.length || warnings?.length ? (
    <ErrorMessagePanel //
      errorHeader="Stamp Duty could not be verified. Please resolve issues."
      warnings={warnings}
      errors={errors}
    />
  ) : (
    <MessageNotificationV2 //
      variant="error"
    >
      Stamp Duty could not be verified. Please resolve issues.
    </MessageNotificationV2>
  );
};

export default React.memo(StampDutyVerificationError);
