import { createAction } from 'typesafe-actions';

import { FeatureToggleEnum } from '../../auth/feature-toggle/models';

interface FlagStatus {
  name: FeatureToggleEnum;
  status: boolean;
  isControlledBy: PlatformEnum;
}

export enum PlatformEnum {
  LaunchDarkley = 'LD',
  TickleUI = 'UI'
}

export const actionAddCurrentPageFeatureFlag = createAction(
  //
  'ADD_CURRENT_PAGE_FEATURE_FLAG'
)<FlagStatus>();

export const actionClearCurrentPageFeatureFlag = createAction(
  //
  'CLEAR_CURRENT_PAGE_FEATURE_FLAG'
)();
