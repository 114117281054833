import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ReferenceFormModel } from './models';

const MAX_MATTER_LENGTH = 200; // WEB-8637

const getValidationSchema = (organisationalType?: HttpTypes.SubscriberOrganisationTypeEnum) => {
  const referenceType = organisationalType === HttpTypes.SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan' : 'matter';
  return yup.object<ReferenceFormModel>({
    reference: yup
      .string()
      .trim()
      .required(`Your ${referenceType} number cannot be blank`)
      .max(MAX_MATTER_LENGTH, `Your ${referenceType} number exceeds ${MAX_MATTER_LENGTH} characters`)
  });
};

export default getValidationSchema;
