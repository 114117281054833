import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import {
  resolveProprietorGroupsValidationSchema,
  YUP_GENERIC_TENANCY_ADDRESS_BOOK_ID_REQUIRED,
  YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic/validationSchema';
import { memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { VISIBILITY_CHECK_CAVEATORS } from '../../checks';
import { NSW_CAVEAT_PARTY_FORM_CONFIG } from '../../config';
import { CaveatModel_2_21_2 } from '../../models';

type FormValues = CaveatModel_2_21_2;

interface CaveatorsArrayContext {
  isActionProhibited5Selected: boolean;
  proprietors: FormValues['proprietors'];
  caveators: FormValues['caveators'];
  partyBook: FormValues['partyBook'];
}

interface CaveatorGroupsContext {
  partyBook: FormValues['partyBook'];
}

// const yupCaveatorItem = memoizeSchemaWithContext<FormValues['caveators'][number], CaveatorsArrayContext, CaveatorItemContext>(
//   yup
//     .object<FormValues['caveators'][number], CaveatorItemContext>({
//       partyBookId: yup // reference to party store
//         .string()
//         .default('')
//         .nullable(true)
//         .required(msg.REQUIRED)
//         .testContextualRule({
//           message: 'Cannot have multiple caveators with the same name',
//           uniqueTestName: 'PARTY ALREADY SELECTED CHECK',
//           requirement: (parent: FormValues['caveators'][number], context: CaveatorItemContext) => {
//             if (!parent.partyBookId) {
//               return true;
//             }
//             return context.caveators.filter(c => c.partyBookId === parent.partyBookId).length <= 1;
//           }
//         })
//         .test(createPartyBookItemTestForPartyBookId(NSW_CAVEAT_PARTY_FORM_CONFIG)),
//       addressBookId: yup.string().default('').nullable(true).required(msg.REQUIRED) // reference to address store
//     })
//     .defined(),

//   (parentContext: CaveatorsArrayContext, parentValue: FormValues['caveators']): CaveatorItemContext => {
//     return {
//       caveators: parentValue,
//       partyBook: parentContext.partyBook
//     };
//   }
// );

// // path: caveators
// const yupCaveatorsArray = memoizeSchemaWithContext<FormValues['caveators'], FormValues, CaveatorsArrayContext>(
//   yup
//     .array<FormValues['caveators'][number], CaveatorsArrayContext>()
//     .of<FormValues['caveators'][number]>(yupCaveatorItem)
//     //.min(1, msg.MIN_ITEMS(1)) // this validation is not needed as user is not able to remove the last item
//     .max(20, msg.MAX_ITEMS(20))
//     .testContextualRule({
//       uniqueTestName: 'Action Prohibited #5 and Caveators',
//       onlyIf: (parent: never, ctx: CaveatorsArrayContext) => {
//         // Rule only applies if AP5 is selected
//         return ctx.isActionProhibited5Selected;
//       },
//       message: 'To select Action Prohibited #5 you must have at least one Proprietor listed as a Caveator',
//       requirement: (parent: never, ctx: CaveatorsArrayContext) => {
//         return atLeastOneProprietorListedAsCaveator({
//           //
//           proprietors: ctx.proprietors,
//           caveators: ctx.caveators
//         });
//       }
//     })
//     .defined(),
//   (parentContext: FormValues): CaveatorsArrayContext => {
//     return {
//       isActionProhibited5Selected: parentContext.extentOfProhibitions.some(item => item.actionProhibitedOption === ActionProhibitedEnum.ActionProhibited5),
//       caveators: parentContext.caveators,
//       proprietors: parentContext.proprietors,
//       partyBook: parentContext.partyBook
//     };
//   }
// );
const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

const yupCaveatorGroups: yup.MixedSchema<FormValues['caveators']['proprietorGroups'], CaveatorGroupsContext> = memoizeSchemaWithContext(
  resolveProprietorGroupsValidationSchema<CaveatorGroupsContext>({
    yupPartyBookId: YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED.test(
      createPartyBookItemTestForPartyBookId(
        //
        NSW_CAVEAT_PARTY_FORM_CONFIG,
        {
          shouldValidatePartyData: shouldValidatePartyData
        }
      )
    ),
    yupAddressBookId: YUP_GENERIC_TENANCY_ADDRESS_BOOK_ID_REQUIRED,
    // turn off unnecessary validations
    yupPartyCapacity: yup.mixed()
  }),
  (parentContext: FormValues): CaveatorGroupsContext => {
    return {
      partyBook: parentContext.partyBook // this is required here for party validation
    };
  }
);

const yupSchema: yup.MixedSchema<FormValues['caveators'], CaveatorsArrayContext> = validateWhenVisible2<FormValues['caveators'], FormValues, CaveatorsArrayContext>({
  isObjectOrArray: true,
  visibilityCheck: (parent: FormValues, _ctx: FormValues) => VISIBILITY_CHECK_CAVEATORS(parent),
  validationWhenVisible: yup.object<FormValues['caveators']>().partial({ proprietorGroups: yupCaveatorGroups }).defined()
});

export default yupSchema;
