import * as React from 'react';

import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import Accordion from 'src/containers/dashboard/invitation/components/modal-dialog-content/Accordion';
import { Body, Scrollable, SubContent, SubHeader } from 'src/containers/dashboard/invitation/components/modal-dialog-content/ModalDialogContent';
import { FinancialRoleMapping } from 'src/models/roles';
import { JoinWorkspaceApiRequest } from '../active-workspace-accordion/api';
import { ParticipantInfo } from '../search-join-table/models';
import JoinWorkspaceForm from './components/JoinWorkspaceForm';
import { JoinWorkspaceDialogModel } from './models';

interface Props {
  workspaceDetail: JoinWorkspaceDialogModel;
  onCancel(): void;
  groupId: string;
  matter?: string;
  role: HttpTypes.WorkspaceRoleEnum; // the role that user want to join the workspace as
  onJoinWorkspace: (data: JoinWorkspaceApiRequest) => Promise<void>;
}

function Participants(props: { participants: Array<ParticipantInfo> }) {
  return (
    <>
      <SubHeader className="pt-[8px]">Participants</SubHeader>
      <SubContent //
        className="mb-[24px] font-[400] leading-[20px] tracking-[0.15px]"
        style={{ fontFeatureSettings: '"clig" off, "liga" off' }}
      >
        {props.participants.map((item, i) => {
          return (
            <Box key={i} className="mt-[8px]">
              <Typography variant="body2" className="leading-[18px] text-[var(--neutral-1000)]">
                {item.subscriberName} - {!item.hasAccepted ? 'Invited as ' : ''}
                {item.role.toUpperCase()}
              </Typography>
              <Typography variant="body2" className="leading-[18px] text-[var(--neutral-600)]">
                {item.assignedGroupName && `(Assigned to group: ${item.assignedGroupName})`}
              </Typography>
            </Box>
          );
        })}
      </SubContent>
    </>
  );
}

function Title(props: {
  //
  reference: string;
  landDescriptions?: string[];
}) {
  return (
    <SubContent //
      className="font-[700] tracking-[0.25px] text-[var(--neutral-1000)]"
    >
      <Typography variant="body2" className="text-[var(--neutral-1000)]">
        <b>{props.reference}</b> {props.landDescriptions?.length ? ` - (${props.landDescriptions[0]})` : ''}
      </Typography>
    </SubContent>
  );
}

function ModalDialogContent(props: Props) {
  const { role, workspaceDetail, onCancel, groupId, matter, onJoinWorkspace } = props;
  const { jurisdictionId, sympliId, titleReferences, participants, workspaceId, proposedSettlementDate, expectedSettlementDate } = workspaceDetail;

  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(titleReferences.length > 1 ? 0 : undefined);
  const handleAccordionExpandChange = (panelIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedIndex(newExpanded ? panelIndex : undefined);
  };

  return (
    <div className="flex">
      <div className="box-border w-[448px] bg-[var(--greek-waters-translucent)] pb-[37px] pl-[48px] pr-[24px] pt-[56px]">
        <DialogTitle //
          className="m-0 p-0 font-volkswagen-serial-xbold text-[34px] font-[800] text-[var(--sympli-green)] "
        >
          Join workspace
        </DialogTitle>

        <SubHeader className="pt-[8px] text-[14px] normal-case">As {FinancialRoleMapping[role]} to collaborate on</SubHeader>
        <Scrollable className="h-[350px] grow" expandedIndex={expandedIndex}>
          {titleReferences.length > 1 ? (
            titleReferences.map((title, index) => {
              return (
                <Accordion
                  //
                  key={index}
                  expanded={index === expandedIndex}
                  onChange={handleAccordionExpandChange(index)}
                  summary={
                    <Title //
                      reference={title.reference}
                      landDescriptions={title.landDescriptions}
                    />
                  }
                >
                  <Body //
                    invitationTitles={titleReferences}
                    sympliId={sympliId}
                    title={title.reference}
                    jurisdictionId={jurisdictionId}
                  />
                </Accordion>
              );
            })
          ) : (
            <>
              <Title //
                reference={titleReferences[0].reference}
                landDescriptions={titleReferences[0].landDescriptions}
              />
              <Body //
                invitationTitles={titleReferences}
                sympliId={sympliId}
                title={titleReferences[0].reference}
                jurisdictionId={jurisdictionId}
              />
            </>
          )}
          <Participants participants={participants} />
        </Scrollable>
      </div>
      <div className="box-border flex w-[536px] flex-col bg-[var(--neutral-000)] py-[56px] pl-[48px] pr-0">
        <JoinWorkspaceForm
          workspaceId={workspaceId}
          groupId={groupId}
          onCancel={onCancel}
          matter={matter}
          proposedSettlementDate={proposedSettlementDate}
          expectedSettlementDate={expectedSettlementDate}
          onSubmit={onJoinWorkspace}
          role={role}
        />
      </div>
    </div>
  );
}

export default React.memo(ModalDialogContent);
