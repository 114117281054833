import { createAction } from 'typesafe-actions';

import type { SnackbarProps } from '@mui/material/Snackbar';

export interface GlobalNotificationActionPayload {
  message: string | React.ReactNode;
  secondaryMessage?: string | React.ReactNode;
  variant?: 'success' | 'info' | 'warning' | 'error' | 'loading' | 'new-success' | 'new-warning' | 'new-error';
  autoHideDuration?: number;
  onClose?: (open: boolean) => void;
  expandableContent?: JSX.Element;
  transitionComponent?: SnackbarProps['TransitionComponent'];
  transitionDuration?: SnackbarProps['transitionDuration'];
}

export const actionOpenGlobalSimpleNotification = createAction(
  //
  'SWITCH_GLOBAL_SIMPLE_NOTIFICATION_OPEN'
)<GlobalNotificationActionPayload>();

export const actionCloseGlobalSimpleNotification = createAction(
  //
  'SWITCH_GLOBAL_SIMPLE_NOTIFICATION_CLOSE'
)();
