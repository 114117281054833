import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache

/**
 path:
 - titleReferences[*].transferors.tenancyType

 schema version:
[{"id":0,"value":"Encumbrancee","name":"Encumbrancee","_meta":null},{"id":1,"value":"JointTenants","name":"Joint Tenants","_meta":null},{"id":2,"value":"Mortgagee","name":"Mortgagee","_meta":null},{"id":3,"value":"Multi","name":"Multi","_meta":null},{"id":4,"value":"SoleProprietor","name":"Sole Proprietor","_meta":null},{"id":5,"value":"Survivorship","name":"Survivorship","_meta":null},{"id":6,"value":"TenantsinCommon","name":"Tenants in Common","_meta":null},{"id":7,"value":"None","name":"None","_meta":null},{"id":8,"value":"LifeEstate","name":"Life Estate","_meta":null}]

 hash:
 WzAsMSwyLDMsNCw1LDYsNyw4XQ==
*/
export enum TenancyTypeEnum {
  Encumbrancee = 0,
  JointTenants = 1,
  Mortgagee = 2,
  Multi = 3,
  SoleProprietor = 4,
  Survivorship = 5,
  TenantsinCommon = 6,
  None = 7,
  LifeEstate = 8
}
export const TITLE_REFERENCES$TRANSFERORS$TENANCY_TYPE_LOOKUP_OPTIONS: LookupEnumModel<TenancyTypeEnum>[] = [
  {
    id: TenancyTypeEnum.Encumbrancee,
    name: 'Encumbrancee'
  },
  {
    id: TenancyTypeEnum.JointTenants,
    name: 'Joint Tenants'
  },
  {
    id: TenancyTypeEnum.Mortgagee,
    name: 'Mortgagee'
  },
  {
    id: TenancyTypeEnum.Multi,
    name: 'Multi'
  },
  {
    id: TenancyTypeEnum.SoleProprietor,
    name: 'Sole Proprietor'
  },
  {
    id: TenancyTypeEnum.Survivorship,
    name: 'Survivorship'
  },
  {
    id: TenancyTypeEnum.TenantsinCommon,
    name: 'Tenants in Common'
  },
  {
    id: TenancyTypeEnum.None,
    name: 'None'
  },
  {
    id: TenancyTypeEnum.LifeEstate,
    name: 'Life Estate'
  }
];

const TransferApplicableAttachmentDocumentTypes: AttachmentTypeEnum[] = [
  AttachmentTypeEnum.MinistersConsent,
  AttachmentTypeEnum.CaveatorsConsent,
  AttachmentTypeEnum.StatutoryDeclarationStatementForNoticeOfSeverance,
  AttachmentTypeEnum.NativeTitleRegistrationApprovalCertificate
];

export const TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS: LookupEnumModel<AttachmentTypeEnum, string>[] = TransferApplicableAttachmentDocumentTypes.map(
  (x): LookupEnumModel<AttachmentTypeEnum> => ({ id: x, name: x })
);

export const TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS_WITHOUT_MINISTERSCONSENT = TRANSFER_ATTACHMENT_DOCUMENT_TYPE_OPTIONS.filter(
  x => x.id !== AttachmentTypeEnum.MinistersConsent
);
