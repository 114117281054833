import * as React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { EditIcon } from '@sympli/ui-framework/icons';

import ToggleCheckbox from 'src/containers/dashboard/invitation/components/toggle-checkbox';
import { SettlementDateTimeVariantEnum } from '../../models';

interface Props {
  redirectSettlementDatePage(): void;
  formattedSettlementDate?: string;
  formattedSettlementTime?: string;
  variant: SettlementDateTimeVariantEnum;
  isAcceptedByUser?: boolean;
  handleOnToggleChange?: (checked: boolean) => void;
  isToggleButtonDisabled?: boolean;
}

function DisplayDateTime({
  variant,
  isAcceptedByUser,
  isToggleButtonDisabled,
  handleOnToggleChange,
  redirectSettlementDatePage,
  formattedSettlementDate,
  formattedSettlementTime
}: Props) {
  const renderDate = () => {
    const disabled = [
      //
      SettlementDateTimeVariantEnum.SettlementInProgress,
      SettlementDateTimeVariantEnum.SettlementIssue
    ].includes(variant);

    return (
      <div className="relative">
        <Typography className={classNames('text-[var(--neutral-1000)] font-medium text-[16px] leading-[24px]')}>{formattedSettlementDate}</Typography>
        {![
          //
          SettlementDateTimeVariantEnum.IsRollOver,
          SettlementDateTimeVariantEnum.Completed,
          SettlementDateTimeVariantEnum.Unsuccessful,
          SettlementDateTimeVariantEnum.Overdue,
          SettlementDateTimeVariantEnum.UnsupportedDate,
          SettlementDateTimeVariantEnum.DateOnly
        ].includes(variant) && (
          <ButtonLink
            className="absolute right-[-20px] top-0"
            onClick={() => {
              redirectSettlementDatePage();
            }}
            disabled={disabled}
          >
            <EditIcon className={classNames(disabled && 'opacity-40')} />
          </ButtonLink>
        )}
      </div>
    );
  };

  const renderTime = () => {
    return (
      <Typography className={classNames('text-[var(--neutral-600)] font-normal text-[12px] leading-[16px]')}>
        {variant === SettlementDateTimeVariantEnum.DateOnly ? 'Time not set' : formattedSettlementTime}
      </Typography>
    );
  };

  const renderButton = (text: string) => {
    return (
      <div className="mt-[12px]">
        <SympliButton
          hasRadius
          variant="contained"
          className={classNames(
            'px-[16px] py-[6px] text-[12px] font-bold leading-[16px] text-[var(--neutral-000)] hover:shadow-none',
            variant === SettlementDateTimeVariantEnum.DateOnly
              ? 'bg-[var(--sunny-day)] hover:bg-[var(--sunny-day-dark)]'
              : 'bg-[var(--watermelon)] hover:bg-[var(--watermelon-dark)]'
          )}
          onClick={() => {
            redirectSettlementDatePage();
          }}
        >
          {text}
        </SympliButton>
      </div>
    );
  };

  const renderToggleCheckbox = ({ isDisabled }: { isDisabled: boolean }) => {
    if (!handleOnToggleChange) return null;

    const checked = Boolean(isAcceptedByUser);
    const disabled = isDisabled || isToggleButtonDisabled;

    return (
      <div className="mt-[12px]">
        <ToggleCheckbox //
          backgroundContainerClassName={classNames(
            checked ? 'shadow-custom-teal' : 'shadow-custom-watermelon',
            checked ? 'bg-[var(--greek-waters-lite)]' : 'bg-[var(--watermelon-lite)]'
          )}
          labelClassName={checked ? 'text-[var(--greek-waters-dark)]' : 'text-[var(--watermelon)]'}
          checked={checked}
          label={isAcceptedByUser ? 'Accepted' : 'Not Accepted'}
          unCheckIconClassName="stroke-[var(--watermelon)] fill-[var(--watermelon)]"
          disabled={disabled}
          onChange={handleOnToggleChange}
          containerClassName={disabled ? 'opacity-40' : undefined}
        />
      </div>
    );
  };

  const renderAction = () => {
    switch (variant) {
      case SettlementDateTimeVariantEnum.DateOnly:
      case SettlementDateTimeVariantEnum.IsRollOver:
        return <>{renderButton('Book')}</>;
      case SettlementDateTimeVariantEnum.Overdue:
      case SettlementDateTimeVariantEnum.Unsuccessful:
      case SettlementDateTimeVariantEnum.UnsupportedDate:
        return <>{renderButton('Rebook')}</>;
      case SettlementDateTimeVariantEnum.SettlementIssue:
      case SettlementDateTimeVariantEnum.SettlementInProgress:
        return renderToggleCheckbox({ isDisabled: true });
      case SettlementDateTimeVariantEnum.SettlementInDays:
      case SettlementDateTimeVariantEnum.SettlementInWeeks:
      case SettlementDateTimeVariantEnum.SettlementToday:
        return renderToggleCheckbox({ isDisabled: false });
      case SettlementDateTimeVariantEnum.Completed:
      case SettlementDateTimeVariantEnum.Unknown:
      case SettlementDateTimeVariantEnum.NoDateNoTime:
      default:
        return null;
    }
  };

  return (
    <div className="h-[84px] flex-auto flex justify-center items-center flex-col">
      {renderDate()}
      {renderTime()}
      {renderAction()}
    </div>
  );
}

export default React.memo(DisplayDateTime);
