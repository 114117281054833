import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class Subscriber {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method uploads subscribers profile picture. It sends a request to the
   * `/settings/subscriber/{subscriberId}/profile/image`
   * API route.
   *
   * @param subscriberId - The subscribers ID.
   * @param body - The data about the image.
   * @param config - Additional config to pass with the request.
   * @returns profileImageUrl
   *
   * @example
   * sdk.settings.subscriber.uploadProfileImage("sub_123", {
   *   base64String: "iVBORw0KGgoAAAANSUM4h...",
   *   imageType: "png"
   * })
   * .then((profileImageUrl) => {
   *   console.log('profileImageUrl')
   * })
   */
  async uploadProfileImage(subscriberId: string, body: HttpTypes.UploadSubscriberProfileImageApiRequestBody, config?: ClientRequestConfig) {
    return await this.#client.fetch<string>( //
      `/settings/subscriber/${subscriberId}/profile/image`,
      {
        method: 'POST',
        body,
        ...config
      }
    );
  }

  /**
   * This method deletes subscribers profile picture. It sends a request to the
   * `/settings/subscriber/{subscriberId}/profile/image`
   * API route.
   *
   * @param subscriberId - The subscribers ID.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * sdk.settings.subscriber.deleteProfileImage("sub_123")
   * .then(() => {
   *   console.log('Image deleted successfully')
   * })
   */
  async deleteProfileImage(subscriberId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/subscriber/${subscriberId}/profile/image`,
      {
        method: 'DELETE',
        ...config
      }
    );
  }

  /**
   * This method retrieves a paginated list of subscriber activity logs. It sends a request to the
   * `/settings/reporting/logs`
   * API route.
   *
   * @param queryParams - Pagination configurations.
   * @param config - Additional config to pass with the request.
   * @returns The paginated list of logs.
   *
   * @example
   * To configure the pagination, pass the `pageSize` and `pageNumber` query parameters.
   *
   * For example, to retrieve only 10 items and skip 10 items:
   *
   * ```ts
   * sdk.settings.subscriber.listActivityLogs({
   *   pageSize: 10,
   *   pageNumber: 10
   * })
   * .then(({ items: logs, pageNumber, pageSize, totalCount, totalPages }) => {
   *   console.log(logs)
   * })
   * ```
   */
  async listActivityLogs(
    queryParams?: {
      pageSize?: number;
      pageNumber?: number;
    },
    config?: ClientRequestConfig
  ) {
    return this.#client.fetch<HttpTypes.SubscriberActivityLogListApiResponse>('/settings/reporting/logs', {
      //
      query: queryParams,
      ...config
    });
  }

  /**
   * This method retrieves preferences for subscriber. It sends a request to the
   * `/settings/subscriber/preferences`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve preferences:
   *
   * ```ts
   * sdk.settings.subscriber.retrievePreferences()
   * .then((preferences) => {
   *   console.log(preferences)
   * })
   * ```
   */
  async retrievePreferences(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsSubscriberPreferencesApiResponse>( //
      `/settings/subscriber/preferences`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves workspace preferences for subscriber. It sends a request to the
   * `/settings/subscriber/workspace-preferences`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve workspace preferences:
   *
   * ```ts
   * sdk.settings.subscriber.retrieveWorkspacePreferences()
   * .then((preferences) => {
   *   console.log(preferences)
   * })
   * ```
   */
  async retrieveWorkspacePreferences(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsSubscriberWorkspacePreferencesApiResponse>( //
      `/settings/subscriber/workspace-preferences`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves signing preferences for subscriber. It sends a request to the
   * `/settings/subscriber/signing-rule`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve signing preferences:
   *
   * ```ts
   * sdk.settings.trustAccountRequest.retrieveSigningPreferences()
   * .then((preferences) => {
   *   console.log(preferences)
   * })
   * ```
   */
  async retrieveSigningPreferences(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsSubscriberSigningRuleApiResponse>( //
      `/settings/subscriber/signing-rule`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves notification preferences for subscriber. It sends a request to the
   * `/settings/subscriber/signing-rule`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve notification preferences:
   *
   * ```ts
   * sdk.settings.trustAccountRequest.retrieveNotificationPreferences()
   * .then((preferences) => {
   *   console.log(preferences)
   * })
   * ```
   */
  async retrieveNotificationPreferences(config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.NotificationSettingsApiResponse>( //
      `/settings/subscriber/preferences/notification-settings`,
      {
        ...config
      }
    );
  }
}
