import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchFinancialAccounts } from '../actions/financialAccounts';

export interface FinancialAccountsState {
  items: HttpTypes.FinancialAccountsApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: FinancialAccountsState = {
  items: [],
  status: 'idle',
  error: undefined
};

export function useFinancialAccounts() {
  const state = useStoreSelector(store => store.debitAccountsFeed);
  if (state) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  FinancialAccountsState,
  Action
>(initialState)
  .handleAction(actionFetchFinancialAccounts.request, (state): FinancialAccountsState => {
    const status = state.status === 'pending' ? 'pending' : 'refetching';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      error: undefined
    };
  })
  .handleAction(actionFetchFinancialAccounts.success, (state, action): FinancialAccountsState => {
    return {
      ...state,
      items: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchFinancialAccounts.failure, (state, action): FinancialAccountsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
