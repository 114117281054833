import React from 'react';

import classNames from 'classnames';
import { FormikProps, setNestedObjectValues } from 'formik';
import _differenceWith from 'lodash-es/differenceWith';
import _get from 'lodash-es/get';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section, { SectionProps } from '@sympli/ui-framework/components/form/base-components/section';
import Select from '@sympli/ui-framework/components/form/base-components/select';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import NoaPropertyDetail from '../../components/noa-property-detail';
import { YES_NO_LOOKUP_OPTIONS } from '../../enums';
import { checkIfAllTitlesAreAssigned } from '../../helper';
import { NoaPropertyDetailModel, NoticeOfAcquisitionDocumentModel, TitleReferenceModel } from '../../models';
import { resolveAllTitlesAssigned } from './helpers';
import styles, { ClassKeys } from './styles';

// this file was automatically generated from sections/SectionArrayComponent.tsx.mustache
const debug = !import.meta.env.PROD;

interface OwnProps {
  name: string;
  formikProps: FormikProps<NoticeOfAcquisitionDocumentModel>;
  disabled: boolean;
  dialogPortalId: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const fieldName = modelKey<NoticeOfAcquisitionDocumentModel>();

class SectionDetailsOfProperties extends React.PureComponent<Props> {
  componentDidUpdate(previous) {
    const {
      values: { arePropertiesSeparatelyRated, propertyDetails, titleReferences },
      setFieldValue,
      setFieldTouched
    } = this.props.formikProps;
    const { allTitlesAssigned: prevAllTitlesAssigned } = previous.formikProps.values;
    const allTitlesAssigned = checkIfAllTitlesAreAssigned(arePropertiesSeparatelyRated, propertyDetails, titleReferences);
    if (allTitlesAssigned !== prevAllTitlesAssigned) {
      setFieldValue(fieldName('allTitlesAssigned'), allTitlesAssigned);
      setFieldTouched(fieldName('allTitlesAssigned'), false);
    }
  }

  private handleOnPropertiesAreSeparatelyRatedChange = (_, arePropertiesSeparatelyRated: boolean) => {
    const { formikProps } = this.props;
    const {
      //
      propertyDetails: currentPropertyDetails,
      titleReferences: availableTitleReferences,
      transactionDetail
    } = formikProps.values;

    let propertyDetails: NoaPropertyDetailModel[] = [];

    // switching to separately rated mode
    if (arePropertiesSeparatelyRated) {
      // if we have multiple titles that are not separately rated, there would be only one property detail item and it would have multiple titles.
      // so if we are separating them, we are going to create a replica of the first property detail item.

      // because they have not been separated before, there is only one property detail
      propertyDetails = currentPropertyDetails[0].titleReferences //
        .map((tr, i) => {
          let item: NoaPropertyDetailModel = {
            ...currentPropertyDetails[0], // replicate first property details but with correct title reference details
            propertyId: i + 1,
            titleReferences: [tr],
            // reset values for the rest of the property details
            ...(i > 0
              ? {
                  constructionOfMainStructure: null,
                  landDimensions: {
                    knownDetails: null,
                    landArea: {
                      areaUnit: null,
                      quantity: null
                    },
                    unitOfEntitlement: ''
                  },
                  landUseType: null,
                  municipalityName: null,
                  municipalityPropertyNumber: '',
                  numberOfBedrooms: null,
                  primaryLandUse: null,
                  salePricePerProperty: null
                }
              : undefined)
          };
          return item;
        });

      if (propertyDetails.length > 2) {
        // we explicitly remove the rest and let user to decide how to combine titles
        // whether to create an additional property or combine more titles under one
        propertyDetails = propertyDetails.slice(0, 2);
        // also cleanup the title references on second title so we force user to decide
        propertyDetails[1].titleReferences = [null]; // explicitly assign empty value
      }
      formikProps.setFieldValue(fieldName('allTitlesAssigned'), false);
      formikProps.setFieldTouched(fieldName('allTitlesAssigned'), false);
    } else {
      // we have multiple separately rated properties that we are now merging together
      // we replace all titles
      propertyDetails.push({
        ...currentPropertyDetails[0],
        titleReferences: [...availableTitleReferences],
        salePricePerProperty: transactionDetail.totalSalePrice
      });
    }

    formikProps.setFieldValue(fieldName('propertyDetails'), propertyDetails);
    formikProps.setFieldTouched(fieldName('propertyDetails'), setNestedObjectValues(propertyDetails, false)); // reset touched state since the list has changed
  };

  private renderPropertySeparatorRadio() {
    return (
      <FormGroup
        title="Are they separately rated?"
        formTip="Legislation requires information about individual properties, those that are separately rated by council, to be sent to the Valuer General following settlement. This includes the municipal number, value of each property, description of the property, construction and number of bedrooms."
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            component={RadioField}
            name={fieldName('arePropertiesSeparatelyRated')}
            options={YES_NO_LOOKUP_OPTIONS}
            format="boolean"
            disabled={this.props.disabled}
            onChange={this.handleOnPropertiesAreSeparatelyRatedChange}
          />
        )}
      </FormGroup>
    );
  }

  private handleAddEmptyTitleToPropertyCombinatorItem = (propertyId: number): TitleReferenceModel | null => {
    const {
      values: {
        propertyDetails,
        // all available titleReferences
        titleReferences: availableTitleReferences
      }
    } = this.props.formikProps;

    const usedTitleReferences = propertyDetails.flatMap(ptc => ptc.titleReferences.filter(Boolean)) as TitleReferenceModel[]; // excluding null values
    if (availableTitleReferences.length - usedTitleReferences.length === 1) {
      // find out which is missing and chuck it in, so the user does not need to do it
      const titleReferences = _differenceWith<TitleReferenceModel, TitleReferenceModel>(
        availableTitleReferences,
        usedTitleReferences, //
        (a, b) => a.reference === b.reference
      );

      return { ...titleReferences[0] };
    }
    return null; // push new item into titleReferenceArray
  };

  private renderTitleItemWithinPropertyCombinatorItem = ({ itemBinding, item: currentSelection, canAdd }: DocumentArrayItemRenderProps<TitleReferenceModel | null>) => {
    const { disabled, formikProps } = this.props;
    const {
      errors,
      touched,
      values: {
        propertyDetails,
        // all available titleReferences
        titleReferences: availableTitleReferences
      },
      setFieldValue
    } = formikProps;

    const titlesUsed = (propertyDetails.flatMap(ptc => ptc.titleReferences.filter(Boolean)) as TitleReferenceModel[]).map(({ reference }) => reference); // excluding null values

    const TITLE_REFERENCE_OPTIONS: LookupEnumModel<string>[] = availableTitleReferences
      .map(tr => tr.reference)
      .filter(titleNumber => titleNumber === currentSelection?.reference || !titlesUsed.includes(titleNumber))
      .map(name => ({ id: name, name }));

    const value = currentSelection?.reference || null;
    let error = _get(touched, itemBinding) && _get(errors, itemBinding);
    error = typeof error === 'string' ? error : undefined;
    const margin = canAdd ? 'none' : undefined;

    return (
      <Select //
        aria-label="Title reference"
        error={error}
        margin={margin}
        value={value}
        disabled={disabled}
        name={itemBinding}
        debug={debug}
        options={TITLE_REFERENCE_OPTIONS}
        onChange={(e, titleNumber) => {
          const tr = availableTitleReferences.find(({ reference }) => reference === titleNumber)!;
          setFieldValue(itemBinding, tr);
        }}
        onBlur={formikProps.handleBlur}
      />
    );
  };

  private renderPropertyVsTitleCombinatorItem = ({ itemBinding, item, fieldName }: DocumentArrayItemRenderProps<NoaPropertyDetailModel>) => {
    const { disabled, formikProps } = this.props;
    const {
      values: {
        propertyDetails,
        // all available titleReferences
        titleReferences: availableTitleReferences
      }
    } = formikProps;

    const shownTitleFields = propertyDetails.flatMap(ptc => ptc.titleReferences); // including null values
    const numberOfOutstandingTitlesToBeUsed = availableTitleReferences.length - shownTitleFields.length;
    const maxItems = item.titleReferences.filter(Boolean).length + numberOfOutstandingTitlesToBeUsed;

    return (
      <div //
        data-name={`${fieldName('titleReferences')}.selection`} /* danger:disable */
      >
        <DocumentFieldArray //
          arrayBinding={createModelKeyAppender<NoaPropertyDetailModel>(itemBinding)('titleReferences')}
          renderItem={this.renderTitleItemWithinPropertyCombinatorItem}
          disabled={disabled}
          itemStyle="nested"
          minItems={1}
          isSimpleType={true}
          maxItems={maxItems}
          addButtonTitle="Combine with another title"
          createNewItem={this.handleAddEmptyTitleToPropertyCombinatorItem.bind(null, item.propertyId)}
        />
      </div>
    );
  };

  private handleAddPropertyToCombinator = (): NoaPropertyDetailModel => {
    const {
      values: {
        propertyDetails,
        // all available titleReferences
        titleReferences: availableTitleReferences
      }
    } = this.props.formikProps;

    let titleReferences: NoaPropertyDetailModel['titleReferences'] = [null]; // explicitly assign empty value

    const usedTitleReferences = propertyDetails.flatMap(ptc => ptc.titleReferences.filter(Boolean)) as TitleReferenceModel[]; // excluding null values
    // automatically add last title so the user does need to do it manually
    if (availableTitleReferences.length - usedTitleReferences.length === 1) {
      titleReferences = _differenceWith<TitleReferenceModel, TitleReferenceModel>(
        availableTitleReferences,
        usedTitleReferences, //
        (a, b) => a.reference === b.reference
      );
    }

    return {
      propertyId: propertyDetails.length + 1,
      constructionOfMainStructure: null,
      landDimensions: {
        knownDetails: null,
        landArea: {
          areaUnit: null,
          quantity: null
        },
        unitOfEntitlement: ''
      },
      landUseType: null,
      municipalityName: null,
      municipalityPropertyNumber: '',
      numberOfBedrooms: null,
      primaryLandUse: null,
      salePricePerProperty: null,
      propertyStreetAddressBookId: '',
      titleReferences
    };
  };

  private renderPropertyVsTitleCombinatorLogic() {
    const {
      values: {
        propertyDetails,
        // all available titleReferences
        titleReferences: availableTitleReferences
      }
    } = this.props.formikProps;

    const shownTitleFields = propertyDetails.flatMap(ptc => ptc.titleReferences); // including null values
    const allTitlesHasBeenUsed = availableTitleReferences.length === shownTitleFields.length;
    const numberOfOutstandingTitlesToBeUsed = availableTitleReferences.length - shownTitleFields.length;
    // we need to calculate what's the maxItems which will also drive whether Add button will be shown
    const maxItems = Math.min(availableTitleReferences.length, propertyDetails.length + (allTitlesHasBeenUsed ? 0 : numberOfOutstandingTitlesToBeUsed));

    return (
      <DocumentFieldArray //
        arrayBinding={fieldName('propertyDetails')}
        renderItem={this.renderPropertyVsTitleCombinatorItem}
        itemTitle="Property"
        disabled={this.props.disabled}
        itemStyle="formGroup"
        minItems={2}
        maxItems={maxItems}
        addButtonTitle="Add property"
        createNewItem={this.handleAddPropertyToCombinator}
      />
    );
  }

  private renderPropertySeparator() {
    const {
      errors,
      values: {
        arePropertiesSeparatelyRated,
        // all available titleReferences
        titleReferences: availableTitleReferences
      }
    } = this.props.formikProps;

    if (availableTitleReferences.length < 2) {
      return null;
    }

    const error = errors.propertyDetails;
    return (
      <Section
        title={`Details of ${pluralize('property')}`}
        spacingBottom="none"
        error={typeof error === 'string' ? error : undefined}
        data-error-name={fieldName('propertyDetails')}
      >
        {this.renderPropertySeparatorRadio()}
        {arePropertiesSeparatelyRated && this.renderPropertyVsTitleCombinatorLogic()}
      </Section>
    );
  }

  private renderPropertyDetails() {
    const { classes, formikProps, disabled } = this.props;
    const { values } = formikProps;
    const {
      allTitlesAssigned,
      arePropertiesSeparatelyRated,
      // all available titleReferences
      titleReferences: availableTitleReferences,
      propertyDetails
    } = values;
    if (!allTitlesAssigned) {
      const { message: sectionMessage, touched } = resolveAllTitlesAssigned<NoticeOfAcquisitionDocumentModel>(formikProps, fieldName, values);
      const messageClass = touched ? classes.error : undefined;
      return (
        <Section title="Details of properties" className={classes.emptySectionPadding} spacingTop="small" data-name={fieldName('allTitlesAssigned')}>
          <Typography className={classNames(messageClass)}> {sectionMessage}</Typography>
        </Section>
      );
    }

    const titleSuffix = !arePropertiesSeparatelyRated && propertyDetails.length < 2 ? 'for all titles' : undefined;
    const spacingTop: SectionProps['spacingTop'] = availableTitleReferences.length > 1 ? 'small' : 'default';
    const spacingBottom: SectionProps['spacingBottom'] = availableTitleReferences.length > 1 ? 'none' : 'default';
    const propertyDetailsLastIndex = propertyDetails.length - 1;
    return propertyDetails.map((pd, i) => {
      const name = `${fieldName('propertyDetails')}[${i}]`;
      const title = `Details of property ${titleSuffix ?? i + 1}`;
      return (
        <div key={name} data-name={name} className={classes.root}>
          <Section title={title} spacingTop={spacingTop} spacingBottom={propertyDetailsLastIndex === i ? 'default' : spacingBottom}>
            <NoaPropertyDetail name={name} key={name} disabled={disabled} />
          </Section>
        </div>
      );
    });
  }

  render() {
    return (
      <div data-name={fieldName('propertyDetails')}>
        {this.renderPropertySeparator()}
        {this.renderPropertyDetails()}
      </div>
    );
  }
}

const StyledComponent = withStyles(styles)(SectionDetailsOfProperties);
export default StyledComponent;
