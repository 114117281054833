import React from 'react';

import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { SignDocumentTypeEnum } from '../../models';
import { useStyles } from './styles';

interface Props {
  id: string;
  certificationStatements: string[];
  acknowledgementStatements?: HttpTypes.AcknowledgementStatementModel[];
  signDocumentType: SignDocumentTypeEnum;
  totalAmount?: number;
  accountDescription?: string;
}

function CertificationStatements({ id, certificationStatements, acknowledgementStatements, signDocumentType, totalAmount, accountDescription }: Props) {
  const classes = useStyles();
  const hasCertificationStatements = certificationStatements.length > 0;
  const hasAcknowledgementStatements = acknowledgementStatements && acknowledgementStatements.length > 0;
  if (!hasCertificationStatements && !hasAcknowledgementStatements) {
    return null;
  }
  return (
    <div id={id}>
      {hasCertificationStatements && (
        <>
          <Typography className={classes.heading}>
            {signDocumentType === SignDocumentTypeEnum.TrustAccountAuthorisationRecord && totalAmount && accountDescription
              ? `By signing this document I authorise the debiting of ${currency(
                  totalAmount
                )} from ${accountDescription} as per financials. This authorisation is provided under the direction of:`
              : 'I certify that:'}
          </Typography>

          <ol className={classes.messages}>
            {certificationStatements.map(item => (
              <li key={item}>
                <Typography className={classes.statement}>{item}</Typography>
              </li>
            ))}
          </ol>
        </>
      )}
      {hasAcknowledgementStatements && (
        <div className={classes.ackStatementContainer}>
          <Typography className={classes.heading}>I acknowledge and agree that:</Typography>
          <ol className={classes.messages}>
            {acknowledgementStatements.map(item => (
              <li key={item.statement}>
                <Typography className={classes.statement}>{item.statement}</Typography>
                {item.subStatements && item.subStatements.length > 0 && (
                  <ol className={classNames(classes.messages, classes.subStatements)}>
                    {item.subStatements.map(sub => (
                      <li key={sub}>
                        <Typography className={classes.statement}>{sub}</Typography>
                      </li>
                    ))}
                  </ol>
                )}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
export default CertificationStatements;
