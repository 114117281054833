import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { ScreenSizeVariant } from 'src/theme';
import { FinancialDashboardRouteTabsEnumV2 } from '../models';

export const useFinancialTableRowDetailStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between'
    }
  }),
  {
    name: 'FinancialTableRowDetail'
  }
);

export interface StyleProps {
  screenVariant: ScreenSizeVariant;
  workspaceStatusGroup: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}

export const useFinancialTableStyles = makeStyles(
  (theme: Theme) => ({
    root({ screenVariant, workspaceStatusGroup, assignmentType }: StyleProps) {
      if (screenVariant === 'large-screen') {
        if (assignmentType === AssignmentTypeEnum.ReAssigned) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 28
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 28
            }
          };
        }
        if (
          //
          workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.recent ||
          //
          (workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.all && assignmentType === AssignmentTypeEnum.AssignedToGroup)
        ) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 36
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 36
            }
          };
        }
      }
      if (screenVariant === 'medium-screen') {
        if (assignmentType === AssignmentTypeEnum.ReAssigned) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 10
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 10
            }
          };
        }
        if (
          //
          assignmentType === AssignmentTypeEnum.AssignedToGroup &&
          workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.all
        ) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 14
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 14
            }
          };
        }
        if (
          //
          assignmentType === AssignmentTypeEnum.AssignedToGroup &&
          (workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.active || //
            workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.toBeRebooked)
        ) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 18
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: 18
            }
          };
        }
        if (
          //
          workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.recent
        ) {
          return {
            '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: assignmentType === AssignmentTypeEnum.AssignedToMe ? 18 : 14
            },
            '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
              paddingRight: assignmentType === AssignmentTypeEnum.AssignedToMe ? 18 : 14
            }
          };
        }
      }
      if (screenVariant === 'small-laptop' && assignmentType === AssignmentTypeEnum.ReAssigned) {
        return {
          '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 8
          },
          '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
            paddingRight: 8
          }
        };
      }
      return {
        '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: screenVariant === 'large-screen' ? 48 : screenVariant === 'medium-screen' ? 28 : 18
        },
        '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: screenVariant === 'large-screen' ? 48 : screenVariant === 'medium-screen' ? 28 : 18
        }
      };
    }
  }),
  {
    name: 'FinancialTable'
  }
);

export const useTableCellHeadCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableHeadCellSelection: {
      width: 24
    },
    headCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);

export const useTableCellBodyCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableBodyCellSelection: {
      width: 24
    },
    bodyCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);
