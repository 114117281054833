import environments from 'src/@core/environments';
import ApiGateway from 'src/api-sdk';

export * from '@sympli/http-agent';
export { default } from '@sympli/http-agent';
export type ApiStatus = 'idle' | 'pending' | 'refetching' | 'resolved' | 'rejected';

// constants
const { TARGET_MACHINE } = environments;

export const sdk = new ApiGateway({
  baseUrl: TARGET_MACHINE,
  basePath: '/gateway/api',
  baseVersion: '1',
  debug: import.meta.env.DEV
});
