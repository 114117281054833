import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useSafeDispatch } from 'src/hooks';
import { useTitleResupplyCost } from '../../reducers/titleResupplyCost';
import OrderTitleConfirmationDialog from './OrderTitleConfirmationDialog';

interface Props {
  workspaceId: string;
  participantId: string;
  ris: {
    reference: string;
    propertyAddress?: HttpTypes.ResupplyLandRegistryInformationApiResponse['propertyAddress'];
  };
  open: boolean;
  onClose: () => void;
}

function OrderTitleConfirmationDialogContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const titleResupplyCostState = useTitleResupplyCost(props.workspaceId, props.participantId);

  return (
    <OrderTitleConfirmationDialog //
      {...props}
      {...titleResupplyCostState}
      dispatch={dispatch}
    />
  );
}

export default React.memo(OrderTitleConfirmationDialogContainer);
