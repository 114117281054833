import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';

import { Store } from 'src/store/reducers';
import { ConversationDocument } from '../../models';

const getConversationList = <T extends Pick<Store, 'messengerConversations'>>(state: T) => state.messengerConversations.items;

export const conversationDetailSelector = createSelector<
  {
    messengerConversations: Store['messengerConversations'];
    conversationId: string;
  },
  string,
  ConversationDocument[],
  ConversationDocument | undefined
>(
  //
  ({ conversationId }) => conversationId,
  getConversationList,
  (conversationId, list) => list.find(item => item.conversationId === conversationId)
);

const getParticipants = <T extends Pick<Store, 'workspaceParticipants'>>(state: T): HttpTypes.WorkspaceParticipant[] => state.workspaceParticipants.items;

export const conversationParticipantMapSelector = createSelector<
  //
  Pick<Store, 'workspaceParticipants'>,
  HttpTypes.WorkspaceParticipant[],
  Map<string, HttpTypes.WorkspaceParticipant>
>(
  //
  getParticipants,
  (workspaceParticipants = []) =>
    workspaceParticipants.reduce<Map<string, HttpTypes.WorkspaceParticipant>>( //
      (result, participant) => result.set(participant.id, participant),
      new Map<string, HttpTypes.WorkspaceParticipant>()
    )
);
