import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchGroupMembers } from '../actions';

export interface GroupMemberItem {
  id: string;
  name: string;
}
export type GroupMembersApiResponse = GroupMemberItem[];

export interface GroupMembersState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: GroupMemberItem[];
  error?: string;
}

const initialState: GroupMembersState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined
};

export function useGroupMembers(): GroupMembersState {
  const state = useStoreSelector(store => store.groupMembers);
  return state;
}

const groupMembersReducer = createReducer<
  //
  GroupMembersState,
  Action
>(initialState)
  .handleAction(actionFetchGroupMembers.request, (state): GroupMembersState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined
    };
  })
  .handleAction(actionFetchGroupMembers.success, (state, action): GroupMembersState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data
    };
  })
  .handleAction(actionFetchGroupMembers.failure, (state, action): GroupMembersState => {
    return {
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    };
  });

export default groupMembersReducer;
