import { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconExclamationCircle } from '@sympli/ui-framework/icons';

import { jurisdictionNameMapping } from 'src/@core/models/jurisdictions';
import { workspaceTypeMapping } from 'src/containers/dashboard/components/create-new-workspace/models';
import { DuplicateWorkspaceItem } from './models';
import { useStyles } from './styles';

export interface Props {
  duplicateWorkspace: DuplicateWorkspaceItem;
}

const DuplicateWorkspace = ({ duplicateWorkspace }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <FlexLayout flexDirection="row">
          <IconExclamationCircle className={classes.iconWarning} />
          <Typography>
            <strong>The above land title(s) are already used in another active workspace</strong>
          </Typography>
        </FlexLayout>
      </Grid>

      <Grid item xs={6}>
        <FlexLayout flexDirection="column">
          <Typography component="div" className={classes.workspaceItem}>
            <strong>Participants in workspace</strong>
            <ul className={classes.noBullets}>
              {duplicateWorkspace.participantInfos.map((participant, index) => (
                <li key={index}>{participant}</li>
              ))}
            </ul>
          </Typography>

          <Typography component="div" className={classes.workspaceItem}>
            <strong>Proprietors on title</strong>
            <div>{duplicateWorkspace.proprietors.join(', ')}</div>
          </Typography>
        </FlexLayout>
      </Grid>

      <Grid item xs={6}>
        <FlexLayout flexDirection="column">
          <Typography component="div" className={classes.workspaceItem}>
            <strong>Sympli ID:</strong> <span>{duplicateWorkspace.sympliId}</span>
          </Typography>
          <Typography component="div" className={classes.workspaceItem}>
            <strong>Workspace type:</strong> <span>{workspaceTypeMapping[duplicateWorkspace.workspaceType]}</span>
          </Typography>
          <Typography component="div" className={classes.workspaceItem}>
            <strong>Jurisdiction:</strong> <span>{jurisdictionNameMapping[duplicateWorkspace.jurisdiction]}</span>
          </Typography>
          {duplicateWorkspace.assignedGroupNames.length > 0 && (
            <Typography component="div" className={classes.workspaceItem}>
              <strong>Assigned group:</strong> <span>{duplicateWorkspace.assignedGroupNames.join(', ')}</span>
            </Typography>
          )}
        </FlexLayout>
      </Grid>
    </Grid>
  );
};

export default memo(DuplicateWorkspace);
