import React from 'react';

import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';

import { HttpTypes } from '@sympli/api-gateway/types';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Logger, { PageActionEnum, SeverityEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import TopPanelContainer from 'src/components/layout/v2/TopPanelContainer';
import AsyncContentWrapper from 'src/containers/dashboard/reports/components/report-dashboard/components/async-content-wrapper';
import { useRequestReduxDataOnMount, useRouterParams, useSafeDispatch } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchScheduledReportHistory } from '../../actions';
import { fetchReportPresignedUrl } from '../../api';
import { useSchedulerDialog } from '../../hooks/useSchedulerDialog';
import { SavedScheduleReportModel, SchedulerFormikModel } from '../../models';
import { ScheduledReportHistoryState } from '../../reducers/scheduledReportHistory';
import ScheduledReportHistoryHeader from './components/scheduled-report-history-header/ScheduledReportHistoryHeader';
import ScheduledReportHistory from './ScheduledReportHistory';

interface RouteParams {
  id: string;
}

const PageWrapper = ({
  children
}: React.PropsWithChildren<{
  //
  header?: React.ReactNode;
  status: ApiStatus;
}>) => {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .container */}
      {/* .header */}
      <TopPanelContainer />
      <div className="grid grid-cols-[1fr] grid-rows-[1fr] overflow-hidden">
        {/* .body */}

        <div className="overflow-y-auto">
          {/* .scrollable-content */}
          {/* <div className="h-[128px] bg-[#EBFBFA]">
              <div className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
                <div className="flex h-[128px] shrink-0 flex-row items-center justify-between">
                  {header}
                </div>
              </div>
            </div> */}
          <div //
            className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function ScheduledReportHistoryContainer(): JSX.Element {
  const { id: parametersReportId } = useRouterParams<RouteParams>();
  const dispatch = useSafeDispatch(useDispatch());
  const { isSchedulerDialogOpen, schedulerDialogCloseHandler, schedulerDialogClickHandler, asyncDialogSchedulerDispatch } = useSchedulerDialog();

  const [
    {
      //
      id,
      reports,
      name,
      filterData,
      reportSendTime,
      scheduledFrequency,
      interval,
      daysOfWeek,
      dayOfMonth,
      reportType,
      status
    }
  ] = useRequestReduxDataOnMount<ScheduledReportHistoryState>(actionFetchScheduledReportHistory.request({ id: parametersReportId }), state => state.scheduledReportHistory);

  const [savedReports, setSavedReports] = React.useState<SavedScheduleReportModel[]>([]);

  React.useEffect(() => {
    setSavedReports(reports.map(report => ({ ...report, reportType, scheduledFrequency: scheduledFrequency!, isLoading: false })));
  }, [reportType, reports, scheduledFrequency]);

  const handleOnDownloadClick = async (id: number) => {
    try {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'download-pdf',
        logGroupId: 'reporting'
      });
      // loop through id, set that id equals current id to be isLoading true
      setSavedReports(prev => prev.map(report => (report.id === id ? { ...report, isLoading: true } : { ...report })));

      const report = await fetchReportPresignedUrl({ reportId: id });
      window.open(report.pdfUrl, '_blank');
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: 'Report has been downloaded',
          autoHideDuration: 3000,
          variant: 'new-success'
        })
      );
    } catch (error) {
      Logger.console(SeverityEnum.Log, error.message);
      dispatch(actionCreateGlobalErrorMessage(error));
    } finally {
      // no matter success or failure, set isLoading back to false
      setSavedReports(prev => prev.map(report => (report.id === id ? { ...report, isLoading: false } : { ...report })));
    }
  };

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<SchedulerFormikModel>) => {
    if (args.error) {
      return;
    }
    // close dialog
    asyncDialogSchedulerDispatch({ type: 'close' });
    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: 'Report has been scheduled',
        autoHideDuration: 3000,
        variant: 'new-success'
      })
    );
    // re-fetch data
    dispatch(actionFetchScheduledReportHistory.request({ id: parametersReportId }));
  };

  return (
    <PageWrapper //
      status={status}
    >
      <AsyncContentWrapper //
        status={status}
      >
        <ScheduledReportHistoryHeader //
          reportName={name}
          onEditClick={schedulerDialogClickHandler}
        />

        <Divider />

        <ScheduledReportHistory //
          savedReports={savedReports}
          handleOnDownloadClick={handleOnDownloadClick}
          handleOnSchedulerClose={schedulerDialogCloseHandler}
          isOpenScheduler={isSchedulerDialogOpen}
          onPostSubmit={handleOnPostSubmit}
          showDisclaimer={reportType === HttpTypes.ReportTypeEnum.UserActivityReport}
          initialValues={{ id, name, scheduledFrequency, filterData, reportSendTime, interval, daysOfWeek, dayOfMonth, reportType }}
        />
      </AsyncContentWrapper>
    </PageWrapper>
  );
}

export default React.memo(ScheduledReportHistoryContainer);
