import * as React from 'react';

import { Form, FormikProps } from 'formik';
import { batch } from 'react-redux';
import { Action } from 'redux';
import Button from '@mui/material/Button';

import { HttpTypes } from '@sympli/api-gateway/types';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';

import Tooltip from 'src/@core/components/tooltip';
import { actionUpdateDirectionsWorkspaceCurrentParticipantDetail } from 'src/@core/store/actions/directionsList';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import Formik from 'src/components/formik';
import { actionUpdateCurrentParticipantDetail, actionUpdateWorkspaceDocumentCurrentParticipantDetail } from 'src/store/actions/workspace';
import { modelKey } from 'src/utils/formUtils';
import { ReferenceFormModel } from './models';
import { useStyles } from './styles';
import getValidationSchema from './validationSchema';

import type { SafeDispatch } from 'src/hooks';

interface Props {
  reference: string;
  workspaceId: string;
  participantId: string;
  dispatch: SafeDispatch<Action>;
  distributionsStatus?: HttpTypes.DocumentStatusEnum;
  organisationalType?: HttpTypes.SubscriberOrganisationTypeEnum;
}

const fieldName = modelKey<ReferenceFormModel>();

const approvedDistributionSummaryList: Set<HttpTypes.DocumentStatusEnum> = new Set([
  //
  HttpTypes.DocumentStatusEnum.Signed,
  HttpTypes.DocumentStatusEnum.LodgementInProgress,
  HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress,
  HttpTypes.DocumentStatusEnum.Lodged
]);

const ReferenceForm = ({ reference, workspaceId, participantId, dispatch, distributionsStatus, organisationalType }: Props) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const classes = useStyles();

  const initialValues: ReferenceFormModel = {
    reference
  };

  const validationSchema = getValidationSchema(organisationalType);

  const handleEditClicked = () => {
    setIsEditing(true);
  };

  const handleOnBlur = (_e: React.FocusEvent<HTMLInputElement>, _v: any, formikProps: FormikProps<ReferenceFormModel>) => {
    if (!formikProps.isValid) {
      // if the user entered invalid value, reset it to initial
      formikProps.setFieldValue(fieldName('reference'), initialValues.reference);
      setIsEditing(false);
      return;
    }

    // submit only if the value was changed
    if (formikProps.values.reference !== initialValues.reference) {
      formikProps.submitForm();
    } else {
      setIsEditing(false);
    }
  };

  const handleOnPreSubmit = (values: ReferenceFormModel) => {
    // trim reference before submitting
    return { reference: values.reference.trim() };
  };

  const handleOnPostSubmit = ({ error, formValues }: FormikPostSubmitArgs<ReferenceFormModel>) => {
    setIsEditing(false);

    if (error) {
      dispatch(actionCreateGlobalErrorMessage({ message: error.message }));
      return;
    }

    const { reference: newReference } = formValues;
    batch(() => {
      // keep the matter reference of the current participant in the workspace detail box up to date
      dispatch(actionUpdateCurrentParticipantDetail({ workspaceId, participantId, reference: newReference }));
      // keep the tooltip matter reference of the current participant in the workspace document list up to date
      dispatch(actionUpdateWorkspaceDocumentCurrentParticipantDetail({ documentParticipant: { id: participantId, reference: newReference } }));
      // keep the tooltip matter reference of the current participant in the workspace distribution statuses up to date
      dispatch(actionUpdateDirectionsWorkspaceCurrentParticipantDetail({ workspaceParticipant: { id: participantId, reference: newReference } }));
    });
  };

  const renderForm = (formikProps: FormikProps<ReferenceFormModel>) => {
    const { isValid, errors, isSubmitting } = formikProps;

    if (!isEditing) {
      return (
        <Button //
          className={classes.referenceButton}
          variant="text"
          onClick={handleEditClicked}
          color="inherit"
          disabled={isSubmitting}
        >
          <span className={classes.textEllipsis}>{reference}</span>
        </Button>
      );
    }

    let tooltipMessage = '';
    if (!isValid) {
      tooltipMessage = errors.reference!;
    } else if (distributionsStatus && approvedDistributionSummaryList.has(distributionsStatus)) {
      const referenceType = organisationalType === HttpTypes.SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan' : 'matter';
      tooltipMessage = `Your ${referenceType} number will be automatically updated on your Financial Settlement Schedule`;
    }

    return (
      <Tooltip //
        classes={{ tooltip: classes.tooltip }}
        title={tooltipMessage}
        open={!!tooltipMessage.length}
      >
        <Form>
          <Field //
            InputProps={{
              classes: {
                input: classes.referenceInput
              }
            }}
            className={classes.reference}
            name={fieldName('reference')}
            component={InputField}
            onBlur={(e: React.FocusEvent<HTMLInputElement>, v: any) => handleOnBlur(e, v, formikProps)}
            autoFocus={true}
            aria-label="Reference"
            fullWidth
          />
        </Form>
      </Tooltip>
    );
  };

  return (
    <Formik //
      method="put"
      action={`/workspaces/${encodeURIComponent(workspaceId)}/participant/${encodeURIComponent(participantId)}/reference`}
      enableReinitialize={true}
      initialValues={initialValues}
      onPostSubmit={handleOnPostSubmit}
      onPreSubmit={handleOnPreSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<ReferenceFormModel>) => renderForm(formikProps)}
    </Formik>
  );
};

export default ReferenceForm;
