import * as React from 'react';

import LogoutContext from 'src/@core/auth/Logout.context';

const Logout = () => {
  const logoutContext = React.useContext(LogoutContext);

  React.useEffect(() => {
    logoutContext.logOut();
  }, [logoutContext]);

  return null;
};

export default React.memo(Logout);
