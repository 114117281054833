import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/@core/theme/colors';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    avatarRoot: {
      width: 48
    },
    avatar: {
      fontSize: 16,
      fontWeight: 600,
      backgroundColor: colors.PROFILE_GREEN_HOVER,
      color: colors.NEUTRAL_1000,
      '&:hover': {
        color: colors.SYMPLI_GREEN
      }
    },
    avatarSelected: {
      color: colors.SYMPLI_GREEN
    }
  }),
  {
    name: 'UserProfileNav'
  }
);
