import React from 'react';

import { useDispatch } from 'react-redux';
import { MenuItemProps } from '@mui/material/MenuItem';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { isAllowedToDeletePrimaryLodgementCaseDocuments } from 'src/containers/shared/document-list/components/document-list-toolbar/helpers';
import DeleteDocument from 'src/containers/workspace/shared/components/menu-items/delete-document';
import SwitchFormView from 'src/containers/workspace/shared/components/menu-items/switch-form-view';
import UnsignDocument from 'src/containers/workspace/shared/components/menu-items/unsign-document';
import { docUpdateHelper } from 'src/containers/workspace/shared/components/menu-items/unsign-document/helper';
import WithdrawDocument from 'src/containers/workspace/shared/components/menu-items/withdraw-document';
import { WorkflowPanelIssuesContainer } from 'src/containers/workspace/shared/components/workflow-panel/components/workflow-panel-issues';
import { useSafeDispatch } from 'src/hooks';
import { actionToggleCurrentParticipantDocDisplay } from 'src/store/actions/workspace';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import { DocumentWorkflowPanelProps } from '../components/document-workflow-panel';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from '../models';
import { memoizedWorkflowSteps } from '../steps';

function useWorkflowPanel(
  queryParams: DocumentPageRouteParams,
  workflowStep: DocumentWorkflowStepsEnum,
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum,
  detail: DocumentFormAndDetailModel,
  displayDocumentAsPdf: boolean,
  lodgementCase: HttpTypes.LodgementCase,
  lodgementCaseDocuments: HttpTypes.WorkspaceDocumentSummary[],
  onStepClick: (_: React.MouseEvent<HTMLButtonElement>, targetStep: DocumentWorkflowStepsEnum) => void,
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  isCriticalRolesEnabled: boolean,
  isDocumentCombinationEnabled: boolean
) {
  const dispatch = useSafeDispatch(useDispatch());
  const { participantId, documentId, workspaceId } = queryParams;
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const isLiDoc = HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions === detail.documentForm.documentType;
  const lodgementCaseDocument = lodgementCaseDocuments.find(d => d.documentId === documentId);
  const acceptedParticipants = lodgementCaseDocument?.documentParticipants?.filter(p => p.participantStatus.id === HttpTypes.ParticipantStatusEnum.Accepted) ?? [];

  if (!detail) {
    return undefined;
  }

  const canDeleteDocument = () => {
    const hasRemovePermission: boolean = detail.documentPermissions.includes(HttpTypes.DocumentPermissionEnum.Remove);

    if (isLiDoc) {
      return false;
    }

    if (isDocumentCombinationEnabled) {
      return hasRemovePermission && acceptedParticipants.length === 1;
    }

    const isParticipantAllowedToDeletePlcDoc = isAllowedToDeletePrimaryLodgementCaseDocuments({
      workspaceType: workspaceTypeId,
      currentParticipantId: participantId,
      workspaceParticipants: workspaceParticipantsState
    });

    // Purchaser, Vendor and Beneficiary participants should only be able to delete their SLC documents,
    // They are not allowed to delete PLC documents even though they have Remove permission at document level
    return (
      (isParticipantAllowedToDeletePlcDoc && hasRemovePermission) ||
      (workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.RegistrationOnly && //
        (!lodgementCase?.isPrimary ||
          (isCriticalRolesEnabled && detail.documentForm.documentType === HttpTypes.DocumentTypeIdentifierEnum.Transfer && acceptedParticipants.length === 1)))
    );
  };

  const handleUnsignClick = () =>
    endpoints
      .unsignMultipleDocuments(queryParams, { documentIds: [documentId] })
      .then((response: HttpTypes.UnsignWorkspaceDocumentsApiResponse[]) => {
        docUpdateHelper(dispatch, queryParams, response, detail);
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message: `${detail.documentForm.name} was unsigned.`,
            variant: 'new-success'
          })
        );
      })
      .catch(error => dispatch(actionCreateGlobalErrorMessage(error)));

  const handleViewChange = () => dispatch(actionToggleCurrentParticipantDocDisplay(participantId));

  const renderMenuList = (): React.ReactElement<MenuItemProps>[] => {
    const menuItems: React.ReactElement<MenuItemProps>[] = [];
    const hasPermissionToUnsign = detail.documentPermissions.includes(HttpTypes.DocumentPermissionEnum.Unsign);

    if (hasPermissionToUnsign) {
      menuItems.push(<UnsignDocument onClick={handleUnsignClick} />);
    }

    if (!isLiDoc) {
      menuItems.push(<SwitchFormView displayDocumentAsPdf={displayDocumentAsPdf} onClick={handleViewChange} />);
    }

    const canWithdrawFromDocument =
      detail.documentForm.documentType === HttpTypes.DocumentTypeIdentifierEnum.Transfer &&
      acceptedParticipants.length > 1 &&
      detail.documentPermissions.some(dp => [HttpTypes.DocumentPermissionEnum.Write, HttpTypes.DocumentPermissionEnum.Review, HttpTypes.DocumentPermissionEnum.Sign].includes(dp));
    if (isCriticalRolesEnabled && canWithdrawFromDocument) {
      menuItems.push(<WithdrawDocument onClick={() => setOpenDialog(true)} />);
    }

    if (canDeleteDocument()) {
      menuItems.push(<DeleteDocument onClick={() => setOpenDeleteDialog(true)} />);
    }
    return menuItems;
  };

  const { steps, lastEnabledStep } = memoizedWorkflowSteps(detail);
  // * When editing form, workflow panel is alway 'light'
  const mode = [DocumentWorkflowStepsEnum.Write, DocumentWorkflowStepsEnum.Resolve].includes(workflowStep) || !displayDocumentAsPdf ? 'light' : 'dark';
  const workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'> = {
    mode,
    steps,
    lastEnabledStep,
    currentStep: workflowStep,
    menuItems: renderMenuList(),
    documentIssues: <WorkflowPanelIssuesContainer buttonTheme={mode} queryParams={queryParams} />,
    onStepClick
  };

  return workflowPanelProps;
}

export { useWorkflowPanel };
