import * as yup from 'yup';

import { Form24A_2_24_1Model } from './models';
import { yupPartyBook } from './sections/party-book/validationSchema';
import { yupTransactionData } from './sections/transaction-details/validationSchema';
import { yupTransfereeData } from './sections/transferee-details/validationSchema';

export default yup
  .object<Form24A_2_24_1Model>()
  .partial({
    partyBook: yupPartyBook,
    transfereeDetails: yupTransfereeData,
    transactionDetails: yupTransactionData
  })
  .defined()
  .log();
