import { HttpTypes } from '@sympli/api-gateway/types';

import { WorkspaceParticipantsState } from 'src/store/reducers/workspace/workspaceParticipants';

const ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS: HttpTypes.WorkspaceRoleEnum[] = [
  //
  HttpTypes.WorkspaceRoleEnum.DischargingMortgagee,
  HttpTypes.WorkspaceRoleEnum.DischargingCaveator,
  HttpTypes.WorkspaceRoleEnum.IncomingMortgagee,
  HttpTypes.WorkspaceRoleEnum.IncomingCaveator,
  HttpTypes.WorkspaceRoleEnum.ToDealWithAnInterest
];

const ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS_MLC: HttpTypes.WorkspaceRoleEnum[] = [
  //
  HttpTypes.WorkspaceRoleEnum.Purchaser,
  HttpTypes.WorkspaceRoleEnum.Vendor,
  HttpTypes.WorkspaceRoleEnum.Beneficiary
];

export function isAllowedAdditionalDocumentsForParticipant(props: {
  workspaceType?: HttpTypes.WorkspaceTypeEnum; //
  currentParticipantId?: string;
  workspaceParticipants: WorkspaceParticipantsState;
  titleReferences: HttpTypes.TitleReference[];
}) {
  const { workspaceParticipants, workspaceType, currentParticipantId, titleReferences } = props;
  if (titleReferences.length === 0) {
    return false;
  }

  if (workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly) {
    return true;
  }
  const allowedRoles = ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS.concat(ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS_MLC);

  const currentParticipant = workspaceParticipants.items.find(p => p.id === currentParticipantId);
  return Boolean(currentParticipant && allowedRoles.some(role => role === currentParticipant.workspaceRole.id));
}

export function isAllowedToDeletePrimaryLodgementCaseDocuments(props: {
  workspaceType?: HttpTypes.WorkspaceTypeEnum; //
  currentParticipantId?: string;
  workspaceParticipants: WorkspaceParticipantsState;
}) {
  const { workspaceParticipants, workspaceType, currentParticipantId } = props;
  if (workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly) {
    return true;
  }
  const currentParticipant = workspaceParticipants.items.find(p => p.id === currentParticipantId);
  return Boolean(currentParticipant && ALLOWED_WORKSPACE_ROLES_ADDITIONAL_DOCUMENTS.some(role => role === currentParticipant.workspaceRole.id));
}
