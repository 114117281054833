import dateFormat from 'dateformat';

import { vicNameChangeConversion } from '@sympli-mfe/document-components/party-form/vic/2-28/components/party-justification';
import { convertReceivingTenancyDetailFromApiToForm, convertReceivingTenancyDetailFromFormToApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { getPurchaser } from 'src/containers/documents/helpers';
import { VIC_M_ADDRESS_FORM_CONFIG, VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { createFormMortgagors, generateMortgagors } from './helpers';
import { ApiMortgage2_28_0Model, Mortgage2_28_0Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: VIC_M_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: VIC_M_PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  // Add name change conversion here if necessary
  // eg. nameChangeConversion: saNameChangeConversion
  nameChangeConversion: vicNameChangeConversion
});

function convertFromApiToFormModel(apiModel: ApiMortgage2_28_0Model, context: IConverterContext): Mortgage2_28_0Model {
  // Implement any necessary conversion from API to form values
  const relinquishingIds = apiModel.mortgagors.map(p => p.partyBookId!).concat(apiModel.transferees.map(t => t.partyBookId!));
  const purchaserName = getPurchaser(context.participants);
  const dataSourceToNameMap = purchaserName ? new Map<DataSource, string>().set(DataSource.Transfer, purchaserName) : undefined;

  let formModel: Mortgage2_28_0Model = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || [], relinquishingIds, dataSourceToNameMap),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || [], dataSourceToNameMap),
    mortgagors: createFormMortgagors(apiModel.mortgagors),
    mortgageeDetails: convertReceivingTenancyDetailFromApiToForm(apiModel.mortgageeDetails)
  };

  if (apiModel.needToGenerateMortgagors) {
    formModel = generateMortgagors(formModel);
  }

  formModel = applyDefaultMap(formModel, fallbackMap);

  return formModel;
}

function convertFromFormToApiModel(formModel: Mortgage2_28_0Model, originalApiModel: ApiMortgage2_28_0Model): ApiMortgage2_28_0Model {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    mortgagors: mortgagorsFromForm,
    mortgageeDetails: mortgageeDetailsFromForm,
    termsAndConditions,
    ...rest
  } = formModel;
  return {
    ...rest,
    needToGenerateMortgagors: false,
    mortgagors: mortgagorsFromForm.proprietorGroups.flatMap(pg => pg.parties).map(p => ({ id: p.partyBookId!, partyBookId: p.partyBookId!, addressBookId: '' })),
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || []),
    mortgageeDetails: convertReceivingTenancyDetailFromFormToApi({ formReceivingTenancyDetail: mortgageeDetailsFromForm, partyBookFromForm: partyBookFromForm || [] }),
    termsAndConditions: termsAndConditions
      ? {
          ...termsAndConditions,
          mortgageDate: termsAndConditions?.mortgageDate ? dateFormat(termsAndConditions?.mortgageDate as Date, DateFormatEnum.DATE) : null
        }
      : undefined
  };
}

class Converter implements IConverter<Mortgage2_28_0Model, ApiMortgage2_28_0Model> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
