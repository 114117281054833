import * as React from 'react';

import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Box from 'src/@core/components/layout/box';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { Card } from 'src/containers/dashboard/components/card';
import { useWorkspaceGroups } from 'src/containers/dashboard/shared/reducers/workspaceGroups';
import { useSafeDispatch } from 'src/hooks';
import { FinancialRoleMapping } from 'src/models/roles';
import { getWorkspaceOverviewUrl, getWorkspaceSettlementDateTimeUrl } from 'src/router/helpers';
import { useLinkedLodgementWorkspaces } from 'src/store/reducers/workspace/linkedLodgementDetail';
import { useLinkedSettlementWorkspaces } from '../../../../../../store/reducers/workspace/linkedSettlementDetail';
import { useWorkspaceDetail } from '../../../../../../store/reducers/workspace/workspaceDetail';
import FinancialLinkedWorkspaceStatusBadge from '../../../components/financial-linked-workspace-status-badge';
import { getLinkedWorkspaceTableRow } from './helper';
import LinkedWorkspacesTable from './linked-workspace-table';
import { getLinkedLodgementParticipantColumns, getLinkedLodgementParticipantRows } from './linked-workspace-table/link-lodgement-row-detail/helper';
import LinkingLodgementParticipantCard from './linked-workspace-table/link-lodgement-row-detail/LinkingLodgementParticipantCard';
import { LinkedWorkspaceTableModel, Variant } from './linked-workspace-table/models';
import { getLinkedParticipantColumns, getLinkedParticipantRows } from './linked-workspace-table/row-detail/helper';
import LinkingParticipantCard from './linked-workspace-table/row-detail/LinkingParticipantCard';
import { LinkedWorkspaceTypeMapping, ParticipantsHaveAccessToLinkedWorkspace } from './models';

//todo: rename to linkedWorkspaceDetail as it is the combination of LL/LS
interface LinkedSettlementDetailContainerProps {
  workspaceId: string;
  participantId: string;
  clusterId: string;
  userId: string;
  subscriberId: string;
  isSdtView?: boolean;
  viewLabelOverride?: string;
  variant?: Variant;
}

function LinkedSettlementDetailContainer(props: LinkedSettlementDetailContainerProps) {
  const { workspaceId, participantId, clusterId, userId, subscriberId, isSdtView, variant } = props;

  const linkedSettlementDetail = useLinkedSettlementWorkspaces(workspaceId, clusterId);
  const linkedLodgementDetail = useLinkedLodgementWorkspaces(workspaceId, clusterId);

  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const linkedWorkspaceOverallStatus = workspaceDetailState.detail?.linkedWorkspaceCluster?.linkedWorkspaceOverallStatus;
  const linkedWorkspaceType = workspaceDetailState.detail?.linkedWorkspaceCluster?.linkedWorkspaceType;
  const useWorkspaceGroupsState = useWorkspaceGroups();
  const userGroupIds = useWorkspaceGroupsState.items.map(d => d.id);
  const dispatch = useSafeDispatch(useDispatch());
  const [rowDetailIndex, setRowDetailIndex] = React.useState<number | undefined>(undefined);
  const [open, setIsOpen] = React.useState(false);
  const [participantsHaveAccess, setParticipantsHaveAccess] = React.useState<ParticipantsHaveAccessToLinkedWorkspace[]>([]);
  const handleOnClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleOnRoleClick = React.useCallback(
    (linkedToParticipantId: string, linkedToWorkspaceId: string) => {
      if (isSdtView) {
        window.open(getWorkspaceSettlementDateTimeUrl(linkedToWorkspaceId, linkedToParticipantId));
      } else {
        window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, linkedToParticipantId));
      }

      // close the current window
      handleOnClose();
    },
    [isSdtView, handleOnClose]
  );

  //1: if there is one role, always display view button, if no permission show the toast message, if has permission open new tab
  //2: if there is multiple roles, but only one role has group access, then open the new tab when click on the view button. Otherwise show the pop up dialog with all the accessible roles.
  const handleOnViewClick = React.useCallback(
    (participantsIsSameSubscriber: HttpTypes.LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => {
      const isLinkedSettlement = linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement;
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: isLinkedSettlement ? 'linked-settlement' : 'linked-lodgement',
        logGroupId: 'workspace',
        route: isSdtView ? 'workspace-proposal' : 'workspace-overview',
        action: isLinkedSettlement ? 'view-linked-settlement' : 'view-linked-lodgement',
        workspaceId,
        participantId,
        linkedToWorkspaceId
      });

      const noPermissionToast = () =>
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: <Typography className="text-[14px] text-[var(--neutral-000)]">You do not have permission to view the workspace</Typography>,
            variant: 'new-error',
            autoHideDuration: 6 * 1000
          })
        );

      if (participantsIsSameSubscriber.length === 1) {
        // iop does not have the group id
        if (!participantsIsSameSubscriber[0].groupId || userGroupIds.indexOf(participantsIsSameSubscriber[0].groupId) === -1) {
          noPermissionToast();

          return;
        }

        if (isSdtView) {
          window.open(getWorkspaceSettlementDateTimeUrl(linkedToWorkspaceId, participantsIsSameSubscriber[0].participantId));
        } else {
          window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, participantsIsSameSubscriber[0].participantId));
        }

        return;
      }

      const participantsHaveGroupAccess = participantsIsSameSubscriber.filter(d => {
        // block iop workspace access since they do not have group id
        if (!d.groupId) {
          return false;
        }
        if (userGroupIds.indexOf(d.groupId) !== -1) {
          return true;
        }
        return false;
      });

      if (participantsHaveGroupAccess.length === 1) {
        if (isSdtView) {
          window.open(getWorkspaceSettlementDateTimeUrl(linkedToWorkspaceId, participantsHaveGroupAccess[0].participantId));
        } else {
          window.open(getWorkspaceOverviewUrl(linkedToWorkspaceId, participantsHaveGroupAccess[0].participantId));
        }
      } else if (participantsHaveGroupAccess.length === 0) {
        // meaning no group access, error pop up
        noPermissionToast();
      } else {
        setIsOpen(true);

        setParticipantsHaveAccess(
          participantsHaveGroupAccess.map(
            d =>
              ({
                ...d,
                workspaceId: linkedToWorkspaceId
              }) as ParticipantsHaveAccessToLinkedWorkspace
          )
        );
      }
    },
    [userGroupIds, isSdtView, dispatch, workspaceId, participantId]
  );

  const handleOnRowToggle = React.useCallback(
    (newRowDetailIndex: number, _: LinkedWorkspaceTableModel) => {
      if (newRowDetailIndex !== rowDetailIndex) {
        const isLinkedSettlement = linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement;
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: isLinkedSettlement ? 'linked-settlement' : 'linked-lodgement',
          logGroupId: 'workspace',
          route: isSdtView ? 'workspace-proposal' : 'workspace-overview',
          action: isLinkedSettlement ? 'open-linked-settlement-table-expansion-panel' : 'open-linked-lodgement-table-expansion-panel',
          workspaceId,
          participantId
        });
        setRowDetailIndex(newRowDetailIndex);
      } else {
        setRowDetailIndex(undefined);
      }
    },
    [setRowDetailIndex, rowDetailIndex, workspaceId, participantId, isSdtView]
  );

  const title = React.useMemo(() => {
    if (linkedWorkspaceType === undefined) {
      return '';
    }
    return LinkedWorkspaceTypeMapping[linkedWorkspaceType];
  }, [linkedWorkspaceType]);

  const rows = React.useMemo(() => {
    if (linkedWorkspaceType === undefined) {
      return [];
    }

    const rowData = getLinkedWorkspaceTableRow(workspaceId, linkedWorkspaceType, linkedSettlementDetail, linkedLodgementDetail);
    return rowData;
  }, [linkedWorkspaceType, linkedSettlementDetail, linkedLodgementDetail, workspaceId]);

  const apiStatus = React.useMemo(() => {
    if (linkedWorkspaceType === undefined) {
      return undefined;
    }

    return linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement ? linkedLodgementDetail.status : linkedSettlementDetail.status;
  }, [linkedWorkspaceType, linkedSettlementDetail, linkedLodgementDetail]);

  const linkedDetail = React.useMemo(
    () =>
      rowDetailIndex === undefined ? null : linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement ? (
        <div className="p-[20px] flex">
          <Card>
            <LinkingLodgementParticipantCard
              //
              rows={getLinkedLodgementParticipantRows(rows, rowDetailIndex)}
              columns={getLinkedLodgementParticipantColumns()}
            />
          </Card>
        </div>
      ) : (
        <div className="p-[20px] flex">
          <Card>
            <LinkingParticipantCard
              //
              rows={getLinkedParticipantRows(rows, rowDetailIndex)}
              columns={getLinkedParticipantColumns()}
            />
          </Card>
        </div>
      ),
    [linkedWorkspaceType, rows, rowDetailIndex]
  );

  if (linkedWorkspaceType === undefined) {
    return null;
  }

  return (
    <>
      <ConfirmationDialog
        //
        title={'Select your role'}
        open={open}
        showActionButtons={false}
        onClose={handleOnClose}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 400 }}>You have multiple roles in this workspace,</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 400 }}> please select the role you wish to continue with:</Typography>
        <List //
          sx={{
            listStyleType: 'disc',
            paddingLeft: '23px',
            paddingTop: '20px',
            '& .MuiListItem-root': {
              display: 'list-item',
              fontSize: 14,
              fontWeight: 700,
              lineHeight: '20px',
              cursor: 'pointer',
              padding: '2px 0px',
              textDecorationLine: 'underline'
            }
          }}
        >
          {participantsHaveAccess.map(d => {
            return (
              <ListItem
                //
                onClick={() => handleOnRoleClick(d.participantId, d.workspaceId)}
              >
                {FinancialRoleMapping[d.workspaceRole]}
              </ListItem>
            );
          })}
        </List>
        <ButtonLink //
          sx={{ fontSize: 14, fontWeight: 700, lineHeight: '20px' }}
          onClick={handleOnClose}
        >
          Cancel
        </ButtonLink>
      </ConfirmationDialog>

      <Box
        //
        title={title}
      >
        {linkedWorkspaceOverallStatus !== undefined && (
          <FinancialLinkedWorkspaceStatusBadge linkedWorkspaceOverallStatus={linkedWorkspaceOverallStatus} workspaceStatus={workspaceDetailState.detail?.workspaceStatus.id} />
        )}
        <LinkedWorkspacesTable
          //
          apiStatus={apiStatus}
          rows={rows}
          rowDetailNode={linkedDetail}
          rowDetailIndex={rowDetailIndex}
          onRowToggle={handleOnRowToggle}
          onViewClick={handleOnViewClick}
          viewButtonLabel={isSdtView ? 'View Setl Date & Time' : undefined}
          variant={variant ?? (isSdtView ? 'large' : 'medium')}
          userProfileDetail={{ userId, subscriberId, groupIds: userGroupIds }}
          showOrderColumn={linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement}
        />
      </Box>
    </>
  );
}

export default React.memo(LinkedSettlementDetailContainer);
