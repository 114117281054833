import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { actionFetchProfile } from '../actions/profile';

const fetchProfile = async () => http.get<HttpTypes.UserProfileModel>('/profile');

function* sagaFetchProfile() {
  try {
    const data = yield* call(fetchProfile);
    yield put(actionFetchProfile.success({ data }));
  } catch (error) {
    yield put(actionFetchProfile.failure({ error, redirectOnClose: '/logout' }));
  }
}

export default [
  //
  takeLatest(actionFetchProfile.request, sagaFetchProfile)
];
