import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { DocumentFormIdentifierDisplayMapping } from 'src/@core/models/document';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { DocumentPageRouteParams } from 'src/containers/documents/models';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { WorkspaceDetailRouteLocationState } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { actionFetchWorkspaceBasicInfo, actionFetchWorkspaceById, actionFetchWorkspaceDocuments, actionFetchWorkspaceParticipants } from 'src/store/actions/workspace';
import { deleteDocument } from '../document-list-delete/api';
import DeleteDocumentDialog from './DeleteDocumentDialog';

import type { SafeDispatch } from 'src/hooks';

interface Props {
  isOpen: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  documentId: string;
  documentType: HttpTypes.DocumentTypeIdentifierEnum;
  documentName: string;
  lodgementCase?: HttpTypes.LodgementCase;
  liDocumentId?: string;
  onCancel?(): void;
}

function DeleteDocumentDialogContainer({ isOpen, setOpenDialog, workspaceTypeId, documentId, documentType, documentName, lodgementCase, liDocumentId, onCancel }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const navigate = useNavigate();
  const queryParams = useRouterParams<DocumentPageRouteParams>();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleOnClose = () => {
    setOpenDialog(false);
  };

  const handleOnNext = async () => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'delete-document',
      logGroupId: 'workspace',
      workspaceId: queryParams.workspaceId,
      participantId: queryParams.participantId,
      documentType: DocumentFormIdentifierDisplayMapping[documentType],
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId]
    });

    try {
      // After navigate() saga does not get handled, so handling refresh in WorkspaceDetailPageContainer using navigate() state.
      let handleRefresh: boolean = true;
      setIsDeleting(true);

      // Toast before delete() otherwise navigate() will stop toast render
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: 'Document has been deleted',
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );

      if (queryParams.documentId === documentId || lodgementCase?.isPrimary === false) {
        const state: WorkspaceDetailRouteLocationState = {
          isDocumentDeleted: true
        };

        handleRefresh = false;
        navigate(resolveWorkspaceDetailLink({ workspaceId: queryParams.workspaceId, participantId: queryParams.participantId }), { state });
      }
      await deleteDocument(queryParams.workspaceId, documentId, queryParams.participantId);
      setIsDeleting(false);

      handleOnClose();

      if (handleRefresh) {
        refresh(dispatch, queryParams.workspaceId, queryParams.participantId, queryParams.documentId, liDocumentId);
      }

      // close the DocumentListDelete
      onCancel && onCancel();
    } catch (error) {
      setIsDeleting(false);
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  };

  return (
    <DeleteDocumentDialog //
      isOpen={isOpen}
      documentName={documentName ?? ''}
      isDeleting={isDeleting}
      onNext={handleOnNext}
      onClose={handleOnClose}
    >
      {workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly || !lodgementCase || lodgementCase.isPrimary ? (
        <Typography>This document will be permanently deleted from the workspace.</Typography>
      ) : (
        <Typography>
          This document and any related content (including the {lodgementCase?.name} and Lodgement Instructions) will be <b>permanently deleted</b> for all workspace participants.
        </Typography>
      )}
    </DeleteDocumentDialog>
  );
}

export function refresh(dispatch: SafeDispatch<any>, workspaceId: string, participantId: string, documentId?: string, liDocumentId?: string) {
  batch(() => {
    // remove document from document list, and fetch workspace tasks again, this should be manual
    dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
    dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
    dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
    dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
  });
}

export default React.memo(DeleteDocumentDialogContainer);
