import React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { VicAddressSelectField as AddressSelectField } from '@sympli-mfe/document-components/address-field/vic/2-28';
import { VIC_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/vic/2-28/config';
import TextOnly from '@sympli-mfe/document-forms-framework/components/text-only';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, resolvePlaceholder, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import { useAddressBookOptions, usePartyBookOptions } from '@sympli-mfe/document-forms-framework/utils/hooks';
import { SRO_PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-28/vic';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import PhoneNumberField from '@sympli/ui-framework/components/formik/phone-number-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { VIC_NOA_ADDRESS_FORM_CONFIG, VIC_NOA_CORRESPONDENCE_ADDRESS_FORM_CONFIG } from '../../config';
import { NoticeOfAcquisitionDocumentModel, TransferorDetailModel } from '../../models';
import { VISIBILITY_CHECK_STATE_REVENUE_OFFICE_HELPER, VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE, VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL } from '../../visibilityChecks';
import HelperText from '../helper-text';
import { resolveContactDetailsMeta } from '../transferee-detail/helpers';
import { useStyles } from './styles';

const debug = !import.meta.env.PROD;

interface Props {
  name: string;
}

function TransferorDetail({ name }: Props): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<NoticeOfAcquisitionDocumentModel>();
  const { values } = formikProps;
  const value: TransferorDetailModel = _get(values, name);
  const { disabled, dialogPortalId } = useDocumentContext();
  const addressBookOptions = useAddressBookOptions(values.addressBook, VIC_NOA_ADDRESS_FORM_CONFIG);
  const partyBookOptions = usePartyBookOptions(values.partyBook, VIC_PARTY_FORM_CONFIG);

  const fieldName = createModelKeyAppender<TransferorDetailModel>(name);

  const {
    //
    phoneIsMandatory,
    emailIsMandatory,
    contactDetailMessage,
    showContactDetailMessageAsError
  } = resolveContactDetailsMeta<TransferorDetailModel>(formikProps, fieldName, value);

  return (
    <div data-name={name} className={classes.root}>
      <TextOnly //
        label="Name"
        name={fieldName('partyBookId')}
        value={value.partyBookId}
        options={partyBookOptions}
        fullWidth
      />

      {VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE(value, null, values) && (
        <>
          <Field //
            name={fieldName('birthDate')}
            component={DatePickerField}
            disabled={disabled}
            label="Date of birth"
            className={classes.quarterWidth}
            disableFuture
            validationOverflowDirection="horizontal"
          />
          <br />
        </>
      )}

      <Field //
        name={fieldName('sroPartyCapacity')}
        component={SelectField}
        debug={debug}
        disabled={disabled}
        format="string"
        label="Representative capacity for State Revenue Office"
        options={SRO_PARTY_CAPACITY_LOOKUP_OPTIONS}
        placeholder={resolveSelectPlaceholder(true)}
        fullWidth
      />
      {VISIBILITY_CHECK_STATE_REVENUE_OFFICE_HELPER(value) && (
        <>
          {/**
           * When a user selects either CustodianBareTrustee or TrusteeOfATrust,
           * they have to send a “Trust NOA” to the SRO outside of Sympli.  Not doing so can have dire consequences
           */}
          <HelperText>
            Under section 46K (1) of the Land Tax Act 2005 the Notice of Trust Acquisition of an interest in land form must be lodged with the SRO by a Trustee acquiring land in
            Victoria on trust, within one month of settlement.
          </HelperText>
          <br />
        </>
      )}

      {VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL(value) && (
        <Field //
          name={fieldName('partyCapacityDetail')}
          component={InputField}
          disabled={disabled}
          label="Trust name"
          placeholder={resolvePlaceholder()}
          fullWidth
        />
      )}

      <AddressSelectField //
        name={fieldName('addressAtTimeOfTransferBookId')}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        label="Address at TIME of transfer"
        optionsOverride={addressBookOptions}
        addressFormConfig={VIC_NOA_ADDRESS_FORM_CONFIG}
      />
      <AddressSelectField //
        name={fieldName('correspondenceAddressBookId')}
        bookRef="addressBook"
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        label="Address for FUTURE correspondence"
        optionsOverride={addressBookOptions}
        addressFormConfig={VIC_NOA_CORRESPONDENCE_ADDRESS_FORM_CONFIG}
      />
      <HelperText //
        data-name={fieldName('missingContactDetails')}
        data-error-name={fieldName('missingContactDetails')}
        variant={showContactDetailMessageAsError ? 'error' : 'default'}
      >
        {contactDetailMessage}
      </HelperText>

      <Field //
        name={fieldName('contactPhoneNumber')}
        component={PhoneNumberField}
        disabled={disabled}
        label="Phone"
        placeholder={resolvePlaceholder(phoneIsMandatory)}
      />
      <Field //
        name={fieldName('emailAddress')}
        component={InputField}
        disabled={disabled}
        label="Email"
        placeholder={resolvePlaceholder(emailIsMandatory)}
        fullWidth
      />
    </div>
  );
}

export default React.memo(TransferorDetail);
