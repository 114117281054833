import { combineReducers } from 'redux';

import { submitFormikReducer as submitFormik } from '@sympli/ui-framework/components/formik';

import { coreReducers } from 'src/@core/store';
import financialWorkspacesFeedV2 from 'src/containers/dashboard/financial-workspaces/reducers/financialWorkspacesFeedV2';
import invitationsArchivedFeed from 'src/containers/dashboard/invitation/reducers/invitationsArchivedFeed';
import invitationsFeedV2 from 'src/containers/dashboard/invitation/reducers/invitationsFeedV2';
import invitationsSubscribersSearch from 'src/containers/dashboard/invitation/reducers/invitationSubscribersSearch';
import sentInvitesFeedDetail from 'src/containers/dashboard/invitation/reducers/sentInvitesFeedDetail';
import withdrawInvitation from 'src/containers/dashboard/invitation/reducers/withdrawInvitation';
import reportsDashboard from 'src/containers/dashboard/reports/reducers/reportsDashboard';
import scheduledReportHistory from 'src/containers/dashboard/reports/reducers/scheduledReportHistory';
import groupMembers from 'src/containers/dashboard/shared/reducers/groupMembers';
import singleGroupMembers from 'src/containers/dashboard/shared/reducers/singleGroupMembers';
import subscriberAssignableGroups from 'src/containers/dashboard/shared/reducers/subscriberAssignableGroups';
import supportedJurisdictions from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import userJurisdictions from 'src/containers/dashboard/shared/reducers/userJurisdictions';
import workspaceGroups from 'src/containers/dashboard/shared/reducers/workspaceGroups';
import standaloneWorkspacesFeedV2 from 'src/containers/dashboard/standalone-workspaces/reducers/standaloneWorkspacesFeedV2';
import debitAccount from 'src/containers/documents/reducers/debitAccount';
import documentMergedPdf from 'src/containers/documents/reducers/documentMergedPdf';
import paymentSummary from 'src/containers/documents/reducers/paymentSummary';
import logsFeed from 'src/containers/settings/reporting/reducers/logsFeed';
import groupDetails from 'src/containers/settings/settings/reducers/groupDetails';
import groupDistributionsWorkflow from 'src/containers/settings/settings/reducers/groupDistributionsWorkflow';
import groupDocumentsWorkflow from 'src/containers/settings/settings/reducers/groupDocumentsWorkflow';
import groupLodgement from 'src/containers/settings/settings/reducers/groupLodgement';
import groupsFeed from 'src/containers/settings/settings/reducers/groupsFeed';
import userDetails from 'src/containers/settings/settings/reducers/userDetails';
import userLinkedDevicesFeed from 'src/containers/settings/settings/reducers/userLinkedDevicesFeed';
import userPermissions from 'src/containers/settings/settings/reducers/userPermissions';
import userReportsPermissions from 'src/containers/settings/settings/reducers/userReportsPermissions';
import usersFeed from 'src/containers/settings/settings/reducers/usersFeed';
import userSuspension from 'src/containers/settings/settings/reducers/userSuspension';
import autoJustificationDetail from 'src/containers/settings/subscriber-profile/reducers/autoJustificationDetail';
import autoJustificationEvidence from 'src/containers/settings/subscriber-profile/reducers/autoJustificationEvidence';
import autoJustificationsFeed from 'src/containers/settings/subscriber-profile/reducers/autoJustificationsFeed';
import billingInvoicingState from 'src/containers/settings/subscriber-profile/reducers/billingInvoicing';
import availableDocumentFeedState from 'src/containers/settings/subscriber-profile/reducers/defaultFieldAvailableDocumentFeed';
import documentGroupFeedState from 'src/containers/settings/subscriber-profile/reducers/defaultFieldDocumentGroupFeed';
import documentDefaultFieldListState from 'src/containers/settings/subscriber-profile/reducers/defaultFieldPopulationList';
import debitAccountsFeed from 'src/containers/settings/subscriber-profile/reducers/financialAccounts';
import jurisdictionDetail from 'src/containers/settings/subscriber-profile/reducers/jurisdictionDetail';
import jurisdictionRevenueOffice from 'src/containers/settings/subscriber-profile/reducers/jurisdictionRevenueOffice';
import jurisdictionsSummaryFeed from 'src/containers/settings/subscriber-profile/reducers/jurisdictionsSummaryFeed';
import notificationsSettings from 'src/containers/settings/subscriber-profile/reducers/notificationsSettings';
import organisationProfile from 'src/containers/settings/subscriber-profile/reducers/organisationProfile';
import preferences from 'src/containers/settings/subscriber-profile/reducers/preferences';
import subscriberSigningRule from 'src/containers/settings/subscriber-profile/reducers/subscriberSigningRule';
import trustAccountDetails from 'src/containers/settings/subscriber-profile/reducers/trustAccountDetails';
import trustAccounts from 'src/containers/settings/subscriber-profile/reducers/trustAccounts';
import linkedWorkspaces from 'src/containers/shared/linked-workspace-list/reducer';
import messengerConversations from 'src/containers/shared/messenger/reducer';
import allDirections from 'src/containers/workspace/financial/all-directions/reducer';
import directionsCategories from 'src/containers/workspace/financial/directions/reducers/categories';
import directionsBreakdown from 'src/containers/workspace/financial/directions/reducers/directionsBreakdown';
import generalAccountUsages from 'src/containers/workspace/financial/directions/reducers/generalAccountUsages';
import settlementDateDetails from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import settlementEditForm from 'src/containers/workspace/financial/settlement-date/reducers/settlementEditForm';
import stampDuty from 'src/containers/workspace/financial/stamp-duty/reducer';
import trustAccountAuthorisationRecords from 'src/containers/workspace/financial/trust-account-authorisation-records/reducer';
import lodgementVerificationDialog from 'src/containers/workspace/shared/components/lodgement-verification-dialog/reducer';
import offPlatformTasks from 'src/containers/workspace/shared/components/off-platform-tasks-container/reducer';
import certificates from 'src/containers/workspace/shared/components/signing-provider/reducers';
import documentMessages from 'src/containers/workspace/shared/components/workflow-panel/reducer';
import workspaceFiles from 'src/containers/workspace/shared/components/workspace-files-container/reducer';
import workspaceReportsFeed from 'src/containers/workspace/shared/detail/components/tab-workspace-report/reducer';
import documentMultiSign from 'src/containers/workspace/sign-documents/reducer';
import titleActions from 'src/containers/workspace/titles/reducers/titleActions';
import titleResupplyCost from 'src/containers/workspace/titles/reducers/titleResupplyCost';
import searchLinkedWorkspaceUpdatedFeed from 'src/store/reducers/searchLinkedWorkspaceUpdated';
import linkedLodgementDetail from 'src/store/reducers/workspace/linkedLodgementDetail';
import linkedSettlementDetail from 'src/store/reducers/workspace/linkedSettlementDetail';
import workspaceActivityLogs from 'src/store/reducers/workspace/workspaceActivityLogs';
import workspaceAuthorityComplianceReport from 'src/store/reducers/workspace/workspaceAuthorityComplianceReport';
import workspaceBasicInfo from 'src/store/reducers/workspace/workspaceBasicInfo';
import workspaceDebitAccountsFeed from 'src/store/reducers/workspace/workspaceDebitAccountsFeed';
import workspaceDetail from 'src/store/reducers/workspace/workspaceDetail';
import workspaceDocuments from 'src/store/reducers/workspace/workspaceDocuments';
import workspaceInvitation from 'src/store/reducers/workspace/workspaceInvitation';
import workspaceLatestTransaction from 'src/store/reducers/workspace/workspaceLatestTransaction';
import workspaceParticipantActivityLogs from 'src/store/reducers/workspace/workspaceParticipantActivityLogs';
import workspaceParticipants from 'src/store/reducers/workspace/workspaceParticipants';
import workspaceParticipantSetting from 'src/store/reducers/workspace/workspaceParticipantSetting';
import workspaceTasks from 'src/store/reducers/workspace/workspaceTasks';
import lrsInfo from './lrsInfo';

type RootReducer = ReturnType<typeof createRootReducer>;
export type Store = ReturnType<RootReducer>;

export const createRootReducer = () =>
  combineReducers({
    ...coreReducers,

    // basic user profile
    searchLinkedWorkspaceUpdatedFeed,
    certificates,

    // Workspace related data
    workspaceBasicInfo,
    workspaceDetail,
    workspaceTasks,
    workspaceParticipantActivityLogs,
    workspaceActivityLogs,
    workspaceInvitation,
    workspaceParticipants,
    workspaceDocuments,
    workspaceFiles: workspaceFiles,
    offPlatformTasks,
    workspaceLatestTransaction,
    linkedSettlementDetail,
    linkedLodgementDetail,

    paymentSummary,
    debitAccount,

    directionsCategories,
    directionsBreakdown,
    trustAccountAuthorisationRecords,
    allDirections,
    generalAccountUsages,
    linkedWorkspaces,

    messengerConversations,
    submitFormik,

    // dashboards
    withdrawInvitation,
    sentInvitesFeedDetail,
    invitationsSubscribersSearch,

    financialWorkspacesFeedV2,
    standaloneWorkspacesFeedV2,
    invitationsFeedV2,
    invitationsArchivedFeed,

    groupMembers,
    singleGroupMembers,
    // setting - start
    // company profile - settings
    organisationProfile,
    preferences,
    notificationsSettings,
    trustAccounts,
    trustAccountDetails,
    jurisdictionsSummaryFeed,
    jurisdictionDetail,
    jurisdictionRevenueOffice,
    autoJustificationsFeed,
    autoJustificationDetail,
    autoJustificationEvidence,
    subscriberSigningRule,

    // new settings pages
    groupsFeed,
    groupDetails,
    groupDocumentsWorkflow,
    groupDistributionsWorkflow,
    groupLodgement,
    usersFeed,
    logsFeed,
    userSuspension,
    userDetails,
    userPermissions,
    userReportsPermissions,

    userLinkedDevicesFeed,

    debitAccountsFeed,

    documentGroupFeedState,
    availableDocumentFeedState,
    documentDefaultFieldListState,
    // setting - end

    // Workspace document related data
    documentMergedPdf,
    documentMultiSign,
    settlementDateDetails,
    settlementEditForm,
    stampDuty,
    titleActions,
    titleResupplyCost,

    subscriberAssignableGroups,
    workspaceGroups,
    supportedJurisdictions,
    userJurisdictions,

    // Workspaces
    workspaceReportsFeed,
    workspaceParticipantSetting,
    workspaceAuthorityComplianceReport,
    lodgementVerificationDialog,
    workspaceDebitAccountsFeed,

    documentMessages,
    billingInvoicingState,
    reportsDashboard,
    scheduledReportHistory,

    lrsInfo
  });
