import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchPreferences = createAsyncAction(
  //
  'FETCH_PREFERENCES',
  'FETCH_PREFERENCES_SUCCESS',
  'FETCH_PREFERENCES_ERROR'
)<
  //
  void, // This indicates that the action does not have a payload
  { data: HttpTypes.SettingsSubscriberPreferencesApiResponse },
  { error: Error }
>();
