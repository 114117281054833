import _uniq from 'lodash-es/uniq';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ProprietorGroupModel } from '@sympli-mfe/document-forms-framework/core/models';
import { MergeMetadata } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { HttpTypes } from '@sympli/api-gateway/types';

import type { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';

const filterDocumentsByTypeAndVersion = (workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[], documentTypeId: HttpTypes.DocumentTypeIdentifierEnum, schemaVersion: string) => {
  return workspaceDocuments.filter((doc: HttpTypes.WorkspaceDocumentSummary) => {
    return (
      doc.documentForm.documentType === documentTypeId &&
      // explicitly use only documents of the same version so we can guarantee the data structure is the same
      doc.documentForm.version === schemaVersion
    );
  });
};

const parseDocuments = <FormValues extends object, InitalApiValues extends object>(
  workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[],
  context: IConverterContext,
  convertFromApiToFormModel: IConverter<FormValues, InitalApiValues>['fromApiToFormModel']
) => {
  return workspaceDocuments
    .map((doc: HttpTypes.WorkspaceDocumentSummary) => {
      try {
        const apiValue: InitalApiValues = JSON.parse(doc.data || '{}');
        const formValue: FormValues = convertFromApiToFormModel(apiValue, context);
        return formValue;
      } catch (e) {
        return null;
      }
    })
    .filter(Boolean) as FormValues[];
};

// this is explicitly NOT memoized since we typically call this multiple times with different document types
export const filterAndParseDocumentsByTypeAndVersion = <FormValues extends object, InitalApiValues extends object>(
  //
  workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[],
  context: IConverterContext,
  convertFromApiToFormModel: IConverter<FormValues, InitalApiValues>['fromApiToFormModel']
): FormValues[] => {
  const otherDocumentsOfSameType: HttpTypes.WorkspaceDocumentSummary[] = filterDocumentsByTypeAndVersion(
    //
    workspaceDocuments,
    context.meta.documentTypeId,
    // explicitly use only documents of the same version so we can guarantee the data structure is the same
    context.meta.schemaVersion
  );

  const parsed = parseDocuments(otherDocumentsOfSameType, context, convertFromApiToFormModel);
  return parsed;
};

export const resolveCrossDocumentMatches = (
  selectedPartiesFromCurrentDocument: PartyModel[],
  selectedPartiesFromOtherDocuments: PartyModel[]
): {
  /**
   * Parties currently selected on this document with same matchingItemHash as items in selectedPartiesFromCurrentDocument
   */
  matchingByPartyItemHash: string[];
  /**
   * Parties currently selected on this document that has same matchingGroupHash as items in selectedPartiesFromCurrentDocument
   * These parties may no be the exact same party as in selectedPartiesFromCurrentDocument, but they are from the same group
   */
  matchingByGroupHash: string[];
} => {
  // find out whether the same party is selected as deceased in other documents (the party needs to be from the same title and from the same group)
  const matchingParties: string[] = selectedPartiesFromCurrentDocument //
    .filter(selectedParty =>
      selectedPartiesFromOtherDocuments.find(
        o => o.mergeMetadata!.matchingItemHash === selectedParty.mergeMetadata!.matchingItemHash && o.legalEntityName === selectedParty.legalEntityName
      )
    )
    .map(selectedParty => selectedParty.mergeMetadata!.matchingItemHash); // return list of party hashes

  // check if same party is pre-deceased in other documents (the party needs to be from the same title and from the same group)
  const matchingGroups: string[] = selectedPartiesFromOtherDocuments //
    .filter(selectedParty => selectedPartiesFromCurrentDocument.find(o => o.mergeMetadata!.matchingGroupHash === selectedParty.mergeMetadata!.matchingGroupHash))
    .map(selectedParty => selectedParty.mergeMetadata!.matchingItemHash); // return list of party hashes

  return {
    /**
     * Parties currently selected on this document with same matchingItemHash as items in selectedPartiesFromCurrentDocument
     */
    matchingByPartyItemHash: _uniq(matchingParties),
    /**
     * Parties currently selected on this document that has same matchingGroupHash as items in selectedPartiesFromCurrentDocument
     * These parties may no be the exact same party as in selectedPartiesFromCurrentDocument, but they are from the same group
     */
    matchingByGroupHash: _uniq(matchingGroups)
  };
};

export const resolveDeceasedGroupWarning = ({
  //
  proprietorGroupParties,
  matchingGroups,
  partyBook
}: {
  proprietorGroupParties: ProprietorGroupModel<{
    //
    isSelected: boolean;
    partyBookId?: string;
  }>['parties'];
  matchingGroups: Map<string, Set<string>>;
  partyBook: PartyModel[];
}): string | undefined => {
  const selectedPartyId = proprietorGroupParties.find(p => p.isSelected)?.partyBookId;
  if (selectedPartyId) {
    const mergeMetadata: MergeMetadata | undefined = partyBook.find(p => p.id === selectedPartyId)?.mergeMetadata;
    if (mergeMetadata) {
      let documentsImpacted: Set<string> | undefined = matchingGroups.get(mergeMetadata.matchingGroupHash);
      if (documentsImpacted?.size) {
        return `This party group is selected on another ${Array.from(documentsImpacted.values()).join(' and ')} document in this workspace; selecting them again may cause an issue in lodgement.`;
      }
    }
  }
};

export const resolveDeceasedPartyWarning = ({
  //
  partyBookId,
  matchingParties,
  partyBook
}: {
  partyBookId?: string;
  matchingParties: Map<string, Set<string>>;
  partyBook: PartyModel[];
}): string | undefined => {
  const mergeMetadata: MergeMetadata | undefined = partyBook.find(p => p.id === partyBookId)?.mergeMetadata;
  if (mergeMetadata) {
    // parties impacted across documents
    let documentsImpacted: Set<string> | undefined = matchingParties.get(mergeMetadata.matchingItemHash);
    if (documentsImpacted?.size) {
      return `This party is selected on another ${Array.from(documentsImpacted.values()).join(' and ')} document in this workspace; selecting them again may cause an issue in lodgement.`;
    }
  }
};
