import { GlobalErrorMessageModel } from 'src/@core/store/actions/globalErrors';
import http from 'src/utils/http';

interface Config<R> {
  url: string;
  immediate?: boolean;
  discardOnError?: boolean;
  onResolve?(response: any): R;
  onError?: (error: GlobalErrorMessageModel) => void;
}
type SubscriptionType = 'resolve' | 'reject';
// type Result = Array<Object>;

// explicitly require to provide typings when declaring new store
export default class JsonRest<R> {
  private url: string;
  private immediate: boolean = true;
  private discardOnError: boolean = false;
  private onResolve: (response: any) => R;
  private onError?: (error: GlobalErrorMessageModel) => void;

  private data?: Promise<R>;
  private subscribers: { resolve: Array<(data: R) => void>; reject: Array<(err: unknown) => void> } = { resolve: [], reject: [] };

  constructor(config: string | Config<R>) {
    if (typeof config === 'string') {
      this.url = config;
    } else {
      this.url = config.url;
      this.immediate = !!config.immediate;
      this.onResolve = config.onResolve || (response => response);
      this.discardOnError = !!config.discardOnError;
      this.onError = config.onError;
    }

    if (this.immediate) {
      this.query();
    }
  }

  public query = (): Promise<R> => {
    if (!this.data) {
      this.data = http.get(this.url).then(this.onResolve);
    }
    return this.data!.then(data => {
      this.subscribers.resolve.forEach(fn => fn(data));
      return data;
    }) //
      .catch(error => {
        this.subscribers.reject.forEach(fn => fn(error));
        if (this.discardOnError) {
          this.data = undefined;
        }

        if (this.onError) {
          this.onError(error);
        }
        throw error;
      });
  };

  public subscribe(event: 'resolve', callback: (data: R) => void);
  public subscribe(event: 'reject', callback: (err: unknown) => void);
  public subscribe(event: SubscriptionType, callback: (dataOrError: any) => void) {
    this.subscribers[event].push(callback);

    return () => {
      this.subscribers[event] = this.subscribers[event].filter(fn => fn !== callback);
    };
  }
}
