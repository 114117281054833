import * as React from 'react';

import { useLocation } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Box from 'src/@core/components/layout/box';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { usePaymentSummary } from 'src/containers/documents/reducers/paymentSummary';
import DocumentListContainer from 'src/containers/shared/document-list';
import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helpers';
import LinkedWorkspaceListContainer from 'src/containers/shared/linked-workspace-list';
import PaymentSummaryBox from 'src/containers/shared/payment-summary-box';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import WorkspacePageDetailBoxContainer from 'src/containers/shared/workspace-detail-box';
import WorkspaceDetailTimestamp from 'src/containers/shared/workspace-detail-timestamp';
import WorkspaceExpansionBox from 'src/containers/shared/workspace-expansion-box';
import { PORTAL_ID_FOR_DIRECTIONS_SUMMARY } from 'src/containers/workspace/financial/directions';
import DirectionsSummaryBoxContainer from 'src/containers/workspace/financial/directions/components/directions-summary-box';
import SettlementDirectionsListContainer from 'src/containers/workspace/financial/directions/components/settlement-directions-list';
import { SelectedSettlementDistributionsTypeEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { PORTAL_ID_FOR_SETTLEMENT_DATES, PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS } from 'src/containers/workspace/financial/settlement-date';
import BoxHeaderLink from 'src/containers/workspace/shared/components/box-header-link';
import { useRouterParams } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { WorkspacePageRouteParams } from 'src/pages/workspace/models';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import { resolveSelectedSettlementDistributionsType } from './helpers';

export default function WorkspacePageLeftPanelContainer() {
  const { pathname } = useLocation();
  const { workspaceId, participantId } = useRouterParams<WorkspacePageRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const paymentSummaryState = usePaymentSummary(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);

  const lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);
  const currentWorkspaceParticipant: HttpTypes.WorkspaceParticipant | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const workspaceStatusId: HttpTypes.WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const workspaceTypeId: HttpTypes.WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const jurisdictionId: HttpTypes.JurisdictionsEnum | undefined = workspaceBasicInfoState.detail?.jurisdictionId;

  const disabledWorkspaceStatuses: HttpTypes.WorkspaceStatusEnum[] = [HttpTypes.WorkspaceStatusEnum.Abandoned, HttpTypes.WorkspaceStatusEnum.Withdrawn];
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  const isNonLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.RegistrationOnly;

  const selectedSettlementDistributionsType: SelectedSettlementDistributionsTypeEnum | undefined = resolveSelectedSettlementDistributionsType(pathname);
  const portalIdForDirectionSummary = pathname.endsWith('/directions') ? PORTAL_ID_FOR_DIRECTIONS_SUMMARY : undefined;
  const isSettlementDatePage = pathname.endsWith('/settlement-date');
  const isSignDocumentsPage = pathname.endsWith('/sign-documents');
  const isSettling = workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementInProgress || workspaceStatusId === HttpTypes.WorkspaceStatusEnum.SettlementIssue;
  const portalIdForSettlementDate = isSettlementDatePage ? PORTAL_ID_FOR_SETTLEMENT_DATES : undefined;
  const portalIdForSettlementParticipants = isSettlementDatePage ? PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS : undefined;
  const showAllBoxes = Number.isInteger(workspaceStatusId) && !disabledWorkspaceStatuses.includes(workspaceStatusId!) && !isSettlementDatePage; // settlement-detail page does not show additional info

  function createTracker(section: string, workspaceId: string, participantId: string, workspaceTypeId?: HttpTypes.WorkspaceTypeEnum) {
    return function trackOnExpandChange(expand: boolean) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'expand-box',
        logGroupId: 'workspace',
        section,
        workspaceId,
        participantId,
        workspaceType: Number.isInteger(workspaceTypeId) ? WorkspaceTypeDisplayMapping[workspaceTypeId!] : undefined,
        expand
      });
    };
  }

  const {
    //
    trackWorkspaceOverviewExpandChange,
    trackDocumentsListExpandChange,
    trackSettlementDirectionsExpandChange,
    trackDirectionsSummaryExpandChange
  } = React.useMemo(() => {
    const trackWorkspaceOverviewExpandChange = createTracker('workspace-overview', workspaceId, participantId, workspaceTypeId);
    const trackDocumentsListExpandChange = createTracker('documents-list', workspaceId, participantId, workspaceTypeId);
    const trackSettlementDirectionsExpandChange = createTracker('settlement-directions', workspaceId, participantId, workspaceTypeId);
    const trackDirectionsSummaryExpandChange = createTracker('directions-summary', workspaceId, participantId, workspaceTypeId);
    return {
      //
      trackWorkspaceOverviewExpandChange,
      trackDocumentsListExpandChange,
      trackSettlementDirectionsExpandChange,
      trackDirectionsSummaryExpandChange
    };
  }, [workspaceId, participantId, workspaceTypeId]);

  const matter = currentWorkspaceParticipant?.reference || workspaceBasicInfoState.cacheFallback?.matter;

  return (
    <>
      <WorkspaceExpansionBox
        collapsedByDefault={isSettling}
        onExpandChange={trackWorkspaceOverviewExpandChange}
        title={
          <BoxHeaderLink //
            // route parameters
            workspaceId={workspaceId}
            participantId={participantId}
            // current participant info
            matter={matter}
            lodgementCaseStatusId={lodgementCaseStatusId}
            // other
            isTopLevelOverView={pathname.endsWith('/detail')}
            linkTo={resolveWorkspaceDetailLink({ workspaceId, participantId })}
            linkIconPosition="left"
            showIcon={!pathname.endsWith('/detail')}
            // toggleable={isWorkspaceSummaryToggleable}
            // workspaceBasicInfo
            workspaceBasicInfo={workspaceBasicInfoState.detail}
            showWorkspaceStatus={workspaceBasicInfoState.status === 'resolved'}
          />
        }
      >
        <WorkspacePageDetailBoxContainer
          // route parameters
          workspaceId={workspaceId}
          participantId={participantId}
          // other
          portalIdForSettlementDate={portalIdForSettlementDate}
          hideAssignedStaff={isSettlementDatePage}
        />
      </WorkspaceExpansionBox>

      {portalIdForSettlementParticipants && <PortalTarget id={portalIdForSettlementParticipants} className="tickle-box" />}

      {showAllBoxes && (
        <>
          <DocumentListContainer
            // route
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            collapsedByDefault={isSignDocumentsPage || isSettling}
            onExpandChange={trackDocumentsListExpandChange}
          />
          <LinkedWorkspaceListContainer //
            workspaceId={workspaceId}
            collapsedByDefault={isSignDocumentsPage || isSettling}
          />
        </>
      )}
      {showAllBoxes && isNonLodgementOnly && (
        <>
          <SettlementDirectionsListContainer
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            collapsedByDefault={isSignDocumentsPage || isSettling}
            selectedSettlementDistributionsType={selectedSettlementDistributionsType}
            onExpandChange={trackSettlementDirectionsExpandChange}
          />
          <DirectionsSummaryBoxContainer
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            portalIdForDirectionSummary={portalIdForDirectionSummary}
            onExpandChange={trackDirectionsSummaryExpandChange}
          />
        </>
      )}
      {showAllBoxes && isLodgementOnly && (
        <Box title="Payment">
          <PaymentSummaryBox //
            // workspace basic info
            workspaceStatusId={workspaceStatusId!}
            workspaceId={workspaceId}
            participantId={participantId}
            jurisdictionId={jurisdictionId}
            // payment summary info
            lineItems={paymentSummaryState.detail?.lineItems}
            isLoading={paymentSummaryState.isLoading}
            error={paymentSummaryState.error}
          />
        </Box>
      )}
      {showAllBoxes && (
        <WorkspaceDetailTimestamp //
          isLoading={workspaceBasicInfoState.isLoading}
          // workspace basic info
          createdDate={workspaceBasicInfoState.detail?.createdDate}
          lastUpdatedDate={workspaceBasicInfoState.detail?.lastUpdatedDate}
          lrsVersion={workspaceBasicInfoState.detail?.lodgementCases[0]?.lrsVersion.split('.').slice(0, 2).join('.')}
        />
      )}
    </>
  );
}
