import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { TitleReferenceEntityModel } from 'src/models';
import { actionSetTitleListAction } from 'src/store/actions/workspace';
import { getTacWarning, useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';
import { TitleListAction } from './models';
import TitleAndAddresses from './TitleAndAddresses';

export type TitleAndAddressesProps = {
  isLoading: boolean;
  workspaceId: string;
  participantId: string;
  // current participant info
  participantRoleId?: HttpTypes.WorkspaceRoleEnum;
  // basic workspace info
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  // full workspace info
  propertyAddress?: string;
  titleReferences: TitleReferenceEntityModel[];
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  workspaceIsLocked: boolean;
  loaderBackgroundColor: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  linkedWorkspaces?: LinkedWorkspaceApiResponseModel[];
};

export type Props = TitleAndAddressesProps;

function TitleAndAddressesContainer(props: Props) {
  const isRemoveTitlesEnabled = useFeatureFlag(FeatureToggleEnum.removeTitlesEnabled);

  const { titleReferences = [], workspaceId, participantId } = props;

  const dispatch = useSafeDispatch(useDispatch());
  const mode = useWorkspaceDetail(workspaceId, participantId).titleListActionMode;
  const setMode = useCallback(
    (mode: TitleListAction) => {
      dispatch(actionSetTitleListAction({ mode }));
    },
    [dispatch]
  );

  const updateTacWithLocalStorage = useCallback((items: TitleReferenceEntityModel[], key?: string) => {
    if (key === undefined) {
      return items;
    }
    return items.map(x => {
      let hasChanges = getTacWarning(key, x.reference, x);
      return { ...x, hasChanges: hasChanges };
    });
  }, []);

  const userId = useProfile().data?.userId;
  const tacKey = `tac/${workspaceId}/${participantId}/${userId}`;
  const updatedTitles = updateTacWithLocalStorage(titleReferences, tacKey);

  return <TitleAndAddresses {...props} mode={mode} onActionClick={setMode} titleReferences={updatedTitles} isRemoveTitlesEnabled={isRemoveTitlesEnabled}></TitleAndAddresses>;
}

export default TitleAndAddressesContainer;
