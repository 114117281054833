import * as React from 'react';

import classNames from 'classnames';
import { Action, Dispatch } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconLocked } from '@sympli/ui-framework/icons';

import ProfileContext, { ProfileContextModel } from 'src/@core/auth/profile-security/Profile.context';
import Header from 'src/@core/components/typography/header';
import CopyIcon from 'src/components/copy-icon';
import TransactionTypeBanner from 'src/containers/workspace/shared/components/transaction-type';
import ReferenceForm from './reference-form';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

// TODO extract api logic!
export interface OwnProps {
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceStatus: HttpTypes.WorkspaceStatusEnum;
  isLocked?: boolean;
  // current participant
  reference?: string;

  distributionsStatus?: HttpTypes.DocumentStatusEnum;
  dispatch: Dispatch<Action<any>> | SafeDispatch<Action<any>>;

  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  transactionType?: HttpTypes.TransactionType;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class StatusHeader extends React.PureComponent<Props> {
  // maybe need lodgement case status for the disable
  private disableUpdateReferenceList: Set<HttpTypes.WorkspaceStatusEnum> = new Set([
    //
    HttpTypes.WorkspaceStatusEnum.SettlementInProgress,
    HttpTypes.WorkspaceStatusEnum.Success,
    HttpTypes.WorkspaceStatusEnum.Finalised
  ]);

  render() {
    const { isLocked, classes, workspaceTypeId, transactionType, workspaceId } = this.props;
    return (
      <ProfileContext.Consumer>
        {profile => (
          <>
            <TransactionTypeBanner textClassName="text-[14px] font-[700]" workspaceTypeId={workspaceTypeId} transactionType={transactionType} workspaceId={workspaceId} />
            <FlexLayout>
              {isLocked && <IconLocked className={classes.lockIcon101} />}
              {this.renderReference(profile)}
            </FlexLayout>
          </>
        )}
      </ProfileContext.Consumer>
    );
  }

  private renderReference(profile: ProfileContextModel) {
    const { reference = '', workspaceStatus, distributionsStatus, classes, isLocked, workspaceId, participantId, dispatch } = this.props;
    const organisationalType = profile?.subscriberOrganisationType;

    if (isLocked || this.disableUpdateReferenceList.has(workspaceStatus)) {
      return (
        <FlexLayout className={classes.header} flexDirection="row" alignItems="center">
          <Header noEllipsis className={classNames(classes.header, 'h-[100%]')}>
            {reference}
          </Header>
          <CopyIcon
            className="ml-[16px]"
            title={organisationalType === HttpTypes.SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan number' : 'matter number'}
            textToCopy={reference}
          />
        </FlexLayout>
      );
    }

    return (
      <>
        <ReferenceForm //
          reference={reference}
          workspaceId={workspaceId}
          participantId={participantId}
          dispatch={dispatch}
          distributionsStatus={distributionsStatus}
        />
        <CopyIcon
          className="pl-[4px]"
          title={organisationalType === HttpTypes.SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan number' : 'matter number'}
          textToCopy={reference}
        />
      </>
    );
  }
}

const StyledStatusHeader = withStyles(styles)(StatusHeader);
export default StyledStatusHeader;
