import React, { useEffect, useMemo } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionLodgementOrderSummary from '@sympli-mfe/document-forms-framework/components/sections/lodgement-order-summary';
import SectionResponsibleSubscriber from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDndContext } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import Subform from '@sympli/ui-framework/components/formik/subform';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { useRouterParams } from 'src/hooks';
import { WorkspacePageRouteParams } from 'src/pages/workspace/models';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { LodgementInstructionsDocumentModel } from './models';
import DealingRequirementsSummary from './sections/dealing-requirements-summary';
import DirectionPreference from './sections/direction-preference';
import { VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY } from './visibilityChecks';

// this file was automatically generated from DocumentForm.tsx.mustache
const fieldName = modelKey<LodgementInstructionsDocumentModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel, nextActionDisabled, setNextActionDisabled } = useDocumentContext();
  const { isDndMode } = useDndContext();
  const { values, dirty } = useFormikContext<LodgementInstructionsDocumentModel>();
  const { isResponsibleParticipant, participants } = values;
  const focusSubscriberPartyRef = React.useRef<ButtonBaseActions>(null);
  const focusDealingRequirementsRef = React.useRef<ButtonBaseActions>(null);
  const { workspaceId, participantId } = useRouterParams<WorkspacePageRouteParams>();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  const participantOptions = useMemo(() => {
    return participants?.map(({ id, role, subscriber }) => ({ id: id, name: `${subscriber?.legalName} (${role})` })) ?? [];
  }, [participants]);

  useEffect(() => {
    if (!isResponsibleParticipant) {
      setNextActionDisabled(!dirty);
    }
  }, [dirty, isResponsibleParticipant, setNextActionDisabled]);

  useEffect(() => {
    if (
      isResponsibleParticipant && //
      participantOptions.length > 1 &&
      focusSubscriberPartyRef.current
    ) {
      focusSubscriberPartyRef.current.focusVisible();
      return;
    }

    if (focusDealingRequirementsRef.current) {
      focusDealingRequirementsRef.current.focusVisible();
      return;
    }

    Logger.console(SeverityEnum.Warning, 'Document has nothing to focus on. Please fix this');
  }, [isResponsibleParticipant, participantOptions.length]);

  return (
    <>
      <Form className={className}>
        {VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY(values) && (
          <SectionResponsibleSubscriber //
            name={fieldName('responsibleParticipantId')}
            focusRef={focusSubscriberPartyRef}
            options={participantOptions}
          />
        )}

        {VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY(values) && (
          <Subform subformBindingPath={fieldName('lodgementOrderSummary')}>
            {() => <SectionLodgementOrderSummary title="Lodgement order" canRearrangeLodgementInstructions={workspaceDetailState.detail?.canRearrangeLodgementInstructions} />}
          </Subform>
        )}

        <Section title="Dealing requirements">
          <DealingRequirementsSummary name={fieldName('dealingRequirementsSummary')} focusRef={focusDealingRequirementsRef} />
        </Section>

        {VISIBILITY_CHECK_DIRECTION_PREFERENCE_AND_LODGEMENT_ORDER_SUMMARY(values) && (
          <Section title="Nature of title to issue">
            <DirectionPreference name={fieldName('directionPreference')} />
          </Section>
        )}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled || isDndMode || nextActionDisabled} // we explicitly use nextActionDisabled flag instead of dirty because this controls both panels
            nextLabel={nextActionLabel}
            onBack={onCancel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
