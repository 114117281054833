import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { HttpTypes } from '@sympli/api-gateway/types';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { DocumentPreview } from 'src/components/document-preview';
import DocPreviewContainer from 'src/containers/documents/components/doc-preview';
import { DocumentFormAndDetailModel, DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import { DocumentMergedPdfState } from 'src/containers/documents/reducers/documentMergedPdf';
import ReadonlyDocument from 'src/containers/documents/views/readonly-document';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { useWorkspaceDocuments } from 'src/store/reducers/workspace/workspaceDocuments';
import { useStyles } from './styles';

interface Props {
  displayDocumentAsPdf: boolean;
  signMultiple: boolean;
  queryParams: DocumentsPageRouteAndQueryModel;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  documentDetail: DocumentFormAndDetailModel;
  documentMergedPdfState?: DocumentMergedPdfState;
  currentParticipant?: HttpTypes.WorkspaceParticipant;
}

function DocumentViewer({
  //
  displayDocumentAsPdf,
  signMultiple,
  queryParams,
  jurisdictionId,
  documentDetail,
  documentMergedPdfState,
  currentParticipant
}: Props) {
  const classes = useStyles();
  const { workspaceId, participantId } = queryParams;
  const workspaceDocuments = useWorkspaceDocuments(workspaceId, participantId).items;

  if (!displayDocumentAsPdf) {
    return (
      <WorkspacePageContentWrapper className={classes.content}>
        <ReadonlyDocument
          queryParams={queryParams}
          documentModel={documentDetail}
          jurisdictionId={jurisdictionId}
          workspaceDocuments={workspaceDocuments}
          currentParticipant={currentParticipant}
        />
      </WorkspacePageContentWrapper>
    );
  }

  if (!signMultiple) {
    return <DocPreviewContainer queryParams={queryParams} />;
  }

  if (documentMergedPdfState) {
    const { status, error, detail } = documentMergedPdfState;

    if (status === 'pending') {
      return <BlockLoader />;
    }

    if (status === 'rejected') {
      return (
        <FormHelperText role="alert" className={classes.errorMessage} error={true}>
          {error}
        </FormHelperText>
      );
    }

    if (detail) {
      return <DocumentPreview src={detail.pdfUrl} />;
    }
  }

  return null;
}

export default React.memo(DocumentViewer);
