import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from '../../layout/constants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 54,

      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        height: 48
      },

      '& + .tickle-subheader': {
        paddingTop: 8
      }
    },
    textEllipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      '& $title': {
        display: 'inline-block'
      },
      textDecoration: 'none',
      marginLeft: -18
    },
    icon: {
      fontSize: 18,
      lineHeight: '18px',
      fontWeight: 'bold'
    },
    title: {
      height: '100%'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
