import * as React from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { HttpTypes } from '@sympli/api-gateway/types';

import { RepresentationSubscriberTypeEnum } from 'src/containers/dashboard/components/create-new-workspace/models';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import AddItemButton from 'src/containers/documents/dynamic-form/components/add-item-button';
import { FinancialRoleMapping } from 'src/models/roles';
import { modelKey } from 'src/utils/formUtils';
import { CreateFinancialWorkspaceInviteParticipantsStepFormModel } from '../../../../models';
import AdditionalInvitationItem from './additional-invitation-item/AdditionalInvitationItem';
import { filterAllowedAdditionalRoles, getDefaultAdditionalInvitation } from './helpers';

interface OwnProps {
  isInteroperable?: boolean;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  groups: GroupOptionModel[];
  allowedRoleIds: HttpTypes.WorkspaceRoleEnum[];
  currentParticipantGroupId?: string;
  currentParticipantReference?: string;
  representationSubscriberType?: RepresentationSubscriberTypeEnum;
}

function AdditionalInvitations(props: OwnProps) {
  const fieldName = modelKey<CreateFinancialWorkspaceInviteParticipantsStepFormModel>();
  const { values, isSubmitting } = useFormikContext<CreateFinancialWorkspaceInviteParticipantsStepFormModel>();
  const { allowedRoleIds, currentParticipantGroupId = '', currentParticipantReference = '' } = props;

  const roleOptions = filterAllowedAdditionalRoles(allowedRoleIds).map((roleId: HttpTypes.WorkspaceRoleEnum) => ({
    id: roleId,
    name: FinancialRoleMapping[roleId]!
  }));

  return (
    <FieldArray
      name={fieldName('additionalInvitations')}
      render={({ push, remove }) => (
        <>
          {values.additionalInvitations.map((_, index) => (
            <AdditionalInvitationItem //
              name={fieldName('additionalInvitations')}
              index={index}
              remove={remove}
              roleOptions={roleOptions}
              key={`additional-invitation-${index}`}
              disabled={isSubmitting}
              {...props}
            />
          ))}
          <AddItemButton //
            onClick={() => push(getDefaultAdditionalInvitation(currentParticipantGroupId, currentParticipantReference))}
            disabled={isSubmitting}
          >
            Add another role
          </AddItemButton>
        </>
      )}
    />
  );
}

export default React.memo(AdditionalInvitations);
