import React from 'react';

import { Form, FormikProps } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Formik from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import { GroupOptionModel } from '../../../shared/models';
import { resolveAssignableGroups } from './helpers';

export interface ForwardInviteToGroupFormValues {
  invitationId: string;
  workspaceId: string;
  groupId: string;
  groupName: string;
}

const fieldName = modelKey<ForwardInviteToGroupFormValues>();

interface ForwardInviteToGroupFormProps {
  invitationId: string;
  workspaceId: string;
  invitationForwardableGroups: GroupOptionModel[];
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  className?: string;

  onCancel(): void;
  onSubmit(data: ForwardInviteToGroupFormValues): Promise<void>;
}

const validationSchema = yup.object<ForwardInviteToGroupFormValues>({
  invitationId: yup.mixed<string>(),
  workspaceId: yup.mixed<string>(),
  groupId: yup.string().trim().required(msg.REQUIRED),
  groupName: yup.mixed<string>()
});

function ForwardInviteToGroupForm({
  //
  invitationForwardableGroups,
  jurisdictionId,
  invitationId,
  workspaceId,
  onCancel,
  onSubmit
}: ForwardInviteToGroupFormProps) {
  const GROUP_OPTIONS: LookupEnumModel<string>[] = React.useMemo(
    () => resolveAssignableGroups(invitationForwardableGroups, jurisdictionId), //
    [jurisdictionId, invitationForwardableGroups]
  );

  const onPreSubmit = React.useCallback(
    (values: ForwardInviteToGroupFormValues) => {
      const groupName = GROUP_OPTIONS.find(item => item.id === values.groupId)?.name || '';
      return { ...values, groupName };
    },
    [GROUP_OPTIONS]
  );

  const initialValues: ForwardInviteToGroupFormValues = React.useMemo<ForwardInviteToGroupFormValues>(
    () => ({
      invitationId,
      workspaceId,
      groupId: '',
      groupName: ''
    }),
    [invitationId, workspaceId]
  );

  const fieldClasses = {
    formControl: 'mt-[60px]',
    marginBottom: 'mb-[24px]'
  };

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={validationSchema}
      onPreSubmit={onPreSubmit}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty }: FormikProps<ForwardInviteToGroupFormValues>) => {
        return (
          <Form className="flex grow flex-col justify-between">
            <Field //
              component={SelectField}
              classes={fieldClasses}
              format="string"
              placeholder="Please Select"
              name={fieldName('groupId')}
              label={
                <>
                  <Typography className={'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]'}>Forward to Group</Typography>
                  <Typography className={'text-[14px] font-[400] text-[var(--neutral-600)]'}>Choose a group within your organisation to forward this invitation</Typography>
                </>
              }
              options={GROUP_OPTIONS}
              fullWidth
              disabled={isSubmitting}
            />
            <div className="flex justify-center gap-[8px]">
              <Button
                //
                disabled={isSubmitting}
                className="rounded-[34px] border-[2px] px-[24px] py-[6px]"
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting}
                disabled={isSubmitting || !(isValid && dirty)}
                type="submit"
                variant="contained"
                color="primary"
                className="rounded-[34px] px-[24px] py-[6px]"
              >
                Forward
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(ForwardInviteToGroupForm);
