import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useFeatureFlag } from 'src/hooks';

const homePagePathMapper: Map<HttpTypes.UserHomePageEnum, string> = new Map([
  [HttpTypes.UserHomePageEnum.StandaloneWorkspaces, '/standalone'],
  [HttpTypes.UserHomePageEnum.FinancialWorkspaces, '/financial-workspaces'],
  [HttpTypes.UserHomePageEnum.Invitations, '/invitations']
]);

const WorkspacesRedirector = () => {
  const navigate = useNavigate();
  const profileData = useProfile().data;
  const homePageFromProfile = profileData?.homePage ?? HttpTypes.UserHomePageEnum.StandaloneWorkspaces;
  const isUserHomePageEnabled: boolean = useFeatureFlag([FeatureToggleEnum.userHomePageEnabled]);
  const homePage = isUserHomePageEnabled ? homePageFromProfile : HttpTypes.UserHomePageEnum.StandaloneWorkspaces;
  useEffect(() => {
    const key: HttpTypes.UserHomePageEnum = homePage === HttpTypes.UserHomePageEnum.Invitations ? HttpTypes.UserHomePageEnum.StandaloneWorkspaces : homePage;
    const path: string = homePagePathMapper.get(key) || '/standalone';
    navigate(path, { replace: true });
  }, [homePage, navigate]);

  // Return nothing as this component only handles redirection
  return null;
};

export default WorkspacesRedirector;
