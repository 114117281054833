import React, { createContext, useContext, useLayoutEffect } from 'react';

import { useDispatch } from 'react-redux';
import { io, Socket } from 'socket.io-client';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { useToken } from 'src/@core/auth/useToken';
import { DialogTypeEnum } from 'src/@core/components/global-notification';
import environments from 'src/@core/environments';
import { AppEnvironmentEnum } from 'src/@core/environments/models';
import { actionPushErrorToQueue, actionRemoveGlobalErrorById } from 'src/@core/store/actions/globalErrors';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useSafeDispatch } from 'src/hooks';
import { obfuscate } from 'src/socket/connection';
import { SocketClientEventToServer } from './models';

interface RealtimeConnectionContext {
  socket: Socket;
}

const RealtimeContext = createContext<RealtimeConnectionContext>({ socket: null } as any);

const SOCKET_PATH = '/realtime/socket.io';
let initialConnection = true;

function RealtimeConnectionProvider(props: React.PropsWithChildren<{}>) {
  const token: string | undefined = useToken();
  const profile = useProfile().data;
  const dispatch = useSafeDispatch(useDispatch());
  const authRef = React.useRef({
    //
    token,
    email: profile?.email
  });
  authRef.current.token = token;

  const socket: Socket = React.useMemo(
    () => {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Initialise new SocketIO instance',
        socketPath: SOCKET_PATH,
        token: obfuscate(authRef.current.token)
      });

      return io(environments.SOCKET_SERVER_URL, {
        path: SOCKET_PATH,
        auth: authRef.current,
        autoConnect: true,
        withCredentials: true
      });
    },
    [] // explicitly ignore auth object, because we only want to create the socket once
  );
  // always assign latest token to socket
  socket.auth = authRef.current;

  // force reconnect when token changes
  useLayoutEffect(() => {
    if (initialConnection) {
      initialConnection = false;
    } else {
      socket.disconnect().connect();
    }
  }, [socket, token]);

  useLayoutEffect(() => {
    function onConnect() {
      if ([AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(
          // cleanup previous disconnect message
          actionRemoveGlobalErrorById({
            id: 'realtime_disconnect',
            snackbar: true
          })
        );

        dispatch(
          actionPushErrorToQueue({
            type: DialogTypeEnum.Success,
            message: 'Realtime - connected',
            id: 'realtime_connect',
            snackbar: true,
            autohideSec: 5
          })
        );
      }

      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: socket.recovered ? 'Connection recovered' : 'Connected',
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token)
      });

      socket.emit(SocketClientEventToServer.Log, {
        message: 'The client has confirmed connection',
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token)
      });
    }

    // this will typically be triggered for one of these reasons:
    // 1. the server is down - timeout
    // 2. the server is up, but the token is invalid - invalid_token
    // 3. the server is up, user lost network connection - xhr poll error
    function onConnectError(err) {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Realtime connection error',
        socketId: socket.id,
        socketPath: SOCKET_PATH,
        token: obfuscate(authRef.current.token),
        error: err.message
      });

      // explicitly DO NOT try to reconnect here as it would DDOS the server
      // socket.io will try to reconnect automatically in case of network issues
      // for invalid token, we need to rely on the token change event to reconnect

      if ([AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(
          // cleanup previous messages
          actionRemoveGlobalErrorById({
            id: 'realtime_disconnect',
            snackbar: true
          })
        );
        dispatch(
          actionRemoveGlobalErrorById({
            id: 'realtime_connect',
            snackbar: true
          })
        );
        dispatch(
          actionPushErrorToQueue({
            type: DialogTypeEnum.Error,
            message: `Realtime connection error - ${err.message}`,
            id: 'realtime_disconnect',
            snackbar: true
          })
        );
      }
    }

    function onDisconnect(reason) {
      if ([AppEnvironmentEnum.local, AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(
          // cleanup previous connection message
          actionRemoveGlobalErrorById({
            id: 'realtime_connect',
            snackbar: true
          })
        );
        if (reason !== 'io client disconnect') {
          dispatch(
            actionPushErrorToQueue({
              type: DialogTypeEnum.Error,
              message: 'Realtime - connection lost',
              id: 'realtime_disconnect',
              snackbar: true
            })
          );
        }
      }
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Disconnected',
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token),
        reason
      });

      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, we need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    }
    function onReconnectAttempt(attempt) {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: 'Reconnect attempt',
        socketId: socket.id,
        socketPath: SOCKET_PATH,
        token: obfuscate(authRef.current.token),
        attempt
      });
      socket.emit(SocketClientEventToServer.Log, {
        message: 'Client tried to reconnect realtime server.',
        socketId: socket.id,
        socketPath: SOCKET_PATH,
        token: authRef.current.token, // explicitly send un-obfuscated token, because we want to know if the token is still valid
        attempt
      });
    }
    // Fired when couldn't reconnect within reconnectionAttempts
    function onReconnectFailed() {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: `Reconnect failed`,
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token)
      });
      // because connection is closed, we can only send log to NewRelics
      const scope = Logger.scopeWithCustomAttributes({
        //
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token)
      });
      Logger.captureException(new Error(`Socket reconnect failed`), scope);
    }
    function onPing() {
      Logger.capturePageAction(PageActionEnum.Realtime, {
        message: `Ping received from server`,
        socketPath: SOCKET_PATH,
        socketId: socket.id,
        token: obfuscate(authRef.current.token)
      });
    }

    socket.on('connect', onConnect);
    socket.on('connect_error', onConnectError);
    socket.on('disconnect', onDisconnect);

    socket.io.on('reconnect_attempt', onReconnectAttempt);
    socket.io.on('reconnect_failed', onReconnectFailed);
    socket.io.on('ping', onPing);

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }

      socket.off('connect', onConnect);
      socket.off('connect_error', onConnectError);
      socket.off('disconnect', onDisconnect);

      socket.io.off('reconnect_attempt', onReconnectAttempt);
      socket.io.off('reconnect_failed', onReconnectFailed);
      socket.io.off('ping', onPing);
    };
  }, [dispatch, socket]);

  return <RealtimeContext.Provider value={{ socket }}>{props.children}</RealtimeContext.Provider>;
}

export function useRealtimeConnection() {
  return useContext(RealtimeContext);
}

// !! This is a wrapper to skip realtime for smoke tests
export default function RealtimeConnectionWrapper(
  props: React.PropsWithChildren<{
    fake?: boolean;
  }>
) {
  // this is necessary for smoke test to work
  if (props.fake || import.meta.env.VITE_SMOKE_TEST === 'true') {
    return (
      <RealtimeContext.Provider
        value={{
          socket: {
            emit(...args) {
              // eslint-disable-next-line no-console
              console.log('emit', ...args);
            },
            on(...args) {
              // eslint-disable-next-line no-console
              console.log('on', ...args);
            },
            off(...args) {
              // eslint-disable-next-line no-console
              console.log('off', ...args);
            }
          } as any
        }}
      >
        {props.children}
      </RealtimeContext.Provider>
    );
  }

  return <RealtimeConnectionProvider>{props.children}</RealtimeConnectionProvider>;
}
