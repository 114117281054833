import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import {
  //
  actionFetchDirectionsList,
  actionResetDirectionsList,
  actionUpdateDirectionsListPaymentsStatus,
  actionUpdateDirectionsListSourceFundsStatus,
  actionUpdateDirectionsListTaaStatus,
  actionUpdateDirectionsWorkspaceCurrentParticipantDetail,
  DirectionsListApiRequest
} from '../actions/directionsList';
import useStoreSelector from '../useStoreSelector';

export interface DirectionsListState {
  detail?: HttpTypes.WorkspaceDirectionsStatusApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: DirectionsListApiRequest;
}

const initialState: DirectionsListState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useDirectionsList(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.directionsList);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const directionsListReducer = createReducer<
  //
  DirectionsListState,
  Action
>(initialState)
  .handleAction(actionResetDirectionsList, (): DirectionsListState => {
    return initialState;
  })
  .handleAction(actionFetchDirectionsList.request, (state, action): DirectionsListState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchDirectionsList.success, (state, action): DirectionsListState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchDirectionsList.failure, (state, action): DirectionsListState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateDirectionsListTaaStatus, (state, action): DirectionsListState => {
    const { detail } = state;

    if (!detail?.trustAccountAuthorisationRecordStatuses) {
      return state;
    }

    const { accountId } = action.payload;
    const { trustAccountAuthorisationRecordStatuses } = detail;
    const updateIndex = trustAccountAuthorisationRecordStatuses?.findIndex(status => status.accountId === accountId);

    if (updateIndex === -1) {
      return state;
    }

    const newStatuses = trustAccountAuthorisationRecordStatuses.concat();
    newStatuses[updateIndex] = {
      ...newStatuses[updateIndex],
      ...action.payload
    };

    return {
      ...state,
      detail: {
        ...detail,
        trustAccountAuthorisationRecordStatuses: newStatuses
      }
    };
  })
  .handleAction(actionUpdateDirectionsListPaymentsStatus, (state, action): DirectionsListState => {
    const { detail } = state;

    if (!detail?.paymentsStatus) {
      return state;
    }

    const newPaymentsStatus: HttpTypes.SettlementDistributionStatusModel['paymentsStatus'] = { ...detail.paymentsStatus, ...action.payload };

    return {
      ...state,
      detail: {
        ...detail,
        paymentsStatus: newPaymentsStatus
      }
    };
  })
  .handleAction(actionUpdateDirectionsListSourceFundsStatus, (state, action): DirectionsListState => {
    const { detail } = state;

    if (!detail?.sourceFundsStatus) {
      return state;
    }

    const newSourceFundsStatus: HttpTypes.SettlementDistributionStatusModel['sourceFundsStatus'] = { ...detail.sourceFundsStatus, ...action.payload };

    return {
      ...state,
      detail: {
        ...detail,
        sourceFundsStatus: newSourceFundsStatus
      }
    };
  })
  .handleAction(actionUpdateDirectionsWorkspaceCurrentParticipantDetail, (state, action): DirectionsListState => {
    const { detail } = state;

    if (detail == null) {
      return state;
    }

    const { workspaceParticipant } = action.payload;
    const { stampDutyStatus, paymentsStatus, sourceFundsStatus } = detail;

    const updatePaymentsStatusData: HttpTypes.WorkspaceDirectionsStatusApiResponse['paymentsStatus'] = {
      ...paymentsStatus,
      workspaceParticipant: {
        ...paymentsStatus.workspaceParticipant,
        ...workspaceParticipant
      }
    };

    const updateSourceFundsStatusData: HttpTypes.WorkspaceDirectionsStatusApiResponse['sourceFundsStatus'] = {
      ...sourceFundsStatus,
      workspaceParticipant: {
        ...sourceFundsStatus.workspaceParticipant,
        ...workspaceParticipant
      }
    };

    let updateStampDutyStatusData: HttpTypes.WorkspaceDirectionsStatusApiResponse['stampDutyStatus'] | undefined;
    if (stampDutyStatus) {
      const updateIndex = stampDutyStatus.workspaceParticipants.findIndex(participant => participant.participantId === workspaceParticipant.id);
      if (updateIndex !== -1) {
        const workspaceParticipants = stampDutyStatus.workspaceParticipants.concat();
        const newWorkspaceParticipants: HttpTypes.ParticipantDetails[] = [
          ...workspaceParticipants.slice(0, updateIndex),
          { ...workspaceParticipants[updateIndex], ...workspaceParticipant },
          ...workspaceParticipants.slice(updateIndex + 1)
        ];

        updateStampDutyStatusData = {
          ...stampDutyStatus,
          workspaceParticipants: newWorkspaceParticipants
        };
      }
    }

    return {
      ...state,
      detail: {
        ...detail,
        stampDutyStatus: updateStampDutyStatusData,
        paymentsStatus: updatePaymentsStatusData,
        sourceFundsStatus: updateSourceFundsStatusData
      }
    };
  });

export default directionsListReducer;
