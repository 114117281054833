// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2082406414/workspace+detail+timestamp+-+data+dependency+insights
import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DateFormatEnum } from 'src/@core/models';
import { LineLoader } from 'src/components/loaders';
import { dateTimeLine } from 'src/utils/formatters';
import { useStyles } from './styles';

export interface Props {
  isLoading: boolean;
  // basic workspace info
  createdDate?: HttpTypes.DateTimeInfo;
  lastUpdatedDate?: HttpTypes.DateTimeInfo;
  userLastAccessedDate?: HttpTypes.DateTimeInfo;
  lrsVersion?: string;
}

function WorkspaceDetailTimestamp({ isLoading, createdDate, lastUpdatedDate, userLastAccessedDate, lrsVersion }: Props) {
  const classes = useStyles();

  if (isLoading) {
    return <LineLoader icon={false} color="grey" variant="large" widthPercent={70} />;
  }

  const renderContent = () => (
    <>
      {lastUpdatedDate && (
        <Typography variant="caption" display="block" color="textSecondary" className={classes.text101}>
          Updated {dateTimeLine(lastUpdatedDate.userLocalTime, DateFormatEnum.AUSDATETIMETEXT)} {`(${lastUpdatedDate.userTimezoneAbbreviation})`}
        </Typography>
      )}
      {createdDate && (
        <Typography variant="caption" display="block" color="textSecondary" className={classes.text101}>
          Created {dateTimeLine(createdDate.userLocalTime, DateFormatEnum.AUSDATETIMETEXT)} {`(${createdDate.userTimezoneAbbreviation})`}
        </Typography>
      )}
      {userLastAccessedDate && (
        <Typography variant="caption" display="block" color="textSecondary" className={classes.text101}>
          Last accessed date {dateTimeLine(userLastAccessedDate.userLocalTime, DateFormatEnum.AUSDATETIMETEXT)} {`(${userLastAccessedDate.userTimezoneAbbreviation})`}
        </Typography>
      )}
      {lrsVersion && (
        <Typography variant="caption" display="block" color="textSecondary" className={classes.text101}>
          Schema version {lrsVersion}
        </Typography>
      )}
    </>
  );

  return <Box className={classes.centerText}>{renderContent()}</Box>;
}

export default React.memo(WorkspaceDetailTimestamp);
