import * as React from 'react';

import { FormikProps } from 'formik';

import { HttpTypes } from '@sympli/api-gateway/types';

import Formik from 'src/components/formik';
import PurchaserPaysVendorBox from 'src/containers/workspace/financial/directions/components/purchaser-pays-vendor-box';
import {
  getExpectedSympliSourceAccountAmount,
  getVerifiedSympliSourceAccountAmount,
  isDistributionValid,
  isSourceFundValid
} from 'src/containers/workspace/financial/directions/helpers';
import DirectionsSummary from '../../../../components/directions-summary';
import { DistributionFormikModel, SourceFundFormikModel } from '../../../../models';
import DirectionForm from '../../components/direction-form';
import DirectionRecordList from '../../components/direction-record-list';
import SourceFundList from '../../components/source-fund-list';
import { NEW_SOURCEFUND } from '../../components/source-fund-list/models';
import SourceFundsDialog from '../../components/source-funds-dialog';
import { getKeyValueMatchingNumberInObject } from '../../helper';
import useEditDirections from '../../hooks/useEditDirections';
import { EditDirectionCommonProps } from '../../models';
import { directionsSummarySelector, otherPartiesDirectionsSummarySelector } from '../../selectors';
import { SourceFundModel } from '../discharge-mortgage-directions/models';
import { VendorDirectionsFormModel } from './models';
import getValidationSchema from './validationSchema';

const WORKSPACE_ROLE = HttpTypes.WorkspaceRoleEnum.Vendor;

interface Props extends EditDirectionCommonProps {
  onDialogToggle: (dialogOpen: boolean) => void;
  dialogOpen: boolean;
}

function VendorDirections({
  queryParams,
  detail,
  portalIdForDirectionSummary,
  dialogOpen,
  workspaceType,
  portalIdForFormSubmit,
  onFormCancel,
  canEdit,
  currentParticipant,
  directionsCategoriesDetail,
  requiresStampDuty,
  onDialogToggle,
  havePurchaserParticipant,
  numberOfLinkedWorkspaces,
  hasManageWorkspacePermission
}: Props) {
  const { workspaceId, participantId, defaultDistributionsValue, onPostSubmit, isSaveAsDraft, setIsSaveAsDraft, trustAccountOptions, trustAccountBankDetailMap } =
    useEditDirections(queryParams, detail, currentParticipant, directionsCategoriesDetail.directionCategories);
  const expectedAmount = React.useMemo(() => getExpectedSympliSourceAccountAmount(detail.sourceFunds, trustAccountBankDetailMap), [detail.sourceFunds, trustAccountBankDetailMap]);
  const verifiedAmount = React.useMemo(() => getVerifiedSympliSourceAccountAmount(detail.trustAccounts), [detail.trustAccounts]);

  const sourceFundDefaultValue = React.useMemo(() => {
    return { ...NEW_SOURCEFUND, subscriberName: currentParticipant.name };
  }, [currentParticipant.name]);

  const otherPartiesSummary = otherPartiesDirectionsSummarySelector({
    workspaceRole: WORKSPACE_ROLE,
    directionsSummary: detail.directionsSummary,
    sourceFunds: detail.sourceFunds,
    directions: detail.directions
  });

  const getInitialValues = React.useCallback((): VendorDirectionsFormModel => {
    const { directions, sourceFunds, purchaserPayAmount } = detail;

    return {
      distributions: directions.map(item => {
        const result: DistributionFormikModel = { ...defaultDistributionsValue, ...item };
        return {
          ...result,
          isEditorOpen: !isDistributionValid(result)
        };
      }),
      sourceFunds: sourceFunds.map(item => {
        const result: SourceFundFormikModel = { ...NEW_SOURCEFUND, ...item };
        return { ...result, isEditorOpen: !isSourceFundValid(result) };
      }),
      purchaserPayAmount,
      hasManageWorkspacePermission
    };
  }, [defaultDistributionsValue, detail, hasManageWorkspacePermission]);

  const handleOnPreSubmit = React.useCallback(
    (values: VendorDirectionsFormModel): HttpTypes.UpdateWorkspaceDirectionsApiRequestBody => {
      const distributions: HttpTypes.UpdateWorkspaceDirectionsApiRequestBody['directions'] = values.distributions
        .filter(item => !item.isLocked)
        .map(item => {
          const { bankDetails, bpayDetails, paymentMethod, amount, directionCategory, category, categoryOther, bankAccountId, id, linkedSettlementItem } = item;
          const commonValues = {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id
          };
          switch (paymentMethod) {
            case HttpTypes.PaymentMethodEnum.BPAY:
              return { ...commonValues, bpayDetails };
            case HttpTypes.PaymentMethodEnum.TrustAccount:
              return { ...commonValues, bankAccountId, bankDetails };
            case HttpTypes.PaymentMethodEnum.Linked:
              return {
                ...commonValues,
                reference: item.reference,
                linkedSettlementItem: {
                  linkedParticipantId: linkedSettlementItem?.linkedToParticipantId!,
                  linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId!,
                  linkedToParticipantMatterReference: linkedSettlementItem?.linkedToParticipantMatter
                }
              };
            case HttpTypes.PaymentMethodEnum.BankTransfer:
            default:
              return { ...commonValues, bankDetails };
          }
        });

      const sourceFunds: SourceFundModel[] = values.sourceFunds.map(item => {
        const { trustAccountId, amount, category, reference, categoryOther, id, paymentMethod, linkedSettlementItem } = item;

        const trustAccount = trustAccountBankDetailMap[trustAccountId];
        if (trustAccount && trustAccount.bankAccountType === HttpTypes.BankAccountTypeEnum.SympliSourceAccount) {
          return {
            trustAccountId,
            amount: Number(amount),
            reference: trustAccount.bankDetails.accountNumber,
            category,
            categoryOther,
            paymentMethod: HttpTypes.PaymentMethodEnum.BankTransfer
          };
        }
        return {
          trustAccountId,
          amount: Number(amount),
          reference,
          category,
          categoryOther,
          id,
          paymentMethod,
          linkedSettlementItem:
            paymentMethod === HttpTypes.PaymentMethodEnum.Linked
              ? {
                  linkedParticipantId: linkedSettlementItem?.linkedToParticipantId,
                  linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId
                }
              : null
        };
      });

      const purchaserPayAmount = Number(values.purchaserPayAmount);

      return { directions: distributions, sourceFunds, purchaserPayAmount, isSaveAsDraft };
    },
    [isSaveAsDraft, trustAccountBankDetailMap]
  );

  return (
    <Formik
      method="post"
      action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions`}
      httpConfig={{ withCredentials: true }}
      getInitialValues={getInitialValues}
      validationSchema={getValidationSchema(trustAccountBankDetailMap, workspaceId, numberOfLinkedWorkspaces)}
      onPreSubmit={handleOnPreSubmit}
      onPostSubmit={onPostSubmit}
    >
      {(formikProps: FormikProps<VendorDirectionsFormModel>) => {
        const { distributions, sourceFunds, purchaserPayAmount } = formikProps.values;
        const isMultipleEditorsOpen = getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) >= 1;
        // * formik will update twice with same value (due to async validation)
        // * these selector prevent recalculation and re-render
        const updatedSummary = directionsSummarySelector({
          workspaceRole: WORKSPACE_ROLE,
          otherPartiesSummary,
          sourceFunds: detail.sourceFunds,
          distributions: detail.directions,
          purchaserPayAmount,
          loanAdvanceRequiredAmount: detail.directionsSummary.loanAdvanceRequiredAmount
        });

        // make sure all editors are closed for add and edit,
        // todo is it ok if it is edit model but no data been modified? do we want to keep it disabled?
        const distributionsInEditModel = distributions.some(item => item.isEditorOpen);
        const sourceFundsInEditModel = sourceFunds.some(item => item.isEditorOpen);

        return (
          <>
            <DirectionForm
              workflowType={detail.distributionWorkflowType}
              stepperCanEdit={canEdit && !distributionsInEditModel && !sourceFundsInEditModel}
              canEdit={canEdit}
              isSubmitting={formikProps.isSubmitting}
              isSaveAsDraft={isSaveAsDraft}
              dirty={formikProps.dirty && isMultipleEditorsOpen}
              onFormCancel={onFormCancel}
              handleOnSaveAndContinueClick={() => {
                setIsSaveAsDraft(false);
                formikProps.submitForm();
              }}
              portalIdForFormSubmit={portalIdForFormSubmit}
            >
              <PurchaserPaysVendorBox canEdit={canEdit} />
              <DirectionRecordList
                canEdit={canEdit}
                isMultipleEditorsOpen={isMultipleEditorsOpen}
                formikProps={formikProps}
                defaultValue={defaultDistributionsValue}
                currentParticipant={currentParticipant}
                trustAccountOptions={trustAccountOptions}
                trustAccountBankDetailMap={trustAccountBankDetailMap}
                workspaceId={workspaceId}
                participantId={participantId}
                workspaceRole={WORKSPACE_ROLE}
                paymentMethodOptions={detail.directionPaymentMethods}
              />
              <SourceFundList
                workspaceId={workspaceId}
                participantId={participantId}
                canEdit={canEdit}
                isMultipleEditorsOpen={isMultipleEditorsOpen}
                formikProps={formikProps}
                currentParticipant={currentParticipant}
                defaultValue={sourceFundDefaultValue}
                trustAccountOptions={trustAccountOptions}
                trustAccountBankDetailMap={trustAccountBankDetailMap}
                sourceFundCategories={directionsCategoriesDetail.sourceFundsCategories}
              />
              <DirectionsSummary
                workspaceTypeId={workspaceType}
                workspaceRoleId={WORKSPACE_ROLE}
                directionsSummary={updatedSummary}
                trustAccounts={detail.trustAccounts}
                trustAccountBankDetailMap={trustAccountBankDetailMap}
                sourceFunds={sourceFunds}
                portalIdForDirectionSummary={portalIdForDirectionSummary}
                requiresStampDuty={requiresStampDuty}
                havePurchaserParticipant={havePurchaserParticipant}
              />
            </DirectionForm>
            <SourceFundsDialog
              open={dialogOpen}
              formikProps={formikProps}
              expectedAmount={expectedAmount}
              verifiedAmount={verifiedAmount}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              defaultValue={defaultDistributionsValue}
              onClose={() => {
                onDialogToggle(false);
              }}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default VendorDirections;
