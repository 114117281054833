import { isSameDay } from 'date-fns';
import dateformat from 'dateformat';
import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { DateFormatEnum } from 'src/@core/models';
import { getTimeInTimeZone } from 'src/@core/utils/timezone';
import { WorkspaceUpdateMessage, WorkspaceUpdateMessageCategoryEnum } from 'src/pages/workspace/socket/workspace-update/models';
import { actionFetchParticipantConversationSummarySilently } from './actions';
import { ConversationDocument, UpdatedRealtimeMessageModel } from './models';

import type { SafeDispatch } from 'src/hooks';

// Return 10:30 AM for today, 12 Apr for other days
export function getFormattedMessageDate(date: Date, currentDate: Date = new Date(), timezone: string = '') {
  const dateFormatString = isSameDay(date, currentDate) ? DateFormatEnum.HOUR_MIN_TIMEMARKER : DateFormatEnum.AU_SHORT_DAY_MONTH;
  const jurisdiction = HttpTypes.UserTimeZoneEnum[timezone];
  const dateInTimezone = getTimeInTimeZone(date, jurisdiction);
  return dateformat(dateInTimezone, dateFormatString);
}

// Return i.e 10/04/23 - 10:30 AM format
export function getDashboardMessageDate(date: Date, timezone: string = '') {
  const dateFormatString = DateFormatEnum.DAY_MONTH_YEAR_MIN_MARKER;
  const jurisdiction = HttpTypes.UserTimeZoneEnum[timezone];
  const dateInTimezone = getTimeInTimeZone(date, jurisdiction);
  return dateformat(dateInTimezone, dateFormatString);
}

export function getFormattedDate(date: Date, timezone: string = '') {
  const jurisdiction = HttpTypes.UserTimeZoneEnum[timezone];
  const dateInTimezone = getTimeInTimeZone(date, jurisdiction);
  return {
    dateWithoutYear: dateformat(dateInTimezone, DateFormatEnum.HOUR_MIN_TIMEMARKER), // i.e   9:30am
    dateWithShortYear: dateformat(dateInTimezone, DateFormatEnum.DATEMONTH_SHORTYEAR) // i.e  14 Apr 24
  };
}

export function getFormattedLongDate(date: Date, timezone: string = '') {
  const jurisdiction = HttpTypes.UserTimeZoneEnum[timezone];
  const dateInTimezone = getTimeInTimeZone(date, jurisdiction);
  return dateformat(dateInTimezone, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER_2); // i.e   9:30am
}

export function getConversationSubject(conversation: ConversationDocument) {
  return conversation.subject;
}

export function handleRealtimeConversationUpdate(dispatch: SafeDispatch<Action>, workspaceId: string, participantId: string, message: WorkspaceUpdateMessage) {
  if (message.Category === WorkspaceUpdateMessageCategoryEnum.Messenger) {
    try {
      const updatedPayload: UpdatedRealtimeMessageModel = JSON.parse(message.Message);

      // need to make sure the message is send to the relevant participant
      if (!updatedPayload.RecipientParticipantIds.includes(participantId)) {
        return;
      }

      const conversationId = updatedPayload.ConversationId;

      dispatch(actionFetchParticipantConversationSummarySilently.request({ workspaceId, participantId, conversationId }));
    } catch (ex) {
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Unsupported message category', message.Category), scope);
    }
  }
}
