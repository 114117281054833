import { defaultMemoize } from 'reselect';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { HttpTypes } from '@sympli/api-gateway/types';

import { filterAndParseDocumentsByTypeAndVersion, resolveCrossDocumentMatches } from 'src/containers/documents/scaffolded-form/shared/crossDocumentUtils';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from '../models';
import { convertFromApiToFormModel } from './convertFromApiToFormModel';
import { resolveDeceasedPartiesOnTAE } from './resolveDeceasedPartiesOnTAE';

type FormModel = TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model;

export const resolveMatchingPartyHashesOnTAE = defaultMemoize(
  ({
    selectedPartiesFromCurrentDocument,
    workspaceDocuments,
    context
  }: {
    selectedPartiesFromCurrentDocument: PartyModel[];
    //
    workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[];
    /**
     * current form context
     */
    context: IConverterContext;
  }): {
    /**
     * Parties currently selected on this document with same matchingItemHash as items in selectedPartiesFromCurrentDocument
     */
    matchingByPartyItemHash: string[];
    /**
     * Parties currently selected on this document that has same matchingGroupHash as items in selectedPartiesFromCurrentDocument
     * These parties may no be the exact same party as in selectedPartiesFromCurrentDocument, but they are from the same group
     */
    matchingByGroupHash: string[];
  } => {
    // parse TAE documents
    const otherDocumentsParsed: FormModel[] = filterAndParseDocumentsByTypeAndVersion(
      workspaceDocuments,
      {
        ...context,
        meta: {
          ...context.meta,
          schemaVersion: '2.21.3', // this version needs to match the source of convertFromApiToFormModelTAE method
          documentTypeId: HttpTypes.DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee
        }
      },
      convertFromApiToFormModel
    );
    // check
    if (otherDocumentsParsed.length) {
      // get selected parties from them
      const selectedPartiesFromOtherDocuments: FormModel['partyBook'] = otherDocumentsParsed
        .flatMap(doc => resolveDeceasedPartiesOnTAE(doc.deceasedTenancyDetail, doc.partyBook, doc.precedingData)) //
        .filter(p => p.mergeMetadata); // we will need the mergedMetadata, so we can compare it with other documents

      return resolveCrossDocumentMatches(selectedPartiesFromCurrentDocument, selectedPartiesFromOtherDocuments);
    }

    // nothing is matching
    return {
      matchingByPartyItemHash: [],
      matchingByGroupHash: []
    };
  }
  //, shallowEqual
);
