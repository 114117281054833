import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import Logger from '@sympli/ui-logger';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionFetchMergedDocumentsPdf } from 'src/containers/documents/actions';
import DocumentViewer from 'src/containers/documents/components/document-viewer';
import DocumentWorkflowPanel, { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { DocumentFormAndDetailModel, DocumentWorkflowStepsEnum, SaveDocumentFormRequest } from 'src/containers/documents/models';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import { resolveSignDocumentActionInfoSelector } from 'src/containers/workspace/shared/detail/selectors';
import { useSafeDispatch } from 'src/hooks';
import { actionUpdateWorkspaceIsLockedStatus } from 'src/store/actions/workspace';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { DocumentMergedPdfState } from '../../reducers/documentMergedPdf';
import { getDocumentsReadyToSign } from '../../selectors';
import { lodgeHelper } from './helpers';
import { memoizedAllParticipantSigned } from './selectors';

interface Props {
  queryParams: SaveDocumentFormRequest;
  disableLodge?: boolean;
  disableNextButton: boolean;
  displayDocumentAsPdf: boolean;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  verifying: boolean;
  documentList: HttpTypes.WorkspaceDocumentSummary[];
  documentMergedPdfState: DocumentMergedPdfState;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
  lodgementCaseId: string;
}

function LodgeDocument({
  //
  queryParams,
  disableLodge,
  displayDocumentAsPdf,
  documentDetail,
  jurisdictionId,
  verifying,
  documentList,
  documentMergedPdfState,
  workflowPanelProps,
  lodgementCaseId
}: Props) {
  const navigate = useNavigate();
  const { workspaceId, participantId } = queryParams;
  const signActionInfos = resolveSignDocumentActionInfoSelector({ detail: documentDetail, participantId });
  const mode = displayDocumentAsPdf ? 'dark' : 'light';

  const documentsReadyToSign = getDocumentsReadyToSign(documentList, lodgementCaseId);

  // Document could be accessed by a participant without signing access
  const canSign = documentDetail.documentPermissions.includes(HttpTypes.DocumentPermissionEnum.Sign);
  const isSignMultiple = canSign && documentsReadyToSign.length > 1;
  const documentIds = React.useMemo(() => documentsReadyToSign.map(d => d.documentId), [documentsReadyToSign]);
  const [isLodgementInProgress, setIsLodgementInProgress] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());

  React.useEffect(() => {
    // * Only fetch merged document when in sign all mode and lodgement verification not in progress
    if (isSignMultiple && !verifying) {
      dispatch(
        actionFetchMergedDocumentsPdf.request({
          workspaceId,
          participantId,
          documentIds
        })
      );
    }
  }, [dispatch, documentIds, participantId, isSignMultiple, verifying, workspaceId]);

  const handleOnLodgement = () => {
    setIsLodgementInProgress(true);

    // when hit lodge button, trigger redux store update workspace lock
    // todo: this should be lodgement case level for SLC
    dispatch(actionUpdateWorkspaceIsLockedStatus(true));
    lodgeHelper({ ...queryParams, lodgementCaseId })
      .then(() => {
        setIsLodgementInProgress(false);
        const link = resolveWorkspaceDetailLink(queryParams);
        navigate(link);
      })
      .catch(error => {
        const scope = Logger.scopeWithCustomAttributes(queryParams);
        Logger.captureException(error, scope);

        setIsLodgementInProgress(false);
        // when fails, we set workspace to unlocked
        dispatch(actionUpdateWorkspaceIsLockedStatus(false));
        dispatch(
          actionCreateGlobalErrorMessage({
            ...error,
            title: 'lodge document failed.',
            message: 'failed to lodge the document.'
          })
        );
      });
  };
  const workspaceDetailsState = useWorkspaceDetail(workspaceId, participantId);

  const hasNoTitles = !workspaceDetailsState.detail?.titleReferences?.length;

  // Disable if Land registry closed or not completely signed.
  const shouldDisableLodge = disableLodge || !memoizedAllParticipantSigned(documentList) || isLodgementInProgress || hasNoTitles;

  const documentWorkflowPanel = (
    <DocumentWorkflowPanel //
      disableStepper={isLodgementInProgress}
      disableMenu={isLodgementInProgress}
      {...workflowPanelProps}
    >
      <ActionLabel //
        documentActionInfo={signActionInfos}
        mode={mode}
        currentParticipantId={participantId}
      />
      <SympliButton //
        mode={mode}
        color="primary"
        variant="contained"
        onClick={handleOnLodgement}
        isLoading={isLodgementInProgress}
        arrowRight={true}
        disabled={shouldDisableLodge}
      >
        Lodge
      </SympliButton>
    </DocumentWorkflowPanel>
  );

  return (
    <>
      {documentWorkflowPanel}
      <DocumentViewer
        displayDocumentAsPdf={displayDocumentAsPdf}
        signMultiple={isSignMultiple}
        queryParams={queryParams}
        jurisdictionId={jurisdictionId}
        documentDetail={documentDetail}
        documentMergedPdfState={documentMergedPdfState}
      />
    </>
  );
}

export default React.memo(LodgeDocument);
