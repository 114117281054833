import * as React from 'react';

import pluralize from 'pluralize';
import { twMerge } from 'tailwind-merge';
import Typography from '@mui/material/Typography';

import { ClockIcon, CompletedIcon, ErrorIcon } from '@sympli/ui-framework/icons';

import { colors } from 'src/theme';
import { SettlementDateTimeVariantEnum } from '../../models';

interface Props {
  variant: SettlementDateTimeVariantEnum;
  weeksTill?: number;
  daysTill?: number;
}

function DueInDateTime({ variant, weeksTill, daysTill }: Props) {
  if (variant === SettlementDateTimeVariantEnum.NoDateNoTime) {
    // This will never happen
    return null;
  }

  if (variant === SettlementDateTimeVariantEnum.Unknown) {
    return (
      <Typography className="text-[var(--watermelon-dark)] text-[10px] uppercase font-extrabold tracking-[0.5px] font-volkswagen-serial-bold">Something went wrong</Typography>
    );
  }

  const renderDueInDaysOrWeeks = (daysOrWeeks: number) => {
    return <Typography className="text-[var(--sunny-day)] text-[40px] font-extrabold leading-[44px] font-volkswagen-serial-bold">{daysOrWeeks}</Typography>;
  };

  const renderText = (text: string, className?: string) => {
    return (
      <Typography className={twMerge('text-[var(--sunny-day)] text-[16px] font-extrabold tracking-[0.5px] leading-[24px] font-volkswagen-serial-xbold', className)}>
        {text}
      </Typography>
    );
  };

  const renderReminder = () => {
    switch (variant) {
      case SettlementDateTimeVariantEnum.DateOnly:
        return (
          <>
            <ErrorIcon className="my-[8px]" />
            {renderText('To be booked', 'text-[12px] medium-screen:text-[16px]')}
          </>
        );
      case SettlementDateTimeVariantEnum.SettlementInWeeks:
        return (
          <>
            {renderDueInDaysOrWeeks(weeksTill ?? 0)}
            {renderText('Weeks')}
          </>
        );
      case SettlementDateTimeVariantEnum.SettlementInDays:
        return (
          <>
            {renderDueInDaysOrWeeks(daysTill ?? 0)}
            {renderText(pluralize('day', daysTill))}
          </>
        );
      case SettlementDateTimeVariantEnum.SettlementToday:
        return (
          <>
            <ClockIcon className="my-[8px]" />
            {renderText('Today')}
          </>
        );
      case SettlementDateTimeVariantEnum.IsRollOver:
        return (
          <>
            <ClockIcon className="my-[8px]" fill={colors.WATERMELON} />
            {renderText('Today', 'text-[var(--watermelon-luminous)]')}
          </>
        );
      case SettlementDateTimeVariantEnum.SettlementInProgress:
        return (
          <>
            <ClockIcon className="my-[8px]" fill={colors.GREEK_WATERS} />
            {renderText('Now', 'text-[var(--sympli-green)]')}
          </>
        );
      case SettlementDateTimeVariantEnum.Completed:
        return (
          <>
            <CompletedIcon className="my-[8px]" />
            {renderText('Completed', 'text-[var(--sympli-green)]')}
          </>
        );
      case SettlementDateTimeVariantEnum.SettlementIssue:
        return (
          <>
            <ClockIcon className="my-[8px]" fill={colors.GREEK_WATERS} />
            {renderText('Now', 'text-[var(--sympli-green)]')}
          </>
        );
      case SettlementDateTimeVariantEnum.Overdue:
      case SettlementDateTimeVariantEnum.Unsuccessful:
      case SettlementDateTimeVariantEnum.UnsupportedDate:
        return (
          <>
            <ErrorIcon className="my-[8px]" fill={colors.WATERMELON} />
            {renderText('To be booked', 'text-[var(--watermelon-luminous)] text-[12px] medium-screen:text-[16px]')}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-[88px] h-[84px] medium-screen:w-[104px] flex justify-center flex-col items-center ">
      <Typography
        className={twMerge(
          'text-[var(--sunny-day)] text-[10px] uppercase font-extrabold tracking-[0.5px] font-volkswagen-serial-xbold',
          [
            SettlementDateTimeVariantEnum.IsRollOver,
            SettlementDateTimeVariantEnum.UnsupportedDate,
            SettlementDateTimeVariantEnum.Unsuccessful,
            SettlementDateTimeVariantEnum.Overdue
          ].includes(variant) && 'text-[var(--watermelon)]',
          [SettlementDateTimeVariantEnum.SettlementInProgress, SettlementDateTimeVariantEnum.Completed, SettlementDateTimeVariantEnum.SettlementIssue].includes(variant) &&
            'text-[var(--sympli-green)]'
        )}
      >
        Settlement
      </Typography>
      {renderReminder()}
    </div>
  );
}

export default React.memo(DueInDateTime);
