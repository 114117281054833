import * as yup from 'yup';

import { FractionModel } from '@sympli-mfe/document-forms-framework/core/models';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import {
  BaseDeceasedTenancyDetailComponentModel,
  DeceasedProprietorGroupModel,
  DeceasedProprietorModel,
  DeceasedTenancyDetailModel
} from '../../../../components/deceased-tenancy-detail/models';
import { getLegalEntityNameNSW } from '../../../../helpers';
import { NoticeOfDeath_2_21_2_Model } from '../../models';
import { resolveDeceasedProprietorSchema } from './components/deceased-proprietor/validationSchema';
import { VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP } from './visibilityChecks';

export const MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE = 'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.';
export const PLEASE_SELECT_A_JOIN_TENANT = 'Please select a joint tenant';

const yupDeceasedProprietorGroups = yup
  .array<DeceasedProprietorGroupModel>(
    yup
      .object<DeceasedProprietorGroupModel>({
        parties: yup
          .array<DeceasedProprietorModel>()
          .when(['isSelected'], (isSelected: boolean, schema) => {
            return isSelected ? schema.of(resolveDeceasedProprietorSchema()) : schema.of(yup.mixed<DeceasedProprietorModel>());
          })
          .required()
          .defined(),
        shareFraction: yup.mixed<FractionModel>(),
        isSelected: yup.mixed(),
        proprietorGroupType: yup.mixed()
      })
      .defined()
  )
  .defined()
  .log();

export const yupDeceasedTenancyDetailSchema = yup
  .object<DeceasedTenancyDetailModel>({
    tenancyType: yup.mixed(),
    proprietorGroups: validateWhenVisible2<DeceasedProprietorGroupModel[]>({
      isObjectOrArray: true,
      visibilityCheck: (parent: DeceasedTenancyDetailModel) => VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP(parent),
      validationWhenVisible: yupDeceasedProprietorGroups,
      disableEarlyAbort: true
    })
  })
  .test(
    //
    'Deceased Proprietors requires name change test',
    MISMATCH_NAME_ACROSS_TITLES_ERROR_MESSAGE,
    function test(this: yup.TestContext<BaseDeceasedTenancyDetailComponentModel>) {
      const { partyBook, deceasedTenancyDetail } = this.options.context!;

      const validationResult = PartyValidations.verifyJustification(partyBook, getLegalEntityNameNSW);
      if (validationResult) return true;

      const selectedDeceasedProprietorId = deceasedTenancyDetail.proprietorGroups.find(pg => pg.isSelected)?.parties.find(p => p.isSelected)?.partyBookId;
      const deceasedProprietorName = partyBook.find(pb => pb.id === selectedDeceasedProprietorId)?.legalEntityName;

      const uniqueNamesRequiringJustification = partyBook
        .filter(pb => pb.mergeMetadata?.requiresJustification)
        .reduce((partyNames: string[], party) => {
          if (!partyNames.includes(party.legalEntityName!)) {
            partyNames.push(party.legalEntityName!);
          }
          return partyNames;
        }, []);

      if (deceasedProprietorName !== undefined) {
        const duplicatePartyNamesRequiringJustification = partyBook.filter(pb => pb.legalEntityName === deceasedProprietorName && pb.mergeMetadata?.requiresJustification);
        const otherPartiesRequiringJustification = uniqueNamesRequiringJustification.filter(name => name !== deceasedProprietorName);

        return duplicatePartyNamesRequiringJustification.length > 1 || otherPartiesRequiringJustification.length > 1
          ? this.createError({ message: 'The parties on the selected titles do not match and will require multiple justifications; please remove the mismatched titles.' })
          : false;
      }

      return uniqueNamesRequiringJustification.length > 2
        ? this.createError({ message: 'The parties on the selected titles do not match and will require multiple justifications; please remove the mismatched titles.' })
        : false;
    }
  )
  .test('At least one proprietor must be selected', PLEASE_SELECT_A_JOIN_TENANT, function test(this: yup.TestContext<NoticeOfDeath_2_21_2_Model>) {
    const { deceasedTenancyDetail, titleReferences } = this.options.context!;

    if (titleReferences.filter(x => x.isSelected).length < 1) {
      return true;
    }

    return deceasedTenancyDetail.proprietorGroups.flatMap(x => x.parties).some(x => x.isSelected);
  })
  .defined();
