import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';

const useInteroperable = (workspaceTypeId?: HttpTypes.WorkspaceTypeEnum) => {
  const interoperableWorkspaceIdEnabled = useFeatureFlag(FeatureToggleEnum.interoperableWorkspaceIdEnabled);
  const isInteroperable = interoperableWorkspaceIdEnabled && workspaceTypeId === HttpTypes.WorkspaceTypeEnum.FinancialSettlement;

  return isInteroperable;
};

export default useInteroperable;
