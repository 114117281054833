import { call, put, takeLatest } from 'typed-redux-saga';

import { GeneralSagasErrorModel } from 'src/@core/store/middlewares/globalErrorMiddleware';
import http from 'src/utils/http';
import { actionFetchStampDutyDetails, FetchStampDutyDetailRequestModel } from './actions';
import { StampDutyApiResponse } from './models';

function fetchStampDutyDetails(payload: FetchStampDutyDetailRequestModel) {
  const { workspaceId, participantId } = payload;
  return http.get<StampDutyApiResponse>(`/workspaces/${workspaceId}/participants/${participantId}/stampduties`);
}

function* sagaFetchStampDutyDetails(action: ReturnType<typeof actionFetchStampDutyDetails.request>) {
  try {
    const data = yield* call(fetchStampDutyDetails, action.payload);

    yield put(actionFetchStampDutyDetails.success({ data }));
  } catch (error) {
    const errorPayload: GeneralSagasErrorModel = { error, skipGlobalErrorHandling: true };
    yield put(actionFetchStampDutyDetails.failure(errorPayload));
  }
}

export default [
  //
  takeLatest(actionFetchStampDutyDetails.request, sagaFetchStampDutyDetails)
];
