import queryString from 'query-string';
import { call, cancelled, put, takeLatest } from 'typed-redux-saga';

import Logger, { PageActionEnum } from '@sympli/ui-logger';

import http from 'src/utils/http';
import { SearchBoxUpdatedApiRequest, SearchCategoryEnum } from '../../components/layout/global-search/models';
import { actionFetchSearchBoxUpdatedFeed } from '../actions/searchBoxUpdated';
import { SearchBoxesUpdatedApiResponse } from '../reducers/searchBoxUpdated';

//todo: the function does not needed at all since we always search
export function captureUserAction(searchCategoryId = SearchCategoryEnum.All) {
  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
    feature: 'global-search',
    searchBy: SearchCategoryEnum[searchCategoryId],
    logGroupId: ''
  });
}

//SearchBox update
const fetchSearchBoxUpdatedFeed = (q: SearchBoxUpdatedApiRequest = {}) => http.get<SearchBoxesUpdatedApiResponse>(`/Search/workspaces?${queryString.stringify(q)}`);

// fetch with debounce input change
function* sagaDebounceFetchSearchBoxUpdatedFeed(action: ReturnType<typeof actionFetchSearchBoxUpdatedFeed.request>) {
  try {
    const data = yield* call(fetchSearchBoxUpdatedFeed, action.payload);

    const isCancelled = yield cancelled();

    if (!isCancelled) {
      captureUserAction(action.payload.searchCategoryId);
      // match empty string address to NO ADDRESS HELD
      const i = data.items.map(x => {
        return {
          ...x,
          titleAddresses: x.titleAddresses?.map(x => (x.length === 0 ? 'NO ADDRESS HELD' : x))
        };
      });
      yield put(actionFetchSearchBoxUpdatedFeed.success({ data: { ...data, items: i, searchTerm: action.payload.searchTerm } }));
    }
  } catch (error) {
    yield put(actionFetchSearchBoxUpdatedFeed.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchSearchBoxUpdatedFeed.request, sagaDebounceFetchSearchBoxUpdatedFeed)
];
