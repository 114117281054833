import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model> = {
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  invalidParties: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  deceasedTenancyDetail: NO_FALLBACK,
  applicantCapacity: [null],
  applicants: resolveReceivingTenancyDetailFallback(),
  precedingData: NO_FALLBACK,
  // UI only properties
  context: NO_FALLBACK
};
