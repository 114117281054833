import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { WeekdayEnum, WeekdayEnumEnumMap } from './models';
import { useStyles } from './styles';

interface WeekdayProps {
  onWeekdayChange: (weekday: WeekdayEnum, selected: boolean) => void;
  isSelected: boolean;
  weekday: WeekdayEnum;
}

function Weekday({ weekday, isSelected, onWeekdayChange }: WeekdayProps) {
  const [selected, setSelected] = useState(isSelected);
  const classes = useStyles();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSelected(!selected);
    onWeekdayChange(weekday, !selected);
  };

  return (
    <SympliButton //
      onClick={handleClick}
      color={selected ? 'primary' : 'inherit'}
      variant={selected ? 'contained' : undefined}
      className={classNames(classes.button, !selected && classes.notSelectedButton)}
    >
      {WeekdayEnumEnumMap[weekday]}
    </SympliButton>
  );
}

export default React.memo(Weekday);
