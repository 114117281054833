import { defaultMemoize } from 'reselect';

import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveMatchingPartyHashesOnNOD } from 'src/containers/documents/scaffolded-form/nsw/2-21/notice-of-death/2-21-2/utils/resolveMatchingPartyHashesOnNOD';
import { resolveMatchingPartyHashesOnTAE } from 'src/containers/documents/scaffolded-form/nsw/2-21/transmission-application-without-duty/2-21-3/utils/resolveMatchingPartyHashesOnTAE';
import { isItemInGroup } from '../../../helpers';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../models';
import { resolveDeceasedPartiesOnTAB } from './resolveDeceasedPartiesOnTAB';
import { resolveMatchingPartyHashesOnTAB } from './resolveMatchingPartyHashesOnTAB';

type FormModel = TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model;

// explicitly memoize this call so we can call it multiple times without re-calculating
const resolveDeceasedPartyBookItemsOnCurrentDocument = defaultMemoize(
  (
    //
    deceasedTenancyDetail: FormModel['deceasedTenancyDetail'],
    partyBook: FormModel['partyBook'],
    precedingData: FormModel['precedingData']
  ): FormModel['partyBook'] => {
    return resolveDeceasedPartiesOnTAB(deceasedTenancyDetail, partyBook, precedingData);
  }
);

export const calculateCrossDocumentMatches = ({
  workspaceDocuments,
  documentId,
  context,
  deceasedTenancyDetail,
  partyBook,
  precedingData
}: {
  //
  workspaceDocuments: HttpTypes.WorkspaceDocumentSummary[];
  documentId: string;
  context: IConverterContext;
  deceasedTenancyDetail: FormModel['deceasedTenancyDetail'];
  partyBook: FormModel['partyBook'];
  precedingData: FormModel['precedingData'];
}): {
  matchingParties: Map<string, Set<string>>;
  matchingGroups: Map<string, Set<string>>;
} => {
  const matchingParties = new Map<
    /**
     * matchingItemHash
     */
    string,
    /**
     * list of documents
     */
    Set<string>
  >();

  const matchingGroups = new Map<
    /**
     * matchingItemHash
     */
    string,
    /**
     * list of documents
     */
    Set<string>
  >();
  // find selected parties from current document
  const selectedPartiesFromCurrentDocument: FormModel['partyBook'] = resolveDeceasedPartyBookItemsOnCurrentDocument(deceasedTenancyDetail, partyBook, precedingData) //
    .filter(p => p.mergeMetadata); // we will need the mergedMetadata, so we can compare it with other documents

  if (!selectedPartiesFromCurrentDocument.length) {
    return {
      matchingParties,
      matchingGroups
    };
  }

  // selected parties from current document (although for NOD we support only one selected party always, this is generic and can be used across any other documents)
  selectedPartiesFromCurrentDocument.forEach(party => {
    // initialize
    matchingParties.set(party.mergeMetadata!.matchingItemHash, new Set<string>());
    // initialize empty list
    // this key may later be removed if we find a surviving party in the same group
    matchingGroups.set(party.mergeMetadata!.matchingGroupHash, new Set<string>());
  });

  const deceasedAcrossTAB = resolveMatchingPartyHashesOnTAB({
    //
    selectedPartiesFromCurrentDocument,
    // exclude the current document from the list
    workspaceDocuments: workspaceDocuments.filter(doc => doc.documentId !== documentId),
    context
  });
  const deceasedAcrossTAE = resolveMatchingPartyHashesOnTAE({
    //
    selectedPartiesFromCurrentDocument,
    workspaceDocuments,
    context
  });
  const deceasedAcrossNOD = resolveMatchingPartyHashesOnNOD({
    //
    selectedPartiesFromCurrentDocument,
    workspaceDocuments,
    context
  });

  // process other documents and add their names to final list
  deceasedAcrossNOD.matchingByPartyItemHash.forEach(matchingItemHash => {
    matchingParties.get(matchingItemHash)?.add('Notice of Death');
  });
  deceasedAcrossTAE.matchingByPartyItemHash.forEach(matchingItemHash => {
    matchingParties.get(matchingItemHash)?.add('Transmission Application');
  });
  deceasedAcrossTAB.matchingByPartyItemHash.forEach(matchingItemHash => {
    matchingParties.get(matchingItemHash)?.add('Transmission Application');
  });

  const precedingDataParties =
    precedingData?.newProprietors
      .flatMap(x => x.proprietorGroups)
      .flatMap(x => x.parties)
      .map(x => x.party) ?? [];

  // now we can go through other documents and add their names to final list if their parties are from the identified groups
  for (const matchingGroupHash of matchingGroups.keys()) {
    if (deceasedAcrossNOD.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
      matchingGroups.get(matchingGroupHash)?.add('Notice of Death');
    }
    if (deceasedAcrossTAE.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
      matchingGroups.get(matchingGroupHash)?.add('Transmission Application');
    }
    if (deceasedAcrossTAB.matchingByGroupHash.find(matchingItemHash => isItemInGroup(matchingGroupHash, matchingItemHash))) {
      matchingGroups.get(matchingGroupHash)?.add('Transmission Application');
    }
  }

  const resultMatchingParties = new Map<string, Set<string>>();
  const resultMatchingGroups = new Map<string, Set<string>>();

  for (const [key, value] of matchingParties) {
    const partyFromPrecedingData = precedingDataParties.find(p => p.mergeMetadata?.matchingItemHash === key);
    const newKey = partyFromPrecedingData ? partyBook.find(p => p.id === partyFromPrecedingData.id)?.mergeMetadata!.matchingItemHash! : key;
    resultMatchingParties.set(newKey, value);
  }

  for (const [key, value] of matchingGroups) {
    const partyFromPrecedingData = precedingDataParties.find(p => p.mergeMetadata?.matchingGroupHash === key);
    const newKey = partyFromPrecedingData ? partyBook.find(p => p.id === partyFromPrecedingData.id)?.mergeMetadata!.matchingGroupHash! : key;
    resultMatchingGroups.set(newKey, value);
  }

  return {
    matchingParties: resultMatchingParties,
    matchingGroups: resultMatchingGroups
  };
};
