import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps, TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { LookupEnumModel } from '@sympli-mfe/enums-shared/models';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-shared/necds';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { populateCaveatModelFromTitleSelected, updatePartyReferences } from './helpers';
import { BaseCaveatModel } from './models';
import Caveators from './sections/caveators';
import ClaimDetails from './sections/claim-details';
import SectionMortgagees from './sections/mortgagees';
import SectionOtherPartyDetails from './sections/other-party-details';
import SectionInterestBeingClaimed from './sections/prohibition';
import SectionRegisteredProprietor from './sections/registered-proprietor';
import { VISIBILITY_CHECK_MORTGAGEES } from './visibilityChecks';

export interface Context<T = NecdsPartyCapacityEnum> {
  partyFormConfig: PartyFormConfig;
  partyCapacityLookup: LookupEnumModel<T>[];
  addressFormConfig: AddressFieldFormConfig;
  addressFormInitialValues: AddressFormValueModel<InitialValuesAddressEntityModel>;
}

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const fieldName = modelKey<BaseCaveatModel>();
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);
  const focusRefCaveators = React.useRef<ButtonBaseActions>(null);
  const focusRefProprietors = React.useRef<ButtonBaseActions>(null);
  const focusRefMortgagees = React.useRef<ButtonBaseActions>(null);
  const context = useRootFormContext<Context>();
  const formikProps = useFormikContext<BaseCaveatModel>();
  const { values, setValues, setFieldValue } = formikProps;

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current || focusRefCaveators.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = () => {
    setValues(values => {
      return populateCaveatModelFromTitleSelected(values, context.partyFormConfig);
    });
  };

  const renderTitleReferencesSection = () => {
    return (
      <TitleReferences //
        name={fieldName('titleReferences')}
        skipTouchedCheck
        data-testid="titleReferences"
        onChange={handleSelectedTitleReferenceChange}
      />
    );
  };

  const syncPartyReferences = async (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields): Promise<TenancyPartyModel> => {
    if (!partyReference.partyBookId) return partyReference;

    let existingPartyReference = values.partyReferences?.find(e => e.partyBookId === partyReference.partyBookId);

    if (fieldChanged === TenancyPartyFields.PartyBookId) {
      if (existingPartyReference) {
        return { ...existingPartyReference, isSelected: partyReference.isSelected };
      }

      return partyReference;
    }

    if (!existingPartyReference) {
      values.partyReferences.push(partyReference);
    } else {
      existingPartyReference.addressBookId = partyReference.addressBookId;
      existingPartyReference.partyCapacity = partyReference.partyCapacity;
    }

    syncCaveators(partyReference);
    syncProprietors(partyReference);
    syncMortgagees(partyReference);
    syncOtherParties(partyReference);
    syncProhibition(partyReference);
    syncClaimDetails(partyReference);

    setFieldValue(fieldName('partyReferences'), values.partyReferences, false);

    return partyReference;
  };

  const renderCaveatorsSection = () => {
    const name = fieldName('caveators');
    return (
      <Section title="Caveator" error={getFormikError(formikProps, name)}>
        <Caveators //
          name={name}
          focusRef={focusRefCaveators}
          dialogPortalId={dialogPortalId}
          partyFormConfig={context.partyFormConfig}
          addressFormConfig={context.addressFormConfig}
          addressFormInitialValues={context.addressFormInitialValues}
          disabled={disabled}
          syncPartyReferences={syncPartyReferences}
        />
      </Section>
    );
  };

  const renderRegisteredProprietorsSection = () => {
    return (
      <SectionRegisteredProprietor //
        name={fieldName('proprietors')}
        partyFormConfig={context.partyFormConfig}
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        formikProps={formikProps}
        addressFormConfig={context.addressFormConfig}
        addressFormInitialValues={context.addressFormInitialValues}
        focusRef={focusRefProprietors}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };

  const renderMortgageesSection = () => {
    return (
      VISIBILITY_CHECK_MORTGAGEES(values) && (
        <SectionMortgagees //
          name={fieldName('mortgagees')}
          partyFormConfig={context.partyFormConfig}
          dialogPortalId={dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          addressFormConfig={context.addressFormConfig}
          addressFormInitialValues={context.addressFormInitialValues}
          focusRef={focusRefMortgagees}
          syncPartyReferences={syncPartyReferences}
        />
      )
    );
  };

  const renderClaimDetailsSection = () => {
    const name = fieldName('claimDetails');
    return (
      <Section //
        title="Grounds of claim"
        data-name={name}
        data-testid={name}
        error={getFormikError(formikProps, name)}
      >
        <ClaimDetails //
          name={name}
          dialogPortalId={dialogPortalId}
          disabled={disabled}
          formikProps={formikProps}
          syncPartyReferences={syncPartyReferences}
        />
      </Section>
    );
  };

  const renderOtherPartyDetailsSection = () => {
    return (
      <SectionOtherPartyDetails //
        name={fieldName('otherParties')}
        partyFormConfig={context.partyFormConfig}
        partyCapacityLookup={context.partyCapacityLookup}
        addressFormConfig={context.addressFormConfig}
        addressFormInitialValues={context.addressFormInitialValues}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };

  const renderInterestBeingClaimedSection = () => {
    return (
      <SectionInterestBeingClaimed //
        name={fieldName('prohibition')}
        formikProps={formikProps}
        disabled={disabled}
        dialogPortalId={dialogPortalId}
        syncPartyReferences={syncPartyReferences}
      />
    );
  };

  return (
    <>
      <Form className={className}>
        {renderTitleReferencesSection()}
        {renderCaveatorsSection()}
        {renderRegisteredProprietorsSection()}
        {renderMortgageesSection()}
        {renderOtherPartyDetailsSection()}
        {renderInterestBeingClaimedSection()}
        {renderClaimDetailsSection()}

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );

  function syncProhibition(partyReference: TenancyPartyModel) {
    let prohibitionChanged = false;

    values.prohibition.notApplicableInstruments.forEach(x => {
      const updatedReceivingParties = updatePartyReferences(x.receivingParties, partyReference!)!;
      const updateRelinquishingParties = updatePartyReferences(x.relinquishingParties, partyReference!)!;

      if (!prohibitionChanged) {
        prohibitionChanged = x.receivingParties !== updatedReceivingParties || x.relinquishingParties !== updateRelinquishingParties;
      }

      x.receivingParties = updatedReceivingParties;
      x.relinquishingParties = updateRelinquishingParties;
    });

    if (prohibitionChanged) {
      setFieldValue(fieldName('prohibition'), values.prohibition, false);
    }
  }

  function syncOtherParties(partyReference: TenancyPartyModel) {
    let otherPartiesChanged = false;

    values.otherParties.proprietorGroups.forEach(x => {
      const updatedParties = updatePartyReferences(x.parties, partyReference!)!;

      if (!otherPartiesChanged) {
        otherPartiesChanged = x.parties !== updatedParties;
      }

      x.parties = updatedParties;
    });

    if (otherPartiesChanged) {
      setFieldValue(fieldName('otherParties'), values.otherParties, false);
    }
  }

  function syncClaimDetails(partyReference: TenancyPartyModel) {
    let claimDetailsChanged = false;

    const updatedClaimDetailParties = updatePartyReferences(values.claimDetails.claimParties, partyReference);

    if (!claimDetailsChanged) {
      claimDetailsChanged = values.claimDetails.claimParties !== updatedClaimDetailParties;
    }

    values.claimDetails.claimParties = updatedClaimDetailParties;

    if (claimDetailsChanged) {
      setFieldValue(fieldName('claimDetails'), values.claimDetails, false);
    }
  }

  function syncMortgagees(partyReference: TenancyPartyModel) {
    const mortgagees = updatePartyReferences(values.mortgagees, partyReference);

    if (mortgagees !== values.mortgagees) {
      setFieldValue(fieldName('mortgagees'), mortgagees, false);
    }
  }

  function syncProprietors(partyReference: TenancyPartyModel) {
    const proprietors = updatePartyReferences(values.proprietors, partyReference)!;

    if (proprietors !== values.proprietors) {
      setFieldValue(fieldName('proprietors'), proprietors, false);
    }
  }

  function syncCaveators(partyReference: TenancyPartyModel) {
    const caveators = updatePartyReferences(values.caveators, partyReference)!;

    if (caveators !== values.caveators) {
      setFieldValue(fieldName('caveators'), caveators, false);
    }
  }
}

export default React.memo(RootForm);
