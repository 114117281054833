import * as React from 'react';
import { useCallback } from 'react';

import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import Weekdays from 'src/containers/dashboard/reports/components/weekdays';
import { WeekdayEnum } from 'src/containers/dashboard/reports/components/weekdays/weekday/models';
import {
  DAILY_INTERVAL_OPTIONS,
  DAY_OF_MONTH_OPTIONS,
  FREQUENCY_OPTIONS,
  GENERATION_TIME_OPTIONS,
  getTextForInterval,
  MONTHLY_INTERVAL_OPTIONS,
  ScheduledFrequencyEnum,
  SchedulerFormikModel,
  WeekdayFullNameMap
} from 'src/containers/dashboard/reports/models';
import { modelKey } from 'src/utils/formUtils';
import { useStyles } from './styles';

const fieldName = modelKey<SchedulerFormikModel>();
interface Props {
  userEmail: string;
  disabled?: boolean;
}

function ReportSchedulerFrequency({ userEmail, disabled }: Props): JSX.Element {
  const classes = useStyles();
  const { values, setFieldValue, setValues } = useFormikContext<SchedulerFormikModel>();
  const { scheduledFrequency, reportSendTime, interval, daysOfWeek, dayOfMonth } = values;

  const isDailyView = scheduledFrequency === ScheduledFrequencyEnum.Daily;
  const isWeeklyView = scheduledFrequency === ScheduledFrequencyEnum.Weekly;
  const isMonthlyView = scheduledFrequency === ScheduledFrequencyEnum.Monthly;

  const handleOnFrequencyChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, resolvedValue?: ScheduledFrequencyEnum) => {
      if (resolvedValue == null) {
        setValues(values => {
          return {
            //
            ...values,
            interval: undefined,
            daysOfWeek: []
          };
        });
      }
    },
    [setValues]
  );

  const frequencyView = () => {
    if (!Number.isInteger(scheduledFrequency)) {
      return null;
    }

    if (isDailyView) {
      return (
        <FlexLayout>
          <Field name={fieldName('interval')} disabled={disabled} component={SelectField} label="Every" options={DAILY_INTERVAL_OPTIONS} className={classes.interval} />
          <Typography className={classes.intervalText}>{getTextForInterval(scheduledFrequency)}</Typography>
        </FlexLayout>
      );
    }

    if (isWeeklyView) {
      return (
        <FlexLayout className={classes.displayMessage} data-name="daysOfWeek">
          <Weekdays
            title={<span className={classes.dayOfWeekTitle}>Select day(s) of the week</span>}
            initialSelectedDays={daysOfWeek ?? []}
            getSelectedDays={(selectedDays: WeekdayEnum[]) => {
              setFieldValue(fieldName('daysOfWeek'), selectedDays);
            }}
          />
        </FlexLayout>
      );
    }

    if (isMonthlyView) {
      return (
        <FlexLayout>
          <Field name={fieldName('interval')} disabled={disabled} component={SelectField} label="Every" options={MONTHLY_INTERVAL_OPTIONS} className={classes.interval} />
          <Typography className={classes.intervalText}>{getTextForInterval(scheduledFrequency)}</Typography>
          <Field //
            name={fieldName('dayOfMonth')}
            component={SelectField}
            label="Report generated on"
            options={DAY_OF_MONTH_OPTIONS}
            placeholder="Please select"
            format="number"
            className={classes.dayOfMonth}
            disabled={disabled}
          />
        </FlexLayout>
      );
    }

    return null;
  };

  const displayMessage = () => {
    let frequency = '',
      showDetailMessage = true;

    if (!Number.isInteger(scheduledFrequency) || !reportSendTime) {
      showDetailMessage = false;
    }

    // daily-Report scheduled for every day at 10:30AM (AEST), sent to arthur.dent@companyname.com.au
    if (isDailyView) {
      if (interval) {
        frequency = interval === 1 ? 'every day' : `every ${interval} days`;
      } else {
        showDetailMessage = false;
      }
    }

    // weekly-Report scheduled for every Wednesday at 10:30AM (AEST), sent to arthur.dent@companyname.com.au
    if (isWeeklyView) {
      if (!daysOfWeek?.length) {
        showDetailMessage = false;
      } else {
        frequency = daysOfWeek.map(d => WeekdayFullNameMap[d]).join(', ');
      }
    }

    // monthly-Report scheduled for the first day of every month at 10:30AM (AEST) sent to arthur.dent@companyname.com.au
    if (isMonthlyView) {
      if (!Number.isInteger(dayOfMonth) || !interval) {
        showDetailMessage = false;
      } else {
        const monthlyOption = DAY_OF_MONTH_OPTIONS.find(d => d.id === dayOfMonth)!.name;
        frequency = interval === 1 ? `${monthlyOption} every month` : `${monthlyOption} every ${interval} months`;
      }
    }

    if (!showDetailMessage) {
      return (
        <Typography>
          <i>Please select report frequency and time.</i>
        </Typography>
      );
    } else {
      return (
        <Typography>
          Report scheduled for <b>{frequency}</b> at <b>{reportSendTime}</b> (AEST/AEDT), sent to {userEmail}
        </Typography>
      );
    }
  };

  return (
    <FlexLayout fullWidth flexDirection="column">
      <FlexLayout>
        <Field //
          name={fieldName('scheduledFrequency')}
          component={SelectField}
          label="Report frequency"
          options={FREQUENCY_OPTIONS}
          placeholder="Please select"
          onChange={handleOnFrequencyChange}
          format="number"
          className={classes.dateAndTime}
          disabled={disabled}
        />
        <Field //
          name={fieldName('reportSendTime')}
          component={SelectField}
          label="Time report generated"
          placeholder="Please select"
          options={GENERATION_TIME_OPTIONS}
          className={classes.dateAndTime}
          disabled={disabled}
        />
      </FlexLayout>

      {frequencyView()}
      {displayMessage()}
    </FlexLayout>
  );
}

export default React.memo(ReportSchedulerFrequency);
