import * as React from 'react';

import _isEqual from 'lodash-es/isEqual';
import { useDispatch } from 'react-redux';

import Logger from '@sympli/ui-logger';

import environments from 'src/@core/environments';
import { AppEnvironmentEnum } from 'src/@core/environments/models';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { useSafeDispatch } from 'src/hooks';
import FinancialWorkspacesContext from '../FinancialWorkspaces.context';
import { shouldUpdateTime } from '../helper';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from '../models';
import { useFinancialWorkspacesFeedV2 } from '../reducers/financialWorkspacesFeedV2';
import { resolveActionArgs } from './helper';
import { AggregatorsQueryParamsModel, FinancialDashboardAggregatorApiResponse } from './models';

interface AggregatorCardWrapperProps<T = number> {
  payloadResolver(assignmentType: AssignmentTypeEnum);
  children(props: { onActionClick(id: string): void; isLoading: boolean; content: T; isFetchingGridData: (id: string) => boolean }): JSX.Element;
  defaultContent?: T;
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(payload: AggregatorsQueryParamsModel): Promise<FinancialDashboardAggregatorApiResponse>;
  contentResolver?(response: FinancialDashboardAggregatorApiResponse): T;
  onActionClick(filters: FinancialWorkspacesV2ApiRequest, engagedTab: FinancialDashboardRouteTabsEnumV2, currentTabFilters: DashboardFilterModel): void;
  refetching?: boolean;
}

function AggregatorCardWrapper<T = number>(props: AggregatorCardWrapperProps<T>) {
  const dispatch = useSafeDispatch(useDispatch());
  const { query, status } = useFinancialWorkspacesFeedV2();
  const { refetching, payloadResolver, assignmentType, fetchAggregatorInfo, onActionClick, localStorageKey, children, defaultContent = 0 as T, contentResolver } = props;
  const [content, setContent] = React.useState<T>(defaultContent);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { currentUpdatedTime, resetCurrentUpdatedTime } = React.useContext(FinancialWorkspacesContext);

  const fetchData = React.useCallback(async () => {
    const payload = payloadResolver(assignmentType);
    try {
      const response = await fetchAggregatorInfo(payload);

      // update header message "updated on what time" when API call returns successfully
      const currentTime = new Date();
      if (shouldUpdateTime(new Date(), currentUpdatedTime)) {
        resetCurrentUpdatedTime(currentTime);
      }

      setContent(contentResolver ? contentResolver(response) : (response[0].count as T));
    } catch (error) {
      // parent is not handling the error, so it handle error by itself now
      if ([AppEnvironmentEnum.dev, AppEnvironmentEnum.sit, AppEnvironmentEnum.local, AppEnvironmentEnum.iwt].includes(environments.APP_ENV)) {
        dispatch(actionCreateGlobalErrorMessage(error));
      }
      const scope = Logger.scopeWithCustomAttributes({ payload, apiName: 'dashboards/aggregators' });
      Logger.captureException(error, scope);
    } finally {
      setIsLoading(false);
    }
  }, [assignmentType, contentResolver, currentUpdatedTime, dispatch, fetchAggregatorInfo, payloadResolver, resetCurrentUpdatedTime]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [assignmentType]); // only refetch aggregators when switching between assignment types

  React.useEffect(() => {
    if (refetching) {
      setIsLoading(true);
      fetchData();
    }
  }, [refetching]);

  const resolvedActionArgs = React.useCallback(
    (id: string) => {
      const { queries } = payloadResolver(assignmentType);
      return resolveActionArgs({
        id,
        queries,
        assignmentType,
        localStorageKey
      });
    },
    [assignmentType, localStorageKey, payloadResolver]
  );

  const isFetchingGridData = React.useCallback(
    (id: string) => {
      const [apiRequest] = resolvedActionArgs(id);

      return (status === 'pending' || status === 'refetching') && _isEqual(apiRequest, query);
    },
    [query, resolvedActionArgs, status]
  );

  const handleOnActionClick = (id: string) => {
    onActionClick.apply(null, resolvedActionArgs(id));
  };

  return children({ onActionClick: handleOnActionClick, isLoading, content, isFetchingGridData });
}

export default React.memo(AggregatorCardWrapper);
