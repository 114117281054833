import { HttpTypes } from '@sympli/api-gateway/types';

const Mortgage = 'Mortgage';
const Caveat = 'Caveat';
const NoticeOfStatutoryCharge = 'Notice of Statutory Charge';
const Covenant = 'Covenant';
const Encumbrance = 'Encumbrance';

// WEB-33433 special logic for the
export function HandleSpecialNoneExistDealingDocuments(jurisdiction: HttpTypes.JurisdictionsEnum, documentTypes: string[]): string[] {
  let specialDisplayDoc: string[] = [Mortgage, Caveat];

  if (jurisdiction === HttpTypes.JurisdictionsEnum.VIC) {
    specialDisplayDoc.push(NoticeOfStatutoryCharge);
    specialDisplayDoc.push(Covenant);
  }
  if (jurisdiction === HttpTypes.JurisdictionsEnum.SA) {
    specialDisplayDoc.push(Encumbrance);
  }

  documentTypes.forEach(key => {
    if (key.toLowerCase() === Mortgage.toLowerCase()) {
      specialDisplayDoc = specialDisplayDoc.filter(d => d !== Mortgage);
    }

    if (key.toLowerCase() === Caveat.toLowerCase()) {
      specialDisplayDoc = specialDisplayDoc.filter(d => d !== Caveat);
    }

    if (jurisdiction === HttpTypes.JurisdictionsEnum.VIC) {
      if (key.toLowerCase() === NoticeOfStatutoryCharge.toLowerCase()) {
        specialDisplayDoc = specialDisplayDoc.filter(d => d !== NoticeOfStatutoryCharge);
      }
      if (key.toLowerCase() === Covenant.toLowerCase()) {
        specialDisplayDoc = specialDisplayDoc.filter(d => d !== Covenant);
      }
    }

    if (jurisdiction === HttpTypes.JurisdictionsEnum.SA) {
      if (key.toLowerCase() === Encumbrance.toLowerCase()) {
        specialDisplayDoc = specialDisplayDoc.filter(d => d !== Encumbrance);
      }
    }
  });

  return specialDisplayDoc;
}
