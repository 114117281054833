import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchUserReportsPermissions } from '../actions/users';

export interface UserReportsPermissionsState {
  detail?: HttpTypes.SettingsUserReportPermissionsApiResponse;
  status: ApiStatus;
  error?: string;
  args?: {
    userId: string;
  };
}

const initialState: UserReportsPermissionsState = {
  detail: undefined,
  status: 'idle',
  error: undefined,
  args: undefined
};

export function useUserReportsPermissions(userId: string) {
  const state = useStoreSelector(store => store.userReportsPermissions);
  // here we make sure that we will never return stale data that does not belong to us
  if (state.args?.userId === userId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  UserReportsPermissionsState,
  Action
>(initialState)
  .handleAction(actionFetchUserReportsPermissions.request, (state, action): UserReportsPermissionsState => {
    return {
      ...state,
      error: undefined,
      status: 'pending',
      args: { userId: action.payload }
    };
  })
  .handleAction(actionFetchUserReportsPermissions.success, (state, action): UserReportsPermissionsState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchUserReportsPermissions.failure, (state, action): UserReportsPermissionsState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
