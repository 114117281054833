import React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import Formik from 'src/components/formik';
import VerticalStatusLine, { VerticalStatusLineProps } from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { createModelKeyAppender, modelKey } from 'src/utils/formUtils';
import CertificationStatements from './components/certification-statements';
import CheckboxLabel from './components/checkbox-label';
import HeadingToolbar from './components/heading-toolbar';
import LandRegistryNotifications from './components/land-registry-notifications';
import LineItemsGrid from './components/line-items-grid';
import MergedDocumentsPdf from './components/merged-documents-pdf';
import SignError from './components/sign-error/SignError';
import SignWarning from './components/sign-warning';
import { getGridModel } from './helpers';
import {
  DocumentModel,
  FinancialLineItem,
  FinancialModel,
  LodgementCaseDetailsModel,
  SignDocumentFormItemMLC,
  SignDocumentsFormModel,
  SignDocumentsFormModelMLC,
  SignDocumentsViewMode,
  SignDocumentTypeEnum
} from './models';
import { useStyles } from './styles';
import validationSchema from './validationSchema';

export interface Props {
  // route params
  workspaceId: string;
  participantId: string;
  // signing data
  //signDocuments: SignDocument[];
  mergedPdfUrl?: string;
  isLoading: boolean;
  disabled?: boolean;
  lineItems: Map<SignDocumentTypeEnum, FinancialLineItem[]>;
  // wizard handlers
  onSubmit: (values: SignDocumentsFormModel) => void;
  onBack: () => void;
  // compliance issues handlers
  onShowVerificationResultsClick(): void;
  // other
  children: (formikProps: FormikProps<SignDocumentsFormModelMLC>) => React.ReactNode;

  lodgementCaseDetails: LodgementCaseDetailsModel[];
  financialDetails: FinancialModel[];
}

const fieldName = modelKey<SignDocumentsFormModel>();

function SignMLCDocuments({
  // route params
  workspaceId,
  participantId,
  // signing data
  //signDocuments,
  mergedPdfUrl,
  isLoading,
  disabled,
  lineItems,
  // wizard handlers
  onSubmit,
  onBack,
  // compliance issues handlers
  onShowVerificationResultsClick,
  // other
  children,

  lodgementCaseDetails,
  financialDetails
}: Props) {
  const classes = useStyles();
  const [viewMode, setViewMode] = React.useState<SignDocumentsViewMode>('documentList');

  const initialValues: SignDocumentsFormModelMLC = React.useMemo<SignDocumentsFormModelMLC>(
    () => ({
      documents: [
        ...lodgementCaseDetails
          .flatMap(d => d.documents)
          .map(doc => ({
            id: doc.id,
            type: SignDocumentTypeEnum.Documents,
            signed: doc.signed,
            toBeSignedByOtherSigners: doc.toBeSignedByOtherSigners,
            disabled: doc.disabled,
            isSelected: false
          })),
        ...financialDetails.map(doc => ({
          id: doc.id,
          type: doc.type,
          signed: doc.signed,
          disabled: doc.disabled,
          toBeSignedByOtherSigners: doc.toBeSignedByOtherSigners,
          isSelected:
            !doc.disabled &&
            (doc.documentStatus === HttpTypes.DocumentStatusEnum.Approved || doc.documentStatus === HttpTypes.DocumentStatusEnum.Signing) &&
            lineItems.get(doc.type)?.length === 0
        }))
      ]
    }),
    [lineItems, lodgementCaseDetails, financialDetails]
  );

  return (
    <Formik //
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps: FormikProps<SignDocumentsFormModelMLC>) => {
        const {
          values: { documents }
        } = formikProps;

        const enabledDocs = documents.filter(d => !d.disabled);
        const selectAllChecked = enabledDocs.length > 0 ? enabledDocs.every(d => d.isSelected) : false;
        return viewMode === 'mergedPdf' ? (
          <MergedDocumentsPdf
            pdfUrl={mergedPdfUrl}
            onBackClick={() => {
              setViewMode('documentList');
            }}
          />
        ) : (
          <WorkspacePageContentWrapper>
            <HeadingToolbar
              disabled={isLoading || disabled}
              onClick={() => {
                setViewMode('mergedPdf');
              }}
              mode="mergedPdf"
            />

            <div className={classes.contentRootMLC}>
              <div>
                <Checkbox
                  className={classes.checkboxLabelTealMLC}
                  name={fieldName('documents')}
                  label={'Select all'}
                  disabled={disabled || documents.every(d => d.disabled)}
                  onChange={e => {
                    const { checked } = e.target;
                    formikProps.setFieldValue(
                      fieldName('documents'),
                      documents.map(doc => ({
                        ...doc,
                        isSelected: doc.disabled ? false : checked
                      }))
                    );
                  }}
                  checked={selectAllChecked}
                />
              </div>

              {/* lodgement case level document display*/}
              {lodgementCaseDetails.map((lodgementCaseDetail: LodgementCaseDetailsModel) => {
                if (!lodgementCaseDetail.documents.length) {
                  return null;
                }
                return (
                  <div key={lodgementCaseDetail.lodgementCase.id} className="mt-[48px]">
                    {lodgementCaseDetails.length > 1 && (
                      <FlexLayout
                        justifyContent="space-between"
                        alignItems="center"
                        className="w-full border-0 border-b-[1px] border-solid border-[var(--greek-waters)] pb-[4px] pt-[2px]"
                      >
                        <Typography className="pb-[10px] font-volkswagen-serial-medium text-[18px] font-[500] leading-[12px] text-[var(--sympli-green)]">
                          {lodgementCaseDetail.lodgementCase.name}
                        </Typography>
                      </FlexLayout>
                    )}
                    {/* render documents */}
                    {lodgementCaseDetail.documents.map((document: DocumentModel, i: number) => {
                      const checkboxName = createModelKeyAppender<SignDocumentFormItemMLC>(`${fieldName('documents')}[${documents.findIndex(d => d.id === document.id)}]`)(
                        'isSelected'
                      );
                      const { text, variant } = resolveDocumentStatus(SignDocumentTypeEnum.Documents, document.documentStatus, undefined, document.lodgementDetail);
                      return (
                        <div key={document.id} className={classNames(classes.checkboxContainer, document.disabled && classes.opacityBox)}>
                          <Field //
                            vertical
                            label={
                              <CheckboxLabel
                                isLoading={isLoading}
                                attachments={document.attachments}
                                workspaceId={workspaceId}
                                participantId={participantId}
                                documentId={document.id}
                              >
                                {document.name}
                              </CheckboxLabel>
                            }
                            aria-describedby={`statement-${i} sign-warning-${i} land-registry-warning-${i}`}
                            name={checkboxName}
                            disabled={disabled || document.disabled}
                            classes={{
                              root: classes.checkboxRoot
                            }}
                            component={CheckboxField}
                            className={disabled || document.disabled ? classes.checkboxMLC : classes.checkboxLabelTealMLC}
                          />
                          {/* <label className={classes.documentLabel}>{document.name}</label> */}
                          <Box display="flex" flexDirection="column" className={classes.documentStatus}>
                            <VerticalStatusLine //
                              text={text}
                              variant={variant}
                              className="h-[14px]"
                              textClassName="truncated"
                            />
                          </Box>
                          <SignError id={`sign-error-${i}`} errorMessages={document.verificationErrors} />
                          <SignWarning id={`sign-warning-${i}`} warningMessage={document.signingWarningMessage} />
                          <LandRegistryNotifications
                            id={`land-registry-warning-${i}`} //
                            documentLodgementCompliances={document.lodgementDetail?.documentLodgementCompliances}
                            resolveIssuesLink={document.resolveIssuesLink}
                            onShowVerificationResultsClick={onShowVerificationResultsClick}
                          />
                          {document.documentStatus !== HttpTypes.DocumentStatusEnum.Lodged && (
                            <CertificationStatements
                              id={`statement-${i}`}
                              certificationStatements={document.certificationStatements}
                              acknowledgementStatements={document.acknowledgementStatements}
                              signDocumentType={SignDocumentTypeEnum.Documents}
                            />
                          )}
                          {i < lodgementCaseDetails.length ? <Divider className={classes.divider} /> : null}
                        </div>
                      );
                    })}
                    {/* render fee breakdown */}
                    {lodgementCaseDetail.lodgementCaseFees && (
                      <div className={classNames(lodgementCaseDetail.documents.every(x => x.disabled) && classes.opacityFeeBreakdown)}>
                        <LineItemsGrid
                          gridModel={getGridModel(
                            lodgementCaseDetail.lodgementCaseFees.feeItems.map(x => ({
                              participantId: '',
                              description: x.detail,
                              type: x.type,
                              addedBy: x.addedBy,
                              amount: x.amount
                            })),
                            SignDocumentTypeEnum.SLCFeeBreakdown
                          )}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

              <Divider className="ml-[-30px] mt-[48px] border-[var(--neutral-1000)]" />

              {/* financial display*/}
              <div className="mb-[48px] mt-[48px]">
                {financialDetails.map((financial: FinancialModel, i: number) => {
                  const checkboxName = createModelKeyAppender<SignDocumentFormItemMLC>(`${fieldName('documents')}[${documents.findIndex(d => d.id === financial.id)}]`)(
                    'isSelected'
                  );
                  const { text, variant } = resolveDocumentStatus(financial.type, financial.documentStatus, financial.isOptedOut);

                  return (
                    <div key={i} className={classNames(classes.checkboxContainer, financial.disabled && classes.opacityBox)}>
                      {financial.isOptedOut ? (
                        <label className={classes.documentLabelMLC}>{financial.name}</label>
                      ) : (
                        <Field //
                          vertical
                          label={
                            <CheckboxLabel isLoading={isLoading} workspaceId={workspaceId} participantId={participantId} documentId={financial.id}>
                              {financial.name}
                            </CheckboxLabel>
                          }
                          aria-describedby={`statement-${i} sign-warning-${i} land-registry-warning-${i}`}
                          name={checkboxName}
                          disabled={disabled || financial.disabled}
                          classes={{
                            root: classes.checkboxRoot
                          }}
                          component={CheckboxField}
                          className={disabled || financial.disabled ? classes.checkboxMLC : classes.checkboxLabelTealMLC}
                        />
                      )}
                      <Box display="flex" flexDirection="column" className={classes.documentStatus}>
                        <VerticalStatusLine //
                          text={financial.documentDescription ?? text}
                          variant={variant}
                          className="h-[14px]"
                          textClassName="truncated"
                        />
                      </Box>
                      <SignError id={`sign-error-${i}`} errorMessages={financial.verificationErrors} />
                      <SignWarning id={`sign-warning-${i}`} warningMessage={financial.signingWarningMessage} />
                      <CertificationStatements
                        id={`statement-${i}`}
                        certificationStatements={financial.certificationStatements}
                        acknowledgementStatements={financial.acknowledgementStatements}
                        signDocumentType={financial.type}
                        totalAmount={financial.totalAmount}
                        accountDescription={financial.accountDescription}
                      />

                      {!isLoading && lineItems.has(financial.type) && (
                        <LineItemsGrid isOptedOut={financial.isOptedOut} gridModel={getGridModel(lineItems.get(financial.type)!, financial.type)} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <WizardStepper className={classes.wizardStepper} backLabel="Cancel" showNext={false} onBack={onBack}>
              {children(formikProps)}
            </WizardStepper>
          </WorkspacePageContentWrapper>
        );
      }}
    </Formik>
  );
}

function resolveDocumentStatus(
  documentType: SignDocumentTypeEnum,
  documentStatus: HttpTypes.DocumentStatusEnum,
  isOptedOut?: boolean,
  lodgementDetail?: HttpTypes.DocumentLodgementDetail
): { text: string; variant: VerticalStatusLineProps['variant'] } {
  let variant: VerticalStatusLineProps['variant'] = 'warning';
  let text = 'IN PREPARATION';

  if (
    [
      SignDocumentTypeEnum.Documents,
      SignDocumentTypeEnum.FinancialSettlementSchedule,
      SignDocumentTypeEnum.TrustAccountAuthorisationRecord,
      SignDocumentTypeEnum.Payments,
      SignDocumentTypeEnum.SourceFunds
    ].includes(documentType)
  ) {
    if (isOptedOut) {
      text = 'N/A';
      return {
        text: 'N/A',
        variant: 'neutral'
      };
    }
    switch (documentStatus) {
      case HttpTypes.DocumentStatusEnum.ResolveIssues:
        return {
          text,
          variant: 'error'
        };
      case HttpTypes.DocumentStatusEnum.Approved:
      case HttpTypes.DocumentStatusEnum.Signing:
        return {
          text: 'APPROVED',
          variant
        };
      case HttpTypes.DocumentStatusEnum.Signed:
        return {
          text: 'SIGNED',
          variant: 'ok'
        };
      case HttpTypes.DocumentStatusEnum.Lodged:
        if (lodgementDetail?.status && lodgementDetail?.lrDocumentId) {
          return {
            text: `${lodgementDetail.status.name}, DEALING: ${lodgementDetail.lrDocumentId}`,
            variant: 'ok'
          };
        }
        return {
          text: 'LODGED',
          variant: 'ok'
        };
    }
  }

  return {
    text,
    variant
  };
}

export default SignMLCDocuments;
