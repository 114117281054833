import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache

/**
 path:
 - setOfOperativeWords

 schema version:
[{"id":0,"value":"FullyWithdraws","name":"The caveator fully withdraws the caveat","_meta":null},{"id":1,"value":"WithdrawsAsToTheLand","name":"The caveator fully withdraws the caveat as regards the whole of the land","_meta":null}]

 hash:
 WzAsMV0=
*/
export enum SetOfOperativeWordsEnum {
  FullyWithdraws = 0,
  WithdrawsAsToTheLand = 1
}
export const SET_OF_OPERATIVE_WORDS_LOOKUP_OPTIONS: LookupEnumModel<number>[] = [
  {
    id: SetOfOperativeWordsEnum.FullyWithdraws,
    name: 'The caveator fully withdraws the caveat'
  },
  {
    id: SetOfOperativeWordsEnum.WithdrawsAsToTheLand,
    name: 'The caveator fully withdraws the caveat as regards the whole of the land'
  }
];
