import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import { NavigateFunction } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { PortalSource, PortalTarget } from '@sympli/ui-framework/components/portal';

import Box from 'src/@core/components/layout/box';
import Main from 'src/@core/components/layout/main';
import Header from 'src/@core/components/typography/header';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { Subheader } from 'src/components/layout';
import { getWorkspaceSettlementRebook } from 'src/containers/shared/helpers';
import ParticipantList from 'src/containers/shared/participant-list';
import ProposalDateBoxContainer101 from 'src/containers/shared/settlement-date-box/ProposalDateBoxContainer';
import { WorkspaceDetailRouteLocationState } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { DATE_DISPLAY_FORMAT, dateTimeLine } from 'src/utils/formatters';
import SettlementDateEditForm from './components/settlement-date-edit-form';
import { PORTAL_ID_FOR_SETTLEMENT_DATE_EDIT_TOP_PANEL } from './components/settlement-date-edit-form/rendered-edit-form';
import SettlementDateReviewFormContainer from './components/settlement-date-review-form/SettlementDateReviewFormContainer';
import { RescindApprovalModel } from './models';
import { SettlementDateDetailsModel, SettlementDateDetailsState } from './reducers/settlementDetail';
import styles, { ClassKeys } from './styles';

import type { SafeDispatch } from 'src/hooks';

export const PORTAL_ID_FOR_SETTLEMENT_DATES = _uniqueId('portal-settlementDates');
export const PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS = _uniqueId('portal-settlementParticipants');

interface OwnProps {
  // route params
  workspaceId: string;
  participantId: string;
  navigate: NavigateFunction;
  // redux
  // workspace basic info
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  workspaceSettlementDate?: HttpTypes.WorkspaceDateTimeModel;
  expectedSettlementDate?: HttpTypes.ExpectedSettlementDate;
  workspaceType?: HttpTypes.WorkspaceTypeEnum;
  isRollover?: boolean;
  // based on workspace participants
  availableParticipants: HttpTypes.WorkspaceParticipant[];
  proposedParticipant?: HttpTypes.WorkspaceParticipant;
  // based on settlement date details
  settlementAcceptedByParticipants?: string[];
  detail: SettlementDateDetailsModel;
  settlementDetailsState: SettlementDateDetailsState;
  // profile
  subscriberId: string;
  // other
  isLoadingWorkspace: boolean;
  isLoadingSettlementDateDetails: boolean;
  isLoadingWorkspaceParticipants: boolean;
  declined: boolean;
  rescindApprovalData: RescindApprovalModel;
  dispatch: SafeDispatch<Action> | Dispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  upSettlementAcceptedByAll?: boolean;
  upSettlementDate?: string;
  upSettlementAcceptedByParticipants?: Array<string>;
  isChanged: boolean;
}

class SettlementDate extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    upSettlementAcceptedByAll: undefined,
    upSettlementDate: undefined,
    upSettlementAcceptedByParticipants: undefined,
    isChanged: false
  };

  get isRebook() {
    const { workspaceStatusId, workspaceSettlementDate } = this.props;
    if (!Number.isInteger(workspaceStatusId)) {
      return false;
    }
    return getWorkspaceSettlementRebook(workspaceSettlementDate, workspaceStatusId);
  }

  render() {
    return (
      <FlexLayout flexDirection="column" fullWidth>
        <div className={this.props.classes.stickyTopPanel}>
          <PortalTarget id={PORTAL_ID_FOR_SETTLEMENT_DATE_EDIT_TOP_PANEL}></PortalTarget>
        </div>
        <Main>
          {this.renderHeader()}
          {this.renderContent()}
        </Main>
      </FlexLayout>
    );
  }

  private renderContent() {
    const {
      //
      classes,
      isLoadingWorkspace,
      isLoadingSettlementDateDetails,
      workspaceStatusId,
      workspaceSettlementDate,
      availableParticipants,
      detail: {
        //
        localWorkspaceSettlementDate: currentSettlementDate,
        localProposedSettlementDate
      },
      settlementDetailsState,
      participantId,
      isRollover
    } = this.props;

    if (isLoadingWorkspace || isLoadingSettlementDateDetails) {
      return (
        <div className={classes.loader}>
          <BlockLoader />
        </div>
      );
    }

    const { upSettlementDate } = this.state;

    let proposedDate: string | undefined;
    if (upSettlementDate) {
      proposedDate = upSettlementDate;
    } else if (localProposedSettlementDate !== currentSettlementDate) {
      proposedDate = localProposedSettlementDate;
    }

    // inject updated settlement info to left panel
    return (
      <>
        <PortalSource id={PORTAL_ID_FOR_SETTLEMENT_DATES}>
          <ProposalDateBoxContainer101
            workspaceStatusId={workspaceStatusId}
            workspaceParticipants={availableParticipants}
            settlementDateTime={workspaceSettlementDate!}
            settlementDetailsState={settlementDetailsState}
            proposedSettlementDate={proposedDate}
            participantId={participantId}
            isRollover={isRollover}
          />
        </PortalSource>
        <PortalSource id={PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS}>
          {
            //
            this.renderParticipants()
          }
        </PortalSource>
        {this.renderViews()}
      </>
    );
  }

  private renderHeader() {
    const {
      detail: { isEditing, workspaceSettlementDate },
      declined
    } = this.props;

    let headingText = `${this.isRebook ? 'Rebook' : 'Review'} Settlement Date and Time`;

    if (isEditing || declined || !workspaceSettlementDate) {
      headingText = `${this.isRebook ? 'Rebook' : 'Edit'} Settlement Date and Time`;
    }
    return <Header>{headingText}</Header>;
  }

  private renderParticipants() {
    const {
      //
      workspaceId,
      participantId,
      availableParticipants,
      settlementAcceptedByParticipants,
      subscriberId,
      isLoadingWorkspaceParticipants,
      isLoadingSettlementDateDetails
    } = this.props;
    const isLoading = isLoadingSettlementDateDetails || isLoadingWorkspaceParticipants;
    const acceptedByParticipants = this.state.upSettlementAcceptedByParticipants || settlementAcceptedByParticipants;

    return (
      <Box title="Participants" disabled={isLoading}>
        <ParticipantList
          // route params
          workspaceId={workspaceId}
          participantId={participantId}
          // profile
          subscriberId={subscriberId}
          // participants
          items={availableParticipants}
          // list of participants that accepted workspace settlement data
          settlementAcceptedByParticipants={acceptedByParticipants}
          // other
          isLoading={isLoading}
        />
      </Box>
    );
  }

  private resolveSettlementDateAcceptedByAll() {
    const { availableParticipants, settlementAcceptedByParticipants } = this.props;
    if (settlementAcceptedByParticipants == null) {
      return true;
    }
    return availableParticipants.length === settlementAcceptedByParticipants.length;
  }

  private renderViews = () => {
    const {
      workspaceId,
      participantId,
      jurisdictionId,
      proposedParticipant,
      availableParticipants,
      declined,
      rescindApprovalData,
      dispatch,
      workspaceSettlementDate,
      expectedSettlementDate,
      detail: {
        localWorkspaceSettlementDate: currentSettlementDate,
        localProposedSettlementDate, // this is from redux
        proposedSettlementDate,
        isEditing,
        isUnsupported,
        isAcceptedByUser,
        settlementDateProposalId,
        reasonText,
        minReschedulingInHours
      },
      isRollover
    } = this.props;
    const reason = this.state.isChanged ? '' : reasonText;
    const settlementAcceptedByAll = this.resolveSettlementDateAcceptedByAll();
    const numberOfParticipants = availableParticipants.length;

    if (isEditing || isUnsupported || declined || workspaceSettlementDate === undefined) {
      return (
        <React.Fragment>
          <Subheader variant="subtitle1">Select a date and time:</Subheader>
          <SettlementDateEditForm
            workspaceId={workspaceId}
            participantId={participantId}
            jurisdictionId={jurisdictionId!}
            reasonText={reason}
            proposedSettlementDate={localProposedSettlementDate}
            workspaceSettlementDate={currentSettlementDate}
            expectedSettlementDate={expectedSettlementDate}
            onSettlementDateChange={this.handleOnSettlementDateChange}
            onRedirect={this.handleOnRedirect}
            minReschedulingInHours={minReschedulingInHours}
            settlementAcceptedByAll={settlementAcceptedByAll}
            numberOfParticipants={numberOfParticipants}
            declined={declined}
            isRollover={isRollover}
            rescindApprovalData={rescindApprovalData}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Subheader variant="subtitle1">A participant has proposed this settlement date and time:</Subheader>
        <SettlementDateReviewFormContainer
          isAcceptedByUser={isAcceptedByUser}
          workspaceId={workspaceId}
          participantId={participantId}
          jurisdictionId={jurisdictionId!}
          reasonText={reason}
          proposedSettlementDate={proposedSettlementDate}
          localProposedSettlementDate={localProposedSettlementDate}
          workspaceSettlementDate={currentSettlementDate}
          settlementDateProposalId={settlementDateProposalId}
          settlementAcceptedByAll={settlementAcceptedByAll}
          dispatch={dispatch}
          proposedByParticipant={proposedParticipant}
          onRedirect={this.handleOnRedirect}
        />
      </React.Fragment>
    );
  };

  private handleOnSettlementDateChange = (userProposedSettlementDate: string) => {
    const {
      participantId,
      settlementAcceptedByParticipants,
      detail: {
        //
        localWorkspaceSettlementDate: currentSettlementDate,
        localProposedSettlementDate
      }
    } = this.props;
    let upSettlementAcceptedByAll = this.resolveSettlementDateAcceptedByAll();
    let upSettlementDate: string | undefined = userProposedSettlementDate;
    let upSettlementAcceptedByParticipants = settlementAcceptedByParticipants;

    // if there was already proposed settlement date, we will use it
    const isChanged = this.hasSettlementDateChanged(localProposedSettlementDate || currentSettlementDate, userProposedSettlementDate);
    this.setState({
      isChanged
    });
    if (isChanged) {
      upSettlementAcceptedByAll = false;
      upSettlementAcceptedByParticipants = [participantId];
      upSettlementDate = userProposedSettlementDate;
    } else {
      upSettlementDate = undefined;
    }

    this.setState({ upSettlementAcceptedByAll, upSettlementAcceptedByParticipants, upSettlementDate });
  };

  private hasSettlementDateChanged(origDate: string = '', newDate: string = '') {
    if (newDate) {
      if (!origDate) {
        return true;
      }
      return dateTimeLine(origDate, DATE_DISPLAY_FORMAT) !== dateTimeLine(newDate, DATE_DISPLAY_FORMAT);
    }

    return false;
  }

  private handleOnRedirect = (message: string, secondaryMessage?: string) => {
    const { workspaceId, participantId, navigate } = this.props;
    const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
    const state: WorkspaceDetailRouteLocationState = {
      message,
      secondaryMessage
    };
    navigate(link, { state });
  };
}

const styledComponent = withStyles(styles)(SettlementDate);
export default styledComponent;
