import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchPreferences } from '../actions/preferences';

export interface PreferencesState {
  detail?: HttpTypes.SettingsSubscriberPreferencesApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: PreferencesState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  PreferencesState,
  Action
>(initialState)
  .handleAction(actionFetchPreferences.request, (state): PreferencesState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchPreferences.success, (state, action): PreferencesState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchPreferences.failure, (state, action): PreferencesState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
