import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { DashboardInvitationStatusFilterEnum, InvitationArchivedTypeEnum } from '../table/models';

export enum FilterEnum {
  ReceivedDate = 0,
  SentDate = 1,
  FromSubscriber = 2,
  ToSubscriber = 3,
  Jurisdiction = 4,
  MyRole = 5,
  ProposedSettlementDate = 6,
  ArchivedType = 7,
  InvitationStatus = 8,
  Groups = 9,
  Members = 10,
  FromToSubscriber = 11,
  ArchivedDate = 12,
  TransactionType = 13,
  SympliId = 14
}

export const FilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyRole,
    name: 'Role'
  },
  {
    id: FilterEnum.ProposedSettlementDate,
    name: 'Proposed Settlement Date'
  },
  {
    id: FilterEnum.SympliId,
    name: 'Sympli ID'
  }
];

export const ArchivedFilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.ArchivedDate,
    name: 'Archived Date'
  },
  {
    id: FilterEnum.FromToSubscriber,
    name: 'From/To Subscriber'
  },
  {
    id: FilterEnum.ArchivedType,
    name: 'Invitation Type'
  },
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyRole,
    name: 'Role'
  },
  {
    id: FilterEnum.InvitationStatus,
    name: 'Invitation Status'
  },
  {
    id: FilterEnum.SympliId,
    name: 'Sympli ID'
  }
];

export const ArchivedTypeOptions: LookupItemModel<number>[] = [
  {
    id: InvitationArchivedTypeEnum.Received,
    name: 'Received'
  },
  {
    id: InvitationArchivedTypeEnum.Sent,
    name: 'Sent'
  }
];

export const InvitationStatusOptions: LookupItemModel<number>[] = [
  {
    id: DashboardInvitationStatusFilterEnum.Accepted,
    name: 'Accepted'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Declined,
    name: 'Declined'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Forwarded,
    name: 'Forwarded'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Withdrawn,
    name: 'Withdrawn'
  }
];

export const TransactionTypeOptions: LookupItemModel<number>[] = [
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Refinance,
    name: 'Refinance'
  },
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Transfer,
    name: 'Transfer'
  },
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Other,
    name: 'Other'
  }
];
