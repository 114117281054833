import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { LogModel } from '../log/models';

export type SubscriberActivityLogsApiRequest = {
  pageSize: number; // defines number of rows shown per page.
  pageNumber: number; // current page number
};

export const actionFetchSubscriberActivityLogsFeed = createAsyncAction(
  //
  'FETCH_SUBSCRIBER_ACTIVITY_LOGS_FEED',
  'FETCH_SUBSCRIBER_ACTIVITY_LOGS_FEED_SUCCESS',
  'FETCH_SUBSCRIBER_ACTIVITY_LOGS_FEED_ERROR'
)<
  //
  SubscriberActivityLogsApiRequest,
  { data: HttpTypes.PaginatedItemsModel<LogModel> },
  { error: Error }
>();
