import * as React from 'react';

import { useFormikContext } from 'formik';

import MfeConsideration, { CONSIDERATION_TYPES_BY_JURISDICTION, ConsiderationTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { PartyCapacityEnum } from '@sympli-mfe/enums-2-24/qld';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { HttpTypes } from '@sympli/api-gateway/types';

import { ConsiderationProps } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/sections/consideration/models';
import { Transfer2_24_1Model } from '../../models';

function Consideration({ name }: ConsiderationProps): JSX.Element {
  const {
    values: { transferors }
  } = useFormikContext<Transfer2_24_1Model>();
  const noPersonalRepresentatives = transferors.proprietorGroups
    .flatMap(pg => pg.parties)
    .filter(p => p.isSelected)
    .every(p => p.partyCapacity.capacity !== PartyCapacityEnum.PersonalRepresentative);

  const options = restrictedLookupOptions(CONSIDERATION_TYPES_BY_JURISDICTION[HttpTypes.JurisdictionsEnum.QLD], {
    blacklist: noPersonalRepresentatives ? [ConsiderationTypeEnum.PursuantToTheRulesOfIntestacy, ConsiderationTypeEnum.PursuantToTheTermsOfAWill] : []
  });
  return <MfeConsideration name={name} jurisdiction={HttpTypes.JurisdictionsEnum.QLD} considerationTypeOptions={options} />;
}

export default React.memo(Consideration);
