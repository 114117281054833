import queryString from 'query-string';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { DocumentDetailDataModel, DocumentPageRouteParams, DocumentPrerequisite, DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { AcknowledgementErrorCodeEnum } from '../workspace/shared/models';
import { currentParticipantDocumentListSelector, readyToSignDocumentLodgementCompliancesSelector } from './selectors';
import { resolveWorkflowStep } from './steps';

export function resolveDocumentPermissions(permissions: Array<HttpTypes.DocumentPermissionEnum>): Partial<Record<HttpTypes.DocumentPermissionEnum, boolean>> {
  // create a map of allowed permissions
  // {[0]: true, [4]: true}
  const permissionTable = permissions.reduce((table, currentValue) => {
    table[currentValue] = true;
    return table;
  }, {});
  return permissionTable;
}

export interface DocumentDetailLinkModel {
  workspaceId: string;
  documentId: string;
  participantId: string;
  taskId?: string;
}

export function resolveDocumentDetailLink(params: DocumentDetailLinkModel) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  return `/workspace/${encodeURIComponent(workspaceId)}/documents?${query}`;
}

const documentUnfinishedStatusSet = new Set([HttpTypes.DocumentStatusEnum.InPreparation, HttpTypes.DocumentStatusEnum.Reviewing]);

export function getNextAvailableDocument(participantId: string, documentList: Array<HttpTypes.WorkspaceDocumentSummary>, documentId: string) {
  const currentParticipantDocumentList = currentParticipantDocumentListSelector({ participantId, documentList });
  const currentDocument = currentParticipantDocumentList.find(x => x.documentId === documentId);
  const documentsInCurrentLodgementCase = currentParticipantDocumentList.filter(x => x.lodgementCaseId === currentDocument?.lodgementCaseId);

  let nextDocument = documentsInCurrentLodgementCase.find(({ documentStatus, documentForm }) => {
    // The document is InPreparation (ready to edit) and Reviewing (ready to review)
    // Exclude lodgement instructions in first round
    return documentForm.documentType !== HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions && documentUnfinishedStatusSet.has(documentStatus.id);
  });
  if (nextDocument) {
    return nextDocument;
  }
  nextDocument = documentsInCurrentLodgementCase.find(({ documentStatus }) => {
    // Find again include lodgement instructions
    return documentUnfinishedStatusSet.has(documentStatus.id);
  });

  return nextDocument;
}

/*
    this is to check if a document is ready to start editing/approving (no need for signing, we have sign all options)
    a few edge cases:
    1: for shared document (one primary) like transfer, vendor will need to wait for purchaser to approved first
    2: for shared document (multiple primaries) like noa, both purchaser/vendor can starting editing/approving (no need to wait for other party)
*/
function isDocumentReadyForTaskLink(doc: HttpTypes.WorkspaceDocumentSummary, currentParticipantId: string) {
  // if current participant has edit permission (primary role), redirect it
  const primaryDocumentParticipants = doc.documentParticipants.filter(p => p.isPrimaryDocumentParticipant === true);
  const isCurrentParticipantHasPrimaryRole = primaryDocumentParticipants.find(d => d.id === currentParticipantId);
  if (isCurrentParticipantHasPrimaryRole) {
    return true;
  }

  // check if all other primary roles have approved the doc
  const reviewedParticipantIds = doc.documentActions.filter(d => d.type === HttpTypes.DocumentActionTypeEnum.Review).map(x => x.participantId);
  if (reviewedParticipantIds.length !== primaryDocumentParticipants.length) {
    return false;
  }

  return true;
}

export function resolveNextFinancialDocumentTaskLink(queryParams: DocumentPageRouteParams, documentList: Array<HttpTypes.WorkspaceDocumentSummary>, documentId: string) {
  const { participantId, workspaceId } = queryParams;

  const nextAvailableDocument = getNextAvailableDocument(participantId, documentList, documentId);

  if (nextAvailableDocument == null) {
    return undefined;
  }

  if (!isDocumentReadyForTaskLink(nextAvailableDocument, participantId)) {
    return undefined;
  }

  return resolveDocumentDetailLink({ participantId, workspaceId, documentId: nextAvailableDocument.documentId });
}

export function resolveDocumentErrorMessages(params: {
  showMerged: boolean;
  documentList: Array<HttpTypes.WorkspaceDocumentSummary>;
  participantId: string;
  lodgementDetail?: HttpTypes.DocumentLodgementDetail;
}): {
  documentErrorMessages?: HttpTypes.DocumentLodgementDetail['documentLodgementCompliances'];
  errorHeader: string;
  warningHeader: string;
} {
  const { showMerged, documentList, participantId, lodgementDetail } = params;
  if (showMerged) {
    return {
      //TODO FIX THIS OTHERWISE A BUG WILL BE RAISED
      documentErrorMessages: readyToSignDocumentLodgementCompliancesSelector({ participantId, documentList }),
      warningHeader: 'Warning: one or more of your documents has an issue. The issue will not prevent lodgement but may cause unintended consequences.',
      errorHeader: 'Error: Lodgement Verification Failed. We have found issues on one or more of these documents, preventing lodgement.'
    };
  }

  return {
    documentErrorMessages: lodgementDetail?.documentLodgementCompliances,
    errorHeader: "Lodgement verification failed. We've found issues in this document.",
    warningHeader: "We've found warnings in this document."
  };
}

// * To be able to action on the current step, user needs to have the permission for the current step
// * e.g. If user is on review step and wants to approve document, user needs to have DocumentPermissionEnum.Review permission
// * Otherwise, we need to disable the next action button
export const documentWorkflowStepPermissionMapper: Record<DocumentWorkflowStepsEnum, HttpTypes.DocumentPermissionEnum> = {
  [DocumentWorkflowStepsEnum.Read]: HttpTypes.DocumentPermissionEnum.Read,
  [DocumentWorkflowStepsEnum.Write]: HttpTypes.DocumentPermissionEnum.Write,
  [DocumentWorkflowStepsEnum.Sign]: HttpTypes.DocumentPermissionEnum.Sign,
  [DocumentWorkflowStepsEnum.Review]: HttpTypes.DocumentPermissionEnum.Review,
  [DocumentWorkflowStepsEnum.Unsign]: HttpTypes.DocumentPermissionEnum.Unsign,
  // * Resolve is a special step (alias for Write), UI use only
  [DocumentWorkflowStepsEnum.Resolve]: HttpTypes.DocumentPermissionEnum.Write,
  // * Lodged is a special step for readonly document (after document is lodged), UI use only
  [DocumentWorkflowStepsEnum.Lodged]: HttpTypes.DocumentPermissionEnum.Read
};

export const isActionPermitted = (currentStep: DocumentWorkflowStepsEnum, permissions?: HttpTypes.DocumentPermissionEnum[]) => {
  return permissions?.includes(documentWorkflowStepPermissionMapper[currentStep]) ?? false;
};

export const getLodgeOnlyWorkflowStep = (
  status: HttpTypes.DocumentStatusEnum,
  documentWorkflowType: HttpTypes.DocumentWorkflowTypeEnum,
  lodgementCaseStatus: HttpTypes.LodgementCaseStatusEnum
) => {
  const isLodged: boolean = [
    HttpTypes.LodgementCaseStatusEnum.LodgementSuccess,
    HttpTypes.LodgementCaseStatusEnum.Registered,
    HttpTypes.LodgementCaseStatusEnum.Rejected,
    HttpTypes.LodgementCaseStatusEnum.Withdrawn,
    HttpTypes.LodgementCaseStatusEnum.Unnecessary
  ].includes(lodgementCaseStatus);

  return isLodged ? DocumentWorkflowStepsEnum.Lodged : resolveWorkflowStep(status, documentWorkflowType);
};

export const getFinancialWorkflowStep = (
  status: HttpTypes.DocumentStatusEnum,
  documentWorkflowType: HttpTypes.DocumentWorkflowTypeEnum,
  lodgementCaseStatus: HttpTypes.LodgementCaseStatusEnum
): DocumentWorkflowStepsEnum | undefined => {
  const isLodged = lodgementCaseStatus === HttpTypes.LodgementCaseStatusEnum.LodgementSuccess || lodgementCaseStatus === HttpTypes.LodgementCaseStatusEnum.Registered;
  return isLodged ? DocumentWorkflowStepsEnum.Lodged : resolveWorkflowStep(status, documentWorkflowType);
};

export function getPurchaser(
  participants:
    | {
        id: string;
        name: string;
        workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
      }[]
    | undefined
): string | undefined {
  return participants?.find(p => p.workspaceRole.id === HttpTypes.WorkspaceRoleEnum.Purchaser)?.name;
}

export const getDocumentPrerequisite = (
  documentData: DocumentDetailDataModel<{
    [key: string]: any;
  }>
): DocumentPrerequisite | undefined => {
  const prerequisite: DocumentPrerequisite = documentData;

  return prerequisite.isPrerequisiteNotReady === true
    ? {
        isPrerequisiteNotReady: prerequisite.isPrerequisiteNotReady,
        prerequisiteError: prerequisite.prerequisiteError
      }
    : undefined;
};

export const getDocumentPrerequisiteError = (documentData: any): string | undefined => {
  const prerequisite = getDocumentPrerequisite(documentData);
  return prerequisite?.isPrerequisiteNotReady === true ? prerequisite.prerequisiteError : undefined;
};

export const getlodgementVerificationErrorMessage = (acknowledgementErrorCode: string): string => {
  let secondaryMessage = '';
  secondaryMessage =
    acknowledgementErrorCode === AcknowledgementErrorCodeEnum.ServiceNotavailableAtThisTime
      ? 'The verification of the Lodgement Case with the Land Registry was unsuccessful as the land registry is unavailable.'
      : 'The verification of the Lodgement Case with the Land Registry was unsuccessful.';

  return `${secondaryMessage} Please try again or call Sympli Customer Support for help.`;
};
