import { HttpTypes } from '@sympli/api-gateway/types';

import { LinkedLodgementWorkspacesState } from 'src/store/reducers/workspace/linkedLodgementDetail';
import { LinkedSettlementWorkspacesState } from 'src/store/reducers/workspace/linkedSettlementDetail';
import { LinkedWorkspaceTableModel } from './linked-workspace-table/models';

export function getLinkedWorkspaceTableRow(
  workspaceId: string,
  linkedWorkspaceType: HttpTypes.LinkedWorkspaceTypeEnum,
  linkedSettlementWorkspacesState: LinkedSettlementWorkspacesState,
  linkedLodgementWorkspacesState: LinkedLodgementWorkspacesState
): LinkedWorkspaceTableModel[] {
  const linkedWorkspaceDetails =
    linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement
      ? (linkedSettlementWorkspacesState?.detail?.linkedSettlementWorkspaceDetails as HttpTypes.LinkedWorkspaceDetail[])
      : linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement
        ? (linkedLodgementWorkspacesState?.detail?.linkedLodgementWorkspaceDetails as HttpTypes.LinkedWorkspaceDetail[])
        : [];

  const rowData =
    linkedWorkspaceDetails?.map(
      (d, index) =>
        ({
          //
          ...d,

          order: linkedWorkspaceType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement ? undefined : index + 1,
          isCurrentWorkspace: d.workspaceId === workspaceId,
          className: d.workspaceId === workspaceId ? 'bg-[var(--neutral-025)]' : undefined
        }) as LinkedWorkspaceTableModel
    ) ?? [];

  return rowData;
}
