import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchDocumentGroupFeed = createAsyncAction(
  //
  'FETCH_DOCUMENT_GROUP_FEED',
  'FETCH_DOCUMENT_GROUP_FEED_SUCCESS',
  'FETCH_DOCUMENT_GROUP_FEED_ERROR'
)<
  //
  void,
  { data: HttpTypes.JurisdictionsEnum[] },
  { error: Error }
>();

export interface AvailbleDocumentApiRequest {
  jurisdictionId: HttpTypes.JurisdictionsEnum;
}

export const actionFetchAvailableDocumentFeed = createAsyncAction(
  //
  'FETCH_AVAILABLE_DOCUMENT_FEED',
  'FETCH_AVAILABLE_DOCUMENT_FEED_SUCCESS',
  'FETCH_AVAILABLE_DOCUMENT_FEED_ERROR'
)<
  //
  AvailbleDocumentApiRequest,
  { data: HttpTypes.DocumentType[] },
  { error: Error }
>();

export interface DocumentDefaultFieldsApiRequest {
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  documentTypeId: HttpTypes.DocumentTypeIdentifierEnum;
}

export const actionFetchDocumentDefaultFields = createAsyncAction(
  //
  'FETCH_DOCUMENT_DEFAULT_FIELDS',
  'FETCH_DOCUMENT_DEFAULT_FIELDS_SUCCESS',
  'FETCH_DOCUMENT_DEFAULT_FIELDS_ERROR'
)<
  //
  DocumentDefaultFieldsApiRequest,
  { data: HttpTypes.DocumentFieldDetailApiResponse },
  { error: Error }
>();
