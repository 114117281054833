import { HttpTypes } from '@sympli/api-gateway/types';

import { ActiveWorkspaceItem, JoinWorkspaceEnum } from '../search-join-table/models';
import { JoinWorkspaceErrorEnum } from './api';

export const getJoinActiveFinancialWorkspaceActions = (
  item: ActiveWorkspaceItem,
  userPermissions: HttpTypes.UserProfileModel['userPermissions'],
  role: HttpTypes.WorkspaceRoleEnum,
  subscriberId: string
): ActiveWorkspaceItem => {
  const newItem: ActiveWorkspaceItem = { ...item };
  newItem.disabled = true;
  newItem.showIndicator = true;

  // user doesn't have manage workspace permission
  if (!userPermissions.includes(HttpTypes.UserLevelPermissionEnum.ManageInvitations)) {
    newItem.joinWorkspaceCondition = JoinWorkspaceEnum.NoWorkspaceManagePermission;
    return newItem satisfies ActiveWorkspaceItem;
  }

  // cannot join a standalone workspace
  if (item.workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly) {
    newItem.joinWorkspaceCondition = JoinWorkspaceEnum.StandaloneWorkspace;
    return newItem satisfies ActiveWorkspaceItem;
  }

  const cannotJoin = item.participants.some(p => {
    if (p.roleId === role) {
      // cannot join again if you are invited as same role
      if (p.subscriberId === subscriberId) {
        if (!p.hasAccepted) {
          // you haven't accepted invitation
          newItem.joinWorkspaceCondition = JoinWorkspaceEnum.OutstandingInvitation;
          return true;
        } else {
          // you have already accepted invitation
          newItem.joinWorkspaceCondition = JoinWorkspaceEnum.ParticipantRoleForbidden;
          return true;
        }
      }
    }

    switch (role) {
      case HttpTypes.WorkspaceRoleEnum.Purchaser:
        newItem.joinWorkspaceCondition = JoinWorkspaceEnum.ParticipantRoleForbidden;
        return [HttpTypes.WorkspaceRoleEnum.Purchaser, HttpTypes.WorkspaceRoleEnum.Beneficiary].includes(p.roleId);
      case HttpTypes.WorkspaceRoleEnum.Vendor:
        newItem.joinWorkspaceCondition = JoinWorkspaceEnum.ParticipantRoleForbidden;
        return [HttpTypes.WorkspaceRoleEnum.Vendor, HttpTypes.WorkspaceRoleEnum.Beneficiary].includes(p.roleId);
      case HttpTypes.WorkspaceRoleEnum.Beneficiary:
        newItem.joinWorkspaceCondition = JoinWorkspaceEnum.ParticipantRoleForbidden;
        return [HttpTypes.WorkspaceRoleEnum.Purchaser, HttpTypes.WorkspaceRoleEnum.Vendor, HttpTypes.WorkspaceRoleEnum.Beneficiary].includes(p.roleId);
      default:
        return false;
    }
  });

  if (!cannotJoin) {
    newItem.disabled = false;
    newItem.showIndicator = false;
    newItem.joinWorkspaceCondition = JoinWorkspaceEnum.CanJoin;
  }

  return newItem satisfies ActiveWorkspaceItem;
};

export const getJoinActiveFinancialWorkspaceFailureNotification = (validationErrorType: JoinWorkspaceErrorEnum): React.ReactNode => {
  let message: string = '';
  switch (validationErrorType) {
    case JoinWorkspaceErrorEnum.ActiveWorkspaceNotFound:
      message = 'The workspace is no longer active and cannot be joined.';
      break;
    case JoinWorkspaceErrorEnum.WorkspaceLocked:
      message = 'The workspace settlement has commenced and cannot be joined.';
      break;
    case JoinWorkspaceErrorEnum.RoleNotAllowedOnTitle:
      message = 'The workspace title does not have available interest for your role and cannot be joined.';
      break;
    case JoinWorkspaceErrorEnum.InvalidRoleSelected:
      message = 'Your participant role cannot be joined with the existing participant roles in the workspace.';
      break;
    case JoinWorkspaceErrorEnum.InvitationExistsForRole:
      message = 'There is an outstanding invitation for the same workspace and role.';
      break;
    default:
      message = 'Something went wrong.';
  }

  return (
    <>
      <b>Could not join workspace. </b>
      {message}
    </>
  );
};
