import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http, { sdk } from 'src/utils/http';
import {
  actionFetchAutoJustificationDetail,
  actionFetchChangeOfNameEvidence as actionFetchAutoJustificationEvidence,
  actionFetchAutoJustifications,
  AutoJustificationDetailRequest,
  AutoJustificationEvidenceRequest
} from '../actions/autoJustification';

const fetchAutoJustifications = (): Promise<HttpTypes.SubscriberAutoJustificationsApiResponse> => {
  // return http.get<SubscriberNameJustificationsApiResponse>(`/settings/auto-justifications`);
  return sdk.settings.autoJustification.list();
};

function* sagaFetchAutoJustifications(action: ReturnType<typeof actionFetchAutoJustifications.request>) {
  try {
    const data = yield* call(fetchAutoJustifications);
    yield put(actionFetchAutoJustifications.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustifications.failure({ error }));
  }
}
const fetchAutoJustificationDetail = (justificationId: AutoJustificationDetailRequest) =>
  Promise.all([
    justificationId !== 'new'
      ? //? http.get<HttpTypes.SubscriberAutoJustificationDetailApiResponse>(`/settings/auto-justification/${encodeURIComponent(justificationId)}/detail`)
        sdk.settings.autoJustification.retrieve(justificationId)
      : undefined,
    http.get<HttpTypes.SubscriberSupportedDocumentsApiResponse>(`/settings/auto-justification/supported-documents`),
    http.get<HttpTypes.SettingsSubscriberDetailsApiResponse>('/settings/subscriber/details')
  ]);

function* sagaFetchAutoJustificationDetail(action: ReturnType<typeof actionFetchAutoJustificationDetail.request>) {
  try {
    const data: [
      HttpTypes.SubscriberAutoJustificationDetailApiResponse | undefined,
      HttpTypes.SubscriberSupportedDocumentsApiResponse,
      HttpTypes.SettingsSubscriberDetailsApiResponse
    ] = yield* call(fetchAutoJustificationDetail, action.payload);
    yield put(actionFetchAutoJustificationDetail.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustificationDetail.failure({ error }));
  }
}

const fetchAutoJustificationEvidence = (justificationId: AutoJustificationEvidenceRequest) =>
  Promise.all([
    http.get<HttpTypes.SubscriberNameJustificationEvidenceApiResponse>(`/settings/auto-justification/${encodeURIComponent(justificationId)}/evidences`),
    http.get<HttpTypes.ChangeOfNameEvidenceOptionsApiResponse>(`/settings/auto-justification/definitions/evidences`)
  ]);

function* sagaFetchAutoJustificationEvidence(action: ReturnType<typeof actionFetchAutoJustificationEvidence.request>) {
  try {
    const data: [HttpTypes.SubscriberNameJustificationEvidenceApiResponse, HttpTypes.ChangeOfNameEvidenceOptionsApiResponse] = yield* call(
      fetchAutoJustificationEvidence,
      action.payload
    );
    yield put(actionFetchAutoJustificationEvidence.success({ data }));
  } catch (error) {
    yield put(actionFetchAutoJustificationEvidence.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchAutoJustifications.request, sagaFetchAutoJustifications),
  takeLatest(actionFetchAutoJustificationDetail.request, sagaFetchAutoJustificationDetail),
  takeLatest(actionFetchAutoJustificationEvidence.request, sagaFetchAutoJustificationEvidence)
];
