import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import AllDirectionsContainer, { AllDirectionsRouteParams } from 'src/containers/workspace/financial/all-directions/AllDirectionsContainer';
import { useAllDirections } from 'src/containers/workspace/financial/all-directions/reducer';
import { useRouterParams } from 'src/hooks';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';

export default function WorkspaceAllDirectionsPageContainer() {
  const { workspaceId, participantId } = useRouterParams<AllDirectionsRouteParams>();

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const allDirectionsState = useAllDirections(workspaceId, participantId);

  if (workspaceDetailState.error || workspaceBasicInfoState.error || workspaceParticipantsState.error || allDirectionsState.error) {
    return <GeneralCrashedContent />;
  }

  return <AllDirectionsContainer />;
}
