import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import Box from 'src/@core/components/layout/box';
import { useDirectionsList } from 'src/@core/store/reducers/directionsList';
import DocumentItemLoader from 'src/components/loaders/document-item-loader';
import { RouteParams } from 'src/containers/shared/document-list/models';
import { useRouterParams } from 'src/hooks';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useAllDirections } from '../../../all-directions/reducer';
import { SelectedSettlementDistributionsTypeEnum } from './models';
import SettlementDirectionsList from './SettlementDirectionsList';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083782708/settlement+directions+list+-+data+dependency+insights
export default function SettlementDirectionsListContainer(props: {
  // route params
  workspaceId: string;
  participantId: string;
  // other
  collapsedByDefault?: boolean;
  selectedSettlementDistributionsType?: SelectedSettlementDistributionsTypeEnum;
  onExpandChange?(expand: boolean): void;
}) {
  const { documentId } = useRouterParams<RouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(props.workspaceId);
  const directionsListState = useDirectionsList(props.workspaceId, props.participantId);
  const allDirectionsState = useAllDirections(props.workspaceId, props.participantId);

  if (directionsListState.isLoading) {
    return (
      <Box>
        <List>
          <ListItem disableGutters>
            <DocumentItemLoader color="grey" />
          </ListItem>
          <ListItem disableGutters>
            <DocumentItemLoader color="grey" />
          </ListItem>
        </List>
      </Box>
    );
  }

  if (directionsListState.status === 'rejected') {
    return (
      <Box>
        <Typography component="div" variant="subtitle1">
          <span>Financials</span>
        </Typography>
        <FormHelperText error role="alert">
          {directionsListState.error}
        </FormHelperText>
      </Box>
    );
  }

  if (!directionsListState.detail) {
    return null;
  }

  return (
    <SettlementDirectionsList
      // route params
      workspaceId={props.workspaceId}
      participantId={props.participantId}
      documentId={documentId}
      // basic workspace info
      isLocked={workspaceBasicInfoState.detail?.isLocked}
      // directions list data
      data={directionsListState.detail!}
      // other
      participantDistributionsData={allDirectionsState?.detail?.distributionsParticipants.find(x => x.participantId === props.participantId)}
      collapsedByDefault={props.collapsedByDefault}
      selectedSettlementDistributionsType={props.selectedSettlementDistributionsType}
      onExpandChange={props.onExpandChange}
    />
  );
}
