import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/reducer';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import {
  financialWorkspaceLodgementErrorsSelector,
  orderedAvailableParticipantsSelector,
  settlementAcceptedByParticipantsSelector,
  settlementDateNotificationsSelector,
  WorkspaceIssuesModel
} from 'src/containers/workspace/shared/detail/selectors';
import { useFeatureFlag, useSafeDispatch, useScreenSize, useStoreSelector } from 'src/hooks';
import { useLinkedLodgementWorkspaces } from 'src/store/reducers/workspace/linkedLodgementDetail';
import { ScreenSizeVariant } from 'src/theme';
import { ApiStatus } from 'src/utils/http';
import {
  actionFetchLinkedLodgementDetail,
  actionFetchLinkedSettlementDetail,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceLatestTransaction,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceTasks
} from '../../../../store/actions/workspace';
import { LinkedSettlementWorkspacesState, useLinkedSettlementWorkspaces } from '../../../../store/reducers/workspace/linkedSettlementDetail';
import { useWorkspaceBasicInfo } from '../../../../store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from '../../../../store/reducers/workspace/workspaceDetail';
import { useWorkspaceDocuments } from '../../../../store/reducers/workspace/workspaceDocuments';
import { useWorkspaceLatestTransaction } from '../../../../store/reducers/workspace/workspaceLatestTransaction';
import { useWorkspaceParticipants } from '../../../../store/reducers/workspace/workspaceParticipants';
import { useWorkspaceTasks } from '../../../../store/reducers/workspace/workspaceTasks';
import { useWorkspaceReportsFeed } from '../../shared/detail/components/tab-workspace-report/reducer';
import { actionFetchAllDirections } from '../all-directions/actions';
import { useDirectionsBreakdown } from '../directions/reducers/directionsBreakdown';
import { actionPostSettlementApproval } from '../settlement-date/actions';
import { RescindSettlementDateTimeModel } from '../settlement-date/models';
import { useSettlementDateDetails } from '../settlement-date/reducers/settlementDetail';
import FinancialDetail from './FinancialDetail';

function FinancialDetailContainer(props: { workspaceId: string; participantId: string }) {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = props;
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const isNewSettlementCardEnabled = useFeatureFlag(FeatureToggleEnum.newSettlementCardEnabled);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const directionsBreakdownState = useDirectionsBreakdown(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const linkedWorkspacesState = useLinkedWorkspaces(workspaceId);
  const workspaceReportsState = useWorkspaceReportsFeed(workspaceId, participantId);
  const settlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const profileState = useProfile();
  const withdrawInvitationLoadingStatus: ApiStatus = useStoreSelector(store => store.withdrawInvitation.status);
  const workspaceLatestTransactionState = useWorkspaceLatestTransaction(workspaceId);
  const linkedWorkspaceClusterId: string | undefined = workspaceDetailState.detail?.linkedWorkspaceCluster?.clusterId;
  const linkedWorkspaceClusterType: HttpTypes.LinkedWorkspaceTypeEnum | undefined = workspaceDetailState.detail?.linkedWorkspaceCluster?.linkedWorkspaceType;

  const linkedSettlementDetail: LinkedSettlementWorkspacesState = useLinkedSettlementWorkspaces(workspaceId, linkedWorkspaceClusterId);
  const linkerUserIds: string[] | undefined = linkedSettlementDetail.detail?.linkedSettlementWorkspaceDetails.find(x => x.workspaceId === workspaceId)?.linkerUserIds;

  const linkedLodgementDetail = useLinkedLodgementWorkspaces(workspaceId, linkedWorkspaceClusterId);

  const workspaceIssues: WorkspaceIssuesModel = financialWorkspaceLodgementErrorsSelector({
    workspaceDetail: workspaceDetailState,
    settlementDateDetails: settlementDateDetailsState,
    participantId,
    isRollover: workspaceBasicInfoState.detail?.isRollover
  });

  const workspaceStatusId: HttpTypes.WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const isAbandonedOrWithdrawnWorkspace =
    Number.isInteger(workspaceStatusId) && [HttpTypes.WorkspaceStatusEnum.Abandoned, HttpTypes.WorkspaceStatusEnum.Withdrawn].includes(workspaceStatusId!);

  const handleOnAcceptSettlementClick = () => {
    dispatch(
      actionPostSettlementApproval.request({
        workspaceId,
        participantId,
        settlementDate: (settlementDateDetailsState.detail.proposedSettlementDate || '').replace(/\+.*$/, 'Z')
      })
    );
    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: "You've accepted the settlement date and time",
        autoHideDuration: 5000,
        variant: 'new-success'
      })
    );
  };

  const handleRefreshTasks = () => {
    dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
  };

  React.useEffect(() => {
    if (withdrawInvitationLoadingStatus === 'resolved') {
      dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
      dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
      dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
    }
  }, [dispatch, workspaceId, participantId, withdrawInvitationLoadingStatus, isAbandonedOrWithdrawnWorkspace]);

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceLatestTransaction.request({ workspaceId }));
  }, [dispatch, participantId, workspaceId]);

  // if it is linked workspace we will need to fetch the linked details
  // for now we only support LL/LS, but dependent lodgement will be coming soon
  React.useEffect(() => {
    if (linkedWorkspaceClusterId) {
      if (linkedWorkspaceClusterType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement) {
        dispatch(actionFetchLinkedSettlementDetail.request({ workspaceId, clusterId: linkedWorkspaceClusterId }));
      } else if (linkedWorkspaceClusterType === HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement) {
        dispatch(actionFetchLinkedLodgementDetail.request({ workspaceId, clusterId: linkedWorkspaceClusterId }));
      }
    }
  }, [dispatch, workspaceId, linkedWorkspaceClusterId, linkedWorkspaceClusterType]);

  // in Financial workspace we need to wait for list of participants
  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const workspaceParticipants = orderedAvailableParticipantsSelector({
    //
    workspaceParticipants: workspaceParticipantsState
  });

  const settlementAcceptedByParticipants = settlementAcceptedByParticipantsSelector({
    settlementDateDetails: settlementDateDetailsState
  });

  const settlementDateNotifications: RescindSettlementDateTimeModel[] | undefined = settlementDateNotificationsSelector({
    settlementDateDetails: settlementDateDetailsState
  });
  const isProposedByUser: boolean = settlementDateDetailsState.detail?.proposedByParticipant?.id === currentWorkspaceParticipant?.id;

  const settlementAwaitingAcceptanceCount: number =
    // All pending invitation is awaiting settlement date acceptance
    workspaceParticipantsState.items.filter(item => item.participantStatus.id === HttpTypes.ParticipantStatusEnum.Pending).length +
    // accepted participants will receive new settlement date and time, if they don't accept, hasAccepted is false
    settlementDateDetailsState.detail.participants.filter(participant => !participant.hasAccepted).length;

  const lodgementCaseIdToDocumentNameMap = React.useMemo<Record<string, string>>(() => {
    return workspaceDocumentsState.items.reduce((entryMap, doc) => {
      // ignore lodgement instructions
      if (doc.documentForm.documentType !== HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions) {
        entryMap[doc.lodgementCaseId] = doc.documentForm.name;
      }
      return entryMap;
    }, {});
  }, [workspaceDocumentsState.items]);

  if (!workspaceBasicInfoState.detail) return null;

  return (
    <FinancialDetail //
      // route
      workspaceId={workspaceId}
      participantId={participantId}
      navigate={navigate}
      // loader
      isLoadingWorkspaceBasicInfo={workspaceBasicInfoState.isLoading}
      isLoadingWorkspaceDetail={workspaceDetailState.isLoading}
      isLoadingWorkspaceParticipants={workspaceParticipantsState.isLoading}
      isLoadingWorkspaceTasks={workspaceTasksState.isLoading}
      isLoadingSettlementDateDetails={settlementDateDetailsState.isLoading}
      // data
      workspaceDetail={{
        // basic workspace info
        isLocked: workspaceBasicInfoState.detail.isLocked,
        isRollover: workspaceBasicInfoState.detail.isRollover,
        jurisdictionId: workspaceBasicInfoState.detail.jurisdictionId,
        workspaceStatusId: workspaceBasicInfoState.detail.workspaceStatusId,
        workspaceTypeId: workspaceBasicInfoState.detail.workspaceTypeId,
        settlementDate: workspaceBasicInfoState.detail.settlementDate,
        expectedSettlementDate: workspaceBasicInfoState.detail.expectedSettlementDate,
        lodgementCases: workspaceBasicInfoState.detail.lodgementCases,
        isInteroperable: workspaceBasicInfoState.detail.isInteroperable,
        transactionType: workspaceBasicInfoState.detail.transactionType,
        // full workspace info
        settlementStatusId: workspaceDetailState.detail?.settlementStatusDetail?.status,
        lastSettlementReadyCheckReasonId: workspaceDetailState.detail?.lastSettlementReadyCheck?.reason?.id,
        clusterId: workspaceDetailState.detail?.linkedWorkspaceCluster?.clusterId,
        linkedWorkspaceOverallStatus: workspaceDetailState.detail?.linkedWorkspaceCluster?.linkedWorkspaceOverallStatus,
        lodgementDetails: workspaceDetailState.detail?.lodgementDetails
      }}
      // current participant info
      currentParticipant={{
        reference: currentWorkspaceParticipant?.reference,
        workspaceRoleId: currentWorkspaceParticipant?.workspaceRole.id,
        groupId: currentWorkspaceParticipant?.groupId
      }}
      // settlement date info
      settlementDateDetail={{
        // list of participants that accepted settlement
        isAcceptedByUser: settlementDateDetailsState.detail?.isAcceptedByUser,
        isProposedByUser,
        isAcceptedByAll: settlementDateDetailsState.detail?.isAcceptedByAll,
        acceptedByParticipants: settlementAcceptedByParticipants,
        localProposedSettlementDate: settlementDateDetailsState.detail?.localProposedSettlementDate,
        proposedSettlementDate: settlementDateDetailsState.detail.proposedSettlementDate,
        settlementDateNotifications: settlementDateNotifications,
        settlementAwaitingAcceptanceCount,
        isUnsupported: settlementDateDetailsState.detail?.isUnsupported
      }}
      // settlement transaction info
      latestSettlementStep={workspaceLatestTransactionState.detail?.settlementStep}
      // list of current workspace participants
      workspaceParticipants={workspaceParticipants}
      // list of withdrawn archived workspace participants
      withdrawnParticipants={workspaceParticipantsState.items.filter(p => p.archivedStatus)}
      // list of linked workspaces
      linkedWorkspaces={linkedWorkspacesState.items}
      // list of reports
      workspaceReports={workspaceReportsState.items}
      // list of tasks for current participant
      workspaceTasksState={workspaceTasksState}
      // linked settlements info
      linkerUserIds={linkerUserIds}
      numberOfLinkedWorkspaces={workspaceDetailState.detail?.linkedWorkspaceCluster?.numberOfLinkedWorkspaces}
      isLinkedSettlementDateTimeMisaligned={linkedSettlementDetail.detail?.isSettlementDateTimeMisaligned || linkedLodgementDetail.detail?.isSettlementDateTimeMisaligned}
      // profile data
      profile={{
        userId: profileState.data!.userId,
        subscriberId: profileState.data!.subscriberId
      }}
      // combined issues (lodgement compliances, settlement issues)
      workspaceIssues={workspaceIssues}
      distributionsStatus={directionsBreakdownState.detail?.documentSummary?.status}
      lodgementCaseIdToDocumentNameMap={lodgementCaseIdToDocumentNameMap}
      isAbandonedOrWithdrawnWorkspace={isAbandonedOrWithdrawnWorkspace}
      // other
      onRefreshTasks={handleRefreshTasks}
      onAcceptSettlementClick={handleOnAcceptSettlementClick}
      dispatch={dispatch}
      isNewSettlementCardEnabled={isNewSettlementCardEnabled}
      screenVariant={screenVariant}
    />
  );
}

export default React.memo(FinancialDetailContainer);
