import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { actionFetchTrustAccountAuthorisationRecords } from './actions';
import { TrustAccountAuthorisationRecordsApiRequest } from './models';

const fetchTrustAccountAuthorisationRecords = (args: TrustAccountAuthorisationRecordsApiRequest) => {
  return endpoints.getDirectionTrustAccountAuthorisationRecords(args);
  // const { workspaceId, participantId, trustAccountId } = payload;
  // return http.get<TrustAccountAuthorisationRecordsApiResponse>(
  //   `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions/trustAccountAuthorisationRecords/${encodeURIComponent(
  //     trustAccountId
  //   )}`,
  //   { withCredentials: true }
  // );
};

function* sagaFetchTrustAccountAuthorisationRecords(action: ReturnType<typeof actionFetchTrustAccountAuthorisationRecords.request>) {
  try {
    const data: HttpTypes.GetDirectionTrustAccountAuthorisationRecordsApiResponse = yield* call(fetchTrustAccountAuthorisationRecords, action.payload);
    yield put(actionFetchTrustAccountAuthorisationRecords.success({ data }));
  } catch (error) {
    yield put(actionFetchTrustAccountAuthorisationRecords.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchTrustAccountAuthorisationRecords.request, sagaFetchTrustAccountAuthorisationRecords)
];
