import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import StatusBadge from '@sympli/ui-framework/components/status-badge';

import { SectionTypeEnum } from '../../models';
import FssSectionTooltip from '../tooltip-contents/fss-section-tooltip/FssSectionTooltip';

interface Props {
  hasIssues?: boolean;
  showToolTips?: boolean;
  financialStatus: HttpTypes.FinancialStatusEnum;
  sectionType: SectionTypeEnum;
  numberOfSigners: number;
}

const steps: string[] = ['in preparation', 'approved', 'signed'];
const notApplicableSteps: string[] = ['n/a'];

function getTitle(financialStatus: HttpTypes.FinancialStatusEnum): JSX.Element {
  switch (financialStatus) {
    case HttpTypes.FinancialStatusEnum.InPreparation:
    case HttpTypes.FinancialStatusEnum.Saved:
      return <>IN PREPARATION</>;
    case HttpTypes.FinancialStatusEnum.Reviewed:
      return <>APPROVED</>;
    case HttpTypes.FinancialStatusEnum.PartiallySigned:
      return <>PARTIALLY SIGNED</>;
    case HttpTypes.FinancialStatusEnum.Signed:
      return <>SIGNED</>;
    case HttpTypes.FinancialStatusEnum.NotApplicable:
      return <>NOT APPLICABLE</>;
  }
}

function getBody(financialStatus: HttpTypes.FinancialStatusEnum, sectionName: string): JSX.Element {
  switch (financialStatus) {
    case HttpTypes.FinancialStatusEnum.InPreparation:
    case HttpTypes.FinancialStatusEnum.Saved:
      return (
        <>
          {sectionName} are <b>now active</b> for you to start completing.
        </>
      );
    case HttpTypes.FinancialStatusEnum.Reviewed:
      return (
        <>
          {sectionName} have now <b>been approved</b> and are now ready to be signed.
        </>
      );
    case HttpTypes.FinancialStatusEnum.PartiallySigned:
      return (
        <>
          {sectionName} have now <b>been partially signed</b>.
        </>
      );
    case HttpTypes.FinancialStatusEnum.Signed:
      return (
        <>
          {sectionName} have now <b>been signed</b> and are ready for settlement.
        </>
      );
    case HttpTypes.FinancialStatusEnum.NotApplicable:
      return (
        <>
          {sectionName} have <b>no line items</b>, so it is <b>not included</b> in this workspace's financials.
        </>
      );
  }
}

function getBody2(financialStatus: HttpTypes.FinancialStatusEnum, sectionName: string): JSX.Element | undefined {
  switch (financialStatus) {
    case HttpTypes.FinancialStatusEnum.PartiallySigned:
      return <>Once another user has signed {sectionName}, they will be ready for settlement.</>;
    default:
      return undefined;
  }
}

function getCurrentStep(financialStatus: HttpTypes.FinancialStatusEnum): number {
  switch (financialStatus) {
    case HttpTypes.FinancialStatusEnum.InPreparation:
    case HttpTypes.FinancialStatusEnum.Saved:
      return 1;
    case HttpTypes.FinancialStatusEnum.Reviewed:
      return 2;
    case HttpTypes.FinancialStatusEnum.PartiallySigned:
      return 2;
    case HttpTypes.FinancialStatusEnum.Signed:
      return 3;
    case HttpTypes.FinancialStatusEnum.NotApplicable:
      return 4;
  }
}

function getTooltip(financialStatus: HttpTypes.FinancialStatusEnum, sectionType: SectionTypeEnum): JSX.Element {
  const sectionName = sectionType === SectionTypeEnum.Payments ? 'My Payments' : 'My Sources';
  return (
    <FssSectionTooltip
      steps={financialStatus === HttpTypes.FinancialStatusEnum.NotApplicable ? notApplicableSteps : steps}
      currentStep={getCurrentStep(financialStatus)}
      title={getTitle(financialStatus)}
      body={getBody(financialStatus, sectionName)}
      body2={getBody2(financialStatus, sectionName)}
    />
  );
}

function getFinancialStatus(financialStatus: HttpTypes.FinancialStatusEnum, numberOfSigners: number): string {
  switch (financialStatus) {
    case HttpTypes.FinancialStatusEnum.InPreparation:
    case HttpTypes.FinancialStatusEnum.Saved:
      return 'IN PREPARATION';
    case HttpTypes.FinancialStatusEnum.Reviewed:
      return 'APPROVED';
    case HttpTypes.FinancialStatusEnum.PartiallySigned:
      return 'PARTIALLY SIGNED (1/2)';
    case HttpTypes.FinancialStatusEnum.Signed:
      return numberOfSigners === 2 ? 'SIGNED (2/2)' : 'SIGNED';
    case HttpTypes.FinancialStatusEnum.NotApplicable:
      return 'N/A';
  }
}

function FssSectionStatus(props: Props) {
  const { hasIssues, showToolTips, financialStatus, sectionType, numberOfSigners } = props;

  if (hasIssues) {
    return <StatusBadge color="error" size="small" variant="outlined" label="Resolve issues" />;
  }

  return showToolTips ? (
    <Tooltip title={getTooltip(financialStatus, sectionType)} tooltipType="document">
      <Typography variant="body5">{getFinancialStatus(financialStatus, numberOfSigners)}</Typography>
    </Tooltip>
  ) : (
    <Typography variant="body5">{getFinancialStatus(financialStatus, numberOfSigners)}</Typography>
  );
}

export default React.memo(FssSectionStatus);
