import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { BANK_ACCOUNT_TYPE_MAPPINGS } from '../../subscriber-profile/financial-accounts/models';
import { SubscriberActivityLogLinkEnum } from './models';

interface GroupMetaData {
  userName?: string;
  groupName: string;
  id: string;
}

interface UserMetaData {
  userName: string;
  id: string;
}

interface LoanAccountMetaData {
  userName?: string;
  loanAccountName: string;
  id: string;
  userId: string;
}

interface FinancialAccountMetaData {
  subscriberId: string;
  accountId: string;
  accountName: string;
  bankAccountType: HttpTypes.SubscriberBankAccountTypeEnum;
}

interface DefaultFieldPopulationMetaData {
  jurisdiction: string;
  jurisdictionName: string;
}

interface AutoJustificationMetaData {
  justificationId: string;
  previousKnownAs: string;
}

// TODO this is wrong
// we need to create a helper for link resolutions and use that everywhere
export function resolveLogLink(linkType: SubscriberActivityLogLinkEnum, metaData: unknown): string {
  switch (Number(linkType)) {
    case SubscriberActivityLogLinkEnum.UserDetailLink:
      const userData = metaData as UserMetaData;
      return `/settings/users/${encodeURIComponent(userData.id)}/detail`;
    case SubscriberActivityLogLinkEnum.GroupDetailLink:
      const groupData = metaData as GroupMetaData;
      return `/settings/groups/${encodeURIComponent(groupData.id)}/detail`;
    case SubscriberActivityLogLinkEnum.DefaultFieldPopulationJurisdictionLink:
      const defaultFieldJurisdictionMetaData = metaData as DefaultFieldPopulationMetaData;
      return `/settings/default-field-population/${encodeURIComponent(defaultFieldJurisdictionMetaData.jurisdiction)}`;
    case SubscriberActivityLogLinkEnum.LoanAccountSignerDetailLink:
      const loanAccountSignersMetaData = metaData as LoanAccountMetaData;
      return `/settings/bank-accounts/${encodeURIComponent(loanAccountSignersMetaData.id)}/signers`;
    case SubscriberActivityLogLinkEnum.LoanAccountSignerUserLink:
      const loanAccountSignersUserMetaData = metaData as LoanAccountMetaData;
      return `/settings/users/${encodeURIComponent(loanAccountSignersUserMetaData.userId)}/loan-accounts`;
    case SubscriberActivityLogLinkEnum.AutoJustificationDetailLink:
      const autoJustificationMetaData = metaData as AutoJustificationMetaData;
      return `/settings/auto-justification/${encodeURIComponent(autoJustificationMetaData.justificationId)}/detail`;
    case SubscriberActivityLogLinkEnum.FinancialAccountsLink:
      const financialAccountMetaData = metaData as FinancialAccountMetaData;
      const mapping = BANK_ACCOUNT_TYPE_MAPPINGS.get(financialAccountMetaData.bankAccountType);
      return `/settings/financial-accounts/${mapping?.apiAccountType}/${encodeURIComponent(financialAccountMetaData.accountId)}/details`;
    default:
      Logger.captureException(new InvalidDataError('Link type not supported!', linkType));
      return '#';
  }
}

const defaultActivityLogTemplateDictionary: Partial<{ [key in keyof typeof HttpTypes.SubscriberActivityLogTypeEnum]: (metaData: unknown) => string }> = {
  [HttpTypes.SubscriberActivityLogTypeEnum.CreateNewGroup]: () => `[:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) group was added`,
  [HttpTypes.SubscriberActivityLogTypeEnum.DeleteGroup]: () => `[:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) group was deleted`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateGroupSettings]: () => `Settings for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateGroupDistributionWorkflow]: () =>
    `Financial Settlement Schedule workflow for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateGroupDocumentWorkflow]: () =>
    `Document workflow for the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateGroupAddUser]: () => `':{userName}' was added to the group [:{groupName}](${SubscriberActivityLogLinkEnum.GroupDetailLink})`,
  [HttpTypes.SubscriberActivityLogTypeEnum.CreateNewUser]: () => `:{role} user [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was added`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserSuspension]: () => `:{role} [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was suspended`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserReActivation]: () => `:{role} [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was reactivated`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserDetails]: () => `User details for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserPermissions]: () => `User permissions for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserRemoveLinkedDevice]: () =>
    `User linked devices for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was removed`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberAddProfileImage]: () => '[Organisation profile image](/settings/organisation) was added',
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberRemoveProfileImage]: () => '[Organisation profile image](/settings/organisation) was deleted',
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberPreferences]: () => '[Subscriber preferences](/settings/preferences) was saved',
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberDefaultFieldPopulation]: () =>
    `Document default field population for [:{jurisdictionName}](${SubscriberActivityLogLinkEnum.DefaultFieldPopulationJurisdictionLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserName]: () => "User name ':{previousName}' was updated to ':{currentName}'",
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateGroupLodgementSettings]: () =>
    'Number of days required until lodgement has been changed from :{previousDaysUntilLodgement} to :{daysUntilLodgement} days',
  [HttpTypes.SubscriberActivityLogTypeEnum.TrustAccountRequestCreated]: () => 'Trust account request was initiated',
  [HttpTypes.SubscriberActivityLogTypeEnum.TrustAccountRequestSubmitted]: () => "Trust account request ':{requestId}' was submitted",
  [HttpTypes.SubscriberActivityLogTypeEnum.TrustAccountRequestStatusUpdated]: () => "Trust account request ':{requestId}' was ':{status}'",
  [HttpTypes.SubscriberActivityLogTypeEnum.RegisteredTrustAccountChangeRequested]: () =>
    "Trust account request ':{requestId}' was initiated for registered trust account ':{trustAccountDescription}'",
  [HttpTypes.SubscriberActivityLogTypeEnum.RegisteredTrustAccountDetailsUpdated]: () => "Information were updated in the registered trust account ':{trustAccountDescription}'",
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateUserReportPermissions]: () => `Report permissions for [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) was saved`,
  [HttpTypes.SubscriberActivityLogTypeEnum.AutoJustificationAdded]: () =>
    `Auto justification for [:{previousKnownAs}](${SubscriberActivityLogLinkEnum.AutoJustificationDetailLink}) was added`,
  [HttpTypes.SubscriberActivityLogTypeEnum.AutoJustificationModified]: () =>
    `Auto justification for [:{previousKnownAs}](${SubscriberActivityLogLinkEnum.AutoJustificationDetailLink}) was edited`,
  [HttpTypes.SubscriberActivityLogTypeEnum.AutoJustificationRemoved]: () => 'Auto justification for :{previousKnownAs} was deleted',
  [HttpTypes.SubscriberActivityLogTypeEnum.UserDeleted]: () => 'User :{userName} was deleted',
  [HttpTypes.SubscriberActivityLogTypeEnum.UserEmailUpdated]: () => `Email address of [:{userName}](${SubscriberActivityLogLinkEnum.UserDetailLink}) is updated`,
  [HttpTypes.SubscriberActivityLogTypeEnum.FinancialAccountDeleted]: () => 'General bank account named :{accountName} was deleted',
  [HttpTypes.SubscriberActivityLogTypeEnum.FinancialAccountUpdated]: metaData => {
    const financialAccountMetaData = metaData as FinancialAccountMetaData;
    const msg = `account named [:{accountName}](${SubscriberActivityLogLinkEnum.FinancialAccountsLink}) was edited`;
    return financialAccountMetaData.bankAccountType === HttpTypes.SubscriberBankAccountTypeEnum.General ? `General bank ${msg}` : `Loan ${msg}`;
  },
  [HttpTypes.SubscriberActivityLogTypeEnum.FinancialAccountAdded]: () =>
    `A new general bank account named [:{accountName}](${SubscriberActivityLogLinkEnum.FinancialAccountsLink}) was added`,
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberPreferencesWorkspaces]: () => '[Subscriber preferences workspaces](/settings/preferences/workspaces) was saved',
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberPreferencesNotifications]: () => '[Subscriber preferences notifications](/settings/preferences/notifications) was saved',
  [HttpTypes.SubscriberActivityLogTypeEnum.UpdateSubscriberSigningRules]: () => '[Subscriber preferences signing rules](/settings/preferences/signing-rules) was saved'
};

const matchRegEx = new RegExp(/:\{\w*\}/g); // regex to match template substitution :{value}
export function getTemplate(category: HttpTypes.SubscriberActivityLogTypeEnum, data: object) {
  const getTemplate = defaultActivityLogTemplateDictionary[category];
  const template = getTemplate ? getTemplate(data) : '';

  return template.slice().replace(matchRegEx, (substring, _, __) => {
    const key = substring.slice(2, substring.length - 1);
    return data[key];
  });
}
