export enum SettlementDateTimeVariantEnum {
  Unknown = -1,
  NoDateNoTime = 0,
  DateOnly = 1,
  UnsupportedDate = 2,
  Unsuccessful = 3,
  Completed = 4,
  SettlementIssue = 5,
  SettlementInProgress = 6,
  IsRollOver = 7,
  Overdue = 8,
  SettlementToday = 9,
  SettlementInDays = 10,
  SettlementInWeeks = 11
}
