import * as React from 'react';

import { useField } from 'formik';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { HttpTypes } from '@sympli/api-gateway/types';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete, IconExclamationCircle } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { RepresentationSubscriberTypeEnum } from 'src/containers/dashboard/components/create-new-workspace/models';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { createArrayItemModelKeyAppender } from 'src/utils/formUtils';
import { InvitationModel } from '../../../../../models';
import Invitation from '../../invitation';
import { useStyles } from './styles';

interface OwnProps {
  name: string;
  index: number;
  remove<T>(index: number): T | undefined;
  roleOptions: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>[];
  isInteroperable?: boolean;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  groups: GroupOptionModel[];
  disabled: boolean;
  representationSubscriberType?: RepresentationSubscriberTypeEnum;
}

function AdditionalInvitationItem({ name, index, remove, roleOptions, isInteroperable, jurisdictionId, groups, disabled, representationSubscriberType }: OwnProps) {
  const classes = useStyles();
  const fieldName = createArrayItemModelKeyAppender<InvitationModel>(name);
  const [, { value: additionalInvitation }, { setValue }] = useField<InvitationModel>(fieldName(index, 'invitation'));
  const [, { error: roleValidationError }] = useField<InvitationModel>(fieldName(index, 'role'));
  const validRoleSelected = Boolean(additionalInvitation.role !== undefined && !roleValidationError);

  const handleDeleteInvitationClicked = React.useCallback(() => remove(index), [remove, index]);
  const handleRoleSelectionChanged = React.useCallback(
    (_, resolvedValue) => {
      const selectedValue: HttpTypes.WorkspaceRoleEnum = resolvedValue === null ? undefined : resolvedValue;
      setValue({ ...additionalInvitation, role: selectedValue });
    },
    [additionalInvitation, setValue]
  );

  return (
    <>
      <FlexLayout alignItems="center">
        <IconButton //
          onClick={e => {
            e.stopPropagation();
            handleDeleteInvitationClicked();
          }}
          aria-label="Delete invitation"
          color="primary"
          disabled={disabled}
          className={classes.deleteButton}
        >
          <IconDelete />
        </IconButton>
        <Field //
          name={fieldName(index, 'role')}
          aria-label="Select role"
          placeholder="Select role"
          options={roleOptions}
          component={SelectField}
          variant="naked"
          format="number"
          key={`select-additional-role-${index}`}
          disabled={disabled}
          className={classes.roleDropdownBox}
          onChange={handleRoleSelectionChanged}
        />
      </FlexLayout>
      {roleValidationError && (
        <FlexLayout className={classes.roleValidationError}>
          <IconExclamationCircle className={classes.roleValidationErrorIcon} />
          <span>{roleValidationError}</span>
        </FlexLayout>
      )}
      {validRoleSelected && (
        <Invitation //
          isInteroperable={isInteroperable}
          jurisdictionId={jurisdictionId}
          groups={groups}
          name={`${name}[${index}]`}
          isAdditionalInvitation
          representationSubscriberType={representationSubscriberType}
        />
      )}
      <Divider className={validRoleSelected ? classes.dividerSelectedRole : classes.divider} />
    </>
  );
}

export default React.memo(AdditionalInvitationItem);
