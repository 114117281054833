import { AutoJustification } from './autoJustification';
import { Group } from './group';
import { Subscriber } from './subscriber';
import { TrustAccount } from './trustAccount';
import { TrustAccountRequest } from './trustAccountRequest';
import { User } from './user';

import type { Client } from '../client';

export class Settings {
  user: User;
  group: Group;
  subscriber: Subscriber;
  autoJustification: AutoJustification;
  trustAccount: TrustAccount;
  trustAccountRequest: TrustAccountRequest;

  constructor(client: Client) {
    this.user = new User(client);
    this.group = new Group(client);
    this.subscriber = new Subscriber(client);
    this.autoJustification = new AutoJustification(client);
    this.trustAccount = new TrustAccount(client);
    this.trustAccountRequest = new TrustAccountRequest(client);
  }
}
