import * as React from 'react';

import classNames from 'classnames';

import { HttpTypes } from '@sympli/api-gateway/types';
import { IconArrowsAltV, IconCheck, IconDelete, IconDocError, IconEditDocument, IconEye, IconLock, IconUsb } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

export interface Props {
  documentStatusId: HttpTypes.DocumentStatusEnum;
  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
  isCurrentParticipant?: boolean;
  mode?: 'reorder' | 'delete' | 'default';
  isLocked?: boolean;
  margin?: 'right';
  className?: string;
}

function DocumentStatusIcon({
  //
  documentStatusId,
  workspaceStatus,
  isCurrentParticipant,
  mode = 'default',
  isLocked,
  margin,
  className
}: Props): JSX.Element {
  const classes = useStyles();
  // mode will override default style since it has more actions

  if (mode === 'delete') {
    return <IconDelete className={classNames(classes.deleteIcon, margin === 'right' && classes.marginRight, className)} />;
  }
  if (mode === 'reorder') {
    return <IconArrowsAltV />;
  }

  if (isLocked) {
    return <IconLock />;
  }

  if (!isCurrentParticipant) {
    return <IconEditDocument className={classNames(classes.disabledIcon, margin === 'right' && classes.marginRight, className)} />;
  }

  if (workspaceStatus && workspaceStatus === HttpTypes.WorkspaceStatusEnum.LodgementInQueue && documentStatusId === HttpTypes.DocumentStatusEnum.LodgementInProgress) {
    return <IconLock />;
  }

  switch (documentStatusId) {
    case HttpTypes.DocumentStatusEnum.Reviewing:
      return <IconEye className={classNames(margin === 'right' && classes.marginRight, className)} />;
    case HttpTypes.DocumentStatusEnum.Signing:
      return <IconUsb className={classNames(margin === 'right' && classes.marginRight, className)} />;
    case HttpTypes.DocumentStatusEnum.Signed:
    case HttpTypes.DocumentStatusEnum.Lodged:
      return <IconCheck color="primary" className={classNames(margin === 'right' && classes.marginRight, className)} />;
    case HttpTypes.DocumentStatusEnum.ResolveIssues:
      return <IconDocError className={classNames(margin === 'right' && classes.marginRight, className)} />;
    default:
      return <IconEditDocument className={classNames(margin === 'right' && classes.marginRight, className)} />;
  }
}

export default React.memo(DocumentStatusIcon);
