import { all, call, put, takeLatest } from 'typed-redux-saga';
import { PayloadAction } from 'typesafe-actions';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionFetchAllDirections, actionUpdateAllDistributionParticipant } from 'src/containers/workspace/financial/all-directions/actions';
import http from 'src/utils/http';
import {
  actionCombinationAllDirectionActionsApprove,
  actionFetchDirections,
  actionFetchDirectionsCategories,
  actionFetchGeneralAccountUsages,
  actionUpdateDirectionsWorkForm,
  DirectionsApiRequest
} from './actions';

export const fetchDirections = (args: DirectionsApiRequest) => {
  return endpoints.getDirections(args);
};

function* sagaFetchDirections(action: ReturnType<typeof actionFetchDirections.request>) {
  try {
    const data: HttpTypes.WorkspaceDirectionsApiResponse = yield* call(fetchDirections, action.payload);
    yield put(actionFetchDirections.success({ data }));
  } catch (error) {
    yield put(actionFetchDirections.failure({ error }));
  }
}

const fetchDirectionsCategories = (query: DirectionsApiRequest) => {
  return endpoints.getDirectionCategories(query);
};

function* sagaFetchDirectionsCategories(action: ReturnType<typeof actionFetchDirectionsCategories.request>) {
  try {
    const response: HttpTypes.WorkspaceDirectionsCategoriesApiResponse = yield* call(fetchDirectionsCategories, action.payload);
    const data = Object.keys(response).reduce((current, key) => {
      // ToDo: In future, as part of a tech debt ticket replace Id:name with Id:Id
      current[key] = response[key].map((item: LookupEnumModel) => ({ id: item.name, name: item.name }));
      return current;
    }, {} as HttpTypes.WorkspaceDirectionsCategoriesApiResponse);
    yield put(actionFetchDirectionsCategories.success({ data }));
  } catch (error) {
    yield put(actionFetchDirectionsCategories.failure({ error }));
  }
}

const fetchAllDirections = (args: DirectionsApiRequest) => {
  return endpoints.getDirectionsOverview(args);
};

function* sagaHandleAllActionsApprove(action: ReturnType<typeof actionCombinationAllDirectionActionsApprove.request>) {
  const { newDirectionForm, newDistributionsParticipantData, workspaceId, participantId } = action.payload;
  const actions: PayloadAction<any, any>[] = [actionUpdateDirectionsWorkForm(newDirectionForm)];
  if (newDistributionsParticipantData != null && !!participantId) {
    actions.push(actionUpdateAllDistributionParticipant.request({ distributionsParticipant: newDistributionsParticipantData, participantId }));
  }

  try {
    if (!!workspaceId && !!participantId) {
      const allDirectionsDetail: HttpTypes.WorkspaceDirectionsOverviewApiResponse = yield* call(fetchAllDirections, { workspaceId, participantId });
      actions.push(actionFetchAllDirections.success({ data: allDirectionsDetail }));
    }
  } catch (error) {
    actions.push(actionFetchAllDirections.failure({ error }));
  }

  yield all(actions.map(action => put(action)));
}

const fetchGeneralAccountUsages = () => {
  return http.get<HttpTypes.GeneralAccountsUsagesApiResponse>('/workspaces/financial-accounts/general/usages');
};

function* sagaFetchGeneralAccountUsages(action: ReturnType<typeof actionFetchGeneralAccountUsages.request>) {
  try {
    const data: HttpTypes.GeneralAccountsUsagesApiResponse = yield* call(fetchGeneralAccountUsages);
    yield put(actionFetchGeneralAccountUsages.success({ data }));
  } catch (error) {
    yield put(actionFetchGeneralAccountUsages.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchDirections.request, sagaFetchDirections),
  takeLatest(actionFetchDirectionsCategories.request, sagaFetchDirectionsCategories),
  takeLatest(actionCombinationAllDirectionActionsApprove.request, sagaHandleAllActionsApprove),
  takeLatest(actionFetchGeneralAccountUsages.request, sagaFetchGeneralAccountUsages)
];
