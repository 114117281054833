import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSafeDispatch } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { useWorkspaceParticipantSettings, WorkspaceParticipantSettingState } from 'src/store/reducers/workspace/workspaceParticipantSetting';
import BillingMethodDialog from './BillingMethodDialog';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
  open: boolean;
  onClose: () => void;
}

function BillingMethodDialogContainer(props: Props) {
  const { workspaceId, participantId } = props.queryParams;
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceParticipantSetting: WorkspaceParticipantSettingState = useWorkspaceParticipantSettings(workspaceId, participantId);

  return (
    <BillingMethodDialog //
      {...props}
      dispatch={dispatch}
      workspaceParticipantSetting={workspaceParticipantSetting}
    />
  );
}

export default React.memo(BillingMethodDialogContainer);
