import { call, put, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { actionFetchDirectionsList, DirectionsListApiRequest } from '../actions/directionsList';

const fetchDirectionsList = (query: DirectionsListApiRequest) => {
  return endpoints.getDirectionsStatus(query);
};

function* sagaFetchDirectionsList(action: ReturnType<typeof actionFetchDirectionsList.request>) {
  try {
    const data: HttpTypes.WorkspaceDirectionsStatusApiResponse = yield* call(fetchDirectionsList, action.payload);
    yield put(actionFetchDirectionsList.success({ data }));
  } catch (error) {
    yield put(actionFetchDirectionsList.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchDirectionsList.request, sagaFetchDirectionsList)
];
