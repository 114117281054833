import { createAction, createAsyncAction } from 'typesafe-actions';

import { WorkspaceSearchApiRequest } from '../../components/layout/global-search/models';
import { SearchBoxesUpdatedApiResponse } from '../reducers/searchBoxUpdated';

export const actionFetchSearchBoxUpdatedArchivedFeed = createAsyncAction(
  //
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED',
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED_SUCCESS',
  'FETCH_SEARCHBOX_UPDATED_ARCHIVED_FEED_ERROR'
)<
  //
  WorkspaceSearchApiRequest,
  { data: SearchBoxesUpdatedApiResponse },
  { error: Error }
>();

export const actionResetSearchBoxArchivedFeed = createAction(
  //
  'FETCH_SEARCHBOX_ARCHIVED_FEED_RESET'
)<WorkspaceSearchApiRequest>();
