import { HttpTypes } from '@sympli/api-gateway/types';
import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { DocumentDenyPermissionModel, DocumentSummary, DocumentWorkflowStepsEnum } from 'src/containers/documents/models';

export interface DirectionsTrustAccountModel {
  id: string;
  bankDetails: HttpTypes.BankDetailsModel;
  bankAccountType: HttpTypes.BankAccountTypeEnum;
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
  balance: number;
}

export interface DirectionTrustAccountLookupItemModel extends LookupItemModel {
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
}

export type SourceFundFormikModel = HttpTypes.WorkspaceDirectionsApiResponse['sourceFunds'][number] & {
  isEditorOpen: boolean;
  // for IM only
  initialLoanAdvanceAmount?: number;
  displayLoanAdvanceAmount?: number;
  isAutoBalancingSurplusForIMAllowed?: boolean;
  // for linked source fund only
  linkedDetails?: LinkedSettlementItemModel;
  linkedToParticipantMatterReference?: string;
};

export type DistributionFormikModel = HttpTypes.WorkspaceDirectionsApiResponse['directions'][number] & {
  disableAddAmountAsVendor: boolean;
  isEditorOpen: boolean;
  prepopulateAccountId: string; // MR TODO this field does not exists in the BE
  linkedSettlementFundsNotRequired?: boolean; // This field does not exist in the Backend
};

export interface LinkedSettlementItemModel {
  reference?: string;
  linkedWorkspaceId: string;
  linkedParticipantId: string;
  linkedItemId?: string;
  linkedToParticipantMatterReference?: string;
}

export interface BpayDetailsModel {
  billerCode: string;
  billerReference: string;
  billerName: string;
  // optional field
  description?: string;
}

export interface HoldingAccountDetailsModel {
  accountId: string;
  reference?: string;

  // special for DM, auto-balancing
  shortfallAmount?: string;
  acceptSurplus?: boolean;
}

export interface DocumentSummaryApiResponse extends DocumentSummary {
  expiry?: string;
  pdfUrl: string;
  xmlString: string;
}

export const DirectionDocumentWorkflowMapping: Partial<Record<HttpTypes.DocumentStatusEnum, DocumentWorkflowStepsEnum>> = {
  [HttpTypes.DocumentStatusEnum.InPreparation]: DocumentWorkflowStepsEnum.Write,
  [HttpTypes.DocumentStatusEnum.Reviewing]: DocumentWorkflowStepsEnum.Review,
  [HttpTypes.DocumentStatusEnum.Signing]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Signed]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress]: DocumentWorkflowStepsEnum.Write,
  [HttpTypes.DocumentStatusEnum.LodgementInProgress]: DocumentWorkflowStepsEnum.Sign,
  [HttpTypes.DocumentStatusEnum.Lodged]: DocumentWorkflowStepsEnum.Sign
};

export interface TrustAccountMap {
  [id: string]: DirectionsTrustAccountModel;
}

export interface DirectionQueryParams {
  workspaceId: string;
  participantId: string;
  workflowStep?: DocumentWorkflowStepsEnum;
  showDialog?: boolean;
}

export interface DirectionCommonProps {
  queryParams: DirectionQueryParams;
  detail: HttpTypes.WorkspaceDirectionsApiResponse;
  currentParticipant: HttpTypes.WorkspaceParticipant;
  workspaceParticipants: HttpTypes.WorkspaceParticipant[];
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  directionsCategoriesDetail: HttpTypes.WorkspaceDirectionsCategoriesApiResponse;
  steps: StepObjectModel<string, DocumentWorkflowStepsEnum>[];
  onStepChange?(targetStep: DocumentWorkflowStepsEnum): void;
  portalIdForDirectionSummary: string;
  paymentsPermissions: Partial<Record<DocumentWorkflowStepsEnum, boolean>>;
  sourceFundPermissions: Partial<Record<DocumentWorkflowStepsEnum, boolean>>;

  requiresStampDuty: boolean;
  hasManageWorkspacePermission: boolean;
}

export enum AuthorisationDenyPermissionReasonCodeEnum {
  APD01_MissingSignDocumentPermission = 1,
  APD02_HasBeenSignedCannotSignAgain = 2,
  APD03_OneOfTheSignersCannotSignAgain = 3
}

export type AuthorisationDenyPermissionModel = DocumentDenyPermissionModel<AuthorisationDenyPermissionReasonCodeEnum>;

export interface TrustAccountOptionModel {
  id: string;
  name: string;
  canUseAsLoanPayout: boolean;
  canUseAsLoanAdvance: boolean;
}

export enum SympliSourceAccountStatusEnum {
  Unverified,
  Shortfall,
  Surplus,
  Cleared
}

export interface UpdateWorkspaceDirectionLineItemRequestModel {
  workspaceId: string;
  participantId: string;
  requestPayload: HttpTypes.SingleDirectionUpsert;
}

export interface DeleteWorkspaceDirectionLineItemRequestModel {
  workspaceId: string;
  participantId: string;
  directionItemId: string;
  isLinkedSettlementItem: boolean;
}
