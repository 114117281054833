import * as React from 'react';

import { RouteProps, Routes } from 'react-router-dom';

import { registerPath } from '@sympli/ui-logger/router';

/**
 * This switch is intended to help us to solve automatic logging of URL parameters (route + query)
 * We process path of every route definition (/workspace/:workspaceId/detail) into a regexp and save it into a cache.
 * Whenever the Logger tries to send data to NR, we use the cache to retrieve path definition and its corresponding regexp.
 * Using the regexp we can figure out route parameter.
 */
function Switch({ children }: React.PropsWithChildren<{}>) {
  // register all routes so we can easily extract params from location.pathname in our Logger
  React.useEffect(() => {
    React.Children.forEach(children, (child: React.ReactElement<RouteProps>) => {
      if (React.isValidElement(child)) {
        const { path, index = false, caseSensitive = false } = child.props;

        if (typeof path === 'string' && ~path.indexOf(':')) {
          registerPath(path, {
            index,
            caseSensitive
          });
        }
      }
    });
  }, []); // explicitly don't pass any references as we want to execute this on mount only

  return <Routes>{children}</Routes>;
}

export default React.memo(Switch);
