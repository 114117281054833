import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchFinancialAccounts = createAsyncAction(
  //
  'FETCH_FINANCIAL_ACCOUNTS',
  'FETCH_FINANCIAL_ACCOUNTS_SUCCESS',
  'FETCH_FINANCIAL_ACCOUNTS_ERROR'
)<
  //
  void,
  { data: HttpTypes.FinancialAccountsApiResponse },
  { error: Error }
>();
