import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useWorkspaceAssignableGroups } from 'src/@core/store/reducers/workspaceAssignableGroups';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { useWorkspaceInvitation, WorkspaceInvitationState } from 'src/store/reducers/workspace/workspaceInvitation';
import InviteAdditionalParticipants from './InviteAdditionalParticipants';

interface Props {
  isInteroperable?: boolean;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  workspaceId: string;
  participantId: string;
  currentWorkspaceRoleId: HttpTypes.WorkspaceRoleEnum;
  openDialog: boolean;
  onClose: () => void;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  currentParticipantGroupId?: string;
  currentParticipantReference?: string;
  // list of current workspace participants
  workspaceParticipants: HttpTypes.WorkspaceParticipant[];
}

function InviteAdditionalParticipantsContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceInvitationState: WorkspaceInvitationState = useWorkspaceInvitation(props.workspaceId, props.participantId);
  const currentSubscriber: HttpTypes.UserProfileModel = useProfile().data!;
  const { items: assignableGroups } = useWorkspaceAssignableGroups();
  const invitationSubscriberSearch = useFeatureFlag(FeatureToggleEnum.invitationSubscriberSearch);

  return (
    <InviteAdditionalParticipants //
      {...props}
      {...workspaceInvitationState}
      dispatch={dispatch}
      currentSubscriber={currentSubscriber}
      assignableGroups={assignableGroups}
      invitationSubscriberSearch={invitationSubscriberSearch}
    />
  );
}

export default React.memo(InviteAdditionalParticipantsContainer);
