import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from 'src/@core/components/layout/constants';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    lvDialogRoot: {
      zIndex: theme.zIndex.tooltip + 1
    },
    lvDialogPaper: {
      borderRadius: 0,
      margin: 0,
      boxShadow: theme.shadows[3],
      position: 'absolute',
      boxSizing: 'border-box',
      top: 80,
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        top: 60
      },
      right: 0,
      width: 'calc(100% - 80px)'
    },
    lvDialogPaperNewLayout: {
      borderRadius: 0,
      margin: 0,
      boxShadow: theme.shadows[3],
      position: 'absolute',
      boxSizing: 'border-box',
      top: 80,
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        top: 60
      },
      width: '100%'
    }
  }),
  {
    name: 'LodgementVerificationDialog'
  }
);
