import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import {
  actionFetchAvailableDocumentFeed,
  actionFetchDocumentDefaultFields,
  actionFetchDocumentGroupFeed,
  AvailbleDocumentApiRequest,
  DocumentDefaultFieldsApiRequest
} from '../actions/defaultFieldPopulation';

const fetchDocumentGroupFeed = () => http.get<HttpTypes.JurisdictionsEnum[]>('/settings/document-default-fields/jurisdictions');

function* sagaFetchDocumentGroupFeed() {
  try {
    const data = yield* call(fetchDocumentGroupFeed);
    yield put(actionFetchDocumentGroupFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchDocumentGroupFeed.failure({ error }));
  }
}

const fetchAvailableDocumentFeed = ({ jurisdictionId }: AvailbleDocumentApiRequest) =>
  http.get<HttpTypes.DocumentType[]>(`/settings/document-default-fields/jurisdictions/${jurisdictionId}/documentTypes`);

function* sagaFetchAvailableDocumentFeed(action: ReturnType<typeof actionFetchAvailableDocumentFeed.request>) {
  try {
    const data = yield* call(fetchAvailableDocumentFeed, action.payload);
    yield put(actionFetchAvailableDocumentFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchAvailableDocumentFeed.failure({ error }));
  }
}

const fetchDocumentDefaultFields = ({ jurisdictionId, documentTypeId }: DocumentDefaultFieldsApiRequest) =>
  http.get<HttpTypes.DocumentFieldDetailApiResponse>(`/settings/document-default-fields/jurisdictions/${jurisdictionId}/documentTypes/${documentTypeId}`);

function* sagaFetchDocumentDefaultFields(action: ReturnType<typeof actionFetchDocumentDefaultFields.request>) {
  try {
    const data = yield* call(fetchDocumentDefaultFields, action.payload);
    yield put(actionFetchDocumentDefaultFields.success({ data }));
  } catch (error) {
    yield put(actionFetchDocumentDefaultFields.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchDocumentGroupFeed.request, sagaFetchDocumentGroupFeed),
  takeLatest(actionFetchAvailableDocumentFeed.request, sagaFetchAvailableDocumentFeed),
  takeLatest(actionFetchDocumentDefaultFields.request, sagaFetchDocumentDefaultFields)
];
