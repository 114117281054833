import { all, call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { actionFetchTrustAccountLists, TrustAccountsRegisteredRequestedLists } from '../actions/trustAccounts';

const fetchTrustAccountRequests = () => http.get<HttpTypes.TrustAccountRequestDetailsApiResponse[]>('/settings/trust-accounts/requests');

const fetchTrustAccounts = () => http.get<HttpTypes.TrustAccountDetails[]>('/settings/trust-accounts');

function* sagaFetchTrustAccountLists() {
  try {
    const data: TrustAccountsRegisteredRequestedLists = yield* all({
      registeredItems: call(fetchTrustAccounts),
      requestedItems: call(fetchTrustAccountRequests)
    });
    yield put(actionFetchTrustAccountLists.success({ data }));
  } catch (error) {
    yield put(actionFetchTrustAccountLists.failure({ error }));
  }
}

export default [takeLatest(actionFetchTrustAccountLists.request, sagaFetchTrustAccountLists)];
