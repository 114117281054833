import { useEffect, useRef, useState } from 'react';

export default function useContentTruncated(dep: Array<unknown> | string) {
  const refContainerRef = useRef<HTMLDivElement | null>(null);
  const [isContentTruncated, setIsContentTruncated] = useState(false);

  useEffect(() => {
    const element = refContainerRef.current;
    if (element && element.scrollWidth > element.clientWidth) {
      // Content is truncated
      setIsContentTruncated(true);
    } else {
      setIsContentTruncated(false);
    }
  }, [...dep]);

  return {
    refContainerRef,
    isContentTruncated
  };
}
