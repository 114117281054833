import Typography from '@mui/material/Typography';

import { colors } from 'src/theme';

export const TotalFundsRequiredToolTipContent = (
  <>
    <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0), textTransform: 'uppercase' }}>
      <b>TOTAL FUNDS REQUIRED CALCULATOR</b>
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(0, 0, 0, 0) }}>
      As a Purchaser or Incoming Mortgagee this calculator helps you determine the <b>amount needed</b> to complete settlement.
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      It is calculated as ('Purchaser pays Vendor' + Purchasers payments) - (Purchasers source funds, including loan advances).
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A figure in red indicates the Purchasers funds are incomplete. When balanced the calculator will display $0.00 in green.
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A breakdown of the figures can be found in the Financial Settlement Summary.
    </Typography>
  </>
);

export const SettlementBalanceCalculatorToolTipContent = (
  <>
    <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0), textTransform: 'uppercase' }}>
      <b>SETTLEMENT BALANCE CALCULATOR</b>
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(0, 0, 0, 0) }}>
      Displays the totals for all payments and all source funds for this transaction. These totals are required to balance for <b>settlement to proceed</b>.
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A figure in red indicates the financial settlement does not balance. When balanced the calculator will display $0.00 in green.
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      If the ‘Total Funds Available Calculator’ is green but the ‘Settlement Balance is red’, the Purchasers Representative needs to complete their figures.
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      If the ‘Total Funds Required Calculator’ is green but the Settlement Balance is red, the Vendors Representative needs to complete their figures.
    </Typography>

    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A breakdown of the figures can be found in the ‘Financial Settlement Summary’.
    </Typography>
  </>
);

export const TotalFundsAvailableToolTipContent = (
  <>
    <Typography variant="body2_bold" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0), textTransform: 'uppercase' }}>
      <b>TOTAL FUNDS AVAILABLE CALCULATOR</b>
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(0, 0, 0, 0) }}>
      Specific to the Vendor, this calculator will help determine the <b>funds available</b> to the Vendor after payments, including the Loan Payout (if any).
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A figure in red indicates the Vendors funds are incomplete. When balanced the calculator will display $0.00 in green.
    </Typography>
    <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
      A breakdown of the figures can be found in the Financial Settlement Summary.
    </Typography>
  </>
);
