import React from 'react';

import classnames from 'classnames';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { IconCircleFull, IconDocError, IconExclamationCircle } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  // document lodgement data
  isUnverified: boolean;
  documentStatus: HttpTypes.DocumentStatusEnum;
  errorsCount: number;
  warningsCount: number;
  // workspace lodgement case data
  isLodgementCaseCompliant?: boolean;
}

function DocumentVerificationResultStatus({
  //
  documentStatus,
  isUnverified,
  isLodgementCaseCompliant,
  errorsCount,
  warningsCount
}: Props) {
  const classes = useStyles();

  // if document lodgement verification is still in progress
  if (documentStatus === HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress) {
    return (
      <div className={classes.secondaryMessage}>
        <IconCircleFull fontSize="small" className={classes.iconNoIssues} />
        <Typography variant="subtitle2" component="span" className={classes.textUnverified}>
          Verifying
        </Typography>
      </div>
    );
  }

  // document lodgement verification was not been done yet
  if (isUnverified) {
    return (
      <div className={classes.secondaryMessage}>
        <IconCircleFull fontSize="small" className={classes.iconNoIssues} />
        <Typography variant="subtitle2" component="span" className={classes.textUnverified}>
          Unverified
        </Typography>
      </div>
    );
  }

  // workspace lodgement verification was overall successful (that also means none of the documents has any errors)
  if (isLodgementCaseCompliant) {
    return (
      <div className={classes.secondaryMessage}>
        <IconCircleFull fontSize="small" className={classnames(classes.iconNoIssues, classes.iconVerified)} />
        <Typography variant="subtitle2" component="span">
          Verified {warningsCount ? ' with' : ''}
        </Typography>
        {warningsCount ? ( // some warnings were found on this particular document
          <>
            <IconExclamationCircle className={classnames(classes.icon, classes.iconWarning)} />
            <Typography variant="subtitle2" component="span">
              {pluralize('warning', warningsCount, true)} found
            </Typography>
          </>
        ) : null}
      </div>
    );
  }

  // workspace lodgement verification was not successful and this document has some issues
  if (errorsCount || warningsCount) {
    return (
      <div className={classes.secondaryMessage}>
        {errorsCount ? (
          <>
            <IconDocError className={classnames(classes.icon, classes.iconError)} />
            <Typography variant="subtitle2" component="span">
              {pluralize('error', errorsCount, true)}
              {warningsCount ? ' and' : ' found on this document'}
            </Typography>
          </>
        ) : null}
        {warningsCount ? (
          <>
            <IconExclamationCircle className={classnames(classes.icon, classes.iconWarning)} />
            <Typography variant="subtitle2" component="span">
              {pluralize('warning', warningsCount, true)} found on this document
            </Typography>
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={classes.secondaryMessage}>
        <IconCircleFull fontSize="small" className={classes.iconNoIssues} />
        <Typography variant="subtitle2" component="span" className={classes.textNoIssues}>
          No issues found
        </Typography>
      </div>
    );
  }
}

export default React.memo(DocumentVerificationResultStatus);
