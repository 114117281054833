import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from '../fallback';
import { ApiNoticeOfDeathModel_2_21_2, NoticeOfDeath_2_21_2_Model } from '../models';
import { convertPartiesToFormModel_2_21_2, generateDeceasedTenancy } from './common';

export function convertFromApiToFormModel(apiModel: ApiNoticeOfDeathModel_2_21_2, context: IConverterContext): NoticeOfDeath_2_21_2_Model {
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);

  const { deceasedJointTenants, ...rest } = apiModel;

  let formModel: NoticeOfDeath_2_21_2_Model = {
    ...rest,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel_2_21_2(apiModel.partyBook, risInvolvedPartyIds),
    deceasedTenancyDetail: deceasedJointTenants,
    context
  };

  if (apiModel.precedingData.dataChanged ?? true) {
    formModel = generateDeceasedTenancy(formModel);
  }

  const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
  return formModelWithDefaultMapApplied;
}
