import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { FormatterInputModel } from '@sympli/ui-framework/components/table';
import { MoreIcon, ResetIcon, ResetLargeIcon, SwitchIcon, SwitchLargeIcon, TrashIcon, TrashLargeIcon } from '@sympli/ui-framework/icons';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { actionResendInvite, actionWithdrawParticipantInvitation } from 'src/containers/dashboard/invitation/actions';
import WithdrawDialogContainer from 'src/containers/workspace/shared/components/withdraw-dialog';
import { useAsyncDialog, useSafeDispatch } from 'src/hooks';
import AnimatedIconButton from './components/animated-icon-button';
import { Row } from './InvitationsCardDetail';

export const InvitationsActionsFormatter = ({ row }: FormatterInputModel<Row>) => {
  const [showActions, setShowActions] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());
  const navigate = useNavigate();
  const { isOpen, asyncDialogDispatch } = useAsyncDialog();

  if (row.currentParticipantId === row.participantId) {
    return null;
  }

  const switchToOtherParticipant = () => {
    navigate(
      resolveWorkspaceDetailLink({
        workspaceId: row.workspaceId,
        participantId: row.participantId
      })
    );
  };

  const onClose = (confirmed?: boolean) => {
    if (!confirmed) {
      asyncDialogDispatch({ type: 'close' });
      return;
    }

    dispatch(
      actionWithdrawParticipantInvitation.request({
        //
        workspaceId: row.workspaceId,
        participantId: row.participantId
      })
    );
    setShowActions(false);
  };

  if (row.canSwitch || row.isPendingInvitation) {
    return (
      <div
        className="relative"
        onMouseLeave={() => {
          setShowActions(false);
        }}
      >
        <ButtonBase
          onClick={() => {
            setShowActions(true);
          }}
        >
          <MoreIcon />
        </ButtonBase>
        {showActions && (
          <div
            style={{ boxShadow: '-2px 0px 2px 0px rgba(0, 0, 0, 0.06)' }}
            className="absolute top-[-8px] right-[-6px] w-[156px] medium-screen:w-[210px] h-[44px] flex items-center bg-[#FAFAFA] pl-[10px] medium-screen:pl-[12px]"
          >
            <Typography className="mr-[24px] text-[12px] text-[var(--neutral-500)] leading-[16px]">{row.canSwitch ? 'Role' : 'Invite'}</Typography>
            <div style={{ boxShadow: '-2px 0px 2px 0px rgba(0, 0, 0, 0.06)' }} className="bg-[var(--greek-waters-translucent)] grow h-full flex items-center">
              {row.canSwitch ? (
                <AnimatedIconButton onClick={switchToOtherParticipant} icon={<SwitchIcon />} hoveredIcon={<SwitchLargeIcon />} />
              ) : (
                <>
                  <AnimatedIconButton
                    onClick={() =>
                      row.canResend
                        ? dispatch(
                            actionResendInvite.request({
                              //
                              id: row.participantId
                            })
                          )
                        : dispatch(
                            actionOpenGlobalSimpleNotification({
                              //
                              message: 'An invite has already been sent to this subscriber in the last 24 hours',
                              autoHideDuration: 2000,
                              variant: 'error'
                            })
                          )
                    }
                    icon={<ResetIcon />}
                    hoveredIcon={<ResetLargeIcon />}
                  />
                  <div className="bg-[var(--watermelon)] h-full w-full flex items-center" style={{ boxShadow: '-2px 0px 2px 0px rgba(0, 0, 0, 0.06)' }}>
                    <AnimatedIconButton
                      onClick={() => {
                        asyncDialogDispatch({ type: 'open' });
                      }}
                      icon={<TrashIcon />}
                      hoveredIcon={<TrashLargeIcon />}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {isOpen && <WithdrawDialogContainer sentToName={row.subscriberName} onClose={onClose} />}
      </div>
    );
  }

  return null;
};
