import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { IconCaretRight } from '@sympli/ui-framework/icons';

import styles, { ClassKeys } from './styles';

interface SubheaderProps extends React.HTMLAttributes<HTMLDivElement> {
  linkTo?: string;
  variant?: 'h2' | 'subtitle1' | 'body2';
}

type Props = SubheaderProps & WithStyles<ClassKeys>;

class Subheader extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    variant: 'body2'
  };

  private globalCssName = 'tickle-subheader';

  render() {
    const { classes, className, variant, linkTo, ...otherProps } = this.props;
    return (
      <div className={classNames(classes.root, this.globalCssName, className)} {...otherProps}>
        <div className={classes.content}>{this.renderHeader()}</div>
      </div>
    );
  }

  renderHeader() {
    const { classes, linkTo } = this.props;

    if (!linkTo) {
      return this.renderTitle();
    }

    return (
      <Link to={linkTo} className={classes.link}>
        {this.renderTitle()}
        <IconCaretRight height="18" width="18" className={classes.icon} />
      </Link>
    );
  }

  renderTitle() {
    const { classes, variant, children } = this.props;
    if (typeof children === 'string') {
      return (
        <Typography variant={variant as any} className={classes.title}>
          {children}
        </Typography>
      );
    }

    return children;
  }
}

export default withStyles(styles)(Subheader);
