import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import msg from '@sympli/ui-framework/utils/messages';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_TAB_GRANTEE_PARTY_FORM_CONFIG } from '../../config';
import { GranteeModel } from '../../models';

const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

export const yupGrantees = yup
  .array<GranteeModel>(
    yup
      .object<GranteeModel>({
        partyBookId: yup //
          .string()
          .default('')
          .trim()
          .required(msg.REQUIRED)
          .test(
            createPartyBookItemTestForPartyBookId(NSW_TAB_GRANTEE_PARTY_FORM_CONFIG, {
              shouldValidatePartyData
            })
          ),
        consent: yup.boolean().equals([true], msg.REQUIRED).defined()
      })
      .defined()
  )
  .min(1, msg.MIN_ITEMS(1))
  .max(20, msg.MAX_ITEMS(20))
  .defined();
