import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/@core/theme/colors';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: 30,
      boxShadow: 'none',
      borderRadius: 11,
      overflow: 'hidden',
      color: '#F9F9F9',
      top: 0,
      background: 'transparent'
    },
    upArrow: {
      transform: 'rotate(-45deg)',
      width: '18px', // 18px
      height: '20px', // 18px
      borderRadius: 3,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: colors.WHITE,
      position: 'absolute',
      top: -9,
      right: 4,
      borderBottom: 'none',
      borderLeft: 'none'
    },
    detailContainer: {
      width: 376,
      padding: '32px 32px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      border: `1px solid ${theme.palette.divider}`,
      marginTop: 20,
      backgroundColor: 'white',
      borderRadius: 11,
      boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.10)'
    },
    profileContainer: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    name: {
      fontSize: 28,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-xbold',
      lineHeight: 'normal',
      fontWeight: 800,
      letterSpacing: 1,
      color: colors.NEUTRAL_1000,
      fontStyle: 'normal',
      marginTop: 16,
      marginBottom: 4
    },
    editIcon: {
      paddingLeft: 8,
      '&:hover': {
        backgroundColor: colors.NEUTRAL_000,
        '& path': {
          fill: colors.SUNNY_DAY
        }
      }
    },
    email: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      fontStyle: 'normal',
      color: colors.SYMPLI_GREEN
    },
    subscriberName: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: colors.NEUTRAL_600,
      '&.MuiGrid-item': {
        paddingTop: 0
      }
    },
    timeZone: {
      marginLeft: 'auto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.NEUTRAL_600,
      '&.MuiGrid-item': {
        paddingTop: 0
      }
    },
    marginTopSpace: {
      marginTop: 32,
      marginBottom: 8
    },
    logoutButton: {
      marginTop: '32px',
      width: '94px',
      borderRadius: 28,
      alignItems: 'center',
      borderColor: colors.SUNNY_DAY,
      borderStyle: 'solid',
      borderWidth: 2,
      '&:hover': {
        backgroundColor: colors.SUNNY_DAY_LITE,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: colors.SUNNY_DAY
      }
    },
    logoutButtonLoader: {
      color: colors.SUNNY_DAY,
      marginLeft: 5
    },
    logout: {
      fontSize: 12,
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '14px',
      color: colors.SUNNY_DAY_DARK
    },
    menu: {
      paddingTop: 15,
      paddingBottom: 20
    },
    menuItem: {
      padding: '6px 24px'
    },
    menuText: {
      paddingLeft: 4,
      fontSize: 13,
      lineHeight: '24px',
      fontWeight: 'normal',
      textDecoration: 'underline'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
