import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchDirections, actionUpdateDirectionsWorkForm, DirectionsApiRequest } from '../actions';

export interface DirectionsDetailState {
  detail?: HttpTypes.WorkspaceDirectionsApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: DirectionsApiRequest;
}

const initialState: DirectionsDetailState = {
  detail: undefined,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useDirectionsBreakdown(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.directionsBreakdown);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const directionsBreakdownReducer = createReducer<
  //
  DirectionsDetailState,
  Action
>(initialState)
  .handleAction(actionFetchDirections.request, (state, action): DirectionsDetailState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchDirections.success, (state, action): DirectionsDetailState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchDirections.failure, (state, action): DirectionsDetailState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateDirectionsWorkForm, (state, action): DirectionsDetailState => {
    const detail: HttpTypes.WorkspaceDirectionsApiResponse = {
      ...state.detail!,
      ...action.payload
    };

    /**
     * prevent race condition
     * when post directions endpoint is too slow, the update action could be later than
     * the get directions endpoint
     * in this case, the pdfUrl becomes stale data.
     * */
    if (!action.payload.documentSummary?.pdfUrl) {
      detail.documentSummary.pdfUrl = state.detail?.documentSummary.pdfUrl;
    }

    return {
      ...state,
      detail
    };
  });

export default directionsBreakdownReducer;
