import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { IconCheck } from '@sympli/ui-framework/icons';

import DocPreviewContainer from 'src/containers/documents/components/doc-preview';
import { SaveDocumentFormRequest } from 'src/containers/documents/models';
import WorkflowPanel from 'src/containers/workspace/shared/components/workflow-panel';
import { dateTimeLine } from 'src/utils/formatters';
import { useStyles } from './styles';

interface Props {
  queryParams: SaveDocumentFormRequest;
  lodgedId?: string;
  lodgedDate?: HttpTypes.WorkspaceDateTimeModel;
}

function LodgedDocument({ lodgedId, lodgedDate, queryParams }: Props) {
  const classes = useStyles();

  if (!lodgedDate) {
    return null;
  }

  return (
    <>
      <WorkflowPanel //
        mode="dark"
        stepper={
          lodgedId ? (
            <Typography className={classes.documentActionTextDark}>
              Land Registry Document ID: <strong>{lodgedId}</strong>
            </Typography>
          ) : null
        }
        disableMenu={true}
      >
        <IconCheck className={classes.documentActionIcon} />
        <Typography className={classes.documentActionTextDark}>
          <strong>Lodged on {dateTimeLine(lodgedDate.userLocalTime, 'dd mmm yyyy')}</strong>
        </Typography>
      </WorkflowPanel>
      <DocPreviewContainer queryParams={queryParams} />
    </>
  );
}

export default React.memo(LodgedDocument);
