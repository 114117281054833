import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveReportLink } from 'src/@core/pages/links';
import { useAsyncDialog } from 'src/hooks';
import { actionDeleteReport } from '../actions';
import { SavedReportModel } from '../models';

interface State {
  reportId: number;
  reportName: string;
  reportFeatureType: HttpTypes.ReportFeatureTypeEnum;
}

export function useDeleteDialog() {
  const navigate = useNavigate();
  const [deleteReportData, setDeleteReportData] = React.useState<State>({ reportId: 0, reportName: '', reportFeatureType: HttpTypes.ReportFeatureTypeEnum.Favourite });

  const dispatch = useDispatch();
  const { isOpen: isDeleteDialogOpen, isLoading: isDeleteDialogLoading, asyncDialogDispatch: asyncDeleteDialogDispatch } = useAsyncDialog();

  const deleteDialogCloseHandler = (value?: boolean) => {
    value
      ? dispatch(
          actionDeleteReport.request({ id: deleteReportData.reportId, reportFeatureType: deleteReportData.reportFeatureType, asyncDialogDispatch: asyncDeleteDialogDispatch })
        )
      : asyncDeleteDialogDispatch({ type: 'close' });
  };

  const rowClickHandler = (e: React.MouseEvent<HTMLTableRowElement>, item: SavedReportModel, rowIndex: number) => {
    navigate(resolveReportLink(item.feature, item.reportType, item.id));
  };

  const deleteDialogClickHandler = (reportName: string, reportId: number, reportFeatureType: HttpTypes.ReportFeatureTypeEnum) => {
    // open the dialog
    asyncDeleteDialogDispatch({ type: 'open' });
    // set the report name showing in the dialog and set the report id so that we can delete it
    setDeleteReportData({ reportName, reportId, reportFeatureType });
  };

  return {
    deleteReportData,
    setDeleteReportData,
    dispatch,
    isDeleteDialogOpen,
    isDeleteDialogLoading,
    rowClickHandler,
    deleteDialogClickHandler,
    deleteDialogCloseHandler
  };
}
