import queryString from 'query-string';

import environments from 'src/@core/environments';
import { WORKSPACE_DETAIL_PAGE, WORKSPACE_DIRECTION_PAGE, WORKSPACE_SETTLEMENT_DATE_TIME_PAGE } from './constants';

/**
 * In this file, we are going to store all navigation link URLs
 */
const { BASENAME } = environments;

export const getWorkspaceOverviewUrl = (
  workspaceId: string,
  participantId: string //
) => `${BASENAME}/workspace/${workspaceId}/${WORKSPACE_DETAIL_PAGE}?participantId=${participantId}`;

export const getWorkspaceSettlementDateTimeUrl = (workspaceId: string, linkedToParticipantId: string) => {
  const query = queryString.stringify({ participantId: linkedToParticipantId });
  return `${BASENAME}/workspace/${workspaceId}/${WORKSPACE_SETTLEMENT_DATE_TIME_PAGE}?${query}`;
};

export const getWorkspaceFinancialSettlementScheduleUrl = (workspaceId: string, participantId: string) =>
  `${BASENAME}/workspace/${workspaceId}/${WORKSPACE_DIRECTION_PAGE}?participantId=${participantId}`;
