import { Action, createReducer } from 'typesafe-actions';

import { WorkspaceActivityLogModel } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { useStoreSelector } from 'src/hooks';
import { actionFetchWorkspaceActivityLogs, WorkspaceActivityLogsApiRequest } from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';

export interface WorkspaceActivityLogsState {
  items: Record<string, Array<WorkspaceActivityLogModel>>;
  totalCount: number;
  loadedCount: number;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  query: WorkspaceActivityLogsApiRequest;
}

const initialState: WorkspaceActivityLogsState = {
  items: {},
  totalCount: 0,
  loadedCount: 0,
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  query: {
    participantId: '',
    pageNumber: 1,
    workspaceId: ''
  }
};

function compareArgs(stateArgs: Partial<WorkspaceActivityLogsApiRequest>, payloadArgs: Partial<WorkspaceActivityLogsApiRequest>) {
  return stateArgs.workspaceId === payloadArgs.workspaceId;
}

export function useWorkspaceActivityLogs(workspaceId: string) {
  const state = useStoreSelector(store => store.workspaceActivityLogs);
  if (compareArgs(state.query, { workspaceId })) {
    return state;
  }
  return initialState;
}

const workspaceActivityLogsReducer = createReducer<
  //
  WorkspaceActivityLogsState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceActivityLogs.request, (state, action): WorkspaceActivityLogsState => {
    const status = compareArgs(state.query, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      query: action.payload
    };
  })
  .handleAction(actionFetchWorkspaceActivityLogs.success, (state, action): WorkspaceActivityLogsState => {
    const { loadedCount, items: groupedLogs } = state;
    const { items, totalCount, pageNumber } = action.payload;

    const newGroupedLogs = items.reduce(
      (result, currentValue) => {
        const { dateTime } = currentValue;
        const dateValue = dateTime.split('T')[0];
        result[dateValue] = (result[dateValue] || []).concat(currentValue);
        return result;
      },
      pageNumber === 1 ? {} : { ...groupedLogs }
    );

    const newLoadedCount = pageNumber === 1 ? items.length : loadedCount + items.length;

    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: newGroupedLogs,
      loadedCount: newLoadedCount,
      totalCount
    };
  })
  .handleAction(
    actionFetchWorkspaceActivityLogs.failure,
    (state, action): WorkspaceActivityLogsState => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    })
  );

export default workspaceActivityLogsReducer;
