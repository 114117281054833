import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { HttpTypes } from '@sympli/api-gateway/types';

import AggregatorCard from 'src/components/aggregator-card/AggregatorCard';
import { getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel, DashboardTypeEnum } from 'src/models';
import { colors } from 'src/theme';
import AggregatorCardWrapper from '../AggregatorCardWrapper';
import {
  AggregatorComponentBaseProps,
  AggregatorNameEnum,
  AggregatorQuery,
  InvitationsDashboardAggregatorApiRequest,
  InvitationsDashboardAggregatorApiResponse,
  TodayCountModel
} from '../models';

const payloadResolver = (assignmentType: AssignmentTypeEnum): InvitationsDashboardAggregatorApiRequest => {
  const { from, to } = getDateRange(DateRangeTypeEnum.Today);

  const lastInvitationSentPeriod: DashboardDateRangeModel = {
    start: from,
    end: to
  };

  const queries: AggregatorQuery[] = [
    {
      name: AggregatorNameEnum.todayReceived,
      invitationType: HttpTypes.InvitationTypeFilterEnum.Received,
      lastInvitationSentPeriod: lastInvitationSentPeriod
    },
    {
      name: AggregatorNameEnum.todaySent,
      invitationType: HttpTypes.InvitationTypeFilterEnum.Sent,
      lastInvitationSentPeriod: lastInvitationSentPeriod
    }
  ];
  const payload: InvitationsDashboardAggregatorApiRequest = {
    assignmentType: assignmentType,
    queries: queries
  };

  return payload;
};

const useStyles = makeStyles(
  () => ({
    cardContent: {
      color: colors.SUNNY_DAY_DARK
    },
    cardTitle: {
      color: colors.NEUTRAL_1000
    },
    cardStatus: {
      color: colors.SUNNY_DAY
    },
    cardRootStyle: {
      padding: '16px 8px'
    }
  }),
  {
    name: 'today'
  }
);

function TodayCard(props: AggregatorComponentBaseProps) {
  const classes = useStyles();

  const defaultContent = React.useMemo<TodayCountModel>(() => ({ received: 0, sent: 0 }), []);
  const contentResolver = React.useCallback(
    (response: InvitationsDashboardAggregatorApiResponse): TodayCountModel => ({
      received: response.find(d => d.name === AggregatorNameEnum.todayReceived)?.count ?? 0,
      sent: response.find(d => d.name === AggregatorNameEnum.todaySent)?.count ?? 0
    }),
    []
  );
  return (
    <AggregatorCardWrapper<TodayCountModel> //
      payloadResolver={payloadResolver}
      defaultContent={defaultContent}
      contentResolver={contentResolver}
      {...props}
    >
      {({ isLoading, content, onActionClick, isFetchingGridData }) => (
        <>
          <AggregatorCard
            title="Today"
            cardType={DashboardTypeEnum.Invitation}
            isLoading={isLoading}
            onActionClick={onActionClick}
            isFetchingGridData={isFetchingGridData}
            items={[
              {
                id: AggregatorNameEnum.todayReceived,
                content: content.received,
                status: 'Received',
                actionText: 'Show me'
              }
            ]}
            titleStyle={classes.cardTitle}
            contentStyle={classes.cardContent}
            statusStyle={classes.cardStatus}
            actionStyle={classes.cardContent}
            rootStyle={classes.cardRootStyle}
          />

          {props.assignmentType !== AssignmentTypeEnum.ReAssigned && (
            <AggregatorCard
              cardType={DashboardTypeEnum.Invitation}
              title=""
              isLoading={isLoading}
              onActionClick={onActionClick}
              isFetchingGridData={isFetchingGridData}
              items={[
                {
                  id: AggregatorNameEnum.todaySent,
                  content: content.sent,
                  status: 'Sent',
                  actionText: 'Show me'
                }
              ]}
              titleStyle={classes.cardTitle}
              contentStyle={classes.cardContent}
              statusStyle={classes.cardStatus}
              actionStyle={classes.cardContent}
              rootStyle={classes.cardRootStyle}
            />
          )}
        </>
      )}
    </AggregatorCardWrapper>
  );
}

export default React.memo(TodayCard);
