import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentFormIdentifierDisplayMapping } from 'src/@core/models/document';
import { actionFetchDirectionsList } from 'src/@core/store/actions/directionsList';
import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { DocumentPageRouteParams } from '../../models';
import WithdrawDialog from './WithdrawDialog';

interface Props {
  workspaceRole: HttpTypes.WorkspaceRoleEnum;
  documentType: HttpTypes.DocumentTypeIdentifierEnum;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function WithdrawDialogContainer({ workspaceRole, documentType, setOpenDialog }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, documentId } = useRouterParams<DocumentPageRouteParams>();
  const navigate = useNavigate();
  const workspaceOverviewLink: string = resolveWorkspaceDetailLink({ workspaceId, participantId });
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const documentIdentifierDisplay = DocumentFormIdentifierDisplayMapping[documentType];

  const handleOnClose = React.useCallback(
    async (confirmed?: boolean) => {
      if (!confirmed) {
        setOpenDialog(false);
        return;
      }
      setIsSubmitting(true);

      // Toast before withdrawDocumentParticipation() otherwise navigate() will stop toast render
      dispatch(
        actionOpenGlobalSimpleNotification({
          message: `You are no longer a participant in the ${documentIdentifierDisplay} document.`,
          variant: 'new-success',
          autoHideDuration: 5000
        })
      );

      await endpoints
        .withdrawDocumentParticipation({ workspaceId, documentId, participantId })
        .then(() => {
          setIsSubmitting(false);
          dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));

          navigate(workspaceOverviewLink);
        })
        .catch(error => {
          setIsSubmitting(false);
          dispatch(actionCreateGlobalErrorMessage(error));
        });

      setOpenDialog(false);
    },
    [dispatch, documentId, documentIdentifierDisplay, navigate, participantId, setOpenDialog, workspaceId, workspaceOverviewLink]
  );

  return (
    <WithdrawDialog //
      onClose={handleOnClose}
      workspaceRole={workspaceRole}
      documentType={documentIdentifierDisplay}
      isSubmitting={isSubmitting}
    />
  );
}
export default React.memo(WithdrawDialogContainer);
