import { FinalTenancyHoldingsExtractor, isValidFraction } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { multiplyFraction, subtractFraction, sumFraction } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { FractionModel, ProprietorGroupModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';

import { Transfer2_21_1Model } from '../../models';

type FormModel = Transfer2_21_1Model;

export class TenancyDetailExtractor implements FinalTenancyHoldingsExtractor {
  /**
   *
   */
  constructor(
    public source: FormModel['transferorsNew'],
    public receivingTenancyDetail: FormModel['transfereesNew']
  ) {}

  private isValidFraction = (fraction?: FractionModel): fraction is FractionModel => {
    return isValidFraction(fraction);
  };

  extractRemainingGroups(): ProprietorGroupModel<TenancyPartyModel>[] {
    const remainingRelinquishingProprietors: ProprietorGroupModel<TenancyPartyModel>[] = this.source.proprietorGroups
      .filter(({ isSelected, shareTransferred }) => !isSelected || shareTransferred === ShareTransferredTypeEnum.Partial)
      .map(g => {
        if (!g.isSelected) {
          return g;
        }
        let adjustedShareFraction: FractionModel | undefined = undefined;
        if (this.isValidFraction(g.shareFractionTransferring)) {
          const transferringFraction: FractionModel = multiplyFraction(g.shareFraction, g.shareFractionTransferring);
          adjustedShareFraction = subtractFraction(g.shareFraction, transferringFraction);
        }

        return {
          ...g,
          shareFraction: adjustedShareFraction ?? {
            numerator: null,
            denominator: null
          }
        };
      });

    return remainingRelinquishingProprietors;
  }

  extractShareTransferred(): FractionModel | undefined {
    const sharesTransferred: FractionModel | undefined = this.source.proprietorGroups
      .filter(proprietorGroup => proprietorGroup.isSelected)
      .map(({ shareTransferred, shareFraction, shareFractionTransferring }) => {
        const transferringShare =
          shareTransferred === ShareTransferredTypeEnum.Partial
            ? this.isValidFraction(shareFractionTransferring)
              ? multiplyFraction(shareFraction, shareFractionTransferring!)
              : undefined
            : shareFraction;
        return transferringShare;
      })
      .reduce(sumFraction);

    return sharesTransferred;
  }
}
