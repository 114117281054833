import * as yup from 'yup';

import { Transfer2_21_1Model } from './models';
import yupConsideration from './sections/consideration/validationSchema';
import yupDocumentAttachments from './sections/document-attachments/validationSchema';
import yupFinalTenancyHoldings from './sections/final-tenancy-holdings/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupTransferees from './sections/transferees/validationSchema';
import yupTransferors from './sections/transferors/validationSchema';

export default yup
  .object<Transfer2_21_1Model>({
    partyBook: yupPartyBook,
    transferors: yupTransferors,
    titleReferences: yupTitleReferences,
    consideration: yupConsideration,
    documentAttachments: yupDocumentAttachments,
    transferees: yupTransferees,
    finalTenancyHoldings: yupFinalTenancyHoldings,
    workspaceTitleReferences: yup.mixed<Transfer2_21_1Model['workspaceTitleReferences']>(),
    needToGeneratePartyBook: yup.mixed<Transfer2_21_1Model['needToGeneratePartyBook']>(),
    partyBookApi: yup.mixed<Transfer2_21_1Model['partyBookApi']>(),
    mergeFailedReason: yup.mixed<Transfer2_21_1Model['mergeFailedReason']>(),
    attachments: yup.mixed<Transfer2_21_1Model['attachments']>(),
    mortgagors: yup.mixed<Transfer2_21_1Model['mortgagors']>()
  })
  .defined()
  .log();
