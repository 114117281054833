import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { actionFetchSettlementDetails } from 'src/containers/workspace/financial/settlement-date/actions';
import { unacceptSettlementDateTime } from 'src/containers/workspace/financial/settlement-date/api';
import { resolveSettlementDateLink } from 'src/containers/workspace/financial/settlement-date/helper';
import { postSettlementApproval } from 'src/containers/workspace/financial/settlement-date/sagas';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { WorkspaceRouteParams } from 'src/router/models';
import { actionFetchWorkspaceTasks } from 'src/store/actions/workspace';
import { dateTimeLine } from 'src/utils/formatters';
import { SettlementDateTimeVariantEnum } from './models';
import SettlementDateTimeCard, { SettlementDateTimeCardProps } from './SettlementDateTimeCard';

function SettlementDateTimeCardContainer(props: Omit<SettlementDateTimeCardProps, 'variant' | 'redirectSettlementDatePage'>) {
  const {
    localProposedSettlementDate,
    isAcceptedByUser,
    expectedSettlementDateTime,
    isInteroperable,
    isRollover,
    settlementDateTime,
    isUnsupported,
    workspaceStatusId,
    proposedSettlementDate
  } = props;
  const dispatch = useSafeDispatch(useDispatch());
  const [isToggleButtonDisabled, setIsToggleButtonDisabled] = React.useState<boolean>(false);
  const calculatedSettlementDateTime = settlementDateTime || expectedSettlementDateTime?.workspaceDateTime;

  const navigate = useNavigate();

  const { workspaceId, participantId } = useRouterParams<WorkspaceRouteParams>();

  const variant: SettlementDateTimeVariantEnum = React.useMemo(() => {
    if (!expectedSettlementDateTime && !settlementDateTime) {
      return SettlementDateTimeVariantEnum.NoDateNoTime;
    }

    if (expectedSettlementDateTime && expectedSettlementDateTime.settlementDateKind === HttpTypes.SettlementDateKindEnum.DateOnly && !proposedSettlementDate) {
      return SettlementDateTimeVariantEnum.DateOnly;
    }

    if (isUnsupported) {
      return SettlementDateTimeVariantEnum.UnsupportedDate; // IOP
    }

    switch (workspaceStatusId) {
      case HttpTypes.WorkspaceStatusEnum.Failed:
        return SettlementDateTimeVariantEnum.Unsuccessful;
      case HttpTypes.WorkspaceStatusEnum.Success:
      case HttpTypes.WorkspaceStatusEnum.Archived:
        return SettlementDateTimeVariantEnum.Completed;
      case HttpTypes.WorkspaceStatusEnum.SettlementIssue:
        return SettlementDateTimeVariantEnum.SettlementIssue;
      case HttpTypes.WorkspaceStatusEnum.SettlementInProgress: // now settling
        return SettlementDateTimeVariantEnum.SettlementInProgress;
      case HttpTypes.WorkspaceStatusEnum.OnSchedule:
      case HttpTypes.WorkspaceStatusEnum.ReadyForSettlement: {
        if (isRollover) {
          return SettlementDateTimeVariantEnum.IsRollOver;
        }
        break;
      }
      default:
        break;
    }

    if (!calculatedSettlementDateTime) {
      return SettlementDateTimeVariantEnum.Unknown;
    }

    const { daysTill, hoursTill, weeksTill } = calculatedSettlementDateTime;

    if (hoursTill < 0) {
      return isInteroperable && workspaceStatusId === HttpTypes.WorkspaceStatusEnum.ReadyForSettlement
        ? SettlementDateTimeVariantEnum.SettlementToday
        : SettlementDateTimeVariantEnum.Overdue;
    } else if (hoursTill > 0 && daysTill === 0) {
      return SettlementDateTimeVariantEnum.SettlementToday;
    } else if (daysTill > 0 && daysTill < 14) {
      return SettlementDateTimeVariantEnum.SettlementInDays;
    } else if (daysTill >= 14 && weeksTill > 0) {
      return SettlementDateTimeVariantEnum.SettlementInWeeks;
    }

    return SettlementDateTimeVariantEnum.Unknown;
  }, [
    //
    expectedSettlementDateTime,
    settlementDateTime,
    proposedSettlementDate,
    isUnsupported,
    workspaceStatusId,
    calculatedSettlementDateTime,
    isRollover,
    isInteroperable
  ]);

  const redirectSettlementDatePage = () => {
    navigate(resolveSettlementDateLink({ workspaceId, participantId }));
  };

  let formattedSettlementDate: string | undefined;
  let formattedSettlementTime: string | undefined;

  if (calculatedSettlementDateTime) {
    const { workspaceLocalTime, workspaceTimezoneAbbreviation } = calculatedSettlementDateTime;
    const settlementDate = isRollover ? workspaceLocalTime : localProposedSettlementDate || workspaceLocalTime;
    formattedSettlementDate = dateTimeLine(settlementDate, 'dd mmm');
    formattedSettlementTime = `${dateTimeLine(settlementDate, 'h:MMTT')} ${workspaceTimezoneAbbreviation}`;
  }

  const handleOnToggleChange = async (checked: boolean) => {
    setIsToggleButtonDisabled(true);

    try {
      if (checked) {
        await postSettlementApproval({
          workspaceId,
          participantId,
          settlementDate: (proposedSettlementDate || '').replace(/\+.*$/, 'Z')
        });
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: "You've accepted the settlement date and time.",
            autoHideDuration: 5000,
            variant: 'new-success'
          })
        );
      } else {
        await unacceptSettlementDateTime(workspaceId, participantId);
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: "You've unaccepted the settlement date and time.",
            secondaryMessage: 'All participants have been notified.',
            variant: 'new-warning',
            autoHideDuration: 5000
          })
        );
      }
      dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
    } catch (error) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: `Unable to ${checked ? 'accept' : 'unaccept'} settlement date and time, please try again.`,
          autoHideDuration: 5000,
          variant: 'new-error'
        })
      );
    } finally {
      dispatch(actionFetchSettlementDetails.request({ workspaceId, participantId }));
    }

    setIsToggleButtonDisabled(false);
  };

  return (
    <SettlementDateTimeCard //
      {...props}
      variant={variant}
      redirectSettlementDatePage={redirectSettlementDatePage}
      weeksTill={calculatedSettlementDateTime?.weeksTill}
      daysTill={calculatedSettlementDateTime?.daysTill}
      formattedSettlementDate={formattedSettlementDate}
      formattedSettlementTime={formattedSettlementTime}
      isAcceptedByUser={isAcceptedByUser}
      handleOnToggleChange={handleOnToggleChange}
      isToggleButtonDisabled={isToggleButtonDisabled}
    />
  );
}

export default React.memo(SettlementDateTimeCardContainer);
