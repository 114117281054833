import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Breadcrumbs from 'src/containers/workspace/shared/components/workspace-status-rebuild/breadcrumbs';
import { colors } from 'src/theme';

function NotApplicableFinancialSettlementSummary() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Not Applicable
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The participant <b>has no line items</b>, so they are <b>not included</b> in this workspace’s financials.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        The system no longer requires their participation in completing this part of the workspace.
      </Typography>
      <Breadcrumbs //
        currentStep={1}
        steps={['N/A']}
        sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
      />
    </Box>
  );
}

export default React.memo(NotApplicableFinancialSettlementSummary);
