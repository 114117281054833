import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import TitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import { resolveDeceasedGroupWarning, resolveDeceasedPartyWarning } from 'src/containers/documents/scaffolded-form/shared/crossDocumentUtils';
import DeceasedTenancyDetail from '../../components/deceased-tenancy-detail/DeceasedTenancyDetail';
import { NSW_TAE_DECEASED_TENANCY_CONTEXT_CONFIG, NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS } from './enums';
import { TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from './models';
import Applicants from './sections/applicants/Applicants';
import { calculateCrossDocumentMatches } from './utils/calculateCrossDocumentMatches';
import { generateDeceasedTenancy } from './utils/common';

type FormModel = TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model;

const fieldName = modelKey<FormModel>();

function RootForm({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel,
  queryParams: { documentId }
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, workspaceDocuments, nextActionLabel } = useDocumentContext();
  const { setValues, values } = useFormikContext<FormModel>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues((values: FormModel) => generateDeceasedTenancy(values));
    },
    [setValues]
  );

  const handleOnTenancyReset = React.useCallback(() => {
    setValues(values => {
      return {
        ...values,
        ...generateDeceasedTenancy(values)
      };
    });
  }, [setValues]);

  const matchingTenancies = calculateCrossDocumentMatches({
    //
    workspaceDocuments,
    documentId,
    context: values.context,
    deceasedTenancyDetail: values.deceasedTenancyDetail,
    partyBook: values.partyBook,
    precedingData: values.precedingData
  });

  const resolveDeceasedTenancyPartyWarning = (
    //
    { itemIndex, item }: DocumentArrayItemRenderProps<FormModel['deceasedTenancyDetail']['proprietorGroups'][number]['parties'][number]>,
    deceasedGroup: FormModel['deceasedTenancyDetail']['proprietorGroups'][number]
  ): string[] => {
    const messages: string[] = [];
    if (itemIndex === 0) {
      const groupMessage: string | undefined = resolveDeceasedGroupWarning({
        //
        proprietorGroupParties: deceasedGroup.parties,
        matchingGroups: matchingTenancies.matchingGroups,
        partyBook: values.partyBook
      });
      groupMessage && messages.push(groupMessage);
    }

    const partyMessage: string | undefined = resolveDeceasedPartyWarning({
      //
      partyBookId: item.partyBookId,
      matchingParties: matchingTenancies.matchingParties,
      partyBook: values.partyBook
    });
    partyMessage && messages.push(partyMessage);

    return messages;
  };

  return (
    <Form className={className}>
      <TitleReferences //
        name={fieldName('titleReferences')}
        focusRef={focusOnTitleReferencesRef}
        onChange={handleSelectedTitleReferenceChange}
        skipTouchedCheck
      />

      <DeceasedTenancyDetail //
        config={NSW_TAE_DECEASED_TENANCY_CONTEXT_CONFIG}
        partyFormConfig={NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE}
        resolveDeceasedPartyWarning={resolveDeceasedTenancyPartyWarning}
        onTenancyReset={values.precedingData.sourceChanged ? handleOnTenancyReset : undefined}
        precedingData={values.precedingData}
      />

      <Applicants name={fieldName('applicants')} />

      <DocumentAttachments //
        name={fieldName('attachments')}
        minItems={0}
        attachmentDocumentTypes={ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS}
      />

      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={disabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
