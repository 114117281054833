import * as React from 'react';

import dateformat from 'dateformat';

import { DateFormatEnum } from 'src/@core/models';
import Header, { HeaderProps } from 'src/containers/dashboard/components/header/Header';
import FinancialWorkspacesContext from 'src/containers/dashboard/financial-workspaces/FinancialWorkspaces.context';
import { useFinancialWorkspacesFeedV2 } from 'src/containers/dashboard/financial-workspaces/reducers/financialWorkspacesFeedV2';
import { shouldUpdateTime } from '../helper';

function HeaderContainer(props: React.PropsWithChildren<HeaderProps>) {
  const { updatedTime } = useFinancialWorkspacesFeedV2();

  const { currentUpdatedTime, resetCurrentUpdatedTime } = React.useContext(FinancialWorkspacesContext);

  React.useEffect(() => {
    // isAfter(new Date(1989, 6, 10), new Date(1987, 1, 11)) // true
    if (shouldUpdateTime(updatedTime, currentUpdatedTime)) {
      resetCurrentUpdatedTime(updatedTime);
    }
  }, [updatedTime]);

  return <Header {...props} time={dateformat(currentUpdatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER)} />;
}

export default React.memo(HeaderContainer);
