import React from 'react';

import { useNavigate } from 'react-router-dom';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import { useMemoizableValidation } from '@sympli-mfe/document-forms-framework/validation';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';

import converter from './conversion';
import { ApiLodgementInstructionsDocumentModel, LodgementInstructions_2_28_0 } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

export type Props = DocumentFormProps<LodgementInstructions_2_28_0, ApiLodgementInstructionsDocumentModel>;

function DocumentForm(props: Props): JSX.Element {
  const { currentParticipant, validateDebounce } = useDocumentContext();
  const navigate = useNavigate();
  const { queryParams, onPostSubmit } = props;
  const { workspaceId, participantId } = queryParams!;
  const liConverter = React.useMemo(() => new converter(props.initialApiValues), [props.initialApiValues]);

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<LodgementInstructions_2_28_0, ApiLodgementInstructionsDocumentModel>) => {
    return new Promise<void>(resolve => {
      if (!args.error) {
        // * Changing workspace responsible participant should redirect user to the workspace overview
        if (currentParticipant?.isResponsibleParticipant && currentParticipant?.id !== args.formValues.responsibleParticipantId) {
          navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
        } else {
          onPostSubmit?.(args);
          // re-calculate new initial data
          const initialValues = liConverter.fromApiToFormModel(args.response, { currentParticipant, meta: props.meta });
          // this will cause dirty flag to be set to false so we can do check for disabled button
          args.formikHelpers.resetForm({ values: initialValues });
        }
      }
      resolve();
    });
  };

  const validate = useMemoizableValidation<LodgementInstructions_2_28_0>(validationSchema, validateDebounce);

  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={liConverter}
        validate={validate}
        RootForm={RootForm}
        onPostSubmit={handleOnPostSubmit}
      />
    </DndContextProvider>
  );
}

export default React.memo(DocumentForm);
