import React, { Fragment, useCallback } from 'react';

import { Form, FormikProps } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconGroup, IconUser } from '@sympli/ui-framework/icons';

import Formik from 'src/components/formik';
import SpinnerLoader from 'src/components/loaders/spinner-loader/SpinnerLoader';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { modelKey } from 'src/utils/formUtils';
import { ReassignWorkspaceGroupFormikModel } from '../reassign-workspace-group-container/models';
import { useStyles } from './styles';
import { getValidationSchema } from './validationSchema';

export interface OwnProps {
  open: boolean;
  assignableGroups: GroupOptionModel[];
  displayNoAccessMessage: boolean;
  displayLoadingIcon: boolean;
  jurisdiction: HttpTypes.JurisdictionsEnum | undefined;
  onClose(): void;
  handleOnSubmit(values: ReassignWorkspaceGroupFormikModel);
  currentParticipantGroupId?: string;
}

const fieldName = modelKey<ReassignWorkspaceGroupFormikModel>();

function ReassignWorkspaceGroupDialog({
  open,
  assignableGroups,
  displayNoAccessMessage,
  displayLoadingIcon,
  jurisdiction,
  onClose,
  handleOnSubmit,
  currentParticipantGroupId
}: OwnProps): JSX.Element {
  const classes = useStyles();
  const filteredAssignableGroups = assignableGroups.filter(g => g.id !== currentParticipantGroupId);

  const renderTitle = () => {
    return (
      <FlexLayout alignItems="center">
        {displayNoAccessMessage ? <IconGroup className={classes.groupIcon} /> : <IconUser className={classes.userIcon} />}
        <Typography className={classes.headingText}>Reassign workspace group</Typography>
      </FlexLayout>
    );
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<ReassignWorkspaceGroupFormikModel>) => {
      const { dirty, isValid, isSubmitting } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;
      return (
        <Form aria-label="Reassign workspace group form">
          <Box className={!isValid && dirty ? classes.contentWithError : classes.content}>
            {displayNoAccessMessage ? (
              <Typography className={classes.message}>
                You are assigning this workspace to a group that you are not a part of. If you continue, you will no longer be able to access this workspace.
              </Typography>
            ) : (
              <>
                <Typography className={classes.selectText}>Select a group</Typography>
                <Field //
                  classes={{ menuPaper: classes.menuPaper, menuItem: classes.menuItem }}
                  className={classes.dropDown}
                  name={fieldName('groupId')}
                  component={SelectField}
                  format="string"
                  options={filteredAssignableGroups}
                  placeholder="Please select a group"
                  aria-label="Reassign group"
                />
              </>
            )}
          </Box>
          <WizardStepper //
            className={classes.stepper}
            disabled={disabled}
            isLoading={isSubmitting}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel={displayNoAccessMessage ? 'Continue' : 'Reassign'}
            color={displayNoAccessMessage ? 'secondary' : 'primary'}
          />
        </Form>
      );
    },
    [filteredAssignableGroups, displayNoAccessMessage, classes, onClose]
  );

  const initialValues: ReassignWorkspaceGroupFormikModel = { groupId: '' };
  return (
    <ConfirmationDialog //
      open={open}
      onClose={onClose}
      title={renderTitle()}
      showActionButtons={false}
      classes={{ dialogContent: classes.dialog, dialogTitle: classes.dialog }}
    >
      {displayLoadingIcon ? (
        <SpinnerLoader className={classes.loader} />
      ) : (
        <Formik //
          initialValues={initialValues}
          validationSchema={getValidationSchema(filteredAssignableGroups, jurisdiction)}
          onSubmit={handleOnSubmit}
        >
          {(formikProps: FormikProps<ReassignWorkspaceGroupFormikModel>) => renderForm(formikProps)}
        </Formik>
      )}
    </ConfirmationDialog>
  );
}

export default React.memo(ReassignWorkspaceGroupDialog);
