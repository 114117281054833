import { columnFormatterCheck, ColumnsModel } from '@sympli/ui-framework/components/table';

import { doubleRowPrimarySecondaryFormatter } from 'src/containers/dashboard/shared/formatters';
import { FinancialRoleMapping } from 'src/models/roles';
import { modelKey } from 'src/utils/formUtils';
import { LinkedWorkspaceTableModel } from '../models';
import { LinkedLodgementParticipantRow } from './LinkingLodgementParticipantCard';

const fieldName = modelKey<LinkedLodgementParticipantRow>();
const cf = columnFormatterCheck<LinkedLodgementParticipantRow>();

export const getLinkedLodgementParticipantRows = (
  //
  linkedWorkspaceDetails: LinkedWorkspaceTableModel[],
  rowDetailIndex?: number
): LinkedLodgementParticipantRow[] => {
  if (rowDetailIndex === undefined) {
    return [];
  }

  const selectedLinkedWorkspace = linkedWorkspaceDetails[rowDetailIndex];
  const rowCardDetails: LinkedLodgementParticipantRow[] = [];

  // the sequence is always from 0 as an array but we start from 1
  if (selectedLinkedWorkspace.linkedByParticipant) {
    rowCardDetails.push({
      linkingSubscriberName: selectedLinkedWorkspace.linkedByParticipant.subscriberDisplayName,
      linkingSubscriberRole: selectedLinkedWorkspace.linkedByParticipant.workspaceRole.toString()
    } as LinkedLodgementParticipantRow);
  }

  return rowCardDetails;
};

export const getLinkedLodgementParticipantColumns = (): ColumnsModel<LinkedLodgementParticipantRow> => {
  return {
    linkingSubscriber: {
      label: 'LINKING SUBSCRIBER',
      name: fieldName('linkingSubscriberName'),
      verticalAlign: 'top',
      thClassName: 'w-[104px]',
      get: ({ linkingSubscriberName: primary, linkingSubscriberRole }: LinkedLodgementParticipantRow) => ({ primary, secondary: FinancialRoleMapping[linkingSubscriberRole] }),
      formatter: cf<{
        //
        primary: LinkedLodgementParticipantRow['linkingSubscriberName'];
        secondary?: LinkedLodgementParticipantRow['linkingSubscriberRole'];
      }>(doubleRowPrimarySecondaryFormatter)
    }
  };
};
