import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { actionFetchJurisdictionDetail, actionFetchJurisdictionRevenueOffice, actionFetchJurisdictionsFeed, actionFetchJurisdictionSummaryFeed } from '../actions/jurisdictions';

export const fetchJurisdictionsSummary = () => http.get<HttpTypes.JurisdictionApiResponse[]>('/settings/jurisdiction-summary');

function* sagaFetchJurisdictionsSummaryFeed() {
  try {
    const data = yield* call(fetchJurisdictionsSummary);

    yield put(actionFetchJurisdictionSummaryFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionSummaryFeed.failure({ error }));
  }
}

export const fetchJurisdictions = () => http.get<HttpTypes.JurisdictionsEnum[]>('/settings/jurisdictions');

function* sagaFetchJurisdictionsFeed() {
  try {
    const data = yield* call(fetchJurisdictions);

    yield put(actionFetchJurisdictionsFeed.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionsFeed.failure({ error }));
  }
}

const fetchJurisdictionDetail = (jurisdictionId: HttpTypes.JurisdictionsEnum) => http.get<HttpTypes.JurisdictionDetailApiResponse>(`/settings/land-registries/${jurisdictionId}`);

function* sagaFetchJurisdictionDetail(action: ReturnType<typeof actionFetchJurisdictionDetail.request>) {
  try {
    const data = yield* call(fetchJurisdictionDetail, action.payload);
    yield put(actionFetchJurisdictionDetail.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionDetail.failure({ error }));
  }
}

const fetchJurisdictionRevenueOffice = (jurisdictionId: HttpTypes.JurisdictionsEnum) =>
  http.get<HttpTypes.JurisdictionRevenueOfficeApiResponse>(`/settings/revenue-offices/${jurisdictionId}`);

function* sagaFetchJurisdictionRevenueOffice(action: ReturnType<typeof actionFetchJurisdictionRevenueOffice.request>) {
  try {
    const data = yield* call(fetchJurisdictionRevenueOffice, action.payload);
    yield put(actionFetchJurisdictionRevenueOffice.success({ data }));
  } catch (error) {
    yield put(actionFetchJurisdictionRevenueOffice.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchJurisdictionSummaryFeed.request, sagaFetchJurisdictionsSummaryFeed),
  takeLatest(actionFetchJurisdictionsFeed.request, sagaFetchJurisdictionsFeed),
  takeLatest(actionFetchJurisdictionDetail.request, sagaFetchJurisdictionDetail),
  takeLatest(actionFetchJurisdictionRevenueOffice.request, sagaFetchJurisdictionRevenueOffice)
];
