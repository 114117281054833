import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    radio: {
      '& div label': {
        flexGrow: 0
      }
    },
    acnGroup: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: theme.spacing(1.25)
    }
  }),
  {
    name: 'Transferee'
  }
);
