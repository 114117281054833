import { HttpTypes } from '@sympli/api-gateway/types';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import RichText from 'src/components/rich-text';
import { DocumentActionModel } from 'src/containers/documents/models';
import { DocumentActionInfo } from './models';

export function resolveBackLink(workspaceType?: HttpTypes.WorkspaceTypeEnum) {
  if (workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly) {
    return {
      linkTo: '/',
      title: 'All lodgement only'
    };
  }

  if (workspaceType === HttpTypes.WorkspaceTypeEnum.FinancialSettlement) {
    return {
      linkTo: '/financial-workspaces',
      title: 'Financial workspaces'
    };
  }

  return {
    linkTo: '/',
    title: 'Back to dashboard'
  };
}

export function resolveSingleDocumentActionInfo(documentActions: DocumentActionModel[], actionType: HttpTypes.DocumentActionTypeEnum): DocumentActionInfo {
  const actionInfo = documentActions.find(action => action.type === actionType);
  const actionInfos: Array<DocumentActionModel> = [];
  if (actionInfo) {
    actionInfos.push(actionInfo);
  }

  const info: DocumentActionInfo = {
    actionType: actionType,
    documentActions: actionInfos
  };

  return info;
}

export function resolveMultipleDocumentActionInfo(
  documentActions: Array<DocumentActionModel>,
  actionType: HttpTypes.DocumentActionTypeEnum,
  numberOfActions: number
): DocumentActionInfo {
  const actionInfos = documentActions.filter(action => action.type === actionType);

  const info: DocumentActionInfo = {
    actionType: actionType,
    documentActions: actionInfos,
    numberOfActions: numberOfActions
  };
  return info;
}

export function convertComplianceToMessageGridModel<
  T extends {
    //
    complianceMessage: string;
    guidanceMessagePlain?: string;
    guidanceMessageRich?: string;
    sympliCode?: string;
  }
>(item: T): MessageModel {
  const sympliCodeSuffix: string = item.sympliCode ? ` (${item.sympliCode})` : '';
  return {
    message: item.complianceMessage,
    remark: item.guidanceMessageRich ? <RichText>{`${item.guidanceMessageRich}${sympliCodeSuffix}`}</RichText> : `${item.guidanceMessagePlain || ''}${sympliCodeSuffix}`
  };
}
