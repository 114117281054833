import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import { convertComplianceToMessageGridModel } from 'src/containers/workspace/shared/detail/helpers';
import { AuthorityDocumentModel } from '../../models';
import DocumentVerificationResultItem from '../document-verification-result-item';
import DocumentVerificationResultStatus from '../document-verification-result-status';
import { useStyles } from './styles';

interface Props {
  // current document data
  document: AuthorityDocumentModel;
  defaultExpanded?: boolean;
  // workspace lodgement case data
  isLodgementCaseCompliant?: boolean;
  // other
  onResolveIssueClick?: (event: React.SyntheticEvent, document: AuthorityDocumentModel) => void;
}

export function DocumentVerificationResults({
  // current document data
  document,
  defaultExpanded = false,
  // workspace lodgement case data
  isLodgementCaseCompliant,
  // other
  onResolveIssueClick
}: Props) {
  const classes = useStyles();

  const { errors, warnings } = React.useMemo<{
    errors: MessageModel[];
    warnings: MessageModel[];
  }>(() => {
    const compliances = document.lodgementDetail?.documentLodgementCompliances ?? [];
    return {
      errors: compliances.filter(c => c.complianceLevel === HttpTypes.ComplianceLevelEnum.Error).map(convertComplianceToMessageGridModel),
      warnings: compliances.filter(c => c.complianceLevel === HttpTypes.ComplianceLevelEnum.Warning).map(convertComplianceToMessageGridModel)
    };
  }, [document]);

  const isUnverified = document.documentStatus.id < HttpTypes.DocumentStatusEnum.Signing && !document.lodgementDetail;

  if (isUnverified || (!errors.length && !warnings.length)) {
    return (
      <Paper square className={classes.root}>
        <div className={classes.container}>
          <Typography variant="subtitle2" component="span" className={classes.docName}>
            {document.documentForm.name}
          </Typography>
          <DocumentVerificationResultStatus
            // document lodgement data
            isUnverified={isUnverified}
            documentStatus={document.documentStatus.id}
            errorsCount={errors.length}
            warningsCount={warnings.length}
            // workspace lodgement case data
            isLodgementCaseCompliant={isLodgementCaseCompliant}
          />
        </div>
      </Paper>
    );
  }

  const showResolveIssuesButtonForDocument = onResolveIssueClick && errors.length > 0 && document.permissions.includes(HttpTypes.DocumentPermissionEnum.Write);

  return (
    <DocumentVerificationResultItem
      // current document data
      errors={errors}
      warnings={warnings}
      defaultExpanded={defaultExpanded}
      // other
      onResolveIssueClick={
        showResolveIssuesButtonForDocument
          ? (event: React.SyntheticEvent) => {
              onResolveIssueClick(event, document);
            }
          : undefined
      }
    >
      <div className={classes.container}>
        <Typography variant="subtitle2" component="span" className={classes.docName}>
          {document.documentForm.name}
        </Typography>
        <DocumentVerificationResultStatus
          // document lodgement data
          isUnverified={isUnverified}
          documentStatus={document.documentStatus.id}
          errorsCount={errors.length}
          warningsCount={warnings.length}
          // workspace lodgement case data
          isLodgementCaseCompliant={isLodgementCaseCompliant}
        />
      </div>
    </DocumentVerificationResultItem>
  );
}

export default React.memo(DocumentVerificationResults);
