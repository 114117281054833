import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';
import msg from '@sympli/ui-framework/utils/messages';

import { BpayDetailsModel, DistributionFormikModel, SourceFundFormikModel, TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { getAmountErrorMessage, getBpayCrnErrorMessage } from './components/direction-payee-detail/helper';
import { CategoryEnum } from './forms/discharge-mortgage-directions/models';
import { IncomingDirectionsFormModel } from './forms/income-mortgage-directions/models';
import { CheckHelper } from './helper';

const BSB_REGEX = /^[0-9]{6}$/; // bsb
const BILLER_CODE_REGEX = /^[0-9]{4,7}$/; // bpay biller code
const BILLER_REFERENCE_REGEX = /^[0-9]{2,20}$/; // bpay biller reference
// Westpac Banking Corporation
const WESTPAC_CORPORATION_BANK_ACCOUNT_NUMBER_REGEX = /^(03|04|73|74)/;
// St. George Bank, Bank of Melbourne, BankSA
const WESTPAC_SUB_BRAND_BANK_ACCOUNT_NUMBER_REGEX = /^(11|33|19|10)/;

export const WESTPAC_CORPORATION_BANK_ACCOUNT_NUMBER_LENGTH: number = 6;
export const WESTPAC_SUB_BRAND_BANK_ACCOUNT_NUMBER_LENGTH: number = 9;
export const GENERIC_BANK_MAX_ACCOUNT_NUMBER_LENGTH: number = 9;

const resolveBankAccountNumberLength = (bsb: string): number => {
  // Westpac Banking Corporation
  if (WESTPAC_CORPORATION_BANK_ACCOUNT_NUMBER_REGEX.test(bsb)) {
    return WESTPAC_CORPORATION_BANK_ACCOUNT_NUMBER_LENGTH;
  }

  // St. George Bank, Bank of Melbourne, BankSA
  if (WESTPAC_SUB_BRAND_BANK_ACCOUNT_NUMBER_REGEX.test(bsb)) {
    return WESTPAC_SUB_BRAND_BANK_ACCOUNT_NUMBER_LENGTH;
  }

  return 0;
};

const yupAccountNumber = yup //
  .string()
  .required(msg.REQUIRED)
  .matches(/^\d*$/, 'Must be numbers only')
  .test(
    'bank account number check',
    msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(GENERIC_BANK_MAX_ACCOUNT_NUMBER_LENGTH), //
    function test(this: yup.TestContext<HttpTypes.BankDetailsModel>, accountNumber: string): boolean | yup.ValidationError {
      const { bsb } = this.parent!;
      const length = resolveBankAccountNumberLength(bsb);
      if (!length || accountNumber?.length === length) {
        return true;
      }

      return this.createError({ message: msg.LENGTH_MUST_BE_X_NUMBERS(length) });
    }
  )
  .max(
    GENERIC_BANK_MAX_ACCOUNT_NUMBER_LENGTH, //
    msg.LENGTH_MUST_BE_X_OR_LESS_NUMBERS(GENERIC_BANK_MAX_ACCOUNT_NUMBER_LENGTH)
  );

export const yupReference = yup //
  .string()
  .trim()
  .max(18, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(18))
  .test('alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly);

const REQUIRED_BSB_LENGTH: number = 6;
export const bankDetailsYupSchema = yup.object<HttpTypes.BankDetailsModel>({
  bsb: yup //
    .string()
    .required(msg.REQUIRED)
    .length(REQUIRED_BSB_LENGTH, msg.LENGTH_MUST_BE_X_NUMBERS(REQUIRED_BSB_LENGTH))
    .matches(BSB_REGEX, msg.INVALID_BSB)
    .test(
      'bsb-async-check',
      msg.INVALID_BSB, //
      function test(this: yup.TestContext, bsb: string = '') {
        // if bsb is not valid, we don't need to execute API check
        if (!BSB_REGEX.test(bsb)) {
          return false;
        }

        // execute API check
        return CheckHelper.checkBsb(bsb)
          .then(res => true)
          .catch(error => false);
      }
    ),
  accountNumber: yupAccountNumber,
  accountName: yup //
    .string()
    .required(msg.REQUIRED)
    .max(32, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(32)),
  reference: yupReference,
  bankName: yup.string()
});

export const getBpayYupSchema = (workspaceId: string) =>
  yup.object<BpayDetailsModel>({
    billerCode: yup //
      .string()
      .default('')
      .required(msg.REQUIRED)
      .matches(BILLER_CODE_REGEX, msg.VALUE_MUST_BE_BETWEEN(4, 7, 'numbers'))
      .test('billerCode-async-check', msg.INVALID_VALUE, function test(this: yup.TestContext, billerCode: string) {
        // if billerCode is not valid, we don't need to execute API check
        if (!BILLER_CODE_REGEX.test(billerCode)) {
          return false;
        }

        // execute API check
        return CheckHelper.checkBillerCode(workspaceId, billerCode)
          .then(res => true)
          .catch(error => false);
      }),
    billerReference: yup //
      .string()
      .default('')
      .defined()
      .trim()
      // .required(msg.REQUIRED)
      .matches(BILLER_REFERENCE_REGEX, msg.VALUE_MUST_BE_BETWEEN(2, 20, 'numbers'))
      .test(
        'billerReference-async-Check',
        msg.INVALID_VALUE, //
        function test(this: yup.TestContext, billerReference: string) {
          const billerCode: string = this.parent.billerCode || '';

          // if billerCode is not valid, we can't verify billerReference, so we consider it valid
          if (!BILLER_CODE_REGEX.test(billerCode)) {
            return true;
          }

          return CheckHelper.checkBillerCode(workspaceId, billerCode)
            .then(res => {
              const { crnPrefix, checkDigitRule, minCrnLength = 2, maxCrnLength = 20 } = res;
              const errorMsg = getBpayCrnErrorMessage(billerReference, crnPrefix, checkDigitRule, minCrnLength, maxCrnLength);
              if (errorMsg) {
                return this.createError({ message: errorMsg });
              }
              return true;
            })
            .catch(error => true); // if biller code is not valid, we can't verify billerReference, so we consider it valid
        }
      ),
    description: yup //
      .string()
      .default('')
      .max(18, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(18))
      .test('alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly),
    billerName: yup.mixed()
  });

export const getBasicDistributionYupObject = (workspaceId: string, numberOfLinkedWorkspaces?: number) => ({
  category: yup
    .string()
    .required(msg.REQUIRED)
    .test('only-one-linked-payment', 'Only one Linked Payment line item is allowed per Workspace', oneLinkedPaymentOnly)
    .test(
      'only-one-linked-workspace',
      'Maximum 10 Linked Workspaces are allowed in a Linked Settlement',
      function lessThanTenLinkedWorkspace(this: yup.TestContext, category: string) {
        if (category !== CategoryEnum.LinkedPayment || !numberOfLinkedWorkspaces) {
          return true;
        }
        const direction = this.from[0].value as DistributionFormikModel;
        return !direction.isEditorOpen || numberOfLinkedWorkspaces! < 10;
      }
    ),
  categoryOther: yup.string().when('category', {
    is: (value: string) => value === 'Other',
    then: yup //
      .string()
      .trim()
      .required(msg.REQUIRED)
      .max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
      .test('alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly)
  }),
  reference: yup.mixed().when('category', {
    is: (value: string) => value === CategoryEnum.LinkedPayment,
    then: yupReference
  }),
  paymentMethod: yup
    .number()
    // * its a selection, possible value could be '' or 0,1,2,
    // * use typeError to catch empty selection
    // * otherwise, yup default typo error is very user unfriendly
    .typeError(msg.INVALID_SELECTION)
    .required(msg.REQUIRED),
  bankDetails: yup
    .mixed() //
    .when('paymentMethod', {
      is: (value: HttpTypes.PaymentMethodEnum) => value === HttpTypes.PaymentMethodEnum.BankTransfer,
      then: bankDetailsYupSchema
    })
    .when('paymentMethod', {
      is: (value: HttpTypes.PaymentMethodEnum) => value === HttpTypes.PaymentMethodEnum.TrustAccount,
      then: yup.object({
        reference: yupReference
      })
    }),
  bankAccountId: yup
    .string()
    .nullable(true)
    .default(null)
    .when('paymentMethod', {
      is: (value: HttpTypes.PaymentMethodEnum) => value === HttpTypes.PaymentMethodEnum.TrustAccount,
      then: yup.string().required(msg.REQUIRED)
    }),
  bpayDetails: yup
    .mixed() //
    .when('paymentMethod', {
      is: (value: HttpTypes.PaymentMethodEnum) => value === HttpTypes.PaymentMethodEnum.BPAY,
      then: getBpayYupSchema(workspaceId)
    }),
  linkedSettlementItem: yup
    .mixed() //
    .when('category', {
      is: (value: CategoryEnum) => value === CategoryEnum.LinkedPayment,
      then: yup.object({
        reference: yupReference
      })
    }),
  amount: yup
    .string()
    .default('')
    .required(msg.REQUIRED)
    .test(
      'direction-amount',
      msg.INVALID_VALUE, //

      function test(this: yup.TestContext, amount: any) {
        if (amount === '' || isNaN(Number(amount))) {
          return false;
        }
        const { paymentMethod } = this.parent;

        if (paymentMethod === HttpTypes.PaymentMethodEnum.TrustAccount) {
          const amountErrorMessage = getAmountErrorMessage(amount, 0, 99999999);
          if (amountErrorMessage) {
            return this.createError({ message: amountErrorMessage });
          }
          return true;
        } else if (paymentMethod !== HttpTypes.PaymentMethodEnum.BPAY) {
          return true;
        }

        const billerCode = this.parent.bpayDetails.billerCode || '';
        // if billerCode is not valid, we can't verify amount, so we consider it valid
        if (!BILLER_CODE_REGEX.test(billerCode)) {
          return true;
        }

        return CheckHelper.checkBillerCode(workspaceId, billerCode)
          .then(response => {
            const { minAmount = 0.01, maxAmount = 1000000 } = response;
            const amountErrorMessage = getAmountErrorMessage(amount, minAmount, maxAmount);
            if (amountErrorMessage) {
              return this.createError({ message: amountErrorMessage });
            }
            return true;
          })
          .catch(error => true); // if billerCode is not valid, we can't verify amount, so we consider it valid
      }
    )
    .when('linkedSettlementFundsNotRequired', {
      is: true,
      then: yup.string(),
      otherwise: yup.string().test('payment-positive-amount', msg.VALUE_MUST_BE_MORE_THAN_X('$0'), positiveAmountCheck)
    })
    .test('payment-max-amount', msg.VALUE_MUST_BE_LESS_THAN_X('$99 million'), maxAmountCheck)
});

export const basicSourceFundsYupObject = (numberOfLinkedWorkspaces?: number) => ({
  category: yup
    .string()
    .required(msg.REQUIRED)
    .test('only-one-linked-source-fund', 'Only one Linked Source Fund line item is allowed per Workspace', oneSourceFundOnly)
    .test(
      'only-one-linked-workspace',
      'Maximum 10 Linked Workspaces are allowed in a Linked Settlement',
      function lessThanTenLinkedWorkspace(this: yup.TestContext, category: string) {
        if (category !== CategoryEnum.LinkedSourceFund || !numberOfLinkedWorkspaces) {
          return true;
        }
        const direction = this.from[0].value as DistributionFormikModel;
        return !direction.isEditorOpen || numberOfLinkedWorkspaces! < 10;
      }
    ),
  reference: yup.mixed().when('category', {
    is: (value: string) => value === CategoryEnum.LinkedSourceFund,
    then: yupReference
  }),
  categoryOther: yup.string().when('category', {
    is: (value: string) => value === 'Other',
    then: yup
      .string()
      .trim()
      .required(msg.REQUIRED)
      .max(30, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(30))
      .test('alphanumeric-only', 'Must be letters and numbers only', alphanumericalOnly)
  }),
  trustAccountId: yup
    .string()
    .default('')
    .when('category', {
      is: (value: string) => value !== CategoryEnum.LinkedSourceFund,
      then: yup.string().required(msg.REQUIRED)
    })
    .defined(),
  amount: yup
    .number()
    .required(msg.REQUIRED)
    .test('sourceFund-amount', msg.INVALID_VALUE, numericAmountCheck)
    .test('sourceFund-max-amount', msg.VALUE_MUST_BE_LESS_THAN_X('$99 million'), maxAmountCheck)
    .when('linkedSettlementFundsNotRequired', {
      is: true,
      then: yup.number(),
      otherwise: yup.number().test('sourceFund-positive-amount', msg.VALUE_MUST_BE_MORE_THAN_X('$0'), positiveAmountCheck)
    })
});

export function getSourceFundsYupObject(trustAccountBankDetailMap: TrustAccountMap, numberOfLinkedWorkspaces?: number) {
  return {
    ...basicSourceFundsYupObject(numberOfLinkedWorkspaces),
    reference: yup
      .string()
      .trim()
      .when(['trustAccountId', 'category'], {
        is: (trustAccountId: string, category: string) => {
          if (category === CategoryEnum.LinkedSourceFund) {
            return true;
          }

          const bankDetails = trustAccountBankDetailMap[trustAccountId];

          return bankDetails == null || bankDetails.bankAccountType !== HttpTypes.BankAccountTypeEnum.SympliSourceAccount;
        },
        then: yupReference
      })
  };
}

export function getDirectionsYupObject(workspaceId: string, numberOfLinkedWorkspaces?: number) {
  return {
    ...getBasicDistributionYupObject(workspaceId, numberOfLinkedWorkspaces)
  };
}

export function numericAmountCheck(this: yup.TestContext, amount: any) {
  if (amount === '' || isNaN(Number(amount))) {
    return false;
  }
  return true;
}

export function positiveAmountCheck(this: yup.TestContext, amount: any) {
  const value = parseFloat(amount?.toString());
  if (isNaN(value)) {
    return true;
  }
  return value > 0;
}

export function maxAmountCheck(this: yup.TestContext, amount: any) {
  const value = parseFloat(amount?.toString());
  if (isNaN(value)) {
    return true;
  }
  // * https://tickleme.atlassian.net/browse/WEB-5495, for version 3.1 use 50m and limit
  // * https://tickleme.atlassian.net/browse/WEB-10151, update to 99m
  return value <= 98999999.99;
}

const REGEXP_ALFA_NUMERIC = /^[a-zA-Z0-9 ]*$/;

export function alphanumericalOnly(this: yup.TestContext, value: string) {
  return REGEXP_ALFA_NUMERIC.test(value);
}

export function oneLinkedPaymentOnly(this: yup.TestContext, value: string) {
  const direction = this.from[0].value as DistributionFormikModel;
  const formModel = this.from[1].value as IncomingDirectionsFormModel;
  return !direction.isEditorOpen || formModel.distributions.filter(x => x.category === CategoryEnum.LinkedPayment).length <= 1;
}

export function oneSourceFundOnly(this: yup.TestContext, value: string) {
  const direction = this.from[0].value as SourceFundFormikModel;
  const formModel = this.from[1].value as IncomingDirectionsFormModel;
  return !direction.isEditorOpen || formModel.sourceFunds.filter(x => x.category === CategoryEnum.LinkedSourceFund).length <= 1;
}
