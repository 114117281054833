import * as React from 'react';

import { Form, FormikProps } from 'formik';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';

import { HttpTypes } from '@sympli/api-gateway/types';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Fieldset from '@sympli/ui-framework/components/fieldset';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { actionCreateGlobalErrorMessage } from 'src/@core/store/actions/globalErrors';
import Formik from 'src/components/formik';
import VerifyPropertySection from 'src/containers/shared/verify-property-section';
import { TitleListItem, titleListItemsSelector, TitleListItemStatusEnum } from 'src/containers/shared/verify-property-section/components/title-list';
import { prepareTitleForSubmission, resolveTitles, verifyTitleReferences } from 'src/containers/shared/verify-property-section/helpers';
import {
  TitleVerificationRequest,
  TitleVerificationResponse,
  TitleVerificationStatusEnum,
  VerifiedTitleResponse,
  VerifyPropertyContextEnum,
  VerifyTitleFormModel
} from 'src/containers/shared/verify-property-section/models';
import validationSchema from 'src/containers/shared/verify-property-section/validationSchema';
import { resolveTitleDetailLink } from 'src/containers/workspace/titles/helper';
import { TitleReferenceEntityModel } from 'src/models';
import { addAdditionalTitles } from './api';
import { getInitialValues } from './initialValues';
import { AddTitlesRequest } from './models';
import { useStyles } from './styles';

import type { SafeDispatch } from 'src/hooks';

export interface Props {
  workspaceId: string;
  participantId: string;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  titleReferences: TitleReferenceEntityModel[];
  handleCancel(): void;
  dispatch: SafeDispatch<Action>;
  existingDocumentTypes: HttpTypes.DocumentTypeIdentifierEnum[];
  navigate: NavigateFunction;
}

export default function AddTitleDialog(props: Props) {
  const { handleCancel, jurisdictionId, workspaceType, titleReferences: existingTitleReferences, dispatch, workspaceId, participantId, existingDocumentTypes } = props;
  const classes = useStyles();
  const [isVerifying, setIsVerifying] = React.useState(false);
  const renderForm = (formikProps: FormikProps<VerifyTitleFormModel>) => {
    const { isValid, values, isSubmitting, errors, setValues } = formikProps;
    const { titleVerificationResult, titleInputValue, titleChips } = values;

    const onVerifyClick = async () => {
      setIsVerifying(true);
      const titles = resolveTitles(titleChips, titleVerificationResult?.titles);
      const existingTitles = existingTitleReferences
        .filter(tr => titles.some(t => t === tr.reference || t === tr.reference.replace('-', '/')))
        .map((title: TitleReferenceEntityModel): VerifiedTitleResponse => {
          return {
            titleReference: title.reference,
            isElectronicLodgementEligible: true,
            isPaperLodgementEligible: false,
            address: {
              addressLine1: title.address.addressLine1 ?? '',
              addressLine2: title.address.addressLine2 ?? ''
            },
            ineligibleReason: '',
            status: TitleVerificationStatusEnum.TitleAlreadyAdded
          };
        });

      const payload: TitleVerificationRequest = {
        checkTitleReferences: true,
        jurisdictionId,
        references: titles.filter(tr => existingTitleReferences.every(existingTitle => existingTitle.reference !== tr && existingTitle.reference.replace('-', '/') !== tr)),
        workspaceType: workspaceType,
        documentTypeIds: existingDocumentTypes,
        workspaceId: workspaceId
      };

      try {
        let titleVerificationResult: TitleVerificationResponse = {
          titles: [],
          hasPaperTitle: false
        };
        if (payload.references.length) {
          titleVerificationResult = await verifyTitleReferences(payload);
        }
        // update ineligibility reason based on the status
        setValues(values => ({
          ...values,
          // reset the following data
          titleInputValue: '',
          titleChips: [],
          // save verification result
          titleVerificationResult: {
            ...titleVerificationResult,
            titles: titleVerificationResult.titles.concat(existingTitles)
          }
        }));
      } catch (error) {
        dispatch(actionCreateGlobalErrorMessage(error));
      }
      setIsVerifying(false);
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>, referenceNumber: string) => {
      setValues(values => {
        const titles = values.titleVerificationResult!.titles.filter(title => title.titleReference !== referenceNumber);
        const titleVerificationResult: TitleVerificationResponse | undefined = titles.length
          ? {
              ...values.titleVerificationResult!,
              titles
            }
          : undefined;

        return { ...values, titleVerificationResult: titleVerificationResult };
      });
    };

    const disableVerifyButton = Boolean((!titleInputValue && !titleChips.length) || errors.titleChips);
    const titles: TitleListItem[] = titleListItemsSelector({
      titleVerificationResult: titleVerificationResult
    });

    const allTitlesAreDuplicate = titles.filter(x => x.status === TitleListItemStatusEnum.Existing).length === titles.length;
    const canSubmit = isValid && !allTitlesAreDuplicate && titles.every(x => x.status !== TitleListItemStatusEnum.Error);
    const disableAddTitle = isSubmitting || isVerifying || !canSubmit;

    return (
      <Form className={classes.form}>
        <Fieldset disabled={isVerifying || isSubmitting}>
          <VerifyPropertySection
            jurisdictionId={jurisdictionId}
            variant={VerifyPropertyContextEnum.AddTitles}
            disableVerifyButton={disableVerifyButton}
            showLoader={isVerifying}
            isFormValid={isValid}
            titles={titles}
            onVerifyClick={onVerifyClick}
            onDeleteClick={onDeleteClick}
            onClose={handleCancel}
          />
        </Fieldset>
        <WizardStepper
          //
          onBack={handleCancel}
          backLabel="Cancel"
          nextLabel="Add title"
          disabled={disableAddTitle}
          isLoading={isSubmitting}
        />
      </Form>
    );
  };

  const handlePreSubmit = (values: VerifyTitleFormModel): AddTitlesRequest => {
    const { titleVerificationResult } = values;
    const titlesToAdd = titleVerificationResult === undefined ? [] : titleVerificationResult.titles.filter(t => t.status !== TitleVerificationStatusEnum.TitleAlreadyAdded);
    return {
      participantId,
      titles: titlesToAdd.map(t => prepareTitleForSubmission(jurisdictionId, t.titleReference))
    };
  };

  const handleAddTitles = async (request: AddTitlesRequest) => {
    const { navigate } = props;
    try {
      await addAdditionalTitles(workspaceId, request);

      handleCancel();
      // Redirect to title detail page of new title
      const titleDetailLink = resolveTitleDetailLink({ workspaceId, participantId, titleReference: request.titles[0] });

      navigate(titleDetailLink);
    } catch (error) {
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  };

  const dialogClasses = React.useMemo(
    () => ({
      //
      root: classes.dialogRoot,
      dialogPaper: classes.dialogPaper,
      dialogTitle: classes.dialogTitle,
      dialogContent: classes.dialogContent
    }),
    [classes]
  );
  return (
    <ConfirmationDialog //
      title="Add new title"
      classes={dialogClasses}
      open={true}
      onClose={handleCancel}
      showActionButtons={false}
      scroll="body"
    >
      <Divider />
      <Formik
        initialValues={getInitialValues(jurisdictionId, existingTitleReferences)}
        validationSchema={validationSchema}
        onPreSubmit={handlePreSubmit}
        onSubmit={handleAddTitles}
      >
        {(formikProps: FormikProps<VerifyTitleFormModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}
