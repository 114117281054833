import { VicNameChange } from '@sympli-mfe/document-components/party-form/vic/2-28/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { VIC_WOC_PARTY_FORM_CONFIG } from '../../config';
import { getSelectedCaveat } from '../../helper';
import { CaveatModel, WithdrawalOfCaveat2_28_0Model } from '../../models';

interface PartyBookValidationContext {
  caveats: CaveatModel[];
}
// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: WithdrawalOfCaveat2_28_0Model): PartyBookValidationContext => {
  return {
    caveats: parentContext.withdrawCaveats
  };
};

const checkShouldValidateItem = ({ caveats }: PartyBookValidationContext, partyIdForCheck: PartyModel<VicNameChange>['id']) => {
  const selectedCaveat = getSelectedCaveat(caveats);
  const selectedCaveatorsIds = selectedCaveat?.caveators.map(x => x.partyBookId) ?? [];
  const isSelectedMortgagee = !!~selectedCaveatorsIds.indexOf(partyIdForCheck);
  return isSelectedMortgagee;
};
const yupPartyItem = resolvePartyModelValidationSchema<VicNameChange, PartyBookValidationContext>(VIC_WOC_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<VicNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
