import { defineAction } from 'redux-define';
import { createAction } from 'typesafe-actions';

import { AxiosError } from 'src/utils/http';

export interface GlobalErrorMessageModel {
  type?: 'success' | 'error' | 'warning' | 'document';
  title?: string; // custom title to be used in error dialog
  message?: string; // custom error message from back_end (message may be undefined)
  redirectOnClose?: string;
  icon?: JSX.Element; // custom icon,
  response?: AxiosError['response'];
  snackbar?: boolean;
  autohideSec?: number;
}
export const CREATE_GLOBAL_ERROR_MESSAGE = defineAction('CREATE_GLOBAL_ERROR_MESSAGE');
export const actionCreateGlobalErrorMessage = <T extends GlobalErrorMessageModel>(payload: T, meta?) => ({ type: CREATE_GLOBAL_ERROR_MESSAGE.ACTION, payload, meta });

export interface ErrorMessageQueueItemModel extends GlobalErrorMessageModel {
  id?: string; // unique id created by our saga
}

export const actionPushErrorToQueue = createAction(
  //
  'PUSH_ERROR_TO_QUEUE'
)<ErrorMessageQueueItemModel>();

export const actionRemoveFirstGlobalError = createAction(
  //
  'REMOVE_FIRST_GLOBAL_ERROR'
)();

export const actionRemoveGlobalErrorById = createAction(
  //
  'REMOVE_GLOBAL_ERROR_BY_ID'
)<//
{ id: string; snackbar?: boolean }>();
