import { Action, createReducer } from 'typesafe-actions';

import {
  //
  actionEnableMfaDialog,
  actionMfaCheckComplete
} from '../actions/mfaCheck';

interface AuthenticationDialogState {
  required: boolean;
  mfaId?: string;
}

const initialState: AuthenticationDialogState = {
  required: false,
  mfaId: undefined
};

const reducer = createReducer<
  //
  AuthenticationDialogState,
  Action
>(initialState)
  .handleAction(actionEnableMfaDialog, (_, action): AuthenticationDialogState => {
    const { mfaId } = action.payload;
    return {
      required: true,
      mfaId
    };
  })
  .handleAction(actionMfaCheckComplete, (): AuthenticationDialogState => {
    return {
      ...initialState
    };
  });

export default reducer;
