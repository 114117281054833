import React, { useCallback, useState } from 'react';

import { Form, FormikHelpers, FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import { CategoryEnum } from 'src/containers/workspace/financial/directions/views/edit-directions/forms/discharge-mortgage-directions/models';
import LinkedWorkspaceSearchContainer from 'src/containers/workspace/shared/components/linked-workspace-search';
import { SearchTableModel } from 'src/containers/workspace/shared/components/linked-workspace-search/components/linked-workspace-search-table/models';
import { modelKey } from 'src/utils/formUtils';
import { getInitialValues, saveLinkedSettlementHelper } from './helpers';
import { LinkedCategory, LinkSettlementDialogFormikModel } from './models';
import { getLinkedSettlementValidationSchema } from './validationSchema';

import type { SafeDispatch } from 'src/hooks';

export interface LinkSettlementDialogProps {
  workspaceId: string;
  participantId: string;
  clusterId?: string;
  workspaceRoleId?: HttpTypes.WorkspaceRoleEnum;
  onClose(): void;
  dispatch: SafeDispatch<Action>;
  categoryOptions: LookupEnumModel<LinkedCategory>[];
}

const fieldName = modelKey<LinkSettlementDialogFormikModel>();

function LinkSettlementDialog({ workspaceId, participantId, dispatch, categoryOptions, workspaceRoleId, onClose, clusterId }: LinkSettlementDialogProps) {
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClearSelectedRow = () => {
    setSelectedRow(undefined);
  };

  const handleOnSubmit = async (formikValues: LinkSettlementDialogFormikModel, formikHelpers: FormikHelpers<LinkSettlementDialogFormikModel>) => {
    setIsLoading(true);
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'linked-settlement',
      logGroupId: 'workspace',
      route: 'workspace-overview',
      action: 'save-linked-settlement',
      workspaceId,
      participantId,
      workspaceRoleId,
      category: formikValues.category
    });
    await saveLinkedSettlementHelper({ workspaceId, participantId, workspaceRoleId, formikValues, dispatch });
    onClose();
    setIsLoading(false);
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<LinkSettlementDialogFormikModel>) => {
      const { isValid, isSubmitting, submitForm, values } = formikProps;
      const disabled = !isValid || isSubmitting || !values.linkedSettlementItem.linkedWorkspaceId || selectedRow === undefined;
      const disableAmount = _get(formikProps.values, fieldName('linkedSettlementFundsNotRequired'));

      const handleOnLinkedWorkspaceRowClick = (rowIndex: number, rowData: SearchTableModel) => {
        const linkedSettlementItemField = fieldName('linkedSettlementItem');

        formikProps.setFieldValue(linkedSettlementItemField, {
          linkedWorkspaceId: rowData.workspaceId,
          linkedParticipantId: rowData.participantId,
          linkedToParticipantMatterReference: rowData.reference
        });

        setSelectedRow(rowIndex);
      };

      const handleOnLinkedPaymentCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { setFieldValue } = formikProps;

        setFieldValue(fieldName('amount'), event.target.checked ? '0' : '1');
      };

      return (
        <Form aria-label="link settlement" className="mt-[32px]">
          <FormGroup
            title="Financial Link"
            titleContainerClassName="mr-[24px] w-[300px] pr-[0px]"
            className="mb-[16px]"
            description={
              <FlexLayout className=" w-[290px]" flexDirection="column">
                <Typography variant="body2" className="text-[var(--neutral-500)] mb-[24px]">
                  Select the <b>category and enter the amount to be exchanged</b> between the linked workspaces (maximum 10). Also note that only one source and one payment per
                  workspace is permitted.
                </Typography>
                <Typography variant="body2" className="text-[var(--neutral-500)] mb-[24px]">
                  The <b>‘Amount’</b> entered is <b>shared across</b>, and can be <b>edited in, both workspaces.</b>
                </Typography>
                <Typography variant="body2" className="text-[var(--neutral-500)]">
                  A <b>$1.00 placeholder amount</b> has been provided in order to complete the link where the final amount is not yet known.
                </Typography>
              </FlexLayout>
            }
            fieldAreaDirection="column"
          >
            <Field
              label="Category"
              component={SelectField}
              name={fieldName('category')}
              fullWidth
              options={categoryOptions}
              className="mb-[24px]"
              disabled={categoryOptions.length === 1}
              placeholder="Please select"
            />
            <Field //
              label="Reference (optional)"
              component={InputField}
              fullWidth
              name={fieldName('reference')}
              className="mb-[24px]"
            />
            <Field //
              label="Amount ($)"
              name={fieldName('amount')}
              component={CurrencyInputField}
              className="w-[315px]"
              disabled={disableAmount}
            />
            <Divider className="bg-[var(--neutral-200)] mb-[24px] mt-[16px]" />
            <Field
              component={CheckboxField}
              name={fieldName('linkedSettlementFundsNotRequired')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnLinkedPaymentCheckboxChange(event)}
              label="Funds not required"
            />
            <FlexLayout flexDirection="row" className="mt-[-6px]">
              <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px] ml-[26px]">
                By checking this option you are choosing not to have any financial demands from the linked workspace. You will still be able to see a line item in both workspaces
                but with $0 value.
              </Typography>
            </FlexLayout>
          </FormGroup>
          <LinkedWorkspaceSearchContainer
            selectedRow={selectedRow}
            clearSelectedRow={handleClearSelectedRow}
            onRowClick={(rowIndex: number, rowData: SearchTableModel) => handleOnLinkedWorkspaceRowClick(rowIndex, rowData)}
            linkedSettlementSearchType={values.category === CategoryEnum.LinkedPayment ? 'excludeLinkedSourceFunds' : 'excludeLinkedDirections'}
            excludeWorkspaceId={workspaceId}
            excludeLinkedWorkspaceClusterId={clusterId}
            numberOfRowsDisplayed={3}
            disabled={!values.category}
            category={values.category}
            type={HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement}
          />
          <Divider className="bg-[var(--neutral-200)] mb-[8px] mt-[16px]" />
          <WizardStepper //
            disabled={disabled}
            isLoading={isLoading}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel="Link"
            color="primary"
            showBackIcon={false}
            showNextIcon={false}
            onNext={submitForm}
          />
        </Form>
      );
    },
    [categoryOptions, clusterId, isLoading, onClose, selectedRow, workspaceId]
  );

  return (
    <Formik //
      initialValues={getInitialValues(categoryOptions)}
      onSubmit={handleOnSubmit}
      validationSchema={getLinkedSettlementValidationSchema()}
    >
      {(formikProps: FormikProps<LinkSettlementDialogFormikModel>) => renderForm(formikProps)}
    </Formik>
  );
}

export default React.memo(LinkSettlementDialog);
