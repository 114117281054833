import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const categoryOptionsSelector = createSelector<
  {
    directionCategory: HttpTypes.DirectionCategoryEnum;
    currentParticipant?: HttpTypes.WorkspaceParticipant;
    directionsCategoriesDetail?: HttpTypes.WorkspaceDirectionsCategoriesApiResponse;
  },
  //
  HttpTypes.DirectionCategoryEnum | undefined,
  HttpTypes.WorkspaceParticipant | undefined,
  HttpTypes.WorkspaceDirectionsCategoriesApiResponse | undefined,
  LookupEnumModel<string, string>[]
>(
  args => args.directionCategory,
  args => args.currentParticipant,
  args => args.directionsCategoriesDetail,
  (directionCategory, currentParticipant, directionsCategoriesDetail) => {
    const workspaceRole = currentParticipant?.workspaceRole?.id;
    const { directionCategories, purchaserAddToVendorCategories } = directionsCategoriesDetail || {};

    let categories = directionCategories;
    // show different set of categories drop down for Purchaser role
    if (workspaceRole === HttpTypes.WorkspaceRoleEnum.Purchaser) {
      categories = directionCategory === HttpTypes.DirectionCategoryEnum.Settlement ? purchaserAddToVendorCategories : directionCategories;
    }

    return categories || [];
  }
);
