import { NO_FALLBACK, ObjectFallback } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { DeceasedTenancyDetailModel } from './models';

export const DECEASED_TENANCY_FALLBACK_MAP: ObjectFallback<DeceasedTenancyDetailModel> = {
  $children: {
    tenancyType: NO_FALLBACK,
    proprietorGroups: {
      $shouldAddDefaultItemToEmptyArray: false,
      $arrayItem: {
        $children: {
          parties: {
            $shouldAddDefaultItemToEmptyArray: false,
            $arrayItem: {
              $children: {
                dateOfDeath: {
                  $children: {
                    type: [null],
                    date: [null],
                    from: [null],
                    to: [null],
                    description: ['']
                  }
                },
                evidences: {
                  $shouldAddDefaultItemToEmptyArray: false,
                  $arrayItem: { $children: { type: [null], date: [null], documentReference: [''], defaultAdded: [false] } }
                },
                partyBookId: NO_FALLBACK,
                isSelected: NO_FALLBACK
              }
            }
          },
          isSelected: NO_FALLBACK,
          proprietorGroupType: NO_FALLBACK,
          shareFraction: NO_FALLBACK,
          isNoSurvivorshipJointType: NO_FALLBACK,
          minimumTrusteeThreshold: NO_FALLBACK
        }
      }
    }
  }
};
