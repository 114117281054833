import * as React from 'react';

import { SnackbarProps } from '@mui/material/Snackbar';

import MessageNotificationV2 from '../message-notification';

export interface GlobalSimpleNotificationProps {
  open: boolean;
  message: string | React.ReactNode;
  secondaryMessage?: string | React.ReactNode;
  variant?: 'success' | 'info' | 'warning' | 'error' | 'loading' | 'new-success' | 'new-warning' | 'new-error';
  autoHideDuration?: number;
  onClose?: (open: boolean) => void;
  expandableContent?: JSX.Element;
  transitionComponent?: SnackbarProps['TransitionComponent'];
  transitionDuration?: SnackbarProps['transitionDuration'];
}

function GlobalSimpleNotification({
  //
  variant = 'success',
  open,
  autoHideDuration = 3000,
  onClose,
  message,
  secondaryMessage,
  expandableContent,
  transitionComponent,
  transitionDuration
}: GlobalSimpleNotificationProps) {
  return (
    <MessageNotificationV2 //
      variant={variant}
      open={open}
      TransitionComponent={transitionComponent}
      transitionDuration={transitionDuration}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      primary={message}
      secondary={secondaryMessage}
      expandableContent={expandableContent}
      zIndex={4}
    />
  );
}

export default React.memo(GlobalSimpleNotification);
