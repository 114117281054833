import * as React from 'react';

import classNames from 'classnames';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import FeatureToggle from 'src/@core/auth/feature-toggle';
import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import LineLoader from 'src/@core/components/line-loader';
import { SEALED_WORKSPACE_STATUSES } from 'src/containers/shared/document-list/models';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import { TitleReferenceEntityModel } from 'src/models';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';
import TitlesAndAddressesAddContainer from '../titles-and-addresses-add';
import TitlesAndAddressesBase from '../titles-and-addresses-base/TitlesAndAddressesBase';
import TitlesAndAddressesDeleteContainer from '../titles-and-addresses-delete';
import { TitleListAction } from './models';
import { useStyles } from './styles';

export type TitleAndAddressesProps = {
  isLoading: boolean;
  workspaceId: string;
  participantId: string;
  // current participant info
  participantRoleId?: HttpTypes.WorkspaceRoleEnum;
  // basic workspace info
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  // full workspace info
  titleReferences: TitleReferenceEntityModel[];
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  loaderBackgroundColor: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  workspaceIsLocked: boolean;
  linkedWorkspaces?: LinkedWorkspaceApiResponseModel[];
  mode?: TitleListAction;
  onActionClick(mode: Omit<TitleListAction, 'default'>): void;
  isRemoveTitlesEnabled: boolean;
};

export type Props = TitleAndAddressesProps;

function TitleAndAddresses(props: Props) {
  const {
    //
    isLoading,
    workspaceId,
    participantRoleId,
    titleReferences = [],
    workspaceStatusId,
    workspaceIsLocked,
    linkedWorkspaces,
    loaderBackgroundColor,
    mode = 'default',
    onActionClick,
    isRemoveTitlesEnabled
  } = props;
  const classes = useStyles();
  const handleOnCancel = React.useCallback(() => {
    onActionClick('default');
  }, [onActionClick]);

  function getTitleList() {
    switch (mode) {
      case 'delete':
        return <TitlesAndAddressesDeleteContainer {...props} onCancel={handleOnCancel} />;

      case 'add':
        return <TitlesAndAddressesAddContainer {...props} onCancel={handleOnCancel} />;

      default:
        return <TitlesAndAddressesBase {...props} dataTestId="title-list" />;
    }
  }

  function isToolbarAvailableForWorkspace() {
    return Boolean(
      (mode === 'default' || mode === 'add') &&
        Number.isInteger(workspaceStatusId) &&
        !SEALED_WORKSPACE_STATUSES.includes(workspaceStatusId) &&
        !workspaceIsLocked &&
        // WEB-21114: Add title button should be hidden in a linked workspace (that is not the hero workspace)
        (linkedWorkspaces?.length ? linkedWorkspaces[linkedWorkspaces?.length - 1].workspaceId === workspaceId : true)
    );
  }

  function getToolbar() {
    const disableAddTitleButton = participantRoleId === HttpTypes.WorkspaceRoleEnum.SourceFunder || titleReferences.length === 20;
    const disableDeleteTitleButton = participantRoleId === HttpTypes.WorkspaceRoleEnum.SourceFunder || titleReferences.length === 0;

    return (
      <FeatureToggle featureToggleType={FeatureToggleEnum.additionalTitlesEnabled}>
        <FlexLayout flexDirection="row" alignItems="center" className={classes.sectionSpacingBottom}>
          <FlexLayout alignItems="center">
            <ButtonLink onClick={() => onActionClick('add')} disabled={disableAddTitleButton} className={classes.buttonLink}>
              Add
            </ButtonLink>
          </FlexLayout>
          {isRemoveTitlesEnabled && (
            <>
              <hr className={classes.vertical} />
              <FlexLayout alignItems="center">
                <ButtonLink onClick={() => onActionClick('delete')} disabled={disableDeleteTitleButton} className={classes.buttonLink}>
                  Delete
                </ButtonLink>
              </FlexLayout>
            </>
          )}
        </FlexLayout>
      </FeatureToggle>
    );
  }

  if (isLoading) {
    return (
      <>
        <LineLoader data-part="title-and-addresses" color={loaderBackgroundColor} variant="medium" icon widthPercent={35} />
        <LineLoader data-part="title-and-addresses" color={loaderBackgroundColor} variant="small" widthPercent={60} marginLeft={20} style={{ marginBottom: 8 }} />
      </>
    );
  }

  return (
    <>
      {getTitleList()}
      {isToolbarAvailableForWorkspace() && getToolbar()}
      <div className={classNames(classes.borderBottom)} />
    </>
  );
}

export default React.memo(TitleAndAddresses);
