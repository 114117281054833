import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import DocumentWorkflowPanel, { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import { DocumentActionInfo } from 'src/containers/workspace/shared/detail/models';
import DocumentViewer from '../../components/document-viewer';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from '../../models';

interface Props {
  queryParams: DocumentPageRouteParams;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
  reviewActionInfo: DocumentActionInfo;
  nextActionLabel: string;
  nextActionDisabled: boolean;
  isWorkflowLoading: boolean;
  isWorkflowStandard: boolean;
  displayDocumentAsPdf: boolean;
  handleOnClick: () => void;
  currentParticipant?: HttpTypes.WorkspaceParticipant;
}

function ViewDocument({
  //
  queryParams,
  documentDetail,
  jurisdictionId,
  workflowPanelProps,
  reviewActionInfo,
  nextActionLabel,
  nextActionDisabled,
  isWorkflowLoading,
  isWorkflowStandard,
  displayDocumentAsPdf,
  handleOnClick,
  currentParticipant
}: Props) {
  const { participantId } = queryParams;

  const documentWorkflowPanel = (
    <DocumentWorkflowPanel //
      disableStepper={isWorkflowLoading}
      {...workflowPanelProps}
      mode="dark"
    >
      {isWorkflowStandard && (
        <ActionLabel //
          documentActionInfo={reviewActionInfo}
          mode="dark"
          currentParticipantId={participantId}
        />
      )}
      <SympliButton //
        color="primary"
        variant="contained"
        arrowRight={true}
        disabled={nextActionDisabled}
        isLoading={isWorkflowLoading}
        onClick={handleOnClick}
        mode="dark"
      >
        {nextActionLabel}
      </SympliButton>
    </DocumentWorkflowPanel>
  );

  return (
    <>
      {documentWorkflowPanel}
      <DocumentViewer //
        displayDocumentAsPdf={displayDocumentAsPdf}
        signMultiple={false}
        queryParams={queryParams}
        documentDetail={documentDetail}
        jurisdictionId={jurisdictionId}
        currentParticipant={currentParticipant}
      />
    </>
  );
}

export default React.memo(ViewDocument);
