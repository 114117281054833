import * as yup from 'yup';

import yupAdditionalAttachments from '@sympli-mfe/document-forms-framework/components/sections/document-attachment/validation-schema';
import { memoizeSchema } from '@sympli-mfe/document-forms-framework/validation';

import { yupDeceasedTenancyDetailSchema } from '../../components/deceased-tenancy-detail/validationSchema';
import { resolvePartyRoleCapacitySchema } from '../../components/party-role-capacity/validationSchema';
import { ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS, APPLICANT_CAPACITY_LOOKUP_OPTIONS, GRANTEE_CAPACITY_LOOKUP_OPTIONS } from './enums';
import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from './models';
import yupApplicants from './sections/applicants/validationSchema';
import yupFinalHoldings from './sections/final-holdings/validationSchema';
import { yupGrantees } from './sections/grantees/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import { yupTitleReferences } from './sections/title-references/validationSchema';

export default yup
  .object<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model>()
  .partial({
    deceasedTenancyDetail: yupDeceasedTenancyDetailSchema,
    titleReferences: yupTitleReferences,
    applicantCapacity: resolvePartyRoleCapacitySchema(APPLICANT_CAPACITY_LOOKUP_OPTIONS),
    granteeCapacity: resolvePartyRoleCapacitySchema(GRANTEE_CAPACITY_LOOKUP_OPTIONS),
    grantees: yupGrantees,
    partyBook: yupPartyBook,
    applicants: yupApplicants,
    attachments: memoizeSchema(yupAdditionalAttachments(ADDITIONAL_ATTACHMENTS_DOCUMENT_TYPE_OPTIONS)),
    finalTenancyHoldings: yupFinalHoldings,
    // UI only properties
    invalidParties: yup.mixed(),
    partyBookApi: yup.mixed(),
    context: yup.mixed()
  })
  .defined()
  .log();
