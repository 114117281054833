import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientHeaders, ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class TrustAccountRequest {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method marks trust account request as reviewed. It sends a request to the
   * `/gateway/api/1.0/Settings/trust-accounts/requests/{requestId}/review`
   * API route.
   *
   * @param requestId - The trust account requests ID.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * sdk.settings.trustAccountRequest.review("requestId")
   * .then(() => {
   *   console.log('Review completed successfully')
   * })
   */
  async review(requestId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/trust-accounts/requests/${requestId}/review`,
      {
        method: 'PUT',
        body: {},
        ...config
      }
    );
  }

  /**
   * This method retrieves a registration template for trust account request by its ID . It sends a request to the
   * `/settings/trust-accounts/requests/{requestId}/getPdf`
   * API route.
   *
   * @param requestId - Trust accounts request ID.
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve a template for trust account request by its ID:
   *
   * ```ts
   * sdk.settings.trustAccountRequest.retrieveRegistrationTemplate("ta_request_123")
   * .then(({pdfUrl}) => {
   *   console.log(pdfUrl)
   * })
   * ```
   */
  async retrieveRegistrationTemplate(requestId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsTrustAccountsGetRegistrationFormApiResponse>( //
      `/settings/trust-accounts/requests/${requestId}/getPdf`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves a Lawn Society Approval Evidence template for trust account by its ID . It sends a request to the
   * `/settings/trust-accounts/requests/{requestId}/getQlsPdf`
   * API route.
   *
   * @param requestId - Trust accounts request ID.
   * @param config - Additional config to pass with the request.
   * @returns The details containing pdfUrl to template.
   *
   * @example
   * To retrieve a template for trust account request by its ID:
   *
   * ```ts
   * sdk.settings.trustAccountRequest.retrieveLawnSocietyApprovalEvidenceTemplate("ta_request_123")
   * .then(({pdfUrl}) => {
   *   console.log(pdfUrl)
   * })
   * ```
   */
  async retrieveLawnSocietyApprovalEvidenceTemplate(requestId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsTrustAccountsGetRegistrationFormApiResponse>( //
      `/settings/trust-accounts/requests/${requestId}/getQlsPdf`,
      {
        ...config
      }
    );
  }

  /**
   * This method uploads registration form related to trust account request. It sends a request to the
   * `/gateway/api/1.0/Settings/trust-accounts/requests/{requestId}/registration-form/{trustAccountRegistrationFormType}`
   * API route.
   *
   * @param subscriberId - The subscribers ID.
   * @param body - The data about the image.
   * @param config - Additional config to pass with the request.
   * @returns profileImageUrl
   *
   * @example
   * sdk.settings.trustAccountRequest.uploadRegistrationForm("sub_123", {
   *   base64String: "iVBORw0KGgoAAAANSUM4h...",
   *   imageType: "png"
   * })
   * .then((profileImageUrl) => {
   *   console.log('profileImageUrl')
   * })
   */
  async uploadRegistrationForm(
    requestId: string,
    args: {
      trustAccountRegistrationFormType: HttpTypes.TrustAccountRegistrationFormTypeEnum;
      file: File;
    },
    config?: ClientRequestConfig
  ) {
    const formData = new FormData();
    formData.append('File', args.file);

    const headers: ClientHeaders = {
      'Content-Type': null,
      ...config?.headers
    };

    return await this.#client.fetch<HttpTypes.SettingsTrustAccountsUploadRegistrationFormApiResponse>( //
      `/settings/trust-accounts/requests/${requestId}/registration-form/${args.trustAccountRegistrationFormType}`,
      {
        method: 'POST',
        body: formData,
        ...config,
        headers
      }
    );
  }
}
