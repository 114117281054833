import { HttpTypes } from '@sympli/api-gateway/types';

import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { StandaloneTableModel } from './table/models';

// this model is used when saving data into local storage
export interface StandaloneDashboardFilterModel {
  selectedTab: StandaloneDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;

  selectedFilters?: number[];
  defaultOpenFilter?: number; // we default open a filter only when it's added by + button
  lastAccessPeriod?: DashboardDateRangeModel;
  completedDatePeriod?: DashboardDateRangeModel;
  archivedDatePeriod?: DashboardDateRangeModel;
  jurisdictions?: number[];
  myTasks?: number[];
  documentTypes?: number[];
  documentStatus?: number[];
  workspaceStatus?: number[];
  groupIds?: string[];
  userIds?: string[];
}

export enum StandaloneDashboardRouteTabsEnumV2 {
  all = 'all',
  active = 'active',
  toBeReworked = 'toBeReworked',
  complete = 'complete',
  archived = 'archived',
  recent = 'recent'
}

export type StandaloneWorkspacesV2ApiRequest = DashboardTableFiltersModel & StandaloneWorkspaceV2FilterQueryParamsModel;

export type StandaloneWorkspacesV2ApiResponse = HttpTypes.PaginatedItemsModel<StandaloneTableModel>;

export interface StandaloneWorkspacesStateV2 {
  rowDetailIndex: number | undefined;

  items: StandaloneTableModel[];
  error?: string;
  status: ApiStatus;
  totalCount: number;

  query: StandaloneWorkspacesV2ApiRequest;

  updatedTime: Date;
}

export interface DashboardTableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
}

export enum SortingOptionEnum {
  LastAccessed = 'lastAccessed',
  CompletedDate = 'completedDate',
  ArchivedDate = 'archivedDate'
}

export interface StandaloneWorkspaceV2FilterQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  sympliId?: string;
  reference?: string;

  lastAccessPeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  completedDatePeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  archivedDatePeriod?: DashboardDateRangeModel | null; // null is used when we want to directly remove it from API query string
  jurisdictions?: number[];
  myTasks?: number[];
  documentTypes?: number[];
  documentStatus?: number[];
  workspaceStatus?: number[];

  userIds?: string[];
  groupIds?: string[];
}

export enum SortingFieldEnum {
  SettlementDate = 0,
  LastUpdated = 1,
  LastAccessed = 2
}
