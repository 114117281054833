import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchJurisdictionSummaryFeed = createAsyncAction(
  //
  'FETCH_JURISDICTIONS_SUMMARY_FEED',
  'FETCH_JURISDICTIONS_SUMMARY_FEED_SUCCESS',
  'FETCH_JURISDICTIONS_SUMMARY_FEED_ERROR'
)<
  //
  void,
  { data: HttpTypes.JurisdictionApiResponse[] },
  { error: Error }
>();

export const actionFetchJurisdictionsFeed = createAsyncAction(
  //
  'FETCH_JURISDICTIONS_FEED',
  'FETCH_JURISDICTIONS_FEED_SUCCESS',
  'FETCH_JURISDICTIONS_FEED_ERROR'
)<
  //
  void,
  { data: HttpTypes.JurisdictionsEnum[] },
  { error: Error }
>();

export const actionFetchJurisdictionDetail = createAsyncAction(
  //
  'FETCH_JURISDICTION_DETAIL',
  'FETCH_JURISDICTION_DETAIL_SUCCESS',
  'FETCH_JURISDICTION_DETAIL_ERROR'
)<
  //
  HttpTypes.JurisdictionsEnum,
  { data: HttpTypes.JurisdictionDetailApiResponse },
  { error: Error }
>();

export const actionFetchJurisdictionRevenueOffice = createAsyncAction(
  //
  'FETCH_JURISDICTION_REVENUE_OFFICE',
  'FETCH_JURISDICTION_REVENUE_OFFICE_SUCCESS',
  'FETCH_JURISDICTION_REVENUE_OFFICE_ERROR'
)<
  //
  HttpTypes.JurisdictionsEnum,
  { data: HttpTypes.JurisdictionRevenueOfficeApiResponse },
  { error: Error }
>();
