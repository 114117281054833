import { batch } from 'react-redux';
import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentFormAndDetailModel, SaveDocumentFormRequest } from 'src/containers/documents/models';
import { actionUpdateDocumentDataInWorkspaceDocuments, actionUpdateWorkspaceDocumentsStatus } from 'src/store/actions/workspace';

import type { SafeDispatch } from 'src/hooks';

export function docUpdateHelper(
  dispatch: SafeDispatch<Action>,
  queryParams: SaveDocumentFormRequest,
  response: HttpTypes.UnsignWorkspaceDocumentsApiResponse[],
  documentDetail: DocumentFormAndDetailModel
  // fetchWorkspaceDetail?: boolean
) {
  const { workspaceId, participantId, documentId } = queryParams;
  // update 'documentDetail' change for selected document
  const currentDocument = response.find(document => documentId === document.documentId);
  if (currentDocument) {
    const { documentPermissions, status, documentActions, documentWorkflow, documentDenyPermissions, isFullySignedByAllParticipants } = currentDocument;
    batch(() => {
      dispatch(
        actionUpdateDocumentDataInWorkspaceDocuments({
          documentId,
          documentPermissions,
          status,
          documentActions,
          documentWorkflow,
          documentDenyPermissions,
          isFullySignedByAllParticipants,
          hash: documentDetail.hash,
          data: documentDetail.data
        })
      );
      dispatch(
        actionUpdateWorkspaceDocumentsStatus({
          workspaceId,
          participantId,
          documents: [{ statusId: status, documentId, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants }]
        })
      );
    });
  }
  // not used
  // // fetch workspace detail again to:
  // // 1. update potential 'workspaceDetail' change
  // // 2. update 'workspaceDocuments' change for document list // TODO what is this and is it stil relevant, why are we not fetching documents?
  // if (fetchWorkspaceDetail) {
  //   // TODO explain why we need to refetch workspace and decide which one to use (full or basic)
  //   dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
  //   dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
  // }
}
