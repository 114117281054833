import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { SectionTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import ParticipantCollapseBox from './ParticipantCollapseBox';

export interface ParticipantCollapseBoxContainerProps {
  openAll: boolean;
  currentParticipantId: string;
  distributionsParticipant: HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number];
  workspaceParticipants: HttpTypes.WorkspaceParticipant[];
  hasItems: boolean;
  sectionType?: SectionTypeEnum;
  subTotalAmount: number;
  renderCollapseDetail: (distributionsParticipant: HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => React.ReactNode;
}

function ParticipantCollapseBoxContainer(props: ParticipantCollapseBoxContainerProps) {
  return (
    <ParticipantCollapseBox //
      {...props}
    />
  );
}

export default React.memo(ParticipantCollapseBoxContainer);
