import React, { useState } from 'react';

import classNames from 'classnames';
import { FormikProps, FormikValues, getIn, setIn, useField } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconInfoCircle, NewIconView } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { BusinessLogicError, PageActionEnum } from '@sympli/ui-logger';

import Tooltip from 'src/@core/components/tooltip';
import LinkedWorkspaceDisplayContainer from 'src/containers/shared/linked-workspace-display/LinkedWorkspaceDisplayContainer';
import LinkedWorkspaceSearchContainer from 'src/containers/workspace/shared/components/linked-workspace-search';
import { SearchTableModel } from 'src/containers/workspace/shared/components/linked-workspace-search/components/linked-workspace-search-table/models';
import { createModelKeyAppender, modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { DirectionsTrustAccountModel, DirectionTrustAccountLookupItemModel, LinkedSettlementItemModel, SourceFundFormikModel, TrustAccountMap } from '../../../../models';
import { useDirectionsCategories } from '../../../../reducers/categories';
import { CategoryEnum } from '../../forms/discharge-mortgage-directions/models';
import { isLoanAdvance, LOAN_ADVANCE_CATEGORY, LOAN_ADVANCE_RANGE_CATEGORY } from '../../forms/income-mortgage-directions/helper';
import { displayToastMessage, getKeyValueMatchingNumberInObject, saveFSSLineItem } from '../../helper';
import { FinancialLineItemLabelEnum, UnlinkLineItemTypeEnum } from '../../models';
import { useStyles } from './styles';

import type { SafeDispatch } from 'src/hooks';

export interface Props<T extends FormikValues> {
  workspaceId: string;
  participantId: string;
  arrayFieldName: string;
  formFieldName: string;
  formikProps: FormikProps<T>;
  currentParticipant: HttpTypes.WorkspaceParticipant;
  trustAccountOptions: Array<DirectionTrustAccountLookupItemModel>;
  trustAccountMap: TrustAccountMap;
  canSetAutoBalanceForIM?: boolean;
  autoBalanceDefaultValue?: boolean;
  dispatch: SafeDispatch<Action>;
  trustAccountBankDetailMap: TrustAccountMap;
  setFocusLabel: (focusLabel: FinancialLineItemLabelEnum | null) => void;
  linkedWorkspaceCluster?: HttpTypes.LinkedWorkspaceCluster;
  index: number;
}

const trustAccFieldName = createModelKeyAppender<HttpTypes.BankDetailsModel>(modelKey<DirectionsTrustAccountModel>()('bankDetails'));

function SourceFundEditor<T extends FormikValues>({
  //
  workspaceId,
  participantId,
  currentParticipant,
  trustAccountOptions,
  trustAccountMap,
  formFieldName,
  arrayFieldName,
  formikProps,
  canSetAutoBalanceForIM,
  autoBalanceDefaultValue,
  dispatch,
  trustAccountBankDetailMap,
  setFocusLabel,
  linkedWorkspaceCluster,
  index
}: Props<T>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLinkedSettlementRow, setSelectedLinkedSettlementRow] = useState<number | undefined>(undefined);
  const { values, setFieldValue, setValues, touched } = formikProps;
  const classes = useStyles();
  const { status, detail: directionsCategoriesDetail, error } = useDirectionsCategories(workspaceId, participantId);
  const trustAccountLoanAdvanceOptions = React.useMemo(() => trustAccountOptions.filter(option => option.canUseAsLoanAdvance), [trustAccountOptions]);
  const [, { value: details, error: formError }] = useField<SourceFundFormikModel>(formFieldName);
  const fieldName = React.useMemo(() => createModelKeyAppender<SourceFundFormikModel>(formFieldName), [formFieldName]);
  const filteredTrustAccountOptions: DirectionTrustAccountLookupItemModel[] = React.useMemo(
    () => (isLoanAdvance(details.category) ? trustAccountLoanAdvanceOptions : trustAccountOptions),
    [trustAccountLoanAdvanceOptions, trustAccountOptions, details.category]
  );
  const isLinkedSourceFundSaved = details.category === CategoryEnum.LinkedSourceFund && Boolean(details.linkedSettlementItem?.linkedToSympliId);
  const filteredSourceFundCategories: LookupEnumModel<string, string>[] | undefined = React.useMemo(() => {
    if (currentParticipant.workspaceRole.id !== HttpTypes.WorkspaceRoleEnum.IncomingMortgagee) {
      return directionsCategoriesDetail?.sourceFundsCategories;
    }

    return details.isAutoBalancingSurplusForIMAllowed
      ? directionsCategoriesDetail?.sourceFundsCategories.filter(x => x.name !== LOAN_ADVANCE_CATEGORY)
      : directionsCategoriesDetail?.sourceFundsCategories.filter(x => x.name !== LOAN_ADVANCE_RANGE_CATEGORY);
  }, [currentParticipant.workspaceRole.id, details.isAutoBalancingSurplusForIMAllowed, directionsCategoriesDetail?.sourceFundsCategories]);
  const isAutoBalanceVisible: boolean = React.useMemo(() => !!canSetAutoBalanceForIM && isLoanAdvance(details.category), [canSetAutoBalanceForIM, details.category]);

  const handleSaveSourceFund = React.useCallback(async () => {
    if (getIn(touched, formFieldName)) {
      setIsLoading(true);

      const distribution: SourceFundFormikModel = _get(values, formFieldName);

      const { category } = distribution;

      let res: boolean;
      if (isLoanAdvance(category)) {
        // This is loan advance
        const { amount, trustAccountId, reference, isAutoBalancingSurplusForIMAllowed, id } = distribution;
        res = await saveFSSLineItem(
          {
            workspaceId,
            participantId,
            requestPayload: {
              type: HttpTypes.DirectionTypeEnum.LoanAdvance,
              loanAdvance: {
                amount,
                accountId: trustAccountId,
                reference,
                isAutoBalancingSurplusForIMAllowed,
                id
              }
            }
          },
          dispatch,
          getKeyValueMatchingNumberInObject(values, 'isEditorOpen', true) === 1
        );
      } else {
        const { trustAccountId, amount, reference, category, categoryOther, id, linkedDetails, linkedSettlementItem } = distribution;
        const trustAccount = trustAccountBankDetailMap[trustAccountId];
        const existingLink: LinkedSettlementItemModel | undefined = linkedSettlementItem
          ? {
              linkedParticipantId: linkedSettlementItem?.linkedToParticipantId,
              linkedWorkspaceId: linkedSettlementItem?.linkedToWorkspaceId,
              linkedToParticipantMatterReference: linkedSettlementItem?.linkedToParticipantMatter
            }
          : undefined;

        if (category === CategoryEnum.LinkedSourceFund) {
          Logger.capturePageAction(PageActionEnum.FeatureTracking, {
            feature: 'linked-settlement',
            logGroupId: 'workspace',
            route: 'workspace-direction',
            action: 'save-linked-settlement',
            workspaceId,
            participantId,
            category: CategoryEnum.LinkedSourceFund
          });
        }

        res = await saveFSSLineItem(
          {
            workspaceId,
            participantId,
            requestPayload: {
              type: HttpTypes.DirectionTypeEnum.SourceFund,
              sourceFund: {
                //review do we need to do this ? is not category change already set the value?
                paymentMethod: category === CategoryEnum.LinkedSourceFund ? HttpTypes.PaymentMethodEnum.Linked : HttpTypes.PaymentMethodEnum.BankTransfer,
                trustAccountId: category === CategoryEnum.LinkedSourceFund ? undefined : trustAccountId,
                amount: Number(amount),
                reference: trustAccount && trustAccount.bankAccountType === HttpTypes.BankAccountTypeEnum.SympliSourceAccount ? trustAccount.bankDetails.accountNumber : reference,
                category,
                categoryOther,
                // We dont want to send the id on initial linked source fund creation
                // but we still want to send the id as-is for all non-linked source funds
                // This is an issue with default IM source fund/loan advance, as they have an id
                id: category !== CategoryEnum.LinkedSourceFund || isLinkedSourceFundSaved ? id : undefined,
                linkedSettlementItem: isLinkedSourceFundSaved ? existingLink : linkedDetails
              }
            }
          },
          dispatch,
          getKeyValueMatchingNumberInObject(values, 'isEditorOpen', true) === 1,
          linkedDetails?.linkedToParticipantMatterReference
        );
      }

      setIsLoading(false);
      if (category !== CategoryEnum.LinkedSourceFund) {
        displayToastMessage(res, dispatch);
      }

      if (res) {
        setFieldValue(fieldName('isEditorOpen'), false);
      }

      setFocusLabel(FinancialLineItemLabelEnum.SourceFund);
    } else {
      // * We do not update bank detail here because we do not care
      setFieldValue(fieldName('isEditorOpen'), false);
    }
  }, [
    //
    setFieldValue,
    fieldName,
    workspaceId,
    participantId,
    formFieldName,
    values,
    dispatch,
    trustAccountBankDetailMap,
    touched,
    setFocusLabel,
    isLinkedSourceFundSaved
  ]);

  const handleOnCategoryChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
    const trustAccountId = details.trustAccountId;
    const isSwitchToLoanAdvance = isLoanAdvance(resolvedValue);
    const shouldEnableAutoBalance = isSwitchToLoanAdvance && !!canSetAutoBalanceForIM && !!autoBalanceDefaultValue;
    const notContainTrustAccount = isSwitchToLoanAdvance && trustAccountId && !trustAccountLoanAdvanceOptions.some(option => option.id === trustAccountId);

    setValues(updatedValues => {
      let newValues = setIn(updatedValues, fieldName('isAutoBalancingSurplusForIMAllowed'), canSetAutoBalanceForIM ? shouldEnableAutoBalance : undefined);

      if (isSwitchToLoanAdvance) {
        newValues = setIn(newValues, fieldName('displayLoanAdvanceAmount'), details.amount);
      }

      if (notContainTrustAccount) {
        newValues = setIn(newValues, fieldName('trustAccountId'), '');
      }

      if (shouldEnableAutoBalance) {
        newValues = setIn(newValues, fieldName('category'), LOAN_ADVANCE_RANGE_CATEGORY);
      }

      if (resolvedValue !== CategoryEnum.LinkedSourceFund) {
        newValues = setIn(newValues, fieldName('linkedSettlementFundsNotRequired'), false);
        newValues = setIn(newValues, fieldName('linkedSettlementItem'), undefined);
        newValues = setIn(newValues, fieldName('paymentMethod'), undefined);

        // if there is only one trust account, by default select it
        const defaultTrustAccountId = trustAccountLoanAdvanceOptions.length === 1 ? trustAccountLoanAdvanceOptions[0].id : '';
        if (defaultTrustAccountId) {
          newValues = setIn(newValues, fieldName('trustAccountId'), defaultTrustAccountId);
        }
      } else {
        newValues = setIn(newValues, fieldName('amount'), 1);
        newValues = setIn(newValues, fieldName('paymentMethod'), HttpTypes.PaymentMethodEnum.Linked);
        newValues = setIn(newValues, fieldName('trustAccountId'), '');
      }

      handleClearSelectedRow();

      return newValues;
    });
  };

  const handleOnAutoBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue(fieldName('category'), checked ? LOAN_ADVANCE_RANGE_CATEGORY : LOAN_ADVANCE_CATEGORY);
  };

  const handleOnAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isLoanAdvance(details.category)) setFieldValue(fieldName('displayLoanAdvanceAmount'), value);
  };

  if (details == null) {
    const scope = Logger.scopeWithCustomAttributes({
      values
    });

    Logger.captureException(new BusinessLogicError(`Empty source fund details of ${formFieldName}`), scope);

    return null;
  }

  if (status === 'pending' || status === 'idle') {
    return <BlockLoader />;
  }

  if (status === 'rejected') {
    return error;
  }

  if (!filteredSourceFundCategories) {
    return null;
  }

  const selectedTrustAccountBankDetail = trustAccountMap[details.trustAccountId];

  // * Only show reference field if selected source fund account is NOT Sympli source account
  // * Use bank account number as reference for Sympli source account (See onPreSubmit function)
  const showReferenceField = selectedTrustAccountBankDetail == null || selectedTrustAccountBankDetail.bankAccountType !== HttpTypes.BankAccountTypeEnum.SympliSourceAccount;

  const handleClearSelectedRow = () => {
    setSelectedLinkedSettlementRow(undefined);
  };

  const renderDistributionDetailField = () => {
    if (details.category === CategoryEnum.LinkedSourceFund) {
      const disableAmount = details.linkedSettlementFundsNotRequired;

      const linkedSettlementItemField = fieldName('linkedSettlementItem');
      const linkedSettlementItemResponse: HttpTypes.LinkedSettlementItemModel = _get(values, linkedSettlementItemField);

      const handleOnLinkedPaymentCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(fieldName('amount'), event.target.checked ? 0 : 1);
      };

      const handleOnLinkedWorkspaceRowClick = (rowIndex: number, rowData: SearchTableModel) => {
        const linkedSettlementItemField = fieldName('linkedSettlementItem');
        const linkedSettlementItemRequest: LinkedSettlementItemModel = _get(values, linkedSettlementItemField);

        setFieldValue(linkedSettlementItemField, {
          ...linkedSettlementItemRequest,
          linkedWorkspaceId: rowData.workspaceId,
          linkedParticipantId: rowData.participantId
        });

        const linkedDetailsField = fieldName('linkedDetails');

        setFieldValue(linkedDetailsField, {
          ...linkedSettlementItemRequest,
          linkedWorkspaceId: rowData.workspaceId,
          linkedParticipantId: rowData.participantId,
          linkedToParticipantMatterReference: rowData.reference
        });

        setSelectedLinkedSettlementRow(rowIndex);
      };

      return (
        <FlexLayout alignItems="center" flexWrap="wrap" className="grow">
          <FlexLayout justifyContent="flex-start" fullWidth alignItems="center">
            <Field //
              label="Amount ($)"
              name={fieldName('amount')}
              component={CurrencyInputField}
              className={classNames(classes.smallField, classes.marginRight, 'w-[315px]')}
              disabled={disableAmount}
              allowNegative={false}
            />
          </FlexLayout>
          {!isLinkedSourceFundSaved && (
            <FlexLayout flexDirection="row" className="mt-[-6px]">
              <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px]">
                The <b>'Amount'</b> entered is <b>shared across</b>, and can be <b>edited in, both workspaces.</b> A <b>$1.00 placeholder amount</b> has been provided in order to
                complete the link where the final amount is not yet known.
              </Typography>
            </FlexLayout>
          )}
          <Field
            component={CheckboxField}
            disabled={isLinkedSourceFundSaved}
            name={fieldName('linkedSettlementFundsNotRequired')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnLinkedPaymentCheckboxChange(event)}
            label="Funds not required"
          />
          {!isLinkedSourceFundSaved && (
            <FlexLayout flexDirection="row" className="mt-[-6px]">
              <Typography variant="body2" className="text-[var(--neutral-600)] mb-[8px] ml-[26px]">
                By checking this option you are choosing not to have any financial demands from the linked workspace. You will still be able to see a line item in both workspaces
                but with $0 value.
              </Typography>
            </FlexLayout>
          )}
          <FlexLayout flexDirection="column" fullWidth alignItems="center">
            {!isLinkedSourceFundSaved ? (
              <LinkedWorkspaceSearchContainer
                selectedRow={selectedLinkedSettlementRow}
                clearSelectedRow={handleClearSelectedRow}
                onRowClick={(rowIndex: number, rowData: SearchTableModel) => handleOnLinkedWorkspaceRowClick(rowIndex, rowData)}
                linkedSettlementSearchType="excludeLinkedDirections"
                excludeWorkspaceId={workspaceId}
                excludeLinkedWorkspaceClusterId={linkedWorkspaceCluster?.clusterId}
                type={HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement}
              />
            ) : (
              <LinkedWorkspaceDisplayContainer //
                isLoading={isLoading}
                linkedWorkspaceItem={linkedSettlementItemResponse}
                id={details.id!} // it must exist when we reach here
                workspaceId={workspaceId}
                participantId={participantId}
                unlinkLineItemTypeEnum={UnlinkLineItemTypeEnum.UnlinkSourceFund}
                formikProps={formikProps}
                index={index}
                arrayFieldName={arrayFieldName}
              />
            )}
          </FlexLayout>
          {isLinkedSourceFundSaved && (
            <FlexLayout flexDirection="row" className="mt-[-6px] pt-[10px] w-full bg-[var(--greek-waters-translucent)] mb-[32px] mt-[8px]">
              <NewIconView fill="var(--sympli-green)" />
              <Typography variant="body2" className="text-[var(--neutral-600)] pl-[8px] mb-[8px]">
                This workspace is part of a <b>linked settlement</b> which includes <b>{linkedWorkspaceCluster?.numberOfLinkedWorkspaces} linked workspaces.</b>
                To unlink this workspace and delete the line item simply click the <b>unlink</b> or <b>delete</b> buttons.
              </Typography>
            </FlexLayout>
          )}

          <SympliButton
            className={classNames(classes.saveButton, isLinkedSourceFundSaved ? 'w-[120px]' : 'w-[64px]')}
            color="primary"
            variant="contained"
            onClick={handleSaveSourceFund}
            isLoading={isLoading}
            disabled={!!formError || isLoading || (selectedLinkedSettlementRow === undefined && !isLinkedSourceFundSaved)}
          >
            {isLinkedSourceFundSaved ? 'Update' : 'Link'}
          </SympliButton>
        </FlexLayout>
      );
    }

    return (
      <>
        <FlexLayout alignItems="center">
          <Field
            readOnly={Boolean(filteredTrustAccountOptions.length === 1 && details.trustAccountId)}
            label="Account description"
            component={SelectField}
            placeholder={resolveSelectPlaceholder(true)}
            name={fieldName('trustAccountId')}
            options={filteredTrustAccountOptions}
            className={classNames(classes.growField, classes.marginRight)}
          />
          {showReferenceField && (
            <Field label="Reference (optional)" name={fieldName('reference')} component={InputField} className={classNames(classes.largeField, classes.marginRight)} />
          )}
        </FlexLayout>

        <FlexLayout alignItems="center">
          <Input //
            readOnly
            label="Account name"
            // the name here is not real form mapping
            name={`selectedTrustAccount.${trustAccFieldName('accountName')}`} // danger:disable
            value={selectedTrustAccountBankDetail?.bankDetails.accountName || ''}
            className={classNames(classes.growField, classes.marginRight)}
          />
          <Field //
            label="Amount ($)"
            name={fieldName('amount')}
            onChange={handleOnAmountChange}
            component={CurrencyInputField}
            className={classNames(classes.amountField, classes.labelCenter, classes.marginRight)}
            data-testid="sourceFund-editor-amount"
            allowNegative={false}
          />
        </FlexLayout>
        <FlexLayout justifyContent="flex-end" fullWidth alignItems="center">
          {isAutoBalanceVisible && (
            <>
              <Field //
                label="Enable auto balance"
                name={fieldName('isAutoBalancingSurplusForIMAllowed')}
                component={CheckboxField}
                className={classes.autoBalanceCheckBox}
                onChange={handleOnAutoBalanceChange}
              />
              <Tooltip title={renderToolTipContent()} placement="top">
                <IconInfoCircle className={classes.iconInfo} />
              </Tooltip>
            </>
          )}
          <SympliButton
            variant="contained"
            color="primary"
            isLoading={isLoading}
            disabled={!!formError || isLoading}
            className={classNames(classes.saveButton, classes.marginRight)}
            onClick={handleSaveSourceFund}
          >
            Update
          </SympliButton>
        </FlexLayout>
      </>
    );
  };

  return (
    <div className={classes.editorBox}>
      <FlexLayout flexDirection="column">
        <FlexLayout flexDirection="row">
          <Field
            label="Category"
            component={SelectField}
            placeholder={resolveSelectPlaceholder(true)}
            name={fieldName('category')}
            className={classes.category}
            options={filteredSourceFundCategories}
            onChange={handleOnCategoryChange}
            readOnly={filteredSourceFundCategories.length === 1 || isLinkedSourceFundSaved}
          />
          {details.category === CategoryEnum.Other && <Field label="Category name" component={InputField} className={classes.categoryName} name={fieldName('categoryOther')} />}
          {details.category === CategoryEnum.LinkedSourceFund && (
            <Field //
              label="Reference (optional)"
              component={InputField}
              className={classes.categoryName}
              name={fieldName('reference')}
            />
          )}
        </FlexLayout>
        {details.category === CategoryEnum.LinkedSourceFund && !isLinkedSourceFundSaved && (
          <FlexLayout flexDirection="row" className="mt-[-6px]">
            <NewIconView fill="#D6AB00" />
            <Typography variant="body2" className="text-[var(--neutral-600)] pl-[8px] mb-[8px]">
              You have selected a <b>shared line item</b> that requires funds from another workspace by creating a link (maximum 10). Note that only 1 source and 1 payment per
              workspace is permitted.{' '}
            </Typography>
          </FlexLayout>
        )}
        {details.category === CategoryEnum.LinkedSourceFund && isLinkedSourceFundSaved && (
          <FlexLayout flexDirection="row" className="mt-[-6px]">
            <Typography variant="body2" className="text-[var(--neutral-600)] text-[13px] pl-[8px] mb-[8px]">
              The Linked Source Fund category can no longer be changed. To remove this line item please delete it.
            </Typography>
          </FlexLayout>
        )}
        {renderDistributionDetailField()}
      </FlexLayout>
    </div>
  );

  function renderToolTipContent() {
    return (
      <Typography className={classes.toolTipContent}>
        If surplus funds are in the workspace, the system will automatically reduce the loan advance amount when all parties approve their Financial Settlement Schedule. The
        reduced loan advance amount will equal the total payments amount in the workspace.
      </Typography>
    );
  }
}

export default React.memo(SourceFundEditor);
