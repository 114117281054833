import * as React from 'react';

import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import { colors } from 'src/theme';
import LodgementCaseStatusTimestamp from '../components/lodgement-case-status-timestamp';

interface Props {
  lodgementDetail: HttpTypes.LodgementDetail;
}

function PreparedRegistrationOnly({ lodgementDetail }: Props) {
  return (
    <>
      <Typography variant="body2_bold" sx={{ color: colors.WHITE, textTransform: 'uppercase' }}>
        Lodgement Verification - Successful
      </Typography>
      <LodgementCaseStatusTimestamp lodgementDetail={lodgementDetail} />
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(2, 0, 0, 0) }}>
        The Lodgement Case has been <b>successfully verified</b> with the Land Registry.
      </Typography>
      <Typography variant="body2" sx={{ color: colors.WHITE, margin: theme => theme.spacing(1, 0, 0, 0) }}>
        If you have not done so, you can now sign your documents.
      </Typography>
    </>
  );
}

export default React.memo(PreparedRegistrationOnly);
