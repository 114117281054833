import React from 'react';

import { MessageNotificationContainer, MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';

import { useWorkspacePersistedNotifications } from 'src/hooks';

export interface Props {
  workspaceId: string;
  participantId: string;
}

const EXPIRY_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days

const WorkspacePersistedNotification = ({ workspaceId, participantId }: Props) => {
  const [notifications, setNotifications] = useWorkspacePersistedNotifications(workspaceId, participantId);

  if (!notifications || notifications?.length === 0) return null;

  return (
    <MessageNotificationContainer>
      {notifications?.map(value => {
        const onNotificationClose = (open: boolean) => {
          value.onClose?.(open);
          if (!open) {
            const remainingNotifications = notifications.filter(notification => notification.messageId !== value.messageId);
            setNotifications(remainingNotifications);
          }
        };
        return (
          <MessageNotificationWithLocalStorage //
            storageNamespace={`${workspaceId}/${participantId}/workspaceNotifications`}
            key={`${participantId}-${value.messageId}`}
            messageId={value.messageId}
            variant={value.variant}
            open={true}
            TransitionComponent={value.transitionComponent}
            transitionDuration={value.transitionDuration}
            expiry={EXPIRY_DURATION}
            onClose={onNotificationClose}
            primary={value.message}
            secondary={value.secondaryMessage}
            expandableContent={value.expandableContent}
          />
        );
      })}
    </MessageNotificationContainer>
  );
};

export default React.memo(WorkspacePersistedNotification);
