import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';
import { sharedStyles } from '../../sharedStyles';

const styles = (theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    root: {},
    content: {
      minHeight: 376
    },
    headerContainer: {
      height: 56
    },
    headerLabel: {
      fontFamily: 'volkswagen_serial-bold',
      color: theme.palette.common.white
    },

    backButton: {
      top: 8,
      left: 0,
      position: 'absolute',
      height: '40px',
      width: '40px'
    },

    // Selected participant
    participant: {
      // paddingLeft: 15,
      // paddingRight: 10
    },
    participantsList: {
      margin: '20px 16px 16px 16px',
      paddingBottom: 16,
      borderBottom: `1px solid ${colors.NEUTRAL_100}`
    },
    participantsListTitle: {
      color: colors.NEUTRAL_600,
      marginBottom: 12
    },
    participantName: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      color: colors.NEUTRAL_1000
    },
    participantRole: {
      fontSize: '13px',
      lineHeight: '20px',
      color: colors.NEUTRAL_500,
      fontWeight: 400
    },
    additionalDesc: {
      padding: '0 16px 16px 16px',
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: colors.NEUTRAL_600
    },

    // Conversation detail form
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    messengerActionSection: {
      paddingRight: 2
    },
    selectedParticipant: {
      alignItems: 'center',
      height: 32,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      marginBottom: 4,
      padding: '0px 8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      background: theme.palette.background.default
    },
    subjectBox: {
      padding: '24px 16px 0 16px'
    },
    messageTitle: {
      color: colors.NEUTRAL_600
    },
    subjectField: {
      width: '100%',
      marginBottom: 0,
      marginTop: 8
    },
    subjectMultiline: {
      maxHeight: 50,
      overflowY: 'hidden'
    },
    multiline: {
      maxHeight: 68,
      overflowY: 'auto'
    },
    multilineInput: {
      overflowY: 'hidden'
    },
    submitIcon: {
      fill: theme.palette.common.white
    },
    loader: {
      marginLeft: -2,
      marginRight: -2
    },
    helperTextError: {
      marginTop: 0,
      paddingTop: 2,
      paddingLeft: 10,
      top: '95%',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      width: 280,
      lineHeight: 1.5,
      color: `${theme.palette.common.white} !important`,
      backgroundColor: theme.palette.error.main
    },
    marginBottom: {
      marginBottom: 20
    },
    privacyLink: {
      fontWeight: '700'
    },
    checkbox: {
      marginTop: '-20px'
    },
    continueButton: {
      '&.Mui-disabled': {
        opacity: 0.4,
        color: colors.GREEK_WATERS_DARK
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
