import * as yup from 'yup';

import { BaseTitleReferenceModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import yupTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { $oneOfItems, createContextCheck } from '@sympli-mfe/document-forms-framework/utils';
import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import { DischargeOfMortgage2_24_0Model } from '../../models';

export default yup //
  .mixed<BaseTitleReferenceModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: createContextCheck(
        (context: DischargeOfMortgage2_24_0Model): boolean => context.titleReferences.length > 0 && $oneOfItems(context.mortgages ?? [], item => item.isSelected === true)
      ),
      validationWhenVisible: yupTitleReferences,
      isObjectOrArray: true
    })
  );
