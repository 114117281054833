import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchWorkspaceAssignableGroups = createAsyncAction(
  //
  'FETCH_WORKSPACE_ASSIGNABLE_GROUPS',
  'FETCH_WORKSPACE_ASSIGNABLE_GROUPS_SUCCESS',
  'FETCH_WORKSPACE_ASSIGNABLE_GROUPS_ERROR'
)<
  //
  void,
  { data: HttpTypes.WorkspaceAssignableGroupListApiResponse },
  { error: Error }
>();
