import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/@core/theme/colors';

export const SCROLL_BAR_WIDTH = '6px';
export const TABLE_WIDTH = 908;
const TABLE_WIDTH_PLUS_MARGIN = '940px'; // TABLE_WIDTH(908px) + LEFT_PADDING(8px) + RIGHT_PADDING(8px) + GAP_BETWEEN_TABLE_AND_SCROLLBAR(16px)

export const useStyles = makeStyles(
  (theme: Theme) => ({
    scrollBar: {
      borderRadius: 0,
      boxShadow: 'none',

      '&::-webkit-scrollbar': {
        width: SCROLL_BAR_WIDTH
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
        borderRadius: 30
      }
    },
    root: {
      width: `calc(${TABLE_WIDTH_PLUS_MARGIN} + ${SCROLL_BAR_WIDTH})`
    },
    loader: {
      width: `calc(${TABLE_WIDTH_PLUS_MARGIN} + ${SCROLL_BAR_WIDTH})`
    },
    typographyRoot: {
      width: 220,
      textAlign: 'center'
    },
    typography: {
      color: '#94979A',
      marginBottom: 16
    },
    paper: {
      minHeight: 300,
      height: 'calc(100vh - 400px)',
      maxHeight: 329, // 7 rows + 1 header = 7 * 41(40 height + 1px border bottom) + 1 * 42 (40 height + 1px border top + 1px border bottom)
      width: `calc(${TABLE_WIDTH_PLUS_MARGIN} + ${SCROLL_BAR_WIDTH})`,
      boxShadow: 'none',
      borderRadius: 'none'
    },
    noPaddingX: {
      paddingLeft: 0,
      paddingRight: 0
    },
    noBorder: {
      border: 'none'
    },
    button: {
      borderRadius: 34,
      borderWidth: 2,
      borderColor: colors.SUNNY_DAY,
      padding: '6px 24px',
      color: colors.SUNNY_DAY_DARK,
      '&:hover': {
        borderColor: colors.SUNNY_DAY,
        backgroundColor: colors.SUNNY_DAY_LITE,
        borderWidth: 2
      }
    },
    bgWhite: {
      backgroundColor: '#FFF'
    }
  }),
  {
    name: 'SearchBoxTable'
  }
);
