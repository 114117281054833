import * as React from 'react';

import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';

import { actionRemoveFirstGlobalError, ErrorMessageQueueItemModel } from '../../store/actions/globalErrors';
import MessageDialog from './message-dialog';
import { DialogTypeEnum } from './message-dialog/models';

import type { SafeDispatch } from '../../hooks';

interface Props {
  error?: ErrorMessageQueueItemModel;
  dispatch: SafeDispatch<Action>;
  navigate: NavigateFunction;
}

class GlobalDialog extends React.Component<Props> {
  private cachedError: ErrorMessageQueueItemModel;

  render() {
    const { error } = this.props;

    // cache the latest error object, if there is no error anymore, use the previous error as content(placeholder)
    this.cachedError = error || this.cachedError;
    return (
      <MessageDialog
        open={!!error}
        type={this.cachedError?.type === DialogTypeEnum.Success ? DialogTypeEnum.Success : DialogTypeEnum.Error}
        title={this.cachedError?.title ?? ''}
        message={this.cachedError?.message ?? ''}
        onIgnore={this.handleOnIgnore}
        onReport={this.handleOnReport}
        icon={this.cachedError?.icon}
      />
    );
  }

  private handleOnIgnore = () => {
    const { error } = this.props;

    if (error?.redirectOnClose) {
      this.props.navigate(error.redirectOnClose);
    }
    this.props.dispatch(actionRemoveFirstGlobalError());
  };

  private handleOnReport = () => {
    const { error } = this.props;

    if (error?.redirectOnClose) {
      this.props.navigate(error.redirectOnClose);
    }
    this.props.dispatch(actionRemoveFirstGlobalError());
  };
}

export default GlobalDialog;
