import { resolveConsiderationFallback } from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { resolveReceivingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { resolveRelinquishingTenancyDetailFallback } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/fallback';
import { FallbackMap, NO_FALLBACK } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { Transfer2_24_1Model } from './models';

// this file was automatically generated from fallback.ts.mustache
export const fallbackMap: FallbackMap<Transfer2_24_1Model> = {
  workspaceTitleReferences: NO_FALLBACK,
  partyBookApi: NO_FALLBACK,
  partyBook: NO_FALLBACK,
  titleReferences: NO_FALLBACK,
  transferors: resolveRelinquishingTenancyDetailFallback(),
  transferees: resolveReceivingTenancyDetailFallback({ initTrustDetails: true }),
  consideration: resolveConsiderationFallback(),
  transferorsOnTitle: NO_FALLBACK,
  attachments: {
    $arrayItem: {
      $children: {
        attachment: NO_FALLBACK,
        documentType: NO_FALLBACK
      }
    },
    $shouldAddDefaultItemToEmptyArray: false
  },
  precedingData: NO_FALLBACK
};
