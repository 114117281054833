import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import { dateTimeLine } from 'src/utils/formatters';
import { ApiStatus } from 'src/utils/http';
import { actionFetchWorkspaceReportsFeed, WorkspaceReportsApiRequest } from './actions';

export interface WorkspaceReportsFeedState {
  items: Array<HttpTypes.WorkspaceReport>;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceReportsApiRequest;
}

const initialState: WorkspaceReportsFeedState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceReportsFeed(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.workspaceReportsFeed);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  WorkspaceReportsFeedState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceReportsFeed.request, (state, action): WorkspaceReportsFeedState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchWorkspaceReportsFeed.success, (state, action): WorkspaceReportsFeedState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data.map(item => ({
        ...item,
        createdDate: dateTimeLine(item.createdDate, 'yyyy-mm-dd')
      }))
    };
  })
  .handleAction(actionFetchWorkspaceReportsFeed.failure, (state, action): WorkspaceReportsFeedState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  });

export default reducer;
