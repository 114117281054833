import * as React from 'react';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import NavTabs from 'src/containers/dashboard/components/nav-tabs';
import ReassignBar from 'src/containers/dashboard/components/reassign-bar';
import RefreshIcon from 'src/containers/dashboard/components/refresh-icon';
import RefreshIconMessage from 'src/containers/dashboard/components/refresh-icon-message';
import WelcomeMessage from 'src/containers/dashboard/components/welcome-message';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { ScreenSizeEnum } from 'src/theme';
import { ApiStatus } from 'src/utils/http';
import Aggregators from './aggregators';
import { AggregatorsQueryParamsModel, FinancialDashboardAggregatorApiResponse } from './aggregators/models';
import FiltersContainer from './filters';
import HeaderContainer from './header/HeaderContainer';
import { resolveFilterTabName } from './helper';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from './models';
import FinancialTable from './table';
import { FinancialTableModel } from './table/models';

interface FinancialWorkspacesProps {
  workspaceStatusGroup: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
  // header component props
  screenSize: ScreenSizeEnum;
  onAssignmentTypeChange(assignmentType: AssignmentTypeEnum): void;
  createNewButtonNode: React.ReactNode;
  // aggregators component props
  localStorageKey: string;
  fetchAggregatorInfo(args: AggregatorsQueryParamsModel): Promise<FinancialDashboardAggregatorApiResponse>;
  onAggregatorClick(filters: FinancialWorkspacesV2ApiRequest, engagedTab: FinancialDashboardRouteTabsEnumV2, currentTabFilters: DashboardFilterModel): void;
  // nav-tabs component props
  onWorkspaceStatusTabChange(workspaceStatusGroup: FinancialDashboardRouteTabsEnumV2): void;
  // table filters component props
  onTableFiltersReset(): void;
  currentTableFilters: DashboardFilterModel;
  onTableAddFilterChange(currentTabFilters: DashboardFilterModel): void;
  onTableFiltersChange(changedFilter: Partial<FinancialWorkspacesV2ApiRequest>, currentTabFilters: DashboardFilterModel): void;

  // table component props
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
  rows: FinancialTableModel[];
  apiStatus?: ApiStatus;
  totalCount: number;
  onPageChange(args: { pageNumber: number }): void;
  onSortChange?(args: {
    //
    sortBy: string;
    sortOrder: DashboardSortDirectionEnum;
  }): void;

  rowDetailNode: React.ReactNode;
  rowDetailIndex?: number;
  onRowToggle(rowIndex: number, rowData: FinancialTableModel): void;
  onRowClick?(rowIndex: number, rowData: FinancialTableModel): void;

  // Reassign Welcome
  shouldShowWelcomeMessage: boolean;
  dismissWelcomeMessageHandler(): void;
  selectedRowIndexes: number[];
  onSelectionChange: (selectedRowIndexes: number[]) => void;

  shouldShowReassignBar: boolean;
  handleOnDismissReassignBar: React.MouseEventHandler<SVGElement>;
  selectedItems?: Array<{ workspaceId: string; participantId: string }>;
  groupId?: string;
  resetSelectedItems: () => void;
  autoRefresh: () => void;
  refetchingAggregators?: boolean;
  isRefetchingData?: boolean;
  refreshHandler: () => void;

  dismissRefreshIconMessageHandler(): void;
  shouldShowRefreshMessage: boolean;
}

function FinancialWorkspaces(props: FinancialWorkspacesProps) {
  const {
    workspaceStatusGroup,
    // header component props
    assignmentType,
    onAssignmentTypeChange,
    createNewButtonNode,
    // aggregators component props
    localStorageKey,
    fetchAggregatorInfo,
    onAggregatorClick,
    // nav-tabs component props
    onWorkspaceStatusTabChange,
    // table filters component props
    currentTableFilters,
    onTableAddFilterChange,
    onTableFiltersReset,
    onTableFiltersChange,
    // table props
    pageSize,
    pageNumber,
    sortBy,
    sortOrder,
    rows,
    apiStatus,
    totalCount,
    onPageChange,
    onSortChange,
    rowDetailNode,
    rowDetailIndex,
    onRowToggle,
    onRowClick,
    // table props for checkbox
    selectedRowIndexes,
    onSelectionChange,
    // reassign welcome
    shouldShowWelcomeMessage,
    dismissWelcomeMessageHandler,
    // reassign bar
    shouldShowReassignBar,
    handleOnDismissReassignBar,
    selectedItems,
    groupId,
    resetSelectedItems,
    autoRefresh,
    refetchingAggregators,
    isRefetchingData,
    refreshHandler,
    dismissRefreshIconMessageHandler,
    shouldShowRefreshMessage
  } = props;

  const [paginationInfo, setPaginationInfo] = React.useState<React.ReactNode>(null);

  const WORKSPACE_STATUS_GROUPS =
    assignmentType === AssignmentTypeEnum.ReAssigned
      ? Object.values(FinancialDashboardRouteTabsEnumV2).filter(x => x !== FinancialDashboardRouteTabsEnumV2.archived && x !== FinancialDashboardRouteTabsEnumV2.complete)
      : Object.values(FinancialDashboardRouteTabsEnumV2);

  // todo: move the below to another layer. this can be purely for function setup
  return (
    <>
      <HeaderContainer //
        assignmentType={assignmentType}
        onAssignmentTypeChange={onAssignmentTypeChange}
        title={'Financial Dashboard'}
      >
        {createNewButtonNode}
      </HeaderContainer>

      <Aggregators //
        localStorageKey={localStorageKey}
        assignmentType={assignmentType}
        onActionClick={onAggregatorClick}
        fetchAggregatorInfo={fetchAggregatorInfo}
        refetching={refetchingAggregators}
      />

      <Box sx={{ position: 'relative' }}>
        <NavTabs<FinancialDashboardRouteTabsEnumV2> //
          tabs={WORKSPACE_STATUS_GROUPS}
          selectedTab={workspaceStatusGroup}
          onChange={onWorkspaceStatusTabChange}
          // resolveHref={tabItem => `/financial-workspaces/${tabItem}`}
          resolveLabel={resolveFilterTabName}
        />
        {shouldShowRefreshMessage && <RefreshIconMessage dismissMessageHandler={dismissRefreshIconMessageHandler} />}
        <RefreshIcon isRefetching={isRefetchingData} refreshHandler={refreshHandler} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          marginBottom: '16px',
          '& div p': {
            margin: 0
          }
        }}
      >
        <div>{paginationInfo}</div>
        <ButtonBase
          sx={{
            color: 'var(--neutral-500)',
            fontFamily: 'Roboto',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
            textDecorationLine: 'underline',
            ml: 0.5,
            minHeight: 16,
            height: 16
          }}
          onClick={onTableFiltersReset}
        >
          Reset filters
        </ButtonBase>
      </Box>

      <FiltersContainer
        //
        assignmentType={assignmentType}
        selectedTab={workspaceStatusGroup}
        currentTableFilters={currentTableFilters}
        onAddFilterChange={onTableAddFilterChange}
        onFilterChange={onTableFiltersChange}
      />

      <FinancialTable //
        assignmentType={assignmentType}
        workspaceStatusGroup={workspaceStatusGroup}
        onPageChange={onPageChange}
        // disable sorting for archived tab
        onSortChange={onSortChange}
        onPaginationInfoChange={setPaginationInfo}
        pageSize={pageSize}
        pageNumber={pageNumber}
        rows={rows}
        apiStatus={apiStatus}
        totalCount={totalCount}
        rowDetailNode={rowDetailNode}
        rowDetailIndex={rowDetailIndex}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onRowToggle={onRowToggle}
        onRowClick={onRowClick}
        selectedRowIndexes={selectedRowIndexes}
        onSelectionChange={onSelectionChange}
      />
      <WelcomeMessage open={shouldShowWelcomeMessage} dismissWelcomeMessageHandler={dismissWelcomeMessageHandler} />
      {shouldShowReassignBar && (
        <ReassignBar
          onDismissClick={handleOnDismissReassignBar}
          selectedItems={selectedItems}
          groupId={groupId}
          resetSelectedItems={resetSelectedItems}
          autoRefresh={autoRefresh}
        />
      )}
    </>
  );
}

export default React.memo(FinancialWorkspaces);
