import React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import ButtonBase from '@mui/material/ButtonBase';

import { IconSortAsc, IconSortDesc } from '@sympli/ui-framework/icons';

import { AppEnvironmentEnum } from 'src/@core/environments/models';
import Popover from '../../popover';
import { randomPaletteColor } from '../environment-label/randomPaletteColor';
import FeatureFlagsControllerFormik, { Props as FormikProps } from './FeatureFlagsControllerFormik';
import { useStyles } from './styles';

export type ContainerProps = {
  environmentName: AppEnvironmentEnum;
  className?: string;
};

export type OptionalFormikProps = {
  onReset?: FormikProps['onReset'];
  onSubmit?: FormikProps['onSubmit'];
  options?: FormikProps['options'];
  initialValues?: FormikProps['initialValues'];
};

export type Props = {
  flagNumber: number;
  anchorEl: HTMLButtonElement | null;
  onClose(): void;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
} & OptionalFormikProps &
  ContainerProps;

function FeatureFlagsController({
  //
  anchorEl,
  environmentName,
  className,
  flagNumber,
  onReset,
  onSubmit,
  onClick,
  onClose,
  options,
  initialValues
}: Props) {
  const classes = useStyles();
  const menuId: string = _uniqueId('feature-flags');
  const labelColor = randomPaletteColor({ seed: environmentName });

  return (
    <div className={classNames(classes.labelBox, className)} style={{ backgroundColor: labelColor }}>
      <ButtonBase aria-owns={anchorEl ? menuId : undefined} aria-haspopup="true" disableRipple onClick={onClick} className={classNames(classes.root)}>
        Flags {flagNumber}
        {Boolean(flagNumber) && (anchorEl ? <IconSortAsc aria-hidden className={classes.selectIcon} /> : <IconSortDesc aria-hidden className={classes.selectIcon} />)}
      </ButtonBase>
      {Boolean(flagNumber) && (
        <Popover id={menuId} open={!!anchorEl} onClose={onClose} anchorEl={anchorEl}>
          <FeatureFlagsControllerFormik initialValues={initialValues!} options={options!} onReset={onReset!} onSubmit={onSubmit!} />
        </Popover>
      )}
    </div>
  );
}

export default FeatureFlagsController;
