import * as yup from 'yup';

import { FinalTenancyHoldingsModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings/models';
import { TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/shared/components/total-shares/constants';

import { Transfer2_24_1Model } from '../../models';

const TOTAL_SHARE_LIMIT: number = 100000;

const yupFinalHoldings = yup.mixed<FinalTenancyHoldingsModel>().test(
  //
  'Total shares should be under limit',
  `${TOTAL_SHARES_OVER_LIMIT_ERROR_MESSAGE(TOTAL_SHARE_LIMIT)}; please check share fractions for all the parties.`,
  function test(this: yup.TestContext<Transfer2_24_1Model>, finalTenancy: FinalTenancyHoldingsModel) {
    return finalTenancy ? finalTenancy.lcm === 0 || finalTenancy.lcm < TOTAL_SHARE_LIMIT : true;
  }
);

export default yupFinalHoldings;
