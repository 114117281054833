import queryString from 'query-string';

import http from 'src/utils/http';
import {
  FetchFavouriteReportApiRequest,
  FetchFavouriteReportApiResponse,
  FetchReportApiRequest,
  FetchReportsApiResponse,
  FetchScheduleReportHistoryApiRequest,
  FetchScheduleReportHistoryApiResponse
} from './models';

export const fetchReportPresignedUrl = (query: FetchScheduleReportHistoryApiRequest) => {
  return http.get<FetchScheduleReportHistoryApiResponse>(`/reports/scheduleReportHistory/${encodeURIComponent(query.reportId)}/url`);
};

export const fetchFavouriteReport = (query: FetchFavouriteReportApiRequest) => {
  return http.get<FetchFavouriteReportApiResponse>(`/reports/favouriteReportParameters/${encodeURIComponent(query.reportId)}`);
};

export const fetchReportDetail = (query: FetchReportApiRequest) => {
  return http.get<FetchReportsApiResponse>(
    queryString.stringifyUrl({
      url: `/dashboards/users/reports/${query.reportType}`,
      query: { subType: query.subType }
    })
  );
};
