import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from '../fallback';
import { ApiTransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model, TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from '../models';
import { convertPartiesToFormModel_2_21_1, generateDeceasedTenancy } from './common';

type FormModel = TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model;

export function convertFromApiToFormModel(apiModel: ApiTransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model, context: IConverterContext): FormModel {
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  let formModel: FormModel = {
    ...apiModel,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel_2_21_1(apiModel.partyBook, risInvolvedPartyIds),
    // UI only properties
    context
  };

  if (apiModel.precedingData.dataChanged ?? true) {
    formModel = generateDeceasedTenancy(formModel);
  }

  const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
  return formModelWithDefaultMapApplied;
}
