import { HttpTypes } from '@sympli/api-gateway/types';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

interface OwnProps {
  workflowType: HttpTypes.DocumentWorkflowTypeEnum;
  isSubmitting: boolean;
  canEdit: boolean;
  isSaveAsDraft: boolean;
  haveCancelButton: boolean;
  formDirty: boolean;

  onFormCancel: () => void;
  handleOnSaveAndContinueClick: () => void;
}

type Props = OwnProps;

function SaveFormStepper(props: Props) {
  const { workflowType, haveCancelButton, onFormCancel, isSubmitting, canEdit, isSaveAsDraft, handleOnSaveAndContinueClick } = props;
  const onBack = haveCancelButton ? onFormCancel : undefined;
  const nextLabel = workflowType === HttpTypes.DocumentWorkflowTypeEnum.Standard ? 'Save and approve' : 'Save and review';
  return (
    <WizardStepper
      onBack={onBack}
      backLabel="Cancel"
      nextLabel={nextLabel}
      isLoading={isSubmitting && !isSaveAsDraft}
      onNext={handleOnSaveAndContinueClick}
      disabled={!canEdit || isSubmitting}
    />
  );
}

export default SaveFormStepper;
