import * as yup from 'yup';

import yupResponsibleParticipantId from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber/validation-schema';
import { HttpTypes } from '@sympli/api-gateway/types';

import { LodgementInstructions_2_25_1 } from './models';
import yupDealingRequirementsSummary from './sections/dealing-requirements-summary/validationSchema';
import { yupIssuingInstructionsValidationSchema } from './sections/issuing-instructions/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<LodgementInstructions_2_25_1>({
    responsibleParticipantId: yupResponsibleParticipantId,
    dealingRequirementsSummary: yupDealingRequirementsSummary,
    issuingInstructions: yupIssuingInstructionsValidationSchema<LodgementInstructions_2_25_1>(),
    workspaceType: yup.mixed<HttpTypes.WorkspaceTypeEnum>(),
    cordConsentRequired: yup.bool()
  })
  .defined()
  .log();
