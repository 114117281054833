import _xor from 'lodash-es/xor';
import { defaultMemoize } from 'reselect';

import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { formatPartyName, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel, ApiPartyCapacityModel, ApiPartySourceModel, resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { PartyAdjuster } from 'src/containers/documents/partyAdjuster';
import { convertPartiesToFormModel } from '../../helpers';
import { PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from './config';
import { EMPTY_MORTGAGOR_PARTY, Mortgage2_21_3Model, TitleReferenceModel } from './models';

export const { convertPartyBookFromFormToApiModel, convertPartyBookFromApiToFormModel } = resolvePartyBookConversion({
  partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
  nameChangeConversion: nswNameChangeConversion
});

export function calculateMortgagorsOnTitle(apiMortgagors: ApiDocumentPartyModel<NswDocumentPartyJustification, {}>[]): Mortgage2_21_3Model['mortgagorsOnTitle'] {
  const partyBook: PartyModel<NswNameChange>[] = convertPartyBookFromApiToFormModel(apiMortgagors);
  const partyBookIds: string[] = partyBook.map(p => p.id);
  const partyNamesMap: Map<string, string> = new Map<string, string>(partyBook.map(p => [p.id, p.legalEntityName!]));
  return {
    partyBook,
    partyBookIds,
    partyNamesMap,
    isOriginal(party: PartyModel<NswNameChange>): boolean {
      if (!this.partyBookIds.includes(party.id)) {
        return false;
      }

      return partyNamesMap.get(party.id) === party.legalEntityName!;
    }
  };
}

function isPartyDefined<T>(value: T | undefined): value is T {
  return !!value;
}

export function isMortgagorsResetTenancyAvailable({
  //
  partyBook,
  mortgagors,
  mortgagorsOnTitle,
  precedingData
}: {
  partyBook: Mortgage2_21_3Model['partyBook'];
  mortgagors: Mortgage2_21_3Model['mortgagors'];
  mortgagorsOnTitle: Mortgage2_21_3Model['mortgagorsOnTitle'];
  precedingData: Mortgage2_21_3Model['precedingData'];
}): boolean {
  if (precedingData.sourceChanged) return true;

  const displayedMortgagorPartyBookIds = mortgagors.proprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId));
  const displayedMortgagorParties: PartyModel<NswNameChange>[] = displayedMortgagorPartyBookIds
    .map(id => partyBook.find(p => p.id === id))
    .filter(isPartyDefined<PartyModel<NswNameChange>>);

  //* 1. has any tenant been added/removed by the user?
  // returns items that are not present across both arrays
  if (_xor(displayedMortgagorPartyBookIds, mortgagorsOnTitle.partyBookIds).length > 0) {
    return true;
  }

  //* 2. has any tenant been updated by the user?
  const isMortgagorUpdated = displayedMortgagorParties.some(p => !mortgagorsOnTitle.isOriginal(p));

  return isMortgagorUpdated;
}

export function generateMortgagors(
  formModel: Mortgage2_21_3Model, //
  partyAdder?: string,
  includeCustomParties: boolean = true
): Mortgage2_21_3Model {
  //
  const customParties: PartyModel<NswNameChange>[] = formModel.partyBook.filter(
    party => party.metadata?.source === DataSource.Custom || party.metadata?.source === DataSource.Subscriber
  );

  const customMortgagors: Array<{
    id: string;
    partyBookId: string;
    partyCapacity: ApiPartyCapacityModel;
  }> = includeCustomParties ? getCustomMortgagors(customParties, formModel.mortgagors) : [];
  const selectedTitles: TitleReferenceModel[] = formModel.titleReferences.filter(tr => tr.isSelected);

  if (!selectedTitles.length) {
    const mortgagors = customMortgagors.length
      ? customMortgagors.map(
          (mortgagor): TenancyPartyModel => ({ ...mortgagor, partyCapacity: mortgagor.partyCapacity.capacity, partyCapacityDetail: mortgagor.partyCapacity.details })
        )
      : [EMPTY_MORTGAGOR_PARTY];
    return {
      ...formModel,
      mortgagors: createFormMortgagors(mortgagors)
    };
  }

  const { precedingData } = formModel;

  const containers = selectedTitles.map(title => {
    const groups =
      precedingData.newProprietors.find(p => p.proprietorsSource.titles.some(t => t === title.reference!))?.proprietorGroups ??
      title.mortgagors.map(group => ({
        ...group,
        parties: group.parties.map((p): ApiPartySourceModel<NswDocumentPartyJustification> => ({ party: p, partyCapacity: {} }))
      }));

    return new Container(title.reference!, new TenancyDetail(groups.map(g => new ProprietorGroup(g.parties.map(({ party, partyCapacity }) => ({ ...party, partyCapacity }))))));
  });

  const result = PartyMerger.merge(containers);

  const apiMortgagors = result.containers.flatMap(c => c.tenancyDetail.proprietorGroups.flatMap(pg => pg.mergedParties));

  const partyBook = convertPartiesToFormModel2212(
    apiMortgagors,
    apiMortgagors.map(p => p.id!),
    partyAdder
  ).concat(customParties);

  const risMortgagors: Array<{
    id: string;
    partyBookId: string;
    partyCapacity: ApiPartyCapacityModel;
  }> = apiMortgagors.map(p => ({ id: p.id!, partyBookId: p.id!, partyCapacity: p.partyCapacity ?? {} }));

  const mortgagors: GenericTenancyDetailModel = createFormMortgagors(
    risMortgagors
      .concat(customMortgagors)
      .map(mortgagor => ({ ...mortgagor, partyCapacity: mortgagor.partyCapacity.capacity, partyCapacityDetail: mortgagor.partyCapacity.details }))
  );

  const mortgagees: Mortgage2_21_3Model['mortgagees'] = {
    ...formModel.mortgagees,
    receivingProprietorGroups: formModel.mortgagees.receivingProprietorGroups.map(pg => {
      return {
        ...pg,
        parties: pg.parties.map(p => {
          let partyBookId = PartyAdjuster.adjust(p.partyBookId, partyBook, formModel.partyBook);
          if (mortgagors.proprietorGroups.flatMap(e => e.parties).some(p => p.partyBookId === partyBookId)) {
            partyBookId = undefined;
          }
          return { ...p, partyBookId: partyBookId };
        })
      };
    })
  };

  const mortgagorsOnTitle = calculateMortgagorsOnTitle(apiMortgagors);

  return {
    ...formModel, //
    partyBookApi: apiMortgagors,
    partyBook: partyBook,
    mortgagees,
    mortgagors,
    precedingData: {
      ...precedingData,
      titlesUsed: selectedTitles
        .map(title => title.reference!)
        .filter(titleReference => Boolean(precedingData.newProprietors.find(np => np.proprietorsSource.titles.includes(titleReference)))),
      dataChanged: formModel.precedingData?.dataChanged ?? false,
      sourceChanged: false,
      manualOverride: false
    },
    mortgagorsOnTitle
  };
}

export function convertPartiesToFormModel2212(
  parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[],
  relinquishingIds?: string[],
  partyAdder?: string
): PartyModel<NswNameChange>[] {
  const uniqueParties = Array.from(
    parties
      .reduce((entryMap, e) => {
        if (!entryMap.has(e.id!)) entryMap.set(e.id!, e);
        return entryMap;
      }, new Map<string, ApiDocumentPartyModel<NswDocumentPartyJustification>>())
      .values()
  );

  const dataSourceToNameMap = partyAdder
    ? new Map<DataSource, string>([
        [DataSource.Transfer, partyAdder],
        [DataSource.TransmissionApplication, partyAdder]
      ])
    : undefined;

  return convertPartiesToFormModel(
    {
      partyFormConfig: PARTY_FORM_WITH_NAME_CHANGE_CONFIG,
      nameChangeConversion: nswNameChangeConversion
    },
    uniqueParties,
    relinquishingIds,
    dataSourceToNameMap
  );
}

/**
 * returns selected title reference items
 */
export const getSelectedTitleReferences = defaultMemoize((titleReferences: TitleReferenceModel[]) => {
  return titleReferences.filter(({ isSelected }) => isSelected);
});

export function filterOutSelectedParties(
  partyBook: PartyModel<{}, {}>[], //
  currentSelectedParty: TenancyPartyModel,
  selectedPartyIds?: string[]
) {
  const partyOptions = partyBook
    .filter(party => {
      const isCurrentlySelected = party.id === currentSelectedParty.partyBookId;
      const hasBeenSelected = (selectedPartyIds ?? []).includes(party.id);

      /*
       * We need to return current selected party too;
       * otherwise, the selected value of the dropdown list will be empty.
       */
      return isCurrentlySelected || !hasBeenSelected;
    })
    .map(party => ({ id: party.id, name: formatPartyName(party) }));

  return partyOptions;
}

function getCustomMortgagors(
  customParties: PartyModel<NswNameChange, {}>[],
  mortgagors: Mortgage2_21_3Model['mortgagors']
): Array<{
  //
  id: string;
  partyBookId: string;
  partyCapacity: ApiPartyCapacityModel;
}> {
  return mortgagors.proprietorGroups
    .flatMap(pg => pg.parties)
    .filter(mortgagor => customParties.some(p => p.id === mortgagor.partyBookId!))
    .map(mortgagor => ({ id: mortgagor.partyBookId!, partyBookId: mortgagor.partyBookId!, partyCapacity: mortgagor.partyCapacity ?? {} }));
}

/**
 *
 * @param parties tenants in the group
 * @param groupId defaults to 1 when only one group can exist in mortgagors section
 * @returns converted form model
 */
export function createFormMortgagors(oldParties: TenancyPartyModel[], groupId: string = '1'): GenericTenancyDetailModel {
  const parties: GenericTenancyDetailModel['proprietorGroups'][number]['parties'] = oldParties.map(p => {
    return {
      isSelected: !!p.isTransactingParty,
      partyBookId: p.partyBookId,
      addressBookId: p.addressBookId,
      partyCapacity: {
        capacity: p.partyCapacity,
        details: p.partyCapacityDetail
      }
    };
  });
  const tenancy: GenericTenancyDetailModel = {
    tenancyType: TenancyTypeEnum.None,
    proprietorGroups: [
      {
        isSelected: parties.length ? parties.every(p => p.isSelected) : false,
        proprietorGroupType: ProprietorGroupTypeEnum.None,
        parties: parties,
        shareFraction: {
          numerator: null,
          denominator: null
        }
      }
    ]
  };
  return tenancy;
}

export function getPartyAdder(
  participants:
    | {
        id: string;
        name: string;
        workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
      }[]
    | undefined
): string | undefined {
  return participants?.find(p => p.workspaceRole.id === HttpTypes.WorkspaceRoleEnum.Purchaser || p.workspaceRole.id === HttpTypes.WorkspaceRoleEnum.Beneficiary)?.name;
}
