import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { documentWorkflowStepPermissionMapper } from 'src/containers/documents/helpers';
import { DocumentWorkflowStepsEnum, PanelVariantEnum } from 'src/containers/documents/models';
import { AuthorisationDenyPermissionReasonCodeEnum } from 'src/containers/workspace/financial/directions/models';
import DocumentNotificationPanel from '../../../../../documents/components/document-notification-panel/DocumentNotificationPanel';
import { DocumentDenyPermissionModel } from '../../../../../documents/models';

interface Props {
  workspaceId: string;
  participantId: string;
  status: HttpTypes.DocumentStatusEnum;
  currentWorkflowStep?: DocumentWorkflowStepsEnum;
  denyPermissions?: HttpTypes.GetDirectionTrustAccountAuthorisationRecordsApiResponse['denyPermissions'];
  documentType: string;
}

function AuthorisationPermissionPanelContainer({ status, documentType, workspaceId, participantId, currentWorkflowStep, denyPermissions = [] }: Props) {
  const linkToWorkspace = resolveWorkspaceDetailLink({ workspaceId, participantId });
  const denyPermissionMap = React.useMemo<Partial<Record<keyof typeof AuthorisationDenyPermissionReasonCodeEnum, DocumentDenyPermissionModel>>>(() => {
    return denyPermissions.reduce((table, currentValue) => {
      table[currentValue.documentPermissionType] = {
        ...currentValue
      };
      return table;
    }, {});
  }, [denyPermissions]);

  if (!Number.isInteger(currentWorkflowStep)) {
    return null;
  }

  if (status === HttpTypes.DocumentStatusEnum.Signed) {
    return (
      <DocumentNotificationPanel //
        variant={PanelVariantEnum.Success}
        primary={`All done! Your ${documentType} is signed.`}
        linkToWorkspace={linkToWorkspace}
      />
    );
  }

  const documentPermission = documentWorkflowStepPermissionMapper[currentWorkflowStep!];
  const deniedPermission: DocumentDenyPermissionModel<AuthorisationDenyPermissionReasonCodeEnum> | undefined = denyPermissionMap[documentPermission!];

  if (!deniedPermission) {
    return null;
  }

  switch (deniedPermission.reasonCode.id) {
    case AuthorisationDenyPermissionReasonCodeEnum.APD01_MissingSignDocumentPermission:
      return (
        <DocumentNotificationPanel //
          variant={PanelVariantEnum.Warning}
          primary={`You do not have permission to sign ${documentType}.`}
          linkToWorkspace={linkToWorkspace}
        />
      );
    case AuthorisationDenyPermissionReasonCodeEnum.APD03_OneOfTheSignersCannotSignAgain:
      return (
        <DocumentNotificationPanel //
          variant={PanelVariantEnum.Warning}
          primary={`You are one of the signers for this ${documentType}. You cannot sign again.`}
          linkToWorkspace={linkToWorkspace}
        />
      );
    default:
      break;
  }

  return null;
}

export default React.memo(AuthorisationPermissionPanelContainer);
