import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-28/necds';
import { SRO_PARTY_CAPACITY_LOOKUP_OPTIONS } from '@sympli-mfe/enums-2-28/vic';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { yupEmailAddress } from 'src/containers/documents/scaffolded-form/vic/2-28/helpers/yupEmailAddress';
import { END_OF_TODAY, TransferorDetailModel } from '../../models';
import {
  VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE,
  VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL,
  VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL
} from '../../visibilityChecks';
import { createYupMissingContactDetails, yupContactPhone } from '../transferees/validationSchema';

// this file was automatically generated from sections/validationSchema.ts.mustache
const yupMissingContactDetails = createYupMissingContactDetails<TransferorDetailModel>(yupContactPhone, yupEmailAddress);

// path: transferors
const yupTransferors = yup //
  .mixed<TransferorDetailModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_TRANSFERORS_AND_TRANSFEROR_REPRESENTATIVE_DETAIL,
      validationWhenVisible: yup
        .array<TransferorDetailModel>()
        .required(msg.REQUIRED)
        .min(1, msg.MIN_ITEMS(1))
        .max(20, msg.MAX_ITEMS(20))
        .of(
          yup
            .object<TransferorDetailModel>()
            .nullable(true)
            .shape({
              partyBookId: yup.mixed<string>(), // readonly field
              sroPartyCapacity: yup
                .mixed<NecdsPartyCapacityEnum | string | null>()
                .required(msg.REQUIRED)
                .oneOf(getLookupValuesAllowingEmpty(SRO_PARTY_CAPACITY_LOOKUP_OPTIONS), msg.INVALID_SELECTION),
              partyCapacityDetail: yup //
                .mixed<string>()
                .test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_TRANSFEROR_PARTY_CAPACITY_DETAIL,
                    validationWhenVisible: yup.string().default('').trim().typeError(msg.INVALID_VALUE)
                  })
                ),
              birthDate: yup //
                .mixed<Date | string | null>()
                .test(
                  validateWhenVisible({
                    visibilityCheck: VISIBILITY_CHECK_TRANSFEROR_BIRTH_DATE,
                    validationWhenVisible: yupDatePicker //
                      .required(msg.REQUIRED)
                      .max(END_OF_TODAY, msg.DATE_MUST_BE_TODAY_OR_PAST_DATE)
                  })
                ),
              addressAtTimeOfTransferBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
              correspondenceAddressBookId: yup.string().default('').trim().typeError(msg.INVALID_VALUE).required(msg.REQUIRED),
              contactPhoneNumber: yupContactPhone,
              emailAddress: yupEmailAddress,
              missingContactDetails: yupMissingContactDetails
            })
        ),
      isObjectOrArray: true
    })
  );

export default yupTransferors;
