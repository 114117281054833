import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export type AutoJustificationsRequest = string;

export const actionFetchAutoJustifications = createAsyncAction(
  //
  'FETCH_AUTOJUSTIFICATIONS',
  'FETCH_AUTOJUSTIFICATIONS_SUCCESS',
  'FETCH_AUTOJUSTIFICATIONS_ERROR'
)<
  //
  AutoJustificationsRequest,
  { data: HttpTypes.SubscriberAutoJustificationsApiResponse },
  { error: Error }
>();

export type AutoJustificationDetailRequest = string;

export const actionFetchAutoJustificationDetail = createAsyncAction(
  //
  'FETCH_AUTOJUSTIFICATION_DETAILS',
  'FETCH_AUTOJUSTIFICATION_DETAILS_SUCCESS',
  'FETCH_AUTOJUSTIFICATION_DETAILS_ERROR'
)<
  //
  AutoJustificationDetailRequest,
  {
    data: [
      //
      HttpTypes.SubscriberAutoJustificationDetailApiResponse | undefined,
      HttpTypes.SubscriberSupportedDocumentsApiResponse,
      HttpTypes.SettingsSubscriberDetailsApiResponse
    ];
  },
  { error: Error }
>();

export type AutoJustificationEvidenceRequest = string;

export const actionFetchChangeOfNameEvidence = createAsyncAction(
  //
  'FETCH_CHANGE_OF_NAME_EVIDENCE',
  'FETCH_CHANGE_OF_NAME_EVIDENCE_SUCCESS',
  'FETCH_CHANGE_OF_NAME_EVIDENCE_ERROR'
)<
  //
  AutoJustificationEvidenceRequest,
  {
    data: [
      //
      HttpTypes.SubscriberNameJustificationEvidenceApiResponse,
      HttpTypes.ChangeOfNameEvidenceOptionsApiResponse
    ];
  },
  { error: Error }
>();
