import React, { useCallback, useEffect } from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import _isEqual from 'lodash-es/isEqual';
import { ButtonBaseActions } from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentAddButtonRenderProps, DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import PartyCheckboxList from '@sympli-mfe/document-forms-framework/components/party-checkbox-list';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import PartySelectField from '@sympli-mfe/document-forms-framework/components/party-select-field';
import { TenancyPartyFields, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { createModelKeyAppender, resolveComboboxPlaceholder, resolveLabel, resolveSelectPlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import SympliAutocompleteField from '@sympli/ui-framework/components/formik/sympli-autocomplete-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import { ENUM_REGISTRY_INSTRUMENT_TYPE_OPTIONS } from '../../enums';
import { filterSelectablePartyReferences, filterSelectedPartySiblingsFromPartyBook, getProprietorAndCustomPartyBook, resolveDealingNumberOptions } from '../../helpers';
import { ADDRESS_BOOK_KEY, ApplicableInstrumentModel, BaseCaveatModel, PARTY_BOOK_KEY } from '../../models';
import { Context } from '../../RootForm';
import { useStyles } from './styles';

interface Props {
  name: string;
  formikProps: FormikProps<BaseCaveatModel>;
  focusRef?: React.RefObject<ButtonBaseActions>;
  disabled: boolean;
  dialogPortalId: string;
  syncPartyReferences: (partyReference: TenancyPartyModel, fieldChanged: TenancyPartyFields) => Promise<TenancyPartyModel>;
}

const debug = !import.meta.env.PROD;

const ApplicableInstrument = ({ name, formikProps, focusRef, disabled, dialogPortalId, syncPartyReferences }: Props): JSX.Element => {
  const classes = useStyles();
  const { values, setFieldValue } = formikProps;
  const fieldName = createModelKeyAppender<ApplicableInstrumentModel>(name);

  const { partyBook, addressBook, titleReferences } = values;
  const applicableInstrument: ApplicableInstrumentModel = _get(values, name);
  const { relinquishingParties, receivingParties } = applicableInstrument;
  const setPartyBook = useCallback((value: PartyModel[]) => setFieldValue(PARTY_BOOK_KEY, value), [setFieldValue]);
  const setAddressBook = useCallback((value: AddressBookEntityModel[]) => setFieldValue(ADDRESS_BOOK_KEY, value), [setFieldValue]);
  const context = useRootFormContext<Context>();
  const [registerDealingOptionsKey, setRegisterDealingOptionsKey] = React.useState<string>('');

  useEffect(() => {
    const updatedParties = filterSelectablePartyReferences(titleReferences, partyBook, relinquishingParties);

    if (!_isEqual(updatedParties, relinquishingParties)) {
      setFieldValue(fieldName('relinquishingParties'), updatedParties);
    }
  }, [partyBook]);

  useEffect(() => {
    const options = resolveDealingNumberOptions(values)
      .map(x => x.id)
      .join(', ');
    if (!_isEqual(options, registerDealingOptionsKey)) {
      setRegisterDealingOptionsKey(options);
    }
  }, [registerDealingOptionsKey, values]);

  const renderRelinquishingParties = () => {
    return (
      <PartyCheckboxList //
        name={fieldName('relinquishingParties')}
        title={resolveLabel('Party relinquishing', true)}
        addButtonLabel={'Add new relinquishing party'}
        disabled={disabled}
        dialogPortalId={dialogPortalId}
        partyBook={partyBook}
        setPartyBook={setPartyBook}
        addressBook={addressBook}
        setAddressBook={setAddressBook}
        partyFormConfig={context.partyFormConfig}
        partyCapacityLookup={context.partyCapacityLookup}
        addressFormConfig={context.addressFormConfig}
        addressFormInitialValues={context.addressFormInitialValues}
        onDataChange={syncPartyReferences}
      />
    );
  };

  const filterReceivingParty = useCallback(
    (currentSelectedParty: TenancyPartyModel) => {
      const proprietorOrCustomPartyBook = getProprietorAndCustomPartyBook(partyBook, titleReferences);
      return filterSelectedPartySiblingsFromPartyBook(proprietorOrCustomPartyBook, currentSelectedParty, receivingParties);
    },
    [partyBook, titleReferences, receivingParties]
  );

  const addressFieldFocusRef = React.useRef<ButtonBaseActions>(null);
  const renderReceivingPartyItem = useCallback(
    ({ itemBinding, item, itemIndex, itemFocusRef, nextItemFocusRef, fieldName, handleRemove }: DocumentArrayItemRenderProps<TenancyPartyModel>) => {
      const title = `Party receiving ${itemIndex + 1}`;
      const filteredPartyOptions = filterReceivingParty(item);
      const tenancyFieldName = createModelKeyAppender<TenancyPartyModel>(itemBinding);
      return (
        <>
          <FlexLayout className={classes.receivingParty}>
            <IconButton color="primary" disabled={disabled} onClick={handleRemove} size="large">
              <IconDelete fill="currentColor" />
            </IconButton>
            <Typography>{title}</Typography>
          </FlexLayout>
          <PartySelectField
            name={fieldName('partyBookId')}
            optionsOverride={filteredPartyOptions}
            partyFormConfig={context.partyFormConfig}
            disabled={disabled}
            bookRef={PARTY_BOOK_KEY}
            dialogPortalId={dialogPortalId}
            focusRef={itemFocusRef}
            nextFocusRef={addressFieldFocusRef}
            onSelect={async (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
              const updatedPartyReference = await syncPartyReferences({ ...item, partyBookId: resolvedValue }, TenancyPartyFields.PartyBookId);
              setFieldValue(itemBinding, updatedPartyReference);
            }}
            handleCustomSubmit={async (isNew: boolean, partyBookId: string) => {
              if (isNew) {
                syncPartyReferences({ ...item, partyBookId }, TenancyPartyFields.PartyBookId);
              }
            }}
          />
          <AddressSelectField
            name={fieldName('addressBookId')}
            sharedAddressFormConfig={context.addressFormConfig}
            sharedInitialValuesForNew={context.addressFormInitialValues}
            disabled={disabled}
            bookRef={ADDRESS_BOOK_KEY}
            dialogPortalId={dialogPortalId}
            focusRef={addressFieldFocusRef}
            nextFocusRef={nextItemFocusRef}
            onSelect={async (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
              setFieldValue(tenancyFieldName('addressBookId'), resolvedValue);
              syncPartyReferences({ ...item, addressBookId: resolvedValue }, TenancyPartyFields.AddressBookId);
            }}
            handleCustomSubmit={async (isNew: boolean, addressBookId: string) => {
              if (isNew) {
                syncPartyReferences({ ...item, addressBookId }, TenancyPartyFields.AddressBookId);
              }
            }}
          />
        </>
      );
    },
    [
      classes.receivingParty,
      disabled,
      context.partyFormConfig,
      context.addressFormConfig,
      context.addressFormInitialValues,
      dialogPortalId,
      filterReceivingParty,
      syncPartyReferences,
      setFieldValue
    ]
  );
  const createNewReceivingPartyItem = (): TenancyPartyModel => ({
    isSelected: true,
    partyBookId: '',
    addressBookId: '',
    partyCapacity: {}
  });
  const renderAddButton = useCallback(
    ({ addButtonFocusRef, onAdd }: DocumentAddButtonRenderProps) => {
      return (
        <FormGroupFooter //
          icon="add"
          onClick={onAdd}
          buttonActions={addButtonFocusRef}
          title="Add new receiving party"
          className={classes.addParty}
          disabled={disabled}
        />
      );
    },
    [disabled, classes.addParty]
  );
  const renderReceivingParties = () => {
    return (
      <DocumentFieldArray
        arrayBinding={fieldName('receivingParties')}
        itemTitle="Party receiving"
        renderItem={renderReceivingPartyItem}
        createNewItem={createNewReceivingPartyItem}
        renderAddButton={renderAddButton}
        maxItems={20}
        disabled={disabled}
        isSimpleType={false}
        itemStyle="none"
        focusRef={focusRef}
      />
    );
  };

  return (
    <>
      <FlexLayout>
        <Field
          label={resolveLabel('Instrument type', true)}
          name={fieldName('instrumentType')}
          format="string"
          options={ENUM_REGISTRY_INSTRUMENT_TYPE_OPTIONS}
          placeholder={resolveSelectPlaceholder(true)}
          component={SelectField}
          disabled={disabled}
          debug={debug}
        />
      </FlexLayout>
      <FlexLayout>
        <Field //
          aria-label="Dealing number"
          label={resolveLabel('Dealing number', true)}
          name={fieldName('dealingNumber')}
          component={SympliAutocompleteField}
          disabled={disabled}
          placeholder={resolveComboboxPlaceholder(true)}
          type="combobox"
          options={resolveDealingNumberOptions(values)}
          key={registerDealingOptionsKey}
          optionIdAttr="id"
          optionNameAttr="name"
          getCustomSelectedOptionLabel={(item: any) => item.id}
        />
      </FlexLayout>
      <FlexLayout>{renderRelinquishingParties()}</FlexLayout>
      <hr className={classes.divider} />
      <FlexLayout>{renderReceivingParties()}</FlexLayout>
    </>
  );
};

export default ApplicableInstrument;
