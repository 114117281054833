import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useStoreSelector } from 'src/hooks';
import {
  actionFetchWorkspaceParticipants,
  actionToggleCurrentParticipantDocDisplay,
  actionUpdateCurrentParticipantDetail,
  actionUpdateParticipantList,
  WorkspaceRelatedApiRequest
} from 'src/store/actions/workspace';
import { ApiStatus } from 'src/utils/http';

export interface WorkspaceParticipantsState {
  items: HttpTypes.WorkspaceParticipantListApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceRelatedApiRequest;
}

const initialState: WorkspaceParticipantsState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useWorkspaceParticipants(workspaceId: string) {
  const state = useStoreSelector(store => store.workspaceParticipants);
  if (state.args?.workspaceId !== workspaceId) {
    return initialState;
  }
  return state;
}

const workspaceParticipantsReducer = createReducer<
  //
  WorkspaceParticipantsState,
  Action
>(initialState)
  .handleAction(actionFetchWorkspaceParticipants.request, (state, action): WorkspaceParticipantsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(
    actionFetchWorkspaceParticipants.success,
    (state, action): WorkspaceParticipantsState => ({
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.items
    })
  )
  .handleAction(
    actionFetchWorkspaceParticipants.failure,
    (state, action): WorkspaceParticipantsState => ({
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    })
  )
  .handleAction(actionUpdateParticipantList, (state, action): WorkspaceParticipantsState => {
    const { participants, workspaceId } = action.payload;
    if (state.args?.workspaceId !== workspaceId) {
      return state;
    }

    return {
      ...state,
      items: participants
    };
  })
  .handleAction(actionToggleCurrentParticipantDocDisplay, (state, action): WorkspaceParticipantsState => {
    const { items } = state;

    if (!items.length) {
      return state;
    }

    const participantId = action.payload;
    const participantIndex = items.findIndex(participant => participant.id === participantId);

    if (participantIndex === -1) {
      return state;
    }

    const currentParticipant = items[participantIndex];
    const updatedParticipant: HttpTypes.WorkspaceParticipant = {
      ...currentParticipant,
      displayDocumentAsPdf: !currentParticipant.displayDocumentAsPdf
    };
    items.splice(participantIndex, 1, updatedParticipant);

    return {
      ...state,
      items: [...items]
    };
  })
  .handleAction(actionUpdateCurrentParticipantDetail, (state, action): WorkspaceParticipantsState => {
    const { items } = state;

    if (!items.length) {
      return state;
    }
    const { participantId, reference, participantArchivedStatus } = action.payload;

    const participantIndex = items.findIndex(item => item.id === participantId);

    if (participantIndex === -1) {
      return state;
    }

    const currentParticipant = items[participantIndex];
    const updatedParticipant: HttpTypes.WorkspaceParticipant = {
      ...currentParticipant,
      // if no reference is passed from action.payload, we use the old reference number
      reference: reference ?? currentParticipant.reference,
      archivedStatus: participantArchivedStatus
    };

    items.splice(participantIndex, 1, updatedParticipant);

    return {
      ...state,
      items: [...items]
    };
  });

export default workspaceParticipantsReducer;
