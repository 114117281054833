import * as React from 'react';

import { DocumentPreviewEnhanced } from 'src/components/document-preview';
import { DirectionsDetailState, useDirectionsBreakdown } from 'src/containers/workspace/financial/directions/reducers/directionsBreakdown';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
}

function DirectionsPreviewContainer({ queryParams }: Props) {
  const { detail, error, status }: DirectionsDetailState = useDirectionsBreakdown(queryParams.workspaceId, queryParams.participantId);

  return (
    <DocumentPreviewEnhanced //
      pdfUrl={detail?.documentSummary.pdfUrl}
      error={error}
      status={status}
    />
  );
}

export default React.memo(DirectionsPreviewContainer);
