import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupItemModel } from '@sympli/ui-framework/models';

export const ReportTypeEnumDisplayMap: Record<HttpTypes.ReportTypeEnum, string> = {
  [HttpTypes.ReportTypeEnum.UserPermissionReport]: 'User permissions',
  [HttpTypes.ReportTypeEnum.UserActivityReport]: 'User activity',
  [HttpTypes.ReportTypeEnum.TeamWorkloadReport]: 'Team workload'
};

export const getReportPermissionOptions = (
  //
  response?: HttpTypes.SettingsUserReportPermissionsApiResponse
): Array<LookupItemModel<HttpTypes.ReportTypeEnum>> => {
  if (!response) {
    return [];
  }

  const reportPermissions = response.reportPermissions
    .filter(x => x.hasAccess) //
    .map(x => ({
      id: x.id,
      name: ReportTypeEnumDisplayMap[x.id]
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return reportPermissions;
};
