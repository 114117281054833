import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { colors, ScreenSizeVariant } from 'src/theme';
import { InvitationsDashboardRouteTabsEnumV2 } from '../models';

export const useInvitationsTableRowDetailStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between'
    }
  }),
  {
    name: 'InvitationsTableRowDetail'
  }
);

interface StyleProps {
  screenVariant: ScreenSizeVariant;
  invitationGroup: InvitationsDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
}

const gaps = {
  'large-screen': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 126,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 126,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 91
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 64,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 64,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 72
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 54
    }
  },
  'medium-screen': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 75,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 75,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 57
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 38,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 38,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 42
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 31
    }
  },
  'small-laptop': {
    [AssignmentTypeEnum.AssignedToMe]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 57,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 57,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 41
    },
    [AssignmentTypeEnum.AssignedToGroup]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 28,
      [InvitationsDashboardRouteTabsEnumV2.sent]: 28,
      [InvitationsDashboardRouteTabsEnumV2.archived]: 28
    },
    [AssignmentTypeEnum.ReAssigned]: {
      [InvitationsDashboardRouteTabsEnumV2.received]: 22
    }
  }
};

export const useInvitationsTableStyles = makeStyles(
  (theme: Theme) => ({
    root({ screenVariant, invitationGroup, assignmentType }: StyleProps) {
      return {
        '& thead th:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: gaps[screenVariant] ? gaps[screenVariant][assignmentType][invitationGroup] : 30
        },
        '& tbody td:not(:last-child):not(.MuiTableCell-paddingNone)': {
          paddingRight: gaps[screenVariant] ? gaps[screenVariant][assignmentType][invitationGroup] : 30
        }
      };
    }
  }),
  {
    name: 'InvitationsTable'
  }
);

export const useTableCellHeadCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableHeadCellSelection: {
      width: 24
    },
    headCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);

export const useTableCellBodyCheckboxStyles = makeStyles(
  (theme: Theme) => ({
    tableBodyCellSelection: {
      width: 24
    },
    bodyCheckbox: {
      marginLeft: -4,
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    button: {
      padding: '6px 24px',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      '&:hover': {
        boxShadow: 'none'
      },
      color: colors.NEUTRAL_000
    },
    buttonPrimary: {
      backgroundColor: colors.SUNNY_DAY,

      '&:hover': {
        backgroundColor: colors.SUNNY_DAY_DARK
      }
    },
    buttonSecondary: {
      backgroundColor: colors.WATERMELON,

      '&:hover': {
        backgroundColor: colors.WATERMELON_DARK
      }
    }
  }),
  {
    name: 'SympliTableCellCheckboxOverrides'
  }
);
