import { addDays } from 'date-fns';
import * as yup from 'yup';

import { createContextCheckUsingRoot } from '@sympli-mfe/document-forms-framework/utils';
import { testContextualRule, validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { vicMcpNumberTest, vicTitleReferenceTest } from 'src/containers/documents/scaffolded-form/vic/2-28/helpers/vicTitleReferenceFormatTest';
import { Encumbrance$BenefitedLandReferences$ReferenceTypeEnum } from '../../enums';
import { BenefitedLandReferenceModel, CovenantDetailModel, EncumbranceModel, TransferDocumentModel } from '../../models';
import { VISIBILITY_CHECK_COVENANT, VISIBILITY_CHECK_REFERENCE_NUMBER } from './visibilityChecks';

export const MIN_EXPIRY_DATE = addDays(new Date(), 1);

const yupBenefitedLandReference = yup.object<BenefitedLandReferenceModel>({
  referenceType: yup.mixed<Encumbrance$BenefitedLandReferences$ReferenceTypeEnum | null>().nullable().required(msg.REQUIRED),
  referenceNumber: yup.mixed<string>().test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_REFERENCE_NUMBER,
      validationWhenVisible: yup
        .string()
        .default('')
        .trim()
        .required(msg.REQUIRED)
        .when('referenceType', {
          is: Encumbrance$BenefitedLandReferences$ReferenceTypeEnum.LrMemorandumID,
          then: (yupString: yup.StringSchema) => yupString.test(vicMcpNumberTest)
        })
        .when('referenceType', {
          is: Encumbrance$BenefitedLandReferences$ReferenceTypeEnum.LandTitleReference,
          then: (yupString: yup.StringSchema) =>
            yupString //
              .test(vicTitleReferenceTest)
              .test(
                testContextualRule({
                  message: 'Subject land title cannot be encumbered by itself',
                  requirement: createContextCheckUsingRoot((testTitleReference: string, _, { titleReferences }: TransferDocumentModel) => {
                    const isSelectedReference = titleReferences?.some(({ reference, isSelected }) => isSelected && reference === testTitleReference);
                    return !isSelectedReference;
                  })
                })
              )
        })
    })
  )
});

const yupCovenantDetail = yup.object<CovenantDetailModel>({
  referenceNumber: yup.string().default('').trim().required(msg.REQUIRED).test(vicMcpNumberTest)
});

const yupEncumbrance = yup
  .object<EncumbranceModel>({
    isCovenantToBeCreated: yup.boolean(),
    benefitedLandReferences: yup.mixed<BenefitedLandReferenceModel[]>().test(
      validateWhenVisible({
        visibilityCheck: VISIBILITY_CHECK_COVENANT,
        validationWhenVisible: yup //
          .array<BenefitedLandReferenceModel>()
          .min(1, msg.MIN_ITEMS(1))
          .max(20, msg.MAX_ITEMS(20))
          .of(yupBenefitedLandReference),
        isObjectOrArray: true
      })
    ),
    covenantDetails: yup.mixed<CovenantDetailModel[]>().test(
      validateWhenVisible({
        visibilityCheck: VISIBILITY_CHECK_COVENANT,
        validationWhenVisible: yup //
          .array<CovenantDetailModel>()
          .min(1, msg.MIN_ITEMS(1))
          .max(20, msg.MAX_ITEMS(20))
          .of(yupCovenantDetail),
        isObjectOrArray: true
      })
    ),
    expiryDate: yup //
      .mixed<Date | string | null>() //
      .test(
        validateWhenVisible({
          visibilityCheck: VISIBILITY_CHECK_COVENANT,
          validationWhenVisible: yupDatePicker.min(MIN_EXPIRY_DATE, 'Must be future date')
        })
      )
  })
  .defined();

export default yupEncumbrance;
