import { HttpTypes } from '@sympli/api-gateway/types';

import { DateRangeModel } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { FinancialTableModel, FinancialTableRowModel, FinancialWorkspaceV2FilterQueryParamsModel } from './table/models';

export type FinancialWorkspacesV2ApiRequest = DashboardTableFiltersModel & FinancialWorkspaceV2FilterQueryParamsModel;
export type FinancialWorkspacesV2ApiResponse = HttpTypes.PaginatedItemsModel<FinancialTableModel>;
export type FinancialWorkspaceRowDetailV2ApiRequest = {
  workspaceId: string;
  participantId: string;
};
export interface FinancialWorkspacesStateV2 {
  rowDetailIndex: number | undefined;

  items: FinancialTableModel[];
  error?: string;
  status: ApiStatus;
  totalCount: number;

  query: FinancialWorkspacesV2ApiRequest;

  updatedTime: Date;
}

export interface FinancialWorkspacesRowDetailStateV2 {
  data?: FinancialTableRowModel;
  error?: string;
  status: ApiStatus;
  query?: FinancialWorkspaceRowDetailV2ApiRequest;
}

export enum FinancialDashboardRouteTabsEnumV2 {
  all = 'all',
  active = 'active',
  toBeRebooked = 'toBeRebooked',
  complete = 'complete',
  archived = 'archived',
  recent = 'recent'
}

export interface FinancialDashboardRouteParams {
  tab?: FinancialDashboardRouteTabsEnumV2;
}

export interface DashboardTableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
}

// this model is used when saving data into local storage
export interface DashboardFilterModel {
  selectedTab: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
  selectedFilters?: number[];
  defaultOpenFilter?: number; // we default open a filter only when it's added by + button
  settlementPeriod?: DashboardDateRangeModel;
  lastAccessPeriod?: DashboardDateRangeModel;
  archivedDatePeriod?: DashboardDateRangeModel;
  myTasks?: number[];
  myRoles?: number[];
  jurisdictions?: number[];
  financialStatus?: number[];
  fundsStatus?: number[];
  workspaceStatus?: number[];
  documentStatus?: number[];
  groupIds?: string[];
  userIds?: string[];
  workspaceMessageStatus?: number[];
  workspaceTransactionType?: number[];
}

export enum StaffFilterEnum {
  AllUsers = 0,
  AssignedToMe = 1,
  Unassigned = 2,
  AssignedToOther = 3
}

export interface WorkspaceFilterQueryParamsModel<T = any> {
  settlingPeriod?: DateRangeModel;
  workspaceStatusIds?: HttpTypes.WorkspaceStatusEnum[]; // TODO Array<number> for pluralized api param
  lodgementCaseStatusIds?: HttpTypes.LodgementCaseStatusEnum;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  documentFormIdentifier?: HttpTypes.DocumentTypeIdentifierEnum; // used for non-financial workspaces only
  type?: T; // current, locked, archived
  allocatedUserType?: StaffFilterEnum;
  searchTerm?: string;
  isIntegrated?: boolean;
  workspaceRoleIds?: HttpTypes.WorkspaceRoleEnum;
  groupId?: string;
  userId?: string;
  hasUnreadMessages?: boolean;
}
