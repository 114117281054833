import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import yupTitleReferences from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import yupExtentOfProhibitions from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/action-prohibited/validationSchema';
import yupCaveators from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/caveator/validationSchema';
import yupDutyAssessmentNumber from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/duty-assessment-number/validationSchema';
import { yupPartyBook } from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/party-book/validationSchema';
import yupProprietors from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/registered-proprietor/validationSchema';
import yupDetailsForServiceOfNotice from 'src/containers/documents/scaffolded-form/nsw/2-21/caveat/2-21-1/sections/service-of-notice/validationSchema';
import { ADDRESS_BOOK_KEY, CaveatModel_2_21_1, PARTY_BOOK_KEY } from './models';
import yupClaimDetails from './sections/claim-details/validationSchema';
import yupSupportingDocuments from './sections/supporting-documents/validationSchema';

export default yup.object<CaveatModel_2_21_1>().shape({
  [PARTY_BOOK_KEY]: yupPartyBook,
  titleReferences: yupTitleReferences,
  extentOfProhibitions: yupExtentOfProhibitions,
  claimDetails: yupClaimDetails,
  dutyAssessmentNumber: yupDutyAssessmentNumber,
  caveators: yupCaveators,
  detailsForServiceOfNotice: yupDetailsForServiceOfNotice,
  proprietors: yupProprietors,
  caveatStatementsAgreed: yup.boolean().test('Checked test', 'Must agree to caveat statement', Boolean),
  supportingDocuments: yupSupportingDocuments,
  [ADDRESS_BOOK_KEY]: yup.mixed<AddressBookEntityModel[]>()
});
