import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { useWorkspaceFinancialAccounts } from 'src/store/reducers/workspace/workspaceDebitAccountsFeed';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import DirectionsContext from '../../../../Directions.context';
import { useDirectionsCategories } from '../../../../reducers/categories';
import DirectionPayeeDetail, { DirectionsPayeeDetailProps } from './DirectionPayeeDetail';

function DirectionPayeeDetailContainer(
  props: Omit<DirectionsPayeeDetailProps, 'isLoading' | 'directionsCategoriesDetail' | 'error' | 'currentParticipant' | 'dispatch' | 'setFocusLabel'>
) {
  const { setFocusLabel } = React.useContext(DirectionsContext);
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceDetail = useWorkspaceDetail(props.workspaceId, props.participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const { isLoading, detail: directionsCategoriesDetail, error } = useDirectionsCategories(props.workspaceId, props.participantId);
  const currentParticipant: HttpTypes.WorkspaceParticipant | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: props.participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const { items: financialAccounts } = useWorkspaceFinancialAccounts();
  const usageState = useStoreSelector(store => store.generalAccountUsages);

  return (
    <DirectionPayeeDetail //
      {...props}
      isLoading={isLoading}
      directionsCategoriesDetail={directionsCategoriesDetail}
      error={error}
      currentParticipant={currentParticipant}
      dispatch={dispatch}
      usage={usageState.detail}
      financialAccounts={financialAccounts}
      setFocusLabel={setFocusLabel}
      workspaceClusterDetail={workspaceDetail.detail?.linkedWorkspaceCluster}
    />
  );
}

export default React.memo(DirectionPayeeDetailContainer);
