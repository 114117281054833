import * as yup from 'yup';

import { Mortgage2_25_1Model } from './models';
import yupMortgageeDetails from './sections/mortgagee-details/validationSchema';
import yupMortgagors from './sections/mortgagors/validationSchema';
import yupTermsAndConditions from './sections/terms-and-conditions/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<Mortgage2_25_1Model>({
    titleReferences: yupTitleReferences,
    mortgageeDetails: yupMortgageeDetails,
    termsAndConditions: yupTermsAndConditions,
    mortgagors: yupMortgagors,
    partyBook: yup.mixed(),
    addressBook: yup.mixed(),
    needToGenerateMortgagors: yup.mixed(),
    transferees: yup.mixed(),
    proprietors: yup.mixed()
  })
  .defined()
  .log();
