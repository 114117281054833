import * as yup from 'yup';

import { HttpTypes } from '@sympli/api-gateway/types';
import msg from '@sympli/ui-framework/utils/messages';

import { DistributionFormikModel, SourceFundFormikModel, TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { getDirectionsYupObject, getSourceFundsYupObject, oneSourceFundOnly } from '../../sharedValidationSchema';
import { CategoryEnum } from '../discharge-mortgage-directions/models';
import { isLoanAdvance } from './helper';
import { IncomingDirectionsFormModel } from './models';

// TODO extra validation rule, need at least one (NEW) distribution OR at least one (NEW) source fund
export function getValidationSchema(trustAccountBankDetailMap: TrustAccountMap, workspaceId: string, numberOfLinkedWorkspaces?: number) {
  return yup.object<IncomingDirectionsFormModel>({
    distributions: yup
      .array<DistributionFormikModel>()
      .of(
        yup.lazy(value => {
          const { isLocked } = value as DistributionFormikModel;
          if (isLocked) {
            return yup.object({});
          } else {
            return yup.object(getDirectionsYupObject(workspaceId, numberOfLinkedWorkspaces));
          }
        })
      )
      .defined(),
    sourceFunds: yup
      .array<SourceFundFormikModel>(
        yup
          .object<SourceFundFormikModel>({
            ...getSourceFundsYupObject(trustAccountBankDetailMap, numberOfLinkedWorkspaces),
            category: yup
              .string()
              .required(msg.REQUIRED)
              .test('sourceFund-max-one-loan-advance', 'Only one Loan Advance line item is allowed', function test(this: yup.TestContext, value: string) {
                const sourceFund = this.from[0].value as SourceFundFormikModel;
                const formModel = this.from[1].value as IncomingDirectionsFormModel;

                return !sourceFund.isEditorOpen || !isLoanAdvance(value) || formModel.sourceFunds.filter(x => isLoanAdvance(x.category)).length <= 1;
              })
              .test('only-one-linked-source-fund', 'Only one Linked Source Fund line item is allowed per Workspace', oneSourceFundOnly)
              .test(
                'only-one-linked-workspace',
                'Maximum 10 Linked Workspaces are allowed in a Linked Settlement',
                function lessThanTenLinkedWorkspace(this: yup.TestContext, category: string) {
                  if (category !== CategoryEnum.LinkedSourceFund || !numberOfLinkedWorkspaces) {
                    return true;
                  }
                  const direction = this.from[0].value as DistributionFormikModel;
                  return !direction.isEditorOpen || numberOfLinkedWorkspaces! < 10;
                }
              ),
            subscriberName: yup.mixed<string>(),
            accountType: yup.mixed<HttpTypes.BankAccountTypeEnum>(),
            participantId: yup.mixed<string>(),
            isEditorOpen: yup.mixed<boolean>(),
            paymentMethod: yup.mixed<HttpTypes.PaymentMethodEnum>()
          })
          .defined()
      )
      .defined(),
    hasManageWorkspacePermission: yup.mixed<boolean>()
  });
}
