import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import GenericLayoutWrapper from 'src/@core/components/layout/GenericLayoutWrapper';
import TopPanelContainer from 'src/components/layout/v2/TopPanelContainer';
import { useAccessibleReports, useRequestReduxDataOnMount } from 'src/hooks';
import { actionFetchReportsDashboard } from './actions';
import ReportsDashboard from './components/report-dashboard';
import { SavedReportModel } from './models';
import { ReportsDashboardState } from './reducers/reportsDashboard';

function ReportsDashboardContainer(): JSX.Element {
  const { status, reports } = useAccessibleReports();

  const [{ savedReports, status: savedReportsStatus }] = useRequestReduxDataOnMount<ReportsDashboardState>(actionFetchReportsDashboard.request(), state => state.reportsDashboard);

  const favouriteReports: SavedReportModel[] = savedReports?.filter(report => report.feature === HttpTypes.ReportFeatureTypeEnum.Favourite) ?? [];
  const scheduledReports: SavedReportModel[] = savedReports?.filter(report => report.feature === HttpTypes.ReportFeatureTypeEnum.Scheduled) ?? [];

  return (
    <GenericLayoutWrapper topPanel={<TopPanelContainer />}>
      <ReportsDashboard //
        favouriteReports={favouriteReports}
        scheduledReports={scheduledReports}
        reportPermissions={reports}
        reportCardsStatus={status}
        savedReportsStatus={savedReportsStatus}
      />
    </GenericLayoutWrapper>
  );
}

export default React.memo(ReportsDashboardContainer);
