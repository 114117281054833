import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useFeatureFlag, usePreviousValue, useRouterParams, useStoreSelector } from 'src/hooks';
import { useLrsInfo } from 'src/store/reducers/lrsInfo';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceDocuments } from 'src/store/reducers/workspace/workspaceDocuments';
import { useWorkspaceParticipants } from '../../store/reducers/workspace/workspaceParticipants';
import { findCurrentWorkspaceParticipantSelector } from '../shared/selectors';
import VerifyingNotification from './components/verifying-notification';
import DocumentsPage from './DocumentsPage';
import { resolveDocumentDetailLink } from './helpers';
import { useWorkflowStep } from './hooks';
import { DocumentPageRouteParams } from './models';
import { useDocumentDetail } from './reducers/document';
import { documentLodgementDetailSelector } from './selectors';
import { useStyles } from './styles';

function DocumentsPageContainer() {
  const navigate = useNavigate();
  const queryParams = useRouterParams<DocumentPageRouteParams>();
  const { workspaceId, participantId, documentId } = queryParams;
  const profileState = useProfile();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const documentDetailState = useDocumentDetail(workspaceId, participantId, documentId);
  const documentMergedPdfState = useStoreSelector(store => store.documentMergedPdf);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const lodgementDetail = documentLodgementDetailSelector({ state: { workspaceDocuments: workspaceDocumentsState }, documentId });
  const classes = useStyles();
  const isCriticalRolesEnabled = useFeatureFlag(FeatureToggleEnum.criticalRoles);
  const isDocumentCombinationEnabled = useFeatureFlag(FeatureToggleEnum.documentCombination);
  const profileUserId: string = profileState.data!.userId;
  const lodgementCaseId = documentDetailState.detail?.lodgementCaseId;
  const jurisdictionId: HttpTypes.JurisdictionsEnum | undefined = workspaceBasicInfoState.detail?.jurisdictionId;
  const lodgementCase: HttpTypes.LodgementCase | undefined = workspaceBasicInfoState.detail?.lodgementCases.find(x => x.id === lodgementCaseId);

  const documentHashId = `${documentId}-${documentDetailState.detail?.hash}`;
  const [currentDocumentHashId, setCurrentDocumentHashId] = React.useState<string>(documentHashId);
  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState,
    lodgementCase
  });
  const lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum | undefined = lodgementCase?.lodgementCaseStatusId;
  const workspaceStatusId: HttpTypes.WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const workspaceTypeId: HttpTypes.WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  const lrsInfo = useLrsInfo(jurisdictionId);

  const verifying = documentDetailState.detail?.status === HttpTypes.DocumentStatusEnum.LodgementVerificationInProgress;

  const documentList = workspaceDocumentsState.items;

  const previousLodgementCaseStatusId = usePreviousValue(lodgementCaseStatusId);
  const [lvFailed, setLvFailed] = React.useState(false);
  const isFinancial = !isLodgementOnly;
  const displayDocumentAsPdf = !!(
    currentWorkspaceParticipant?.displayDocumentAsPdf || HttpTypes.DocumentTypeIdentifierEnum.LodgementInstructions === documentDetailState.detail?.documentForm.documentType
  );
  const isPdfUrlExists: boolean = !!documentDetailState.detail?.pdfS3Key;

  const workflowStepParams = useWorkflowStep({
    documentId: documentDetailState.detail?.documentId,
    status: documentDetailState.detail?.status,
    documentWorkflowType: documentDetailState.detail?.documentWorkflowType,
    lodgementCaseStatusId,
    isFinancial,
    isPrimary: lodgementCase?.isPrimary,
    documentPermissions: documentDetailState.detail?.documentPermissions,
    displayDocumentAsPdf,
    parsedData: documentDetailState.detail?.parsedData,
    isPdfUrlExists
  });

  // it's important to handle isRefetching as well in order to make sure we get refreshed http cookie before we try to load PDF
  // explicitly check for isEditView to avoid destroying the form (replacing by loader) when we are in edit view
  const isLoading =
    workspaceBasicInfoState.isLoading ||
    documentDetailState.isLoading ||
    (documentDetailState.isRefetching && !workflowStepParams.isEditView) ||
    currentDocumentHashId !== documentHashId;

  React.useEffect(() => {
    if (currentDocumentHashId !== documentHashId) {
      setCurrentDocumentHashId(documentHashId);
    }
  }, [currentDocumentHashId, documentHashId]);

  React.useEffect(() => {
    // when lodgementCaseStatusId transitions from LodgementVerificationRequested to LodgementVerificationError, set lvFailed to true
    if (
      previousLodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested &&
      lodgementCaseStatusId === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError
    ) {
      setLvFailed(true);
    }
  }, [lodgementCaseStatusId, previousLodgementCaseStatusId]);

  React.useEffect(() => {
    // reset lvFailed when a different document is opened (we don't want to redirect
    // to the failed document every time user makes change in a different document)
    setLvFailed(false);
  }, [documentId]);

  React.useEffect(() => {
    // if LV failed, find the failed document and redirect to it if it is not currently open
    if (!lvFailed) {
      return;
    }
    const lvFailedDocument = documentList.find(d => d.documentStatus.id === HttpTypes.DocumentStatusEnum.ResolveIssues);
    if (lvFailedDocument && lvFailedDocument.documentId !== documentId) {
      const documentLink = resolveDocumentDetailLink({ participantId, workspaceId, documentId: lvFailedDocument.documentId });
      navigate(documentLink);
    }
  }, [documentId, documentList, lvFailed, navigate, participantId, workspaceId]);
  return (
    <>
      {verifying ? <VerifyingNotification /> : null}
      <FlexLayout flexDirection="column" fullWidth fullHeight className={classes.root}>
        {/* We want to make sure both workspace detail and document detail are present before we show the document content */}
        {isLoading ? (
          <BlockLoader />
        ) : (
          Number.isInteger(jurisdictionId) && //
          Number.isInteger(lodgementCaseStatusId) &&
          Number.isInteger(workspaceStatusId) &&
          Number.isInteger(workspaceTypeId) &&
          documentDetailState.detail && (
            <DocumentsPage //
              profileUserId={profileUserId}
              queryParams={queryParams}
              workspaceDetail={{
                // workspace basic info
                jurisdictionId: jurisdictionId!,
                workspaceTypeId: workspaceTypeId!,
                // workspace detail
                workspaceLodgementDate: workspaceDetailState.detail?.lodgementDetails.find(x => x.lodgementCaseId === lodgementCaseId)?.dateTime,
                // LRS info
                isValidOperatingHoursForRegOnly: lrsInfo.isValidOperatingHoursForRegOnly,
                lodgementCases: workspaceDetailState.detail?.lodgementCases,
                isLocked: workspaceDetailState.detail?.isLocked,
                lodgementDetails: workspaceDetailState.detail?.lodgementDetails
              }}
              detail={documentDetailState.detail}
              documentList={documentList}
              documentMergedPdfState={documentMergedPdfState}
              verifying={verifying}
              currentParticipant={currentWorkspaceParticipant}
              participants={workspaceParticipantsState.items}
              lodgementDetail={lodgementDetail}
              lodgementCase={lodgementCase!}
              isCriticalRolesEnabled={isCriticalRolesEnabled}
              isDocumentCombinationEnabled={isDocumentCombinationEnabled}
              // * Workflow step related props
              workflowStep={workflowStepParams.workflowStep}
              onStepClick={workflowStepParams.onStepClick}
              updateWorkflowStep={workflowStepParams.updateWorkflowStep}
              isEditView={workflowStepParams.isEditView}
              readOnlyViewMode={workflowStepParams.readOnlyViewMode}
            />
          )
        )}
      </FlexLayout>
    </>
  );
}

export default React.memo(DocumentsPageContainer);
