import React from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import Consideration from '@sympli-mfe/document-forms-framework/components/sections/consideration';
import { DocumentAttachments } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import TitleReferences, { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import { DEFAULT_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG } from './config';
import { generateTransferors } from './helpers';
import { BaseTransfer2_24_1Model, TransferRootFormContext } from './models';
import FinalTenancyHoldings from './sections/final-tenancy-holdings/FinalTenancyHoldings';
import Transferees from './sections/transferees';
import Transferors from './sections/transferors';

function RootForm<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const context = useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  const formikProps = useFormikContext<BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const fieldName = modelKey<BaseTransfer2_24_1Model<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();

  const { setValues } = formikProps;

  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, i: number[]) => {
      setValues(values => {
        return generateTransferors(values, context.partyFormConfig, context.nameChangeConversion, context.partyConversion);
      });
    },
    [context.nameChangeConversion, context.partyConversion, context.partyFormConfig, setValues]
  );

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          focusRef={focusOnTitleReferencesRef}
          onChange={handleSelectedTitleReferenceChange}
          skipTouchedCheck
          partLandAffectedType="description"
          resolvePartLandAffectedVisibility={() => false}
        />

        <Transferors //
          name={fieldName('transferors')}
        />

        {context.ConsiderationComponent ? (
          <context.ConsiderationComponent name={fieldName('consideration')} />
        ) : (
          <Consideration //
            name={fieldName('consideration')}
            jurisdiction={context.jurisdiction}
          />
        )}

        <Transferees //
          name={fieldName('transferees')}
        />

        <FinalTenancyHoldings name={fieldName('finalTenancyHoldings')} />

        <DocumentAttachments
          name={fieldName('attachments')}
          minItems={0}
          attachmentDocumentTypes={context.documentAttachmentTypeOptions}
          labelConfig={DEFAULT_TRANSFER_DOCUMENT_ATTACHMENT_LABEL_CONFIG}
        />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);
