import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import MfaAuthenticationDialogContainer from 'src/@core/auth/mfa-authentication-dialog';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import DocumentNotificationPanel from 'src/containers/documents/components/document-notification-panel/DocumentNotificationPanel';
import { DocumentResponseBase, getPanelDetails } from 'src/containers/documents/components/document-notification-panel/helper';
import { resolveDocumentPermissions } from 'src/containers/documents/helpers';
import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { WorkspaceDetailState } from '../../../../store/reducers/workspace/workspaceDetail';
import { WorkspaceParticipantsState } from '../../../../store/reducers/workspace/workspaceParticipants';
import { DirectionsRouteLocationState } from './DirectionsContainer';
import { FSS_DOCUMENT_TYPE } from './helpers';
import { DirectionCommonProps, DirectionDocumentWorkflowMapping } from './models';
import { DirectionsCategoriesState } from './reducers/categories';
import { directionsWorkflowStepsSelector } from './steps';
import EditDirectionsContainer from './views/edit-directions';
import ReviewDirections from './views/review-directions';
import SignDirections from './views/sign-directions';

import type { SafeDispatch } from 'src/hooks';

interface Props {
  // route params
  workspaceId: string;
  participantId: string;
  state: DirectionsRouteLocationState;
  pathname: string;
  navigate: NavigateFunction;

  // user profile
  userProfile: HttpTypes.UserProfileModel;
  // workspace detail
  workspaceDetailState: WorkspaceDetailState;
  // workspace participants
  workspaceParticipantsState: WorkspaceParticipantsState;
  // current participant detail
  currentParticipant?: HttpTypes.WorkspaceParticipant;
  // directions breakdown and categories
  detail: HttpTypes.WorkspaceDirectionsApiResponse;
  directionsCategoriesState: DirectionsCategoriesState;

  // other
  isLoading: boolean;
  dispatch: SafeDispatch<Action<any>>;
  // new layout
}
export const PORTAL_ID_FOR_DIRECTIONS_SUMMARY = _uniqueId('portal-directionSummary');

function Directions({
  //
  workspaceId,
  participantId,
  state,
  navigate,
  pathname,
  detail,
  workspaceDetailState,
  currentParticipant,
  userProfile,
  directionsCategoriesState,
  workspaceParticipantsState,
  dispatch,
  isLoading
}: Props) {
  const [workflowStep, setWorkflowStep] = React.useState<DocumentWorkflowStepsEnum | undefined>();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const status = detail?.documentSummary.status;
    if (Number.isInteger(status)) {
      setWorkflowStep(DirectionDocumentWorkflowMapping[status!]);
    }
  }, [detail]);

  React.useEffect(() => {
    // wait for workflowStep to be initialized
    if (!Number.isInteger(workflowStep)) {
      return;
    }

    if (state) {
      if (state.showDialog) {
        setDialogOpen(true);
      }

      // we check user has permission to access the step passed via state
      if (state.workflowStep && state.workflowStep < workflowStep!) {
        setWorkflowStep(state.workflowStep);
      }
      // cleanup location state
      // history.replace({ ...history.location, state: undefined });
      navigate(pathname, { state: undefined, replace: true });
      //
    }
  }, [dispatch, navigate, pathname, state, workflowStep]);

  function MapToDocumentStatus(financialStatus: HttpTypes.FinancialStatusEnum): HttpTypes.DocumentStatusEnum {
    switch (financialStatus) {
      case HttpTypes.FinancialStatusEnum.Signed:
        return HttpTypes.DocumentStatusEnum.Signed;
      case HttpTypes.FinancialStatusEnum.PartiallySigned:
      case HttpTypes.FinancialStatusEnum.Reviewed:
      case HttpTypes.FinancialStatusEnum.NotApplicable:
        return HttpTypes.DocumentStatusEnum.Signing;
      case HttpTypes.FinancialStatusEnum.Saved:
        return HttpTypes.DocumentStatusEnum.Reviewing;
      case HttpTypes.FinancialStatusEnum.InPreparation:
        return HttpTypes.DocumentStatusEnum.InPreparation;
    }
  }

  function getSectionPanelDetails(financialActions: HttpTypes.DocumentActionModel[], financialSummary: HttpTypes.FinancialSectionSummary) {
    const paymentsDocument: DocumentResponseBase = {
      documentActions: financialActions,
      documentDenyPermissions: financialSummary.documentDenyPermissions,
      isFullySignedByAllParticipants: false, // not applicable for financials
      status: MapToDocumentStatus(financialSummary.financialStatus)
    };

    return getPanelDetails(paymentsDocument, workflowStep!, FSS_DOCUMENT_TYPE, detail!.versionId, userProfile.userId, participantId, []);
  }

  const renderDocumentNotification = () => {
    if (detail == null) {
      return null;
    }

    const documentSummary = detail.documentSummary;
    if (!documentSummary.paymentsSummary || !documentSummary.sourceFundsSummary) {
      return null;
    }

    const financialActions = documentSummary.financialActions ?? [];
    const paymentsPanelDetails = getSectionPanelDetails(financialActions, documentSummary.paymentsSummary);
    const sourceFundsPanelDetails = getSectionPanelDetails(financialActions, documentSummary.sourceFundsSummary);

    // nothing to display
    if (paymentsPanelDetails.panelVariant === undefined && sourceFundsPanelDetails.panelVariant === undefined) {
      return null;
    }

    // You can sign either payments or source funds, show no panel
    if (
      (paymentsPanelDetails.panelVariant === undefined && documentSummary.paymentsSummary.financialStatus !== HttpTypes.FinancialStatusEnum.NotApplicable) ||
      (sourceFundsPanelDetails.panelVariant === undefined && documentSummary.sourceFundsSummary.financialStatus !== HttpTypes.FinancialStatusEnum.NotApplicable)
    ) {
      return null;
    }

    const panelToDisplay = paymentsPanelDetails.priority > sourceFundsPanelDetails.priority ? paymentsPanelDetails : sourceFundsPanelDetails;

    return (
      <DocumentNotificationPanel //
        variant={panelToDisplay.panelVariant!}
        primary={panelToDisplay.primaryMessage}
        secondary={panelToDisplay.secondaryMessage}
        linkToWorkspace={resolveWorkspaceDetailLink({ workspaceId, participantId })}
      />
    );
  };

  const renderContent = () => {
    if (isLoading || !currentParticipant) {
      return <BlockLoader />;
    }

    const { workspaceType, isLocked, requiresStampDuty } = workspaceDetailState.detail!;
    const { items } = workspaceParticipantsState;

    const commonProps: DirectionCommonProps = {
      queryParams: {
        workspaceId,
        participantId
      },
      detail,
      currentParticipant,
      workspaceParticipants: items,
      workspaceType: workspaceType.id,
      directionsCategoriesDetail: directionsCategoriesState.detail!,
      steps: directionsWorkflowStepsSelector(detail),
      onStepChange: setWorkflowStep,
      portalIdForDirectionSummary: PORTAL_ID_FOR_DIRECTIONS_SUMMARY,
      paymentsPermissions: resolveDocumentPermissions(detail.documentSummary.paymentsSummary.documentPermissions),
      sourceFundPermissions: resolveDocumentPermissions(detail.documentSummary.sourceFundsSummary.documentPermissions),
      requiresStampDuty: requiresStampDuty,
      hasManageWorkspacePermission: userProfile.userPermissions.includes(HttpTypes.UserLevelPermissionEnum.ManageInvitations)
    };

    switch (workflowStep) {
      case DocumentWorkflowStepsEnum.Write:
        return (
          <EditDirectionsContainer
            {...commonProps}
            onDialogToggle={open => {
              setDialogOpen(open);
            }}
            dialogOpen={dialogOpen}
            isLocked={isLocked}
          />
        );
      case DocumentWorkflowStepsEnum.Review:
        return (
          <ReviewDirections //
            {...commonProps}
          />
        );
      case DocumentWorkflowStepsEnum.Sign:
        return (
          <SignDirections //
            {...commonProps}
            userProfile={userProfile}
            dispatch={dispatch}
          />
        );
      default:
        return 'Unknown document status';
    }
  };

  return (
    <>
      <MfaAuthenticationDialogContainer />
      <FlexLayout flexDirection="column" fullWidth fullHeight>
        {renderDocumentNotification()}
        {renderContent()}
      </FlexLayout>
    </>
  );
}

export default React.memo(Directions);
