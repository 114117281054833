import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { titlesHasMatchingFraction, titlesHasSameTenancyStructureAndNumberOfGroups, titlesHaveMatchingPartyTypes } from '../../helpers';
import { TitleReferenceModel, TransmissionApplicationWithoutDuty2_21_1Model } from '../../models';

type FormModel = TransmissionApplicationWithoutDuty2_21_1Model;
interface TransmissionContext {
  partyBook: FormModel['partyBook'];
}

const contextResolver = ({ partyBook }: FormModel): TransmissionContext => ({ partyBook });

// path: titleReferences

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel, TransmissionContext>() //
  .test(
    //
    'Multiple title selected: check for matching relinquishing proprietor details',
    'Validation failed',
    function test(this: yup.TestContext<TransmissionContext>, titleReferences: TitleReferenceModel[]) {
      const { partyBook } = this.options.context!;
      const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected);
      if (selectedTitleReferences.length <= 1) {
        return true;
      }

      // Check tenancy type per title
      if (!titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences)) {
        return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles' });
      }

      if (
        titlesHasSameTenancyStructureAndNumberOfGroups(selectedTitleReferences) &&
        titlesHaveMatchingPartyTypes(selectedTitleReferences, partyBook) &&
        !titlesHasMatchingFraction(selectedTitleReferences)
      ) {
        return this.createError({
          message: 'The share fraction of the proprietors does not match; please remove the mismatched titles. To transact on mismatched titles, please create a new workspace.'
        });
      }

      return true;
    }
  );

const yupMemoizedSchema = memoizeSchemaWithContext<TitleReferenceModel[], FormModel, TransmissionContext>(yupTitleReferences, contextResolver);
export default yupMemoizedSchema;
