import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { IconActiveLink } from '../../icons';

interface Props {
  icon: JSX.Element;
  to: string;
  isActive: boolean;
  dataTestId?: string;
}

function TopPanelIconLink({ isActive, icon, to, dataTestId }: Props) {
  const iconProps: React.HTMLAttributes<HTMLElement> = {
    className: 'h-[28px] w-[28px] medium-screen:h-[36px] medium-screen:w-[36px]'
  };

  return (
    <div className="relative" data-testid={dataTestId}>
      <Link to={to} className="group flex">
        {React.cloneElement(icon, iconProps)}
      </Link>
      <IconActiveLink
        aria-hidden
        className={classNames(isActive ? 'block' : 'hidden', 'absolute left-[50%] mt-[4px]')}
        style={{
          //
          transform: 'translate(-50%, -50%)',
          width: '13px'
        }}
      />
    </div>
  );
}

export default React.memo(TopPanelIconLink);
