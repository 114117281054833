import React, { useCallback, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { HttpTypes } from '@sympli/api-gateway/types';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { colors } from 'src/theme';
import { UserProfileDetail } from '../../models';

export interface ViewButtonProps {
  linkedToWorkspaceId: string;
  linkedWorkspaceParticipants: HttpTypes.LinkedWorkspaceParticipant[];
  userProfileDetail: UserProfileDetail;
  isCurrentWorkspace: boolean;
  viewButtonLabel?: string;
  onViewClick: (participantsHaveAccess: HttpTypes.LinkedWorkspaceParticipant[], linkedToWorkspaceId: string) => void;
  className?: string;
}

function ViewButton({
  //
  linkedToWorkspaceId,
  linkedWorkspaceParticipants,
  userProfileDetail,
  onViewClick,
  isCurrentWorkspace,
  viewButtonLabel,
  className
}: ViewButtonProps) {
  const participantsHaveAccess = useMemo(() => {
    const participantsIsSameSubscriber = linkedWorkspaceParticipants.filter(d => d.subscriberId === userProfileDetail.subscriberId);
    return participantsIsSameSubscriber;
  }, [linkedWorkspaceParticipants, userProfileDetail]);

  const handleOnViewButtonClick = useCallback(() => {
    onViewClick(participantsHaveAccess, linkedToWorkspaceId);
  }, [participantsHaveAccess, linkedToWorkspaceId, onViewClick]);

  //
  if (participantsHaveAccess.length === 0 || isCurrentWorkspace) {
    return <span className="px-[20px]">-</span>;
  }

  return (
    <SympliButton //
      variant="outlined"
      href={''}
      onClick={handleOnViewButtonClick}
      color={'warning'}
      data-binding="description"
      className={twMerge('inline-block max-w-[95%] items-start rounded-[34px] px-[24px] py-[8px] text-xs font-bold', className)}
      sx={{
        marginTop: 0,
        border: `2px solid ${colors.SUNNY_DAY_LUMINOUS}`,
        '&.Mui-disabled': { backgroundColor: colors.NEUTRAL_000 },
        color: colors.SUNNY_DAY_DARK,
        '&:hover': {
          border: `2px solid ${colors.SUNNY_DAY_LUMINOUS}`,
          color: colors.SUNNY_DAY_DARK,
          backgroundColor: colors.SUNNY_DAY_TRANSLUCENT
        }
      }}
    >
      {viewButtonLabel ?? 'VIEW'}
    </SympliButton>
  );
}

export default React.memo(ViewButton);
