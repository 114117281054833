import * as React from 'react';

import classNames from 'classnames';
import MuiBox from '@mui/material/Box';

import { ColumnsModel, SortDirectionEnum, TableV2 as Table } from '@sympli/ui-framework/components/table';
import { IconCheckboxTick } from '@sympli/ui-framework/icons';

import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { ApiStatus } from 'src/utils/http';
import DidNotLoad from '../../components/svg/DidNotLoad';
import NoResults from '../../components/svg/NoResults';
import { FinancialDashboardRouteTabsEnumV2 } from '../models';
import { resolveColumns } from './helpers';
import { FinancialTableContext, FinancialTableModel } from './models';
import { useFinancialTableStyles, useTableCellBodyCheckboxStyles, useTableCellHeadCheckboxStyles } from './styles';

const FinancialTable = ({
  //
  workspaceStatusGroup,
  assignmentType,
  onPaginationInfoChange,
  onPageChange,
  onSortChange,
  pageNumber,
  pageSize,
  rows,
  apiStatus,
  totalCount,
  rowDetailNode,
  rowDetailIndex,
  onRowToggle,
  sortBy,
  sortOrder,
  onRowClick,
  selectedRowIndexes,
  onSelectionChange
}: {
  workspaceStatusGroup: FinancialDashboardRouteTabsEnumV2;
  assignmentType: AssignmentTypeEnum;
  onPaginationInfoChange(paginationInfo: React.ReactNode): void;
  onPageChange(args: { pageNumber: number }): void;
  onSortChange?(args: {
    //
    sortBy: string;
    sortOrder: DashboardSortDirectionEnum;
  }): void;

  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: DashboardSortDirectionEnum;
  rows: FinancialTableModel[];
  apiStatus?: ApiStatus;
  totalCount: number;
  // detail
  rowDetailNode: React.ReactNode;
  rowDetailIndex?: number;
  onRowToggle(rowIndex: number, rowData: FinancialTableModel): void;
  onRowClick?(rowIndex: number, rowData: FinancialTableModel): void;

  // selection mode
  selectedRowIndexes: number[];
  onSelectionChange: (selectedRowIndexes: number[]) => void;
}) => {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const tableContext = React.useMemo<FinancialTableContext>(() => ({ screenVariant, selectedTab: workspaceStatusGroup }), [screenVariant, workspaceStatusGroup]);

  const columns: ColumnsModel<FinancialTableModel> = React.useMemo(
    () =>
      resolveColumns({
        //
        screenVariant,
        workspaceStatusGroup,
        assignmentType
      }),
    [screenVariant, workspaceStatusGroup, assignmentType]
  );
  const classes = useFinancialTableStyles({ workspaceStatusGroup, screenVariant, assignmentType });
  const isLoading = apiStatus === 'idle' || apiStatus === 'pending';
  const noDataMessage = apiStatus === 'rejected' ? <DidNotLoad /> : <NoResults />;

  const handleOnPageChange = React.useCallback(
    (pageNumber: number) => {
      onPageChange({ pageNumber });
    },
    [onPageChange]
  );

  const handleOnSortChange = React.useCallback(
    (sortBy: string, sortDirection: SortDirectionEnum) => {
      onSortChange?.({
        sortBy,
        sortOrder: sortDirection === SortDirectionEnum.asc ? DashboardSortDirectionEnum.asc : DashboardSortDirectionEnum.desc
      });
    },
    [onSortChange]
  );

  const tableCellHeadCheckboxClasses = useTableCellHeadCheckboxStyles();
  const tableCellBodyCheckboxClasses = useTableCellBodyCheckboxStyles();

  return (
    <Table //
      className={classNames(classes.root, assignmentType === AssignmentTypeEnum.ReAssigned && 'mb-[144px]')}
      context={tableContext}
      sortBy={sortBy}
      sortDirection={sortOrder === DashboardSortDirectionEnum.asc ? SortDirectionEnum.asc : SortDirectionEnum.desc}
      onSortChange={onSortChange ? handleOnSortChange : undefined}
      onPaginationInfoChange={onPaginationInfoChange}
      onPageChange={handleOnPageChange}
      noDataMessage={noDataMessage}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      selectionMode={assignmentType === AssignmentTypeEnum.ReAssigned ? 'all' : 'none'}
      selectedRowIndexes={assignmentType === AssignmentTypeEnum.ReAssigned ? selectedRowIndexes : undefined}
      onSelectionChange={assignmentType === AssignmentTypeEnum.ReAssigned ? onSelectionChange : undefined}
      classesTableHead={tableCellHeadCheckboxClasses}
      tableHeadCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #333' }} />}
      tableHeadCheckboxCheckedIcon={
        <MuiBox sx={{ width: 24, height: 24, border: '1px solid #333', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconCheckboxTick width={20} height={20} />
        </MuiBox>
      }
      tableBodyCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0' }} />}
      tableBodyCheckboxCheckedIcon={
        <MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconCheckboxTick width={20} height={20} />
        </MuiBox>
      }
      classesTableBody={tableCellBodyCheckboxClasses}
      disableHeadCheckboxIndeterminate
      recordType="Financial Workspace"
      columns={columns}
      rows={rows}
      isLoading={isLoading}
      onRowClick={onRowClick}
      {...(workspaceStatusGroup !== FinancialDashboardRouteTabsEnumV2.archived && assignmentType !== AssignmentTypeEnum.ReAssigned
        ? {
            onRowToggle,
            rowHoverActionNode: 'Workspace Details',
            rowHideActionNode: 'Hide Workspace Details',
            rowHoverActionColumnStartIndex: workspaceStatusGroup === FinancialDashboardRouteTabsEnumV2.complete ? -3 : -4,
            rowHoverActionColumnEndIndex: -1,
            rowDetailNode,
            rowDetailIndex
          }
        : {
            onRowClick
          })}
    />
  );
};

export default React.memo(FinancialTable);
