import { HttpTypes } from '@sympli/api-gateway/types';

import { WorkspaceActivityLogModel } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { DocumentParticipationAddedNotification, DocumentParticipationWithdrawnNotification } from './components/DocumentParticipation';
import { DocumentUpdatedNotification, LodgementInstructionsOrderUpdatedNotification } from './components/DocumentPrefill';

export function mapLogToNotification(log: WorkspaceActivityLogModel) {
  switch (log.workspaceLogType) {
    case HttpTypes.LogTypeEnum.DocumentParticipationWithdrawn:
      return DocumentParticipationWithdrawnNotification;
    case HttpTypes.LogTypeEnum.DocumentParticipationAdded:
      return DocumentParticipationAddedNotification;
    case HttpTypes.LogTypeEnum.LodgementInstructionsOrderUpdated:
      return LodgementInstructionsOrderUpdatedNotification;
    case HttpTypes.LogTypeEnum.DocumentUpdated:
    case HttpTypes.LogTypeEnum.WorkspaceDocumentsChanged:
      return DocumentUpdatedNotification;
    default:
      return undefined;
  }
}
