import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { YUP_RELINQUISHING_PARTY_BOOK_ID_REQUIRED } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/validationSchema';
import { PartyCapacityModel } from '@sympli-mfe/document-forms-framework/core/models';
import { getLookupValuesAllowingEmpty } from '@sympli-mfe/document-forms-framework/validation';
import { NecdsPartyCapacityEnum } from '@sympli-mfe/enums-2-24/necds';
import { HttpTypes } from '@sympli/api-gateway/types';
import msg from '@sympli/ui-framework/utils/messages';

import { resolveTransferorSchema } from 'src/containers/documents/scaffolded-form/shared/2-24/transfer/sections/transferors/validationSchema';
import yupConsideration from '../../../../shared/2-24/transfer/sections/consideration/validationSchema';
import { getLegalEntityNameQLD } from '../../helpers';
import { QLD_TRANSFER_PARTY_FORM_CONFIG, QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG, QLD_TRANSFEROR_PARTY_CAPACITY_TYPE } from './config';
import { Transfer2_24_1Model } from './models';
import { yupAttachments } from './sections/attachments/validationSchema';
import yupFinalHoldings from './sections/final-tenancy-holdings/validationSchema';
import yupPartyBook from './sections/party-book/validationSchema';
import yupTitleReferences from './sections/title-references/validationSchema';
import yupTransferees from './sections/transferees/validationSchema';

export default yup
  .object<Transfer2_24_1Model>()
  .partial({
    titleReferences: yupTitleReferences,
    partyBook: yupPartyBook,
    transferors: resolveTransferorSchema({
      getLegalEntityName: getLegalEntityNameQLD,
      allowNameChange: false,
      yupPartyBookId: YUP_RELINQUISHING_PARTY_BOOK_ID_REQUIRED.test(
        createPartyBookItemTestForPartyBookId(
          //
          QLD_TRANSFER_PARTY_FORM_CONFIG,
          {
            shouldValidatePartyData: (party: PartyModel) => !QLD_TRANSFER_RELINQUISHING_TENANCY_CONFIG.disablePartyEditConfig!.disabled(party)
          }
        )
      ),
      yupPartyCapacity: yup.object<PartyCapacityModel>({
        capacity: yup.mixed<NecdsPartyCapacityEnum>().oneOf(getLookupValuesAllowingEmpty(QLD_TRANSFEROR_PARTY_CAPACITY_TYPE)),
        details: yup
          .string()
          .notRequired()
          .max(300, ({ max }) => msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max))
      }),
      yupAddressBookId: yup.mixed<string>(),
      allowSeverJointTenancy: true
    }),
    transferees: yupTransferees,
    consideration: yupConsideration(HttpTypes.JurisdictionsEnum.QLD),
    attachments: yupAttachments,
    finalTenancyHoldings: yupFinalHoldings
  })
  .defined()
  .log();
