import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { actionFetchLinkedWorkspaces, LinkedWorkspacesApiRequest } from './actions';
import { LinkedWorkspaceApiResponseModel } from './models';

const fetchLinkedWorkspaces = ({ workspaceId }: LinkedWorkspacesApiRequest) => http.get<LinkedWorkspaceApiResponseModel[]>(`/workspaces/${encodeURIComponent(workspaceId)}/links`);

function* sagaFetchLinkedWorkspaces(action: ReturnType<typeof actionFetchLinkedWorkspaces.request>) {
  try {
    const data = yield* call(fetchLinkedWorkspaces, action.payload);

    yield put(actionFetchLinkedWorkspaces.success({ data }));
  } catch (error) {
    yield put(actionFetchLinkedWorkspaces.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchLinkedWorkspaces.request, sagaFetchLinkedWorkspaces)
];
