import _cloneDeep from 'lodash-es/cloneDeep';

import { getDocumentDetails } from '@sympli-mfe/document-forms-framework/api/document';
import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentBaseTitleReferenceModel } from '../../models';
import { CaveatModel, CaveatorPartyModel, WithdrawalOfCaveat_2_26_0_Model } from './models';

export const filterTitleReferences = (caveats: CaveatModel[]): DocumentBaseTitleReferenceModel[] => {
  // get unique list of title references across multiple caveats
  const titleReferences = caveats
    .flatMap(x => x.titleReferences)
    .reduce<DocumentBaseTitleReferenceModel[]>((titles: DocumentBaseTitleReferenceModel[], currentTitle: DocumentBaseTitleReferenceModel) => {
      const exists = titles.findIndex(tr => tr.reference === currentTitle.reference);
      if (exists !== -1) {
        titles[exists].isSelected = titles[exists].isSelected || currentTitle.isSelected;
      } else {
        titles.push(currentTitle);
      }
      return titles;
    }, []);
  return titleReferences;
};

export const getSelectedTitleReferences = (titleReferences: DocumentBaseTitleReferenceModel[]) => {
  // get selected title references
  return titleReferences.filter(({ isSelected }) => isSelected);
};

export const getCaveatsBeingWithdrawn = (caveats: CaveatModel[], titleReferences: DocumentBaseTitleReferenceModel[]): CaveatModel[] => {
  // get some caveats which contains all the selected title references
  const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected).flat();

  // filter caveats which include all the selected title references
  const caveatsBeingWithdrawn = caveats.filter(caveat => {
    const caveatTitleReferences = caveat.titleReferences.flatMap(title => title.reference);
    // check whether the caveat title references includes the title reference selected by the user
    return selectedTitleReferences.every(title => caveatTitleReferences.includes(title.reference));
  });

  return caveatsBeingWithdrawn;
};

export const getSelectedCaveat = (caveatSelection: CaveatModel[]): CaveatModel | undefined => {
  // get selected caveat
  // user can select only one caveat
  // automatically select the first one incase there is only one caveat
  if (caveatSelection.length === 1) {
    return caveatSelection[0];
  }

  return caveatSelection.find(({ isSelected }) => isSelected);
};

export const getCaveatorsForSelectedCaveat = (caveats: CaveatModel[]): CaveatorPartyModel[] | undefined => {
  // get caveators for the selected caveat
  // note: only one caveat can be withdrawn
  // !We need to return always full clone to prevent formik issue with touched objects not being correctly populated
  // when the user hits submit due to the implementation of the setNestedObjectValues within which they check for referential
  // equality of processed object/array
  return _cloneDeep(getSelectedCaveat(caveats)?.caveators || []);
};

export const getOtherWithdrawalsOfCaveatDocIds = (currentDocumentId: string, documents: HttpTypes.WorkspaceDocumentSummary[]) =>
  documents
    .filter(
      document =>
        document.documentForm.documentType === HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfCaveat && //
        document.documentId !== currentDocumentId
    )
    .flatMap(document => document.documentId);

export const getDealingNumbersSelectedInOtherWithdrawalsOfCaveat = async (workspaceId: string, participantId: string, documentIds: string[]) => {
  if (!documentIds.length) return [];
  const documentDetails = await getDocumentDetails(workspaceId, participantId, documentIds);
  return documentDetails.flatMap(documentDetails => {
    const data = JSON.parse(documentDetails.data) as WithdrawalOfCaveat_2_26_0_Model;
    return data.caveats.filter(caveat => caveat.isSelected).flatMap(caveat => caveat.dealingNumber);
  });
};
