import * as React from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import ToggleButtons from 'src/components/toggle-buttons/ToggleButtons';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';

export interface HeaderProps {
  title: string;
  assignmentType: AssignmentTypeEnum;
  assignmentTypeOptions?: LookupEnumModel<number>[];
  onAssignmentTypeChange: (assignment: AssignmentTypeEnum) => void;
  className?: string;
}

export interface HeaderPropsFromContainer {
  time: string;
}

const ASSIGNMENT_TYPE_LOOKUP_OPTIONS: LookupItemModel<AssignmentTypeEnum>[] = [
  {
    id: AssignmentTypeEnum.AssignedToMe,
    name: 'Assigned to me'
  },
  {
    id: AssignmentTypeEnum.AssignedToGroup,
    name: 'Assigned to group'
  },
  {
    id: AssignmentTypeEnum.ReAssigned,
    name: 'Reassign'
  }
];

function Header({
  //
  title,
  assignmentType,
  assignmentTypeOptions = ASSIGNMENT_TYPE_LOOKUP_OPTIONS,
  onAssignmentTypeChange,
  children,
  className,
  time
}: React.PropsWithChildren<HeaderProps & HeaderPropsFromContainer>) {
  return (
    <div className={classNames('relative flex h-[202px] shrink-0 flex-row items-center justify-between', className)}>
      <Box>
        <Typography variant="h1" className="font-volkswagen-serial-regular text-[40px] font-[400] leading-[40px] tracking-[1px] text-[var(--neutral-1000)]">
          {title}
        </Typography>
        <Typography className="text-[12px] font-[400] uppercase leading-[16px] text-[var(--neutral-600)]">{`Updated ${time}`}</Typography>
      </Box>
      <ToggleButtons //
        items={assignmentTypeOptions}
        value={assignmentType}
        label="Assignment"
        onChange={onAssignmentTypeChange}
      />
      {children}
    </div>
  );
}

export default React.memo(Header);
