import * as yup from 'yup';

import { NswDocumentPartyJustification } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { TitleReferenceModel } from 'src/containers/documents/scaffolded-form/shared/components/title-references/models';
import { NoticeOfDeath_2_21_2_Model } from '../../models';

export const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match; please remove the mismatched titles.';

export const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel<PartLandAffectedModel, NswDocumentPartyJustification>>().test(
  'Multiple title selected: check for tenancy structure is same',
  'Party merge failed.',
  function test(this: yup.TestContext<NoticeOfDeath_2_21_2_Model>) {
    const { mergeFailedReason } = this.options.context!;
    if (!mergeFailedReason) return true;

    if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame) return this.createError({ message: MISMATCHED_TENANCY_STRUCTURE_MESSAGE });

    if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
      return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.PartyCapacityMismatch)
      return this.createError({ message: 'The party capacity of the proprietors do not match; please remove the mismatched titles.' });

    if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
      return this.createError({ message: 'The share fraction of the proprietors does not match; please remove the mismatched titles.' });

    return false;
  }
);
