import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_TAE_PARTY_FORM_CONFIG } from '../../config';

const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

const validationSchema = resolveMemoizedReceivingTenancyValidation({
  //
  partyFormConfig: NSW_TAE_PARTY_FORM_CONFIG,
  isAddressRequired: false,
  forceDobVisibilityOnReceivingIndividual: false,
  shouldValidatePartyData
});

export default validationSchema;
