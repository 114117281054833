import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useProfile } from 'src/@core/store/reducers/profile';
import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import { actionUpdateConversationIsRead } from '../../actions';
import { useMessengerConversations } from '../../reducer';
import { getIsFinalisedOrArchived } from '../../selectors';
import MessageList from './MessageList';

interface Props {
  workspaceId: string;
  participantId: string;
  onConversationSelect(selectedConversationId?: string): void;
}

function MessageListContainer({ workspaceId, participantId, onConversationSelect }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const { timezone }: HttpTypes.UserProfileModel = useProfile().data!;
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);
  const messengerConversations = useMessengerConversations(workspaceId, participantId);

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const isNewMessageDisabled = getIsFinalisedOrArchived(workspaceBasicInfoState);

  const handleOnConversationRead = React.useCallback(
    (conversationId: string) => {
      const participantDetail = workspaceParticipants.items.find(x => x.id === participantId);
      if (!participantDetail?.archivedStatus) {
        dispatch(actionUpdateConversationIsRead.request({ workspaceId, participantId, conversationId, isRead: true }));
      }
    },
    [workspaceParticipants.items, participantId, dispatch, workspaceId]
  );

  const sortedConversations = React.useMemo(() => {
    // Sorting conversations based on the last message created time, in descending order
    return [...messengerConversations.items].sort((a, b) => {
      const aTimeStamp = new Date(a.messages[a.messages.length - 1].createdDate).getTime();
      const bTimeStamp = new Date(b.messages[b.messages.length - 1].createdDate).getTime();
      return bTimeStamp - aTimeStamp;
    });
  }, [messengerConversations.items]);

  return (
    <MessageList //
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // profile
      timezone={timezone}
      // workspace participants
      participants={workspaceParticipants.items}
      // conversation
      items={sortedConversations}
      onConversationRead={handleOnConversationRead}
      onConversationSelect={onConversationSelect}
      newMessageDisabled={isNewMessageDisabled}
    />
  );
}

export default React.memo(MessageListContainer);
