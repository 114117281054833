import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import { ColumnsModel, FormatterInputModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import ChipDetail from 'src/containers/dashboard/components/chip/ChipDetail';
import { getDashboardMessageDate } from 'src/containers/shared/messenger/helpers';
import { ConversationDocument } from 'src/containers/shared/messenger/models';

interface Row {
  isRead: boolean;
  title: string;
  sentBySubscriberName: string;
  text: string;
  createdDate: string;
}

interface Props {
  items: ConversationDocument[];
  timezone: HttpTypes.UserProfileModel['timezone'];
}

function MessagesCardDetail(props: Props) {
  const { items, timezone } = props;
  const latestConversation = items[0];
  const rows: Row[] = latestConversation
    ? [
        {
          isRead: latestConversation.isRead,
          title: latestConversation.subject,
          sentBySubscriberName: latestConversation.messages[latestConversation.messages.length - 1].sentBySubscriberName,
          text: latestConversation.messages[latestConversation.messages.length - 1].text,
          createdDate: latestConversation.messages[latestConversation.messages.length - 1].createdDate
        }
      ]
    : [
        {
          isRead: true,
          title: '',
          sentBySubscriberName: '',
          text: '',
          createdDate: ''
        }
      ];

  const columns: ColumnsModel<Row> = {
    messages: {
      label: 'RECENT MESSAGE',
      formatter: ({ row: { isRead, title, sentBySubscriberName, text, createdDate }, tooltipHandlers }: FormatterInputModel<Row>) => {
        if (isRead) {
          return <ChipDetail text="0 Messages" />;
        }

        return (
          <div className="leading-4">
            <div className="text-[12px] font-bold  capitalize">{title}</div>
            <div className="text-[12px] font-normal capitalize">{sentBySubscriberName}</div>
            <div className="text-[10px] font-normal text-[var(--neutral-400) capitalize">{getDashboardMessageDate(new Date(createdDate), timezone)}</div>
            <br />
            <div className="truncated text-[12px] font-normal" {...tooltipHandlers}>
              {text}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
    />
  );
}

export default React.memo(MessagesCardDetail);
