import { VIC_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/vic/2-28/config';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { VIC_TOC_PARTY_FORM_CONFIG } from './config';
import { fallbackMap } from './fallback';
import { ApiTransferOfControlModel, TransferOfControlModel } from './models';

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: VIC_TOC_PARTY_FORM_CONFIG
});

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: VIC_ADDRESS_FORM_CONFIG
});

function convertFromApiToFormModel(apiModel: ApiTransferOfControlModel, context: IConverterContext): TransferOfControlModel {
  // Implement any necessary conversion from API to form values
  let formModel: TransferOfControlModel = {
    ...apiModel,
    partyBook: convertPartyBookFromApiToFormModel(apiModel.partyBook || []),
    addressBook: convertAddressBookFromApiToFormModel(apiModel.addressBook || [])
  };
  formModel = applyDefaultMap(formModel, fallbackMap);
  return formModel;
}

function convertFromFormToApiModel(formModel: TransferOfControlModel, originalApiModel: ApiTransferOfControlModel): ApiTransferOfControlModel {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    partyBook: partyBookFromForm,
    addressBook: addressBookFromForm,
    ...rest
  } = formModel;
  return {
    ...rest,
    partyBook: convertPartyBookFromFormToApiModel(partyBookFromForm || [], originalApiModel.partyBook || []),
    addressBook: convertAddressBookFromFormToApiModel(addressBookFromForm || [], originalApiModel.addressBook || [])
  };
}

class Converter implements IConverter<TransferOfControlModel, ApiTransferOfControlModel> {
  fromApiToFormModel = convertFromApiToFormModel;
  fromFormToApiModel = convertFromFormToApiModel;
}

export default new Converter();
