import { stringify } from 'query-string';

import { ClientFetch, Config, FetchArgs, FetchInput } from './types';

export class FetchError extends Error {
  response: Response & { data?: unknown };
  constructor(response: Response, data?: { message: string }) {
    super(data?.message ?? response.statusText);
    this.response = {
      ...response,
      status: response.status,
      statusText: response.statusText,
      data
    };
  }
}

const normalizeRequest = (init: FetchArgs | undefined, headers: Headers, config: Config): RequestInit | undefined => {
  let body = init?.body;
  if (body && headers.get('content-type')?.includes('application/json')) {
    body = JSON.stringify(body);
  }

  return {
    ...init,
    headers,
    ...(body ? { body: body as RequestInit['body'] } : {})
  } as RequestInit;
};

const normalizeResponse = async (resp: Response, reqHeaders: Headers) => {
  if (resp.status >= 300) {
    let data;
    try {
      data = await resp.json();
    } catch (e) {}

    throw new FetchError(resp, data);
  }

  // If we requested JSON, we try to parse the response. Otherwise, we return the raw response.
  const isJsonRequest = reqHeaders.get('accept')?.includes('application/json');
  return isJsonRequest && resp.status !== 204 ? await resp.json() : resp;
};

const generateUUID = () => {
  let d = new Date().getTime();
  if (typeof window.performance !== 'undefined' && typeof window.performance.now === 'function') {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export class Client {
  #config: Config;
  #token: string | null = null;

  #fetch: ClientFetch;

  constructor(config: Config) {
    this.#config = config;

    this.#fetch = this.initClient();
  }

  /**
   * `fetch` closely follows (and uses under the hood) the native `fetch` API. There are, however, few key differences:
   * - Non 2xx statuses throw a `FetchError` with the status code as the `status` property, rather than resolving the promise
   * - You can pass `body` and `query` as objects, and they will be encoded and stringified.
   * - The response gets parsed as JSON if the `accept` header is set to `application/json`, otherwise the raw Response object is returned
   *
   * Since the response is dynamically determined, we cannot know if it is JSON or not. Therefore, it is important to pass `Response` as the return type
   *
   * @param input: FetchInput
   * @param init: FetchArgs
   * @returns Promise<T>
   */
  fetch<T extends any>(input: FetchInput, init?: FetchArgs): Promise<T> {
    return this.#fetch(input, init) as unknown as Promise<T>;
  }

  setToken(token: string | null) {
    this.#token = token;
  }

  protected initClient(): ClientFetch {
    const defaultHeaders = new Headers({
      'content-type': 'application/json',
      accept: 'application/json'
    });

    return async (input: FetchInput, init?: FetchArgs) => {
      // We always want to fetch the up-to-date JWT token before firing off a request.
      const headers = new Headers(defaultHeaders);
      const customHeaders = {
        'x-correlation-id': generateUUID(),
        authorization: this.#token ? `Bearer ${this.#token}` : null,
        ...this.#config.globalHeaders, // make sure to inject HEADER_APPLICATION_ENVIRONMENT
        ...init?.headers
      };
      // We use `headers.set` in order to ensure headers are overwritten in a case-insensitive manner.
      Object.entries(customHeaders).forEach(([key, value]) => {
        if (value === null) {
          headers.delete(key);
        } else {
          headers.set(key, value);
        }
      });

      let normalizedInput: RequestInfo | URL = input;
      if (input instanceof URL || typeof input === 'string') {
        const baseUrl = new URL(this.#config.baseUrl + (this.#config.basePath ?? '') + '/' + (init?.version ?? this.#config.baseVersion));
        const fullPath = `${baseUrl.pathname.replace(/\/$/, '')}/${input.toString().replace(/^\//, '')}`;
        normalizedInput = new URL(fullPath, baseUrl.origin);
        if (init?.query || init?.params) {
          const params = Object.fromEntries(normalizedInput.searchParams.entries());
          const stringifiedQuery = stringify({ ...params, ...init?.query, ...init?.params });
          normalizedInput.search = stringifiedQuery;
        }
      }

      // Any non-request errors (eg. invalid JSON in the response) will be thrown as-is.
      return await fetch(normalizedInput, normalizeRequest(init, headers, this.#config)) //
        .then(resp => {
          return normalizeResponse(resp, headers);
        });
    };
  }
}
