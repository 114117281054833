import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';
import { TitleReferenceModel, Transfer2_21_1Model } from '../../models';

type FormModel = Transfer2_21_1Model;

interface TransferContext {
  partyBook: FormModel['partyBook'];
  mergeFailedReason?: MergeFailedReasonsEnum;
}

const contextResolver = ({ partyBook, mergeFailedReason }: FormModel): TransferContext => ({ partyBook, mergeFailedReason });

// path: titleReferences

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel, TransferContext>() //
  .test(
    //
    'Multiple title selected: check for tenancy structure is same',
    'Party merge failed.',
    function test(this: yup.TestContext<TransferContext>) {
      const { mergeFailedReason } = this.options.context!;
      if (!mergeFailedReason) return true;

      if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame)
        return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles.' });

      if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
        return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

      if (mergeFailedReason === MergeFailedReasonsEnum.PartyCapacityMismatch)
        return this.createError({ message: 'The party capacity of the proprietors do not match; please remove the mismatched titles.' });

      if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
        return this.createError({
          message: 'The share fraction of the proprietors does not match; please remove the mismatched titles. To transact on mismatched titles, please create a new workspace.'
        });

      return false;
    }
  );

const memoizedSchema = memoizeSchemaWithContext<TitleReferenceModel[], FormModel, TransferContext>(yupTitleReferences, contextResolver);
export default memoizedSchema;
