import React from 'react';

import { ColumnsModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import { useLinkingParticipantCardStyles } from '../row-detail/styles';

export interface LinkedLodgementParticipantRow {
  linkingSubscriberName: string;
  linkingSubscriberRole: string;
}

export interface Props {
  rows: LinkedLodgementParticipantRow[];
  columns: ColumnsModel<LinkedLodgementParticipantRow>;
}

function LinkingLodgementParticipantCard({
  //
  rows,
  columns
}: Props) {
  const classes = useLinkingParticipantCardStyles();
  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      className={classes.root}
    />
  );
}

export default React.memo(LinkingLodgementParticipantCard);
