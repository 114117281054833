import format from 'date-fns/format';

import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { getFiltersWithSelectedTab } from '../filters/helper';
import { FilterEnum } from '../filters/models';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from '../models';
import { SortingFieldEnum } from '../table/models';
import { AggregatorNameEnum, AggregatorQuery } from './models';

export const resolveActionArgs = (props: {
  //
  id: string;
  queries: AggregatorQuery[];
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
}): [/*filters: */ FinancialWorkspacesV2ApiRequest, /*engagedTab: */ FinancialDashboardRouteTabsEnumV2, /*currentTabFilters: */ DashboardFilterModel] => {
  const { id, queries, assignmentType, localStorageKey } = props;

  const mappedTab = id === AggregatorNameEnum.previous ? FinancialDashboardRouteTabsEnumV2.toBeRebooked : FinancialDashboardRouteTabsEnumV2.active;

  const [tabFilters, _] = getFiltersWithSelectedTab(localStorageKey, mappedTab, assignmentType);
  const selectedCardQuery = queries.find(d => d.name === id);

  //!important we need a clean tabFilters to store in our localStorage!
  const newTabFilters: DashboardFilterModel = { assignmentType: tabFilters.assignmentType, selectedTab: tabFilters.selectedTab };

  newTabFilters.workspaceStatus = selectedCardQuery?.workspaceStatus;
  newTabFilters.myTasks = selectedCardQuery?.myTasks;

  const newSettlementPeriod: DashboardDateRangeModel | undefined = selectedCardQuery?.settlementPeriod
    ? {
        start: format(selectedCardQuery?.settlementPeriod.start as Date, 'dd/MM/yyyy'),
        end: format(selectedCardQuery?.settlementPeriod.end as Date, 'dd/MM/yyyy')
      }
    : undefined;

  newTabFilters.settlementPeriod = newSettlementPeriod;
  newTabFilters.selectedFilters = [];

  if (id === AggregatorNameEnum.totalOpenWorkspaces) {
    newTabFilters.selectedFilters.push(FilterEnum.SettlementDate, FilterEnum.WorkspaceStatus);
    //@ts-ignore
    newTabFilters.settlementPeriod = 'AllDates';
  } else if (id === AggregatorNameEnum.readyToSign) {
    newTabFilters.selectedFilters.push(FilterEnum.SettlementDate, FilterEnum.WorkspaceStatus, FilterEnum.MyTasks);
    //@ts-ignore
    newTabFilters.settlementPeriod = 'AllDates';
  } else if (id !== AggregatorNameEnum.previous) {
    newTabFilters.selectedFilters.push(FilterEnum.SettlementDate, FilterEnum.WorkspaceStatus);
  }

  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    newTabFilters.selectedFilters.push(FilterEnum.Groups);
  }

  return [
    {
      assignmentType,
      settlementPeriod: newSettlementPeriod,
      myTasks: selectedCardQuery?.myTasks,
      workspaceStatus: selectedCardQuery?.workspaceStatus,
      pageNumber: 1,
      pageSize: 20,
      withProposals: id === AggregatorNameEnum.previous ? false : undefined,
      sortBy: selectedCardQuery?.settlementPeriod ? SortingFieldEnum.SettlementDate.toString() : undefined,
      sortOrder: selectedCardQuery?.settlementPeriod ? DashboardSortDirectionEnum.asc : undefined
    },
    mappedTab,
    newTabFilters
  ];
};

export const updateLocalStorage = (
  //
  localStorageKey: string,
  updatedValues: DashboardFilterModel
) => {
  const value = localStorage.getItem(localStorageKey);

  const newItems = value ? (JSON.parse(value) as DashboardFilterModel[]) : [];

  const existingItemIndex = newItems.findIndex(d => d.selectedTab === updatedValues.selectedTab && d.assignmentType === updatedValues.assignmentType);
  if (existingItemIndex !== -1) {
    newItems[existingItemIndex] = { ...updatedValues };
  } else {
    newItems.push(updatedValues);
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newItems));
};

export const removeLocalStorageSavedModel = (
  //
  localStorageKey: string,
  selectedTab: FinancialDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
) => {
  const value = localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }
  const existingItem = JSON.parse(value) as DashboardFilterModel[];
  const filteredItem = existingItem.filter(d => d.selectedTab !== selectedTab || d.assignmentType !== assignmentType);

  localStorage.setItem(localStorageKey, JSON.stringify(filteredItem));
};

export const removeLocalStorageDateTime = (localStorageKey: string) => {
  const value = localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }

  const existingItem = JSON.parse(value) as DashboardFilterModel[];

  existingItem.forEach((item, index, items) => {
    if (
      (item.selectedTab === FinancialDashboardRouteTabsEnumV2.recent || item.selectedTab === FinancialDashboardRouteTabsEnumV2.active) &&
      (item.settlementPeriod || item.lastAccessPeriod)
    ) {
      items[index] = { ...item, settlementPeriod: undefined, lastAccessPeriod: undefined };
    }
  });
  localStorage.setItem(localStorageKey, JSON.stringify(existingItem));
};
