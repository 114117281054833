import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import Box from 'src/@core/components/layout/box';
import { useSafeDispatch } from 'src/hooks';
import OffPlatformTasks from '../off-platform-tasks';
import { OffPlatformTaskStatusEnum } from '../off-platform-tasks/enums';
import { OffPlatformTaskModel } from '../off-platform-tasks/models';
import { actionFetchOffPlatformTasks, actionUpdateOffPlatformTaskStatus } from './actions';
import { errorMessage, title } from './constants';
import { useOffPlatformTasks } from './reducer';
import { useStyles } from './styles';

export interface OwnProps {
  workspaceId: string;
  participantId: string;
  disabled?: boolean;
}

export default function OffPlatformTasksContainer({ workspaceId, participantId, disabled }: OwnProps) {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { items, status, isLoading, isRefetching } = useOffPlatformTasks(workspaceId);

  const onUpdateStatus = (opTask: OffPlatformTaskModel, newStatus: OffPlatformTaskStatusEnum): void => {
    dispatch(actionUpdateOffPlatformTaskStatus.request({ workspaceId, participantId, offPlatformTaskId: opTask.id, newStatus }));
  };

  useEffect(() => {
    dispatch(actionFetchOffPlatformTasks.request({ workspaceId }));
  }, [dispatch, workspaceId]);

  if (isLoading || isRefetching) {
    return (
      <div className={classes.loader}>
        <BlockLoader />
      </div>
    );
  }

  if (status === 'rejected') {
    return (
      <FormHelperText role="alert" error={true}>
        {errorMessage}
      </FormHelperText>
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <Box title={title} className={classes.container} disabled={disabled}>
      <OffPlatformTasks items={items} disabled={disabled} onUpdateStatus={onUpdateStatus} />
    </Box>
  );
}
