import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { Mortgage2_21_3Model, TitleReferenceModel } from '../../models';

type FormModel = Mortgage2_21_3Model;

interface TitleReferencesContext {
  partyBook: FormModel['partyBook'];
}
// ToDo: Check message for Mortgage document. The one below was copied from Transfer.
const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match; please remove the mismatched titles';

// path: titleReferences

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel, TitleReferencesContext>() //
  .test(
    //
    'Multiple title selected: check for matching relinquishing proprietor details',
    MISMATCHED_TENANCY_STRUCTURE_MESSAGE,
    function test(this: yup.TestContext<TitleReferencesContext>) {
      const { partyBook } = this.options.context!;
      const result = PartyValidations.verifyTenancyStructure(partyBook);
      return result === undefined;
    }
  );

const yupSchema = memoizeSchemaWithContext<TitleReferenceModel[], FormModel, TitleReferencesContext>(
  yupTitleReferences,
  function contextResolver(parent: FormModel): TitleReferencesContext {
    return {
      partyBook: parent.partyBook
    };
  }
);

export default yupSchema;
