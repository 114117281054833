import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import StatusBadge from '@sympli/ui-framework/components/status-badge';
import { IconCheck, IconClock } from '@sympli/ui-framework/icons';

import { getWorkspaceStatusEnumMapping } from 'src/utils/status-mapping/workspaceStatusHelper';
import DefaultStatusBadge from './DefaultStatusBadge';

interface Props {
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  size?: 'small' | 'medium';
}

// https://projects.invisionapp.com/d/main#/console/17568417/379129641/preview
function LodgementOnlyWorkspaceStatusBadge({
  //
  workspaceStatusId,
  lodgementCaseStatusId,
  size = 'small'
}: Props) {
  const label = getWorkspaceStatusEnumMapping(size)[workspaceStatusId];

  switch (workspaceStatusId) {
    case HttpTypes.WorkspaceStatusEnum.ReadyForLodge:
      return (
        <StatusBadge //
          color="primary"
          size={size}
          label={label}
          aria-label={`Workspace status ${label}`}
        />
      );
    case HttpTypes.WorkspaceStatusEnum.LodgementInQueue:
      return (
        <StatusBadge //
          variant="outlined"
          color="secondary"
          size={size}
          label={label}
          aria-label={`Workspace status ${label}`}
          icon={<IconClock />}
        />
      );
    case HttpTypes.WorkspaceStatusEnum.Archived:
      return (
        <StatusBadge //
          label={label}
          color="secondary"
          size={size}
          icon={<IconCheck />}
          iconColor="primary"
          aria-label={`Workspace status ${label}`}
        />
      );
    case HttpTypes.WorkspaceStatusEnum.Abandoned:
      return (
        <StatusBadge //
          label={label}
          color="warning"
          size={size}
          aria-label={`Workspace status ${label}`}
        />
      );
    default:
      return (
        <DefaultStatusBadge //
          lodgementCaseStatusId={lodgementCaseStatusId}
          size={size}
          aria-label={`Workspace status ${label}`}
        />
      );
  }
}

export default React.memo(LodgementOnlyWorkspaceStatusBadge);
