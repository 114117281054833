import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { getWorkspaceRoleTypeOptions } from 'src/models/roles';

interface WorkspaceRoleTypeOptionsSelectorParams {
  subscriberProfile: HttpTypes.UserProfileModel;
  jurisdiction: HttpTypes.JurisdictionsEnum | null;
  areCriticalRolesEnabled: boolean;
  areCriticalRolesNSWEnabled: boolean;
}

const getSubscriberProfileWorkspaceRoles = (data: WorkspaceRoleTypeOptionsSelectorParams) => data.subscriberProfile.subscriberWorkspaceRoles;

export const workspaceRoleTypeOptionsSelector = createSelector<
  WorkspaceRoleTypeOptionsSelectorParams,
  HttpTypes.SubscriberWorkspaceRoleModel[],
  HttpTypes.JurisdictionsEnum | null,
  boolean,
  boolean,
  LookupEnumModel<HttpTypes.ClientWorkspaceRoleEnum>[]
>(
  getSubscriberProfileWorkspaceRoles,
  data => data.jurisdiction,
  data => data.areCriticalRolesEnabled,
  data => data.areCriticalRolesNSWEnabled,
  (roles, jurisdiction, areCriticalRolesEnabled, areCriticalRolesNSWEnabled) => {
    const enabledRoles: HttpTypes.ClientWorkspaceRoleEnum[] = roles.filter(role => role.isEnabled).map(role => role.workspaceRole);
    const enabledRolesSet = new Set<HttpTypes.ClientWorkspaceRoleEnum>(enabledRoles);
    const roleTypeOptions: LookupEnumModel<HttpTypes.ClientWorkspaceRoleEnum>[] = getWorkspaceRoleTypeOptions(jurisdiction, areCriticalRolesEnabled, areCriticalRolesNSWEnabled);
    return roleTypeOptions.filter(role => enabledRolesSet.has(role.id));
  }
);
