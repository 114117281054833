import * as React from 'react';

import dateformat from 'dateformat';

import { DateFormatEnum } from 'src/@core/models';
import Header, { HeaderProps } from 'src/containers/dashboard/components/header/Header';
import { shouldUpdateTime } from '../helper';
import InvitationsDashboardContext from '../InvitationsDashboard.context';
import { useInvitationsFeedV2 } from '../reducers/invitationsFeedV2';

function HeaderContainer(props: React.PropsWithChildren<HeaderProps>) {
  const { updatedTime } = useInvitationsFeedV2();

  const { currentUpdatedTime, resetCurrentUpdatedTime } = React.useContext(InvitationsDashboardContext);

  React.useEffect(() => {
    // isAfter(new Date(1989, 6, 10), new Date(1987, 1, 11)) // true
    if (shouldUpdateTime(updatedTime, currentUpdatedTime)) {
      resetCurrentUpdatedTime(updatedTime);
    }
  }, [updatedTime]);

  return <Header {...props} time={dateformat(currentUpdatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER)} />;
}

export default React.memo(HeaderContainer);
