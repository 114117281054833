import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import DocumentViewer from 'src/containers/documents/components/document-viewer';
import DocumentWorkflowPanel, { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { DocumentFormAndDetailModel, DocumentWorkflowStepsEnum, SaveDocumentFormRequest } from 'src/containers/documents/models';
import ActionLabel from 'src/containers/workspace/shared/components/action-label';
import SignButton from 'src/containers/workspace/shared/components/sign-button';
import { resolveSignDocumentActionInfoSelector } from 'src/containers/workspace/shared/detail/selectors';
import { resolveSignDocumentsLink } from 'src/containers/workspace/sign-documents/helpers';
import { DocumentMergedPdfState } from '../../reducers/documentMergedPdf';

interface Props {
  queryParams: SaveDocumentFormRequest;
  disableNextButton: boolean;
  displayDocumentAsPdf: boolean;
  documentDetail: DocumentFormAndDetailModel;
  documentMergedPdfState: DocumentMergedPdfState;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
}

function SignDocument({
  //
  queryParams,
  disableNextButton,
  displayDocumentAsPdf,
  documentDetail,
  documentMergedPdfState,
  jurisdictionId,
  workflowPanelProps
}: Props) {
  const { workspaceId, participantId } = queryParams;
  const signActionInfos = resolveSignDocumentActionInfoSelector({ detail: documentDetail, participantId });
  const mode = displayDocumentAsPdf ? 'dark' : 'light';

  const documentWorkflowPanel = (
    <DocumentWorkflowPanel //
      {...workflowPanelProps}
    >
      <ActionLabel //
        documentActionInfo={signActionInfos}
        mode={mode}
        currentParticipantId={participantId}
      />

      <SignButton mode={mode} disabled={disableNextButton} href={resolveSignDocumentsLink({ workspaceId, participantId })}>
        Sign
      </SignButton>
    </DocumentWorkflowPanel>
  );

  return (
    <>
      {documentWorkflowPanel}
      <DocumentViewer
        displayDocumentAsPdf={displayDocumentAsPdf}
        signMultiple={false}
        queryParams={queryParams}
        jurisdictionId={jurisdictionId}
        documentDetail={documentDetail}
        documentMergedPdfState={documentMergedPdfState}
      />
    </>
  );
}

export default React.memo(SignDocument);
