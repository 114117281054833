import { batch } from 'react-redux';
import { Action } from 'redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentDetailApiRequest } from 'src/containers/documents/models';
import {
  actionCleanupWorkspaceDocumentErrors,
  actionUpdateDocumentDataInWorkspaceDocuments,
  actionUpdateWorkspaceDocumentsStatus,
  UpdatedDocumentStatus,
  UpdateWorkspaceDocumentsStatusRequestModel
} from 'src/store/actions/workspace';

import type { SafeDispatch } from 'src/hooks';

export function docUpdateHelper(dispatch: SafeDispatch<Action>, queryParams: DocumentDetailApiRequest, response: HttpTypes.UpdateWorkspaceDocumentApiResponse) {
  const {
    documentPermissions,
    documentDenyPermissions,
    status,
    hash,
    pdfS3Key,
    documentActions,
    documentWorkflow,
    isFullySignedByAllParticipants,
    updatedData,
    postProcessImpactedDocuments
  } = response;
  const { workspaceId, participantId, documentId } = queryParams;
  // make sure we update statuses in the workspace detail
  // update all docs to same status (For Lodgement only), only support single document in WS}

  const documentStatuses: UpdatedDocumentStatus[] = postProcessImpactedDocuments.map(doc => ({
    statusId: doc.status,
    documentId: doc.documentId,
    documentActions: doc.documentActions,
    documentPermissions: doc.documentPermissions,
    documentDenyPermissions: doc.documentDenyPermissions,
    isFullySignedByAllParticipants: doc.isFullySignedByAllParticipants
  }));

  const statusUpdates: UpdateWorkspaceDocumentsStatusRequestModel = {
    workspaceId,
    participantId,
    documents: [
      //
      { statusId: status, documentId, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants },
      ...documentStatuses
    ]
  };
  batch(() => {
    // update document data that will cause transition to new state
    dispatch(
      actionUpdateDocumentDataInWorkspaceDocuments({
        documentId,
        data: updatedData,
        documentPermissions,
        documentDenyPermissions,
        status,
        hash,
        pdfS3Key,
        documentActions,
        documentWorkflow,
        isFullySignedByAllParticipants
      })
    );

    for (const doc of postProcessImpactedDocuments) {
      const { documentId, documentPermissions, documentDenyPermissions, status, hash, pdfS3Key, documentActions, documentWorkflow, isFullySignedByAllParticipants, updatedData } =
        doc;
      dispatch(
        actionUpdateDocumentDataInWorkspaceDocuments({
          documentId,
          data: updatedData,
          documentPermissions,
          documentDenyPermissions,
          status,
          hash,
          pdfS3Key,
          documentActions,
          documentWorkflow,
          isFullySignedByAllParticipants
        })
      );
    }

    // cleanup document lodgement errors first
    dispatch(actionCleanupWorkspaceDocumentErrors(workspaceId));
    // update status of the document in the workspace detail
    dispatch(actionUpdateWorkspaceDocumentsStatus(statusUpdates));
  });
}
