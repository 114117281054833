import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import { StatusChip } from '@sympli/components-library/status-chip';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { FormatterInputModel } from '@sympli/ui-framework/components/table';
import { NewAddIcon, NewIndicator } from '@sympli/ui-framework/icons';

import { IconStar } from 'src/components/icons';
import { resolveWorkspaceLogTypeMapping } from 'src/containers/dashboard/financial-workspaces/helper';
import { LastSystemActivityModel } from 'src/containers/dashboard/financial-workspaces/table/models';
import { resolveLodgementCaseStepAndVariant } from 'src/containers/shared/lodgement-case-status/conversion';
import { StatusProgressModel } from 'src/containers/shared/lodgement-case-status/models';
import { JoinWorkspaceEnum, ParticipantInfo } from 'src/containers/shared/verify-property-section/components/search-join-table/models';
import { resolveFinancialSettlementStepAndVariant } from 'src/containers/workspace/financial/directions/components/financials-box-title/helper';
import { FinancialSettlementStatusEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { getVerticalStatusWithToolTip } from 'src/containers/workspace/shared/components/lodgement-only-workspace-status-progress/helper';
import { colors, ScreenSizeVariant } from 'src/theme';
import { dateTimeLine } from 'src/utils/formatters';
import { resolveFinancialWorkspaceStatus, VerticalStatusLineVariant } from 'src/utils/status-mapping/workspaceStatusHelper';
import { getTitleAddress, getVariant } from './helpers';
import JoinWorkspaceTooltipContent from './JoinWorkspaceTooltipContent';
import TasksStatus from './TasksStatus';
import ThreeLineContent from './ThreeLineContent';
import VerticalStatusLine from './VerticalStatusLine';
import WithPlusN from './WithPlusN';
import FullVerticalStatus from './WorkspaceStatus';

export function settlementTimeFormatter<
  //
  R extends object,
  V extends {
    settlementDate?: {
      userLocalTime: string;
      workspaceLocalTime: string;
      userTimezoneAbbreviation: string;
      workspaceTimezoneAbbreviation: string;
      hoursTill: number;
    };
    workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
    pendingProposedSettlementDate?: HttpTypes.WorkspaceDateTimeModel;
    expectedSettlementDate: { workspaceDateTime: HttpTypes.WorkspaceDateTimeModel; settlementDateKind: HttpTypes.SettlementDateKindEnum } | undefined;
    primaryClassName?: string;
    isUnsupportedSettlementDate?: boolean;
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  //TODO clean up after backend has done the changes needed
  const { settlementDate, expectedSettlementDate, pendingProposedSettlementDate, isUnsupportedSettlementDate, workspaceStatusId, primaryClassName } = value;
  if (!settlementDate && !pendingProposedSettlementDate) {
    return <div className="flex items-center">-</div>;
  }

  if (expectedSettlementDate?.settlementDateKind === HttpTypes.SettlementDateKindEnum.DateOnly) {
    const workspaceDateTime = expectedSettlementDate.workspaceDateTime;
    const dateOnly = dateTimeLine(workspaceDateTime.workspaceLocalTime, 'd mmm');
    const displayDateOnlyStrikeThrough = (workspaceStatusId === -1 && workspaceDateTime.businessDaysTill < 0) || isUnsupportedSettlementDate === true;
    return (
      <ThreeLineContent
        //
        first={dateOnly}
        second={'-'}
        primaryClassName={classNames('font-[500] text-[14px] leading-[20px]', displayDateOnlyStrikeThrough && 'strikethrough', primaryClassName)}
        secondaryClassName={classNames('font-[400] text-[12px] leading-[20px]', displayDateOnlyStrikeThrough && 'strikethrough')}
      />
    );
  }

  const { userLocalTime, workspaceLocalTime, userTimezoneAbbreviation, workspaceTimezoneAbbreviation, hoursTill } = pendingProposedSettlementDate ?? settlementDate!;
  const [date, localTime] = dateTimeLine(userLocalTime, 'd mmm|h:MMTT').split('|');
  const displayStrikeThrough: boolean = (workspaceStatusId === -1 && hoursTill < 0) || isUnsupportedSettlementDate === true;
  //Only workspaces that have WS Status as ‘In Preparation (Errors)’ and settlement date and time are passed

  return userTimezoneAbbreviation !== workspaceTimezoneAbbreviation ? (
    <ThreeLineContent
      //
      first={date}
      second={`${dateTimeLine(workspaceLocalTime, 'h:MMTT')} ${workspaceTimezoneAbbreviation}`}
      third={`${localTime} ${userTimezoneAbbreviation}`}
      primaryClassName={classNames('font-[500] text-[14px] leading-[14px]', displayStrikeThrough && 'strikethrough', primaryClassName)}
      secondaryClassName={classNames('font-[400] text-[12px] leading-[12px]', displayStrikeThrough && 'strikethrough')}
      thirdClassName={classNames('font-[400] text-[12px] leading-[12px]', displayStrikeThrough && 'strikethrough')}
    />
  ) : (
    <ThreeLineContent
      //
      first={date}
      second={`${localTime} ${userTimezoneAbbreviation}`}
      primaryClassName={classNames('font-[500] text-[14px] leading-[20px]', displayStrikeThrough && 'strikethrough', primaryClassName)}
      secondaryClassName={classNames('font-[400] text-[12px] leading-[20px]', displayStrikeThrough && 'strikethrough')}
    />
  );

  // 1 Nov
  // 11:00AM WAST
  // 1:00PM AEST
}

// This is the new one, will use it replace the old settlementTimeFormatter
export function settlementDateTimeFormatter<
  //
  R extends object,
  V extends {
    workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
    pendingProposedSettlementDate?: HttpTypes.WorkspaceDateTimeModel;
    expectedSettlementDate?: HttpTypes.ExpectedSettlementDate;
    primaryClassName?: string;
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  if (!value.expectedSettlementDate && !value.pendingProposedSettlementDate) {
    return <div className="flex items-center">-</div>;
  }

  const { userLocalTime, workspaceLocalTime, userTimezoneAbbreviation, workspaceTimezoneAbbreviation, hoursTill } =
    value.expectedSettlementDate?.workspaceDateTime ?? value.pendingProposedSettlementDate!;
  const [date, localTime] = dateTimeLine(userLocalTime, 'd mmm|h:MMTT').split('|');

  const displayTime = value.expectedSettlementDate?.settlementDateKind !== HttpTypes.SettlementDateKindEnum.DateOnly;
  const displayWorkspaceTime = userTimezoneAbbreviation !== workspaceTimezoneAbbreviation && displayTime;
  //Only workspaces that have WS Status as ‘In Preparation (Errors)’ and settlement date and time are passed
  const displayStrikeThrough: boolean = value.workspaceStatusId === -1 && hoursTill < 0;

  return displayWorkspaceTime ? (
    <ThreeLineContent
      //
      first={date}
      second={`${dateTimeLine(workspaceLocalTime, 'h:MMTT')} ${workspaceTimezoneAbbreviation}`}
      third={`${localTime} ${userTimezoneAbbreviation}`}
      primaryClassName={classNames('font-[500] text-[14px] leading-[14px]', displayStrikeThrough && 'strikethrough', value.primaryClassName)}
      secondaryClassName={classNames('font-[400] text-[12px] leading-[12px]', displayStrikeThrough && 'strikethrough')}
      thirdClassName={classNames('font-[400] text-[12px] leading-[12px]', displayStrikeThrough && 'strikethrough')}
    />
  ) : (
    <ThreeLineContent
      //
      first={date}
      second={displayTime ? `${localTime} ${userTimezoneAbbreviation}` : '-'}
      primaryClassName={classNames('font-[500] text-[14px] leading-[20px]', displayStrikeThrough && 'strikethrough', value.primaryClassName)}
      secondaryClassName={classNames('font-[400] text-[12px] leading-[20px]', displayStrikeThrough && 'strikethrough')}
    />
  );

  // 1 Nov
  // 11:00AM WAST
  // 1:00PM AEST
}

export function dashboardTimeFormatter<
  //
  R extends object,
  V extends {
    userLocalTime: string;
    workspaceLocalTime: string;
    userTimezoneAbbreviation: string;
    workspaceTimezoneAbbreviation: string;
  },
  C = any
>({ value }: FormatterInputModel<R, V | undefined, C>) {
  if (!value) {
    return <div className="flex items-center">-</div>;
  }
  const [date, localTime] = dateTimeLine(value.userLocalTime, 'd mmm|h:MMTT').split('|');
  let wsTime;
  if (value.userTimezoneAbbreviation !== value.workspaceTimezoneAbbreviation) {
    wsTime = dateTimeLine(value.workspaceLocalTime, 'h:MMTT');
  }

  return wsTime ? (
    <ThreeLineContent
      //
      first={date}
      second={`${wsTime} ${value.workspaceTimezoneAbbreviation}`}
      third={`${localTime} ${value.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[14px]`}
      secondaryClassName={`font-[400] text-[12px] leading-[12px]`}
      thirdClassName={`font-[400] text-[12px] leading-[12px]`}
    />
  ) : (
    <ThreeLineContent
      //
      first={date}
      second={`${localTime} ${value.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[20px]`}
      secondaryClassName={`font-[400] text-[12px] leading-[20px]`}
    />
  );

  // 1 Nov
  // 11:00AM WAST
  // 1:00PM AEST
}

export function snapshotSettlementDateFormatter<
  //
  R extends object,
  V extends HttpTypes.ExpectedSettlementDate,
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  const { settlementDateKind, workspaceDateTime } = value;
  if (!workspaceDateTime) {
    return <div className="flex items-center">-</div>;
  }

  if (settlementDateKind === HttpTypes.SettlementDateKindEnum.DateOnly) {
    const dateOnly = dateTimeLine(workspaceDateTime.workspaceLocalTime, 'd mmm');
    const displayDateOnlyStrikeThrough = workspaceDateTime.businessDaysTill < 0;
    return (
      <ThreeLineContent
        //
        first={dateOnly}
        second={'-'}
        primaryClassName={`font-[500] text-[14px] leading-[20px] ${displayDateOnlyStrikeThrough && 'strikethrough'}`}
        secondaryClassName={`font-[400] text-[12px] leading-[20px] ${displayDateOnlyStrikeThrough && 'strikethrough'}`}
      />
    );
  }

  return threeLineSettlementDatetimeDisplay(workspaceDateTime);
  // 1 Nov
  // 11:00AM WAST
  // 1:00PM AEST
}

// new SDT formatter for global search in linked workspace
export function newSettlementDatetimeDisplay<
  //
  R extends object,
  V extends {
    snapshotSettlementDate?: {
      userLocalTime: string;
      workspaceLocalTime: string;
      userTimezoneAbbreviation: string;
      workspaceTimezoneAbbreviation: string;
      businessDaysTill: number;
      hoursTill: number;
    };
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  const { snapshotSettlementDate } = value;

  return threeLineSettlementDatetimeDisplay(snapshotSettlementDate);
}

export function threeLineSettlementDatetimeDisplay(snapshotDateTime?: //
{
  userLocalTime: string;
  workspaceLocalTime: string;
  userTimezoneAbbreviation: string;
  workspaceTimezoneAbbreviation: string;
  businessDaysTill: number;
  hoursTill: number;
}) {
  if (!snapshotDateTime) {
    return <div className="flex items-center">-</div>;
  }

  const [date, userLocalTime] = dateTimeLine(snapshotDateTime.userLocalTime, 'd mmm|h:MMTT').split('|');
  const workspaceLocalTime = dateTimeLine(snapshotDateTime.workspaceLocalTime, 'h:MMTT');

  // if the settlement date and time has passed
  const displayStrikeThrough = snapshotDateTime.hoursTill < 0;

  return snapshotDateTime.userTimezoneAbbreviation !== snapshotDateTime.workspaceTimezoneAbbreviation ? (
    <ThreeLineContent
      //
      first={date}
      second={`${workspaceLocalTime} ${snapshotDateTime.workspaceTimezoneAbbreviation}`}
      third={`${userLocalTime} ${snapshotDateTime.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[14px] ${displayStrikeThrough && 'strikethrough'}`}
      secondaryClassName={`font-[400] text-[12px] leading-[12px] ${displayStrikeThrough && 'strikethrough'}`}
      thirdClassName={`font-[400] text-[12px] leading-[12px] ${displayStrikeThrough && 'strikethrough'}`}
    />
  ) : (
    <ThreeLineContent
      //
      first={date}
      second={`${userLocalTime} ${snapshotDateTime.userTimezoneAbbreviation}`}
      primaryClassName={`font-[500] text-[14px] leading-[20px] ${displayStrikeThrough && 'strikethrough'}`}
      secondaryClassName={`font-[400] text-[12px] leading-[20px] ${displayStrikeThrough && 'strikethrough'}`}
    />
  );
}

export function referenceSympliIdFormatter<
  //
  R extends {
    reference: string;
    sympliId: string;
  },
  V = any,
  C = any
>({ value, row, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  return (
    <>
      <div className="bold truncated" {...tooltipHandlers} data-binding="reference">
        {row.reference}
      </div>
      <div className="secondary truncated" data-binding="sympliId">
        {row.sympliId}
      </div>
    </>
  );

  // 34/2 Dawes/Riordan
  // 10004374
}

export function resolveCustomerName(
  party:
    | {
        clientType: 0; // Individual
        firstName?: string;
        middleName?: string;
        lastName?: string;
      }
    | {
        clientType: 1; //  Organisation
        organisationName: string;
      }
) {
  if (party.clientType === 1) {
    return party.organisationName;
  }

  const { firstName = '', middleName = '', lastName = '' } = party;

  if (firstName) {
    if (middleName) {
      if (lastName) {
        return `${firstName[0]}. ${middleName[0]}. ${lastName}`;
      }
      return `${firstName[0]}. ${middleName}`;
    } else if (lastName) {
      return `${firstName[0]}. ${lastName}`;
    }
    return firstName;
  }

  return lastName;
}

export function customerFormatter<
  //
  R extends object,
  V extends Array<
    | {
        clientType: 0; // Individual
        firstName?: string;
        middleName?: string;
        lastName?: string;
      }
    | {
        clientType: 1; //  Organisation
        organisationName: string;
      }
  >,
  C = any
>({ value: customers, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const [first, second, ...rest] = customers;
  const primaryCustName = first ? resolveCustomerName(first) : '';
  const primary: string = primaryCustName ? primaryCustName : '-';
  const secondary: string | undefined = second ? resolveCustomerName(second) : undefined;

  return (
    <WithPlusN //
      primary={primary}
      secondary={secondary}
      plusN={Math.max(rest.length)}
      primaryClassName="primary truncated"
      primaryDataBinding="clients[0]"
      secondaryClassName="primary truncated"
      secondaryDataBinding="clients[1]"
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function multiRowPrimaryFormatter<
  //
  R extends object,
  V extends {
    primary: string | number;
  },
  C = any
>({
  value,
  tooltipHandlers,
  primaryDataBinding,
  primaryClassOverride
}: FormatterInputModel<R, V, C> & {
  primaryDataBinding?: string;
  primaryClassOverride?: string;
}) {
  return (
    <div className={primaryClassOverride ?? 'primary bold'} {...tooltipHandlers} data-binding={primaryDataBinding}>
      {value.primary}
    </div>
  );
}

export function doubleRowPrimaryFormatter<
  //
  R extends object,
  V extends string[],
  C = any
>({
  value,
  tooltipHandlers,
  primaryDataBinding,
  secondaryDataBinding
}: FormatterInputModel<R, V, C> & {
  primaryDataBinding: string;
  secondaryDataBinding: string;
}) {
  const [primary, secondary, ...rest] = value;

  return (
    <WithPlusN //
      primary={primary}
      primaryClassName="primary truncated"
      primaryDataBinding={primaryDataBinding}
      secondary={secondary}
      secondaryClassName="primary truncated"
      secondaryDataBinding={secondaryDataBinding}
      plusN={rest.length}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function doubleRowPrimarySecondaryFormatter<
  //
  R extends object,
  V extends {
    primary?: string | number;
    secondary?: string | number;
  },
  C = any
>({
  value,
  tooltipHandlers,
  primaryDataBinding,
  secondaryDataBinding,
  primaryClassOverride
}: FormatterInputModel<R, V, C> & {
  primaryDataBinding?: string;
  secondaryDataBinding?: string;
  primaryClassOverride?: string;
}) {
  if (value.primary === undefined) {
    return null;
  }
  return (
    <>
      <div className={primaryClassOverride ?? 'primary bold truncated'} {...tooltipHandlers} data-binding={primaryDataBinding}>
        {value.primary}
      </div>
      {value.secondary !== undefined ? (
        <div className="secondary truncated" {...tooltipHandlers} data-binding={secondaryDataBinding}>
          {value.secondary}
        </div>
      ) : null}
    </>
  );
}

export function addressFormatter<
  //
  R extends object,
  V extends {
    addresses: string[];
    jurisdictionId: HttpTypes.JurisdictionsEnum;
  },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { addresses, jurisdictionId } = value;

  const primary: string | undefined = getTitleAddress(addresses.filter(Boolean)[0]);
  const secondary = HttpTypes.JurisdictionsEnum[jurisdictionId];

  return (
    <WithPlusN //
      primary={primary ? primary : 'No address held'}
      primaryClassName="primary truncated"
      primaryDataBinding="address"
      secondary={secondary}
      secondaryClassName="secondary"
      secondaryDataBinding="jurisdictionId"
      plusN={Math.max(addresses.length - 1, 0)}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function participantsFormatter<
  //
  R extends object,
  V extends {
    participants: ParticipantInfo[];
  },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { participants } = value;

  if (!participants.length) return '-';

  const primary: string = participants[0].subscriberName;
  const secondary: string = `${!participants[0].hasAccepted ? 'Invited as ' : ''}${participants[0].role}`;

  return (
    <WithPlusN //
      primary={primary ? primary : '-'}
      primaryClassName="primary truncated"
      primaryDataBinding="participant"
      secondary={secondary}
      secondaryClassName="secondary truncated uppercase"
      secondaryDataBinding="role"
      plusN={Math.max(participants.length - 1, 0)}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function proprietorsFormatter<
  //
  R extends object,
  V extends {
    proprietors: string[];
  },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { proprietors } = value;

  if (!proprietors.length) return '-';

  const primary: string = resolveCustomerName({ clientType: 1, organisationName: proprietors[0] });
  const secondary: string | undefined = proprietors.length > 1 ? resolveCustomerName({ clientType: 1, organisationName: proprietors[1] }) : undefined;

  return (
    <WithPlusN //
      primary={primary ? primary : '-'}
      primaryClassName="primary truncated"
      primaryDataBinding="first-proprietor"
      secondary={secondary}
      secondaryClassName="truncated"
      secondaryDataBinding="second-proprietor"
      plusN={Math.max(proprietors.length - 2, 0)}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function inviteTypeFormatter<
  //
  R extends object,
  V extends HttpTypes.ParticipantArchivedStatusEnum,
  C = any
>({ value: inviteType }: FormatterInputModel<R, V, C>) {
  let text = '';
  switch (inviteType) {
    case HttpTypes.ParticipantArchivedStatusEnum.Sent:
      text = 'SENT';
      break;
    case HttpTypes.ParticipantArchivedStatusEnum.Received:
    case HttpTypes.ParticipantArchivedStatusEnum.Forwarded:
      text = 'RECEIVED';
      break;
    case HttpTypes.ParticipantArchivedStatusEnum.SentAndReceived:
      text = 'SENT / RECEIVED';
      break;
    default:
      break;
  }
  return (
    <div //
      className="text-[10px] font-[500] leading-[12px] text-[var(--neutral-800)]"
    >
      {text}
    </div>
  );
}

export function titleFormatter<
  //
  R extends object,
  V extends {
    titles: string[];
    sympliId: string;
  },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { titles, sympliId } = value;

  const primary: string = titles.length ? titles[0] : '-';
  const secondary = sympliId;

  return (
    <WithPlusN //
      primary={primary}
      primaryClassName="primary bold"
      primaryDataBinding="title"
      secondary={secondary}
      secondaryClassName="secondary"
      secondaryDataBinding="sympliId"
      plusN={Math.max(titles.length - 1, 0)}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function tasksStatusFormatter<
  //
  R extends object,
  V extends {
    taskCount: number;
    status: 'ok' | 'outstanding' | 'overdue';
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  return (
    <TasksStatus //
      {...value}
    />
  );
}

export function jurisdictionFormatter<
  //
  R extends object,
  V extends HttpTypes.JurisdictionsEnum,
  C = any
>({ value: jurisdictionId }: FormatterInputModel<R, V, C>) {
  const text = HttpTypes.JurisdictionsEnum[jurisdictionId];
  return (
    <div className="primary" data-binding="jurisdictionId">
      {text}
    </div>
  );
}

export function workspaceTypeFormatter<
  //
  R extends object,
  V extends { workspaceType: HttpTypes.WorkspaceTypeEnum },
  C = any
>({ value, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  return (
    <div className="primary truncated" data-binding="workspaceType" {...tooltipHandlers}>
      {value.workspaceType === HttpTypes.WorkspaceTypeEnum.RegistrationOnly ? 'Standalone' : 'Financial'}
    </div>
  );
}

export function actionFormatter<
  //
  R extends object,
  V extends {
    //
    disabled: boolean;
    clickHandler?: () => void;
    showIndicator: boolean;
    joinWorkspaceCondition: JoinWorkspaceEnum;
  },
  C extends {
    workspaceType: HttpTypes.WorkspaceTypeEnum;
    role?: HttpTypes.WorkspaceRoleEnum;
  }
>({ value, context }: FormatterInputModel<R, V, C>) {
  const { clickHandler, showIndicator, disabled, joinWorkspaceCondition } = value;
  const { role } = context!;
  return (
    <div className="flex items-center justify-between">
      <ButtonBase onClick={clickHandler} disabled={disabled}>
        <NewAddIcon fill={disabled ? colors.NEUTRAL_500 : colors.SYMPLI_GREEN} />
        <Typography variant="body2_bold" className={classNames('ml-[8px] underline', disabled && 'text-[var(--neutral-500)]')}>
          Join
        </Typography>
      </ButtonBase>
      {showIndicator && (
        <Tooltip placement="top-end" title={<JoinWorkspaceTooltipContent joinWorkspaceCondition={joinWorkspaceCondition} role={role} />}>
          <Box className="flex items-center">
            <NewIndicator fill={disabled ? colors.NEUTRAL_500 : colors.SYMPLI_GREEN} />
          </Box>
        </Tooltip>
      )}
    </div>
  );
}

export function groupNameFormatter<
  //
  R extends object,
  V extends string,
  C = any
>({ value: groupName, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  return (
    <div className="primary bold truncated" data-binding="groupName" {...tooltipHandlers}>
      {groupName || ''}
    </div>
  );
}

export function memberFormatter<
  //
  R extends object,
  V extends string,
  C = any
>({ value: member, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  return (
    <div className="primary truncated" data-binding="member" {...tooltipHandlers}>
      {member || 'Unassigned'}
    </div>
  );
}

export function sentByFormatter<
  //
  R extends object,
  V extends string,
  C = any
>({ value: sentBy, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  return (
    <div className="primary truncated" data-binding="sentBy" {...tooltipHandlers}>
      {sentBy || '-'}
    </div>
  );
}

export function workspaceStatusBarFormatter<
  //
  R extends object,
  V extends HttpTypes.WorkspaceStatusEnum, // WorkspaceStatusEnum
  C = any
>({ value: workspaceStatusId }: FormatterInputModel<R, V, C>) {
  const info: { variant: VerticalStatusLineVariant; title: string } | null = resolveFinancialWorkspaceStatus(workspaceStatusId);
  if (!info) {
    return null;
  }

  return (
    <FullVerticalStatus //
      aria-label={info.title}
      variant={info.variant}
    />
  );
}

export function workspaceStatusBarWithLinkWorkspaceFormatter<
  //
  R extends object,
  V extends {
    workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
    linkedWorkspaceType?: HttpTypes.LinkedWorkspaceTypeEnum;
    linkedWorkspaceOverallStatus?: HttpTypes.LinkedWorkspaceOverallStatusEnum;
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  const { workspaceStatusId, linkedWorkspaceOverallStatus } = value;

  const info: { variant: VerticalStatusLineVariant; title: string } | null = resolveFinancialWorkspaceStatus(workspaceStatusId);
  if (!info) {
    return null;
  }

  if (linkedWorkspaceOverallStatus === undefined) {
    return (
      <FlexLayout className="h-full ml-[2px]" flexDirection="row-reverse">
        <FullVerticalStatus //
          aria-label={info.title}
          variant={info.variant}
          className="w-[50%]"
        />
      </FlexLayout>
    );
  }

  /*
      notReady warning
      ready teal
      if main workspace archived then archived --only if it still has the cluster id
    */
  let overallVariant: VerticalStatusLineVariant = linkedWorkspaceOverallStatus === HttpTypes.LinkedWorkspaceOverallStatusEnum.NotReady ? 'warning' : 'ok';

  if (info.variant === 'neutral') {
    overallVariant = 'neutral';
  }

  return (
    <FlexLayout className="h-full">
      <FullVerticalStatus //
        aria-label={info.title}
        variant={info.variant}
        className="w-[45%] mr-[2px]"
      />

      <FullVerticalStatus //
        aria-label={info.title}
        variant={overallVariant}
        className="w-[45%]"
      />
    </FlexLayout>
  );
}

export function standaloneWorkspaceStatusBarFormatter<
  //
  R extends object,
  V extends {
    workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
    lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  },
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  const { lodgementCaseStatusId, workspaceStatusId } = value;
  const { title, variant } = getVerticalStatusWithToolTip(workspaceStatusId, lodgementCaseStatusId);

  return (
    <FullVerticalStatus //
      aria-label={title}
      variant={variant}
    />
  );
}

export function invitationStatusBarFormatter<
  //
  R extends object,
  V extends HttpTypes.InvitationStatusEnum,
  C = any
>({ value }: FormatterInputModel<R, V, C>) {
  let variant: 'ok' | 'warning' | 'error' | 'neutral' = 'warning';
  let label = '';

  switch (value) {
    case HttpTypes.InvitationStatusEnum.Accepted:
      variant = 'ok';
      label = 'Accepted';
      break;
    case HttpTypes.InvitationStatusEnum.Rejected:
      variant = 'error';
      label = 'Declined';
      break;
    case HttpTypes.InvitationStatusEnum.Forwarded:
      variant = 'neutral';
      label = 'forwarded';
      break;
    case HttpTypes.InvitationStatusEnum.Withdrawn:
      variant = 'neutral';
      label = 'Withdrawn';
      break;
    case HttpTypes.InvitationStatusEnum.Pending:
    default:
      variant = 'warning';
      label = 'Pending';
      break;
  }

  return (
    <FullVerticalStatus //
      aria-label={label}
      variant={variant}
    />
  );
}

export function invitationStatusFormatter<
  //
  R extends object,
  V extends HttpTypes.InvitationStatusEnum, //number
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value, context, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  let variant: 'ok' | 'warning' | 'error' | 'neutral' = 'warning';
  let label = '';

  switch (value) {
    case HttpTypes.InvitationStatusEnum.Accepted:
      variant = 'ok';
      label = 'Accepted';
      break;
    case HttpTypes.InvitationStatusEnum.Rejected:
      variant = 'error';
      label = 'Declined';
      break;
    case HttpTypes.InvitationStatusEnum.Forwarded:
      variant = 'neutral';
      label = 'forwarded';
      break;
    case HttpTypes.InvitationStatusEnum.Withdrawn:
      variant = 'neutral';
      label = 'Withdrawn';
      break;
    case HttpTypes.InvitationStatusEnum.Pending:
    default:
      variant = 'warning';
      label = 'Pending';
      break;
  }
  return (
    <VerticalStatusLine //
      text={label}
      variant={variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function participantRoleFormatter<
  //
  R extends object,
  V extends string[],
  C = any
>({ value: roles, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const [first, ...rest] = roles;
  return (
    <WithPlusN //
      primary={first}
      plusN={Math.max(rest.length)}
      primaryClassName="primary"
      primaryDataBinding="roles[0]"
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function documentTypeFormatter<
  //
  R extends object,
  V extends string[],
  C = any
>({ value: documents, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  if (!documents || documents.length === 0) {
    return (
      <div className="text-[14px] font-[700] leading-[20px]" data-binding="documents">
        <p>No document</p>
      </div>
    );
  }

  const [first, ...rest] = documents;
  return (
    <WithPlusN //
      primary={first}
      plusN={Math.max(rest.length)}
      primaryClassName="font-[700] text-[14px] leading-[20px]"
      primaryDataBinding="documents[0]"
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function financialWorkspaceStatusFormatter<
  //
  R extends object,
  V extends HttpTypes.WorkspaceStatusEnum,
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value: workspaceStatusId, tooltipHandlers, context }: FormatterInputModel<R, V, C>) {
  const info: { variant: VerticalStatusLineVariant; title: string } | null = resolveFinancialWorkspaceStatus(workspaceStatusId);
  if (!info) {
    return null;
  }

  return (
    <VerticalStatusLine //
      text={info.title}
      variant={info.variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function standaloneWorkspaceStatusFormatter<
  //
  R extends object,
  V extends {
    workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
    lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  },
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value, context, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const { lodgementCaseStatusId, workspaceStatusId } = value;

  const { variant, title } = getVerticalStatusWithToolTip(workspaceStatusId, lodgementCaseStatusId);

  return (
    <VerticalStatusLine //
      text={title}
      variant={variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

const TODAY = new Date();
const TODAY_DATE_FORMATTED = dateTimeLine(TODAY, 'd mmm');

function resolveSettlementSystemActivity(logType: HttpTypes.LogTypeEnum, desc: string): React.ReactNode | undefined {
  // let description: React.ReactNode | string = desc;
  // let background: string = '';
  // let color: string = '';

  if (logType === HttpTypes.LogTypeEnum.SettlementStarts || logType === HttpTypes.LogTypeEnum.LodgementInitated) {
    return <StatusChip variant="light-green">{desc}</StatusChip>;
  } else if (
    logType === HttpTypes.LogTypeEnum.SettlementRollover ||
    logType === HttpTypes.LogTypeEnum.SettlementFailed ||
    logType === HttpTypes.LogTypeEnum.SettlementPaused ||
    logType === HttpTypes.LogTypeEnum.LodgementFailed ||
    logType === HttpTypes.LogTypeEnum.LodgementFailedTechnicalError ||
    logType === HttpTypes.LogTypeEnum.LodgementFailedConnectivityError
  ) {
    return <StatusChip variant="red">{desc}</StatusChip>;
  } else if (logType === HttpTypes.LogTypeEnum.SettlementSuccessful || logType === HttpTypes.LogTypeEnum.LodgementCompleted || logType === HttpTypes.LogTypeEnum.LodgementSuccess) {
    return (
      <StatusChip variant="light-green">
        <>
          <span className="pr-2">{desc}</span>
          <IconStar />
        </>
      </StatusChip>
    );
  } else {
    return undefined;
  }
}

export function systemActivityFormatter<
  //
  R extends object,
  V extends LastSystemActivityModel | undefined,
  C = any
>({ value, ...rest }: FormatterInputModel<R, V | undefined, C>) {
  if (!value) {
    return doubleRowPrimarySecondaryFormatter({
      value: {
        primary: '-',
        secondary: undefined
      },
      primaryClassOverride: 'primary truncated',
      ...rest
    });
  }

  const { logType } = value;
  // settlement log has special format
  const { desc } = resolveWorkspaceLogTypeMapping(logType);

  const settlementActivity = resolveSettlementSystemActivity(logType, desc);
  if (settlementActivity) {
    return settlementActivity;
  }

  const primary: string = desc;

  let [datePart, secondary] = dateTimeLine(value.userLocalTime, 'd mmm|h:MMTT').split('|');
  if (datePart !== TODAY_DATE_FORMATTED) {
    secondary = `${datePart} - ${secondary} ${value.userTimezoneAbbreviation}`;
  }

  return doubleRowPrimarySecondaryFormatter({
    value: {
      primary,
      secondary
    },
    ...rest,
    primaryDataBinding: 'primary',
    secondaryDataBinding: 'secondary'
  });
}

export function lodgementCaseStatusFormatter<
  //
  R extends object,
  V extends {
    lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
    allDocumentsFullySigned: boolean;
  },
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value: { lodgementCaseStatusId, allDocumentsFullySigned }, context, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const info: Pick<StatusProgressModel, 'step' | 'variant'> | null = resolveLodgementCaseStepAndVariant({ lodgementCaseStatusId, allDocumentsSigned: allDocumentsFullySigned });
  if (!info) {
    return null;
  }
  const text = info.step.description;
  const variant = getVariant(info);

  return (
    <VerticalStatusLine //
      text={text}
      variant={variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function financialSettlementStatusFormatter<
  //
  R extends object,
  V extends FinancialSettlementStatusEnum, //string
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value: financialSettlementStatusId, context, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const info: Pick<StatusProgressModel, 'step' | 'variant'> | null = resolveFinancialSettlementStepAndVariant({ financialSettlementStatusId, displayInDashboardGrid: true });
  if (!info) {
    return null;
  }
  const text = info.step.description;
  const variant: 'ok' | 'warning' | 'error' | 'neutral' = info.variant === 'success' ? 'ok' : info.variant === 'failure' ? 'error' : 'warning';

  return (
    <VerticalStatusLine //
      text={text}
      variant={variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function distributionFundsStatusFormatter<
  //
  R extends object,
  V extends boolean,
  C extends {
    screenVariant: ScreenSizeVariant;
  }
>({ value: IsFinancialBalanced, context, tooltipHandlers }: FormatterInputModel<R, V, C>) {
  const text = IsFinancialBalanced ? 'Balanced' : 'Unbalanced';
  const variant = IsFinancialBalanced ? 'ok' : 'error';

  return (
    <VerticalStatusLine //
      text={text}
      variant={variant}
      textClassName={context?.screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
      tooltipHandlers={tooltipHandlers}
    />
  );
}

export function unreadMessageFormatter<
  //
  R extends object,
  V extends number,
  C = any
>({ value: unreadMessagesCount }: FormatterInputModel<R, V, C>) {
  if (!unreadMessagesCount) {
    return null;
  }

  return (
    <svg
      aria-label={pluralize('Unread Message', unreadMessagesCount, true)}
      style={{ lineHeight: 'normal', paddingTop: 8 }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="4" fill={colors.NEUTRAL_600} />
    </svg>
  );
}
