// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2084897049/Tasks+tab+-+data+dependency+insights
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helpers';
import { useSafeDispatch } from 'src/hooks';
import { actionFetchWorkspaceTasks } from 'src/store/actions/workspace';
import { useLrsInfo } from 'src/store/reducers/lrsInfo';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceDocuments } from 'src/store/reducers/workspace/workspaceDocuments';
import { useWorkspaceTasks } from 'src/store/reducers/workspace/workspaceTasks';
import TabTasks from './TabTasks';

export default function TabTasksContainer({
  workspaceId,
  participantId,
  ...rest
}: {
  //
  workspaceId: string;
  participantId: string;
  backLink: string;
  onSelectReportsTab?: () => void;
}) {
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const lrsInfo = useLrsInfo(workspaceBasicInfoState.detail?.jurisdictionId);
  const dispatch = useSafeDispatch(useDispatch());
  const lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);

  // whenever this component mounts we want to refetch list of tasks to guarantee that tasks list gets refreshed
  useEffect(() => {
    dispatch(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
  }, [dispatch, workspaceId, participantId]);

  return (
    <TabTasks
      //
      workspaceId={workspaceId}
      participantId={participantId}
      workspaceTypeId={workspaceBasicInfoState.detail?.workspaceTypeId}
      {...rest}
      dispatch={dispatch}
      workspaceDetailState={{
        isLoadingWorkspaceBasicInfo: workspaceBasicInfoState.isLoading,
        jurisdictionId: workspaceBasicInfoState.detail?.jurisdictionId,
        workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
        lodgementCaseStatusId,
        isLodgementReady: workspaceDetailState.detail?.lodgementDetails[0]?.isLodgementReady,
        isValidOperatingHoursForRegOnly: lrsInfo.isValidOperatingHoursForRegOnly
      }}
      workspaceTasksState={workspaceTasksState}
      workspaceDocumentsState={{
        isLoading: workspaceDocumentsState.isLoading,
        status: workspaceDocumentsState.status,
        count: workspaceDocumentsState.items.length
      }}
    />
  );
}
