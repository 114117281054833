import * as React from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { ListLoader } from 'src/components/loaders';
import { InvitesDashboardApiRequest } from 'src/containers/dashboard/invitation/models';
import OnlineStatusIcon, { OnlineStatusEnum } from './components/online-status-icon';
import ParticipantItem from './components/participant-item';
import styles, { ClassKeys } from './styles';

export type SettlementParticipantMapType = { [participantId: string]: boolean };

interface OwnProps {
  items: HttpTypes.WorkspaceParticipant[];
  isLoading?: boolean;

  workspaceId?: string;
  subscriberId?: string;
  participantId?: string;

  settlementAcceptedByParticipants?: Array<string>; // leave empty if you don't want to show info about settlement acceptance date
  loaderBackgroundColor?: 'white' | 'grey';

  isRenderWithdrawAndResendButtons?: boolean;
  callbackActions?: Action[];
  canResend?: boolean;
  query?: InvitesDashboardApiRequest;
  dataTestId?: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  participantId?: string;
  participantName?: string;
  participantRole?: string;
  dialogOpen: boolean;
  isWithdrawLoading: boolean;
}

class ParticipantList extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    loaderBackgroundColor: 'grey'
  };

  public readonly state: Readonly<State> = {
    dialogOpen: false,
    isWithdrawLoading: false
  };

  render() {
    const { isLoading, items, classes, loaderBackgroundColor, dataTestId } = this.props;

    if (isLoading) {
      return (
        <ListLoader //
          lineCount={2}
          color={loaderBackgroundColor}
          disableAvatar
        />
      );
    }

    if (!items.length) {
      return null;
    }

    return (
      <React.Fragment>
        <List data-testid={dataTestId} className={classNames(classes.root)}>
          {this.renderList()}
        </List>
        {this.renderSettlementHelp()}
      </React.Fragment>
    );
  }

  private renderList() {
    const {
      //
      items,
      classes,
      workspaceId,
      participantId,
      settlementAcceptedByParticipants,
      isRenderWithdrawAndResendButtons,
      query,
      canResend
    } = this.props;

    const acceptedSet = new Set(settlementAcceptedByParticipants);

    return items
      .filter(p => !p.archivedStatus || ![HttpTypes.ParticipantArchivedTypeEnum.Withdraw, HttpTypes.ParticipantArchivedTypeEnum.Abandon].includes(p.archivedStatus.type))
      .map((item, i) => (
        <ListItem disableGutters className={classNames(classes.listItem)} key={`key-${item.id}-${i}`} data-subscriber-id={item.subscriberId}>
          <ParticipantItem
            item={item}
            workspaceId={workspaceId!}
            hasSwitchLink={item.canSwitch}
            hasAcceptedSettlement={this.resolveSettlementAcceptance(item.id, acceptedSet)}
            participantId={participantId}
            isRenderWithdrawAndResendButtons={isRenderWithdrawAndResendButtons}
            canResend={canResend}
            query={query}
          />
        </ListItem>
      ));
  }

  private renderSettlementHelp() {
    const { items, settlementAcceptedByParticipants, classes } = this.props;
    if (settlementAcceptedByParticipants == null) {
      return null;
    }
    // Accepted number is not equal to participant number
    if (settlementAcceptedByParticipants.length !== items.length) {
      if (items.filter(p => p.participantStatus.name === 'Pending').length === 0) {
        return (
          <FlexLayout alignItems="center" className={classes.settlementHelp}>
            <OnlineStatusIcon value={OnlineStatusEnum.Idle} />
            <div className={classes.awaitingMsg}>Awaiting acceptance of date and time</div>
          </FlexLayout>
        );
      }
    }
    return null;
  }

  private resolveSettlementAcceptance(participantId: string, acceptedSet: Set<string>) {
    const { settlementAcceptedByParticipants } = this.props;
    if (settlementAcceptedByParticipants == null) {
      return true;
    }
    return acceptedSet.has(participantId);
  }
}

export default withStyles(styles)(ParticipantList);
