import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useProfile } from 'src/@core/store/reducers/profile';
import { actionFetchParticipantConversationSummary } from 'src/containers/shared/messenger/actions';
import { useMessengerConversations } from 'src/containers/shared/messenger/reducer';
import { useSafeDispatch } from 'src/hooks';
import MessagesCardDetail from './MessagesCardDetail';

interface Props {
  participantId: string;
  workspaceId: string;
}

function MessagesCardDetailContainer({ workspaceId, participantId }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const messengerConversationsState = useMessengerConversations(workspaceId, participantId);
  const { timezone } = useProfile().data!;

  useEffect(() => {
    dispatch(actionFetchParticipantConversationSummary.request({ workspaceId, participantId }));
  }, [dispatch, participantId, workspaceId]);

  const sortedUnreadConversations = React.useMemo(() => {
    // Sorting conversations based on the last message created time, in descending order
    return [...messengerConversationsState.items]
      .filter(item => !item.isRead)
      .sort((a, b) => {
        const aTimeStamp = new Date(a.messages[a.messages.length - 1].createdDate).getTime();
        const bTimeStamp = new Date(b.messages[b.messages.length - 1].createdDate).getTime();
        return bTimeStamp - aTimeStamp;
      });
  }, [messengerConversationsState.items]);

  return <MessagesCardDetail items={sortedUnreadConversations} timezone={timezone} />;
}

export default React.memo(MessagesCardDetailContainer);
