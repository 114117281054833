import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { prepareReceivingTenancyDetailForApi } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { IConverter } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import PartyJustification from 'src/containers/documents/party-merge/PartyJustification';
import { getLegalEntityNameNSW } from '../../helpers';
import { NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { fallbackMap } from './fallback';
import { ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model, TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from './models';
import { convertFromApiToFormModel } from './utils/convertFromApiToFormModel';

const { convertPartyBookFromFormToApiModel } = resolvePartyBookConversion<NswDocumentPartyJustification, NswNameChange>({
  partyFormConfig: NSW_TAB_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
  nameChangeConversion: nswNameChangeConversion
});

class Converter implements IConverter<TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model, ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model> {
  fromApiToFormModel = convertFromApiToFormModel;

  fromFormToApiModel(
    formValues: TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model,
    _originalApiModel: ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model
  ): ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model {
    const formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const {
      // Pluck out any sections that
      // 1) need to be replaced, or
      // 2) are used by the form but should not be sent back to the server
      partyBook,
      partyBookApi,
      mergeFailedReason: mergeFailed,
      applicants: formApplicants,
      finalTenancyHoldings,
      context,
      ...rest
    } = formModel;

    PartyJustification.adjustJustification(partyBook, getLegalEntityNameNSW);
    const applicants = prepareReceivingTenancyDetailForApi(formApplicants);
    const result: ApiTransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model = {
      ...rest,
      invalidParties: false,
      partyBook: convertPartyBookFromFormToApiModel(partyBook, partyBookApi),
      applicants,
      precedingData: {
        ...formModel.precedingData,
        dataChanged: false
      }
    };

    return result;
  }
}

export default new Converter();
