import { HttpTypes } from '@sympli/api-gateway/types';

export enum VariantEnum {
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

// we will receive authority compliances on existing documents, so they will definitely contain documentId
// export type AuthorityDocumentModel = Omit<AuthorityComplianceReportApiResponse['documents'][number], 'documentId'> & { documentId: string };

type DocumentSummaryModel = HttpTypes.AuthorityComplianceReportApiResponse['documents'][number];
export interface AuthorityDocumentModel extends DocumentSummaryModel {
  documentId: string;
}
