import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import SectionTitleReference from '@sympli-mfe/document-forms-framework/components/sections/title-reference';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';

import SectionIssuingPartyDirection from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/issuing-party-direction';
import { NominateeCtToPaperInstrument_2_18_1Model } from './models';
import SectionNominationDetails from './sections/nomination-details';

const fieldName = modelKey<NominateeCtToPaperInstrument_2_18_1Model>();

function RootForm({ className, hideActionsPanel, onSaveChanges, onCancel }: RootFormProps): JSX.Element {
  const { isLoading, disabled, nextActionDisabled, nextActionLabel } = useDocumentContext();
  const { values } = useFormikContext<NominateeCtToPaperInstrument_2_18_1Model>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    focusOnTitleReferencesRef.current?.focusVisible();
  }, []);

  return (
    <Form className={className}>
      <SectionTitleReference //
        name={fieldName('titleReferences')}
        disabled={disabled}
        focusRef={focusOnTitleReferencesRef}
      />
      <SectionNominationDetails //
        name={fieldName('nominationDetails')}
        ctControllerName={fieldName('ctController')}
        ctControllerValue={values.ctController}
        autoFocus={values.titleReferences.length === 1}
      />
      <SectionIssuingPartyDirection name={fieldName('issuingPartyDirection')} />
      {!hideActionsPanel && (
        <DocumentActionPanel //
          isLoading={isLoading}
          disabled={nextActionDisabled}
          onBack={onCancel}
          nextLabel={nextActionLabel}
          onSaveChanges={onSaveChanges}
        />
      )}
    </Form>
  );
}

export default React.memo(RootForm);
