import * as yup from 'yup';

import msg from '@sympli/ui-framework/utils/messages';

import { maxAmountCheck, numericAmountCheck, positiveAmountCheck, yupReference } from 'src/containers/workspace/financial/directions/views/edit-directions/sharedValidationSchema';
import { LinkedCategory, LinkLodgementDialogFormikModel, LinkSettlementDialogFormikModel } from './models';

export function getLinkedSettlementValidationSchema() {
  return yup.object<LinkSettlementDialogFormikModel>({
    category: yup.mixed<LinkedCategory>().required(msg.REQUIRED),
    reference: yupReference,
    amount: yup
      .string()
      .required(msg.REQUIRED)
      .test('valid amount', msg.INVALID_VALUE, numericAmountCheck)
      .test('max amount', msg.VALUE_MUST_BE_LESS_THAN_X('$99 million'), maxAmountCheck)
      .when('linkedSettlementFundsNotRequired', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().test('only positive amount', msg.VALUE_MUST_BE_MORE_THAN_X('$0'), positiveAmountCheck)
      }),
    linkedSettlementFundsNotRequired: yup.mixed(),
    linkedSettlementItem: yup.mixed()
  });
}

export function getLinkLodgementValidationSchema() {
  return yup.object<LinkLodgementDialogFormikModel>({
    linkedLodgementItem: yup.mixed()
  });
}
