import * as React from 'react';

import { useFormikContext } from 'formik';

import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import SectionTenancyReceiving from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { disablePartyFieldEditConfig } from '../../../../components/tenancy/disableTenancyFieldConfig';
import { BaseTransmissionApplicationModel } from '../../models';

interface Props<TNameChange extends {}> {
  name: string;
  partyFormConfig: PartyFormConfig<TNameChange>;
  componentLabels: TenancyComponentLabelConfig;
  partyCapacityLookup: LookupItemModel<string>[];
}

function Applicants<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  name,
  partyFormConfig,
  componentLabels,
  partyCapacityLookup
}: Props<TNameChange>) {
  const {
    values: { deceasedTenancyDetail, partyBook }
  } = useFormikContext<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const relinquishingPartyIds = deceasedTenancyDetail.proprietorGroups.find(pg => pg.isSelected)?.parties.map(p => p.partyBookId) ?? [];
  const referencedParties = partyBook.filter(pb => pb.metadata?.partyBookId).map(p => p.metadata?.partyBookId!);

  return (
    <SectionTenancyReceiving
      name={name}
      partyFormConfig={partyFormConfig}
      partyIdsToFilter={relinquishingPartyIds.concat(referencedParties)}
      componentLabels={componentLabels}
      allowNewGroup={false}
      disablePartyEditConfig={disablePartyFieldEditConfig()}
      partyCapacityLookup={partyCapacityLookup}
      defaultCapacity={partyCapacityLookup.length === 1 ? partyCapacityLookup[0].id : undefined}
    />
  );
}

export default React.memo(Applicants);
