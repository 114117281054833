import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchDocumentGroupFeed } from '../actions/defaultFieldPopulation';

export interface DocumentGroupFeedState {
  items: Array<HttpTypes.JurisdictionsEnum>;
  status: ApiStatus;
  error?: string;
}

const initialState: DocumentGroupFeedState = {
  items: [],
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  DocumentGroupFeedState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentGroupFeed.request, (state): DocumentGroupFeedState => {
    return {
      ...initialState,
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchDocumentGroupFeed.success, (state, action): DocumentGroupFeedState => {
    return {
      ...state,
      status: 'resolved',
      items: action.payload.data
    };
  })
  .handleAction(actionFetchDocumentGroupFeed.failure, (state, action): DocumentGroupFeedState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
