import * as yup from 'yup';

import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { resolveMemoizedReceivingTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving/validation-schema';
import { TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';
import { yupDatePicker } from '@sympli/ui-framework/components/formik/date-picker-field';
import msg from '@sympli/ui-framework/utils/messages';

import { QLD_TRANSFER_PARTY_FORM_CONFIG, QLD_TRANSFER_RECEIVING_TENANCY_CONFIG, QLD_TRANSFEREE_PARTY_CAPACITY_TYPE } from '../../config';
import { Transfer2_24_1Model } from '../../models';

const MISMATCH_PARTY_TYPES_ERROR_MESSAGE = 'An individual and organisation transferee are present in one group; such combination is not supported for this document.';
const DOB_ERROR_MESSAGE = 'Must be between 18 and 150 years';
const DOB_INVALID_FORMAT = 'Invalid date. Must be in dd/mm/yyyy format';

const yupDobDate = yupDatePicker
  .typeError(DOB_INVALID_FORMAT)
  .required(msg.REQUIRED)
  .min(QLD_TRANSFER_RECEIVING_TENANCY_CONFIG.partyDobMinDate!, DOB_ERROR_MESSAGE)
  .max(QLD_TRANSFER_RECEIVING_TENANCY_CONFIG.partyDobMaxDate!, DOB_ERROR_MESSAGE);

const yupTransferees = resolveMemoizedReceivingTenancyValidation({
  //
  isAddressRequired: false,
  partyFormConfig: QLD_TRANSFER_PARTY_FORM_CONFIG,
  forceDobVisibilityOnReceivingIndividual: true,
  partyCapacityOptions: QLD_TRANSFEREE_PARTY_CAPACITY_TYPE,
  yupDob: yup
    .mixed<unknown>()
    .test(
      'Transferee date of birth validation',
      'Transferee date of birth must be between 18 and 150 years',
      function test(this: yup.TestContext<{ partyBook: PartyModel[] }> /*value: any */) {
        const party = this.options.context?.partyBook.find(p => p.id === this.parent.partyBookId);
        yupDobDate.validateSync(party!.dob);
        return true;
      }
    )
}).test(
  //
  'Mismatch party types check',
  MISMATCH_PARTY_TYPES_ERROR_MESSAGE,
  function test(this: yup.TestContext<Transfer2_24_1Model>) {
    const { transferees, partyBook } = this.options.context!;
    const partyTypesInTenancy = transferees.proprietorGroups.map(pg => {
      return pg.parties.reduce((acc: PartyTypeEnum[], current: TenancyPartyModel) => {
        if (!current.partyBookId) return [];
        const party = partyBook.find(pb => pb.id === current.partyBookId)!;
        if (!acc.includes(party.partyType)) {
          acc.push(party.partyType);
        }
        return acc;
      }, []);
    });

    // Check 0 as well in case no party has been selected yet
    return partyTypesInTenancy.every(p => p.length <= 1);
  }
);

export default yupTransferees;
