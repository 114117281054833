import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class Group {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves a NON paginated list of groups. It sends a request to the
   * `/settings/groups`
   * API route.
   *
   * @param config - Additional config to pass with the request.
   * @returns NON paginated list of groups.
   *
   * @example
   * To retrieve the list of groups:
   *
   * ```ts
   * sdk.settings.group.list()
   * .then((groups) => {
   *   console.log(users)
   * })
   * ```
   */
  async list(queryParams?: HttpTypes.SettingsUserListApiRequestFilters, config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.SubscriberGroupListApiResponse>( //
      `/settings/groups`,
      {
        query: queryParams,
        ...config
      }
    );
  }

  /**
   * This method retrieves a group by its ID. It sends a request to the
   * `/settings/groups/{groupId}`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param config - Additional config to pass with the request.
   * @returns The groups details.
   *
   * @example
   * To retrieve a group by its ID:
   *
   * ```ts
   * sdk.settings.group.retrieve("group_123")
   * .then((group) => {
   *   console.log(group)
   * })
   * ```
   */
  async retrieve(groupId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SubscriberGroupApiResponse>( //
      `/settings/groups/${groupId}`,
      {
        ...config
      }
    );
  }

  /**
   * This method removes a group. It sends a request to the
   * `/settings/groups/{groupId}`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * To remove the group:
   *
   * ```ts
   * sdk.settings.group.remove("group_123")
   * .then(() => {
   *   console.log('Group has been removed')
   * })
   * ```
   */
  async remove(groupId: string, config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/groups/${groupId}`,
      {
        method: 'DELETE',
        ...config
      }
    );
  }

  /**
   * This method retrieves a NON paginated list of users matching search criteria. It sends a request to the
   * `/settings/groups/{groupId}/users/search`
   * API route.
   *
   * @params groupId - The groups ID.
   * @param queryParams - Filters.
   * @param config - Additional config to pass with the request.
   * @returns The NON paginated list of users.
   *
   * @example
   * To retrieve the list of users matching search criteria:
   *
   * ```ts
   * sdk.settings.group.listBySearchTerm({
   *   searchTerm: 'John'
   * })
   * .then((users) => {
   *   console.log(users)
   * })
   * ```
   */
  async listUsersBySearchTerm(
    groupId: string,
    queryParams: {
      searchTerm: string;
    },
    config?: ClientRequestConfig
  ) {
    return this.#client.fetch<HttpTypes.SearchUserListApiResponse>( //
      `/settings/groups/${groupId}/users/search`,
      {
        query: queryParams,
        ...config
      }
    );
  }

  /**
   * This method removes a user from the group. It sends a request to the
   * `/settings/groups/{groupId}/users/{userId}`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param userId - The ID of a user to be removed.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * To remove user from the group:
   *
   * ```ts
   * sdk.settings.group.removeUserFromGroup("group_123", "user_123")
   * .then(() => {
   *   console.log('User has been removed from the group')
   * })
   * ```
   */
  async removeUser(groupId: string, userId: string, config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/groups/${groupId}/users/${userId}`,
      {
        method: 'DELETE',
        ...config
      }
    );
  }

  /**
   * This method retrieves document workflow settings for a group. It sends a request to the
   * `/settings/groups/{groupId}/groupDocumentWorkflow`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param config - Additional config to pass with the request.
   * @returns The workflow settings.
   *
   * @example
   * To retrieve a groups document workflow settings:
   *
   * ```ts
   * sdk.settings.group.retrieveDocumentWorkflowSettings("group_123")
   * .then(({workflowType, dutySegregationType, requiredNumberOfSigners }) => {
   *   console.log(workflowType)
   * })
   * ```
   */
  async retrieveDocumentWorkflowSettings(groupId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.GroupDocumentsWorkflowApiResponse>( //
      `/settings/groups/${groupId}/groupDocumentWorkflow`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves distribution workflow settings for a group. It sends a request to the
   * `/settings/groups/{groupId}/groupDistributionsWorkflow`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param config - Additional config to pass with the request.
   * @returns The groups details.
   *
   * @example
   * To retrieve a groups distribution workflow settings:
   *
   * ```ts
   * sdk.settings.group.retrieveDistributionWorkflowSettings("group_123")
   * .then(({workflowType, dutySegregationType, requiredNumberOfSigners }) => {
   *   console.log(workflowType)
   * })
   * ```
   */
  async retrieveDistributionWorkflowSettings(groupId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.GroupDistributionsWorkflowApiResponse>( //
      `/settings/groups/${groupId}/groupDistributionsWorkflow`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves lodgement settings for a group. It sends a request to the
   * `/settings/groups/{groupId}/groupLodgementPreferences`
   * API route.
   *
   * @param groupId - The groups ID.
   * @param config - Additional config to pass with the request.
   * @returns The groups details.
   *
   * @example
   * To retrieve a groups lodgement settings:
   *
   * ```ts
   * sdk.settings.group.retrieveLodgementSettings("group_123")
   * .then(({daysUntilLodgement}) => {
   *   console.log(daysUntilLodgement)
   * })
   * ```
   */
  async retrieveLodgementSettings(groupId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.GroupLodgementPreferencesApiResponse>( //
      `/settings/groups/${groupId}/groupLodgementPreferences`,
      {
        ...config
      }
    );
  }
}
