import { useLocation } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import TopPanel from 'src/@core/components/layout/top-panel/TopPanel';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useAccessibleReports, useFeatureFlag, useRouterParams } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/models/workspace';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';

function TopPanelContainer(props: { hideSearchBar?: boolean }) {
  const { pathname } = useLocation();
  const user: HttpTypes.UserProfileModel | undefined = useProfile().data;
  const showBiReportingDashboard: boolean = useFeatureFlag([FeatureToggleEnum.bIReporting]);
  const showFinancialDashboards: boolean = useFeatureFlag(FeatureToggleEnum.financialSettlementsEnabled);
  const { reports } = useAccessibleReports();

  const { workspaceId = 'unknown' } = useRouterParams<Partial<WorkspaceDetailRouteParams>>();
  const { detail } = useWorkspaceBasicInfo(workspaceId);
  const workspaceTypeId: HttpTypes.WorkspaceTypeEnum | undefined = detail?.workspaceTypeId;
  if (!user) {
    return null;
  }

  return (
    <TopPanel //
      {...props}
      workspaceTypeId={workspaceTypeId}
      userName={user!.name}
      firstName={user!.firstName}
      lastName={user!.lastName}
      userEmail={user!.email}
      userAvatarUrl={user!.avatarUrl}
      userSubscriberName={user!.subscriberName}
      userTimezone={user!.timezone}
      showFinancialWsLink={showFinancialDashboards}
      showReportsLink={user!.hasReportAccess && showBiReportingDashboard}
      pathname={pathname}
      reports={reports}
    />
  );
}

export default TopPanelContainer;
