import { call, put, takeLatest } from 'typed-redux-saga';

import { HttpTypes } from '@sympli/api-gateway/types';

import { sdk } from 'src/utils/http';
import { actionFetchPreferences } from '../actions/preferences';

const fetchPreferences = (): Promise<HttpTypes.SettingsSubscriberPreferencesApiResponse> => {
  // return http.get<HttpTypes.SettingsSubscriberPreferencesApiResponse>('/settings/subscriber/preferences');
  return sdk.settings.subscriber.retrievePreferences();
};

function* sagaFetchPreferences() {
  try {
    const data = yield* call(fetchPreferences);
    yield put(actionFetchPreferences.success({ data }));
  } catch (error) {
    yield put(actionFetchPreferences.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchPreferences.request, sagaFetchPreferences)
];
