import { NswDocumentPartyJustification, NswNameChange, nswNameChangeConversion } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { ProprietorGroupModel, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { applyDefaultMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DataSource } from '@sympli-mfe/document-forms-framework/shared-config/common';
import { ApiDocumentPartyModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { Container, ProprietorGroup, TenancyDetail } from 'src/containers/documents/party-merge/model';
import PartyMerger from 'src/containers/documents/party-merge/PartyMerger';
import { updateProprietorWithCapacity } from '../../components/deceased-tenancy-detail/helpers';
import { DeceasedProprietorModel, DeceasedTenancyDetailModel, initDeceasedProprietor } from '../../components/deceased-tenancy-detail/models';
import { convertPartiesToFormModel } from '../../helpers';
import { NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from './config';
import { fallbackMap } from './fallback';
import { ApiTransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model, TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model } from './models';

export function convertFromApiToFormModel(
  apiModel: ApiTransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model,
  _context: IConverterContext
): TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model {
  const risInvolvedPartyIds = apiModel.partyBook.filter(p => p.propertiesMappedFromRis?.length).map(p => p.id!);
  let formModel: TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model = {
    ...apiModel,
    partyBookApi: apiModel.partyBook,
    partyBook: convertPartiesToFormModel_2_21_1(apiModel.partyBook, risInvolvedPartyIds)
  };

  if (apiModel.invalidParties) {
    formModel = generateDeceasedTenancy(formModel);
  }

  const formModelWithDefaultMapApplied = applyDefaultMap(formModel, fallbackMap);
  return formModelWithDefaultMapApplied;
}

export function generateDeceasedTenancy(
  formModel: TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model
): TransmissionApplicationByExecutorAdministratorTrustee_2_21_2_Model {
  const customParties = formModel.partyBook.filter(pb => pb.metadata?.source === DataSource.Custom);
  const selectedTitles = formModel.titleReferences.filter(tr => tr.isSelected);

  const containers = selectedTitles.map(
    title =>
      new Container(
        title.reference!,
        new TenancyDetail(
          title.proprietor.proprietorGroups.map(pg => new ProprietorGroup(pg.parties, pg, pg.shareFraction, pg.proprietorGroupType)),
          title.proprietor.tenancyType
        )
      )
  );

  const mergeResult = PartyMerger.merge(containers);

  const groups: ProprietorGroupModel<ApiDocumentPartyModel<NswDocumentPartyJustification>>[] = mergeResult.containers.flatMap(container =>
    container.tenancyDetail.proprietorGroups.map(pg => ({
      parties: pg.mergedParties,
      isSelected: false,
      proprietorGroupType: pg.proprietorGroupType!,
      shareFraction: pg.shareFraction!
    }))
  );

  const tenancyType = mergeResult.containers[0]?.tenancyDetail.tenancyType! ?? TenancyTypeEnum.None;
  const deceasedTenancyDetail: DeceasedTenancyDetailModel = {
    tenancyType,
    proprietorGroups: groups.map(pg => ({
      ...pg,
      isSelected: groups.length === 1 && (pg.parties.length === 1 || tenancyType === TenancyTypeEnum.JointTenants),
      parties: pg.parties.map(p => {
        const defaultSelected = groups.length === 1 && pg.parties.length === 1;
        const proprietor: DeceasedProprietorModel = initDeceasedProprietor(defaultSelected, p.id!);
        return defaultSelected ? updateProprietorWithCapacity(proprietor, proprietor.isSelected, formModel.applicantCapacity) : proprietor;
      })
    }))
  };

  const unselectedProprietorParties = formModel.titleReferences
    .filter(tr => !tr.isSelected)
    .flatMap(tr => tr.proprietor.proprietorGroups.flatMap(pg => pg.parties))
    .map((party, index: number) => ({ ...party, id: (party.id = party.externalId ? party.externalId : `TITLE-${++index}`) }));

  const titleProprietors = groups.flatMap(pg => pg.parties).concat(unselectedProprietorParties);

  const partyBook = convertPartiesToFormModel_2_21_1(
    titleProprietors,
    titleProprietors.map(p => p.id!)
  ).concat(customParties);

  return {
    ...formModel,
    mergeFailedReason: mergeResult.failedReason,
    deceasedTenancyDetail: deceasedTenancyDetail,
    partyBookApi: titleProprietors,
    partyBook
  };
}

export function convertPartiesToFormModel_2_21_1(parties: ApiDocumentPartyModel<NswDocumentPartyJustification>[], relinquishingIds?: string[]): PartyModel<NswNameChange>[] {
  return convertPartiesToFormModel(
    {
      partyFormConfig: NSW_TAE_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
      nameChangeConversion: nswNameChangeConversion
    },
    parties,
    relinquishingIds
  );
}
