import dateFormat from 'dateformat';

import { NSW_PARTY_FORM_CONFIG as NSW_CAVEAT_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { caveatFallbackMap } from './checks';
import { NSW_CAVEAT_ADDRESS_FORM_CONFIG } from './config';
import { ADDRESS_BOOK_KEY, ApiCaveatModel, CaveatModel, Claim, PARTY_BOOK_KEY } from './models';

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: NSW_CAVEAT_ADDRESS_FORM_CONFIG
});

const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: NSW_CAVEAT_PARTY_FORM_CONFIG
});

export function convertFromApiToFormModel(apiModel: ApiCaveatModel): CaveatModel {
  const relinquishingIds = (apiModel.proprietors || []).map(proprietor => proprietor.partyBookId).filter(Boolean) as string[];
  let formModel: CaveatModel = {
    ...apiModel,
    [PARTY_BOOK_KEY]: convertPartyBookFromApiToFormModel(apiModel[PARTY_BOOK_KEY], relinquishingIds),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromApiToFormModel(apiModel[ADDRESS_BOOK_KEY])
  };
  formModel = applyDefaultMap(formModel, caveatFallbackMap);
  return formModel;
}

export function convertFromFormToApiModel(formModel: CaveatModel, originalApiModel: ApiCaveatModel): ApiCaveatModel {
  formModel = applyVisibilityFallbackMap(formModel, caveatFallbackMap);
  const {
    // Pluck out any sections that
    // 1) need to be replaced, or
    // 2) are used by the form but should not be sent back to the server
    [PARTY_BOOK_KEY]: formPartyBook,
    [ADDRESS_BOOK_KEY]: formAddressBook,
    claimDetails,
    ...rest
  } = formModel;

  const amendedClaimDetails: Claim | undefined =
    claimDetails && isDate(claimDetails.claimDate)
      ? {
          ...claimDetails,
          claimDate: dateFormat(claimDetails.claimDate as Date, DateFormatEnum.DATE)
        }
      : claimDetails;

  return {
    ...rest,
    claimDetails: amendedClaimDetails,
    [PARTY_BOOK_KEY]: convertPartyBookFromFormToApiModel(formPartyBook, originalApiModel[PARTY_BOOK_KEY]),
    [ADDRESS_BOOK_KEY]: convertAddressBookFromFormToApiModel(formAddressBook, originalApiModel[ADDRESS_BOOK_KEY])
  };
}

function isDate(possibleDate: unknown) {
  return possibleDate instanceof Date;
}
