import React from 'react';

import classNames from 'classnames';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteCloseReason, AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { IconClose } from 'src/@core/components/icons';
import { colors } from 'src/theme';
import { useStyles } from './styles';

export interface Props {
  selectedItems?: Array<{ workspaceId: string; participantId: string }>;
  value: LookupItemModel<string> | null;
  searchValue: string;
  options: LookupItemModel<string>[];
  selectedNumber: number;
  isLoading: boolean;
  open: boolean;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  onClose?: (event: React.SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: LookupItemModel<string> | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<LookupItemModel<string>> | undefined
  ) => void;

  onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => void;
  onMenuClick: (option: LookupItemModel<string>) => void;
  onAssignButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onUnassignButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onDismissClick: React.MouseEventHandler<SVGElement>;
  assignButtonLoading?: boolean;
  unassignButtonLoading?: boolean;
}

const ReassignBar = React.forwardRef(
  (
    {
      //
      open,
      onOpen,
      onClose,
      onBlur,
      value,
      searchValue,
      onMenuClick,
      options,
      onInputChange,
      onChange,
      selectedNumber,
      isLoading,
      onAssignButtonClick,
      onUnassignButtonClick,
      onDismissClick,
      assignButtonLoading,
      unassignButtonLoading
    }: Props,
    ref
  ) => {
    const classes = useStyles();

    return (
      <Box
        ref={ref}
        className={classNames(classes.root, 'px-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]')}
      >
        <Typography className={classes.title}>
          <b>{selectedNumber}</b> selected
        </Typography>
        <Box className={classNames(classes.searchInputContainer, assignButtonLoading && 'w-[770px]')}>
          <Autocomplete //
            id="combo-box-reassign-for-dashboard"
            options={isLoading ? [] : options}
            slotProps={{
              popper: {
                placement: 'top'
              }
            }}
            noOptionsText="No Members Found"
            onBlur={onBlur}
            classes={{ paper: classes.paper, listbox: classes.listBox, noOptions: classes.baseFontStyle, loading: classes.baseFontStyle }}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            value={value}
            onChange={onChange}
            inputValue={searchValue}
            onInputChange={onInputChange}
            renderInput={params => (
              <TextField //
                {...params}
                variant="outlined"
                classes={{ root: classes.textField }}
                autoFocus
                label="Team Member"
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents auto-selecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <React.Fragment>{isLoading ? <CircularProgress classes={{ svg: classes.circularProgressSvg }} size={20} /> : null}</React.Fragment>
                }}
              />
            )}
            loading={isLoading}
            renderOption={(props, option, state) => {
              return (
                <MenuItem
                  key={option.id}
                  onClick={() => onMenuClick(option)}
                  sx={{ width: '100%', height: '64px', '&:hover': { background: colors.SUNNY_DAY_DARK }, borderTop: `1px solid ${colors.SUNNY_DAY_LUMINOUS}` }}
                >
                  <Typography
                    sx={{
                      //
                      color: colors.WHITE,
                      width: '100%',
                      '&:hover': { background: colors.SUNNY_DAY_DARK },
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.15px'
                    }}
                  >
                    {option.name}
                  </Typography>
                </MenuItem>
              );
            }}
            getOptionLabel={option => {
              return option.name;
            }}
            filterOptions={opt => {
              if (searchValue.trim().length < 3) {
                return [];
              }
              return opt.filter(x => {
                return x.name.toLowerCase().includes(searchValue.trim().toLowerCase());
              });
            }}
          />
          <SympliButton
            hasRadius
            variant="contained"
            className={classes.button}
            disabled={Boolean(value?.name !== searchValue) || assignButtonLoading}
            onClick={onAssignButtonClick}
          >
            {assignButtonLoading ? 'Assigning...' : 'Assign'}
          </SympliButton>
          <div className={classNames('h-[24px] w-[1px] bg-[var(--sunny-day-dark)] ')} />
          <SympliButton hasRadius variant="outlined" className={classes.unassignButton} onClick={onUnassignButtonClick}>
            {unassignButtonLoading ? 'Unassigning...' : 'Set as unassigned'}
          </SympliButton>
        </Box>
        <IconClose className={classes.closeIcon} data-testid="clear-reassign-modal" fill={colors.WHITE} onClick={onDismissClick} />
      </Box>
    );
  }
);

export default React.memo(ReassignBar);
