import { asDecimal } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { FractionModel, ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { ApiPartyCapacityModel, Identity, MergeMetadata } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { PartyTypeEnum } from '@sympli-mfe/enums-shared/necds';

export declare type MergeParty = {
  id?: string;
  partyType?: PartyTypeEnum;
  legalEntityName?: string;
  externalId?: string;
  mergeMetadata?: MergeMetadata;
  partyCapacity?: ApiPartyCapacityModel;
};

export class Container<T extends MergeParty> {
  constructor(
    public readonly key: string,
    public readonly tenancyDetail: TenancyDetail<T>
  ) {}

  /**
   * Will deep copy of top-level party data and a shallow copy of the nested data.
   * @returns Return a new container
   */
  clone() {
    return new Container(this.key, this.tenancyDetail.clone());
  }

  /**
   * Reset merge meta data
   * @returns Return same object after mutation
   */
  resetMetadata(requiresJustification: boolean) {
    this.tenancyDetail.proprietorGroups.forEach((pg, pgIndex) =>
      pg.parties.forEach((p, pIndex) => {
        const identity = new Identity(this.key, pgIndex, pIndex);
        p.mergeMetadata = {
          requiresJustification: requiresJustification,
          matchingGroupHash: identity.groupHash,
          matchingItemHash: identity.itemHash,
          identity: identity
        };
      })
    );

    return this;
  }
}

export class TenancyDetail<T extends MergeParty> {
  constructor(
    public proprietorGroups: ProprietorGroup<T>[],
    public tenancyType?: TenancyTypeEnum
  ) {}

  clone() {
    return new TenancyDetail(
      this.proprietorGroups.map(pg => pg.clone()),
      this.tenancyType
    );
  }
}

export class ProprietorGroup<T extends MergeParty> {
  mergedParties: T[];

  constructor(
    public parties: T[],
    public tag?: any,
    public shareFraction?: FractionModel,
    public proprietorGroupType?: ProprietorGroupTypeEnum
  ) {
    this.mergedParties = [...parties];
  }

  /**
   * Will deep copy of top-level party data and a shallow copy of the nested data.
   * @returns Return a new proprietor group
   */
  clone() {
    return new ProprietorGroup(
      this.parties.map(p => ({ ...p })),
      this.tag,
      this.shareFraction,
      this.proprietorGroupType
    );
  }

  /**
   * Will check the party types are same between groups
   * @returns true when parties are same between groups
   */
  matchedPartyTypes(reference: ProprietorGroup<T>): boolean {
    return (
      this.parties
        .map(cp => cp.partyType)
        .sort()
        .join(',') ===
      reference.parties
        .map(rp => rp.partyType)
        .sort()
        .join(',')
    );
  }

  /**
   * Will check the party types are same between groups
   * @returns true when parties are same between groups
   */
  matchedPartyCapacities(reference: ProprietorGroup<T>): boolean {
    return (
      this.parties
        .map(cp => cp.partyCapacity?.capacity)
        .sort()
        .join(',') ===
      reference.parties
        .map(rp => rp.partyCapacity?.capacity)
        .sort()
        .join(',')
    );
  }

  /**
   * Will check the share fraction is same
   * @returns true when same
   */
  sameShareFraction(reference: ProprietorGroup<T>): boolean {
    if (!this.shareFraction && !reference.shareFraction) {
      return true;
    }

    if (!this.shareFraction || !reference.shareFraction) {
      return false;
    }

    return asDecimal(this.shareFraction) === asDecimal(reference.shareFraction);
  }
}

export enum MergeFailedReasonsEnum {
  None,
  StructureNotSame,
  ShareFractionMismatch,
  PartyTypeMismatch,
  PartyCapacityMismatch
}

export class PartyMergeResult<T extends MergeParty> {
  constructor(
    public readonly containers: Container<T>[],
    public readonly failedReason?: MergeFailedReasonsEnum
  ) {
    let id = 1;
    this.containers.forEach(c =>
      c.tenancyDetail.proprietorGroups.forEach(pg =>
        pg.mergedParties.forEach(party => {
          party.id = party.externalId ? party.externalId : `PM-${id++}`;
        })
      )
    );
  }
}

export class ComparisonsMap<T extends MergeParty> {
  constructor(
    public readonly comparisons: ReferenceGroup<T>[][],
    public readonly failedReason?: MergeFailedReasonsEnum
  ) {}

  static structureNotSame<T extends MergeParty>() {
    return new ComparisonsMap<T>([], MergeFailedReasonsEnum.StructureNotSame);
  }

  validate(): MergeFailedReasonsEnum | undefined {
    for (const references of this.comparisons) {
      for (const reference of references) {
        for (const comparisonGroup of reference.comparisonGroups) {
          if (!comparisonGroup.proprietorGroup.sameShareFraction(reference.proprietorGroup)) {
            return MergeFailedReasonsEnum.ShareFractionMismatch;
          } else if (!comparisonGroup.proprietorGroup.matchedPartyTypes(reference.proprietorGroup)) {
            return MergeFailedReasonsEnum.PartyTypeMismatch;
          } else if (!comparisonGroup.proprietorGroup.matchedPartyCapacities(reference.proprietorGroup)) {
            return MergeFailedReasonsEnum.PartyCapacityMismatch;
          }
        }
      }
    }
    return undefined;
  }
}

export class ReferenceGroup<T extends MergeParty> {
  comparisonGroups: ComparisonGroup<T>[];

  constructor(public readonly proprietorGroup: ProprietorGroup<T>) {
    this.comparisonGroups = [];
  }

  get maxMatched(): number {
    return Math.max(...this.comparisonGroups.map(x => x.matchingPartyMaps.filter(e => e.partyMatch.matched).length), 0);
  }

  get maxNameMatched(): number {
    return Math.max(...this.comparisonGroups.map(x => x.matchingPartyMaps.filter(e => e.partyMatch.nameMatched).length), 0);
  }
}

export class ComparisonGroup<T extends MergeParty> {
  constructor(
    public readonly proprietorGroup: ProprietorGroup<T>,
    public readonly matchingPartyMaps: PartyMap<T>[]
  ) {}
}

export class PartyMap<T extends MergeParty> {
  constructor(
    public readonly referenceParty: T,
    public readonly matchedParty: T,
    public readonly partyMatch: PartyMatch
  ) {}
}

export class PartyMatch {
  constructor(
    public readonly matched: boolean,
    public readonly nameMatched: boolean
  ) {}
}
