import queryString from 'query-string';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import endpoints from '@sympli/api-gateway/endpoints';
import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { getTemplate } from 'src/containers/workspace/shared/detail/components/tab-logs/helper';
import { WorkspaceActivityLogModel } from 'src/containers/workspace/shared/detail/components/tab-logs/models';
import { Store } from 'src/store/reducers';
import http from 'src/utils/http';
import {
  actionFetchAdditionalInvitableRoles,
  actionFetchLinkedLodgementDetail,
  actionFetchLinkedSettlementDetail,
  actionFetchWorkspaceActivityLogs,
  actionFetchWorkspaceAuthorityComplianceReport,
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceFinancialAccounts,
  actionFetchWorkspaceLatestTransaction,
  actionFetchWorkspaceParticipantActivityLogs,
  actionFetchWorkspaceParticipants,
  actionFetchWorkspaceParticipantSetting,
  actionFetchWorkspaceTasks,
  WorkspaceActivityLogsApiRequest,
  WorkspaceActivityLogsApiResponse,
  WorkspaceAuthorityComplianceReportRequest,
  WorkspaceLogQueryTypeEnum,
  WorkspaceParticipantSettingApiRequest,
  WorkspaceRelatedApiRequest
} from '../../../../store/actions/workspace';
import { WorkspaceInvitableRoleDetailApiResponse } from '../../../../store/reducers/workspace/workspaceInvitation';
import { WorkspaceTaskApiResponse } from './components/my-tasks/models';
import {
  LinkedLodgementDetailApiResponse,
  LinkedSettlementDetailApiResponse,
  ParticipantSettingApiResponse,
  WorkspaceDetailModel,
  WorkspaceLatestTransactionApiResponse
} from './models';

const fetchWorkspaceBasicInfo = (args: WorkspaceRelatedApiRequest) => {
  return endpoints.getWorkspaceBasicInfo(args);

  // const { workspaceId, participantId } = args;
  // const query = `?${queryString.stringify({ participantId })}`;

  // return http.get<WorkspaceBasicInfoApiResponse>(`/workspaces/${encodeURIComponent(workspaceId)}/basic${query}`);
};

function* sagaFetchWorkspaceBasicInfo(action: ReturnType<typeof actionFetchWorkspaceBasicInfo.request>) {
  try {
    const detail: HttpTypes.WorkspaceBasicInfoApiResponse = yield* call(fetchWorkspaceBasicInfo, action.payload);

    yield put(actionFetchWorkspaceBasicInfo.success({ detail }));
  } catch (error) {
    yield put(actionFetchWorkspaceBasicInfo.failure({ error }));
  }
}

const fetchWorkspaceById = (args: WorkspaceRelatedApiRequest) => {
  return endpoints.getWorkspaceDetail(args);
};

function* sagaFetchWorkspaceById(action: ReturnType<typeof actionFetchWorkspaceById.request>) {
  try {
    const { participantId } = action.payload;

    const data: HttpTypes.WorkspaceDetailApiResponse = yield* call(fetchWorkspaceById, action.payload);

    const userId: string = yield select(s => s.profile.data.userId);
    const detail: WorkspaceDetailModel = { ...data, currentParticipantId: participantId, tacKey: `tac/${data.id}/${participantId}/${userId}` };

    yield put(actionFetchWorkspaceById.success({ detail }));
  } catch (error) {
    yield put(actionFetchWorkspaceById.failure({ error }));
  }
}

const fetchWorkspaceParticipants = (args: WorkspaceRelatedApiRequest) => {
  // const { workspaceId, participantId } = args;
  // const query = `?${queryString.stringify({ participantId })}`;

  // return http.get<InvitedParticipantApiResponse[]>(`/workspaces/${encodeURIComponent(workspaceId)}/participants${query}`);
  return endpoints.getWorkspaceParticipants(args);
};

function* sagaFetchWorkspaceParticipants(action: ReturnType<typeof actionFetchWorkspaceParticipants.request>) {
  try {
    const data: HttpTypes.WorkspaceParticipantListApiResponse = yield* call(fetchWorkspaceParticipants, action.payload);
    yield put(actionFetchWorkspaceParticipants.success({ items: data }));
  } catch (error) {
    yield put(actionFetchWorkspaceParticipants.failure({ error }));
  }
}

export const fetchWorkspaceDocuments = (args: WorkspaceRelatedApiRequest) => {
  const { workspaceId, participantId = '' } = args;

  return endpoints.getDocuments({ workspaceId, participantId });
  // return http.get<DocumentApiResponse[]>(`/workspaces/${encodeURIComponent(workspaceId)}/documents/participants/${encodeURIComponent(participantId)}`);
};

function* sagaFetchWorkspaceDocuments(action: ReturnType<typeof actionFetchWorkspaceDocuments.request>) {
  try {
    const result: HttpTypes.WorkspaceDocumentSummary[] = yield* call(fetchWorkspaceDocuments, action.payload);

    yield put(actionFetchWorkspaceDocuments.success({ items: result }));
  } catch (error) {
    yield put(actionFetchWorkspaceDocuments.failure({ error }));
  }
}

const fetchWorkspaceParticipantActivityLogs = (args: WorkspaceActivityLogsApiRequest) => {
  const { workspaceId, participantId, pageNumber } = args;
  const query = queryString.stringify({ pageNumber, pageSize: 50 });
  const apiPath = `/workspaces/${encodeURIComponent(workspaceId)}/participant/${encodeURIComponent(participantId)}/log?${query}`;
  return http.get<WorkspaceActivityLogsApiResponse>(apiPath);
};

// if we reach in this stage, we already have the workspace detail.
const getWorkspaceParticipants = (state: Store) => state.workspaceParticipants.items;

function* sagaFetchWorkspaceParticipantActivityLogs(action: ReturnType<typeof actionFetchWorkspaceParticipantActivityLogs.request>) {
  try {
    const responseData = yield* call(fetchWorkspaceParticipantActivityLogs, action.payload);
    const { items, totalCount } = responseData;

    const converted: Array<WorkspaceActivityLogModel> = yield* convertWorkspaceLogs(items);

    yield put(actionFetchWorkspaceParticipantActivityLogs.success({ items: converted, totalCount, pageNumber: action.payload.pageNumber }));
  } catch (error) {
    yield put(actionFetchWorkspaceParticipantActivityLogs.failure({ error }));
  }
}

const fetchWorkspaceActivityLogs = (args: WorkspaceActivityLogsApiRequest) => {
  const { workspaceId, participantId, pageNumber } = args;
  const query = queryString.stringify({ pageNumber, pageSize: 50, queryType: WorkspaceLogQueryTypeEnum.Notifications });
  const apiPath = `/workspaces/${encodeURIComponent(workspaceId)}/participant/${encodeURIComponent(participantId)}/log?${query}`;
  return http.get<WorkspaceActivityLogsApiResponse>(apiPath);
};

function* sagaFetchWorkspaceActivityLogs(action: ReturnType<typeof actionFetchWorkspaceActivityLogs.request>) {
  try {
    const responseData = yield* call(fetchWorkspaceActivityLogs, action.payload);
    const { items, totalCount } = responseData;

    const converted: Array<WorkspaceActivityLogModel> = yield* convertWorkspaceLogs(items);

    yield put(actionFetchWorkspaceActivityLogs.success({ items: converted, totalCount, pageNumber: action.payload.pageNumber }));
  } catch (error) {
    yield put(actionFetchWorkspaceActivityLogs.failure({ error }));
  }
}

function* convertWorkspaceLogs(items: WorkspaceActivityLogModel[]) {
  const participants = (yield select(getWorkspaceParticipants)) as HttpTypes.WorkspaceParticipantListApiResponse;
  const converted: Array<WorkspaceActivityLogModel> = items.map(item => {
    const { workspaceLogType, data, text } = item;

    let dataObject: any = {};
    try {
      if (data) {
        // sometimes data doesn't exist, from some very simple workspace logs, we only have plain text log
        dataObject = JSON.parse(data);
      }
    } catch (e) {
      Logger.captureException(new InvalidDataError('Cannot parse JSON data from activity log', data));
    } finally {
      // when we cant parse the data object due to some error, it doesn't mean the whole item is invalid
      // Here we always check if data object does have message prop then we use text prop as a fallback
      if (!dataObject.Message && text) {
        dataObject.Message = text;
      }
    }

    return {
      ...item,
      dataObject,
      template: getTemplate(workspaceLogType, dataObject),
      participant: participants.find(d => d.id === item.participantId)
    };
  });
  return converted;
}

const fetchWorkspaceTasks = (args: WorkspaceRelatedApiRequest) => {
  const { workspaceId, participantId } = args;
  return http.get<WorkspaceTaskApiResponse[]>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/tasks`);
};

function* sagaFetchWorkspaceTask(action: ReturnType<typeof actionFetchWorkspaceTasks.request>) {
  try {
    const items = yield* call(fetchWorkspaceTasks, action.payload);
    yield put(actionFetchWorkspaceTasks.success({ items }));
  } catch (error) {
    yield put(actionFetchWorkspaceTasks.failure({ error }));
  }
}

const fetchAdditionalInvitableRoles = (args: WorkspaceRelatedApiRequest) => {
  const { workspaceId, participantId } = args;
  return http.get<WorkspaceInvitableRoleDetailApiResponse>(`/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/financial/invitations`);
};

function* sagaFetchAdditionalInvitableRoles(action: ReturnType<typeof actionFetchAdditionalInvitableRoles.request>) {
  try {
    const detail = yield* call(fetchAdditionalInvitableRoles, action.payload);
    yield put(actionFetchAdditionalInvitableRoles.success({ detail }));
  } catch (error) {
    yield put(actionFetchAdditionalInvitableRoles.failure({ error }));
  }
}

const fetchWorkspaceParticipantSetting = (queryParams: WorkspaceParticipantSettingApiRequest) => {
  const { workspaceId, participantId } = queryParams;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/settings`;
  return http.get<ParticipantSettingApiResponse>(uri);
};

function* sagaFetchWorkspaceParticipantSetting(action: ReturnType<typeof actionFetchWorkspaceParticipantSetting.request>) {
  try {
    const data = yield* call(fetchWorkspaceParticipantSetting, action.payload);

    yield put(actionFetchWorkspaceParticipantSetting.success(data));
  } catch (error) {
    yield put(actionFetchWorkspaceParticipantSetting.failure({ error }));
  }
}

const fetchWorkspaceAuthorityComplianceReport = ({ workspaceId, participantId, authorityMessageId, messageType, lodgementCaseId }: WorkspaceAuthorityComplianceReportRequest) => {
  return endpoints.getAuthorityComplianceReport({ workspaceId, participantId, authorityMessageId, messageType, lodgementCaseId });
};

function* sagaFetchWorkspaceAuthorityComplianceReport(action: ReturnType<typeof actionFetchWorkspaceAuthorityComplianceReport.request>) {
  try {
    const data: HttpTypes.AuthorityComplianceReportApiResponse = yield* call(fetchWorkspaceAuthorityComplianceReport, action.payload);
    yield put(actionFetchWorkspaceAuthorityComplianceReport.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceAuthorityComplianceReport.failure({ error }));
  }
}

const fetchWorkspaceLatestTransaction = (workspaceId: string) => {
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/latest-settlement-transaction`;
  return http.get<WorkspaceLatestTransactionApiResponse>(uri);
};

function* sagaFetchWorkspaceLatestTransaction(action: ReturnType<typeof actionFetchWorkspaceLatestTransaction.request>) {
  try {
    const detail: WorkspaceLatestTransactionApiResponse = yield* call(fetchWorkspaceLatestTransaction, action.payload.workspaceId);
    yield put(actionFetchWorkspaceLatestTransaction.success({ detail }));
  } catch (error) {
    yield put(actionFetchWorkspaceLatestTransaction.failure({ error }));
  }
}

const fetchWorkspaceDebitAccountsFeed = () => http.get<HttpTypes.FinancialAccountsApiResponse>('/workspaces/financial-accounts');

function* sagaFetchFinancialAccounts() {
  try {
    const data = yield* call(fetchWorkspaceDebitAccountsFeed);
    yield put(actionFetchWorkspaceFinancialAccounts.success({ data }));
  } catch (error) {
    yield put(actionFetchWorkspaceFinancialAccounts.failure({ error }));
  }
}

const fetchLinkedSettlementWorkspaces = (workspaceId: string, clusterId: string) => {
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/clusters/${encodeURIComponent(clusterId)}/linked-settlement-workspaces`;
  return http.get<LinkedSettlementDetailApiResponse>(uri);
};

function* sagaFetchLinkedSettlementWorkspaces(action: ReturnType<typeof actionFetchLinkedSettlementDetail.request>) {
  try {
    const detail: LinkedSettlementDetailApiResponse = yield* call(fetchLinkedSettlementWorkspaces, action.payload.workspaceId, action.payload.clusterId);
    yield put(actionFetchLinkedSettlementDetail.success({ detail }));
  } catch (error) {
    yield put(actionFetchLinkedSettlementDetail.failure({ error }));
  }
}

const fetchLinkedLodgementWorkspaces = (workspaceId: string, clusterId: string) => {
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/clusters/${encodeURIComponent(clusterId)}/linked-lodgement-workspaces`;
  return http.get<LinkedLodgementDetailApiResponse>(uri);
};

function* sagaFetchLinkedLodgementWorkspaces(action: ReturnType<typeof actionFetchLinkedLodgementDetail.request>) {
  try {
    const detail: LinkedLodgementDetailApiResponse = yield* call(fetchLinkedLodgementWorkspaces, action.payload.workspaceId, action.payload.clusterId);
    yield put(actionFetchLinkedLodgementDetail.success({ detail }));
  } catch (error) {
    yield put(actionFetchLinkedLodgementDetail.failure({ error }));
  }
}

export default [
  takeLatest(actionFetchWorkspaceBasicInfo.request, sagaFetchWorkspaceBasicInfo),
  takeLatest(actionFetchWorkspaceById.request, sagaFetchWorkspaceById),
  takeLatest(actionFetchWorkspaceParticipants.request, sagaFetchWorkspaceParticipants),
  takeLatest(actionFetchWorkspaceDocuments.request, sagaFetchWorkspaceDocuments),
  takeLatest(actionFetchWorkspaceParticipantActivityLogs.request, sagaFetchWorkspaceParticipantActivityLogs),
  takeLatest(actionFetchWorkspaceActivityLogs.request, sagaFetchWorkspaceActivityLogs),
  takeLatest(actionFetchWorkspaceTasks.request, sagaFetchWorkspaceTask),
  takeLatest(actionFetchAdditionalInvitableRoles.request, sagaFetchAdditionalInvitableRoles),
  takeLatest(actionFetchWorkspaceParticipantSetting.request, sagaFetchWorkspaceParticipantSetting),
  takeLatest(actionFetchWorkspaceAuthorityComplianceReport.request, sagaFetchWorkspaceAuthorityComplianceReport),
  takeLatest(actionFetchWorkspaceLatestTransaction.request, sagaFetchWorkspaceLatestTransaction),
  takeLatest(actionFetchWorkspaceFinancialAccounts.request, sagaFetchFinancialAccounts),
  takeLatest(actionFetchLinkedSettlementDetail.request, sagaFetchLinkedSettlementWorkspaces),
  takeLatest(actionFetchLinkedLodgementDetail.request, sagaFetchLinkedLodgementWorkspaces)
];
