import * as React from 'react';

import classNames from 'classnames';
import MuiBox from '@mui/material/Box';

import { ColumnsModel, TableV2 as Table } from '@sympli/ui-framework/components/table';
import { IconCheckboxTick } from '@sympli/ui-framework/icons';

import DidNotLoad from 'src/containers/dashboard/components/svg/DidNotLoad';
import { useTableCellBodyCheckboxStyles, useTableCellHeadCheckboxStyles } from 'src/containers/dashboard/financial-workspaces/table/styles';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { ApiStatus } from 'src/utils/http';
import { resolveColumns } from './helpers';
import { UnlinkedLodgementWorkspaceTableContext, UnlinkLodgementWorkspaceTableModel } from './models';
import { useSmallScreenStyles } from './styles';

const UnlinkLodgementWorkspacesTable = ({
  rows,
  apiStatus,
  selectedRowIndexes,
  onSelectionChange
}: {
  rows: UnlinkLodgementWorkspaceTableModel[];
  apiStatus?: ApiStatus;

  // selection mode
  selectedRowIndexes: number[];
  onSelectionChange: (selectedRowIndexes: number[]) => void;
}) => {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const smallScreenClasses = useSmallScreenStyles();
  const tableContext = React.useMemo<UnlinkedLodgementWorkspaceTableContext>(() => ({ screenVariant }), [screenVariant]);

  const tableCellHeadCheckboxClasses = useTableCellHeadCheckboxStyles();
  const tableCellBodyCheckboxClasses = useTableCellBodyCheckboxStyles();

  const columns: ColumnsModel<UnlinkLodgementWorkspaceTableModel> = React.useMemo(() => resolveColumns({ screenVariant }), [screenVariant]);
  const isLoading = apiStatus === 'idle' || apiStatus === 'pending';
  const noDataMessage = apiStatus === 'rejected' ? <DidNotLoad /> : undefined;

  return (
    <Table //
      className={classNames(
        'mb-[10px] mt-[10px] border-b border-x-0 border-t-0 border-solid border-[var(--neutral-800)]',
        screenVariant === 'small-laptop' && smallScreenClasses.root
      )}
      context={tableContext}
      noDataMessage={noDataMessage}
      recordType="Linked Workspaces"
      columns={columns}
      rows={rows}
      noFooter
      isLoading={isLoading}
      selectionMode="all"
      selectedRowIndexes={selectedRowIndexes}
      onSelectionChange={onSelectionChange}
      classesTableHead={tableCellHeadCheckboxClasses}
      classesTableBody={tableCellBodyCheckboxClasses}
      tableHeadCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #333' }} />}
      tableHeadCheckboxCheckedIcon={
        <MuiBox sx={{ width: 24, height: 24, border: '1px solid #333', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconCheckboxTick width={20} height={20} />
        </MuiBox>
      }
      tableBodyCheckboxIcon={<MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0' }} />}
      tableBodyCheckboxCheckedIcon={
        <MuiBox sx={{ width: 24, height: 24, border: '1px solid #D0D0D0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconCheckboxTick width={20} height={20} />
        </MuiBox>
      }
      disableHeadCheckboxIndeterminate
    />
  );
};

export default React.memo(UnlinkLodgementWorkspacesTable);
