import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiNominateLodgementCase_Model, NominateLodgementCase_Model } from './models';

class Converter implements IConverter<NominateLodgementCase_Model, ApiNominateLodgementCase_Model> {
  fromApiToFormModel(apiModel: ApiNominateLodgementCase_Model, context: IConverterContext): NominateLodgementCase_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: NominateLodgementCase_Model, _originalApiModel: ApiNominateLodgementCase_Model): ApiNominateLodgementCase_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
