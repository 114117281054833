import _uniqueId from 'lodash-es/uniqueId';
import { ActionMeta } from 'redux-actions';
import { put, select, takeLatest } from 'typed-redux-saga';

import Logger from '@sympli/ui-logger';

import UserManagerProvider from '../../auth/UserManagerProvider';
import { DialogTypeEnum } from '../../components/global-notification';
import environments from '../../environments';
import { actionPushErrorToQueue, CREATE_GLOBAL_ERROR_MESSAGE, GlobalErrorMessageModel } from '../actions/globalErrors';
import { actionEnableMfaDialog } from '../actions/mfaCheck';
import { actionRequireLinkDevice } from '../actions/profile';
import { ActionStore } from '../middlewares/globalErrorMiddleware';
import { CoreStore } from '../reducers';

const DEFAULT_TITLE = 'Something went wrong';
const DEFAULT_MESSAGE = 'Please call 1300 SYMPLI (1300 796 754).';
// 400
const DEFAULT_BAD_REQUEST_ERROR_MESSAGE = 'Check your details and try again. If urgent, please call 1300 SYMPLI (1300 796 754).';
// 404
const DEFAULT_NOT_FOUND_ERROR_MESSAGE = "We can't seem to find the page you're looking for. Please check your URL.";
// 403
const DEFAULT_FORBIDDEN_ERROR_TITLE = 'Access denied';
const DEFAULT_FORBIDDEN_ERROR_MESSAGE = 'You do not have permission to access this page. Please contact your admin.';
// 409
const DEFAULT_CONFLICT_ERROR_MESSAGE = 'There was an error with your information. Please refresh the page and try again.';
// 429
const DEFAULT_TOO_MANY_REQUESTS_ERROR_MESSAGE = 'An excessive amount of requests has been detected. Please try again in a while.';

// 5XX
const DEFAULT_SERVER_ERROR_TITLE = 'Internal server error';
const DEFAULT_SERVER_ERROR_MESSAGE = 'Sorry, there were some technical issues while processing your request. Please call 1300 SYMPLI (1300 796 754) for assistance.';
const { BASENAME } = environments;
function* sagaPushGlobalErrors(action: ActionMeta<GlobalErrorMessageModel, any>) {
  try {
    const { payload: error, meta = {} } = action;
    if (error == null) {
      return;
    }
    // get status and httpMessage from AxiosError Object
    const statusCode = Number(error.response?.status);

    switch (statusCode) {
      // * Invalid auth token
      case 401:
        // we don't want to show an error dialog, but instead we redirect user to our login page
        // to avoid infinite loop, we need to clean up the staled token in the storage before hard reload
        UserManagerProvider.authClient.clearStorage();
        window.location.href = environments.BASENAME + '/logout';
        break;
      case 423:
        // For user logout current account
        yield window.location.replace(`${BASENAME}/logout`);
        break;
      // * Require MFA
      case 418:
        const originalAction: ActionMeta<any, any> = meta?.action;
        const mfaId: string = (error.response?.data as any)?.id; // TODO fix type

        if (originalAction != null) {
          ActionStore.push(originalAction);
        }
        yield put(actionEnableMfaDialog({ mfaId }));
        break;

      // * Require linking user device
      case 420:
        const state: CoreStore = yield select();
        // this is to avoid link device get double handled
        const { mfaSetupInProgress } = state.profile;
        if (!mfaSetupInProgress) {
          yield put(actionRequireLinkDevice());
        }
        break;

      default:
        const { title, message, type } = resolveGeneralErrorMessageByStatusCode(error);
        yield put(actionPushErrorToQueue({ ...error, id: _uniqueId('error'), title, message, type }));
    }
  } catch (error) {
    Logger.captureException(error);
  }
}

export function resolveGeneralErrorMessageByStatusCode(error: GlobalErrorMessageModel) {
  // get title and message from GlobalErrorMessage object
  let { title = DEFAULT_TITLE, message = DEFAULT_MESSAGE, redirectOnClose } = error;
  let type: DialogTypeEnum | undefined = undefined;

  // get status and httpMessage from AxiosError Object
  const statusCode = Number(error.response?.status);
  const httpDetailMessage = (error.response?.data as any)?.message ?? ''; // TODO fix type
  switch (statusCode) {
    case 400:
      message = httpDetailMessage || DEFAULT_BAD_REQUEST_ERROR_MESSAGE;
      break;
    case 403:
      title = DEFAULT_FORBIDDEN_ERROR_TITLE;
      message = httpDetailMessage || DEFAULT_FORBIDDEN_ERROR_MESSAGE;
      break;
    case 404:
      // * This method only been used if the document has been deleted.
      // * All others 404 should be displayed as a global error [WEB-11091](https://tickleme.atlassian.net/browse/WEB-11091)
      if (httpDetailMessage?.includes('This document cannot be opened because this document has been deleted from this workspace.')) {
        title = 'Document cannot be opened';
        type = DialogTypeEnum.Document;
      }
      message = httpDetailMessage || DEFAULT_NOT_FOUND_ERROR_MESSAGE;
      break;
    case 409:
      message = httpDetailMessage || DEFAULT_CONFLICT_ERROR_MESSAGE;
      break;
    case 429:
      message = httpDetailMessage || DEFAULT_TOO_MANY_REQUESTS_ERROR_MESSAGE;
      break;
    case 500:
      title = DEFAULT_SERVER_ERROR_TITLE;
      if (!httpDetailMessage || httpDetailMessage === 'internal server error') {
        message = DEFAULT_SERVER_ERROR_MESSAGE;
      } else {
        message = httpDetailMessage;
      }
      message = DEFAULT_SERVER_ERROR_MESSAGE;
      break;
    default:
  }

  return { title, message, type, redirectOnClose };
}

export default [
  takeLatest(CREATE_GLOBAL_ERROR_MESSAGE.ACTION, sagaPushGlobalErrors) //
];
