import * as React from 'react';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { UserAvatar } from 'src/components/avatars';
import DirectionStatusBadgeNew from '../../../directions/components/direction-status-badge/DirectionStatusBadgeNew';
import FssSectionStatus from '../../../directions/components/settlement-directions-list/components/fss-section-status/FssSectionStatus';
import { SectionTypeEnum } from '../../../directions/components/settlement-directions-list/models';
import { Currency } from '../styled-components';
import { ParticipantCollapseBoxContainerProps } from './ParticipantCollapseBoxContainer';
import styles, { ClassKeys } from './styles';

interface OwnProps extends ParticipantCollapseBoxContainerProps {}

interface State {
  open: boolean;
  openAll?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class ParticipantCollapseBox extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    open: this.props.openAll
  };

  get directionInDraft() {
    const { sectionType, distributionsParticipant } = this.props;
    const isDraft = ParticipantCollapseBox.getFinancialStatusIsDraft;

    if (sectionType === SectionTypeEnum.Sources) return isDraft(distributionsParticipant.sourceFundsFinancialStatus);
    if (sectionType === SectionTypeEnum.Payments) return isDraft(distributionsParticipant.paymentsFinancialStatus);

    return [HttpTypes.DocumentStatusEnum.InPreparation, HttpTypes.DocumentStatusEnum.Awaiting, HttpTypes.DocumentStatusEnum.Reviewing].includes(
      distributionsParticipant.distributionStatus
    );
  }

  static getFinancialStatusIsDraft(financialStatus: HttpTypes.FinancialStatusEnum): boolean {
    return [HttpTypes.FinancialStatusEnum.InPreparation, HttpTypes.FinancialStatusEnum.Saved].includes(financialStatus);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { openAll: nextOpenAll } = nextProps;
    const { openAll: prevOpenAll } = prevState;
    if (nextOpenAll !== prevOpenAll) {
      return {
        open: nextOpenAll,
        openAll: nextOpenAll
      };
    }
    return null;
  }

  render() {
    const { distributionsParticipant, classes, subTotalAmount, renderCollapseDetail, hasItems } = this.props;
    const { open } = this.state;
    const hideAmount = hasItems && open;

    return (
      <FlexLayout flexDirection="column" className={classes.root}>
        <FlexLayout className={classes.infoRow}>
          <FlexLayout className={classes.columnOne}>{this.renderParticipant(distributionsParticipant)} </FlexLayout>
          <FlexLayout alignItems="center" className={classes.columnTwo}>
            {hasItems && (
              <ButtonLink
                onClick={() => {
                  this.setState(({ open }) => ({
                    open: !open
                  }));
                }}
                color="inherit"
                className={classes.showDetailsButtonLink}
              >
                {open ? 'Hide line items' : 'Show line items'}
              </ButtonLink>
            )}
          </FlexLayout>
          <Currency className={classes.columnThree}>{!hideAmount && currency(subTotalAmount, '')}</Currency>
        </FlexLayout>
        <Collapse in={open}>
          <div className={this.directionInDraft ? classes.grey : ''}>{renderCollapseDetail(distributionsParticipant)}</div>
        </Collapse>
      </FlexLayout>
    );
  }

  private renderParticipant = (distributionsParticipant: HttpTypes.WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'][number]) => {
    const { currentParticipantId, workspaceParticipants, classes } = this.props;
    const { participantId } = distributionsParticipant;
    const isCurrentParticipant = currentParticipantId === participantId;
    const participant = workspaceParticipants.find(p => p.id === participantId);
    const subscriberName = participant?.name ?? 'Unknown participant';
    const subscriberRole = participant?.workspaceRole.name ?? '';
    const src = participant?.avatarUrl ?? '';

    return (
      <>
        <UserAvatar src={src} className={classes.avatar} text={subscriberName} showToolTip />
        <FlexLayout flexDirection="column" alignItems="flex-start">
          <Typography className={classes.name} title={subscriberName}>
            {subscriberRole}
          </Typography>
          <div>{this.renderNewStatusBadge(isCurrentParticipant)}</div>
        </FlexLayout>
      </>
    );
  };

  private renderNewStatusBadge = (isCurrentParticipant: boolean) => {
    const {
      sectionType,
      distributionsParticipant: { isPartiallySigned, paymentsFinancialStatus, sourceFundsFinancialStatus, distributionStatus, paymentsRequiredSigners, sourceFundsRequiredSigners }
    } = this.props;
    if (sectionType !== undefined) {
      return (
        <FssSectionStatus
          financialStatus={sectionType === SectionTypeEnum.Payments ? paymentsFinancialStatus : sourceFundsFinancialStatus}
          sectionType={sectionType}
          numberOfSigners={sectionType === SectionTypeEnum.Payments ? paymentsRequiredSigners : sourceFundsRequiredSigners}
        />
      );
    }
    return (
      <DirectionStatusBadgeNew
        isCurrentParticipant={isCurrentParticipant}
        status={distributionStatus}
        isPartiallySigned={isPartiallySigned}
        paymentsStatus={paymentsFinancialStatus}
        sourceFundsStatus={sourceFundsFinancialStatus}
      />
    );
  };

  private handleOnClickItemSwitch = () => {
    this.setState(({ open }) => ({
      open: !open
    }));
  };
}

const styledComponent = withStyles(styles)(ParticipantCollapseBox);

export default styledComponent;
