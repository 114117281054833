import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { Scope } from '@sympli/ui-logger';

import { ApiStatus } from 'src/utils/http';
import {
  //
  actionFetchProfile,
  actionRequireLinkDevice,
  actionUpdateProfile
} from '../actions/profile';
import useStoreSelector from '../useStoreSelector';

export interface AuthProfileState {
  data?: HttpTypes.UserProfileModel;
  isLoading: boolean;
  status: ApiStatus;
  error?: string;
  mfaSetupInProgress: boolean;
}
const initialState: AuthProfileState = {
  status: 'idle',
  isLoading: true,
  mfaSetupInProgress: false
};

interface NewRelicUserModel {
  id: string;
  email: string;
  subscriberId: string;
  isSoftTokenEnabled: boolean;
  isSSOEnabled: boolean;
}
export function useProfile() {
  return useStoreSelector(store => store.profile); /* danger:disable */
}

const reducer = createReducer<
  //
  AuthProfileState,
  Action
>(initialState)
  .handleAction(actionFetchProfile.request, (state): AuthProfileState => {
    const status = state.data ? 'refetching' : 'pending';
    return {
      ...state,
      isLoading: status !== 'refetching',
      status: state.data ? 'refetching' : 'pending', // here we only need to check profile data for refetching flag, because api only allows to fetch current logged in user
      error: undefined
    };
  })
  .handleAction(actionFetchProfile.success, (state, action): AuthProfileState => {
    const { data } = action.payload;
    // setup default scope that will be available in all logs
    Logger.setDefaultScope(
      new Scope<NewRelicUserModel>().setUser({
        id: data.userId,
        subscriberId: data.subscriberId,
        email: data.email,
        isSoftTokenEnabled: data.isSoftTokenEnabled,
        isSSOEnabled: data.isSSOEnabled
      })
    );

    return {
      ...state,
      isLoading: false,
      status: 'resolved',
      data
    };
  })
  .handleAction(actionFetchProfile.failure, (state, action): AuthProfileState => {
    return {
      ...state,
      isLoading: false,
      status: 'rejected',
      error: action.payload.error.message
    };
  })
  .handleAction(actionUpdateProfile, (state, action): AuthProfileState => {
    return {
      ...state,
      data: {
        ...state.data!,
        ...action.payload
      }
    };
  })
  .handleAction(actionRequireLinkDevice, (state): AuthProfileState => {
    return {
      ...state,
      mfaSetupInProgress: true
    };
  });
export default reducer;
