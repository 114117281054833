import * as yup from 'yup';

import { getLookupValuesAllowingEmpty, memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import { ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS, IssuingPartyDirectionEnum } from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/enums';
import yupCtController from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/nomination-details/validationSchema';
import yupTitleReferences from 'src/containers/documents/scaffolded-form/vic/2-25/components/nomination/title-references/validationSchema';
import { BridgeWorkspaceModel, NominateLodgementCase_2_18_1_Model } from './models';
import { VISIBILITY_CHECK_ISSUING_PARTY_DIRECTION } from './visibilityCheck';

export function yupIssuingPartyDirectionValidationSchema<CRoot extends object = {}>(): yup.MixedSchema<IssuingPartyDirectionEnum | null> {
  const yupValidation: yup.MixedSchema<IssuingPartyDirectionEnum | null> = yup //
    .mixed<IssuingPartyDirectionEnum | null>()
    .required(msg.REQUIRED)
    .oneOf(getLookupValuesAllowingEmpty(ISSUING_PARTY_DIRECTION_LOOKUP_OPTIONS), msg.INVALID_SELECTION);

  const yupIssuingPartyDirection = validateWhenVisible2({
    visibilityCheck: (
      parent: NominateLodgementCase_2_18_1_Model,
      context: NominateLodgementCase_2_18_1_Model //
    ) => VISIBILITY_CHECK_ISSUING_PARTY_DIRECTION(context.bridgeWorkspace),
    validationWhenVisible: yupValidation
  });

  const schema = memoizeSchemaWithContext(yupIssuingPartyDirection, (parentContext: CRoot): CRoot => parentContext);
  return schema as yup.MixedSchema<IssuingPartyDirectionEnum, object>;
}

const yupIssuingPartyDirection = yupIssuingPartyDirectionValidationSchema<NominateLodgementCase_2_18_1_Model>();

export default yup
  .object<NominateLodgementCase_2_18_1_Model>({
    titleReferences: yupTitleReferences,
    ctController: yupCtController,
    issuingPartyDirection: yupIssuingPartyDirection,
    bridgeWorkspace: yup.mixed<BridgeWorkspaceModel>()
  })
  .defined()
  .log();
