import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';
import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';
import { IconCalculator, IconEye, IconUsb } from '@sympli/ui-framework/icons';

import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';

const getWorkflowSteps = (directionDetail: HttpTypes.WorkspaceDirectionsApiResponse) => directionDetail.documentSummary.paymentsSummary.workflow.steps;
export const directionsWorkflowStepsSelector = createSelector<
  //
  HttpTypes.WorkspaceDirectionsApiResponse,
  HttpTypes.AccessOptionEnum[],
  StepObjectModel<string, number>[]
>(getWorkflowSteps, steps => {
  const enabledStepSet = new Set<any>(steps);
  const documentSteps: StepObjectModel<string, number>[] = directionsStepConstructor().filter(step => enabledStepSet.has(step.value));
  return documentSteps;
});

function directionsStepConstructor() {
  const directionsSteps: Array<StepObjectModel> = [
    {
      icon: <IconCalculator />,
      label: 'Edit form',
      value: DocumentWorkflowStepsEnum.Write
    },
    {
      icon: <IconEye />,
      label: 'Review',
      value: DocumentWorkflowStepsEnum.Review
    },
    {
      icon: <IconUsb />,
      label: 'Sign',
      value: DocumentWorkflowStepsEnum.Sign
    }
  ];
  return directionsSteps;
}
