import React from 'react';

import { useFormikContext } from 'formik';
import Divider from '@mui/material/Divider';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import PartyForm, { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import { HttpTypes } from '@sympli/api-gateway/types';
import Subform from '@sympli/ui-framework/components/formik/subform';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorModel } from 'src/containers/documents/scaffolded-form/nsw/2-21/components/deceased-tenancy-detail/models';
import { NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE } from '../../../../configs';
import DateOfDeath from '../../components/date-of-death';
import EvidenceArray from '../../components/evidence-array';
import { useStyles } from './styles';
import { VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE } from './visibilityChecks';

interface Props {
  name: string;
  proprietor: DeceasedProprietorModel;
  onPartyTypeChange(partyType: HttpTypes.PartyTypeEnum): void;
}

function DeceasedProprietor({ name, proprietor, onPartyTypeChange }: Props): JSX.Element {
  const { divider } = useStyles();
  const { disabled } = useDocumentContext();

  const { values } = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { partyBook } = values;
  const fieldName = createModelKeyAppender<DeceasedProprietorModel>(name);
  const partyBookIndex = partyBook.findIndex(party => party.id === proprietor.partyBookId);
  const partyBindingPath = `partyBook[${partyBookIndex}]`;

  const party = partyBook[partyBookIndex];

  // if party merge marks that the party requires name change and it is not provided yet, disable all fields after party form
  const requiresJustificationButIsNotChanging = Boolean(
    party.mergeMetadata?.requiresJustification && party.receivingOrRelinquishingDetails.isRelinquishing && !party.receivingOrRelinquishingDetails.isChangingName
  );

  const partyConfig: PartyFormConfig<NswNameChange> = {
    ...NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE,
    nameChangeConfig: !proprietor.isSelected ? undefined : NSW_NOD_PARTY_FORM_CONFIG_WITH_NAME_CHANGE.nameChangeConfig
  };

  return (
    <div data-section={name} data-testid={name}>
      <FlexLayout justifyContent="flex-start" flexDirection="column">
        <Subform //
          subformBindingPath={partyBindingPath}
          component={PartyForm}
          disabled={disabled}
          config={partyConfig}
          showFormGroups={false}
          onPartyTypeChange={onPartyTypeChange}
        />

        {VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE(proprietor) && (
          <>
            <Divider className={divider} />
            <DateOfDeath //
              name={fieldName('dateOfDeath')}
              disabled={disabled || requiresJustificationButIsNotChanging}
            />
          </>
        )}
        {VISIBILITY_CHECK_DATE_OF_DEATH_AND_EVIDENCE(proprietor) && (
          <>
            <Divider className={divider} />
            <EvidenceArray //
              name={fieldName('evidences')}
              disabled={disabled || requiresJustificationButIsNotChanging}
            />
          </>
        )}
      </FlexLayout>
    </div>
  );
}

export default React.memo(DeceasedProprietor);
