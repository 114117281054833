import isAfter from 'date-fns/isAfter';
import dateformat from 'dateformat';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { DateFormatEnum } from 'src/@core/models';
import { convertDateObject, getDateRange } from 'src/components/date-range-picker/helpers';
import { DateRangeTypeEnum } from 'src/components/date-range-picker/models';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { WorkspaceStatusEnum } from './filters/models';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from './models';
import { FinancialTableModel, SortingOptionEnum } from './table/models';

export const defaultTabQuery = (
  //
  selectedTab: FinancialDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum,
  groupId: string | undefined
): FinancialWorkspacesV2ApiRequest => {
  let sortBy: string | undefined = undefined;
  let sortOrder: DashboardSortDirectionEnum | undefined = DashboardSortDirectionEnum.asc;
  let settlementPeriod: DashboardDateRangeModel | undefined = undefined;
  let lastAccessPeriod: DashboardDateRangeModel | undefined = undefined;
  let workspaceStatus: number[] = [];
  let withProposals: boolean | undefined = undefined;
  let groupIds: string[] = [];
  const { from, to } = getDateRange(DateRangeTypeEnum.Today);
  const pageSize = 20;
  const pageNumber = 1;

  workspaceStatus = getWorkspaceStatusFilter(selectedTab, assignmentType);
  if (assignmentType === AssignmentTypeEnum.ReAssigned && groupId) {
    groupIds = [groupId];
  }
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all: {
      sortBy = SortingOptionEnum.SettlementDate;
      sortOrder = DashboardSortDirectionEnum.asc;
      // backend will automatically exclude withdrawn, abandoned, archived, but we want to be more consistent so we still have it here as other tabs will have
      break;
    }
    case FinancialDashboardRouteTabsEnumV2.active: {
      settlementPeriod = {
        start: from,
        end: to
      };
      sortBy = SortingOptionEnum.SettlementDate;
      sortOrder = DashboardSortDirectionEnum.asc;
      break;
    }
    case FinancialDashboardRouteTabsEnumV2.toBeRebooked: {
      withProposals = false;
      sortBy = SortingOptionEnum.SettlementDate;
      sortOrder = DashboardSortDirectionEnum.asc;
      break;
    }
    case FinancialDashboardRouteTabsEnumV2.complete: {
      sortBy = SortingOptionEnum.SettlementDate;
      sortOrder = DashboardSortDirectionEnum.desc;
      break;
    }
    case FinancialDashboardRouteTabsEnumV2.archived: {
      sortOrder = DashboardSortDirectionEnum.desc;
      sortBy = SortingOptionEnum.ArchivedDate;
      break;
    }
    case FinancialDashboardRouteTabsEnumV2.recent: {
      sortOrder = DashboardSortDirectionEnum.desc;
      sortBy = SortingOptionEnum.LastAccessed;
      lastAccessPeriod = {
        start: from,
        end: to
      };
      break;
    }
  }

  return { sortBy, sortOrder, pageSize, pageNumber, settlementPeriod, lastAccessPeriod, workspaceStatus, withProposals, assignmentType: assignmentType, groupIds: groupIds };
};

const getWorkspaceStatusFilter = (selectedTab: FinancialDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): number[] => {
  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    return getReassignWorkspaceStatusFilter(selectedTab);
  }
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Settling,
        WorkspaceStatusEnum.SettlingWithErrors,
        WorkspaceStatusEnum.Settled
      ];
    case FinancialDashboardRouteTabsEnumV2.active:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Settling,
        WorkspaceStatusEnum.SettlingWithErrors
      ];
    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return [WorkspaceStatusEnum.InPreparationWithErrors];
    case FinancialDashboardRouteTabsEnumV2.complete:
      return [WorkspaceStatusEnum.Settled];
    case FinancialDashboardRouteTabsEnumV2.archived:
      return [WorkspaceStatusEnum.Archived, WorkspaceStatusEnum.Abandoned, WorkspaceStatusEnum.Withdrawn];
    case FinancialDashboardRouteTabsEnumV2.recent:
      return [
        WorkspaceStatusEnum.InPreparation,
        WorkspaceStatusEnum.InPreparationWithErrors,
        WorkspaceStatusEnum.Ready,
        WorkspaceStatusEnum.Settling,
        WorkspaceStatusEnum.SettlingWithErrors,
        WorkspaceStatusEnum.Settled
      ];
  }
};

const getReassignWorkspaceStatusFilter = (selectedTab: FinancialDashboardRouteTabsEnumV2): number[] => {
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all:
    case FinancialDashboardRouteTabsEnumV2.active:
    case FinancialDashboardRouteTabsEnumV2.recent:
      return [WorkspaceStatusEnum.InPreparation, WorkspaceStatusEnum.InPreparationWithErrors, WorkspaceStatusEnum.Ready];
    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return [WorkspaceStatusEnum.InPreparationWithErrors];
    default:
      return [];
  }
};

export const resolveSavedTabFiltersQuery = (currentTabFilters: DashboardFilterModel, assignmentType: AssignmentTypeEnum): FinancialWorkspacesV2ApiRequest => {
  const { selectedTab, selectedFilters, settlementPeriod, archivedDatePeriod, lastAccessPeriod, ...rest } = currentTabFilters;

  return {
    pageNumber: 1,
    pageSize: 20,
    ...convertDateObject(settlementPeriod, 'settlementPeriod'),
    ...convertDateObject(lastAccessPeriod, 'lastAccessPeriod'),
    ...convertDateObject(archivedDatePeriod, 'archivedDatePeriod'),
    ...rest
  };
};

export const resolveWorkspaceDetailArgs = (
  items: FinancialTableModel[],
  rowDetailIndex?: number
): {
  //
  workspaceId?: string;
  participantId?: string;
  linkedWorkspaceClusterId?: string;
  linkedWorkspaceType?: HttpTypes.LinkedWorkspaceTypeEnum;
} => {
  if (Number.isInteger(rowDetailIndex)) {
    const row: FinancialTableModel = items[rowDetailIndex!];
    if (row) {
      return {
        workspaceId: row.workspaceId,
        participantId: row.participantId,
        linkedWorkspaceClusterId: row.linkedWorkspaceClusterId,
        linkedWorkspaceType: row.linkedWorkspaceType
      };
    } else {
      // this should never happen since reducer takes care of resetting rowDetailIndex
      Logger.captureException(new BusinessLogicError('No row data found.'));
    }
  }
  return {
    workspaceId: undefined,
    participantId: undefined,
    linkedWorkspaceClusterId: undefined
  };
};

export const resolveFilterTabName = (tab: string) => {
  switch (tab) {
    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return 'to be rebooked';
    case FinancialDashboardRouteTabsEnumV2.all:
    case FinancialDashboardRouteTabsEnumV2.active:
    case FinancialDashboardRouteTabsEnumV2.archived:
    case FinancialDashboardRouteTabsEnumV2.complete:
    case FinancialDashboardRouteTabsEnumV2.recent:
    default:
      return tab;
  }
};

// this return the mapped message and source and some special comment
// be aware in the panel some has special desc like settlement paused if display as technical issues
export const resolveWorkspaceLogTypeMapping = (
  logType: HttpTypes.LogTypeEnum
): //
{ desc: string; panelDesc?: string; source?: string; comment?: React.ReactNode } => {
  switch (logType) {
    case HttpTypes.LogTypeEnum.LodgementInitated:
      return { desc: 'Lodgement In Progress' };
    case HttpTypes.LogTypeEnum.LodgementInstructionsOrderUpdated:
      return { desc: 'Lodgement Order Updated' };
    case HttpTypes.LogTypeEnum.LodgementVerificationSuccess:
      return { desc: 'Lodgement Verification Successful', source: 'Land Registry' };

    case HttpTypes.LogTypeEnum.LodgementSuccess:
      return { desc: 'Lodged', source: 'Land Registry' };
    case HttpTypes.LogTypeEnum.LodgementVerificationFailed:
      return { desc: 'Lodgement Verification Unsuccessful', source: 'Land Registry' };
    case HttpTypes.LogTypeEnum.WorkspaceWithdraw:
      return { desc: 'Participant Withdrawn' };
    case HttpTypes.LogTypeEnum.TitleActivityPositiveChange:
      return { desc: 'Positive Title Activity Check', source: 'Land Registry' };

    case HttpTypes.LogTypeEnum.TitleLandRegistryInformationSupplied:
      return { desc: 'Registry Information Updated', source: 'Land Registry' };

    case HttpTypes.LogTypeEnum.StampDutyVerifyFailed:
      return { desc: 'Stamp Duty Verification Unsuccessful', source: 'State Revenue Office' };

    case HttpTypes.LogTypeEnum.SettlementStarts:
      return {
        desc: 'Settlement In Progress',
        comment: (
          <Typography variant="body2">
            <b>The settlement process has started</b> and may take 30 minutes. The statuses will update as the settlement progresses.
          </Typography>
        )
      };

    case HttpTypes.LogTypeEnum.SettlementRollover:
      return {
        desc: 'Settlement Rollover',
        comment: <Typography variant="body2">Please view the workspace for further details.</Typography>
      };

    case HttpTypes.LogTypeEnum.SettlementPaused:
      return {
        desc: 'Settlement Paused',
        panelDesc: 'Technical Issues',
        comment: (
          <Typography>
            If the workspace status doesn't progress in the following <b>20min</b>, don't hesitate to call Sympli Customer Support on
            <b>1300 796 754</b> to help you settle in time.
          </Typography>
        )
      };
    case HttpTypes.LogTypeEnum.SettlementFailed:
      return {
        desc: 'Settlement Unsuccessful',
        comment: (
          <>
            <Typography variant="body2">
              <b>Something went wrong.</b>
            </Typography>
            <Typography variant="body2">
              This workspace has <b>attempted to settle</b> with no success. It has returned to an 'In Preparation' state.
            </Typography>

            <Typography variant="body2">
              <b>Review the errors</b> that appear in the workspace and edit to progress it further.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Note: This workspace needs to be <b>rebooked</b>.
            </Typography>
          </>
        )
      };

    case HttpTypes.LogTypeEnum.SettlementSuccessful:
      return {
        desc: 'Settlement Success',
        panelDesc: 'Settled',
        comment: (
          <>
            <Typography variant="body2">
              The matter has now <b>been settled!</b>
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Note1: You are <b>not able to edit</b> this workspace.
            </Typography>

            <Typography variant="body2">
              Note2: To ensure system efficiency and responsiveness, 90 days from the settlement day, this workspace will be moved to 'Archive'
            </Typography>
          </>
        )
      };

    case HttpTypes.LogTypeEnum.LodgementFailed:
      return { desc: 'Lodgement Unsuccessful', source: 'Land Registry' };
    case HttpTypes.LogTypeEnum.LodgementCompleted:
      return { desc: 'Completed' };
    case HttpTypes.LogTypeEnum.LodgementFailedTechnicalError:
    case HttpTypes.LogTypeEnum.LodgementFailedConnectivityError:
      return { desc: 'Lodgement Paused' };
    case HttpTypes.LogTypeEnum.WorkspaceLinkedPaymentCreated:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedSourceFundCreated:
      return {
        desc: 'Linked Settlement is active',
        comment: 'A shared financial line item has been added and settlement date & time needs to be aligned with all the linked workspaces.'
      };

    case HttpTypes.LogTypeEnum.WorkspaceLinkedPaymentUnlinked:
    case HttpTypes.LogTypeEnum.WorkspaceLinkedSourceFundUnlinked:
      return { desc: 'Linked Settlement is deactivated', comment: 'This workspace is no longer part of Linked Settlement.' };
    case HttpTypes.LogTypeEnum.WorkspaceLinkedLodgementCreated:
      return {
        desc: 'Linked Lodgement is active',
        comment: (
          <Typography variant="body2">
            This workspace is now part of a linked lodgement and the settlement date & time needs to be aligned with all the linked workspaces.
          </Typography>
        )
      };
    case HttpTypes.LogTypeEnum.WorkspaceLinkedLodgementUnlinked:
      return {
        desc: 'Linked Lodgement is deactivated',
        comment: <Typography variant="body2">This workspace is no longer part of linked lodgement.</Typography>
      };
    default:
      return { desc: '' };
  }
};

export const shouldUpdateTime = (updatedTime: Date, currentUpdatedTime: Date) => {
  return (
    isAfter(updatedTime, currentUpdatedTime) &&
    dateformat(updatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER) !== dateformat(currentUpdatedTime, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER)
  );
};

export const getDefaultAssignmentTypeTab = (key: string): AssignmentTypeEnum => {
  const data = localStorage.getItem(key);
  if (data) {
    return Number(data) as AssignmentTypeEnum;
  }
  return AssignmentTypeEnum.AssignedToMe;
};
