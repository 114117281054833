export function IconMessengerOpen(props: React.PropsWithChildren<React.HTMLAttributes<SVGElement>>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 0C8.975 0 0 8.975 0 20C0 24.8 1.725 29.4125 4.875 33.05V38.625C4.875 39.3875 5.4875 40 6.25 40H20C31.025 40 40 31.025 40 20C40 8.975 31.025 0 20 0ZM32.175 28.4125C28.9375 31.6875 24.6125 33.5 20 33.5C15.3875 33.5 11.0625 31.6875 7.825 28.4125C7 27.575 7.0125 26.2375 7.8375 25.4125C8.675 24.5875 10.0125 24.6 10.8375 25.425C13.275 27.8875 16.525 29.25 19.9875 29.25C23.45 29.25 26.7 27.8875 29.1375 25.425C29.9625 24.5875 31.3125 24.5875 32.1375 25.4125C32.9625 26.2375 32.975 27.5875 32.15 28.4125H32.175Z"
        fill="white"
      />
      <defs>
        <rect width="40" height="40" fill="white" />
      </defs>
    </svg>
  );
}

export function IconMessengerClose(props: React.PropsWithChildren<React.HTMLAttributes<SVGElement>>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 0C8.975 0 0 8.975 0 20C0 24.8 1.725 29.4125 4.875 33.05V38.625C4.875 39.3875 5.4875 40 6.25 40H20C31.025 40 40 31.025 40 20C40 8.975 31.025 0 20 0ZM29.375 26.375C30.2 27.2 30.2 28.55 29.375 29.375C28.9625 29.7875 28.4125 30 27.875 30C27.3375 30 26.7875 29.7875 26.375 29.375L20 23L13.625 29.375C13.2125 29.7875 12.6625 30 12.125 30C11.5875 30 11.0375 29.7875 10.625 29.375C9.8 28.55 9.8 27.2 10.625 26.375L17 20L10.625 13.625C9.8 12.8 9.8 11.45 10.625 10.625C11.45 9.8 12.8 9.8 13.625 10.625L20 17L26.375 10.625C27.2 9.8 28.55 9.8 29.375 10.625C30.2 11.45 30.2 12.8 29.375 13.625L23 20L29.375 26.375Z"
        fill="white"
      />
      <defs>
        <rect width="40" height="40" fill="white" />
      </defs>
    </svg>
  );
}
