import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import { PartyModel, resolveMemoizedContextBasedPartyBookValidationSchema, resolvePartyModelValidationSchema } from '@sympli-mfe/document-forms-framework/components/party-form';

import { NSW_DM_PARTY_FORM_CONFIG } from '../../config';
import { getMortgageePartyBookIds, getSelectedMortgages } from '../../helpers';
import { DischargeOfMortgage2_17_1Model, MortgageModel } from '../../models';

interface PartyBookValidationContext {
  mortgages: MortgageModel[];
}

// context that will be passed to memoized validation.
// same context will be passed into checkShouldValidateItem
const resolveValidationContext = (parentContext: DischargeOfMortgage2_17_1Model): PartyBookValidationContext => {
  return {
    mortgages: parentContext.mortgages
  };
};

const checkShouldValidateItem = ({ mortgages }: PartyBookValidationContext, partyIdForCheck: PartyModel<NswNameChange>['id']) => {
  const selectedMortgages = getSelectedMortgages(mortgages);
  const mortgageePartyBookIds = getMortgageePartyBookIds(selectedMortgages);
  const isSelectedMortgagee = !!~mortgageePartyBookIds.indexOf(partyIdForCheck);
  return isSelectedMortgagee;
};

export const yupPartyItem = resolvePartyModelValidationSchema<NswNameChange, PartyBookValidationContext>(NSW_DM_PARTY_FORM_CONFIG);

const yupPartyBook = resolveMemoizedContextBasedPartyBookValidationSchema<PartyModel<NswNameChange>, PartyBookValidationContext>({
  yupPartyItem,
  checkShouldValidateItem,
  resolveValidationContext
});

export default yupPartyBook;
