import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useFormControlStyleOverrides = makeStyles(
  {
    root: {
      // width: 120,
      flexShrink: 0,
      borderRadius: 4,
      background: colors.NEUTRAL_025,
      '&:hover': {
        background: colors.GREEK_WATERS_TRANSLUCENT
      },
      '&.devHelper': {
        '&[data-name]::after': {
          position: 'absolute',
          content: 'attr(data-name)',
          fontSize: 12,
          color: '#d2691e80',
          fontWeight: 'normal',
          pointerEvents: 'none',
          marginTop: 65
        }
      }
    }
  },
  { name: 'TextboxSearch_FormControlStyleOverrides' }
);
export const useInputLabelStyleOverrides = makeStyles(
  {
    root: {
      color: colors.NEUTRAL_600,
      fontFamily: 'Roboto',
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      textTransform: 'uppercase',

      paddingLeft: 12,
      paddingRight: 13,
      paddingTop: 13,

      '&.Mui-focused': {
        color: colors.NEUTRAL_600
      }
    }
  },
  { name: 'TextboxSearch_InputLabelStyleOverrides' }
);

export const useSelectOverridesClasses = makeStyles(
  {
    root: {
      height: 64,
      borderRadius: 4,
      backgroundColor: 'inherit',

      '&.MuiInputBase-root': {
        '& div.MuiSelect-select': {
          textOverflow: 'inherit'
        }
      },

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: colors.NEUTRAL_025
      },

      '&:hover': {
        background: 'inherit',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: colors.GREEK_WATERS_TRANSLUCENT
        }
      },

      '&:before': {
        borderBottom: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      },
      '&.Mui-focused': {
        background: colors.GREEK_WATERS_TRANSLUCENT,
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: colors.GREEK_WATERS_TRANSLUCENT
        }
      },

      '& fieldset': {
        boxSizing: 'border-box',
        borderRadius: 4,
        height: 64,
        top: 0,
        '& > legend': {
          display: 'none'
        }
      }
    },
    select: {
      color: colors.NEUTRAL_1000,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '24px',
      paddingTop: 28,
      paddingLeft: 11
    }
  },
  { name: 'TextboxSearch_SelectStyleOverrides' }
);

export const usePaperOverridesClasses = makeStyles(
  {
    root: {
      marginTop: 4,
      background: colors.GREEK_WATERS_TRANSLUCENT,
      borderRadius: 4,
      width: 300,
      boxShadow: '0px 20px 20px 0px rgba(0, 0, 0, 0.15)',
      paddingTop: 16
    }
  },
  { name: 'TextboxSearch_PaperStyleOverrides' }
);

export const useMenuOverridesClasses = makeStyles(
  {
    list: {
      paddingTop: 0,
      paddingBottom: 4
    }
  },
  {
    name: 'TextboxSearch_MenuStyleOverrides'
  }
);

export const useListSubheaderOverridesClasses = makeStyles(
  {
    root: {
      paddingLeft: 13,
      paddingRight: 13,
      paddingBottom: 20,
      background: colors.GREEK_WATERS_TRANSLUCENT
    }
  },
  {
    name: 'TextboxSearch_ListSubheaderStyleOverrides'
  }
);

export const useButtonsOverridesClasses = makeStyles({
  buttons: {
    flexDirection: 'row-reverse',
    width: '100%',
    marginTop: 16
  },
  clearButton: {
    height: 24,
    minHeight: 24,
    minWidth: 'auto',
    padding: '4px 12px',
    borderRadius: 34,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    border: `1px solid ${colors.SUNNY_DAY_LUMINOUS}`,
    color: colors.SUNNY_DAY_DARK,
    marginRight: 8,
    '&:hover': {
      borderColor: colors.SUNNY_DAY_LUMINOUS,
      backgroundColor: 'inherit',
      border: `1px solid ${colors.SUNNY_DAY_LUMINOUS}`
    }
  },
  searchButton: {
    height: 24,
    padding: '4px 12px',
    borderRadius: 34,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    backgroundColor: colors.SUNNY_DAY,
    color: colors.WHITE,
    minWidth: 'auto'
  }
});

export const useTextFieldOverridesClasses = makeStyles(
  {
    root: {
      top: 4,
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: colors.SUNNY_DAY
      },
      '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: colors.SUNNY_DAY
        }
      },
      '& .Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: colors.SUNNY_DAY
        }
      },
      '& label': {
        color: colors.SUNNY_DAY_DARK,
        '&.Mui-focused': {
          color: colors.SUNNY_DAY_DARK
        },
        top: -6,
        marginLeft: 13,
        paddingRight: 4,
        background: colors.GREEK_WATERS_TRANSLUCENT,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '14px',
        letterSpacing: '0.5px',
        // the label should never have any transform otherwise it will drop to the input search, this is to override MUI's default one
        transform: 'none'
      },
      '& .MuiInputBase-root': {
        paddingLeft: 0,
        height: 46,
        '& .MuiInputAdornment-positionStart': {
          paddingLeft: 8,
          paddingRight: 8
        },
        '& input': {
          color: colors.NEUTRAL_1000,
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '20px',
          letterSpacing: '0.5px'
        },
        '& svg': {
          width: 20,
          height: 20
        }
      }
    }
  },
  {
    name: 'TextboxSearch_TextFieldStyleOverrides'
  }
);
