import { WorkspaceSocket } from 'src/pages/workspace/socket/workspace-update';

export const socket = new WorkspaceSocket();

// export default function useWorkspaceSocket(workspaceId: string, participantId: string, workspaceTypeId?: WorkspaceTypeEnum, documentId?: string) {
//   const dispatch = useSafeDispatch(useDispatch());
//   const { subscriberId, userId, email } = useProfile().data!;
//   const isLodgementOnly = workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
//   const onWorkspaceUpdate = useWorkspaceUpdateListener({ workspaceId, participantId, isLodgementOnly, documentId });

//   useEffect(() => {
//     /**
//      * add new listener when onWorkspaceUpdate has changed & socket must be connected!
//      * we don't want to add un-necessary listeners, i.e workspaceTypeId is from workspaceBasicInfo, we wait until getting the data from
//      * backend, then we add the event listener. If we don't do that, that might cause race condition inside addListener function when we
//      * remove the outdated onWorkspaceUpdate function and add the latest one.
//      * https://tickleme.atlassian.net/browse/WEB-33290
//      */
//     socket.isConnected &&
//       participantId &&
//       workspaceId &&
//       workspaceTypeId !== undefined &&
//       socket.addListener(onWorkspaceUpdate, {
//         subscriberId,
//         userId,
//         email,
//         documentId,
//         workspaceId,
//         participantId,
//         workspaceTypeId
//       });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dispatch, documentId, workspaceTypeId, participantId, workspaceId, socket.isConnected]);
// }
