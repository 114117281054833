import { Action, createReducer } from 'typesafe-actions';

import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { FeatureToggleEnum } from '../../auth/feature-toggle/models';
import { actionAddCurrentPageFeatureFlag, actionClearCurrentPageFeatureFlag, PlatformEnum } from '../actions/currentPageFeatureFlags';

/*
  This is the redux data where we calculate how many feature flags a page is using,
  it is cleared when URL changes,
  it is updated by adding flags one by one using useFeatureFlag's dispatch actionAddCurrentPageFeatureFlag
*/

export interface FeatureStatus {
  isEnabled: boolean;
  isControlledBy: PlatformEnum;
}

interface CurrentPageFeatureFlagsState {
  items: Map<FeatureToggleEnum, FeatureStatus>;
}

const initialState: CurrentPageFeatureFlagsState = {
  items: new Map()
};

const reducer = createReducer<
  //
  CurrentPageFeatureFlagsState,
  Action
>(initialState)
  .handleAction(actionAddCurrentPageFeatureFlag, (state, action): CurrentPageFeatureFlagsState => {
    const newItems = new Map(state.items);
    const { name: flagName, status: flagStatus, isControlledBy } = action.payload;

    if (newItems.has(flagName) && newItems.get(flagName)?.isEnabled !== flagStatus) {
      // This should only happen when we change feature flags status via UI FeatureFlagsController
      const message = `The current page's feature flag(${flagName}) is changed to (${flagStatus})`;
      Logger.console(SeverityEnum.Info, message);
    }

    // filter out None, it is not a valid feature
    if (flagName !== FeatureToggleEnum.none) {
      newItems.set(flagName, { isEnabled: flagStatus, isControlledBy });
    }

    return {
      items: newItems
    };
  })
  .handleAction(actionClearCurrentPageFeatureFlag, (): CurrentPageFeatureFlagsState => {
    return {
      items: new Map()
    };
  });

export default reducer;
