import React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import { resolveWorkspaceDetailLink } from 'src/@core/pages/links';
import { actionFetchDirectionsList } from 'src/@core/store/actions/directionsList';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { useDirectionsList } from 'src/@core/store/reducers/directionsList';
import { useProfile } from 'src/@core/store/reducers/profile';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import { actionFetchDirections } from 'src/containers/workspace/financial/directions/actions';
import { useFeatureFlag, useRouterParams, useSafeDispatch } from 'src/hooks';
import { useLrsInfo } from 'src/store/reducers/lrsInfo';
import { actionFetchWorkspaceDocuments } from '../../../store/actions/workspace';
import { useWorkspaceBasicInfo } from '../../../store/reducers/workspace/workspaceBasicInfo';
import { useWorkspaceDetail } from '../../../store/reducers/workspace/workspaceDetail';
import { useWorkspaceDocuments } from '../../../store/reducers/workspace/workspaceDocuments';
import { useWorkspaceParticipants } from '../../../store/reducers/workspace/workspaceParticipants';
import { actionFetchAllDirections } from '../financial/all-directions/actions';
import { useDirectionsBreakdown } from '../financial/directions/reducers/directionsBreakdown';
import { actionOpenLodgementVerificationDialog } from '../shared/components/lodgement-verification-dialog/actions';
import SigningProvider, { OnSignArgs } from '../shared/components/signing-provider';
import { multiSignDocuments, MultiSignedDocumentModel, ToBeMultiSignDocumentsModel } from '../shared/components/signing-provider/helpers';
import { selectWorkspaceFiles } from '../shared/components/workspace-files-container/selector';
import { WorkspaceFilesApiResponse } from '../shared/components/workspace-files/models';
import { actionFetchDocumentMultiSign } from './actions';
import { multiSign, resolveFinancials, resolveLineItems, resolveLodgementCaseDocuments, resolveToBeMultiSignDocumentsModelMLC } from './helpers';
import { FinancialModel as FinancialDetailsModel, FinancialLineItem, LodgementCaseDetailsModel, SignDocumentsRouteParams, SignDocumentTypeEnum } from './models';
import { useDocumentMultiSign } from './reducer';
import SignMLCDocuments from './SignMLCDocuments';
import { useStyles } from './styles';

const EMPTY_WORKSPACE_FILES: WorkspaceFilesApiResponse[] = [];

function SignMLCDocumentsContainer() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, returnUrl } = useRouterParams<SignDocumentsRouteParams>();
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const currentParticipant: HttpTypes.WorkspaceParticipant | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const lrsInfo = useLrsInfo(workspaceBasicInfoState.detail?.jurisdictionId);
  const workspaceTypeId: HttpTypes.WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const workspaceStatusId: HttpTypes.WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const isNonLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly;
  const isNotAbandoned = Number.isInteger(workspaceStatusId) && ![HttpTypes.WorkspaceStatusEnum.Abandoned, HttpTypes.WorkspaceStatusEnum.Withdrawn].includes(workspaceStatusId!);
  const [toBeMultiSignDocumentsModel, setToBeMultiSignDocumentsModel] = React.useState<ToBeMultiSignDocumentsModel | undefined>(undefined);
  const [readyToSignCallback, setReadyToSignCallback] = React.useState<(() => void) | undefined>(undefined);
  const { isLoading: isWorkspaceLoading, error: workspaceError, detail: workspaceDetail } = useWorkspaceDetail(workspaceId, participantId);
  const { items: documents, error: documentsError, isLoading: isDocumentsLoading } = useWorkspaceDocuments(workspaceId, participantId);
  const { detail: directionsDetail, isLoading: isDirectionLoading, error: directionsError } = useDirectionsList(workspaceId, participantId);
  const { detail: directionListDetail, isLoading: isDirectionListLoading, error: directionListError } = useDirectionsBreakdown(workspaceId, participantId);
  const {
    detail: multiSignDetail,
    isLoading: isMultiSignDetailLoading,
    isRefetching: isMultiSignDetailRefetching,
    error: multiSignError,
    status: multiSignStatus
  } = useDocumentMultiSign(workspaceId, participantId);
  const workspaceDocumentState = useWorkspaceDocuments(workspaceId, participantId);
  const isDocumentAttachmentsEnhancementEnabled = useFeatureFlag(FeatureToggleEnum.documentAttachmentsEnhancementEnabled);
  const { data: userData, isLoading: isProfileLoading, error: profileError } = useProfile();
  const [isSigning, setIsSigning] = React.useState(false);
  const [isSignAndLodge, setIsSignAndLodge] = React.useState(false);
  // we need to make sure multiSigning data is always up to date
  const isMultiSignLoading = isMultiSignDetailLoading || isMultiSignDetailRefetching;
  // we don't need to worry about direction error for lodgement only
  const error = profileError || documentsError || multiSignError || ((directionsError || directionListError) && !isLodgementOnly);
  // we don't need to worry about direction loading for lodgement only
  const isLoading = isProfileLoading || isDocumentsLoading || ((isDirectionLoading || isDirectionListLoading) && !isLodgementOnly);

  const documentAttachments = selectWorkspaceFiles({
    workspaceDocuments: workspaceDocumentState.items,
    workspaceFiles: EMPTY_WORKSPACE_FILES,
    participantId,
    isDocumentAttachmentsEnhancementEnabled
  });

  const handleReadyToSign = React.useCallback(
    (readyToSignCallback: () => void) => {
      setReadyToSignCallback(() => readyToSignCallback);
    },
    [setReadyToSignCallback]
  );

  React.useEffect(() => {
    if (readyToSignCallback && toBeMultiSignDocumentsModel) {
      readyToSignCallback();
      setReadyToSignCallback(undefined); // we need to clean up the ready to sign callback to avoid loop
      setIsSigning(true);
    }
  }, [readyToSignCallback, toBeMultiSignDocumentsModel]);

  const handleOnSign = React.useCallback(
    (args: OnSignArgs): Promise<MultiSignedDocumentModel> => {
      return multiSignDocuments({
        ...args,
        ...toBeMultiSignDocumentsModel! // this cant be undefined when onSign is called
      });
    },
    [toBeMultiSignDocumentsModel]
  );

  const onBack = React.useCallback(() => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
      navigate(link);
    }
  }, [navigate, participantId, returnUrl, workspaceId]);

  const handlePostSign = React.useCallback(
    (signedData: MultiSignedDocumentModel) => {
      return multiSign({
        //
        workspaceId,
        participantId,
        isLodgementRequired: isSignAndLodge,
        signedData
      })
        .then(() => {
          batch(() => {
            // update document list
            dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
            // if it is not lodgement only
            if (isNonLodgementOnly) {
              // direction list
              dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
              // directions
              dispatch(actionFetchDirections.request({ workspaceId, participantId }));
              // directions overview
              dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
            }
            dispatch(
              actionOpenGlobalSimpleNotification({
                variant: 'loading',
                message: `Signing documents. Documents for ${currentParticipant?.reference} are being signed, we'll let you know if there are any problems`,
                autoHideDuration: 5000
              })
            );
          });

          onBack();
        })
        .finally(() => {
          setIsSigning(false);
        });
    },
    [currentParticipant?.reference, dispatch, isNonLodgementOnly, isSignAndLodge, onBack, participantId, workspaceId]
  );

  const lodgementCaseDetails: LodgementCaseDetailsModel[] = React.useMemo<LodgementCaseDetailsModel[]>(() => {
    if (isLoading || isWorkspaceLoading || !workspaceDetail) {
      return [];
    }
    return resolveLodgementCaseDocuments(workspaceId, participantId, documents, documentAttachments, workspaceDetail!.lodgementCases, multiSignDetail);
  }, [workspaceId, participantId, documents, isLoading, multiSignDetail, documentAttachments, isWorkspaceLoading, workspaceDetail]);

  const financialDetails: FinancialDetailsModel[] = React.useMemo<FinancialDetailsModel[]>(() => {
    if (isLoading || !directionsDetail) {
      return [];
    }
    return resolveFinancials(userData!.supportPageUrl, directionsDetail);
  }, [directionsDetail, isLoading, userData]);

  const financialLineItems = React.useMemo<Map<SignDocumentTypeEnum, FinancialLineItem[]>>(() => {
    if (isLoading) {
      return new Map<SignDocumentTypeEnum, FinancialLineItem[]>();
    }
    return resolveLineItems(participantId, isLodgementOnly ? undefined : directionListDetail);
  }, [isLoading, isLodgementOnly, directionListDetail, participantId]);

  // TODO move fetch logic to page level
  // we need to refetch to get latest documents data
  React.useEffect(() => {
    dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
  }, [dispatch, participantId, workspaceId]);

  // we need to refetch to get latest direction data
  React.useEffect(() => {
    if (isNonLodgementOnly && isNotAbandoned) {
      batch(() => {
        dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
        dispatch(actionFetchDirections.request({ workspaceId, participantId }));
      });
    }
  }, [dispatch, workspaceId, participantId, isNonLodgementOnly, isNotAbandoned]);

  React.useEffect(() => {
    if (isDocumentsLoading) {
      return;
    }

    dispatch(actionFetchDocumentMultiSign.request({ workspaceId, participantId }));
    // * We need to refetch document multi-sign data when document is added/removed or document list is reordered
  }, [isDocumentsLoading, dispatch, documents.map(doc => doc.documentId).join(), workspaceId, participantId]);

  React.useEffect(() => {
    // WEB-41103 If no signable documents, UI will redirect to the overview page
    if (multiSignStatus === 'resolved' && !multiSignDetail?.mergedPdfUrl) {
      const scope = Logger.scopeWithCustomAttributes({ workspaceId, participantId });
      Logger.console(SeverityEnum.Warning, 'No signable documents.', scope);
      const workspaceOverviewUrl = resolveWorkspaceDetailLink({ workspaceId, participantId });
      navigate(workspaceOverviewUrl, { replace: true });
    }
  }, [multiSignStatus, workspaceId, participantId, multiSignDetail, navigate]);

  const handleOnShowVerificationResultsClick = React.useCallback(() => {
    dispatch(actionOpenLodgementVerificationDialog());
  }, [dispatch]);

  const handleOnSubmit = React.useCallback(
    values => {
      setToBeMultiSignDocumentsModel(resolveToBeMultiSignDocumentsModelMLC(values, multiSignDetail!));
    },
    [multiSignDetail]
  );

  if (workspaceError || error) {
    return <GeneralCrashedContent />;
  }

  if (isWorkspaceLoading || isLoading) {
    return <BlockLoader />;
  }

  const hasNoTitles = !workspaceDetail?.titleReferences?.length;
  return (
    <SignMLCDocuments
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      financialDetails={financialDetails}
      lodgementCaseDetails={lodgementCaseDetails}
      // signing data
      //signDocuments={signDocuments}
      lineItems={financialLineItems}
      mergedPdfUrl={multiSignDetail?.mergedPdfUrl}
      isLoading={isMultiSignLoading}
      disabled={isSigning}
      // wizard handlers
      onBack={onBack}
      onSubmit={handleOnSubmit}
      // compliance issues handlers
      onShowVerificationResultsClick={handleOnShowVerificationResultsClick}
    >
      {formikProps => {
        const selectedDocuments = formikProps.values.documents.filter(x => x.isSelected).length;
        const signLabel = selectedDocuments ? `Sign (${selectedDocuments}) selected` : 'Sign selected';
        return (
          <FlexLayout className={classes.signingButtons} fullHeight>
            {isLodgementOnly && (
              <SigningProvider //
                disabled={
                  !formikProps.values.documents.every(d => d.isSelected || d.signed) ||
                  formikProps.values.documents.some(d => d.toBeSignedByOtherSigners) ||
                  isSigning ||
                  isMultiSignLoading ||
                  !lrsInfo.isValidOperatingHoursForRegOnly ||
                  hasNoTitles
                }
                preStartSigning={() => {
                  setIsSignAndLodge(true);
                  setToBeMultiSignDocumentsModel(undefined);
                  return formikProps.submitForm();
                }}
                mode="light"
                label={`${signLabel} and lodge`}
                onReadyToSign={handleReadyToSign}
                onSign={handleOnSign}
                onPostSign={handlePostSign}
                isLoading={isMultiSignLoading}
                variant="outlined"
                showArrow={false}
              />
            )}
            <SigningProvider //
              disabled={!formikProps.isValid || isSigning || isMultiSignLoading}
              preStartSigning={() => {
                setIsSignAndLodge(false);
                setToBeMultiSignDocumentsModel(undefined);
                return formikProps.submitForm();
              }}
              mode="light"
              label={signLabel}
              onReadyToSign={handleReadyToSign}
              onSign={handleOnSign}
              onPostSign={handlePostSign}
              isLoading={isMultiSignLoading}
            />
          </FlexLayout>
        );
      }}
    </SignMLCDocuments>
  );
}

export default SignMLCDocumentsContainer;
