import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model } from '../models';

type FormModel = TransmissionApplicationBeneficiaryDeviseeNextOfKin_2_21_1_Model;

export function resolveDeceasedPartiesOnTAB(
  //
  deceasedTenancyDetail: FormModel['deceasedTenancyDetail'],
  partyBook: FormModel['partyBook'],
  precedingData: FormModel['precedingData']
): FormModel['partyBook'] {
  try {
    if (Array.isArray(deceasedTenancyDetail?.proprietorGroups) && Array.isArray(partyBook)) {
      const selectedPartyBookIds: string[] = deceasedTenancyDetail?.proprietorGroups //
        .flatMap(group =>
          group.parties //
            .filter(p => p.isSelected)
            .map(proprietor => proprietor.partyBookId)
            .filter(Boolean)
        );

      const precedingDataParties =
        precedingData?.newProprietors
          .flatMap(x => x.proprietorGroups)
          .flatMap(x => x.parties)
          .map(x => x.party) ?? [];
      const selectedParties: FormModel['partyBook'] = partyBook.filter(p => selectedPartyBookIds.includes(p.id));
      return selectedParties.map(x => {
        const partyFromPrecedingData = precedingDataParties.find(p => p.id === x.id);
        const mergeMetadata = partyFromPrecedingData?.mergeMetadata ?? x.mergeMetadata;

        return {
          ...x,
          mergeMetadata
        };
      });
    }
  } catch (e) {}
  Logger.captureException(new BusinessLogicError('Unable to resolve deceased parties'));
  return [];
}
