import React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import { MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';

import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceBasicInfo } from 'src/store/reducers/workspace/workspaceBasicInfo';
import { actionFetchWorkspaceActivityLogs } from '../../../../../../store/actions/workspace';
import { useWorkspaceActivityLogs } from '../../../../../../store/reducers/workspace/workspaceActivityLogs';
import { useWorkspaceParticipants } from '../../../../../../store/reducers/workspace/workspaceParticipants';
import WorkspaceLogNotifications from './WorkspaceLogNotifications';

export function WorkspaceLogNotificationsContainer(props: { workspaceId: string; participantId: string }) {
  const { workspaceId, participantId } = props;
  const workspaceParticipantsState = useWorkspaceParticipants(props.workspaceId);
  const workspaceDetail = useWorkspaceBasicInfo(props.workspaceId);
  const workspaceActivityLogsState = useWorkspaceActivityLogs(props.workspaceId);
  const dispatch = useSafeDispatch(useDispatch());

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceActivityLogs.request({ workspaceId, participantId, pageNumber: 1 }));
  }, [dispatch, participantId, workspaceId]);

  const externalNotifications = getWithdrawnNotifications(workspaceId, participantId, workspaceParticipantsState.items);
  return (
    <WorkspaceLogNotifications //
      {...{ ...props, workspaceLogs: workspaceActivityLogsState.items, workspaceParticipants: workspaceParticipantsState.items }}
      workspaceStatus={workspaceDetail.detail?.workspaceStatusId}
      externalNotifications={externalNotifications}
    />
  );
}

// TODO: WEB-41432 Clean this up and use workspace log as a source for withdrawn notifications
function getWithdrawnNotifications(workspaceId: string, participantId: string, workspaceParticipants: HttpTypes.WorkspaceParticipant[]) {
  const withdrawnParticipants = workspaceParticipants.filter(p => p.archivedStatus);

  const items = workspaceParticipants.filter(p => p.participantStatus.id === HttpTypes.ParticipantStatusEnum.Accepted);

  const currentParticipant = items.find(x => x.id === participantId);
  if (!currentParticipant?.withdrawnParticipantIdsAfterAcceptedInvite?.length) {
    return [];
  }

  const visibleWithdrawnParticipants = withdrawnParticipants.filter(participant => currentParticipant!.withdrawnParticipantIdsAfterAcceptedInvite!.includes(participant.id));

  return visibleWithdrawnParticipants.map(x => (
    <MessageNotificationWithLocalStorage
      key={x.id}
      v2
      storageNamespace={`${workspaceId}/${participantId}/withdrawParticipantWarning`}
      messageId={x.id}
      open={undefined}
      variant="warning"
      primary={
        <div>
          <strong>'{x.workspaceRole.name}' has withdrawn from workspace.</strong> This action may have caused changes to your workspace, please check your tasks.
        </div>
      }
    />
  ));
}
