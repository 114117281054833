import { NAME_SUFFIX_LOOKUP_OPTIONS, NameSuffixEnum, PARTY_CAPACITY_LOOKUP_OPTIONS, PartyCapacityEnum } from '@sympli-mfe/enums-2-24/qld';
import { restrictedLookupOptions } from '@sympli-mfe/enums-shared/helpers';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, EvidenceTypeEnum } from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/components/evidence/enums';
import {
  DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS,
  DeceasedTenancyPartyCapacityEnum
} from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail/enums';

export const APPLICANT_CAPACITY_LOOKUP_OPTIONS: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[] = restrictedLookupOptions(DECEASED_TENANCY_PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [
    DeceasedTenancyPartyCapacityEnum.Trustee,
    DeceasedTenancyPartyCapacityEnum.Administrator,
    DeceasedTenancyPartyCapacityEnum.Executor,
    DeceasedTenancyPartyCapacityEnum.ExecutorByRepresentation
  ]
});

export const TRANSMISSION_FIRST_EVIDENCE_TYPE_LOOKUP_OPTIONS = restrictedLookupOptions(EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, {
  whitelist: [
    EvidenceTypeEnum.ExemplificationOfQueenslandGrantOfRepresentation,
    EvidenceTypeEnum.ExemplificationOfQueenslandRecognisedGrant,
    EvidenceTypeEnum.QueenslandGrantOfRepresentation,
    EvidenceTypeEnum.QueenslandRecognisedGrant
  ]
});

export const TRANSMISSION_NAME_SUFFIX_LOOKUP_OPTIONS: LookupEnumModel<string>[] = restrictedLookupOptions(NAME_SUFFIX_LOOKUP_OPTIONS, {
  whitelist: [NameSuffixEnum.Junior, NameSuffixEnum.Senior]
});

export const TRANSMISSION_PARTY_CAPACITY_LOOKUP_OPTIONS: LookupItemModel<PartyCapacityEnum>[] = restrictedLookupOptions(PARTY_CAPACITY_LOOKUP_OPTIONS, {
  whitelist: [PartyCapacityEnum.PersonalRepresentative]
});
