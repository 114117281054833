import * as React from 'react';

import classNames from 'classnames';
import { endOfToday, endOfYesterday } from 'date-fns';
import dateFormat from 'dateformat';
import { FieldArray, FormikErrors, FormikProps } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';
import _debounce from 'lodash-es/debounce';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';
import pluralize from 'pluralize';
import { ClassKeyOfStyles, ClassNameMap } from '@mui/styles/withStyles';

import ArrayItem from '@sympli-mfe/document-forms-framework/components/array-item';
import DocumentUploaderField from '@sympli-mfe/document-forms-framework/components/document-uploader-field';
import ArrowBox from '@sympli/ui-framework/components/form/base-components/arrow-box';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';

import { DateFormatEnum } from 'src/@core/models';
import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import AbnField from '../components/abn-field';
import AddItemButton from '../components/add-item-button';
import FormPropertyGroup from '../components/form-property-group';
import FractionField from '../components/fraction-field';
import FractionGroupField from '../components/fraction-group-field';
import ToggleValueField from '../components/toggle-value-field';
import WarningField from '../components/warning-field';
import { JsonSchemaRootModel, TextTransformTypeEnum } from '../models';
import { ClassKeys } from '../styles';
import { resetInvisibleValue, resolveItemForArray } from './data';
import { enhanceTitle, isFieldVisible, matchesOneOfTheGrammarRules, resolveBinding, resolveEnumOptions, resolveTitle } from './helpers';
import { DataSourcePropsDefinition, ExtraDefinition, ParsedFieldDefinition } from './models';
import { SourceDataReconciler } from './SourceDataReconsiler';

// tslint:disable:no-string-literal
interface Props {
  formikProps: FormikProps<any>;
  fields: Array<ParsedFieldDefinition>;
  // classes: ClassNameMap<keyof ReturnType<ClassKeys>>;
}

export default class DefinitionToJsx {
  private schema: JsonSchemaRootModel;

  private formikProps: FormikProps<any>;
  private fields: Array<ParsedFieldDefinition>;
  private classes: ClassNameMap<ClassKeyOfStyles<ClassKeys>>;
  private onBlockingActionInProgress: (inProgress: boolean) => void;

  private disableAllInputFields: boolean = false;

  private queryParams?: DocumentsPageRouteAndQueryModel;
  private sourceDataReconciler: SourceDataReconciler;

  constructor(props: {
    //
    schema: JsonSchemaRootModel;
    classes: ClassNameMap<keyof ReturnType<ClassKeys>>;
    onBlockingActionInProgress(inProgress: boolean): void;
    disableAllInputFields?: boolean;
    queryParams?: DocumentsPageRouteAndQueryModel;
  }) {
    const { classes, schema, onBlockingActionInProgress, disableAllInputFields, queryParams } = props;
    this.classes = classes;
    this.schema = schema;
    this.onBlockingActionInProgress = onBlockingActionInProgress;
    this.disableAllInputFields = disableAllInputFields || false;
    this.queryParams = queryParams;
    this.sourceDataReconciler = new SourceDataReconciler();
  }

  public getJsx(props: Props) {
    this.updateProps(props);

    return this.transformFields(this.fields);
  }

  public cleanValues(props: { values: object; fields: Array<ParsedFieldDefinition> }) {
    // traverse all values and if the field has _meta.invisibleValue specified
    // we are going to check the visibility and reset it's value if the field is invisible

    // create new instance of values so we don't mutate formik internals.
    const values = _cloneDeep(props.values);

    function handleDateFormat(definition: ParsedFieldDefinition, bindingPath: string) {
      const { extra } = definition;
      if (extra) {
        const { component } = extra;
        // console.log(`component ${component}`);
        if (component && component.toLowerCase() === 'datepicker') {
          const origValue = _get(values, bindingPath);
          if (origValue) {
            const dateTimeStr = dateFormat(origValue, DateFormatEnum.DATE);
            // console.log(`origValue ${origValue}, new string  ${dateTimeStr}`);
            _set(values, bindingPath, dateTimeStr);
          }
        }
      }
    }

    function traverse(fields: Array<ParsedFieldDefinition>, bindingPrefix: string = '', parentIsInvisible: boolean = false) {
      // tslint:disable-next-line:comment-format
      //! this function directly and explicitly mutates values object using resetInvisibleValue

      fields.forEach(fd => {
        const {
          type,
          name,
          extra: { metadata, invisibleValue },
          childrenExtra = {},
          children = []
        } = fd;

        if (metadata) {
          return;
        }

        const bindingPath = resolveBinding(name, bindingPrefix);
        const definition: ParsedFieldDefinition = {
          ...fd,
          name: bindingPath
        };

        // based on ticket web-4716 we will only handle the date format,
        // and backend specific said they no need time zone
        handleDateFormat(definition, bindingPath);

        // we need to calculate whether field is invisible
        let isInvisible = parentIsInvisible;

        if (!isInvisible) {
          // it make sense for us to do it in the case when invisibleValue was specified
          if (typeof invisibleValue !== 'undefined') {
            isInvisible = !isFieldVisible(definition, values);
            // and it also make sense to check if for objects and arrays, since we well need to check their nested fields
          } else if (type === 'object' || type === 'array') {
            isInvisible = !isFieldVisible(definition, values);
          }
        }

        // if invisibleValue was specified for the field, we will reset it's value when either field itself is invisible or it's parent is invisible
        if (typeof invisibleValue !== 'undefined' && isInvisible) {
          resetInvisibleValue(values, bindingPath, invisibleValue);
        }

        // we still need to continue to deeper level
        // but we can do it only if the value was not reset to null...
        if (invisibleValue !== null) {
          // we need to check items visibility
          if (type === 'array') {
            const { visibility: childrenVisibilityRules, invisibleValue: childInvisibleValue } = childrenExtra;

            // only if there are visibility rules
            if (Array.isArray(childrenVisibilityRules)) {
              _get(values, bindingPath, []).forEach((_, i) => {
                let itemBindingPath = `${bindingPath}[${i}]`;
                let isArrayItemInVisible = !matchesOneOfTheGrammarRules(childrenVisibilityRules, values, itemBindingPath);

                // reset value on given path if we have the invisible value defined on the item level
                if (typeof childInvisibleValue !== 'undefined') {
                  if (isInvisible || isArrayItemInVisible) {
                    resetInvisibleValue(values, itemBindingPath, childInvisibleValue);
                  }

                  // we still need to continue to deeper level
                  // but we can do it only if the value was not reset to null...
                  if (childInvisibleValue !== null) {
                    traverse(children, itemBindingPath, isInvisible || isArrayItemInVisible);
                  }
                } else {
                  // if we don't have invisibleValue defined, we are going to traverse the properties of given item
                  traverse(children, itemBindingPath, isInvisible || isArrayItemInVisible);
                }
              });
            } else {
              // recursively traverse each children field with prefix that represents item in the array
              _get(values, bindingPath, []).forEach((_, i) => {
                let itemBindingPath = `${bindingPath}[${i}]`;
                traverse(children, itemBindingPath, isInvisible);
              });
            }
          } else if (type === 'object') {
            // recursively traverse each children field with prefix that represents the object itself
            traverse(children, bindingPath, isInvisible);
          }
        }
      });
    }

    traverse(_cloneDeep(props.fields));
    return values;
  }

  private updateProps(props: Props) {
    Object.entries(props).forEach(([key, newKeyData]) => {
      this[key] = newKeyData;
    });
  }

  private transformFields(fields: Array<ParsedFieldDefinition>) {
    // ignore definitions marked as metadata
    const visibleFields = fields.filter(fd => !fd.extra.metadata);

    if (!visibleFields.length) {
      return [];
    }

    const { values } = this.formikProps;
    let shownFields = 0;

    return visibleFields.reduce((nodes: Array<React.ReactNode>, definition: ParsedFieldDefinition, i: number) => {
      if (!isFieldVisible(definition, values)) {
        return nodes;
      }
      shownFields++;

      // we want to show border only if there are at least two visible items
      const showTopBorder = shownFields > 1;
      const component = definition.extra.component;
      // if (isCustomComponent(component)) {
      //   nodes.push(this.resolveCustomComponentNode(definition));
      //   return nodes;
      // }

      if (component) {
        nodes.push(this.resolveFieldNode(definition, showTopBorder));
        return nodes;
      }

      switch (definition.type) {
        case 'object':
          nodes.push(this.resolveObjectNode(definition, showTopBorder));
          break;
        case 'array':
          nodes.push(this.resolveArrayNode(definition, showTopBorder));
          break;
        default:
          nodes.push(this.resolveFieldNode(definition, showTopBorder));
      }

      return nodes;
    }, []);
  }

  // private resolveCustomComponentNode(definition: ParsedFieldDefinition) {
  //   const dynamicComponentInstance = resolveDynamicComponent(
  //     definition,
  //     {
  //       formikProps: this.formikProps,
  //       name: definition.name,
  //       key: name
  //     },
  //     this.schema
  //   );

  //   // if so, the helper returned an instance of it so we just pass it further
  //   return dynamicComponentInstance;
  // }

  private resolveObjectNode(definition: ParsedFieldDefinition, showBorder: boolean) {
    const {
      formikProps: { values, errors, touched }
    } = this;

    const { name, children = [], extra } = definition;
    let title: any = resolveTitle(definition, values);

    let objectFields = children.map(fd => {
      return { ...fd, name: resolveBinding(fd.name, name) };
    });

    const errorMessage = _get(touched, name, false) && _get(errors, name);

    return (
      <FormPropertyGroup
        key={`object-wrapper-${name}`}
        title={title}
        error={typeof errorMessage === 'string' ? errorMessage : undefined}
        variant={extra.isRoot ? 'root' : 'nested'}
        topBorder={showBorder}
      >
        {this.transformFields(objectFields)}
      </FormPropertyGroup>
    );
  }

  private resolveArrayNode(definition: ParsedFieldDefinition, showBorder: boolean) {
    const { name, extra, children = [], childrenType, childrenExtra = {} } = definition;
    const { visibility: childrenVisibilityRules } = childrenExtra;
    const {
      formikProps: { values, errors, touched },
      disableAllInputFields
    } = this;

    let title: any = resolveTitle(definition, values);
    const titleSingular = pluralize.singular(title);
    const { minItems = 1, maxItems = 20, canAdd = true, dataSourceProps = { title: '', titleSuffix: '', sourceName: '' } } = extra;
    const { title: itemTitle, titleSuffix: itemTitleSuffix } = dataSourceProps;
    const items = _get(values, name, []);
    let visibleIndexes: { [x: string]: any };
    const count = items.length;
    let hasVisibleItem = false;

    if (childrenType === 'object' && Array.isArray(childrenVisibilityRules)) {
      visibleIndexes = {};
      items.forEach((_, i) => {
        const isVisible = matchesOneOfTheGrammarRules(childrenVisibilityRules, values, `${definition.name}[${i}]`);
        visibleIndexes[`${i}`] = isVisible;
        hasVisibleItem = hasVisibleItem || isVisible;
      });
    } else {
      hasVisibleItem = !!count;
      if (dataSourceProps.sourceName) {
        this.reconcileData(items, name, dataSourceProps as DataSourcePropsDefinition);
      }
    }

    // we don't want to render anything if there are no data and we are not allowed to add new item
    if (!hasVisibleItem && !canAdd) {
      return null;
    }

    const jsxFieldArray = (
      <FieldArray
        name={name}
        render={({ push, remove }) => {
          function addItem() {
            // resolve new item always when user clicked on add button
            const item = resolveItemForArray(definition, values, name);
            push(item);
          }

          if (!count && maxItems > 0 && canAdd && !disableAllInputFields) {
            return <AddItemButton onClick={addItem}>Add new {titleSingular}</AddItemButton>;
          }

          const lastIndex = count - 1;
          let shownItems = 0;

          return items.map((item, i) => {
            if (visibleIndexes) {
              if (!visibleIndexes[i]) {
                return null;
              }
            }
            shownItems++;

            let hasDelete = items.length > minItems;
            let isLastItem = i === lastIndex;
            let hasAdd = isLastItem && count < maxItems;
            let bindingPrefix = `${name}[${i}]`;

            let arrayFields = children.map(fd => {
              return { ...fd, name: resolveBinding(fd.name, bindingPrefix) };
            });

            let isSimpleType = _get(arrayFields, '[0].name', '').endsWith(']');
            // const showTopBorder = shownItems > 1;

            let itemNode: React.ReactNode = this.transformFields(arrayFields);

            // error message for current array item;
            const errorsForItems = _get(touched, name, false) && _get(errors, name);
            let errorMessage: string | FormikErrors<any> | undefined = undefined; // give type to fix the compiler error
            if (Array.isArray(errorsForItems)) {
              // get message on current index
              errorMessage = errorsForItems[i];
            }

            // complex types need arrow box
            if (!isSimpleType) {
              let resolvedTitle = enhanceTitle({
                title: `${itemTitle || titleSingular} ${shownItems}`, //
                titleSuffix: itemTitleSuffix,
                data: item
              });
              return (
                <ArrayItem //
                  key={`array-item-${bindingPrefix}`}
                  itemIndex={i}
                  titleForItem={resolvedTitle}
                  error={typeof errorMessage === 'string' ? errorMessage : undefined}
                  // render remove button only if we can add more items
                  onRemove={canAdd ? remove : undefined}
                  disableRemove={!hasDelete}
                  titleForRemove={`Remove this ${titleSingular}`}
                >
                  <ArrowBox>{itemNode}</ArrowBox>
                  {!disableAllInputFields && canAdd && hasAdd ? (
                    <AddItemButton className={classNames(isSimpleType && 'simple')} onClick={addItem}>
                      Add new {titleSingular}
                    </AddItemButton>
                  ) : null}
                </ArrayItem>
              );
            } else {
              return (
                <ArrayItem //
                  isSimpleType={true}
                  key={`array-item-${bindingPrefix}`}
                  itemIndex={i}
                  error={typeof errorMessage === 'string' ? errorMessage : undefined}
                  // no title shown for simple types
                  // titleForItem={resolvedTitle}
                  // render remove button only if we can add more items
                  onRemove={canAdd ? remove : undefined}
                  disableRemove={!hasDelete}
                  titleForRemove={`Remove this ${titleSingular}`}
                >
                  {itemNode}
                  {!disableAllInputFields && canAdd && hasAdd ? (
                    <AddItemButton className={classNames(isSimpleType && 'simple')} onClick={addItem}>
                      Add new {titleSingular}
                    </AddItemButton>
                  ) : null}
                </ArrayItem>
              );
            }
          });
        }}
      />
    );

    const variant = extra.isRoot ? 'root' : 'nested';
    const errorMessage = _get(errors, name) as string;
    return (
      <FormPropertyGroup key={`array-wrapper-${name}`} title={title} error={typeof errorMessage === 'string' ? errorMessage : undefined} variant={variant} topBorder={showBorder}>
        {jsxFieldArray}
      </FormPropertyGroup>
    );
  }

  private reconcileData = _debounce((value: any, name: string, dataSourceProps: DataSourcePropsDefinition) => {
    const { sourceName, sourceIdentifierName } = dataSourceProps as any;
    const destinationIdentifierName = dataSourceProps.destinationIdentifierName || sourceIdentifierName;
    const {
      formikProps: { values, setFieldValue }
    } = this;

    if (typeof sourceName === 'string') {
      const props = {
        sourceName,
        sourceIdentifierName,
        destinationIdentifierName,
        field: {
          name,
          value
        },
        form: {
          values,
          setFieldValue
        }
      };
      this.sourceDataReconciler.reconcileFieldValues.call(props);
    }
  }, 300);

  private resolveFieldWrapper(definition: ParsedFieldDefinition, F: JSX.Element, Component: React.ComponentClass) {
    const { title = '', extra = {} } = definition;
    const { dataTitle } = extra;

    if (Component.displayName === 'CheckboxField' && typeof dataTitle === 'string' && title.length) {
      return (
        <React.Fragment>
          <label className={classNames(this.classes.fieldLabel, this.classes.checkboxTitle)}>{title}</label>
          {F}
        </React.Fragment>
      );
    }

    return F;
  }

  private resolveFieldNode(definition: ParsedFieldDefinition, showBorder: boolean) {
    let { type, name } = definition;

    const {
      formikProps: { values },
      disableAllInputFields
    } = this;

    const title = resolveTitle(definition, values);

    const options = resolveEnumOptions(values, definition);

    const extra = {
      ...definition.extra,
      options
    };

    const Component = this.resolveComponent(type, extra);
    const props = this.resolveFieldProps(type, extra);
    // add data-schema from definition as a dataset prop
    const datasetProps = {
      'data-schema': JSON.stringify(definition, null, 2)
    };

    // console.log(`disableAllInputFields: ${disableAllInputFields}`);
    let F: JSX.Element = (
      <Field //
        key={`field-${name}`}
        label={title}
        name={name}
        component={Component as React.ComponentType<any>}
        {...props}
        {...extra.componentProps}
        {...datasetProps}
        disabled={disableAllInputFields || extra.disabled}
      />
    );
    // we will wrap our field in certain scenarios
    F = this.resolveFieldWrapper(definition, F, Component as any);

    if (extra.isRoot) {
      return (
        <FormPropertyGroup //
          key={`prop-wrapper-${name}`}
          title={title}
          variant="root"
          topBorder={showBorder}
        >
          {F}
        </FormPropertyGroup>
      );
    }

    return F;
  }

  private resolveFieldProps(type: string, extra: Partial<ExtraDefinition>) {
    const { options, component, disabled = false, textTransform } = extra;
    const hasEnum = Array.isArray(options);
    const props = {
      disabled
    };

    if (hasEnum) {
      props['options'] = options;
      props['placeholder'] = 'Please select';
      props['format'] = type;
    }

    switch (component) {
      case 'radio':
      case 'checkbox':
        props['format'] = type;
        props['vertical'] = true;
        break;

      case 'ToggleValue':
        props['actionLabel'] = 'Edit';
        break;
      case 'DocumentUploader':
        props['onUploadInProgress'] = this.onBlockingActionInProgress;
        if (this.queryParams) {
          props['queryParams'] = this.queryParams;
        }
        if (this.schema.state === 'vic') {
          props['maxSize'] = 10;
        }
        break;
      case 'DatePicker':
        const { datePickerComponentValidation } = extra;
        // keep support for datePickerComponentValidation due to backward compatibility
        const componentValidation = datePickerComponentValidation?.type || extra.componentValidation;
        if (componentValidation) {
          if (componentValidation === 'past-date-exclusively') {
            props['maxDate'] = endOfYesterday();
          }

          if (componentValidation === 'past-date-inclusively') {
            props['maxDate'] = endOfToday();
          }
        }

        break;
      default:
        if (type === 'string' && textTransform === TextTransformTypeEnum.UPPERCASE) {
          props['isUpperCase'] = true;
        }
        break;
    }

    props['classes'] = {
      devHelper: this.classes.devHelper
    };

    return props;
  }

  private resolveComponent(type: string, extra: Partial<ExtraDefinition> = {}) {
    const { component = '', options } = extra;

    switch (component) {
      case 'abn':
        return AbnField;
      case 'fraction':
        return FractionField;
      case 'fraction-group':
        return FractionGroupField;
      case 'ToggleValue':
        return ToggleValueField;
      case 'DatePicker':
        return DatePickerField;
      case 'DocumentUploader':
        return DocumentUploaderField;
      case 'radio':
        return RadioField;
      case 'WarningLabel':
        return WarningField;
      case 'checkbox':
        // if it has options define, it will be
        if (Array.isArray(options)) {
          return CheckboxGroupField;
        }
        return CheckboxField;
      default:
        break;
    }

    if (Array.isArray(options)) {
      return SelectField;
    }

    if (type === 'boolean') {
      return CheckboxField;
    }

    return InputField;
  }
}
