import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { CategoryEnum } from 'src/containers/workspace/financial/directions/views/edit-directions/forms/discharge-mortgage-directions/models';

export type LinkedCategory = CategoryEnum.LinkedPayment | CategoryEnum.LinkedSourceFund;

export interface LinkSettlementDialogFormikModel {
  category?: LinkedCategory;
  reference?: string;
  amount?: string;
  linkedSettlementFundsNotRequired: boolean;
  linkedSettlementItem: {
    linkedWorkspaceId: string;
    linkedParticipantId: string;
    linkedToParticipantMatterReference: string;
  };
}

export interface LinkLodgementDialogFormikModel {
  linkedLodgementItem: LinkedLodgementItem;
}

export interface LinkedLodgementItem {
  linkedWorkspaceId: string;
  linkedParticipantId: string;
  linkedToParticipantRole: number;
}

export const LINKED_WORKSPACE_LOOKUP_OPTIONS: LookupEnumModel<HttpTypes.LinkedWorkspaceTypeEnum>[] = [
  {
    id: HttpTypes.LinkedWorkspaceTypeEnum.LinkedSettlement,
    name: 'For Settlement'
  },
  {
    id: HttpTypes.LinkedWorkspaceTypeEnum.LinkedLodgement,
    name: 'For Lodgement'
  }
];

export interface LinkLodgementRequest {
  toWorkspaceId: string;
  toParticipantId: string;
  toParticipantWorkspaceRole: HttpTypes.WorkspaceRoleEnum;
}

export interface LinkLodgementResponse {
  clusterId: string;
  numberOfLinkedWorkspaces: number;
}
