import makeStyles from '@mui/styles/makeStyles';

const environmentLabelBase: any = {
  color: 'white',
  display: 'flex',
  margin: '0 auto',
  cursor: 'default',
  paddingTop: 3,
  'white-space': 'nowrap'
};

export const useStyles = makeStyles(
  {
    environmentLabelLeft: {
      fontSize: 22,
      fontWeight: 'bold',
      flex: 2,
      ...environmentLabelBase
    },
    environmentLabelRight: {
      fontSize: 10,
      flex: 1,
      'flex-direction': 'column',
      gap: '1px',
      position: 'relative',
      'padding-left': '10px',
      'text-align': 'center',
      ...environmentLabelBase,

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '1px',
        background: 'linear-gradient(to bottom, #DCB, #BAA)'
      }
    },
    localOn: {
      'background-color': 'green',
      '&::after': {
        content: '" : local"'
      }
    },
    localOff: {
      '&::after': {
        content: '" : remote"'
      }
    },
    v2Label: {
      position: 'absolute',
      width: 12,
      height: 12,
      top: -12,
      right: -8,
      border: '1px solid #18CDB6',
      background: '#18CDB6',
      borderRadius: '50%',
      fontSize: 12,
      padding: 4,
      color: 'white',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600
    },
    labelBox: {
      position: 'relative',
      marginLeft: 10,
      borderRadius: 5,
      height: 32,
      width: 'fit-content',
      padding: '0px 10px',
      display: 'flex',
      'align-item': 'center',
      gap: '10px'
    }
  },
  { name: 'EnvironmentLabel' }
);
