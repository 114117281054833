import { HttpTypes } from '@sympli/api-gateway/types';

import { ScreenSizeVariant } from 'src/theme';
import { InvitationsDashboardRouteTabsEnumV2 } from '../models';

export type InvitationsTableRowModel = HttpTypes.InvitationDashboardRecord;

export enum SortingFieldEnum {
  LastInvitationSentDate = 0,
  LastInvitationStatusUpdatedDate = 1
}

export enum SortingOptionEnum {
  SettlementDate = 'settlementDate',
  LastUpdated = 'lastUpdated',
  LastAccessed = 'lastAccessed'
}

export enum InvitationArchivedTypeEnum {
  Received = 0,
  Sent = 1
}

export enum DashboardInvitationStatusFilterEnum {
  Accepted = 0,
  Declined = 1,
  Forwarded = 2,
  Withdrawn = 3
}

export interface InvitationsTableContext {
  screenVariant: ScreenSizeVariant;
  selectedTab: InvitationsDashboardRouteTabsEnumV2;
}

export enum ArchivedInvitationLogsRoleEnum {
  Forwarder = 0,
  OriginalSender = 1,
  LastReceiver = 2
}

export enum ArchivedInvitationLogsActionEnum {
  Accepted = 0,
  Declined = 1,
  Withdrawn = 2,
  Forwarded = 3,
  Sent = 4
}
