import * as React from 'react';

import classNames from 'classnames';

import { AppEnvironmentEnum } from 'src/@core/environments/models';
import { LOCAL_API, LOCAL_REALTIME, SERVICE_ENV } from 'src/@core/environments/resolveRunTimeEnv';
import { randomPaletteColor } from './randomPaletteColor';
import { useStyles } from './styles';

interface Props {
  environmentName: AppEnvironmentEnum;
  className?: string;
}

const EnvironmentLabel = ({ environmentName, className }: Props) => {
  const classes = useStyles();

  if (shouldShowEnvironmentLabel(environmentName)) {
    const labelColor = randomPaletteColor({ seed: environmentName });

    return (
      <div className={classNames(classes.labelBox, className)} style={{ backgroundColor: labelColor }}>
        <div className={classes.environmentLabelLeft}>{environmentName === AppEnvironmentEnum.local ? `local-${SERVICE_ENV}`.toUpperCase() : environmentName.toUpperCase()}</div>
        <div className={classes.environmentLabelRight}>
          <div className={LOCAL_API ? classes.localOn : classes.localOff}>api</div>
          <div className={LOCAL_REALTIME ? classes.localOn : classes.localOff}>real-time</div>
        </div>
      </div>
    );
  }

  return <div className={classes.labelBox} />;
};

const shouldShowEnvironmentLabel = (environmentName: string) => {
  return !['production'].includes(environmentName);
};

export default React.memo(EnvironmentLabel);
