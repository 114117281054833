import * as React from 'react';

import { useFormikContext } from 'formik';
import _get from 'lodash-es/get';

import { VicPartyForm as PartyForm } from '@sympli-mfe/document-components/party-form/vic/2-28';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { VIC_DM_PARTY_FORM_CONFIG } from '../../config';
import { DischargeOfMortgage2_28_0Model, MortgageePartyModel } from '../../models';

// this file was automatically generated from components/ObjectComponent.tsx.mustache
interface Props {
  name: string;
  disabled: boolean;
  className?: string;
}

function Mortgagee({ name, disabled, className }: Props): JSX.Element {
  const formikProps = useFormikContext<DischargeOfMortgage2_28_0Model>();
  const { values } = formikProps;

  const value: MortgageePartyModel = _get(values, name);
  const partyBook: PartyModel[] = values.partyBook ?? [];
  const bookIndexOfPartyId = partyBook.findIndex(party => party.id === value.partyBookId);
  const partyBindingPath = `partyBook[${bookIndexOfPartyId}]`;

  return (
    <div data-name={name} className={className}>
      <Subform //
        subformBindingPath={partyBindingPath}
        component={PartyForm}
        disabled={disabled}
        showFormGroups={false}
        config={VIC_DM_PARTY_FORM_CONFIG}
      />
    </div>
  );
}

export default React.memo(Mortgagee);
