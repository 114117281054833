import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchSubscriberAssignableGroups } from '../actions';
import { GroupOptionModel } from '../models';

export interface SubscriberAssignableGroupsState {
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  items: GroupOptionModel[];
  error?: string;
}

const initialState: SubscriberAssignableGroupsState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined
};

export function useSubscriberAssignableGroups() {
  const state = useStoreSelector(store => store.subscriberAssignableGroups);
  return state;
}

const subscriberAssignableGroupsReducer = createReducer<
  //
  SubscriberAssignableGroupsState,
  Action
>(initialState)
  .handleAction(actionFetchSubscriberAssignableGroups.request, (state): SubscriberAssignableGroupsState => {
    const status = state.status === 'resolved' ? 'refetching' : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined
    };
  })
  .handleAction(actionFetchSubscriberAssignableGroups.success, (state, action): SubscriberAssignableGroupsState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data
    };
  })
  .handleAction(actionFetchSubscriberAssignableGroups.failure, (state, action): SubscriberAssignableGroupsState => {
    return {
      ...state,
      error: action.payload.error.message,
      isLoading: false,
      isRefetching: false,
      status: 'rejected'
    };
  });

export default subscriberAssignableGroupsReducer;
