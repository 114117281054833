import { createAction, createAsyncAction } from 'typesafe-actions';

import { WorkspaceSearchApiRequest } from '../../components/layout/global-search/models';
import { SearchBoxesUpdatedApiResponse } from '../reducers/searchBoxUpdated';

export const actionResetSearchBoxFeed = createAction(
  //
  'FETCH_SEARCHBOX_FEED_RESET'
)<WorkspaceSearchApiRequest>();

export const actionFetchSearchBoxUpdatedFeed = createAsyncAction(
  //
  'FETCH_SEARCHBOX_UPDATED_FEED',
  'FETCH_SEARCHBOX_UPDATED_FEED_SUCCESS',
  'FETCH_SEARCHBOX_UPDATED_FEED_ERROR'
)<
  //
  WorkspaceSearchApiRequest,
  { data: SearchBoxesUpdatedApiResponse },
  { error: Error }
>();
