import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiNominateeCtToPaperInstrument_Model, NominateeCtToPaperInstrument_Model } from './models';

class Converter implements IConverter<NominateeCtToPaperInstrument_Model, ApiNominateeCtToPaperInstrument_Model> {
  fromApiToFormModel(apiModel: ApiNominateeCtToPaperInstrument_Model, context: IConverterContext): NominateeCtToPaperInstrument_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: NominateeCtToPaperInstrument_Model, _originalApiModel: ApiNominateeCtToPaperInstrument_Model): ApiNominateeCtToPaperInstrument_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
