import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { NswNameChange } from '@sympli-mfe/document-components/party-form/nsw/2-21/components/party-justification';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import SectionLike from '@sympli-mfe/document-forms-framework/components/section-like';
import { TenancyChangeMessageConfigurator } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/config';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { PrecedingDocumentData } from '@sympli-mfe/document-forms-framework/core/prefill/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, getFormikError, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import MessageList from '@sympli/ui-framework/components/form/base-components/message-list';
import Section from '@sympli/ui-framework/components/form/base-components/section';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import { IconRefresh } from '@sympli/ui-framework/icons';

import PartyRoleCapacity from '../party-role-capacity/PartyRoleCapacity';
import DeceasedProprietorArray from './components/deceased-proprietor-array';
import { DeceasedTenancyContextData, DeceasedTenancyContextProvider } from './context/DeceasedTenancyContext';
import { DeceasedTenancyPartyCapacityEnum } from './enums';
import { haveOrganisationPartyInGroup, isDisabledGroup, updateProprietorWithCapacity } from './helpers';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedProprietorModel, DeceasedTenancyDetailModel } from './models';
import { useStyles } from './styles';

interface SectionProps<TParty> {
  config: DeceasedTenancyContextData;
  partyFormConfig: PartyFormConfig<NswNameChange>;
  onTenancyReset?(): void;
  resolveDeceasedPartyWarning?: (
    //
    deceasedPartyItemRenderProps: DocumentArrayItemRenderProps<DeceasedProprietorModel>,
    deceasedGroup: DeceasedProprietorGroupModel
  ) => string | string[] | undefined;
  precedingData?: PrecedingDocumentData<TParty>;
}

const fieldName = modelKey<BaseDeceasedTenancyDetailComponentModel>();
const deceasedTenancySectionName = fieldName('deceasedTenancyDetail');

function DeceasedTenancyDetail<TParty>({
  //
  partyFormConfig,
  config,
  resolveDeceasedPartyWarning
}: Omit<SectionProps<TParty>, 'tenancyChangeLabelConfig' | 'onTenancyReset'>) {
  const fieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(deceasedTenancySectionName);
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { partyBook, deceasedTenancyDetail } = formikProps.values;
  const classes = useStyles();

  const { disabled } = useDocumentContext();

  const handleSelectAllProprietorsChange =
    (itemBinding: string) =>
    (_: React.ChangeEvent<HTMLInputElement>, isSelected: boolean): void => {
      const groupItems: DeceasedProprietorGroupModel = getIn(formikProps.values, itemBinding) ?? [];
      let updatedItems: DeceasedProprietorGroupModel = {
        ...groupItems,
        parties: groupItems.parties.map(p => ({ ...p, isSelected: false })),
        isSelected: isSelected
      };
      formikProps.setFieldValue(itemBinding, updatedItems);
    };

  const renderProprietorGroup = ({ itemBinding, itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorGroupModel>) => {
    const numberOfPartiesInPreceedingGroups = deceasedTenancyDetail.proprietorGroups.slice(0, itemIndex).flatMap(group => group.parties ?? []).length;
    const isJointTenants = deceasedTenancyDetail.tenancyType === TenancyTypeEnum.JointTenants || item.proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse;
    const hasMultipleParties = item.parties.length > 1;
    const isFirstGroup = itemIndex === 0;
    const disabledCheckbox = disabled || isDisabledGroup(deceasedTenancyDetail.proprietorGroups, item.isSelected) || haveOrganisationPartyInGroup(item, partyBook);
    const groupFieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
    const lastDeceasedValidationError = getFormikError(formikProps, groupFieldName('isSelected'));

    const resolvePartyItemWarning =
      typeof resolveDeceasedPartyWarning === 'function'
        ? (args: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
            return resolveDeceasedPartyWarning(args, item);
          }
        : undefined;

    return (
      <SectionLike //
        className={classNames(isFirstGroup && classes.deceasedGroupFirst, classes.marginBottomIfNotLast)}
        key={itemBinding}
        data-testid={itemBinding}
      >
        <div key={itemBinding} className={classes.bottomDivider}>
          {isJointTenants && (
            <>
              {lastDeceasedValidationError && (
                <MessageList //
                  className={classes.errorMessage}
                  dataErrorName={groupFieldName('isSelected')}
                  messages={[{ message: lastDeceasedValidationError, type: 'error' }]}
                />
              )}
              {hasMultipleParties && (
                <Field
                  name={groupFieldName('isSelected')}
                  component={CheckboxField}
                  label="Joint Tenants"
                  format="boolean"
                  className={classes.jointTenantCheckbox}
                  onChange={handleSelectAllProprietorsChange(itemBinding)}
                  disabled={disabledCheckbox}
                  data-testid={'select-all-parties'}
                  validationOverflowDirection="horizontal"
                  ignoreFormikError
                />
              )}
            </>
          )}
          <DeceasedProprietorArray //
            resolveItemWarning={resolvePartyItemWarning}
            itemBinding={itemBinding}
            numberOfPartiesInPreceedingGroups={numberOfPartiesInPreceedingGroups}
            partyFormConfig={partyFormConfig}
            disabledCheckbox={disabledCheckbox}
            config={config}
          />
        </div>
      </SectionLike>
    );
  };

  return (
    <DocumentFieldArray //
      arrayBinding={fieldName('proprietorGroups')}
      renderItem={renderProprietorGroup}
      disabled={disabled}
      itemStyle="none"
      mode="fixed"
    />
  );
}

function SectionDeceasedTenancyDetail<TParty>({
  //
  partyFormConfig,
  config,
  resolveDeceasedPartyWarning,
  onTenancyReset,
  precedingData
}: SectionProps<TParty>): JSX.Element {
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { setFieldValue } = formikProps;
  const {
    deceasedTenancyDetail: { proprietorGroups }
  } = formikProps.values;

  const { palette } = useTheme<Theme>();
  const { disabled } = useDocumentContext();
  const sectionErrorMessage = getFormikError(formikProps, deceasedTenancySectionName);
  const sectionTitle = `Deceased registered ${pluralize('party', proprietorGroups.flatMap(group => group.parties).length)}`;

  const deceasedTenancyDetailFieldName = createModelKeyAppender<DeceasedTenancyDetailModel>(fieldName('deceasedTenancyDetail'));

  const tenancyChangeMessageConfig = TenancyChangeMessageConfigurator.configure(false, precedingData);
  const [isResetTenancyConfirmationOpen, setIsResetTenancyConfirmationOpen] = useState(false);

  const handleOnResetTenancyButtonClick = useCallback(() => {
    setIsResetTenancyConfirmationOpen(true);
  }, []);

  const handleOnResetTenancyDialogClose = useCallback(
    (confirmed?: boolean) => {
      if (confirmed) {
        onTenancyReset?.();
      }
      setIsResetTenancyConfirmationOpen(false);
    },
    [onTenancyReset]
  );

  const renderResetTenancyButton: JSX.Element | undefined = useMemo(() => {
    if (!onTenancyReset) {
      return undefined;
    }

    return (
      <div className={classes.titleSuffix}>
        <ButtonLink
          //
          className={classes.titleSuffixButton}
          onClick={handleOnResetTenancyButtonClick}
          icon={<IconRefresh fill={palette.primary.main} style={{ marginRight: 4 }} />}
          disabled={disabled}
          color="inherit"
        >
          Reset tenancy
        </ButtonLink>
        <ConfirmationDialog //
          title="Reset tenancy"
          open={isResetTenancyConfirmationOpen}
          onClose={handleOnResetTenancyDialogClose}
          showBackIcon
        >
          {tenancyChangeMessageConfig.dialog}
        </ConfirmationDialog>
      </div>
    );
  }, [
    onTenancyReset,
    classes.titleSuffix,
    classes.titleSuffixButton,
    handleOnResetTenancyButtonClick,
    palette.primary.main,
    disabled,
    isResetTenancyConfirmationOpen,
    handleOnResetTenancyDialogClose,
    tenancyChangeMessageConfig.dialog
  ]);

  const handleApplicantCapacityChange = React.useCallback(
    (_, applicantCapacity: DeceasedTenancyPartyCapacityEnum) => {
      if (proprietorGroups.some(pg => pg.isSelected)) {
        const updatedProprietorGroups = proprietorGroups.map(group => {
          if (!group.isSelected) {
            return group;
          }
          return {
            ...group,
            parties: group.parties.map((party): DeceasedProprietorModel => updateProprietorWithCapacity(party, party.isSelected, applicantCapacity))
          };
        });
        setFieldValue(deceasedTenancyDetailFieldName('proprietorGroups'), updatedProprietorGroups);
      }
    },
    [deceasedTenancyDetailFieldName, proprietorGroups, setFieldValue]
  );

  const sectionWarnings: string[] = onTenancyReset ? tenancyChangeMessageConfig?.messages.filter(m => m.type === 'warning').map(m => m.message) : [];

  return (
    <DeceasedTenancyContextProvider config={config}>
      <PartyRoleCapacity
        name={fieldName('applicantCapacity')}
        options={config.partyCapacities}
        title="Complete on behalf of"
        partyRole="Applicant"
        onChange={handleApplicantCapacityChange}
        showDescription={config.showPartyRoleCapacityDescription}
      />

      <Section //
        title={sectionTitle}
        titleSuffix={renderResetTenancyButton}
        className={classNames(proprietorGroups.length && classes.section)}
        error={sectionErrorMessage}
        warning={sectionWarnings}
        info={tenancyChangeMessageConfig.messages.find(m => m.type === 'info')?.message}
        data-name={deceasedTenancySectionName}
        data-testid={deceasedTenancySectionName}
      >
        {!proprietorGroups.length ? (
          <Typography className={classes.noDeceasedGroupsMessage}>There are no deceased registered parties to display. Please ensure that a title is selected.</Typography>
        ) : (
          <DeceasedTenancyDetail //
            partyFormConfig={partyFormConfig}
            config={config}
            resolveDeceasedPartyWarning={resolveDeceasedPartyWarning}
          />
        )}
      </Section>
    </DeceasedTenancyContextProvider>
  );
}

export default React.memo(SectionDeceasedTenancyDetail);
