import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    options: {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    optionItem: {
      margin: 0,
      padding: 0,
      minWidth: 'unset'
    },
    optionText: {
      fontSize: 14,
      textDecoration: 'underline'
    }
  }),
  {
    name: 'ManageWorkspaceBoxContainer'
  }
);
