import * as yup from 'yup';

import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference/validation-schema';

import { TitleReferenceModel } from '../../models';

const allEqual = <T>(array: T[]) => new Set(array).size === 1;

const MISMATCHED_ESTATE_TYPE_MESSAGE = 'All selected titles must have the same estate type';
const MISMATCHED_TENANCY_STRUCTURE_MESSAGE = 'The tenancy of the proprietors does not match - please remove the mismatched titles';

const yupTitleReferences = resolveTitleReferencesValidationSchema<TitleReferenceModel>() //
  .test({
    name: 'Multiple selected title references: Matching tenancy type and estate type test',
    message: 'Invalid title reference selection', // This message should never show
    test(this: yup.TestContext, titleReferences: TitleReferenceModel[]) {
      const selectedTitleReferences = titleReferences.filter(({ isSelected }) => isSelected);
      if (selectedTitleReferences.length <= 1) {
        //
        return true;
      }
      if (!allEqual(selectedTitleReferences.map(({ estateType }) => estateType))) {
        return this.createError({ message: MISMATCHED_ESTATE_TYPE_MESSAGE });
      }

      /**
       * Before checking fine-grained group structure, we check that the number of groups match. If the number of groups is not consistent between titles,
       * we know that the group structure does not match.
       */
      const selectedTitleReferencesRelinquishingProprietorGroups = selectedTitleReferences.map(({ transferorDetails }) => transferorDetails.relinquishingProprietorGroups);
      if (!allEqual(selectedTitleReferencesRelinquishingProprietorGroups.map(titleReferenceGroups => titleReferenceGroups.length))) {
        return this.createError({ message: MISMATCHED_TENANCY_STRUCTURE_MESSAGE });
      }
      const sharedNumberOfGroups = selectedTitleReferencesRelinquishingProprietorGroups[0].length;

      /**
       * We compare "group structure" by ensuring that groups of one title have numbers of tenants that match those of another title.
       * (Note: At this stage, we have already determined that each title has the same number of groups.)
       *
       * e.g.
       *
       *    Title A: Group A-1: Tenant A-1-I
       *                        Tenant A-1-II
       *             Group A-2: Tenant A-2-I
       *
       *    Title B: Group B-1: Tenant B-1-I
       *             Group B-2: Tenant B-2-I
       *                        Tenant B-2-II
       *
       *    Title C: Group C-1: Tenant C-1-I
       *                        Tenant C-1-II
       *                        Tenant C-1-III
       *             Group C-2: Tenant C-2-I
       *
       * Both titles A and B have same group structure, because both have two groups: one with 1 tenant, and the other with 2 tenants
       * (we don't care about order of groups, tenant names, etc.)
       * Title C does not match the structure of title A or B, because despite the fact it has a 1-tenant group like A and B,
       * its other group has 3 tenants, not 2 like A and B.
       */
      const selectedTitleReferencesOrderedGroupSizes = selectedTitleReferencesRelinquishingProprietorGroups.map(titleReferenceGroups =>
        titleReferenceGroups.map(group => group.parties.length).sort()
      );
      for (let index = 0; index < sharedNumberOfGroups; index++) {
        // Note: We have already ensured that arrays will be of the same length (so index will not be out of bounds),
        //       and we have ordered by size (so we will compare size smallest group, then next smallest, etc.)
        if (!allEqual(selectedTitleReferencesOrderedGroupSizes.map(titleReferenceOrderedGroupSizes => titleReferenceOrderedGroupSizes[index]))) {
          return this.createError({ message: MISMATCHED_TENANCY_STRUCTURE_MESSAGE });
        }
      }
      return true;
    }
  });
export default yupTitleReferences;
