import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import SupportingDocumentUploader from '@sympli-mfe/document-forms-framework/components/supporting-document-uploader-field';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { getCharacterCountHelperText, modelKey, resolvePlaceholder } from '@sympli-mfe/document-forms-framework/utils';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import StandardTermsDocumentReferenceArray from '../../components/standard-terms-document-reference-array';
import { REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS, REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT } from '../../isRequiredChecks';
import { MortgageTermsAndConditionsModel } from '../../models';
import { useStyles } from './styles';
import { ADDITIONAL_TERMS_AND_CONDITIONS_MAX_CHARACTER_COUNT } from './validationSchema';

// this file was automatically generated from sections/SectionNonArrayComponent.tsx.mustache
interface Props {
  name: string;
}

const fieldName = modelKey<MortgageTermsAndConditionsModel>();

function TermsAndConditions({ name }: Props): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<MortgageTermsAndConditionsModel>();
  const fieldClasses = useMemo(
    () => ({
      formControl: classes.gridAreaInput,
      formControlValidation: classNames(classes.formControlValidation, classes.gridAreaHelper)
    }),
    [classes]
  );

  const { disabled, filesApi } = useDocumentContext();

  const isAdditionalTermsAndConditionsRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS(values);
  const isAttachmentRequired = REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT(values);

  const additionalTermsAndConditionsPlaceholder = resolvePlaceholder(isAdditionalTermsAndConditionsRequired);

  const additionalTermsAndConditionsCharacterCount = (values.additionalTermsAndConditions ?? '').trim().length;
  const additionalTermsAndConditionsCharacterCountText = getCharacterCountHelperText(
    //
    additionalTermsAndConditionsCharacterCount,
    ADDITIONAL_TERMS_AND_CONDITIONS_MAX_CHARACTER_COUNT
  );

  return (
    <div data-name={name} className={classes.root}>
      <StandardTermsDocumentReferenceArray name={fieldName('standardTermsDocumentReferences')} maxItems={20} minItems={1} />
      <FormGroup //
        title="Additional terms and conditions"
        fieldAreaDirection="column"
      >
        {({ titleId }) => (
          <Field //
            aria-labelledby={titleId}
            name={fieldName('additionalTermsAndConditions')}
            component={InputField}
            disabled={disabled}
            classes={fieldClasses}
            className={classNames(classes.inputFormControlGrid, classes.noBottomMargin)}
            fullWidth
            multiline
            minRows={2}
            placeholder={additionalTermsAndConditionsPlaceholder}
            helperText={additionalTermsAndConditionsCharacterCountText}
          />
        )}
      </FormGroup>
      <SupportingDocumentUploader
        name={fieldName('termsAndConditionsAttachment')}
        titleLabel="Upload supporting document"
        disabled={disabled}
        required={isAttachmentRequired}
        onUpload={filesApi.upload}
        onDownload={filesApi.download}
      />
    </div>
  );
}

const MemoizedTermsAndConditions = React.memo(TermsAndConditions);

export default ({ name }: Props) => {
  return (
    <Subform //
      subformBindingPath={name}
      component={MemoizedTermsAndConditions}
      name={name}
    />
  );
};
