import { createSelector } from 'reselect';

import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { isDstObservedInNSW } from 'src/@core/utils/timezone';
import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { SupportedJurisdictionApiResponse } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { TitleVerificationStatusEnum, VerifiedTitleResponse } from 'src/containers/shared/verify-property-section/models';
import { TimeFormatTypeEnum, timeStringConvert } from 'src/containers/workspace/financial/settlement-date/helper';
import { initializeTimeOptions } from 'src/utils/timeHelper';
import { RepresentationSubscriberTypeEnum } from '../../../../models';

export interface JurisdictionSelectorParamModel {
  groupId: string;
  assignableGroupOptions: GroupOptionModel[];
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  supportedJurisdictions: SupportedJurisdictionApiResponse;
}

const getGroupId = (data: JurisdictionSelectorParamModel) => data.groupId;
const getAssignableGroupOption = (data: JurisdictionSelectorParamModel) => data.assignableGroupOptions;

const getWorkspaceTypeId = (data: JurisdictionSelectorParamModel) => data.workspaceTypeId;
const getSupportedJurisdictions = (data: JurisdictionSelectorParamModel) => data.supportedJurisdictions;

const DEFAULT_JURISDICTIONS_OPTIONS: LookupEnumModel<HttpTypes.JurisdictionsEnum, string>[] = [];
export const jurisdictionSelector = createSelector<
  JurisdictionSelectorParamModel,
  string,
  GroupOptionModel[],
  HttpTypes.WorkspaceTypeEnum,
  SupportedJurisdictionApiResponse,
  LookupEnumModel<HttpTypes.JurisdictionsEnum>[]
>(
  getGroupId, //
  getAssignableGroupOption,
  getWorkspaceTypeId,
  getSupportedJurisdictions,
  (
    groupId: string, //
    assignableGroupOptions: GroupOptionModel[] = [],
    workspaceTypeId,
    supportedJurisdictions: SupportedJurisdictionApiResponse = []
  ) => {
    if (groupId === '') {
      return DEFAULT_JURISDICTIONS_OPTIONS;
    }
    const selectedGroup = assignableGroupOptions.find(option => option.id === groupId);
    const currentWsTypeSupportedJurisdiction = supportedJurisdictions.find(item => item.workspaceType === workspaceTypeId);
    if (!(selectedGroup && currentWsTypeSupportedJurisdiction)) {
      return DEFAULT_JURISDICTIONS_OPTIONS;
    }
    const supportedJurisdictionSet = new Set(currentWsTypeSupportedJurisdiction.supportedJurisdictions);
    const result = selectedGroup.jurisdictions.filter(item => supportedJurisdictionSet.has(item.id));
    return result;
  }
);
const resolveTimeByNSWDaylightTimeSaving = (
  //
  date: Date | null,
  jurisdictionId: HttpTypes.JurisdictionsEnum,
  dstTime: number,
  stdTime: number
) => {
  if (!date) {
    return dstTime;
  }
  const isDST = isDstObservedInNSW(date);
  const time = isDST ? dstTime : stdTime;
  return time;
};
interface SettlementTimeOptionsSelector {
  jurisdictionId: HttpTypes.JurisdictionsEnum;
  format?: TimeFormatTypeEnum;
  settleDate?: Date | string | null;
}
const getJurisdictionId = ({ jurisdictionId }) => jurisdictionId;
const getFormat = ({ format = TimeFormatTypeEnum._24H }) => format;
const getSettleDate = ({ settleDate }: SettlementTimeOptionsSelector): Date | null => {
  if (!settleDate) return null;
  if (typeof settleDate === 'string') return new Date(settleDate);
  return settleDate;
};
export const settlementTimeOptionsSelector = createSelector<
  //
  SettlementTimeOptionsSelector,
  HttpTypes.JurisdictionsEnum,
  TimeFormatTypeEnum,
  Date | null,
  LookupEnumModel<string>[]
>(
  //
  getJurisdictionId,
  getFormat,
  getSettleDate,
  (jurisdictionId: HttpTypes.JurisdictionsEnum, format: TimeFormatTypeEnum, settleDate: Date | null) => {
    let options: LookupEnumModel<string>[];
    switch (jurisdictionId) {
      case HttpTypes.JurisdictionsEnum.QLD:
        options = initializeTimeOptions(9 * 60 + 30, 16 * 60);
        break;
      case HttpTypes.JurisdictionsEnum.WA:
        const end = resolveTimeByNSWDaylightTimeSaving(settleDate, HttpTypes.JurisdictionsEnum.WA, 16 * 60, 15 * 60);
        options = initializeTimeOptions(8 * 60 + 30, end);
        break;
      case HttpTypes.JurisdictionsEnum.VIC:
        options = initializeTimeOptions(9 * 60 + 30, 17 * 60);
        break;
      case HttpTypes.JurisdictionsEnum.NSW:
        options = initializeTimeOptions(9 * 60 + 30, 17 * 60);
        break;
      default:
        options = initializeTimeOptions();
        break;
    }
    return format === TimeFormatTypeEnum._24H ? options : options.map(({ id, name }) => ({ id, name: timeStringConvert(name) }));
  }
);

export function getInvitationOrderByRole(): HttpTypes.WorkspaceRoleEnum[] {
  return [
    //
    HttpTypes.WorkspaceRoleEnum.Vendor,
    HttpTypes.WorkspaceRoleEnum.Purchaser,
    HttpTypes.WorkspaceRoleEnum.DischargingMortgagee,
    HttpTypes.WorkspaceRoleEnum.IncomingMortgagee,
    HttpTypes.WorkspaceRoleEnum.DischargingCaveator,
    HttpTypes.WorkspaceRoleEnum.IncomingCaveator,
    HttpTypes.WorkspaceRoleEnum.ToDealWithAnInterest,
    HttpTypes.WorkspaceRoleEnum.SourceFunder,
    HttpTypes.WorkspaceRoleEnum.Beneficiary
  ];
}

export function isLinkedWorkspaceRequired(jurisdictionId: HttpTypes.JurisdictionsEnum | null, hasPaperTitle: boolean = false) {
  return jurisdictionId === HttpTypes.JurisdictionsEnum.VIC && hasPaperTitle;
}

const TITLE_VERIFICATION_ERROR_STATUES: TitleVerificationStatusEnum[] = [
  TitleVerificationStatusEnum.ErrorControllerNotRegistered,
  TitleVerificationStatusEnum.ErrorInconsistentControllers,
  TitleVerificationStatusEnum.ErrorNonElectronicLodgement,
  TitleVerificationStatusEnum.ErrorRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotCurrentSubscriber,
  TitleVerificationStatusEnum.ErrorPaperTitle
];

export function isTitleVerificationSuccessful(titles?: VerifiedTitleResponse[]) {
  if (titles && titles.length) {
    return titles.every(t => !TITLE_VERIFICATION_ERROR_STATUES.some(e => e === t.status));
  }
  return false;
}

export const getRepresentationSubscriberType = (
  invitationSubscriberSearch: boolean,
  subscriberOrganisationType: HttpTypes.SubscriberOrganisationTypeEnum
): RepresentationSubscriberTypeEnum => {
  if (!invitationSubscriberSearch) {
    return RepresentationSubscriberTypeEnum.Other;
  }

  switch (subscriberOrganisationType) {
    case HttpTypes.SubscriberOrganisationTypeEnum.ConveyancingPractice:
    case HttpTypes.SubscriberOrganisationTypeEnum.LawPractice:
      return RepresentationSubscriberTypeEnum.Represented;
    case HttpTypes.SubscriberOrganisationTypeEnum.FinancialInstitution:
      return RepresentationSubscriberTypeEnum.SelfRepresented;
    default:
      return RepresentationSubscriberTypeEnum.Other;
  }
};

export const TRANSACTION_TYPE_OPTIONS: LookupEnumModel<HttpTypes.WorkspaceTransactionTypeEnum>[] = [
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Refinance,
    name: 'Refinance'
  },
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Transfer,
    name: 'Transfer'
  },
  {
    id: HttpTypes.WorkspaceTransactionTypeEnum.Other,
    name: 'Other'
  }
];

export const resolveTransactionType = (workspaceRole: HttpTypes.WorkspaceRoleEnum): HttpTypes.WorkspaceTransactionTypeEnum | null => {
  switch (workspaceRole) {
    case HttpTypes.WorkspaceRoleEnum.IncomingMortgagee:
    case HttpTypes.WorkspaceRoleEnum.DischargingMortgagee:
      return HttpTypes.WorkspaceTransactionTypeEnum.Refinance;
    case HttpTypes.WorkspaceRoleEnum.Purchaser:
    case HttpTypes.WorkspaceRoleEnum.Vendor:
      return HttpTypes.WorkspaceTransactionTypeEnum.Transfer;
    default:
      return null;
  }
};
