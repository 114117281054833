import * as React from 'react';

import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';

import { useProfile } from 'src/@core/store/reducers/profile';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/selectors';
import { orderedAvailableParticipantsSelector } from 'src/containers/workspace/shared/detail/selectors';
import { useSafeDispatch } from 'src/hooks';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import { actionCreateNewConversation } from '../../actions';
import NewMessage from './NewMessage';

interface Props {
  workspaceId: string;
  participantId: string;
  onConversationSelect: (selectedConversationId?: string) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function NewMessageContainer({ workspaceId, participantId, onConversationSelect, onBackClick }: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const { name }: HttpTypes.UserProfileModel = useProfile().data!;
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);
  const participants: HttpTypes.WorkspaceParticipant[] = orderedAvailableParticipantsSelector({
    workspaceParticipants
  });

  const currentParticipant: HttpTypes.WorkspaceParticipant | undefined = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants
  });
  const currentParticipantSubscriberName = currentParticipant?.name;

  const handleOnConversationCreated = React.useCallback(
    (args: {
      //
      conversationId: string;
      recipientParticipantIds: string[];
      subject: string;
      message: string;
    }) => {
      /// if user was withdrawn we don't do anything
      if (!currentParticipantSubscriberName) {
        return;
      }
      dispatch(
        actionCreateNewConversation({
          //
          workspaceId,
          participantId,
          ...args,
          sentBySubscriberName: currentParticipantSubscriberName,

          sentByUserName: name
        })
      );
      onConversationSelect(args.conversationId);
    },
    [dispatch, onConversationSelect, workspaceId, participantId, name, currentParticipantSubscriberName]
  );

  return (
    <NewMessage //
      workspaceId={workspaceId}
      participantId={participantId}
      participants={participants}
      onConversationCreated={handleOnConversationCreated}
      onBackClick={onBackClick}
    />
  );
}

export default React.memo(NewMessageContainer);
