import * as React from 'react';

import { FormikProps } from 'formik';
import Grid from '@mui/material/Grid';

import { HttpTypes } from '@sympli/api-gateway/types';
import FormGroupFooter from '@sympli/ui-framework/components/form/layout/form-group-footer';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import AccountNumberField from 'src/components/account-number';
import BsbNumberField from 'src/components/bsb-NumberInput';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { ConditionalDistributionModel } from '../../../../../components/direction-record/models';
import { DischargeDirectionsFormModel } from '../../../models';
import { EditBankDetailsProps } from './EditBankDetails';
import { CommonProps } from './models';
import { useStyles } from './styles';

interface AcceptSurplusEditBankDetailsProps {
  financialAccounts?: EditBankDetailsProps['financialAccounts'];
  itemFieldName: EditBankDetailsProps['itemFieldName'];
  formikProps: EditBankDetailsProps['formikProps'];
  isLoading: EditBankDetailsProps['isLoading'];
  disableSave: boolean;
  onSave: CommonProps['onUpdate'];
}

function AcceptSurplusEditBankDetails(props: AcceptSurplusEditBankDetailsProps) {
  const {
    //
    itemFieldName,
    formikProps,
    isLoading,
    disableSave,
    onSave
  } = props;

  const classes = useStyles();

  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);

  const bankDetailsFieldName = createModelKeyAppender<HttpTypes.BankDetailsModel>(fieldName('bankDetails'));

  const handleOnClick = async (formikProps: FormikProps<DischargeDirectionsFormModel>, itemFieldName: string) => {
    await onSave(itemFieldName, formikProps);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field //
            className={classes.fullWidth}
            label="Payee name"
            component={InputField}
            name={bankDetailsFieldName('accountName')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field //
            className={classes.fullWidth}
            label="Reference (optional)"
            component={InputField}
            name={bankDetailsFieldName('reference')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <BsbNumberField name={bankDetailsFieldName('bsb')} label="BSB" className={classes.fullWidth} />
        </Grid>
        <Grid item xs={4}>
          <AccountNumberField name={bankDetailsFieldName('accountNumber')} label="Account number" className={classes.fullWidth} />
        </Grid>
      </Grid>
      <FlexLayout fullWidth>
        <FormGroupFooter
          className={classes.formGroupFooter}
          title="If there is a surplus in this workspace it will be paid into this account"
          formTip="The Surplus funds will be calculated after all parties in the workspace have approved their Financial Settlement Statement. This amount will populate automatically based on the difference between the source funds and directions"
        />
        <SympliButton //
          className={classes.saveButton}
          color="primary"
          variant="contained"
          onClick={_ => handleOnClick(formikProps, itemFieldName)}
          isLoading={isLoading}
          disabled={disableSave || isLoading}
        >
          Update
        </SympliButton>
      </FlexLayout>
    </React.Fragment>
  );
}

export default AcceptSurplusEditBankDetails;
