import { HttpTypes } from '@sympli/api-gateway/types';

import DeleteModeButtons from 'src/containers/shared/document-list/components/delete-mode-buttons';
import TitlesAndAddressesBase, { TitlesAndAddressesBaseProps } from '../titles-and-addresses-base/TitlesAndAddressesBase';

interface Props extends TitlesAndAddressesBaseProps {
  onCancel(): void;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
}

function TitlesAndAddressesDelete(props: Props) {
  const { onCancel } = props;

  return (
    <>
      <TitlesAndAddressesBase {...props} dataTestId="title-list-delete" />
      <DeleteModeButtons handleOnCancel={onCancel} />
    </>
  );
}

export default TitlesAndAddressesDelete;
