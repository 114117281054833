import React from 'react';

import { useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import SectionGenericTenancy from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG, PARTY_FORM_WITH_NAME_CHANGE_CONFIG } from '../../config';
import { generateMortgagors, getPartyAdder, isMortgagorsResetTenancyAvailable } from '../../helper';
import { Mortgage2_21_3Model } from '../../models';

interface Props {
  name: string;
  focusRef?: React.RefObject<ButtonBaseActions>;
}

const INCLUDE_CUSTOM_PARTIES = true;

function SectionMortgagors({ name, focusRef }: Props): JSX.Element {
  const { values, setValues } = useFormikContext<Mortgage2_21_3Model>();
  const { participants } = useDocumentContext();

  React.useEffect(() => {
    const enableResetTenancyButton = isMortgagorsResetTenancyAvailable({
      partyBook: values.partyBook,
      mortgagors: values.mortgagors,
      mortgagorsOnTitle: values.mortgagorsOnTitle,
      precedingData: values.precedingData
    });

    if (values.precedingData.manualOverride !== enableResetTenancyButton) {
      setValues(values => ({
        ...values,
        precedingData: { ...values.precedingData, manualOverride: enableResetTenancyButton }
      }));
    }
  }, [values.partyBook, values.mortgagors, values.mortgagorsOnTitle, setValues, values.precedingData]);

  const handleOnTenancyReset = () => {
    setValues(values => {
      return {
        ...generateMortgagors(values, getPartyAdder(participants), !INCLUDE_CUSTOM_PARTIES)
      };
    });
  };

  const mortgageePartyIds = values.mortgagees.receivingProprietorGroups.flatMap(pg => pg.parties.map(p => p.partyBookId).filter(id => Boolean(id))) as string[];

  return (
    <SectionGenericTenancy //
      name={name}
      focusRef={focusRef}
      partyFormConfig={PARTY_FORM_WITH_NAME_CHANGE_CONFIG}
      componentLabels={NSW_MORTGAGOR_TENANCY_COMPONENT_LABEL_CONFIG}
      // disable adding additional joint group
      allowNewGroup={false}
      // enable adding new parties
      tenancyPartiesMode="dynamic"
      // enable tenancy reset
      onTenancyReset={values.precedingData.manualOverride ? handleOnTenancyReset : undefined}
      disablePartyEditConfig={disablePartyFieldEditConfig(true)}
      // disable mortgagees from selection
      partyIdsToFilter={mortgageePartyIds}
      precedingData={values.precedingData}
    />
  );
}

export default React.memo(SectionMortgagors);
