import * as yup from 'yup';

import { createPartyBookItemTestForPartyBookId, PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { GenericTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic';
import {
  resolveProprietorGroupsValidationSchema,
  YUP_GENERIC_TENANCY_ADDRESS_BOOK_ID_REQUIRED,
  YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED
} from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/generic/validationSchema';
import { memoizeSchemaWithContext, validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import { disablePartyFieldEditConfig } from 'src/containers/documents/scaffolded-form/shared/components/tenancy/disableTenancyFieldConfig';
import { NSW_CAVEAT_PARTY_FORM_CONFIG as PARTY_FORM_CONFIG } from '../../config';
import { CaveatModel_2_21_2 } from '../../models';

// run party data validation only if party field edit is not disabled
const shouldValidatePartyData = (party: PartyModel) => !disablePartyFieldEditConfig().disabled(party);

interface ProprietorGroupsContext {
  partyBook: CaveatModel_2_21_2['partyBook'];
  proprietorGroups: CaveatModel_2_21_2['proprietors']['proprietorGroups'];
}

const yupProprietorGroups: yup.MixedSchema<CaveatModel_2_21_2['proprietors']['proprietorGroups'], ProprietorGroupsContext> = memoizeSchemaWithContext(
  resolveProprietorGroupsValidationSchema<ProprietorGroupsContext>({
    yupPartyBookId: YUP_GENERIC_TENANCY_PARTY_BOOK_ID_REQUIRED.test(
      createPartyBookItemTestForPartyBookId(
        //
        PARTY_FORM_CONFIG,
        {
          shouldValidatePartyData
        }
      )
    ),
    yupAddressBookId: YUP_GENERIC_TENANCY_ADDRESS_BOOK_ID_REQUIRED,
    // turn off unnecessary validations
    yupPartyCapacity: yup.mixed()
  }).testContextualRule({
    message: 'At least 1 proprietor must be affected.',
    uniqueTestName: 'proprietorsAffected',
    requirement: (parent: never, ctx: ProprietorGroupsContext): boolean => {
      return ctx.proprietorGroups.some(({ parties }) => parties.some(({ isSelected }) => isSelected));
    }
  }),
  (parentContext: CaveatModel_2_21_2): ProprietorGroupsContext => {
    return {
      partyBook: parentContext.partyBook, // this is required here for party validation
      proprietorGroups: parentContext.proprietors.proprietorGroups
    };
  }
);

const yupProprietorsEditable: yup.MixedSchema<GenericTenancyDetailModel, CaveatModel_2_21_2> = validateWhenVisible2<GenericTenancyDetailModel, CaveatModel_2_21_2>({
  //
  visibilityCheck: (parent: CaveatModel_2_21_2, _) => parent.titleReferences.filter(tr => tr.isSelected).length >= 1,
  isObjectOrArray: true,
  validationWhenVisible: yup
    .object<GenericTenancyDetailModel>()
    .partial({
      proprietorGroups: yupProprietorGroups
    })
    .log()
    .defined()
});

export default yupProprietorsEditable;
