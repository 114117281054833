import { HttpTypes } from '@sympli/api-gateway/types';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';

enum StaticOptionEnum {
  ShowMoreResults,
  ShowNoResults
}

export interface ResultItem {
  id: string;
  settlementDate: string;
  matter: string;
  titleReferences: string;
  address: string;
  subscriberId: string;
  workspaceRoleId: HttpTypes.WorkspaceRoleEnum;
  workspaceRoleIds: HttpTypes.WorkspaceRoleEnum[];
  searchHighlights: {
    [key in keyof Pick<ResultItem, 'matter' | 'sympliId' | 'clientNames' | 'titles' | 'titleReferences' | 'titleAddresses'>]?: string[];
  };
  participantId: string;
  isReadOnly: boolean;
  sympliId: string;
  workspaceType: HttpTypes.WorkspaceTypeEnum;
  workspaceId: string;
  jurisdiction: string;
  titles?: string[];
  titleAddresses?: string[];
  clientNames?: string[];
}

export interface ResultItemModel extends ResultItem {
  staticOptionType?: StaticOptionEnum;
  propertyAddress?: string;
}

export const defaultResultItem: ResultItem = {
  id: '',
  settlementDate: '',
  matter: '',
  titleReferences: '',
  address: '',
  subscriberId: '',
  participantId: '',
  workspaceRoleId: HttpTypes.WorkspaceRoleEnum.Applicant,
  // replace the original -1 which caused the ts error,
  // the purpose of this just give a default value and will be replaced once response returned from backend
  workspaceRoleIds: [HttpTypes.WorkspaceRoleEnum.Applicant],
  workspaceType: 0,
  sympliId: '',
  searchHighlights: {
    matter: ['']
  },
  workspaceId: '',
  isReadOnly: false,
  jurisdiction: '',
  clientNames: [],
  titles: [],
  titleAddresses: []
};

export interface SearchBoxUpdatedApiRequest {
  searchTerm?: string;
  searchCategoryId?: SearchCategoryEnum;
}

export interface WorkspaceSearchApiRequest {
  searchTerm?: string;
  filterSearchTerm?: string;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  tab?: SearchDashboardPageTabsEnum;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum;
  searchPredefineId?: SearchPredefineEnum;
  searchCategoryId?: SearchCategoryEnum;
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: SortDirectionEnum;
}

export enum SearchDashboardPageTabsEnum {
  Workspaces = 'workspaces',
  Archived = 'archived'
}

export enum SearchCategoryEnum {
  All = 0,
  SympliId = 1,
  Matter = 2,
  Title = 3,
  Address = 4,
  ClientName = 5
}

export enum SearchPredefineEnum {
  Default = 0,
  Active = 1,
  Archived = 2
}
