import { call, put, takeLatest } from 'typed-redux-saga';

import http from 'src/utils/http';
import { OffPlatformTasksApiResponse } from '../off-platform-tasks/models';
import { actionFetchOffPlatformTasks, actionUpdateOffPlatformTaskStatus, OffPlatformTasksApiRequest, UpdateOffPlatformTaskStatusApiRequest } from './actions';

const fetchOffPlatformTasks = (args: OffPlatformTasksApiRequest) => {
  const svc = `/workspaces/${encodeURIComponent(args.workspaceId)}/off-platform-tasks`;
  return http.get<OffPlatformTasksApiResponse>(svc);
};

const updateOffPlatformTaskStatus = (args: UpdateOffPlatformTaskStatusApiRequest) => {
  const svc = `/workspaces/${encodeURIComponent(args.workspaceId)}/participants/${encodeURIComponent(args.participantId)}/off-platform-tasks/${encodeURIComponent(
    args.offPlatformTaskId
  )}`;
  return http.put<boolean>(svc, { offPlatformTaskStatusId: args.newStatus });
};

function* sagaFetchOffPlatformTasks(action: ReturnType<typeof actionFetchOffPlatformTasks.request>) {
  try {
    const { items } = yield* call(fetchOffPlatformTasks, action.payload);
    yield put(actionFetchOffPlatformTasks.success({ items }));
  } catch (error) {
    yield put(actionFetchOffPlatformTasks.failure({ error }));
  }
}

function* sagaUpdateOffPlatformTaskStatus(action: ReturnType<typeof actionUpdateOffPlatformTaskStatus.request>) {
  try {
    yield* call(updateOffPlatformTaskStatus, action.payload);
    yield put(actionUpdateOffPlatformTaskStatus.success());
  } catch (error) {
    yield put(actionUpdateOffPlatformTaskStatus.failure({ error }));
  }
}

export default [takeLatest(actionFetchOffPlatformTasks.request, sagaFetchOffPlatformTasks), takeLatest(actionUpdateOffPlatformTaskStatus.request, sagaUpdateOffPlatformTaskStatus)];
