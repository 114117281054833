import * as React from 'react';

import { useFormikContext } from 'formik';

import RelinquishingTenancySection from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing';
import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';

import { generateTransferors, isTransferorsResetTenancyAvailable } from '../../helpers';
import { BaseTransfer2_24_1Model, TransferRootFormContext } from '../../models';

interface Props {
  name: string;
}

function Transferors<TPartLandAffected extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({ name }: Props) {
  const { values, setValues } = useFormikContext<BaseTransfer2_24_1Model<TPartLandAffected, TDocumentPartyJustification, TNameChange>>();
  const { relinquishingTenancyConfig, shareTransferredTypeOptions, partyFormConfig, nameChangeConversion, partyConversion } =
    useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  const { partySelectionMode, groupSelectionMode, ...config } = relinquishingTenancyConfig;
  React.useEffect(() => {
    const enableResetTenancyButton = isTransferorsResetTenancyAvailable({
      partyBook: values.partyBook,
      transferors: values.transferors,
      transferorsOnTitle: values.transferorsOnTitle
    });

    if (values.precedingData.manualOverride !== enableResetTenancyButton) {
      setValues(values => ({
        ...values,
        precedingData: { ...values.precedingData, manualOverride: enableResetTenancyButton }
      }));
    }
  }, [values.partyBook, values.transferors, values.transferorsOnTitle, setValues, values.precedingData.manualOverride]);

  const handleOnTenancyReset = React.useCallback(() => {
    setValues(values => {
      return {
        ...values,
        ...generateTransferors(values, partyFormConfig, nameChangeConversion, partyConversion)
      };
    });
  }, [nameChangeConversion, partyConversion, partyFormConfig, setValues]);

  return (
    <RelinquishingTenancySection //
      name={name}
      shareTransferredTypeOptions={shareTransferredTypeOptions}
      onTenancyReset={values.precedingData.manualOverride ? handleOnTenancyReset : undefined}
      risTenancyType={values.transferors.tenancyType}
      // Need to remove party and group selection if default tenancyType is SoleProprietor and has not been changed
      groupSelectionMode={values.transferors.tenancyType === TenancyTypeEnum.SoleProprietor && !values.precedingData.manualOverride ? 'none' : groupSelectionMode}
      partySelectionMode={values.transferors.tenancyType === TenancyTypeEnum.SoleProprietor && !values.precedingData.manualOverride ? 'none' : partySelectionMode}
      {...config}
    />
  );
}

export default React.memo(Transferors);
