import { HttpTypes } from '@sympli/api-gateway/types';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { GroupedOptions } from 'src/components/multiple-select-checkbox/MultipleSelectCheckbox';
import { AllocatedUser, TitleReferenceEntityModel } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { WorkspaceFilterQueryParamsModel } from '../financial-workspaces/models';

export interface WorkspaceDetailBoxDataModel {
  workspaceId: string;
  currentParticipantRoleId?: HttpTypes.WorkspaceRoleEnum;
  currentParticipantId?: string;
  partyNames?: Array<string>;
  matter?: string;
  titleReferences: Array<TitleReferenceEntityModel>;
  settlementDateTime?: HttpTypes.WorkspaceDateTimeModel;
  sympliId: string;
  propertyAddress?: string;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  createdDate?: HttpTypes.WorkspaceDateTimeModel;
  lastUpdatedDate?: HttpTypes.WorkspaceDateTimeModel;
  registered?: string;

  workspaceStatus?: HttpTypes.WorkspaceStatusEnum;
  settlementStatusDetail?: HttpTypes.SettlementStatusDetail;
  lodgementCaseStatus?: HttpTypes.LodgementCaseStatusEnum;
  workspaceType?: HttpTypes.WorkspaceTypeEnum;
  isLocked?: boolean;
  expectedLodgementDate?: HttpTypes.WorkspaceDateTimeModel;
  isIntegrated?: boolean;
  requiresStampDuty?: boolean;
  participants: HttpTypes.WorkspaceParticipant[];
  userLastAccessedDate?: HttpTypes.WorkspaceDateTimeModel;
}

export interface CurrentPartiesModel {
  names: Array<string>;
  workspaceRole: LookupEnumModel<HttpTypes.WorkspaceRoleEnum>;
  participantId: string;
  documentNames: Array<string>;
  allocatedUser?: AllocatedUser;
  groupId: string;
}

export type FetchDashboardFeedRequestModel = WorkspacesSummaryApiRequest;
export type FetchDashboardFeedResponseModel = HttpTypes.PaginatedItemsModel<WorkspaceSummaryModel>;

export interface WorkspaceFeedDetailDataModel extends WorkspaceDetailBoxDataModel {
  participants: HttpTypes.WorkspaceParticipant[];
}

export interface WorkspacesSummaryApiResponse extends HttpTypes.PaginatedItemsModel<WorkspaceSummaryModel> {}

export interface WorkspaceSummaryModel {
  id: string;
  sympliId: string;
  isLocked: boolean;
  jurisdiction: LookupEnumModel<HttpTypes.JurisdictionsEnum>;
  lodgementCaseStatus: LookupEnumModel<HttpTypes.LodgementCaseStatusEnum>;
  workspaceStatus: LookupEnumModel<HttpTypes.WorkspaceStatusEnum>;
  workspaceType: LookupEnumModel<HttpTypes.WorkspaceTypeEnum>;
  settlementDateTime?: HttpTypes.WorkspaceDateTimeModel;
  expectedLodgementDate?: HttpTypes.WorkspaceDateTimeModel;
  // TODO xinxin to add this property to dashboard items so we can remove this optional flag
  // numberOfTasks?: number; // number of tasks for all participants in the workspace
  createdDate: HttpTypes.WorkspaceDateTimeModel;
  lastUpdatedDate: HttpTypes.WorkspaceDateTimeModel;

  // different from the basic endpoint
  titleReferences: Array<TitleReferenceEntityModel>;
  currentParties: CurrentPartiesModel;
  matter: string;
  participants: HttpTypes.WorkspaceParticipant[]; // all
  propertyAddress?: string;
  registered?: string;
  isIntegrated?: boolean;
  currentSubscriberParticipants?: HttpTypes.WorkspaceParticipant[];
  messagesCount?: number;
  requiresStampDuty?: boolean;
  isRollover?: boolean;
  userLastAccessedDate?: HttpTypes.WorkspaceDateTimeModel;
}

export interface TableFiltersModel {
  pageSize?: number; // defines number of rows shown per page. Default set to DEFAULT_PAGE_SIZE
  pageNumber?: number; // current page number. Default set to 1
  sortBy?: string;
  sortOrder?: SortDirectionEnum;
}
export type WorkspacesSummaryApiRequest = TableFiltersModel & WorkspaceFilterQueryParamsModel;

export interface UpdateDashboardAllocatedUserPayload {
  assignedParticipants: { [participantId: string]: boolean };
  allocatedUser: AllocatedUser;
  workspaceId: string;
}

// generic state shared across other workspace dashboards
export interface WorkspaceDashboardFeedDetailState {
  data?: WorkspaceSummaryModel | null;
  status: ApiStatus;
  error?: string;
}

// generic initialState shared across other workspace dashboards
export const dashboardFeedDetailInitialState: WorkspaceDashboardFeedDetailState = {
  data: undefined,
  status: 'idle',
  error: undefined
};

// generic state shared across other workspace dashboards
export interface WorkspaceDashboardFeedState {
  selectedRowIndex: number;
  selectedWorkspaceId?: string;

  items: Array<WorkspaceSummaryModel>;
  error?: string;
  status: ApiStatus;
  totalCount: number;

  query: WorkspacesSummaryApiRequest;
}

// generic initialState shared across other workspace dashboards
export const dashboardFeedInitialState: WorkspaceDashboardFeedState = {
  selectedRowIndex: 0,

  items: [],
  status: 'idle',
  error: undefined,

  totalCount: 0,

  query: {
    settlingPeriod: undefined,
    workspaceStatusIds: undefined,
    jurisdictionId: undefined,
    workspaceRoleIds: undefined,
    groupId: undefined,
    userId: undefined,
    hasUnreadMessages: undefined,
    searchTerm: undefined,
    sortBy: 'tillSettlement',
    sortOrder: SortDirectionEnum.asc,
    pageSize: 10,
    pageNumber: 1 // service page number is index from 1
  }
};

export interface WorkspaceSummaryCountApiResponse {
  totalCurrent: number;
  totalArchived: number;
}

export type GroupOptionModel = HttpTypes.WorkspaceGroup;

export type WorkspaceGroupsApiResponse = GroupOptionModel[];
export type SubscriberAssignableGroupsApiResponse = GroupOptionModel[];
export type ForwardableGroupsApiResponse = GroupOptionModel[];

export type SingleGroupMembersRequestModel = {
  groupIds: string;
  excludeInactive: boolean;
};

export type WorkspaceGroupRequestModel = {
  includeUnassignableGroups: boolean;
};

export enum AssignmentTypeEnum {
  AssignedToMe = 0,
  AssignedToGroup = 1,
  ReAssigned = 2
}

export enum DashboardSortDirectionEnum {
  asc = 0,
  desc = 1
}

export const UNASSIGNED_OPTION: LookupItemModel<string>[] = [
  {
    id: '00000000-0000-0000-0000-000000000000',
    name: 'Unassigned'
  }
];

type FilterType = 'groupMultipleCheckbox' | 'multipleCheckbox' | 'singleCheckbox' | 'multipleSearchCheckbox' | 'datetimePicker' | 'textboxSearch';

export interface FilterDataModel {
  name: string; // unique identify
  type: FilterType;
  label: string;
  searchBoxLabel?: string;
  placeHolder?: string;
  values?: (string | number)[];
  GroupOptions?: GroupedOptions<number>[];
  options?: LookupItemModel<number | string>[]; // Datetime component is undefined
  unit?: string;
  additionalOptionsOnTop?: LookupItemModel<number | string>[];
  defaultOpen?: boolean;
}

export const MyRoleOptions: LookupItemModel<number>[] = [
  {
    id: HttpTypes.MyRolesFilterEnum.IncomingMortgagee,
    name: 'Incoming Mortgagee'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.DischargeMortgagee,
    name: 'Discharge Mortgagee'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.Purchaser,
    name: 'Purchaser'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.Vendor,
    name: 'Vendor'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.CaveatorOnTitle,
    name: 'Caveator On Title'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.Beneficiary,
    name: 'Beneficiary'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.ControllingParty,
    name: 'Controlling Party'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.IncomingCaveator,
    name: 'Incoming Caveator'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.ToDealWithAnInterest,
    name: 'To Deal With An Interest'
  },
  {
    id: HttpTypes.MyRolesFilterEnum.SourceFunder,
    name: 'Source Funder'
  }
];
