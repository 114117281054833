import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import VerticalStatusLine from 'src/containers/dashboard/shared/formatters/VerticalStatusLine';
import { useScreenSize } from 'src/hooks';
import { ScreenSizeVariant } from 'src/theme';
import { VerticalStatusLineVariant } from 'src/utils/status-mapping/workspaceStatusHelper';
import { getVerticalStatusWithToolTip } from './helper';

interface Props {
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  lodgementCaseStatusId: HttpTypes.LodgementCaseStatusEnum;
  extraContent?: React.ReactNode;
}

function LodgementOnlyWorkspaceStatusProgress({
  //
  workspaceStatusId,
  lodgementCaseStatusId,
  extraContent
}: Props) {
  const screenVariant: ScreenSizeVariant = useScreenSize();

  const wsStatusInfo: {
    //
    variant: VerticalStatusLineVariant;
    title: string;
    tooltipContent: NonNullable<React.ReactNode> | undefined;
  } = React.useMemo(() => {
    const info = getVerticalStatusWithToolTip(workspaceStatusId, lodgementCaseStatusId, true);
    return { ...info };
  }, [workspaceStatusId, lodgementCaseStatusId]);

  const { title, variant, tooltipContent } = wsStatusInfo;

  if (extraContent) {
    return (
      <VerticalStatusLine //
        text={title}
        variant={variant}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={tooltipContent}
        extraContent={extraContent}
        dataTestId="workspace-overview-status-banner"
      />
    );
  }

  return (
    <FlexLayout flexDirection="row" justifyContent="flex-start">
      <VerticalStatusLine //
        text={title}
        variant={variant}
        textClassName={screenVariant === 'small-laptop' ? 'clip' : 'truncate'}
        tooltipTitle={tooltipContent}
        dataTestId="workspace-overview-status"
      />
    </FlexLayout>
  );
}

export default React.memo(LodgementOnlyWorkspaceStatusProgress);
