import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchAutoJustifications } from '../actions/autoJustification';

export interface AutoJustificationsFeedState {
  detail?: HttpTypes.SubscriberAutoJustificationsApiResponse;
  status: ApiStatus;
  error?: string;
}

export const initialAutoJustificationsFeedState: AutoJustificationsFeedState = {
  detail: undefined,
  status: 'idle',
  error: undefined
};

const reducer = createReducer<
  //
  AutoJustificationsFeedState,
  Action
>(initialAutoJustificationsFeedState)
  .handleAction(actionFetchAutoJustifications.request, (state): AutoJustificationsFeedState => {
    return {
      ...state,
      error: undefined,
      status: 'pending'
    };
  })
  .handleAction(actionFetchAutoJustifications.success, (state, action): AutoJustificationsFeedState => {
    return {
      ...state,
      status: 'resolved',
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchAutoJustifications.failure, (state, action): AutoJustificationsFeedState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
