import * as React from 'react';

import classNames from 'classnames';
import { DebouncedFunc } from 'lodash-es/debounce';

import Input from '@sympli/ui-framework/components/form/base-components/input';
import msg from '@sympli/ui-framework/utils/messages';

import { IconClose, IconSearch } from 'src/@core/components/icons';
import { useStyles } from './styles';

export interface LinkedWorkspaceSearchBoxProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  fetchSearchBoxWithDebounce: DebouncedFunc<(searchTerm?: string, pageNumber?: number) => void>;
  clearSelectedRow: () => void;
  disabled?: boolean;
}

function LinkedWorkspaceSearchBox({ value, setValue, fetchSearchBoxWithDebounce, clearSelectedRow, disabled = false }: LinkedWorkspaceSearchBoxProps) {
  const classes = useStyles();
  const MAX_SEARCH_LENGTH = 200;

  const placeholder = "Search for references, ID's, titles, addresses";

  const handleOnInputChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = _event.target.value;
    setValue(newValue);
    // when user adds more spacing we don't want to call backend again
    if (value.trim() !== newValue.trim() && newValue.trim().length <= MAX_SEARCH_LENGTH) {
      fetchSearchBoxWithDebounce(newValue);
      clearSelectedRow();
    }
  };

  const handleOnClearSearchInput = () => {
    setValue('');
    clearSelectedRow();
  };

  const renderEndAdornment = () => {
    return <IconClose className={classNames(classes.actionIcon, classes.searchBoxIconClose)} onClick={handleOnClearSearchInput} data-testid="clear-button" />;
  };

  return (
    <Input
      className={classes.searchInput}
      error={value.length > MAX_SEARCH_LENGTH ? msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(MAX_SEARCH_LENGTH) : undefined}
      aria-label="Search"
      autoFocus={true}
      classes={{ formControl: classes.inputStyle }}
      placeholder={placeholder}
      value={value}
      onChange={handleOnInputChange}
      startAdornment={<IconSearch className={classNames(classes.actionIcon, classes.searchBoxIconSearch)} />}
      endAdornment={renderEndAdornment()}
      disabled={disabled}
      // we don't allow user to enter more than max length, but we need to trigger error message when they do, once they exceed it, we stop them from typing more, that's why we need +1
      inputProps={{ className: classes.inputBox, maxLength: MAX_SEARCH_LENGTH + 1 }}
    />
  );
}

export default React.memo(LinkedWorkspaceSearchBox);
