import React from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { IconDelete, IconPencil, IconTimes } from '@sympli/ui-framework/icons';

import Tooltip from 'src/@core/components/tooltip';
import { useSafeDispatch } from 'src/hooks';
import { actionRemoveFileWithIssue } from '../workspace-files-container/actions';
import { UploadFileTypeEnum } from '../workspace-files/enums';
import { hasIssue } from '../workspace-files/helpers';
import { WorkspaceFile } from '../workspace-files/models';
import { useStyles } from './styles';

interface Props {
  workspaceId: string;
  participantId: string;
  file: WorkspaceFile;
  disabled?: boolean;
  onEditFile: (file: WorkspaceFile | null) => void;
  onDeleteFile: (file: WorkspaceFile | null) => void;
}

export default function WorkspaceFileManageButtons(props: Props) {
  const classes = useStyles();
  const { workspaceId, participantId, file, disabled, onEditFile, onDeleteFile } = props;
  const { uploadedByParticipantId, status, id: fileId, uploadFileType } = file;

  const dispatch = useSafeDispatch(useDispatch());

  // File can be edited only when it is a WorkspaceFile type and by its own creator
  if (!(uploadFileType === UploadFileTypeEnum.WorkspaceFile && uploadedByParticipantId === participantId)) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      {hasIssue(status) ? (
        <Tooltip classes={{ tooltip: classes.tooltip }} title="Remove">
          <IconButton
            className={classNames(classes.removeFile, classes.error)}
            onClick={e => {
              e.stopPropagation();
              dispatch(actionRemoveFileWithIssue.request({ workspaceId, participantId, fileId }));
            }}
            aria-label="Remove file"
            disabled={disabled}
          >
            <IconTimes className={classNames(disabled && classes.iconDisabled)} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Edit">
            <IconButton
              className={classes.button}
              aria-label="Edit file"
              onClick={e => {
                e.stopPropagation();
                onEditFile(file);
              }}
              disabled={disabled}
            >
              <IconPencil fontSize="24" className={classNames(classes.buttonIcon, disabled && classes.iconDisabled)} />
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Delete">
            <IconButton
              className={classes.button}
              aria-label="Delete file"
              onClick={e => {
                e.stopPropagation();
                onDeleteFile(file);
              }}
              disabled={disabled}
            >
              <IconDelete className={classNames(classes.buttonIcon, disabled && classes.iconDisabled)} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
}
