import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import OutsideOperationHoursMessageBox from 'src/containers/workspace/registration-only/detail/components/outside-operation-hours';
import {
  IconLodgementFailed,
  IconLodgementInProgress,
  IconLodgementQueued,
  IconLodgementSuccess,
  IconLodgementVerificationInProgress
} from 'src/containers/workspace/shared/components/task-box-icons';
import Summary from '../summary';
import { isLodgementProgressVisible } from './helpers';
import styles, { ClassKeys } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2084897049/Tasks+tab+-+data+dependency+insights
export interface LodgementProgressProps {
  // basic workspace info
  workspaceStatusId?: HttpTypes.WorkspaceStatusEnum;
  jurisdictionId?: HttpTypes.JurisdictionsEnum;
  lodgementCaseStatusId?: HttpTypes.LodgementCaseStatusEnum;
  // full workspace info
  isValidOperatingHours?: boolean;
  // other
  backLink: string;
  onSelectReportsTab?: () => void;
  onLodgementAttemptClick(): Promise<void>;
}
type Props = LodgementProgressProps & WithStyles<ClassKeys>;

interface State {
  isButtonLoading: boolean;
}

class LodgementProgress extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isButtonLoading: false
  };

  private finalisedNegativeTitle(): string {
    switch (this.props.lodgementCaseStatusId) {
      case HttpTypes.LodgementCaseStatusEnum.Rejected:
        return 'Documents rejected';
      case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
        return 'Documents withdrawn';
      case HttpTypes.LodgementCaseStatusEnum.Unnecessary:
        return 'Documents unnecessary';

      default:
        return '';
    }
  }

  render() {
    const { workspaceStatusId, lodgementCaseStatusId, classes } = this.props;
    if (!isLodgementProgressVisible(workspaceStatusId, lodgementCaseStatusId)) {
      return null;
    }

    // If workspace status is 'LodgementInQueue', it doesn't mean lodgement yet
    // it's a transition status between OnSchedule and Lodging. When the scheduled
    // lodgement is kicked to lodgement processing queue, workspace status will
    // go back to 'OnSchedule' and LodgementCaseStatus will be 'LodgementRequested'
    // and the rest is the same as before
    if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.LodgementInQueue) {
      // https://projects.invisionapp.com/d/main#/console/16278382/436406762/preview
      return (
        <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
          <IconLodgementQueued />
          <Summary
            title="Added to lodgement queue"
            message="Lodgement will begin at the next available opportunity."
            description="Workspace is locked for lodgement. You can leave this page and we'll notify you of any updates."
          />
          {this.renderBackToDashboardButton()}
        </FlexLayout>
      );
    }

    switch (lodgementCaseStatusId) {
      case HttpTypes.LodgementCaseStatusEnum.LodgementRequested:
        // https://projects.invisionapp.com/d/main#/console/16278382/386925833/preview
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementInProgress />
            <Summary
              title="Lodgement in progress"
              message="Your documents are being lodged"
              description="This will take a moment, you can leave this page and we'll notify you of any updates."
            />
            {this.renderBackToDashboardButton()}
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.LodgementSuccess:
        // https://projects.invisionapp.com/d/main#/console/16278382/386925834/preview
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementSuccess />
            <Summary
              title="Lodgement completed and awaiting registration with the land registry."
              description={
                <span>
                  We'll notify you of any updates on your documents. <br /> Thank you for choosing Sympli.
                </span>
              }
            />
            <span>
              {this.renderBackToDashboardButton()}
              {this.renderDownloadReportsButton()}
            </span>
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.LodgementError:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            {this.renderOutsideOperationHoursMessage()}
            <IconLodgementFailed />
            <Summary
              title="We are experiencing technical issues"
              message="You can reattempt lodgement or call 1300 SYMPLI for assistance"
              description="To resolve this issue, you can reattempt lodgement by clicking on the button below or call Sympli for assistance."
            />
            <span>
              {this.renderBackToDashboardButton()}
              {this.renderLodgeButton('Reattempt lodgement now')}
            </span>
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.LodgementConnectionError:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            {this.renderOutsideOperationHoursMessage()}
            <IconLodgementFailed />
            <Summary
              title="Cannot connect to the land registry"
              message="Your documents cannot be lodged right now."
              description="Your documents are signed and ready for lodgement. Please try again later or call 1300 SYMPLI (1300 796 754)."
            />
            <span>
              {this.renderBackToDashboardButton()}
              {this.renderLodgeButton('Lodge')}
            </span>
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementVerificationInProgress />
            <Summary
              title="Verification in progress"
              message="The land registry is verifying your document."
              description="This will take a moment, you can leave this page and we'll notify you of any updates."
            />
            {this.renderBackToDashboardButton()}
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.Registered:
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementSuccess />
            <Summary
              title="Documents registered"
              description={
                <span>
                  Your documents have been finalised with the land registry. <br /> This workspace will be auto archived after 90 days.
                </span>
              }
            />
            <span>
              {this.renderBackToDashboardButton()}
              {this.renderDownloadReportsButton()}
            </span>
          </FlexLayout>
        );
      case HttpTypes.LodgementCaseStatusEnum.Rejected:
      case HttpTypes.LodgementCaseStatusEnum.Withdrawn:
      case HttpTypes.LodgementCaseStatusEnum.Unnecessary:
        // https://projects.invisionapp.com/d/main#/console/16278382/447504342/preview
        return (
          <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
            <IconLodgementFailed />
            <Summary
              title={this.finalisedNegativeTitle()}
              description={
                <span>
                  Your documents have been finalised with the land registry. <br /> This workspace will be auto archived after 90 days.
                </span>
              }
            />
            <span>
              {this.renderBackToDashboardButton()}
              {this.renderDownloadReportsButton()}
            </span>
          </FlexLayout>
        );
      default: {
        return null;
      }
    }
  }

  private renderOutsideOperationHoursMessage() {
    const { jurisdictionId, isValidOperatingHours } = this.props;
    const showOutsideHoursMessage = typeof isValidOperatingHours === 'boolean' && !isValidOperatingHours;
    if (!(showOutsideHoursMessage && Number.isInteger(jurisdictionId))) {
      return null;
    }
    return <OutsideOperationHoursMessageBox jurisdictionId={jurisdictionId!} />;
  }

  private renderBackToDashboardButton() {
    const { classes, backLink } = this.props;
    return (
      <SympliButton href={backLink} variant="outlined" color="primary" className={classes.actionButton}>
        Back to dashboard
      </SympliButton>
    );
  }

  private renderDownloadReportsButton() {
    const { classes, onSelectReportsTab } = this.props;
    if (!onSelectReportsTab) {
      return null;
    }

    return (
      <SympliButton onClick={onSelectReportsTab} variant="contained" color="primary" arrowRight className={classes.actionButton}>
        Download reports
      </SympliButton>
    );
  }

  private renderLodgeButton(buttonLabel: string = 'Lodge now') {
    const { isButtonLoading } = this.state;
    return (
      <SympliButton
        className={this.props.classes.actionButton}
        arrowRight
        isLoading={isButtonLoading}
        disabled={isButtonLoading || !this.props.isValidOperatingHours}
        color="primary"
        variant="contained"
        onClick={this.handleOnLodgeNowClick}
      >
        {buttonLabel}
      </SympliButton>
    );
  }

  private handleOnLodgeNowClick = () => {
    this.setState({ isButtonLoading: true }, () => {
      this.props
        .onLodgementAttemptClick() //
        .finally(() => this.setState({ isButtonLoading: true }));
    });
  };
}

export default withStyles(styles)(LodgementProgress);
