import { HttpTypes } from '@sympli/api-gateway/types';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { UserAvatarModel } from 'src/components/avatars/multi-user-avatar';
import { DocumentWorkflowModel } from 'src/containers/documents/models';

export interface RouteParams {
  workspaceId: string;
  participantId: string;
  documentId?: string;
}

// export interface DocumentParticipant extends InvitedParticipantApiResponse {
//   documentStatus: DocumentStatusEnum;
//   isPrimaryDocumentParticipant: boolean;
//   documentWorkflow: DocumentWorkflowModel;
// }
export type DocumentParticipant = {
  documentStatus: HttpTypes.DocumentStatusEnum;
  isPrimaryDocumentParticipant: boolean;
  documentWorkflow: DocumentWorkflowModel;
} & HttpTypes.WorkspaceParticipant;

export type DocumentLodgementDetail = Exclude<HttpTypes.WorkspaceDocumentSummary['lodgementDetail'], undefined>;
export type DocumentLodgementCompliance = Exclude<DocumentLodgementDetail['documentLodgementCompliances'][number], 'undefined'>;

// * the display order of the document participants ordered by role in the document list
// * this is a general order for transfer, and lease document, add potential role order for future documents here
// * this general order should hold long enough
export const documentParticipantOrderedList: HttpTypes.WorkspaceRoleEnum[] = [
  //
  HttpTypes.WorkspaceRoleEnum.Purchaser,
  HttpTypes.WorkspaceRoleEnum.Vendor,
  HttpTypes.WorkspaceRoleEnum.Lessee,
  HttpTypes.WorkspaceRoleEnum.Lessor
];

// the display order of the workspace participants ordered by role in the all distributions list and participants list
export const generalWorkspaceRoleOrderedList: HttpTypes.WorkspaceRoleEnum[] = [
  //
  HttpTypes.WorkspaceRoleEnum.ControllingParty,
  HttpTypes.WorkspaceRoleEnum.DischargingCaveator,
  HttpTypes.WorkspaceRoleEnum.DischargingMortgagee,
  HttpTypes.WorkspaceRoleEnum.Vendor,
  HttpTypes.WorkspaceRoleEnum.Purchaser,
  HttpTypes.WorkspaceRoleEnum.IncomingMortgagee,
  HttpTypes.WorkspaceRoleEnum.Lessee,
  HttpTypes.WorkspaceRoleEnum.Lessor,
  HttpTypes.WorkspaceRoleEnum.Beneficiary,
  HttpTypes.WorkspaceRoleEnum.IncomingCaveator,
  HttpTypes.WorkspaceRoleEnum.ToDealWithAnInterest,
  HttpTypes.WorkspaceRoleEnum.SourceFunder
];

export const SEALED_WORKSPACE_STATUSES: HttpTypes.WorkspaceStatusEnum[] = [
  //
  HttpTypes.WorkspaceStatusEnum.Success,
  HttpTypes.WorkspaceStatusEnum.SettlementInProgress,
  HttpTypes.WorkspaceStatusEnum.Finalised,
  HttpTypes.WorkspaceStatusEnum.Archived,
  HttpTypes.WorkspaceStatusEnum.Abandoned,
  HttpTypes.WorkspaceStatusEnum.Withdrawn,
  HttpTypes.WorkspaceStatusEnum.LodgementInQueue
];

export enum DocumentListItemStatusEnum {
  Reorder = 'REORDER',
  Delete = 'DELETE',
  Unknown = 'UNKNOWN', // special status where we don't know what to do
  InDraft = 'IN_DRAFT',
  AwaitingInviteAcceptance = 'AWAITING_INVITE_ACCEPTANCE',
  Edit = 'EDIT',
  AwaitingEditFromOthers = 'AWAITING_EDIT_FROM_OTHERS',
  ReadyToApprove = 'READY_TO_APPROVE',
  Approved = 'APPROVED',
  AwaitingApproveFromOthers = 'AWAITING_APPROVE_FROM_OTHERS',
  ReadyToSign = 'READY_TO_SIGN',
  Signed = 'SIGNED',
  VerificationInProgress = 'LV_IN_PROGRESS',
  LodgementInQueue = 'LODGEMENT_IN_QUEUE',
  LodgementInProgress = 'LODGEMENT_IN_PROGRESS',
  Lodged = 'LODGED',
  ResolveIssues = 'RESOLVE_ISSUE',
  IssuesFound = 'ISSUES_FOUND'
}

/**
 * participantMode defines what level of access user has to given document
 * - primary - user can edit, review, sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-with-edit-document-permission
 *
 * - non-primary - user can review and sign
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-document-participant-who-DOES-NOT-have-edit-document-permission
 *
 * - ws-only - user can only see document after it has been approved
 *   https://tickleme.atlassian.net/wiki/spaces/DEV/pages/1495924737/Document+list+item#Status-mapping-for-workspace-participants-not-listed-as-document-participants
 *
 * default: ws-only
 */
export type DocumentListItemParticipantMode = 'primary' | 'non-primary' | 'ws-only';
export type DocumentItemVisualMode = 'secondary' | 'info' | 'primary';
export type DocumentListAction = 'default' | 'add' | 'reorder' | 'delete';

export interface ConsolidationModel {
  documentType: HttpTypes.WorkspaceDocumentSummary['documentForm']['documentType']; // LookupEnumModel<DocumentTypeIdentifierEnum>;
  documentStatusId: HttpTypes.WorkspaceDocumentSummary['documentStatus']['id']; // DocumentStatusEnum;
  documentActions: HttpTypes.WorkspaceDocumentSummary['documentActions']; // DocumentActionModel[];
  documentParticipants: HttpTypes.WorkspaceDocumentSummary['documentParticipants']; //DocumentParticipant[]; // all document participants
  primaryDocumentParticipants: HttpTypes.WorkspaceDocumentSummary['documentParticipants']; // DocumentParticipant[]; // primary only
  currentDocumentParticipant?: HttpTypes.WorkspaceDocumentSummary['documentParticipants'][number]; // current document participant resolved based on query parameter
  numberOfRequiredSigners: number;
  lrDocumentId?: string;
  jurisdiction?: HttpTypes.WorkspaceDocumentSummary['jurisdiction']['id']; // JurisdictionsEnum;
  hasDealingRequirements?: HttpTypes.WorkspaceDocumentSummary['hasDealingRequirements']; // boolean | null;
  documentLodgementStatus?: LookupEnumModel<HttpTypes.DocumentLodgementStatusEnum>;
  lodgementDateTime?: HttpTypes.WorkspaceDateTimeModel;
  workspaceParticipants?: HttpTypes.WorkspaceParticipant[];
  isCriticalRolesEnabled: boolean;
}

export interface DocumentListParticipant extends UserAvatarModel {
  role: string;
  badge: JSX.Element;
  canJoin: boolean;
  participantStatusId: HttpTypes.ParticipantStatusEnum;
}

export interface LodgementCaseDetail {
  lodgementCase?: HttpTypes.LodgementCase;
  documents: DocumentListModel[];
}

export interface DocumentListModel {
  documentId: string;
  documentIdentifier: HttpTypes.DocumentTypeIdentifierEnum;
  uiStatus: DocumentListItemStatusEnum;
  description: React.ReactNode;
  visualMode: DocumentItemVisualMode;
  linkTo?: string;
  isLI: boolean;
  name: string;
  icon?: JSX.Element;
  participants: DocumentListParticipant[];
  selected: boolean;
  disabled: boolean;
  supportingDocuments: DocumentListModel[];
  canRemove: boolean;
  isFullySignedByAllParticipants: boolean;
  className?: string;
}

export enum DocumentStatusDisplayEnum {
  ToBeInvited = 'TO BE INVITED',
  Invited = 'INVITED',
  InPreparation = 'IN PREPARATION',
  Approved = 'APPROVED',
  Signed = 'SIGNED',
  PartiallySigned = 'PARTIALLY SIGNED',
  Lodging = 'LODGING',
  Lodged = 'LODGED',
  NA = 'N/A'
}

export const DocumentStatusSteps: string[] = [DocumentStatusDisplayEnum.InPreparation, DocumentStatusDisplayEnum.Approved, DocumentStatusDisplayEnum.Signed];

export const SLCDocumentLists: Array<HttpTypes.DocumentTypeIdentifierEnum> = [
  HttpTypes.DocumentTypeIdentifierEnum.PriorityNotice,
  HttpTypes.DocumentTypeIdentifierEnum.ExtensionOfPriorityNotice,
  HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfPriorityNotice,
  HttpTypes.DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase
];
