import { FinalTenancyHoldingsExtractor, isValidFraction } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { RelinquishingProprietorGroupModel, RelinquishingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing/models';
import { ShareTransferredTypeEnum } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/relinquishing';
import { multiplyFraction, subtractFraction, sumFraction } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/shared';
import { FractionModel, ProprietorGroupModel, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';

export class TenancyDetailExtractor implements FinalTenancyHoldingsExtractor {
  /**
   *
   */
  constructor(
    public source: RelinquishingTenancyDetailModel,
    public receivingTenancyDetail: ReceivingTenancyDetailModel,
    public shouldMergeRemainingWithReceiving: boolean
  ) {}

  private isValidFraction = (fraction?: FractionModel): fraction is FractionModel => {
    return isValidFraction(fraction);
  };

  extractRemainingGroups(): ProprietorGroupModel<TenancyPartyModel>[] {
    const remainingRelinquishingProprietors: ProprietorGroupModel<TenancyPartyModel>[] = this.source.proprietorGroups
      .filter(({ isSelected, shareTransferred, parties }) => !isSelected || shareTransferred === ShareTransferredTypeEnum.Partial || parties.some(p => !p.isSelected))
      .map(g => {
        // Non transacting group
        if (!g.isSelected) {
          return g;
        }

        // Group selling partial share
        let shareTransferring: FractionModel | undefined = this.extractShareTransferring(g);

        let adjustedShareFraction: FractionModel | undefined = undefined;
        if (this.isValidFraction(shareTransferring)) {
          const transferringFraction: FractionModel = multiplyFraction(g.shareFraction, shareTransferring);
          adjustedShareFraction = subtractFraction(g.shareFraction, transferringFraction);
        }

        return {
          ...g,
          parties: g.shareTransferred === ShareTransferredTypeEnum.Whole ? g.parties.filter(p => !p.isSelected) : g.parties,
          shareFraction: adjustedShareFraction ?? {
            numerator: null,
            denominator: null
          }
        };
      });

    return remainingRelinquishingProprietors;
  }

  extractShareTransferred(): FractionModel | undefined {
    const sharesTransferred: (FractionModel | undefined)[] = this.source.proprietorGroups
      .filter(proprietorGroup => proprietorGroup.isSelected)
      .map(group => {
        const { shareTransferred, shareFraction } = group;

        const shareTransferring = this.extractShareTransferring(group);
        const transferringShare =
          shareTransferred === ShareTransferredTypeEnum.Partial || group.parties.some(p => !p.isSelected)
            ? this.isValidFraction(shareTransferring)
              ? multiplyFraction(shareFraction, shareTransferring!)
              : undefined
            : shareFraction;
        return transferringShare;
      })
      .filter(Boolean);

    if (!sharesTransferred.length) return undefined;

    const sumOfShares = sharesTransferred.reduce(sumFraction);
    return sumOfShares;
  }

  private extractShareTransferring(group: RelinquishingProprietorGroupModel): FractionModel | undefined {
    return group.shareTransferred === ShareTransferredTypeEnum.Whole && group.parties.some(p => !p.isSelected)
      ? {
          numerator: group.parties.filter(p => p.isSelected).length,
          denominator: group.parties.length
        }
      : group.shareFractionTransferring;
  }
}
