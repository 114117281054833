import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: 18,
      '& >$row:last-child': {
        borderBottom: 0
      }
    },
    root101: {
      paddingTop: 16,
      '& >$row:last-child': {
        borderBottom: 0
      }
    },
    row101: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      paddingBottom: 4
    },
    bold: {
      fontWeight: 'bold'
    },
    feeItems: {
      marginLeft: 21
    },
    feeItems101: {
      marginLeft: 0
    },
    feeTotal: {
      borderTop: `1px solid ${theme.palette.divider}`,
      fontWeight: 'bold'
    },
    feeTotal101: {
      borderTop: `1px solid ${theme.palette.divider}`,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      paddingTop: 4
    },
    gstInclusive: {
      color: theme.palette.text.secondary,
      fontSize: 13
    },
    gstInclusive101: {
      lineHeight: '20px',
      fontSize: 13,
      fontWeight: 400,
      color: colors.GREY_400
    },
    breakdown: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.GREY_400,
      paddingBottom: 5,
      paddingTop: 2
    },
    breakdownItalic: {
      fontStyle: 'italic'
    }
  }),
  {
    name: 'PaymentLineItems'
  }
);
