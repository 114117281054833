import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HttpTypes } from '@sympli/api-gateway/types';

import { SettlementDateDetailsState } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import IconTypography from 'src/containers/workspace/shared/components/workspace-status-rebuild/icon-typography';
import { ErrorHint } from 'src/containers/workspace/shared/components/workspace-status-rebuild/icons';
import { colors } from 'src/theme';
import {
  activeAcceptedSettlementDateParticipantsNumber,
  activeParticipantsAndInvitations,
  FINALIZED_WORKSPACE_STATUSES,
  getDateString,
  getDateTimeString,
  getSettlementDateTime,
  getSettlementDateTimeStatus
} from '../helpers';
import SettlementDateBox, { Props as SettlementDateBoxProps } from './SettlementDateBox';

export interface BasicParticipantInfo {
  //
  participantStatus: HttpTypes.WorkspaceParticipant['participantStatus'];
  workspaceRole: HttpTypes.WorkspaceParticipant['workspaceRole'];
  archivedStatus?: HttpTypes.WorkspaceParticipant['archivedStatus'];
}

interface Props<P extends BasicParticipantInfo> {
  // route params
  participantId: string;
  // workspace basic info
  settlementDateTime?: HttpTypes.WorkspaceDateTimeModel;
  workspaceStatusId: HttpTypes.WorkspaceStatusEnum;
  isInteroperable?: boolean;
  expectedSettlementDateTime?: HttpTypes.ExpectedSettlementDate;
  // settlement date details
  settlementDetailsState: SettlementDateDetailsState;
  // TODO remove this
  settlementDateTimeData?: HttpTypes.WorkspaceDateTimeModel;
  // other
  workspaceParticipants: P[];
  editLink?: string;
  isRollover?: boolean;
}

/**
 * This component is used in workspace detail page and all dashboards
 * We need to clean up the code when Dashboard2.0 is introduced
 */
function SettlementDateBoxContainer<P extends BasicParticipantInfo>({
  participantId,
  // workspace basic info
  settlementDateTime,
  expectedSettlementDateTime,
  workspaceStatusId,
  isInteroperable,
  // other
  workspaceParticipants,
  settlementDetailsState,
  settlementDateTimeData,
  editLink,
  isRollover
}: Props<P>) {
  const SETTLEMENT_TITLE = 'Settlement';

  // participants from settlement-date-proposal
  const { participants, proposedSettlementDate } = settlementDetailsState.detail;

  const activeParticipants = workspaceParticipants.filter(p => !p.archivedStatus).map(p => p.participantStatus);

  const showProposedSettlementDate = useMemo(() => {
    if (!settlementDateTime) {
      return false;
    }

    const { isAcceptedByUser, proposedByParticipant, localProposedSettlementDate } = settlementDetailsState.detail;

    // if self proposed then auto accepted.
    if (!localProposedSettlementDate || isAcceptedByUser || proposedByParticipant?.id === participantId) {
      return false;
    }

    if (workspaceStatusId === HttpTypes.WorkspaceStatusEnum.Failed) {
      // overdue
      if (settlementDateTime!.hoursTill < 0) {
        return false;
      } else {
        // hoursTill > 0 means new datetime been proposed
        return true;
      }
    }

    // is settlement date time accepted by all active participants(exclude withdrawn participants)
    if (activeAcceptedSettlementDateParticipantsNumber(participants) === activeParticipantsAndInvitations(activeParticipants)) {
      return false;
    }

    return true;
  }, [activeParticipants, participantId, participants, settlementDateTime, settlementDetailsState.detail, workspaceStatusId]);

  if (!settlementDateTimeData && !settlementDateTime && !expectedSettlementDateTime) {
    return (
      <SettlementDateBox //
        ariaLabel={SETTLEMENT_TITLE}
        title={SETTLEMENT_TITLE}
        description={
          <Box flexDirection="column">
            <Typography variant="body2">Propose settlement date and time</Typography>
            <IconTypography text="Date Not Set" />
          </Box>
        }
        linkTo={editLink}
      />
    );
  }

  if (
    // date only condition
    expectedSettlementDateTime &&
    // Kind will only change from DateOnly to DateAndTime when all participants accept invite
    expectedSettlementDateTime.settlementDateKind === HttpTypes.SettlementDateKindEnum.DateOnly &&
    /**
     * if there is already a new proposal, we should:
     * 1. show accepted for whom proposed it
     * 2. show waiting for accept for other participants
     * both proposedSettlementDate(from settlement-date-proposal) and settlementDateTime(from basic info API) should have value
     */
    !(proposedSettlementDate && settlementDateTime)
  ) {
    return (
      <SettlementDateBox //
        ariaLabel={SETTLEMENT_TITLE}
        title={SETTLEMENT_TITLE}
        description={
          <Box flexDirection="column">
            <Typography variant="body2">{getDateString(expectedSettlementDateTime.workspaceDateTime)}</Typography>
            <IconTypography text="Time Not Set" />
          </Box>
        }
        linkTo={editLink}
      />
    );
  }

  // For workspace detail page, we always have settlementDateTimeData, but for dashboard page, we only have settlementDateTime
  const descriptionText: SettlementDateBoxProps['description'] = getSettlementDateTime(settlementDateTimeData || settlementDateTime!, isRollover, workspaceStatusId);

  const status: JSX.Element | null = getSettlementDateTimeStatus(
    activeParticipants,
    participants,
    settlementDateTimeData || settlementDateTime,
    workspaceStatusId,
    isInteroperable
  );

  // if the workspace is finalized, we only need to show existing ws settlement info, no need to worry about proposals
  if (FINALIZED_WORKSPACE_STATUSES.includes(workspaceStatusId)) {
    return (
      <SettlementDateBox //
        ariaLabel={SETTLEMENT_TITLE}
        title={SETTLEMENT_TITLE}
        description={
          <Box flexDirection="column">
            {descriptionText}
            {status}
          </Box>
        }
        linkTo={editLink}
      />
    );
  }

  if (showProposedSettlementDate) {
    return (
      <SettlementDateBox
        ariaLabel={SETTLEMENT_TITLE}
        title={SETTLEMENT_TITLE}
        description={
          <Box flexDirection="column">
            <Typography variant="body2">{getDateTimeString(settlementDateTimeData || settlementDateTime!)}</Typography>
            <IconTypography icon={<ErrorHint style={{ background: colors.SUNNY_DAY }} />} text="Pending" />
          </Box>
        }
        linkTo={editLink}
      />
    );
  }

  return (
    <SettlementDateBox //
      ariaLabel={SETTLEMENT_TITLE}
      title={SETTLEMENT_TITLE}
      description={
        <Box flexDirection="column">
          {descriptionText}
          {status}
        </Box>
      }
      linkTo={editLink}
    />
  );
}

export default React.memo(SettlementDateBoxContainer);
