import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { actionFetchDocumentDefaultFields } from '../actions/defaultFieldPopulation';

export interface DocumentDefaultFieldListState {
  details?: HttpTypes.DocumentFieldDetailApiResponse;
  isLoading: boolean;
  error?: string;
}

const initialState: DocumentDefaultFieldListState = {
  details: undefined,
  isLoading: false,
  error: undefined
};

const reducer = createReducer<
  //
  DocumentDefaultFieldListState,
  Action
>(initialState)
  .handleAction(actionFetchDocumentDefaultFields.request, (state): DocumentDefaultFieldListState => {
    return {
      ...state,
      isLoading: true,
      error: undefined
    };
  })
  .handleAction(actionFetchDocumentDefaultFields.success, (state, action): DocumentDefaultFieldListState => {
    return {
      ...state,
      details: action.payload.data,
      isLoading: false
    };
  })
  .handleAction(actionFetchDocumentDefaultFields.failure, (state, action): DocumentDefaultFieldListState => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error.message
    };
  });

export default reducer;
