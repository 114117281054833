import React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { LodgementCaseLogMap } from 'src/containers/workspace/shared/detail/models';
import { dateTimeLine } from 'src/utils/formatters';
import { useStyles } from './styles';

interface CaptionText {
  lastAttemptedText: string;
  reattemptText?: string;
}

interface Props {
  lodgementCaseStatus?: HttpTypes.LodgementCaseStatusEnum;
  pastAuthorityComplianceReport?: HttpTypes.AuthorityComplianceReportApiResponse;
  lodgementCaseLogSortedMap: LodgementCaseLogMap;
}

function Caption({ lodgementCaseStatus, lodgementCaseLogSortedMap, pastAuthorityComplianceReport }: Props) {
  const classes = useStyles();

  const getCaptionText = (): CaptionText => {
    if (pastAuthorityComplianceReport) {
      const time = pastAuthorityComplianceReport.lodgementCaseCompliance.dateTime!.workspaceLocalTime;
      return pastAuthorityComplianceReport.messageType === HttpTypes.AuthorityComplianceReportTypeEnum.Lodgement
        ? { lastAttemptedText: `Lodgement results from ${dateTimeLine(time, 'd mmm yyyy, "at" h:MM TT')}.` }
        : { lastAttemptedText: `Verification results from ${dateTimeLine(time, 'd mmm yyyy, "at" h:MM TT')}.` };
    }

    if (lodgementCaseStatus === undefined || lodgementCaseStatus === HttpTypes.LodgementCaseStatusEnum.Unverified) {
      return {
        lastAttemptedText: 'Documents unverified.',
        reattemptText: 'Lodgement verification will be performed when all documents in the workspace have been approved.'
      };
    }

    if (lodgementCaseStatus === HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested) {
      const times = lodgementCaseLogSortedMap[HttpTypes.LodgementCaseStatusEnum.LodgementVerificationRequested]?.map(c => new Date(c.createAt).getTime()) ?? [];
      if (times.length) {
        const time = new Date(Math.max(...times));
        return {
          lastAttemptedText: `Verification last attempted on ${dateTimeLine(time, 'd mmm yyyy, "at" h:MM TT')}.`,
          reattemptText: 'Lodgement verification is in progress.'
        };
      }
      return { lastAttemptedText: 'Documents unverified.' };
    }

    const lastSuccess: number = Math.max(
      ...(lodgementCaseLogSortedMap[HttpTypes.LodgementCaseStatusEnum.LodgementVerificationSuccess]?.map((c: HttpTypes.LodgementCaseLogDetail) => new Date(c.createAt).getTime()) ??
        [])
    );
    const lastError: number = Math.max(
      ...(lodgementCaseLogSortedMap[HttpTypes.LodgementCaseStatusEnum.LodgementVerificationError]?.map((c: HttpTypes.LodgementCaseLogDetail) => new Date(c.createAt).getTime()) ??
        [])
    );

    const last: number = Math.max(lastSuccess, lastError);
    if (last) {
      const time = new Date(last);
      return {
        lastAttemptedText: `Verification last attempted on ${dateTimeLine(time, 'd mmm yyyy, "at" h:MM TT')}.`,
        reattemptText: 'Lodgement verification will be reattempted when all documents in the workspace have been approved.'
      };
    }
    return { lastAttemptedText: 'Documents unverified.' };
  };

  const { lastAttemptedText, reattemptText } = getCaptionText();

  return (
    <div className={classes.root}>
      <div className={classes.contactSupport}>
        If you require further assistance please contact customer support on <strong>1300 SYMPLI (1300 796 754)</strong>.
      </div>
      {lastAttemptedText}
      <br />
      {reattemptText && reattemptText}
    </div>
  );
}

export default React.memo(Caption);
