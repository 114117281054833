import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveUuid } from 'src/utils/formUtils';
import { AllowedAdditionalParticipantRole } from '../../../../models';
import { CreateDocumentsItemModel } from '../../../reg-only-form/models';
import { DEFAULT_INVITATION_ITEM_MODEL } from '../../initialValues';
import { AdditionalParticipantRoleConfig, InvitationModel } from '../../models';
import { InvitationItemModel } from './models';

export const compulsoryInvitationMapping: Partial<Record<HttpTypes.WorkspaceRoleEnum, HttpTypes.WorkspaceRoleEnum[]>> = {
  [HttpTypes.WorkspaceRoleEnum.Vendor]: [HttpTypes.WorkspaceRoleEnum.Purchaser],
  [HttpTypes.WorkspaceRoleEnum.Purchaser]: [HttpTypes.WorkspaceRoleEnum.Vendor]
};

export function resolveDocumentByWorkspaceRole(createdDocuments: CreateDocumentsItemModel[], workspaceRole: HttpTypes.WorkspaceRoleEnum) {
  switch (workspaceRole) {
    case HttpTypes.WorkspaceRoleEnum.IncomingMortgagee: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.Mortgage);
    }
    case HttpTypes.WorkspaceRoleEnum.DischargingMortgagee: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.DischargeOfMortgage);
    }
    case HttpTypes.WorkspaceRoleEnum.Vendor:
    case HttpTypes.WorkspaceRoleEnum.Purchaser: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.Transfer);
    }
    case HttpTypes.WorkspaceRoleEnum.DischargingCaveator: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.WithdrawalOfCaveat);
    }
    case HttpTypes.WorkspaceRoleEnum.Beneficiary: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin);
    }
    case HttpTypes.WorkspaceRoleEnum.IncomingCaveator: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.Caveat);
    }
    case HttpTypes.WorkspaceRoleEnum.ToDealWithAnInterest: {
      return createdDocuments.find(doc => doc.documentIdentifier === HttpTypes.DocumentTypeIdentifierEnum.SubLease);
    }
    default: {
      return undefined;
    }
  }
}

export function getDefaultInvitation(groupId: string, matterNumber: string, workspaceRole?: HttpTypes.WorkspaceRoleEnum): InvitationModel {
  return { role: workspaceRole, id: resolveUuid('number', 20), invitation: { ...DEFAULT_INVITATION_ITEM_MODEL, groupId, matterNumber } };
}

export function setInvitationDefaultGroupId(invitation: InvitationModel, defaultGroupId: string): InvitationModel {
  return { ...invitation, invitation: invitation.invitation && { ...invitation.invitation, groupId: defaultGroupId } };
}

export function setInvitationItemDefaultGroupId(invitationItem: InvitationItemModel, defaultGroupId: string): InvitationItemModel {
  return { ...invitationItem, groupId: defaultGroupId };
}

export function getAdditionalParticipantRoleConfigs(
  allowedAdditionalParticipantRoles: AllowedAdditionalParticipantRole[]
): Partial<Record<HttpTypes.WorkspaceRoleEnum, AdditionalParticipantRoleConfig>> {
  const result: Partial<Record<HttpTypes.WorkspaceRoleEnum, AdditionalParticipantRoleConfig>> = allowedAdditionalParticipantRoles.reduce(
    (map, allowedAdditionalParticipantRole) => {
      map[allowedAdditionalParticipantRole.workspaceRole] = {
        compatibleRoles: allowedAdditionalParticipantRole.compatibleWorkspaceRoles,
        numberOfParticipantsAllowed: allowedAdditionalParticipantRole.numberOfParticipantsAllowed
      };
      return map;
    },
    {}
  );
  return result;
}
