import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import { DashboardDateRangeModel } from 'src/models';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from '../models';

// to be discussed, what backend will send back to us
export interface AggregatorComponentBaseProps {
  assignmentType: AssignmentTypeEnum;
  localStorageKey: string;
  fetchAggregatorInfo(payload: AggregatorsQueryParamsModel): Promise<FinancialDashboardAggregatorApiResponse>;
  onActionClick(filters: FinancialWorkspacesV2ApiRequest, engagedTab: FinancialDashboardRouteTabsEnumV2, currentTabFilters: DashboardFilterModel): void;
}

// might be easier if we can just have the object per type
export interface Aggregators {
  name: string;
  count: number;
}

export interface AggregatorsQueryParamsModel {
  assignmentType: AssignmentTypeEnum;
  queries: AggregatorQuery[];
}

export interface AggregatorQuery {
  name: string;
  myTasks?: number[];
  workspaceStatus?: number[];
  settlementPeriod?: DashboardDateRangeModel;
  withProposals?: boolean;
}

export type FinancialDashboardAggregatorApiRequest = AggregatorsQueryParamsModel;
export type FinancialDashboardAggregatorApiResponse = Aggregators[];

export enum AggregatorNameEnum {
  previous = 'previous-to-be-rebooked',
  todaySettlements = 'today-settlements',
  todayNotReady = 'today-not-ready',
  readyToSign = 'ready-to-sign',
  tomorrowSettlements = 'tomorrow-settlements',
  tomorrowNotReady = 'tomorrow-not-ready',
  thisWeekSettlements = 'this-week-settlements',
  totalOpenWorkspaces = 'total-open-workspaces',
  todaySettled = 'today-settled-workspaces',
  todayRebooked = 'today-rebooked-workspaces'
}

export interface TodayCountModel {
  settlements: number;
  notReady: number;
  readyToSign: number;
  settled: number;
  rebooked: number;
}

export interface TomorrowCountModel {
  settlements: number;
  notReady: number;
}
