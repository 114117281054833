import { HttpTypes } from '@sympli/api-gateway/types';

import { ClientRequestConfig } from '../types';

import type { Client } from '../client';

export class User {
  /**
   * @ignore
   */
  #client: Client;

  constructor(client: Client) {
    this.#client = client;
  }

  /**
   * This method retrieves a paginated list of users. It sends a request to the
   * `/settings/users`
   * API route.
   *
   * @param queryParams - Filters and pagination configurations.
   * @param config - Additional config to pass with the request.
   * @returns The paginated list of customers.
   *
   * @example
   * To retrieve the list of users:
   *
   * ```ts
   * sdk.settings.user.list()
   * .then(({ items: users, pageNumber, pageSize, totalCount, totalPages }) => {
   *   console.log(users)
   * })
   * ```
   *
   * To configure the pagination, pass the `pageSize` and `pageNumber` query parameters.
   *
   * For example, to retrieve only 10 items and skip 10 items:
   *
   * ```ts
   * sdk.settings.user.list({
   *   pageSize: 10,
   *   pageNumber: 10
   * })
   * .then(({ items: users, pageNumber, pageSize, totalCount, totalPages }) => {
   *   console.log(users)
   * })
   * ```
   */
  async list(queryParams?: HttpTypes.SettingsUserListApiRequestFilters, config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.SettingsUserListApiResponse>( //
      `/settings/users`,
      {
        query: queryParams,
        ...config
      }
    );
  }

  /**
   * This method retrieves a user by its ID. It sends a request to the
   * `/settings/users/{userId}`
   * API route.
   *
   * @param userId - The users ID.
   * @param config - Additional config to pass with the request.
   * @returns The users details.
   *
   * @example
   * To retrieve a user by its ID:
   *
   * ```ts
   * sdk.settings.user.retrieve("user_123")
   * .then(({ user }) => {
   *   console.log(user)
   * })
   * ```
   */
  async retrieve(userId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsUserApiResponse>( //
      `/settings/users/${userId}`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves permissions of a user. It sends a request to the
   * `/settings/users/{userId}/permissions`
   * API route.
   *
   * @param userId - The users ID.
   * @param config - Additional config to pass with the request.
   * @returns The users details.
   *
   * @example
   * To retrieve the permissions of a user by its ID:
   *
   * ```ts
   * sdk.settings.user.retrievePermissions("user_123")
   * .then(({ permissions, roles, groups }) => {
   *   console.log(permissions)
   * })
   * ```
   */
  async retrievePermissions(userId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsUserPermissionsApiResponse>( //
      `/settings/users/${userId}/permissions`,
      {
        ...config
      }
    );
  }

  /**
   * This method retrieves report permissions of a user. It sends a request to the
   * `/settings/users/{userId}/report-permissions`
   * API route.
   *
   * @param userId - The users ID.
   * @param config - Additional config to pass with the request.
   * @returns The users report permissions.
   *
   * @example
   * To retrieve the report permissions of a user by its ID:
   *
   * ```ts
   * sdk.settings.user.retrieveReportPermissions("user_123")
   * .then(({ reportPermissions, teamWorkloadReportAssignedUsers, maxTeamWorkloadReportAccessNumber }) => {
   *   console.log(reportPermissions)
   * })
   * ```
   */
  async retrieveReportPermissions(userId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsUserReportPermissionsApiResponse>( //
      `/settings/users/${userId}/report-permissions`,
      {
        ...config
      }
    );
  }

  /**
   * This method resends activation email to a user. It sends a request to the
   * `/settings/users/{userId}/activationEmail`
   * API route.
   *
   * @param userId - The users ID.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * sdk.settings.user.resendActivationEmail("user_123")
   * .then(() => {
   *   console.log('done')
   * })
   */
  async resendActivationEmail(userId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/users/${userId}/activationEmail`,
      {
        method: 'POST',
        ...config
      }
    );
  }

  /**
   * This method updates suspension status of a user. It sends a request to the
   * `/settings/users/{userId}/suspension`
   * API route.
   *
   * @param userId - The users ID.
   * @param body - The data to update in the suspension status.
   * @param config - Additional config to pass with the request.
   * @returns nothing
   *
   * @example
   * sdk.settings.user.updateUserSuspension("user_123", {
   *   isSuspended: true,
   * })
   * .then(() => {
   *   console.log('done')
   * })
   */
  async updateUserSuspension(userId: string, body: HttpTypes.SettingsUpdateUserSuspensionApiRequestBody, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.ResponseNotDefined>( //
      `/settings/users/${userId}/suspension`,
      {
        method: 'POST',
        body,
        ...config
      }
    );
  }

  /**
   * This method retrieves non-paginated list of linked devices of a user. It sends a request to the
   * `/settings/users/{userId}/devices`
   * API route.
   *
   * @param userId - The users ID.
   * @param config - Additional config to pass with the request.
   * @returns The list of devices of the user.
   *
   * @example
   * To retrieve the list of linked devices of a user:
   *
   * ```ts
   * sdk.settings.user.listLinkedDevices("user_1")
   * .then((devices) => {
   *   console.log(devices)
   * })
   * ```
   */
  async listLinkedDevices(userId: string, config?: ClientRequestConfig) {
    return this.#client.fetch<HttpTypes.SettingsUserDeviceListApiResponse>( //
      `/settings/users/${userId}/devices`,
      {
        ...config
      }
    );
  }

  /**
   * This method removes linked device from list of users devices. It sends a request to the
   * `/settings/users/{userId}/devices/{deviceId}/unlink`
   * API route.
   *
   * @param userId - The users ID.
   * @param deviceId - The users device ID.
   * @param config - Additional config to pass with the request.
   * @returns list of linked devices
   *
   * @example
   * sdk.settings.user.removeLinkedDevice("user_123", "device_123")
   * .then(() => {
   *   console.log('done')
   * })
   */
  async removeLinkedDevice(userId: string, deviceId: string, config?: ClientRequestConfig) {
    return await this.#client.fetch<HttpTypes.SettingsRemoveUserDeviceApiResponse>( //
      `/settings/users/${userId}/devices/${deviceId}/unlink`,
      {
        method: 'PUT',
        ...config
      }
    );
  }

  /**
   * This method verifies whether given email is taken. It sends a request to the
   * `/settings/users/validate-email`
   * API route.
   *
   * @param body - Payload containing email.
   * @param config - Additional config to pass with the request.
   * @returns true if email is taken, false otherwise
   *
   * @example
   * sdk.settings.user.isEmailTaken("user_123")
   * .then(() => {
   *   console.log('done')
   * })
   */
  async isEmailTaken(body: HttpTypes.SettingsIsUserEmailTaken, config?: ClientRequestConfig) {
    return await this.#client.fetch<boolean>( //
      `/settings/users/validate-email`,
      {
        method: 'PUT',
        body,
        ...config
      }
    );
  }
}
