import { HttpTypes } from '@sympli/api-gateway/types';

import { resolveReportLink } from 'src/@core/pages/links';

export function isActiveNav(
  category: 'new-ws' | 'ws' | 'workspaces' | 'standalone' | 'financial-workspaces' | 'tasks' | 'settlements' | 'invitations' | 'reports' | 'reports-home' | 'reports-page',
  args: {
    //
    pathname: string;
    workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
    reportTypeId?: HttpTypes.ReportTypeEnum;
  }
) {
  const {
    //
    pathname,
    workspaceTypeId,
    reportTypeId
  } = args;

  switch (category) {
    case 'ws':
      return (['standalone', 'financial-workspaces'] as const).some(c => isActiveNav(c, args));
    case 'new-ws':
      return (['standalone', 'financial-workspaces'] as const).some(c => isActiveNav(c, args));
    case 'workspaces':
      return pathname.startsWith('/workspace');
    case 'standalone':
      return pathname.startsWith('/standalone') || (pathname.startsWith('/workspace') && workspaceTypeId === HttpTypes.WorkspaceTypeEnum.RegistrationOnly);
    case 'financial-workspaces':
      return pathname.startsWith('/financial-workspaces') || (pathname.startsWith('/workspace') && workspaceTypeId !== HttpTypes.WorkspaceTypeEnum.RegistrationOnly);
    case 'settlements':
      return pathname.startsWith('/settlements');
    case 'invitations':
      return pathname.startsWith('/invitations');
    case 'tasks':
      return pathname === '/';
    case 'reports':
      return pathname.startsWith('/reports');
    case 'reports-home':
      return pathname === '/reports';
    case 'reports-page':
      if (Number.isInteger(reportTypeId)) {
        return pathname === resolveReportLink(HttpTypes.ReportFeatureTypeEnum.Favourite, reportTypeId!);
      }
      return false;
    default:
      return false;
  }
}
