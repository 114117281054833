import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { HttpTypes } from '@sympli/api-gateway/types';

import { FeatureToggleEnum } from 'src/@core/auth/feature-toggle/models';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useSubscriberAssignableGroups } from 'src/containers/dashboard/shared/reducers/subscriberAssignableGroups';
import { useFeatureFlag } from 'src/hooks';
import { CreateFinancialWorkspaceSharedModel, CreateFinancialWorkspaceStepEnum } from '../../models';
import InviteParticipants from './InviteParticipants';

interface Props {
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  onGlobalValuesUpdate: (value?: Partial<CreateFinancialWorkspaceSharedModel>) => CreateFinancialWorkspaceSharedModel;
  onStepChange: (step: CreateFinancialWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
}

function InviteParticipantsContainer(props: Props) {
  const navigate = useNavigate();
  const currentSubscriberData: HttpTypes.UserProfileModel = useProfile().data!;
  const { items: subscriberAssignableGroups } = useSubscriberAssignableGroups();
  const invitationSubscriberSearch = useFeatureFlag(FeatureToggleEnum.invitationSubscriberSearch);

  return (
    <InviteParticipants //
      {...props}
      currentSubscriberData={currentSubscriberData}
      assignableGroups={subscriberAssignableGroups}
      navigate={navigate}
      invitationSubscriberSearch={invitationSubscriberSearch}
    />
  );
}

export default React.memo(InviteParticipantsContainer);
