import dateFormat from 'dateformat';
import { call, put, takeLatest } from 'typed-redux-saga';

import { DateFormatEnum } from 'src/@core/models';
import { getTimeInTimeZone } from 'src/@core/utils/timezone';
import http, { NoResponseData } from 'src/utils/http';
import { actionFetchWorkspaceTasks } from '../../../../store/actions/workspace';
import { actionFetchSettlementDetails, actionPostSettlementApproval, actionSetCalendarMonth, SettlementApprovalRequestModel } from './actions';
import { SettlementDateDetailsApiResponse, SettlementDateDetailsModel } from './reducers/settlementDetail';

const fetchSettlementDetails = (workspaceId: string) => http.get<SettlementDateDetailsApiResponse>(`/workspaces/${workspaceId}/settlement-date-proposal`);

function* sagaFetchSettlementDetails(action: ReturnType<typeof actionFetchSettlementDetails.request>) {
  try {
    // Fetch the SettlementDetail
    const { workspaceId, participantId: currentParticipantId } = action.payload;
    const response = yield* call(fetchSettlementDetails, workspaceId);

    const {
      //
      participants,
      proposedByParticipantId,
      workspaceSettlementDate,
      proposedSettlementDate,
      settlementDateProposalId,
      minReschedulingInHours,
      reasonText,
      jurisdiction
    } = response;

    // TODO find participant Id from roleId and subscriberId
    // find currently signed user in the list of settlement participants.
    const currentParticipant = participants.find(item => item.id === currentParticipantId);
    const isAcceptedByUser = currentParticipant?.hasAccepted ?? false;
    const participantId = currentParticipant?.id ?? '';
    const isAcceptedByAll = participants.every(item => item.hasAccepted);
    const proposedByParticipant = participants.find(item => item.id === proposedByParticipantId);
    const localWorkspaceSettlementDate = workspaceSettlementDate ? dateFormat(getTimeInTimeZone(new Date(workspaceSettlementDate), jurisdiction), "yyyy-mm-dd'T'HH:MM") : undefined;
    const localProposedSettlementDate = proposedSettlementDate ? dateFormat(getTimeInTimeZone(new Date(proposedSettlementDate), jurisdiction), "yyyy-mm-dd'T'HH:MM") : undefined;

    const data: SettlementDateDetailsModel = {
      ...response,
      reasonText,
      settlementDateProposalId: settlementDateProposalId ?? undefined,
      workspaceSettlementDate: workspaceSettlementDate ?? undefined,
      localWorkspaceSettlementDate,
      proposedSettlementDate: proposedSettlementDate ?? undefined,
      localProposedSettlementDate,
      isAcceptedByUser,
      participantId,
      isAcceptedByAll,
      proposedByParticipant,
      isEditing: isAcceptedByAll,
      minReschedulingInHours: minReschedulingInHours
    };

    // * '2018-04-04T13:00:00.000+00:00' -> '2018-04-04T13:00:00'
    const settlementDateNoTimeZone = (proposedSettlementDate ?? workspaceSettlementDate)?.replace(/\.0.*$/, '');
    const calendarYearAndMonth = dateFormat(settlementDateNoTimeZone, DateFormatEnum.DATE);
    yield put(actionSetCalendarMonth(calendarYearAndMonth));

    yield put(actionFetchSettlementDetails.success({ data }));
  } catch (error) {
    yield put(actionFetchSettlementDetails.failure({ error }));
  }
}

export function postSettlementApproval(query: SettlementApprovalRequestModel) {
  return http.post<NoResponseData, SettlementApprovalRequestModel>(`/workspaces/${encodeURIComponent(query.workspaceId)}/settlement-date-approval`, query);
}

function* sagaPostSettlementApproval(action: ReturnType<typeof actionPostSettlementApproval.request>) {
  try {
    yield* call(postSettlementApproval, action.payload);
    yield put(actionPostSettlementApproval.success({ data: action.payload }));

    // fetch the task again since they due date will be changed
    const { workspaceId, participantId } = action.payload;
    yield put(actionFetchWorkspaceTasks.request({ workspaceId, participantId }));
  } catch (error) {
    yield put(actionPostSettlementApproval.failure({ error }));
  } finally {
    // participantId should never be null based on the contract and model.
    const { workspaceId, participantId = '' } = action.payload;
    yield put(actionFetchSettlementDetails.request({ workspaceId, participantId }));
  }
}

export default [
  //
  takeLatest(actionFetchSettlementDetails.request, sagaFetchSettlementDetails),
  takeLatest(actionPostSettlementApproval.request, sagaPostSettlementApproval)
];
