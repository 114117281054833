import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchSubscriberSigningRule = createAsyncAction(
  //
  'FETCH_SIGNING_RULE',
  'FETCH_SIGNING_RULE_SUCCESS',
  'FETCH_SIGNING_RULE_ERROR'
)<
  //
  void,
  { data: HttpTypes.SettingsSubscriberSigningRuleApiResponse },
  { error: Error }
>();
