import { call, put, takeLatest } from 'typed-redux-saga';

import { PaymentSummaryApiRequest } from 'src/containers/documents/models';
import { RegOnlyPaymentSummaryApiResponse } from 'src/containers/shared/payment-summary-box/models';
import http, { NoResponseData } from 'src/utils/http';
import { actionFetchMergedDocumentsPdf, actionFetchPaymentSummary, actionSaveDebitAccount, actionUpdatePaymentSummary, MergedDocumentsPdfApiRequest } from './actions';
import { SaveDebitAccountApiRequest } from './models';

const fetchPaymentSummaryData = (q: PaymentSummaryApiRequest) => {
  const { workspaceId, participantId } = q;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/payment-summary`;
  return http.get<RegOnlyPaymentSummaryApiResponse>(uri);
};

function* sagaFetchPaymentSummary(action: ReturnType<typeof actionFetchPaymentSummary.request>) {
  try {
    const payload = yield* call(fetchPaymentSummaryData, action.payload);
    yield put(actionFetchPaymentSummary.success(payload));
  } catch (error) {
    yield put(actionFetchPaymentSummary.failure({ error }));
  }
}

const fetchMergedDocumentsPdf = (payload: MergedDocumentsPdfApiRequest) => {
  const { workspaceId, participantId, documentIds } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/merge-pdf`;
  return http.post<string>(uri, documentIds); // TODO check usage
};

function* sagaFetchMergedDocumentsPdf(action: ReturnType<typeof actionFetchMergedDocumentsPdf.request>) {
  try {
    const payload = yield* call(fetchMergedDocumentsPdf, action.payload);
    yield put(actionFetchMergedDocumentsPdf.success(payload));
  } catch (error) {
    yield put(actionFetchMergedDocumentsPdf.failure({ error }));
  }
}

const saveDebitAccountData = (q: SaveDebitAccountApiRequest) => {
  const { workspaceId, participantId, debitAccountId } = q;

  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${participantId}/payment-summary?debitAccountId=${debitAccountId}`;
  return http.post<NoResponseData>(uri, {});
};

function* sagaSaveDebitAccountData(action: ReturnType<typeof actionSaveDebitAccount.request>) {
  try {
    yield put(actionUpdatePaymentSummary(action.payload.paymentSummaryModel));

    yield* call(saveDebitAccountData, action.payload);
    yield put(actionSaveDebitAccount.success());
  } catch (error) {
    yield put(actionSaveDebitAccount.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchPaymentSummary.request, sagaFetchPaymentSummary),
  takeLatest(actionFetchMergedDocumentsPdf.request, sagaFetchMergedDocumentsPdf),
  takeLatest(actionSaveDebitAccount.request, sagaSaveDebitAccountData)
];
