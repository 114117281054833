import { HttpTypes } from '@sympli/api-gateway/types';

import http, { sdk } from 'src/utils/http';

export const getQrCode = (): Promise<HttpTypes.MfaRetrieveMfaQrCodeApiResponse> => {
  //return http.get<MfaDataApiResponse>('/profile/link-device');
  return sdk.profile.mfa.retrieveMfaQrCode();
};

export interface FactorStatusApiResponse {
  isActive: boolean;
  factorId: string;
}

export const pollFactorStatus = (factorId: string) => http.get<FactorStatusApiResponse>(`/mfa/factor/${factorId}/enroll-status`);
