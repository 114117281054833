import * as React from 'react';

import classNames from 'classnames';
import dateFormat from 'dateformat';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCheck } from '@sympli/ui-framework/icons';

import Popover from 'src/@core/components/popover';
import { DocumentActionInfo } from 'src/containers/workspace/shared/detail/models';
import { getParticipantActionDisplayName, resolveActionPrefix, resolveActionRolePrefix } from './helpers';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  mode: 'light' | 'dark';
  documentActionInfo: DocumentActionInfo;
  currentParticipantId: string;
}
type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  showMultipleActionTooltip: boolean;
}

class ActionLabel extends React.PureComponent<Props, State> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  public readonly state: Readonly<State> = {
    showMultipleActionTooltip: false
  };

  render() {
    const { documentActionInfo, mode, classes } = this.props;
    if (!documentActionInfo) {
      return null;
    }

    const textStyle = mode === 'dark' ? classes.documentActionTextDark : classes.documentActionTextLight;

    if (this.resolveSingleSigner(documentActionInfo)) {
      return <>{this.renderSingleActionInfo(textStyle)}</>;
    }

    return <>{this.renderMultipleActionInfo(textStyle)}</>;
  }

  renderSingleActionInfo = (textStyle: string) => {
    const { documentActionInfo, classes, currentParticipantId } = this.props;
    if (documentActionInfo.documentActions.length !== 1) {
      return null;
    }

    const singleDocumentAction = documentActionInfo.documentActions[0];
    const actionPrefix = resolveActionPrefix(singleDocumentAction.type);
    const actionLabel = `${actionPrefix} ${getParticipantActionDisplayName(currentParticipantId, singleDocumentAction)}`;
    return (
      <React.Fragment>
        <IconCheck className={classes.documentActionIcon} />
        <Typography className={classNames(classes.text, textStyle)}>{actionLabel}</Typography>
      </React.Fragment>
    );
  };

  private renderMultipleActionInfo = (textStyle: string) => {
    const { documentActionInfo, classes, mode } = this.props;
    const { numberOfActions } = documentActionInfo;
    if (!numberOfActions) {
      return null;
    }

    const actionPrefix = resolveActionPrefix(documentActionInfo.actionType);
    const actionRolePrefix = resolveActionRolePrefix(documentActionInfo.actionType);

    if (documentActionInfo.documentActions.length === 0) {
      return <span className={textStyle}>{`${numberOfActions} ${actionRolePrefix}s required`}</span>;
    }

    const { showMultipleActionTooltip } = this.state;

    return (
      <>
        <Popover
          PaperProps={{
            classes: {
              root: classes.toolTip
            }
          }}
          open={showMultipleActionTooltip}
          anchorEl={this.buttonRef.current}
          onClose={this.onToolTipClose}
        >
          {this.renderMultipleActionContent()}
        </Popover>
        <IconCheck className={classes.documentActionIcon} />
        <Typography className={textStyle}>
          <span>{actionPrefix}</span>
          <SympliButton ref={this.buttonRef} onClick={this.onToolTipOpen} className={mode === 'dark' ? classes.multipleSignersDark : classes.multipleSignersLight} color="inherit">
            {`${documentActionInfo.documentActions.length} of ${numberOfActions} ${actionRolePrefix}s`}
          </SympliButton>
        </Typography>
      </>
    );
  };

  private renderMultipleActionContent = () => {
    const { documentActionInfo, classes } = this.props;

    return (
      <>
        {documentActionInfo.documentActions.map((action, index) => {
          const actionRole = resolveActionRolePrefix(documentActionInfo.actionType);
          return (
            <div key={index} className={classes.documentActionToolTip}>
              <FlexLayout justifyContent="flex-start">
                <div className={classes.toolTipLabel}>
                  {actionRole} {index + 1}:
                </div>
                <div className={classNames(classes.toolTipDes)}>{action.userFullName}</div>
              </FlexLayout>
              <FlexLayout justifyContent="flex-start">
                <div className={classes.toolTipLabel}>Authorised on:</div>
                <div className={classes.toolTipDes}>{dateFormat(action.createdDateTime, "dd mmm yyyy', at 'hh:MM TT")}</div>
              </FlexLayout>
            </div>
          );
        })}
      </>
    );
  };

  private resolveSingleSigner(documentActionInfo: DocumentActionInfo): boolean {
    if (!documentActionInfo.numberOfActions || documentActionInfo.numberOfActions === 1) {
      return true;
    }

    return false;
  }

  private onToolTipOpen = () => {
    this.setState({ showMultipleActionTooltip: true });
  };

  private onToolTipClose = () => {
    this.setState({ showMultipleActionTooltip: false });
  };
}

export default withStyles(styles)(ActionLabel);
