import * as React from 'react';

import SympliButton from '@sympli/ui-framework/components/sympli-button';

interface Props {
  redirectSettlementDatePage(): void;
}

function NoDateNoTime({ redirectSettlementDatePage }: Props) {
  return (
    <div className="w-full flex h-full justify-center flex-col items-center gap-[8px]">
      <span className="text-[35px] font-extrabold text-[var(--sunny-day)] font-volkswagen-serial-bold">Not Set yet</span>
      <SympliButton
        hasRadius
        variant="contained"
        className="rounded-[34px] px-[16px] py-[6px] bg-[var(--sunny-day)] text-[12px] hover:bg-[var(--sunny-day-dark)]"
        onClick={() => {
          redirectSettlementDatePage();
        }}
      >
        Book
      </SympliButton>
    </div>
  );
}

export default React.memo(NoDateNoTime);
