import { createAsyncAction } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

export const actionFetchBillingInvoicing = createAsyncAction(
  //
  'FETCH_BILLING_INVOICEING',
  'FETCH_BILLING_INVOICEING_SUCCESS',
  'FETCH_BILLING_INVOICEING_ERROR'
)<
  //
  void,
  { data: HttpTypes.BillingInvoicingApiResponse },
  { error: Error }
>();
