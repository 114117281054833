import * as React from 'react';

import { FormikProps, getIn } from 'formik';
import Typography from '@mui/material/Typography';
import withStyles, { ClassNameMap, WithStyles } from '@mui/styles/withStyles';

import { NswAddressSelectField_2_21 as AddressSelectField } from '@sympli-mfe/document-components/address-field/nsw/2-21';
import { NswPartyForm_2_21 as PartyForm } from '@sympli-mfe/document-components/party-form/nsw/2-21';
import { AddressFieldFormConfig, formatAddressLine } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { formatPartyName, formatPartyType } from '@sympli-mfe/document-forms-framework/components/party-form';
import Checkbox, { CheckboxClassKeys } from '@sympli/ui-framework/components/form/base-components/checkbox';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import Subform from '@sympli/ui-framework/components/formik/subform';

import { NSW_CAVEAT_ADDRESS_FORM_CONFIG } from '../../config';
import { ADDRESS_BOOK_KEY, CaveatModel, PARTY_BOOK_KEY, Proprietor } from '../../models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  name: string;
  formikProps: FormikProps<FormValues>;
  disabled: boolean;
  portalIdDialogs: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

type FormValues = CaveatModel;

class SectionRegisteredProprietor extends React.PureComponent<Props> {
  private readonly proprietorsClasses: Partial<ClassNameMap<keyof ReturnType<CheckboxClassKeys>>> = {
    root: this.props.classes.checkboxLabelRoot
  };

  private addressFormConfig: AddressFieldFormConfig = NSW_CAVEAT_ADDRESS_FORM_CONFIG;

  render() {
    const { name, formikProps, disabled, classes, portalIdDialogs } = this.props;
    const { values } = formikProps;

    const items: Proprietor[] = getIn(values, name) ?? [];
    const count = items.length;
    const hasMultipleItems = count > 1;
    const disableCheckbox = disabled || count <= 1;

    const partyBook = formikProps.values[PARTY_BOOK_KEY];

    return (
      <>
        {!disableCheckbox && (
          <FormGroup
            title={
              <Checkbox
                label="Select all proprietors"
                name="select-all-proprietors"
                format="boolean"
                classes={this.proprietorsClasses}
                checked={items.every(item => item.isAffected)}
                onChange={this.handleSelectAllProprietorsChange}
              />
            }
            paddingBottom={true}
          ></FormGroup>
        )}
        <DocumentFieldArray
          mode="fixed"
          arrayBinding={name}
          disabled={disabled}
          isSimpleType={false}
          itemStyle="formGroup"
          itemTitle={({ itemIndex, fieldName, itemFocusRef }) => (
            <Field // TODO: Pass itemFocusRef to checkbox once FW CheckboxProps['action'] type is fixed to accept RefObject
              component={CheckboxField}
              label={hasMultipleItems ? `Proprietor ${itemIndex + 1}` : 'Proprietor'}
              name={fieldName('isAffected')}
              disabled={disableCheckbox}
              classes={this.proprietorsClasses}
            />
          )}
          renderItem={({ item, fieldName, nextItemFocusRef }: DocumentArrayItemRenderProps<Proprietor>) => {
            if (!item.isAffected) {
              const party = formikProps.values[PARTY_BOOK_KEY].find(party => party.id === item.partyBookId)!;
              // If the party type is not readonly, we cannot be sure that its party type is meaningful, as we are forcing the type to "Individual" by default.
              const partyTypeLabel = party.partyType && party.readonlyMap?.partyType ? formatPartyType(party.partyType) : 'Name';
              const address = item.addressBookId ? formikProps.values[ADDRESS_BOOK_KEY].find(address => address.id === item.addressBookId) : null;
              return (
                <>
                  <Typography className={classes.partyOrAddressTitle}>{partyTypeLabel}</Typography>
                  <Typography className={classes.partyOrAddressDisplay}>{formatPartyName(party)}</Typography>
                  {address && (
                    <>
                      <Typography className={classes.partyOrAddressTitle}>Address</Typography>
                      <Typography className={classes.partyOrAddressDisplay}>{formatAddressLine(address, this.addressFormConfig)}</Typography>
                    </>
                  )}
                </>
              );
            }

            const bookIndexOfPartyId = partyBook.findIndex(party => party.id === item.partyBookId);
            const partyBindingPath = `${PARTY_BOOK_KEY}[${bookIndexOfPartyId}]`;

            return (
              <div data-name={`${name}[${bookIndexOfPartyId}]`}>
                <Subform //
                  subformBindingPath={partyBindingPath}
                  component={PartyForm}
                  disabled={disabled}
                  showFormGroups={false}
                />
                <AddressSelectField //
                  name={fieldName('addressBookId')}
                  disabled={disabled}
                  bookRef={ADDRESS_BOOK_KEY}
                  dialogPortalId={portalIdDialogs}
                  nextFocusRef={nextItemFocusRef}
                  addressFormConfig={NSW_CAVEAT_ADDRESS_FORM_CONFIG}
                />
              </div>
            );
          }}
        />
      </>
    );
  }

  handleSelectAllProprietorsChange = (event: React.ChangeEvent<HTMLInputElement>, newAreAllAffected: boolean): void => {
    const {
      name,
      formikProps: { touched, values, setFieldValue, setFieldTouched }
    } = this.props;

    const items: Proprietor[] = getIn(values, name) ?? [];
    const updatedItems = items.map(proprietor => ({
      ...proprietor,
      isAffected: newAreAllAffected
    }));

    setFieldValue(name, updatedItems);
    // we want to show errors immediately, therefore field needs to be marked as touched
    // prevent unnecessary render of Formik tree by calling setFieldTouched only when it makes sense
    !getIn(touched, name) && setFieldTouched(name, true, false /* don't trigger validation */);
  };
}

export default withStyles(styles)(SectionRegisteredProprietor);
