import * as React from 'react';

import dateFormat from 'dateformat';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { HttpTypes } from '@sympli/api-gateway/types';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import { IconCaretRight, IconInvoiceDollar } from '@sympli/ui-framework/icons';

import { DateFormatEnum } from 'src/@core/models';
import { ApiStatus } from 'src/utils/http';
import { WorkspaceReportsApiRequest } from './actions';
import { getReport } from './api';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  workspaceId: string;
  participantId: string;
  items: HttpTypes.WorkspaceReport[];
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: WorkspaceReportsApiRequest;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isLoadingPdf: boolean;
  loadingPDFId: string;
}

class WorkspaceReport extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isLoadingPdf: false,
    loadingPDFId: ''
  };

  render() {
    const { classes, error, isLoading, items } = this.props;

    if (isLoading) {
      return <BlockLoader />;
    }

    if (error) {
      return (
        <FormHelperText role="alert" error className={classes.overdueWarning}>
          {error}
        </FormHelperText>
      );
    }

    if (items.length === 0) {
      return 'No report generated yet.';
    }

    return <List className={classes.root}>{items.map(item => this.renderReportListItem(item))}</List>;
  }

  private renderReportListItem(item: HttpTypes.WorkspaceReport) {
    const { classes } = this.props;
    const { isLoadingPdf, loadingPDFId } = this.state;

    return (
      <ListItem key={item.id} button={true} className={classes.listItem} onClick={() => this.handleOnRowClick(item)} disabled={isLoadingPdf}>
        {isLoadingPdf && item.id === loadingPDFId ? (
          <InlineLoader alignCenter={true} />
        ) : (
          <>
            <ListItemText className={classes.listItemFirst}>
              <Typography>{dateFormat(item.createdDate, DateFormatEnum.DATEMONTHYEAR)}</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2">{item.description}</Typography>
              <Typography className={classes.listItemReference}>{item.reference}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  this.handleOnRowClick(item);
                }}
              >
                <IconInvoiceDollar className={classes.downloadIcon} />
                <Typography className={classes.downloadLabel} variant="subtitle2">
                  Download PDF
                </Typography>
                <IconCaretRight width="18" height="18" color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    );
  }

  private handleOnRowClick = async (item: HttpTypes.WorkspaceReport) => {
    const { workspaceId, participantId } = this.props;

    this.setState({ isLoadingPdf: true });
    this.setState({ loadingPDFId: item.id });
    return getReport({ workspaceId, participantId, reportId: item.id })
      .then((report: HttpTypes.WorkspaceReport) => {
        window.open(report.pdfUrl, '_blank');
      })
      .finally(() => {
        this.setState({ isLoadingPdf: false });
        this.setState({ loadingPDFId: '' });
      });
  };
}

const styledComponent = withStyles(styles)(WorkspaceReport);

export default styledComponent;
