import * as React from 'react';

import GeneralCrashedContent from 'src/@core/components/general-crashed-content';
import GenericLayoutWrapper from 'src/@core/components/layout/GenericLayoutWrapper';

import type { ErrorBoundaryInjectedProps } from 'src/@core/components/error-boundary';

type Props = Partial<ErrorBoundaryInjectedProps> & {
  topPanel: React.ReactNode;
};

function AppCrashedPage({ topPanel, ...rest }: Props): JSX.Element {
  return (
    <GenericLayoutWrapper topPanel={topPanel}>
      <div className="p-[32px]">
        <GeneralCrashedContent {...rest} />
      </div>
    </GenericLayoutWrapper>
  );
}

export default React.memo(AppCrashedPage);
