import React, { useCallback } from 'react';

import { Form, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDelete } from '@sympli/ui-framework/icons';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import { useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { modelKey } from 'src/utils/formUtils';
import { actionFetchWorkspaceFiles } from '../workspace-files-container/actions';
import { WorkspaceFile } from '../workspace-files/models';
import { getInitialValues } from './helpers';
import { DeleteFileApiModel } from './models';
import ReasonField from './ReasonField';
import { useStyles } from './styles';
import { getValidationSchema } from './validationSchema';

export interface OwnProps {
  workspaceId: string;
  participantId: string;
  workspaceTypeId: HttpTypes.WorkspaceTypeEnum;
  deleteFile: WorkspaceFile;
  openDialog: boolean;
  onClose(): void;
}

const fieldName = modelKey<DeleteFileApiModel>();

function WorkspaceFileDeleteDialog({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  deleteFile,
  openDialog,
  onClose
}: OwnProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { id: fileId, description } = deleteFile;

  const renderTitle = () => {
    return (
      <FlexLayout alignItems="center">
        <IconDelete className={classes.headingIcon} />
        <span className={classes.headingText}>Delete file</span>
      </FlexLayout>
    );
  };

  const onPostSubmit = (args: FormikPostSubmitArgs<DeleteFileApiModel>) => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: `uploaded-files-delete`,
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId]
    });
    if (args.error) {
      return;
    }
    onClose();
    // Refresh uploaded files list
    dispatch(actionFetchWorkspaceFiles.request({ workspaceId, participantId }));
  };

  const renderForm = useCallback(
    (formikProps: FormikProps<DeleteFileApiModel>) => {
      const { dirty, isValid, isSubmitting } = formikProps;
      const disabled = !dirty || !isValid || isSubmitting;
      return (
        <Form aria-label={`Delete file form`}>
          <ReasonField //
            name={fieldName('reason')}
          />
          <WizardStepper //
            disabled={disabled}
            isLoading={isSubmitting}
            onBack={onClose}
            backLabel="Cancel"
            nextLabel={'Delete'}
            color="secondary"
          />
        </Form>
      );
    },
    [onClose]
  );

  return (
    <ConfirmationDialog //
      open={openDialog}
      onClose={onClose}
      title={renderTitle()}
      showActionButtons={false}
      classes={{ dialogContent: classes.dialogContent }}
    >
      <p>You are about to delete the {description} file.</p>
      <p>This action cannot be undone.</p>
      <Formik //
        method="post"
        action={`/files/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/files/${encodeURIComponent(fileId)}/delete`}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onPostSubmit={onPostSubmit}
      >
        {(formikProps: FormikProps<DeleteFileApiModel>) => renderForm(formikProps)}
      </Formik>
    </ConfirmationDialog>
  );
}

export default React.memo(WorkspaceFileDeleteDialog);
