import { HttpTypes } from '@sympli/api-gateway/types';

import http from 'src/utils/http';
import { DeleteWorkspaceDirectionLineItemRequestModel, UpdateWorkspaceDirectionLineItemRequestModel } from './models';

export const updateWorkspaceDirectionLineItem = (payload: UpdateWorkspaceDirectionLineItemRequestModel) => {
  const { workspaceId, participantId, requestPayload } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/direction-lineitem`;
  return http.post<HttpTypes.UpdateWorkspaceDirectionLineItemApiResponse>(uri, requestPayload);
};

export const deleteWorkspaceDirectionLineItem = (payload: DeleteWorkspaceDirectionLineItemRequestModel) => {
  const { workspaceId, participantId, directionItemId } = payload;
  const uri = `/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/direction-lineitem/${directionItemId}?isLinkedSettlementItem=${payload.isLinkedSettlementItem}`;
  return http.delete<HttpTypes.UpdateWorkspaceDirectionLineItemApiResponse>(uri);
};
