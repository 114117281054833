import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { FeatureToggleEnum } from '../auth/feature-toggle/models';
import { actionAddCurrentPageFeatureFlag, PlatformEnum } from '../store/actions/currentPageFeatureFlags';
import { useProfile } from '../store/reducers/profile';
import useStoreSelector from '../store/useStoreSelector';
import { getFeatureFlags } from './helpers';
import useSafeDispatch from './useSafeDispatch';

export default function useFeatureFlag(featureToggleType: FeatureToggleEnum | FeatureToggleEnum[]): boolean {
  // feature flags enabled by LaunchDarkley
  // no stale data issue, profile is global across platform
  const enabledFeaturesByLD = useProfile().data?.features.names as FeatureToggleEnum[] | undefined;
  // feature flags controlled by UI
  // no stale data issue, featureFlagsControlledByUI is global across platform
  const featuresControlledByUI = useStoreSelector(store => store.featureFlagsControlledByUI.items);
  const dispatch = useSafeDispatch(useDispatch());

  const featureFlags = getFeatureFlags(featuresControlledByUI, enabledFeaturesByLD);

  // this is what UI needs to know, whether this feature should be treated as enabled or disabled
  const isFeatureEnabled = Array.isArray(featureToggleType) //
    ? featureToggleType.some(type => featureFlags.some(flag => flag.name === type && flag.isEnabled))
    : featureFlags.some(flag => flag.name === featureToggleType && flag.isEnabled);

  const featureToggleTypeArray = Array.isArray(featureToggleType) ? featureToggleType : new Array(featureToggleType);

  // this useEffect is used to update our redux reducer 'currentPageFeatureFlags'
  useEffect(() => {
    featureToggleTypeArray.map(flag => {
      const feature = featureFlags.find(f => f.name === flag);
      return feature
        ? dispatch(
            actionAddCurrentPageFeatureFlag({
              name: flag,
              status: feature.isEnabled,
              isControlledBy: feature.isControlledBy
            })
          )
        : // feature cannot be found in 'featureFlags' is disabled by both UI and LD, platform is LD
          dispatch(
            actionAddCurrentPageFeatureFlag({
              name: flag,
              status: false,
              isControlledBy: PlatformEnum.LaunchDarkley
            })
          );
    });
  }, [dispatch, featureFlags, featureToggleTypeArray]); // always make Array as dependency, to ensure useEffect always gets executed

  return isFeatureEnabled;
}
