import React, { useCallback, useEffect, useMemo } from 'react';

import _debounce from 'lodash-es/debounce';
import _isEqual from 'lodash-es/isEqual';
import { useDispatch } from 'react-redux';

import { HttpTypes } from '@sympli/api-gateway/types';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import Secured from 'src/@core/auth/profile-security/Secured';
import { actionOpenGlobalSimpleNotification } from 'src/@core/store/actions/globalSimpleNotification';
import { useProfile } from 'src/@core/store/reducers/profile';
import { useWorkspaceAssignableGroups } from 'src/@core/store/reducers/workspaceAssignableGroups';
import DashboardWrapper from 'src/components/layout/v2/DashboardWrapper';
import { AssignmentTypeEnum, DashboardSortDirectionEnum } from 'src/containers/dashboard/shared/models';
import { useSafeDispatch, useScreenSize } from 'src/hooks';
import { DashboardTypeEnum } from 'src/models';
import { getWorkspaceOverviewUrl } from 'src/router/helpers';
import { screens, ScreenSizeEnum, ScreenSizeVariant } from 'src/theme';
import CreateNewWorkspaceContainer from '../components/create-new-workspace';
import { actionFetchGroupMembers } from '../shared/actions';
import { DEBOUNCE_LIMIT } from '../shared/helpers';
import { usePermissionDialog } from '../shared/hooks/usePermissionDialog';
import useShowMessage from '../shared/hooks/useShowMessage';
import { actionFetchStandaloneWorkspacesFeedV2 } from './actions';
import { removeLocalStorageDateTime, removeLocalStorageSavedModel, updateLocalStorage } from './aggregators/helper';
import { fetchAggregatorInfo } from './api';
import { defaultDashboardFilterModel, getFiltersWithSelectedTab } from './filters/helper';
import { defaultTabQuery, getDefaultAssignmentTypeTab, resolveSavedTabFiltersQuery } from './helpers';
import { getWarningMessage, WarningMessageEnum } from './messages';
import { StandaloneDashboardFilterModel, StandaloneDashboardRouteTabsEnumV2, StandaloneWorkspacesV2ApiRequest } from './models';
import { useStandaloneWorkspacesFeedV2 } from './reducers/standaloneWorkspacesFeedV2';
import StandaloneWorkspaces from './StandaloneWorkspaces';
import StandaloneWorkspacesContext from './StandaloneWorkspaces.context';
import { StandaloneTableModel } from './table/models';

function StandaloneWorkspacesContainer() {
  const dispatch = useSafeDispatch(useDispatch());
  const dispatchErrorDialog = usePermissionDialog('workspaces');
  const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([]);
  const [isRefetchingData, setIsRefetchingData] = React.useState<boolean>(false);
  const [currentUpdatedTime, setCurrentUpdatedTime] = React.useState<Date>(new Date());
  const [selectedTab, setSelectedTab] = React.useState<StandaloneDashboardRouteTabsEnumV2>(StandaloneDashboardRouteTabsEnumV2.active);
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const screenSize: ScreenSizeEnum = parseInt(screens[screenVariant]);

  // reset checkbox values
  const resetSelectedItems = () => {
    setSelectedIndexes([]);
  };

  const { query, totalCount, items, status } = useStandaloneWorkspacesFeedV2();
  // assigned to group is always there for the group value
  const assignedGroup = useWorkspaceAssignableGroups().items;
  const defaultGroup = assignedGroup.length > 0 ? assignedGroup[0].id : undefined;

  const { userId, userPermissions } = useProfile().data!;
  const hasManageInvitationPermission = userPermissions.includes(HttpTypes.UserLevelPermissionEnum.ManageInvitations);

  // localStorageKey is for storing filter config
  const localStorageKey = `${userId}_standaloneDashboard`;
  const uniqueStorageKeyForWelcomeMessage = `SA_DB_${userId}`;
  const uniqueStorageKeyForRefreshMessage = `SA_DB_REFRESH_${userId}`;

  const assignmentTypeLocalStorageKey = `${userId}_standaloneDashboard_assignmentType`;
  const [assignmentType, setAssignmentType] = React.useState<AssignmentTypeEnum>(getDefaultAssignmentTypeTab(assignmentTypeLocalStorageKey));

  const [selectedFilters, hasSavedValue] = useMemo(() => getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType), [localStorageKey, selectedTab, assignmentType]);
  // this is filters we need to persistent during all actions
  // as aggregators can impact filters (override), so it is always top down actions
  const [currentTabFilters, setCurrentTabFilters] = React.useState(selectedFilters);

  const fetchInitialData = React.useCallback(
    (assignmentType: AssignmentTypeEnum, defaultGroup?: string) => {
      const { sortBy, sortOrder }: StandaloneWorkspacesV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);
      const filter = hasSavedValue
        ? {
            withProposals: selectedTab === StandaloneDashboardRouteTabsEnumV2.toBeReworked ? false : undefined,
            ...resolveSavedTabFiltersQuery(selectedFilters, assignmentType),
            sortBy,
            sortOrder
          }
        : defaultTabQuery(selectedTab, assignmentType, defaultGroup);
      if (!filter.workspaceStatus || filter.workspaceStatus.length === 0) {
        filter.workspaceStatus = [...(defaultTabQuery(selectedTab, assignmentType, defaultGroup).workspaceStatus ?? [])];
      }

      // This is to avoid duplicate calls when dependencies update not at the same time
      if ((status === 'pending' || status === 'refetching') && _isEqual(query, filter)) {
        return;
      }

      dispatch(actionFetchStandaloneWorkspacesFeedV2.request(filter));
    },
    [dispatch, hasSavedValue, selectedFilters, selectedTab]
  );

  useEffect(() => {
    // defaultGroup is only used for Reassign Tab, other tabs don't need to listen to the change of defaultGroup
    if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      fetchInitialData(assignmentType, defaultGroup);
    }
  }, [assignmentType, defaultGroup, fetchInitialData]);

  useEffect(() => {
    if (assignmentType !== AssignmentTypeEnum.ReAssigned) {
      fetchInitialData(assignmentType);
    }
  }, [fetchInitialData, assignmentType]);

  useEffect(() => {
    if (currentTabFilters.groupIds?.length === 0 && assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
      setCurrentTabFilters({ ...currentTabFilters, groupIds: [defaultGroup] });
    }
  }, [assignmentType, currentTabFilters, defaultGroup]);

  const sorting: {
    sortBy: string | undefined;
    sortOrder: DashboardSortDirectionEnum | undefined;
  } = useMemo(() => {
    const { sortBy, sortOrder }: StandaloneWorkspacesV2ApiRequest = defaultTabQuery(selectedTab, assignmentType, defaultGroup);

    return {
      sortBy: query.sortBy !== undefined ? query.sortBy : sortBy,
      sortOrder: query.sortOrder !== undefined ? query.sortOrder : sortOrder
    };
  }, [selectedTab, assignmentType, defaultGroup, query.sortBy, query.sortOrder]);

  useEffect(() => {
    dispatch(actionFetchGroupMembers.request());
  }, [dispatch]);

  // clean up the date time filters when un-mount since the SDT/LAST ACCESS should always be default
  useEffect(() => {
    return () => {
      removeLocalStorageDateTime(localStorageKey);
    };
  }, [localStorageKey]);

  const fetchData = useCallback(
    (updatedFilter: Partial<StandaloneWorkspacesV2ApiRequest>) => {
      const shouldUseDefaultWorkspaceStatus = updatedFilter => {
        const combinedQuery: StandaloneWorkspacesV2ApiRequest = {
          ...query,
          ...updatedFilter
        };
        return !combinedQuery.workspaceStatus || combinedQuery.workspaceStatus.length === 0;
      };

      if (shouldUseDefaultWorkspaceStatus(updatedFilter)) {
        const defaultQuery = defaultTabQuery(selectedTab, assignmentType, defaultGroup);

        updatedFilter = {
          ...updatedFilter,
          workspaceStatus: defaultQuery.workspaceStatus
        };
      }

      const newQuery: StandaloneWorkspacesV2ApiRequest = {
        ...query,
        ...updatedFilter,
        assignmentType
      };

      dispatch(actionFetchStandaloneWorkspacesFeedV2.request(newQuery));
    },
    [query, assignmentType, dispatch, selectedTab, defaultGroup]
  );

  const handleOnAssignmentChange = useCallback(
    (assignmentType: AssignmentTypeEnum) => {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'assignment-tab',
        logGroupId: `${DashboardTypeEnum.Standalone}-dashboard`,
        tabName: `${assignmentType}`
      });

      setAssignmentType(assignmentType);
      setSelectedTab(StandaloneDashboardRouteTabsEnumV2.active);
      localStorage.setItem(assignmentTypeLocalStorageKey, assignmentType.toString());
      const [selectedFilters] = getFiltersWithSelectedTab(localStorageKey, StandaloneDashboardRouteTabsEnumV2.active, assignmentType);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [assignmentTypeLocalStorageKey, localStorageKey]
  );

  const handleOnWorkspaceStatusTabChange = useCallback(
    (selectedTab: StandaloneDashboardRouteTabsEnumV2, skipLogging?: boolean) => {
      if (!skipLogging) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'filter-tab',
          logGroupId: `${DashboardTypeEnum.Standalone}-dashboard`,
          tabName: `${selectedTab}`
        });
      }
      setSelectedTab(selectedTab);
      // have to reset this as the current filters been in state
      const [selectedFilters, _] = getFiltersWithSelectedTab(localStorageKey, selectedTab, assignmentType);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...selectedFilters });

      resetSelectedItems();
    },
    [assignmentType, localStorageKey, setSelectedTab]
  );

  const handleOnTableFiltersReset = useCallback(() => {
    removeLocalStorageSavedModel(localStorageKey, selectedTab, assignmentType);
    setCurrentTabFilters(defaultDashboardFilterModel(selectedTab, assignmentType));
    // fire the default api call as we cleanup all the filters

    dispatch(actionFetchStandaloneWorkspacesFeedV2.request(defaultTabQuery(selectedTab, assignmentType, defaultGroup)));
    resetSelectedItems();
  }, [defaultGroup, assignmentType, dispatch, localStorageKey, selectedTab]);

  const handleOnAggregatorActionClick = useCallback(
    (
      //
      filters: StandaloneWorkspacesV2ApiRequest,
      engagedTab: StandaloneDashboardRouteTabsEnumV2,
      currentTabFilters: StandaloneDashboardFilterModel
    ) => {
      // for this one we should always fire the call direction instead of getting existing query cached in redux
      if (assignmentType === AssignmentTypeEnum.ReAssigned && defaultGroup) {
        filters.groupIds = [defaultGroup];
        currentTabFilters.groupIds = [defaultGroup];
      }
      updateLocalStorage(localStorageKey, currentTabFilters);

      handleOnWorkspaceStatusTabChange(engagedTab, true);
      // must manually fire the call since since tab will not trigger the api call
      if (selectedTab === engagedTab) {
        dispatch(actionFetchStandaloneWorkspacesFeedV2.request(filters));
      }

      resetSelectedItems();
    },
    [assignmentType, defaultGroup, dispatch, handleOnWorkspaceStatusTabChange, localStorageKey, selectedTab]
  );

  const handleOnPageChange = useCallback(
    (args: { pageNumber: number }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
      if (selectedIndexes.length > 0) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: getWarningMessage(WarningMessageEnum.NoteReassign),
            autoHideDuration: 5000,
            variant: 'new-warning'
          })
        );
      }
    },
    [fetchData, assignmentType, selectedIndexes, dispatch]
  );

  const handleOnSortChange = useCallback(
    (args: { sortBy: string; sortOrder: DashboardSortDirectionEnum }) => {
      fetchData({
        ...args,
        assignmentType
      });
      resetSelectedItems();
    },
    [fetchData, assignmentType]
  );

  const handleOnRowClick = React.useCallback(
    (rowDetailIndex: number, rowData: StandaloneTableModel) => {
      if (assignmentType === AssignmentTypeEnum.ReAssigned) {
        if (!hasManageInvitationPermission) {
          dispatchErrorDialog();
        } else {
          setSelectedIndexes(selectedIndexes.includes(rowDetailIndex) ? selectedIndexes.filter(x => x !== rowDetailIndex) : selectedIndexes.concat([rowDetailIndex]));
        }
      } else {
        const { workspaceId, participantId } = rowData;
        // open in a new tab
        window.open(getWorkspaceOverviewUrl(workspaceId, participantId));
      }
    },
    [assignmentType, dispatchErrorDialog, hasManageInvitationPermission, selectedIndexes]
  );

  const handleOnAddFilterUpdate = useCallback(
    (currentTabFilters: StandaloneDashboardFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);

      // need to update the filter as change local storage directly will not trigger the re-render
      setCurrentTabFilters({ ...currentTabFilters });
    },
    [localStorageKey]
  );

  const refreshTableData = useCallback(() => {
    dispatch(actionFetchStandaloneWorkspacesFeedV2.request(query));
  }, [dispatch, query]);

  const refreshHandler = () => {
    setIsRefetchingData(true);
    refreshTableData();
  };

  React.useEffect(() => {
    if (isRefetchingData && (status === 'resolved' || status === 'rejected')) {
      setIsRefetchingData(false);
    }
  }, [status, isRefetchingData]);

  const fetchDataWithDebounce = useMemo(() => _debounce(fetchData, DEBOUNCE_LIMIT), [fetchData]);

  const handleOnTableFilterChange = useCallback(
    (updatedFilters: StandaloneWorkspacesV2ApiRequest, currentTabFilters: StandaloneDashboardFilterModel) => {
      updateLocalStorage(localStorageKey, currentTabFilters);
      resetSelectedItems();
      if (typeof updatedFilters.lastAccessPeriod === 'string' && updatedFilters.lastAccessPeriod === 'AllDates') {
        updatedFilters.lastAccessPeriod = null;
      }

      if (typeof updatedFilters.completedDatePeriod === 'string' && updatedFilters.completedDatePeriod === 'AllDates') {
        updatedFilters.completedDatePeriod = null;
      }

      if (typeof updatedFilters.archivedDatePeriod === 'string' && updatedFilters.archivedDatePeriod === 'AllDates') {
        updatedFilters.archivedDatePeriod = null;
      }

      // state update so force the filter component update
      setCurrentTabFilters({ ...currentTabFilters });

      fetchDataWithDebounce({
        ...query, // merge current query
        ...updatedFilters,
        pageNumber: 1
      });
    },
    [fetchDataWithDebounce, localStorageKey, query]
  );

  // Reassign handlers
  const handleOnDismissReassignBar = (event: React.MouseEvent) => {
    resetSelectedItems();
  };

  const [shouldShowWelcomeMessage, dismissWelcomeMessageHandler] = useShowMessage(uniqueStorageKeyForWelcomeMessage);
  const [shouldShowRefreshMessage, dismissRefreshIconMessageHandler] = useShowMessage(uniqueStorageKeyForRefreshMessage);

  const refresh = useCallback(() => {
    dispatch(actionFetchStandaloneWorkspacesFeedV2.request(query));
  }, [dispatch, query]);

  const resetCurrentUpdatedTime = React.useCallback((date: Date) => {
    setCurrentUpdatedTime(date);
  }, []);

  return (
    <DashboardWrapper>
      <StandaloneWorkspacesContext.Provider value={{ currentUpdatedTime, resetCurrentUpdatedTime }}>
        <StandaloneWorkspaces
          /**
           * header component props
           */
          screenSize={screenSize}
          assignmentType={assignmentType}
          onAssignmentTypeChange={handleOnAssignmentChange}
          createNewButtonNode={
            <Secured requiredUserPermissions={HttpTypes.UserLevelPermissionEnum.ManageInvitations} hiddenForDisabled>
              <CreateNewWorkspaceContainer />
            </Secured>
          }
          /**
           * aggregators component props
           */
          localStorageKey={localStorageKey}
          fetchAggregatorInfo={fetchAggregatorInfo}
          onAggregatorClick={handleOnAggregatorActionClick}
          /**
           * nav-tabs component props
           */
          workspaceStatusGroup={selectedTab}
          onWorkspaceStatusTabChange={handleOnWorkspaceStatusTabChange}
          /**
           * reset filters props
           */
          onTableFiltersReset={handleOnTableFiltersReset}
          /**
           * filters component props
           */
          currentTableFilters={currentTabFilters}
          onTableAddFilterChange={handleOnAddFilterUpdate}
          onTableFiltersChange={handleOnTableFilterChange}
          /**
           * table component props
           */
          rows={items}
          apiStatus={status}
          totalCount={totalCount}
          pageSize={query.pageSize}
          pageNumber={query.pageNumber}
          sortBy={sorting.sortBy}
          sortOrder={sorting.sortOrder}
          onPageChange={handleOnPageChange}
          // disable sorting for archived tab
          onSortChange={handleOnSortChange}
          onRowClick={handleOnRowClick}
          /**
           * Reassign Table Extra props
           */
          selectedRowIndexes={selectedIndexes}
          onSelectionChange={(selectedRowIndexes: number[]) => {
            if (!hasManageInvitationPermission) {
              dispatchErrorDialog();
            } else {
              setSelectedIndexes(selectedRowIndexes);
            }
          }}
          /**
           * Reassign bar props
           */
          shouldShowReassignBar={selectedIndexes.length > 0}
          handleOnDismissReassignBar={handleOnDismissReassignBar}
          selectedItems={items.filter((x, index) => selectedIndexes.includes(index)).map(x => ({ workspaceId: x.workspaceId, participantId: x.participantId }))}
          groupId={currentTabFilters.groupIds && currentTabFilters.groupIds.length > 0 ? currentTabFilters.groupIds[0] : defaultGroup}
          resetSelectedItems={resetSelectedItems}
          autoRefresh={refresh}
          /**
           * Reassign Welcome
           * */
          shouldShowWelcomeMessage={shouldShowWelcomeMessage && assignmentType === AssignmentTypeEnum.ReAssigned}
          dismissWelcomeMessageHandler={dismissWelcomeMessageHandler}
          /** 
         refresh icon
        */
          refetchingAggregators={isRefetchingData}
          isRefetchingData={isRefetchingData}
          refreshHandler={refreshHandler}
          shouldShowRefreshMessage={shouldShowRefreshMessage}
          dismissRefreshIconMessageHandler={dismissRefreshIconMessageHandler}
        />
      </StandaloneWorkspacesContext.Provider>
    </DashboardWrapper>
  );
}

export default React.memo(StandaloneWorkspacesContainer);
