import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchGeneralAccountUsages } from '../actions';

export interface GeneralAccountUsagesState {
  detail?: HttpTypes.GeneralAccountsUsagesApiResponse;
  status: ApiStatus;
  isLoading: boolean;
  error?: string;
}

const initialState: GeneralAccountUsagesState = {
  detail: undefined,
  status: 'idle',
  isLoading: false,
  error: undefined
};

const generalAccountUsages = createReducer<
  //
  GeneralAccountUsagesState,
  Action
>(initialState)
  .handleAction(actionFetchGeneralAccountUsages.request, (state, _): GeneralAccountUsagesState => {
    return {
      ...state,
      isLoading: true,
      error: undefined
    };
  })
  .handleAction(actionFetchGeneralAccountUsages.success, (state, action): GeneralAccountUsagesState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      detail: action.payload.data
    };
  })
  .handleAction(actionFetchGeneralAccountUsages.failure, (state, action): GeneralAccountUsagesState => {
    return {
      ...state,
      status: 'rejected',
      isLoading: false,
      error: action.payload.error.message
    };
  });

export default generalAccountUsages;
