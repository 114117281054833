import * as React from 'react';

import { HttpTypes } from '@sympli/api-gateway/types';

import { DocumentListModel, LodgementCaseDetail } from 'src/containers/shared/document-list/models';
import { useWorkspaceDetail } from 'src/store/reducers/workspace/workspaceDetail';
import { useWorkspaceParticipants } from 'src/store/reducers/workspace/workspaceParticipants';
import DeleteDocumentDialog from '../delete-document-dialog';
import { isAllowedToDeletePrimaryLodgementCaseDocuments } from '../document-list-toolbar/helpers';
import DocumentListDelete from './DocumentListDelete';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
type Props = {
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: HttpTypes.WorkspaceTypeEnum;
  documentId?: string;
  lodgementCaseDetail: LodgementCaseDetail;
  onCancel(): void;
};

function DocumentListDeleteContainer({ workspaceId, participantId, workspaceTypeId, lodgementCaseDetail, onCancel }: Props) {
  const [dialogState, setDialogState] = React.useState<{
    //
    isOpen: boolean;
    document?: DocumentListModel;
    lodgementCase?: HttpTypes.LodgementCase;
  }>({
    //
    isOpen: false,
    document: undefined
  });
  const classes = useStyles();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);

  // add delete icons and assign handler that displays dialog
  const itemsToDelete = React.useMemo(() => {
    return lodgementCaseDetail.documents.map(item => {
      return item.icon && !item.isLI
        ? {
            ...item,
            icon: React.cloneElement(item.icon, {
              className: classes.deleteIcon,
              onClick: (event: React.MouseEvent) => {
                // prevent default behaviour which is navigation to a particular document
                event.preventDefault();
                event.stopPropagation();

                setDialogState({
                  //
                  isOpen: true,
                  document: item,
                  lodgementCase: lodgementCaseDetail.lodgementCase
                });
              }
            })
          }
        : item;
    });
  }, [lodgementCaseDetail, classes]);

  const isParticipantAllowedToDeletePLCDocuments = isAllowedToDeletePrimaryLodgementCaseDocuments({
    workspaceType: workspaceDetailState.detail?.workspaceType.id,
    currentParticipantId: workspaceDetailState.detail?.currentParticipantId,
    workspaceParticipants: workspaceParticipantsState
  });

  return (
    <>
      <DocumentListDelete //
        items={itemsToDelete}
        forceDisable={lodgementCaseDetail.lodgementCase?.isPrimary && !isParticipantAllowedToDeletePLCDocuments}
      />
      {dialogState.document && (
        <DeleteDocumentDialog //
          isOpen={dialogState.isOpen}
          setOpenDialog={(isOpen: boolean) => setDialogState({ ...dialogState, isOpen })}
          workspaceTypeId={workspaceTypeId!}
          documentId={dialogState.document.documentId}
          documentType={dialogState.document.documentIdentifier}
          documentName={dialogState.document.name ?? ''}
          lodgementCase={dialogState.lodgementCase}
          liDocumentId={lodgementCaseDetail.documents.find(d => d.isLI)?.documentId}
          onCancel={onCancel}
        />
      )}
    </>
  );
}

export default React.memo(DocumentListDeleteContainer);
