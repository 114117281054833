import { Action, createReducer } from 'typesafe-actions';

import { HttpTypes } from '@sympli/api-gateway/types';

import { ApiStatus } from 'src/utils/http';
import { actionFetchJurisdictionRevenueOffice } from '../actions/jurisdictions';

export interface JurisdictionRevenueOfficeState {
  detail?: HttpTypes.JurisdictionRevenueOfficeApiResponse;
  status: ApiStatus;
  error?: string;
}

const initialState: JurisdictionRevenueOfficeState = {
  status: 'idle'
};

const reducer = createReducer<
  //
  JurisdictionRevenueOfficeState,
  Action
>(initialState)
  .handleAction(actionFetchJurisdictionRevenueOffice.request, (state): JurisdictionRevenueOfficeState => {
    return {
      ...state,
      status: 'pending',
      error: undefined
    };
  })
  .handleAction(actionFetchJurisdictionRevenueOffice.success, (state, action): JurisdictionRevenueOfficeState => {
    return {
      ...state,
      detail: action.payload.data,
      status: 'resolved'
    };
  })
  .handleAction(actionFetchJurisdictionRevenueOffice.failure, (state, action): JurisdictionRevenueOfficeState => {
    return {
      ...state,
      status: 'rejected',
      error: action.payload.error.message
    };
  });

export default reducer;
