import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiRequestToTransferEctControl_2_28_0_Model, RequestToTransferEctControl_2_28_0_Model } from './models';

class Converter implements IConverter<RequestToTransferEctControl_2_28_0_Model, ApiRequestToTransferEctControl_2_28_0_Model> {
  fromApiToFormModel(apiModel: ApiRequestToTransferEctControl_2_28_0_Model, context: IConverterContext): RequestToTransferEctControl_2_28_0_Model {
    return applyDefaultMap(apiModel, fallbackMap);
  }
  fromFormToApiModel(model: RequestToTransferEctControl_2_28_0_Model, _originalApiModel: ApiRequestToTransferEctControl_2_28_0_Model): ApiRequestToTransferEctControl_2_28_0_Model {
    return applyVisibilityFallbackMap(model, fallbackMap);
  }
}

export default new Converter();
